import React, { PureComponent, ReactNode } from "react";
import "./TooltipWrapper.scss";

interface Props {
    tooltip?: React.ReactElement<any> | string;
    children: ReactNode;
    tooltipWidth?: number;
}

class TooltipWrapper extends PureComponent<Props> {
    public render(): ReactNode {
        const { tooltip, tooltipWidth, children } = this.props;
        if (!tooltip) {
            return children;
        }

        return (
            <span className="tooltip-wrapper">
                <span className="info-label">
                    {children}
                    <span className="tooltip-wrapper-content" style={{ width: tooltipWidth }}>
                        {tooltip}
                    </span>
                </span>
            </span>
        );
    }
}

export { TooltipWrapper };

import React, { Component } from "react";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { Api } from "api/Api";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Intl } from "i18n/Intl";
import { Path } from "utils/Path";
import { Account, ClientShareRequest } from "api/graphql/types";
import { AccountActions } from "actions/AccountActions";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Button } from "components/Button/Button";
import { Dialog } from "components/Dialog/Dialog";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Avatar } from "components/Avatar";
import { ApiError, ApiErrorCode } from "api/ApiError";

import "./ClientShareRequestPage.scss";

interface ReduxProps {
    clientShareRequest: ClientShareRequest | null;
}

interface Params {
    requestId?: string;
}

type Props = RouteComponentProps<Params> & DispatchProp & ReduxProps;

interface State {
    isLoading: boolean;
}

class ClientShareRequestPageComponent extends Component<Props, State> {
    public state: State = {
        isLoading: false,
    };

    public componentWillMount(): void {
        if (!this.props.clientShareRequest) {
            Alert.error({ title: Intl.formatMessage({ id: "page.clientShareRequest.requestNotFoundError" }) });
        }
    }

    private onButtonClick = (accepted: boolean) => () => {
        const { clientShareRequest } = this.props;
        if (!clientShareRequest) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            let isRequestSucceed = true;
            try {
                if (accepted) {
                    await Api.acceptClientShareRequest(clientShareRequest.id);
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientShareRequest.acceptSucceed" }) });
                } else {
                    await Api.rejectClientShareRequest(clientShareRequest.id);
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientShareRequest.rejectSucceed" }) });
                }
            } catch (error) {
                if (error instanceof ApiError && error.code === ApiErrorCode.NOT_FOUND) {
                    Alert.error({ title: Intl.formatMessage({ id: "page.clientShareRequest.requestInvalid" }) });
                } else {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }
                isRequestSucceed = false;
            }

            try {
                const clientExtId = clientShareRequest.client.extId;
                const account: Account = await Api.me();
                this.props.dispatch(AccountActions.updateAccount(account));
                if (accepted && isRequestSucceed) {
                    this.props.history.push(Path.clientProfile(clientExtId));
                } else {
                    this.props.history.push(Path.dashboard);
                }
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.props.history.push(Path.dashboard);
            }
        });
    };

    private onCancelClick = () => {
        this.props.history.push(Path.dashboard);
    };

    public render(): React.ReactElement<any> {
        const { clientShareRequest } = this.props;
        if (!clientShareRequest) {
            return <Redirect to={Path.dashboard} />;
        }

        const { client, createdBy } = clientShareRequest;
        const avatarUrl: string = client.avatar ? client.avatar.url : null;
        return (
            <div className="client-share-request-page">
                <Loading type={LoadingType.layer} />
                <Dialog title={Intl.formatMessage({ id: "page.clientShareRequest.title" })} visible={!this.state.isLoading} onCloseClick={this.onCancelClick}>
                    <div className="content">
                        <Avatar className="avatar" name={client.name} url={avatarUrl} />
                        {IntlHelpers.asHtml({ id: "page.clientShareRequest.content" }, { clientName: client.name })}
                        {Intl.formatMessage(
                            { id: "page.clientShareRequest.info" },
                            { name: createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" }), email: createdBy?.email || Intl.formatMessage({ id: "common.deletedUser" }) },
                        )}
                    </div>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.reject" })} onClick={this.onButtonClick(false)} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: "common.accept" })} onClick={this.onButtonClick(true)} />
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<Params>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<Params>): ReduxProps => {
    return { clientShareRequest: AccountSelectors.getClientShareRequestById(state, props.match.params.requestId) };
};

export const ClientShareRequestPage = withRouter(connect(mapStateToProps)(ClientShareRequestPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientShareRequestPage;

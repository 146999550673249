import { Intl } from "i18n/Intl";
import { SelectOption } from "components/Inputs/Select/Select";
import { EverydaySituationType } from "api/graphql/types";

export type EverydaySituationTypeOption = SelectOption<EverydaySituationType>;

class EverydaySituationTypeOptions {
    public static get(): EverydaySituationTypeOption[] {
        return [EverydaySituationType.text, EverydaySituationType.audio, EverydaySituationType.image].map((everydaySituationType: EverydaySituationType) => {
            return { id: everydaySituationType, label: Intl.formatMessage({ id: `enum.everydaySituationType.${everydaySituationType}` }), value: everydaySituationType };
        });
    }
}

export { EverydaySituationTypeOptions };

import React, { InputHTMLAttributes } from "react";
import "../Inputs.scss";

type Props = { hasError?: boolean | null; innerRef?: (ref: HTMLInputElement | null) => void } & InputHTMLAttributes<HTMLInputElement>;

class Input extends React.PureComponent<Props> {
    public render(): React.ReactElement<any> {
        const { innerRef, className, hasError, ...otherProps } = this.props;
        return <input ref={innerRef} className={`${className ? `${className} ` : ""}${hasError ? "has-error" : ""}`} {...otherProps} />;
    }
}

export { Input };

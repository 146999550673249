import React, { PureComponent } from "react";
import { Intl } from "i18n/Intl";
import { AlertActions } from "actions/AlertActions";
import { store } from "store";
import { ImageSrc } from "utils/ImageSrc";

import { AlertType } from "./AlertTypes";

import "./Alert.scss";

interface Props {
    type: AlertType;
    message: string;
    visible: boolean;
    onClose: () => void;
}

class Alert extends PureComponent<Props> {
    public static success(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(AlertActions.show({ message: options.title, timeout: options.timeout || undefined, callback: options.callback, type: AlertType.success }));
    }

    public static info(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(AlertActions.show({ message: options.title, timeout: options.timeout || 5000, callback: options.callback, type: AlertType.info }));
    }

    public static error(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(AlertActions.show({ message: options.title, timeout: options.timeout || undefined, callback: options.callback, type: AlertType.error }));
    }

    public renderIcon(): React.ReactElement<any> {
        switch (this.props.type) {
            case AlertType.success:
                return <img src={ImageSrc.alert.success} alt={Intl.formatMessage({ id: "component.alert.alt.success" })} />;
            case AlertType.info:
                return <img src={ImageSrc.alert.info} alt={Intl.formatMessage({ id: "component.alert.alt.info" })} />;
            case AlertType.error:
            default:
                return <img src={ImageSrc.alert.error} alt={Intl.formatMessage({ id: "component.alert.alt.error" })} />;
        }
    }
    private getTypeClassName = (): string => {
        switch (this.props.type) {
            case AlertType.success:
                return " success";
            case AlertType.error:
                return " has-error";
            case AlertType.info:
            default:
                return "";
        }
    };

    private getClassName = (): string => {
        const showClassName: string = this.props.visible ? " show" : "";
        const typeClassName: string = this.getTypeClassName();
        return `alert-layer${showClassName}${typeClassName}`;
    };

    public render(): React.ReactElement<any> {
        const className: string = this.getClassName();
        return (
            <div className={className}>
                <div className="alert-touch-layer" onClick={this.props.onClose}></div>
                <div className="alert-window alert">
                    {this.renderIcon()}
                    <p>{this.props.message}</p>
                </div>
            </div>
        );
    }
}

export { Alert, AlertType };

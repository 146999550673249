import React, { PureComponent } from "react";
import { TooltipWrapper } from "./TooltipWrapper/TooltipWrapper";

interface Props {
    isVisible: boolean;
    tooltipText: string;
}

class DisabledLock extends PureComponent<Props> {
    public render(): React.ReactElement {
        return (
            <>
                {this.props.isVisible && (
                    <TooltipWrapper tooltip={this.props.tooltipText}>
                        <span className="badge badge-danger mr-20">
                            <i className="fa fa-lock" />
                        </span>
                    </TooltipWrapper>
                )}
            </>
        );
    }
}

export { DisabledLock };

import React, { PureComponent } from "react";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { Link } from "react-router-dom";

import { ApplicationState } from "reducers/index";
import { Path } from "utils/Path";
import { Dropdown, DropdownItem } from "components/Dropdown/Dropdown";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Account, AccountType } from "api/graphql/types";
import { TestId } from "utils/TestId";
import { AuthSelectors } from "selectors/AuthSelectors";
import { ImageSrc } from "utils/ImageSrc";
import { Env } from "utils/Env";

interface ReduxProps {
    account: Account | null;
    isLoggedIn: boolean;
}

interface ComponentProps {
    onLogoutClick: () => void;
}

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    isLogoutConfirmDialogVisible: boolean;
}

class AppBarComponent extends PureComponent<Props, State> {
    public state: State = {
        isLogoutConfirmDialogVisible: false,
    };

    private getDropdownItems = (): DropdownItem[] => {
        const { account } = this.props;

        if (!account) {
            return [];
        }

        const profile: DropdownItem = { label: Intl.formatMessage({ id: "component.appBar.dropDownMenu.profile" }), path: Path.profile };
        const addClient: DropdownItem = { label: Intl.formatMessage({ id: "component.appBar.dropDownMenu.addClient" }), path: Path.addClient };
        const myEventLog: DropdownItem = { label: Intl.formatMessage({ id: "component.appBar.dropDownMenu.myEventLog" }), path: Path.myEventLog };
        const logout: DropdownItem = { label: Intl.formatMessage({ id: "component.appBar.dropDownMenu.logout" }), onClick: this.props.onLogoutClick };

        switch (account.accountType) {
            case AccountType.supporter:
                return [profile, addClient, myEventLog, logout];
            case AccountType.superadmin:
            case AccountType.admin:
            case AccountType.supervisor:
            default:
                return [profile, myEventLog, logout];
        }
    };

    public render(): React.ReactElement<any> {
        const isSupervisor: boolean = !!this.props.account && this.props.account.accountType === AccountType.supervisor;
        return (
            <nav className="navbar navbar-static-top" role="navigation" style={{ zIndex: 0 }}>
                <div className="logo">
                    <Link to={this.props.isLoggedIn ? Path.dashboard : Path.login}>
                        <img src={isSupervisor ? ImageSrc.logo.blue : ImageSrc.logo.default} width="50" height="54" alt={Intl.formatMessage({ id: "component.appBar.alt" })} />
                        <span>{IntlHelpers.asHtml({ id: "component.appBar.title" })}</span>
                    </Link>
                </div>
                <div className="version">
                    <span>v{Env.packageJson.version}</span>
                </div>
                {this.props.isLoggedIn && this.props.account && (
                    <>
                        <div className="profile-menu">
                            <Dropdown
                                id={TestId.appBar.profileMenu}
                                label={this.props.account.name!}
                                info={isSupervisor ? Intl.formatMessage({ id: "component.appBar.superVisorInfo" }) : undefined}
                                imageUrl={this.props.account.avatar ? this.props.account.avatar.url : undefined}
                                items={this.getDropdownItems()}
                            />
                        </div>
                        <ul className="nav navbar-top-links navbar-right">
                            <li>
                                <button onClick={this.props.onLogoutClick} className="btn btn-link">
                                    {Intl.formatMessage({ id: "common.logout" })}
                                    <span className="fa fa-sign-out-alt right"></span>
                                </button>
                            </li>
                        </ul>
                    </>
                )}
            </nav>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account,
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

export const AppBar = connect(mapStateToProps)(AppBarComponent);

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface ConfirmAvatarDeleteDialogProps {
    onOkClick: () => void;
}

type Props = ConfirmAvatarDeleteDialogProps & DialogVisibilityProps;

class ConfirmAvatarDeleteDialogComponent extends Component<Props> {
    private onOkClick = (): void => {
        this.props.onOkClick();
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "sharedComponent.confirmAvatarDeleteDialog.title" })} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "sharedComponent.confirmAvatarDeleteDialog.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button label={Intl.formatMessage({ id: "common.yes" })} onClick={this.onOkClick} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const ConfirmAvatarDeleteDialog: React.ComponentClass<Props> = ConfirmAvatarDeleteDialogComponent;

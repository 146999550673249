import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { DialogVisibilityProps } from "./DialogsContainer";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { ContentLibraryDirectories } from "models/ContentLibraryDirectories";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { DirectoryUtils } from "utils/DirectoryUtils";
import { Loading } from "components/Loading/Loading";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Alert } from "../Alert/Alert";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface MoveAssetsDialogProps {
    onMoved: () => void;
    assets: string[];
    isVisible: boolean;
    currentDirectory: string | null;
    isPublic: boolean;
}

type Props = MoveAssetsDialogProps & DialogVisibilityProps & RouteComponentProps;

type DirectoryOptionType = SelectOption<string | null>;

interface State {
    assets: string[];
    directories: DirectoryOptionType[];
    selectedDirectory: DirectoryOptionType;
    isLoading: boolean;
}

class MoveAssetsDialogComponent extends PureComponent<Props, State> {
    private static getInitialState(): State {
        return {
            assets: [],
            selectedDirectory: {
                id: "",
                value: null,
                label: Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" }),
            },
            directories: [],
            isLoading: false,
        };
    }

    public readonly state: State = MoveAssetsDialogComponent.getInitialState();

    public componentDidMount(): void {
        this.fetchDirectories();
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.isVisible && !nextProps.isVisible) {
            this.setState({ ...MoveAssetsDialogComponent.getInitialState(), assets: this.props.assets });
        }
    }

    private readonly fetchDirectories = async () => {
        const directories = this.props.isPublic
            ? await ContentLibraryDirectories.getPublicAssetDirectories(this.props.history.location.pathname, true)
            : await ContentLibraryDirectories.getPersonalAssetDirectories(this.props.history.location.pathname, true);

        const directoryOptions = [
            {
                id: "",
                value: null,
                label: Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" }),
            },
            ...DirectoryUtils.getDirectoriesSelectValue(directories),
        ];

        this.setState({
            directories: directoryOptions,
            selectedDirectory: Select.getSelectOption(directoryOptions, this.props.currentDirectory),
        });
    };

    private readonly onCloseClick = () => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    private readonly onDirectoryChange = (selectedDirectory: DirectoryOptionType): void => {
        this.setState({ selectedDirectory });
    };

    private readonly moveAssets = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { assets, currentDirectory } = this.props;
        const { selectedDirectory } = this.state;
        if (selectedDirectory.id === currentDirectory) {
            Alert.error({ title: Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.sameDir" }) });
            return;
        }
        try {
            if (this.props.isPublic) {
                await Api.moveAssetsToPublicAssetDirectory({ assetIds: assets, directoryId: selectedDirectory.id || null });
            } else {
                await Api.moveAssetsToPersonalAssetDirectory({ assetIds: assets, directoryId: selectedDirectory.id || null });
            }
            Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.succeed" }) });
            this.props.onHide();
            this.props.onMoved();
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> {
        const { assets } = this.props;
        if (this.state.isLoading) {
            return <Loading />;
        }

        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.title" })} visible={this.props.isVisible} onCloseClick={this.onCloseClick}>
                <form onSubmit={this.moveAssets}>
                    <h1>
                        {assets.length}
                        {Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.description" })}
                    </h1>
                    <hr />
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.directory.label" })}>
                        <Select
                            options={this.state.directories}
                            onChange={item => this.onDirectoryChange(item)}
                            value={this.state.selectedDirectory}
                            emptyLabel={Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.directory.placeholder" })}
                            disabled={false}
                        />
                    </InputWrapper>

                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCloseClick} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.submitLabel" })} type="submit" />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const MoveAssetsDialog = withRouter(MoveAssetsDialogComponent);

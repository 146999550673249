import React, { InputHTMLAttributes } from "react";
import { isNil } from "lodash";
import Cleave from "cleave.js/react";

import "../Inputs.scss";

type Props = Omit<Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">, "value"> & {
    hasError?: boolean | null;
    innerRef?: (ref: HTMLInputElement | null) => void;
    value?: number | null;
    onChange: (value: number | null) => void;
};

class NumberInput extends React.Component<Props> {
    private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = Number.parseInt(event.currentTarget.value, 10);
        this.props.onChange(isNaN(value) ? null : Math.abs(value));
    };

    public render(): React.ReactElement<any> {
        const { innerRef, className, hasError, type, ...otherProps } = this.props;
        return (
            <Cleave
                htmlRef={innerRef}
                className={`${className ? `${className} ` : ""}${hasError ? "has-error" : ""}`}
                {...otherProps}
                options={{
                    numericOnly: true,
                    numeral: true,
                    numeralThousandsGroupStyle: "none",
                    numeralDecimalScale: 0,
                    noImmediatePrefix: true,
                    numeralPositiveOnly: true,
                }}
                onChange={this.onChange}
                value={isNil(this.props.value) ? "" : this.props.value}
            />
        );
    }
}

export { NumberInput };

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { SupportedClient } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface VerifyChangeClientManagerDialogProps {
    client: SupportedClient;
    operation: "accept" | "reject";
    onChangeClientManagerFinished: () => Promise<void>;
    onClose?: () => void;
}

type Props = VerifyChangeClientManagerDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class VerifyChangeClientManagerDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.onHide();
    };

    private onMainButtonClick = async (): Promise<void> => {
        try {
            if (this.props.operation === "accept") {
                await Api.acceptClientChangeManagerRequest(this.props.client.pendingClientManagerChangeRequest!.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.accepted" }) });
            } else {
                await Api.rejectClientChangeManagerRequest(this.props.client.pendingClientManagerChangeRequest!.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.rejected" }) });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            return;
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.onHide();
        this.props.onChangeClientManagerFinished();
    };

    private onSecondaryButtonClick = async (): Promise<void> => {
        try {
            if (this.props.operation === "accept") {
                await Api.rejectClientChangeManagerRequest(this.props.client.pendingClientManagerChangeRequest!.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.rejected" }) });
            } else {
                await Api.acceptClientChangeManagerRequest(this.props.client.pendingClientManagerChangeRequest!.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.accepted" }) });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            return;
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.onHide();
        this.props.onChangeClientManagerFinished();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "sharedComponent.verifyChangeClientManagerDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.onClose : undefined}
            >
                <form>
                    <p>
                        {Intl.formatMessage(
                            { id: "sharedComponent.verifyChangeClientManagerDialog.description" },
                            {
                                clientName: this.props.client.name,
                                supporterName: this.props.client.pendingClientManagerChangeRequest!.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" }),
                            },
                        )}
                    </p>

                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-4">
                            <Button hollow label={Intl.formatMessage({ id: "sharedComponent.verifyChangeClientManagerDialog.later" })} onClick={this.onClose} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-8 text-right">
                            <Button
                                hollow
                                label={Intl.formatMessage({ id: `sharedComponent.verifyChangeClientManagerDialog.${this.props.operation}.secondaryButton` })}
                                onClick={this.onSecondaryButtonClick}
                                disabled={this.state.isLoading}
                            />
                            <span className="mr-20" />
                            <Button
                                label={Intl.formatMessage({ id: `sharedComponent.verifyChangeClientManagerDialog.${this.props.operation}.mainButton` })}
                                onClick={this.onMainButtonClick}
                                disabled={this.state.isLoading}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const VerifyChangeClientManagerDialog: React.ComponentClass<Props> = VerifyChangeClientManagerDialogComponent;

import React, { PureComponent } from "react";
import { IconInfo } from "./ContentElementUtils";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";

interface Props {
    icon: string;
    iconElement?: JSX.Element;
    title: string;
    iconInfo?: IconInfo;
}

class ContentElementIcon extends PureComponent<Props> {
    public render(): React.ReactElement<any> | null {
        if (!this.props.iconInfo) {
            return null;
        }

        return (
            <TooltipWrapper tooltip={this.props.iconInfo.tooltip}>
                {this.props.iconElement ? (
                    <span className={`fa ${this.props.iconInfo.active ? " active" : ""}`} title={this.props.title}>
                        {this.props.iconElement}
                    </span>
                ) : (
                    <span className={`fa ${this.props.icon}${this.props.iconInfo.active ? " active" : ""}`} title={this.props.title}>
                        {this.props.title}
                    </span>
                )}
            </TooltipWrapper>
        );
    }
}

export { ContentElementIcon };

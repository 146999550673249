import React, { PureComponent } from "react";

interface Props {
    isVisible: boolean;
    children: React.ReactNode;
}

class BottomBar extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <>
                {this.props.isVisible && (
                    <div className="grid-x">
                        <div className="cell medium-12" style={{ height: "60px" }}>
                            &nbsp;
                        </div>
                    </div>
                )}

                <div className={`grid-x grid-padding-x fixed-row ${this.props.isVisible ? "show" : ""}`}>{this.props.children}</div>
            </>
        );
    }
}

export { BottomBar };

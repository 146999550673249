import { SelectOption } from "components/Inputs/Select/Select";
import { Intl } from "i18n/Intl";
import { AppStatItemType } from "api/graphql/types";
import { ObjectUtils } from "utils/ObjectUtils";

type AppStatItemTypeOption = SelectOption<AppStatItemType | null>;

class AppStatItemTypeSelectOptions {
    public static get(): AppStatItemTypeOption[] {
        return [
            { id: "null", label: Intl.formatMessage({ id: "enum.appStatItemType.null" }), value: null },
            ...ObjectUtils.enumAsArray<AppStatItemType>(AppStatItemType).map(
                (type: AppStatItemType): AppStatItemTypeOption => {
                    return { id: type, label: type, value: type };
                },
            ),
        ];
    }
}

export { AppStatItemTypeSelectOptions };
export type { AppStatItemTypeOption };

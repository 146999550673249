import React, { Component } from "react";
import { ContentLibraryTable } from "pages/_shared/ContentLibraryTable/ContentLibraryTable";
import { SearchListType, SupportedClient } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { Path } from "utils/Path";
import { LinkGroupItem, LinkGroup } from "components/LinkGroup/LinkGroup";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { ContentLibraryLinks } from "models/ContentLibraryLinks";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { Intl } from "i18n/Intl";
import { Formatter } from "utils/Formatter";

interface ComponentProps {
    client: SupportedClient;
}

type Props = ComponentProps & RouteComponentProps;

class ClientDetailContentLibraryTabComponent extends Component<Props> {
    private readonly urlQueryParser = new ContentLibraryUrlQueryParser();

    private renderLinks = (): React.ReactElement<any> => {
        const clientLinkGroupItems: LinkGroupItem[] = ContentLibraryLinks.getForClientDetail(this.props.client.extId);
        const currentPath: string = this.props.location.pathname + this.props.location.search;
        return (
            <LinkGroup
                className="mt-2"
                isLinkActive={(item: LinkGroupItem): boolean => currentPath.startsWith(item.toPage)}
                links={clientLinkGroupItems}
                title={Intl.formatMessage({ id: "page.clientDetail.tabs.contentLibrary.linkGroupTitle" }, { extId: Formatter.formatExtId(this.props.client.extId) })}
            />
        );
    };

    public render(): React.ReactElement<any> {
        const urlQuery = this.urlQueryParser.parse(this.props.location.search);
        if (!urlQuery.searchListType && !urlQuery.extraContentLibraryType) {
            return <Redirect to={`${Path.clientDetailContentLibrary(this.props.client.extId)}?${this.urlQueryParser.getUrlQuery({ searchListType: SearchListType.agendaItem })}`} />;
        }

        return <ContentLibraryTable pageContent={PageContent.clientDetail} client={this.props.client} renderLinks={this.renderLinks} />;
    }
}

export const ClientDetailContentLibraryTab = withRouter(ClientDetailContentLibraryTabComponent);

// tslint:disable-next-line: no-default-export
export default ClientDetailContentLibraryTab;

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { AgendaContent } from "api/graphql/types";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface ConfirmAgendaDialogProps {
    agenda: AgendaContent | null;
    onUpdateAllClick: () => void;
    onUpdateCurrentClick: () => void;
}

type Props = ConfirmAgendaDialogProps & DialogVisibilityProps;

class ConfirmAgendaDialog extends PureComponent<Props> {
    private onUpdateCurrentClick = (): void => {
        this.props.onUpdateCurrentClick();
        this.props.onHide();
    };

    private onUpdateAllClick = (): void => {
        this.props.onUpdateAllClick();
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        let description = "";
        if (this.props.agenda && this.props.agenda.day) {
            description = Intl.formatMessage({ id: "page.agendaItem.confirmAgendaDialog.description" }, { date: DateUtils.format(this.props.agenda.day, DateFormat.yyyymmdd) });
        }

        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "page.agendaItem.confirmAgendaDialog.title" })} onCloseClick={this.props.onHide}>
                <p>{description}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "page.agendaItem.confirmAgendaDialog.updateCurrent" })} onClick={this.onUpdateCurrentClick} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button label={Intl.formatMessage({ id: "page.agendaItem.confirmAgendaDialog.updateAll" })} onClick={this.onUpdateAllClick} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { ConfirmAgendaDialog };

import React from "react";
import { VideoPlayer } from "components/VideoPlayer/VideoPlayer";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DownloadExtension, KnowledgeBaseDownloadLink } from "components/KnowledgeBaseDownloadLink/KnowledgeBaseDownloadLink";
import { EducationVideo } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ApplicationState } from "reducers";
import { connect, DispatchProp } from "react-redux";

import "./VideoList.scss";

type ReduxProps = {
    authToken: string;
};

type ComponentProps = {
    data: EducationVideo[];
    modulePosition: number | null;
    subModulePosition: number | null;
};

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    indexActive: number;
}

class VideoListComponent extends React.PureComponent<Props, State> {
    private videoWrapperRef: HTMLDivElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            indexActive: 0,
        };
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.data !== nextProps.data) {
            this.setState({ indexActive: 0 });
        }
    }

    private setActiveIndex = (newIndexActive: number): void => {
        const { indexActive } = this.state;

        if (indexActive !== newIndexActive) {
            this.setState({ indexActive: newIndexActive }, () => {
                if (this.videoWrapperRef) {
                    this.videoWrapperRef.scrollIntoView();
                }
            });
        }
    };

    private videoTitle = (video: EducationVideo): React.ReactElement => {
        return (
            <div className="video-list-title">
                {Intl.formatMessage(
                    { id: "component.videoList.title" },
                    { position: `${this.props.modulePosition || ""}.${this.props.subModulePosition || ""}.${video.position || ""}`, title: video.title },
                )}
            </div>
        );
    };

    public render(): React.ReactNode {
        const { data } = this.props;
        const { indexActive } = this.state;

        if (data.length === 0) {
            return null;
        }

        const activeData: EducationVideo = data[indexActive];
        return (
            <div className="grid-x padded video-list">
                <div className="cell auto medium-7">
                    <div
                        ref={(ref: HTMLDivElement | null): void => {
                            this.videoWrapperRef = ref;
                        }}
                    >
                        <VideoPlayer config={{ file: { attributes: { poster: activeData.thumbnail } } }} url={activeData.url} />

                        <div className="title vid">{this.videoTitle(activeData)}</div>

                        <div>
                            <KnowledgeBaseDownloadLink id={activeData.id} url={activeData.url} title={activeData.title || ""} extension={DownloadExtension.mp4} />
                        </div>
                    </div>
                </div>

                <div className="cell medium-5">
                    {data.map((item: EducationVideo, i: number) => {
                        if (indexActive === i) {
                            return null;
                        }
                        const poster = this.props.authToken ? `${item.thumbnail}?authorization=${this.props.authToken}` : item.thumbnail;
                        return (
                            <div key={`dummy-${i}`} className="video-nav">
                                <div className="grid-x">
                                    <div className="cell shrink">
                                        <div className="video-layer" onClick={(): void => this.setActiveIndex(i)}>
                                            <video className="inactive-video-thumbnail" poster={poster} onClick={(): void => this.setActiveIndex(i)}>
                                                {IntlHelpers.asHtml({ id: "component.videoList.error" }, { url: data[indexActive].url })}
                                            </video>
                                        </div>
                                    </div>

                                    <div className="cell auto">
                                        <div onClick={(): void => this.setActiveIndex(i)} className="title">
                                            {this.videoTitle(item)}
                                        </div>

                                        <div className="inactive-video-download-link">
                                            <KnowledgeBaseDownloadLink id={item.id} url={item.url} title={item.title || ""} extension={DownloadExtension.mp4} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { authToken: state.auth.authToken! };
};

export const VideoList = connect(mapStateToProps)(VideoListComponent);

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { TestId } from "utils/TestId";
import { SelectionBoard } from "api/graphql/types";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteSelectionBoardDialogProps {
    selectionBoard: SelectionBoard | null;
    onDeleteClick: () => void;
}

type Props = DeleteSelectionBoardDialogProps & DialogVisibilityProps;

class DeleteSelectionBoardDialog extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.clientSelectionBoards.deleteDialog.title" })} visible={this.props.isVisible && !!this.props.selectionBoard} onCloseClick={this.props.onHide}>
                {this.props.selectionBoard ? (
                    <>
                        <p>{Intl.formatMessage({ id: "page.clientSelectionBoards.deleteDialog.description" }, { name: this.props.selectionBoard.title })}</p>
                        <hr />
                        <div className="row buttons">
                            <div className="cell medium-6">
                                <Button id={TestId.navigation.logoutDialog.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                            </div>
                            <div className="cell medium-6 text-right">
                                <Button
                                    className="btn-danger btn--text-large fw-700"
                                    id={TestId.navigation.logoutDialog.acceptButton}
                                    label={Intl.formatMessage({ id: "common.delete" })}
                                    onClick={this.props.onDeleteClick}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </Dialog>
        );
    }
}

export { DeleteSelectionBoardDialog };

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { LibraryContentShareRequestListOptionsInput, LibraryContentShareRequestSortField, LibraryContentShareRequestListItem, SortOrder, AssetContent, AssetType } from "api/graphql/types";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { Table, Column } from "components/Table/Table";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { OptionsMenu } from "components/OptionsMenu/OptionsMenu";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ImageSrc } from "utils/ImageSrc";
import { Image } from "components/Image";
import { Path } from "utils/Path";
import { ApiTypes } from "api/ApiTypes";
import "./DashboardContentShareRequestTable.scss";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { OverlayIcon } from "components/OverlayIcon";

enum ContentShareRequestTableColumn {
    libraryContentTitle = "libraryContentTitle",
    libraryContentType = "libraryContentType",
    libraryContentUpdatedAt = "libraryContentUpdatedAt",
    actions = "actions",
}

type Props = RouteComponentProps;

interface State {
    libraryContentShareRequests: LibraryContentShareRequestListItem[];
    count: number;
    isLoading: boolean;
    options: LibraryContentShareRequestListOptionsInput;
}

class DashboardContentShareRequestTableComponent extends Component<Props, State> {
    public readonly state: State = {
        libraryContentShareRequests: [],
        isLoading: true,
        count: 0,
        options: DashboardContentShareRequestTableComponent.getInitialOptions(this.props),
    };

    public componentDidMount(): void {
        this.refreshLibraryContentShareRequests(this.state.options);
    }

    private refreshLibraryContentShareRequests = (listOptions?: LibraryContentShareRequestListOptionsInput): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await Api.getPendingLibraryContentShareRequests({ listOptions });
                    this.setState({ libraryContentShareRequests: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, libraryContentShareRequests: [] });
                }
            },
        );
    };

    private readonly getColumns = (): Array<Column<LibraryContentShareRequestListItem>> => {
        return ObjectUtils.enumAsArray<ContentShareRequestTableColumn>(ContentShareRequestTableColumn).map(
            (columnName: ContentShareRequestTableColumn): Column<LibraryContentShareRequestListItem> => {
                const accessor = (() => {
                    switch (columnName) {
                        case ContentShareRequestTableColumn.libraryContentTitle:
                            return "title";
                        case ContentShareRequestTableColumn.libraryContentType:
                            return "type";
                        case ContentShareRequestTableColumn.libraryContentUpdatedAt:
                            return "updatedAt";
                        default:
                            return "";
                    }
                })();
                return {
                    id: columnName,
                    name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.contentShareRequest.table.columns.${columnName}` }),
                    accessor: accessor as keyof LibraryContentShareRequestListItem,
                    renderCell: (libraryContentShareRequest: LibraryContentShareRequestListItem): React.ReactElement<any> | null => {
                        switch (columnName) {
                            case ContentShareRequestTableColumn.actions:
                                return (
                                    <OptionsMenu
                                        items={[
                                            {
                                                label: Intl.formatMessage({ id: "common.view" }),
                                                icon: "fa-eye",
                                                onClick: () => {
                                                    const path = this.getViewPath(libraryContentShareRequest);
                                                    if (path) {
                                                        this.props.history.push(path);
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                );
                            case ContentShareRequestTableColumn.libraryContentUpdatedAt:
                                return <>{DateUtils.format(new Date(libraryContentShareRequest.libraryContent.updatedAt), DateFormat.dateTime)}</>;
                            case ContentShareRequestTableColumn.libraryContentTitle: {
                                const asset = ((libraryContentShareRequest.libraryContent as any).image || (libraryContentShareRequest.libraryContent as any).video) as AssetContent | null | undefined;
                                const searchListType = ApiTypes.getSearchListTypeByContent(libraryContentShareRequest.libraryContent);
                                return (
                                    <>
                                        <div className="table-image-name text-left">
                                            <div
                                                className="table-image-container "
                                                onClick={
                                                    asset && asset.url
                                                        ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                              event.stopPropagation();
                                                              store.dispatch(
                                                                  DialogsActions.show({
                                                                      type: DialogType.showAsset,
                                                                      assetUrl: asset.url,
                                                                      assetType: asset.assetType || AssetType.image,
                                                                      originalFileName: asset.originalFileName,
                                                                      thumbnailUrl: asset.thumbnailUrl,
                                                                      dialogTitle: asset.title || Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` }),
                                                                  }),
                                                              );
                                                          }
                                                        : undefined
                                                }
                                            >
                                                <Image src={asset?.thumbnailUrl || asset?.url} fallback={ImageSrc[searchListType!]} />
                                                {asset && asset.url && (
                                                    <>
                                                        <div className="content-overlay" />
                                                        <div className="content-overlay-icon">
                                                            <OverlayIcon assetType={asset.assetType} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {libraryContentShareRequest.libraryContent.title}
                                        </div>
                                    </>
                                );
                            }
                            case ContentShareRequestTableColumn.libraryContentType: {
                                const searchListType = ApiTypes.getSearchListTypeByContent(libraryContentShareRequest.libraryContent);
                                return <>{Intl.formatMessage({ id: `enum.searchListType.${searchListType}` })}</>;
                            }
                            default:
                                return null;
                        }
                    },
                    isNonSortable: !ObjectUtils.isEnumContains<LibraryContentShareRequestSortField>(LibraryContentShareRequestSortField, columnName),
                };
            },
        );
    };

    private updateQueryParams = (): void => {
        const control = this.state.options.control || {};
        const options: ListQueryParameter<LibraryContentShareRequestSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField: this.state.options.sortField,
        };

        const params = new ListUrlQueryParser<LibraryContentShareRequestSortField>(LibraryContentShareRequestSortField, "sharedComponent.contentLibraryTable.contentShareRequest.table").getUrlQuery(
            options,
        );
        this.props.history.push({ search: `?velem-megosztott&${params}` });
        this.refreshLibraryContentShareRequests(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): LibraryContentShareRequestSortField | undefined => {
        switch (columnId) {
            case ContentShareRequestTableColumn.libraryContentUpdatedAt:
                return LibraryContentShareRequestSortField.libraryContentUpdatedAt;
            case ContentShareRequestTableColumn.libraryContentTitle:
                return LibraryContentShareRequestSortField.libraryContentTitle;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: LibraryContentShareRequestSortField | null): ContentShareRequestTableColumn | undefined => {
        switch (columnId) {
            case LibraryContentShareRequestSortField.libraryContentUpdatedAt:
                return ContentShareRequestTableColumn.libraryContentUpdatedAt;
            case LibraryContentShareRequestSortField.libraryContentTitle:
                return ContentShareRequestTableColumn.libraryContentTitle;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<LibraryContentShareRequestListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = (options.control || {}).limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage((this.state.options.control || {}).offset, (this.state.options.control || {}).limit, this.state.count);
    }

    private static getInitialOptions(props: Props): LibraryContentShareRequestListOptionsInput {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<LibraryContentShareRequestSortField>(LibraryContentShareRequestSortField, "page.contentShareRequestList.table").parse(
            props.location.search,
        );

        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;
        return {
            sortField,
            control: {
                sortOrder,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    private getViewPath = (item: LibraryContentShareRequestListItem): string | null => {
        switch (item.libraryContent.__typename) {
            case "Agenda":
                return Path.viewAgendaShareRequest(item.libraryContent.id);
            case "AgendaItem":
                return Path.viewAgendaItemShareRequest(item.libraryContent.id);
            case "Flowchart":
                return Path.viewFlowchartShareRequest(item.libraryContent.id);
            case "FlowchartItem":
                return Path.viewFlowchartItemShareRequest(item.libraryContent.id);
            default:
                return null;
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="dashboard-content-share-request-table">
                {!this.state.isLoading && (
                    <div className="row table-header-info">
                        <div className="table-title">
                            <span className="table-title-text">{Intl.formatMessage({ id: "page.dashboard.contentShareRequestTable.tableTitle" })}</span>
                            <span className="table-title-count">{Intl.formatMessage({ id: "page.dashboard.contentShareRequestTable.dataCount" }, { count: this.state.count })}</span>
                        </div>
                        <span
                            className="table-link-to-all"
                            onClick={() => {
                                this.props.history.push(Path.contentShareRequestList);
                            }}
                        >
                            {Intl.formatMessage({ id: "page.dashboard.contentShareRequestTable.linkToAll" })}
                            <span className="fa fa-chevron-right" />
                        </span>
                    </div>
                )}
                <div className="row">
                    <Table
                        keyExtractor={(item: LibraryContentShareRequestListItem, column?: Column<LibraryContentShareRequestListItem>): string => {
                            return `${item.requestId}_${column ? column.id : ""}`;
                        }}
                        columns={this.getColumns()}
                        sortBy={{
                            columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                            order: (this.state.options.control || {}).sortOrder || undefined,
                        }}
                        data={this.state.libraryContentShareRequests}
                        count={this.state.count}
                        limit={(this.state.options.control || {}).limit}
                        isSortable={true}
                        onSortOrderChange={this.onSortOrderChange}
                        onPageChange={this.onPageChange}
                        isPaginationEnabled={true}
                        currentPage={this.getCurrentPage()}
                        isLoading={this.state.isLoading}
                        onRowClick={(libraryContentShareRequest: LibraryContentShareRequestListItem): void => {
                            const path = this.getViewPath(libraryContentShareRequest);
                            if (path) {
                                this.props.history.push(path);
                            }
                        }}
                        renderEmpty={(): string => Intl.formatMessage({ id: "page.dashboard.contentShareRequestTable.noData" })}
                    />
                </div>
            </div>
        );
    }
}

export const DashboardContentShareRequestTable = withRouter(DashboardContentShareRequestTableComponent);

import React, { Component } from "react";
import { PageType } from "utils/TypeUtils";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { TestId } from "utils/TestId";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Prompt } from "components/Prompt";
import { CreateFaqItemInput, UpdateFaqItemInput } from "api/graphql/types";
import { isEqual } from "lodash";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Path } from "utils/Path";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotificationType } from "components/NotificationBar/Notification";

type FaqItemInput = CreateFaqItemInput | UpdateFaqItemInput;

interface RouteParams {
    clientExtId?: string;
}

interface ComponentProps {
    faq: FaqItemInput;
    pageType: PageType;
    onSubmit: (faq: FaqItemInput) => Promise<FaqItemInput>;
}

type Props = ComponentProps & RouteComponentProps<RouteParams>;

interface Errors {
    question: string | null;
    answer: string | null;
}

interface State {
    faq: FaqItemInput;
    errors: Errors;
    isValidationEnabled: boolean;
}

class FaqFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private questionRef: HTMLInputElement | null = null;
    private answerRef: HTMLTextAreaElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            faq: {
                question: props.faq.question,
                answer: props.faq.answer,
            },
            isValidationEnabled: false,
            errors: {
                question: null,
                answer: null,
            },
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = FaqFormComponent.getInitialStateFromProps(props);
    }

    public isChanged = (): boolean => {
        return !isEqual(this.state.faq, this.props.faq);
    };

    private onCancelClick = (): void => {
        this.setState({ faq: this.props.faq, isValidationEnabled: false, errors: { answer: null, question: null } });
    };

    private onSubmit = async (): Promise<void> => {
        const { faq, errors } = this.state;
        const questionError: string | null = IntlHelpers.getValidationError(Validator.validateFaqQuestion(faq.question || ""));
        const answerError: string | null = IntlHelpers.getValidationError(Validator.validateFaqAnswer(faq.answer || ""));
        if (errors.question !== null || questionError || errors.answer !== null || answerError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, question: questionError, answer: answerError } });
            if (errors.question && this.questionRef) {
                this.questionRef.focus();
            } else if (errors.answer && this.answerRef) {
                this.answerRef.focus();
            }
            return;
        }

        try {
            const faq: FaqItemInput = await this.props.onSubmit(this.state.faq);
            this.setState({ faq, isValidationEnabled: false }, () => {
                if (this.props.pageType === PageType.create) {
                    Alert.success({ title: Intl.formatMessage({ id: `page.faq.page.${this.props.pageType}.saveSucceed` }) });
                    this.props.history.push(Path.faqList);
                } else if (this.props.pageType === PageType.edit) {
                    if (this.pageRef) {
                        this.pageRef.showNotification({ message: Intl.formatMessage({ id: `page.faq.page.${this.props.pageType}.saveSucceed` }), type: NotificationType.success });
                    }
                }
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private renderBottomBar = (): React.ReactElement => {
        return (
            <BottomBar isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button id={TestId.faqPage.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged()} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button id={TestId.faqPage.saveButton} label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged()} onClick={this.onSubmit} />
                </div>
            </BottomBar>
        );
    };

    private onQuestionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const question: string = event.currentTarget.value;
        const questionError: string | null = IntlHelpers.getValidationError(Validator.validateFaqQuestion(question));
        this.setState({ faq: { ...this.state.faq, question }, errors: { ...this.state.errors, question: questionError } });
    };

    private onAnswerChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const answer: string = event.currentTarget.value;
        const answerError: string | null = IntlHelpers.getValidationError(Validator.validateFaqAnswer(answer));
        this.setState({ faq: { ...this.state.faq, answer }, errors: { ...this.state.errors, answer: answerError } });
    };

    public render(): React.ReactElement<any> {
        const { pageType } = this.props;
        const { faq, errors, isValidationEnabled } = this.state;

        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                id={TestId.faqPage.faqPageContainer}
                title={Intl.formatMessage({ id: `page.faq.page.${pageType}.title` })}
            >
                <div className="left-side">
                    <p>{Intl.formatMessage({ id: `page.faq.page.${pageType}.lead` })}</p>
                    <InputWrapper id={TestId.faqPage.questionInput} inputLabel={Intl.formatMessage({ id: "page.faq.page.question.label" })} errorMessage={isValidationEnabled ? errors.question : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.questionRef = ref;
                            }}
                            placeholder={Intl.formatMessage({ id: "page.faq.page.question.placeholder" })}
                            value={faq.question || ""}
                            hasError={isValidationEnabled && !!errors.question}
                            onChange={this.onQuestionChange}
                        />
                    </InputWrapper>
                    <InputWrapper id={TestId.faqPage.answerInput} inputLabel={Intl.formatMessage({ id: "page.faq.page.answer.label" })} errorMessage={isValidationEnabled ? errors.answer : null}>
                        <TextArea
                            innerRef={(ref: HTMLTextAreaElement | null): void => {
                                this.answerRef = ref;
                            }}
                            placeholder={Intl.formatMessage({ id: "page.faq.page.answer.placeholder" })}
                            value={faq.answer || ""}
                            hasError={isValidationEnabled && !!errors.answer}
                            onChange={this.onAnswerChange}
                        />
                    </InputWrapper>
                </div>
                {this.renderBottomBar()}

                <Prompt when={this.isChanged()} />
            </Page>
        );
    }
}

export const FaqForm = withRouter(FaqFormComponent);

import { AllowedUploadContentTypes, AllowedUploadFileSizes, NamedColor, AppStatItemTypeTitle, AppEventLogNameTitle, AppEventLogCategoryNameTitle } from "api/graphql/types";

export enum SettingsActionType {
    updateSettings = "@settings/update",
}

interface UpdateSettings {
    type: SettingsActionType.updateSettings;
    unlockColors: NamedColor[];
    backgroundColors: NamedColor[];
    itemBackgroundColors: NamedColor[];
    allowedUploadContentTypes: AllowedUploadContentTypes;
    allowedUploadFileSizes: AllowedUploadFileSizes;
    appStatItemTypeTitles: AppStatItemTypeTitle[];
    appEventLogNameTitles: AppEventLogNameTitle[];
    appEventLogCategoryNameTitles: AppEventLogCategoryNameTitle[];
    clientEverydaySituationDirectoryDefaultImageId: string;
}

export class SettingsActions {
    public static updateSettings({
        unlockColors,
        backgroundColors,
        itemBackgroundColors,
        allowedUploadContentTypes,
        allowedUploadFileSizes,
        appStatItemTypeTitles,
        appEventLogNameTitles,
        appEventLogCategoryNameTitles,
        clientEverydaySituationDirectoryDefaultImageId,
    }: Omit<UpdateSettings, "type">): UpdateSettings {
        return {
            type: SettingsActionType.updateSettings,
            unlockColors,
            backgroundColors,
            itemBackgroundColors,
            allowedUploadContentTypes,
            allowedUploadFileSizes,
            appStatItemTypeTitles,
            appEventLogNameTitles,
            appEventLogCategoryNameTitles,
            clientEverydaySituationDirectoryDefaultImageId,
        };
    }
}

export type SettingsAction = UpdateSettings;

import React, { Component } from "react";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { SupportedClient, ClientConfigInput, MoodMeter, TimerType, ClientFunctionsConfig, MoodMeterItem, MoodMeterItemInput, DashboardItem, SelectionBoardListType } from "api/graphql/types";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { ClientSettingsSelectOptions } from "models/ClientSettingsSelectOptions";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { cloneDeep, isEqual, isNil } from "lodash";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { MoodMeterForm } from "./MoodMeterForm";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

interface OwnProps {
    onShowNotification: () => void;
}

type Props = OwnProps & RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

interface CurrentConfig {
    moodMeter: MoodMeter | null;
    timer: {
        type: SelectOption<TimerType>;
        requestConfirmation: boolean;
    };
    selectionBoardListType: SelectOption<SelectionBoardListType>;
    award: {
        requestConfirmation: boolean;
    };
}

interface FormErrors {
    moodMeterItems?: string | null;
    question?: string | null;
}

interface State {
    clientFunctionsConfig: ClientFunctionsConfig | null;
    notificationOnProfilePage: string | null;
    validationEnabled: boolean;
    currentConfig: CurrentConfig;
    formErrors: FormErrors;
    isLoading: boolean;
}

class FunctionsTabComponent extends Component<Props, State> {
    private moodMeterFormRef: MoodMeterForm | null = null;

    public readonly state: State = {
        clientFunctionsConfig: null,
        notificationOnProfilePage: null,
        validationEnabled: false,
        currentConfig: {
            moodMeter: {
                enabled: false,
                title: "",
                multiSelect: false,
                intensity: false,
                message: false,
                items: [],
                question: null,
                __typename: "MoodMeter",
            },
            timer: {
                type: ClientSettingsSelectOptions.timerType[0],
                requestConfirmation: false,
            },
            selectionBoardListType: ClientSettingsSelectOptions.selectionBoardListType[0],
            award: {
                requestConfirmation: false,
            },
        },
        formErrors: {
            moodMeterItems: null,
            question: null,
        },
        isLoading: true,
    };

    private getDefaultConfig = (clientFunctionsConfig: ClientFunctionsConfig): CurrentConfig => {
        const {
            moodMeter,
            timer: { type: timerType, requestConfirmation: timerRequestConfirmation },
            selectionBoardListType,
            award: { requestConfirmation: awardRequestConfirmation },
        } = clientFunctionsConfig;

        return {
            moodMeter,
            timer: {
                type: Select.getSelectOption(ClientSettingsSelectOptions.timerType, timerType),
                requestConfirmation: timerRequestConfirmation,
            },
            selectionBoardListType: Select.getSelectOption(ClientSettingsSelectOptions.selectionBoardListType, selectionBoardListType),
            award: {
                requestConfirmation: awardRequestConfirmation,
            },
        };
    };

    private refreshSettingsData = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { clientFunctionsConfig, notificationOnProfilePage } = await Api.getClientFunctionsSettingsData(clientId);
                    this.setState({
                        clientFunctionsConfig,
                        currentConfig: this.getDefaultConfig(cloneDeep(clientFunctionsConfig)),
                        notificationOnProfilePage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshSettingsData(this.props.client.id);
        } else {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
            this.setState({ isLoading: false });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshSettingsData(nextProps.client.id);
        }
    }

    private shouldEnabledDashboardItemsBadConfigOpen = (): void => {
        const { clientFunctionsConfig, currentConfig } = this.state;
        const badConfigItems: string[] = [];

        if (
            clientFunctionsConfig?.enabledDashboardItems &&
            clientFunctionsConfig.enabledDashboardItems.find((item: DashboardItem) => item === DashboardItem.moodMeter) &&
            (!currentConfig.moodMeter || !currentConfig.moodMeter.enabled)
        ) {
            badConfigItems.push(Intl.formatMessage({ id: `page.clientSettings.enabledDashboardItems.${DashboardItem.moodMeter}` }));
        }

        if (badConfigItems.length > 0) {
            Alert.info({ title: Intl.formatMessage({ id: "page.clientSettings.enabledDashboardItemsWarning" }, { badConfigItems: badConfigItems.join(", ") }) });
        }
    };

    private shouldMoodMeterBadConfigOpen = (): void => {
        const { clientFunctionsConfig, currentConfig } = this.state;
        if (!isNil(clientFunctionsConfig) && clientFunctionsConfig.moodMeter && clientFunctionsConfig.moodMeter.enabled && (!currentConfig.moodMeter || !currentConfig.moodMeter.enabled)) {
            Alert.info({ title: Intl.formatMessage({ id: "page.clientSettings.moodMeter.badConfigAlert" }) });
        }
    };

    private onCurrentConfigChange = (currentConfigChanges: Partial<CurrentConfig>, formErrorChanges?: Partial<FormErrors>): void => {
        this.setState({ currentConfig: { ...this.state.currentConfig, ...currentConfigChanges }, formErrors: { ...this.state.formErrors, ...formErrorChanges } });
    };

    private convertCurrentConfigToClientConfigInput = (): ClientConfigInput => {
        const {
            timer: { type, requestConfirmation: timerRequestConfirmation },
            selectionBoardListType,
            moodMeter,
            award: { requestConfirmation: awardRequestConfirmation },
        } = this.state.currentConfig;

        return {
            moodMeter: moodMeter
                ? {
                      enabled: moodMeter.enabled,
                      title: moodMeter.title,
                      multiSelect: moodMeter.multiSelect,
                      intensity: moodMeter.intensity,
                      message: !!moodMeter.message,
                      items: moodMeter.items.map(
                          (item: MoodMeterItem, index: number): MoodMeterItemInput => {
                              return {
                                  emotion: item.emotion,
                                  name: item.name,
                                  position: index + 1,
                              };
                          },
                      ),
                      question: moodMeter.question,
                  }
                : {
                      enabled: false,
                      title: "",
                      intensity: false,
                      message: false,
                      multiSelect: false,
                      items: [],
                  },
            timer: { type: type.value, requestConfirmation: timerRequestConfirmation },
            selectionBoardListType: selectionBoardListType.value,
            award: { requestConfirmation: awardRequestConfirmation },
        };
    };

    private isChanged = (): boolean => {
        if (this.state.clientFunctionsConfig) {
            return !isEqual(this.getDefaultConfig(this.state.clientFunctionsConfig), this.state.currentConfig);
        }
        return false;
    };

    private onSaveClick = async (): Promise<void> => {
        if (!this.props.client) {
            return;
        }

        const formErrors = {
            moodMeterItems:
                this.state.currentConfig.moodMeter && this.state.currentConfig.moodMeter.enabled
                    ? IntlHelpers.getValidationError(Validator.validateMoodMeterItems(this.state.currentConfig.moodMeter.items))
                    : null,
            question: this.state.currentConfig.moodMeter?.question !== null ? IntlHelpers.getValidationError(Validator.validateNonEmpty(this.state.currentConfig.moodMeter?.question || null)) : null,
        };

        if (formErrors.moodMeterItems || formErrors.question) {
            this.setState({ validationEnabled: true, formErrors }, () => {
                if (!!formErrors.moodMeterItems && this.moodMeterFormRef) {
                    this.moodMeterFormRef.scrollIntoView();
                }
            });
            return;
        }

        try {
            const clientFunctionsConfig: ClientFunctionsConfig = await Api.updateClientConfig(this.props.client.id, this.convertCurrentConfigToClientConfigInput());

            this.shouldEnabledDashboardItemsBadConfigOpen();
            this.shouldMoodMeterBadConfigOpen();

            this.props.onShowNotification();

            this.setState({ clientFunctionsConfig, currentConfig: this.getDefaultConfig(cloneDeep(clientFunctionsConfig)) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
        this.setState({ isLoading: false });
    };

    private onCancelClick = (): void => {
        this.setState({ validationEnabled: false, currentConfig: this.getDefaultConfig(cloneDeep(this.state.clientFunctionsConfig!)) });
    };

    private onMoodMeterChange = (moodMeter: MoodMeter | null): void => {
        const moodMeterItemsError = moodMeter && moodMeter.items ? IntlHelpers.getValidationError(Validator.validateMoodMeterItems(moodMeter.items)) : null;
        const moodMeterQuestionError = moodMeter && moodMeter.question !== null ? IntlHelpers.getValidationError(Validator.validateNonEmpty(moodMeter.question)) : null;

        this.onCurrentConfigChange({ moodMeter }, { ...this.state.formErrors, moodMeterItems: moodMeterItemsError, question: moodMeterQuestionError });
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client || (!this.state.isLoading && !this.state.clientFunctionsConfig)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientSettings.functionsTab.title" })}>
                    <p className="lead">{Intl.formatMessage({ id: "page.clientSettings.functionsTab.description" })}</p>

                    <MoodMeterForm
                        ref={(ref: MoodMeterForm | null): void => {
                            this.moodMeterFormRef = ref;
                        }}
                        value={this.state.currentConfig.moodMeter}
                        onChange={this.onMoodMeterChange}
                        isValidationEnabled={this.state.validationEnabled}
                        errors={{ moodMeterItems: this.state.formErrors.moodMeterItems, question: this.state.formErrors.question }}
                    />

                    <hr />

                    <InputWrapper id={TestId.clientSettings.timerType} inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.timer.type" })} padded icon="fa-hourglass-half">
                        <Select
                            options={ClientSettingsSelectOptions.timerType}
                            value={this.state.currentConfig.timer.type}
                            onChange={(timerType: SelectOption<TimerType>) => {
                                this.onCurrentConfigChange({ timer: { ...this.state.currentConfig.timer, type: timerType } });
                            }}
                        />
                    </InputWrapper>

                    <InputWrapper id={TestId.clientSettings.timer.requestConfirmation} inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.timer.requestConfirmation" })} padded>
                        <Checkbox
                            label={""}
                            checked={this.state.currentConfig.timer.requestConfirmation}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({ timer: { ...this.state.currentConfig.timer, requestConfirmation: event.currentTarget.checked } });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper
                        id={TestId.clientSettings.selectionBoardListType}
                        inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.selectionBoardListType" })}
                        padded
                        icon="fa-map-signs"
                    >
                        <Select
                            options={ClientSettingsSelectOptions.selectionBoardListType}
                            value={this.state.currentConfig.selectionBoardListType}
                            onChange={(selectionBoardListType: SelectOption<SelectionBoardListType>) => {
                                this.onCurrentConfigChange({ selectionBoardListType });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper
                        id={TestId.clientSettings.award.requestConfirmation}
                        inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.award.requestConfirmation" })}
                        padded
                        icon="fa-star"
                    >
                        <Checkbox
                            label={""}
                            checked={this.state.currentConfig.award.requestConfirmation}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({ award: { ...this.state.currentConfig.award, requestConfirmation: event.currentTarget.checked } });
                            }}
                        />
                    </InputWrapper>
                </Section>
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button id={TestId.clientSettings.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button id={TestId.clientSettings.saveButton} label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>
                <Prompt when={this.isChanged()} />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
    };
};

export const FunctionsTab = withRouter(connect(mapStateToProps)(FunctionsTabComponent));

export default FunctionsTab;

import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { PdfIcon } from "components/Icons/PdfIcon";
import { Intl } from "i18n/Intl";

export enum DownloadExtension {
    mp4 = "mp4",
    pdf = "pdf",
}

interface ReduxProps {
    authToken: string | null;
}

interface LocalProps {
    id: string | null;
    url: string;
    title: string;
    extension: DownloadExtension.mp4 | DownloadExtension.pdf;
}

interface State {
    progress: number;
}

type Props = ReduxProps & LocalProps;

class KnowledgeBaseDownloadLinkComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            progress: 0,
        };
    }

    private XHR: XMLHttpRequest = new XMLHttpRequest();

    public componentWillUnmount(): void {
        this.XHR.abort();
    }

    private download = (id: string | null = null, url: string, fileName: string | null, extension: DownloadExtension.mp4 | DownloadExtension.pdf): void => {
        this.XHR.open("GET", url);
        this.XHR.setRequestHeader("authorization", `Bearer ${this.props.authToken}`);
        this.XHR.responseType = "blob";

        this.XHR.onload = (): void => {
            const blob: Blob = this.XHR.response;
            const anchor: HTMLAnchorElement = document.createElement("a");
            const blobObject: string = window.URL.createObjectURL(blob);
            anchor.href = blobObject;
            anchor.download = fileName ? `${fileName}.${extension}` : this.getFileName(extension);
            anchor.dispatchEvent(new MouseEvent("click"));
            window.URL.revokeObjectURL(blobObject);
        };

        this.XHR.onprogress = this.updateProgresses(id);
        this.XHR.send();
    };

    private getFileName = (extension: DownloadExtension.mp4 | DownloadExtension.pdf): string => {
        const aoszEro: string = "AOSZ_ERO";
        switch (extension) {
            case DownloadExtension.mp4:
                return `${aoszEro}_VIDEO.mp4`;
            case DownloadExtension.pdf:
                return `${aoszEro}_PDF.pdf`;
            default:
                return `${aoszEro}_FILE`;
        }
    };

    private updateProgresses = (id: string | null = null): ((event: ProgressEvent) => void) => (event: ProgressEvent): void => {
        if (event.lengthComputable) {
            const percent: number = (event.loaded / event.total) * 100;
            const percentRound: number = Math.round(percent);
            if (id) {
                this.setState({ progress: percentRound });
            }
        }
    };

    private onDocumentClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        const { id, url, title } = this.props;
        event.preventDefault();
        this.download(id, url, title, DownloadExtension.pdf);
    };

    public render(): JSX.Element {
        const { id, url, title, extension } = this.props;
        const { progress } = this.state;
        const displayProgress: boolean = !!progress && progress < 100;

        if (extension === DownloadExtension.mp4) {
            return displayProgress ? (
                <span>{`${Intl.formatMessage({ id: "common.download" })} ${progress} %`}</span>
            ) : (
                <div className="btn btn-primary btn-outline knowledge-base-download-button" onClick={(): void => this.download(id, url, title, extension)}>
                    {Intl.formatMessage({ id: "common.download" })}
                </div>
            );
        } else {
            return displayProgress ? (
                <div className="grid-x padded align-middle knowledge-base-download-link-title">
                    <span className="cell shrink" aria-hidden={true}>
                        <PdfIcon width={40} height={52} />
                    </span>

                    <span>{`${Intl.formatMessage({ id: "component.knowledgeBaseDownloadLink.downloadInProgress" })} ${progress} %`}</span>
                </div>
            ) : (
                <a href={url} className="grid-x padded align-middle knowledge-base-download-link-title" onClick={this.onDocumentClick}>
                    <span className="cell shrink" aria-hidden={true}>
                        <PdfIcon width={40} height={52} />
                    </span>

                    <span className="cell auto">{title || Intl.formatMessage({ id: "component.knowledgeBaseDownloadLink.downloadPdf" })}</span>
                </a>
            );
        }
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        authToken: state.auth.authToken,
    };
};

export const KnowledgeBaseDownloadLink = connect(mapStateToProps)(KnowledgeBaseDownloadLinkComponent);

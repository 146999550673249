import { ReduxAction } from "actions";
import { DialogsActionType, Dialog } from "actions/DialogsActions";

export type DialogWithUUID = Dialog & { uuid: string };
export type DialogsReducerState = DialogWithUUID[] | [];

export class DialogsReducer {
    private static readonly initialState: DialogsReducerState = [];

    public static instance(state: DialogsReducerState = DialogsReducer.initialState, action: ReduxAction): DialogsReducerState {
        switch (action.type) {
            case DialogsActionType.show:
                return [...state, { ...action.dialog, uuid: action.uuid }];
            case DialogsActionType.hide:
                const newState: DialogsReducerState = [...state];
                return newState.filter(dialog => dialog.uuid !== action.uuid);
            default:
                return state;
        }
    }
}

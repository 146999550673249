import React, { PureComponent } from "react";
import { Button } from "components/Button/Button";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { Intl } from "i18n/Intl";

import "./Slot.scss";
import { PlusIcon } from "components/Icons/PlusIcon";
import { Color } from "theme/Color";

interface Props {
    url?: string;
    name?: string;
    message: string;
    style?: any;
    onCloseClick: () => void;
    disabled?: boolean;
    hasError?: boolean;
    fileUpload?: boolean;
    overlayIcon?: React.ReactElement;
    className?: string;
    customPlaceholder?: React.ReactElement;
    onPreviewClick?: () => void;
}

class Slot extends PureComponent<Props> {
    private onCloseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.props.onCloseClick();
    };

    public render(): React.ReactElement<any> {
        if (this.props.url) {
            return (
                <div className={`uploaded-image${this.props.fileUpload ? " file-upload" : ""}`}>
                    <div className={`uploaded-image-container${this.props.className ? ` ${this.props.className}` : ""}`} onClick={this.props.onPreviewClick}>
                        <Image src={this.props.url} fallback={ImageSrc.asset} />
                        {this.props.onPreviewClick && this.props.overlayIcon && (
                            <>
                                <div className="content-overlay" />
                                <div className="content-overlay-icon">{this.props.overlayIcon}</div>
                            </>
                        )}
                    </div>
                    {!this.props.disabled && (
                        <Button
                            className={`delete${this.props.name ? "" : " left-auto right-n6"}`}
                            link
                            tabIndex={-1}
                            icon={{ name: "fa-times", large: true }}
                            title={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.onCloseClick}
                        />
                    )}
                    {/* <div className="asset-name">{this.props.name}</div> */}
                </div>
            );
        }

        return (
            this.props.customPlaceholder || (
                <div
                    style={this.props.style}
                    className={`slot image-slot${this.props.hasError ? " error" : ""}${this.props.fileUpload ? " file-upload" : ""}${this.props.disabled ? " disabled" : ""}${
                        this.props.className ? ` ${this.props.className}` : ""
                    }`}
                >
                    {!this.props.disabled &&
                        (this.props.fileUpload ? (
                            <span className="upload-plus-container">
                                <PlusIcon fill={this.props.hasError ? Color.error : Color.primary} />
                            </span>
                        ) : (
                            <span className="upload-circle" />
                        ))}
                    <span className="upload-text">{this.props.message}</span>
                </div>
            )
        );
    }
}

export { Slot };

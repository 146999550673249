import React, { Component } from "react";
import { SelectionBoard, SupportedClient, SearchListType, AssetType, Account, SelectionBoardInput, AccountType } from "api/graphql/types";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils } from "pages/_shared/ContentPageUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { isNil } from "lodash";
import { ClientSelectionBoardForm } from "./ClientSelectionBoardForm";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Intl } from "i18n/Intl";
import { NotificationType } from "components/NotificationBar/Notification";
import { TestId } from "utils/TestId";
import { Log } from "utils/Log";
import { ApiErrorCode, ApiError } from "api/ApiError";
import { Formatter } from "utils/Formatter";
import { OptionMenuItem, OptionsMenu } from "components/OptionsMenu/OptionsMenu";
import { DisabledItemUtils } from "utils/DisabledItemUtils";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface RouteParams {
    clientExtId?: string;
    selectionBoardId?: string;
}

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    selectionBoard: SelectionBoard;
    isLoading: boolean;
}

class SelectionBoardPageComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.selectionBoardId),
            selectionBoard: {
                __typename: "SelectionBoard",
                id: "",
                title: "",
                backgroundColor: null,
                itemCount: 0,
                items: [],
                image: null,
                createdBy: {
                    __typename: "Account",
                    id: props.account.id,
                },
                disabledAt: null,
            },
        };
    };

    public readonly state: State = SelectionBoardPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshSelectionBoard(this.props);
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.selectionBoardId !== nextProps.match.params.selectionBoardId || this.props.match.params.clientExtId !== nextProps.match.params.clientExtId) {
            this.refreshSelectionBoard(nextProps);
        }
    }

    private refreshSelectionBoard = (props: Props): void => {
        const { selectionBoardId } = props.match.params;
        if (!selectionBoardId) {
            this.setState(SelectionBoardPageComponent.getInitialStateFromProps(props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    let selectionBoard: SelectionBoard;
                    if (this.props.client) {
                        selectionBoard = await Api.getMyClientSelectionBoardById(this.props.client.id, selectionBoardId);
                    } else {
                        selectionBoard = await Api.getClientSelectionBoardById(this.props.match.params.clientExtId!, selectionBoardId);
                    }
                    this.setState({ isLoading: false, selectionBoard });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private onSubmit = async (selectionBoard: SelectionBoardInput): Promise<SelectionBoard> => {
        const selectionBoardId: string | undefined = this.props.match.params.selectionBoardId;
        const clientId: string | undefined = this.props.client!.id;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);

        if (pageType === PageType.create) {
            const result = await Api.createSelectionBoard(clientId!, selectionBoard);
            this.setState({ selectionBoard: result }, () => {
                Alert.success({ title: Intl.formatMessage({ id: "page.clientSelectionBoards.createSucceed" }) });
            });
            return result;
        } else if (pageType === PageType.edit) {
            const result = await Api.updateSelectionBoard(selectionBoardId!, selectionBoard);
            this.setState({ selectionBoard: result });
            if (this.pageRef) {
                this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.clientSelectionBoards.updateSucceed" }), type: NotificationType.success });
            }
            return result;
        } else {
            Log.warning("View cannot submit (ClientSelectionBoardPage)!");
            throw new ApiError(ApiErrorCode.UNKNOWN);
        }
    };

    private renderPageButtons = (): React.ReactElement<any> | null => {
        const items: OptionMenuItem[] = [];
        const isEnable = !!this.state.selectionBoard.disabledAt;
        items.push({
            label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }),
            icon: isEnable ? "fa-unlock" : "fa-lock",
            onClick: () => {
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.contentToggler,
                        onToggled: (): void => this.refreshSelectionBoard(this.props),
                        content: this.state.selectionBoard,
                    }),
                );
            },
        });

        const relAssets = DisabledItemUtils.getRelatedAssets(this.state.selectionBoard!);
        if (relAssets.length > 0) {
            items.push({
                label: Intl.formatMessage({ id: "component.extraContentOptionMenu.assetRelDisable" }),
                icon: "fa-lock",
                onClick: () => {
                    this.props.dispatch(
                        DialogsActions.show({
                            type: DialogType.disableRelatingAssets,
                            content: this.state.selectionBoard,
                            clientId: this.props.client?.id,
                            onDisabled: (): void => this.refreshSelectionBoard(this.props),
                        }),
                    );
                },
            });
        }

        return (
            <>
                {this.state.selectionBoard.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                {this.props.account.accountType !== AccountType.supporter && items.length > 0 && <OptionsMenu items={items} />}
            </>
        );
    };

    public render(): React.ReactElement {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const isSelectionBoardIdPresentInEditMode: boolean =
            pageType === PageType.create || (pageType === PageType.edit && !isNil(this.props.match.params.selectionBoardId)) || pageType === PageType.view;

        if (!isSelectionBoardIdPresentInEditMode || isNil(this.props.match.params.clientExtId)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <Page
                id={TestId.clientSelectionBoardsPagePage.clientSelectionBoardsPagePageContainer}
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: "page.clientSelectionBoards.page.title" }, { name: this.props.client?.name || Formatter.formatExtId(this.props.match.params.clientExtId) })}
                hasSideBar={pageType !== PageType.view}
                renderButtons={this.renderPageButtons}
            >
                <DragContainer dragIds={[]} searchListTypes={[SearchListType.asset]} assetTypes={[AssetType.image]} createdById={this.props.account!.id} hideSidebar={pageType === PageType.view}>
                    <div className="left-side">
                        {this.props.client && <p>{Intl.formatMessage({ id: "page.clientSelectionBoard.lead" }, { name: this.props.client.name })}</p>}
                        <ClientSelectionBoardForm pageType={pageType} account={this.props.account} selectionBoard={this.state.selectionBoard} onSubmit={this.onSubmit} />
                    </div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
        account: state.account!,
    };
};

export const SelectionBoardPage = connect(mapStateToProps)(withRouter(SelectionBoardPageComponent));

// tslint:disable-next-line: no-default-export
export default SelectionBoardPage;

class FilePreview {
    private mediaUrl: string;
    private imageUrl: string;

    constructor(mediaUrl: string) {
        this.mediaUrl = mediaUrl;
        this.imageUrl = "";
    }

    public getVideoThumbnail = (): Promise<string> => {
        const video = document.createElement("video");

        return new Promise(resolve => {
            video.addEventListener("timeupdate", () => this.timeUpdate(video, resolve));
            video.preload = "metadata";
            video.src = this.mediaUrl;
            video.muted = true;
            video.play();
        });
    };

    private timeUpdate = (video: HTMLVideoElement, resolve: any) => {
        if (this.snapImage(video)) {
            video.removeEventListener("timeupdate", () => this.timeUpdate(video, resolve));
            video.pause();
            resolve(this.imageUrl);
        }
    };

    private snapImage = (video: HTMLVideoElement) => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = canvas.toDataURL();
            const success = image.length > 100000;
            if (success) {
                this.imageUrl = image;
            }
            return success;
        } else {
            return false;
        }
    };
}

export { FilePreview };

import React, { Component } from "react";
import { Input } from "components/Inputs/Input/Input";
import { Button } from "components/Button/Button";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

export interface ListItem {
    id?: string;
    value: string;
}

interface Props {
    deleteLabel: string;
    addLabel: string;
    newValuePlaceholder: string;
    value: ListItem[];
    errors?: Array<string | null>;
    onChange: (value: ListItem[], callback?: () => void) => void;
    disabled?: boolean;
}

interface State {
    newValue: string;
}

class ListInput extends Component<Props, State> {
    private newValueRef: HTMLInputElement | null = null;
    private errorValueRef: HTMLInputElement | null = null;

    public readonly state: State = {
        newValue: "",
    };

    private onDeleteClick = (index: number) => () => {
        const newValue = [...this.props.value];
        newValue.splice(index, 1);
        this.props.onChange(newValue);
    };

    private onChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue: ListItem[] = [...this.props.value];
        newValue[index] = { value: event.currentTarget.value };
        this.props.onChange(newValue);
    };

    private onBlur = () => {
        this.props.onChange(this.props.value.filter((item: ListItem) => item.value !== ""));
    };

    public focus(): void {
        if (this.errorValueRef) {
            this.errorValueRef.focus();
        }
    }

    private renderListItem = (item: ListItem, index: number): React.ReactElement<any> => {
        const errorMessage: string | null = this.props.errors ? this.props.errors[index] : null;
        const isFirstError = this.props.errors && index === this.props.errors.findIndex((error: string | null): boolean => error !== null);
        const innerRef = isFirstError
            ? (ref: HTMLInputElement | null): void => {
                  this.errorValueRef = ref;
              }
            : undefined;
        return (
            <div key={item.id || `${index}`} className="task grid-x align-middle">
                <div className="cell auto">
                    <Input
                        innerRef={innerRef}
                        type="text"
                        value={item.value}
                        onChange={this.onChange(index)}
                        onBlur={this.onBlur}
                        disabled={this.props.disabled}
                        placeholder={this.props.newValuePlaceholder}
                    />
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </div>
                {!this.props.disabled && (
                    <div className="cell delete">
                        <Button link icon={{ name: "fa-times", large: true }} ariaLabel={this.props.deleteLabel} title={this.props.deleteLabel} onClick={this.onDeleteClick(index)} />
                    </div>
                )}
            </div>
        );
    };

    private onNewValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newValue: event.currentTarget.value });
    };

    private onNewValueBlur = () => {
        if (this.state.newValue !== "") {
            this.props.onChange([...this.props.value, { value: this.state.newValue }]);
            this.setState({ newValue: "" });
        }
    };

    private onNewValueSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.state.newValue !== "") {
            this.props.onChange([...this.props.value, { value: this.state.newValue }]);
            this.setState({ newValue: "" }, () => {
                if (this.newValueRef) {
                    this.newValueRef.focus();
                }
            });
        } else if (this.newValueRef) {
            this.newValueRef.focus();
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <>
                {this.props.value.map(this.renderListItem)}

                {!this.props.disabled && (
                    <form onSubmit={this.onNewValueSubmit} onBlur={this.onNewValueBlur}>
                        <div key={this.props.value.length} className="task grid-x align-middle">
                            <div className="cell auto">
                                <Input
                                    innerRef={(ref: HTMLInputElement | null) => {
                                        this.newValueRef = ref;
                                    }}
                                    type="text"
                                    placeholder={this.props.newValuePlaceholder}
                                    onChange={this.onNewValueChange}
                                    value={this.state.newValue}
                                />
                            </div>
                            <div className="cell delete">
                                <Button link type="submit" icon={{ name: "fa-plus", large: true }} ariaLabel={this.props.addLabel} title={this.props.addLabel} />
                            </div>
                        </div>
                    </form>
                )}
            </>
        );
    }
}

export { ListInput };

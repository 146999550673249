import React, { Component } from "react";
import { DateUtils, TimeUnit } from "utils/DateUtils";
import { PeriodOption } from "models/PeriodSelectOptions";
import { Intl } from "i18n/Intl";
import { NumberInput } from "../NumberInput/NumberInput";

interface Props {
    value: number | null; // seconds
    onChange: (value: number | null, unit: TimeUnit) => void;
    disabled?: boolean;
}

interface State {
    selectedPeriod: PeriodOption;
}

class PeriodInput extends Component<Props, State> {
    private inputRef: HTMLInputElement | null = null;

    public readonly state: State = {
        // https://jira.bigfish.hu/browse/AOSZ-874: only minutes available
        // selectedPeriod: Select.getSelectOption(PeriodSelectOptions.get(), DateUtils.getNearestTimeUnit(this.props.value || 0, [TimeUnit.second, TimeUnit.minute, TimeUnit.hour])),
        selectedPeriod: { id: TimeUnit.minute, label: Intl.formatMessage({ id: `enum.timeUnit.${TimeUnit.minute}` }), value: TimeUnit.minute },
    };

    private onSecondsChange = (value: number | null): void => {
        if (value !== null) {
            const seconds = DateUtils.toSeconds(value, this.state.selectedPeriod.value);
            this.props.onChange(Math.floor(seconds), this.state.selectedPeriod.value);
        } else {
            this.props.onChange(null, this.state.selectedPeriod.value);
        }
    };

    // https://jira.bigfish.hu/browse/AOSZ-874: only minutes available
    // private onPeriodChange = (selectedPeriod: PeriodOption) => {
    //     this.setState({ selectedPeriod }, () => {
    //         if (this.props.value !== null) {
    //             const newValue = DateUtils.secondsTo(this.props.value, this.state.selectedPeriod.value) || 0;
    //             if (newValue !== null) {
    //                 const seconds = DateUtils.toSeconds(newValue, this.state.selectedPeriod.value);
    //                 this.props.onChange(Math.floor(seconds), this.state.selectedPeriod.value);
    //                 return;
    //             }
    //         }
    //         this.props.onChange(null, this.state.selectedPeriod.value);
    //     });
    // };

    public focus(): void {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }

    public render(): React.ReactElement<any> {
        const inputValue = DateUtils.secondsTo(this.props.value, this.state.selectedPeriod.value);

        return (
            <div className="grid-x align-middle padded">
                {this.props.disabled ? (
                    <div className="cell">
                        <div className="input-info">
                            <span>{`${inputValue} ${Intl.formatMessage({ id: `enum.timeUnit.${this.state.selectedPeriod.value}` })}`}</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="cell medium-5">
                            <NumberInput
                                innerRef={(ref: HTMLInputElement | null): void => {
                                    this.inputRef = ref;
                                }}
                                min={0}
                                max={60}
                                value={inputValue}
                                onChange={this.onSecondsChange}
                            />
                        </div>
                        <div className="cell medium-7">
                            <span>{Intl.formatMessage({ id: `enum.timeUnit.${this.state.selectedPeriod.value}` })}</span>
                            {/* https://jira.bigfish.hu/browse/AOSZ-874: only minutes available */}
                            {/* <Select options={PeriodSelectOptions.get()} value={this.state.selectedPeriod} onChange={this.onPeriodChange} /> */}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export { PeriodInput };

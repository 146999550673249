import React, { Component } from "react";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Award, Client, AssetType, Award_image } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Column, Table } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { DispatchProp, connect } from "react-redux";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Button } from "components/Button/Button";
import { Path } from "utils/Path";
import { Section } from "components/Section";
import { isNil } from "lodash";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Image } from "components/Image";

enum AwardsTableColumn {
    title = "title",
    targetScore = "targetScore",
    isActive = "isActive",
    actions = "actions",
}

interface State {
    isLoading: boolean;
    awards: Award[];
}

interface ComponentProps {
    client: Client;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class ClientAwardsTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        awards: [],
    };

    public componentDidMount(): void {
        this.refreshClientAwards(this.props.client!.id);
    }

    private refreshClientAwards = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const awards = await Api.getClientAwards(clientId);
                    this.setState({ isLoading: false, awards });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, awards: [] });
                }
            },
        );
    };

    private onAwardEdit = (awardId: string): void => {
        this.props.history.push(Path.editClientAward(this.props.client.extId, awardId));
    };

    private renderActionsCell = (awardId: string): React.ReactElement => {
        return (
            <div className="action-buttons">
                <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={() => this.onAwardEdit(awardId)} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                <Button link icon={{ name: "fa-trash", large: true }} onClick={() => this.onAwardDelete(awardId)} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
            </div>
        );
    };

    private onImageClick = (image: Award_image | null): void => {
        if (isNil(image?.url)) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.showAsset,
                assetUrl: image!.url,
                assetType: AssetType.image,
                originalFileName: image!.originalFileName,
                thumbnailUrl: image!.url,
                dialogTitle: Intl.formatMessage({ id: "page.clientAwardsBase.showImageDialogTitle" }),
            }),
        );
    };

    private renderTitleAndImage = (award: Award): React.ReactElement => {
        return (
            <div className="awardsbase-title-and-image">
                <div className={`awardsbase-image-container ${!isNil(award.image?.url) ? " show-cursor" : ""}`} onClick={() => this.onImageClick(award.image)}>
                    <Image className="awardsbase-image" src={award.image?.thumbnailUrl || award.image?.url} />
                    {award.image?.url && (
                        <>
                            <div className="content-overlay" />
                            <div className="content-overlay-icon">
                                <span className="fa fa-search-plus" />
                            </div>
                        </>
                    )}
                </div>
                <span className="awardsbase-title">{award.title}</span>
            </div>
        );
    };

    private readonly getColumns = (): Array<Column<Award>> => {
        const columnNames: AwardsTableColumn[] = ObjectUtils.enumAsArray<AwardsTableColumn>(AwardsTableColumn);

        return columnNames.map(
            (columnName: AwardsTableColumn): Column<Award> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.clientAwardsBase.clientAwardsTab.columns.${columnName}` }),
                accessor: columnName as keyof Award,
                renderCell: (award: Award): React.ReactElement | null => {
                    switch (columnName) {
                        case AwardsTableColumn.title:
                            return <span>{this.renderTitleAndImage(award)}</span>;
                        case AwardsTableColumn.targetScore:
                            return !isNil(award[columnName]) ? (
                                <span>{Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.targetScorePoints" }, { points: award[columnName] })}</span>
                            ) : (
                                <></>
                            );
                        case AwardsTableColumn.isActive:
                            return this.getIsDisabledCell(award[columnName], award.id);
                        case AwardsTableColumn.actions:
                            return <span>{this.renderActionsCell(award.id)}</span>;
                        default:
                            return <></>;
                    }
                },
            }),
        );
    };

    private onActivateClick = async (awardId: string): Promise<void> => {
        try {
            await Api.activateAward(awardId);
            this.refreshClientAwards(this.props.client!.id);
            Alert.success({ title: Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.activateSucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private getIsDisabledCell = (isActive: boolean, awardId: string): React.ReactElement => {
        if (isActive) {
            return <span>{Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.isActiveValues.true" })}</span>;
        }
        return (
            <span className="client-award-inactive" onClick={() => this.onActivateClick(awardId)}>
                {Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.isActiveValues.false" })}
            </span>
        );
    };

    private onAwardDelete = (clientAwardId: string): void => {
        const award = this.state.awards.find(award => award.id === clientAwardId);
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteClientAward,
                clientAwardId,
                onDeleted: () => this.refreshClientAwards(this.props.client!.id),
                isAwardActive: award?.isActive ?? false,
                clientName: this.props.client.name,
            }),
        );
    };

    private renderList = (): React.ReactElement => {
        return (
            <>
                <p>{Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.lead" }, { name: this.props.client!.name })}</p>
                <p className="balance">
                    <span>{Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.balance" }, { name: this.props.client!.name })}</span>
                    <span className="points">{Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.targetScorePoints" }, { points: this.props.client!.awardScoreBalance || 0 })}</span>
                </p>
                <Table
                    keyExtractor={(award: Award, column?: Column<Award>): string => {
                        return `${award.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    data={this.state.awards}
                    count={this.state.awards.length}
                    isLoading={this.state.isLoading}
                    currentPage={1}
                    renderEmpty={(): string => Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.noData" })}
                />
            </>
        );
    };

    public render(): React.ReactElement {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.title" })}>
                    {this.renderList()}
                    <Button
                        icon={{ name: "fa-plus", position: "left" }}
                        label={Intl.formatMessage({ id: "common.addReward" })}
                        onClick={() => this.props.history.push(Path.createClientAward(this.props.client.extId))}
                        hollow
                    />
                </Section>
            </div>
        );
    }
}

export const ClientAwardsTab = withRouter(connect()(ClientAwardsTabComponent));

export default ClientAwardsTab;

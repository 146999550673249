import React, { PureComponent } from "react";
import { Select } from "../Select/Select";
import { ApplicationState } from "reducers/index";
import { connect, MapStateToProps } from "react-redux";
import { ColorOption } from "./ColorOption";
import { SettingsSelectors, ColorType } from "selectors/SettingsSelectors";
import { Intl } from "i18n/Intl";
import { NamedColor } from "api/graphql/types";

interface ReduxProps {
    colors: ColorOption[];
}

interface ComponentProps {
    isNullable?: boolean;
    selectedColor: string | null;
    options?: ColorOption[];
    onChange: (item: NamedColor | null) => any;
    colorType: ColorType;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
}

type Props = ComponentProps & ReduxProps;

class ColorSelectComponent extends PureComponent<Props> {
    private renderOption = (item: ColorOption | null): React.ReactElement<any> => {
        if (!item) {
            return <>{Intl.formatMessage({ id: "component.colorSelect.empty" })}</>;
        }

        return (
            <>
                {item.value && (
                    <span
                        className={`color-option${
                            item.value.replace(/\s/g, "").toLowerCase() === ("#ffffff" || "#fff" || "white" || "rgb(255,255,255)" || "rgba(255,255,255,1)") ? " color-option--has-border" : ""
                        }`}
                        style={{ background: item.value }}
                    />
                )}
                {item.label}
            </>
        );
    };

    private onChange = (colorOption: ColorOption) => {
        this.props.onChange(colorOption.value ? { name: colorOption.label, value: colorOption.value, __typename: "NamedColor" } : null);
    };

    public render(): React.ReactElement<any> {
        const currentColorOption: ColorOption | null =
            (this.props.options || this.props.colors).find((colorOption: ColorOption): boolean => {
                return colorOption.value === this.props.selectedColor;
            }) || null;

        return (
            <Select
                options={this.props.colors}
                onChange={this.onChange}
                renderOption={this.renderOption}
                value={currentColorOption}
                emptyLabel={Intl.formatMessage({ id: "component.colorSelect.empty" })}
                disabled={this.props.disabled}
                hasError={this.props.hasError}
                className={this.props.className}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    return { colors: SettingsSelectors.getColorOptionsByType(state, props.isNullable, props.colorType) };
};

const ColorSelect = connect(mapStateToProps)(ColorSelectComponent);

export { ColorSelect };
export type { ColorOption };

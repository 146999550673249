import React, { PureComponent } from "react";

interface Props {
    isSmall?: boolean;
    title: string;
    description: string | JSX.Element;
    href: string;
}

class Box extends PureComponent<Props> {
    public render(): React.ReactElement {
        const { title, description, isSmall } = this.props;
        if (isSmall) {
            return (
                <div className="cell medium-4">
                    <a href={this.props.href}>
                        <div className="basic-box">
                            <p className="box-title">{title}</p>
                            <p className="box-description">{description}</p>
                        </div>
                    </a>
                </div>
            );
        }
        return (
            <div className="cell medium-6">
                <a href={this.props.href}>
                    <div className="basic-box">
                        <p className="box-title">{title}</p>
                        <p className="box-description">{description}</p>
                    </div>
                </a>
            </div>
        );
    }
}

export { Box };

import React, { PureComponent } from "react";
import { DropdownItem, Dropdown } from "components/Dropdown/Dropdown";
import { RouteComponentProps, withRouter } from "react-router";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect } from "react-redux";
import { Intl } from "i18n/Intl";
import { SupportedClient } from "api/graphql/types";
import { Path } from "utils/Path";

interface ComponentProps {
    client?: SupportedClient | null;
    isUploadEnabled: boolean;
    onAssetUploaded?: () => void;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class CreateContentButtonComponent extends PureComponent<Props> {
    private getCreateAgendaPath = (): string => {
        if (this.props.client) {
            return Path.createClientAgenda(this.props.client.extId);
        }
        return Path.createAgenda();
    };

    private getCreateAgendaItemPath = (): string => {
        if (this.props.client) {
            return Path.createClientAgendaItem(this.props.client.extId);
        }
        return Path.createAgendaItem();
    };

    private getCreateFlowchartPath = (): string => {
        if (this.props.client) {
            return Path.createClientFlowchart(this.props.client.extId);
        }
        return Path.createFlowchart();
    };

    private getCreateFlowchartItemPath = (): string => {
        if (this.props.client) {
            return Path.createClientFlowchartItem(this.props.client.extId);
        }
        return Path.createFlowchartItem();
    };

    private onCreateAssetClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.uploadAsset,
                isVisible: true,
                onAssetUploadFinished: this.onAssetUploadFinished,
            }),
        );
    };

    private getDropdownItems = (): DropdownItem[] => {
        const items: DropdownItem[] = [
            { label: Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAgendaItem" }), path: this.getCreateAgendaItemPath() },
            { label: Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAgenda" }), path: this.getCreateAgendaPath() },
            { label: Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createFlowchartItem" }), path: this.getCreateFlowchartItemPath() },
            { label: Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createFlowchart" }), path: this.getCreateFlowchartPath() },
        ];

        if (this.props.isUploadEnabled) {
            items.push({ label: Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAsset" }), onClick: this.onCreateAssetClick });
        }

        return items;
    };

    private onAssetUploadFinished = (): void => {
        if (this.props.onAssetUploaded) {
            this.props.onAssetUploaded();
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <>
                <Dropdown addElement label={Intl.formatMessage({ id: "sharedComponent.createContentButton.label" })} items={this.getDropdownItems()} />
            </>
        );
    }
}

export const CreateContentButton = withRouter(connect()(CreateContentButtonComponent));

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Section } from "components/Section";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { SupporterDetail, SupporterDetail_pendingClientShareRequests_result, ClientShareRequestClient, Account, AccountType } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";
import { Formatter } from "utils/Formatter";
import { Button } from "components/Button/Button";
import { AccountDetailStatusForm } from "pages/_shared/AccountDetails/AccountDetailStatusForm";
import { Select } from "components/Inputs/Select/Select";
import { AccountTypeSelectOptions } from "models/AccountTypeSelectOptions";
import { SupporterDetailClientList } from "./SupporterDetailClientList";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { ApplicationState } from "reducers";

interface Params {
    supporterExtId: string;
}

interface ReduxProps {
    account: Account;
}

type ComponentProps = RouteComponentProps<Params>;

type Props = ReduxProps & ComponentProps & DispatchProp;

interface State {
    isLoading: boolean;
    supporter: SupporterDetail | null;
}

class SupporterDetailProfileTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        supporter: null,
    };

    public componentDidMount(): void {
        this.refreshSupporter(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.refreshSupporter(nextProps);
        }
    }

    private refreshSupporter(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const account: SupporterDetail = await Api.getSupporterByExtId(props.match.params.supporterExtId);
                    this.setState({ supporter: account, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.supporterList);
                        },
                    });
                }
            },
        );
    }

    private deleteAccount = async (): Promise<void> => {
        try {
            await Api.deleteAccount(this.state.supporter!.id);
            Alert.success({
                title: Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.accountData.delete.deleteSucceed" }),
                callback: (): void => {
                    this.props.history.push(Path.supporterList);
                },
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> | null {
        if (this.state.isLoading && !this.state.supporter) {
            return <Loading type={LoadingType.layer} />;
        }

        const { supporter } = this.state;
        if (!supporter) {
            return <Redirect to={Path.supporterList} />;
        }

        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.personalData.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.personalData.emailForm.email.label" })}>
                        <Input
                            placeholder={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.personalData.emailForm.email.placeholder" })}
                            type="email"
                            value={supporter.email}
                            onChange={(): void => {}}
                            disabled={true}
                        />
                    </InputWrapper>

                    <div className="input-wrapper grid-x">
                        <span className="input-label cell">{Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.personalData.extId" })}</span>
                        <span className="cell auto">
                            <strong className="value-cell">{Formatter.formatExtId(supporter.extId!)}</strong>
                        </span>
                        <span className="cell button-cell">
                            <CopyToClipboardButton copiedTooltipMessage={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.personalData.copied" })} textToCopy={supporter.extId!} />
                        </span>
                    </div>
                </Section>

                <Section label={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.accountData.title" })}>
                    {[AccountType.admin, AccountType.superadmin].includes(this.props.account.accountType) && (
                        <div className="input-wrapper grid-x">
                            <span className="input-label cell">{Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.accountData.delete.label" })}</span>
                            <span className="cell button-cell">
                                <Button
                                    className="btn-danger btn--text-large fw-700"
                                    label={Intl.formatMessage({ id: "common.delete" })}
                                    ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                    onClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.deleteAccountConfirm,
                                                onConfirmed: this.deleteAccount,
                                            }),
                                        );
                                    }}
                                />
                            </span>
                        </div>
                    )}
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.accountData.accountType.label" })}>
                        <Select
                            options={AccountTypeSelectOptions.get()}
                            onChange={(): void => {}}
                            value={Select.getSelectOption(AccountTypeSelectOptions.get(), supporter.accountType)}
                            disabled={true}
                        />
                    </InputWrapper>

                    {[AccountType.admin, AccountType.superadmin, AccountType.supervisor].includes(this.props.account.accountType) && (
                        <AccountDetailStatusForm
                            account={supporter}
                            onSaveSucceed={(): void => this.refreshSupporter(this.props)}
                            disabled={this.props.account.accountType === AccountType.supervisor}
                        />
                    )}
                </Section>
                <Section label={Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.clients.title" })}>
                    <SupporterDetailClientList
                        activeClients={(supporter.myClients || { result: [] }).result}
                        pendingClients={
                            supporter.pendingClientShareRequests
                                ? supporter.pendingClientShareRequests.result.map(
                                      (req: SupporterDetail_pendingClientShareRequests_result): ClientShareRequestClient => {
                                          return req.client;
                                      },
                                  )
                                : []
                        }
                    />
                </Section>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const SupporterDetailProfileTab = withRouter(connect(mapStateToProps)(SupporterDetailProfileTabComponent));

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface VerifyDeleteClientInstantAwardDialogProps {
    clientInstantAwardId: string;
    onDeleted: () => void;
    hasReferences: boolean;
}

type Props = VerifyDeleteClientInstantAwardDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class VerifyDeleteClientInstantAwardDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private deleteClientInstantAward = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.deleteClientInstantAward(this.props.clientInstantAwardId);
                    Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.verifyDeleteClientInstantAwardDialog.success" }) });
                    this.props.onHide();
                    this.props.onDeleted();
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }

                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "sharedComponent.verifyDeleteClientInstantAwardDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <i className="fas fa-exclamation-triangle verify-delete-client-instant-award-dialog-icon" />
                <p>{Intl.formatMessage({ id: `sharedComponent.verifyDeleteClientInstantAwardDialog.descriptionLineReferences.${this.props.hasReferences}` })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            label={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.deleteClientInstantAward}
                            disabled={this.state.isLoading}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const VerifyDeleteClientInstantAwardDialog: React.ComponentClass<Props> = VerifyDeleteClientInstantAwardDialogComponent;

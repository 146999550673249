import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";

import "./GeneralFileInput.scss";

interface ComponentProps {
    url: string | null;
    type: string;
    onFileSelected: (file: File[]) => void | Promise<void>;
    disabled?: boolean;
    placeholder: React.ReactElement<any>;
    renderContent?: (url: string) => React.ReactElement<any>;
}

type Props = ComponentProps & NotificationProp;

class GeneralFileInputComponent extends Component<Props> {
    private onFileRejected = (files: File[]): void => {
        files.forEach((): void => {
            this.props.showNotification({
                message: Intl.formatMessage({ id: "component.generalFileInput.fileRejected" }),
                type: NotificationType.error,
            });
        });
    };

    public render(): React.ReactElement {
        return (
            <Dropzone onDrop={this.props.onFileSelected} accept={this.props.type} onDropRejected={this.onFileRejected} disabled={this.props.disabled}>
                {({ getRootProps, getInputProps }): React.ReactElement => (
                    <div className="file-input-wrapper">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.props.url ? this.props.renderContent ? this.props.renderContent(this.props.url) : <img src={this.props.url} /> : this.props.placeholder}
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }
}

export const GeneralFileInput: React.ComponentClass<ComponentProps> = withNotification(GeneralFileInputComponent);

import * as React from "react";

function SvgFlowChart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path d="M24 12c0 6.627-5.372 12-12 12-6.627 0-12-5.373-12-12S5.373 0 12 0c6.628 0 12 5.373 12 12z" fill="currentColor" />
            <path
                d="M5.836 8.559l.45-.43a.452.452 0 01.331-.137c.13 0 .241.046.332.137l3.79 3.789a.452.452 0 01.136.332c0 .13-.046.24-.137.332l-3.789 3.79a.452.452 0 01-.332.136.452.452 0 01-.332-.137l-.45-.43a.452.452 0 01-.136-.332.53.53 0 01.156-.332L8.2 13.031H2.594a.452.452 0 01-.332-.136.452.452 0 01-.137-.332v-.626c0-.13.046-.24.137-.332a.452.452 0 01.332-.136h5.605L5.855 9.223A.53.53 0 015.7 8.89c0-.13.046-.241.137-.332zM16.46 8.559l.45-.43a.452.452 0 01.332-.137c.13 0 .241.046.332.137l3.79 3.789a.452.452 0 01.136.332c0 .13-.046.24-.137.332l-3.789 3.79a.452.452 0 01-.332.136.452.452 0 01-.332-.137l-.45-.43a.452.452 0 01-.136-.332.53.53 0 01.156-.332l2.344-2.246H13.22a.452.452 0 01-.332-.136.452.452 0 01-.137-.332v-.626c0-.13.046-.24.137-.332a.452.452 0 01.332-.136h5.605L16.48 9.223a.53.53 0 01-.156-.332c0-.13.046-.241.137-.332z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgFlowChart;

import React, { Component } from "react";
import { AssetType, SupportedClient, ClientGamesConfig, SearchListType } from "api/graphql/types";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { DragContainer } from "../_shared/Draggables/DragContainer";
import { Path } from "utils/Path";
import { TabPanel } from "components/TabPanel/TabPanel";
import { MemoryGameTab } from "./MemoryGameTab";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Loading, LoadingType } from "components/Loading/Loading";
import { TestId } from "../../utils/TestId";
import { SortingGameTab } from "./SortingGameTab";

interface ReduxProps {
    client: SupportedClient | null;
    accountId: string;
}

interface RouteParams {
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ReduxProps & ComponentProps & DispatchProp;

interface State {
    config: ClientGamesConfig | null;
    isLoading: boolean;
    dragIds: string[];
}

class ClientGamesComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            config: null,
            isLoading: true,
            dragIds: [],
        };
    }

    public componentDidMount(): void {
        this.fetchConfig();
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.client!.id !== prevProps.client!.id) {
            this.fetchConfig();
        }
        if (this.props.match.path !== prevProps.match.path) {
            this.setState({
                dragIds: [],
            });
        }
    }

    private readonly fetchConfig = () => {
        this.setState({ isLoading: true }, async () => {
            try {
                const config = await Api.getClientGameConfig(this.props.client?.id || "");
                this.setState({
                    isLoading: false,
                    config: config,
                });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly getSearchListType = (): SearchListType => {
        if (this.props.match.url === Path.clientMemoryGames(this.props.client!.extId)) {
            return SearchListType.asset;
        } else {
            return SearchListType.flowchart;
        }
    };

    public readonly onDragIdChange = (dragIds: string[]): void => {
        this.setState({
            dragIds,
        });
    };

    private renderContent = (): React.ReactElement<any> => {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div className="left-side">
                <TabPanel
                    tabs={[
                        {
                            label: Intl.formatMessage({ id: "page.clientGames.memoryTab.title" }),
                            to: Path.clientMemoryGames(this.props.client?.extId),
                            component: MemoryGameTab,
                            additionalProps: { client: this.props.client, gameConfig: this.state.config, onDragIdChange: this.onDragIdChange },
                            id: TestId.clientGames.memoryGameTab.id,
                        },
                        {
                            label: Intl.formatMessage({ id: "page.clientGames.sortingTab.title" }),
                            to: Path.clientSortingGames(this.props.client?.extId),
                            component: SortingGameTab,
                            additionalProps: { client: this.props.client, gameConfig: this.state.config, onDragIdChange: this.onDragIdChange },
                            id: TestId.clientGames.sortingGameTab.id,
                        },
                    ]}
                />
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client || !this.props.match.params.clientExtId) {
            return <Redirect to={Path.dashboard} />;
        }

        const searchListType = this.getSearchListType();
        return (
            <Page title={Intl.formatMessage({ id: "page.clientGames.title" }, { name: this.props.client.name })} hasSideBar={true} hasTabs={true}>
                <DragContainer
                    hideSidebar={this.state.isLoading}
                    searchListTypes={[searchListType]}
                    assetTypes={searchListType === SearchListType.asset ? [AssetType.image] : []}
                    createdById={searchListType === SearchListType.asset ? this.props.accountId : undefined}
                    client={searchListType === SearchListType.asset ? null : this.props.client}
                    dragIds={this.state.dragIds}
                    isUsableInSortingGame={searchListType === SearchListType.flowchart}
                >
                    {this.state.isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
        accountId: state.account!.id,
    };
};

export const ClientGamesPage = connect(mapStateToProps)(withRouter(ClientGamesComponent));

// tslint:disable-next-line: no-default-export
export default ClientGamesPage;

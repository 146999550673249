import React, { Component } from "react";
import {
    AssetContent,
    AssetType,
    SearchListType,
    Flowchart,
    FlowchartItemContent,
    FlowchartItemFlowchartRel,
    SupportedClient,
    Account,
    AccountType,
    Tag,
    LibraryContentShareRequestWithData,
} from "api/graphql/types";
import { Input } from "components/Inputs/Input/Input";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { cloneDeep } from "apollo-utilities";
import isEqual from "lodash/isEqual";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { PageType } from "utils/TypeUtils";
import { Alert } from "components/Alert/Alert";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FlowchartItemInput } from "pages/_shared/Draggables/Input/FlowchartItemInput";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { sortBy } from "lodash";
import { ContentPageButtons } from "pages/_shared/ContentPageButtons/ContentPageButtons";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { ContentDeviceList } from "components/ContentDeviceList/ContentDeviceList";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import { ApplicationState } from "reducers/index";
import { LibraryContentShareRequestSection } from "pages/_shared/LibraryShareRequestSection/LibraryContentShareRequestSection";

interface ReduxProps {
    tags: Tag[];
}

interface ComponentProps {
    myAccount: Account;
    isLoading: boolean;
    client?: SupportedClient | null;
    pageType: PageType;
    flowchart: Flowchart;
    onSubmit: (flowchartItem: Flowchart) => Promise<Flowchart>;
    onEditClick: () => void;
    onToggled: () => void;

    clientExtId?: string;
    supporterExtId?: string;
    supervisorId?: string;
    pageContent: PageContent;
    accountType: AccountType;
    refreshFlowchart: () => void;

    shareRequest?: LibraryContentShareRequestWithData;
}

interface Errors {
    title: string | null;
    description: string | null;
}

interface State {
    flowchart: Flowchart;
    errors: Errors;
    isValidationEnabled: boolean;
    isSaved: boolean;
    isPromptDisabled: boolean;
    selectedTags: string[];
    isLoading: boolean;
}

type Props = ReduxProps & ComponentProps & RouteComponentProps & DispatchProp;

class FlowchartFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;
    private descriptionRef: HTMLTextAreaElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            flowchart: cloneDeep(props.flowchart),
            errors: {
                title: null,
                description: null,
            },
            isValidationEnabled: false,
            isSaved: false,
            isPromptDisabled: false,
            selectedTags: [],
            isLoading: false,
        };
    }

    public readonly state: State = FlowchartFormComponent.getInitialStateFromProps(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.flowchart !== nextProps.flowchart) {
            this.setState(FlowchartFormComponent.getInitialStateFromProps(nextProps));
        }
    }

    private onSubmitClick = (): void => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartTitle(this.state.flowchart.title));
        const descriptionError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartDescription(this.state.flowchart.description));
        if (errors.title !== null || titleError || errors.description !== null || descriptionError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError, description: descriptionError } });
            if (titleError && this.titleRef) {
                this.titleRef.focus();
            } else if (descriptionError && this.descriptionRef) {
                this.descriptionRef.focus();
            }
            return;
        }
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const flowchart: Flowchart = await this.props.onSubmit(this.state.flowchart);
                    this.setState({ flowchart, isPromptDisabled: true, isLoading: false });
                    if (this.props.pageType === PageType.create) {
                        const path: string | undefined = ContentPageUtils.getEditPath(flowchart, this.props.pageContent, this.props.client ? this.props.client.extId : undefined);

                        if (path) {
                            this.props.history.push(path);
                        }
                    }
                    if (this.pageRef) {
                        this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.flowchart.saveSucceed" }), type: NotificationType.success });
                    }
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onAssignClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.assignToClient,
                content: this.props.flowchart,
            }),
        );
    };

    private onShareClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.shareContent,
                content: this.props.flowchart,
                onSucceed: this.props.refreshFlowchart,
            }),
        );
    };

    private onToggleClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                onToggled: this.onToggled,
                content: this.props.flowchart,
            }),
        );
    };

    private onDeleteClick = () => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteFlowchart,
                onDeleted: () => {
                    this.props.history.push(ContentPageUtils.getRootPath(this.props.match.path, this.props.clientExtId, this.props.supporterExtId));
                },
                flowchart: this.props.flowchart,
            }),
        );
    };

    private onSyncClick = (): void => {
        if (!this.props.client) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.sync,
                client: this.props.client,
                close: this.props.refreshFlowchart,
            }),
        );
    };

    private onRelAssetDisable = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.disableRelatingAssets,
                content: this.props.flowchart,
                clientId: this.props.client?.id,
                onDisabled: this.props.refreshFlowchart,
            }),
        );
    };

    public renderPageButtons = (): React.ReactElement<any> | null => {
        if (this.props.pageContent === PageContent.shareRequest) {
            return null;
        }
        return (
            <>
                {this.props.flowchart.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                <ContentPageButtons
                    pageContent={this.props.pageContent}
                    content={this.props.flowchart}
                    isSaveDisabled={!this.isChanged()}
                    myAccount={this.props.myAccount}
                    pageType={this.props.pageType}
                    onShareClick={this.onShareClick}
                    onAssignClick={this.onAssignClick}
                    onEditClick={this.props.onEditClick}
                    onSubmitClick={this.onSubmitClick}
                    onToggleClick={this.onToggleClick}
                    onDeleteClick={this.onDeleteClick}
                    onSyncClick={this.onSyncClick}
                    onRelAssetDisable={this.onRelAssetDisable}
                />
            </>
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartItemTitle(title));
        this.setState({ flowchart: { ...this.state.flowchart, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const description: string = event.currentTarget.value;
        const descriptionError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartDescription(description));
        this.setState({ flowchart: { ...this.state.flowchart, description }, errors: { ...this.state.errors, description: descriptionError } });
    };

    private onImageChange = (assets: AssetContent[]): void => {
        const image: AssetContent | null = assets.length > 0 ? assets[0] : null;
        this.setState({ flowchart: { ...this.state.flowchart, image } });
    };

    private onUsableInSortingGameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const usableInSortingGame: boolean = event.currentTarget.checked;
        this.setState({ flowchart: { ...this.state.flowchart, usableInSortingGame } });
    };

    private onFlowchartItemsChange = (branch: number) => (flowchartItems: FlowchartItemContent[]) => {
        const others = this.state.flowchart.itemList?.filter(i => i.branch !== branch) || [];
        const currentItems = this.state.flowchart.itemList?.filter(i => i.branch === branch) || [];
        const ids = currentItems.map(i => {
            return { relId: i.id, flowchartItemId: i.flowchartItem.id };
        });

        const itemList: FlowchartItemFlowchartRel[] = flowchartItems.map(
            (flowchartItem: FlowchartItemContent, position: number): FlowchartItemFlowchartRel => {
                const index = ids.findIndex(i => i.flowchartItemId === flowchartItem.id);
                let relId: string | undefined = undefined;
                if (index !== -1) {
                    relId = ids[index].relId;
                    ids.splice(index, 1);
                }
                return { id: relId!, flowchartItem, position, __typename: "FlowchartItemFlowchartRel", branch };
            },
        );
        this.setState({ flowchart: { ...this.state.flowchart, itemList: [...itemList, ...others] } });
    };

    private readonly onTagChange = (tags: string[]) => {
        this.setState({
            flowchart: { ...this.state.flowchart, tags },
        });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.flowchart, this.props.flowchart);
    };

    private shouldPreventRouteChange = (): boolean => {
        if (this.props.pageType !== PageType.view && !this.state.isPromptDisabled) {
            return this.isChanged();
        }
        return false;
    };

    private onToggled = (): void => {
        this.props.onToggled();
    };

    private getBranch = (branch: number): FlowchartItemContent[] => {
        const branchItems = this.state.flowchart.itemList?.filter(x => x.branch === branch) || [];
        const sorted = sortBy(branchItems || [], "position");
        return sorted.map(x => x.flowchartItem);
    };

    public renderContent(): React.ReactElement<any> {
        const { flowchart, errors, isValidationEnabled } = this.state;
        const disabled: boolean = this.props.pageType === PageType.view;
        const isEditEnabled: boolean = this.props.pageType === PageType.edit || ContentPageUtils.isEditEnabled(this.props.flowchart, this.props.myAccount, this.props.client);
        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.flowchart.data.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchart.data.name.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            value={flowchart.title}
                            placeholder={Intl.formatMessage({ id: "page.flowchart.data.name.placeholder" })}
                            onChange={this.onTitleChange}
                            disabled={disabled}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchart.data.description.label" })} errorMessage={isValidationEnabled ? errors.description : null}>
                        <TextArea
                            innerRef={(ref: HTMLTextAreaElement | null): void => {
                                this.descriptionRef = ref;
                            }}
                            value={flowchart.description || ""}
                            placeholder={Intl.formatMessage({ id: "page.flowchart.data.description.placeholder" })}
                            onChange={this.onDescriptionChange}
                            disabled={disabled}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchart.data.image.label" })}>
                        <AssetInput
                            droppableId="image"
                            value={flowchart.image ? [flowchart.image] : []}
                            onChange={this.onImageChange}
                            maxItemCount={1}
                            assetType={AssetType.image}
                            disabled={disabled}
                        />
                    </InputWrapper>
                </Section>
                <Section label={Intl.formatMessage({ id: "page.flowchart.usableInSortingGame.label" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchart.usableInSortingGame.label" })}>
                        <Checkbox checked={flowchart.usableInSortingGame} onChange={this.onUsableInSortingGameChange} disabled={disabled} />
                    </InputWrapper>
                </Section>

                <Section label={Intl.formatMessage({ id: "page.flowchart.items.title" })}>
                    <FlowchartItemInput
                        droppableId="flowchartItems"
                        value={this.getBranch(0)}
                        branchA={this.getBranch(1)}
                        branchB={this.getBranch(2)}
                        onChange={this.onFlowchartItemsChange(0)}
                        onBranchAChange={this.onFlowchartItemsChange(1)}
                        onBranchBChange={this.onFlowchartItemsChange(2)}
                        disabled={disabled}
                        onViewClick={(flowchartItem: FlowchartItemContent) => {
                            const path: string | undefined = ContentPageUtils.getViewPath(
                                flowchartItem,
                                this.props.pageContent,
                                this.props.clientExtId,
                                this.props.supporterExtId,
                                undefined,
                                this.props.supervisorId,
                            );
                            if (path) {
                                this.props.history.push(path);
                            }
                        }}
                        onEditClick={
                            isEditEnabled
                                ? (flowchartItem: FlowchartItemContent) => {
                                      const path: string | undefined = ContentPageUtils.getEditPath(flowchartItem, this.props.pageContent, this.props.clientExtId);
                                      if (path) {
                                          this.props.history.push(path);
                                      }
                                  }
                                : undefined
                        }
                    />
                </Section>

                <Section label={Intl.formatMessage({ id: "page.asset.tagSection.title" })}>
                    <TagSelect tags={this.props.tags} selectedTags={flowchart.tags} onChange={this.onTagChange} disabled={disabled} renderTags={true} />
                </Section>

                {this.props.pageType === PageType.view && this.state.flowchart.client && (
                    <Section label={Intl.formatMessage({ id: "component.contentDeviceList.title" })}>
                        <ContentDeviceList content={this.props.flowchart} />
                    </Section>
                )}

                {(this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest) && (
                    <LibraryContentShareRequestSection shareRequest={this.props.shareRequest} content={this.props.flowchart} />
                )}
                <Prompt when={this.shouldPreventRouteChange()} />
            </>
        );
    }

    private getDragIds(): string[] {
        const dragIds: string[] = [];
        if (this.state.flowchart.image) {
            dragIds.push(this.state.flowchart.image.id);
        }
        return dragIds;
    }

    public render(): React.ReactElement<any> {
        const hideSidebar: boolean = this.props.pageType === PageType.view;
        const title: string = ContentPageUtils.getContentPageTitleName(this.props);
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={(title ? title + " - " : "") + Intl.formatMessage({ id: `page.flowchart.title.${this.props.pageType}` })}
                renderButtons={this.renderPageButtons}
                hasSideBar={!hideSidebar}
            >
                <DragContainer
                    dragIds={this.getDragIds()}
                    hideSidebar={hideSidebar}
                    searchListTypes={[SearchListType.asset, SearchListType.flowchartItem]}
                    assetTypes={[AssetType.image]}
                    createdById={this.props.myAccount.id}
                    client={this.props.client}
                >
                    {(this.props.isLoading || this.state.isLoading) && <Loading type={LoadingType.layer} />}
                    <div className="left-side">{!this.props.isLoading && this.renderContent()}</div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags };
};

export const FlowchartForm = withRouter(connect(mapStateToProps)(FlowchartFormComponent));

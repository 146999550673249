import React from "react";
import { SearchListType, AssetType, AssetContent } from "api/graphql/types";
import { ResponderProvided, DragStart, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { withDragContext } from "../DragContext";
import { DragUtils, DragType } from "../DragUtils";
import { ContentInput, GenericComponentProps } from "./ContentInput";
import { AnyContentType, ApiTypes } from "api/ApiTypes";
import { Intl } from "i18n/Intl";
import { Slot } from "components/Slot/Slot";
import { Draggable } from "../Draggable";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface ComponentProps extends GenericComponentProps<AssetContent> {
    assetType?: AssetType;
    slotMessageOverride?: string;
}

class AssetInputComponent extends ContentInput<AssetContent, ComponentProps> {
    protected slotMessage = (): string => this.props.slotMessageOverride || Intl.formatMessage({ id: `sharedComponent.contentInput.asset.slotMessage.${this.props.assetType || "any"}` });
    protected slotDisabledMessage = (): string => Intl.formatMessage({ id: `sharedComponent.contentInput.no.${this.props.assetType || "any"}` });
    protected readonly searchListType: SearchListType = SearchListType.asset;

    protected isDropEnabled = (initial: DragStart, _provided: ResponderProvided, dragType: DragType | null): boolean => {
        if (((dragType && dragType.searchListType === this.searchListType) || initial.source.droppableId === this.props.droppableId) && !this.props.disabled) {
            if (
                initial.source.droppableId !== this.props.droppableId &&
                (this.isMaxCountReached() ||
                    (dragType && dragType.searchListType === SearchListType.asset && dragType.assetType && this.props.assetType && dragType.assetType !== this.props.assetType))
            ) {
                return false;
            }
            return true;
        }
        return false;
    };

    protected renderDraggable = (asset: AssetContent, index: number): React.ReactElement<any> => {
        return (
            <Draggable
                draggableId={`${this.props.droppableId}-asset-${index}-${asset.id}`}
                droppableId={this.props.droppableId}
                key={`asset-${index}-${asset.id}`}
                index={index}
                type={SearchListType.asset}
                isDragDisabled={this.isDragDisabled()}
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? "grabbed" : ""}
                        style={DragUtils.getStyle(provided.draggableProps.style, snapshot)}
                    >
                        <Slot
                            message=""
                            hasError={this.props.hasError}
                            url={ApiTypes.getAssetImageUrl(asset)}
                            name={asset.title}
                            disabled={this.props.disabled}
                            onCloseClick={() => this.onCloseClick(asset, index)}
                            overlayIcon={<OverlayIcon assetType={asset.assetType} />}
                            onPreviewClick={(): void => {
                                store.dispatch(
                                    DialogsActions.show({
                                        type: DialogType.showAsset,
                                        assetUrl: asset.url,
                                        assetType: asset?.assetType || AssetType.image,
                                        originalFileName: asset?.originalFileName,
                                        thumbnailUrl: asset?.thumbnailUrl,
                                        dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` }),
                                    }),
                                );
                            }}
                        />
                    </div>
                )}
            </Draggable>
        );
    };

    protected addClicked(content: AnyContentType, type: DragType) {
        if (type.searchListType === SearchListType.asset && (!this.props.assetType || this.props.assetType === type.assetType)) {
            return super.addClicked(content, type);
        }
        return false;
    }
}

export const AssetInput = withDragContext(AssetInputComponent);

// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function IndeterminateIcon({ width, height }: IconProps): React.ReactElement {
    return (
        <svg width={width} height={height} viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="3" rx="1" fill="#00459B" />
        </svg>
    );
}

export { IndeterminateIcon };

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import "./LinkGroup.scss";
import { DirectoryList } from "models/ContentLibraryDirectories";

export interface LinkGroupItem {
    label: string;
    toPage: string;
    isAsset: boolean;
}

interface Props {
    title?: string;
    links: LinkGroupItem[];
    isLinkActive: (link: LinkGroupItem) => boolean;
    isDirectoryActive?: (directory: DirectoryList) => boolean;
    isDirectoryHasActiveChild?: (directories: DirectoryList[]) => boolean;
    renderLink?: (item: LinkGroupItem) => React.ReactElement<any>;
    directories?: DirectoryList[];
    className?: string;
}

class LinkGroup extends PureComponent<Props> {
    private renderDirectories = (items: DirectoryList[], is_active?: boolean): React.ReactElement<any> => {
        return (
            <ul className={`subdir ${is_active || (this.props.isDirectoryHasActiveChild && this.props.isDirectoryHasActiveChild(items)) ? "open" : ""}`}>
                {items.map((item: DirectoryList, key) => {
                    return (
                        <li
                            key={key}
                            className={`${item.children ? "directory" : ""} ${
                                this.props.isDirectoryActive && this.props.isDirectoryActive(item)
                                    ? "active"
                                    : item.children && this.props.isDirectoryHasActiveChild && this.props.isDirectoryHasActiveChild(item.children)
                                    ? "open"
                                    : ""
                            }`}
                        >
                            <Link to={item.toPage ? item.toPage : ""} className={`${item.children ? "has-children" : ""}`}>
                                {item.name}
                            </Link>
                            {item.children ? this.renderDirectories(item.children, this.props.isDirectoryActive ? this.props.isDirectoryActive(item) : false) : null}
                        </li>
                    );
                })}
            </ul>
        );
    };
    private renderLink = (item: LinkGroupItem): React.ReactElement<any> => {
        return (
            <li key={item.label + item.toPage} className={`${this.props.isLinkActive(item) ? (item.isAsset ? "open" : "active") : ""} ${item.isAsset ? "directory" : ""}`}>
                <Link to={item.toPage} className={`${item.isAsset && this.props.directories ? "has-children" : ""}`}>
                    {item.label}
                </Link>
                {item.isAsset && this.props.directories ? this.renderDirectories(this.props.directories, this.props.isLinkActive(item)) : null}
            </li>
        );
    };
    public render(): React.ReactElement<any> {
        return (
            <div className={`link-group ${this.props.className || ""}`}>
                {this.props.title && <h3>{this.props.title}</h3>}
                <ul>{this.props.links.map(this.renderLink)}</ul>
            </div>
        );
    }
}

export { LinkGroup };

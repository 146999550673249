import React, { PureComponent } from "react";
import Cleave from "cleave.js/react";
import { SelectOption } from "../Select/Select";

import "../Inputs.scss";
import "./TimeSelect.scss";

export interface TimeSelectOption {
    id: string;
    label: string;
    value: string;
    disabled?: boolean;
}

interface Props {
    emptyLabel?: string;
    hasError?: boolean;
    disabled?: boolean;
    renderOption?: (item: TimeSelectOption, index?: number, items?: Array<TimeSelectOption>) => React.ReactElement;
    className?: string;
    onChange: (value: string) => void;
    value: string;
}

interface State {
    isOpened: boolean;
}

class TimeSelect extends PureComponent<Props, State> {
    private inputRef: HTMLInputElement | null = null;
    private containerRef: HTMLDivElement | null = null;

    private selectOptions: SelectOption[] = TimeSelect.getSelectOptions();

    private static getSelectOptions(): SelectOption[] {
        const times: string[] = [];
        // TODO: Refactor!
        for (let hour = 0; hour <= 23; hour++) {
            [":00", ":15", ":30", ":45"].forEach((minute: string) => {
                times.push(`${hour.toString().padStart(2, "0")}${minute.toString().padStart(2, "0")}`);
            });
        }

        return times.map(
            (time: string): SelectOption => {
                return { id: time, label: time, value: time };
            },
        );
    }

    /**
     * Get TimeSelectOption from list by value
     * @param list Select option list
     * @param value current value of selected select option
     */
    public static getTimeSelectOption(list: Array<TimeSelectOption>, value: string | null): TimeSelectOption {
        const current = list.find((option: TimeSelectOption): boolean => option.value === value);
        if (!current) {
            console.warn("current not found!", current, list);
        }
        return current || list[0];
    }

    public readonly state: State = {
        isOpened: false,
    };

    public scrollIntoView = (): void => {
        if (this.containerRef) {
            this.containerRef.scrollIntoView();
        }
    };

    public componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    private onClickOutside = (event: MouseEvent): void => {
        if (this.containerRef && !this.containerRef.contains(event.target as Node) && this.state.isOpened) {
            // this.props.onTextInputChange!(this.props.value?.label || "");
            if (this.props.value.length !== 5) {
                this.props.onChange(this.props.value.padEnd(5, "00:00"));
            }
            this.onOpenChange();
        }
    };

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    private onOptionSelected = (item: TimeSelectOption) => () => {
        this.props.onChange(item.value);
        this.onOpenChange();
    };

    private renderOption = (item: TimeSelectOption, index: number, items: Array<TimeSelectOption>): React.ReactElement<any> => {
        const isActive: boolean = item.value === this.props.value;
        const isDisabled: boolean = !!item.disabled;
        return (
            <div key={item.id} className={`option${isActive ? " active" : ""}${isDisabled ? " disabled" : ""}`} onClick={isDisabled ? undefined : this.onOptionSelected(item)} id={`option-${item.id}`}>
                {this.props.renderOption ? this.props.renderOption(item, index, items) : item.label}
            </div>
        );
    };

    private renderSelectedOption = (): React.ReactElement<any> => {
        if (this.props.value) {
            return <>{this.props.value}</>;
        }

        return <>{this.props.emptyLabel}</>;
    };

    public render(): React.ReactElement {
        if (this.props.disabled) {
            return (
                <div className={`select disabled ${this.props.className || ""}`}>
                    <div className="select-value">{this.renderSelectedOption()}</div>
                </div>
            );
        }

        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                className={`time-select-container${this.state.isOpened ? " opened" : ""} ${this.props.className || ""}`}
            >
                <div
                    className={`select-value${this.props.hasError ? " has-error" : ""}`}
                    onClick={() => {
                        this.setState({ isOpened: true }, () => {
                            this.inputRef?.focus();
                        });
                    }}
                >
                    <Cleave
                        htmlRef={(ref: HTMLInputElement | null) => {
                            this.inputRef = ref;
                        }}
                        disabled={this.props.disabled}
                        options={{
                            time: true,
                            timePattern: ["h", "m"],
                        }}
                        className="select-text-input"
                        value={this.props.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.props.onChange!(event.currentTarget.value);
                        }}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                            // enter pressed
                            if (event.keyCode === 13) {
                                this.inputRef?.blur();
                                if (this.props.value.length !== 5) {
                                    this.props.onChange(this.props.value.padEnd(5, "00:00"));
                                }
                                this.onOpenChange();
                            }
                        }}
                    />{" "}
                </div>
                <div className="options">{this.selectOptions.map(this.renderOption)}</div>
            </div>
        );
    }
}

export { TimeSelect };

import { ReduxAction } from "actions";
import { AuthActionType } from "actions/AuthActions";
import { TagsActionType } from "actions/TagsActions";
import { Tag } from "api/graphql/types";

export type TagsReducerState = Tag[];

export class TagsReducer {
    private static readonly initialState: TagsReducerState = [];

    public static instance(state: TagsReducerState = TagsReducer.initialState, action: ReduxAction): TagsReducerState {
        switch (action.type) {
            case TagsActionType.updateTags:
                return [...action.tags];
            case AuthActionType.logout:
                return [...TagsReducer.initialState];
            default:
                return state;
        }
    }
}

import React, { Component } from "react";
import { AppStatItem } from "api/graphql/types";
import { ClientEventLogValueConverter } from "../ClientEventLogTable/ClientEventLogValueConverter";

interface Props {
    eventLogRow: AppStatItem;
}

interface State {
    isOpened: boolean;
}

class ValueRowComponent extends Component<Props, State> {
    public readonly state: Readonly<State> = {
        isOpened: false,
    };

    private onOpen = (): void => {
        this.setState({ isOpened: !this.state.isOpened });
    };

    public render(): React.ReactElement<any> {
        return <>{ClientEventLogValueConverter.get(this.props.eventLogRow, this.state.isOpened, this.onOpen)}</>;
    }
}

export const ValueRow: React.ComponentClass<Props, State> = ValueRowComponent;

import React, { Component } from "react";
import { TestId } from "utils/TestId";
import { Page } from "components/Page";
import { SupporterList } from "./SupporterList";
import { Section } from "components/Section";
import { Button } from "components/Button/Button";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Alert } from "components/Alert/Alert";
import { Account, Asset, ClientProfileData, ClientSupporter, Device, SupportedClient } from "api/graphql/types";
import { Api } from "api/Api";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { AppPath, Path } from "utils/Path";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Intl } from "i18n/Intl";
import { Formatter } from "utils/Formatter";
import { ClientProfileAvatarForm } from "./ClientProfileAvatarForm";
import { AccountActions } from "actions/AccountActions";
import { ClientProfileNameForm } from "./ClientProfileNameForm";
import { ClientProfileDateOfBirthForm } from "./ClientProfileDateOfBirthForm";
import { DateUtils } from "utils/DateUtils";
import { ClientProfileIntroductionForm } from "./ClientProfileIntroductionForm";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";
import { DeviceList } from "./DeviceList";
import { cloneDeep } from "apollo-utilities";
import { NotificationType } from "components/NotificationBar/Notification";
import { ApplicationState } from "reducers/index";
import { Log } from "utils/Log";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { DefaultAgenda } from "./DefaultAgenda";
import { AccountStatus, ClientSupporterStatus } from "api/ApiTypes";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { CardNotification } from "../../components/CardNotification/CardNotification";
import * as GqlTypes from "../../api/graphql/types";

interface ReduxProps {
    accountId: string;
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams> & NotificationProp;

interface State {
    clientProfile: ClientProfileData | null;
    isLoading: boolean;
}

class ClientProfilePageComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    public readonly state: State = {
        clientProfile: null,
        isLoading: true,
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshClientProfile(this.props.client.id);
        } else {
            this.setState({ isLoading: false });
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.client && this.props.client && this.props.client.id !== nextProps.client.id) {
            this.refreshClientProfile(nextProps.client.id);
        }
    }

    private refreshClientProfile = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientProfile: ClientProfileData = await Api.getMyClientById(clientId);
                    this.setState({ clientProfile, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(AppPath.dashboard);
                        },
                    });
                }
            },
        );
    };

    private onRemoveSupporter = async (supporter: ClientSupporter): Promise<void> => {
        try {
            if (!this.state.clientProfile || (!supporter.clientShareRequest && !supporter.account)) {
                Log.warning("Remove supporter not available for", supporter.name);
                return;
            }
            if (supporter.clientShareRequest) {
                await Api.deleteClientShareRequest(supporter.clientShareRequest.id);
            } else if (supporter.account) {
                await Api.unlinkClientFromSupporter(this.state.clientProfile.id, supporter.account.id);
            }

            const newClientProfile: ClientProfileData = cloneDeep(this.state.clientProfile!);
            newClientProfile.supporters.count--;
            newClientProfile.supporters.result = newClientProfile.supporters.result.filter((s: ClientSupporter) => s.email !== supporter.email);
            this.setState({ clientProfile: newClientProfile });
            if (supporter.account && supporter.account.id === this.props.accountId) {
                const account: Account = await Api.me();
                this.props.dispatch(AccountActions.updateAccount(account));
                this.setState({ clientProfile: null });
                Alert.success({ title: Intl.formatMessage({ id: "page.clientProfile.removeSupporterSucceed" }) });
            } else {
                if (this.pageRef) {
                    this.pageRef.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.removeSupporterSucceed" }) });
                }
            }
        } catch (error) {
            if (this.pageRef) {
                this.pageRef.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
            }
        }
    };

    private onRemoveDevice = async (device: Device): Promise<void> => {
        try {
            await Api.deleteDevice(device.id);
            const newClientProfile: ClientProfileData = cloneDeep(this.state.clientProfile!);
            newClientProfile.devices.count--;
            newClientProfile.devices.result = newClientProfile.devices.result.filter((d: Device) => d.id !== device.id);
            this.setState({ clientProfile: newClientProfile });

            if (this.pageRef) {
                this.pageRef.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.removeDeviceSucceed" }) });
            }
        } catch (error) {
            if (this.pageRef) {
                this.pageRef.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
            }
        }
    };

    private onAvatarChange = (avatar: Asset | null): void => {
        this.setState({ clientProfile: { ...this.state.clientProfile!, avatar } });
        this.props.dispatch(AccountActions.updateClient({ ...this.state.clientProfile!, avatar }));
    };

    private onUpdateName = (clientProfile: ClientProfileData): void => {
        this.setState({ clientProfile });
        this.props.dispatch(AccountActions.updateClient(clientProfile));
    };

    private onClientChange = (clientProfile: ClientProfileData): void => {
        this.setState({ clientProfile });
    };

    private deleteProfile = async (): Promise<void> => {
        const { clientProfile } = this.state;

        if (!clientProfile) {
            return;
        }

        try {
            const clientId: string = clientProfile.id;
            await Api.deleteClient(clientId);
            this.props.dispatch(AccountActions.deleteClient(clientId));
            this.setState({ clientProfile: null });
            Alert.success({ title: Intl.formatMessage({ id: "page.clientProfile.accountData.delete.deleteSucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onDeleteProfileClick = (): void => {
        if (!this.state.clientProfile) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteClientProfile,
                profileName: this.state.clientProfile.name,
                onDelete: this.deleteProfile,
            }),
        );
    };

    private onAddSupporterSucceed = async (): Promise<void> => {
        if (this.pageRef) {
            this.pageRef.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.addSupporterSucceed" }) });
        }
        try {
            const clientProfile: ClientProfileData = await Api.getMyClientById(this.props.client!.id);
            this.setState({ clientProfile, isLoading: false });
        } catch (error) {
            Alert.error({
                title: IntlHelpers.getMessageFromError(error),
                callback: () => {
                    this.props.history.replace(AppPath.dashboard);
                },
            });
        }
    };

    private onSetClientManagerClick = (): React.ReactElement<any> | void => {
        const { clientProfile } = this.state;
        if (!clientProfile) {
            return <Redirect to={AppPath.dashboard} />;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.changeClientManager,
                client: clientProfile,
                supporters: this.getChangeClientManagerSupporters(),
                onChangeClientManagerFinished: () => this.refreshClientProfile(clientProfile.id),
            }),
        );
    };

    private getChangeClientManagerSupporters = (): ClientSupporter[] => {
        return this.state.clientProfile!.supporters.result.reduce((prevState: ClientSupporter[], currentSupporter: ClientSupporter): ClientSupporter[] => {
            if (currentSupporter.account && currentSupporter.status === ClientSupporterStatus.active && currentSupporter.name && currentSupporter.account.id !== this.props.accountId) {
                prevState.push(currentSupporter);
            }
            return prevState;
        }, []);
    };

    private onPendingClientManagerChangeClick = (): void => {
        if (!this.state.clientProfile) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteChangeClientManager,
                client: this.state.clientProfile!,
                onDeleteChangeClientManagerFinished: () => this.refreshClientProfile(this.state.clientProfile!.id),
            }),
        );
    };

    private onQuestionnaireClick = (): void => {
        const { clientProfile } = this.state;
        if (clientProfile) {
            const url = Path.clientQuestionnaire(clientProfile.extId);
            this.props.history.push(url);
        }
    };

    private onIntroductionAudioUploaded = (introductionAudio: Asset | null): void => {
        this.setState({ clientProfile: { ...this.state.clientProfile!, introductionAudio } });
    };

    private onIntroductionAudioDeleted = (): void => {
        this.setState({ clientProfile: { ...this.state.clientProfile!, introductionAudio: null } });
    };

    private onIntroductionVideoUploaded = (introductionVideo: Asset | null): void => {
        this.setState({ clientProfile: { ...this.state.clientProfile!, introductionVideo } });
    };

    private onIntroductionVideoDeleted = (): void => {
        this.setState({ clientProfile: { ...this.state.clientProfile!, introductionVideo: null } });
    };

    private onSelfSupportConfirm = async (success: boolean) => {
        const { clientProfile } = this.state;
        if (success && clientProfile) {
            const appData: GqlTypes.appData = await Api.getAppData();
            this.props.dispatch(AccountActions.updateAccount(appData.me));
            this.refreshClientProfile(clientProfile.id);
        }
    };

    private readonly onSelfSupportButtonClick = (isSelfSupporting: boolean): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.selfSupporting,
                isSelfSupporting: isSelfSupporting,
                clientProfile: this.state.clientProfile,
                onConfirmed: this.onSelfSupportConfirm,
            }),
        );
    };

    private readonly renderClientTodos = (clientProfile: ClientProfileData): React.ReactElement<any> | null => {
        return (
            <>
                {clientProfile.showSelfSupportingStateDecisionWarning ? (
                    <CardNotification
                        title={Intl.formatMessage({ id: "page.clientProfile.selfSupporting.card.title" }, { name: clientProfile.name })}
                        description={Intl.formatMessage({ id: "page.clientProfile.selfSupporting.card.description" }, { name: clientProfile.name })}
                        type="error"
                        renderButton={
                            <>
                                <hr />
                                <div className="grid-x">
                                    <button className="btn btn-outline" onClick={() => this.onSelfSupportButtonClick(false)}>
                                        {Intl.formatMessage({ id: "page.clientProfile.selfSupporting.buttons.no" })}
                                    </button>
                                    <button className="btn btn-primary" onClick={() => this.onSelfSupportButtonClick(true)}>
                                        {Intl.formatMessage({ id: "page.clientProfile.selfSupporting.buttons.yes" })}
                                    </button>
                                </div>
                            </>
                        }
                    />
                ) : (
                    clientProfile.showSelfSupportingStateDecisionNotification && (
                        <CardNotification
                            title={`${Intl.formatMessage({ id: "page.clientProfile.notifications.selfSupportingWarning.title" }, { name: clientProfile.name })}`}
                            description={Intl.formatMessage({ id: "page.clientProfile.notifications.selfSupportingWarning.description" }, { name: clientProfile.name })}
                            type={"warning"}
                        />
                    )
                )}
                {clientProfile.showClientQuestionnaireEvaluationNotification && (
                    <CardNotification
                        title={Intl.formatMessage({ id: "page.clientProfile.notifications.clientQuestionnaireEvaluation.title" }, { name: clientProfile.name })}
                        description={Intl.formatMessage({ id: "page.clientProfile.notifications.clientQuestionnaireEvaluation.description" }, { name: clientProfile.name })}
                        button={Intl.formatMessage({ id: "page.clientProfile.notifications.clientQuestionnaireEvaluation.button" })}
                        onButtonClick={this.onQuestionnaireClick}
                        type={"warning"}
                    />
                )}
            </>
        );
    };

    public render(): React.ReactElement<any> {
        const { client } = this.props;
        if (!this.props.match.params.clientExtId || !client) {
            return <Redirect to={AppPath.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const { clientProfile } = this.state;
        if (!clientProfile) {
            return <Redirect to={AppPath.dashboard} />;
        }

        const isMeClientManager = this.props.accountId === clientProfile.managedBy.id;
        return (
            <Page
                ref={(ref: Page | null) => {
                    this.pageRef = ref;
                }}
                id={TestId.clientProfile.container}
                title={Intl.formatMessage({ id: "page.clientProfile.title" }, { name: clientProfile.name })}
            >
                <div className="left-side">
                    <div className="row">
                        <div className="col-md-9">
                            {this.renderClientTodos(clientProfile)}

                            <Section label={Intl.formatMessage({ id: "page.clientProfile.personalData.title" })}>
                                <ClientProfileNameForm clientId={clientProfile.id!} name={clientProfile.name || ""} onNameChange={this.onUpdateName} disabled={!isMeClientManager} />
                                <ClientProfileDateOfBirthForm
                                    clientId={clientProfile.id}
                                    dateOfBirth={DateUtils.parse(clientProfile.dateOfBirth)}
                                    onDateOfBirthChange={this.onClientChange}
                                    disabled={!isMeClientManager}
                                />
                                <div className="input-wrapper grid-x">
                                    <span className="input-label cell">{Intl.formatMessage({ id: "page.clientProfile.personalData.extId" })}</span>
                                    <span className="cell auto">
                                        <strong className="value-cell">{Formatter.formatExtId(clientProfile.extId!)}</strong>
                                    </span>
                                    <span className="cell button-cell">
                                        <CopyToClipboardButton
                                            copiedTooltipMessage={Intl.formatMessage({ id: "page.clientProfile.personalData.copied" })}
                                            id={TestId.clientProfile.copyButton}
                                            textToCopy={clientProfile.extId}
                                        />
                                    </span>
                                </div>
                                <div className="input-wrapper grid-x">
                                    <span className="input-label cell">{Intl.formatMessage({ id: "page.clientProfile.personalData.isSelfSupporting" })}</span>
                                    <span className="cell auto">
                                        <strong className="value-cell">{Intl.formatMessage({ id: `common.${clientProfile?.isSelfSupporting ? "yes" : "no"}` })}</strong>
                                    </span>
                                </div>
                            </Section>

                            <Section label={Intl.formatMessage({ id: "page.clientProfile.introductionSection.title" })}>
                                <ClientProfileIntroductionForm
                                    clientId={clientProfile.id!}
                                    introduction={clientProfile.introduction || ""}
                                    introductionAudio={clientProfile.introductionAudio}
                                    introductionVideo={clientProfile.introductionVideo}
                                    onIntroductionChange={this.onClientChange}
                                    onIntroductionAudioUploaded={this.onIntroductionAudioUploaded}
                                    onIntroductionAudioDeleted={this.onIntroductionAudioDeleted}
                                    onIntroductionVideoUploaded={this.onIntroductionVideoUploaded}
                                    onIntroductionVideoDeleted={this.onIntroductionVideoDeleted}
                                />
                            </Section>

                            <Section
                                label={
                                    <div className="grid-x align-middle">
                                        <span className="cell medium-6">{Intl.formatMessage({ id: "page.clientProfile.deviceList.title" })}</span>
                                        {clientProfile.devices.result.length > 0 && (
                                            <span className="cell medium-6 text-right">
                                                <Button
                                                    hollow
                                                    className="small no-margin"
                                                    icon={{ name: "fa-sync-alt", large: false, position: "left" }}
                                                    label={Intl.formatMessage({ id: "page.clientProfile.deviceList.sync" })}
                                                    onClick={async (): Promise<void> => {
                                                        try {
                                                            await Api.syncClientAgendas(clientProfile.id);
                                                            if (this.pageRef) {
                                                                this.pageRef.showNotification({
                                                                    message: Intl.formatMessage({ id: "page.clientProfile.deviceList.syncSucceed" }),
                                                                    type: NotificationType.success,
                                                                });
                                                            }
                                                        } catch (error) {
                                                            if (error.code === ApiErrorCode.DISABLED_ENTITY_IN_SYNC_DATA) {
                                                                this.props.dispatch(
                                                                    DialogsActions.show({
                                                                        type: DialogType.syncWarning,
                                                                        client,
                                                                    }),
                                                                );
                                                            } else {
                                                                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                }
                            >
                                <DeviceList lastSyncDataGeneratedAt={new Date(clientProfile.lastSyncDataGeneratedAt)} data={clientProfile.devices.result} onRemove={this.onRemoveDevice} />
                            </Section>

                            <Section label={Intl.formatMessage({ id: "page.clientProfile.defaultAgenda.title" })}>
                                <p className="lead">{Intl.formatMessage({ id: "page.clientProfile.defaultAgenda.hint" })}</p>
                                <DefaultAgenda agenda={clientProfile.defaultAgenda} />
                            </Section>

                            <Section
                                label={
                                    <div className="grid-x align-middle">
                                        <span className="cell medium-4">{Intl.formatMessage({ id: "page.clientProfile.supporterList.title" })}</span>
                                        {clientProfile.managedBy.id === this.props.accountId && (
                                            <span className="cell medium-8 text-right">
                                                {clientProfile.pendingClientManagerChangeRequest !== null ? (
                                                    <Button
                                                        hollow
                                                        className="small btn-primary no-margin"
                                                        label={Intl.formatMessage({ id: "page.clientProfile.supporterList.deleteClientChangeManagerRequest.delete" })}
                                                        onClick={this.onPendingClientManagerChangeClick}
                                                    />
                                                ) : (
                                                    <Button
                                                        hollow
                                                        className="small btn-primary no-margin"
                                                        label={Intl.formatMessage({ id: "page.clientProfile.supporterList.addClientChangeManagerRequest.add" })}
                                                        disabled={
                                                            clientProfile.supporters.result.filter((supporter: ClientSupporter): boolean => supporter.status === AccountStatus.active).length === 1
                                                        }
                                                        onClick={this.onSetClientManagerClick}
                                                    />
                                                )}
                                                <span className="mr-20" />
                                                <Button
                                                    className="small btn-primary no-margin"
                                                    label={Intl.formatMessage({ id: "page.clientProfile.supporterList.addButton" })}
                                                    onClick={(): void => {
                                                        this.props.dispatch(
                                                            DialogsActions.show({
                                                                type: DialogType.addSupporter,
                                                                clientId: this.props.client!.id,
                                                                onSucceed: this.onAddSupporterSucceed,
                                                            }),
                                                        );
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                }
                            >
                                <SupporterList
                                    pendingClientManagerChangeRequest={clientProfile.pendingClientManagerChangeRequest}
                                    data={clientProfile.supporters.result}
                                    managedBySupporterId={clientProfile.managedBy.id}
                                    onRemove={this.onRemoveSupporter}
                                    accountId={this.props.accountId}
                                />
                            </Section>

                            <Section label={Intl.formatMessage({ id: "page.clientProfile.questionnaireSection.title" })}>
                                <p className="lead">{clientProfile.notificationOnProfilePage.replace("[CLIENT_NAME]", clientProfile.name)}</p>
                                {isMeClientManager && (
                                    <Button
                                        label={Intl.formatMessage({ id: "page.clientProfile.questionnaireSection.button" })}
                                        ariaLabel={Intl.formatMessage({ id: "page.clientProfile.questionnaireSection.button" })}
                                        onClick={this.onQuestionnaireClick}
                                    />
                                )}
                            </Section>

                            {clientProfile.managedBy.id === this.props.accountId && (
                                <Section label="">
                                    <div className="input-wrapper grid-x">
                                        <span className="input-label cell">{Intl.formatMessage({ id: "page.clientProfile.accountData.delete.label" })}</span>
                                        <span className="cell auto">
                                            <strong className="value-cell">&nbsp;</strong>
                                        </span>
                                        <span className="cell button-cell">
                                            <Button
                                                className="btn-danger btn--text-large fw-700"
                                                id={TestId.clientProfile.deleteProfileButton}
                                                label={Intl.formatMessage({ id: "common.delete" })}
                                                ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                                onClick={this.onDeleteProfileClick}
                                            />
                                        </span>
                                    </div>
                                </Section>
                            )}
                        </div>
                        <div className="col-md-3 mt-20">
                            <Section label={Intl.formatMessage({ id: "page.clientProfile.avatar.title" })} noTopMargin>
                                <ClientProfileAvatarForm clientId={clientProfile.id!} avatar={client.avatar} onAvatarChange={this.onAvatarChange} />
                            </Section>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return {
        accountId: state.account!.id,
        client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId),
    };
};

export const ClientProfilePage = withNotification(withRouter(connect(mapStateToProps)(ClientProfilePageComponent)));

// tslint:disable-next-line: no-default-export
export default ClientProfilePage;

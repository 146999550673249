import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Foldable.scss";

interface Props {
    ariaId: string;
    disabled?: boolean;
    isOpened: boolean;
}

export const FoldableFC: React.FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => {
    const [height, setHeight] = useState(0);
    const [folding, setFolding] = useState(false);
    const foldableRef = useRef<HTMLDivElement>(null);
    const foldingTimeout = useRef<number | null>(null);

    const calculateHeight = () => {
        const calculatedHeight = (foldableRef.current && foldableRef.current.scrollHeight) || 0;
        setHeight(calculatedHeight);
    };

    const handleOpenChange = useCallback(() => {
        setFolding(true);

        foldingTimeout.current = window.setTimeout(() => {
            foldingTimeout.current = null;
            setFolding(false);
        }, 250);
    }, []);

    useEffect(() => {
        calculateHeight();
        handleOpenChange();
        return () => {
            if (foldingTimeout.current !== null) {
                clearTimeout(foldingTimeout.current);
            }
        };
    }, [props.isOpened, handleOpenChange]);

    const { ariaId, children, disabled, isOpened, ...otherProps } = props;

    return (
        <div id={`${ariaId}-Group`} className="foldable-presentation" role="presentation" {...otherProps}>
            <div id={`${ariaId}-Region`} className={`foldable-region${disabled ? " foldable-region--is-disabled" : ""}`} role="region" aria-hidden={!isOpened} aria-labelledby={`${ariaId}-Heading`}>
                <div
                    className="foldable-element"
                    style={{
                        height: isOpened ? height : 0,
                        overflow: "hidden",
                    }}
                >
                    <div ref={foldableRef} style={!folding && isOpened ? { overflow: "visible" } : {}}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { Component } from "react";
import { Section } from "components/Section";
import {
    Client,
    EverydaySituationClientDirectory,
    AssetType,
    AssetContent,
    ImageEverydaySituation,
    TextEverydaySituation,
    AudioEverydaySituation,
    EverydaySituation,
    EverydaySituationClientDirectory_image,
} from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Redirect } from "react-router-dom";
import { Path } from "utils/Path";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { ApiTypes } from "api/ApiTypes";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { Input } from "components/Inputs/Input/Input";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { isEqual, isNil } from "lodash";
import { Validator } from "utils/Validator";
import { Prompt } from "components/Prompt";
import { EverydaySituationListItem } from "./EverydaySituationListItem";
import { EverydaySituationTypeOptions } from "models/EverydaySituationTypeOptions";
import { Select } from "components/Inputs/Select/Select";
import { connect, DispatchProp } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { ImageSrc } from "utils/ImageSrc";
import { DirectoryImage } from "./DirectoryImage";
import { NotificationType } from "components/NotificationBar/Notification";
import { store } from "store";

interface ComponentProps {
    client: Client;
    setDragIds: (dragIds: string[]) => void;
}

type Props = ComponentProps & NotificationProp & DispatchProp;

interface State {
    isLoading: boolean;
    everydaySituation: EverydaySituationClientDirectory | null;
    currentEverydaySituation: EverydaySituationClientDirectory | null;
    fallbackImage: EverydaySituationClientDirectory_image | null;
    isValidationEnabled: boolean;

    errors: {
        title: string | null;
    };
}

class ClientEverydaySituationsClientDirectoryComponent extends Component<Props, State> {
    private titleRef: HTMLInputElement | null = null;

    private static FALLBACK_IMAGE_BACKEND_URL = "asset/situation/EverydaySituationsClientDirectoryIcon.png";

    public readonly state: State = {
        isLoading: true,
        everydaySituation: null,
        currentEverydaySituation: null,
        isValidationEnabled: false,
        fallbackImage: null,

        errors: {
            title: null,
        },
    };

    public componentDidMount(): void {
        this.refreshEverydaySituationClientDirectory();
    }

    private isImageFallback = (image: EverydaySituationClientDirectory_image | null): boolean => {
        const defaultImageId = store.getState().settings.clientEverydaySituationDirectoryDefaultImageId;
        if (!image) {
            return false;
        }
        if (defaultImageId && image.id === defaultImageId) {
            return true;
        }
        return image.url && image.url.endsWith(ClientEverydaySituationsClientDirectoryComponent.FALLBACK_IMAGE_BACKEND_URL);
    };

    private readonly refreshEverydaySituationClientDirectory = async (): Promise<void> => {
        try {
            const everydaySituation = await Api.getEverydaySituationClientDirectoryByClientId(this.props.client.id);
            if (everydaySituation.image) {
                this.props.setDragIds([everydaySituation.image.id]);
            }
            this.setState({ everydaySituation, currentEverydaySituation: everydaySituation, isLoading: false, isValidationEnabled: false, errors: { title: null } });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    };

    private readonly onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateEverydaySituationClientDirectoryTitle(title));
        this.setState({ currentEverydaySituation: { ...this.state.currentEverydaySituation!, title: event.currentTarget.value }, errors: { ...this.state.errors, title: titleError } });
    };

    private readonly onImageChange = (assets: AssetContent[]): void => {
        const image = assets[0] || null;
        const dragIds: string[] = [];
        if (image) {
            dragIds.push(image.id);
        }
        this.props.setDragIds(dragIds);
        this.setState({ currentEverydaySituation: { ...this.state.currentEverydaySituation!, image } });
    };

    private readonly onSaveClick = async (): Promise<void> => {
        if (this.state.errors.title) {
            if (this.state.errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            this.setState({ isValidationEnabled: true });
            return;
        }

        const input = ApiTypes.everydaySituationClientDirectoryToUpdateEverydaySituationClientDirectoryInput(this.state.currentEverydaySituation!);
        try {
            const everydaySituation = await Api.updateEverydaySituationClientDirectory({
                ...input,
                imageId: input.imageId || store.getState().settings.clientEverydaySituationDirectoryDefaultImageId,
            });
            this.setState({ everydaySituation, currentEverydaySituation: { ...everydaySituation }, isValidationEnabled: false });
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.saveSucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    };
    private readonly onCancelClick = (): void => {
        this.refreshEverydaySituationClientDirectory();
    };

    private readonly isChanged = (): boolean => {
        return !isEqual(this.state.everydaySituation, this.state.currentEverydaySituation);
    };

    private readonly onIsVisibleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ currentEverydaySituation: { ...this.state.currentEverydaySituation!, isVisible: event.currentTarget.checked }, errors: { title: null } });
    };

    private readonly onWritableChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ currentEverydaySituation: { ...this.state.currentEverydaySituation!, isWritable: event.currentTarget.checked } });
    };

    private readonly onDeleteSituationClick = (situation: EverydaySituation) => async () => {
        try {
            await Api.deleteEverydaySituation(situation.id);
            this.refreshEverydaySituationClientDirectory();
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private customPlaceholder = (): React.ReactElement => {
        return <DirectoryImage fallback={ImageSrc.everydaySituationsClientDirectoryIcon} disableOverlay={true} disableHover={true} />;
    };

    public render(): React.ReactElement {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const { currentEverydaySituation, everydaySituation, errors, isValidationEnabled } = this.state;
        if (!currentEverydaySituation || !everydaySituation) {
            return <Redirect to={Path.dashboard} />;
        }
        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);

        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.title" }, { name: clientShortName })}>
                    <p>{Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.visibility.description" }, { name: clientShortName })}</p>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.visibility.label" })}>
                        <Checkbox checked={currentEverydaySituation.isVisible} onChange={this.onIsVisibleChange} />
                    </InputWrapper>

                    <hr />

                    <div style={currentEverydaySituation.isVisible ? {} : { opacity: 0.4 }}>
                        <p>{Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.writability.description" }, { name: clientShortName })}</p>

                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.writability.label" })}>
                            <Checkbox checked={currentEverydaySituation.isWritable} onChange={this.onWritableChange} disabled={!currentEverydaySituation.isVisible} />
                        </InputWrapper>

                        <div className="mt-30">
                            <div>
                                <InputWrapper
                                    inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.title.label" })}
                                    errorMessage={isValidationEnabled ? errors.title : null}
                                >
                                    <Input
                                        innerRef={(ref: HTMLInputElement | null) => {
                                            this.titleRef = ref;
                                        }}
                                        type="text"
                                        value={currentEverydaySituation.title}
                                        onChange={this.onTitleChange}
                                        disabled={!currentEverydaySituation.isVisible}
                                        hasError={isValidationEnabled && !!errors.title}
                                        placeholder={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.title.placeholder" })}
                                    />
                                </InputWrapper>
                                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.image.label" })}>
                                    <AssetInput
                                        droppableId="image"
                                        assetType={AssetType.image}
                                        value={this.isImageFallback(currentEverydaySituation?.image) || isNil(currentEverydaySituation?.image) ? [] : [currentEverydaySituation.image as AssetContent]}
                                        onChange={this.onImageChange}
                                        maxItemCount={1}
                                        minItemCount={1}
                                        disabled={!currentEverydaySituation.isVisible}
                                        customPlaceholder={this.customPlaceholder()}
                                    />
                                </InputWrapper>

                                <InputWrapper className="mb-0" inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.items.label" })} />

                                {currentEverydaySituation.situations.map(situation => {
                                    const formValues = {
                                        id: situation.id,
                                        title: situation.title,
                                        type: Select.getSelectOption(EverydaySituationTypeOptions.get(), ApiTypes.getEverydaySituationType(situation)),
                                        image: ((situation as ImageEverydaySituation).image as AssetContent) || null,
                                        description: (situation as TextEverydaySituation).description || undefined,
                                        audio: ((situation as AudioEverydaySituation).audio as AssetContent) || null,
                                    };
                                    return (
                                        <EverydaySituationListItem
                                            key={situation.id}
                                            isEditable={false}
                                            formValues={formValues}
                                            onCopyClick={() => {
                                                this.props.dispatch(
                                                    DialogsActions.show({
                                                        type: DialogType.copyEverydaySituation,
                                                        client: this.props.client,
                                                        onCopySucceed: this.refreshEverydaySituationClientDirectory,
                                                        everydaySituation: situation,
                                                    }),
                                                );
                                            }}
                                            updateFormValues={() => {}}
                                            onDeleteClick={this.onDeleteSituationClick(situation)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Section>

                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>

                <Prompt when={this.isChanged()} />
            </>
        );
    }
}

export const ClientEverydaySituationsClientDirectory: React.ComponentClass<ComponentProps> = withNotification(connect()(ClientEverydaySituationsClientDirectoryComponent));

import React, { Component } from "react";
import { Section } from "components/Section";
import {
    Timer,
    Checklist,
    AgendaItemAlert,
    SearchListType,
    FlowchartContent,
    SupportedClient,
    MoodMeterDisplayConfig,
    AgendaItemType,
    AssetType,
    AgendaItemContent,
    AgendaItem_agendaItems,
    AgendaItemAgendaItemRel,
    Account,
    Agenda,
    AccountType,
    AssetContent,
    Notification,
    ItemAwardInput,
    Tag,
    AgendaItem_instantAwards,
    ItemAwardType,
    LibraryContentShareRequestWithData,
    SelectionBoard,
    AgendaItem,
} from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { TestId } from "utils/TestId";
import { Loading, LoadingType } from "components/Loading/Loading";
import { ChecklistInput, ChecklistValidationError } from "pages/_shared/Inputs/ChecklistInput";
import { AwardInput, AwardValidationError } from "pages/_shared/Inputs/AwardInput";
import { TimerInput, TimerValidationError } from "pages/_shared/Inputs/TimerInput";
import { TimeInput, TimeValidationError } from "pages/_shared/Inputs/TimeInput";
import { AgendaItemAlertInput, AlertValidationError } from "pages/_shared/Inputs/AgendaItemAlertInput";
import { Page } from "components/Page";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NotificationType } from "components/NotificationBar/Notification";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { PageType } from "utils/TypeUtils";
import { cloneDeep } from "apollo-utilities";
import isEqual from "lodash/isEqual";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Prompt } from "components/Prompt";
import { FlowchartRelInput } from "pages/_shared/Inputs/FlowchartRelInput";
import { MoodMeterDisplayConfigInput } from "pages/_shared/Inputs/MoodMeterDisplayConfigInput";
import { ContentPageButtons } from "pages/_shared/ContentPageButtons/ContentPageButtons";
import { Select } from "components/Inputs/Select/Select";
import { AgendaItemTypeOptions, AgendaItemTypeOption } from "./AgendaItemTypeOptions";
import { AgendaItemInput } from "pages/_shared/Draggables/Input/AgendaItemInput";
import { DragUtils } from "pages/_shared/Draggables/DragUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { ClientAdditionalFunctions } from "api/ApiTypes";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { MultipleAssetInputWrapper } from "components/MultipleAssetInputWrapper/MultipleAssetInputWrapper";
import { NotificationInput } from "../_shared/Inputs/NotificationInput";
import { isNil } from "lodash";
import { ApplicationState } from "reducers/index";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import { LibraryContentShareRequestSection } from "pages/_shared/LibraryShareRequestSection/LibraryContentShareRequestSection";
import { SelectionBoardInput } from "../_shared/Inputs/SelectionBoardInput";

interface ReduxProps {
    tags: Tag[];
}

interface ComponentProps {
    myAccount: Account;
    isLoading: boolean;
    pageType: PageType;
    agendaItem: AgendaItem;
    agenda: Agenda | null;
    clientAdditionalFunctions: ClientAdditionalFunctions | null;
    onSubmit: (agendaItem: AgendaItem, agendaId?: string) => Promise<AgendaItem>;
    onEditClick: () => void;
    onToggled: () => void;
    disabled?: boolean;
    client?: SupportedClient | null;
    pageContent: PageContent;
    clientExtId?: string;
    supporterExtId?: string;
    supervisorId?: string;
    accountType: AccountType;
    refreshAgendaItem: () => void;

    shareRequest?: LibraryContentShareRequestWithData;
}

type Props = ReduxProps & ComponentProps & RouteComponentProps & DispatchProp;

interface Errors {
    title: string | null;
    alertTypeWithTime: TimeValidationError | null;
    alert: AlertValidationError | null;
    timer: TimerValidationError | null;
    award: AwardValidationError | null;
    notification: string | null;
    instantAwards: string | null;
    checklist: ChecklistValidationError | null;
    agendaA: string | null;
    agendaB: string | null;
}

interface State {
    isLoading: boolean;
    agendaItem: AgendaItem;
    errors: Errors;
    isFlowchartRelEnabled: boolean;
    isValidationEnabled: boolean;
    isPromptDisabled: boolean;
}

class AgendaItemFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;
    private alertRef: AgendaItemAlertInput | null = null;
    private notificationRef: NotificationInput | null = null;
    private awardRef: AwardInput | null = null;
    private timerRef: TimerInput | null = null;
    private checklistRef: ChecklistInput | null = null;
    private timeRef: TimeInput | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            isLoading: false,
            agendaItem: cloneDeep(props.agendaItem),
            errors: {
                title: null,
                alertTypeWithTime: null,
                alert: null,
                timer: null,
                award: null,
                notification: null,
                instantAwards: null,
                checklist: null,
                agendaA: null,
                agendaB: null,
            },
            isFlowchartRelEnabled: !!props.agendaItem.flowchart,
            isValidationEnabled: false,
            isPromptDisabled: false,
        };
    }

    public readonly state: State = AgendaItemFormComponent.getInitialStateFromProps(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.agendaItem !== nextProps.agendaItem) {
            this.setState(AgendaItemFormComponent.getInitialStateFromProps(nextProps));
        }
    }

    private isThereError = (errors: Errors) => {
        return (
            errors.title !== null ||
            (errors.alert !== null && !isNil(errors.alert.title)) ||
            (errors.timer !== null && errors.timer.seconds !== null) ||
            (errors.award !== null && errors.award.score !== null) ||
            errors.notification !== null ||
            (errors.checklist && errors.checklist.items && errors.checklist.items.some((i: string | null) => i !== null))
        );
    };

    private shouldBadConfigAlertOpen = (): void => {
        if (!this.props.clientAdditionalFunctions) {
            return;
        }

        const badConfigItems: string[] = [];

        if (!this.props.agendaItem.moodMeter && this.state.agendaItem.moodMeter && !this.props.clientAdditionalFunctions.isMoodMeterActive) {
            badConfigItems.push(Intl.formatMessage({ id: "page.agendaItem.badConfigInput.moodMeter" }));
        }

        if (badConfigItems.length > 0) {
            Alert.info({ title: Intl.formatMessage({ id: "page.agendaItem.badConfigInputAlert" }, { badConfigItems: badConfigItems.join(", ") }) });
        }
    };

    private onSubmitClick = (): void => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaItemTitle(this.state.agendaItem.title));
        const alertTypeWithTimeError: string | null = this.state.agendaItem.alert
            ? IntlHelpers.getValidationError(Validator.validateAlertTypeWithTime(this.state.agendaItem.alert.display, this.state.agendaItem.time))
            : null;

        const agendaA: string | null =
            this.state.agendaItem.type === AgendaItemType.Select
                ? IntlHelpers.getValidationError(Validator.validateNonNull(this.state.agendaItem.agendaItems ? this.getAgendaItemByPosition(1)[0] || null : null))
                : null;

        const agendaB: string | null =
            this.state.agendaItem.type === AgendaItemType.Select
                ? IntlHelpers.getValidationError(Validator.validateNonNull(this.state.agendaItem.agendaItems ? this.getAgendaItemByPosition(2)[0] || null : null))
                : null;

        const instantAwards: string | null =
            this.state.agendaItem.award?.type === ItemAwardType.instant && !isNil(this.state.agendaItem.instantAwards)
                ? IntlHelpers.getValidationError(Validator.validateInstantAwardsNumber(this.state.agendaItem.instantAwards.length))
                : null;

        if (titleError || alertTypeWithTimeError || this.isThereError(errors) || agendaA || agendaB || (errors.timer && errors.timer.seconds) || instantAwards) {
            this.setState({
                isValidationEnabled: true,
                errors: {
                    ...this.state.errors,
                    title: titleError,
                    alertTypeWithTime: {
                        ...this.state.errors.alertTypeWithTime,
                        enabled: alertTypeWithTimeError,
                    },
                    agendaA,
                    agendaB,
                    instantAwards,
                },
            });

            if (titleError && this.titleRef) {
                this.titleRef.focus();
            } else if (errors.checklist && this.checklistRef) {
                this.checklistRef.focus();
            } else if (errors.alert && errors.alert.title && this.alertRef) {
                this.alertRef.focus();
            } else if (errors.notification && this.notificationRef) {
                this.notificationRef.focus();
            } else if (((errors.award && errors.award.score) || errors.instantAwards) && this.awardRef) {
                this.awardRef.focus();
            } else if (errors.timer && errors.timer.seconds && this.timerRef) {
                this.timerRef.focus();
            } else if (alertTypeWithTimeError && this.timeRef) {
                this.timeRef.focus();
            }
            return;
        }

        this.shouldBadConfigAlertOpen();

        if (this.props.agenda && this.props.agenda.day && this.props.agendaItem.referenceCount > 1) {
            this.props.dispatch(
                DialogsActions.show({
                    type: DialogType.confirmAgenda,
                    agenda: this.props.agenda,
                    onUpdateAllClick: () => this.updateAgendaItem(false),
                    onUpdateCurrentClick: () => this.updateAgendaItem(true),
                }),
            );
        } else {
            this.updateAgendaItem(false);
        }
    };

    private updateAgendaItem = (withAgendaId: boolean): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const agendaItem: AgendaItem = await this.props.onSubmit(this.state.agendaItem, withAgendaId && this.props.agenda ? this.props.agenda.id : undefined);
                    this.setState({ agendaItem, isPromptDisabled: true, isLoading: false });
                    if (this.props.pageType === PageType.create) {
                        const path: string | undefined = ContentPageUtils.getEditPath(
                            agendaItem,
                            this.props.pageContent,
                            this.props.client ? this.props.client.extId : undefined,
                            this.props.agenda ? this.props.agenda.id : undefined,
                        );

                        if (path) {
                            this.props.history.push(path);
                        }
                    }
                    if (this.pageRef) {
                        this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.agendaItem.saveSucceed" }), type: NotificationType.success });
                    }
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onAssignClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.assignToClient,
                content: this.props.agendaItem,
            }),
        );
    };

    private onShareClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.shareContent,
                content: this.props.agendaItem,
                onSucceed: this.props.refreshAgendaItem,
            }),
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaItemTitle(title));
        this.setState({ agendaItem: { ...this.state.agendaItem, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onFlowchartInputChange = (flowchart: FlowchartContent | null) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, flowchart } });
    };

    private onChecklistChange = (checklist: Checklist | null, errors: ChecklistValidationError) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, checklist }, errors: { ...this.state.errors, checklist: errors } });
    };

    private onMoodMeterDisplayConfigInputChange = (moodMeter: MoodMeterDisplayConfig | null) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, moodMeter } });
    };

    private onAlertInputChange = (alert: AgendaItemAlert | null, errors: AlertValidationError | null) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, alert }, errors: { ...this.state.errors, alert: errors } });
    };

    private onNotificationInputChange = (notification: Notification | null, errors: string | null) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, notification }, errors: { ...this.state.errors, notification: errors } });
    };

    private onAwardInputChange = (award: ItemAwardInput | null, errors: AwardValidationError | null) => {
        this.setState({
            agendaItem: {
                ...this.state.agendaItem,
                award: !isNil(award)
                    ? {
                          ...award,
                          __typename: "ItemAward",
                          score: award.type === ItemAwardType.instant ? null : award.score!,
                          useTimer: award.type === ItemAwardType.score ? false : award.useTimer || false,
                      }
                    : null,
                instantAwards: award?.type === ItemAwardType.score ? [] : this.state.agendaItem.instantAwards,
            },
            errors: {
                ...this.state.errors,
                award: !isNil(errors) ? { ...errors, score: award?.type === ItemAwardType.instant ? null : errors.score } : null,
                instantAwards: award?.type === ItemAwardType.score ? null : this.state.errors.instantAwards,
            },
        });
    };

    private onInstantAwardsChange = (instantAwards: AgendaItem_instantAwards[]) => {
        let instantAwardError: string | null = null;
        if (this.state.agendaItem.award?.type === ItemAwardType.instant) {
            instantAwardError = IntlHelpers.getValidationError(Validator.validateInstantAwardsNumber(instantAwards.length));
        }
        this.setState({ agendaItem: { ...this.state.agendaItem, instantAwards }, errors: { ...this.state.errors, instantAwards: instantAwardError } });
    };

    private onTimerInputChange = (timer: Timer | null, errors: TimerValidationError | null) => {
        this.setState({ agendaItem: { ...this.state.agendaItem, timer }, errors: { ...this.state.errors, timer: errors } });
    };

    private onIsCancelledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ agendaItem: { ...this.state.agendaItem, isCancelled: event.currentTarget.checked } });
    };

    private onTimeInputChange = (time: string | null) => {
        const alertTypeWithTimeError: string | null = this.state.agendaItem.alert
            ? IntlHelpers.getValidationError(Validator.validateAlertTypeWithTime(this.state.agendaItem.alert.display, time))
            : null;

        this.setState({
            agendaItem: {
                ...this.state.agendaItem,
                time,
            },
            errors: {
                ...this.state.errors,
                alertTypeWithTime: {
                    ...this.state.errors.alertTypeWithTime,
                    enabled: alertTypeWithTimeError,
                },
            },
        });
    };

    private onContentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const content: string = event.currentTarget.value;
        this.setState({ agendaItem: { ...this.state.agendaItem, content } });
    };

    private readonly onTagChange = (tags: string[]) => {
        this.setState({
            agendaItem: { ...this.state.agendaItem, tags },
        });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.agendaItem, this.props.agendaItem);
    };

    private onAgendaItemTypeChange = (agendaItemType: AgendaItemTypeOption): void => {
        this.setState({ agendaItem: { ...this.state.agendaItem, type: agendaItemType.value } });
    };

    private shouldPreventRouteChange = (): boolean => {
        if (this.props.pageType !== PageType.view && !this.state.isPromptDisabled) {
            return this.isChanged();
        }
        return false;
    };

    private onToggleClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                onToggled: this.onToggled,
                content: this.props.agendaItem,
            }),
        );
    };

    private onToggled = (): void => {
        this.props.onToggled();
    };

    private onSyncClick = (): void => {
        if (!this.props.client) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.sync,
                client: this.props.client,
                close: this.props.refreshAgendaItem,
            }),
        );
    };

    private onDeleteClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteAgendaItem,
                onDeleted: () => {
                    this.props.history.push(ContentPageUtils.getRootPath(this.props.match.path, this.props.clientExtId, this.props.supporterExtId));
                },
                agendaItem: this.props.agendaItem,
            }),
        );
    };

    private onRelAssetDisable = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.disableRelatingAssets,
                content: this.props.agendaItem,
                clientId: this.props.client?.id,
                onDisabled: this.props.refreshAgendaItem,
            }),
        );
    };

    private renderPageButtons = (): React.ReactElement<any> | null => {
        if (this.props.pageContent === PageContent.shareRequest) {
            return null;
        }
        return (
            <>
                {this.props.agendaItem.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                <ContentPageButtons
                    pageContent={this.props.pageContent}
                    content={this.props.agendaItem}
                    isSaveDisabled={!this.isChanged()}
                    myAccount={this.props.myAccount}
                    pageType={this.props.pageType}
                    onShareClick={this.onShareClick}
                    onAssignClick={this.onAssignClick}
                    onEditClick={this.props.onEditClick}
                    onSubmitClick={this.onSubmitClick}
                    onToggleClick={this.onToggleClick}
                    onDeleteClick={this.onDeleteClick}
                    onSyncClick={this.onSyncClick}
                    onRelAssetDisable={this.onRelAssetDisable}
                />
            </>
        );
    };

    private getAgendaItemByPosition = (position: number): AgendaItemContent[] => {
        if (this.state.agendaItem.agendaItems) {
            const agendaItemRel: AgendaItem_agendaItems | undefined = this.state.agendaItem.agendaItems.find((a: AgendaItem_agendaItems): boolean => a.position === position);
            return agendaItemRel ? [agendaItemRel.agendaItem] : [];
        }
        return [];
    };

    private onAgendaItemsChange = (position: number) => (agendaItems: AgendaItemContent[]) => {
        const newAgendaItems: AgendaItem_agendaItems[] = (this.state.agendaItem.agendaItems || []).filter((a: AgendaItemAgendaItemRel): boolean => a.position !== position);
        if (agendaItems.length > 0) {
            newAgendaItems.push({ position, agendaItem: agendaItems[0], __typename: "AgendaItemAgendaItemRel" });
        }

        const agendaA: string | null =
            this.state.isValidationEnabled && position === 1 ? IntlHelpers.getValidationError(Validator.validateNonNull(agendaItems.length === 0 ? null : agendaItems[0])) : this.state.errors.agendaA;

        const agendaB: string | null =
            this.state.isValidationEnabled && position === 2 ? IntlHelpers.getValidationError(Validator.validateNonNull(agendaItems.length === 0 ? null : agendaItems[0])) : this.state.errors.agendaB;

        this.setState({
            agendaItem: { ...this.state.agendaItem, agendaItems: newAgendaItems },
            errors: { ...this.state.errors, agendaA, agendaB },
        });
    };

    private onAssetChange = (assets: AssetContent[], assetType: AssetType): void => {
        const assetContent: AssetContent | null = assets.length > 0 ? assets[0] : null;
        switch (assetType) {
            case AssetType.audio: {
                this.setState({ agendaItem: { ...this.state.agendaItem, audio: assetContent } });
                break;
            }
            case AssetType.video: {
                this.setState({ agendaItem: { ...this.state.agendaItem, video: assetContent } });
                break;
            }
            case AssetType.image: {
                this.setState({ agendaItem: { ...this.state.agendaItem, image: assetContent } });
            }
        }
    };

    private readonly onSelectionBoardChange = (selectionBoard: SelectionBoard | null): void => {
        this.setState({
            agendaItem: { ...this.state.agendaItem, selectionBoard },
        });
    };

    public renderContent(): React.ReactElement<any> {
        const disabled: boolean = this.props.pageType === PageType.view;
        const { agendaItem, isValidationEnabled, errors } = this.state;
        const agendaItemTypeOptions: AgendaItemTypeOption[] = AgendaItemTypeOptions.get();
        const isEditEnabled: boolean = ContentPageUtils.isEditEnabled(this.props.agendaItem, this.props.myAccount, this.props.client);
        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.agendaItem.data.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.type.label" })}>
                        <Select
                            value={Select.getSelectOption(agendaItemTypeOptions, this.state.agendaItem.type)}
                            onChange={this.onAgendaItemTypeChange}
                            options={agendaItemTypeOptions}
                            disabled={disabled || this.props.pageType === PageType.edit}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper
                        id={TestId.agendaItemForm.title}
                        inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.name.label" })}
                        errorMessage={this.state.isValidationEnabled ? this.state.errors.title : null}
                    >
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.titleRef = ref;
                            }}
                            name="title"
                            type="text"
                            placeholder={Intl.formatMessage({ id: "page.agendaItem.data.name.placeholder" })}
                            value={this.state.agendaItem.title || ""}
                            onChange={this.onTitleChange}
                            disabled={disabled}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.isCancelled.label" })}>
                        <Checkbox checked={!!this.state.agendaItem.isCancelled} onChange={this.onIsCancelledChange} disabled={disabled} />
                    </InputWrapper>

                    <hr />

                    <InputWrapper id={TestId.agendaItemForm.content} inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.content.label" })}>
                        <Input
                            name="content"
                            type="text"
                            placeholder={Intl.formatMessage({ id: "page.agendaItem.data.content.placeholder" })}
                            value={this.state.agendaItem.content || ""}
                            onChange={this.onContentChange}
                            disabled={disabled}
                        />
                    </InputWrapper>
                    <hr />

                    {this.state.agendaItem.type === AgendaItemType.Simple && (
                        <>
                            <MultipleAssetInputWrapper
                                label={Intl.formatMessage({ id: "page.agendaItem.data.media.label" })}
                                items={this.state.agendaItem}
                                onAssetChange={this.onAssetChange}
                                disabled={disabled}
                            />

                            <hr />

                            <FlowchartRelInput
                                testIds={TestId.agendaItemForm.flowchart}
                                value={agendaItem.flowchart}
                                onChange={this.onFlowchartInputChange}
                                disabled={disabled}
                                enabled={this.state.isFlowchartRelEnabled}
                                onEnabledChange={enabled => {
                                    this.setState({ isFlowchartRelEnabled: enabled });
                                }}
                                onViewClick={(flowchart: FlowchartContent) => {
                                    const path: string | undefined = ContentPageUtils.getViewPath(
                                        flowchart,
                                        this.props.pageContent,
                                        this.props.clientExtId,
                                        this.props.supporterExtId,
                                        undefined,
                                        this.props.supervisorId,
                                    );
                                    if (path) {
                                        this.props.history.push(path);
                                    }
                                }}
                                onEditClick={
                                    isEditEnabled
                                        ? (flowchart: FlowchartContent) => {
                                              const path: string | undefined = ContentPageUtils.getEditPath(flowchart, this.props.pageContent, this.props.clientExtId);
                                              if (path) {
                                                  this.props.history.push(path);
                                              }
                                          }
                                        : undefined
                                }
                            />
                        </>
                    )}
                    {this.state.agendaItem.type === AgendaItemType.Select && (
                        <>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.agendaItems.a" })} errorMessage={isValidationEnabled ? errors.agendaA : null}>
                                <AgendaItemInput
                                    droppableId="agendaItemA"
                                    value={this.getAgendaItemByPosition(1)}
                                    onChange={this.onAgendaItemsChange(1)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={disabled}
                                    onViewClick={(agendaItem: AgendaItemContent) => {
                                        const path: string | undefined = ContentPageUtils.getViewPath(
                                            agendaItem,
                                            this.props.pageContent,
                                            this.props.clientExtId,
                                            this.props.supporterExtId,
                                            undefined,
                                            this.props.supervisorId,
                                        );
                                        if (path) {
                                            this.props.history.push(path);
                                        }
                                    }}
                                    onEditClick={
                                        isEditEnabled
                                            ? (agendaItem: AgendaItemContent) => {
                                                  const path: string | undefined = ContentPageUtils.getEditPath(agendaItem, this.props.pageContent, this.props.clientExtId);
                                                  if (path) {
                                                      this.props.history.push(path);
                                                  }
                                              }
                                            : undefined
                                    }
                                    hasError={!!errors.agendaA}
                                />
                            </InputWrapper>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.agendaItems.b" })} errorMessage={isValidationEnabled ? errors.agendaB : null}>
                                <AgendaItemInput
                                    droppableId="agendaItemB"
                                    value={this.getAgendaItemByPosition(2)}
                                    onChange={this.onAgendaItemsChange(2)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={disabled}
                                    onViewClick={(agendaItem: AgendaItemContent) => {
                                        const path: string | undefined = ContentPageUtils.getViewPath(
                                            agendaItem,
                                            this.props.pageContent,
                                            this.props.clientExtId,
                                            this.props.supporterExtId,
                                            undefined,
                                            this.props.supervisorId,
                                        );
                                        if (path) {
                                            this.props.history.push(path);
                                        }
                                    }}
                                    onEditClick={
                                        isEditEnabled
                                            ? (agendaItem: AgendaItemContent) => {
                                                  const path: string | undefined = ContentPageUtils.getEditPath(agendaItem, this.props.pageContent, this.props.clientExtId);
                                                  if (path) {
                                                      this.props.history.push(path);
                                                  }
                                              }
                                            : undefined
                                    }
                                    hasError={!!errors.agendaB}
                                />
                            </InputWrapper>
                        </>
                    )}
                </Section>
                {this.state.agendaItem.type === AgendaItemType.Simple && (
                    <Section label={Intl.formatMessage({ id: "page.agendaItem.additions.title" })}>
                        <MoodMeterDisplayConfigInput testIds={TestId.agendaItemForm.moodMeter} value={agendaItem.moodMeter} onChange={this.onMoodMeterDisplayConfigInputChange} disabled={disabled} />
                        <hr />
                        <AgendaItemAlertInput
                            ref={(ref: AgendaItemAlertInput | null): void => {
                                this.alertRef = ref;
                            }}
                            testIds={TestId.agendaItemForm.alert}
                            value={agendaItem.alert}
                            onChange={this.onAlertInputChange}
                            disabled={disabled}
                            isValidationEnabled={isValidationEnabled}
                            errors={errors.alert}
                        />
                        <hr />
                        <NotificationInput
                            ref={(ref: NotificationInput | null): void => {
                                this.notificationRef = ref;
                            }}
                            value={agendaItem.notification}
                            onChange={this.onNotificationInputChange}
                            disabled={disabled}
                            isValidationEnabled={isValidationEnabled}
                            error={this.state.errors.notification}
                        />
                        <hr />
                        {this.props.client && (
                            <>
                                <SelectionBoardInput
                                    clientId={this.props.client.id}
                                    value={agendaItem.selectionBoard}
                                    onChange={this.onSelectionBoardChange}
                                    disabled={disabled || !this.props.client}
                                />
                                <hr />
                            </>
                        )}
                        <ChecklistInput
                            ref={(ref: ChecklistInput | null): void => {
                                this.checklistRef = ref;
                            }}
                            isValidationEnabled={isValidationEnabled}
                            value={agendaItem.checklist}
                            onChange={this.onChecklistChange}
                            disabled={disabled}
                            errors={errors.checklist}
                        />
                        <hr />

                        <TimerInput
                            ref={(ref: TimerInput | null): void => {
                                this.timerRef = ref;
                            }}
                            isValidationEnabled={isValidationEnabled}
                            testIds={TestId.agendaItemForm.timer}
                            value={agendaItem.timer}
                            onChange={this.onTimerInputChange}
                            disabled={disabled}
                            errors={errors.timer}
                        />
                        <hr />
                        <TimeInput
                            ref={(ref: TimeInput | null): void => {
                                this.timeRef = ref;
                            }}
                            testIds={TestId.agendaItemForm.time}
                            value={agendaItem.time}
                            onChange={this.onTimeInputChange}
                            disabled={disabled}
                            isValidationEnabled={isValidationEnabled}
                            errors={this.state.errors.alertTypeWithTime}
                        />
                        <hr />
                        <AwardInput
                            ref={(ref: AwardInput | null): void => {
                                this.awardRef = ref;
                            }}
                            clientId={this.props.client?.id}
                            testIds={TestId.agendaItemForm.award}
                            isValidationEnabled={isValidationEnabled}
                            value={agendaItem.award}
                            activeInstantAwards={agendaItem.instantAwards}
                            onAwardChange={this.onAwardInputChange}
                            onInstantAwardsChange={this.onInstantAwardsChange}
                            disabled={disabled}
                            isClientPresent={!isNil(this.props.client)}
                            awardErrors={errors.award}
                            instantAwardErrors={errors.instantAwards}
                            dispatch={this.props.dispatch}
                        />
                    </Section>
                )}

                <Section label={Intl.formatMessage({ id: "page.asset.tagSection.title" })}>
                    <TagSelect tags={this.props.tags} selectedTags={agendaItem.tags} onChange={this.onTagChange} disabled={disabled} renderTags={true} />
                </Section>

                {(this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest) && (
                    <LibraryContentShareRequestSection shareRequest={this.props.shareRequest} content={this.props.agendaItem} />
                )}

                <Prompt when={this.shouldPreventRouteChange()} />
            </>
        );
    }

    private getDragIds(): string[] {
        const dragIds: string[] = [];
        if (this.state.agendaItem.image) {
            dragIds.push(this.state.agendaItem.image.id);
        }
        if (this.state.agendaItem.audio) {
            dragIds.push(this.state.agendaItem.audio.id);
        }
        if (this.state.agendaItem.video) {
            dragIds.push(this.state.agendaItem.video.id);
        }
        if (this.state.agendaItem.agendaItems) {
            dragIds.push(...this.state.agendaItem.agendaItems.map((agendaItemItem: AgendaItem_agendaItems): string => DragUtils.getDragId(agendaItemItem.agendaItem)));
        }
        if (this.state.agendaItem.flowchart) {
            dragIds.push(this.state.agendaItem.flowchart.id);
        }

        return dragIds;
    }

    public render(): React.ReactElement<any> {
        const hideSidebar: boolean = this.props.pageType === PageType.view;
        let searchListTypes: SearchListType[] = [];
        if (this.state.agendaItem.type === AgendaItemType.Simple) {
            searchListTypes = [SearchListType.asset];
            if (this.state.isFlowchartRelEnabled) {
                searchListTypes.push(SearchListType.flowchart);
            }
        } else if (this.state.agendaItem.type === AgendaItemType.Select) {
            searchListTypes = [SearchListType.agendaItem];
        }

        const dragIds: string[] = this.getDragIds();
        const titleName: string = ContentPageUtils.getContentPageTitleName(this.props);
        return (
            <Page
                id={TestId.agendaItemForm.container}
                ref={(ref: Page | null) => {
                    this.pageRef = ref;
                }}
                title={(titleName ? titleName + " - " : "") + Intl.formatMessage({ id: `page.agendaItem.title.${this.props.pageType}` })}
                renderButtons={this.renderPageButtons}
                hasSideBar={!hideSidebar}
            >
                <DragContainer
                    hideSidebar={hideSidebar}
                    searchListTypes={searchListTypes}
                    assetTypes={this.state.agendaItem.type === AgendaItemType.Simple ? [AssetType.image, AssetType.video, AssetType.audio] : []}
                    agendaItemTypes={[AgendaItemType.Simple]}
                    createdById={this.props.myAccount.id}
                    client={this.props.client}
                    dragIds={dragIds}
                    canHaveMultipleAssets={true}
                >
                    {(this.props.isLoading || this.state.isLoading) && <Loading type={LoadingType.layer} />}
                    <div className="left-side">{!this.props.isLoading && this.renderContent()}</div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags };
};

export const AgendaItemForm = withRouter(connect(mapStateToProps)(AgendaItemFormComponent));

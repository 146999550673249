import React, { PureComponent } from "react";
import { OptionMenuItem, OptionsMenu } from "components/OptionsMenu/OptionsMenu";
import { RouteComponentProps, withRouter } from "react-router";
import { Intl } from "i18n/Intl";
import { AccountType, CalendarAgenda } from "api/graphql/types";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AnyContentListType, AnyContentType } from "api/ApiTypes";
import { AccountSelectors } from "selectors/AccountSelectors";

import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ContentPageUtils, PageContent } from "../ContentPageUtils";
import { DisabledItemUtils } from "utils/DisabledItemUtils";

interface ReduxProps {
    accountType: AccountType;
    isOwnClientContent: boolean;
    accountId: string;
}

interface ComponentProps {
    pageContent: PageContent;
    supporterExtId?: string | null;
    clientExtId?: string;
    supervisorId?: string | null;
    content: AnyContentListType | AnyContentType | CalendarAgenda;
    isUsed?: boolean;
    onAssignToDayClick?: () => void;
    onSetDefaultAgendaClick?: () => void;
    onUnsetClientDefaultAgenda?: () => void;
    onDeleteClick?: () => void;
    onShareClick?: () => void;
    onAssignToClientClick?: () => void;
    onToggled?: () => void;
    onRelAssetDisable?: () => void;
    isSyncAvailable?: boolean;
    onSyncClick?: () => void;
    refreshContents?: () => void;
}

type Props = ReduxProps & ComponentProps & RouteComponentProps;

class ContentOptionMenuComponent extends PureComponent<Props> {
    private isTogglerAvailable(): boolean {
        if (this.props.pageContent === PageContent.public || this.props.pageContent === PageContent.own || this.props.pageContent === PageContent.supervisorDetail) {
            return false;
        }

        switch (this.props.content.__typename) {
            case "Asset":
                return false;
            default:
                return true;
        }
    }

    private onCopyClick = async (content: AnyContentListType | AnyContentType | CalendarAgenda): Promise<void> => {
        try {
            await Api.copyContentToAccountPersonalLibrary(content);
            Alert.success({ title: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrarySucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private getSupporterOptions(): OptionMenuItem[] {
        const options: OptionMenuItem[] = [];
        const viewPath: string | undefined = ContentPageUtils.getViewPath(
            this.props.content,
            this.props.pageContent,
            this.props.clientExtId,
            this.props.supporterExtId,
            undefined,
            this.props.supervisorId,
        );

        const editPath: string | undefined =
            this.props.pageContent !== PageContent.public ? ContentPageUtils.getEditPath(this.props.content, this.props.pageContent, this.props.clientExtId) : undefined;

        if (viewPath) {
            options.push({
                label: Intl.formatMessage({ id: "common.view" }),
                icon: "fa-eye",
                onClick: () => {
                    this.props.history.push(viewPath);
                },
            });
        }

        if (editPath) {
            options.push({
                label: Intl.formatMessage({ id: "common.edit" }),
                icon: "fa-pencil-alt",
                onClick: () => {
                    this.props.history.push(editPath);
                },
            });
        }

        if (this.props.onAssignToDayClick) {
            options.push({ label: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.agendaTable.options.assignToDay" }), icon: "fa-calendar-alt", onClick: this.props.onAssignToDayClick });
        }

        if (this.props.pageContent === PageContent.own && !this.props.clientExtId && this.props.onAssignToClientClick) {
            options.push({
                label: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.agendaTable.options.assignToClient" }),
                icon: "fa-user-plus",
                onClick: this.props.onAssignToClientClick,
            });
        }

        if (this.props.isOwnClientContent) {
            if (this.props.onSetDefaultAgendaClick) {
                options.push({
                    label: Intl.formatMessage({ id: "component.agendaOptionMenu.setAsDefaultAgenda.label" }),
                    icon: "fab fa-adn",
                    onClick: this.props.onSetDefaultAgendaClick,
                });
            } else if (this.props.onUnsetClientDefaultAgenda) {
                options.push({
                    label: Intl.formatMessage({ id: "component.agendaOptionMenu.unsetClientDefaultAgenda.label" }),
                    icon: "fab fa-adn",
                    onClick: this.props.onUnsetClientDefaultAgenda,
                });
            }
        }

        if (this.props.isSyncAvailable && this.props.onSyncClick) {
            options.push({ label: Intl.formatMessage({ id: "common.sync" }), icon: "fa-sync", onClick: this.props.onSyncClick });
        }

        if (this.props.pageContent === PageContent.own && this.props.onShareClick) {
            options.push({ label: Intl.formatMessage({ id: "common.share" }), icon: "fa-share-alt", onClick: this.props.onShareClick });
        }

        if (this.props.content.__typename !== "Asset" && !this.props.content.disabledAt && (this.props.clientExtId || this.props.pageContent === PageContent.public)) {
            options.push({
                label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                icon: "fa-clone",
                onClick: () => this.onCopyClick(this.props.content),
            });
        }

        if (!this.props.isUsed && this.props.pageContent !== PageContent.public && this.props.onDeleteClick) {
            options.push({ label: Intl.formatMessage({ id: "common.delete" }), icon: "fa-trash", onClick: this.props.onDeleteClick });
        }

        return options;
    }

    private getSupervisorOptions(): OptionMenuItem[] {
        const options: OptionMenuItem[] = [];
        const viewPath: string | undefined = ContentPageUtils.getViewPath(
            this.props.content,
            this.props.pageContent,
            this.props.clientExtId,
            this.props.supporterExtId,
            undefined,
            this.props.supervisorId,
        );

        const editPath: string | undefined = ContentPageUtils.getEditPath(this.props.content, this.props.pageContent, this.props.clientExtId);

        if (viewPath) {
            options.push({
                label: Intl.formatMessage({ id: "common.view" }),
                icon: "fa-eye",
                onClick: () => {
                    this.props.history.push(viewPath);
                },
            });
        }

        if (editPath) {
            options.push({
                label: Intl.formatMessage({ id: "common.edit" }),
                icon: "fa-pencil-alt",
                onClick: () => {
                    this.props.history.push(editPath);
                },
            });
        }

        if (this.props.pageContent === PageContent.own && this.props.onShareClick) {
            options.push({ label: Intl.formatMessage({ id: "common.share" }), icon: "fa-share-alt", onClick: this.props.onShareClick });
        }

        if (this.isTogglerAvailable()) {
            if (this.props.onToggled) {
                const isEnable: boolean = this.props.content && this.props.content.__typename !== "Asset" && this.props.content.disabledAt;
                options.push({ label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }), icon: isEnable ? "fa-unlock" : "fa-lock", onClick: this.props.onToggled });
            }
            const relAssets = DisabledItemUtils.getRelatedAssets(this.props.content as any);
            if (relAssets.length > 0 && this.props.onRelAssetDisable) {
                options.push({ label: Intl.formatMessage({ id: "component.extraContentOptionMenu.assetRelDisable" }), icon: "fa-lock", onClick: this.props.onRelAssetDisable });
            }
        }

        if (
            !this.props.isUsed &&
            this.props.content.__typename !== "Asset" &&
            (this.props.pageContent === PageContent.own || this.props.pageContent === PageContent.public) &&
            this.props.onDeleteClick
        ) {
            options.push({ label: Intl.formatMessage({ id: "common.delete" }), icon: "fa-trash", onClick: this.props.onDeleteClick });
        }

        if (this.props.content.__typename !== "Asset" && !this.props.content.disabledAt && this.props.pageContent === PageContent.public) {
            options.push({
                label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                icon: "fa-clone",
                onClick: () => this.onCopyClick(this.props.content),
            });
        }

        return options;
    }

    private getAdminOptions(): OptionMenuItem[] {
        const options: OptionMenuItem[] = [];
        const viewPath: string | undefined = ContentPageUtils.getViewPath(
            this.props.content,
            this.props.pageContent,
            this.props.clientExtId,
            this.props.supporterExtId,
            undefined,
            this.props.supervisorId,
        );

        if (viewPath) {
            options.push({
                label: Intl.formatMessage({ id: "common.view" }),
                icon: "fa-eye",
                onClick: () => {
                    this.props.history.push(viewPath);
                },
            });
        }

        if (this.isTogglerAvailable() && this.props.onToggled) {
            const isEnable: boolean = this.props.content && this.props.content.__typename !== "Asset" && this.props.content.disabledAt;
            options.push({ label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }), icon: isEnable ? "fa-unlock" : "fa-lock", onClick: this.props.onToggled });
        }

        if (!this.props.isUsed && this.props.pageContent !== PageContent.public && this.props.onDeleteClick) {
            options.push({ label: Intl.formatMessage({ id: "common.delete" }), icon: "fa-trash", onClick: this.props.onDeleteClick });
        }

        return options;
    }

    private getOptions(): OptionMenuItem[] {
        switch (this.props.accountType) {
            case AccountType.supporter:
                return this.getSupporterOptions();
            case AccountType.supervisor:
                return this.getSupervisorOptions();
            case AccountType.admin:
            case AccountType.superadmin:
                return this.getAdminOptions();
            default:
                return [];
        }
    }

    public render(): React.ReactElement<any> | null {
        const options: OptionMenuItem[] = this.getOptions();
        return options.length > 0 ? <OptionsMenu items={options} /> : null;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const { account } = state;

    return {
        accountType: account!.accountType,
        isOwnClientContent: !!AccountSelectors.getClientByExtId(state, props.clientExtId),
        accountId: account!.id,
    };
};

export const ContentOptionMenu = withRouter(connect(mapStateToProps)(ContentOptionMenuComponent));

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { AssetObject, AssetObjectForm } from "./components/AssetObjectForm";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { AsyncUtils } from "utils/AsyncUtils";
import { uuid4 } from "@sentry/utils";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Select, SelectOption } from "../Inputs/Select/Select";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { ContentLibraryDirectories } from "models/ContentLibraryDirectories";
import { DirectoryUtils } from "utils/DirectoryUtils";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ImageSrc } from "utils/ImageSrc";
import { FilePreview } from "utils/FilePreview";
import { store } from "store";

export interface UploadAssetDialogProps {
    onAssetUploadFinished: (succeed: boolean) => void;
    isVisible: boolean;
}

type Props = UploadAssetDialogProps & DialogVisibilityProps & RouteComponentProps;

type DirectoryOptionType = SelectOption<string | null>;

interface State {
    assetObjects: AssetObject[];
    personalAssetDirectories: SelectOption[];
    selectedDirectory: DirectoryOptionType;
    isLoading: boolean;
    isValidationEnabled: boolean;
}

class UploadAssetDialogComponent extends PureComponent<Props> {
    private static createAssetObject(): AssetObject {
        return {
            id: uuid4(),
            name: "",
            file: null,
            isLoading: false,
            progress: null,
            url: null,
            errors: {
                file: null,
                name: null,
            },
            tags: [],
        };
    }

    private static getInitialState(): State {
        return {
            assetObjects: [UploadAssetDialogComponent.createAssetObject()],
            isLoading: false,
            isValidationEnabled: false,
            personalAssetDirectories: [],
            selectedDirectory: {
                id: "",
                value: null,
                label: Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" }),
            },
        };
    }

    public readonly state: State = UploadAssetDialogComponent.getInitialState();

    public componentDidMount(): void {
        this.fetchDirectories();
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.isVisible && !nextProps.isVisible) {
            this.setState(UploadAssetDialogComponent.getInitialState());
        }
    }

    private fetchDirectories = async () => {
        try {
            const personalAssetDirectories = await ContentLibraryDirectories.getPersonalAssetDirectories(this.props.history.location.pathname);
            const { selectedDirectory } = this.state;
            this.setState({
                personalAssetDirectories: [selectedDirectory, ...DirectoryUtils.getDirectoriesSelectValue(personalAssetDirectories)],
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private validateAssetObject(assetObject: AssetObject, index: number): AssetObject {
        if (index > 0 && assetObject.name === "" && !assetObject.file) {
            return { ...assetObject, errors: { file: null, name: null } };
        }

        return {
            ...assetObject,
            errors: {
                file: IntlHelpers.getValidationError(Validator.validateNonNull(assetObject.file)),
                name: IntlHelpers.getValidationError(Validator.validateAssetName(assetObject.name)),
            },
        };
    }

    private uploadAsset = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const filterEmpty = (assetObject: AssetObject): boolean => {
            return !!assetObject.name || !!assetObject.file;
        };
        const assetObjects: AssetObject[] = this.state.assetObjects.map(this.validateAssetObject);
        if (assetObjects.length <= 1 || this.hasError(assetObjects.filter(filterEmpty))) {
            this.setState({ assetObjects, isValidationEnabled: true });
            return;
        }

        const newAssetObjects: AssetObject[] = assetObjects.map(
            (assetObject: AssetObject): AssetObject => {
                return { ...assetObject, progress: 0 };
            },
        );

        this.setState({ isLoading: true, assetObject: newAssetObjects }, async () => {
            const errors: Error[] = [];
            const errorIndexes: number[] = [];
            await AsyncUtils.forEach(
                this.state.assetObjects.filter(filterEmpty),
                async (assetObject: AssetObject, index: number): Promise<void> => {
                    try {
                        const onProgressChange = (progress: number) => {
                            const progressDiv = document.getElementById(`progress-asset-${index}`);
                            if (progressDiv) {
                                progressDiv.style.width = `${progress}%`;
                                progressDiv.classList.add("upload-in-progress");
                                if (progress === 100) {
                                    progressDiv.classList.add("upload-success");
                                }
                            }
                            const progressAssetObjects: AssetObject[] = [...this.state.assetObjects];
                            progressAssetObjects[index] = { ...progressAssetObjects[index], progress };
                            this.setState({ assetObjects: progressAssetObjects });
                        };
                        await Api.uploadAsset(assetObject.name, assetObject.file!, assetObject.tags ? assetObject.tags : [], this.state.selectedDirectory.id || null, onProgressChange);
                    } catch (error) {
                        errors.push(error);
                        errorIndexes.push(index);
                    }
                },
            );
            if (errors.length === 0) {
                this.props.onHide();
                Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.uploadSuccess" }) });
                this.setState({ isLoading: false });
                this.props.onAssetUploadFinished(true);
                return;
            }

            errors.map((error: Error): void => {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            });

            const filteredAssetObjects: AssetObject[] = this.state.assetObjects.filter((_, index: number): boolean => errorIndexes.includes(index));
            const remainingAssetObjects: AssetObject[] = filteredAssetObjects.map((assetObject: AssetObject): AssetObject => ({ ...assetObject, progress: null }));
            this.setState({ isLoading: false, assetObjects: [...remainingAssetObjects, UploadAssetDialogComponent.createAssetObject()] });
            this.props.onAssetUploadFinished(false);
        });
    };

    private hasError(assetObjects: AssetObject[]): boolean {
        return assetObjects.some((assetObject: AssetObject): boolean => !!assetObject.errors.file || !!assetObject.errors.name);
    }

    private onDirectoryChange = (item: DirectoryOptionType): void => {
        this.setState({
            selectedDirectory: item,
        });
    };

    private onAssetObjectChange = (index: number) => async (assetObject: AssetObject | null, files: File[]): Promise<void> => {
        const assetObjects = [...this.state.assetObjects];
        if (assetObject) {
            if (index === this.state.assetObjects.length - 1 && assetObject.name === "" && assetObject.file === null) {
                assetObjects[index] = { ...assetObject, errors: { file: null, name: null } };
            } else {
                assetObjects[index] = { ...assetObject };
            }
        } else {
            assetObjects.splice(index, 1);
        }

        if (files.length > 0) {
            const otherAssetObjects: AssetObject[] = await AsyncUtils.map(
                files,
                async (file: File): Promise<AssetObject> => {
                    let url: string = URL.createObjectURL(file);
                    if (store.getState().settings.allowedUploadContentTypes?.audio.includes(file.type)) {
                        url = ImageSrc.audioAsset;
                    } else if (store.getState().settings.allowedUploadContentTypes?.video.includes(file.type)) {
                        url = await new FilePreview(url!).getVideoThumbnail();
                    }
                    return { id: uuid4(), url, file, progress: null, name: file.name, isLoading: false, errors: { file: null, name: null } };
                },
            );

            assetObjects.splice(index, 0, ...otherAssetObjects);
        }

        if (assetObjects.every((assetObject: AssetObject): boolean => assetObject.file !== null)) {
            assetObjects.push(UploadAssetDialogComponent.createAssetObject());
        }

        this.setState({ assetObjects });
    };

    private onCloseClick = () => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    public render(): React.ReactElement<any> {
        const { isLoading, isValidationEnabled, assetObjects, personalAssetDirectories } = this.state;

        const hasError: boolean = this.hasError(assetObjects);
        return (
            <Dialog
                title={Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.title" })}
                visible={this.props.isVisible}
                onCloseClick={this.onCloseClick}
                modalWindowCustomClassName="w-780"
                modalHeaderCustomClassName="bb-0 pl-30 pr-30"
                modalContentCustomClassName="bt-0 br-0 bb-0 bl-0 pt-0 pr-0 pb-0 pl-0"
            >
                <form onSubmit={this.uploadAsset}>
                    <div className="modal-content--scrollable">
                        <p className="mb-40">{Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.description" })}</p>

                        {assetObjects.map(
                            (assetObject: AssetObject, index: number): React.ReactElement<any> => {
                                return (
                                    <React.Fragment key={assetObject.id}>
                                        <AssetObjectForm assetObject={assetObject} onChange={this.onAssetObjectChange(index)} isValidationEnabled={isValidationEnabled} disabled={isLoading} />
                                        {assetObject.file && assetObject.progress !== null ? (
                                            <div className="progress">
                                                <div className={`progress-bar progress-bar-success upload-progress ${assetObject.errors.name ? "upload-error" : ""}`} id={`progress-asset-${index}`} />
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                );
                            },
                        )}
                        <hr className="mt-20 mb-30" />

                        <InputWrapper className="mb-0" inputLabel={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.label" })}>
                            <Select
                                options={personalAssetDirectories ? personalAssetDirectories : []}
                                onChange={item => this.onDirectoryChange(item)}
                                value={this.state.selectedDirectory}
                                emptyLabel={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" })}
                                disabled={false}
                            />
                        </InputWrapper>
                    </div>

                    <div className="pr-30 pb-30 pl-30">
                        <hr className="mt-0 mb-30" />
                        <div className="row buttons">
                            <div className="cell medium-6">
                                <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={isLoading} onClick={this.onCloseClick} />
                            </div>
                            <div className="cell medium-6 text-right">
                                <Button label={Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.submitLabel" })} type="submit" disabled={isLoading || (isValidationEnabled && hasError)} />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const UploadAssetDialog = withRouter(UploadAssetDialogComponent);

import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { ClientShareRequestClient, SupportedClient } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ObjectUtils } from "utils/ObjectUtils";
import { Formatter } from "utils/Formatter";
import { withRouter, RouteComponentProps } from "react-router";
import { Path } from "utils/Path";
import { DateUtils, DateFormat } from "utils/DateUtils";

interface ComponentProps {
    activeClients: SupportedClient[];
    pendingClients: ClientShareRequestClient[];
}

enum ClientStatus {
    active = "active",
    pending = "pending",
}

type Props = ComponentProps & RouteComponentProps;

enum SupporterDetailClientListColumn {
    extId = "extId",
    dateOfBirth = "dateOfBirth",
    status = "status",
}

interface ClientWithStatus extends ClientShareRequestClient {
    status: ClientStatus;
}

interface State {
    data: ClientWithStatus[];
}

class SupporterDetailClientListComponent extends Component<Props, State> {
    public state: State = SupporterDetailClientListComponent.getDerivedStateFromProps(this.props);

    private readonly columns: Array<Column<ClientWithStatus>> = ObjectUtils.enumAsArray<SupporterDetailClientListColumn>(SupporterDetailClientListColumn).map(
        (columnName: string): Column<ClientWithStatus> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.supporterDetail.tabs.profile.clients.table.columns.${columnName}` }),
            accessor: columnName as keyof ClientShareRequestClient,
            renderCell: (client: ClientWithStatus): React.ReactElement<any> | null => {
                switch (columnName) {
                    case SupporterDetailClientListColumn.extId:
                        return <>{Formatter.formatExtId(client.extId!)}</>;
                    case SupporterDetailClientListColumn.dateOfBirth:
                        return <>{DateUtils.format(new Date(client.dateOfBirth), DateFormat.yyyymmdd)}</>;
                    case SupporterDetailClientListColumn.status:
                        return <>{Intl.formatMessage({ id: `enum.clientStatus.${client.status}` })}</>;
                    default:
                        return null;
                }
            },
        }),
    );

    public static getDerivedStateFromProps(props: Props): State {
        const activeClients: ClientWithStatus[] = props.activeClients.map((client: SupportedClient): ClientWithStatus => ({ ...client, status: ClientStatus.active }));
        const pendingClients: ClientWithStatus[] = props.pendingClients.map((client: ClientShareRequestClient): ClientWithStatus => ({ ...client, status: ClientStatus.pending }));
        return { data: [...activeClients, ...pendingClients] };
    }

    public render(): React.ReactElement<any> {
        return (
            <Table
                keyExtractor={(item: ClientWithStatus, column?: Column<ClientWithStatus>): string => {
                    return item.id + (column ? column.id : "");
                }}
                columns={this.columns}
                data={this.state.data}
                count={this.state.data.length}
                currentPage={1}
                isLoading={false}
                renderEmpty={(): string => Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.clients.noData" })}
                onRowClick={(client: ClientWithStatus): void => {
                    this.props.history.push(Path.clientDetail(client.extId));
                }}
            />
        );
    }
}

export const SupporterDetailClientList = withRouter(SupporterDetailClientListComponent);

import React, { Component } from "react";
import { Page } from "components/Page";
import { SortableList } from "components/SortableList/SortableList";
import { Section } from "components/Section";
import { SortableTable } from "components/Table/SortableTable";

interface Props {}

interface State {
    data: any[];
}

class SandboxPageComponent extends Component<Props, State> {
    public readonly state: State = {
        data: [
            { id: "1", value: "alma", position: 1 },
            { id: "2", value: "korte", position: 2 },
            { id: "3", value: "szilva", position: 3 },
        ],
    };

    public render(): React.ReactElement<any> {
        return (
            <Page title={"Sandbox Page"}>
                <Section label="SortableList" noTopMargin={false}>
                    <SortableList
                        data={this.state.data}
                        renderItem={(item: any) => {
                            return (
                                <div key={item.value} style={{ minHeight: 50, width: 780, border: "1px solid #D8E4E8", boxSizing: "border-box", borderRadius: 5 }}>
                                    {item.position}. {item.value}
                                </div>
                            );
                        }}
                        onOrderChange={(data: any[]) => {
                            this.setState({ data });
                        }}
                    />

                    <SortableTable
                        data={this.state.data}
                        count={this.state.data.length}
                        renderItem={(item: any) => {
                            return (
                                <div key={item.value} style={{ minHeight: 50, width: 780, border: "1px solid #D8E4E8", boxSizing: "border-box", borderRadius: 5 }}>
                                    {item.position}. {item.value}
                                </div>
                            );
                        }}
                        onOrderChange={(data: any[]) => {
                            this.setState({ data });
                        }}
                        isLoading={false}
                        columns={[
                            {
                                id: "id",
                                name: "ID",
                                accessor: "id",
                            },
                            {
                                id: "value",
                                name: "Value",
                                accessor: "value",
                            },
                            {
                                id: "position",
                                name: "Position",
                                accessor: "position",
                            },
                        ]}
                    />
                </Section>
            </Page>
        );
    }
}

export const SandboxPage = SandboxPageComponent;

// tslint:disable-next-line: no-default-export
export default SandboxPage;

import { Time } from "./DateUtils";
import { Intl } from "i18n/Intl";

class Formatter {
    public static formatExtId(extId: string): string {
        return (extId.match(/.{1,3}/g) || []).join("-").toUpperCase();
    }

    public static formatTime({ hour, minute }: Time): string {
        return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
    }

    public static formatBoolean(value: any): string {
        return Intl.formatMessage({ id: `common.${value ? "yes" : "no"}` });
    }
}

export { Formatter };

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { AgendaItem_moodMeter, MoodMeterDisplay } from "api/graphql/types";
import { Input } from "components/Inputs/Input/Input";
import { Intl } from "i18n/Intl";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { MoodMeterDisplayOptions } from "models/MoodMeterDisplaySelectOptions";
import { MoodMeterQuestionSelectOptions, MoodMeterQuestion, MoodMeterQuestionSelectOption } from "models/MoodMeterQuestionSelectOptions";
import { IntlHelpers } from "i18n/IntlHelpers";

interface Props {
    testIds: {
        display: string;
        title: string;
    };
    value: AgendaItem_moodMeter | null;
    onChange: (moodMeter: AgendaItem_moodMeter | null) => void;
    disabled?: boolean;
}

interface State {
    displayOption: MoodMeterDisplay | null;
}

class MoodMeterDisplayConfigInput extends Component<Props, State> {
    public readonly state: State = {
        displayOption: this.props.value ? this.props.value.display : null,
    };

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.moodMeter.${id}` });
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.target.value;
        this.props.onChange({ ...this.props.value!, title });
    };

    private getDisplay = (): SelectOption<MoodMeterDisplay | null> => {
        if (this.props.value && this.props.value.display) {
            return Select.getSelectOption(MoodMeterDisplayOptions.get(), this.props.value.display);
        }
        return MoodMeterDisplayOptions.get()[0];
    };

    private onDisplayChange = (displayOption: SelectOption<MoodMeterDisplay | null>): void => {
        if (displayOption.value !== null) {
            this.setState({ displayOption: displayOption.value });
            if (this.props.value === null) {
                this.props.onChange({ __typename: "MoodMeterDisplayCongfig", display: displayOption.value, title: "" });
            } else {
                this.props.onChange({ ...this.props.value!, display: displayOption.value });
            }
        } else {
            this.setState({ displayOption: MoodMeterDisplayOptions.get()[0].value });
            this.props.onChange(null);
        }
    };

    private getCurrentOption = (): MoodMeterQuestion | null | undefined => {
        if (!this.props.value) {
            return null;
        }

        if (this.props.value.title === null || this.props.value.title === Intl.formatMessage({ id: "enum.moodMeterQuestion.null" })) {
            return null;
        }

        const res = IntlHelpers.fromI18nToEnum<MoodMeterQuestion>(MoodMeterQuestion, this.props.value?.title, "enum.moodMeterQuestion");
        if ([null, MoodMeterQuestion.k1, MoodMeterQuestion.k2, MoodMeterQuestion.k3].includes(res!)) {
            return res || null;
        }
        return undefined;
    };

    private onQuestionSelected = (option: MoodMeterQuestionSelectOption): void => {
        let title: string | null = Intl.formatMessage({ id: `enum.moodMeterQuestion.${option.value}` });
        if (option.value === MoodMeterQuestion.k4) {
            title = "";
        }
        this.props.onChange({ ...this.props.value!, title });
    };

    public render(): React.ReactElement<any> {
        const selectOptions = MoodMeterQuestionSelectOptions.getForContent();
        const currentOption = this.getCurrentOption();
        return (
            <>
                <InputWrapper id={this.props.testIds.display} inputLabel={this.localTranslation("display.label")} padded icon="fa-smile">
                    <Select options={MoodMeterDisplayOptions.get()} onChange={this.onDisplayChange} value={this.getDisplay()} disabled={this.props.disabled} />
                </InputWrapper>
                {this.state.displayOption !== null && (
                    <>
                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.question.label" })} padded>
                            <Select
                                options={selectOptions}
                                value={Select.getSelectOption(selectOptions, currentOption || (currentOption === null ? null : MoodMeterQuestion.k4))}
                                disabled={this.props.disabled}
                                onChange={this.onQuestionSelected}
                            />
                        </InputWrapper>

                        {currentOption !== null && ![null, MoodMeterQuestion.k1, MoodMeterQuestion.k2, MoodMeterQuestion.k3].includes(currentOption!) && (
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.customQuestion.label" })} padded>
                                <Input value={this.props.value?.title || ""} onChange={this.onTitleChange} placeholder={this.localTranslation("title.placeholder")} disabled={this.props.disabled} />
                            </InputWrapper>
                        )}
                    </>
                )}
            </>
        );
    }
}

export { MoodMeterDisplayConfigInput };

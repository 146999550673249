class ApiError extends Error {
    public readonly intlKey: string;
    public readonly code: ApiErrorCode;

    constructor(message: ApiErrorCode) {
        super(message);
        this.intlKey = `error.api.${message}`;
        this.code = message;
    }
}

/**
 * Error messages from Api excluded 'E_' started codes
 */
enum ApiErrorCode {
    UNKNOWN = "UNKNOWN",
    FORBIDDEN = "FORBIDDEN",
    UNAUTHORIZED = "UNAUTHORIZED",
    UNAUTHENTICATED = "UNAUTHENTICATED",
    VALIDATION = "VALIDATION",
    ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
    USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
    INVALID_RESPONSE = "INVALID_RESPONSE",
    NETWORK_ERROR = "NETWORK_ERROR",
    BAD_USER_INPUT = "BAD_USER_INPUT",
    ACCOUNT_ALREADY_ACTIVATED = "ACCOUNT_ALREADY_ACTIVATED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    NOT_FOUND = "NOT_FOUND",
    ACCOUNT_NOT_DELETABLE_CLIENT_EXIST = "ACCOUNT_NOT_DELETABLE_CLIENT_EXIST",
    FILE_TOO_BIG = "FILE_TOO_BIG",
    ACCOUNT_DISABLED = "ACCOUNT_DISABLED",
    ACCOUNT_NOT_ACTIVATED = "ACCOUNT_NOT_ACTIVATED",
    ACCOUNT_NOT_DISABLED = "ACCOUNT_NOT_DISABLED",
    CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND",
    ASSET_NOT_FOUND = "ASSET_NOT_FOUND",
    CLIENT_PRIZE_NOT_FOUND = "CLIENT_PRIZE_NOT_FOUND",
    CLIENT_ACCOUNT_RELATION_NOT_FOUND = "CLIENT_ACCOUNT_RELATION_NOT_FOUND",
    AGENDA_NOT_FOUND = "AGENDA_NOT_FOUND",
    AGENDA_ITEM_NOT_FOUND = "AGENDA_ITEM_NOT_FOUND",
    FLOWCHART_NOT_FOUND = "FLOWCHART_NOT_FOUND",
    FLOWCHART_ITEM_NOT_FOUND = "FLOWCHART_ITEM_NOT_FOUND",
    PRIZE_NOT_FOUND = "PRIZE_NOT_FOUND",
    CHECKLIST_ITEM_NOT_FOUND = "CHECKLIST_ITEM_NOT_FOUND",
    CHECKLIST_ITEMS_NOT_FOUND = "CHECKLIST_ITEMS_NOT_FOUND",
    PRIZE_ITEM_NOT_FOUND = "PRIZE_ITEM_NOT_FOUND",
    DUPLICATED_POSITIONS = "DUPLICATED_POSITIONS",
    SEARCH_LIST_TYPE_NOT_FOUND = "SEARCH_LIST_TYPE_NOT_FOUND",
    SHARE_REQUEST_ALREADY_CREATED = "SHARE_REQUEST_ALREADY_CREATED",
    AGENDA_ASSIGNED_TO_DATE = "AGENDA_ASSIGNED_TO_DATE",
    CLIENT_DAY_AGENDA_ALREADY_EXISTS = "CLIENT_DAY_AGENDA_ALREADY_EXISTS",
    DEVICE_NOT_FOUND = "DEVICE_NOT_FOUND",
    AGENDA_DISABLED = "AGENDA_DISABLED",
    AGENDA_ITEM_DISABLED = "AGENDA_ITEM_DISABLED",
    FLOWCHART_DISABLED = "FLOWCHART_DISABLED",
    FLOWCHART_ITEM_DISABLED = "FLOWCHART_ITEM_DISABLED",

    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",

    ACCOUNT_NOT_DELETABLE_CLIENT_EXISTS = "ACCOUNT_NOT_DELETABLE_CLIENT_EXISTS",
    AGENDA_NOT_DISABLED = "AGENDA_NOT_DISABLED",
    AGENDA_ITEM_NOT_DISABLED = "AGENDA_ITEM_NOT_DISABLED",
    FLOWCHART_NOT_DISABLED = "FLOWCHART_NOT_DISABLED",
    FLOWCHART_ITEM_NOT_DISABLED = "FLOWCHART_ITEM_NOT_DISABLED",
    ALREADY_PUBLISHED = "ALREADY_PUBLISHED",
    AGENDA_ITEM_TYPE_NOT_SIMPLE = "AGENDA_ITEM_TYPE_NOT_SIMPLE",
    TOO_FEW_PROPERTY = "TOO_FEW_PROPERTY",
    SELECTION_BOARD_NOT_FOUND = "SELECTION_BOARD_NOT_FOUND",
    SELECTION_BOARD_ITEM_NOT_FOUND = "SELECTION_BOARD_ITEM_NOT_FOUND",
    TOO_MUCH_SELECTION_BOARD_ITEMS = "TOO_MUCH_SELECTION_BOARD_ITEMS",
    TOO_FEW_SELECTION_BOARD_ITEMS = "TOO_FEW_SELECTION_BOARD_ITEMS",
    STORAGE_SIZE_LIMIT_EXCEEDED = "STORAGE_SIZE_LIMIT_EXCEEDED",

    NOTIFICATION_CONTENT_TYPE_NOT_FOUND = "NOTIFICATION_CONTENT_TYPE_NOT_FOUND",
    DELETE_DEFAULT_AGENDA_NOT_ALLOWED = "DELETE_DEFAULT_AGENDA_NOT_ALLOWED",
    DELETE_AGENDA_ITEM_FLOWCHART_NOT_ALLOWED = "DELETE_AGENDA_ITEM_FLOWCHART_NOT_ALLOWED",
    DELETE_RELATED_AGENDA_ITEM_NOT_ALLOWED = "DELETE_RELATED_AGENDA_ITEM_NOT_ALLOWED",
    DELETE_ASSET_NOT_ALLOWED = "DELETE_ASSET_NOT_ALLOWED",
    CLIENT_CHANGE_MANAGER_REQUEST_NOT_FOUND = "CLIENT_CHANGE_MANAGER_REQUEST_NOT_FOUND",
    CLIENT_CHANGE_MANAGER_REQUEST_ALREADY_CREATED = "CLIENT_CHANGE_MANAGER_REQUEST_ALREADY_CREATED",

    EVERYDAY_SITUATION_NOT_FOUND = "EVERYDAY_SITUATION_NOT_FOUND",
    EVERYDAY_SITUATION_DIRECTORY_NOT_FOUND = "EVERYDAY_SITUATION_DIRECTORY_NOT_FOUND",
    EVERYDAY_SITUATION_NOT_ALLOWED_IN_CLIENT_DIRECTORY = "EVERYDAY_SITUATION_NOT_ALLOWED_IN_CLIENT_DIRECTORY",
    EVERYDAY_SITUATION_NOT_ALLOWED_OUTSIDE_CLIENT_DIRECTORY = "EVERYDAY_SITUATION_NOT_ALLOWED_OUTSIDE_CLIENT_DIRECTORY",
    EVERYDAY_SITUATION_DIRECTORY_INVALID_FLAGS = "EVERYDAY_SITUATION_DIRECTORY_INVALID_FLAGS",

    FLOWCHART_USED_IN_SORTING_GAME = "FLOWCHART_USED_IN_SORTING_GAME",
    FLOWCHART_NOT_USABLE_IN_SORTING_GAME = "FLOWCHART_NOT_USABLE_IN_SORTING_GAME",
    FLOWCHART_ITEM_TOO_FEW_FOR_SORTING_GAME = "FLOWCHART_ITEM_TOO_FEW_FOR_SORTING_GAME",
    FLOWCHART_ITEM_TOO_MANY_FOR_SORTING_GAME = "FLOWCHART_ITEM_TOO_MANY_FOR_SORTING_GAME",
    FLOWCHART_ITEM_DOES_NOT_HAVE_IMAGE = "FLOWCHART_ITEM_DOES_NOT_HAVE_IMAGE",
    FLOWCHART_CONTAINS_BRANCH = "FLOWCHART_CONTAINS_BRANCH",
    FLOWCHART_INVALID_BRANCH_ITEM_POSITION = "FLOWCHART_INVALID_BRANCH_ITEM_POSITION",
    FLOWCHART_EMBED_NOT_ALLOWED = "FLOWCHART_EMBED_NOT_ALLOWED",
    FLOWCHART_ITEM_TYPE_CHANGE_NOT_ALLOWED = "FLOWCHART_ITEM_TYPE_CHANGE_NOT_ALLOWED",
    FLOWCHART_ITEM_TYPE_NOT_SELECT = "FLOWCHART_ITEM_TYPE_NOT_SELECT",
    FLOWCHART_ITEM_TYPE_NOT_SIMPLE = "FLOWCHART_ITEM_TYPE_NOT_SIMPLE",
    FLOWCHART_ONLY_ONE_EMBEDDED_FLOWCHART_ENABLED = "FLOWCHART_ONLY_ONE_EMBEDDED_FLOWCHART_ENABLED",
    FLOWCHART_ONLY_ONE_SELECTION_POINT_ENABLED = "FLOWCHART_ONLY_ONE_SELECTION_POINT_ENABLED",

    LIBRARY_CONTENT_CAN_NOT_BE_SHARED = "LIBRARY_CONTENT_CAN_NOT_BE_SHARED",
    LIBRARY_CONTENT_NOT_FOUND = "LIBRARY_CONTENT_NOT_FOUND",
    LIBRARY_CONTENT_SHARE_REQUEST_NOT_FOUND = "LIBRARY_CONTENT_SHARE_REQUEST_NOT_FOUND",
    LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_CREATED = "LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_CREATED",
    LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_ACCEPTED = "LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_ACCEPTED",
    LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_REJECTED = "LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_REJECTED",

    TAG_ALREADY_EXISTS = "TAG_ALREADY_EXISTS",
    TAG_NOT_FOUND = "TAG_NOT_FOUND",

    AGENDA_ITEM_NOT_USABLE_FOR_SPARE = "AGENDA_ITEM_NOT_USABLE_FOR_SPARE",
    AGENDA_ITEM_TYPE_CHANGE_NOT_ALLOWED = "AGENDA_ITEM_TYPE_CHANGE_NOT_ALLOWED",
    AGENDA_ITEM_TYPE_NOT_SELECT = "AGENDA_ITEM_TYPE_NOT_SELECT",
    ASSET_DIRECTORY_INVALID_PARENT = "ASSET_DIRECTORY_INVALID_PARENT",
    ASSET_DIRECTORY_NOT_EMPTY = "ASSET_DIRECTORY_NOT_EMPTY",
    ASSET_DIRECTORY_NOT_FOUND = "ASSET_DIRECTORY_NOT_FOUND",
    ASSET_DIRECTORY_TOO_DEEP = "ASSET_DIRECTORY_TOO_DEEP",
    ASSET_DISABLED = "ASSET_DISABLED",
    ASSET_NOT_ALLOWED_TO_REPLACE = "ASSET_NOT_ALLOWED_TO_REPLACE",
    ASSET_NOT_DISABLED = "ASSET_NOT_DISABLED",
    AWARD_DISABLED = "AWARD_DISABLED",
    AWARD_NOT_DISABLED = "AWARD_NOT_DISABLED",
    AWARD_NOT_FOUND = "AWARD_NOT_FOUND",
    CLIENT_ALREADY_SELF_SUPPORTING = "CLIENT_ALREADY_SELF_SUPPORTING",
    CLIENT_CHANGE_NOT_ALLOWED = "CLIENT_CHANGE_NOT_ALLOWED",
    CLIENT_PROFILE_CLASSIFICATION_FAILED = "CLIENT_PROFILE_CLASSIFICATION_FAILED",
    CLIENT_SHARE_REQUEST_ALREADY_CREATED = "CLIENT_SHARE_REQUEST_ALREADY_CREATED",
    CLIENT_TOO_YOUNG_FOR_SELF_SUPPORTING_DECISION = "CLIENT_TOO_YOUNG_FOR_SELF_SUPPORTING_DECISION",
    DELETE_AGENDA_ITEM_SELECTION_BOARD_NOT_ALLOWED = "DELETE_AGENDA_ITEM_SELECTION_BOARD_NOT_ALLOWED",
    DELETE_EVERYDAY_SITUATION_ASSET_NOT_ALLOWED = "DELETE_EVERYDAY_SITUATION_ASSET_NOT_ALLOWED",
    DELETE_EVERYDAY_SITUATION_CLIENT_DIRECTORY_NOT_ALLOWED = "DELETE_EVERYDAY_SITUATION_CLIENT_DIRECTORY_NOT_ALLOWED",
    DELETE_EVERYDAY_SITUATION_NOT_ALLOWED = "DELETE_EVERYDAY_SITUATION_NOT_ALLOWED",
    DELETE_FLOWCHART_ITEM_FLOWCHART_NOT_ALLOWED = "DELETE_FLOWCHART_ITEM_FLOWCHART_NOT_ALLOWED",
    DELETE_SORTING_GAME_FLOWCHART_NOT_ALLOWED = "DELETE_SORTING_GAME_FLOWCHART_NOT_ALLOWED",
    DELETE_SPARE_AGENDA_ITEM_NOT_ALLOWED = "DELETE_SPARE_AGENDA_ITEM_NOT_ALLOWED",
    DELETE_RELATED_FLOWCHART_ITEM_NOT_ALLOWED = "DELETE_RELATED_FLOWCHART_ITEM_NOT_ALLOWED",
    DISABLED_CONTENT_LIST_TYPE_NOT_FOUND = "DISABLED_CONTENT_LIST_TYPE_NOT_FOUND",
    DISABLED_ENTITY_IN_SYNC_DATA = "DISABLED_ENTITY_IN_SYNC_DATA",
    EDUCATION_CONTENT_NOT_FOUND = "EDUCATION_CONTENT_NOT_FOUND",
    EDUCATION_CONTENT_MODULE_NOT_FOUND = "EDUCATION_CONTENT_MODULE_NOT_FOUND",
    EDUCATION_CONTENT_MODULE_TOO_DEEP = "EDUCATION_CONTENT_MODULE_TOO_DEEP",
    EDUCATION_CONTENT_MODULE_NOT_MODIFIABLE = "EDUCATION_CONTENT_MODULE_NOT_MODIFIABLE",
    EDUCATION_CONTENT_MODULE_NOT_DELETABLE = "EDUCATION_CONTENT_MODULE_NOT_DELETABLE",
    EDUCATION_CONTENT_MODULE_NOT_IN_PARENT_MODULE = "EDUCATION_CONTENT_MODULE_NOT_IN_PARENT_MODULE",
    EDUCATION_CONTENT_NOT_IN_MODULE = "EDUCATION_CONTENT_NOT_IN_MODULE",
    EVERYDAY_SITUATION_DIRECTORY_DISABLED = "EVERYDAY_SITUATION_DIRECTORY_DISABLED",
    EVERYDAY_SITUATION_DIRECTORY_NOT_DISABLED = "EVERYDAY_SITUATION_DIRECTORY_NOT_DISABLED",
    EVERYDAY_SITUATION_DIRECTORY_ORDERING_NOT_ALLOWED = "EVERYDAY_SITUATION_DIRECTORY_ORDERING_NOT_ALLOWED",
    EVERYDAY_SITUATION_DISABLED = "EVERYDAY_SITUATION_DISABLED",
    EVERYDAY_SITUATION_NOT_DISABLED = "EVERYDAY_SITUATION_NOT_DISABLED",
    FAQ_ITEM_NOT_FOUND = "FAQ_ITEM_NOT_FOUND",
    FLOWCHART_SELECT_ITEM_WITH_INVALID_BRANCH = "FLOWCHART_SELECT_ITEM_WITH_INVALID_BRANCH",
    INSTANT_AWARD_DISABLED = "INSTANT_AWARD_DISABLED",
    INSTANT_AWARD_NOT_DISABLED = "INSTANT_AWARD_NOT_DISABLED",
    INSTANT_AWARD_NOT_FOUND = "INSTANT_AWARD_NOT_FOUND",
    INSTANT_AWARD_ALLOWED_ONLY_IN_CLIENT_LIBRARY = "INSTANT_AWARD_ALLOWED_ONLY_IN_CLIENT_LIBRARY",
    INVALID_ASSET_TYPE = "INVALID_ASSET_TYPE",
    INVALID_EDUCATION_CONTENT_TYPE = "INVALID_EDUCATION_CONTENT_TYPE",
    INVALID_AUDIO_CODEC = "INVALID_AUDIO_CODEC",
    INVALID_CLIENT_QUESTIONNAIRE_ANSWERS = "INVALID_CLIENT_QUESTIONNAIRE_ANSWERS",
    INVALID_ITEM_ASSET_CONFIGURATION = "INVALID_ITEM_ASSET_CONFIGURATION",
    INVALID_POSITION = "INVALID_POSITION",
    INVALID_VIDEO_RESOLUTION = "INVALID_VIDEO_RESOLUTION",
    SELECTION_BOARD_EMBED_NOT_ALLOWED = "SELECTION_BOARD_EMBED_NOT_ALLOWED",
    SELECTION_BOARD_DISABLED = "SELECTION_BOARD_DISABLED",
    SELECTION_BOARD_ITEM_NO_CONTENT = "SELECTION_BOARD_ITEM_NO_CONTENT",
    SELECTION_BOARD_NOT_DISABLED = "SELECTION_BOARD_NOT_DISABLED",
    SELF_SUPPORTING_STATE_ALREADY_DECIDED = "SELF_SUPPORTING_STATE_ALREADY_DECIDED",
}

export { ApiError, ApiErrorCode };

import React, { PureComponent } from "react";
import { DirectoryList } from "../../models/ContentLibraryDirectories";
import { Intl } from "../../i18n/Intl";
import { SearchListType } from "../../api/graphql/types";

interface Props {
    directories: DirectoryList[];
    onDirectoryClick: (assetDirectoryId: string | null) => void;
    selectedDirectory: string | null;
}

class DirectorySidebar extends PureComponent<Props> {
    private isDirectoryActive = (directory: DirectoryList): boolean => {
        return directory.id === this.props.selectedDirectory;
    };

    private isDirectoryHasActiveChild = (directories: DirectoryList[]): boolean => {
        let isActive: boolean = false;
        directories.forEach((directory: DirectoryList) => {
            if (this.isDirectoryActive(directory)) {
                isActive = true;
            }
            if (directory.children) {
                isActive = this.isDirectoryHasActiveChild(directory.children);
            }
        });
        return isActive;
    };

    public readonly renderDirectories = (items: DirectoryList[], is_active?: boolean): React.ReactElement => {
        return (
            <ul className={`subdir ${is_active || (this.isDirectoryHasActiveChild && this.isDirectoryHasActiveChild(items)) ? "open" : ""}`}>
                {items.map((item: DirectoryList, key) => {
                    return (
                        <li
                            key={key}
                            className={`${item.children ? "directory" : ""} ${
                                this.isDirectoryActive && this.isDirectoryActive(item)
                                    ? "active"
                                    : item.children && this.isDirectoryHasActiveChild && this.isDirectoryHasActiveChild(item.children)
                                    ? "open"
                                    : ""
                            }`}
                        >
                            <a className={`${item.children ? "has-children" : ""}`} onClick={() => this.props.onDirectoryClick(item.id)}>
                                {item.name}
                            </a>
                            {item.children ? this.renderDirectories(item.children, this.isDirectoryActive(item)) : null}
                        </li>
                    );
                })}
            </ul>
        );
    };

    public render(): React.ReactElement {
        return (
            <div className="link-group">
                <ul>
                    <li className="directory active open">
                        <a className="has-children" onClick={() => this.props.onDirectoryClick(null)}>
                            {Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${SearchListType.asset}` })}
                        </a>
                        {this.renderDirectories(this.props.directories, true)}
                    </li>
                </ul>
            </div>
        );
    }
}

export { DirectorySidebar };

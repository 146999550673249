import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteAssetDialogProps {
    onDeleted: () => void;
    asset: { id: string; title: string } | null;
}

type Props = DeleteAssetDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class DeleteAssetDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private deleteAsset = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                if (this.props.asset) {
                    try {
                        await Api.deleteAsset(this.props.asset.id);
                        Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.deleteAssetDialog.succeed" }) });
                        this.props.onHide();
                        this.props.onDeleted();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }

                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible && !!this.props.asset}
                title={Intl.formatMessage({ id: "sharedComponent.deleteAssetDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <p>{Intl.formatMessage({ id: "sharedComponent.deleteAssetDialog.description" }, { title: this.props.asset ? this.props.asset.title : "" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button className="btn-danger btn--text-large fw-700" label={Intl.formatMessage({ id: "common.delete" })} onClick={this.deleteAsset} disabled={this.state.isLoading} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const DeleteAssetDialog: React.ComponentClass<Props> = DeleteAssetDialogComponent;

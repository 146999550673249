import React, { Component } from "react";
import { Tabs, TabList, Tab as ReactTab, TabPanel as ReactTabPanel } from "react-tabs";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import "./TabPanel.scss";

interface Tab<AdditionalProp> {
    id: string;
    to: string;
    label: string | React.ReactElement;
    component: React.ComponentClass<any>;
    additionalProps?: AdditionalProp;
}

interface ComponentProps<AdditionalProp> {
    tabs: Array<Tab<AdditionalProp>>;
}

type Props<AdditionalProp> = ComponentProps<AdditionalProp> & RouteComponentProps & { tabListClassName?: string };

interface State {}

class TabPanelComponent<AdditionalProp> extends Component<Props<AdditionalProp>, State> {
    public state: State = {};

    private getSelectedTabIndex(props: Props<AdditionalProp>): number {
        const currentIndex: number = props.tabs.findIndex((tab: Tab<AdditionalProp>) => tab.to === this.props.location.pathname);
        return currentIndex === -1 ? 0 : currentIndex;
    }

    private onTabSelected = (): void => {};

    public render(): React.ReactElement<any> {
        return (
            <div className="inside-tabs">
                <Tabs selectedIndex={this.getSelectedTabIndex(this.props)} onSelect={this.onTabSelected} selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
                    <TabList className={this.props.tabListClassName}>
                        {this.props.tabs.map(
                            (tab: Tab<AdditionalProp>): React.ReactElement<any> => {
                                return (
                                    <ReactTab key={tab.id}>
                                        <Link id={tab.id} to={tab.to}>
                                            <div className="small-text tab-panel-label">{tab.label}</div>
                                        </Link>
                                    </ReactTab>
                                );
                            },
                        )}
                    </TabList>

                    {this.props.tabs.map(
                        (tab: Tab<AdditionalProp>): React.ReactElement<any> => {
                            return (
                                <ReactTabPanel key={tab.id}>
                                    <div className="content-center">
                                        <tab.component {...tab.additionalProps} />
                                    </div>
                                </ReactTabPanel>
                            );
                        },
                    )}
                </Tabs>
            </div>
        );
    }
}

export const TabPanel = withRouter(TabPanelComponent);

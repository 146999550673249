import React, { Component } from "react";
import { Client, ClientGamesConfig, FlowchartContent, Game, Flowchart, AssetType } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Section } from "components/Section";
import { Intl } from "i18n/Intl";
import { Loading, LoadingType } from "components/Loading/Loading";
import { CardNotification } from "components/CardNotification/CardNotification";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";
import { FlowchartInput } from "../_shared/Draggables/Input/FlowchartInput";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { TestId } from "utils/TestId";
import { Prompt } from "components/Prompt";
import { Path } from "utils/Path";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { ContentElement } from "pages/_shared/Draggables/ContentElement/ContentElement";
import { ImageSrc } from "utils/ImageSrc";
import { ApiTypes } from "api/ApiTypes";
import { ContentElementUtils } from "pages/_shared/Draggables/ContentElement/ContentElementUtils";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { OverlayIcon } from "components/OverlayIcon";

interface RouteParams {
    clientExtId: string;
}

interface ComponentProps {
    client: Client;
    gameConfig: ClientGamesConfig;
    onDragIdChange: (dragIds: string[]) => void;
}

type Props = ComponentProps & NotificationProp & RouteComponentProps<RouteParams>;

interface State {
    flowcharts: Flowchart[];
    currentFlowcharts: FlowchartContent[];
    isLoading: boolean;
}

class SortingGameTabComponent extends Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            flowcharts: [],
            currentFlowcharts: [],
            isLoading: true,
        };
    }

    componentDidMount(): void {
        this.fetchConfig();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.client.id !== prevProps.client.id) {
            this.fetchConfig();
        }
    }

    private readonly fetchConfig = async () => {
        try {
            const flowcharts = await Api.getClientSortingGameConfig(this.props.client!.id);
            this.setState(
                {
                    flowcharts,
                    currentFlowcharts: [...flowcharts],
                    isLoading: false,
                },
                () => {
                    this.props.onDragIdChange(this.state.currentFlowcharts.map(flowchart => flowchart.id));
                },
            );
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    };

    private readonly isEnabled = (): boolean => {
        return !isNil(this.props.gameConfig.enabledGames?.find(game => game.name === Game.sorting));
    };

    private readonly onAddFlowchart = (flowchartContent: FlowchartContent[]) => {
        const { currentFlowcharts: currentSortingGameConfig } = this.state;
        const flowchart: FlowchartContent | null = flowchartContent[0] || null;
        if (flowchart) {
            if (!flowchart.client) {
                Alert.error({ title: Intl.formatMessage({ id: "page.clientGames.messages.flowchartNotAssignedToClient.title" }) });
                return;
            }
            if (!flowchart.usableInSortingGame) {
                Alert.error({ title: Intl.formatMessage({ id: "page.clientGames.messages.flowchartNotUsableInSortingGame.title" }) });
                return;
            }

            this.setState(
                {
                    currentFlowcharts: [...currentSortingGameConfig, flowchart],
                },
                () => {
                    this.props.onDragIdChange(this.state.currentFlowcharts.map(flowchart => flowchart.id));
                },
            );
        }
    };

    private readonly viewFlowchart = (flowchartId: string): void => {
        this.props.history.push(Path.viewFlowchart(flowchartId));
    };

    private readonly editFlowchart = (flowchartId: string): void => {
        this.props.history.push(Path.editFlowchart(flowchartId));
    };

    private readonly removeFlowchart = (flowchartId: string): void => {
        this.setState(
            {
                currentFlowcharts: this.state.currentFlowcharts.filter(flowchart => flowchart.id !== flowchartId),
            },
            () => {
                this.props.onDragIdChange(this.state.currentFlowcharts.map(flowchart => flowchart.id));
            },
        );
    };

    private readonly isChanged = (): boolean => {
        return !isEqual(this.state.flowcharts, this.state.currentFlowcharts);
    };

    private readonly onCancelClick = () => {
        this.setState(
            {
                currentFlowcharts: this.state.flowcharts,
            },
            () => {
                this.props.onDragIdChange(this.state.flowcharts.map(flowchart => flowchart.id));
            },
        );
    };

    private readonly onSaveClick = () => {
        const { client } = this.props;

        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const flowcharts = await Api.updateClientSortingGameConfig(
                        client.id,
                        this.state.currentFlowcharts.map(flowchart => flowchart.id),
                    );
                    this.setState({
                        flowcharts,
                        currentFlowcharts: flowcharts,
                        isLoading: false,
                    });
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientGames.sortingTab.succeed" }) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly renderFlowchart = (flowchart: FlowchartContent) => {
        return (
            <ContentElement
                key={flowchart.id}
                title={flowchart.title}
                fallbackImage={ImageSrc.flowchart}
                images={flowchart.image ? [ApiTypes.getAssetImageUrl(flowchart.image)] : []}
                meta={Intl.formatMessage({ id: "enum.searchListType.flowchart" })}
                contentChildren={ContentElementUtils.getFlowchartFlowchartItems(flowchart as any)}
                reorder={{ call: () => {}, upEnabled: false, downEnabled: false }}
                onViewClick={() => this.viewFlowchart(flowchart.id)}
                onEditClick={() => this.editFlowchart(flowchart.id)}
                onCloseClick={() => this.removeFlowchart(flowchart.id)}
                onPreviewClick={
                    flowchart.image
                        ? (): void => {
                              store.dispatch(
                                  DialogsActions.show({
                                      type: DialogType.showAsset,
                                      assetUrl: flowchart.image?.url,
                                      assetType: flowchart.image?.assetType || AssetType.image,
                                      originalFileName: flowchart.image?.title,
                                      thumbnailUrl: flowchart.image?.thumbnailUrl,
                                      dialogTitle: flowchart.image?.title || Intl.formatMessage({ id: `enum.assetType.${flowchart.image?.assetType}` }),
                                  }),
                              );
                          }
                        : undefined
                }
                overlayIcon={flowchart.image ? <OverlayIcon assetType={flowchart.image.assetType} /> : undefined}
            />
        );
    };

    private readonly renderContent = (): React.ReactElement => {
        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);
        return (
            <>
                <p>{Intl.formatMessage({ id: "page.clientGames.sortingTab.description" }, { name: clientShortName })}</p>
                {this.isEnabled() || (
                    <CardNotification
                        title={Intl.formatMessage({ id: "page.clientGames.messages.gameNotEnabled.title" }, { name: this.props.client.name })}
                        description={Intl.formatMessage({ id: "page.clientGames.messages.gameNotEnabled.description" }, { name: this.props.client.name })}
                        type={"warning"}
                        className="mb-20"
                    />
                )}
                {this.state.currentFlowcharts.map(this.renderFlowchart)}
                <FlowchartInput
                    droppableId="flowchartId"
                    maxItemCount={1}
                    value={[]}
                    onChange={this.onAddFlowchart}
                    disabled={false}
                    onViewClick={(flowchart: FlowchartContent) => {
                        this.props.history.push(Path.viewFlowchart(flowchart.id));
                    }}
                    onEditClick={(flowchart: FlowchartContent) => {
                        this.props.history.push(Path.editFlowchart(flowchart.id));
                    }}
                    hasError={false}
                    slotMessageOverride={Intl.formatMessage({ id: "page.clientGames.sortingTab.slotMessage" })}
                />
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} id={TestId.clientGames.sortingGameTab.cancelButton} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button label={Intl.formatMessage({ id: "common.save" })} id={TestId.clientGames.sortingGameTab.saveButton} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>

                <Prompt when={this.isChanged()} />
            </>
        );
    };

    public render(): React.ReactElement {
        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientGames.sortingTab.title" }, { name: this.props.client.name })}>
                    {this.state.isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}
                </Section>
            </>
        );
    }
}

export const SortingGameTab = withNotification(withRouter(SortingGameTabComponent));

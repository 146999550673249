import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Button } from "components/Button/Button";
import { Api } from "api/Api";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { ClientProfileData, ClientProfileData_introductionAudio, ClientProfileData_introductionVideo, AssetType, Asset } from "api/graphql/types";
import { NotificationType } from "components/NotificationBar/Notification";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { Prompt } from "components/Prompt";
import { UploadAssetForm } from "components/UploadAssetForm";

interface ComponentProps {
    clientId: string;
    introduction: string;
    introductionAudio: ClientProfileData_introductionAudio | null;
    introductionVideo: ClientProfileData_introductionVideo | null;
    onIntroductionChange: (client: ClientProfileData) => void;
    onIntroductionAudioUploaded: (audio: Asset | null) => void;
    onIntroductionAudioDeleted: () => void;
    onIntroductionVideoUploaded: (video: Asset | null) => void;
    onIntroductionVideoDeleted: () => void;
}

type Props = ComponentProps & NotificationProp;

interface State {
    isEditable: boolean;
    introduction: string;
    formErrors: {
        introduction: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ClientProfileIntroductionFormComponent extends Component<Props, State> {
    private introductionRef: HTMLTextAreaElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            introduction: this.props.introduction,
            formErrors: {
                introduction: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onIntroductionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const introduction: string = event.target.value;
        const introductionError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateClientIntroduction(introduction)) : null;
        this.setState({ introduction, formErrors: { ...this.state.formErrors, introduction: introductionError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { introduction } = this.state;
        const formErrors = {
            introduction: IntlHelpers.getValidationError(Validator.validateClientIntroduction(introduction)),
        };

        if (formErrors.introduction) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.introduction && this.introductionRef) {
                    this.introductionRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const client: ClientProfileData = await Api.updateClient(this.props.clientId, { introduction });
                    this.props.onIntroductionChange(client);
                    this.props.showNotification({
                        type: NotificationType.success,
                        message: Intl.formatMessage({ id: "page.clientProfile.introductionSection.introductionForm.introductionChangeSucceed" }),
                    });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onAudioFileSelected = async (file: File, onProgressChange: (progress: number) => void): Promise<void> => {
        try {
            const audio: Asset | null = await Api.uploadClientIntroductionAudio(this.props.clientId, file, onProgressChange);
            this.props.onIntroductionAudioUploaded(audio);
            this.props.showNotification({
                type: NotificationType.success,
                message: Intl.formatMessage(
                    { id: "page.clientProfile.introductionSection.introductionForm.uploadSucceed" },
                    { assetType: Intl.formatMessage({ id: "enum.assetType.introduction_audio" }) },
                ),
            });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onVideoFileSelected = async (file: File, onProgressChange: (progress: number) => void): Promise<void> => {
        try {
            const video: Asset | null = await Api.uploadClientIntroductionVideo(this.props.clientId, file, onProgressChange);
            this.props.onIntroductionVideoUploaded(video);
            this.props.showNotification({
                type: NotificationType.success,
                message: Intl.formatMessage(
                    { id: "page.clientProfile.introductionSection.introductionForm.uploadSucceed" },
                    { assetType: Intl.formatMessage({ id: "enum.assetType.introduction_video" }) },
                ),
            });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onAudioFileDeleted = async (): Promise<void> => {
        try {
            await Api.deleteClientIntroductionAudio(this.props.clientId);
            this.props.onIntroductionAudioDeleted();
            this.props.showNotification({
                type: NotificationType.success,
                message: Intl.formatMessage(
                    { id: "page.clientProfile.introductionSection.introductionForm.deleteSucceed" },
                    { assetType: Intl.formatMessage({ id: "enum.assetType.introduction_audio" }) },
                ),
            });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onVideoFileDeleted = async (): Promise<void> => {
        try {
            await Api.deleteClientIntroductionVideo(this.props.clientId);
            this.props.onIntroductionVideoDeleted();
            this.props.showNotification({
                type: NotificationType.success,
                message: Intl.formatMessage(
                    { id: "page.clientProfile.introductionSection.introductionForm.deleteSucceed" },
                    { assetType: Intl.formatMessage({ id: "enum.assetType.introduction_video" }) },
                ),
            });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, introduction, formErrors, isLoading } = this.state;

        return (
            <form id={TestId.clientProfile.introductionForm} noValidate={true} onSubmit={this.onSubmit}>
                <InputWrapper
                    id={TestId.clientProfile.introductionInput}
                    inputLabel={Intl.formatMessage({ id: "page.clientProfile.introductionSection.introductionForm.introduction.label" })}
                    errorMessage={formErrors.introduction}
                >
                    <TextArea
                        innerRef={(ref: HTMLTextAreaElement | null): void => {
                            this.introductionRef = ref;
                        }}
                        hasError={!!formErrors.introduction}
                        placeholder={Intl.formatMessage({ id: "page.clientProfile.introductionSection.introductionForm.introduction.placeholder" })}
                        value={introduction}
                        onChange={this.onIntroductionChange}
                        disabled={!isEditable}
                    />
                </InputWrapper>

                <UploadAssetForm type={AssetType.introduction_audio} asset={this.props.introductionAudio} onFileSelected={this.onAudioFileSelected} deleteAsset={this.onAudioFileDeleted} />

                <UploadAssetForm type={AssetType.introduction_video} asset={this.props.introductionVideo} onFileSelected={this.onVideoFileSelected} deleteAsset={this.onVideoFileDeleted} />

                {isEditable ? (
                    <div className="action-button-container">
                        <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            type="submit"
                            disabled={isLoading || !!formErrors.introduction || introduction === this.props.introduction}
                        />
                    </div>
                ) : (
                    <div className="edit-button">
                        <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                    </div>
                )}
                <Prompt when={introduction !== this.props.introduction} />
            </form>
        );
    }
}

const ClientProfileIntroductionForm = withNotification(ClientProfileIntroductionFormComponent);

export { ClientProfileIntroductionForm };

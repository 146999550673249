import gql from "graphql-tag";
import { DocumentNode } from "graphql";

class Fragments {
    public static readonly atomicAsset: DocumentNode = gql`
        fragment AtomicAsset on Asset {
            id
            title
            url
            thumbnailUrl
            disabledAt
            publishedAt
            originalFileName
            assetType
        }
    `;

    public static readonly asset: DocumentNode = gql`
        fragment Asset on Asset {
            id
            title
            url
            thumbnailUrl
            originalFileName
            assetType
            disabledAt
            deletable
            publishedAt
            createdBy {
                id
            }
            tags
        }
    `;

    public static readonly libraryContentShareRequest: DocumentNode = gql`
        fragment LibraryContentShareRequest on LibraryContentShareRequest {
            id
            sharedWithAccount {
                id
                extId
                email
            }
            comment
            status
            createdAt
        }
    `;

    public static readonly device: DocumentNode = gql`
        fragment Device on Device {
            id
            deviceType
            info {
                userAgent
            }
            agendaDataLastDownloadedAt
            clientDataLastDownloadedAt
            createdAt
            updatedAt
        }
    `;

    public static readonly clientChangeManagerRequest: DocumentNode = gql`
        fragment ClientChangeManagerRequest on ClientChangeManagerRequest {
            id
            createdBy {
                id
                name
            }
            newManager {
                id
            }
        }
    `;

    public static readonly instantAward: DocumentNode = gql`
        fragment InstantAward on InstantAward {
            id
            title
            image {
                ...Asset
            }
            referenceCount
        }
        ${Fragments.asset}
    `;

    public static readonly assetContent: DocumentNode = gql`
        fragment AssetContent on Asset {
            id
            title
            originalFileName
            url
            thumbnailUrl
            assetType
            disabledAt
            deletable
            publishedAt
            createdBy {
                id
            }
            tags
        }
    `;

    public static readonly selectionBoardItem: DocumentNode = gql`
        fragment SelectionBoardItem on SelectionBoardItem {
            id
            title
            image {
                ...AssetContent
            }
        }
        ${Fragments.assetContent}
    `;

    public static readonly selectionBoard: DocumentNode = gql`
        fragment SelectionBoard on SelectionBoard {
            id
            title
            backgroundColor
            itemCount
            items {
                ...SelectionBoardItem
            }
            image {
                ...Asset
            }
            disabledAt
            createdBy {
                id
            }
        }
        ${Fragments.selectionBoardItem}
        ${Fragments.asset}
    `;

    public static readonly selectionBoardInfo: DocumentNode = gql`
        fragment SelectionBoardInfo on SelectionBoard {
            id
            title
        }
    `;

    public static readonly client: DocumentNode = gql`
        fragment Client on Client {
            id
            extId
            avatar {
                ...Asset
            }
            introductionAudio {
                ...Asset
            }
            introductionVideo {
                ...Asset
            }
            name
            devices {
                result {
                    ...Device
                }
            }
            lastSyncDataGeneratedAt
            dateOfBirth
            defaultAgenda {
                id
            }
            pendingClientManagerChangeRequest {
                ...ClientChangeManagerRequest
            }
            awardScoreBalance
            instantAwards {
                result {
                    ...InstantAward
                }
            }
            selectionBoards {
                result {
                    ...SelectionBoard
                }
            }
        }
        ${Fragments.asset}
        ${Fragments.device}
        ${Fragments.clientChangeManagerRequest}
        ${Fragments.instantAward}
        ${Fragments.selectionBoard}
    `;

    public static readonly contentClient: DocumentNode = gql`
        fragment ContentClient on Client {
            id
            extId
            name
            lastSyncDataGeneratedAt
            devices {
                count
                result {
                    ...Device
                }
            }
        }
        ${Fragments.device}
    `;

    public static readonly supportedClient: DocumentNode = gql`
        fragment SupportedClient on Client {
            id
            extId
            avatar {
                ...Asset
            }
            defaultAgenda {
                id
                updatedAt
            }
            name
            dateOfBirth
            pendingClientManagerChangeRequest {
                ...ClientChangeManagerRequest
            }
            awardScoreBalance
            lastSyncDataGeneratedAt
            notificationAfterCreate
            showSelfSupportingStateDecisionNotification
            showSelfSupportingStateDecisionWarning
            showClientQuestionnaireEvaluationNotification
        }
        ${Fragments.asset}
        ${Fragments.clientChangeManagerRequest}
    `;

    public static readonly supporter: DocumentNode = gql`
        fragment Supporter on Account {
            id
            extId
            email
            name
            activatedAt
            disabledAt
        }
    `;

    public static readonly clientSupporter: DocumentNode = gql`
        fragment ClientSupporter on ClientSupporter {
            account {
                id
                extId
            }
            clientShareRequest {
                id
            }
            name
            email
            status
            createdAt
        }
    `;

    public static readonly moodMeterItem: DocumentNode = gql`
        fragment MoodMeterItem on MoodMeterItem {
            emotion
            name
            position
        }
    `;

    public static readonly moodMeter: DocumentNode = gql`
        fragment MoodMeter on MoodMeter {
            enabled
            title
            multiSelect
            intensity
            message
            items {
                ...MoodMeterItem
            }
            question
        }
        ${Fragments.moodMeterItem}
    `;

    public static readonly agendaConfig: DocumentNode = gql`
        fragment AgendaConfig on AgendaConfig {
            itemBackgroundColor
            colorsOfDays {
                mon
                tue
                wed
                thu
                fri
                sat
                sun
            }
            itemCheckBoxSize
            itemSize
            calendarView
            withoutDefaultAgenda {
                text
                image {
                    ...AssetContent
                }
            }
        }
        ${Fragments.assetContent}
    `;

    public static readonly unlock: DocumentNode = gql`
        fragment Unlock on Unlock {
            enabled
            colors
            pattern
            failMessage
        }
    `;

    public static readonly clientConfig: DocumentNode = gql`
        fragment ClientConfig on ClientConfig {
            enabledDashboardItems
            enabledGames {
                name
            }
            mainPageType
            mainPageBackgroundColor
            mainPageDataSyncEnabled
            agenda {
                ...AgendaConfig
            }
            flowchartListType
            flowchartItemBackgroundColor
            moodMeter {
                ...MoodMeter
            }
            timer {
                type
                requestConfirmation
            }
            selectionBoardListType
            award {
                requestConfirmation
            }
            unlock {
                ...Unlock
            }
            highContrastMode
        }
        ${Fragments.agendaConfig}
        ${Fragments.moodMeter}
        ${Fragments.unlock}
    `;

    public static readonly clientAppConfig: DocumentNode = gql`
        fragment ClientAppConfig on ClientConfig {
            agenda {
                ...AgendaConfig
            }
            flowchartItemBackgroundColor
            unlock {
                ...Unlock
            }
            highContrastMode
        }
        ${Fragments.agendaConfig}
        ${Fragments.unlock}
    `;

    public static readonly clientMainPageConfig: DocumentNode = gql`
        fragment ClientMainPageConfig on ClientConfig {
            mainPageType
            mainPageBackgroundColor
            mainPageDataSyncEnabled
            enabledDashboardItems
            enabledGames {
                name
            }
            moodMeter {
                enabled
            }
        }
    `;

    public static readonly clientContentsConfig: DocumentNode = gql`
        fragment ClientContentsConfig on ClientConfig {
            agenda {
                ...AgendaConfig
            }
            flowchartListType
        }
        ${Fragments.agendaConfig}
    `;

    public static readonly clientFunctionsConfig: DocumentNode = gql`
        fragment ClientFunctionsConfig on ClientConfig {
            moodMeter {
                ...MoodMeter
            }
            timer {
                type
                requestConfirmation
            }
            selectionBoardListType
            award {
                requestConfirmation
            }
            enabledDashboardItems
        }
        ${Fragments.moodMeter}
    `;

    public static readonly clientShareRequestClient: DocumentNode = gql`
        fragment ClientShareRequestClient on Client {
            id
            extId
            name
            dateOfBirth
            avatar {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly clientShareRequest: DocumentNode = gql`
        fragment ClientShareRequest on ClientShareRequest {
            id
            client {
                ...ClientShareRequestClient
            }
            createdBy {
                id
                name
                email
            }
            status
        }
        ${Fragments.clientShareRequestClient}
        ${Fragments.asset}
    `;

    public static readonly account: DocumentNode = gql`
        fragment Account on Account {
            id
            extId
            name
            email
            pin
            accountType
            avatar {
                ...Asset
            }
            myClients {
                result {
                    ...SupportedClient
                }
            }
            pendingClientShareRequests {
                count
                result {
                    ...ClientShareRequest
                }
            }
            storageSizeUsed
            config {
                storageSizeLimit
            }
            disabledReason
            disabledAt
            activatedAt
        }
        ${Fragments.asset}
        ${Fragments.supportedClient}
        ${Fragments.clientShareRequest}
    `;

    public static readonly storageSizeUsed: DocumentNode = gql`
        fragment StorageSizeUsed on Account {
            id
            storageSizeUsed
        }
    `;

    public static readonly authResponse: DocumentNode = gql`
        fragment AuthResponse on AuthResponse {
            authToken
            refreshToken
        }
    `;

    public static readonly clientQuestionnaireAnswerOption: DocumentNode = gql`
        fragment ClientQuestionnaireAnswerOption on ClientQuestionnaireAnswerOption {
            id
            title
        }
    `;

    public static readonly clientQuestionnaireQuestion: DocumentNode = gql`
        fragment ClientQuestionnaireQuestion on ClientQuestionnaireQuestion {
            id
            title
            description
            answerOptions {
                ...ClientQuestionnaireAnswerOption
            }
        }
        ${Fragments.clientQuestionnaireAnswerOption}
    `;

    public static readonly clientEventLog: DocumentNode = gql`
        fragment ClientEventLog on AppStatItem {
            type
            data
            device {
                ...Device
            }
            createdAt
        }
        ${Fragments.device}
    `;

    public static readonly assetListItem: DocumentNode = gql`
        fragment AssetListItem on Asset {
            id
            title
            originalFileName
            fileSize
            assetType
            deletable
            url
            thumbnailUrl
            createdAt
            disabledAt
            publishedAt
            createdBy {
                id
                name
            }
            tags
        }
    `;

    public static readonly adminListItem: DocumentNode = gql`
        fragment AdminListItem on Account {
            id
            extId
            name
            email
            activatedAt
            disabledAt
        }
    `;

    public static readonly supervisorListItem: DocumentNode = gql`
        fragment SupervisorListItem on Account {
            id
            extId
            name
            email
            activatedAt
            disabledAt
        }
    `;

    public static readonly supporterListItem: DocumentNode = gql`
        fragment SupporterListItem on Account {
            id
            extId
            email
            activatedAt
            disabledAt
            createdAt
        }
    `;

    public static readonly clientListItem: DocumentNode = gql`
        fragment ClientListItem on Client {
            id
            extId
            dateOfBirth
            createdAt
        }
    `;

    public static readonly supporterDetail: DocumentNode = gql`
        fragment SupporterDetail on Account {
            id
            extId
            email
            name
            accountType
            pin
            avatar {
                ...Asset
            }
            pendingClientShareRequests {
                count
                result {
                    ...ClientShareRequest
                }
            }
            myClients {
                result {
                    ...SupportedClient
                }
            }
            storageSizeUsed
            config {
                storageSizeLimit
            }
            disabledReason
            activatedAt
            disabledAt
        }
        ${Fragments.asset}
        ${Fragments.supportedClient}
        ${Fragments.clientShareRequest}
    `;

    public static readonly agendaListItem: DocumentNode = gql`
        fragment AgendaListItem on Agenda {
            id
            title
            itemList {
                position
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            day
            disabledAt
        }
    `;

    public static readonly agendaItemListItem: DocumentNode = gql`
        fragment AgendaItemListItem on AgendaItem {
            id
            title
            image {
                ...Asset
            }
            audio {
                ...Asset
            }
            video {
                ...Asset
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static readonly flowchartListItem: DocumentNode = gql`
        fragment FlowchartListItem on Flowchart {
            id
            title
            image {
                ...Asset
            }
            itemList {
                position
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static readonly flowchartItemListItem: DocumentNode = gql`
        fragment FlowchartItemListItem on FlowchartItem {
            id
            flowchartType: type
            title
            image {
                ...Asset
            }
            audio {
                ...Asset
            }
            video {
                ...Asset
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static readonly checklistItem: DocumentNode = gql`
        fragment ChecklistItem on ChecklistItem {
            id
            title
            position
        }
    `;

    public static readonly checklist: DocumentNode = gql`
        fragment Checklist on Checklist {
            id
            title
            items {
                ...ChecklistItem
            }
        }
        ${Fragments.checklistItem}
    `;

    public static readonly agendaItemAlert: DocumentNode = gql`
        fragment AgendaItemAlert on AgendaItemAlert {
            title
            vibrate
            flash
            display
            requestInteraction
            audioChecked
            audio {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly agendaContent: DocumentNode = gql`
        fragment AgendaContent on Agenda {
            id
            title
            description
            client {
                id
                extId
            }
            updatedAt
            itemList {
                position
            }
            createdBy {
                id
            }
            day
            disabledAt
            publishedAt
        }
    `;

    public static readonly clientProfileData: DocumentNode = gql`
        fragment ClientProfileData on Client {
            id
            extId
            avatar {
                ...Asset
            }
            name
            dateOfBirth
            introduction
            introductionAudio {
                ...Asset
            }
            introductionVideo {
                ...Asset
            }
            lastSyncDataGeneratedAt
            devices {
                count
                result {
                    ...Device
                }
            }
            supporters {
                count
                result {
                    ...ClientSupporter
                }
            }
            managedBy {
                id
            }
            defaultAgenda {
                ...AgendaContent
            }
            isSelfSupporting
            pendingClientManagerChangeRequest {
                ...ClientChangeManagerRequest
            }
            showSelfSupportingStateDecisionWarning
            showSelfSupportingStateDecisionNotification
            showClientQuestionnaireEvaluationNotification
            awardScoreBalance
            instantAwards {
                result {
                    ...InstantAward
                }
            }
            selectionBoards {
                result {
                    ...SelectionBoard
                }
            }
            notificationAfterCreate
            notificationOnProfilePage
        }
        ${Fragments.asset}
        ${Fragments.device}
        ${Fragments.clientSupporter}
        ${Fragments.agendaContent}
        ${Fragments.clientChangeManagerRequest}
        ${Fragments.instantAward}
        ${Fragments.selectionBoard}
    `;

    public static readonly timer: DocumentNode = gql`
        fragment Timer on Timer {
            seconds
            skipEnabled
            endWarning
        }
    `;

    public static readonly itemAward: DocumentNode = gql`
        fragment ItemAward on ItemAward {
            score
            type
            useTimer
        }
    `;

    public static readonly moodMeterDisplayConfig: DocumentNode = gql`
        fragment MoodMeterDisplayConfig on MoodMeterDisplayCongfig {
            display
            title
        }
    `;

    public static readonly notification: DocumentNode = gql`
        fragment Notification on Notification {
            text
            image {
                ...Asset
            }
            audio {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly flowchartItemContent: DocumentNode = gql`
        fragment FlowchartItemContent on FlowchartItem {
            id
            flowchartType: type
            title
            client {
                id
                extId
            }
            image {
                ...AssetContent
            }
            video {
                ...AssetContent
            }
            moodMeter {
                ...MoodMeterDisplayConfig
            }
            notification {
                ...Notification
            }
            award {
                ...ItemAward
            }
            timer {
                ...Timer
            }
            checklist {
                ...Checklist
            }
            createdBy {
                id
            }
            flowchart {
                id
                title
                image {
                    ...AssetContent
                }
                itemList {
                    flowchartItem {
                        id
                        title
                        image {
                            ...AssetContent
                        }
                        video {
                            ...AssetContent
                        }
                        award {
                            ...ItemAward
                        }
                        moodMeter {
                            ...MoodMeterDisplayConfig
                        }
                        notification {
                            ...Notification
                        }
                        checklist {
                            ...Checklist
                        }
                        timer {
                            ...Timer
                        }
                    }
                    position
                }
            }
            flowchartItems {
                position
                branch
                flowchartItem {
                    title
                    image {
                        ...AssetContent
                    }
                    video {
                        ...AssetContent
                    }
                    moodMeter {
                        ...MoodMeterDisplayConfig
                    }
                    notification {
                        ...Notification
                    }
                    award {
                        ...ItemAward
                    }
                    timer {
                        ...Timer
                    }
                    checklist {
                        ...Checklist
                    }
                    flowchart {
                        id
                    }
                }
            }
            disabledAt
            publishedAt
        }
        ${Fragments.assetContent}
        ${Fragments.moodMeterDisplayConfig}
        ${Fragments.notification}
        ${Fragments.itemAward}
        ${Fragments.timer}
        ${Fragments.checklist}
    `;

    public static readonly flowchartContent: DocumentNode = gql`
        fragment FlowchartContent on Flowchart {
            id
            title
            description
            image {
                ...Asset
            }
            client {
                id
                extId
            }
            createdBy {
                id
            }
            disabledAt
            publishedAt
            itemList {
                flowchartItem {
                    ...FlowchartItemContent
                }
                position
            }
            usableInSortingGame
        }
        ${Fragments.asset}
        ${Fragments.flowchartItemContent}
    `;

    public static readonly agendaItemContentChild: DocumentNode = gql`
        fragment AgendaItemContentChild on AgendaItem {
            id
            title
            type
            image {
                ...AssetContent
            }
            video {
                ...AssetContent
            }
            content
            isCancelled
            flowchart {
                id
                title
                image {
                    ...AssetContent
                }
                itemList {
                    flowchartItem {
                        id
                        title
                        image {
                            ...AssetContent
                        }
                        video {
                            ...AssetContent
                        }
                        award {
                            ...ItemAward
                        }
                        moodMeter {
                            ...MoodMeterDisplayConfig
                        }
                        notification {
                            ...Notification
                        }
                        checklist {
                            ...Checklist
                        }
                        timer {
                            ...Timer
                        }
                    }
                    position
                }
            }
            client {
                id
                extId
            }
            time
            alert {
                ...AgendaItemAlert
            }
            moodMeter {
                ...MoodMeterDisplayConfig
            }
            notification {
                ...Notification
            }
            timer {
                ...Timer
            }
            award {
                ...ItemAward
            }
            checklist {
                ...Checklist
            }
            selectionBoard {
                ...SelectionBoardInfo
            }
            agendaItems {
                position
                agendaItem {
                    id
                    title
                    type
                    image {
                        ...AssetContent
                    }
                    content
                    isCancelled
                    flowchart {
                        id
                        title
                        image {
                            ...AssetContent
                        }
                        itemList {
                            flowchartItem {
                                id
                                title
                                image {
                                    ...AssetContent
                                }
                                award {
                                    ...ItemAward
                                }
                                notification {
                                    ...Notification
                                }
                                moodMeter {
                                    ...MoodMeterDisplayConfig
                                }
                                checklist {
                                    ...Checklist
                                }
                                timer {
                                    ...Timer
                                }
                            }
                            position
                        }
                    }
                    client {
                        id
                        extId
                    }
                    time
                    alert {
                        ...AgendaItemAlert
                    }
                    moodMeter {
                        ...MoodMeterDisplayConfig
                    }
                    timer {
                        ...Timer
                    }
                    award {
                        ...ItemAward
                    }
                    checklist {
                        ...Checklist
                    }
                    createdBy {
                        id
                    }
                    disabledAt
                    referenceCount
                    publishedAt
                }
            }
            createdBy {
                id
            }
            disabledAt
            referenceCount
            publishedAt
        }
        ${Fragments.agendaItemAlert}
        ${Fragments.notification}
        ${Fragments.moodMeterDisplayConfig}
        ${Fragments.notification}
        ${Fragments.timer}
        ${Fragments.itemAward}
        ${Fragments.checklist}
        ${Fragments.selectionBoardInfo}
    `;

    public static readonly agendaItemContent: DocumentNode = gql`
        fragment AgendaItemContent on AgendaItem {
            id
            title
            type
            image {
                ...AssetContent
            }
            video {
                ...AssetContent
            }
            content
            isCancelled
            flowchart {
                id
                title
                image {
                    ...AssetContent
                }
                itemList {
                    flowchartItem {
                        id
                        title
                        image {
                            ...AssetContent
                        }
                        video {
                            ...AssetContent
                        }
                        award {
                            ...ItemAward
                        }
                        moodMeter {
                            ...MoodMeterDisplayConfig
                        }
                        notification {
                            ...Notification
                        }
                        checklist {
                            ...Checklist
                        }
                        timer {
                            ...Timer
                        }
                    }
                    position
                }
            }
            client {
                id
                extId
            }
            time
            alert {
                ...AgendaItemAlert
            }
            moodMeter {
                ...MoodMeterDisplayConfig
            }
            notification {
                ...Notification
            }
            timer {
                ...Timer
            }
            award {
                ...ItemAward
            }
            checklist {
                ...Checklist
            }
            selectionBoard {
                ...SelectionBoardInfo
            }
            agendaItems {
                position
                agendaItem {
                    id
                    title
                    type
                    image {
                        ...AssetContent
                    }
                    video {
                        ...AssetContent
                    }
                    content
                    isCancelled
                    flowchart {
                        id
                        title
                        image {
                            ...AssetContent
                        }
                        itemList {
                            flowchartItem {
                                id
                                title
                                image {
                                    ...AssetContent
                                }
                                video {
                                    ...AssetContent
                                }
                                award {
                                    ...ItemAward
                                }
                                moodMeter {
                                    ...MoodMeterDisplayConfig
                                }
                                notification {
                                    ...Notification
                                }
                                checklist {
                                    ...Checklist
                                }
                                timer {
                                    ...Timer
                                }
                            }
                            position
                        }
                    }
                    client {
                        id
                        extId
                    }
                    time
                    alert {
                        ...AgendaItemAlert
                    }
                    moodMeter {
                        ...MoodMeterDisplayConfig
                    }
                    timer {
                        ...Timer
                    }
                    award {
                        ...ItemAward
                    }
                    checklist {
                        ...Checklist
                    }
                    selectionBoard {
                        ...SelectionBoardInfo
                    }
                    agendaItems {
                        position
                        agendaItem {
                            ...AgendaItemContentChild
                        }
                    }
                    notification {
                        ...Notification
                    }
                    createdBy {
                        id
                    }
                    disabledAt
                    referenceCount
                    publishedAt
                }
            }
            createdBy {
                id
            }
            disabledAt
            referenceCount
            publishedAt
        }
        ${Fragments.assetContent}
        ${Fragments.agendaItemContentChild}
        ${Fragments.notification}
        ${Fragments.agendaItemAlert}
        ${Fragments.moodMeterDisplayConfig}
        ${Fragments.timer}
        ${Fragments.itemAward}
        ${Fragments.checklist}
        ${Fragments.selectionBoardInfo}
    `;

    public static readonly agendaItemAgendaItemRel: DocumentNode = gql`
        fragment AgendaItemAgendaItemRel on AgendaItemAgendaItemRel {
            agendaItem {
                ...AgendaItemContent
            }
            position
        }
        ${Fragments.agendaItemContent}
    `;

    public static readonly agendaItem: DocumentNode = gql`
        fragment AgendaItem on AgendaItem {
            id
            title
            type
            content
            isCancelled
            flowchart {
                ...FlowchartContent
            }
            client {
                ...ContentClient
            }
            image {
                ...Asset
            }
            audio {
                ...Asset
            }
            video {
                ...Asset
            }
            time
            alert {
                ...AgendaItemAlert
            }
            timer {
                ...Timer
            }
            award {
                ...ItemAward
            }
            instantAwards {
                ...InstantAward
            }
            checklist {
                ...Checklist
            }
            notification {
                ...Notification
            }
            moodMeter {
                ...MoodMeterDisplayConfig
            }
            createdBy {
                id
                name
                extId
            }
            image {
                ...Asset
            }
            tags
            createdAt
            updatedAt
            disabledAt
            publishedAt
            referenceCount
            agendaItems {
                ...AgendaItemAgendaItemRel
            }
            shareRequests {
                ...LibraryContentShareRequest
            }
            selectionBoard {
                ...SelectionBoard
            }
        }
        ${Fragments.agendaItemAlert}
        ${Fragments.contentClient}
        ${Fragments.asset}
        ${Fragments.timer}
        ${Fragments.assetContent}
        ${Fragments.notification}
        ${Fragments.itemAward}
        ${Fragments.flowchartContent}
        ${Fragments.checklist}
        ${Fragments.moodMeterDisplayConfig}
        ${Fragments.agendaItemAgendaItemRel}
        ${Fragments.instantAward}
        ${Fragments.libraryContentShareRequest}
        ${Fragments.selectionBoard}
    `;

    public static readonly namedColor: DocumentNode = gql`
        fragment NamedColor on NamedColor {
            name
            value
        }
    `;
    public static readonly allowedUploadContentTypes: DocumentNode = gql`
        fragment AllowedUploadContentTypes on AllowedUploadContentTypes {
            image
            audio
            video
        }
    `;

    public static readonly allowedUploadFileSizes: DocumentNode = gql`
        fragment AllowedUploadFileSizes on AllowedUploadFileSizes {
            image
            audio
            video
            educationDocument
            educationVideo
        }
    `;

    public static appStatItemTypeTitle: DocumentNode = gql`
        fragment AppStatItemTypeTitle on AppStatItemTypeTitle {
            key
            title
        }
    `;

    public static appEventLogNameTitle: DocumentNode = gql`
        fragment AppEventLogNameTitle on AppEventLogNameTitle {
            key
            title
            accessibility
            category
        }
    `;

    public static appEventLogCategoryNameTitle: DocumentNode = gql`
        fragment AppEventLogCategoryNameTitle on AppEventLogCategoryNameTitle {
            key
            title
            position
        }
    `;

    public static readonly cmsSettings: DocumentNode = gql`
        fragment CmsSettings on CmsSettings {
            unlockColors {
                ...NamedColor
            }
            backgroundColors {
                ...NamedColor
            }
            itemBackgroundColors {
                ...NamedColor
            }
            allowedUploadContentTypes {
                ...AllowedUploadContentTypes
            }
            allowedUploadFileSizes {
                ...AllowedUploadFileSizes
            }
            appStatItemTypeTitles {
                ...AppStatItemTypeTitle
            }
            appEventLogNameTitles {
                ...AppEventLogNameTitle
            }
            appEventLogCategoryNameTitles {
                ...AppEventLogCategoryNameTitle
            }
            clientEverydaySituationDirectoryDefaultImageId
        }
        ${Fragments.namedColor}
        ${Fragments.allowedUploadContentTypes}
        ${Fragments.allowedUploadFileSizes}
        ${Fragments.appStatItemTypeTitle}
        ${Fragments.appEventLogNameTitle}
        ${Fragments.appEventLogCategoryNameTitle}
    `;

    public static readonly flowchartItemFlowchartRel: DocumentNode = gql`
        fragment FlowchartItemFlowchartRel on FlowchartItemFlowchartRel {
            id
            flowchartItem {
                ...FlowchartItemContent
            }
            position
            branch
        }
        ${Fragments.flowchartItemContent}
    `;

    public static readonly flowchart: DocumentNode = gql`
        fragment Flowchart on Flowchart {
            id
            title
            description
            image {
                ...AssetContent
            }
            client {
                ...ContentClient
            }
            itemList {
                ...FlowchartItemFlowchartRel
            }
            createdBy {
                id
                extId
                name
            }
            usableInSortingGame
            createdAt
            updatedAt
            disabledAt
            publishedAt
            usableInSortingGame
            tags
            shareRequests {
                ...LibraryContentShareRequest
            }
        }
        ${Fragments.assetContent}
        ${Fragments.contentClient}
        ${Fragments.flowchartItemFlowchartRel}
        ${Fragments.libraryContentShareRequest}
    `;

    public static readonly flowchartItemFlowchartItemRel: DocumentNode = gql`
        fragment FlowchartItemFlowchartItemRel on FlowchartItemFlowchartItemRel {
            flowchartItem {
                ...FlowchartItemContent
            }
            position
            branch
        }
        ${Fragments.flowchartItemContent}
    `;

    public static readonly flowchartItem: DocumentNode = gql`
        fragment FlowchartItem on FlowchartItem {
            id
            flowchartType: type
            title
            image {
                ...AssetContent
            }
            audio {
                ...AssetContent
            }
            video {
                ...AssetContent
            }
            client {
                ...ContentClient
            }
            moodMeter {
                ...MoodMeterDisplayConfig
            }
            notification {
                ...Notification
            }
            award {
                ...ItemAward
            }
            instantAwards {
                ...InstantAward
            }
            checklist {
                ...Checklist
            }
            timer {
                ...Timer
            }
            flowchart {
                ...FlowchartContent
            }
            flowchartItems {
                ...FlowchartItemFlowchartItemRel
            }
            createdBy {
                id
                extId
                name
            }
            tags
            disabledAt
            createdAt
            updatedAt
            publishedAt
            shareRequests {
                ...LibraryContentShareRequest
            }
        }
        ${Fragments.assetContent}
        ${Fragments.contentClient}
        ${Fragments.moodMeterDisplayConfig}
        ${Fragments.notification}
        ${Fragments.itemAward}
        ${Fragments.checklist}
        ${Fragments.timer}
        ${Fragments.flowchartContent}
        ${Fragments.flowchartItemFlowchartItemRel}
        ${Fragments.instantAward}
        ${Fragments.libraryContentShareRequest}
    `;

    public static readonly agendaItemAgendaRel: DocumentNode = gql`
        fragment AgendaItemAgendaRel on AgendaItemAgendaRel {
            id
            agendaItem {
                ...AgendaItemContent
            }
            position
        }
        ${Fragments.agendaItemContent}
    `;

    public static readonly agenda: DocumentNode = gql`
        fragment Agenda on Agenda {
            id
            title
            description
            day
            client {
                ...ContentClient
            }
            itemList {
                ...AgendaItemAgendaRel
            }
            createdBy {
                id
                extId
                name
            }
            shareRequests {
                ...LibraryContentShareRequest
            }
            tags
            createdAt
            updatedAt
            publishedAt
            disabledAt
        }
        ${Fragments.contentClient}
        ${Fragments.agendaItemAgendaRel}
        ${Fragments.libraryContentShareRequest}
    `;

    public static readonly calendarAgenda: DocumentNode = gql`
        fragment CalendarAgenda on Agenda {
            id
            title
            day
            disabledAt
            createdBy {
                id
            }
            updatedAt
        }
    `;

    public static appEventLog: DocumentNode = gql`
        fragment AppEventLog on AppEventLog {
            event
            eventTitle
            timestamp
            entity {
                client {
                    id
                    extId
                }
            }
            entityTitle
            request {
                account {
                    id
                    extId
                }
            }
        }
    `;

    public static tag: DocumentNode = gql`
        fragment Tag on Tag {
            id
            title
            category
        }
    `;

    public static adminTag: DocumentNode = gql`
        fragment AdminTag on Tag {
            ...Tag
            createdBy {
                ...Account
            }
            createdAt
            updatedAt
        }
        ${Fragments.tag}
        ${Fragments.account}
    `;

    public static readonly clientGamesConfig: DocumentNode = gql`
        fragment ClientGamesConfig on ClientConfig {
            enabledGames {
                name
            }
        }
    `;

    public static memoryGameConfig: DocumentNode = gql`
        fragment MemoryGameConfig on MemoryGameConfig {
            numberOfPairs
            images {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static textEverydaySituation = gql`
        fragment TextEverydaySituation on TextEverydaySituation {
            id
            title
            description
            createdAt
            updatedAt
            disabledAt
            createdBy {
                id
            }
        }
    `;

    public static imageEverydaySituation = gql`
        fragment ImageEverydaySituation on ImageEverydaySituation {
            id
            title
            image {
                ...AtomicAsset
            }
            createdAt
            updatedAt
            disabledAt
            createdBy {
                id
            }
        }
        ${Fragments.atomicAsset}
    `;

    public static textAndImageEverydaySituation = gql`
        fragment TextAndImageEverydaySituation on TextAndImageEverydaySituation {
            id
            title
            description
            image {
                ...AtomicAsset
            }
            createdAt
            updatedAt
            disabledAt
            createdBy {
                id
            }
        }
        ${Fragments.atomicAsset}
    `;

    public static audioEverydaySituation = gql`
        fragment AudioEverydaySituation on AudioEverydaySituation {
            id
            title
            audio {
                ...AtomicAsset
            }
            createdAt
            updatedAt
            disabledAt
            createdBy {
                id
            }
        }
        ${Fragments.atomicAsset}
    `;

    public static everydaySituationClientDirectory = gql`
        fragment EverydaySituationClientDirectory on EverydaySituationClientDirectory {
            id
            title
            isVisible
            isWritable
            image {
                ...AtomicAsset
            }
            situations {
                ... on TextEverydaySituation {
                    ...TextEverydaySituation
                }
                ... on ImageEverydaySituation {
                    ...ImageEverydaySituation
                }
                ... on TextAndImageEverydaySituation {
                    ...TextAndImageEverydaySituation
                }
                ... on AudioEverydaySituation {
                    ...AudioEverydaySituation
                }
            }
            createdAt
            updatedAt
        }
        ${Fragments.atomicAsset}
        ${Fragments.textEverydaySituation}
        ${Fragments.imageEverydaySituation}
        ${Fragments.textAndImageEverydaySituation}
        ${Fragments.audioEverydaySituation}
    `;

    public static everydaySituation = gql`
        fragment EverydaySituation on EverydaySituation {
            ... on TextEverydaySituation {
                ...TextEverydaySituation
            }
            ... on ImageEverydaySituation {
                ...ImageEverydaySituation
            }
            ... on TextAndImageEverydaySituation {
                ...TextAndImageEverydaySituation
            }
            ... on AudioEverydaySituation {
                ...AudioEverydaySituation
            }
        }
        ${Fragments.textEverydaySituation}
        ${Fragments.imageEverydaySituation}
        ${Fragments.textAndImageEverydaySituation}
        ${Fragments.audioEverydaySituation}
    `;

    public static everydaySituationDirectory = gql`
        fragment EverydaySituationDirectory on EverydaySituationDirectory {
            id
            title
            position
            image {
                ...AtomicAsset
            }
            situations {
                ...EverydaySituation
            }
            createdBy {
                id
            }
            disabledAt
            createdAt
            updatedAt
        }
        ${Fragments.atomicAsset}
        ${Fragments.everydaySituation}
    `;

    public static everydaySituationDirectoryList = gql`
        fragment EverydaySituationDirectoryList on EverydaySituationDirectory {
            id
            title
            position
            image {
                ...AssetContent
            }
            situations {
                ...EverydaySituation
            }
            createdAt
            updatedAt
            disabledAt
            createdBy {
                id
            }
        }
        ${Fragments.assetContent}
        ${Fragments.everydaySituation}
    `;

    public static everydaySituationDirectoryWithItemCount = gql`
        fragment EverydaySituationDirectoryWithItemCount on EverydaySituationDirectory {
            id
            title
            position
            image {
                ...AtomicAsset
            }
            itemCount {
                text
                audio
                image
            }
            disabledAt
        }
        ${Fragments.atomicAsset}
    `;

    public static faq: DocumentNode = gql`
        fragment Faq on FaqItem {
            id
            question
            answer
            createdBy {
                name
            }
            updatedAt
            position
        }
    `;

    public static assetDirectoryItem: DocumentNode = gql`
        fragment AssetDirectoryItem on AssetDirectory {
            id
            name
            createdBy {
                id
                name
            }
            createdAt
            publishedAt
        }
    `;

    public static assetDirectory: DocumentNode = gql`
        fragment AssetDirectory on AssetDirectory {
            ...AssetDirectoryItem
            children {
                ...AssetDirectoryItem
            }
            parent {
                ...AssetDirectoryItem
            }
        }
        ${Fragments.assetDirectoryItem}
    `;

    // used for edit
    public static assetDirectoryData: DocumentNode = gql`
        fragment AssetDirectoryData on AssetDirectory {
            id
            name
            deletable
        }
    `;

    public static readonly award: DocumentNode = gql`
        fragment Award on Award {
            id
            title
            autoReactivation
            isActive
            targetScore
            image {
                ...Asset
            }
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static disabledPublicContentList: DocumentNode = gql`
        fragment DisabledPublicContentList on DisabledContentList {
            count
            result {
                ... on Agenda {
                    id
                    title
                    createdAt
                    agendaClient: client {
                        id
                        extId
                    }
                }
                ... on AgendaItem {
                    id
                    title
                    createdAt
                    agendaItemClient: client {
                        id
                        extId
                    }
                    agendaItemImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                    agendaItemVideo: video {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on Flowchart {
                    id
                    title
                    createdAt
                    flowchartClient: client {
                        id
                        extId
                    }
                    flowchartImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on FlowchartItem {
                    id
                    title
                    createdAt
                    flowchartItemClient: client {
                        id
                        extId
                    }
                    flowchartItemImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                    flowchartItemVideo: video {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on Asset {
                    id
                    title
                    createdAt
                    url
                    thumbnailUrl
                    assetType
                }
                ... on Award {
                    id
                    title
                    createdAt
                    awardClient: client {
                        id
                        extId
                    }
                    awardImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on InstantAward {
                    id
                    title
                    createdAt
                    instantAwardClient: client {
                        id
                        extId
                    }
                    instantAwardImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on TextEverydaySituation {
                    id
                    title
                    textEverydaySituationClient: client {
                        id
                        extId
                    }
                    textEverydaySituationDirectory: directory {
                        id
                    }
                    createdAt
                }
                ... on ImageEverydaySituation {
                    id
                    title
                    createdAt
                    imageEverydaySituationClient: client {
                        id
                        extId
                    }
                    imageEverydaySituationImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                    imageEverydaySituationDirectory: directory {
                        id
                    }
                }
                ... on TextAndImageEverydaySituation {
                    id
                    title
                    createdAt
                    textAndImageEverydaySituationClient: client {
                        id
                        extId
                    }
                    textAndImageEverydaySituationImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                    textAndImageEverydaySituationDirectory: directory {
                        id
                    }
                }
                ... on AudioEverydaySituation {
                    id
                    title
                    audioEverydaySituationClient: client {
                        id
                        extId
                    }
                    createdAt
                    audioEverydaySituationDirectory: directory {
                        id
                    }
                }
                ... on EverydaySituationDirectory {
                    id
                    title
                    createdAt
                    everydaySituationDirectoryClient: client {
                        id
                        extId
                    }
                    everydaySituationDirectoryImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                }
                ... on SelectionBoard {
                    id
                    title
                    createdAt
                    selectionClient: client {
                        id
                        extId
                    }
                    selectionImage: image {
                        id
                        url
                        thumbnailUrl
                    }
                }
            }
        }
    `;

    public static educationContentModuleList: DocumentNode = gql`
        fragment EducationContentModuleListItem on EducationContentModule {
            id
            title
            position
            subModules {
                id
            }
            createdBy {
                id
                name
                accountType
            }
            createdAt
            updatedAt
        }
    `;

    public static educationContent: DocumentNode = gql`
        fragment EducationContent on EducationContent {
            id
            type
            title
            etag
            url
            thumbnail
            mimeType
            size
            position
            createdAt
        }
    `;

    public static educationContentSubModule: DocumentNode = gql`
        fragment EducationContentSubModule on EducationContentModule {
            id
            title
            position
            videoContents {
                ...EducationContent
            }
            documentContents {
                ...EducationContent
            }
            createdBy {
                id
                name
                accountType
            }
            createdAt
            updatedAt
        }
        ${Fragments.educationContent}
    `;

    public static educationContentModule: DocumentNode = gql`
        fragment EducationContentModule on EducationContentModule {
            id
            title
            position
            videoContents {
                id
                type
                title
                etag
                url
                thumbnail
                mimeType
                size
                position
                createdAt
            }
            documentContents {
                id
                type
                title
                etag
                url
                thumbnail
                mimeType
                size
                position
                createdAt
            }
            subModules {
                ...EducationContentSubModule
            }
            createdBy {
                id
                name
                accountType
            }
            createdAt
            updatedAt
        }
        ${Fragments.educationContentSubModule}
    `;

    public static educationContentModuleTab: DocumentNode = gql`
        fragment EducationContentModuleTab on EducationContentModule {
            id
            title
            position
        }
    `;

    public static educationDocument: DocumentNode = gql`
        fragment EducationDocument on EducationContent {
            id
            title
            url
            thumbnail
        }
    `;

    public static educationVideo: DocumentNode = gql`
        fragment EducationVideo on EducationContent {
            id
            title
            url
            thumbnail
            position
        }
    `;

    public static educationSubModule: DocumentNode = gql`
        fragment EducationSubModule on EducationContentModule {
            id
            title
            position
            documentContents {
                ...EducationDocument
            }
            videoContents {
                ...EducationVideo
            }
        }
        ${Fragments.educationDocument}
        ${Fragments.educationVideo}
    `;

    public static instantAwardListItem: DocumentNode = gql`
        fragment InstantAwardListItem on InstantAward {
            id
            referenceCount
            title
            image {
                ...Asset
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static awardListItem: DocumentNode = gql`
        fragment AwardListItem on Award {
            id
            title
            image {
                ...Asset
            }
            targetScore
            createdBy {
                id
                name
            }
            isActive
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
    `;

    public static selectionBoardListItem: DocumentNode = gql`
        fragment SelectionBoardListItem on SelectionBoard {
            id
            title
            image {
                ...Asset
            }
            items {
                ...SelectionBoardItem
            }
            createdBy {
                id
                name
            }
            createdAt
            updatedAt
            disabledAt
        }
        ${Fragments.asset}
        ${Fragments.selectionBoardItem}
    `;

    public static aggregations: DocumentNode = gql`
        fragment Aggregations on Aggregations {
            timeAggregations {
                dateAggregation {
                    date
                    count
                }
                hourAggregation {
                    hour
                    count
                }
                weekAggregation {
                    week
                    count
                }
            }
        }
    `;

    public static appStatItem: DocumentNode = gql`
        fragment AppStatItem on AppStatItem {
            type
            typeTitle
            data
            device {
                ...Device
            }
            account {
                id
                extId
            }
            client {
                id
                extId
            }
            createdAt
        }
        ${Fragments.device}
    `;

    public static libraryContentShareRequestListItem: DocumentNode = gql`
        fragment LibraryContentShareRequestListItem on LibraryContentShareRequestListItem {
            requestId
            requestCreatedBy {
                id
                email
            }
            requestCreatedAt
            requestStatus
            requestComment
            libraryContent {
                ... on Agenda {
                    ...Agenda
                }
                ... on AgendaItem {
                    ...AgendaItem
                }
                ... on Flowchart {
                    ...Flowchart
                }
                ... on FlowchartItem {
                    ...FlowchartItem
                }
            }
        }
        ${Fragments.agenda}
        ${Fragments.agendaItem}
        ${Fragments.flowchart}
        ${Fragments.flowchartItem}
    `;

    public static readonly libraryContentShareRequestWithData: DocumentNode = gql`
        fragment LibraryContentShareRequestWithData on LibraryContentShareRequest {
            ...LibraryContentShareRequest
            libraryContent {
                ... on Agenda {
                    ...Agenda
                }
                ... on AgendaItem {
                    ...AgendaItem
                }
                ... on Flowchart {
                    ...Flowchart
                }
                ... on FlowchartItem {
                    ...FlowchartItem
                }
            }
        }
        ${Fragments.libraryContentShareRequest}
        ${Fragments.agenda}
        ${Fragments.agendaItem}
        ${Fragments.flowchart}
        ${Fragments.flowchartItem}
    `;
}

export { Fragments };

import React, { PureComponent } from "react";
import { SidebarContentElement } from "pages/_shared/Draggables/ContentLibrarySideBar/SidebarContentElement/SidebarContentElement";
import { SearchForm } from "components/SearchForm/SearchForm";
import { Accordion } from "components/Accordion/Accordion";
import {
    SearchListType,
    AssetType,
    AssetContent,
    AgendaContent,
    AgendaItemContent,
    FlowchartContent,
    FlowchartItemContent,
    SupportedClient,
    AgendaItemType,
    FlowchartItemType,
} from "api/graphql/types";
import { CreateContentButton } from "../../CreateContentButton/CreateContentButton";
import { RouteComponentProps, withRouter } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { AnyContentType, ApiTypes } from "api/ApiTypes";
import { ContentLibrarySideBarQueryParser } from "./ContentLibrarySideBarUrlQueryParser";
import { Log } from "utils/Log";
import { Intl } from "i18n/Intl";
import { DroppableProvided } from "react-beautiful-dnd";
import { Droppable } from "../Droppable";
import { DraggableContentType } from "pages/_shared/Draggables/DragContainer";
import { Loading } from "components/Loading/Loading";
import { AssetOptionMenu } from "pages/_shared/ContentOptionMenu/AssetOptionMenu";
import { ContentOptionMenu } from "pages/_shared/ContentOptionMenu/ContentOptionMenu";
import isEqual from "lodash/isEqual";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { DispatchProp, connect } from "react-redux";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";
import { ContentLibraryDirectories, DirectoryList } from "models/ContentLibraryDirectories";
import { DirectorySidebar } from "components/DirectorySidebar/DirectorySidebar";
import { isNil, uniqBy } from "lodash";

import "./ContentLibrarySideBar.scss";

interface ComponentProps {
    client?: SupportedClient | null;
    createdById?: string;
    searchListTypes: SearchListType[];
    agendaItemTypes?: AgendaItemType[];
    assetTypes?: AssetType[];
    flowchartItemTypes?: FlowchartItemType[];

    myLibrary: DraggableContentType[];
    clientLibrary: DraggableContentType[];

    onDataLoaded: (myLibrary?: AnyContentType[], clientLibrary?: AnyContentType[]) => void;
    onAddClicked: (content: AnyContentType) => void;
    dragIds: string[];
    isUsableInSortingGame?: boolean;
    isUploadEnabled: boolean;
    canHaveMultipleAssets?: boolean;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

interface ContentLibrarySideBarSearchListOptions {
    search?: string;
    tags?: string[];
    directory?: string;
}

interface State {
    isLoading: boolean;
    isFirstLoading: boolean;
    options: ContentLibrarySideBarSearchListOptions;
    offsets: {
        [LibraryType.My]: number;
        [LibraryType.Client]: number;
    };
    isSearchInProgress: boolean;
    personalDirectories: DirectoryList[] | null;
    selectedDirectory: string | null;
    myLibraryContentCount: number;
    clientLibraryContentCount: number;
}

export enum LibraryType {
    My = "My",
    Client = "Client",
    All = "All",
}

class ContentLibrarySideBarComponent extends PureComponent<Props, State> {
    private clientLibraryBottomRef: HTMLDivElement | null = null;
    private myLibraryBottomRef: HTMLDivElement | null = null;
    private static readonly DEFAULT_PAGE_LIMIT: number = 10;

    private static getInitialOptions(props: Props): ContentLibrarySideBarSearchListOptions {
        const params = new ContentLibrarySideBarQueryParser().parse(props.location.search);
        return { search: params.search, tags: params.tags, directory: params.directory };
    }

    private static getInitialOffsets(): { [LibraryType.My]: number; [LibraryType.Client]: number } {
        return {
            [LibraryType.My]: 0,
            [LibraryType.Client]: 0,
        };
    }

    public readonly state: State = {
        isLoading: true,
        isFirstLoading: true,
        options: ContentLibrarySideBarComponent.getInitialOptions(this.props),
        offsets: ContentLibrarySideBarComponent.getInitialOffsets(),
        isSearchInProgress: true,
        personalDirectories: null,
        selectedDirectory: null,
        myLibraryContentCount: 0,
        clientLibraryContentCount: 0,
    };

    public componentDidMount(): void {
        let libraryType = LibraryType.All;
        if (!this.props.client) {
            libraryType = LibraryType.My;
        } else if (!this.props.createdById) {
            libraryType = LibraryType.Client;
        }
        this.refreshLibrary(libraryType, this.state.options);
        this.refreshAssetDirectories();
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: ContentLibrarySideBarSearchListOptions = ContentLibrarySideBarComponent.getInitialOptions(nextProps);
        const searchChanged: boolean = this.state.options.search !== nextOptions.search;
        const tagsChanged: boolean = !isEqual(this.state.options.tags, nextOptions.tags);
        const searchListTypesChanged = !isEqual(this.props.searchListTypes, nextProps.searchListTypes);
        const isFlowchartItemTypesChanged = !isEqual(this.props.flowchartItemTypes, nextProps.flowchartItemTypes);

        if (searchChanged || searchListTypesChanged || tagsChanged || isFlowchartItemTypesChanged) {
            if (searchChanged || tagsChanged) {
                this.setState({ options: nextOptions }, () => {
                    this.updateQueryParams();
                });
            } else if (searchListTypesChanged || isFlowchartItemTypesChanged) {
                const isResetNeeded = this.props.createdById !== nextProps.createdById || this.props.client !== nextProps.client;
                if (nextProps.createdById && nextProps.client) {
                    this.refreshLibrary(LibraryType.All, this.state.options, isResetNeeded);
                } else if (nextProps.createdById) {
                    this.refreshLibrary(LibraryType.My, this.state.options, isResetNeeded);
                } else {
                    this.refreshLibrary(LibraryType.Client, this.state.options, isResetNeeded);
                }
            }
        } else {
            this.setState({ isSearchInProgress: false });
        }
    }

    private handleScroll = () => {
        if (this.state.isLoading) {
            return;
        }

        if (!isNil(this.myLibraryBottomRef) && this.state.offsets[LibraryType.My] < this.state.myLibraryContentCount) {
            const myLibraryBottomPosition = this.myLibraryBottomRef.getBoundingClientRect().top;
            if (myLibraryBottomPosition > 0 && myLibraryBottomPosition <= window.innerHeight) {
                this.refreshLibrary(LibraryType.My, this.state.options);
            }
        }

        if (!isNil(this.clientLibraryBottomRef) && this.state.offsets[LibraryType.Client] < this.state.clientLibraryContentCount) {
            const clientLibraryBottomPosition = this.clientLibraryBottomRef.getBoundingClientRect().top;
            if (clientLibraryBottomPosition > 0 && clientLibraryBottomPosition <= window.innerHeight) {
                this.refreshLibrary(LibraryType.Client, this.state.options);
            }
        }
    };

    private increaseOffsets = (refreshLibrary: LibraryType): void => {
        switch (refreshLibrary) {
            case LibraryType.My:
                this.setState({
                    offsets: {
                        ...this.state.offsets,
                        [LibraryType.My]: this.state.offsets[LibraryType.My] + ContentLibrarySideBarComponent.DEFAULT_PAGE_LIMIT,
                    },
                });
                break;
            case LibraryType.Client:
                this.setState({
                    offsets: {
                        ...this.state.offsets,
                        [LibraryType.Client]: this.state.offsets[LibraryType.Client] + ContentLibrarySideBarComponent.DEFAULT_PAGE_LIMIT,
                    },
                });
                break;
            default:
                this.setState({
                    offsets: {
                        ...this.state.offsets,
                        [LibraryType.My]: this.state.offsets[LibraryType.My] + ContentLibrarySideBarComponent.DEFAULT_PAGE_LIMIT,
                        [LibraryType.Client]: this.state.offsets[LibraryType.Client] + ContentLibrarySideBarComponent.DEFAULT_PAGE_LIMIT,
                    },
                });
                break;
        }
    };

    private filterContents = (myLibrary: AnyContentType[], clientLibrary: AnyContentType[]): { myLibrary: AnyContentType[]; clientLibrary: AnyContentType[] } => {
        const filter = (content: AnyContentType): boolean => {
            switch (content.__typename) {
                case "AgendaItem":
                    return !isNil(this.props.agendaItemTypes) ? this.props.agendaItemTypes.includes(content.type) : true;
                case "FlowchartItem":
                    return !isNil(this.props.flowchartItemTypes) ? this.props.flowchartItemTypes.includes(content.flowchartType) : true;
                default:
                    return true;
            }
        };

        return {
            myLibrary: myLibrary.filter(filter),
            clientLibrary: clientLibrary.filter(filter),
        };
    };

    /**
     * If LibraryType is "All", then an initial loading has occured (e. g. search), so we don't stack props.myLibrary with the currently fetched myLibrary, but if only a specific LibraryType is * called (My/Client) then it's a scrollevent in which case we do
     */
    private appendContents = (refreshLibraryType: LibraryType, myLibrary: AnyContentType[], clientLibrary: AnyContentType[]): { myLibrary: AnyContentType[]; clientLibrary: AnyContentType[] } => {
        const getUniqById = (libraryContents: AnyContentType[]): AnyContentType[] => {
            return uniqBy(libraryContents, "id");
        };

        const appendedMyLibrary: AnyContentType[] =
            refreshLibraryType === LibraryType.All
                ? myLibrary
                : getUniqById([
                      ...this.props.myLibrary.map((draggableContent: DraggableContentType) => {
                          return draggableContent.content;
                      }),
                      ...myLibrary,
                  ]);

        const appendedClientLibrary: AnyContentType[] =
            refreshLibraryType === LibraryType.All
                ? clientLibrary
                : getUniqById([
                      ...this.props.clientLibrary.map((draggableContent: DraggableContentType) => {
                          return draggableContent.content;
                      }),
                      ...clientLibrary,
                  ]);

        return { myLibrary: appendedMyLibrary, clientLibrary: appendedClientLibrary };
    };

    private refreshLibrary = (refreshLibraryType: LibraryType, options?: ContentLibrarySideBarSearchListOptions, reset?: boolean): void => {
        this.setState(
            { isLoading: true, offsets: refreshLibraryType === LibraryType.All || reset ? ContentLibrarySideBarComponent.getInitialOffsets() : this.state.offsets, isFirstLoading: !!reset },
            async (): Promise<void> => {
                try {
                    const getOffset = (): number => {
                        switch (refreshLibraryType) {
                            case LibraryType.My:
                                return this.state.offsets[LibraryType.My];
                            case LibraryType.Client:
                                return this.state.offsets[LibraryType.Client];
                            default:
                                return 0;
                        }
                    };

                    const getParams = (searchListTypes: SearchListType[], assetTypes?: AssetType[], usableInSortingGame?: boolean) => {
                        return {
                            clientId: this.props.client?.id,
                            createdById: this.props.createdById,
                            searchListTypes,
                            assetTypes,
                            search: options?.search,
                            limit: ContentLibrarySideBarComponent.DEFAULT_PAGE_LIMIT,
                            offset: getOffset(),
                            tags: options?.tags,
                            assetDirectory: options?.directory,
                            usableInSortingGame,
                        };
                    };

                    const result = await Api.getContentLibrarySidebarData(refreshLibraryType, getParams(this.props.searchListTypes, this.props.assetTypes, this.props.isUsableInSortingGame));
                    let myLibrary: AnyContentType[] = result.myLibrary.result;
                    let clientLibrary: AnyContentType[] = result.clientLibrary.result;

                    const newMyLibraryContentCount: number = result.myLibrary.count;
                    const newClientLibraryContentCount: number = result.clientLibrary.count;

                    const { myLibrary: filteredMyLibrary, clientLibrary: filteredClientLibrary } = this.filterContents(myLibrary, clientLibrary);
                    myLibrary = filteredMyLibrary;
                    clientLibrary = filteredClientLibrary;

                    const { myLibrary: appendedMyLibrary, clientLibrary: appendedClientLibrary } = this.appendContents(refreshLibraryType, myLibrary, clientLibrary);
                    myLibrary = reset ? myLibrary : appendedMyLibrary;
                    clientLibrary = reset ? clientLibrary : appendedClientLibrary;

                    // Storing count for the infinite scrolling
                    this.setState({
                        myLibraryContentCount: reset || [LibraryType.All, LibraryType.My].includes(refreshLibraryType) ? newMyLibraryContentCount : this.state.myLibraryContentCount,
                        clientLibraryContentCount: reset || [LibraryType.All, LibraryType.Client].includes(refreshLibraryType) ? newClientLibraryContentCount : this.state.clientLibraryContentCount,
                    });

                    this.increaseOffsets(refreshLibraryType);
                    this.props.onDataLoaded(myLibrary, clientLibrary);
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.props.onDataLoaded([], []);
                }
                this.setState({ isLoading: false, isFirstLoading: false, isSearchInProgress: false });
            },
        );
    };

    private refreshAssetDirectories = async () => {
        try {
            const personalDirectories = await ContentLibraryDirectories.getPersonalAssetDirectories(this.props.history.location.pathname);
            this.setState({ personalDirectories });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onDirectoryChange = (assetDirectoryId: string | null) => {
        this.setState(
            {
                options: { ...this.state.options, directory: assetDirectoryId || undefined },
            },
            () => {
                this.updateQueryParams();
                this.refreshAssetDirectories();
            },
        );
    };

    private updateQueryParams = (): void => {
        const params = new ContentLibrarySideBarQueryParser().getUrlQuery(this.state.options);
        this.props.history.push({ search: `?${params}` });
        this.refreshLibrary(LibraryType.All, this.state.options);
    };

    private onSearchClick = (search: string, tags?: string[]): void => {
        this.setState({ isSearchInProgress: true }, () => {
            const params = new ContentLibrarySideBarQueryParser().getUrlQuery({ ...this.state.options, search, tags });
            this.props.history.push({ search: `?${params}` });
        });
    };

    private renderContentElement = (pageContent: PageContent) => (element: DraggableContentType, index: number): React.ReactElement<any> | null => {
        if (element.content.__typename === "Asset") {
            return this.renderAsset(element.id, element.content, index, pageContent);
        }
        if (element.content.__typename === "Agenda") {
            return this.renderAgenda(element.id, element.content, index, pageContent);
        }
        if (element.content.__typename === "AgendaItem") {
            return this.renderAgendaItem(element.id, element.content, index, pageContent);
        }
        if (element.content.__typename === "Flowchart") {
            return this.renderFlowchart(element.id, element.content, index, pageContent);
        }
        if (element.content.__typename === "FlowchartItem") {
            return this.renderFlowchartItem(element.id, element.content, index, pageContent);
        }
        Log.warning("Element type invalid", element);
        return null;
    };

    private isUsedId = (id: string): boolean => {
        return this.props.dragIds.includes(id);
    };

    private renderAsset(id: string, asset: AssetContent, index: number, pageContent: PageContent): React.ReactElement<any> {
        return (
            <SidebarContentElement
                key={id}
                id={id}
                index={index}
                title={asset.title}
                searchListType={SearchListType.asset}
                meta={Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` })}
                imageUrls={[ApiTypes.getAssetImageUrl(asset)]}
                onAddClick={() => this.props.onAddClicked(asset)}
                canHaveMultipleAssets={this.props.canHaveMultipleAssets}
                isUsed={this.isUsedId(asset.id)}
                disabled={this.state.isSearchInProgress}
                renderOptionsMenu={() => (
                    <AssetOptionMenu
                        asset={asset}
                        pageContent={pageContent}
                        isOwnAsset={pageContent === PageContent.own}
                        isPublic={pageContent === PageContent.public}
                        onDeleteClick={() => this.onDeleteClick(DialogType.deleteAsset, asset)}
                        isUsed={this.isUsedId(asset.id)}
                    />
                )}
            />
        );
    }

    private onDeleteClick = (
        dialogType: DialogType.deleteAgenda | DialogType.deleteAgendaItem | DialogType.deleteFlowchart | DialogType.deleteFlowchartItem | DialogType.deleteAsset,
        content: AgendaContent | AgendaItemContent | FlowchartContent | FlowchartItemContent | AssetContent,
    ) => {
        const props: any = {
            onDeleted: () => this.refreshLibrary(LibraryType.All, this.state.options),
        };

        switch (dialogType) {
            case DialogType.deleteAgenda:
                props.agenda = content;
                break;
            case DialogType.deleteAgendaItem:
                props.agendaItem = content;
                break;
            case DialogType.deleteFlowchart:
                props.flowchart = content;
                break;
            case DialogType.deleteFlowchartItem:
                props.flowchartItem = content;
                break;
            case DialogType.deleteAsset:
                props.asset = content;
                break;
            default:
                break;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType[dialogType],
                ...props,
            }),
        );
    };

    private renderAgenda(id: string, agenda: AgendaContent, index: number, pageContent: PageContent): React.ReactElement<any> {
        return (
            <SidebarContentElement
                key={agenda.id}
                id={id}
                searchListType={SearchListType.agenda}
                index={index}
                title={agenda.title}
                disabled={this.state.isSearchInProgress}
                isUsed={this.isUsedId(agenda.id)}
                meta={Intl.formatMessage({ id: "enum.searchListType.agenda" })}
                imageUrls={[]}
                onAddClick={() => this.props.onAddClicked(agenda)}
                renderOptionsMenu={() => (
                    <ContentOptionMenu
                        pageContent={pageContent}
                        clientExtId={this.props.client ? this.props.client.extId : undefined}
                        content={agenda}
                        onDeleteClick={() => this.onDeleteClick(DialogType.deleteAgenda, agenda)}
                        isUsed={this.isUsedId(agenda.id)}
                    />
                )}
            />
        );
    }

    private renderAgendaItem(id: string, agendaItem: AgendaItemContent, index: number, pageContent: PageContent): React.ReactElement<any> {
        const asset = agendaItem.image || agendaItem.video;
        return (
            <SidebarContentElement
                id={id}
                key={agendaItem.id}
                index={index}
                title={agendaItem.title}
                disabled={this.state.isSearchInProgress}
                meta={Intl.formatMessage({ id: "enum.searchListType.agendaItem" })}
                imageUrls={asset ? [ApiTypes.getAssetImageUrl(asset)] : []}
                searchListType={SearchListType.agendaItem}
                isUsed={this.isUsedId(agendaItem.id)}
                onAddClick={() => this.props.onAddClicked(agendaItem)}
                isSelect={agendaItem.type === AgendaItemType.Select}
                renderOptionsMenu={() => (
                    <ContentOptionMenu
                        pageContent={pageContent}
                        clientExtId={this.props.client ? this.props.client.extId : undefined}
                        content={agendaItem}
                        onDeleteClick={() => this.onDeleteClick(DialogType.deleteAgendaItem, agendaItem)}
                        isUsed={this.isUsedId(agendaItem.id)}
                    />
                )}
            />
        );
    }

    private renderFlowchart(id: string, flowchart: FlowchartContent, index: number, pageContent: PageContent): React.ReactElement<any> {
        return (
            <SidebarContentElement
                key={flowchart.id}
                id={id}
                index={index}
                title={flowchart.title}
                disabled={this.state.isSearchInProgress}
                meta={Intl.formatMessage({ id: "enum.searchListType.flowchart" })}
                imageUrls={flowchart.image ? [ApiTypes.getAssetImageUrl(flowchart.image)] : []}
                searchListType={SearchListType.flowchart}
                isUsed={this.isUsedId(flowchart.id)}
                onAddClick={() => this.props.onAddClicked(flowchart)}
                renderOptionsMenu={() => (
                    <>
                        <ContentOptionMenu
                            pageContent={pageContent}
                            clientExtId={this.props.client?.extId}
                            content={flowchart}
                            onDeleteClick={() => this.onDeleteClick(DialogType.deleteFlowchart, flowchart)}
                            isUsed={this.isUsedId(flowchart.id)}
                        />
                    </>
                )}
            />
        );
    }

    private renderFlowchartItem(id: string, flowchartItem: FlowchartItemContent, index: number, pageContent: PageContent): React.ReactElement<any> {
        const asset = flowchartItem.image || flowchartItem.video;
        return (
            <SidebarContentElement
                key={flowchartItem.id}
                id={id}
                index={index}
                title={flowchartItem.title}
                disabled={this.state.isSearchInProgress}
                isUsed={this.isUsedId(flowchartItem.id)}
                meta={Intl.formatMessage({ id: "enum.searchListType.flowchartItem" })}
                imageUrls={asset ? [ApiTypes.getAssetImageUrl(asset)] : []}
                searchListType={SearchListType.flowchartItem}
                isSelect={flowchartItem.flowchartType === FlowchartItemType.Select}
                isEmbedded={!!flowchartItem.flowchart}
                onAddClick={() => this.props.onAddClicked(flowchartItem)}
                renderOptionsMenu={() => (
                    <ContentOptionMenu
                        pageContent={pageContent}
                        clientExtId={this.props.client ? this.props.client.extId : undefined}
                        content={flowchartItem}
                        onDeleteClick={() => this.onDeleteClick(DialogType.deleteFlowchartItem, flowchartItem)}
                        isUsed={this.isUsedId(flowchartItem.id)}
                    />
                )}
            />
        );
    }

    public render(): React.ReactElement<any> {
        const isClientLibraryOpened: boolean = this.props.clientLibrary.length > 0;
        const isMyLibraryOpened: boolean = !isClientLibraryOpened && this.props.myLibrary.length > 0;

        return (
            <div onScroll={this.handleScroll} className="right-side content-library-side-bar">
                <h2>{Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.title" })}</h2>
                <CreateContentButton client={this.props.client} isUploadEnabled={this.props.isUploadEnabled} onAssetUploaded={() => this.refreshLibrary(LibraryType.All, this.state.options)} />

                <div className="searchForm-wrapper">
                    <SearchForm
                        icon
                        isLoading={!this.state.isFirstLoading && this.state.isLoading}
                        onSearchClick={this.onSearchClick}
                        defaultValue={this.state.options.search}
                        withTags={true}
                        selectedTags={this.state.options.tags || []}
                        searchPlaceholder={Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.searchForm.placeholder" })}
                        onTypeStarted={(): void => {
                            this.setState({ isSearchInProgress: true });
                        }}
                    />
                </div>

                {this.state.isFirstLoading ? (
                    <Loading />
                ) : (
                    <div className="scrolled-content">
                        {this.props.client && (
                            <>
                                <Accordion
                                    icon={"fa-list-alt"}
                                    label={Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.contentType.client" }, { name: this.props.client?.name })}
                                    isOpen={isClientLibraryOpened}
                                >
                                    <Droppable droppableId={"clientContent"} isDropDisabled={true}>
                                        {(dropProvided: DroppableProvided): React.ReactElement<any> => {
                                            return (
                                                <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                                    {this.props.clientLibrary.map(this.renderContentElement(PageContent.client))}
                                                    {dropProvided.placeholder}
                                                </div>
                                            );
                                        }}
                                    </Droppable>
                                    {this.state.isLoading && <span className="loading-label">{Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.loadMore" })}</span>}
                                    <div
                                        ref={(ref: HTMLDivElement | null): void => {
                                            this.clientLibraryBottomRef = ref;
                                        }}
                                    />
                                    {!this.state.isLoading && this.props.clientLibrary.length === 0 && (
                                        <div className="text-center p-5 font-bold">{Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.noData" })}</div>
                                    )}
                                </Accordion>
                            </>
                        )}

                        {this.props.createdById && (
                            <Accordion icon={"fa-list-alt"} label={Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.contentType.mine" })} isOpen={isMyLibraryOpened}>
                                {this.props.createdById && this.state.personalDirectories && this.props.searchListTypes.includes(SearchListType.asset) && (
                                    <DirectorySidebar directories={this.state.personalDirectories} onDirectoryClick={this.onDirectoryChange} selectedDirectory={this.state.options.directory || null} />
                                )}
                                {this.props.myLibrary.length > 0 ? (
                                    <>
                                        <Droppable droppableId={"myContent"} isDropDisabled={true}>
                                            {(dropProvided: DroppableProvided): React.ReactElement<any> => {
                                                return (
                                                    <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                                        {this.props.myLibrary.map(this.renderContentElement(PageContent.own))}
                                                        {dropProvided.placeholder}
                                                    </div>
                                                );
                                            }}
                                        </Droppable>
                                        {this.state.isLoading && <span className="loading-label">{Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.loadMore" })}</span>}
                                        <div
                                            ref={(ref: HTMLDivElement | null): void => {
                                                this.myLibraryBottomRef = ref;
                                            }}
                                        />
                                    </>
                                ) : (
                                    <div className="text-center p-5 font-bold">{Intl.formatMessage({ id: "sharedComponent.contentLibrarySideBar.noData" })}</div>
                                )}
                            </Accordion>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export const ContentLibrarySideBar = withRouter(connect()(ContentLibrarySideBarComponent));

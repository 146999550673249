import React, { PureComponent } from "react";
import { Notification } from "components/NotificationBar/Notification";
import { NotificationContext } from "./NotificationBar/NotificationContext";
import { NotificationBar } from "./NotificationBar/NotificationBar";
import { Title } from "./Title";
import { Link } from "react-router-dom";

interface Props {
    id?: string;
    title: string;
    children?: React.ReactNode;
    hasSideBar?: React.ReactNode;
    hasTabs?: boolean;
    backButtonUrl?: string;
    renderButtons?: () => React.ReactElement<any> | Array<React.ReactElement<any>> | null;
}

class Page extends PureComponent<Props> {
    private notificationBarRef: NotificationBar | null = null;

    public showNotification = (notification: Notification): void => {
        if (this.notificationBarRef) {
            this.notificationBarRef.showNotification(notification);
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <main className={`subpage${this.props.hasSideBar ? " side-bar-view" : ""}${this.props.hasTabs ? " has-tabs" : ""}`} id={this.props.id}>
                <div className={`page-header ${this.props.backButtonUrl ? "has-back-button" : ""}`}>
                    {this.props.backButtonUrl && (
                        <Link to={this.props.backButtonUrl}>
                            <i className="fa fa-chevron-left" />
                        </Link>
                    )}
                    <Title>{this.props.title}</Title>
                    <h1>{this.props.title}</h1>
                    {this.props.renderButtons && <span className="buttons">{this.props.renderButtons()}</span>}
                </div>

                <div className="main-content">
                    <NotificationContext.Provider value={{ showNotification: this.showNotification }}>
                        {this.props.children}
                        <NotificationBar
                            ref={(ref: NotificationBar | null): void => {
                                this.notificationBarRef = ref;
                            }}
                        />
                    </NotificationContext.Provider>
                </div>
            </main>
        );
    }
}

export { Page };

import React, { Component } from "react";
import { Account, AccountType } from "api/graphql/types";
import { isNil } from "lodash";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { Redirect } from "react-router-dom";
import { Path } from "utils/Path";
import AccountEventLogTable from "pages/_shared/AccountEventLogTable/AccountEventLogTable";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";

interface ReduxProps {
    account?: Account | null;
}

type Props = ReduxProps;

class MyEventLogPageComponent extends Component<Props> {
    public render(): React.ReactElement {
        const { account } = this.props;

        if (isNil(account)) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.myEventLog.title" }, { name: account.name })}>
                <AccountEventLogTable
                    viewedAccountType={account.accountType}
                    authAccountExtId={account.accountType === AccountType.supporter ? account.extId || undefined : undefined}
                    authAccountId={[AccountType.supervisor, AccountType.superadmin, AccountType.admin].includes(account.accountType) ? account.id : undefined}
                />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, Props, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account };
};

export const MyEventLogPage = connect(mapStateToProps)(MyEventLogPageComponent);

export default MyEventLogPage;

import * as React from "react";

function SvgTags(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={17} fill="none" {...props}>
            <path
                d="M5 3.571C5 4.364 4.364 5 3.571 5a1.424 1.424 0 01-1.428-1.429c0-.792.636-1.428 1.428-1.428C4.364 2.143 5 2.779 5 3.57zM16.909 10c0-.38-.157-.748-.413-1.016l-7.98-7.968C7.946.446 6.864 0 6.07 0H1.43C.647 0 0 .647 0 1.429V6.07c0 .793.446 1.875 1.016 2.433l7.98 7.992c.256.256.625.413 1.004.413.38 0 .748-.157 1.016-.413l5.48-5.491c.256-.257.413-.626.413-1.005zm4.285 0c0-.38-.156-.748-.413-1.016l-7.98-7.968C12.232.446 11.15 0 10.357 0h-2.5c.793 0 1.875.446 2.444 1.016l7.98 7.968c.257.268.413.637.413 1.016 0 .38-.156.748-.413 1.005l-5.245 5.245c.38.39.67.659 1.25.659.38 0 .748-.157 1.015-.413l5.48-5.491c.257-.257.413-.626.413-1.005z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgTags;

import React, { Component } from "react";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { InstantAward, AssetType, InstantAward_image, Client } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Column, Table } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { DispatchProp, connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Button } from "components/Button/Button";
import { Path } from "utils/Path";
import { Section } from "components/Section";
import { isNil } from "lodash";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Image } from "components/Image";

enum InstantAwardsTableColumn {
    title = "title",
    actions = "actions",
}

interface ComponentProps {
    client: Client;
}

interface State {
    isLoading: boolean;
    instantAwards: InstantAward[];
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class ClientInstantAwardsTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        instantAwards: [],
    };

    public componentDidMount(): void {
        this.refreshClientInstantAwards(this.props.client!.id);
    }

    private refreshClientInstantAwards = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const instantAwards = await Api.getClientInstantAwards(clientId);
                    this.setState({ isLoading: false, instantAwards });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, instantAwards: [] });
                }
            },
        );
    };

    private onInstantAwardEdit = (instantAwardId: string): void => {
        this.props.history.push(Path.editClientInstantAward(this.props.client.extId, instantAwardId));
    };

    private renderActionsCell = (instantAwardId: string): React.ReactElement => {
        return (
            <div className="action-buttons">
                <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={() => this.onInstantAwardEdit(instantAwardId)} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                <Button link icon={{ name: "fa-trash", large: true }} onClick={() => this.onInstantAwardDelete(instantAwardId)} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
            </div>
        );
    };

    private onImageClick = (image: InstantAward_image | null): void => {
        if (isNil(image?.url)) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.showAsset,
                assetUrl: image!.url,
                assetType: AssetType.image,
                originalFileName: image!.originalFileName,
                thumbnailUrl: image!.url,
                dialogTitle: Intl.formatMessage({ id: "page.clientAwardsBase.showImageDialogTitle" }),
            }),
        );
    };

    private renderTitleAndImage = (instantAward: InstantAward): React.ReactElement => {
        return (
            <div className="awardsbase-title-and-image">
                <div className={`awardsbase-image-container ${!isNil(instantAward.image?.url) ? " show-cursor" : ""}`} onClick={() => this.onImageClick(instantAward.image)}>
                    <Image className="awardsbase-image" src={instantAward.image?.thumbnailUrl || instantAward.image?.url} />
                    {instantAward.image?.url && (
                        <>
                            <div className="content-overlay" />
                            <div className="content-overlay-icon">
                                <span className="fa fa-search-plus" />
                            </div>
                        </>
                    )}
                </div>
                <span className="awardsbase-title">{instantAward.title}</span>
            </div>
        );
    };

    private readonly getColumns = (): Array<Column<InstantAward>> => {
        const columnNames: InstantAwardsTableColumn[] = ObjectUtils.enumAsArray<InstantAwardsTableColumn>(InstantAwardsTableColumn);

        return columnNames.map(
            (columnName: InstantAwardsTableColumn): Column<InstantAward> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.clientAwardsBase.clientInstantAwardsTab.columns.${columnName}` }),
                accessor: columnName as keyof InstantAward,
                renderCell: (instantAward: InstantAward): React.ReactElement | null => {
                    switch (columnName) {
                        case InstantAwardsTableColumn.title:
                            return this.renderTitleAndImage(instantAward);
                        case InstantAwardsTableColumn.actions:
                            return <span>{this.renderActionsCell(instantAward.id)}</span>;
                        default:
                            return <></>;
                    }
                },
            }),
        );
    };

    private onInstantAwardDelete = (clientInstantAwardId: string): void => {
        const award = this.state.instantAwards.find(award => award.id === clientInstantAwardId);
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteClientInstantAward,
                clientInstantAwardId,
                onDeleted: () => this.refreshClientInstantAwards(this.props.client!.id),
                hasReferences: (award?.referenceCount ?? 0) > 0,
            }),
        );
    };

    private renderList = (): React.ReactElement => {
        return (
            <>
                <p>{Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAwardsTab.lead" })}</p>
                <Table
                    keyExtractor={(instantAward: InstantAward, column?: Column<InstantAward>): string => {
                        return `${instantAward.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    data={this.state.instantAwards}
                    count={this.state.instantAwards.length}
                    isLoading={this.state.isLoading}
                    currentPage={1}
                    renderEmpty={(): string => Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAwardsTab.noData" })}
                />
            </>
        );
    };

    public render(): React.ReactElement {
        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAwardsTab.title" })}>
                    {this.renderList()}
                    <Button
                        icon={{ name: "fa-plus", position: "left" }}
                        label={Intl.formatMessage({ id: "common.addReward" })}
                        onClick={() => this.props.history.push(Path.createClientInstantAward(this.props.client.extId))}
                        hollow
                    />
                </Section>
            </div>
        );
    }
}

export const ClientInstantAwardsTab = withRouter(connect()(ClientInstantAwardsTabComponent));

export default ClientInstantAwardsTab;

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator, ValidatorConstants } from "utils/Validator";
import { Button } from "components/Button/Button";
import { Api } from "api/Api";
import { DateInput } from "components/Inputs/Date/DateInput";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ClientProfileData } from "api/graphql/types";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";

interface ComponentProps {
    clientId: string;
    dateOfBirth: Date;
    onDateOfBirthChange: (client: ClientProfileData) => void;
    disabled: boolean;
}

type Props = ComponentProps & NotificationProp;

interface State {
    isEditable: boolean;
    dateOfBirth: Date | null;
    formErrors: {
        dateOfBirth: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ClientProfileDateOfBirthFormComponent extends Component<Props, State> {
    private dateOfBirthRef: DateInput | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            dateOfBirth: this.props.dateOfBirth,
            formErrors: {
                dateOfBirth: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onDateOfBirthChange = (dateOfBirth: Date | null): void => {
        const dateOfBirthError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateClientDateOfBirth(dateOfBirth)) : null;
        this.setState({ dateOfBirth, formErrors: { ...this.state.formErrors, dateOfBirth: dateOfBirthError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { dateOfBirth } = this.state;
        const formErrors = {
            dateOfBirth: IntlHelpers.getValidationError(Validator.validateClientDateOfBirth(dateOfBirth)),
        };

        if (!dateOfBirth || formErrors.dateOfBirth) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.dateOfBirth && this.dateOfBirthRef) {
                    this.dateOfBirthRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const dateOfBirthString: string = DateUtils.format(dateOfBirth, DateFormat.default);
                    const client: ClientProfileData = await Api.updateClient(this.props.clientId, { dateOfBirth: dateOfBirthString });
                    this.props.onDateOfBirthChange(client);
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.personalData.dateOfBirthForm.dateOfBirthChangeSucceed" }) });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, dateOfBirth, formErrors, isLoading } = this.state;
        const isDateInputDisabled = !isEditable;
        return (
            <form id={TestId.clientProfile.dateOfBirthForm} noValidate={true} onSubmit={this.onSubmit}>
                <InputWrapper
                    id={TestId.clientProfile.dateOfBirthInput}
                    inputLabel={Intl.formatMessage({ id: "page.clientProfile.personalData.dateOfBirthForm.dateOfBirth.label" })}
                    errorMessage={formErrors.dateOfBirth}
                    childrenWrapperClassName={isDateInputDisabled ? "doEdit" : ""}
                >
                    <DateInput
                        ref={(ref: DateInput | null) => {
                            this.dateOfBirthRef = ref;
                        }}
                        placeholder={Intl.formatMessage({ id: "page.clientProfile.personalData.dateOfBirthForm.dateOfBirth.placeholder" })}
                        value={dateOfBirth}
                        onChange={this.onDateOfBirthChange}
                        min={ValidatorConstants.getClientDateOfBirthMinDate()}
                        max={ValidatorConstants.getClientDateOfBirthMaxDate()}
                        disabled={isDateInputDisabled || this.props.disabled}
                    />
                </InputWrapper>

                {!this.props.disabled &&
                    (isEditable ? (
                        <div className="action-button-container">
                            <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                            <Button
                                ariaLabel={Intl.formatMessage({ id: "common.save" })}
                                label={Intl.formatMessage({ id: "common.save" })}
                                type="submit"
                                disabled={isLoading || !!formErrors.dateOfBirth || dateOfBirth === this.props.dateOfBirth}
                            />
                        </div>
                    ) : (
                        <div className="edit-button">
                            <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                        </div>
                    ))}

                {!this.props.disabled && <Prompt when={!dateOfBirth || dateOfBirth.getTime() !== this.props.dateOfBirth.getTime()} />}
            </form>
        );
    }
}

const ClientProfileDateOfBirthForm = withNotification(ClientProfileDateOfBirthFormComponent);

export { ClientProfileDateOfBirthForm };

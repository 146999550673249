// tslint:disable: max-line-length
import { DateUtils } from "./DateUtils";
import { ColorsOfDaysInput, MoodMeterItemInput, ClientGameInput, AgendaItemAlertDisplayType, AssetContent, SelectionBoardItemInput, FlowchartItemFlowchartItemRel } from "../api/graphql/types"; // use absolute path to be able to use in tests
import { ObjectUtils } from "./ObjectUtils";
import { ColorsOfDaysType } from "./TypeUtils";
import { isNil } from "lodash";
import { SelectionBoardItemLocal } from "pages/ClientSelectionBoardsPage/ClientSelectionBoardItemInputForm";

enum ValidatorMessage {
    invalidPassword = "invalidPassword",
    passwordTooShort = "passwordTooShort",
    passwordsNotMatch = "passwordsNotMatch",
    invalidEmail = "invalidEmail",
    invalidName = "invalidName",
    invalidPin = "invalidPin",
    clientTooYoung = "clientTooYoung",
    clientTooOld = "clientTooOld",
    introductionTooLong = "introductionTooLong",
    required = "required",
    invalidUnlockPattern = "invalidUnlockPattern",
    avatarFileTooBig = "avatarFileTooBig",
    invalidAssetName = "invalidAssetName",
    invalidSelectionBoardItemsCount = "invalidSelectionBoardItemsCount",
    invalidSelectionBoardTitle = "invalidSelectionBoardTitle",
    invalidSelectionBoardItem = "invalidSelectionBoardItem",
    invalidSelectionBoardItemTitle = "invalidSelectionBoardItemTitle",
    invalidFlowchartItemTitle = "invalidFlowchartItemTitle",
    invalidFlowchartItemFlowchartItemsLength = "invalidFlowchartItemFlowchartItemsLength",
    invalidChecklistItemTitle = "invalidChecklistItemTitle",
    invalidAwardScore = "invalidAwardScore",
    invalidTimer = "invalidTimer",
    invalidFlowchartTitle = "invalidFlowchartTitle",
    invalidFlowchartDescription = "invalidFlowchartDescription",
    invalidAgendaItemTitle = "invalidAgendaItemTitle",
    invalidAgendaItemAlertText = "invalidAgendaItemAlertText",
    invalidAgendaTitle = "invalidAgendaTitle",
    invalidAgendaDescription = "invalidAgendaDescription",
    moodMeterItemsTooFew = "moodMeterItemsTooFew",
    moodMeterItemsTooMany = "moodMeterItemsTooMany",
    invalidClientPrizeTitle = "invalidClientPrizeTitle",
    invalidClientPrizeTargetTitle = "invalidClientPrizeTargetTitle",
    invalidAlertTypeWithTime = "invalidAlertTypeWithTime",
    invalidEnabledGamesCount = "invalidEnabledGamesCount",
    faqQuestionTooShort = "faqQuestionTooShort",
    faqAnswerTooShort = "faqAnswerTooShort",
    invalidMemoryGamesAssetCount = "invalidMemoryGamesAssetCount",
    invalidEverydaySituationClientDirectoryTitle = "invalidEverydaySituationClientDirectoryTitle",
    awardTitleLengthInvalid = "awardTitleLengthInvalid",
    instantAwardTitleLengthInvalid = "instantAwardTitleLengthInvalid",
    awardScoreInvalid = "awardScoreInvalid",
    agendaItemInstantAwardsNumberInvalid = "agendaItemInstantAwardsNumberInvalid",
    invalidTagTitle = "invalidTagTitle",
    invalidSubModuleTitle = "invalidSubModuleTitle",
    invalidSubModuleVideoTitle = "invalidSubModuleVideoTitle",
    invalidNotification = "invalidNotification",
    shareRequestEmailNotUnique = "shareRequestEmailNotUnique",
}

class ValidatorConstants {
    public static readonly EMAIL_REGEX: RegExp = /(?:[a-z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    public static readonly CLIENT_NAME_LENGTH_MIN: number = 3;
    public static readonly CLIENT_NAME_LENGTH_MAX: number = 128;

    public static readonly MIN_PASSWORD_LENGTH: number = 8;
    public static readonly PIN_REGEX: RegExp = /^\d{4}$/;
    public static readonly CLIENT_INTRODUCTION_MAX_LENGTH: number = 1024;

    public static readonly CLIENT_MAX_AGE = 100;
    public static readonly CLIENT_MIN_AGE = 2;

    public static readonly CLIENT_CONFIG_UNLOCK_MIN_LENGTH = 1;
    public static readonly CLIENT_CONFIG_UNLOCK_MAX_LENGTH = 8;

    public static readonly AVATAR_IMAGE_MAX_WIDTH = 400;
    public static readonly AVATAR_IMAGE_MAX_HEIGHT = 400;

    public static readonly ASSET_NAME_LENGTH_MIN: number = 3;
    public static readonly ASSET_NAME_LENGTH_MAX: number = 128;

    public static readonly SELECTION_BOARD_TITLE_LENGTH_MIN: number = 3;
    public static readonly SELECTION_BOARD_TITLE_LENGTH_MAX: number = 128;
    public static readonly SELECTION_BOARD_ITEM_COUNT_MIN: number = 2;
    public static readonly SELECTION_BOARD_ITEM_COUNT_MAX: number = 12;
    public static readonly SELECTION_BOARD_ITEM_TITLE_LENGTH_MIN: number = 3;
    public static readonly SELECTION_BOARD_ITEM_TITLE_LENGTH_MAX: number = 128;

    public static readonly FLOWCHART_ITEM_TITLE_LENGTH_MIN: number = 3;
    public static readonly FLOWCHART_ITEM_TITLE_LENGTH_MAX: number = 128;

    public static readonly FLOWCHART_ITEM_FLOWCHART_ITEMS_LENGTH_MIN: number = 2;
    public static readonly FLOWCHART_ITEM_FLOWCHART_ITEMS_LENGTH_MAX: number = 2;

    public static readonly CHECKLIST_TITLE_LENGTH_MIN: number = 3;
    public static readonly CHECKLIST_TITLE_LENGTH_MAX: number = 128;

    public static readonly PRIZE_SCORE_MIN = 1;
    public static readonly PRIZE_SCORE_MAX = 10000;

    public static readonly PRIZE_TITLE_LENGTH_MIN: number = 3;
    public static readonly PRIZE_TITLE_LENGTH_MAX: number = 128;
    public static readonly PRIZE_TARGET_TITLE_LENGTH_MIN: number = 3;
    public static readonly PRIZE_TARGET_TITLE_LENGTH_MAX: number = 1024;

    public static readonly FLOWCHART_TITLE_LENGTH_MIN: number = 3;
    public static readonly FLOWCHART_TITLE_LENGTH_MAX: number = 128;
    public static readonly FLOWCHART_DESCRIPTION_LENGTH_MAX: number = 1024;

    public static readonly AGENDA_ITEM_TITLE_LENGTH_MIN: number = 3;
    public static readonly AGENDA_ITEM_TITLE_LENGTH_MAX: number = 128;

    public static readonly AGENDA_ITEM_ALERT_TITLE_LENGTH_MIN: number = 1;
    public static readonly AGENDA_ITEM_ALERT_TITLE_LENGTH_MAX: number = 128;

    // https://jira.bigfish.hu/browse/AOSZ-874: Accept 1 - 60 min
    // public static readonly TIMER_SEC_MIN: number = 1;
    // public static readonly TIMER_SEC_MAX: number = 86400;
    public static readonly TIMER_SEC_MIN: number = 0;
    public static readonly TIMER_SEC_MAX: number = 60 * 60;

    public static readonly AGENDA_TITLE_LENGTH_MIN: number = 3;
    public static readonly AGENDA_TITLE_LENGTH_MAX: number = 128;
    public static readonly AGENDA_DESCRIPTION_LENGTH_MAX: number = 1024;

    public static readonly MOODMETER_ITEMS_LENGTH_MIN: number = 2;
    public static readonly MOODMETER_ITEMS_LENGTH_MAX: number = 12;

    public static readonly MIN_ENABLED_GAMES_COUNT: number = 1;

    public static readonly MAX_TAG_COUNT: number = 7;

    public static readonly FAQ_QUESTION_LENGTH_MIN: number = 1;
    public static readonly FAQ_ANSWER_LENGTH_MIN: number = 1;

    public static readonly INSTANT_AWARD_TITLE_LENGTH_MIN: number = 3;
    public static readonly INSTANT_AWARD_TITLE_LENGTH_MAX: number = 128;

    public static readonly AWARD_TITLE_LENGTH_MIN: number = 3;
    public static readonly AWARD_TITLE_LENGTH_MAX: number = 128;

    public static readonly AWARD_SCORE_MIN: number = 1;
    public static readonly AWARD_SCORE_MAX: number = 10000;

    public static readonly AGENDA_ITEM_INSTANT_AWARDS_LENGTH_MIN = 1;

    public static readonly EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MIN: number = 3;
    public static readonly EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MAX: number = 128;

    public static readonly MEMORY_GAME_MAX_ASSET_COUNT: number = 40;

    public static readonly TAG_TITLE_LENGTH_MIN: number = 3;
    public static readonly TAG_TITLE_LENGTH_MAX: number = 128;

    public static readonly EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MIN: number = 3;
    public static readonly EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MAX: number = 128;

    public static readonly EDUCATION_CONTENT_TITLE_LENGTH_MIN: number = 3;
    public static readonly EDUCATION_CONTENT_TITLE_LENGTH_MAX: number = 128;

    public static readonly NOTIFICATION_TEXT_LENGTH_MIN: number = 1;
    public static readonly NOTIFICATION_TEXT_LENGTH_MAX: number = 1024;

    public static getClientDateOfBirthMinDate() {
        return DateUtils.subYears(new Date(), ValidatorConstants.CLIENT_MAX_AGE);
    }

    public static getClientDateOfBirthMaxDate() {
        return DateUtils.subYears(new Date(), ValidatorConstants.CLIENT_MIN_AGE);
    }
}

/**
 * Class for validator functions, returns null or a ValidatorMessage
 * Return value can processed by IntlHelper
 */
class Validator {
    /**
     * Check password not empty and at least 8 chars length
     * @param password password
     */
    public static validatePassword(password: string): ValidatorMessage | null {
        if (!password) {
            return ValidatorMessage.invalidPassword;
        }
        if (password.length < ValidatorConstants.MIN_PASSWORD_LENGTH) {
            return ValidatorMessage.passwordTooShort;
        }

        return null;
    }

    /**
     * Check passwords equality
     * @param password password
     * @param passwordRepeat passwordRepeat
     */
    public static validatePasswordRepeat(password: string, passwordRepeat: string): ValidatorMessage | null {
        if (password !== passwordRepeat) {
            return ValidatorMessage.passwordsNotMatch;
        }
        return this.validatePassword(password);
    }

    /**
     * Validate email by regex
     * @param email email
     */
    public static validateEmail(email: string): ValidatorMessage | null {
        if (!email.trim() || !ValidatorConstants.EMAIL_REGEX.test(email)) {
            return ValidatorMessage.invalidEmail;
        }
        return null;
    }

    public static validateShareRequestEmail(email: string, emails: string[]): ValidatorMessage | null {
        const emailError = Validator.validateEmail(email);
        if (emailError) {
            return emailError;
        }

        if (emails.some((e: string) => email === e)) {
            return ValidatorMessage.shareRequestEmailNotUnique;
        }
        return null;
    }

    /**
     * Validate client name
     * @param name name
     */
    public static validateName(name: string): ValidatorMessage | null {
        if (name.length < ValidatorConstants.CLIENT_NAME_LENGTH_MIN || name.length > ValidatorConstants.CLIENT_NAME_LENGTH_MAX) {
            return ValidatorMessage.invalidName;
        }
        return null;
    }

    /**
     * Validate app pin code
     * @param pin pin code
     */
    public static validatePin(pin: string): ValidatorMessage | null {
        if (!ValidatorConstants.PIN_REGEX.test(pin)) {
            return ValidatorMessage.invalidPin;
        }
        return null;
    }

    /**
     * Validate client date of birth
     * - min: ValidatorConstants.CLIENT_MIN_AGE
     * - max: ValidatorConstants.CLIENT_MAX_AGE
     * @param dateOfBirth Client date of birth
     */
    public static validateClientDateOfBirth(dateOfBirth: Date | null): ValidatorMessage | null {
        if (!dateOfBirth) {
            return ValidatorMessage.required;
        }
        const age: number = DateUtils.getAge(dateOfBirth);
        if (age < ValidatorConstants.CLIENT_MIN_AGE) {
            return ValidatorMessage.clientTooYoung;
        } else if (age > ValidatorConstants.CLIENT_MAX_AGE) {
            return ValidatorMessage.clientTooOld;
        }
        return null;
    }

    /**
     * Validate client introduction
     * @param introduction string
     */
    public static validateClientIntroduction(introduction: string): ValidatorMessage | null {
        if (introduction && introduction.length > ValidatorConstants.CLIENT_INTRODUCTION_MAX_LENGTH) {
            return ValidatorMessage.introductionTooLong;
        }
        return null;
    }

    /**
     * Validate clientConfig.unlock.pattern length
     * - min: ValidatorConstants.CLIENT_CONFIG_UNLOCK_MIN_LENGTH
     * - max: ValidatorConstants.CLIENT_CONFIG_UNLOCK_MAX_LENGTH
     * @param pattern string unlock pattern
     */
    public static validateClientConfigUnlockPattern(pattern: string[]): ValidatorMessage | null {
        if (pattern.length < ValidatorConstants.CLIENT_CONFIG_UNLOCK_MIN_LENGTH || pattern.length > ValidatorConstants.CLIENT_CONFIG_UNLOCK_MAX_LENGTH) {
            return ValidatorMessage.invalidUnlockPattern;
        }
        return null;
    }

    /**
     * Validate ColorsOfDaysInput
     * if default, we don't validate
     * if custom, every field must be non null value
     * @param colorsOfDaysType ColorsOfDaysType the selected option value
     * @param colorsOfDays ColorsOfDaysInput
     */
    public static validateClientConfigColorsOfDays(colorsOfDaysType: ColorsOfDaysType, colorsOfDays: ColorsOfDaysInput): ValidatorMessage | null {
        if (colorsOfDaysType === ColorsOfDaysType.custom) {
            const hasUnfilledValue: boolean = ObjectUtils.keys(colorsOfDays).some((color: keyof ColorsOfDaysInput) => colorsOfDays[color] === null);
            if (hasUnfilledValue) {
                return ValidatorMessage.required;
            }
        }
        return null;
    }

    /**
     * Validate moodMeter items
     * There should be at least 2.
     * @param items MoodMeterItemInput[]
     */
    public static validateMoodMeterItems(items: MoodMeterItemInput[]): ValidatorMessage | null {
        if (items.length < ValidatorConstants.MOODMETER_ITEMS_LENGTH_MIN) {
            return ValidatorMessage.moodMeterItemsTooFew;
        } else if (items.length > ValidatorConstants.MOODMETER_ITEMS_LENGTH_MAX) {
            return ValidatorMessage.moodMeterItemsTooMany;
        }
        return null;
    }

    /**
     * Validate image size for avatar
     * @param image HTMLImageElement
     */
    public static validateAvatarImageSize(width: number, height: number): ValidatorMessage | null {
        if (width > ValidatorConstants.AVATAR_IMAGE_MAX_WIDTH || height > ValidatorConstants.AVATAR_IMAGE_MAX_HEIGHT) {
            return ValidatorMessage.avatarFileTooBig;
        }
        return null;
    }

    /**
     * Validate asset name
     * @param name string
     */
    public static validateAssetName(name: string): ValidatorMessage | null {
        if (name.length < ValidatorConstants.ASSET_NAME_LENGTH_MIN || name.length > ValidatorConstants.ASSET_NAME_LENGTH_MAX) {
            return ValidatorMessage.invalidAssetName;
        }
        return null;
    }

    /**
     * Validate given value is non null
     * @param value any | null
     */
    public static validateNonNull(value: any | null): ValidatorMessage | null {
        if (value === null) {
            return ValidatorMessage.required;
        }
        return null;
    }

    /**
     * Validate given string is non null or empty
     * @param value string | null
     */
    public static validateNonEmpty(value: string | null): ValidatorMessage | null {
        if (value === "" || value === null) {
            return ValidatorMessage.required;
        }
        return null;
    }

    /**
     * Validate FlowchartItem title
     * @param title string
     */
    public static validateFlowchartItemTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.FLOWCHART_ITEM_TITLE_LENGTH_MIN || title.length > ValidatorConstants.FLOWCHART_ITEM_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidFlowchartItemTitle;
        }
        return null;
    }

    /**
     * Validate FlowchartItem flowchartItems
     * @param title string
     */
    public static validateFlowchartItemFlowchartItems(flowchartItems: FlowchartItemFlowchartItemRel[]): ValidatorMessage | null {
        if (flowchartItems.length < ValidatorConstants.FLOWCHART_ITEM_FLOWCHART_ITEMS_LENGTH_MIN || flowchartItems.length > ValidatorConstants.FLOWCHART_ITEM_FLOWCHART_ITEMS_LENGTH_MAX) {
            return ValidatorMessage.invalidFlowchartItemFlowchartItemsLength;
        }
        return null;
    }

    /**
     * Validate SelectionBoard title
     * @param title: string
     */
    public static validateSelectionBoardTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.SELECTION_BOARD_TITLE_LENGTH_MIN || title.length > ValidatorConstants.SELECTION_BOARD_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidSelectionBoardTitle;
        }
        return null;
    }

    /**
     * Validate SelectionBoardItems count
     * @param items: Array<SelectionBoardItem | null>
     */
    public static validateSelectionBoardItems(items: Array<SelectionBoardItemInput | null>): ValidatorMessage | null {
        if (items.length < ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MIN || items.length > ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MAX) {
            return ValidatorMessage.invalidSelectionBoardItemsCount;
        }
        return null;
    }

    /**
     * Validate ChecklistItem title
     * @param title string
     */
    public static validateChecklistItemTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.CHECKLIST_TITLE_LENGTH_MIN || title.length > ValidatorConstants.CHECKLIST_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidChecklistItemTitle;
        }
        return null;
    }

    public static validateSelectionBoardItem(item: SelectionBoardItemLocal): ValidatorMessage | null {
        if (!item.image?.id && !item.title) {
            return ValidatorMessage.invalidSelectionBoardItem;
        }
        if (
            !item.title ||
            (item.title && (item.title.length < ValidatorConstants.SELECTION_BOARD_ITEM_TITLE_LENGTH_MIN || item.title.length > ValidatorConstants.SELECTION_BOARD_ITEM_TITLE_LENGTH_MAX))
        ) {
            return ValidatorMessage.invalidSelectionBoardItemTitle;
        }
        return null;
    }

    /**
     * Validate Award score
     * @param score number
     */
    public static validateAwardScore(score: number | null): ValidatorMessage | null {
        if (score === null || score < ValidatorConstants.PRIZE_SCORE_MIN || score > ValidatorConstants.PRIZE_SCORE_MAX || Number.isNaN(score)) {
            return ValidatorMessage.invalidAwardScore;
        }
        return null;
    }

    /**
     * Validate timer seconds
     * @param seconds number
     */
    public static validateTimerSeconds(seconds: number | null): ValidatorMessage | null {
        if (isNil(seconds) || seconds < ValidatorConstants.TIMER_SEC_MIN || seconds > ValidatorConstants.TIMER_SEC_MAX) {
            return ValidatorMessage.invalidTimer;
        }
        return null;
    }

    /**
     * Validate Flowchart title
     * @param title string
     */
    public static validateFlowchartTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.FLOWCHART_TITLE_LENGTH_MIN || title.length > ValidatorConstants.FLOWCHART_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidFlowchartTitle;
        }
        return null;
    }

    /**
     * Validate Flowchart description
     * @param description string
     */
    public static validateFlowchartDescription(description: string | null): ValidatorMessage | null {
        if (description && description.length > ValidatorConstants.FLOWCHART_DESCRIPTION_LENGTH_MAX) {
            return ValidatorMessage.invalidFlowchartDescription;
        }
        return null;
    }

    /**
     * Validate AgendaItem title
     * @param title string
     */
    public static validateAgendaItemTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.AGENDA_ITEM_TITLE_LENGTH_MIN || title.length > ValidatorConstants.AGENDA_ITEM_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidAgendaItemTitle;
        }
        return null;
    }

    /**
     * Validate AgendaItem title
     * @param title string
     */
    public static validateAgendaItemAlertTitle(text: string): ValidatorMessage | null {
        if (text.length < ValidatorConstants.AGENDA_ITEM_ALERT_TITLE_LENGTH_MIN || text.length > ValidatorConstants.AGENDA_ITEM_ALERT_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidAgendaItemAlertText;
        }
        return null;
    }

    /**
     * Validate AgendaItem title
     * @param title string
     */
    public static validateAlertTypeWithTime(alertType: AgendaItemAlertDisplayType, time: string | null): ValidatorMessage | null {
        if (alertType === AgendaItemAlertDisplayType.Time && !time) {
            return ValidatorMessage.invalidAlertTypeWithTime;
        }
        return null;
    }

    /**
     * Validate Agenda title
     * @param title string
     */
    public static validateAgendaTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.AGENDA_TITLE_LENGTH_MIN || title.length > ValidatorConstants.AGENDA_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidAgendaTitle;
        }
        return null;
    }

    /**
     * Validate Agenda description
     * @param description string
     */
    public static validateAgendaDescription(description: string | null): ValidatorMessage | null {
        if (description && description.length > ValidatorConstants.AGENDA_DESCRIPTION_LENGTH_MAX) {
            return ValidatorMessage.invalidAgendaDescription;
        }
        return null;
    }

    public static validateClientPrizeTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.PRIZE_TITLE_LENGTH_MIN || title.length > ValidatorConstants.PRIZE_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidClientPrizeTitle;
        }
        return null;
    }

    public static validateClientPrizeTargetTitle(targetTitle: string): ValidatorMessage | null {
        if (targetTitle.length < ValidatorConstants.PRIZE_TARGET_TITLE_LENGTH_MIN || targetTitle.length > ValidatorConstants.PRIZE_TARGET_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidClientPrizeTargetTitle;
        }
        return null;
    }

    public static validateEnabledGamesCount(enabledGames: Array<ClientGameInput | null>): ValidatorMessage | null {
        if (enabledGames.filter((value: ClientGameInput | null): boolean => value !== null).length < ValidatorConstants.MIN_ENABLED_GAMES_COUNT) {
            return ValidatorMessage.invalidEnabledGamesCount;
        }
        return null;
    }

    /**
     * Validate Faq question
     * @param title string
     */
    public static validateFaqQuestion(question: string): ValidatorMessage | null {
        if (question.length < ValidatorConstants.FAQ_QUESTION_LENGTH_MIN) {
            return ValidatorMessage.faqQuestionTooShort;
        }
        return null;
    }

    /**
     * Validate Faq answer
     * @param title string
     */
    public static validateFaqAnswer(answer: string): ValidatorMessage | null {
        if (answer.length < ValidatorConstants.FAQ_ANSWER_LENGTH_MIN) {
            return ValidatorMessage.faqAnswerTooShort;
        }
        return null;
    }

    public static validateMemoryGamesAssetCount(assets: AssetContent[], numberOfPairs: number): ValidatorMessage | null {
        if (assets.length < numberOfPairs) {
            return ValidatorMessage.invalidMemoryGamesAssetCount;
        }
        return null;
    }

    public static validateEverydaySituationClientDirectoryTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MIN || title.length > ValidatorConstants.EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidEverydaySituationClientDirectoryTitle;
        }
        return null;
    }

    public static validateClientInstantAwardTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.INSTANT_AWARD_TITLE_LENGTH_MIN || title.length > ValidatorConstants.INSTANT_AWARD_TITLE_LENGTH_MAX) {
            return ValidatorMessage.instantAwardTitleLengthInvalid;
        }
        return null;
    }

    public static validateClientAwardTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.AWARD_TITLE_LENGTH_MIN || title.length > ValidatorConstants.AWARD_TITLE_LENGTH_MAX) {
            return ValidatorMessage.awardTitleLengthInvalid;
        }
        return null;
    }

    public static validateClientAwardTargetScore(targetScore: number | null): ValidatorMessage | null {
        if (isNil(targetScore) || isNaN(targetScore) || targetScore < ValidatorConstants.AWARD_SCORE_MIN || targetScore > ValidatorConstants.AWARD_SCORE_MAX) {
            return ValidatorMessage.awardScoreInvalid;
        }
        return null;
    }

    public static validateInstantAwardsNumber(instantAwardsNumber: number): ValidatorMessage | null {
        if (instantAwardsNumber < ValidatorConstants.AGENDA_ITEM_INSTANT_AWARDS_LENGTH_MIN) {
            return ValidatorMessage.agendaItemInstantAwardsNumberInvalid;
        }
        return null;
    }

    public static validateTagTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.TAG_TITLE_LENGTH_MIN || title.length > ValidatorConstants.TAG_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidTagTitle;
        }
        return null;
    }

    public static validateSubModuleTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MIN || title.length > ValidatorConstants.EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidSubModuleVideoTitle;
        }
        return null;
    }

    public static validateSubModuleVideoTitle(title: string): ValidatorMessage | null {
        if (title.length < ValidatorConstants.EDUCATION_CONTENT_TITLE_LENGTH_MIN || title.length > ValidatorConstants.EDUCATION_CONTENT_TITLE_LENGTH_MAX) {
            return ValidatorMessage.invalidSubModuleTitle;
        }
        return null;
    }

    public static validateNotificationInputs(title?: string | null, image?: AssetContent | null, audio?: AssetContent | null): ValidatorMessage | null {
        if (!title && !image && !audio) {
            return ValidatorMessage.invalidNotification;
        }
        return null;
    }
}

export { Validator, ValidatorConstants, ValidatorMessage };

import React, { Component } from "react";
import { AccountType, SupportedClient, AwardListItem, AwardListSortField, ListControl, SortOrder, AssetType } from "api/graphql/types";
import { PageContent } from "../ContentPageUtils";
import { RouteComponentProps } from "react-router";
import { DispatchProp } from "react-redux";
import { ListQueryParameter, ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { Column, Table } from "components/Table/Table";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import isEqual from "lodash/isEqual";
import { ListResult } from "api/ApiTypes";
import { isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";
import { store } from "store";
import { OverlayIcon } from "components/OverlayIcon";

interface AwardTableListOptions {
    sortField?: AwardListSortField | null;
    control: ListControl;
}

interface State {
    awards: AwardListItem[];
    count: number;
    options: AwardTableListOptions;
    isLoading: boolean;
}

enum AwardItemTableColumn {
    title = "title",
    isDisabled = "isDisabled",
    targetScore = "targetScore",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    actions = "actions",
}

interface ComponentProps {
    currentUrlParams: string;
    directory: string | undefined;
    client: SupportedClient;
    pageContent: PageContent;
    accountType: AccountType;
    getAwardList: (options?: AwardTableListOptions) => Promise<ListResult<AwardListItem>>;
    search: string;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class AwardTable extends Component<Props, State> {
    private static getInitialOptions(props: Props): AwardTableListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<AwardListSortField>(AwardListSortField, "sharedComponent.contentLibraryTable.awardTable").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                search: props.search,
                sortOrder,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public constructor(props: Props) {
        super(props);
        this.state = {
            awards: [],
            count: 0,
            options: AwardTable.getInitialOptions(props),
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        this.refreshAwardList(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Readonly<Props>): void {
        const nextOptions: AwardTableListOptions = AwardTable.getInitialOptions(nextProps);
        if (!isEqual(this.state.options, nextOptions) || nextProps.pageContent !== this.props.pageContent) {
            this.setState({ options: nextOptions }, () => {
                this.updateQueryParams();
            });
        }
    }

    private readonly refreshAwardList = (options?: AwardTableListOptions) => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response: ListResult<AwardListItem> = await this.props.getAwardList(options);
                    this.setState({
                        awards: response.result,
                        count: response.count,
                        isLoading: false,
                    });
                } catch (errors) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(errors) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private onDeleteClick = (awardId: string) => {
        const award = this.state.awards.find(award => award.id === awardId);
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteClientAward,
                clientAwardId: awardId,
                onDeleted: () => this.refreshAwardList(this.state.options),
                isAwardActive: award?.isActive ?? false,
                clientName: this.props.client.name,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<AwardListItem>> => {
        const columnNames: AwardItemTableColumn[] = ObjectUtils.enumAsArray<AwardItemTableColumn>(AwardItemTableColumn);

        return columnNames.map(
            (columnName: AwardItemTableColumn): Column<AwardListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.awardTable.columns.${columnName}` }),
                accessor: columnName as keyof AwardListItem,
                renderCell: (award: AwardListItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case AwardItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(award.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case AwardItemTableColumn.updatedAt:
                            return <>{award.updatedAt ? DateUtils.format(new Date(award.updatedAt), DateFormat.yyyymmddhhmm) : DateUtils.format(new Date(award.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case AwardItemTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    content={award}
                                    clientExtId={this.props.client.extId}
                                    onToggled={() => this.onToggled(award)}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: award,
                                                clientId: this.props.client?.id,
                                                onDisabled: (): void => this.refreshAwardList(this.state.options),
                                            }),
                                        );
                                    }}
                                    onDeleteClick={() => this.onDeleteClick(award.id)}
                                />
                            );
                        case AwardItemTableColumn.targetScore:
                            return <>{award.targetScore}</>;
                        case AwardItemTableColumn.title:
                            return (
                                <div className="table-image-name">
                                    <div
                                        className="table-image-container"
                                        onClick={
                                            award.image
                                                ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                      event.stopPropagation();
                                                      store.dispatch(
                                                          DialogsActions.show({
                                                              type: DialogType.showAsset,
                                                              assetUrl: award.image?.url,
                                                              assetType: award.image?.assetType || AssetType.image,
                                                              originalFileName: award.image?.originalFileName,
                                                              thumbnailUrl: award.image?.thumbnailUrl,
                                                              dialogTitle: award.image?.title || Intl.formatMessage({ id: `enum.assetType.${AssetType.image}` }),
                                                          }),
                                                      );
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Image src={award.image?.thumbnailUrl || award.image?.url} fallback={ImageSrc.asset} />
                                        {award.image && (
                                            <>
                                                <div className="content-overlay" />
                                                <div className="content-overlay-icon">
                                                    <OverlayIcon assetType={award.image.assetType} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {award.title}
                                </div>
                            );
                        case AwardItemTableColumn.isDisabled:
                            return <DisabledLock isVisible={!isNil(award.disabledAt)} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.awardTable.disabledTooltip" })} />;
                        default:
                            return null;
                    }
                },
                isNonSortable: ![AwardItemTableColumn.title, AwardItemTableColumn.createdAt, AwardItemTableColumn.updatedAt].includes(columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<AwardListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<AwardListSortField>(AwardListSortField, "sharedComponent.contentLibraryTable.awardTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshAwardList(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): AwardListSortField | undefined => {
        switch (columnId) {
            case AwardItemTableColumn.title:
                return AwardListSortField.title;
            case AwardItemTableColumn.createdAt:
                return AwardListSortField.createdAt;
            case AwardItemTableColumn.updatedAt:
                return AwardListSortField.updatedAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AwardListSortField | null): keyof AwardListItem | undefined => {
        switch (columnId) {
            case AwardListSortField.title:
                return AwardItemTableColumn.title;
            case AwardListSortField.createdAt:
                return AwardItemTableColumn.createdAt;
            case AwardListSortField.updatedAt:
                return AwardItemTableColumn.updatedAt;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AwardListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private readonly onToggled = (content: AwardListItem) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                content: content,
                onToggled: () => {
                    this.refreshAwardList(this.state.options);
                },
            }),
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="award-table">
                <Table
                    keyExtractor={(item: AwardListItem, column?: Column<AwardListItem>): string => {
                        return `${item.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                        order: this.state.options.control.sortOrder || undefined,
                    }}
                    data={this.state.awards}
                    count={this.state.count}
                    limit={this.state.options.control.limit}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={true}
                    currentPage={this.getCurrentPage()}
                    isLoading={this.state.isLoading}
                    renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.awardTable.noData" })}
                />
            </div>
        );
    }
}

export { AwardTable };

// tslint:disable-next-line: typedef variable-name
export const TestId = {
    authPage: {
        tabLink: {
            registration: "registration-tab",
            login: "login-tab",
        },
        registrationForm: {
            legalAge: "legalAge",
            termsAccepted: "termsAccepted",
            nameInput: "nameInput",
            emailInput: "emailInput",
        },
        loginForm: {
            emailInput: "emailInput",
            passwordInput: "passwordInput",
            forgotPasswordLink: "forgotPasswordLink",
        },
    },
    activation: {
        passwordInput: "passwordInput",
        passwordRepeatInput: "passwordRepeatInput",
        codeInput: "codeInput",
    },
    forgotPassword: {
        emailInput: "emailInput",
    },
    resetPassword: {
        passwordInput: "passwordInput",
        passwordRepeatInput: "passwordRepeatInput",
    },
    dashboard: {
        container: "container",
    },
    profilePage: {
        container: "profilePageContainer",
        nameForm: "nameForm",
        nameInput: "nameInput",
        emailForm: "emailForm",
        emailInput: "emailInput",
        passwordForm: "passwordForm",
        oldPasswordInput: "oldPasswordInput",
        newPasswordInput: "newPasswordInput",
        newPasswordAgainInput: "newPasswordAgainInput",
        copyButton: "copyButton",
        pinForm: "pinForm",
        pinInput: "pinInput",
        deletePinDialog: {
            cancelButton: "cancelButton",
            acceptButton: "acceptButton",
        },
    },
    appBar: {
        profileMenu: "profileMenu",
    },
    navigation: {
        container: "navigationContainer",
        logoutDialog: {
            acceptButton: "acceptButton",
            cancelButton: "cancelButton",
        },
    },
    notificationBar: {
        success: "notificationBarSuccess",
    },
    addClient: {
        container: "addClientContainer",
        personalData: {
            container: "addClientPersonalDataContainer",
            nameInput: "nameInput",
            dateOfBirthInput: "dateOfBirthInput",
            introductionInput: "introductionInput",
            statementOfSupport: "statementOfSupport",
        },
        questionnaire: {
            prevButton: "prevButton",
            container: "addClientQuestionnaireContainer",
        },
        goToClientProfileButton: "goToClientProfileButton",
    },
    clientProfile: {
        container: "clientProfileContainer",
        nameForm: "nameForm",
        nameInput: "nameInput",
        dateOfBirthForm: "dateOfBirthForm",
        dateOfBirthInput: "dateOfBirthInput",
        introductionForm: "introductionForm",
        introductionInput: "introductionInput",
        copyButton: "copyButton",
        deleteProfileButton: "deleteProfileButton",
        deleteProfileDialog: {
            cancelButton: "cancelButton",
            acceptButton: "acceptButton",
            emailInput: "emailInput",
        },
        addSupporterButton: "addSupporter",
    },
    clientSettings: {
        container: "clientSettingsContainer",
        unlockEnabled: "unlockEnabled",
        unlockPatternColorPicker: "unlockPatternColorPicker",
        failMessageInput: "failMessageInput",
        highContrastMode: "highContrastMode",
        agendaBackgroundItemColor: "agendaBackgroundItemColor",
        colorsOfDaysType: "colorsOfDaysType",
        mainPageType: "mainPageType",
        mainPageDataSyncEnabled: "mainPageDataSyncEnabled",
        agendaItemSize: "agendaItemSize",
        agendaItemTextVisibility: "agendaItemTextVisibility",
        calendarView: "calendarView",
        flowchartListType: "flowchartListType",
        agendaItemCheckboxSize: "agendaItemCheckboxSize",
        moodMeterEnabled: "clientSettingsMoodMeterEnabled",
        moodMeterMessage: "clientSettingsMoodMeterMessage",
        moodMeter: "clientSettingsMoodMeterEnabled",
        timer: {
            requestConfirmation: "timerRequestConfirmation",
        },
        award: {
            requestConfirmation: "awardRequestConfirmation",
        },
        selectionBoardListType: "selectionBoardListType",
        timerType: "timerType",
        saveButton: "saveButton",
        cancelButton: "cancelButton",
        tabs: {
            app: "app",
            mainPage: "mainPage",
            contents: "contents",
            functions: "functions",
        },
    },
    clientGames: {
        memoryGameTab: {
            id: "clientMemoryTab",
            numberOfPairs: "clientMemoryNumberOfPairs",
            targetTitle: "clientMemoryTargetTitle",
            cancelButton: "clientMemoryGameCancelButton",
            saveButton: "clientMemoryGameSaveButton",
        },
        sortingGameTab: {
            id: "clientSortingTab",
            targetTitle: "clientSortingTargetTitle",
            cancelButton: "clientSortingGameCancelButton",
            saveButton: "clientSortingGameSaveButton",
        },
    },
    clientAwards: {
        container: "clientAwardsContainer",
        isActiveCheckbox: "clientAwardsIsActiveCheckbox",
        balance: "clientAwardsBalance",
        targetScoreDisplay: "clientAwardsTargetScoreDisplay",
        targetScore: "clientAwardsTargetScore",
        title: "clientAwardsTitle",
        targetTitle: "clientAwardsTargetTitle",
        restartButton: "clientAwardsRestartButton",
        cancelButton: "clientAwardsCancelButton",
        saveButton: "clientAwardsSaveButton",
    },
    agendaItemForm: {
        container: "agendaItemCreateContainer",
        title: "agendaItemCreateTitle",
        content: "agendaItemContent",
        flowchart: {
            enabled: "agendaItemCreateFlowchartEnabled",
            flowchartId: "agendaItemCreateFlowchartFlowchartId",
        },
        checklist: "agendaItemChecklist",
        moodMeter: {
            display: "agendaItemMoodMeterDisplay",
            title: "agendaItemMoodMeterTitle",
        },
        alert: {
            displayOptions: "agendaItemCreateAlertDisplay",
            type: "agendaItemCreateAlertType",
            notifications: "agendaItemCreateAlertNotifications",
            text: "agendaItemCreateAlertText",
        },
        award: {
            enabled: "awardEnabled",
            score: "awardScore",
            requestConfirmation: "awardRequestConfirmation",
        },
        timer: {
            enabled: "agendaItemCreateTimer",
            type: "agendaItemCreateTimerType",
            seconds: "agendaItemCreateTimerValue",
            timeUnit: "agendaItemCreateTimerUnit",
            requestConfirmation: "agendaItemCreateTimerRequestConfirmation",
            skipEnabled: "agendaItemCreateTimerSkipEnabled",
            extendTimeEnabled: "agendaItemCreateTimerExtendTimeEnabled",
            pauseEnabled: "agendaItemCreateTimerPauseEnabled",
            endWarning: "agendaItemCreateTimerEndWarning",
        },
        time: {
            enabled: "agendaItemCreateTimeEnabled",
            hours: "agendaItemCreateTimeHours",
            minutes: "agendaItemCreateTimeMinutes",
        },
        cancelButton: "agendaItemCreateCancelButton",
        saveButton: "agendaItemCreateSaveButton",
    },
    faqPage: {
        faqListContainer: "faqPageFaqListContainer",
        faqPageContainer: "faqPageFaqPageContainer",
        createFaqButton: "faqPageCreateFaqButton",
        questionInput: "faqPageQuestionInput",
        answerInput: "faqPageAnswerInput",
        saveButton: "faqPageSaveButton",
        cancelButton: "faqPageCancelButton",
        verifyDeleteFaqDialog: {
            deleteButtonLabel: "verifyDeleteFaqDialogDeleteButtonLabel",
        },
    },
    clientSelectionBoardsListPage: {
        clientSelectionBoardsListContainer: "clientSelectionBoardsListContainer",
        addClientSelectionBoardButton: "addClientSelectionBoardButton",
    },
    clientSelectionBoardsPagePage: {
        clientSelectionBoardsPagePageContainer: "clientSelectionBoardsPagePageContainer",
        titleInput: "clientSelectionBoardsTitleInput",
        backgroundColorSelect: "clientSelectionBoardsColorSelect",
        saveButton: "clientSelectionBoardsSaveButton",
        cancelButton: "clientSelectionBoardsCancelButton",
    },
};

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { Path } from "utils/Path";
import { Input } from "components/Inputs/Input/Input";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Alert } from "components/Alert/Alert";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DispatchProp } from "react-redux";
import { Env } from "utils/Env";
import { login_login } from "api/graphql/types";
import { AuthActions } from "actions/AuthActions";
import { Api } from "api/Api";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { TestId } from "utils/TestId";
import { Validator } from "utils/Validator";
import { ApiErrorCode } from "api/ApiError";
import { StaticContext, RouteComponentProps } from "react-router";
import { Button } from "components/Button/Button";
import { Cookie } from "utils/Cookie";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Title } from "components/Title";

interface LocationState {
    from?: {
        pathname: string;
    };
    noRedirect?: boolean;
}

type Props = { isLoggedIn: boolean } & DispatchProp & RouteComponentProps<{}, StaticContext, LocationState>;

interface State {
    isLoading: boolean;
    email: string;
    password: string;
    isStayLoggedIn: boolean;
    formErrors: {
        email: string | null;
        password: string | null;
    };
    validationEnabled: boolean;
    redirectToReferrer: boolean;
}

class LoginFormComponent extends Component<Props, State> {
    private emailRef: HTMLInputElement | null = null;
    private passwordRef: HTMLInputElement | null = null;

    public state: Readonly<State> = {
        isLoading: false,
        email: Env.debugUser || "",
        password: Env.debugPassword || "",
        isStayLoggedIn: Cookie.isStayLoggedIn(),
        formErrors: {
            email: null,
            password: null,
        },
        validationEnabled: false,
        redirectToReferrer: false,
    };

    public componentDidMount(): void {
        const { state } = this.props.location;
        if (state && state.noRedirect) {
            this.props.location.state.noRedirect = undefined;
        }
    }

    private onLoginSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { email, password } = this.state;
        const emailError: string | null = IntlHelpers.getValidationError(Validator.validateEmail(email));
        const passwordError: string | null = IntlHelpers.getValidationError(Validator.validatePassword(password));

        if (emailError) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors: { email: emailError, password: passwordError } }, () => {
                if (emailError && this.emailRef) {
                    this.emailRef.focus();
                } else if (passwordError && this.passwordRef) {
                    this.passwordRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const result: login_login = await Api.login(email, password);
                    this.setState({ isLoading: false, redirectToReferrer: true }, () => {
                        this.props.dispatch(AuthActions.updateAuthData(result.authToken, result.refreshToken, this.state.isStayLoggedIn));
                    });
                } catch (error) {
                    if (error.code === ApiErrorCode.UNAUTHENTICATED) {
                        Alert.error({ title: Intl.formatMessage({ id: "page.auth.login.loginFailed" }) });
                        if (this.emailRef) {
                            this.emailRef.focus();
                        }
                    } else {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.currentTarget.value;
        const emailError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateEmail(email)) : null;
        this.setState({ email: event.currentTarget.value, formErrors: { ...this.state.formErrors, email: emailError } });
    };

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const password: string = event.currentTarget.value;
        const passwordError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validatePassword(password)) : null;
        this.setState({ password, formErrors: { ...this.state.formErrors, password: passwordError } });
    };

    public render(): React.ReactElement<any> {
        const { formErrors, email, password, isLoading, redirectToReferrer } = this.state;
        const { state } = this.props.location;
        if (redirectToReferrer && state && state.from) {
            return <Redirect to={state.from.pathname} />;
        }

        if (this.props.isLoggedIn && (!state || !state.noRedirect)) {
            return <Redirect to={Path.dashboard} />;
        }

        const loginButtonDisabled: boolean = isLoading || !!formErrors.email || !!formErrors.password || email === "" || password === "";
        return (
            <form noValidate={true} onSubmit={this.onLoginSubmit}>
                <Title>{Intl.formatMessage({ id: "page.auth.login.title" })}</Title>
                <div className="lead">{Intl.formatMessage({ id: "page.auth.login.lead" })}</div>

                <InputWrapper id={TestId.authPage.loginForm.emailInput} errorMessage={formErrors.email} inputLabel={Intl.formatMessage({ id: "page.auth.login.form.email.label" })}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.emailRef = ref;
                        }}
                        placeholder={Intl.formatMessage({ id: "page.auth.login.form.email.placeholder" })}
                        type="email"
                        value={this.state.email}
                        onChange={this.onEmailChange}
                        hasError={!!formErrors.email}
                        autoComplete="email"
                    />
                </InputWrapper>

                <InputWrapper id={TestId.authPage.loginForm.passwordInput} errorMessage={formErrors.password} inputLabel={Intl.formatMessage({ id: "page.auth.login.form.password.label" })}>
                    <PasswordInput
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.passwordRef = ref;
                        }}
                        placeholder={Intl.formatMessage({ id: "page.auth.login.form.password.placeholder" })}
                        value={this.state.password}
                        onChange={this.onPasswordChange}
                        hasError={!!formErrors.password}
                    />
                </InputWrapper>

                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.auth.login.form.stayLoggedIn.label" })}>
                    <Checkbox
                        label={""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            this.setState({ isStayLoggedIn: event.currentTarget.checked });
                        }}
                        checked={this.state.isStayLoggedIn}
                    />
                </InputWrapper>

                <div className="button-wrapper">
                    <div className="grid-x">
                        <div className="cell large-shrink">
                            <Link id={TestId.authPage.loginForm.forgotPasswordLink} to={Path.forgotPassword}>
                                {Intl.formatMessage({ id: "page.auth.login.forgotPassword" })}
                            </Link>
                        </div>
                        <div className="cell large-auto">
                            <Button type="submit" label={Intl.formatMessage({ id: "page.auth.login.button" })} disabled={loginButtonDisabled} />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export const LoginForm: React.ComponentClass<Props> = LoginFormComponent;

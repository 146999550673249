import React, { PureComponent, ReactElement } from "react";
import { Alert } from "components/Alert/Alert";
import { Intl } from "i18n/Intl";
import { TextUtils } from "utils/TextUtils";
import isEqual from "lodash/isEqual";
import { ValidatorConstants } from "utils/Validator";
import { Tag, AdminTag } from "api/graphql/types";

import "./TagSelect.scss";

interface Props {
    tags: Tag[];
    selectedTags: string[];
    onChange: (value: string[]) => void;
    disabled?: boolean;
    renderTags?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
}

interface State {
    isShow: boolean;
    selectedTags: string[];
    inputSelectedTags: string[];
    matches: Tag[];
    searchText: string;
}

class TagSelect extends PureComponent<Props, State> {
    private inputRef: HTMLInputElement | null = null;
    private buttonRef: HTMLButtonElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isShow: false,
            searchText: "",
            selectedTags: [...props.selectedTags],
            inputSelectedTags: [...props.selectedTags],
            matches: [...props.tags],
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.selectedTags !== this.props.selectedTags) {
            this.setState({
                isShow: false,
                selectedTags: [...this.props.selectedTags],
                inputSelectedTags: [...this.props.selectedTags],
                matches: [...this.props.tags],
            });
        }
    }

    private readonly toggleInput = () => {
        const isShow = !this.state.isShow;
        if (this.props.onOpenChange) {
            this.props.onOpenChange(isShow);
        }
        this.setState({ isShow }, () => {
            if (isShow && this.buttonRef) {
                this.buttonRef.scrollIntoView();
                if (this.inputRef) {
                    this.inputRef.focus();
                }
            }
        });
    };

    private readonly isSelected = (tag: string): boolean => {
        return this.state.inputSelectedTags.includes(tag);
    };

    private readonly onRemoveTagSelection = (tag: string) => {
        const inputSelectedTags = this.state.inputSelectedTags.filter(selectedTag => selectedTag !== tag);
        this.setState(
            {
                inputSelectedTags,
                selectedTags: inputSelectedTags,
            },
            () => this.props.onChange(inputSelectedTags),
        );
    };

    private readonly onSelect = (item: Tag | AdminTag) => {
        if (this.isSelected(item.title)) {
            return;
        }
        if (this.state.inputSelectedTags.length >= ValidatorConstants.MAX_TAG_COUNT) {
            Alert.error({ title: Intl.formatMessage({ id: "sharedComponent.tagSelect.tooManyTags" }) });
            return;
        } else {
            this.setState(
                {
                    inputSelectedTags: [...this.state.inputSelectedTags, item.title],
                    matches: [...this.props.tags],
                    searchText: "",
                },
                () => {
                    if (this.buttonRef) {
                        this.buttonRef.scrollIntoView();
                    }
                    if (this.inputRef) {
                        this.inputRef.focus();
                    }
                },
            );
        }
    };

    private readonly onSearch = (event: React.FormEvent<HTMLInputElement>) => {
        const matches: Array<Tag | AdminTag> = [];
        const searchText = event.currentTarget.value;
        this.props.tags.forEach(tag => {
            if (TextUtils.matchPartial(searchText).test(tag.title)) {
                matches.push(tag);
            }
        });
        this.setState({
            matches: matches,
            searchText,
        });
    };

    private readonly onAdd = () => {
        this.setState(
            {
                selectedTags: this.state.inputSelectedTags,
            },
            () => {
                this.toggleInput();
                this.props.onChange(this.state.selectedTags);
            },
        );
    };

    private readonly onCancel = () => {
        this.setState(
            {
                inputSelectedTags: this.state.selectedTags,
            },
            () => this.toggleInput(),
        );
    };

    public static readonly renderOnlyTags = (tags: string[], onRemoveTagSelection?: (index: number) => void): ReactElement[] => {
        return tags.map((tag: string, index: number) => (
            <div key={tag} className="tag">
                <span className="title">{tag}</span>
                <button
                    className="btn"
                    onClick={() => {
                        onRemoveTagSelection && onRemoveTagSelection(index);
                    }}
                >
                    <i className="fa fa-times" />
                </button>
            </div>
        ));
    };

    public readonly renderTags = (tags: string[], deletable: boolean = false) => {
        return tags.map(tag => (
            <div key={tag} className="tag">
                <span className="title">{tag}</span>
                {deletable ? (
                    <button className="btn" onClick={() => this.onRemoveTagSelection(tag)}>
                        <i className="fa fa-times" />
                    </button>
                ) : null}
            </div>
        ));
    };

    public render(): React.ReactElement<any> {
        const { isShow, selectedTags, inputSelectedTags } = this.state;
        const disabled = isEqual(selectedTags, inputSelectedTags);
        if (this.props.disabled) {
            if (selectedTags.length === 0) {
                return <p className="mb-40">{Intl.formatMessage({ id: "sharedComponent.tagSelect.noSelectedTags" })}</p>;
            }
            return <>{this.renderTags(selectedTags)}</>;
        }
        return (
            <div className="tag-input-holder">
                <div className={`tag-input-group ${isShow ? "open" : ""}`}>
                    {isShow ? (
                        <div className="tag-list-container">
                            <div className="tag-input-container">
                                {this.renderTags(inputSelectedTags, true)}
                                <input
                                    ref={(ref: HTMLInputElement | null): void => {
                                        this.inputRef = ref;
                                    }}
                                    type="text"
                                    className="tag-input"
                                    value={this.state.searchText}
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.tagSelect.filterTags" })}
                                    onChange={this.onSearch}
                                />
                            </div>
                            <ul className="tag-list">
                                {this.state.matches.map(tag => (
                                    <li key={tag.id} className={`${this.isSelected(tag.title) ? "active" : null}`} onClick={() => this.onSelect(tag)}>
                                        {tag.title}
                                    </li>
                                ))}
                            </ul>
                            <div className="button-container pb-3">
                                <div className="grid-x">
                                    <div className="cell medium-6">
                                        <button className="btn btn-primary btn-outline float-right mr-2" onClick={this.onCancel}>
                                            {Intl.formatMessage({ id: "common.cancel" })}
                                        </button>
                                    </div>
                                    <div className="cell medium-6">
                                        <button
                                            className="btn btn-primary ml-2"
                                            onClick={this.onAdd}
                                            disabled={disabled}
                                            ref={(ref: HTMLButtonElement | null) => {
                                                this.buttonRef = ref;
                                            }}
                                        >
                                            {Intl.formatMessage({ id: "common.add" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="tag-input-placeholder" onClick={this.toggleInput}>
                                <span>{Intl.formatMessage({ id: "sharedComponent.tagSelect.placeholder" })}</span>
                                <i className="fa fa-chevron-down" />
                            </div>
                        </>
                    )}
                </div>
                {!isShow && this.props.renderTags ? <div className="mt-3">{this.renderTags(selectedTags, true)}</div> : null}
            </div>
        );
    }
}

export { TagSelect };

import React, { Component } from "react";
import { AssetType } from "api/graphql/types";

interface Props {
    assetType: AssetType;
}

class OverlayIcon extends Component<Props> {
    public render(): React.ReactElement<any> | null {
        switch (this.props.assetType) {
            case AssetType.introduction_video:
            case AssetType.video:
            case AssetType.introduction_audio:
            case AssetType.audio:
                return <span className="fa fa-play-circle"></span>;
            case AssetType.image:
            case AssetType.avatar:
                return <span className="fa fa-search-plus"></span>;
            // return <span className="fa fa-eye"></span>;
            default:
                return null;
        }
    }
}

export { OverlayIcon };

import React, { Component } from "react";
import { Notification, NotificationType, NotificationTimeout } from "components/NotificationBar/Notification";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";

import "./Notification.scss";
import { TestId } from "utils/TestId";

interface State {
    notifications: Notification[];
    currentNotification: Notification | null;
    isShowing: boolean;
}

class NotificationBar extends Component<{}, State> {
    private static readonly HIDE_ANIMATION_LENGTH: number = 500;

    public readonly state: State = {
        notifications: [],
        currentNotification: null,
        isShowing: false,
    };

    private showingTimeout: NodeJS.Timeout | null = null;
    private removeTimeout: NodeJS.Timeout | null = null;

    public componentWillUnmount(): void {
        if (this.showingTimeout) {
            clearTimeout(this.showingTimeout);
        }

        if (this.removeTimeout) {
            clearTimeout(this.removeTimeout);
        }
    }

    public showNotification = (notification: Notification): void => {
        this.setState({ notifications: [...this.state.notifications, notification] }, (): void => {
            if (!this.state.currentNotification) {
                this.loadQueue();
            }
        });
    };

    private loadQueue(): void {
        if (this.state.notifications.length > 0) {
            const currentNotification: Notification = this.state.notifications[0];
            this.setState({ currentNotification, isShowing: true }, (): void => {
                if (currentNotification.type === NotificationType.success) {
                    this.setShowingTimeout(currentNotification.timeout || NotificationTimeout.normal);
                }
            });
        }
    }

    private setShowingTimeout(timeout: number): void {
        this.showingTimeout = setTimeout(this.startRemove, timeout);
    }

    private setRemoveTimeout(): void {
        this.removeTimeout = setTimeout(() => {
            const notifications = [...this.state.notifications];
            notifications.shift();
            const newCurrentNotification = notifications.length > 0 ? notifications[0] : null;
            const show = newCurrentNotification !== null;
            this.setState({ notifications, currentNotification: newCurrentNotification, isShowing: show }, () => {
                if (show) {
                    this.loadQueue();
                }
            });
        }, NotificationBar.HIDE_ANIMATION_LENGTH);
    }

    private getIcon = (): React.ReactElement<any> | null => {
        if (!this.state.currentNotification) {
            return null;
        }
        switch (this.state.currentNotification.type) {
            case NotificationType.success:
                return <span className="fa fa-check-circle"></span>;
            case NotificationType.error:
                return <span className="fa fa-times"></span>;
            case NotificationType.info:
            default:
                return null;
        }
    };

    private getTypeClassName = (): string => {
        if (!this.state.currentNotification) {
            return "";
        }
        switch (this.state.currentNotification.type) {
            case NotificationType.success:
                return "success";
            case NotificationType.error:
                return "has-error";
            case NotificationType.info:
            default:
                return "";
        }
    };

    private getClassName = (): string => {
        const showClassName: string = this.state.isShowing ? " show" : " ";
        const typeClassName: string = this.getTypeClassName();
        return `info-bar ${showClassName} ${typeClassName}`;
    };

    private startRemove = (): void => {
        this.setState({ isShowing: false }, () => {
            this.setRemoveTimeout();
        });
    };

    private getId = (): string => {
        if (!this.state.currentNotification) {
            return "";
        }
        switch (this.state.currentNotification.type) {
            case NotificationType.success:
                return TestId.notificationBar.success;
            case NotificationType.error:
            case NotificationType.info:
            default:
                return "";
        }
    };

    public render(): React.ReactElement<any> {
        const className: string = this.getClassName();
        const message: string | null = this.state.currentNotification ? this.state.currentNotification.message : null;
        const notificationType: NotificationType | null = this.state.currentNotification ? this.state.currentNotification.type : null;
        return (
            <div className={className}>
                {this.getIcon()}
                <span id={this.getId()}>{message}</span>
                {notificationType !== NotificationType.success && (
                    <span id={this.getId()} className="notification-bar-button-wrapper">
                        <Button hollow={true} white={true} label={Intl.formatMessage({ id: "common.ok" })} onClick={this.startRemove} />
                    </span>
                )}
            </div>
        );
    }
}

export { NotificationBar };

import React, { useContext } from "react";
import { DraggableProvided, DraggableStateSnapshot, DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { DragContext } from "./DragContext";

type Props = {
    draggableId: string;
    droppableId?: string;
    index: number;
    type: string;
    isDragDisabled?: boolean | null;
    children: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => React.ReactElement<any> | null;
};

export const Draggable: React.FC<Props> = (props: Props) => {
    const context = useContext(DragContext);
    const [{ isDragging }, drag] = useDrag({
        item: {
            source: {
                droppableId: props.droppableId,
            },
            draggableId: props.draggableId,
            type: props.type,
        },
        begin: () => {
            context.onDragStart(
                {
                    source: {
                        droppableId: props.droppableId,
                    },
                    draggableId: props.draggableId,
                },
                {},
                context.getDraggableById(props.draggableId)?.content as any,
            );
        },
        end: (_item: any, _monitor: DragSourceMonitor) => {
            context.onDragEnd({ source: { droppableId: props.droppableId!, index: 0 } }, {});
        },
        canDrag: !props.isDragDisabled,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    const provided: DraggableProvided = {
        draggableProps: {} as DraggableProvidedDraggableProps,
        dragHandleProps: {} as DraggableProvidedDragHandleProps,
        innerRef: (_element?: HTMLElement | null) => {},
        placeholder: null,
    };

    const snapshot: DraggableStateSnapshot = {
        isDragging,
        isDropAnimating: false,
        draggingOver: undefined,
        dropAnimation: undefined,
        // the id of a draggable that you are combining with
        combineWith: undefined,
        // a combine target is being dragged over by
        combineTargetFor: undefined,
        // What type of movement is being done: 'FLUID' or 'SNAP'
        mode: "FLUID",
    };

    return (
        <div ref={drag} style={{ opacity }}>
            {props.children(provided, snapshot)}
        </div>
    );
};

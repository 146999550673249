import React, { PureComponent } from "react";
import { SelectOption } from "./SelectOption";
//import { Input } from "../Input/Input";

import "./Select.scss";

interface Props<T> {
    emptyLabel?: string;
    value: SelectOption<T> | null;
    onChange: (item: SelectOption<T>) => any;
    options: Array<SelectOption<T>>;
    hasError?: boolean;
    disabled?: boolean;
    renderOption?: (item: SelectOption<T> | null, index?: number, items?: Array<SelectOption<T>>) => React.ReactElement<any>;
    className?: string;
}

interface State {
    isOpened: boolean;
}

class Select<T> extends PureComponent<Props<T>, State> {
    private containerRef: HTMLDivElement | null = null;

    /**
     * Get SelectOption from list by value
     * @param list Select option list
     * @param value current value of selected select option
     */
    public static getSelectOption<T>(list: Array<SelectOption<T>>, value: T | null): SelectOption<T> {
        const current = list.find((option: SelectOption<T>): boolean => option.value === value);
        if (!current) {
            console.warn("current not found!", current, list);
        }
        return current || list[0];
    }

    public readonly state: State = {
        isOpened: false,
    };

    public scrollIntoView = (): void => {
        if (this.containerRef) {
            this.containerRef.scrollIntoView();
        }
    };

    public componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    private onClickOutside = (event: MouseEvent): void => {
        if (this.containerRef && !this.containerRef.contains(event.target as Node) && this.state.isOpened) {
            this.onOpenChange();
        }
    };

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    private onOptionSelected = (item: SelectOption<T>) => () => {
        this.props.onChange(item);
        this.onOpenChange();
    };

    private renderOption = (item: SelectOption<T>, index: number, items: Array<SelectOption<T>>): React.ReactElement<any> => {
        const isActive: boolean = !!this.props.value && item.id === this.props.value.id;
        const isDisabled: boolean = !!item.disabled;
        return (
            <div key={item.id} className={`option${isActive ? " active" : ""}${isDisabled ? " disabled" : ""}`} onClick={isDisabled ? undefined : this.onOptionSelected(item)} id={`option-${item.id}`}>
                {this.props.renderOption ? this.props.renderOption(item, index, items) : item.label}
            </div>
        );
    };

    private renderSelectedOption = (): React.ReactElement<any> => {
        if (this.props.renderOption) {
            return this.props.renderOption(this.props.value);
        }

        if (this.props.value) {
            return <>{this.props.value.label}</>;
        }

        return <>{this.props.emptyLabel}</>;
    };

    public render(): React.ReactElement<any> {
        if (this.props.disabled) {
            return (
                <div className={`select disabled ${this.props.className ? this.props.className : ""}`}>
                    <div className="select-value">{this.renderSelectedOption()}</div>
                </div>
            );
        }

        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                className={`select${this.state.isOpened ? " opened" : ""} ${this.props.className ? this.props.className : ""}`}
            >
                <div className={`select-value${this.props.hasError ? " has-error" : ""}`} onClick={this.onOpenChange}>
                    {this.renderSelectedOption()}
                </div>
                <div className="options">{this.props.options.map(this.renderOption)}</div>
            </div>
        );
    }
}

export { Select };
export type { SelectOption };

import React, { Component } from "react";
import { Page } from "components/Page";
import { SearchListType, Account } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { LinkGroupItem, LinkGroup } from "components/LinkGroup/LinkGroup";
import { ContentLibraryLinks } from "models/ContentLibraryLinks";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Intl } from "i18n/Intl";
import { SearchForm } from "components/SearchForm/SearchForm";
import { ContentShareRequestTable } from "./ContentShareRequestTable";
import { ContentShareRequestUrlQueryParser } from "./ContentShareRequestUrlQueryParser";

import "./ContentShareRequestListPage.scss";

interface ReduxProps {
    account: Account;
}

type Props = RouteComponentProps & ReduxProps & DispatchProp;

type State = {
    search: string;
    searchListType: SearchListType;
    selectedTags: string[];
};

class ContentShareRequestListPageComponent extends Component<Props, State> {
    private readonly urlQueryParser = new ContentShareRequestUrlQueryParser();

    private static getStateFromProps(props: Props): State {
        const { search, searchListType } = new ContentShareRequestUrlQueryParser().parse(props.location.search);
        return { search: search || "", searchListType: searchListType || SearchListType.agenda, selectedTags: [] };
    }

    public readonly state: State = ContentShareRequestListPageComponent.getStateFromProps(this.props);

    public componentDidUpdate(prevProps: Props): void {
        const urlQueryParser = new ContentShareRequestUrlQueryParser();
        const currentParams = urlQueryParser.parse(this.props.location.search);
        const prevParams = urlQueryParser.parse(prevProps.location.search);
        if (currentParams.searchListType !== prevParams.searchListType) {
            this.setState(ContentShareRequestListPageComponent.getStateFromProps(this.props));
        }
    }

    private readonly isLinkActive = (item: LinkGroupItem): boolean => {
        const currentPath: string = this.props.location.pathname + this.props.location.search;
        return currentPath.startsWith(item.toPage);
    };

    private onSearchClick = (search: string): void => {
        this.setState({ search });
    };

    public render(): React.ReactElement<any> {
        const params = this.urlQueryParser.parse(this.props.location.search);
        if (!params.searchListType) {
            const urlQuery: string = this.urlQueryParser.getUrlQuery({ searchListType: SearchListType.agendaItem });
            return <Redirect to={`${this.props.history.location.pathname}?${urlQuery}`} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.publicContentLibrary.title" })}>
                <div className="side-bar-left">
                    <LinkGroup
                        title={Intl.formatMessage({ id: "page.contentShareRequestList.title" })}
                        links={ContentLibraryLinks.getForSupervisorShareRequests(this.props.location.pathname)}
                        isLinkActive={this.isLinkActive}
                    />
                </div>

                <div className="left-side content-share-request-table pt-0">
                    <span className="row table-title-row">
                        <span className="col-md-9 p-0">
                            <h2>
                                {Intl.formatMessage({ id: "page.contentShareRequestList.title" })}
                                &nbsp;
                                <span className="fa fa-angle-right"></span>
                                &nbsp;
                                {Intl.formatMessage({ id: `enum.searchListType.${this.state.searchListType}` })}
                            </h2>
                        </span>
                        <span className="col-md-3 p-0">
                            <SearchForm
                                defaultValue={this.state.search}
                                searchPlaceholder={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.searchForm.placeholder" })}
                                onSearchClick={this.onSearchClick}
                                icon={true}
                                withTags={false}
                            />
                        </span>
                    </span>
                    <ContentShareRequestTable search={this.state.search} searchListType={this.state.searchListType} />
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const ContentShareRequestListPage = withRouter(connect(mapStateToProps)(ContentShareRequestListPageComponent));

export default ContentShareRequestListPage;

import React, { Component } from "react";
import { Asset, SupportedClient, InstantAward, Account, InstantAwardInput } from "api/graphql/types";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils } from "pages/_shared/ContentPageUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { ClientInstantAwardForm } from "./ClientInstantAwardForm";
import { isNil } from "lodash";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Log } from "utils/Log";

interface RouteParams {
    clientExtId?: string;
    clientInstantAwardId?: string;
}

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

type ComponentProps = RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

type Props = ComponentProps;

export interface LocalClientInstantAwardInput {
    title: string;
    image?: Asset | null;
}

interface State {
    isLoading: boolean;
    clientInstantAward: LocalClientInstantAwardInput;
}

class ClientInstantAwardPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.clientInstantAwardId),
            clientInstantAward: {
                title: "",
                image: null,
            },
        };
    };

    public readonly state: State = ClientInstantAwardPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshClientInstantAward();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.client !== this.props.client || prevProps.match.params.clientInstantAwardId !== this.props.match.params.clientInstantAwardId) {
            this.refreshClientInstantAward();
        }
    }

    private refreshClientInstantAward = (): void => {
        const { clientInstantAwardId } = this.props.match.params;
        if (isNil(this.props.client) || !clientInstantAwardId) {
            this.setState(ClientInstantAwardPageComponent.getInitialStateFromProps(this.props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientInstantAward: InstantAward = await Api.getClientInstantAwardById(this.props.client!.id, clientInstantAwardId);
                    this.setState({ isLoading: false, clientInstantAward: { title: clientInstantAward.title, image: clientInstantAward.image } });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private getClientInstantAwardInput = (clientInstantAward: LocalClientInstantAwardInput): InstantAwardInput => {
        return {
            title: clientInstantAward.title || "",
            imageId: clientInstantAward.image?.id || null,
        };
    };

    private onSubmit = async (clientInstantAward: LocalClientInstantAwardInput): Promise<InstantAward> => {
        const clientInstantAwardId: string | undefined = this.props.match.params.clientInstantAwardId;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);

        if (pageType === PageType.create) {
            const result = await Api.createClientInstantAward(this.props.client!.id, this.getClientInstantAwardInput(clientInstantAward));
            this.setState({ clientInstantAward: result });
            return result;
        } else if (pageType === PageType.edit && !isNil(clientInstantAwardId)) {
            const result = await Api.updateClientInstantAward(clientInstantAwardId, this.getClientInstantAwardInput(clientInstantAward));
            this.setState({ clientInstantAward: result });
            return result;
        } else {
            Log.warning("View cannot submit (ClientInstantAwardPage)!");
            throw new ApiError(ApiErrorCode.UNKNOWN);
        }
    };

    public render(): React.ReactElement {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const isClientInstantAwardIdPresentInEditMode: boolean = pageType === PageType.create || (pageType === PageType.edit && !isNil(this.props.match.params.clientInstantAwardId));

        if (isNil(this.props.client) || !isClientInstantAwardIdPresentInEditMode) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <ClientInstantAwardForm
                pageType={pageType}
                accountId={this.props.account!.id}
                clientName={this.props.client.name}
                clientExtId={this.props.client.extId}
                clientInstantAward={this.state.clientInstantAward}
                onSubmit={this.onSubmit}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        account: state.account!,
        client,
    };
};

export const ClientInstantAwardPage = withRouter(connect(mapStateToProps)(ClientInstantAwardPageComponent));

export default ClientInstantAwardPage;

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Button } from "components/Button/Button";
import { Account } from "api/graphql/types";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Input } from "components/Inputs/Input/Input";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & NotificationProp;

interface State {
    isEditable: boolean;
    password: string;
    newPassword: string;
    newPasswordAgain: string;
    formErrors: {
        password: string | null;
        newPassword: string | null;
        newPasswordAgain: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ProfilePasswordFormComponent extends Component<Props, State> {
    private passwordRef: HTMLInputElement | null = null;
    private newPasswordRef: HTMLInputElement | null = null;
    private newPasswordAgainRef: HTMLInputElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            password: "",
            newPassword: "",
            newPasswordAgain: "",
            formErrors: {
                password: null,
                newPassword: null,
                newPasswordAgain: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const password: string = event.currentTarget.value;
        const passwordError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validatePassword(password)) : null;
        this.setState({ password, formErrors: { ...this.state.formErrors, password: passwordError } });
    };

    private onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newPassword: string = event.currentTarget.value;
        const newPasswordError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validatePassword(newPassword)) : null;
        this.setState({ newPassword, formErrors: { ...this.state.formErrors, newPassword: newPasswordError } });
    };

    private onNewPasswordAgainChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newPasswordAgain: string = event.currentTarget.value;
        const newPasswordAgainError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validatePasswordRepeat(this.state.newPassword, newPasswordAgain)) : null;
        this.setState({ newPasswordAgain, formErrors: { ...this.state.formErrors, newPasswordAgain: newPasswordAgainError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { password, newPassword, newPasswordAgain } = this.state;
        const formErrors = {
            password: IntlHelpers.getValidationError(Validator.validatePassword(password)),
            newPassword: IntlHelpers.getValidationError(Validator.validatePassword(newPassword)),
            newPasswordAgain: IntlHelpers.getValidationError(Validator.validatePasswordRepeat(newPassword, newPasswordAgain)),
        };

        if (formErrors.password || formErrors.newPassword) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.password && this.passwordRef) {
                    this.passwordRef.focus();
                } else if (!!formErrors.newPassword && this.newPasswordRef) {
                    this.newPasswordRef.focus();
                } else if (!!formErrors.newPasswordAgain && this.newPasswordAgainRef) {
                    this.newPasswordAgainRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.changePassword(password, newPassword);
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.profile.personalData.passwordForm.passwordChangeSucceed" }) });
                    this.resetForm();
                } catch (error) {
                    if (error instanceof ApiError && error.code === ApiErrorCode.UNAUTHENTICATED) {
                        this.props.showNotification({ type: NotificationType.error, message: Intl.formatMessage({ id: "page.profile.personalData.passwordForm.passwordChangeFailed" }) });
                        if (this.passwordRef) {
                            this.passwordRef.focus();
                        }
                    } else {
                        this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    }
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, password, newPassword, newPasswordAgain, formErrors, isLoading } = this.state;
        const passwordLabel = isEditable
            ? Intl.formatMessage({ id: "page.profile.personalData.passwordForm.password.label" })
            : Intl.formatMessage({ id: "page.profile.personalData.passwordForm.password.disabledLabel" });

        const InputComponent = isEditable ? PasswordInput : Input;
        return (
            <form noValidate={true} onSubmit={this.onSubmit} id={TestId.profilePage.passwordForm}>
                <InputWrapper id={TestId.profilePage.oldPasswordInput} inputLabel={passwordLabel} errorMessage={formErrors.password}>
                    <InputComponent
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.passwordRef = ref;
                        }}
                        hasError={!!formErrors.password}
                        placeholder={Intl.formatMessage({ id: "page.profile.personalData.passwordForm.password.placeholder" })}
                        value={isEditable ? password : Intl.formatMessage({ id: "page.profile.personalData.passwordForm.password.disabledValue" })}
                        onChange={this.onPasswordChange}
                        disabled={!isEditable}
                        tabIndex={1}
                    />
                </InputWrapper>

                {isEditable && (
                    <>
                        <InputWrapper
                            id={TestId.profilePage.newPasswordInput}
                            inputLabel={Intl.formatMessage({ id: "page.profile.personalData.passwordForm.newPassword.label" })}
                            errorMessage={formErrors.newPassword}
                        >
                            <PasswordInput
                                innerRef={(ref: HTMLInputElement | null): void => {
                                    this.newPasswordRef = ref;
                                }}
                                hasError={!!formErrors.newPassword}
                                placeholder={Intl.formatMessage({ id: "page.profile.personalData.passwordForm.newPassword.placeholder" })}
                                value={newPassword}
                                onChange={this.onNewPasswordChange}
                                disabled={!isEditable}
                                tabIndex={2}
                            />
                        </InputWrapper>
                        <InputWrapper
                            id={TestId.profilePage.newPasswordAgainInput}
                            inputLabel={Intl.formatMessage({ id: "page.profile.personalData.passwordForm.newPasswordAgain.label" })}
                            errorMessage={formErrors.newPasswordAgain}
                        >
                            <PasswordInput
                                innerRef={(ref: HTMLInputElement | null): void => {
                                    this.newPasswordAgainRef = ref;
                                }}
                                hasError={!!formErrors.newPasswordAgain}
                                placeholder={Intl.formatMessage({ id: "page.profile.personalData.passwordForm.newPasswordAgain.placeholder" })}
                                value={newPasswordAgain}
                                onChange={this.onNewPasswordAgainChange}
                                disabled={!isEditable}
                                tabIndex={3}
                            />
                        </InputWrapper>
                    </>
                )}

                {isEditable ? (
                    <div className="action-button-container">
                        <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} tabIndex={3} />
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            type="submit"
                            disabled={isLoading || !!formErrors.password || !!formErrors.newPassword || !!formErrors.newPasswordAgain || !password || !newPassword || !newPasswordAgain}
                            tabIndex={5}
                        />
                    </div>
                ) : (
                    <div className="edit-button">
                        <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                    </div>
                )}
                <Prompt when={!!password || !!newPassword} />
            </form>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account!,
    };
};

export const ProfilePasswordForm = withNotification(connect(mapStateToProps)(ProfilePasswordFormComponent));

import React, { Component } from "react";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Intl } from "i18n/Intl";
import {
    AssetContent,
    AssetType,
    AtomicAsset,
    SupportedClient,
    SearchListType,
    Account,
    EverydaySituation,
    EverydaySituationType,
    ImageEverydaySituation,
    TextEverydaySituation,
    AudioEverydaySituation,
    CreateEverydaySituationInput,
    UpdateEverydaySituationInput,
} from "api/graphql/types";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { PageType } from "utils/TypeUtils";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Loading, LoadingType } from "components/Loading/Loading";
import { cloneDeep, isEqual, uniqueId } from "lodash";
import { Section } from "components/Section";
import { BottomBar } from "components/BottomBar";
import { Prompt } from "components/Prompt";
import isNil from "lodash/isNil";
import { Select } from "components/Inputs/Select/Select";
import { EverydaySituationListItem, EverydaySituationFormValues } from "pages/ClientEverydaySituationsPage/EverydaySituationListItem";
import { EverydaySituationTypeOptions } from "models/EverydaySituationTypeOptions";
import { ApiTypes } from "api/ApiTypes";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";
import { connect, DispatchProp } from "react-redux";
import { Alert } from "components/Alert/Alert";

export interface FormValues {
    id: string | null;
    title: string;
    position: number | null;
    image: AtomicAsset | null;
    situations: EverydaySituation[];
}

type ComponentProps = {
    onSubmit: (formValues: FormValues, situations: EverydaySituationFormValues[], situationsToDelete: EverydaySituationFormValues[]) => void;
    onCancel: () => void;
    refreshEverydaySituations: () => void;

    myAccount: Account;
    formValues: FormValues;
    client: SupportedClient;
    isLoading: boolean;
    pageType: PageType;
    isPromptDisabled: boolean;
};

type Props = ComponentProps & DispatchProp;

type EverydaySituationWithKey = { key: string; item: EverydaySituationFormValues };

interface State {
    isValidationEnabled: boolean;
    formValues: FormValues;
    situationsToCreate: EverydaySituationWithKey[];
    situationsToUpdate: EverydaySituationWithKey[];
    situationsToDelete: EverydaySituationWithKey[];
    isShowForm: boolean;

    errors: {
        title: string | null;
        image: string | null;
    };
}

class ClientEverydaySituationsDirectoryFormComponent extends Component<Props, State> {
    private titleRef: HTMLInputElement | null = null;
    private itemRefs: Array<EverydaySituationListItem | null> = [];

    private getInitialState(props: Props): State {
        this.itemRefs = new Array(props.formValues.situations.length);
        return {
            isValidationEnabled: false,
            formValues: cloneDeep(props.formValues),
            situationsToCreate: [],
            situationsToUpdate: props.formValues.situations.map(s => {
                return { key: uniqueId(), item: this.convertToFormValues(s) };
            }),
            situationsToDelete: [],
            isShowForm: false,

            errors: {
                title: IntlHelpers.getValidationError(Validator.validateEverydaySituationClientDirectoryTitle(this.props.formValues.title)),
                image: IntlHelpers.getValidationError(Validator.validateNonNull(this.props.formValues.image)),
            },
        };
    }

    public readonly state: State = this.getInitialState(this.props);

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.formValues.id !== prevProps.formValues.id) {
            this.setState(this.getInitialState(this.props));
        }
    }

    private readonly onSubmit = (): void => {
        const { errors } = this.state;
        if (errors.title || errors.image || (this.state.situationsToCreate.length === 0 && this.state.situationsToUpdate.length === 0)) {
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            this.setState({ isValidationEnabled: true });
            return;
        }

        const getItem = (s: EverydaySituationWithKey) => s.item;
        this.props.onSubmit(this.state.formValues, [...this.state.situationsToUpdate.map(getItem), ...this.state.situationsToCreate.map(getItem)], this.state.situationsToDelete.map(getItem));
    };

    private readonly onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title = event.currentTarget.value;
        const titleError = IntlHelpers.getValidationError(Validator.validateEverydaySituationClientDirectoryTitle(title));
        this.setState({ formValues: { ...this.state.formValues, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private readonly onImageChange = (assets: AssetContent[]): void => {
        const image = assets[0] || null;
        const imageError = IntlHelpers.getValidationError(Validator.validateNonNull(image));
        this.setState({ formValues: { ...this.state.formValues, image }, errors: { ...this.state.errors, image: imageError } });
    };

    private readonly onDiscardChanges = () => {
        if (this.props.pageType === PageType.create) {
            this.props.onCancel();
        } else {
            this.setState(this.getInitialState(this.props));
        }
    };

    private convertToFormValues(everydaySituation: EverydaySituation): EverydaySituationFormValues {
        return {
            id: everydaySituation.id,
            title: everydaySituation.title,
            type: Select.getSelectOption(EverydaySituationTypeOptions.get(), ApiTypes.getEverydaySituationType(everydaySituation)),
            image: ((everydaySituation as ImageEverydaySituation).image as AssetContent) || null,
            description: (everydaySituation as TextEverydaySituation).description || undefined,
            audio: ((everydaySituation as AudioEverydaySituation).audio as AssetContent) || null,
            isDisabled: !!everydaySituation.disabledAt,
        };
    }

    private readonly isChanged = (): boolean => {
        return (
            !isEqual(this.props.formValues.image, this.state.formValues.image) ||
            !isEqual(this.props.formValues.title, this.state.formValues.title) ||
            this.state.situationsToCreate.length > 0 ||
            !isEqual(
                this.state.situationsToUpdate.map(s => s.item),
                this.props.formValues.situations.map(this.convertToFormValues),
            )
        );
    };

    private readonly updateItemToCreate = (index: number) => (formValues: EverydaySituationFormValues | null): void => {
        if (formValues === null) {
            const situationsToCreate = [...this.state.situationsToCreate];
            situationsToCreate.splice(index, 1);
            this.itemRefs.splice(this.state.situationsToUpdate.length + index, 1);
            this.setState({ situationsToCreate });
        } else {
            const situationsToCreate = [...this.state.situationsToUpdate];
            situationsToCreate[index] = { ...situationsToCreate[index], item: formValues };
            this.setState({ situationsToCreate });
        }
    };

    private readonly updateItem = (index: number) => (formValues: EverydaySituationFormValues | null): void => {
        if (formValues === null) {
            const situationsToUpdate = [...this.state.situationsToUpdate];
            const situationsToDelete = [...this.state.situationsToDelete, situationsToUpdate.splice(index, 1)[0]];
            this.itemRefs.splice(index, 1);
            this.setState({ situationsToUpdate, situationsToDelete });
        } else {
            const situationsToUpdate = [...this.state.situationsToUpdate];
            situationsToUpdate[index] = { ...situationsToUpdate[index], item: formValues };
            this.setState({ situationsToUpdate });
        }
    };

    private readonly onEditModeChange = (): void => {
        this.forceUpdate();
    };

    private readonly renderItem = (formValues: EverydaySituationWithKey, index: number): React.ReactElement<any> => {
        const everydaySituation: UpdateEverydaySituationInput = {
            id: formValues.item.id!,
            assetId: formValues.item.image?.id || formValues.item.audio?.id,
            title: formValues.item.title,
            description: formValues.item.description,
            directoryId: this.props.formValues.id,
        };

        return (
            <EverydaySituationListItem
                ref={(ref: EverydaySituationListItem) => {
                    this.itemRefs[index] = ref;
                }}
                key={formValues.key}
                formValues={formValues.item}
                updateFormValues={this.updateItem(index)}
                onEditModeChange={this.onEditModeChange}
                isEditable={true}
                onDeleteClick={() => {
                    this.updateItem(index)(null);
                }}
                onMoveClick={() => {
                    if (this.state.situationsToUpdate.length === 1) {
                        Alert.error({ title: Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.items.moveError" }) });
                        return;
                    }
                    this.props.dispatch(
                        DialogsActions.show({
                            type: DialogType.moveEverydaySituation,
                            clientId: this.props.client.id,
                            onMoveSucceed: async () => {
                                await this.props.refreshEverydaySituations();
                                this.updateItem(index)(null);
                            },
                            everydaySituation,
                        }),
                    );
                }}
            />
        );
    };

    private readonly renderItemsToCreate = (formValues: EverydaySituationWithKey, index: number) => {
        const everydaySituation: CreateEverydaySituationInput = {
            assetId: formValues.item.image?.id || formValues.item.audio?.id,
            title: formValues.item.title,
            description: formValues.item.description,
            directoryId: this.props.formValues.id!,
            situationType: formValues.item.type.value,
        };
        return (
            <EverydaySituationListItem
                ref={(ref: EverydaySituationListItem) => {
                    this.itemRefs[this.state.situationsToUpdate.length + index] = ref;
                }}
                key={formValues.key}
                formValues={formValues.item}
                updateFormValues={this.updateItemToCreate(index)}
                onEditModeChange={this.onEditModeChange}
                isEditable={true}
                onDeleteClick={() => {
                    this.updateItemToCreate(index)(null);
                }}
                onMoveClick={() => {
                    this.props.dispatch(
                        DialogsActions.show({
                            type: DialogType.moveEverydaySituation,
                            clientId: this.props.client.id,
                            onMoveSucceed: async () => {
                                await this.props.refreshEverydaySituations();
                                this.updateItemToCreate(index)(null);
                            },
                            everydaySituation,
                        }),
                    );
                }}
            />
        );
    };

    private readonly renderContent = () => {
        const { formValues, isValidationEnabled, errors } = this.state;
        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);
        return (
            <Section label={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.title" }, { name: this.props.client.name })}>
                <p>{Intl.formatMessage({ id: "page.clientEverydaySituations.directories.editDescription" }, { name: clientShortName })}</p>

                <div className="table-row action-button-container">
                    <div style={{ textAlign: "left" }}>
                        <InputWrapper
                            inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.title.label" })}
                            errorMessage={isValidationEnabled ? errors.title : null}
                        >
                            <Input
                                innerRef={(ref: HTMLInputElement | null) => {
                                    this.titleRef = ref;
                                }}
                                type="text"
                                value={formValues.title}
                                onChange={this.onTitleChange}
                                hasError={isValidationEnabled && !isNil(errors.title)}
                                placeholder={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.title.placeholder" })}
                            />
                        </InputWrapper>
                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituationsDirectory.form.image.label" })} errorMessage={isValidationEnabled ? errors.image : null}>
                            <AssetInput
                                droppableId="image"
                                assetType={AssetType.image}
                                value={formValues.image ? [formValues.image as AssetContent] : []}
                                onChange={this.onImageChange}
                                maxItemCount={1}
                                minItemCount={1}
                                hasError={isValidationEnabled && !isNil(errors.image)}
                            />
                        </InputWrapper>

                        <InputWrapper className="mb-0" inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.items.label" })} />
                        <div>
                            {isValidationEnabled && this.state.situationsToCreate.length === 0 && this.state.situationsToUpdate.length === 0 && (
                                <ErrorMessage message={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.form.items.error" })} />
                            )}
                            {this.state.situationsToUpdate.map(this.renderItem)}
                            {this.state.situationsToCreate.map(this.renderItemsToCreate)}
                        </div>

                        {!this.state.isShowForm && (
                            <div>
                                <hr />
                                <Button
                                    label={Intl.formatMessage({ id: "common.addItem" })}
                                    icon={{ name: "fa-plus", position: "left" }}
                                    onClick={() => {
                                        this.setState({ isShowForm: true });
                                    }}
                                />
                            </div>
                        )}

                        {this.state.isShowForm && (
                            <EverydaySituationListItem
                                formValues={{
                                    title: "",
                                    audio: null,
                                    description: "",
                                    image: null,
                                    type: Select.getSelectOption(EverydaySituationTypeOptions.get(), EverydaySituationType.text),
                                }}
                                updateFormValues={(formValues: EverydaySituationFormValues | null) => {
                                    if (formValues === null) {
                                        this.setState({ isShowForm: false });
                                    } else {
                                        this.setState({ situationsToCreate: [{ key: uniqueId(), item: formValues }, ...this.state.situationsToCreate], isShowForm: false });
                                    }
                                }}
                                isTypeEditable={true}
                                isEditable={true}
                                onDeleteClick={() => {
                                    this.setState({ isShowForm: false });
                                }}
                            />
                        )}
                    </div>
                </div>

                <BottomBar isVisible={this.props.pageType === PageType.create || this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onDiscardChanges} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button
                            label={Intl.formatMessage({ id: "common.save" })}
                            onClick={this.onSubmit}
                            disabled={
                                this.itemRefs.some(r => {
                                    return r?.isEditMode();
                                }) ||
                                this.state.isShowForm ||
                                !!this.state.errors.title ||
                                !!this.state.errors.image ||
                                (this.state.situationsToCreate.length === 0 && this.state.situationsToUpdate.length === 0)
                            }
                        />
                    </div>
                </BottomBar>
                <Prompt when={this.isChanged() && !this.props.isPromptDisabled} />
            </Section>
        );
    };

    public render(): React.ReactElement<any> {
        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);
        return (
            <Page title={Intl.formatMessage({ id: `page.clientEverydaySituations.directories.page.${this.props.pageType}.title` }, { name: clientShortName })} hasSideBar={true}>
                <DragContainer dragIds={[]} hideSidebar={false} searchListTypes={[SearchListType.asset]} assetTypes={[AssetType.image, AssetType.audio]} createdById={this.props.myAccount.id}>
                    <div className="left-side">{this.props.isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}</div>
                </DragContainer>
            </Page>
        );
    }
}

export const ClientEverydaySituationsDirectoryForm = connect()(ClientEverydaySituationsDirectoryFormComponent);

import React, { Component } from "react";
import { FlowchartItem, FlowchartItemInput, SupportedClient, AccountType, Account, FlowchartItemType, ContentClient } from "api/graphql/types";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { FlowchartItemForm } from "./FlowchartItemForm";
import { PageType } from "utils/TypeUtils";
import { ApiTypes, ClientAdditionalFunctions } from "api/ApiTypes";
import { AccountSelectors } from "selectors/AccountSelectors";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

interface RouteParams {
    flowchartItemId?: string;
    clientExtId?: string;
    supporterExtId?: string;
    supervisorId?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams>;

interface State {
    flowchartItem: FlowchartItem;
    isLoading: boolean;
    clientAdditionalFunctions: ClientAdditionalFunctions | null;
}

class FlowchartItemPageComponent extends Component<Props, State> {
    public static getInitialStateFromProps(props: Props): State {
        return {
            isLoading: !!props.match.params.flowchartItemId,
            flowchartItem: {
                id: "",
                flowchartType: FlowchartItemType.Simple,
                title: "",
                client: (props.client as unknown) as ContentClient,
                image: null,
                audio: null,
                video: null,
                moodMeter: null,
                notification: null,
                timer: null,
                checklist: null,
                award: null,
                instantAwards: [],
                flowchart: null,
                __typename: "FlowchartItem",
                createdBy: { id: props.account.id, name: "", extId: props.account.extId, __typename: "Account" },
                disabledAt: null,
                createdAt: null,
                updatedAt: null,
                publishedAt: null,
                tags: [],
                flowchartItems: [],
                shareRequests: null,
            },
            clientAdditionalFunctions: null,
        };
    }

    public readonly state: State = FlowchartItemPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        this.refreshFlowchartItem(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.flowchartItemId !== nextProps.match.params.flowchartItemId) {
            this.refreshFlowchartItem(nextProps);
        }
    }

    private refreshClientAdditionalFunctions = async (): Promise<void> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        if (clientId) {
            try {
                const clientAdditionalFunctions: ClientAdditionalFunctions = await Api.getClientAdditionalFunctions(clientId);
                this.setState({ clientAdditionalFunctions });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                return;
            }
        }
    };

    private refreshFlowchartItem = (props: Props): void => {
        const { flowchartItemId } = props.match.params;

        if (!flowchartItemId) {
            this.setState(FlowchartItemPageComponent.getInitialStateFromProps(props));
            this.refreshClientAdditionalFunctions();
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
                    let flowchartItem: FlowchartItem = FlowchartItemPageComponent.getInitialStateFromProps(this.props).flowchartItem;
                    let clientAdditionalFunctions: ClientAdditionalFunctions | null = null;

                    if (clientId) {
                        const flowchartItemWithClientData: {
                            flowchartItem: FlowchartItem;
                            clientAdditionalFunctions: ClientAdditionalFunctions;
                        } = await Api.getFlowchartItemByIdWithClientAdditionalFunctions(flowchartItemId, clientId);

                        flowchartItem = flowchartItemWithClientData.flowchartItem;
                        clientAdditionalFunctions = flowchartItemWithClientData.clientAdditionalFunctions;
                    } else {
                        flowchartItem = await Api.getFlowchartItemById(flowchartItemId);
                    }

                    this.setState({ isLoading: false, flowchartItem, clientAdditionalFunctions });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                }
            },
        );
    };

    private onSubmit = async (flowchartItem: FlowchartItem): Promise<FlowchartItem> => {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const flowchartItemInput: FlowchartItemInput = ApiTypes.flowchartItemToFlowchartItemInput(flowchartItem);
        if (pageType === PageType.create) {
            const result = await Api.createFlowchartItem(flowchartItemInput);
            return result;
        } else if (pageType === PageType.edit) {
            const result = await Api.updateFlowchartItem(flowchartItem.id, flowchartItemInput);
            this.setState({ flowchartItem: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    private onToggled = (): void => {
        const flowchartItem = { ...this.state.flowchartItem };
        flowchartItem.disabledAt = flowchartItem.disabledAt ? null : DateUtils.format(new Date(), DateFormat.default);
        this.setState({ flowchartItem });
    };

    private onEditClick = (): void => {
        const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, !!this.state.flowchartItem.publishedAt);
        const path: string | undefined = ContentPageUtils.getEditPath(this.state.flowchartItem, pageContent, this.props.match.params.clientExtId);
        if (path) {
            this.props.history.push(path);
        }
    };

    public render(): React.ReactElement<any> {
        if (this.props.match.params.clientExtId && this.props.account.accountType === AccountType.supporter && !this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        const { flowchartItem, isLoading } = this.state;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        return (
            <FlowchartItemForm
                myAccount={this.props.account}
                flowchartItem={flowchartItem}
                pageContent={ContentPageUtils.getPageContent(this.props.match.path, !!this.state.flowchartItem.publishedAt)}
                clientExtId={this.props.match.params.clientExtId}
                supporterExtId={this.props.match.params.supporterExtId}
                supervisorId={this.props.match.params.supervisorId}
                isLoading={isLoading}
                clientAdditionalFunctions={this.state.clientAdditionalFunctions}
                pageType={pageType}
                onSubmit={this.onSubmit}
                onEditClick={this.onEditClick}
                client={this.props.client}
                onToggled={this.onToggled}
                accountType={this.props.account.accountType}
                refreshFlowchartItem={(): void => this.refreshFlowchartItem(this.props)}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return { account: state.account!, client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId) };
};

export const FlowchartItemPage = withRouter(connect(mapStateToProps)(FlowchartItemPageComponent));

// tslint:disable-next-line: no-default-export
export default FlowchartItemPage;

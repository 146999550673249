import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { CreateTagInput, getTags, TagCategory } from "api/graphql/types";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { IntlHelpers } from "i18n/IntlHelpers";
import { BottomBar } from "components/BottomBar";
import { Prompt } from "components/Prompt";
import { Button } from "components/Button/Button";
import { RouteComponentProps } from "react-router";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { Validator } from "utils/Validator";
import { TagsActions } from "actions/TagsActions";
import { connect, DispatchProp } from "react-redux";

type Props = RouteComponentProps & DispatchProp;

interface TagInput {
    title: string;
    category: SelectOption<TagCategory> | null;
}

interface Errors {
    title: string | null;
    category: string | null;
}

interface State {
    tag: TagInput;
    errors: Errors;
    isValidationEnabled: boolean;
    isPromptDisabled: boolean;
}

class CreateTagPageComponent extends Component<Props, State> {
    private titleRef: HTMLInputElement | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {
            tag: {
                title: "",
                category: null,
            },
            errors: {
                title: null,
                category: null,
            },
            isValidationEnabled: false,
            isPromptDisabled: false,
        };
    }

    private readonly getCategoryOptions = (): SelectOption<TagCategory>[] => {
        return Object.values(TagCategory).map(
            (category: TagCategory): SelectOption<TagCategory> => {
                return {
                    id: category,
                    value: category,
                    label: Intl.formatMessage({ id: `enum.tagCategory.${category}` }),
                };
            },
        );
    };

    private readonly onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = this.state.isValidationEnabled ? IntlHelpers.getValidationError(Validator.validateTagTitle(title)) : null;
        this.setState({
            tag: {
                ...this.state.tag,
                title,
            },
            errors: {
                ...this.state.errors,
                title: titleError,
            },
        });
    };

    public isChanged = (): boolean => {
        return !!this.state.tag.title || !!this.state.tag.category;
    };

    private readonly onCategoryChange = (category: SelectOption<TagCategory>): void => {
        const categoryError: string | null = this.state.isValidationEnabled ? IntlHelpers.getValidationError(Validator.validateNonNull(category)) : null;
        this.setState({
            tag: {
                ...this.state.tag,
                category,
            },
            errors: {
                ...this.state.errors,
                category: categoryError,
            },
        });
    };

    private readonly onDiscardChanges = () => {
        this.props.history.push(Path.tags);
    };

    private readonly onSubmit = async () => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateTagTitle(this.state.tag.title));
        const categoryError: string | null = IntlHelpers.getValidationError(Validator.validateNonNull(this.state.tag.category));

        if (titleError || categoryError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError, category: categoryError } });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            return;
        }

        try {
            const input: CreateTagInput = {
                title: this.state.tag.title,
                category: this.state.tag.category!.value,
            };
            await Api.createTag({ input });
            Alert.success({ title: Intl.formatMessage({ id: "page.createTag.success" }) });
            this.setState({ isPromptDisabled: true });
            const tags: getTags = await Api.getTags();
            this.props.dispatch(TagsActions.updateTags(tags.getTags?.result || []));
            this.props.history.push(Path.tags);
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement {
        const { isValidationEnabled, errors, tag } = this.state;
        return (
            <Page title={Intl.formatMessage({ id: "page.createTag.title" })} hasSideBar={false}>
                <div className="left-side">
                    <p className="m-3">{Intl.formatMessage({ id: "page.createTag.description" })}</p>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.createTag.form.title.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null) => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            name="title"
                            placeholder={Intl.formatMessage({ id: "page.createTag.form.title.placeholder" })}
                            value={tag.title}
                            onChange={this.onTitleChange}
                            required={true}
                            hasError={isValidationEnabled && errors.title !== null}
                        />
                    </InputWrapper>

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.createTag.form.category.label" })} errorMessage={isValidationEnabled ? errors.category : null}>
                        <Select
                            value={tag.category}
                            onChange={this.onCategoryChange}
                            options={this.getCategoryOptions()}
                            emptyLabel={Intl.formatMessage({ id: "page.createTag.form.category.placeholder" })}
                            hasError={isValidationEnabled && errors.category !== null}
                        />
                    </InputWrapper>

                    <BottomBar isVisible={true}>
                        <div className="cell medium-6 text-right">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onDiscardChanges} />
                        </div>
                        <div className="cell medium-6 text-left">
                            <Button label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSubmit} disabled={!this.isChanged()} />
                        </div>
                    </BottomBar>

                    <Prompt when={this.isChanged() && !this.state.isPromptDisabled} />
                </div>
            </Page>
        );
    }
}

export const CreateTagPage = connect()(CreateTagPageComponent);

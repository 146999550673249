import AuthPage from "./AuthPage/AuthPage";
import AccountActivationPage from "./AccountActivationPage/AccountActivationPage";
import ForgotPasswordPage from "./ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import DashboardPage from "./DashboardPage/DashboardPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import EmailActivationPage from "./EmailActivationPage/EmailActivationPage";
import AddClientPage from "./AddClientPage/AddClientPage";
import ClientProfilePage from "./ClientProfilePage/ClientProfilePage";
import ClientCalendarPage from "./ClientCalendarPage/ClientCalendarPage";
import ClientAwardsBasePage from "./ClientAwardsBasePage/ClientAwardsBasePage";
import ClientSelectionBoardsList from "./ClientSelectionBoardsPage/ClientSelectionBoardsList";
import ClientSelectionBoardPage from "./ClientSelectionBoardsPage/ClientSelectionBoardPage";
import ClientContentLibraryPage from "./ClientContentLibraryPage/ClientContentLibraryPage";
import ClientEventLogPage from "./ClientEventLogPage/ClientEventLogPage";
import ClientSettingsPage from "./ClientSettingsPage/ClientSettingsPage";
import ClientEverydaySituationsPage from "./ClientEverydaySituationsPage/ClientEverydaySituationsPage";
import PublicContentLibraryPage from "./PublicContentLibraryPage/PublicContentLibraryPage";
import AdminListPage from "./AdminListPage/AdminListPage";
import SupervisorListPage from "./SupervisorListPage/SupervisorListPage";
import SupporterListPage from "./SupporterListPage/SupporterListPage";
import ClientListPage from "./ClientListPage/ClientListPage";
import AdminDetailPage from "./AdminDetailPage/AdminDetailPage";
import SupervisorDetailPage from "./SupervisorDetailPage/SupervisorDetailPage";
import SupporterDetailPage from "./SupporterDetailPage/SupporterDetailPage";
import ClientDetailPage from "./ClientDetailPage/ClientDetailPage";
import AgendaItemPage from "./AgendaItemPage/AgendaItemPage";
import AgendaPage from "./AgendaPage/AgendaPage";
import FlowchartItemPage from "./FlowchartItemPage/FlowchartItemPage";
import FlowchartPage from "./FlowchartPage/FlowchartPage";
import ClientShareRequestPage from "./ClientShareRequestPage/ClientShareRequestPage";
import ChangeClientManagerRequestPage from "./ChangeClientManagerRequestPage/ChangeClientManagerRequestPage";
import AssetPage from "./AssetPage/AssetPage";
import ClientQuestionnairePage from "./ClientQuestionnairePage/ClientQuestionnairePage";
import ClientSpareAgendaItemsPage from "./ClientSpareAgendaItemsPage/ClientSpareAgendaItemsPage";
import FaqList from "./FaqPage/FaqList";
import SandboxPage from "./SandboxPage/SandboxPage";
import ClientEverydaySituationsDirectoryPage from "./ClientEverydaySituationsDirectoryPage/ClientEverydaySituationsDirectoryPage";
import ClientGamesPage from "./ClientGamesPages/ClientGamesPage";
import ClientInstantAwardPage from "./ClientAwardsBasePage/ClientInstantAwardPage";
import ClientAwardPage from "./ClientAwardsBasePage/ClientAwardPage";
import FaqPage from "./FaqPage/FaqPage";
import { ReplaceAssetItemPage } from "./ReplaceAssetItemPage/ReplaceAssetItemPage";
import { TagListPage } from "./TagsPage/TagListPage";
import { CreateTagPage } from "./TagsPage/CreateTagPage";
import { EducationModuleListPage } from "./EducationModulesListPages/EducationModuleListPage";
import { EducationSubModuleListPage } from "./EducationModulesListPages/EducationSubModuleListPage";
import { EducationSubModulePage } from "./EducationModulesPage/EducationSubModulePage";
import { EducationPage } from "./EducationPage/EducationPage";
import { ClientEverydaySituationDirectoryPage } from "./ClientEverydaySituationsPage/ClientEverydaySituationDirectoryPage";
import ContentShareRequestListPage from "./ContentShareRequestListPage/ContentShareRequestListPage";
import ContentShareRequestPage from "./ContentShareRequestPage/ContentShareRequestPage";
import { MyEventLogPage } from "./MyEventLogPage/MyEventLogPage";
import { SystemEventLogPage } from "./SystemEventLogPage/SystemEventLogPage";

class Pages {
    public static readonly auth = AuthPage;
    public static readonly accountActivation = AccountActivationPage;
    public static readonly forgotPassword = ForgotPasswordPage;
    public static readonly resetPassword = ResetPasswordPage;
    public static readonly dashboard = DashboardPage;
    public static readonly profile = ProfilePage;
    public static readonly emailActivation = EmailActivationPage;
    public static readonly addClient = AddClientPage;
    public static readonly myEventLog = MyEventLogPage;
    public static readonly clientProfile = ClientProfilePage;
    public static readonly clientQuestionnairePage = ClientQuestionnairePage;
    public static readonly clientCalendar = ClientCalendarPage;
    public static readonly clientAwardsBase = ClientAwardsBasePage;
    public static readonly clientInstantAward = ClientInstantAwardPage;
    public static readonly clientAward = ClientAwardPage;
    public static readonly clientSelectionBoards = ClientSelectionBoardsList;
    public static readonly clientSelectionBoard = ClientSelectionBoardPage;
    public static readonly clientEverydaySituationsClientDirectory = ClientEverydaySituationsPage;
    public static readonly clientEverydaySituationsDirectory = ClientEverydaySituationsDirectoryPage;
    public static readonly clientEverydaySituationDirectory = ClientEverydaySituationDirectoryPage;
    public static readonly clientContentLibrary = ClientContentLibraryPage;
    public static readonly clientGames = ClientGamesPage;
    public static readonly clientEventLog = ClientEventLogPage;
    public static readonly clientSettings = ClientSettingsPage;
    public static readonly publicContentLibrary = PublicContentLibraryPage;
    public static readonly contentShareRequestList = ContentShareRequestListPage;
    public static readonly contentShareRequest = ContentShareRequestPage;
    public static readonly replaceAssetItem = ReplaceAssetItemPage;
    public static readonly systemEventLog = SystemEventLogPage;
    public static readonly educationModuleListPage = EducationModuleListPage;
    public static readonly educationSubModuleListPage = EducationSubModuleListPage;
    public static readonly educationSubModulePage = EducationSubModulePage;
    public static readonly faqList = FaqList;
    public static readonly faq = FaqPage;
    public static readonly education = EducationPage;
    public static readonly adminList = AdminListPage;
    public static readonly supervisorList = SupervisorListPage;
    public static readonly supporterList = SupporterListPage;
    public static readonly clientList = ClientListPage;
    public static readonly adminDetail = AdminDetailPage;
    public static readonly supervisorDetail = SupervisorDetailPage;
    public static readonly supporterDetail = SupporterDetailPage;
    public static readonly clientDetail = ClientDetailPage;
    public static readonly agendaItemPage = AgendaItemPage;
    public static readonly agenda = AgendaPage;
    public static readonly flowchartItem = FlowchartItemPage;
    public static readonly flowchart = FlowchartPage;
    public static readonly clientShareRequest = ClientShareRequestPage;
    public static readonly changeClientManagerRequest = ChangeClientManagerRequestPage;
    public static readonly assetPage = AssetPage;
    public static readonly clientSpareAgendaItems = ClientSpareAgendaItemsPage;
    public static readonly tagsPage = TagListPage;
    public static readonly createTagPage = CreateTagPage;

    public static readonly sandboxPage = SandboxPage;
}

export { Pages };

import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import ReactTooltip from "react-tooltip";
import copy from "clipboard-copy";

interface Props {
    id?: string;
    copiedTooltipMessage: string;
    textToCopy: string;
}

interface State {
    showTooltip: boolean;
}

class CopyToClipboardButton extends Component<Props, State> {
    private copyToClipboardButtonRef: HTMLButtonElement | null = null;
    private tooltipTimeout: NodeJS.Timeout | null = null;

    public readonly state: State = {
        showTooltip: false,
    };

    public componentWillUnmount(): void {
        if (this.tooltipTimeout) {
            clearTimeout(this.tooltipTimeout);
            ReactTooltip.hide();
        }
    }

    public onCopyExtId = (): void => {
        copy(this.props.textToCopy).catch(() => {});
        this.setState({ showTooltip: true }, (): void => {
            const target = findDOMNode(this.copyToClipboardButtonRef);
            if (target) {
                ReactTooltip.show(target as Element);
            }
        });

        if (this.tooltipTimeout) {
            clearTimeout(this.tooltipTimeout);
        }

        this.tooltipTimeout = setTimeout(() => {
            ReactTooltip.hide();
            this.setState({ showTooltip: false });
        }, 750);
    };

    public render(): React.ReactElement<any> {
        const tooltipProps = this.state.showTooltip ? { "data-tip": this.props.copiedTooltipMessage, "data-place": "bottom" } : {};
        return (
            <Button
                id={this.props.id}
                innerRef={(ref: HTMLButtonElement | null): void => {
                    this.copyToClipboardButtonRef = ref;
                }}
                label={Intl.formatMessage({ id: "common.copy" })}
                ariaLabel={Intl.formatMessage({ id: "common.copy" })}
                onClick={this.onCopyExtId}
                {...tooltipProps}
            />
        );
    }
}

export { CopyToClipboardButton };

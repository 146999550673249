import React, { Component } from "react";
import { Page } from "components/Page";
import { ContentLibraryTable } from "pages/_shared/ContentLibraryTable/ContentLibraryTable";
import { SearchListType, ClientProfileData, SupportedClient } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { LinkGroupItem, LinkGroup } from "components/LinkGroup/LinkGroup";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { Api } from "api/Api";
import { ContentLibraryLinks } from "models/ContentLibraryLinks";
import { Intl } from "i18n/Intl";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { PageContent } from "pages/_shared/ContentPageUtils";

interface ReduxProps {
    client: SupportedClient | null;
}
interface RouteParams {
    clientExtId?: string;
    searchListType?: string;
}

type Props = ReduxProps & RouteComponentProps<RouteParams>;

interface State {
    clientProfileData: ClientProfileData | null;
    isLoading: boolean;
}

class ClientContentLibraryPageComponent extends Component<Props, State> {
    private readonly urlQueryParser = new ContentLibraryUrlQueryParser();
    public readonly state: State = {
        clientProfileData: null,
        isLoading: true,
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshClient(this.props.client.id);
        } else {
            this.setState({ isLoading: false });
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshClient(nextProps.client.id);
        }
    }

    private refreshClient = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientProfileData: ClientProfileData = await Api.getMyClientById(clientId);
                    this.setState({ clientProfileData, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private renderLinks = (): React.ReactElement<any> => {
        const clientLinkGroupItems: LinkGroupItem[] = ContentLibraryLinks.getForClient(this.props.match.params.clientExtId!);
        const currentPath: string = this.props.location.pathname + this.props.location.search;
        return <LinkGroup isLinkActive={(item: LinkGroupItem): boolean => currentPath.startsWith(item.toPage)} links={clientLinkGroupItems} />;
    };

    public render(): React.ReactElement<any> {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const { clientProfileData } = this.state;
        if (!clientProfileData) {
            return <Redirect to={Path.dashboard} />;
        }

        const currentSearchListType: SearchListType | undefined = this.urlQueryParser.parse(this.props.location.search).searchListType;
        if (!currentSearchListType || currentSearchListType === SearchListType.asset) {
            return <Redirect to={`${Path.clientContentLibrary(this.props.match.params.clientExtId!)}?${this.urlQueryParser.getUrlQuery({ searchListType: SearchListType.agendaItem })}`} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.clientContentLibrary.title" }, { name: clientProfileData.name })}>
                <ContentLibraryTable client={this.props.client} renderLinks={this.renderLinks} pageContent={PageContent.client} />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return {
        client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId),
    };
};

export const ClientContentLibraryPage = withRouter(connect(mapStateToProps)(ClientContentLibraryPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientContentLibraryPage;

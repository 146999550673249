import React, { Component } from "react";
import { Page } from "components/Page";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { TabPanel } from "components/TabPanel/TabPanel";
import AppEventLogTab from "./AppEventLogTab";
import CmsEventLogTab from "./CmsEventLogTab";
import "./SystemEventLogPage.scss";

class SystemEventLogPageComponent extends Component<{}> {
    public render(): React.ReactElement {
        return (
            <Page title={Intl.formatMessage({ id: "page.systemEventLog.title" })} hasTabs={true}>
                <div className="left-side system-event-log-page">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.systemEventLog.appEventLogTab.title" }),
                                label: Intl.formatMessage({ id: "page.systemEventLog.appEventLogTab.title" }),
                                to: Path.appEventLog,
                                component: AppEventLogTab,
                            },
                            {
                                id: Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.title" }),
                                label: Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.title" }),
                                to: Path.cmsEventLog,
                                component: CmsEventLogTab,
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

export const SystemEventLogPage = SystemEventLogPageComponent;

import React, { PureComponent } from "react";
import { Prompt as RoutePrompt, withRouter, RouteComponentProps } from "react-router-dom";
import { Location } from "history";
import { AppPath } from "utils/Path";

export enum PromptMessage {
    samePath = "samePath",
    newPath = "newPath",
    redirectedPath = "redirectedPath",
    logout = "logout",
}

interface ComponentProps {
    when: boolean;
    pathsToSkip?: string[];
}

type Props = ComponentProps & RouteComponentProps;

class PromptComponent extends PureComponent<Props> {
    private getMessage = (location: Location): string | boolean => {
        if (location.pathname === this.props.location.pathname) {
            return PromptMessage.samePath;
        }

        if (location.pathname === AppPath.logout) {
            return PromptMessage.logout;
        }

        if (this.props.pathsToSkip && this.props.pathsToSkip.includes(location.pathname)) {
            return PromptMessage.redirectedPath;
        }

        return PromptMessage.newPath;
    };

    public render(): React.ReactElement<any> {
        return <RoutePrompt when={this.props.when} message={this.getMessage} />;
    }
}

export const Prompt = withRouter(PromptComponent);

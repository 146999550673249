import { applyMiddleware, createStore, Middleware, Store, compose } from "redux";
import reduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { rootReducer, ApplicationState } from "./reducers";
import multi from "redux-multi";
import { Env, RunMode } from "utils/Env";

const middlewares: Middleware[] = [reduxThunk, multi];

if (Env.runMode === RunMode.dev) {
    const logger: Middleware = createLogger();
    middlewares.push(logger);
}

const composeEnhancers: (<R>(a: R) => R) | undefined = Env.debugMode ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const enhancer: any = composeEnhancers ? composeEnhancers(applyMiddleware(...middlewares)) : undefined;

const store: Store<ApplicationState> = createStore(rootReducer, enhancer);

export { store };

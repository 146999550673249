import React from "react";
import "../Dropdown/Dropdown.scss";

interface OptionMenuItem {
    label: string;
    onClick: (item: OptionMenuItem) => void | Promise<void>;
    icon?: string;
}

interface Props {
    id?: string;
    items: OptionMenuItem[];
    renderItem?: (item: OptionMenuItem) => React.ReactElement<any>;
}

interface State {
    isOpened: boolean;
}

class OptionsMenu extends React.PureComponent<Props, State> {
    private containerRef: HTMLDivElement | null = null;

    public readonly state: State = {
        isOpened: false,
    };

    public componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    private onClickOutside = (event: MouseEvent): void => {
        if (this.containerRef && !this.containerRef.contains(event.target as Node) && this.state.isOpened) {
            this.onOpenChange();
        }
    };

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    private onItemClick = (item: OptionMenuItem) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        this.setState({ isOpened: false }, () => {
            item.onClick(item);
        });
    };

    private renderOptionItem = (item: OptionMenuItem): React.ReactElement<any> => {
        return (
            <div key={item.label} className="dropdown-item" onClick={this.onItemClick(item)}>
                {item.icon && <span className={`fa ${item.icon}`}></span>}
                {item.label}
            </div>
        );
    };

    private onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.onOpenChange();
    };

    public render(): React.ReactElement<any> {
        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                id={this.props.id}
                className={`dropdown thin${this.state.isOpened ? " show" : ""}`}
            >
                <button className="btn btn-link large-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.onButtonClick}>
                    <span className="fa fa-ellipsis-v"></span>
                </button>

                <div className={`dropdown-menu${this.state.isOpened ? " show" : ""}`} x-placement="bottom-start">
                    {this.props.items.map(this.renderOptionItem)}
                </div>
            </div>
        );
    }
}

export { OptionsMenu };
export type { OptionMenuItem };

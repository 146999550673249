import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { compose } from "redux";

import { ApplicationState } from "reducers/index";
import { AccountType, ClientShareRequest, SupportedClient } from "api/graphql/types";
import { NavItem, NavItems } from "./NavItems";
import { MenuItem } from "./MenuItem";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ShareRequestMenuItem } from "./ShareRequestMenuItem";
import { Path } from "utils/Path";
import { ChangeClientManagerRequestMenuItem } from "./ChangeClientManagerRequestMenuItem";

interface ReduxProps {
    accountId: string | null;
    clients: SupportedClient[];
    clientShareRequests: ClientShareRequest[];
    accountType: AccountType | null;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps;

interface State {
    navItems: NavItem[];
    openedNavItemKey: string | null;
}

class MenuComponent extends Component<Props, State> {
    public readonly state: State = {
        navItems: NavItems.get(this.props.accountType, this.props.clients),
        openedNavItemKey: null,
    };

    public static getDerivedStateFromProps(props: Props): Partial<State> {
        return {
            navItems: NavItems.get(props.accountType, props.clients),
        };
    }

    private onOpenChange = (navItem: NavItem) => (isOpen: boolean) => {
        this.setState({ openedNavItemKey: isOpen ? navItem.key : null });
    };

    private renderMenuItem = (navItem: NavItem): React.ReactElement<any> => {
        const isOpened = this.state.openedNavItemKey === navItem.key;
        return <MenuItem key={navItem.key} navItem={navItem} isOpened={isOpened} onOpenChange={this.onOpenChange(navItem)} />;
    };

    private renderClientShareRequest = (clientShareRequests: ClientShareRequest): React.ReactElement<any> => {
        return <ShareRequestMenuItem key={clientShareRequests.id} clientShareRequest={clientShareRequests} />;
    };

    private renderClientManagerChangeRequest = (client: SupportedClient): React.ReactElement<any> | null => {
        return <ChangeClientManagerRequestMenuItem key={client.id} client={client} accountId={this.props.accountId} />;
    };

    public render(): React.ReactElement<any> {
        const showRequests = !this.props.location.pathname.includes(Path.clientShareRequest(""));
        return (
            <ul className="nav metismenu" id="side-menu">
                {this.props.clients.map(this.renderClientManagerChangeRequest)}
                {showRequests && this.props.clientShareRequests.map(this.renderClientShareRequest)}
                {this.state.navItems.map(this.renderMenuItem)}
            </ul>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        accountId: state.account ? state.account.id : null,
        clients: AccountSelectors.getClients(state),
        accountType: AccountSelectors.getAccountType(state),
        clientShareRequests: state.account && state.account.pendingClientShareRequests ? state.account.pendingClientShareRequests.result : [],
    };
};

export const Menu = compose(withRouter, connect(mapStateToProps))(MenuComponent) as React.ComponentClass;

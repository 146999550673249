import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checklist, Checklist_items, ChecklistItem } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ListInput, ListItem } from "components/Inputs/ListInput/ListInput";
import { Validator } from "utils/Validator";
import { IntlHelpers } from "i18n/IntlHelpers";

export interface ChecklistValidationError {
    items?: Array<string | null>;
}

interface Props {
    id?: string;
    value: Checklist | null;
    onChange: (value: Checklist | null, validationError: ChecklistValidationError) => void;
    errors?: ChecklistValidationError | null;
    isValidationEnabled: boolean;
    disabled?: boolean;
    addLabel?: string;
}

interface State {
    items: ListItem[];
}

class ChecklistInput extends Component<Props, State> {
    private listRef: ListInput | null = null;

    public static getDerivedStateFromProps(props: Props): State {
        let items: ListItem[] = [];
        if (props.value) {
            const reorderItems = props.value.items.sort((s1: ChecklistItem, s2: ChecklistItem): number => {
                return s1.position - s2.position;
            });

            items = reorderItems.map((item: ChecklistItem): ListItem => ({ id: item.id, value: item.title }));
        }

        return { items };
    }

    public readonly state: State = ChecklistInput.getDerivedStateFromProps(this.props);

    public focus(): void {
        if (this.listRef) {
            this.listRef.focus();
        }
    }

    private static toChecklistItems(list: ListItem[]): ChecklistItem[] {
        return list.map(
            (item: ListItem, index: number): Checklist_items => {
                return { id: item.id || "", position: index, title: item.value, __typename: "ChecklistItem" };
            },
        );
    }

    private static toChecklist(checklist: Checklist | null, list: ListItem[]): Checklist {
        // TODO: remove "-", backend validates title right now
        return { id: checklist && checklist.id ? checklist.id : "", items: ChecklistInput.toChecklistItems(list), title: "-", __typename: "Checklist" };
    }

    private onChange = (list: ListItem[]) => {
        if (list.length > 0) {
            if (this.props.value) {
                const items = ChecklistInput.toChecklistItems(list);
                const errors: ChecklistValidationError = {
                    items: items.map((listItem: ChecklistItem): string | null => IntlHelpers.getValidationError(Validator.validateChecklistItemTitle(listItem.title))),
                };

                this.props.onChange({ ...this.props.value, items }, errors);
            } else {
                const checklist = ChecklistInput.toChecklist(this.props.value, list);
                const errors: ChecklistValidationError = {
                    items: checklist.items.map((listItem: ChecklistItem): string | null => IntlHelpers.getValidationError(Validator.validateChecklistItemTitle(listItem.title))),
                };

                this.props.onChange(checklist, errors);
            }
        } else {
            this.props.onChange(null, {});
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <InputWrapper id={this.props.id} inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.checklist.label" })} padded icon="fa-clipboard-list">
                <ListInput
                    ref={(ref: ListInput | null): void => {
                        this.listRef = ref;
                    }}
                    value={this.state.items}
                    errors={this.props.isValidationEnabled && this.props.errors ? this.props.errors.items : undefined}
                    onChange={this.onChange}
                    newValuePlaceholder={Intl.formatMessage({ id: "sharedComponent.inputs.checklist.placeholder" })}
                    deleteLabel={Intl.formatMessage({ id: "common.delete" })}
                    addLabel={this.props.addLabel || Intl.formatMessage({ id: "common.add" })}
                    disabled={this.props.disabled}
                />
            </InputWrapper>
        );
    }
}

export { ChecklistInput };

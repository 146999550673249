import React, { Component } from "react";
import { AccountType, InstantAwardListItem, InstantAwardListSortField, ListControl, SortOrder, SupportedClient, AssetType } from "api/graphql/types";
import { PageContent } from "../ContentPageUtils";
import { RouteComponentProps } from "react-router";
import { DispatchProp } from "react-redux";
import { ListQueryParameter, ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { Column, Table } from "components/Table/Table";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import isEqual from "lodash/isEqual";
import { ListResult } from "api/ApiTypes";
import { isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";
import { OverlayIcon } from "components/OverlayIcon";

interface InstantAwardTableListOptions {
    sortField?: InstantAwardListSortField | null;
    control: ListControl;
}

interface State {
    instantAwards: InstantAwardListItem[];
    count: number;
    options: InstantAwardTableListOptions;
    isLoading: boolean;
}

enum InstantAwardItemTableColumn {
    title = "title",
    isDisabled = "isDisabled",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    actions = "actions",
}

interface ComponentProps {
    currentUrlParams: string;
    directory: string | undefined;
    client: SupportedClient;
    pageContent: PageContent;
    accountType: AccountType;
    getInstantAwardList: (options?: InstantAwardTableListOptions) => Promise<ListResult<InstantAwardListItem>>;
    search: string;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class InstantAwardTable extends Component<Props, State> {
    private static getInitialOptions(props: Props): InstantAwardTableListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<InstantAwardListSortField>(InstantAwardListSortField, "sharedComponent.contentLibraryTable.instantAwardTable").parse(
            props.location.search,
        );
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                search: props.search,
                sortOrder,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public constructor(props: Props) {
        super(props);
        this.state = {
            instantAwards: [],
            count: 0,
            options: InstantAwardTable.getInitialOptions(props),
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        this.refreshInstantAwardList(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Readonly<Props>): void {
        const nextOptions: InstantAwardTableListOptions = InstantAwardTable.getInitialOptions(nextProps);
        if (!isEqual(this.state.options, nextOptions) || nextProps.pageContent !== this.props.pageContent) {
            this.setState({ options: nextOptions }, () => {
                this.updateQueryParams();
            });
        }
    }

    private readonly refreshInstantAwardList = (options?: InstantAwardTableListOptions) => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response: ListResult<InstantAwardListItem> = await this.props.getInstantAwardList(options);
                    this.setState({
                        instantAwards: response.result,
                        count: response.count,
                        isLoading: false,
                    });
                } catch (errors) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(errors) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private onDeleteClick = (instantAwardId: string) => {
        const award = this.state.instantAwards.find(award => award.id === instantAwardId);
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteClientInstantAward,
                clientInstantAwardId: instantAwardId,
                onDeleted: () => this.refreshInstantAwardList(this.state.options),
                hasReferences: (award?.referenceCount ?? 0) > 0,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<InstantAwardListItem>> => {
        const columnNames: InstantAwardItemTableColumn[] = ObjectUtils.enumAsArray<InstantAwardItemTableColumn>(InstantAwardItemTableColumn);

        return columnNames.map(
            (columnName: InstantAwardItemTableColumn): Column<InstantAwardListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.instantAwardTable.columns.${columnName}` }),
                accessor: columnName as keyof InstantAwardListItem,
                renderCell: (instantAward: InstantAwardListItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case InstantAwardItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(instantAward.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case InstantAwardItemTableColumn.updatedAt:
                            return (
                                <>
                                    {instantAward.updatedAt
                                        ? DateUtils.format(new Date(instantAward.updatedAt), DateFormat.yyyymmddhhmm)
                                        : DateUtils.format(new Date(instantAward.createdAt), DateFormat.yyyymmddhhmm)}
                                </>
                            );
                        case InstantAwardItemTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    content={instantAward}
                                    clientExtId={this.props.client.extId}
                                    onToggled={() => this.onToggled(instantAward)}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: instantAward,
                                                clientId: this.props.client?.id,
                                                onDisabled: (): void => this.refreshInstantAwardList(this.state.options),
                                            }),
                                        );
                                    }}
                                    onDeleteClick={() => this.onDeleteClick(instantAward.id)}
                                />
                            );
                        case InstantAwardItemTableColumn.title:
                            return (
                                <div className="table-image-name">
                                    <div
                                        className="table-image-container"
                                        onClick={
                                            instantAward.image
                                                ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                      event.stopPropagation();
                                                      this.props.dispatch(
                                                          DialogsActions.show({
                                                              type: DialogType.showAsset,
                                                              assetUrl: instantAward.image?.url,
                                                              assetType: instantAward.image?.assetType || AssetType.image,
                                                              originalFileName: instantAward.image?.originalFileName,
                                                              thumbnailUrl: instantAward.image?.thumbnailUrl,
                                                              dialogTitle: instantAward.image?.title || Intl.formatMessage({ id: `enum.assetType.${AssetType.image}` }),
                                                          }),
                                                      );
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Image src={instantAward.image?.thumbnailUrl || instantAward.image?.url} fallback={ImageSrc.asset} />
                                        {instantAward.image && (
                                            <>
                                                <div className="content-overlay" />
                                                <div className="content-overlay-icon">
                                                    <OverlayIcon assetType={instantAward.image.assetType} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {instantAward.title}
                                </div>
                            );
                        case InstantAwardItemTableColumn.isDisabled:
                            return (
                                <DisabledLock
                                    isVisible={!isNil(instantAward.disabledAt)}
                                    tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.instantAwardTable.disabledTooltip" })}
                                />
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: ![InstantAwardItemTableColumn.title, InstantAwardItemTableColumn.createdAt, InstantAwardItemTableColumn.updatedAt].includes(columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<InstantAwardListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<InstantAwardListSortField>(InstantAwardListSortField, "sharedComponent.contentLibraryTable.instantAwardTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshInstantAwardList(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): InstantAwardListSortField | undefined => {
        switch (columnId) {
            case InstantAwardItemTableColumn.title:
                return InstantAwardListSortField.title;
            case InstantAwardItemTableColumn.createdAt:
                return InstantAwardListSortField.createdAt;
            case InstantAwardItemTableColumn.updatedAt:
                return InstantAwardListSortField.updatedAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: InstantAwardListSortField | null): keyof InstantAwardListItem | undefined => {
        switch (columnId) {
            case InstantAwardListSortField.title:
                return InstantAwardItemTableColumn.title;
            case InstantAwardListSortField.createdAt:
                return InstantAwardItemTableColumn.createdAt;
            case InstantAwardListSortField.updatedAt:
                return InstantAwardItemTableColumn.updatedAt;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<InstantAwardListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private readonly onToggled = (content: InstantAwardListItem) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                content: content,
                onToggled: () => {
                    this.refreshInstantAwardList(this.state.options);
                },
            }),
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Table
                keyExtractor={(item: InstantAwardListItem, column?: Column<InstantAwardListItem>): string => {
                    return `${item.id}_${column ? column.id : ""}`;
                }}
                columns={this.getColumns()}
                sortBy={{
                    columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                    order: this.state.options.control.sortOrder || undefined,
                }}
                data={this.state.instantAwards}
                count={this.state.count}
                limit={this.state.options.control.limit}
                isSortable={true}
                onSortOrderChange={this.onSortOrderChange}
                onPageChange={this.onPageChange}
                isPaginationEnabled={true}
                currentPage={this.getCurrentPage()}
                isLoading={this.state.isLoading}
                renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.instantAwardTable.noData" })}
            />
        );
    }
}

export { InstantAwardTable };

import React, { Component } from "react";
import { SelectionBoard } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { Api } from "api/Api";
import { Loading } from "components/Loading/Loading";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

type SelectionBoardSelectOption = SelectOption<SelectionBoard>;

interface Props {
    clientId: string;
    value: SelectionBoard | null;
    onChange: (selectionBoard: SelectionBoard | null) => void;
    disabled?: boolean;
}

interface State {
    isEnabled: boolean;
    isLoading: boolean;
    selectionBoards: SelectionBoard[];
}

class SelectionBoardInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isEnabled: !!this.props.value,
            isLoading: true,
            selectionBoards: [],
        };
    }

    public componentDidMount(): void {
        this.refreshSelectionBoards();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.clientId !== prevProps.clientId) {
            this.refreshSelectionBoards();
        }
    }

    private refreshSelectionBoards() {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const selectionBoards = await Api.getClientSelectionBoards(this.props.clientId);
                    this.setState({ selectionBoards, isLoading: false });
                } catch (error) {
                    this.setState({ selectionBoards: [], isLoading: false });
                }
            },
        );
    }

    private readonly onEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                isEnabled: event.currentTarget.checked,
            },
            () => {
                this.props.onChange(this.state.isEnabled && this.state.selectionBoards.length > 0 ? this.state.selectionBoards[0] : null);
            },
        );
    };

    private readonly onSelectionBoardChange = (item: SelectionBoardSelectOption): void => {
        this.props.onChange(item.value);
    };

    private renderContent = () => {
        if (!this.state.isEnabled) {
            return null;
        }

        if (this.state.isLoading) {
            return <Loading />;
        }

        if (this.state.selectionBoards.length === 0) {
            return <ErrorMessage message={Intl.formatMessage({ id: "sharedComponent.inputs.selectionBoard.noAvailableSelectionBoard" })} />;
        }

        const options = this.state.selectionBoards.map(
            (selectionBoard): SelectionBoardSelectOption => {
                return {
                    id: selectionBoard.id,
                    value: selectionBoard,
                    label: selectionBoard.title,
                };
            },
        );
        const currentValue =
            options.find(sb => {
                return sb.id === this.props.value?.id;
            }) || options[0];

        return (
            <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.selectionBoard.board.label" })} padded>
                <Select
                    value={currentValue}
                    onChange={this.onSelectionBoardChange}
                    options={options}
                    emptyLabel={Intl.formatMessage({ id: "sharedComponent.inputs.selectionBoard.board.emptyLabel" })}
                    disabled={this.props.disabled}
                />
            </InputWrapper>
        );
    };

    public render(): React.ReactElement {
        return (
            <>
                <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.selectionBoard.enabled.label" })} padded icon="fa-map-signs">
                    <Checkbox checked={this.state.isEnabled} onChange={this.onEnabledChange} disabled={this.props.disabled} />
                </InputWrapper>
                {this.renderContent()}
            </>
        );
    }
}

export { SelectionBoardInput };

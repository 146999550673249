import React, { Component } from "react";
import { AssetType, AllowedUploadContentTypes, ClientProfileData_introductionAudio, ClientProfileData_introductionVideo } from "api/graphql/types";
import { FileInput } from "components/Inputs/FileInput/FileInput";
import { Intl } from "i18n/Intl";
import { NotificationProp, withNotification } from "./NotificationBar/NotificationContext";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { Slot } from "./Slot/Slot";
import { ApplicationState } from "reducers";
import { FilePreview } from "utils/FilePreview";
import { InputWrapper } from "./InputWrapper/InputWrapper";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "./DialogContainer/DialogsContainer";
import { ImageSrc } from "utils/ImageSrc";
import { isNil } from "lodash";
import { OverlayIcon } from "./OverlayIcon";

interface ComponentProps {
    type: AssetType;
    asset: ClientProfileData_introductionAudio | ClientProfileData_introductionVideo | null;
    onFileSelected: (file: File, onProgressChange: (progress: number) => void) => Promise<void> | void;
    deleteAsset: () => Promise<void> | void;
}

interface ReduxProps {
    allowedUploadContentTypes: AllowedUploadContentTypes | null;
}

type Props = ComponentProps & NotificationProp & DispatchProp & ReduxProps;

interface State {
    progress: number | null;
    url: string | null;
}

class UploadAssetFormComponent extends Component<Props, State> {
    public readonly state: State = {
        progress: null,
        url: null,
    };

    private onFileSelected = async (files: File[]) => {
        if (files.length < 1) {
            return;
        }

        const file: File = files[0];

        const asset: HTMLImageElement = new Image();
        let url: string = URL.createObjectURL(file);

        if (this.props.allowedUploadContentTypes?.audio.includes(file.type)) {
            url = ImageSrc.audioAsset;
        } else if (this.props.allowedUploadContentTypes?.video.includes(file.type)) {
            url = await new FilePreview(url).getVideoThumbnail();
        }

        asset.addEventListener("load", () => {
            this.setState(
                { url },
                async (): Promise<void> => {
                    await this.props.onFileSelected(file, (progress: number) => this.setState({ progress }));
                    this.setState({ progress: null });
                },
            );
        });
        asset.src = url;
    };

    private getThumbnail = (type: AssetType): string => {
        if (isNil(this.props.asset)) {
            return "";
        }

        switch (type) {
            case AssetType.introduction_audio:
                return ImageSrc.audioAsset;
            case AssetType.introduction_video:
            default:
                return this.props.asset.thumbnailUrl || "";
        }
    };

    private onDeleteClick = () => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.confirmUploadAssetDelete,
                assetType: this.props.type,
                onOkClick: this.props.deleteAsset,
            }),
        );
    };

    private getClassName = (): string => {
        if (isNil(this.state.progress)) {
            return "";
        }

        if (this.state.progress! >= 1 && this.state.progress! < 100) {
            return "upload-in-progress";
        }

        if (this.state.progress === 100) {
            return "upload-in-progress upload-success";
        }

        return "";
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="grid-x">
                <InputWrapper inputLabel={`${Intl.formatMessage({ id: `enum.assetType.${this.props.type}` })}:`}>
                    <FileInput
                        progress={null}
                        type={[this.props.type]}
                        onFileSelected={this.onFileSelected}
                        assetUrl={this.props.asset?.url}
                        originalFileName={this.props.asset?.originalFileName}
                        url={this.props.asset?.thumbnailUrl || this.getThumbnail(this.props.type)}
                        placeholder={
                            <Slot
                                message={Intl.formatMessage({ id: `page.clientProfile.introductionSection.introductionForm.${this.props.type}.placeholder` })}
                                onCloseClick={(): void => {}}
                                fileUpload
                            />
                        }
                        dialogTitle={Intl.formatMessage({ id: `enum.assetType.${this.props.type}` })}
                        disabled={!isNil(this.props.asset)}
                        renderContent={() => (
                            <Slot
                                message={Intl.formatMessage({ id: `page.clientProfile.introductionSection.introductionForm.${this.props.type}.placeholder` })}
                                url={this.getThumbnail(this.props.type)}
                                onCloseClick={this.onDeleteClick}
                                fileUpload
                                overlayIcon={<OverlayIcon assetType={this.props.type} />}
                                onPreviewClick={() => {}}
                            />
                        )}
                        previewEnabled={true}
                    />
                    <div>
                        <div className={`progress-bar progress-bar-success upload-progress ${this.getClassName()}`} style={{ width: `${this.state.progress}%` }} />
                    </div>
                </InputWrapper>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { allowedUploadContentTypes: state.settings.allowedUploadContentTypes };
};

const UploadAssetForm = withNotification(connect(mapStateToProps)(UploadAssetFormComponent));

export { UploadAssetForm };

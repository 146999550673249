import React from "react";
import { DispatchProp } from "react-redux";
import { ReCaptcha } from "react-recaptcha-v3";

import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Input } from "components/Inputs/Input/Input";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Alert } from "components/Alert/Alert";

import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { TestId } from "utils/TestId";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { Env } from "utils/Env";
import { Color } from "theme/Color";
import { ApiErrorCode } from "api/ApiError";
import { Redirect } from "react-router";
import { Path } from "utils/Path";
import { Button } from "components/Button/Button";
import { Title } from "components/Title";

type Props = {
    isLoggedIn: boolean;
    onRegistrationSucceed: (email: string) => void;
} & DispatchProp;

interface State {
    isLoading: boolean;
    name: string;
    email: string;
    legalAge: boolean;
    termsAccepted: boolean;
    clientToken: string;
    formErrors: {
        name: string | null;
        email: string | null;
    };
    validationEnabled: boolean;
}

class RegistrationFormComponent extends React.Component<Props, State> {
    private recaptchaRef: ReCaptcha | null = null;
    private nameRef: HTMLInputElement | null = null;
    private emailRef: HTMLInputElement | null = null;

    public state: Readonly<State> = {
        isLoading: false,
        name: "",
        email: "",
        legalAge: false,
        termsAccepted: false,
        clientToken: "",
        formErrors: {
            name: null,
            email: null,
        },
        validationEnabled: false,
    };

    public componentWillUnmount(): void {
        document.body.classList.add("hide-recaptcha-badge");
    }

    public componentDidMount(): void {
        document.body.classList.remove("hide-recaptcha-badge");
    }

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name: string = event.target.value;
        const nameError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateName(name)) : null;
        this.setState({ name, formErrors: { ...this.state.formErrors, name: nameError } });
    };

    private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.target.value;
        const emailError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateEmail(email)) : null;
        this.setState({ email, formErrors: { ...this.state.formErrors, email: emailError } });
    };

    private onLegalAgeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ legalAge: event.target.checked });
    };

    private onTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ termsAccepted: event.target.checked });
    };

    private verifyReCapchaCallback = (clientToken: string): void => {
        this.setState({ clientToken });
    };

    private refreshRecaptchaClientToken(): void {
        if (this.recaptchaRef) {
            this.recaptchaRef.execute();
        }
    }

    private onRegistrationSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { email, name, clientToken } = this.state;
        const formErrors = {
            email: IntlHelpers.getValidationError(Validator.validateEmail(email)),
            name: IntlHelpers.getValidationError(Validator.validateName(name)),
        };

        if (formErrors.email || formErrors.name) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.name && this.nameRef) {
                    this.nameRef.focus();
                } else if (!!formErrors.email && this.emailRef) {
                    this.emailRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.registration(email, name, clientToken);
                    this.props.onRegistrationSucceed(email);
                } catch (error) {
                    const message = error.message === ApiErrorCode.VALIDATION && clientToken === "" ? Intl.formatMessage({ id: "error.recaptcha" }) : IntlHelpers.getMessageFromError(error);
                    Alert.error({ title: message });
                    this.setState({ isLoading: false, clientToken: "" }, (): void => this.refreshRecaptchaClientToken());
                }
            },
        );
    };

    public render(): React.ReactElement<any> {
        if (this.props.isLoggedIn) {
            return <Redirect to={Path.dashboard} />;
        }

        const { formErrors, email, name, legalAge, termsAccepted, isLoading } = this.state;
        const registerButtonDisabled: boolean = isLoading || !!formErrors.email || !!formErrors.name || !email || !name || !legalAge || !termsAccepted;
        return (
            <form noValidate={true} onSubmit={this.onRegistrationSubmit}>
                <Title>{Intl.formatMessage({ id: "page.auth.registration.title" })}</Title>
                <div className="lead">{Intl.formatMessage({ id: "page.auth.registration.lead" })}</div>

                <InputWrapper id={TestId.authPage.registrationForm.nameInput} inputLabel={Intl.formatMessage({ id: "page.auth.registration.form.name.label" })} errorMessage={formErrors.name}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.nameRef = ref;
                        }}
                        hasError={!!formErrors.name}
                        placeholder={Intl.formatMessage({ id: "page.auth.registration.form.name.placeholder" })}
                        type="text"
                        value={name}
                        onChange={this.onNameChange}
                    />
                </InputWrapper>

                <InputWrapper id={TestId.authPage.registrationForm.emailInput} inputLabel={Intl.formatMessage({ id: "page.auth.registration.form.email.label" })} errorMessage={formErrors.email}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.emailRef = ref;
                        }}
                        hasError={!!formErrors.email}
                        placeholder={Intl.formatMessage({ id: "page.auth.registration.form.email.placeholder" })}
                        type="email"
                        value={email}
                        onChange={this.onEmailChange}
                    />
                </InputWrapper>
                <div className="large-offset">
                    <Checkbox
                        id={TestId.authPage.registrationForm.legalAge}
                        checked={legalAge}
                        onChange={this.onLegalAgeChange}
                        label={Intl.formatMessage({ id: "page.auth.registration.form.legalAge" })}
                    />
                    <Checkbox
                        id={TestId.authPage.registrationForm.termsAccepted}
                        onChange={this.onTermsAcceptedChange}
                        checked={termsAccepted}
                        label={IntlHelpers.asHtml(
                            { id: "page.auth.registration.form.termsAccepted" },
                            {
                                termsUrl: "/docs/felhasznalasi_feltetelek.pdf",
                                privacyUrl: "/docs/adatkezelesi_tajekoztato.pdf",
                                style: `color: ${Color.primary}; border-bottom: ${Color.primary} 2px solid;`,
                            },
                        )}
                    />

                    {Env.recaptchaSiteKey && (
                        <ReCaptcha
                            ref={(ref: ReCaptcha | null): void => {
                                this.recaptchaRef = ref;
                            }}
                            sitekey={Env.recaptchaSiteKey}
                            action="homepage"
                            verifyCallback={this.verifyReCapchaCallback}
                        />
                    )}
                </div>

                <div className="button-wrapper">
                    <Button type="submit" label={Intl.formatMessage({ id: "page.auth.registration.button" })} disabled={registerButtonDisabled} />
                </div>
            </form>
        );
    }
}

export const RegistrationForm: React.ComponentClass<Props> = RegistrationFormComponent;

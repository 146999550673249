import React from "react";
import { Link } from "react-router-dom";
import { Path } from "utils/Path";
import { Input } from "components/Inputs/Input/Input";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Intl } from "i18n/Intl";
import { Validator } from "utils/Validator";
import { Button } from "components/Button/Button";
import { TestId } from "utils/TestId";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { RouteComponentProps, withRouter } from "react-router";
import { Title } from "components/Title";

type Props = RouteComponentProps;

interface State {
    email: string;
    formErrors: {
        email: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ForgotPasswordPageComponent extends React.Component<Props, State> {
    private emailRef: HTMLInputElement | null = null;

    public state: State = {
        email: "",
        formErrors: {
            email: null,
        },
        validationEnabled: false,
        isLoading: false,
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { email } = this.state;
        const emailError: string | null = IntlHelpers.getValidationError(Validator.validateEmail(email));

        if (emailError) {
            this.setState({ formErrors: { email: emailError }, validationEnabled: true }, () => {
                if (this.emailRef) {
                    this.emailRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.forgotPassword(email);
                    Alert.success({
                        title: Intl.formatMessage({ id: "page.forgotPassword.forgotPasswordSucceed.message" }),
                        callback: (): void => {
                            this.props.history.push(Path.login);
                        },
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.target.value;
        const emailError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateEmail(email)) : null;
        this.setState({ email, formErrors: { email: emailError } });
    };

    public render(): JSX.Element {
        const { email, formErrors, isLoading } = this.state;
        const isSendButtonDisabled: boolean = !email || !!formErrors.email || isLoading;
        return (
            <main className="guest-page">
                <Title>{Intl.formatMessage({ id: "page.forgotPassword.title" })}</Title>
                <Tabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
                    <TabList>
                        <Tab>
                            <Link to={Path.forgotPassword}>{Intl.formatMessage({ id: "page.forgotPassword.title" })}</Link>
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <form noValidate={true} onSubmit={this.onSubmit}>
                            <div className="lead">{Intl.formatMessage({ id: "page.forgotPassword.lead" })}</div>
                            <InputWrapper id={TestId.forgotPassword.emailInput} errorMessage={formErrors.email} inputLabel={Intl.formatMessage({ id: "page.forgotPassword.form.email.label" })}>
                                <Input
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.emailRef = ref;
                                    }}
                                    autoComplete="email"
                                    placeholder={Intl.formatMessage({ id: "page.forgotPassword.form.email.placeholder" })}
                                    required={true}
                                    type="email"
                                    value={email}
                                    onChange={this.onChange}
                                    hasError={!!formErrors.email}
                                    tabIndex={1}
                                />
                            </InputWrapper>

                            <div className="button-wrapper">
                                <div className="grid-x">
                                    <div className="cell large-shrink">
                                        <Link to={Path.login}>{Intl.formatMessage({ id: "page.forgotPassword.login" })}</Link>
                                    </div>

                                    <div className="cell large-auto">
                                        <Button
                                            type="submit"
                                            disabled={isSendButtonDisabled}
                                            label={Intl.formatMessage({ id: "page.forgotPassword.form.button" })}
                                            ariaLabel={Intl.formatMessage({ id: "page.forgotPassword.form.button" })}
                                            tabIndex={2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>
            </main>
        );
    }
}

export const ForgotPasswordPage = withRouter(ForgotPasswordPageComponent);

// tslint:disable-next-line: no-default-export
export default ForgotPasswordPage;

import React, { Component } from "react";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Alert } from "components/Alert/Alert";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AgendaItem, AgendaItemInput, AgendaItemType, Account, AccountType, Agenda, SupportedClient, ContentClient } from "api/graphql/types";
import { Path } from "utils/Path";
import { AgendaItemForm } from "./AgendaItemForm";
import { PageType } from "utils/TypeUtils";
import { ApiTypes, ClientAdditionalFunctions } from "api/ApiTypes";
import { AccountSelectors } from "selectors/AccountSelectors";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";

interface RouteParams {
    agendaId?: string;
    agendaItemId?: string;
    clientExtId?: string;
    accountExtId?: string;
    supporterExtId?: string;
    supervisorId?: string;
}

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams>;

interface State {
    agendaItem: AgendaItem;
    agenda: Agenda | null;
    clientAdditionalFunctions: ClientAdditionalFunctions | null;
    isLoading: boolean;
}

class AgendaItemPageComponent extends Component<Props, State> {
    public static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !!props.match.params.agendaItemId,
            agendaItem: {
                __typename: "AgendaItem",
                id: "",
                content: null,
                type: AgendaItemType.Simple,
                title: "",
                client: (props.client as unknown) as ContentClient,
                flowchart: null,
                checklist: null,
                moodMeter: null,
                alert: null,
                award: null,
                instantAwards: [],
                timer: null,
                time: null,
                createdBy: {
                    id: props.account.id,
                    extId: props.account.extId,
                    name: "",
                    __typename: "Account",
                },
                isCancelled: null,
                image: null,
                audio: null,
                video: null,
                createdAt: null,
                updatedAt: null,
                disabledAt: null,
                notification: null,
                agendaItems: [],
                tags: [],
                publishedAt: null,
                referenceCount: 0,
                shareRequests: null,
                selectionBoard: null,
            },
            agenda: null,
            clientAdditionalFunctions: null,
        };
    };

    public readonly state: State = AgendaItemPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        this.refreshAgendaItem(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.agendaItemId !== nextProps.match.params.agendaItemId) {
            this.refreshAgendaItem(nextProps);
        }
    }

    private refreshClientAdditionalFunctions = async (): Promise<void> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;

        if (clientId) {
            try {
                const clientAdditionalFunctions: ClientAdditionalFunctions = await Api.getClientAdditionalFunctions(clientId);
                this.setState({ clientAdditionalFunctions });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                return;
            }
        }
    };

    private refreshAgendaItem = (props: Props): void => {
        const { agendaItemId, agendaId } = props.match.params;
        if (!agendaItemId) {
            this.setState(AgendaItemPageComponent.getInitialStateFromProps(props));
            this.refreshClientAdditionalFunctions();
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;

                    let agendaItem: AgendaItem = AgendaItemPageComponent.getInitialStateFromProps(this.props).agendaItem;
                    let clientAdditionalFunctions: ClientAdditionalFunctions | null = null;

                    if (clientId) {
                        const agendaItemWithClientData: { agendaItem: AgendaItem; clientAdditionalFunctions: ClientAdditionalFunctions } = await Api.getAgendaItemByIdWithClientAdditionalFunctions(
                            agendaItemId,
                            clientId,
                        );
                        agendaItem = agendaItemWithClientData.agendaItem;
                        clientAdditionalFunctions = agendaItemWithClientData.clientAdditionalFunctions;
                    } else {
                        agendaItem = await Api.getAgendaItemById(agendaItemId);
                    }

                    let agenda: Agenda | null = null;
                    if (agendaId) {
                        agenda = await Api.getAgendaById(agendaId);
                    }

                    this.setState({ agendaItem, agenda, clientAdditionalFunctions, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private onSubmit = async (agendaItem: AgendaItem, agendaId?: string): Promise<AgendaItem> => {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const agendaItemInput: AgendaItemInput = ApiTypes.agendaItemToAgendaItemInput(agendaItem);
        if (pageType === PageType.create) {
            const result = await Api.createAgendaItem(agendaItemInput);
            return result;
        } else if (pageType === PageType.edit) {
            const result = await Api.updateAgendaItem(agendaItem.id, agendaItemInput, agendaId);
            if (result.id !== this.state.agendaItem.id) {
                const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, !!result.publishedAt);
                const path: string | undefined = ContentPageUtils.getEditPath(result, pageContent, this.props.match.params.clientExtId);
                if (path) {
                    this.props.history.push(path);
                }
            }
            this.setState({ agendaItem: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    private onToggled = (): void => {
        const agendaItem = { ...this.state.agendaItem };
        agendaItem.disabledAt = agendaItem.disabledAt ? null : DateUtils.format(new Date(), DateFormat.default);
        this.setState({ agendaItem });
    };

    private onEditClick = (): void => {
        const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, !!this.state.agendaItem.publishedAt);
        const path: string | undefined = ContentPageUtils.getEditPath(this.state.agendaItem, pageContent, this.props.match.params.clientExtId, this.props.match.params.agendaId);
        if (path) {
            this.props.history.push(path);
        }
    };

    public render(): React.ReactElement<any> {
        if (this.props.match.params.clientExtId && this.props.account.accountType === AccountType.supporter && !this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        return (
            <AgendaItemForm
                myAccount={this.props.account}
                clientExtId={this.props.match.params.clientExtId}
                supporterExtId={this.props.match.params.supporterExtId}
                supervisorId={this.props.match.params.supervisorId}
                isLoading={this.state.isLoading || !this.state.agendaItem}
                pageContent={ContentPageUtils.getPageContent(this.props.match.path, !!this.state.agendaItem.publishedAt)}
                agenda={this.state.agenda}
                agendaItem={this.state.agendaItem}
                clientAdditionalFunctions={this.state.clientAdditionalFunctions}
                pageType={pageType}
                onSubmit={this.onSubmit}
                onEditClick={this.onEditClick}
                onToggled={this.onToggled}
                client={this.props.client}
                accountType={this.props.account.accountType}
                refreshAgendaItem={(): void => this.refreshAgendaItem(this.props)}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return { account: state.account!, client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId) };
};

export const AgendaItemPage = connect(mapStateToProps)(AgendaItemPageComponent);

// tslint:disable-next-line: no-default-export
export default AgendaItemPage;

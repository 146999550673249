import React, { PureComponent } from "react";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";

interface Props {
    currentPage: number;
    pagesCount: number;
    onPageChange: (newPage: number) => void;
}

class TableFooter extends PureComponent<Props> {
    private static readonly ELLIPSIS: string = "…";

    private onPageChange = (pageNum: number) => (): void => {
        this.props.onPageChange(pageNum);
    };

    /**
     * Calculating pages and ellipseses and rendering corresponding buttons
     */
    private getPages = (): Array<number | string> => {
        const { currentPage, pagesCount } = this.props;

        if (!this.props.currentPage || !this.props.pagesCount) {
            return [];
        }

        let pagesArray: number[] = [];
        let finalArray: Array<number | string> = [];

        if (currentPage && currentPage >= 1 && pagesCount && pagesCount >= 1) {
            finalArray = [1];
            if (pagesCount >= 8) {
                let startWithEllipsis = false;
                let endWithEllipsis = false;

                pagesArray.push(1);

                if (currentPage >= 5) {
                    startWithEllipsis = true;
                    pagesArray = [1, currentPage - 2, currentPage - 1];
                } else {
                    pagesArray = [1, 2, 3, 4, 5, 6];
                }

                pagesArray.push(currentPage);

                if (currentPage <= pagesCount - 4) {
                    endWithEllipsis = true;
                    pagesArray = [...pagesArray, currentPage + 1, currentPage + 2];
                } else {
                    pagesArray = [...pagesArray, pagesCount - 5, pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1];
                }

                pagesArray.push(pagesCount);

                finalArray = pagesArray.filter((value: number, index: number) => pagesArray.indexOf(value) === index).sort((a: number, b: number) => a - b);

                if (startWithEllipsis) {
                    const firstPageNum: number | string = finalArray[0];
                    finalArray.shift();
                    finalArray.unshift(firstPageNum, TableFooter.ELLIPSIS);
                }

                if (endWithEllipsis) {
                    const lastPageNum: number | string = finalArray[finalArray.length - 1];
                    finalArray.pop();
                    finalArray.push(TableFooter.ELLIPSIS, lastPageNum);
                }
            } else if (pagesCount >= 2) {
                for (let i = 2; i <= pagesCount; i++) {
                    finalArray.push(i);
                }
            }
        }
        return finalArray;
    };

    public render(): React.ReactElement<any> {
        const { currentPage } = this.props;
        const pageNumbers = this.getPages();
        return (
            <div className="table-footer">
                <div className="btn-group pager">
                    <Button
                        icon={{ name: "fa-chevron-left" }}
                        className="btn-white"
                        title={Intl.formatMessage({ id: "common.previousPage" })}
                        onClick={this.onPageChange(this.props.currentPage - 1)}
                        disabled={this.props.currentPage - 1 === 0}
                    />
                    {pageNumbers.map(
                        (pageNumber: string | number, index: number): JSX.Element => {
                            const parsedPageNum = String(pageNumber);
                            const isCurrentPageActive: boolean = !!currentPage && String(currentPage) === String(pageNumber);
                            const key = `${parsedPageNum}-${index}`;

                            return parsedPageNum === TableFooter.ELLIPSIS ? (
                                <span key={key} className="hidden">
                                    {TableFooter.ELLIPSIS}
                                </span>
                            ) : (
                                <Button key={key} className={`btn-white ${isCurrentPageActive ? "active" : ""}`} label={parsedPageNum} onClick={this.onPageChange(Number(pageNumber))} />
                            );
                        },
                    )}
                    <Button
                        icon={{ name: "fa-chevron-right" }}
                        className="btn-white"
                        title={Intl.formatMessage({ id: "common.nextPage" })}
                        onClick={this.onPageChange(this.props.currentPage + 1)}
                        disabled={this.props.currentPage === this.props.pagesCount}
                    />
                </div>
            </div>
        );
    }
}

export { TableFooter };

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";
import { ItemAwardType } from "api/graphql/types";
import { ObjectUtils } from "utils/ObjectUtils";

type AwardSelectOption = SelectOption<ItemAwardType>;

class AwardSelectOptions {
    public static readonly type: Array<SelectOption<ItemAwardType>> = [
        ...ObjectUtils.enumAsArray<ItemAwardType>(ItemAwardType).map(
            (type: ItemAwardType): SelectOption<ItemAwardType> => {
                return {
                    id: type,
                    label: Intl.formatMessage({ id: `enum.itemAwardType.${type}` }),
                    value: type,
                };
            },
        ),
    ];
}

export type { AwardSelectOption };
export { AwardSelectOptions };

import React from "react";
import { Avatar } from "components/Avatar";
import { Link } from "react-router-dom";

import "./Dropdown.scss";

export interface DropdownItemDiv {
    label: string;
    onClick: () => void | Promise<void>;
}

export interface DropdownItemLink {
    label: string;
    path: string;
}

export type DropdownItem = DropdownItemDiv | DropdownItemLink;

interface Props {
    id?: string;
    label: string | JSX.Element;
    info?: string | JSX.Element;
    items?: DropdownItem[];
    renderItem?: (item: DropdownItem) => React.ReactElement<any>;
    imageUrl?: string;
    addElement?: boolean;
    defaultOpened?: boolean;
}

interface State {
    isOpened: boolean;
}
class Dropdown extends React.PureComponent<Props, State> {
    private containerRef: HTMLDivElement | null = null;

    private static isLink(dropdownItem: DropdownItemDiv | DropdownItemLink): dropdownItem is DropdownItemLink {
        return !!(dropdownItem as any).path;
    }

    public readonly state: State = {
        isOpened: this.props.defaultOpened || false,
    };

    public componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    private onClickOutside = (event: MouseEvent): void => {
        if (this.containerRef && !this.containerRef.contains(event.target as Node) && this.state.isOpened) {
            this.onOpenChange();
        }
    };

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    private renderDropdownItem = (item: DropdownItem): React.ReactElement<any> => {
        if (Dropdown.isLink(item)) {
            return (
                <Link
                    key={item.label}
                    className="dropdown-item"
                    to={item.path}
                    onClick={(): void => {
                        this.setState({ isOpened: false });
                    }}
                >
                    {item.label}
                </Link>
            );
        }

        const onClick = () => {
            this.setState({ isOpened: false });
            item.onClick();
        };

        return (
            <div key={item.label} className="dropdown-item" onClick={onClick}>
                {item.label}
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                id={this.props.id}
                className={`dropdown${this.props.addElement ? " add-element" : ""}${this.state.isOpened ? " show" : ""}`}
            >
                <button className="btn btn-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.onOpenChange}>
                    {this.props.imageUrl && <Avatar url={this.props.imageUrl} />}
                    {this.props.addElement && <span className="fa fa-plus"></span>}
                    <span className="name">
                        <span>{this.props.label}</span>
                        {this.props.info && <span className="sv-name">{this.props.info}</span>}
                    </span>
                </button>

                <div className={`dropdown-menu${this.state.isOpened ? " show" : ""}`} x-placement="bottom-start">
                    {this.props.items && this.props.items.map(this.renderDropdownItem)}
                </div>
            </div>
        );
    }
}

export { Dropdown };

import { SortOrder, AppStatItemType, AppEventLogName } from "api/graphql/types";
import { UrlQueryParser, ParsedParameter } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { QueryParserTypeUtils, EnumType } from "utils/TypeUtils";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ClientProfile } from "api/ApiTypes";

enum QueryParameter {
    sortField = "sortField",
    sortOrder = "sortOrder",
    search = "search",
    limit = "limit",
    page = "page",
    dateTimeFrom = "dateTimeFrom",
    dateTimeTo = "dateTimeTo",
    appStatItemType = "appStatItemType",
    appEventLogName = "appEventLogName",
    authAccountExtId = "authAccountExtId",
    clientExtId = "clientExtId",
    clientProfile = "clientProfile",
}

export interface EventLogQueryParameter<SortFieldType> {
    sortField?: SortFieldType | null;
    sortOrder?: SortOrder | null;
    search?: string | null;
    limit?: number | null;
    page?: number | null;
    dateTimeFrom?: Date | null;
    dateTimeTo?: Date | null;
    appStatItemType?: AppStatItemType | null;
    appEventLogName?: AppEventLogName | null;
    authAccountExtId?: string | null;
    clientExtId?: string | null;
    clientProfile?: ClientProfile | null;
}

class EventLogUrlQueryParser<SortFieldType> extends UrlQueryParser<EventLogQueryParameter<SortFieldType>> {
    public constructor(protected sortFieldEnum: EnumType, protected translationBaseKey: string) {
        super();
    }

    public translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.eventLogQueryParameter.${key}` });
    }

    public getUrlQuery(options: EventLogQueryParameter<SortFieldType>): string {
        const sortField: SortFieldType | null = options.sortField || null;
        const sortOrder: SortOrder | null = options.sortOrder || null;
        const search: string | null = options.search || null;
        const limit: number | null = options.limit || null;
        const page: number | null = options.page || null;
        const dateTimeFrom: Date | null = options.dateTimeFrom || null;
        const dateTimeTo: Date | null = options.dateTimeTo || null;
        const appStatItemType: AppStatItemType | null = options.appStatItemType || null;
        const appEventLogName: AppEventLogName | null = options.appEventLogName || null;
        const authAccountExtId: string | null = options.authAccountExtId || null;
        const clientExtId: string | null = options.clientExtId || null;
        const clientProfile: ClientProfile | null = options.clientProfile || null;

        const paramArray: string[] = [];

        if (sortField) {
            paramArray.push(`${this.translateListQuery(QueryParameter.sortField)}=${Intl.formatMessage({ id: `${this.translationBaseKey}.sortField.${sortField}` })}`);
        }
        if (sortOrder) {
            paramArray.push(`${this.translateListQuery(QueryParameter.sortOrder)}=${Intl.formatMessage({ id: `enum.sortOrder.${sortOrder}` })}`);
        }
        if (search) {
            paramArray.push(`${this.translateListQuery(QueryParameter.search)}=${search}`);
        }
        if (limit) {
            paramArray.push(`${this.translateListQuery(QueryParameter.limit)}=${limit}`);
        }
        if (page) {
            paramArray.push(`${this.translateListQuery(QueryParameter.page)}=${page}`);
        }
        if (dateTimeFrom) {
            paramArray.push(`${this.translateListQuery(QueryParameter.dateTimeFrom)}=${DateUtils.format(dateTimeFrom, DateFormat.yyyymmddhhmmNoSpace)}`);
        }
        if (dateTimeTo) {
            paramArray.push(`${this.translateListQuery(QueryParameter.dateTimeTo)}=${DateUtils.format(dateTimeTo, DateFormat.yyyymmddhhmmNoSpace)}`);
        }
        if (appStatItemType) {
            paramArray.push(`${this.translateListQuery(QueryParameter.appStatItemType)}=${Intl.formatMessage({ id: `enum.appStatItemTypeQueryParam.${appStatItemType}` })}`);
        }
        if (appEventLogName) {
            paramArray.push(`${this.translateListQuery(QueryParameter.appEventLogName)}=${Intl.formatMessage({ id: `enum.appEventLogNameQueryParam.${appEventLogName}` })}`);
        }
        if (authAccountExtId) {
            paramArray.push(`${this.translateListQuery(QueryParameter.authAccountExtId)}=${authAccountExtId}`);
        }
        if (clientExtId) {
            paramArray.push(`${this.translateListQuery(QueryParameter.clientExtId)}=${clientExtId}`);
        }
        if (clientProfile) {
            paramArray.push(`${this.translateListQuery(QueryParameter.clientProfile)}=${clientProfile}`);
        }

        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): EventLogQueryParameter<SortFieldType> {
        const keys = {
            [QueryParameter.sortField]: this.translateListQuery(QueryParameter.sortField),
            [QueryParameter.sortOrder]: this.translateListQuery(QueryParameter.sortOrder),
            [QueryParameter.search]: this.translateListQuery(QueryParameter.search),
            [QueryParameter.limit]: this.translateListQuery(QueryParameter.limit),
            [QueryParameter.page]: this.translateListQuery(QueryParameter.page),
            [QueryParameter.dateTimeFrom]: this.translateListQuery(QueryParameter.dateTimeFrom),
            [QueryParameter.dateTimeTo]: this.translateListQuery(QueryParameter.dateTimeTo),
            [QueryParameter.appStatItemType]: this.translateListQuery(QueryParameter.appStatItemType),
            [QueryParameter.appEventLogName]: this.translateListQuery(QueryParameter.appEventLogName),
            [QueryParameter.authAccountExtId]: this.translateListQuery(QueryParameter.authAccountExtId),
            [QueryParameter.clientExtId]: this.translateListQuery(QueryParameter.clientExtId),
            [QueryParameter.clientProfile]: this.translateListQuery(QueryParameter.clientProfile),
        };

        const sortField: SortFieldType | undefined = IntlHelpers.fromI18nToEnum<SortFieldType>(this.sortFieldEnum, parsedParams[keys.sortField], `${this.translationBaseKey}.sortField`);
        const sortOrder: SortOrder | null | undefined = IntlHelpers.fromI18nToEnum<SortOrder>(SortOrder, parsedParams[keys.sortOrder], "enum.sortOrder");
        const search: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.search]);
        const limit: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.limit]);
        const page: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.page]);
        const dateTimeFrom: Date | undefined = QueryParserTypeUtils.toDate(parsedParams[keys.dateTimeFrom]);
        const dateTimeTo: Date | undefined = QueryParserTypeUtils.toDate(parsedParams[keys.dateTimeTo]);
        const appStatItemType: AppStatItemType | null | undefined = IntlHelpers.fromI18nToEnum<AppStatItemType>(AppStatItemType, parsedParams[keys.appStatItemType], "enum.appStatItemTypeQueryParam");
        const appEventLogName: AppEventLogName | null | undefined = IntlHelpers.fromI18nToEnum<AppEventLogName>(AppEventLogName, parsedParams[keys.appEventLogName], "enum.appEventLogNameQueryParam");
        const authAccountExtId: string | null | undefined = QueryParserTypeUtils.toString(parsedParams[keys.authAccountExtId]);
        const clientExtId: string | null | undefined = QueryParserTypeUtils.toString(parsedParams[keys.clientExtId]);
        const clientProfile: ClientProfile | null | undefined = IntlHelpers.fromI18nToEnum<ClientProfile>(ClientProfile, parsedParams[keys.clientProfile], "enum.clientProfile");

        return { sortField, sortOrder, search, limit, page, dateTimeFrom, dateTimeTo, appStatItemType, appEventLogName, authAccountExtId, clientExtId, clientProfile };
    }
}

export { EventLogUrlQueryParser };

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Button } from "components/Button/Button";
import { Account } from "api/graphql/types";
import { AccountActions } from "actions/AccountActions";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & NotificationProp;

interface State {
    isEditable: boolean;
    name: string;
    formErrors: {
        name: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ProfileNameFormComponent extends Component<Props, State> {
    private nameRef: HTMLInputElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            name: this.props.account.name!,
            formErrors: {
                name: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name: string = event.target.value;
        const nameError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateName(name)) : null;
        this.setState({ name, formErrors: { ...this.state.formErrors, name: nameError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { name } = this.state;
        const formErrors = {
            name: IntlHelpers.getValidationError(Validator.validateName(name)),
        };

        if (formErrors.name) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.name && this.nameRef) {
                    this.nameRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.updateName(name);
                    this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, name }));
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.profile.personalData.nameForm.nameChangeSucceed" }) });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.success, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, name, formErrors, isLoading } = this.state;
        return (
            <form noValidate={true} onSubmit={this.onSubmit} id={TestId.profilePage.nameForm}>
                <InputWrapper id={TestId.profilePage.nameInput} inputLabel={Intl.formatMessage({ id: "page.profile.personalData.nameForm.name.label" })} errorMessage={formErrors.name}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.nameRef = ref;
                        }}
                        hasError={!!formErrors.name}
                        placeholder={Intl.formatMessage({ id: "page.profile.personalData.nameForm.name.placeholder" })}
                        type="name"
                        value={name}
                        onChange={this.onNameChange}
                        disabled={!isEditable}
                    />
                </InputWrapper>

                {isEditable ? (
                    <div className="action-button-container">
                        <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            type="submit"
                            disabled={isLoading || !!formErrors.name || name === this.props.account.name}
                        />
                    </div>
                ) : (
                    <div className="edit-button">
                        <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                    </div>
                )}
                <Prompt when={name !== this.props.account.name} />
            </form>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account!,
    };
};

export const ProfileNameForm = withNotification(connect(mapStateToProps)(ProfileNameFormComponent));

import React, { Component } from "react";
import { LibraryContentShareRequest, Agenda, AgendaItem, Flowchart, FlowchartItem, RequestStatus, AccountType, LibraryContentShareRequestWithData } from "api/graphql/types";
import { Section } from "components/Section";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { ShareRequestBottomBar } from "../ShareRequestBottomBar";
import { ApiTypes } from "api/ApiTypes";
import { Formatter } from "utils/Formatter";
import { Intl } from "i18n/Intl";
import { uniqBy } from "lodash";

interface ReduxProps {
    accountId: string;
    accountType: AccountType;
}

interface ComponentProps {
    content: Agenda | AgendaItem | Flowchart | FlowchartItem;
    shareRequest?: LibraryContentShareRequestWithData;
}

type Props = ReduxProps & ComponentProps & DispatchProp;

class LibraryContentShareRequestSectionComponent extends Component<Props> {
    private renderShareRequest(shareRequest: LibraryContentShareRequest) {
        if (!shareRequest.sharedWithAccount) {
            return null;
        }

        if (shareRequest.status === RequestStatus.accepted) {
            return (
                <div key={shareRequest.id} className="grid-x">
                    <div className="cell shrink pr-25">
                        <span className="fa fa-share-alt clr-blue fs-16 lh-20" />
                    </div>

                    <div className="cell auto">
                        <p className="mb-30">
                            {shareRequest.sharedWithAccount.email} - {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.accepted" })}
                        </p>
                    </div>
                </div>
            );
        }

        if (shareRequest.status === RequestStatus.rejected) {
            return (
                <div key={shareRequest.id} className="grid-x">
                    <div className="cell shrink pr-25">
                        <span className="fa fa-ban clr-error fs-16 lh-20" />
                    </div>

                    <div className="cell auto">
                        <p className="mb-30">
                            {shareRequest.sharedWithAccount.email} - {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.rejected" })}
                        </p>
                    </div>
                </div>
            );
        }
        return (
            <div key={shareRequest.id} className="grid-x">
                <div className="cell shrink pr-25">
                    <span className="fa fa-clock clr-blue fs-16 lh-20" />
                </div>

                <div className="cell auto">
                    <p className="mb-30">
                        {shareRequest.sharedWithAccount.email} - {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.pending" })}
                    </p>
                </div>
            </div>
        );
    }

    public render(): React.ReactElement | null {
        const shareRequest: LibraryContentShareRequest[] = this.props.content.shareRequests || [];
        const isPublished = shareRequest.some(sr => !sr.sharedWithAccount && sr.status === RequestStatus.accepted);
        const customShares = uniqBy(
            [...shareRequest, this.props.shareRequest].filter((sr): sr is LibraryContentShareRequest => !!sr && !!sr.sharedWithAccount),
            "id",
        );
        const shareForMe = shareRequest.find(sr => sr.sharedWithAccount?.id === this.props.accountId);
        const shareForPublish = shareRequest.find(sr => !sr.sharedWithAccount && sr.status === RequestStatus.pending);
        const currentShare = shareForPublish || shareForMe;
        const searchListType = ApiTypes.getSearchListTypeByContent(this.props.content);
        const canHandleShare = (shareForPublish && this.props.accountType === AccountType.supervisor) || !!shareForMe || this.props.shareRequest?.sharedWithAccount?.id === this.props.accountId;
        return (
            <>
                <>
                    {(this.props.content.publishedAt || isPublished || shareForPublish || customShares.length > 0) && (
                        <Section label={Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.shareStatus" })}>
                            {(this.props.content.publishedAt || isPublished || shareForPublish) && (
                                <div className="grid-x">
                                    <div className="cell medium-3 pr-25 clr-blue fs-12 fw-700 lh-20 text-uppercase">
                                        {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.sharedContent" })}
                                    </div>

                                    <div className="cell shrink pr-25">
                                        <span className="fa fa-share-alt clr-blue fs-16 lh-20" />
                                    </div>

                                    <div className="cell auto">
                                        <p className="mb-30">{Intl.formatMessage({ id: `common.${shareForPublish ? "inProgress" : "accepted"}` })}</p>
                                    </div>
                                </div>
                            )}
                            {customShares.length > 0 && (
                                <div className="grid-x">
                                    <div className="cell medium-3 pr-25 clr-blue fs-12 fw-700 lh-20 text-uppercase">
                                        {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.custom" })}
                                    </div>

                                    <div className="cell auto">{customShares.map(this.renderShareRequest)}</div>
                                </div>
                            )}
                        </Section>
                    )}

                    <Section label={Intl.formatMessage({ id: `sharedComponent.libraryContentShareRequestSection.${this.props.accountType === AccountType.supervisor ? "shareSource" : "source"}` })}>
                        {this.props.content.createdBy?.extId && this.props.accountType === AccountType.supervisor && (
                            <div className="grid-x">
                                <div className="cell medium-3 pr-25 clr-blue fs-12 fw-700 lh-20 text-uppercase">
                                    {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.createdByExtId" })}
                                </div>

                                <div className="cell auto">
                                    <p className="mb-30">{Formatter.formatExtId(this.props.content.createdBy.extId)}</p>
                                </div>
                            </div>
                        )}
                        <div className="grid-x">
                            <div className="cell medium-3 pr-25 clr-blue fs-12 fw-700 lh-20 text-uppercase">
                                {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.createdAt" })}
                            </div>

                            <div className="cell auto">
                                <p className="mb-30">{DateUtils.format(new Date(this.props.content.createdAt), DateFormat.yyyymmdd)}</p>
                            </div>
                        </div>
                        {this.props.accountType === AccountType.supervisor && shareForPublish && shareForPublish.comment && (
                            <div className="grid-x">
                                <div className="cell medium-3 pr-25 clr-blue fs-12 fw-700 lh-20 text-uppercase">
                                    {Intl.formatMessage({ id: "sharedComponent.libraryContentShareRequestSection.comment" })}
                                </div>

                                <div className="cell auto">
                                    <p className="mb-30">{shareForPublish.comment}</p>
                                </div>
                            </div>
                        )}
                    </Section>

                    {canHandleShare && searchListType && (this.props.shareRequest || currentShare) && (
                        <ShareRequestBottomBar searchListType={searchListType} shareRequest={this.props.shareRequest! || currentShare} />
                    )}
                </>
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountId: state.account!.id, accountType: state.account!.accountType };
};

export const LibraryContentShareRequestSection = connect(mapStateToProps)(LibraryContentShareRequestSectionComponent);

import React, { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Account, AssetContent, AssetType, SearchListType } from "api/graphql/types";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { DragContainer } from "../_shared/Draggables/DragContainer";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Path } from "utils/Path";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { AssetInput } from "../_shared/Draggables/Input/AssetInput";
import { Button } from "components/Button/Button";
import { BottomBar } from "components/BottomBar";
import isNil from "lodash/isNil";

interface RouteParams {
    assetItemId?: string;
}

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams>;

interface State {
    isLoading: boolean;
    asset: AssetContent | null;
    changedAsset: AssetContent | null;
}

class ReplaceAssetItemPageComponent extends Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            asset: null,
            changedAsset: null,
        };
    }
    componentDidMount(): void {
        this.fetchAssets();
    }

    private readonly fetchAssets = async () => {
        const assetId = this.props.match.params.assetItemId;
        try {
            const asset: AssetContent = await Api.getMyAssetById(assetId || "");
            this.setState({
                isLoading: false,
                asset,
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private readonly onAssetChange = (assets: AssetContent[]): void => {
        const assetContent: AssetContent | null = assets.length > 0 ? assets[0] : null;
        if (this.state.asset) {
            this.setState({ changedAsset: assetContent });
        }
    };

    private readonly onCancelClick = () => {
        this.props.history.push(`${Path.publicContentLibrary}?tipus=mediaelemek`);
    };

    private readonly onSaveClick = async () => {
        if (this.state.asset && this.state.changedAsset) {
            try {
                await Api.replaceAsset({ assetId: this.state.asset.id, replacementAssetId: this.state.changedAsset.id });
                Alert.success({ title: Intl.formatMessage({ id: "page.replaceAssetItemPage.messages.success" }) });
                this.props.history.push(`${Path.publicContentLibrary}?tipus=mediaelemek`);
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            }
        } else {
            Alert.error({ title: Intl.formatMessage({ id: "page.replaceAssetItemPage.messages.noAsset" }) });
        }
    };

    private readonly renderContent = (asset: AssetContent, changedAsset: AssetContent | null): React.ReactElement<any> => {
        return (
            <>
                <p className="mt-4">{Intl.formatMessage({ id: "page.replaceAssetItemPage.description" })}</p>
                <div className="grid-x grid-padding-x">
                    <div className="cell medium-6">
                        <div className="card">
                            <div className="input-wrapper grid-x">
                                <span className="input-label cell">
                                    <span>{Intl.formatMessage({ id: "page.replaceAssetItemPage.swappableItem.title" })}:</span>
                                </span>

                                <span className="cell input-content">
                                    <div className="uploaded-image mt-2">
                                        <div className="uploaded-image-contaier">
                                            <Image src={asset.thumbnailUrl} fallback={asset.assetType === AssetType.image ? ImageSrc.asset : ImageSrc.audioAsset} />
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="cell medium-6">
                        <div className="card">
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.replaceAssetItemPage.newItem.title" })}>
                                <AssetInput
                                    droppableId={asset.assetType}
                                    assetType={asset.assetType}
                                    value={changedAsset ? [changedAsset] : []}
                                    onChange={(assets: AssetContent[]) => this.onAssetChange(assets)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={false}
                                    slotMessageOverride={Intl.formatMessage({ id: "page.replaceAssetItemPage.newItem.slotLabel" })}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                </div>
                <BottomBar isVisible={!isNil(this.state.changedAsset)}>
                    <div className="cell medium-6 text-right">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={isNil(this.state.changedAsset)} onClick={() => this.onCancelClick()} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button label={Intl.formatMessage({ id: "common.save" })} disabled={isNil(this.state.changedAsset)} onClick={() => this.onSaveClick()} />
                    </div>
                </BottomBar>
            </>
        );
    };

    public render(): React.ReactElement<any> {
        const { asset, isLoading, changedAsset } = this.state;
        const dragIds: string[] = [];
        if (!this.props.match.params.assetItemId || (!isLoading && !asset)) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={`${asset?.title} - ${Intl.formatMessage({ id: "page.replaceAssetItemPage.title" })}`} hasSideBar={true}>
                <DragContainer createdById={this.props.account.id} searchListTypes={[SearchListType.asset]} dragIds={dragIds} assetTypes={[AssetType.image, AssetType.video, AssetType.audio]}>
                    <div className="left-side">{isLoading ? <Loading type={LoadingType.layer} /> : asset ? this.renderContent(asset, changedAsset) : null}</div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

const ReplaceAssetItemPage = connect(mapStateToProps)(ReplaceAssetItemPageComponent);

export { ReplaceAssetItemPage };

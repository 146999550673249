import { AnyContentType, AnyContentListType } from "api/ApiTypes";
import {
    Account,
    AgendaContent,
    AgendaItemContent,
    FlowchartContent,
    FlowchartItemContent,
    AgendaListItem,
    AgendaItemListItem,
    FlowchartListItem,
    FlowchartItemListItem,
    CalendarAgenda,
    Agenda,
    AgendaItem,
    Flowchart,
    FlowchartItem,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result as EverydaySituationDirectory,
    SupportedClient,
    AssetListItem,
    Asset,
    AssetContent,
} from "api/graphql/types";
import { PageType } from "utils/TypeUtils";
import { AppPath, Path } from "utils/Path";
import { Log } from "utils/Log";
import { Formatter } from "utils/Formatter";
import { Intl } from "i18n/Intl";

enum PageContent {
    own = "own",
    public = "public",
    client = "client",
    clientDetail = "clientDetail",
    supporterDetail = "supporterDetail",
    supervisorDetail = "supervisorDetail",
    shareRequest = "shareRequest",
}

class ContentPageUtils {
    public static getPageType(path: string): PageType {
        switch (path) {
            case AppPath.createAgenda:
            case AppPath.createAgendaItem:
            case AppPath.createFlowchart:
            case AppPath.createFlowchartItem:
            case AppPath.createClientAgenda:
            case AppPath.createClientAgendaItem:
            case AppPath.createClientFlowchart:
            case AppPath.createClientFlowchartItem:
            case AppPath.createFaq:
            case AppPath.createClientInstantAward:
            case AppPath.createClientAward:
            case AppPath.createSelectionBoard:
            case AppPath.createEducationSubModule:
                return PageType.create;

            case AppPath.editAgenda:
            case AppPath.editAgendaItem:
            case AppPath.editAgendaAgendaItem:
            case AppPath.editFlowchart:
            case AppPath.editFlowchartItem:
            case AppPath.editClientAgenda:
            case AppPath.editClientAgendaItem:
            case AppPath.editClientAgendaAgendaItem:
            case AppPath.editClientFlowchart:
            case AppPath.editClientFlowchartItem:
            case AppPath.editAsset:
            case AppPath.editFaq:
            case AppPath.editClientInstantAward:
            case AppPath.editClientAward:
            case AppPath.editSelectionBoard:
            case AppPath.editEducationSubModule:
                return PageType.edit;

            case AppPath.viewAgenda:
            case AppPath.viewAgendaItem:
            case AppPath.viewAgendaAgendaItem:
            case AppPath.viewFlowchart:
            case AppPath.viewFlowchartItem:
            case AppPath.viewClientAgenda:
            case AppPath.viewClientAgendaItem:
            case AppPath.viewClientAgendaAgendaItem:
            case AppPath.viewClientFlowchart:
            case AppPath.viewClientFlowchartItem:
            case AppPath.viewClientDetailAgenda:
            case AppPath.viewClientDetailAgendaItem:
            case AppPath.viewClientDetailFlowchart:
            case AppPath.viewClientDetailFlowchartItem:
            case AppPath.viewSupporterDetailAgenda:
            case AppPath.viewSupporterDetailAgendaItem:
            case AppPath.viewSupporterDetailFlowchart:
            case AppPath.viewSupporterDetailFlowchartItem:
            case AppPath.viewSupporterDetailAsset:
            case AppPath.viewSupervisorDetailAgenda:
            case AppPath.viewSupervisorDetailAgendaItem:
            case AppPath.viewSupervisorDetailFlowchart:
            case AppPath.viewSupervisorDetailFlowchartItem:
            case AppPath.viewSupervisorDetailAsset:
            case AppPath.viewAsset:
            case AppPath.viewAgendaShareRequest:
            case AppPath.viewAgendaItemShareRequest:
            case AppPath.viewFlowchartShareRequest:
            case AppPath.viewFlowchartItemShareRequest:
            case AppPath.viewSelectionBoard:
            case AppPath.viewClientSelectionBoard:
                return PageType.view;
            default:
                Log.debug("Unknown path", path, "returning PageType.view");
                return PageType.view;
        }
    }

    public static getPageContent(path: string, isPublic: boolean): PageContent {
        switch (path) {
            case AppPath.createAgenda:
            case AppPath.createAgendaItem:
            case AppPath.createFlowchart:
            case AppPath.createFlowchartItem:
            case AppPath.editAgenda:
            case AppPath.editAgendaItem:
            case AppPath.editFlowchart:
            case AppPath.editFlowchartItem:
            case AppPath.viewAgenda:
            case AppPath.viewAgendaItem:
            case AppPath.viewAgendaAgendaItem:
            case AppPath.editAgendaAgendaItem:
            case AppPath.viewFlowchart:
            case AppPath.viewFlowchartItem:
            case AppPath.viewAsset:
                return isPublic ? PageContent.public : PageContent.own;

            case AppPath.createClientAgenda:
            case AppPath.createClientAgendaItem:
            case AppPath.createClientFlowchart:
            case AppPath.createClientFlowchartItem:
            case AppPath.editClientAgenda:
            case AppPath.editClientAgendaItem:
            case AppPath.editClientFlowchart:
            case AppPath.editClientFlowchartItem:
            case AppPath.viewClientAgenda:
            case AppPath.viewClientAgendaItem:
            case AppPath.viewClientFlowchart:
            case AppPath.viewClientFlowchartItem:
            case AppPath.viewClientAgendaAgendaItem:
            case AppPath.editClientAgendaAgendaItem:
                return PageContent.client;

            case AppPath.viewClientDetailAgenda:
            case AppPath.viewClientDetailAgendaItem:
            case AppPath.viewClientDetailFlowchart:
            case AppPath.viewClientDetailFlowchartItem:
                return PageContent.clientDetail;

            case AppPath.viewSupporterDetailAgenda:
            case AppPath.viewSupporterDetailAgendaItem:
            case AppPath.viewSupporterDetailFlowchart:
            case AppPath.viewSupporterDetailFlowchartItem:
            case AppPath.viewSupporterDetailAsset:
                return PageContent.supporterDetail;

            case AppPath.viewSupervisorDetailAgenda:
            case AppPath.viewSupervisorDetailAgendaItem:
            case AppPath.viewSupervisorDetailFlowchart:
            case AppPath.viewSupervisorDetailFlowchartItem:
            case AppPath.viewSupervisorDetailAsset:
                return PageContent.supervisorDetail;

            case AppPath.viewAgendaShareRequest:
            case AppPath.viewAgendaItemShareRequest:
            case AppPath.viewFlowchartShareRequest:
            case AppPath.viewFlowchartItemShareRequest:
                return PageContent.shareRequest;
            default:
                return PageContent.own;
        }
    }

    private static getAssetViewPath(content: AssetListItem | AssetContent | Asset, pageContent: PageContent, supporterExtId?: string | null, supervisorId?: string | null): string | undefined {
        switch (pageContent) {
            case PageContent.own:
            case PageContent.public:
                return Path.viewAsset(content.id);
            case PageContent.supporterDetail:
                if (!supporterExtId) {
                    Log.warning("getAssetViewPath(): PageContent.supporterDetail - supporterExtId is:", supporterExtId);
                    return undefined;
                }
                return Path.viewSupporterDetailAsset(supporterExtId, content.id);
            case PageContent.supervisorDetail:
                if (!supervisorId) {
                    Log.warning("getAssetViewPath(): PageContent.supervisorDetail - supervisorId is:", supervisorId);
                    return undefined;
                }
                return Path.viewSupervisorDetailAsset(supervisorId, content.id);
            default:
                Log.warning("getAssetViewPath(): default");
        }
        return undefined;
    }

    private static getAgendaViewPath(
        content: AgendaContent | AgendaListItem | CalendarAgenda,
        pageContent: PageContent,
        clientExtId?: string | null,
        supporterExtId?: string | null,
        supervisorId?: string | null,
    ): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getAgendaViewPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }
                return Path.viewClientAgenda(clientExtId, content.id);
            case PageContent.clientDetail:
                if (!clientExtId) {
                    Log.warning("getAgendaViewPath(): PageContent.clientDetail - clientExtId is:", clientExtId);
                    return undefined;
                }
                return Path.viewClientDetailAgenda(clientExtId, content.id);
            case PageContent.own:
            case PageContent.public:
                return Path.viewAgenda(content.id);
            case PageContent.supporterDetail:
                if (!supporterExtId) {
                    Log.warning("getAgendaViewPath(): PageContent.supporterDetail - supporterExtId is:", supporterExtId);
                    return undefined;
                }
                return Path.viewSupporterDetailAgenda(supporterExtId, content.id);
            case PageContent.supervisorDetail:
                if (!supervisorId) {
                    Log.warning("getAgendaViewPath(): PageContent.supervisorDetail - supervisorId is:", supervisorId);
                    return undefined;
                }
                return Path.viewSupervisorDetailAgenda(supervisorId, content.id);
            default:
                Log.warning("getAgendaViewPath(): default");
        }
        return undefined;
    }

    private static getAgendaItemViewPath(
        content: AgendaItemContent | AgendaItemListItem,
        pageContent: PageContent,
        clientExtId?: string | null,
        supporterExtId?: string | null,
        agendaId?: string | null,
        supervisorId?: string | null,
    ): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getAgendaItemViewPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return agendaId ? Path.viewClientAgendaAgendaItem(clientExtId, agendaId, content.id) : Path.viewClientAgendaItem(clientExtId, content.id);
            case PageContent.clientDetail:
                if (!clientExtId) {
                    Log.warning("getAgendaItemViewPath(): PageContent.clientDetail - clientExtId is:", clientExtId);
                    return undefined;
                }
                return Path.viewClientDetailAgendaItem(clientExtId, content.id);
            case PageContent.own:
            case PageContent.public:
                return agendaId ? Path.viewAgendaAgendaItem(agendaId, content.id) : Path.viewAgendaItem(content.id);
            case PageContent.supporterDetail:
                if (!supporterExtId) {
                    Log.warning("getAgendaItemViewPath(): PageContent.supporterDetail - supporterExtId is:", supporterExtId);
                    return undefined;
                }
                return Path.viewSupporterDetailAgendaItem(supporterExtId, content.id);
            case PageContent.supervisorDetail:
                if (!supervisorId) {
                    Log.warning("getAgendaItemViewPath(): PageContent.supervisorDetail - supervisorId is:", supervisorId);
                    return undefined;
                }
                return Path.viewSupervisorDetailAgendaItem(supervisorId, content.id);
            case PageContent.shareRequest:
                if (!content.createdBy) {
                    Log.warning("Content createdBy not found!", content);
                    return undefined;
                }
                return Path.viewSupporterDetailAgendaItem(content.createdBy.id, content.id);
            default:
                Log.warning("getAgendaItemViewPath(): default");
        }
        return undefined;
    }

    private static getFlowchartViewPath(
        content: FlowchartContent | FlowchartListItem,
        pageContent: PageContent,
        clientExtId?: string | null,
        supporterExtId?: string | null,
        supervisorId?: string | null,
    ): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getFlowchartViewPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return Path.viewClientFlowchart(clientExtId, content.id);
            case PageContent.clientDetail:
                if (!clientExtId) {
                    Log.warning("getFlowchartViewPath(): PageContent.clientDetail - clientExtId is:", clientExtId);
                    return undefined;
                }
                return Path.viewClientDetailFlowchart(clientExtId, content.id);
            case PageContent.own:
            case PageContent.public:
                return Path.viewFlowchart(content.id);
            case PageContent.supporterDetail:
                if (!supporterExtId) {
                    Log.warning("getFlowchartViewPath(): PageContent.supporterDetail - supporterExtId is:", supporterExtId);
                    return undefined;
                }
                return Path.viewSupporterDetailFlowchart(supporterExtId, content.id);
            case PageContent.supervisorDetail:
                if (!supervisorId) {
                    Log.warning("getFlowchartViewPath(): PageContent.supervisorDetail - supervisorId is:", supervisorId);
                    return undefined;
                }
                return Path.viewSupervisorDetailFlowchart(supervisorId, content.id);
            default:
                Log.warning("getFlowchartViewPath(): default");
        }
        return undefined;
    }

    private static getFlowchartItemViewPath(
        content: FlowchartItemContent | FlowchartItemListItem,
        pageContent: PageContent,
        clientExtId?: string | null,
        supporterExtId?: string | null,
        supervisorId?: string | null,
    ): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getFlowchartItemViewPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return Path.viewClientFlowchartItem(clientExtId, content.id);
            case PageContent.clientDetail:
                if (!clientExtId) {
                    Log.warning("getFlowchartItemViewPath(): PageContent.clientDetail - clientExtId is:", clientExtId);
                    return undefined;
                }
                return Path.viewClientDetailFlowchartItem(clientExtId, content.id);
            case PageContent.own:
            case PageContent.public:
                return Path.viewFlowchartItem(content.id);
            case PageContent.supporterDetail:
                if (!supporterExtId) {
                    Log.warning("getFlowchartItemViewPath(): PageContent.supporterDetail - supporterExtId is:", supporterExtId);
                    return undefined;
                }
                return Path.viewSupporterDetailFlowchartItem(supporterExtId, content.id);
            case PageContent.supervisorDetail:
                if (!supervisorId) {
                    Log.warning("getFlowchartItemViewPath(): PageContent.supervisorDetail - supervisorId is:", supervisorId);
                    return undefined;
                }
                return Path.viewSupervisorDetailFlowchartItem(supervisorId, content.id);
            default:
                Log.warning("getFlowchartItemViewPath(): default");
        }
        return undefined;
    }

    private static getEverydaySituationDirectoryViewPath = (clientExtId: string | null | undefined, everydaySituationDirectoryId: string | null | undefined): string => {
        return clientExtId && everydaySituationDirectoryId ? Path.viewClientEverydaySituationDirectory(clientExtId, everydaySituationDirectoryId) : "";
    };

    public static getViewPath(
        content: AnyContentType | AnyContentListType | CalendarAgenda | EverydaySituationDirectory,
        pageContent: PageContent,
        clientExtId?: string | null,
        supporterExtId?: string | null,
        agendaId?: string | null,
        supervisorId?: string | null,
    ): string | undefined {
        switch (content.__typename) {
            case "Agenda":
                return ContentPageUtils.getAgendaViewPath(content, pageContent, clientExtId, supporterExtId, supervisorId);
            case "AgendaItem":
                return ContentPageUtils.getAgendaItemViewPath(content, pageContent, clientExtId, supporterExtId, agendaId, supervisorId);
            case "Flowchart":
                return ContentPageUtils.getFlowchartViewPath(content, pageContent, clientExtId, supporterExtId, supervisorId);
            case "FlowchartItem":
                return ContentPageUtils.getFlowchartItemViewPath(content, pageContent, clientExtId, supporterExtId, supervisorId);
            case "EverydaySituationDirectory":
                return ContentPageUtils.getEverydaySituationDirectoryViewPath(clientExtId, content.id);
            case "Asset":
                return ContentPageUtils.getAssetViewPath(content, pageContent, supporterExtId, supervisorId);
            case "SelectionBoard":
                return ContentPageUtils.getSelectionBoardViewPath(content.id, clientExtId);
            default:
                return undefined;
        }
    }

    private static getAgendaEditPath(content: AgendaContent | AgendaListItem | CalendarAgenda, pageContent: PageContent, clientExtId?: string | null): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getAgendaEditPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return Path.editClientAgenda(clientExtId, content.id);
            case PageContent.clientDetail:
            case PageContent.supporterDetail:
                return undefined;
            case PageContent.own:
            case PageContent.public:
                return Path.editAgenda(content.id);
            default:
                Log.warning("getAgendaEditPath(): default");
        }
        return undefined;
    }

    private static getAgendaItemEditPath(content: AgendaItemContent | AgendaItemListItem, pageContent: PageContent, clientExtId?: string | null, agendaId?: string | null): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getAgendaItemEditPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return agendaId ? Path.editClientAgendaAgendaItem(clientExtId, agendaId, content.id) : Path.editClientAgendaItem(clientExtId, content.id);
            case PageContent.clientDetail:
            case PageContent.supporterDetail:
                return undefined;
            case PageContent.own:
            case PageContent.public:
                return agendaId ? Path.editAgendaAgendaItem(agendaId, content.id) : Path.editAgendaItem(content.id);
            default:
                Log.warning("getAgendaItemEditPath(): default");
        }
        return undefined;
    }

    private static getFlowchartEditPath(content: FlowchartContent | FlowchartListItem, pageContent: PageContent, clientExtId?: string | null): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getFlowchartEditPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return Path.editClientFlowchart(clientExtId, content.id);
            case PageContent.clientDetail:
            case PageContent.supporterDetail:
                return undefined;
            case PageContent.own:
            case PageContent.public:
                return Path.editFlowchart(content.id);
            default:
                Log.warning("getFlowchartEditPath(): default");
        }
        return undefined;
    }

    private static getFlowchartItemEditPath(content: FlowchartItemContent | FlowchartItemListItem, pageContent: PageContent, clientExtId?: string | null): string | undefined {
        switch (pageContent) {
            case PageContent.client:
                if (!clientExtId) {
                    Log.warning("getFlowchartItemEditPath(): PageContent.client - clientExtId is:", clientExtId);
                    return undefined;
                }

                return Path.editClientFlowchartItem(clientExtId, content.id);
            case PageContent.clientDetail:
            case PageContent.supporterDetail:
                return undefined;
            case PageContent.own:
            case PageContent.public:
                return Path.editFlowchartItem(content.id);
            default:
                Log.warning("getFlowchartItemEditPath(): default");
        }
        return undefined;
    }

    private static getSelectionBoardViewPath(selectionBoardId: string, clientExtId?: string | null): string | undefined {
        if (!clientExtId) {
            return undefined;
        }

        return Path.viewClientSelectionBoard(clientExtId, selectionBoardId);
    }

    public static getEditPath(content: AnyContentType | AnyContentListType | CalendarAgenda, pageContent: PageContent, clientExtId?: string | null, agendaId?: string | null): string | undefined {
        switch (content.__typename) {
            case "Agenda":
                return ContentPageUtils.getAgendaEditPath(content, pageContent, clientExtId);
            case "AgendaItem":
                return ContentPageUtils.getAgendaItemEditPath(content, pageContent, clientExtId, agendaId);
            case "Flowchart":
                return ContentPageUtils.getFlowchartEditPath(content, pageContent, clientExtId);
            case "FlowchartItem":
                return ContentPageUtils.getFlowchartItemEditPath(content, pageContent, clientExtId);
            default:
                return undefined;
        }
    }

    public static isEditEnabled(content: AnyContentType, myAccount: Account, client?: SupportedClient | null): boolean {
        if (content.__typename !== "EverydaySituationDirectory" && content.createdBy?.id === myAccount.id) {
            return true;
        }

        if (myAccount.myClients && client) {
            return myAccount.myClients.result.some((client: SupportedClient) => client.id === client.id);
        }
        return false;
    }

    public static getRootPath(path: string, clientExtId?: string | null, supporterExtId?: string | null, supervisorId?: string | null): string {
        switch (path) {
            case AppPath.createAgenda:
            case AppPath.createAgendaItem:
            case AppPath.createFlowchart:
            case AppPath.createFlowchartItem:
            case AppPath.editAgenda:
            case AppPath.editAgendaItem:
            case AppPath.editFlowchart:
            case AppPath.editFlowchartItem:
            case AppPath.editClientAgenda:
            case AppPath.viewAgenda:
            case AppPath.viewAgendaItem:
            case AppPath.viewFlowchart:
            case AppPath.viewFlowchartItem:
                return Path.publicContentLibrary;

            case AppPath.createClientAgenda:
            case AppPath.createClientAgendaItem:
            case AppPath.createClientFlowchart:
            case AppPath.createClientFlowchartItem:
            case AppPath.editClientAgendaItem:
            case AppPath.editClientFlowchart:
            case AppPath.editClientFlowchartItem:
            case AppPath.viewClientAgenda:
            case AppPath.viewClientAgendaItem:
            case AppPath.viewClientFlowchart:
            case AppPath.viewClientFlowchartItem:
                return PageType.create;

            case AppPath.viewClientDetailAgenda:
            case AppPath.viewClientDetailAgendaItem:
            case AppPath.viewClientDetailFlowchart:
            case AppPath.viewClientDetailFlowchartItem:
                return clientExtId ? Path.clientDetailContentLibrary(clientExtId) : Path.clientList;

            case AppPath.viewSupporterDetailAgenda:
            case AppPath.viewSupporterDetailAgendaItem:
            case AppPath.viewSupporterDetailFlowchart:
            case AppPath.viewSupporterDetailFlowchartItem:
                return supporterExtId ? Path.supporterDetailContentLibrary(supporterExtId) : Path.supporterList;

            case AppPath.viewSupervisorDetailAgenda:
            case AppPath.viewSupervisorDetailAgendaItem:
            case AppPath.viewSupervisorDetailFlowchart:
            case AppPath.viewSupervisorDetailFlowchartItem:
                return supervisorId ? Path.supervisorDetailContentLibrary(supervisorId) : Path.supervisorList;
            default:
                return Path.dashboard;
        }
    }

    public static getContentPageTitleName(props: {
        client?: SupportedClient | null;
        accountExtId?: string | null;
        supporterExtId?: string | null;
        clientExtId?: string | null;
        supervisorId?: string | null;
        agenda?: Agenda | null;
        agendaItem?: AgendaItem | null;
        flowchart?: Flowchart | null;
        flowchartItem?: FlowchartItem | null;
    }): string {
        if (props.client) {
            return props.client.name;
        }

        if (props.accountExtId) {
            return Formatter.formatExtId(props.accountExtId);
        }

        if (props.supporterExtId) {
            return Formatter.formatExtId(props.supporterExtId);
        }

        if (props.clientExtId) {
            return Formatter.formatExtId(props.clientExtId);
        }

        if (props.supervisorId) {
            if (props.agenda) {
                return props.agenda.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" });
            }
            if (props.agendaItem) {
                return props.agendaItem.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" });
            }
            if (props.flowchart) {
                return props.flowchart.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" });
            }
            if (props.flowchartItem) {
                return props.flowchartItem.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" });
            }
        }

        return "";
    }
}

export { ContentPageUtils, PageContent };

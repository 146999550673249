import React from "react";

import { InputWrapper } from "../InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { AgendaItem, AssetContent, AssetType, FlowchartItem } from "api/graphql/types";
import "../Inputs/Inputs.scss";
import "./MultipleAssetInputWrapper.scss";

interface Props {
    items: AgendaItem | FlowchartItem;
    label: string;
    onAssetChange: (assets: AssetContent[], assetType: AssetType) => void;
    disabled: boolean;
}
class MultipleAssetInputWrapper extends React.PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <>
                <p className="text-primary mt-4">{this.props.label}</p>
                <div className="grid-x grid-padding-x">
                    <div className="cell medium-6">
                        <div className={this.props.items.video ? "inactive-layer card" : "card"}>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.image.label" })}>
                                <AssetInput
                                    droppableId={`${this.props.label}-image`}
                                    assetType={AssetType.image}
                                    value={this.props.items.image ? [this.props.items.image] : []}
                                    onChange={(assets: AssetContent[]) => this.props.onAssetChange(assets, AssetType.image)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={this.props.disabled || !!this.props.items.video}
                                />
                            </InputWrapper>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.audio.label" })}>
                                <AssetInput
                                    droppableId={`${this.props.label}-audio`}
                                    assetType={AssetType.audio}
                                    value={this.props.items.audio ? [this.props.items.audio] : []}
                                    onChange={(assets: AssetContent[]) => this.props.onAssetChange(assets, AssetType.audio)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={this.props.disabled || !!this.props.items.video}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="cell medium-6">
                        <div className={this.props.items.image || this.props.items.audio ? "inactive-layer card" : "card"}>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agendaItem.data.video.label" })}>
                                <AssetInput
                                    droppableId={`${this.props.label}-video`}
                                    assetType={AssetType.video}
                                    value={this.props.items.video ? [this.props.items.video] : []}
                                    onChange={(assets: AssetContent[]) => this.props.onAssetChange(assets, AssetType.video)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={this.props.disabled || !!this.props.items.image || !!this.props.items.audio}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export { MultipleAssetInputWrapper };

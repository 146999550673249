import React, { Component } from "react";
import { Page } from "components/Page";
import { Path } from "utils/Path";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { EducationContentModule, EducationContentSubModule, EducationContentModuleOrderInput } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { withRouter, RouteComponentProps } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Column } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { SortableTable } from "components/Table/SortableTable";
import { ImageSrc } from "utils/ImageSrc";
import "./EducationSubModuleListPage.scss";
import { isNil } from "lodash";
import { Redirect } from "react-router-dom";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect } from "react-redux";

enum EducationSubModuleTableColumn {
    title = "title",
    createdBy = "createdBy",
    updatedAt = "updatedAt",
    actions = "actions",
}

interface RouteParams {
    moduleId?: string;
}

type Props = RouteComponentProps<RouteParams> & DispatchProp;

interface State {
    educationContentModule: EducationContentModule | null;
    isLoading: boolean;
}

class EducationSubModuleListPageComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            educationContentModule: null,
            isLoading: true,
        };
    }

    componentDidMount(): void {
        this.refreshEducationContentModule();
    }

    private readonly refreshEducationContentModule = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await Api.getEducationModuleById(this.props.match.params.moduleId || "");
                    this.setState({
                        educationContentModule: response || null,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private redirectToSubModule = (subModule: EducationContentSubModule): void => {
        this.props.history.push(Path.educationSubModuleList(subModule.id));
    };

    private onSubModuleDelete = (subModule: EducationContentSubModule) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteSubModule,
                subModuleId: subModule.id,
                onDeleted: this.refreshEducationContentModule,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<EducationContentSubModule>> => {
        const columnNames: EducationSubModuleTableColumn[] = ObjectUtils.enumAsArray<EducationSubModuleTableColumn>(EducationSubModuleTableColumn);

        return columnNames.map(
            (columnName: EducationSubModuleTableColumn): Column<EducationContentSubModule> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.educationModules.table.columns.${columnName}` }),
                accessor: columnName as keyof EducationContentSubModule,
                renderCell: (subModule: EducationContentSubModule): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case EducationSubModuleTableColumn.updatedAt:
                            return (
                                <>
                                    {subModule.updatedAt
                                        ? DateUtils.format(new Date(subModule.updatedAt), DateFormat.yyyymmddhhmm)
                                        : DateUtils.format(new Date(subModule.createdAt), DateFormat.yyyymmddhhmm)}
                                </>
                            );
                        case EducationSubModuleTableColumn.actions:
                            return (
                                <div className="action-buttons">
                                    <Button
                                        link
                                        icon={{ name: "fa-pencil-alt", large: true }}
                                        onClick={() => this.props.history.push(Path.editEducationSubModule(this.props.match.params.moduleId!, subModule.id))}
                                        ariaLabel={Intl.formatMessage({ id: "common.edit" })}
                                    />
                                    <Button link icon={{ name: "fa-trash", large: true }} onClick={() => this.onSubModuleDelete(subModule)} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
                                    <div className="move-icon">
                                        <img src={ImageSrc.moveIcon} />
                                    </div>
                                </div>
                            );
                        case EducationSubModuleTableColumn.createdBy:
                            return (
                                <>
                                    <span>{subModule.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</span>
                                    <br />
                                    <span>({Intl.formatMessage({ id: `enum.accountType.${subModule.createdBy?.accountType}` }) || Intl.formatMessage({ id: "common.unknown" })})</span>
                                </>
                            );
                        case EducationSubModuleTableColumn.title:
                            return (
                                <div>
                                    {Intl.formatMessage(
                                        { id: "page.educationSubModule.table.columnContent.title" },
                                        { module: this.state.educationContentModule?.position, subModule: subModule.position, title: subModule.title },
                                    )}
                                </div>
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: true,
            }),
        );
    };

    private readonly renderButtons = (): React.ReactElement => {
        return (
            <Button
                type="button"
                label={Intl.formatMessage({ id: "page.educationSubModule.createSubModuleButton" })}
                icon={{
                    name: "fa-plus",
                    position: "left",
                }}
                onClick={() => {
                    this.props.history.push(Path.createEducationSubModule(this.state.educationContentModule!.id));
                }}
            />
        );
    };

    private getSubModuleOrder = (subModules: EducationContentSubModule[]): EducationContentModuleOrderInput[] => {
        return subModules.map((subModule: EducationContentSubModule, index: number) => {
            return {
                moduleId: subModule.id,
                position: index + 1,
            };
        });
    };

    private onOrderChange = (subModules: EducationContentSubModule[]) => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const orderInput: EducationContentModuleOrderInput[] = this.getSubModuleOrder(subModules);
                    await Api.orderEducationContentModules(this.props.match.params.moduleId!, orderInput);
                    this.refreshEducationContentModule();
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly renderContent = (): React.ReactElement => {
        const { educationContentModule } = this.state;
        if (isNil(educationContentModule)) {
            return <Redirect to={Path.dashboard} />;
        }
        return (
            <>
                <div className="left-side education-sub-module-list-page">
                    <p>{Intl.formatMessage({ id: "page.educationModules.description" })}</p>
                    <SortableTable
                        data={educationContentModule.subModules || []}
                        onOrderChange={this.onOrderChange}
                        isLoading={this.state.isLoading}
                        columns={this.getColumns()}
                        areArrowsHidden={true}
                        count={educationContentModule.subModules?.length || 0}
                        onRowClick={this.redirectToSubModule}
                    />
                </div>
            </>
        );
    };

    public render(): React.ReactElement {
        if (isNil(this.props.match.params.moduleId)) {
            <Redirect to={Path.dashboard} />;
        }

        return (
            <Page
                title={Intl.formatMessage({ id: "page.educationSubModule.title" }, { position: this.state.educationContentModule?.position })}
                backButtonUrl={Path.educationModuleList}
                renderButtons={this.renderButtons}
            >
                {this.state.isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}
            </Page>
        );
    }
}

export const EducationSubModuleListPage = withRouter(connect()(EducationSubModuleListPageComponent));

import React, { Component } from "react";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Account, AccountType } from "api/graphql/types";
import { ProfileNameForm } from "./ProfileNameForm";
import { Intl } from "i18n/Intl";
import { ProfileEmailForm } from "./ProfileEmailForm";
import { Button } from "components/Button/Button";
import { Section } from "components/Section";
import { ProfilePasswordForm } from "./ProfilePasswordForm";
import { ProfilePinForm } from "./ProfilePinForm";
import { Formatter } from "utils/Formatter";
import { ProfileAvatarForm } from "./ProfileAvatarForm";
import { TestId } from "utils/TestId";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Page } from "components/Page";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";
import { AuthActions } from "actions/AuthActions";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface ReduxProps {
    account: Account | null;
}

type Props = ReduxProps & DispatchProp;

class ProfilePageComponent extends Component<Props> {
    private onDeleteProfile = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteProfile,
                profileName: this.props.account!.name,
                onDelete: this.deleteProfile,
            }),
        );
    };

    private deleteProfile = async (): Promise<void> => {
        try {
            await Api.deleteMyAccount();
            Alert.success({ title: Intl.formatMessage({ id: "page.profile.accountData.delete.deleteSucceed" }) });
            this.props.dispatch(AuthActions.logout());
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> | null {
        const { account } = this.props;
        if (!account) {
            return null;
        }

        return (
            <Page id={TestId.profilePage.container} title={Intl.formatMessage({ id: "page.profile.title" })}>
                <div className="left-side">
                    <div className="row">
                        <div className="col-md-9">
                            <Section label={Intl.formatMessage({ id: "page.profile.personalData.title" })}>
                                <ProfileNameForm />
                                <ProfileEmailForm />
                                <ProfilePasswordForm />
                                {account.extId && (
                                    <div className="input-wrapper grid-x">
                                        <span className="input-label cell">{Intl.formatMessage({ id: "page.profile.personalData.extId" })}</span>
                                        <span className="cell auto">
                                            <strong className="value-cell">{Formatter.formatExtId(account.extId)}</strong>
                                        </span>
                                        <span className="cell button-cell">
                                            <CopyToClipboardButton
                                                copiedTooltipMessage={Intl.formatMessage({ id: "page.profile.personalData.copied" })}
                                                id={TestId.profilePage.copyButton}
                                                textToCopy={account.extId}
                                            />
                                        </span>
                                    </div>
                                )}
                            </Section>

                            {account.accountType === AccountType.supporter && (
                                <Section label={Intl.formatMessage({ id: "page.profile.pin.title" })}>
                                    <p>{Intl.formatMessage({ id: "page.profile.pin.description" })}</p>
                                    <ProfilePinForm />
                                </Section>
                            )}

                            <Section label={Intl.formatMessage({ id: "page.profile.accountData.title" })}>
                                <div className="input-wrapper grid-x">
                                    <span className="input-label cell">{Intl.formatMessage({ id: "page.profile.accountData.delete.label" })}</span>
                                    <span className="cell auto">
                                        <strong className="value-cell">&nbsp;</strong>
                                    </span>
                                    <span className="cell button-cell">
                                        <Button
                                            className="btn-danger btn--text-large fw-700"
                                            id={TestId.profilePage.copyButton}
                                            label={Intl.formatMessage({ id: "common.delete" })}
                                            ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                            onClick={this.onDeleteProfile}
                                        />
                                    </span>
                                </div>
                            </Section>
                        </div>
                        <div className="col-md-3 mt-20">
                            <Section label={Intl.formatMessage({ id: "page.profile.avatar.title" })} noTopMargin>
                                <ProfileAvatarForm />
                            </Section>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account,
    };
};

export const ProfilePage = connect(mapStateToProps)(ProfilePageComponent);

// tslint:disable-next-line: no-default-export
export default ProfilePage;

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";

export interface RejectContentShareRequestDialogProps {
    requestId: string;
    onRejected: () => Promise<void> | void;
}

type Props = RejectContentShareRequestDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class RejectContentShareRequestDialog extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private rejectContentShareRequestDialog = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.rejectLibraryContentShareRequest(this.props.requestId);
                    Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.rejectContentShareRequestDialog.succeed" }) });
                    this.props.onHide();
                    this.props.onRejected();
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }

                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "sharedComponent.rejectContentShareRequestDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <i className="fas fa-exclamation-triangle verify-delete-everyday-situation-dialog-icon" />
                <p>{Intl.formatMessage({ id: "sharedComponent.rejectContentShareRequestDialog.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            label={Intl.formatMessage({ id: "sharedComponent.rejectContentShareRequestDialog.reject" })}
                            onClick={this.rejectContentShareRequestDialog}
                            disabled={this.state.isLoading}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { RejectContentShareRequestDialog };

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { ObjectUtils } from "utils/ObjectUtils";
import { ClientProfile } from "api/ApiTypes";
import { Intl } from "i18n/Intl";

type ClientProfileOption = SelectOption<ClientProfile | null>;

class ClientProfileOptions {
    public static get(): ClientProfileOption[] {
        return [
            { id: "null", label: Intl.formatMessage({ id: "enum.clientProfile.null" }), value: null },
            ...ObjectUtils.enumAsArray<ClientProfile>(ClientProfile).map(
                (clientProfile: ClientProfile): ClientProfileOption => {
                    return { id: clientProfile, label: clientProfile, value: clientProfile };
                },
            ),
        ];
    }
}

export { ClientProfileOptions };
export type { ClientProfileOption };

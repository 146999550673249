import React, { Component } from "react";
import { Asset, Tag, Account, AccountType, AssetType } from "api/graphql/types";
import { Page } from "components/Page";
import { PageType } from "utils/TypeUtils";
import { Intl } from "i18n/Intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Loading, LoadingType } from "components/Loading/Loading";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Prompt } from "components/Prompt";
import { isEqual, isNil } from "lodash";
import { Section } from "components/Section";
import { NotificationType } from "components/NotificationBar/Notification";
import { Alert } from "components/Alert/Alert";
import { Button } from "components/Button/Button";
import { BottomBar } from "components/BottomBar";
import { Path } from "utils/Path";
import { ApplicationState } from "reducers";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { OptionMenuItem, OptionsMenu } from "components/OptionsMenu/OptionsMenu";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { ImageSrc } from "utils/ImageSrc";

interface ReduxProps {
    tags: Tag[];
    account: Account;
}

interface ComponentProps {
    pageType: PageType;
    pageContent: PageContent;
    asset: Asset;
    isLoading: boolean;
    onSubmit: (title: string, tags: string[]) => Promise<Asset>;
    refreshAsset: () => void;
}

interface Errors {
    title: string | null;
}

interface State {
    title: string;
    errors: Errors;
    isValidationEnabled: boolean;
    isPromptDisabled: boolean; // For prompt
    selectedTags: string[];
}

type Props = ComponentProps & RouteComponentProps & DispatchProp & ReduxProps;

class AssetFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = AssetFormComponent.getInitialStateFromProps(props);
    }

    private static getInitialStateFromProps(props: Props): State {
        return {
            title: props.asset.title,
            errors: {
                title: null,
            },
            isValidationEnabled: false,
            isPromptDisabled: false,
            selectedTags: [...props.asset.tags],
        };
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.asset.title !== nextProps.asset.title) {
            this.setState(AssetFormComponent.getInitialStateFromProps(nextProps));
        }
    }

    private onSubmitClick = async (): Promise<void> => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaTitle(this.state.title));
        if (errors.title !== null || titleError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError } });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            return;
        }
        try {
            const asset: Asset = await this.props.onSubmit(this.state.title, this.state.selectedTags);
            this.setState({ isPromptDisabled: true, title: asset.title });
            if (this.pageRef) {
                this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.asset.saveSucceed" }), type: NotificationType.success });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaTitle(title));
        this.setState({ title, errors: { ...this.state.errors, title: titleError } });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.title, this.props.asset.title) || !isEqual(this.state.selectedTags, this.props.asset.tags);
    };

    private shouldPreventRouteChange = (): boolean => {
        if (this.props.pageType !== PageType.view && !this.state.isPromptDisabled) {
            return this.isChanged();
        }
        return false;
    };

    private onCancelClick = (): void => {
        this.setState({ isValidationEnabled: false, title: this.props.asset.title });
    };

    private onEditClick = (): void => {
        if (!isNil(this.props.asset)) {
            this.props.history.push(Path.editAsset(this.props.asset.id));
        }
    };

    private readonly onTagChange = (selectedTags: string[]) => {
        this.setState({
            selectedTags,
        });
    };

    private readonly renderButtons = (): React.ReactElement => {
        const items: OptionMenuItem[] = [];
        if (this.props.asset.createdBy?.id !== this.props.account.id && !this.props.asset.publishedAt && this.props.account.accountType === AccountType.supervisor) {
            const isEnable = !!this.props.asset.disabledAt;
            items.push({
                label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }),
                icon: isEnable ? "fa-unlock" : "fa-lock",
                onClick: () => {
                    this.props.dispatch(
                        DialogsActions.show({
                            type: DialogType.contentToggler,
                            onToggled: this.props.refreshAsset,
                            content: this.props.asset,
                        }),
                    );
                },
            });
        }

        return (
            <>
                {this.props.asset.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                {items.length > 0 && <OptionsMenu items={items} />}
            </>
        );
    };

    private renderBottomBar = (): React.ReactElement | null => {
        if (this.props.account.id === this.props.asset.createdBy?.id) {
            if (this.props.pageType === PageType.view) {
                return (
                    <BottomBar isVisible={true}>
                        <div className="cell medium-12 text-center">
                            <Button label={Intl.formatMessage({ id: "common.edit" })} onClick={this.onEditClick} />
                        </div>
                    </BottomBar>
                );
            } else {
                return (
                    <BottomBar isVisible={this.isChanged()}>
                        <div className="cell medium-6 text-right">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged()} onClick={this.onCancelClick} />
                        </div>
                        <div className="cell medium-6 text-left">
                            <Button label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged()} onClick={this.onSubmitClick} />
                        </div>
                    </BottomBar>
                );
            }
        }
        return null;
    };

    private readonly getFallbackImage = (): string | null => {
        switch (this.props.asset.assetType) {
            case AssetType.image:
            case AssetType.avatar:
            case AssetType.video:
            case AssetType.introduction_video:
                return ImageSrc.asset;
            case AssetType.audio:
            case AssetType.introduction_audio:
                return ImageSrc.audioAsset;
            default:
                return null;
        }
    };

    public renderContent(): React.ReactElement<any> {
        const { title, errors, isValidationEnabled } = this.state;
        const disabled: boolean = this.props.pageType === PageType.view;

        return (
            <>
                <p>{Intl.formatMessage({ id: "page.asset.lead" })}</p>
                <Section label={Intl.formatMessage({ id: "page.asset.titleSection.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.asset.preview.label" })}>
                        <img src={this.props.asset.url || this.props.asset.thumbnailUrl || this.getFallbackImage()} className="asset-form-preview" />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.asset.title.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            value={title}
                            onChange={this.onTitleChange}
                            disabled={disabled}
                        />
                    </InputWrapper>
                </Section>
                <Section label={Intl.formatMessage({ id: "page.asset.tagSection.title" })}>
                    <TagSelect tags={this.props.tags} selectedTags={this.state.selectedTags} onChange={this.onTagChange} disabled={disabled} renderTags={true} />
                </Section>

                {this.renderBottomBar()}

                <Prompt when={this.shouldPreventRouteChange()} />
            </>
        );
    }

    public render(): React.ReactElement<any> {
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                renderButtons={this.renderButtons}
                title={Intl.formatMessage({ id: `page.asset.pageTitle.${this.props.pageType}` })}
            >
                <div className="left-side">{this.props.isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}</div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags, account: state.account! };
};

export const AssetForm = withRouter(connect(mapStateToProps)(AssetFormComponent));

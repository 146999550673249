import React, { PureComponent } from "react";
import { Column } from "./Table";
import { SortOrder } from "api/graphql/types";

interface Props<T> {
    column: Column<T>;
    onChangeSort?: (column?: Column<T>, sortOrder?: SortOrder) => void;
    sortOrder?: SortOrder;
    isSortable: boolean;
}

class ColumnHeader<T = any> extends PureComponent<Props<T>, {}> {
    /**
     * Moves the order (normal -> ascending -> descending) and fires the appropriate ascending/descending prop methods
     */
    private onHeaderClick = (column: Column<T>): ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void) => (): void => {
        const { sortOrder, onChangeSort, isSortable } = this.props;
        let nextSortOrder: SortOrder | undefined = undefined;

        switch (sortOrder) {
            case undefined:
                nextSortOrder = SortOrder.ASC;
                break;
            case SortOrder.ASC:
                nextSortOrder = SortOrder.DESC;
                break;
            case SortOrder.DESC:
            default:
                nextSortOrder = undefined;
                break;
        }

        if (onChangeSort && isSortable) {
            nextSortOrder ? onChangeSort(column, nextSortOrder) : onChangeSort(undefined, undefined);
        }
    };

    private renderChevrons = (): JSX.Element => {
        switch (this.props.sortOrder) {
            case SortOrder.ASC:
                return <span className="fa fa-chevron-up" />;
            case SortOrder.DESC:
                return <span className="fa fa-chevron-down" />;
            case undefined:
            default:
                return <span />;
        }
    };

    public render(): React.ReactElement<any> {
        const { column, isSortable } = this.props;
        return (
            <span className={`header-cell ${column.className || column.accessor}`} key={column.id} onClick={this.onHeaderClick(column)}>
                <span className={`title ${isSortable ? " sortable" : ""}`}>{column.name}</span>
                {this.renderChevrons()}
            </span>
        );
    }
}

export { ColumnHeader };

export interface ColorsOfDays {
    mon: string | null;
    tue: string | null;
    wed: string | null;
    thu: string | null;
    fri: string | null;
    sat: string | null;
    sun: string | null;
}

export class ColorsOfDaysUtils {
    public static getEmptyColorsOfDays = (): ColorsOfDays => {
        return { mon: null, tue: null, wed: null, thu: null, fri: null, sat: null, sun: null };
    };
}

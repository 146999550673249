import { Account, SupportedClient } from "api/graphql/types";

export enum AccountActionType {
    updateAccount = "@account/updateAccount",
    addClient = "@account/addClient",
    updateClient = "@account/updateClient",
    deleteClient = "@account/deleteClient",
}

interface UpdateAccount {
    type: AccountActionType.updateAccount;
    account: Account;
}

interface AddClient {
    type: AccountActionType.addClient;
    client: SupportedClient;
}

interface UpdateClient {
    type: AccountActionType.updateClient;
    client: SupportedClient;
}

interface DeleteClient {
    type: AccountActionType.deleteClient;
    clientId: string;
}

export class AccountActions {
    public static updateAccount(account: Account): UpdateAccount {
        return { type: AccountActionType.updateAccount, account };
    }

    public static addClient(client: SupportedClient): AddClient {
        return { type: AccountActionType.addClient, client };
    }

    public static updateClient(client: SupportedClient): UpdateClient {
        return { type: AccountActionType.updateClient, client };
    }

    public static deleteClient(clientId: string): DeleteClient {
        return { type: AccountActionType.deleteClient, clientId };
    }
}

export type AccountAction = UpdateAccount | AddClient | UpdateClient | DeleteClient;

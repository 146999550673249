// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function TickIcon({ width, height }: IconProps): React.ReactElement {
    return (
        <svg viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <path d="M6.00685 11.369L1.52589 6.888L0 8.40315L6.00685 14.41L18.9017 1.51515L17.3866 0L6.00685 11.369Z" fill="#ffffff" />
        </svg>
    );
}

export { TickIcon };

import React from "react";
import { TickIcon } from "components/Icons/TickIcon";

import "../Inputs.scss";
import { IndeterminateIcon } from "../../Icons/IndeterminateIcon";

interface Props {
    id?: string;
    label?: JSX.Element | string;
    className?: string;
    errorMessage?: string;
    hasError?: boolean | null;
    checked: boolean;
    innerRef?: (ref: HTMLInputElement | null) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

class Checkbox extends React.PureComponent<Props> {
    private containerRef: HTMLDivElement | null = null;

    public scrollIntoView = (): void => {
        if (this.containerRef) {
            this.containerRef.scrollIntoView();
        }
    };

    public render(): React.ReactElement<any> {
        const { id, label, className, errorMessage, hasError, checked, innerRef, ...otherProps } = this.props;
        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
            >
                <label id={id} className={`checkbox-wrapper${className ? ` ${className}` : ""}`}>
                    <input ref={innerRef} type="checkbox" className="show-for-sr" {...otherProps} checked={checked} />
                    <div className={`checkbox-toggle${hasError ? " has-error" : ""}`}>
                        <div className="icon-wrapper">
                            <TickIcon />
                        </div>
                        <div className="icon-indeterminate-wrapper">
                            <IndeterminateIcon />
                        </div>
                    </div>
                    {label && <div className="checkbox-label">{label}</div>}
                    {errorMessage && <div className="error-wrapper">{errorMessage}</div>}
                </label>
            </div>
        );
    }
}

export { Checkbox };

import React, { PureComponent } from "react";
import { ContentInfo } from "./ContentElementUtils";
import { ContentElementIcon } from "./ContentElementIcon";
import { Intl } from "i18n/Intl";
import { FlowChart } from "components/Svg";

interface Props {
    info: ContentInfo;
}

class ContentElementInfo extends PureComponent<Props> {
    public render() {
        const { info } = this.props;
        return (
            <div className="content-element-icons">
                <ContentElementIcon icon={"fa-smile"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.moodMeter" })} iconInfo={info.moodMeter} />
                <ContentElementIcon icon={"fa-exclamation-circle"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.alert" })} iconInfo={info.alert} />
                <ContentElementIcon icon={"fa-comment"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.notification" })} iconInfo={info.notification} />
                <ContentElementIcon icon={"fa-map-signs"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.selectionBoard" })} iconInfo={info.selectionBoard} />
                <ContentElementIcon icon={"fa-clipboard-list"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.checklist" })} iconInfo={info.checklist} />
                <ContentElementIcon icon={"fa-adjust"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.timer" })} iconInfo={info.timer} />
                <ContentElementIcon icon={"fa-clock"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.time" })} iconInfo={info.time} />
                <ContentElementIcon icon={"fa-star"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.award" })} iconInfo={info.award} />
                <ContentElementIcon
                    iconElement={<FlowChart style={{ display: "block", marginTop: 3 }} />}
                    icon={"fa-arrow-circle-right"}
                    title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.flowchart" })}
                    iconInfo={info.isFlowchartPresent}
                />
                {info.isSelect && (
                    <ContentElementIcon
                        icon={"fa-question-circle"}
                        title={""}
                        iconInfo={{
                            active: info.isSelect,
                            tooltip: Intl.formatMessage({ id: "sharedComponent.contentElement.icon.select" }),
                        }}
                    />
                )}

                {info.time && info.timeValue && (
                    <span className={"time active"} title={Intl.formatMessage({ id: "sharedComponent.contentElement.icon.time" })}>
                        {info.timeValue}
                    </span>
                )}
            </div>
        );
    }
}

export { ContentElementInfo };

import React, { InputHTMLAttributes } from "react";
import "../Inputs.scss";

type Props = {
    hasError?: boolean | null;
    innerRef?: (ref: HTMLTextAreaElement | null) => void;
    disabled?: boolean;
    value?: string;
} & InputHTMLAttributes<HTMLTextAreaElement>;

class TextArea extends React.PureComponent<Props> {
    public render(): React.ReactElement<any> {
        const { innerRef, className, hasError, value, ...otherProps } = this.props;
        return <textarea ref={innerRef} className={`${className ? `${className} ` : ""}${hasError ? "has-error" : ""}`} value={value} {...otherProps} />;
    }
}

export { TextArea };

import { ValidatorConstants } from "../../utils/Validator";
import { EverydaySituationType } from "../../api/graphql/types";

/* eslint quotes: 0 */

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeHu = {
    common: {
        loading: "Betöltés...",
        save: "Mentés",
        saved: "Mentve",
        cancel: "Mégsem",
        copy: "Másolás",
        delete: "Törlés",
        yes: "Igen",
        no: "Nem",
        confirm: "Megerősítés",
        logout: "Kijelentkezés",
        close: "Bezárás",
        nextPage: "Következő",
        previousPage: "Előző",
        next: "Tovább",
        add: "Hozzáadás",
        back: "Vissza",
        clear: "Ürítés",
        empty: "Nincs megadva",
        ok: "Rendben",
        search: "Keresés",
        leave: "Elnavigálok",
        reject: "Elutasítás",
        accept: "Elfogadás",
        today: "MAI NAP",
        weekdaysShortName: { 1: "H", 2: "K", 3: "Sze", 4: "Cs", 5: "P", 6: "Szo", 7: "V" },
        share: "Megosztás",
        edit: "Szerkesztés",
        view: "Megtekintés",
        remove: "Eltávolítás",
        enable: "Engedélyezés",
        disable: "Tiltás",
        download: "Letöltés",
        copyToAccountPersonalLibrary: "Másolás a saját tárba",
        copyToAccountPersonalLibrarySucceed: "Másolás sikeres!",
        send: "Elküldés",
        searchWithTag: "Keresés címkével:",
        up: "Egyet fel",
        down: "Egyet le",
        addItem: "Új elem hozzáadása",
        addReward: "Új jutalom hozzáadása",
        move: "Mozgatás",
        selectAll: "Összes kijelölése",
        browse: "Tallózás",
        disabledContent: "Letiltott tartalom",
        accepted: "Elfogadva",
        rejected: "Elutasítva",
        inProgress: "Folyamatban",
        sync: "Adatfrissítés",
        deletedUser: "⚠️ törölt felhasználó ⚠️",
        unknown: "Ismeretlen",
        event: "esemény",
    },

    enum: {
        accountStatus: {
            active: "Aktív",
            inactive: "Inaktív",
            disabled: "Felfüggesztett",
        },
        clientSupporterStatus: {
            active: "Aktív",
            pending: "Meghívva",
        },
        accountType: {
            admin: "Adminisztrátor",
            superadmin: "Szuper adminisztrátor",
            supervisor: "Szakmai szupervizor",
            supporter: "Támogató",
            undefined: "Ismeretlen",
        },
        clientStatus: {
            active: "Aktív",
            pending: "Függőben",
        },
        sortOrder: {
            ASC: "novekvo",
            DESC: "csokkeno",
        },
        listQueryParameter: {
            sortField: "rendezes",
            sortOrder: "sorrend",
            search: "kereses",
            tags: "cimkek",
            limit: "talalatok_szama",
            page: "oldal",
        },
        searchListTypeQueryParameter: {
            agenda: "napirendek",
            agendaItem: "napirendi_lepesek",
            flowchart: "folyamatabrak",
            flowchartItem: "folyamatabra_lepesek",
            asset: "mediaelemek",
            everydaySituations: "mindennapi-helyzetek",
            instantAward: "azonnali-jutalmak",
            award: "pontgyujteses-jutalmak",
            selectionBoard: "valasztotabla",
        },
        clientCalendarQueryParameter: {
            year: "ev",
            month: "honap",
        },
        eventLogQueryParameter: {
            sortField: "rendezes",
            sortOrder: "sorrend",
            search: "kereses",
            limit: "talalatok_szama",
            page: "oldal",
            dateTimeFrom: "kezdo_datum",
            dateTimeTo: "veg_datum",
            appStatItemType: "esemeny_tipusa",
            appEventLogName: "esemeny_tipusa",
            authAccountExtId: "tamogato",
            clientExtId: "felhasznalo",
            clientProfile: "perszona_besorolas",
        },
        searchListType: {
            agenda: "Napirendi sorok",
            agendaItem: "Napirendi lépés",
            flowchart: "Folyamatábra-sor",
            flowchartItem: "Folyamatábra lépés",
            asset: "Médiaelem",
        },
        contentLibraryQueryParameter: {
            searchListType: "tipus",
            extraContentLibraryType: "tipus",
            isPublic: "publikus_tar",
            search: "kereses",
            tags: "cimkek",
            directory: "mappa",
            day: "nap",
            isDisabled: "tiltott_tar",
            isShared: "velem-megosztott",
        },
        contentLibrarySideBarQueryParameter: {
            search: "kereses",
            tags: "cimkek",
            directory: "mappa",
        },
        assetType: {
            avatar: "Profilkép",
            image: "Kép",
            audio: "Hang",
            video: "Videó",
            introduction_audio: "Bemutatkozó hangfájl",
            introduction_video: "Bemutatkozó videó",
            waiting_image: "Várakozó kép",
        },
        timeUnit: {
            second: "másodperc",
            minute: "perc",
            hour: "óra",
            day: "nap",
        },
        timeUnitShort: {
            second: "mp",
            minute: "p",
            hour: "ó",
            day: "n",
        },
        moodMeterDisplay: {
            null: "Nincs",
            Before: "Igen, a lépés elején jelenik meg",
            After: "Igen, a lépés végén jelenik meg",
        },
        agendaItemAlert: {
            display: {
                null: "Nincs",
                Time: "Előre beállított",
                Before: "Igen, a lépés elején jelenik meg",
                After: "Igen, a lépés végén jelenik meg",
            },
            type: {
                Once: "Egyszeri",
                Repeating: "Ismétlődő",
            },
            notifications: {
                Flash: "Fény",
                Vibrate: "Rezgés",
                FlashAndVibrate: "Fény és rezgés",
                WithoutFlashAndVibrate: "Fény és rezgés nélkül",
            },
        },
        deviceType: {
            browser: "Böngésző",
            android: "Android",
            ios: "iOS",
        },
        shareType: {
            publish: "Felajánlás közösségi tárba",
            custom: "Egyéni megosztás",
        },
        agendaItemType: {
            Simple: "Egyszerű",
            Select: "Választós",
        },
        appStatItemType: {
            null: "Minden esemény",
        },
        appStatItemTypeQueryParam: {
            null: "minden_esemeny",
            AccountLogin: "tamogato_felhasznalo_bejelentkezese",
            AgendaItemStateChange: "napirendi_lepes_allapotvaltozasa",
            Alert: "ertesites",
            AppBackground: "data_alkalmazas_bezarasa_hatterbe_kerul",
            AppForeground: "data_alkalmazas_elinditasa_eloterbe_kerul",
            AppReset: "mobil_app_alaphelyzetbe_allitasa",
            AppUnlock: "alkalmazas_feloldasa",
            AwardAchieved: "pontgyujteses_jutalom_elerese",
            CheckFlowchartItem: "folyamatabra_lepes_allapotvaltozasa",
            CheckListItemStateChange: "feladatlista_valtoztatasa",
            ClientSetup: "tamogatott_felhasznalo_beallitasa",
            CreateSituation: "mindennapi_helyzetek_sugo_tartalombevitel",
            DeviceStat: "eszkoz_adatok_frissitese",
            DownloadAccountData: "felhasznaloi_adatok_letoltese",
            DownloadAgendaData: "napirendi_adatok_letoltese",
            DownloadClientData: "tamogatoi_adat_letoltese",
            InstantAwardAchieved: "azonnali_jutalom_elerese",
            MoodReport: "erzelmi_homero_visszajelzes",
            ScoreChanged: "jutalompont_megszerzese",
            UploadPushToken: "push_token_feltoltese",
            UploadStats: "adatok_feltoltese",
            UseAgenda: "napirend_sor_elinditasa_bezarasa",
            UseChecklist: "listazo_elinditasa_bezarasa",
            UseEducation: "oktato_funkcio_elinditasa_elso_bejelentkezeskor",
            UseEverydaySituation: "mindennapi_helyzetek_sugo_elinditasa_bezarasa",
            UseFlowchart: "folyamatabra_elinditasa_bezarasa",
            UseGame: "idotolto_elinditasa_bezarasa",
            UseIntroduction: "felhasznalo_bemutatasanak_elinditasa_bezarasa",
            UseMoodMeter: "erzelmi_homero_hasznalata",
            UseNotification: "emlekezteto_elinditasa_bezarasa",
            UseSelectionBoard: "lehetosegek_tara_valaszto_elinditasa_bezarasa",
            UseTimer: "idojelzo_elinditasa_bezarasa",
            UseVolumeMeter: "hangeromero_hasznalata",
            UseWaiting: "varakozas_elinditasa_bezarasa",
        },
        clientProfile: {
            null: "Kiválaszt...",
            A: "A",
            B: "B",
            C: "C",
            N: "N",
            K: "K",
        },
        appEventLogName: {
            null: "Minden esemény",
        },
        appEventLogNameQueryParam: {
            null: "minden_esemeny",
            createClient: "felhasznaloi_profil_letrehozasa",
            updateClient: "felhasznaloi_profil_frissitese",
            updateClientConfig: "felhasznaloi_profil_beallitasok_frissitese",
            updateClientMemoryGameConfig: "memoria_jatek_beallitasok_frissitese",
            updateClientSortingGameConfig: "sorbarendezo_jatek_beallitasok_frissitese",
            updateClientSpareAgendaItems: "tartalek_lepesek_frissitese",
            setDefaultAgenda: "alapertelmezett_napirendi_sor_beallitasa",
            unsetDefaultAgenda: "alapertelmezett_napirendi_sor_torlese",
            shareClient: "tamogatoi_egyuttmukodes_kezdemenyezese",
            deleteClient: "felhasznaloi_profil_torlese",
            acceptClientShareRequest: "tamogatoi_egyuttmukodes_elfogadasa",
            rejectClientShareRequest: "tamogatoi_egyuttmukodes_elutasitasa",
            deleteClientShareRequest: "tamogatoi_egyuttmukodes_kezdemenyezes_tortlese",
            unlinkSupporter: "tamogatoi_egyuttmukodes_megszuntetese",
            createClientChangeManagerRequest: "fotamogatoi_jog_atadas_kezdemenyezese",
            deleteClientChangeManagerRequest: "fotamogatoi_jog_atadas_kezdemenyezes_torlese",
            acceptClientChangeManagerRequest: "fotamogatoi_meghivas_elfogadasa",
            rejectClientChangeManagerRequest: "fotamogatoi_meghivas_elutasitasa",
            clientQuestionnaireEvaluation: "kerdoiv_ismetelt_kitoltese",
            setSelfSupportingStateDecision: "onrendelkezesre_valtas",
            clientProfileChange: "felhasznaloi_profil_valtozasa",
            createAccount: "tamogatoi_profil_letrehozasa",
            disableAccount: "tamogatoi_profil_tiltasa",
            enableAccount: "tamogatoi_profil_engedelyezese",
            resendActivationCode: "aktivalo_kod_ujrakuldese",
            changeEmailRequest: "e_mail_cim_csere_kezdemenyezese",
            changeEmailByCode: "e_mail_cim_megvaltoztatasa",
            deleteAccount: "tamogatoi_profil_torlese",
            updateAccount: "tamogatoi_profil_frissitese",
            login: "belepes",
            activateAccount: "tamogatoi_profil_aktivalasa",
            refreshToken: "autentikacios_token_frissitese",
            resetPassword: "jelszo_visszaallitasa",
            logout: "kilepes",
            changePassword: "jelszo_modositasa",
            forgotPassword: "elfelejtett_jelszo_emlekezteto_kuldese",
            uploadAccountAvatar: "tamogatoi_profilkep_feltoltese",
            deleteAccountAvatar: "tamogatoi_profilkep_torlese",
            uploadClientAvatar: "felhasznaloi_profilkep_feltoltese",
            deleteClientAvatar: "felhasznaloi_profilkep_torlese",
            uploadAsset: "mediaelem_feltoltese",
            updateAsset: "mediaelem_frissitese",
            deleteAsset: "medialelem_torlese",
            moveAssetsToPersonalAssetDirectory: "mediaelemek_athelyezese_a_sajat_tarban",
            createPersonalAssetDirectory: "medialelem_mappa_letrehozasa",
            updatePersonalAssetDirectory: "mediaelem_mappa_frissitese",
            deletePersonalAssetDirectory: "mediaelem_mappa_torolese",
            moveAssetsToPublicAssetDirectory: "mediaelemek_athelyezese_a_publikus_tarban",
            createPublicAssetDirectory: "publikus_mediaelem_mappa_letrehozasa",
            updatePublicAssetDirectory: "publikus_mediaelem_mappa_frissitese",
            deletePublicAssetDirectory: "publikus_mediaelem_mappa_torlese",
            uploadClientIntroductionAudio: "felhasznalo_bemutatkozo_hangfajl_feltoltese",
            deleteClientIntroductionAudio: "felhasznalo_bemutatkozo_hangfajl_torlese",
            uploadClientIntroductionVideo: "felhasznalo_bemutatkozo_video_feltoltese",
            deleteClientIntroductionVideo: "felhasznalo_bemutatkozo_video_torlese",
            copyAssetToPublicLibrary: "mediaelem_publikus_tarba_masolasa",
            copyAssetToAccountPersonalLibrary: "mediaelem_szemelyes_tarba_masolasa",
            disableAsset: "mediaelem_tiltasa",
            enableAsset: "mediaelem_engedelyezese",
            replaceAsset: "mediaelem_csereje",
            createAgenda: "napirendi_sor_letrehozasa",
            createAgendaItem: "napirendi_lepes_letrehozasa",
            updateAgenda: "napirendi_sor_frissitese",
            updateAgendaItem: "napirendi_lepes_frissitese",
            disableAgenda: "napirendi_sor_tiltasa",
            enableAgenda: "napirendi_sor_engedelyezese",
            deleteAgenda: "napirendi_sor_torlese",
            disableAgendaItem: "napirendi_lepes_tiltasa",
            enableAgendaItem: "napirendi_lepes_engedelyezese",
            deleteAgendaItem: "napirendi_lepes_torlese",
            createAgendaSyncData: "napirendi_szinkronizacios_adatok_generalasa",
            copyAgendaToClientAgendaLibrary: "napirendi_sor_naphoz_rendelese",
            copyAgendaToClientPersonalLibrary: "napirendi_sor_felhasznaloi_tarba_masolasa",
            copyAgendaToPublicLibrary: "napirendi_sor_publikus_tarba_masolasa",
            copyAgendaToAccountPersonalLibrary: "napirendi_sor_sajat_tarba_masolasa",
            copyAgendaItemToClientPersonalLibrary: "napirendi_lepes_felhasznaloi_tarba_masolasa",
            copyAgendaItemToPublicLibrary: "napirendi_lepes_publikus_tarba_masolasa",
            copyAgendaItemToAccountPersonalLibrary: "napirendi_lepes_sajat_tarba_masolasa",
            createFlowchart: "folyamatabra_sor_letrehozasa",
            updateFlowchart: "folyamatabra_sor_frissitese",
            disableFlowchart: "folyamatabra_sor_tiltasa",
            enableFlowchart: "folyamatabra_sor_engedelyezese",
            deleteFlowchart: "folyamatabra_sor_torlese",
            copyFlowchartToClientLibrary: "folyamatabra_sor_felhasznaloi_tarba_masolasa",
            copyFlowchartToPublicLibrary: "folyamatabra_sor_publikus_tarba_masolasa",
            createFlowchartItem: "folyamatabra_lepes_letrehozasa",
            updateFlowchartItem: "folyamatabra_lepes_frissitese",
            disableFlowchartItem: "folyamatabra_lepes_tiltasa",
            enableFlowchartItem: "folyamatabra_lepes_engedelyezese",
            deleteFlowchartItem: "folyamatabra_lepes_torlese",
            copyFlowchartItemToClientLibrary: "folyamatabra_lepes_felhasznaloi_tarba_masolasa",
            copyFlowchartItemToPublicLibrary: "folyamatabra_lepes_publikus_tarba_masolasa",
            copyFlowchartToAccountPersonalLibrary: "folyamatabra_sor_sajat_tarba_masolasa",
            copyFlowchartItemToAccountPersonalLibrary: "folyamatabra_lepes_sajat_tarba_masolasa",
            createSelectionBoard: "valaszto_tabla_letrehozasa",
            updateSelectionBoard: "valaszto_tabla_frissitese",
            deleteSelectionBoard: "valaszto_tabla_torlese",
            orderSelectionBoards: "valaszto_tablak_sorbarendezese",
            disableSelectionBoard: "valaszto_tabla_tiltasa",
            enableSelectionBoard: "valaszto_tabla_engedelyezese",
            createAward: "pontgyujteses_jutalom_letrehozasa",
            updateAward: "pontgyujteses_jutalom_frissitese",
            deleteAward: "pontgyujteses_jutalom_torlese",
            activateAward: "pontgyujteses_jutalom_aktivalasa",
            disableAward: "pontgyujteses_jutalom_tiltasa",
            enableAward: "pontgyujteses_jutalom_engedelyezese",
            createInstantAward: "azonnali_jutalom_letrehozasa",
            updateInstantAward: "azonnali_jutalom_frissitese",
            deleteInstantAward: "azonnali_jutalom_torlese",
            disableInstantAward: "azonnali_jutalom_tiltasa",
            enableInstantAward: "azonnali_jutalom_engedelyezese",
            sendEmail: "email_kuldes",
            createTag: "cimke_letrehozasa",
            deleteTag: "cimke_torlese",
            createEverydaySituationDirectory: "mindennapi_helyzetek_mappa_letrehozasa",
            updateEverydaySituationDirectory: "mindennapi_helyzetek_mappa_frissitese",
            deleteEverydaySituationDirectory: "mindennapi_helyzetek_mappa_torlese",
            disableEverydaySituationDirectory: "mindennapi_helyzetek_mappa_tiltasa",
            enableEverydaySituationDirectory: "mindennapi_helyzetek_mappa_engedelyezese",
            createEverydaySituation: "mindennapi_helyzet_elem_letrehozasa",
            createEverydaySituationByClient: "mindennapi_helyzet_elem_letrehozasa_a_mobil_eszkozon",
            updateEverydaySituation: "mindennapi_helyzet_elem_frissitese",
            deleteEverydaySituation: "mindennapi_helyzet_elem_torlese",
            disableEverydaySituation: "mindennapi_helyzet_elem_tiltasa",
            enableEverydaySituation: "mindennapi_helyzet_elem_engedelyezese",
            orderEverydaySituationDirectories: "mindennapi_helyzetek_mappainak_sorbarendezese",
            createFaqItem: "gyik_elem_letrehozasa",
            updateFaqItem: "gyik_elem_frissitese",
            deleteFaqItem: "gyik_elem_torlese",
            orderFaqItems: "gyik_elemek_sorbarendezese",
            createEducationContent: "tananyag_tartalom_letrehozasa",
            updateEducationContent: "tananyag_tartalom_frissitese",
            deleteEducationContent: "tananyag_tartalom_torlese",
            createEducationContentModule: "tananyag_modul_letrehozasa",
            updateEducationContentModule: "tananyag_modul_frissitese",
            deleteEducationContentModule: "tananyag_modul_torlese",
            orderEducationContentModules: "tananyag_modulok_sorbarendezese",
            orderEducationContents: "tananyag_tartalmak_sorbarendezese",
            shareLibraryContent: "tartalom_megosztas",
            shareLibraryContentToPublic: "mintatartalom_felajanlas",
            acceptLibraryContentPrivateShareRequest: "tartalom_megosztas_elfogadasa",
            acceptLibraryContentPublicShareRequest: "mintatartalom_felajanlas_elfogadasa",
            rejectLibraryContentPrivateShareRequest: "tartalom_megosztas_elutasitasa",
            rejectLibraryContentPublicShareRequest: "mintatartalom_felajanlas_elutasitasa",
            createOAuthClient: "oauth_kliens_letrehozasa",
            updateOAuthClient: "oauth_kliens_frissitese",
            disableOAuthClient: "oauth_kliens_tiltasa",
            enableOAuthClient: "oauth_kliens_engedelyezese",
            deleteOAuthClient: "oauth_kliens_torlese",
            revokeOAuthToken: "oauth_token_visszavonasa",
        },
        boolean: {
            true: "Igen",
            false: "Nem",
        },
        clientDeviceSyncStatus: {
            pending: "Frissítésre vár",
            updated: "Frissítve",
        },
        contentDeviceSyncStatus: {
            syncNotSelected: "Nincs az adatfrissítésre jelölve",
            syncSelectedButUnsynced: "Adatfrissítésre jelölve, de még nem frissült a mobilra",
            syncSelectedButOutdatedContent: "Adatfrissítésre jelölve, de a mobilon még régebbi változat van",
            synced: "Frissült, a mobilon ez fut jelenleg",
            unknown: "Ismeretlen",
        },
        moodMeterEmotion: {
            Calmness: "Nyugalom",
            Anger: "Düh",
            Loathing: "Utálat",
            Sadness: "Szomorúság",
            Fear: "Félelem",
            Happiness: "Vidámság",
            Boredom: "Unalom",
            Constraint: "Feszélyezettség",
            Disappointment: "Csalódottság",
            Disgust: "Undor",
            Excitement: "Izgatottság",
            Fatigue: "Fáradtság",
            Indifference: "Közönbösség",
            Pain: "Fájdalom",
            Pride: "Büszkeség",
            Puzzlement: "Zavarodottság",
            Shame: "Szégyen",
            Surprise: "Meglepettség",
            Worry: "Aggodalom",
        },
        itemAwardType: {
            instant: "Azonnali jutalmak",
            score: "Pontgyűjtős",
        },
        everydaySituationType: {
            text: "Szöveges tartalom hozzáadása",
            audio: "Hangfájl hozzáadása",
            image: "Képes tartalom hozzáadása",
        },
        tagCategory: {
            by_action: "Tevékenység szerint",
            by_age: "Életkor",
            by_detail: "Részletesség szerint",
            by_form: "Forma és médiatípus szerint",
            by_function: "Funkciók szerint",
            by_gender: "Nem szerint",
            by_user: "Felhasználó szerint",
        },
        flowchartItemType: {
            Simple: "Egyszerű",
            Select: "Választós",
        },
        moodMeterQuestion: {
            null: "Mit éreztem?",
            k1: "Mit érzek?",
            k2: "Hogyan éreztem magam?",
            k3: "Hogyan érzem magam?",
            k4: "Egyedi kérdés",
        },
    },

    fileSize: {
        B: "{size} B",
        KB: "{size} KB",
        MB: "{size} MB",
        GB: "{size} GB",
    },

    error: {
        api: {
            UNKNOWN: "Sajnáljuk, hiba történt!",
            FORBIDDEN: "Önnek nincs jogosultsága ehhez a művelethez!",
            UNAUTHORIZED: "Azonosítási hiba!",
            UNAUTHENTICATED: "Azonosítási hiba!",
            VALIDATION: "Hiba történt, kérjük ellenőrizze a mezőket!",
            ACCOUNT_NOT_FOUND: "Nem található a megadott email cím!",
            USER_ALREADY_EXISTS: "Ilyen email címmel már szerepel felhasználó az adatbázisban!",
            INVALID_RESPONSE: "Érvénytelen válasz!",
            NETWORK_ERROR: "Hálózati hiba!",
            BAD_USER_INPUT: "Helytelen formátum!",
            ACCOUNT_ALREADY_ACTIVATED: "Ez a fiók már aktiválva van!",
            REQUEST_TIMEOUT: "Kérés időtúllépés miatt megszakadt!",
            NOT_FOUND: "A kért tartalom nem található!",
            ACCOUNT_NOT_DELETABLE_CLIENT_EXIST: "A profil nem törölhető amíg van hozzárendelt felhasználó.",
            FILE_TOO_BIG: "A fájl mérete túl nagy!",
            ACCOUNT_DISABLED: "Sajnáljuk, de felhasználói fiókját letiltották!",
            ACCOUNT_NOT_ACTIVATED: "Fiók nincs aktiválva!",
            ACCOUNT_NOT_DISABLED: "Fiók nincs letiltva!",
            CLIENT_NOT_FOUND: "Felhasználó nem található!",
            ASSET_NOT_FOUND: "Tartalom nem található!",
            CLIENT_PRIZE_NOT_FOUND: "Felhasználó jutalom nem található!",
            CLIENT_ACCOUNT_RELATION_NOT_FOUND: "Felhasználó-támogató kapcsolat nem található!",
            AGENDA_NOT_FOUND: "Napirend nem található!",
            AGENDA_ITEM_NOT_FOUND: "Napirendi pont nem található!",
            FLOWCHART_NOT_FOUND: "Folyamatábra nem található",
            FLOWCHART_ITEM_NOT_FOUND: "Folyamatábra lépés nem található!",
            PRIZE_NOT_FOUND: "Jutalom nem található!",
            CHECKLIST_ITEM_NOT_FOUND: "Feladatlista elem nem található!",
            CHECKLIST_ITEMS_NOT_FOUND: "Feladatlist elemek nem találhatóak!",
            PRIZE_ITEM_NOT_FOUND: "Jutalom elem nem található!",
            DUPLICATED_POSITIONS: "Duplikált pozíciók!",
            SEARCH_LIST_TYPE_NOT_FOUND: "Keresési lista típusa nem található!",
            SHARE_REQUEST_ALREADY_CREATED: "Ez a személy már meg van hívva!",
            AGENDA_ASSIGNED_TO_DATE: "Ez a napirend már naphoz van rendelve!",
            CLIENT_DAY_AGENDA_ALREADY_EXISTS: "Ehhez a naphoz már tartozik napirend!",
            DEVICE_NOT_FOUND: "Készülék nem található!",
            NOT_IMPLEMENTED: "Sajnáljuk, hiba történt!",
            AGENDA_DISABLED: "Ez a napirend már tiltva van!",
            AGENDA_ITEM_DISABLED: "Ez a napirendi lépés már tiltva van!",
            FLOWCHART_DISABLED: "Ez a folymatábra már tiltva van!",
            FLOWCHART_ITEM_DISABLED: "Ez a folyamatábra lépés már tiltva van!",
            INTERNAL_SERVER_ERROR: "Ismeretlen hiba történt!",

            ACCOUNT_NOT_DELETABLE_CLIENT_EXISTS: "A profil nem törölhető, amíg tartozik hozzá felhasználó!",
            AGENDA_NOT_DISABLED: "Napirend nincs letiltva!",
            AGENDA_ITEM_NOT_DISABLED: "Napirendi lépés nincs letiltva!",
            FLOWCHART_NOT_DISABLED: "Folyamatábra nincs letiltva!",
            FLOWCHART_ITEM_NOT_DISABLED: "Folyamatábra lépés nincs letiltva!",
            ALREADY_PUBLISHED: "Az adott tartalom már megosztásra került!",
            AGENDA_ITEM_TYPE_NOT_SIMPLE: "Napirend típusa nem 'Egyszerű'!",
            TOO_FEW_PROPERTY: "Nincs elegendő tulajdonság megadva!",
            SELECTION_BOARD_NOT_FOUND: "'Lehetőségek tára' tábla nem található!",
            SELECTION_BOARD_ITEM_NOT_FOUND: "'Lehetőségek tára' tábla elem nem található!",
            TOO_MUCH_SELECTION_BOARD_ITEMS: "Túl sok 'Lehetőségek tára' tábla elem!",
            TOO_FEW_SELECTION_BOARD_ITEMS: "Túl kevés 'Lehetőségek tára' tábla elem!",
            STORAGE_SIZE_LIMIT_EXCEEDED: "Nincs elegendő tárhely!",

            NOTIFICATION_CONTENT_TYPE_NOT_FOUND: "Ismeretlen értesítési tartalomtípus!",
            DELETE_DEFAULT_AGENDA_NOT_ALLOWED: "Tilos az alapértelmezett napirend törlése!",
            DELETE_AGENDA_ITEM_FLOWCHART_NOT_ALLOWED: "Tilos a napirendi lépés folyamatábrájának a törlése!",
            DELETE_RELATED_AGENDA_ITEM_NOT_ALLOWED: "Tilos a csatolt napirendi lépés törlése!",
            DELETE_ASSET_NOT_ALLOWED: "Tilos a médiaelem törlése!",
            CLIENT_CHANGE_MANAGER_REQUEST_NOT_FOUND: "Főtámogatói felkérés nem található!",
            CLIENT_CHANGE_MANAGER_REQUEST_ALREADY_CREATED: "Egyszerre legfeljebb egy főtámogatói felkérés lehet érvényben!",

            EVERYDAY_SITUATION_NOT_FOUND: "Mindennapi helyzet nem található!",
            EVERYDAY_SITUATION_DIRECTORY_NOT_FOUND: "Mindennapi helyzet nem található!",
            EVERYDAY_SITUATION_NOT_ALLOWED_IN_CLIENT_DIRECTORY: "A kért művelet nem engedélyezett a felhasználó mappájában!",
            EVERYDAY_SITUATION_NOT_ALLOWED_OUTSIDE_CLIENT_DIRECTORY: "A kért művelet nem engedélyezett a felhasználó mappáján kívül!",
            EVERYDAY_SITUATION_DIRECTORY_INVALID_FLAGS: "Mindennapi helyzet mappa érvénytelen zászlók",

            FLOWCHART_USED_IN_SORTING_GAME: "A folyamatábra már szerepel egy sorbarendező játékban!",
            FLOWCHART_NOT_USABLE_IN_SORTING_GAME: "A folyamatábra nem használható sorbarendező játékban!",
            FLOWCHART_ITEM_TOO_FEW_FOR_SORTING_GAME: "Túl kevés a folyamatábra lépés sorbarendező játékhoz!",
            FLOWCHART_ITEM_TOO_MANY_FOR_SORTING_GAME: "Túl sok a folyamatábra lépés sorbarendező játékhoz!",
            FLOWCHART_ITEM_DOES_NOT_HAVE_IMAGE: "Egy vagy több folyamatábra lépés nem rendelkezik képpel!",
            FLOWCHART_CONTAINS_BRANCH: "A folyamatábra tartalmaz egy, vagy több elágazást.!",
            FLOWCHART_INVALID_BRANCH_ITEM_POSITION: "A folyamatábra elágazásának sorrendje hibás!",
            FLOWCHART_EMBED_NOT_ALLOWED: "A folyamatábra beágyazás nem engedélyezett!",
            FLOWCHART_ITEM_TYPE_CHANGE_NOT_ALLOWED: "A folyamatábra lépés megváltoztatása nem engedélyezett!",
            FLOWCHART_ITEM_TYPE_NOT_SELECT: `A folyamatábra lépés típusa nem "választós"!`,
            FLOWCHART_ITEM_TYPE_NOT_SIMPLE: `A folyamatábra lépés típusa nem "egyszerű"!`,
            FLOWCHART_ONLY_ONE_EMBEDDED_FLOWCHART_ENABLED: "Kizárólag egy folyamatábra beágyazása engedélyezett!",
            FLOWCHART_ONLY_ONE_SELECTION_POINT_ENABLED: "Kizárólag egy választó pont engedélyezett!",

            LIBRARY_CONTENT_CAN_NOT_BE_SHARED: "A kért tartalmi elem nem osztható meg!",
            LIBRARY_CONTENT_NOT_FOUND: "Tartalmi elem nem található!",
            LIBRARY_CONTENT_SHARE_REQUEST_NOT_FOUND: "Megosztási kérelem nem található",
            LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_CREATED: "A megosztási kérelem már létre lett hozva!",
            LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_ACCEPTED: "A megosztási kérelem már el lett fogadva!",
            LIBRARY_CONTENT_SHARE_REQUEST_ALREADY_REJECTED: "A megosztási kérelem már el lett utasítva!",

            TAG_ALREADY_EXISTS: "A címke már létezik!",
            TAG_NOT_FOUND: "A címke nem található!",

            AGENDA_ITEM_NOT_USABLE_FOR_SPARE: "Napirend nem használható tartalék lépésként!",
            AGENDA_ITEM_TYPE_CHANGE_NOT_ALLOWED: "Napirendi lépés típusa nem változtatható!",
            AGENDA_ITEM_TYPE_NOT_SELECT: "Napirendi lépés típusa nincs kiválasztva!",
            ASSET_DIRECTORY_INVALID_PARENT: "Érvénytelen főkönyvtár!",
            ASSET_DIRECTORY_NOT_EMPTY: "Mappa nem üres!",
            ASSET_DIRECTORY_NOT_FOUND: "Mappa nem található!",
            ASSET_DIRECTORY_TOO_DEEP: "További mappabeágyazás nem engedélyezett!",
            ASSET_DISABLED: "Méddiaelem tiltva van!",
            ASSET_NOT_ALLOWED_TO_REPLACE: "Médiaelem cseréje nem megengedett!",
            ASSET_NOT_DISABLED: "Médiaelem nincs tiltva!",
            AWARD_DISABLED: "A jutalom tiltva van!",
            AWARD_NOT_DISABLED: "A jutalom nincs tiltva!",
            AWARD_NOT_FOUND: "Jutalom nem található!",
            CLIENT_ALREADY_SELF_SUPPORTING: "A támogatott már önrendelkező!",
            CLIENT_CHANGE_NOT_ALLOWED: "Főtámogatói jog átadása nem megengedett!",
            CLIENT_PROFILE_CLASSIFICATION_FAILED: "Támogatott besorolása nem sikerült!",
            CLIENT_SHARE_REQUEST_ALREADY_CREATED: "Együttműködő támogató már meg van hívva!",
            CLIENT_TOO_YOUNG_FOR_SELF_SUPPORTING_DECISION: "Kiskorú nem lehet önrendelkező!",
            DELETE_AGENDA_ITEM_SELECTION_BOARD_NOT_ALLOWED: "Ezt a választótáblát nem lehet törölni, mert hozzá van rendelve olyan napirendi lépéshez, amelyet a felhasználó személy használ.",
            DELETE_EVERYDAY_SITUATION_ASSET_NOT_ALLOWED: "Mindennapi helyzet médiaelem törlése nem engedélyezett!",
            DELETE_EVERYDAY_SITUATION_CLIENT_DIRECTORY_NOT_ALLOWED: "Mindennapi helyzet mappa törlése nem engedélyezett!",
            DELETE_EVERYDAY_SITUATION_NOT_ALLOWED: "Mindennapi helyzet törlése nem engedélyezett",
            DELETE_FLOWCHART_ITEM_FLOWCHART_NOT_ALLOWED: "Törlés nem engedélyezett",
            DELETE_SORTING_GAME_FLOWCHART_NOT_ALLOWED: "Törlés nem engedélyezett",
            DELETE_SPARE_AGENDA_ITEM_NOT_ALLOWED: "Törlés nem engedélyezett",
            DELETE_RELATED_FLOWCHART_ITEM_NOT_ALLOWED: "Törlés nem engedélyezett",
            DISABLED_CONTENT_LIST_TYPE_NOT_FOUND: "Lista típusa nem található!",
            DISABLED_ENTITY_IN_SYNC_DATA: "Tiltott elem miatt nem lehetséges a frissítés!",
            EDUCATION_CONTENT_NOT_FOUND: "Tartalom nem található!",
            EDUCATION_CONTENT_MODULE_NOT_FOUND: "Tartalom nem található!",
            EDUCATION_CONTENT_MODULE_TOO_DEEP: "További mappabeágyazás nem engedélyezett!",
            EDUCATION_CONTENT_MODULE_NOT_MODIFIABLE: "Tartalom nem módosítható!",
            EDUCATION_CONTENT_MODULE_NOT_DELETABLE: "Tartalom nem törölhető!",
            EDUCATION_CONTENT_MODULE_NOT_IN_PARENT_MODULE: "Módosítási hiba!",
            EDUCATION_CONTENT_NOT_IN_MODULE: "Módosítási hiba!",
            EVERYDAY_SITUATION_DIRECTORY_DISABLED: "Mappa tiltva van!",
            EVERYDAY_SITUATION_DIRECTORY_NOT_DISABLED: "Mappa nincs tiltva!",
            EVERYDAY_SITUATION_DIRECTORY_ORDERING_NOT_ALLOWED: "Sorbarendezés nem engedélyezett!",
            EVERYDAY_SITUATION_DISABLED: "Tartalom tiltva!",
            EVERYDAY_SITUATION_NOT_DISABLED: "Tartalom nincs tiltva!",
            FAQ_ITEM_NOT_FOUND: "Elem nem található",
            FLOWCHART_SELECT_ITEM_WITH_INVALID_BRANCH: "Módosítási hiba!",
            INSTANT_AWARD_DISABLED: "Elem tiltva van!",
            INSTANT_AWARD_NOT_DISABLED: "Elem nincs tiltva!",
            INSTANT_AWARD_NOT_FOUND: "Elem nem található!",
            INSTANT_AWARD_ALLOWED_ONLY_IN_CLIENT_LIBRARY: "Módosítás nem engedélyezett",
            INVALID_ASSET_TYPE: "Érvénytelen típus!",
            INVALID_EDUCATION_CONTENT_TYPE: "Érvénytelen típus!",
            INVALID_AUDIO_CODEC: "Érvénytelen formátum!",
            INVALID_CLIENT_QUESTIONNAIRE_ANSWERS: "Érvénytelen kérdőív!",
            INVALID_ITEM_ASSET_CONFIGURATION: "Érvénytelen beállítások!",
            INVALID_POSITION: "Érvénytelen pozíció!",
            INVALID_VIDEO_RESOLUTION: "Érvénytelen videó felbontás!",
            SELECTION_BOARD_EMBED_NOT_ALLOWED: "Választó tábla beágyazása nem engedélyezett!",
            SELECTION_BOARD_DISABLED: "Elem tiltva van!",
            SELECTION_BOARD_ITEM_NO_CONTENT: "Hiányzó tartalom!",
            SELECTION_BOARD_NOT_DISABLED: "Elem nincs tiltva!",
            SELF_SUPPORTING_STATE_ALREADY_DECIDED: "Nyilatkozat leadása már megtörtént!",
        },
        validation: {
            invalidPassword: "Érvénytelen jelszó!",
            passwordTooShort: `A jelszónak legalább ${ValidatorConstants.MIN_PASSWORD_LENGTH} karakter hosszúnak kell lennie!`,
            passwordsNotMatch: "Jelszavak nem egyeznek!",
            invalidEmail: "Érvénytelen email-cím!",
            invalidName: `Érvénytelen név! Legalább ${ValidatorConstants.CLIENT_NAME_LENGTH_MIN}, maximum ${ValidatorConstants.CLIENT_NAME_LENGTH_MAX} karakternek kell lennie!`,
            invalidPin: "Érvénytelen pinkód! A pinkódnak 4 számjegyből kell állnia!",
            clientTooYoung: `A felhasználó életkora legalább ${ValidatorConstants.CLIENT_MIN_AGE} évnek kell lennie!`,
            clientTooOld: `A felhasználó életkora legfeljebb ${ValidatorConstants.CLIENT_MAX_AGE} év lehet!`,
            introductionTooLong: `A bemutatkozás legfeljebb ${ValidatorConstants.CLIENT_INTRODUCTION_MAX_LENGTH} karakter lehet!`,
            required: "A mező kitöltése kötelező!",
            invalidUnlockPattern: `A feloldási mintának legalább ${ValidatorConstants.CLIENT_CONFIG_UNLOCK_MIN_LENGTH}, maximum ${ValidatorConstants.CLIENT_CONFIG_UNLOCK_MAX_LENGTH} hosszúnak kell lennie!`,
            avatarFileTooBig: `A profilkép mérete legfeljebb ${ValidatorConstants.AVATAR_IMAGE_MAX_HEIGHT}x${ValidatorConstants.AVATAR_IMAGE_MAX_WIDTH} képpont és 2 megabájt lehet!`,
            invalidAssetName: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.ASSET_NAME_LENGTH_MIN}, maximum ${ValidatorConstants.ASSET_NAME_LENGTH_MAX} karakternek kell lennie!`,
            invalidSelectionBoardTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.SELECTION_BOARD_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.SELECTION_BOARD_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidSelectionBoardItemsCount: `Érvénytelen elemszám! Legalább ${ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MIN}, maximum ${ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MAX} elemnek kell lennie!`,
            invalidSelectionBoardItem: "Érvénytelen elem! Legalább egy szövegnek kell tartoznia hozzá!",
            invalidSelectionBoardItemTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.SELECTION_BOARD_ITEM_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.SELECTION_BOARD_ITEM_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidFlowchartItemTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.FLOWCHART_ITEM_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.FLOWCHART_ITEM_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidFlowchartItemFlowchartItemsLength: "Két folyamatábra lépést kell csatolni!",
            invalidChecklistItemTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.CHECKLIST_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.CHECKLIST_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidAwardScore: `Érvénytelen pontszám! Legalább ${ValidatorConstants.PRIZE_SCORE_MIN}, maximum ${ValidatorConstants.PRIZE_SCORE_MAX} pontnak kell lennie!`,
            invalidTimer: {
                second: `Érvénytelen időtartam! A másodpercnek {min} és {max} közötti értéknek kell lennie!`,
                minute: `Érvénytelen időtartam! A percnek {min} és {max} közötti értéknek kell lennie!`,
                hour: `Érvénytelen időtartam! Az órának {min} és {max} közötti értéknek kell lennie!`,
                day: `Érvénytelen időtartam! A napnak {min} és {max} közötti értéknek kell lennie!`,
            },
            invalidFlowchartTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.FLOWCHART_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.FLOWCHART_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidFlowchartDescription: `Érvénytelen megnevezés! Legfeljebb ${ValidatorConstants.FLOWCHART_DESCRIPTION_LENGTH_MAX} karakter lehet!`,
            invalidAgendaItemTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.AGENDA_ITEM_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.AGENDA_ITEM_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidAgendaItemAlertText: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.AGENDA_ITEM_ALERT_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.AGENDA_ITEM_ALERT_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidAgendaTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.AGENDA_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.AGENDA_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidAgendaDescription: `Érvénytelen leírás! Legfeljebb ${ValidatorConstants.AGENDA_DESCRIPTION_LENGTH_MAX} karakter lehet!`,
            moodMeterItemsTooFew: `Legalább ${ValidatorConstants.MOODMETER_ITEMS_LENGTH_MIN} érzelmet meg kell adni!`,
            moodMeterItemsTooMany: `Egyszerre maximum ${ValidatorConstants.MOODMETER_ITEMS_LENGTH_MAX} érzelmet állíthatsz be!`,
            invalidClientPrizeTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.PRIZE_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.PRIZE_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidClientPrizeTargetTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.PRIZE_TARGET_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.PRIZE_TARGET_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidAlertTypeWithTime: "Amennyiben előre beállított figyelmeztetést választ ki, kérjük válasszon időpontot is!",
            invalidEnabledGamesCount: "Legalább 1 játékot engedélyeznie kell!",
            faqQuestionTooShort: "A kérdésnek legalább 1 karakternek kell lennie!",
            faqAnswerTooShort: "A válasznak legalább 1 karakternek kell lennie!",
            invalidMemoryGamesAssetCount: "Legalább {count} képet hozzá kell adni a játékhoz.",
            invalidEverydaySituationClientDirectoryTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.EVERYDAY_SITUATION_DIRECTORY_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            awardTitleLengthInvalid: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.AWARD_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.AWARD_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            instantAwardTitleLengthInvalid: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.INSTANT_AWARD_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.INSTANT_AWARD_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            awardScoreInvalid: `Érvénytelen érték! Legalább ${ValidatorConstants.AWARD_SCORE_MIN}, maximum ${ValidatorConstants.AWARD_SCORE_MAX} pontnak kell lennie!`,
            agendaItemInstantAwardsNumberInvalid: `Legalább ${ValidatorConstants.AGENDA_ITEM_INSTANT_AWARDS_LENGTH_MIN} jutalmat ki kell választani!`,
            invalidTagTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.TAG_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.TAG_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidSubModuleTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.EDUCATION_CONTENT_MODULE_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidSubModuleVideoTitle: `Érvénytelen megnevezés! Legalább ${ValidatorConstants.EDUCATION_CONTENT_TITLE_LENGTH_MIN}, maximum ${ValidatorConstants.EDUCATION_CONTENT_TITLE_LENGTH_MAX} karakternek kell lennie!`,
            invalidNotification: "Érvénytelen emlékeztető! Legalább egy képnek, hangnak, vagy szövegnek kell lennie!",
            shareRequestEmailNotUnique: "Ezt az email-címet már hozzáadtad!",
        },
        recaptcha: "Regisztrációs hiba történt, kérem próbálja meg később!",
        default: "Sajnáljuk, hiba történt!",
    },

    component: {
        appBar: {
            alt: "AOSZ - CMS",
            title: "<strong>Digitális autonómia-támogatás</strong><br/> az autizmus spektrumon",
            superVisorInfo: "szakmai szupervizor",
            dropDownMenu: {
                profile: "Profilom",
                addClient: "Felhasználó hozzáadása",
                myEventLog: "Eseménynaplóm",
                logout: "Kijelentkezés",
            },
        },
        passwordInput: {
            showPassword: "Jelszó megjelenítése",
            hidePassword: "Jelszó elrejtése",
        },
        navigation: {
            shareRequest: {
                message: "{createdBy} felkért, hogy te is legyél támogatója {client} felhasználónak. Itt tudod a felkérést elfogadni vagy elutasítani",
                accept: "Elfogadás",
                reject: "Elutasítás",
                accepted: "Meghívó elfogadása sikeres",
                rejected: "Meghívó elutasítása sikeres",
            },
            changeClientManagerRequest: {
                message: "{createdBy} felkért, hogy te legyél a főtámogatója {client} felhasználónak. Itt tudod a felkérést elfogadni vagy elutasítani",
                accept: "Elfogadás",
                reject: "Elutasítás",
                accepted: "Főtámogatói meghívó elfogadása sikeres",
                rejected: "Főtámogatói meghívó elutasítása sikeres",
            },
            menu: {
                dashboard: "Kezdőlap",
                clientProfile: "Felhasználói profil",
                clientCalendar: "Naptár",
                clientContentLibrary: "Tartalomtár",
                clientAwards: "Jutalmazás",
                clientSelectionBoard: "Választó",
                clientGames: "Időtöltők",
                everydaySituations: "Mindennapi helyzetek",
                clientEventLog: "Eseménynapló",
                clientSettings: "Beállítások",
                knowledgeBase: "Tudásbázis",
                educationModules: "Útmutatók",
                education: "Útmutató",
                faq: "GYIK",
                users: "A DATA rendszer tagjai",
                adminList: "Admin felhasználók",
                supervisorList: "Szakmai szupervizorok",
                supporterList: "Támogatók",
                clientList: "Felhasználók",
                publicContentLibrary: "Tartalomtárak",
                publicContentLibrarySuperadmin: "Közösségi tár",
                ownContentLibrary: "Saját és közösségi",
                contentShareRequestList: "Felajánlott tartalmak",
                systemEventLog: "Rendszer-eseménynapló",
                clientSpareAgendaItems: "Tartalék lépések",
                tags: "Címkék",
                helpdesk: "Helpdesk",
            },
            logoutDialog: {
                title: "Kijelentkezés",
                description: "Biztosan ki szeretnél jelentkezni?",
                logout: "Kijelentkezés",
            },
        },
        alert: {
            alt: {
                success: "Siker",
                error: "Hiba",
                info: "Információ",
            },
        },
        colorSelect: {
            empty: "Válassz egy színt...",
        },
        fileInput: {
            fileRejected: {
                fileSize: "A '{fileName}' nem csatolható! A feltölthető {fileType} fájl mérete lefeljebb {maxFileSize} lehet!",
                assetType: "A '{fileName}' nem csatolható! A fájl kiterjesztése nem megfelelő!",
            },
        },
        confirmLeaveDialog: {
            logout: {
                title: "Kijelentkezés",
                description: "Ha kijelentkezel, elvesznek a módosításaid! Biztosan ki akarsz jelentkezni?",
            },
            pageLeave: {
                title: "Oldal elhagyása",
                description: "Ha elhagyod az oldalt, elvesznek a módosításaid!",
            },
        },
        videoList: {
            error: `Sajnáljuk, de a böngészője nem támogatja a ott vidókat, de ne aggódjon, a <a href="{url}" download="true">letöltés</a> linkre kattintva lejátszhatja a kedvenc videólejátszójával!`,
            title: "{position} almodul: {title}",
        },
        agendaOptionMenu: {
            setAsDefaultAgenda: {
                label: "Alapértelmezett napirendi sor",
            },
            unsetClientDefaultAgenda: {
                label: "Ne legyen alapértelmezett",
            },
        },
        extraContentOptionMenu: {
            disable: "Tiltás",
            assetRelDisable: "Kapcsolódó médiaelem tiltása",
        },
        contentDeviceList: {
            title: "Mobileszközök",
            noData: "Nincs hozzárendelt eszköz",
            table: {
                columns: {
                    id: "Mobileszköz azonosító",
                    deviceType: "Készülék",
                    appVersion: "Alkalmazás",
                    createdAt: "Összekapcsolás dátuma",
                    status: "Adatfrissítés állapota",
                },
            },
        },
        generalFileInput: {
            fileRejected: "Hiba történt a fájlfeltöltés közben!",
        },
        knowledgeBaseDownloadLink: {
            downloadInProgress: "Letöltés folyamatban",
            downloadPdf: "PDF Letöltése",
        },
    },

    sharedComponent: {
        confirmAvatarDeleteDialog: {
            title: "Profilkép törlése",
            description: "Biztos, hogy törli a profilképet?",
        },
        clientEventLogTable: {
            lead:
                "Itt láthatod {name} aktivitását a DATA rendszerben. A szűrők segítségével részletesen láthatod az aktivitást, például a különböző funkciók használatát akár különböző időszakokban, napszakokban, vagy hogy milyen eszközön használta az alkalmazást.",
            filtersSection: "Szűrők",
            table: {
                columns: {
                    clientExtId: "Felhasználó azonosítója",
                    type: "Esemény megnevezése",
                    title: "Cím",
                    startedAt: "Kezdet",
                    finishedAt: "Vég",
                    value: "Esemény részletei",
                    appVersion: "Alkalmazás",
                    createdAt: "Dátum, idő",
                },
            },
            dateFrom: {
                label: "Kezdő dátum:",
                placeholder: "Válassza ki az esemény kezdődátumát",
            },
            hourFrom: {
                label: "Óra:",
                value: "0:00",
            },
            dateTo: {
                label: "Vég dátum:",
                placeholder: "Válassza ki az esemény végdátumát",
            },
            hourTo: {
                label: "Óra:",
                value: "23:00",
            },
            type: {
                label: "Esemény típusa:",
            },
            queryParams: {
                sortField: "rendezes",
                sortOrder: "sorrend",
                search: "kereses",
                limit: "talalatok_szama",
                page: "oldal",
            },
            sortField: {
                type: "esemeny",
                indexedAt: "index",
                createdAt: "datum",
            },
            noData: "Nincs megjeleníthető adat",
            valueConverter: {
                column: {
                    AccountLogin: {
                        success: "Sikeres:",
                    },
                    AgendaItemStateChange: {
                        id: "Tartalom azonosítója:",
                        checked: "Bepipálva:",
                    },
                    Alert: {
                        type: "Típus:",
                        message: "Szöveg:",
                    },
                    AppUnlock: {
                        success: "Sikeres:",
                    },
                    AwardAchieved: {
                        title: "Megnevezés:",
                    },
                    CheckFlowchartItem: {
                        flowchartId: "Folyamatábra azonosítója:",
                    },
                    CreateSituation: {
                        clientExtId: "Felhasználó azonosítója:",
                        type: "Típus:",
                        types: {
                            image: "Kép",
                            text: "Szöveg",
                            audio: "Hang",
                            text_and_image: "Szöveg és kép",
                        },
                    },
                    DeviceStat: {
                        battery: "Akkumulátor:",
                        charging: "Töltés alatt:",
                        freeAppStorage: "Fennmaradó hely:",
                        network: "Hálózat:",
                    },
                    DownloadAccountData: {
                        duration: "Időtartam:",
                        message: "Szöveg:",
                        size: "Méret:",
                        success: "Sikeres:",
                    },
                    MoodReport: {
                        results: "Válaszok:",
                    },
                    ScoreChanged: {
                        flowchartItemId: "Folyamatábra-lépés azonosítója:",
                        agendaItemId: "Napirendi lépés azonosítója:",
                        score: "Pontszám:",
                        balance: "Összpontszám:",
                    },
                    UploadPushToken: {
                        success: "Sikeres:",
                        duration: "Időtartam:",
                    },
                    UseAgenda: {
                        id: "Azonosító:",
                    },
                    UseNotification: {
                        imageAssetId: "Kép azonosítója:",
                        audioAssetId: "Hangfájl azonosítója:",
                    },
                    UseGame: {
                        title: "Cím:",
                        duration: "Időtartam:",
                    },
                },
                moodReport: {
                    emotionAndIntensity: "Érzelem / Intenzitás:",
                    mood: "Érzelem:",
                    message: "Üzenet:",
                },
                useAgenda: {
                    start: "Elindítás: {start}",
                    end: "Bezárás: {end}",
                },
                scoreChanged: {
                    points: "{score} Pont",
                },
                showMoreText: "Mutass többet",
                showLessText: "Mutass kevesebbet",
            },
            buttonLabels: {
                deleteFilters: "Szűrők törlése",
                applyFilters: "Szűrők alkalmazása",
                switch: {
                    list: "Váltás lista nézetre",
                    chart: "Váltás grafikon nézetre",
                },
            },
            dummyText: {
                from: "-tól",
                dash: "-",
                to: "-ig",
            },
            chartTitle: "Események száma óránként",
            xAxisLabel: "ESEMÉNYEK SZÁMA",
            yAxisLabel: "IDŐ",
        },
        accountDetailStatusForm: {
            accountStatus: {
                label: "Státusz:",
            },
            reason: {
                label: "Felfüggesztés oka:",
                placeholder: "Adja meg a felfüggesztés okát",
                empty: "Nincs megadva",
            },
            saveSucceed: "Módosítások mentése sikeres!",
        },
        deleteAccountConfirmDialog: {
            confirm: {
                title: "Fiók törlése",
                description: "Biztosan törölni akarod a fiókod?",
            },
            confirmAgain: {
                title: "Fiók törlés megerősítése",
                description: "A fiók törlése végleges, biztos hogy törli?",
            },
        },
        contentLibraryTable: {
            libraryType: {
                mine: "Saját táram",
                public: "Közösségi tár",
                client: "{name} tára",
                supported: "{extId} tára",
                supervisor: "{name} tára",
                disabled: "Letiltott tartalmak",
                shared: "Velem megosztottak",
                sharedContents: "Megosztott tartalmi elemek",
            },
            extraContentLibraryType: {
                everydaySituations: "Minidennapi helyzetek",
                instantAward: "Azonnali jutalmak",
                award: "Pontgyüjtéses jutalmazás",
                selectionBoard: "Választók",
            },
            links: {
                agenda: "Napirendi sorok",
                agendaItem: "Napirendi lépések",
                flowchart: "Folyamatábra-sorok",
                flowchartItem: "Folyamatábra lépések",
                asset: "Médiaelemek",
                everydaySituations: "Mindennapi helyzetek",
                instantAward: "Azonnali jutalmazás",
                award: "Pontgyűjtős jutalmazás",
                selectionBoard: "Választók",
            },
            searchForm: {
                placeholder: "keresett kifejezés...",
                tagHeader: "Címkék:",
            },
            tableTitle: "<h2>{library} <span class='fa fa-angle-right'></span> {searchListType}</h2>",
            tableDirectoryTitle: "<h2>{library} <span class='fa fa-angle-right'></span> {searchListType} <span class='fa fa-angle-right'></span> {directoryName}</h2>",
            searchWithTags: {
                label: "Keresés cimkékkel:",
                removeAll: "Összes cimke törlése",
            },
            agendaTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                    day: "hozzarendelt_nap",
                    fileSize: "fajlmeret",
                },
                columns: {
                    title: "Megnevezés",
                    numberOfSteps: "Lépések száma",
                    createdAt: "Létrehozás dátuma",
                    createdByName: "Létrehozta",
                    updatedAt: "Legutóbbi módosítás",
                    info: " ",
                    actions: " ",
                    day: "Hozzárendelt nap",
                },
                options: {
                    assignToDay: "Naphoz rendelés",
                    assignToClient: "Személyhez rendelés",
                },
                defaultAgendaTooltip: "Alapértelmezett napirend",
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            agendaItemTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                    fileSize: "fajlmeret",
                    day: "nap",
                },
                columns: {
                    title: "Név",
                    info: " ",
                    createdAt: "Létrehozás dátuma",
                    createdByName: "Létrehozta",
                    updatedAt: "Legutóbbi módosítás",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            flowchartTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                    fileSize: "fajlmeret",
                },
                columns: {
                    title: "Név",
                    numberOfSteps: "Lépések száma",
                    info: " ",
                    createdAt: "Létrehozás dátuma",
                    createdByName: "Létrehozta",
                    updatedAt: "Legutóbbi módosítás",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            flowchartItemTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                    fileSize: "fajlmeret",
                },
                columns: {
                    title: "Név",
                    info: " ",
                    createdAt: "Létrehozás dátuma",
                    createdByName: "Létrehozta",
                    updatedAt: "Legutóbbi módosítás",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            assetTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                    fileSize: "fajlmeret",
                },
                columns: {
                    title: "Név",
                    type: "Típus",
                    info: " ",
                    fileSize: "Méret",
                    createdAt: "Feltöltés dátuma",
                    createdByName: "Létrehozta",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
                moveAll: "Kijelöltek áthelyezése",
                move: "Áthelyezés",
            },
            disabledItemsTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Megnevezés",
                    type: "Típus",
                    createdAt: "Feltöltés dátuma",
                    actions: " ",
                    info: " ",
                },
                types: {
                    Agenda: "Napirendi sor",
                    AgendaItem: "Napirendi lépés",
                    Flowchart: "Folyamatábra-sor",
                    FlowchartItem: "Folyamatábra lépés",
                    Asset: "Médiaelem",
                    Award: "Jutalom",
                    TextEverydaySituation: "Szöveges mindennapi helyzet",
                    ImageEverydaySituation: "Képes mindennapi helyzet",
                    TextAndImageEverydaySituation: "Szöveges és képes mindennapi helyzet",
                    AudioEverydaySituation: "Hangos mindennapi helyzet",
                    EverydaySituationDirectory: "Mindennapi helyzet mappa",
                    InstantAward: "Azonnali jutalom",
                    SelectionBoard: "Választó tábla",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            everydaySituationTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Név",
                    isDisabled: " ",
                    createdAt: "Feltöltés dátuma",
                    updatedAt: "Módosítás dátuma",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            instantAwardTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Név",
                    isDisabled: " ",
                    createdAt: "Feltöltés dátuma",
                    updatedAt: "Módosítás dátuma",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            selectionBoardTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Név",
                    isDisabled: " ",
                    createdAt: "Feltöltés dátuma",
                    updatedAt: "Módosítás dátuma",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            awardTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    updatedAt: "modositas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Név",
                    isDisabled: " ",
                    targetScore: "Cél pontszám",
                    createdAt: "Feltöltés dátuma",
                    updatedAt: "Módosítás dátuma",
                    actions: " ",
                },
                disabledTooltip: "Ez a tartalom le lett tiltva",
                noData: "Nincs megjeleníthető adat",
            },
            contentShareRequest: {
                table: {
                    sortField: {
                        libraryContentTitle: "nev",
                        libraryContentCreatedAt: "letrehozas_datuma",
                        libraryContentUpdatedAt: "modositas_datuma",
                    },
                    columns: {
                        libraryContentTitle: "Név",
                        libraryContentType: "Típus",
                        libraryContentCreatedAt: "Létrehozás dátuma",
                        libraryContentUpdatedAt: "Utolsó módosítás dátuma",
                        actions: " ",
                    },
                },
                noData: "Nincs megjeleníthető adat",
            },
            storageSizeInUse: "{storageSize} használatban",
        },
        createContentButton: {
            label: "ÚJ TARTALMI ELEM",
            items: {
                createAgenda: "Új napirendi sor létrehozása",
                createAgendaItem: "Új napirendi lépés létrehozása",
                createFlowchart: "Új folyamatábra-sor létrehozása",
                createFlowchartItem: "Új folyamatábra lépés létrehozása",
                createAsset: "Médiaelemek feltöltése",
                createAssetDirectory: "Új mappa hozzáadása",
            },
        },
        uploadAssetDialog: {
            title: "Médiaelem feltöltése",
            description: "Itt töltheted fel azokat a médiaelemeket, amelyekből napirendi és folyamatábra lépéseket, valamint más tartalmi elemeket szerkeszthetsz.",
            file: {
                label: "Fájl:",
                placeholder: "Töltsön fel egy fájlt!",
            },
            name: {
                label: "Médiaelem megnevezése:",
                placeholder: "Megnevezés",
            },
            submitLabel: "Feltöltés",
            uploadSuccess: "Feltöltés sikeres!",
        },
        tagSelect: {
            filterTags: "keresett kifejezés...",
            placeholder: "Adj hozzá címkéket",
            noSelectedTags: "Nincs cimke hozzárendelve",
            tooManyTags: `Maximum ${ValidatorConstants.MAX_TAG_COUNT} címke adható hozzá egy médiaelemhez!`,
        },
        createAssetDirectoryDialog: {
            title: "Médiaelem mappa létrehozása",
            name: {
                label: "Mappa elnevezése:",
                placeholder: "Megnevezés",
            },
            directory: {
                label: "Befogadó mappa kiválasztása:",
                placeholder: "Médiatár",
            },
            submitLabel: "Létrehozás",
        },
        moveAssetsDialog: {
            title: "Médiaelemek áthelyezése",
            description: " elem áthelyezése",
            directory: {
                label: "Befogadó mappa kiválasztása:",
                placeholder: "Médiatár",
            },
            noAsset: "Nincs kijelölt elem.",
            sameDir: "A forrás és a cél mappa megegyezik.",
            submitLabel: "Áthelyezés",
            succeed: "Médiaelem áthelyezés sikeres!",
        },
        contentLibrarySideBar: {
            title: "TARTALOMTÁRAK",
            searchForm: {
                placeholder: "keresett kifejezés...",
            },
            contentType: {
                client: "{name } - tartalomtár",
                mine: "Saját táram",
                public: "Közösségi tár",
            },
            loadMore: "További elemek betöltése...",
            noData: "Nincs megjeleníthető tartalom",
        },
        contentElement: {
            action: {
                view: "Megtekintés",
                edit: "Szerkesztés",
                delete: "Eltávolítás",
            },
            icon: {
                award: "Jutalompont",
                flowchart: "Folyamatábra",
                moodMeter: "Érzelmi hőmérő",
                alert: "Figyelmeztetés",
                selectionBoard: "Választótábla",
                checklist: "Feladatlista",
                notification: "Emlékeztető",
                timer: "Időjelzés",
                time: "Időpont",
                select: "Választós napirendi lépés",
            },
        },
        contentInput: {
            agenda: {
                slotMessage: "Húzz ide egy napirendet!",
            },
            agendaItem: {
                slotMessage: "Húzz ide egy napirendi lépést!",
            },
            flowchart: {
                slotMessage: "Húzz ide egy folyamatábra-sort!",
            },
            flowchartItem: {
                slotMessage: "Húzz ide egy folyamatábra lépést!",
            },
            asset: {
                slotMessage: {
                    avatar: "Húzz ide egy profilképet!",
                    image: "Húzz ide egy képet!",
                    audio: "Húzz ide egy hangot!",
                    video: "Húzz ide egy videót!",
                    waiting_image: "Húzz ide egy várakozó képet!",
                    any: "Húzz ide egy médiaelemet!",
                },
            },
            noContent: "Nincs megjeleníthető elem",
            noImage: "Nincs megadva kép",
            no: {
                image: "Nincs megadva kép",
                audio: "Nincs megadva hang",
                video: "Nincs megadva videó",
                waiting_image: "Nincs megadva várakozó kép",
                any: "Nincs megadva médiaelem",
                avatar: "Nincs megadva profilkép",
            },
        },
        inputs: {
            flowchart: {
                enabled: {
                    label: "Kapcsolódó folyamatábra-sor:",
                },
            },
            checklist: {
                label: "Listázó:",
                placeholder: "Listaelem megnevezése...",
            },
            award: {
                enabled: {
                    label: "Jutalmazás:",
                },
                type: {
                    label: "Típusa:",
                },
                score: {
                    label: "Lépés értéke:",
                },
                useTimer: {
                    label: "Időzítő kapcsolása a jutalomhoz:",
                },
                noAvailableAward: "Nincs elérhető azonnali jutalom! Először vegyél fel új azonnali jutalmat a 'Jutalmazás' menüpontban!",
                instantAwardError: "Legalább 1 jutalmat ki kell választani! Kérjük hozzon létre azonnali 1 vagy több azonnali jutalmat a felhasználónak!",
            },
            timer: {
                enabled: {
                    label: "Időjelző:",
                },
                seconds: {
                    label: "Időtartam:",
                },
                period: {
                    label: "Periódus:",
                },
                skipEnabled: {
                    label: "Tovább lehet lépni mielőtt lejár az idő:",
                },
                endWarning: {
                    label: "Az idő lejártakor legyen figyelmeztetés:",
                },
            },
            selectionBoard: {
                enabled: {
                    label: "Választó",
                },
                board: {
                    label: "Választó tábla",
                    emptyLabel: "Válaszd ki a táblát",
                },
                noAvailableSelectionBoard: "Nincs elérhető választótábla! Először vegyél fel új választótáblát a 'Választó' menüpontban!",
            },
            time: {
                enabled: {
                    label: "Időponthoz rendelés:",
                },
                hoursAndMinutes: {
                    label: "Időpont:",
                },
            },
            alert: {
                displayOptions: {
                    label: "Figyelmeztetés:",
                },
                type: {
                    label: "Típus:",
                },
                notifications: {
                    label: "Jelzés:",
                },
                text: {
                    label: "Szöveg:",
                    placeholder: "Add meg a figyelmeztetés szövegét!",
                },
                isCustomAudio: {
                    label: "HANGJELZÉS HASZNÁLATA",
                },
                audio: {
                    label: "Egyedi hangfájl:",
                },
            },
            moodMeter: {
                display: {
                    label: "Érzelmi hőmérő:",
                },
                title: {
                    label: "Az érzelmi hőmérőnél megjelenő kérdés:",
                    placeholder: "Itt adhatod meg a kérdést, ami megjelenik a mobilon",
                },
            },
            notification: {
                enabled: {
                    label: "Emlékeztető",
                },
                image: {
                    label: "Kép:",
                },
                audio: {
                    label: "Hangfájl:",
                },
                text: {
                    label: "Szöveg:",
                    placeholder: "Add meg az emlékeztető szövegét!",
                },
            },
        },
        deleteAgendaItem: {
            title: "Napirendi lépés törlése",
            description: "Biztosan törölni akarja a `{title}` nevű napirendi lépést?",
            succeed: "Napirendi lépés törlése sikeres",
        },
        deleteAssetDialog: {
            title: "Médiaelem törlése",
            description: "Biztosan törölni akarja a `{title}` nevű médiaelemet?",
            succeed: "Médiaelem törlése sikeres!",
        },
        deleteAgendaDialog: {
            title: "Napirend törlése",
            description: "Biztosan törölni akarja a `{title}` nevű napirendet?",
            succeed: "Napirend törlése sikeres!",
        },
        deleteFlowchartItemDialog: {
            title: "Folyamatábra lépés törlése",
            description: "Biztosan törölni akarja a `{title}` nevű folyamatábra lépést?",
            succeed: "Folyamatábra lépés törlése sikeres",
        },
        deleteFlowchartDialog: {
            title: "Folyamatábra törlése",
            description: "Biztosan törölni akarja a `{title}` nevű folyamatábrát?",
            succeed: "Folyamatábra törlése sikeres",
        },
        assignToDayDialog: {
            title: "Naphoz rendelés",
            placeholder: "Adjon meg egy dátumot",
            succeed: "Naphoz rendelés sikeres!",
            nameInputTitle: "Felhasználó személy neve:",
            dateInputTitle: "A nap kiválasztása:",
            assignButtonTitle: "Hozzárendelés",
        },
        syncDialog: {
            title: "Tartalom frissítése",
            description: "Biztosan frissíteni akarja?",
            warning: "Figyelem! Ez minden mentett tartalmi elemet érint, nem csak a jelenlegit!",
            sync: "Adatfrissítés",
            syncSucceed: "Adatok frissítése eszközökre sikeres!",
        },
        syncWarningDialog: {
            title: "Szinkronizációs hiba",
            description:
                "Az adatfrissítés nem lehetséges, mert a frissíteni kívánt tartalmak közt a szupervízor által letiltott elem szerepel. A tartalmi elem javítását követően a tiltás feloldódik, akkor újra megkísérelheted az adatfrissítést.",
            continueButton: "TOVÁBB A LETILTOTT ELEMEK MAPPÁRA",
        },
        assignToClientDialog: {
            title: "Felhasználó személyhez rendelés",
            form: {
                title: "Hozzárendelés adatai",
                type: {
                    label: {
                        Agenda: "Napirend:",
                        AgendaItem: "Napirendi lépés:",
                        Flowchart: "Folyamatábra-sor:",
                        FlowchartItem: "Folyamatábra lépés:",
                    },
                },
                client: {
                    label: "Felhasználó személy neve:",
                },
                assignButton: "Hozzárendelés",
            },
            noClient: "Nincs kiválasztható felhasználó",
            succeed: "Hozzárendelés sikeres!",
        },
        setDefaultAgendaDialog: {
            title: "Alapértelmezett napirend beállítása",
            description: "Biztosan a(z) `{name}` nevű napirend legyen az alapértelmezett napirend?",
            succeed: "Alapértelmezett napirend beállítása sikeres",
        },
        unsetClientDefaultAgendaDialog: {
            title: "Alapértelmezett napirend beállítása",
            description: "Biztos le szeretnéd venni az alapértelmezett jelölést a napirendi sorról?",
            succeed: "Alapértelmezettnek jelölés eltávolítása sikeres",
        },
        shareContentDialog: {
            title: "Megosztás",
            form: {
                description: "Itt megoszthatod közösségi tartalomként a kiválasztott tartalmat. Ezután ez a tartalom bármelyik támogató számára elérhető lesz.",
                shareType: {
                    label: "Megosztás módja:",
                },
                publish: {
                    description: "Itt ajánlhatsz fel tartalmakat a közösségi tárba.",
                    supporterDescription: " A közösségi tárba való felvételről a DATA rendszer szupervízora dönt.",
                    comment: {
                        label: "Megjegyzés:",
                        placeholder: "Az itt megadott információk (pl. megadhatod a neved vagy e-mail címed) látszanak majd a közösségi tárban rólad, mint a tartalom létrehozójáról.",
                    },
                    privacyAccept: {
                        label:
                            "Csak olyan tartalmakat ossz meg, amelyek megfelelnek a DATA rendszer szakmai és etikai szabályainak. A felajánlás véglegesítésével tudomásul veszed, hogy a tartalmat más támogatók is használhatják, de a szabályok betartása téged terhel. Ez nem vonatkozik arra, ha mások továbbszerkesztik a tartalmadat. A közösségi tárba való bekerülést szupervízor bírálja el, aki szerkesztheti is a tartalmat.",
                    },
                    button: {
                        label: "Felajánlás közösségi tárba",
                    },
                },
                custom: {
                    description: "Itt oszthatsz meg tartalmakat más támogatókkal.",
                    email: {
                        label: "Címzettek hozzáadása:",
                        placeholder: " Írd be azoknak az e-mail címeit, akikkel a tartalmad meg szeretnéd osztani!",
                    },
                    button: {
                        label: "Megosztás elküldése",
                    },
                },
                error: {
                    accountNotFound: "Ez az e-mail cím még nem található a rendszerben, ellenőrizd, hogy jó címet adtál-e meg.",
                },
            },
            confirmDescription:
                "Csak olyan tartalmakat ossz meg, amelyek megfelelnek a DATA rendszer szakmai és etikai szabályainak. A megosztás véglegesítésével tudomásul veszed, hogy a tartalmat más támogatók is használhatják, de a szabályok betartása téged terhel. Ez nem vonatkozik arra, ha mások továbbszerkesztik a tartalmadat.",
            succeed: "Sikeres megosztás.",
        },
        contentTogglerDialog: {
            enable: {
                title: {
                    Agenda: "Napirend engedélyezése",
                    AgendaItem: "Napirendi lépés engedélyezése",
                    Flowchart: "Folyamatábra engedélyezése",
                    FlowchartItem: "Folyamatábra lépés engedélyezése",
                    Asset: "Médiaelem engedélyezése",
                    EverydaySituationDirectory: "Mindennapi helyzet mappa engedélyezése",
                    TextEverydaySituation: "Szöveges mindennapi helyzet engedélyezése",
                    ImageEverydaySituation: "Képes mindennapi helyzet engedélyezése",
                    TextAndImageEverydaySituation: "Szöveges és képes mindennapi helyzet engedélyezése",
                    AudioEverydaySituation: "Hangos mindennapi helyzet engedélyezése",
                    InstantAward: "Azonnali jutalom engedélyezése",
                    Award: "Pontgyűjtős jutalom engedélyezése",
                    SelectionBoard: "Választótábla engedélyezése",
                },
                description: "Biztosan engedélyezi a `{name}` nevű tartalmi elemet?",
                enableSucceed: "Engedélyezés sikeres!",
                enable: "Engedélyezés",
            },
            disable: {
                title: {
                    Agenda: "Napirend tiltása",
                    AgendaItem: "Napirendi lépés tiltása",
                    Flowchart: "Folyamatábra tiltása",
                    FlowchartItem: "Folyamatábra lépés tiltása",
                    Asset: "Médiaelem tiltása",
                    EverydaySituationDirectory: "Mindennapi helyzet mappa tiltása",
                    EverydaySituation: "Mindennapi helyzet elem tiltása",
                    TextEverydaySituation: "Szöveges mindennapi helyzet tiltása",
                    ImageEverydaySituation: "Képes mindennapi helyzet tiltása",
                    TextAndImageEverydaySituation: "Szöveges és képes mindennapi helyzet tiltása",
                    AudioEverydaySituation: "Hangos mindennapi helyzet tiltása",
                    InstantAward: "Azonnali jutalom tiltása",
                    Award: "Pontgyűjtős jutalom tiltása",
                    SelectionBoard: "Választótábla tiltása",
                },
                description: "Biztosan tiltja a `{name}` nevű tartalmi elemet?",
                disable: "Tiltás",
                disableSucceed: "Tiltás sikeres!",
            },
        },
        contentPageButtons: {
            assignToClient: "Személyhez rendelés",
            sync: "Adatfrissítés",
        },
        tooltip: {
            agendaItem: {
                award: {
                    score: "Pontok száma: {score}",
                    requestConfirmation: "Támogatói azonosítás: {requestConfirmation}",
                },
                moodMeter: {
                    display: "Megjelenítés: {display}",
                    title: "Szöveg: {title}",
                },
                alert: {
                    display: "Megjelenítés: {display}",
                    type: "Típus: {type}",
                    notifications: "Jelzés: {notifications}",
                    title: "Szöveg: {title}",
                },
                timer: {
                    seconds: "Időtartam: {seconds}",
                    requestConfirmation: "Azonosítás az időtartam megváltoztatásához: {requestConfirmation}",
                    skipEnabled: "Továbblépési lehetőség: {skipEnabled}",
                    extendTimeEnabled: "Időtartam meghosszabbítás: {extendTimeEnabled}",
                    pauseEnabled: "Szüneteltethető: {pauseEnabled}",
                    endWarning: "Idő lejártakor figyelmeztetés: {endWarning}",
                },
                time: "",
                selectionBoard: "Választótábla: {selectionBoard}",
            },
        },
        verifyChangeClientManagerDialog: {
            title: "Főtámogatói jog átvétele",
            description:
                "Ha elfogadod a felkérést, te leszel {clientName} fő támogatója. Ez azt jelenti, hogy {clientName} néhány alapadatát csak te tudod majd szerkeszteni, csak te tudod {clientName} profilját törölni és csak te tudsz meghívni {clientName} számára együttműködő támogatókat vagy törölni őket. Az eddigi, együttműködő támogatóként létrehozott tartalmaid és szerkesztési jogosulságaid is megmardnak. Amennyiben erről nem történt köztetek egyeztetés, kérjük egyeztess erről {supporterName} támogatóval.",
            accept: {
                mainButton: "Felkérés elfogadása",
                secondaryButton: "Visszautasítás",
            },
            reject: {
                mainButton: "Felkérés visszautasítása",
                secondaryButton: "Elfogadás",
            },
            later: "Később döntök",
        },
        confirmUploadAssetDeleteDialog: {
            title: {
                introduction_audio: "Hangfájl törlése",
                introduction_video: "Videó törlése",
            },
            description: {
                introduction_audio: "Biztos, hogy törli a hangfájlt?",
                introduction_video: "Biztos, hogy törli a videót?",
            },
        },
        deleteEverydaySituationDialog: {
            title: "Mindennapi helyzetek elem törlése",
            description1: "Biztosan törlöd a kijelölt mindennapi helyzetek elemet?",
            succeed: "Mindennapi helyzetek elem törlése sikeres",
        },
        deleteEverydaySituationDirectoryDialog: {
            title: "Mindennapi helyzetek mappa törlése",
            description1: "Biztosan törlöd a kijelölt mappát?",
            succeed: "Mindennapi helyzetek mappa törlése sikeres",
        },
        verifyDeleteFaqDialog: {
            title: "Kérdés törlése",
            description: "Ez a kérdés fontos lehet a DATA rendszer használói számára. Biztosan törlöd?",
            deleteButtonLabel: "Végleges törlés",
            success: "Kérdés törölve!",
        },
        verifyDeleteSelectionBoardDialog: {
            title: "Választótábla törlése",
            description: "Biztosan törölni akarja a `{title}` nevű választót?",
            deleteButtonLabel: "Végleges törlés",
            success: "Választótábla törölve!",
        },
        moveEverydaySituationDialog: {
            title: "Elem áthelyezése",
            moveSucceed: "Elem áthelyezése sikeres!",
            form: {
                directory: {
                    label: "Célmappa kiválasztása",
                    emptyLabel: "Válassz mappát ...",
                },
            },
            move: "Áthelyezés",
        },
        copyEverydaySituationDialog: {
            title: "Elem másolása",
            copySucceed: "Elem másolása sikeres!",
            form: {
                warning: "Még nem hoztál létre mappát, ahova be lehet másolni az elemet. Hozz létre legalább egy mappát és ismételd meg a másolást!",
                directory: {
                    label: "Célmappa kiválasztása",
                    emptyLabel: "Válassz mappát ...",
                },
            },
            copy: "Másolás",
            add: "Mappa hozzáadása",
        },
        verifyDeleteClientInstantAwardDialog: {
            title: "Azonnali jutalom törlése",
            descriptionLineReferences: {
                true: "Ez a jutalom be van állítva napirendi lépéshez vagy folyamatábrához, ha törlöd, onnan is törlődni fog. Biztosan törlöd?",
                false: "Biztosan törlöd a kijelölt jutalmat?",
            },
            deleteButtonLabel: "Végleges törlés",
            success: "Azonnali jutalom törölve!",
        },
        verifyDeleteClientAwardDialog: {
            title: "Pontgyűjtős jutalom törlése",
            descriptionLineActive: {
                false: "Biztosan törlöd a kijelölt jutalmat?",
                true: "Ez a jutalom aktív, a jutalmat éppen használhatja {name}, biztosan törlöd?",
            },
            deleteButtonLabel: "Végleges törlés",
            success: "Pontgyűjtős jutalom törölve!",
        },
        deleteTagDialog: {
            title: "Címke törlése",
            description: "Biztos törölni szeretnéd a '{title}' nevű címkét?",
            succeed: "Címke törlése sikeres",
        },
        disableRelatingAssetDialog: {
            title: "Kapcsolódó médiaelem tiltása",
            description: "Válaszd ki a kijelölt tartalmi elemben található letiltani kívánt médiaelemeket",
            disableSucceed: "Tiltás sikeres!",
            assetTable: {
                noData: "Nincs megjeleníthető adat",
            },
        },
        editAssetDirectoryDialog: {
            saveSucceed: "Sikeres mentés!",
            delete: {
                label: "Mappa törlése",
                description: "Mappát törölni csak abban az esetben tudsz, ha a kijelölt mappa nem tartalmaz elemeket.",
                succeed: "Mappa törlése sikeres!",
            },
            title: "Mappa szerkesztése / törlése",
            name: {
                label: "Mappa elnevezése",
                placeholder: "Mappa elnevezése...",
            },
        },
        verifyDeleteSubModuleDialog: {
            title: "Almodul törlése",
            description: "Az almodul törlésével annak minden elemét törlöd, és ezek nem állíthatók vissza. Biztosan törlöd ezt az almodult?",
            deleteButtonLabel: "Végleges törlés",
            success: "Almodul törölve!",
        },
        rejectContentShareRequestDialog: {
            title: "Felajánlott tartalom elvetése",
            description: "Biztosan elveted ezt a felajánlott tartalmat?",
            reject: "Elvetés",
            succeed: "Felajánlott tartalom elutasítása sikeres!",
        },
        clientTodoDialog: {
            title: "Teendőid vannak!",
            description: "A következő felhasználóknál teendőid vannak. Kérünk, ellenőrizd a felhasználó profiljánál, hogy mivel van teendőd.",
        },
        libraryContentShareRequestSection: {
            shareStatus: "Megosztási állapot",
            sharedContent: "KÖZÖSSÉGI TÁRBA FELAJÁNLOTT:",
            accepted: "felhasználóval megosztott tartalom",
            rejected: "Elutasítva",
            pending: "megosztás elfogadásra vár",
            custom: "Egyénileg megosztott:",

            source: "Eredeti forrás",
            shareSource: "A FELAJÁNLOTT TARTALOM FORRÁSA",
            createdAt: "Létrehozás dátuma:",
            createdByExtId: "Létrehozó kódja:",
            comment: "Létrehozó megjegyzése:",
        },
        shareRequestBottomBar: {
            succeed: "Sikeres elfogadás!",
        },
        accountEventLogTable: {
            filtersSection: "Szűrők",
            table: {
                columns: {
                    account: {
                        supporter: "Támogató azonosítója",
                        supervisor: "Szupervizor azonosítója",
                        admin: "Adminisztrátor azonosítója",
                        superadmin: "Szuperadmin azonosítója",
                    },
                    event: "Esemény megnevezése",
                    client: "Felhasználó azonosítója",
                    timestamp: "Dátum, idő",
                    title: "Cím",
                },
                sortField: {
                    timestamp: "datum",
                },
            },
            dateFrom: {
                label: "Kezdő dátum:",
                placeholder: "Válassza ki az esemény kezdődátumát",
            },
            dateTo: {
                label: "Vég dátum:",
                placeholder: "Válassza ki az esemény végdátumát",
            },
            hourFrom: {
                label: "Óra:",
                value: "0:00",
            },
            hourTo: {
                label: "Óra:",
                value: "23:00",
            },
            dummyText: {
                from: "-tól",
                dash: "-",
                to: "-ig",
            },
            type: {
                label: "Esemény típusa:",
            },
            buttonLabels: {
                deleteFilters: "Szűrők törlése",
                applyFilters: "Szűrők alkalmazása",
                switch: {
                    list: "Váltás lista nézetre",
                    chart: "Váltás grafikon nézetre",
                },
            },
            chartTitle: {
                hourly: "Események száma óránként",
                daily: "Események száma naponként",
                weekly: "Események száma hetenként",
            },
            week: "{week}. hét",
            xAxisLabel: "ESEMÉNYEK SZÁMA",
            yAxisLabel: "IDŐ",
            noData: "Nincs megjeleníthető adat",
        },
    },

    page: {
        auth: {
            tabs: {
                login: "Bejelentkezés",
                registration: "Regisztráció",
                registrationSucceed: "Sikeres regisztráció!",
            },
            login: {
                title: "Bejelentkezés",
                lead: "Itt a DATA rendszer tervezőfelületére jelentkezhetsz be. Itt tervezheted és szerkesztheted meg az applikáció tartalmait és itt találod a DATA tudástárát is.",
                form: {
                    email: {
                        label: "E-mail cím:",
                        placeholder: "Adja meg az email címét",
                    },
                    password: {
                        label: "Jelszó:",
                        placeholder: "Adjon meg egy jelszót",
                    },
                    stayLoggedIn: {
                        label: "Maradjak bejelentkezve:",
                    },
                },
                button: "Bejelentkezés",
                forgotPassword: "Elfelejtetted a jelszavad?",
                loginFailed: "Helytelen email-cím vagy jelszó!",
            },
            registration: {
                title: "Regisztráció",
                lead: "Itt a DATA rendszer tervezőfelületére regisztrálhatsz.",
                form: {
                    name: {
                        label: "Név:",
                        placeholder: "Adja meg a nevét",
                    },
                    email: {
                        label: "E-mail cím:",
                        placeholder: "Adja meg az email címét",
                    },
                    legalAge: "Elmúltam 18 éves",
                    termsAccepted:
                        "Elfogadom a <a href='{termsUrl}' style='{style}' target='_blank'>felhasználási feltételeket</a> és az <a href='{privacyUrl}' style='{style}' target='_blank'>adatkezelési nyilatkozatot</a>.",
                },
                button: "Regisztráció",
            },
            registrationSucceed: {
                message: "Az aktiválólinket kiküldtük a <strong>{email}</strong> email-címre. A regisztrációd befejezéséhez aktiváld fiókodat az email-ben küldött linkre kattintva!",
            },
        },
        accountActivation: {
            title: "Fiók aktiválása",
            form: {
                code: {
                    label: "Kód:",
                    placeholder: "Adja meg a kódot",
                },
                password: {
                    label: "Jelszó:",
                    placeholder: "Adjon meg egy jelszót",
                },
                passwordRepeat: {
                    label: "Jelszó ismétlés:",
                    placeholder: "Ismételje meg a megadott jelszót",
                },
                button: "Fiók aktiválása",
            },
            activationSucceed: {
                message: "Sikeres aktiválás! Jelentkezz be az általad megadott email cím - jelszó párossal!",
            },
        },
        forgotPassword: {
            title: "Jelszó emlékeztető",
            lead:
                "Itt kérhetsz segítséget, amennyiben elfelejtetted a jelszavad. Írd be az email címedet, majd kattints az elküldés gombra. Az email fiókodba érkező üzenetben, a „Jelszó megadása” gombra  kattintva megváltoztathatod a jelszavad.",
            form: {
                email: {
                    label: "Email-cím:",
                    placeholder: "Adja meg az email-címét",
                },
                button: "Elküldés",
            },
            login: "Bejelentkezés",
            forgotPasswordSucceed: {
                message: "Sikeresen kiküldtük a jelszóemlékeztető emailt!",
            },
        },
        resetPassword: {
            title: "Új jelszó beállítása",
            pageTitle: "Jelszó visszaállítása",
            form: {
                password: {
                    label: "Új jelszó:",
                    placeholder: "Adja meg az új jelszót",
                },
                passwordRepeat: {
                    label: "Jelszó ismétlés:",
                    placeholder: "Ismételje meg az új jelszót",
                },
                button: "Jelszó visszaállítása",
            },
            resetPasswordSucceed: {
                message: "Sikeres jelszómódosítás!",
            },
        },
        emailActivation: {
            title: "E-mail aktiválás",
            activationSucceed: {
                message: "Sikeres email-aktiválás!",
            },
            activationFailed: {
                message: "Érvénytelen aktiváló link!",
            },
        },
        dashboard: {
            title: "Üdv, {name}!",
            description: "A DATA rendszerhez tartozó alkalmazást jelenleg az alábbi helyekről töltheted le a mobilodon:",
            description2: "Ha hibát szeretnél bejelenteni, kérdésed van vagy visszajelzést adnál a DATA rendszerről, kattints a következő linkre:",
            description3: "<a href='https://data-helpdesk.atlassian.net/servicedesk/customer/portal/1/user/login' class='more' target='_blank'>DATA rendszer hibabejelentő felület</a>",
            ios: "iOS alkalmazás:",
            android: "Android alkalmazás:",
            noClients: "Önnek nincs még támogatott felhasználója.",
            logsCount: "{count} esemény",
            chartTitle: "Események az elmúlt 7 napban ({date})",
            adminChartTitleClient: "Az új felhasználói profilok száma az elmúlt 7 napban ({date})",
            adminChartTitleSupporter: "Az új támogatói fiók regisztrációk az elmúlt 7 napban ({date})",
            errorTitle: "Hibabejelentés",
            contentShareRequestTable: {
                noData: "Nincsenek felajánlott tartalmak",
                tableTitle: "Felajánlott mintatartalmak",
                dataCount: "({count} db)",
                linkToAll: "Tovább az összeshez ",
            },
        },
        clientShareRequest: {
            title: "Felkérés együttműködésre",
            content: "<p>Felkérése érkezett, hogy legyen Ön is <strong>{clientName}</strong> támogatója.</p>",
            info: "A felkérő: {name} - {email}",
            acceptSucceed: "Együttműködés elfogadása sikeres!",
            rejectSucceed: "Együttműködés elutasítása sikeres!",
            requestNotFoundError: "Felkérés nem található!",
            requestInvalid: "Érvénytelen felkérés!",
        },
        profile: {
            title: "Profilom",
            personalData: {
                title: "Személyes adatok",
                nameForm: {
                    name: {
                        label: "Név:",
                        placeholder: "Adja meg a nevét",
                    },
                    nameChangeSucceed: "Név megváltoztatása sikeres!",
                },
                emailForm: {
                    email: {
                        label: "E-mail cím:",
                        placeholder: "Adja meg az email címét",
                    },
                    emailChangeSucceed: "Elküldtük az aktiváló linket! A módosítás véglegesítéséhez aktiváld az új e-mail címed!",
                },
                passwordForm: {
                    password: {
                        disabledLabel: "Jelszó:",
                        label: "Régi jelszó:",
                        placeholder: "Adja meg a régi jelszavát",
                        disabledValue: "**************",
                    },
                    newPassword: {
                        label: "Új jelszó:",
                        placeholder: "Adja meg az új jelszót",
                    },
                    newPasswordAgain: {
                        label: "Új jelszó újra:",
                        placeholder: "Adja meg az új jelszót újra",
                    },
                    passwordChangeSucceed: "Jelszó megváltoztatása sikeres!",
                    passwordChangeFailed: "Helytelen régi jelszó!",
                },
                extId: "Személyes azonosító",
                copied: "Kimásolva",
            },
            avatar: {
                title: "Profilképem",
                uploadSucceed: "Profilkép feltöltése sikeres!",
                deleteSucceed: "Profilkép törlése sikeres!",
            },
            accountData: {
                title: "Profiladatok",
                delete: {
                    label: "Profilom törlése",
                    confirm: {
                        title: "Fiók törlése",
                        description:
                            "Ha törlöd a profilodat, megszűnik a regisztrációd és az összes felhasználói profil is törlődik, amit te hoztál létre. Ha a felhasználó személy továbbra is használná az alkalmazást, add át a felhasználóid felügyeleti jogát egy másik támogató személynek, mielőtt törlöd a profilod!",
                    },
                    confirmAgain: {
                        title: "Fiók törlés megerősítése",
                        description: "A törlés megerősítéséhez írja be a nevét",
                        inputLabel: "Neve:",
                        inputPlaceholder: "Írja be a nevét",
                        validationError: "A megadott név nem egyezik a profilnévvel!",
                    },
                    deleteSucceed: "Töröltük a fiókot!",
                },
            },
            pin: {
                title: "Támogatói kód",
                description:
                    "Ezt a kódot kell majd használnod, amikor az alkalmazásban a mobileszközön szeretnél szerkeszteni vagy jóváhagyni változtatásokat. Ha bármelyik felhasználónál, akinek a támogatásában rész veszel, van támogatói kóddal védett funkció vagy jóváhagyás, akkor mindenképpen állítsd be ezt a kódot!",
                pinForm: {
                    pin: {
                        label: "Támogatói kód megadása:",
                        placeholder: "Adja meg a támogatói kódot",
                        notGivenPlaceholder: "Nincs megadva",
                    },
                    pinChangeSucceed: "Pinkód megváltoztatása sikeres!",
                },
                delete: {
                    success: "Pinkód törlése sikeres!",
                    dialog: {
                        title: "Támogatói kód törlése",
                        description:
                            "Ha a támogatói kódot törlöd, az összes felhasználódnál törlődni fog. Így szabadon hozzáfér az eddig akár kóddal védett funkciókhoz, mert többé nem fog támogatói azonosítást kérni az applikáció.",
                        confirmDelete: "KÓD TÖRLÉSE",
                    },
                },
            },
        },
        clientEventLog: {
            title: "{name} - Eseménynapló",
        },
        addClient: {
            title: "Felhasználó hozzáadása",
            personalData: {
                form: {
                    personalData: {
                        title: "Személyes adatok",
                        name: {
                            label: "Név:*",
                            placeholder: "Adja meg a nevét",
                        },
                        dateOfBirth: {
                            label: "Születési dátum:*",
                            placeholder: "Add meg a felhasználó születési dátumát!",
                        },
                    },
                    avatar: {
                        title: "Profilkép",
                    },
                    restOfTheFormWarning: "A profil összes további adatának felvitelére az alábbi nyilatkozatok elfogadása és a felhasználó felvétele után lesz lehetőséged.",
                    selfSupporting: {
                        label: "Önrendelkező:",
                    },
                    visuallyImpared: {
                        label: "Gyengénlátó:",
                    },
                    statementOfSupport: "Elfogadom a <a href='{url}' style='{style}' target='_blank'>támogatói nyilatkozatot</a>.",
                },
            },
            questionnaire: {
                title: "Képesség felmérés",
            },
            questionsNotAnsweredError: "Hozzáadás nem lehetséges, amíg nem válaszoltál minden kérdésre!",
            manage: "Felhasználói profil kezelése",
        },
        clientSelectionBoard: {
            lead: "Itt szerkesztheted meg {name} számára az egyes választótáblákat.",
        },
        clientSelectionBoards: {
            lead: "Itt készíthetsz {name} számára választótáblákat.",
            title: {
                label: "Választótábla neve:",
                placeholder: "Nevezd el a táblát",
            },
            image: {
                label: "VÁLASZTÓTÁBLA ELŐNÉZETI KÉPE:",
            },
            backgroundColor: {
                listLabel: "Tábla színe:",
                formLabel: "Választótábla színe:",
            },
            itemCount: {
                label: "Tábla elemszáma:",
            },
            itemName: {
                placeholder: "Választás neve",
                disabledPlaceholder: "Név nincs megadva",
            },
            items: {
                label: "VÁLASZTÓTÁBLA ELEMEI:",
            },
            add: "Új tábla hozzáadása",
            deleteDialog: {
                title: "Táblázat törlése",
                description: "Biztosan törölni szeretné a '{name}' nevű táblázatot?",
            },
            deleteSucceed: "Táblázat törlése sikeres!",
            createSucceed: "Táblázat létrehozása sikeres!",
            updateSucceed: "Táblázat mentése sikeres!",
            page: {
                title: "{name} - Választó",
            },
        },
        clientProfile: {
            title: "{name} - Profil",
            notifications: {
                selfSupportingWarning: {
                    title: "{name} önrendelkezése",
                    description:
                        "Ezt a figyelmeztetést azért kaptad, mert {name} hamarosan nagykorú lesz, ezért nyilatkoznod kell arról, hogy átadod-e neki az önrendelkezés jogát a DATA rendszerben.",
                },
                clientQuestionnaireEvaluation: {
                    title: "{name} kérdőív újbóli kitöltése",
                    description:
                        "Ezt a figyelmeztetést azért kaptad, mert legalább fél éve nem töltötted ki újra {name} kérdőívét. Ha kitöltöd a kérdőívet, a rendszer ajánl számodra beállításokat és funkciókat {name} támogatásához. Korábbi javaslatainkat megtekintheted itt, a DATA rendszer tervezőfelületén, {name} 'felhasználó profil' menüpontjában.",
                    button: "Kérdőív kitöltése újra",
                },
            },
            selfSupporting: {
                card: {
                    title: "{name} önrendelkezése",
                    description:
                        "Ezt a figyelmeztetést azért kaptad, mert {name} hamarosan nagykorú lesz, ezért nyilatkoznod kell arról, hogy átadod-e neki az önrendelkezés jogát a DATA rendszerben.",
                },
                buttons: {
                    yes: "Önrendelkező",
                    no: "Nem önrendelkező",
                    statement: "Nyilatkozom",
                },
                dialog: {
                    title: "{name} - Döntés az önrendelkezési jog átadásáról",
                    true: {
                        title: "",
                        description: "{name} önrendelkező felnőtt, ezért átadhatod neki a az önrendelkezés jogát a DATA rendszerben. Ha átadod a jogot az az alábbiakat jelenti:",
                        list: `
                            <li>{name} támogatói profilt hoz létre</li>
                            <li>mint jelenlegi főtámogató, meg kell hívnod együttműködésre a saját felhasználói profiljába</li>
                            <li>át kell adnod neki a főtámogatói jogosultságot, így {name} öntámogatóvá válik</li>
                            <li>ezután {name} dönthet arról, hogy kik maradnak az együttműködő támogatói</li>
                            <li>a támogatóként létrehozott tartalmaid megmaradnak és {name} döntése alapján együttműködőként tovább támogathatod</li>
                            `,
                        statement:
                            "<p>Én, {supervisorName} nyilatkozom arról, hogy {clientName} önrendelkező felnőtt, ezért a DATA rendszerben a főtámogatói jogot átadom számára, így öntámogatóvá válik.</p>",
                    },
                    false: {
                        title: "Biztosan nem adod át a jogot?",
                        description:
                            "Ha nem adod át a jogot {name} számára, akkor továbbra is te maradsz a főtámogatója ebben a profilban.<br /><br />Figyelem! Amennyiben {name} önrendelkező felnőtt, bármikor létrehozhat saját magának egy másik támogatói profilt, és ezzel öntámogatóvá válik.",
                        statement:
                            "<p>Én, {supervisorName} nyilatkozom arról, hogy {clientName} önrendelkező felnőtt, de a DATA rendszerben ezzel a felhasználói profiljával a főtámogatói jogot nem adom át számára. A főtámogatója továbbra is én, {supervisorName} maradok.<br /><br />Figyelem! {clientName} önrendelkező felnőttként jogosult öntámogatóként saját profilt létrehozni a DATA rendszerben.</p>",
                        next: "NEM ADOM ÁT A JOGOT",
                    },
                },
            },
            personalData: {
                title: "Személyes adatok",
                nameForm: {
                    name: {
                        label: "Név:",
                        placeholder: "Adja meg a nevét",
                    },
                    nameChangeSucceed: "Név megváltoztatása sikeres!",
                },
                dateOfBirthForm: {
                    dateOfBirth: {
                        label: "Születési dátum:",
                        placeholder: "Add meg a felhasználó születési dátumát!",
                    },
                    isSelfSupporting: {
                        label: "Önrendelkező",
                    },
                    dateOfBirthChangeSucceed: "Születési év megváltoztatása sikeres!",
                },
                visuallyImpairedForm: {
                    label: "Gyengénlátó",
                    saveSucceed: "Gyengénlátás megváltoztatása sikeres!",
                },
                selfSupportingForm: {
                    label: "Önrendelkező",
                    saveSucceed: "Önellátás megváltoztatása sikeres!",
                },
                extId: "Személyes azonosító",
                copied: "Kimásolva",
                isSelfSupporting: "Önrendelkező",
            },
            introductionSection: {
                title: "Bemutatkozás",
                introductionForm: {
                    introduction: {
                        label: "BEMUTATKOZÓ SZÖVEG:",
                        placeholder: "Adjon meg egy leírást...",
                    },
                    introduction_audio: {
                        placeholder: "Töltsön fel egy hangfájlt!",
                    },
                    introduction_video: {
                        placeholder: "Töltsön fel egy videót!",
                    },
                    introductionChangeSucceed: "Leírás megváltoztatása sikeres!",
                    uploadSucceed: "{assetType} feltöltése sikeres!",
                    deleteSucceed: "{assetType} törlése sikeres!",
                },
            },
            questionnaireSection: {
                title: "Képesség felmérés",
                button: "Kérdőív kitöltése újra",
            },
            avatar: {
                title: "Profilkép",
                uploadSucceed: "Profilkép feltöltése sikeres!",
                deleteSucceed: "Profilkép törlése sikeres!",
            },
            accountData: {
                title: "Profil adatok",
                delete: {
                    label: "Felhasználói profil törlése:",
                    confirm: {
                        title: "Fiók törlése",
                        description: "Biztosan törölni akarod a fiókot?",
                    },
                    confirmAgain: {
                        title: "Fiók törlés megerősítése",
                        description: "A törlés megerősítéséhez írja be a felhasználó nevét!",
                        validationError: "A megadott név nem egyezik a felhasználó nevével!",
                        inputLabel: "Felhasználó neve:",
                        inputPlaceholder: "Felhasználó neve",
                    },
                    deleteSucceed: "Töröltük a fiókodat!",
                },
                changeManager: {
                    confirm: {
                        title: "Főtámogatói meghívó küldése",
                        description:
                            "Ha átadod a főtámogatói jogot, akkor nem te leszel {clientName} fő támogatója. Ez azt jelenti, hogy  csak az új főtámogató tudja majd {clientName} összes alapadatát szerkeszteni, csak ő tudja {clientName} profilját törölni csak ő tud meghívni {clientName} számára együttműködő támogatókat és törölni őket.  Együttműködő támogatóként továbbra is létrehozhatsz tartalmakat {clientName} számára, ha a főtámogató nem töröl vagy nem törlöd a saját profilodat. Felkérésedről {supporterName} támogatót értesítjük, ezt követően tudja majd elfogadni vagy visszautasítani a felkérésedet.",
                        supporter: {
                            label: "Támogató neve:",
                        },
                    },
                    button: {
                        label: "Meghívó küldése",
                    },
                    succeed: "Főtámogatói meghívó küldése sikeres!",
                },
            },
            deviceList: {
                title: "Mobileszközök",
                noData: "Nincs hozzárendelt eszköz",
                sync: "Adatfrissítés mobileszközre",
                syncSucceed: "Adatok frissítése eszközökre sikeres!",
                table: {
                    columns: {
                        id: "Mobileszköz azonosító",
                        deviceType: "Készülék",
                        appVersion: "Alkalmazás",
                        remove: "Eltávolítás",
                        createdAt: "Összekapcsolás dátuma",
                        updatedAt: "Adatfrissítés állapota",
                    },
                },
            },
            supporterList: {
                title: "Támogatók",
                noData: "Nincs hozzárendelt támogató",
                addButton: "EGYÜTTMŰKÖDŐ TÁMOGATÓ HOZZÁADÁSA",
                dialog: {
                    title: "Együttműködő támogató hozzáadása",
                    description: " ",
                    inputLabel: "Támogató e-mail címe:",
                    inputPlaceholder: "Írja be az e-mail címet",
                    confirmButton: "Meghívó küldése",
                },
                deleteClientChangeManagerRequest: {
                    confirm: {
                        title: "Főtámogatói meghívó visszavonása",
                        description: "Biztosan vissza akarod vonni a főtámogatói meghívót?",
                        button: "Meghívás visszavonása",
                    },
                    delete: "Főtámogatói meghívó visszavonása",
                    succeed: "Főtámogatói meghívó visszavonása sikeres!",
                },
                addClientChangeManagerRequest: {
                    add: "Főtámogatói jog átadása",
                    success: "Főtámogatói meghívó küldése sikeres!",
                },
                table: {
                    columns: {
                        email: "Email",
                        name: "Név",
                        role: "Szerep",
                        status: "Státusz",
                        remove: "Eltávolítás",
                    },
                    newCollaborator: "Meghívott együttműködő",
                    collaborator: "Együttműködő",
                    manager: "Főtámogató",
                    newManager: "Meghívott főtámogató",
                },
            },
            defaultAgenda: {
                title: "Alapértelmezett napirendi sor",
                hint: "A felhasználó bármely napirendi sorát kiválaszthatod alapértelmezettnek. Ha adott naphoz nem állítottál be napirendi sort, ez jelenik meg a felhasználó személy számára.",
                table: {
                    columns: {
                        title: "Név",
                        numberOfSteps: "Lépések száma",
                        updatedAt: "Utolsó módosítás",
                        actions: " ",
                    },
                },
                noData: "Nincs alapértelmezett napirendi sor",
            },
            addSupporterSucceed: "Támogató meghívása sikeres!",
            removeSupporterSucceed: "Támogató eltávolítása sikeres!",
            removeDeviceSucceed: "Támogatói készülék eltávolítása sikeres!",
        },
        clientQuestionnaire: {
            title: "{name} - Képesség felmérés",
            questionsNotAnsweredError: "Beküldés nem lehetséges, amíg nem válaszoltál minden kérdésre!",
            dialog: {
                title: "Képességfelmérés",
                button: "Rendben",
            },
        },
        clientCalendar: {
            title: "{name} - Naptár",
            syncRequired: "Napirend még nincs szinkronizálva",
        },
        clientGames: {
            title: "{name} - Időtöltők",
            memoryTab: {
                title: "MEMÓRIA JÁTÉK",
                description:
                    "Itt készíthetsz {name} számára memóriajátékot. Válaszd ki, hány pár legyen egy játékban és hogy mely képekből adódjanak ki a véletlenszerű párok. A képekből csak egyet kell ide betenned.",
                succeed: "Memóriajáték mentése sikeres!",
            },
            sortingTab: {
                title: "SORBARENDEZŐ",
                description:
                    "Itt válaszdhatod ki {name} számára, hogy milyen folyamatábrák sorbarendezésével játszhat. A kiválasztott folyamtábrák maximum 12 lépésesek lehetnek, nem tartalmazhatnak elágazást és minden lépéséhez kell, hogy tartozzon kép.",
                slotMessage: "Húzd ide a kiválasztott folyamatábra-sort!",
                succeed: "Sorbarendező mentése sikeres!",
            },
            form: {
                numberOfPairs: {
                    label: "Hány pár legyen a játékban?",
                    placeholder: "Nincs beállítva",
                },
            },
            messages: {
                gameNotEnabled: {
                    title: "Ez a játék nincs bekapcsolva {name} számára.",
                    description: "Ahhoz, hogy ez a játék {name} számára elérhető legyen, engedélyezni kell a beállítások fül alatt.",
                },
                flowchartNotUsableInSortingGame: {
                    title: "A folyamatábra nem használható sorbarendező játékban!",
                },
                flowchartNotAssignedToClient: {
                    title: "A folyamatábra nincs a támogatotthoz rendelve!",
                },
            },
        },
        clientSettings: {
            title: "{name} - Beállítások",
            appTab: {
                title: "ALKALMAZÁS BEÁLLÍTÁSAI",
                description: `Az általad kitöltött kérdőívben megadott válaszaid alapján alakítottuk {name} számára az itt található alapbeállítás-készletet. A kérdőívet bármikor kitöltheted, így javaslataink követni fogják {name}  fejlődését.
                    Ha úgy véled, hogy időközben a funkciók és tartalmak bővítésére van szükséged, a Tervezőfelületen a "Tudásbázis" menüpont alatt találsz információkat ennek javasolt menetéről és módjáról.
                    {name}  "felhasználói profil" menüpontjában olvashatod el újra a javaslatainkat, és töltheted ki újra a kérdőívet.
                    `,
                unlock: {
                    title: "Alkalmazás feloldása",
                    pattern: {
                        label: "Feloldó kód:",
                        title:
                            "Itt állíthatod be, hogy az alkalmazást milyen feloldó kóddal lehet a mobileszközön elindítani. Tetszőlegesen 1-8  elemből álló színsorozatot hozhatsz létre. Ha beállítod ezt a kódot, figyelj arra, hogy a felhasználó személynek tudnia kell ezt a színsort megjegyezni és használni a feloldáshoz.",
                    },
                    failMessage: {
                        label: "Hibaüzenet szövege:",
                        placeholder: "Ide írhatod, hogy milyen üzenet jelenjen meg hibás feloldókód beütésekor",
                    },
                },
                agendaItemBackground: "Napirend hátterének színe:",
                flowchartItemBackground: "Folyamatábra hátterének színe:",
                colorsOfDaysType: "Hét napjainak színe a többnapos nézetben:",
                highContrastMode: "Magas kontrasztú változat (gyengénlátó felhasználóknak ajánlott)",
            },
            mainPageTab: {
                title: "NYITÓKÉPERNYŐ BEÁLLÍTÁSAI",
                description: "Itt állíthatod be, hogy az alkalmazás nyitóképernyőjén melyik funkciók legyenek láthatók {name} számára.",
                mainPageType: "Nyitóképernyő nézete:",
                mainPageBackgroundColor: "Nyitóképernyő háttérszíne:",
                mainPageDataSyncEnabled: "Mobil nyitóképernyőről indított adatfrissítés engedélyezése",
                enabledDashboardItems: "Nyitóképernyőn megjelenő menüpontok",
            },
            contentsTab: {
                title: "NAPIREND ÉS FOLYAMATÁBRA BEÁLLÍTÁSAI",
                agendaItemSize: "Napirendi lépések mérete:",
                agendaItemTextVisibility: "Napirend megjelenés:",
                calendarView: "Napirend többnapos nézete:",
                withoutDefaultAgendaHint:
                    "Ha nem rendeltél az adott naphoz napirendet, akkor a segítségkérés vizuális jele és a  „Most nincs DATA-s napirended, kérj segítséget a támogatódtól!” mondat jelenik meg. Ha szeretnél egyedi üzenetet ezekre az esetekre, itt állíthatod be. Ez a mobilalkalmazásban minden olyan napon megjelenik, amelyhez nem hoztál létre és nem rendeltél hozzá egyedi napirendi sort.",
                withoutDefaultAgenda: {
                    text: {
                        label: "Hiányzó napirend szövege:",
                        placeholder: "Ide írhatod a szöveget, ami megjelenik, ha nincs napirend beállítva.",
                    },
                    image: {
                        label: "Hiányzó napirend képe:",
                    },
                },
                flowchartListType: "FOLYAMATÁBRÁK LISTÁJÁNAK MÉRETE:",
                agendaItemCheckboxSize: "FOLYAMATÁBRA LÉPÉSEKNÉL A PIPA MÉRETE:",
            },
            functionsTab: {
                title: "TOVÁBBI FUNKCIÓK BEÁLLÍTÁSAI",
                description:
                    "A fő funkciókhoz csatlakoztathatsz egy sor kiegészítőt is, mint az időjelző, a jutalom vagy az érzelmi hőmérő. Itt adhatod meg ezeknek a kiegészítőként is működő funkcióknak az alapbeállításait.",
                moodMeter: {
                    enabled: {
                        label: "Érzelmi hőmérő",
                    },
                    items: "Elérhető érzelmek és megnevezéseik:",
                    name: {
                        placeholder: "Nevezd át ezt az érzelmet!",
                    },
                    multiSelect: {
                        label: "Több érzelem is kiválasztható:",
                    },
                    message: {
                        label: "Szöveges visszajelzés lehetősége:",
                    },
                    intensity: {
                        label: "Intenzitás állítható:",
                    },
                    question: {
                        label: "Kérdés:",
                    },
                    customQuestion: {
                        label: "Egyedi kérdés szövege:",
                        placeholder: "Adja meg a kérdést...",
                    },
                },
                timer: {
                    type: "Időjelző típusa:",
                    requestConfirmation: "AZ IDŐTARTAMOK MEGVÁLTOZTATÁSÁHOZ ÉS SZÜNETELTETÉSÉHEZ SZÜKSÉGES A TÁMOGATÓI KÓD BEÍRÁSA:",
                },
                selectionBoardListType: "VÁLASZTÓTÁBLÁK LISTANÉZETE:",
                award: {
                    requestConfirmation: "A JUTALOM JÓVÁHAGYÁSÁHOZ SZÜKSÉGES A TÁMOGATÓI KÓD BEÍRÁSA:",
                },
            },
            colorsOfDaysType: {
                default: "Alapértelmezett",
                custom: "Egyedi",
            },
            days: {
                mon: "Hétfő:",
                tue: "Kedd:",
                wed: "Szerda:",
                thu: "Csütörtök:",
                fri: "Péntek:",
                sat: "Szombat:",
                sun: "Vasárnap:",
            },
            enabledDashboardItems: {
                agenda: "Napirend",
                flowchart: "Folyamatábra",
                games: "Időtöltők",
                moodMeter: "Érzelmeim",
                volumeMeter: "Hangerőmérő",
                awards: "Jutalmazás",
                opportunities: "Lehetőségek tárháza",
                waiting: "Várakozás",
                education: "Oktató funkció (Appbemutató)",
                timer: "Időjelző",
                situations: "Mindennapi helyzetek",
                selectionBoards: "Választó",
            },
            enabledGames: {
                bubbles: "Buborékpukkasztás",
                spinner: "Pörgettyű",
                drawing: "Rajzolás",
                memory: "Memóriajáték",
                sorting: "Sorbarendező",
            },
            mainPageType: {
                simple: "Egyszerűsített",
                normal: "Normál",
            },
            calendarView: {
                true: "Engedélyezett",
                false: "Letiltva",
            },
            agendaItemSize: {
                large: "Nagy (kártyanézet)",
                medium: "Közepes",
                small: "Kicsi",
            },
            agendaItemTextVisibility: {
                reduced: "Magas kontrasztú (gyengénlátóknak)",
                normal: "Normál",
            },
            flowchartListType: {
                card: "Nagy (kártyanézet)",
                list: "Kicsi (listanézet)",
            },
            agendaItemCheckboxSize: {
                large: "Normál",
                small: "Kicsi",
            },
            moodMeter: {
                itemNamePlaceholder: "Add meg az érzelem nevét...",
                badConfigAlert: "Ön kikapcsolta az érzelmi hőmérőt. Ez hibás konfigurációt eredményezhet olyan tartalmaknál, amikhez az érzelmi hőmérő csatolva van!",
            },

            timer: {
                timer: "Timer",
                sandGlass: "Homokóra",
                decreasing: "Fogyatkozó",
            },
            selectionBoardListType: {
                list: "Lista",
                card: "Nagy (kártyanézet)",
            },
            enabledDashboardItemsWarning:
                "Egy vagy több menüpont hozzá lett adva a nyitóképernyőhöz, viszont ki van kapcsolva a felhasználónál, ami hibát eredményezhet. A menüpont(ok): {badConfigItems}",
            saveSucceed: "Beállítások módosítása sikeres!",
        },
        clientAwards: {
            title: "{name} - Jutalmazás",
            description: "Itt állíthatod be, hogy a felhasználó számára milyen jutalmak és hány pontért legyenek elérhetők.",
            isActive: {
                label: "Jutalmazás használata",
            },
            currentState: {
                title: "Aktuális állapot",
                points: {
                    label: "Elért pontszám:",
                    points: "pont",
                    restart: "Pontozás újraindítása",
                },
                restartSuccess: "A pontok újraindítása sikeres.",
            },
            settings: {
                title: "Beállítások",
                image: {
                    label: "Cél képe:",
                },
                targetLabel: "Célpontszám",
                targetScore: {
                    label: "Célpontszám:",
                    placeholder: "Adja meg a célpontszámot",
                },
                prizeTitle: {
                    label: "Jutalom megnevezése:",
                    placeholder: "Adja meg a jutalom megnevezését...",
                },
                message: {
                    label: "Üzenet szövege:",
                    placeholder: "Adja meg az üzenet szövegét...",
                },
            },
            saveSuccess: "A jutalmazás mentése sikeres.",
            badConfigAlert: "Ön kikapcsolta a jutalmazást. Ez hibás konfigurációt eredményezhet olyan tartalmaknál, amikhez jutalmazás van csatolva!",
        },
        adminList: {
            title: "Admin felhasználók",
            table: {
                columns: {
                    name: "Név",
                    email: "E-mail cím",
                    status: "Státusz",
                },
            },
            sortField: {
                id: "azonosito",
                extId: "kulso_azonosito",
                name: "nev",
                email: "email",
                activatedAt: "aktivalas_datuma",
                createdAt: "letrehozas_datuma",
                disabledAt: "tiltas_datuma",
            },
            addButton: "Új felhasználó",
            searchPlaceholder: "Keresés...",
            noData: "Nincs megjeleníthető adat",
            createAdminAccountDialog: {
                title: "Új adminisztrátor hozzáadása",
                description: "Itt hívhatsz meg új adminisztrátorokat a DATA rendszerbe",
                email: {
                    label: "E-mail cím:",
                    placeholder: "Adja meg az email címét",
                },
                name: {
                    label: "Név:",
                    placeholder: "Adja meg a nevét",
                },
                add: "ÚJ adminisztrátor hozzáadása",
            },
            createAdminAccountSucceed: "Új felhasználó hozzáadása sikeres!",
        },
        supervisorList: {
            title: "Szakmai szupervizorok",
            table: {
                columns: {
                    name: "Név",
                    email: "E-mail cím",
                    status: "Státusz",
                },
            },
            sortField: {
                id: "azonosito",
                extId: "kulso_azonosito",
                name: "nev",
                email: "email",
                activatedAt: "aktivalas_datuma",
                createdAt: "letrehozas_datuma",
                disabledAt: "tiltas_datuma",
            },
            addButton: "Új felhasználó",
            searchPlaceholder: "Keresés...",
            noData: "Nincs megjeleníthető adat",
            createSupervisorAccountDialog: {
                title: "Új szupervizor hozzáadása",
                description: "Itt hívhatsz meg új szupervízorokat a DATA rendszerbe",
                email: {
                    label: "E-mail cím:",
                    placeholder: "Adja meg az email címét",
                },
                name: {
                    label: "Név:",
                    placeholder: "Adja meg a nevét",
                },
                add: "ÚJ szupervízor hozzáadása",
            },
            createSupervisorAccountSucceed: "Új felhasználó hozzáadása sikeres!",
        },
        supporterList: {
            title: "Támogató felhasználók",
            table: {
                columns: {
                    extId: "Azonosító",
                    email: "E-mail cím",
                    status: "Státusz",
                },
            },
            sortField: {
                id: "azonosito",
                extId: "kulso_azonosito",
                name: "nev",
                email: "email",
                activatedAt: "aktivalas_datuma",
                createdAt: "letrehozas_datuma",
                disabledAt: "tiltas_datuma",
            },
            addButton: "Új felhasználó",
            searchPlaceholder: "Keresés...",
            noData: "Nincs megjeleníthető adat",
            createSupporterAccountDialog: {
                title: "Új támogató hozzáadása",
                description: "Itt hívhatsz meg új támogató személyeket a DATA rendszerbe",
                email: {
                    label: "E-mail cím:",
                    placeholder: "Adja meg az email címét",
                },
                name: {
                    label: "Név:",
                    placeholder: "Adja meg a nevét",
                },
                add: "Új támogató hozzáadása",
            },
            createSupporterAccountSucceed: "Új felhasználó hozzáadása sikeres!",
        },
        clientList: {
            title: "Támogatott felhasználók",
            table: {
                columns: {
                    extId: "Azonosító",
                    dateOfBirth: "Születési dátum",
                    createdAt: "Létrehozás dátuma",
                },
            },
            sortField: {
                extId: "azonosito",
                name: "nev",
                dateOfBirth: "szuletesi_datum",
                createdAt: "letrehozas_datuma",
                updatedAt: "modositas_datuma",
            },
            searchPlaceholder: "Keresés...",
            noData: "Nincs megjeleníthető adat",
        },
        clientDetail: {
            title: "{extId} - Adatlap",
            tabs: {
                profile: {
                    title: "Profil adatok",
                    personalData: {
                        title: "Személyes adatok",
                        dateOfBirthForm: {
                            dateOfBirth: {
                                label: "Születési dátum:*",
                                placeholder: "Adja meg a születési dátumot",
                            },
                        },
                        introductionForm: {
                            introduction: {
                                label: "BEMUTATKOZÓ SZÖVEG:",
                                placeholder: "Bemutatkozó szöveg nincs megadva",
                            },
                        },
                        extId: "Azonosító",
                        copied: "Kimásolva",
                    },
                    supporterList: {
                        title: "Támogatók",
                        noData: "Nincs hozzárendelt támogató",
                        table: {
                            columns: {
                                email: "Email",
                                extId: "Azonosító",
                                role: "Szerep",
                                status: "Státusz",
                            },
                        },
                        newCollaborator: "Meghívott együttműködő",
                        collaborator: "Együttműködő",
                        manager: "Főtámogató",
                        newManager: "Meghívott főtámogató",
                    },
                },
                contentLibrary: {
                    title: "Tartalomtár",
                    linkGroupTitle: "{extId} tára",
                },
                eventLog: {
                    title: "Eseménynapló",
                },
            },
        },
        adminDetail: {
            title: "{name} - Adatlap",
            tabs: {
                profile: {
                    title: "Profil adatok",
                    personalData: {
                        title: "Személyes adatok",
                        nameForm: {
                            name: {
                                label: "Név:",
                                placeholder: "Adja meg a nevét",
                            },
                        },
                        emailForm: {
                            email: {
                                label: "E-mail cím:",
                                placeholder: "Adja meg az email címét",
                            },
                        },
                    },
                    accountData: {
                        title: "Fiók adatok",
                        delete: {
                            label: "Profil törlése",
                            deleteSucceed: "Töröltük a fiókot!",
                        },
                        accountType: {
                            label: "Csoport:",
                        },
                    },
                    saveSucceed: "Módosítások mentése sikeres!",
                },
                eventLog: {
                    title: "Eseménynapló",
                },
            },
        },
        supervisorDetail: {
            title: "{name} - Adatlap",
            tabs: {
                profile: {
                    title: "Profil adatok",
                    personalData: {
                        title: "Személyes adatok",
                        nameForm: {
                            name: {
                                label: "Név:",
                                placeholder: "Adja meg a nevét",
                            },
                        },
                        emailForm: {
                            email: {
                                label: "E-mail cím:",
                                placeholder: "Adja meg az email címét",
                            },
                        },
                    },
                    accountData: {
                        title: "Fiók adatok",
                        delete: {
                            label: "Profil törlése:",
                            deleteSucceed: "Töröltük a fiókot!",
                        },
                        accountType: {
                            label: "Csoport:",
                        },
                    },
                    saveSucceed: "Módosítások mentése sikeres!",
                },
                contentLibrary: {
                    title: "Tartalomtár",
                    linkGroupTitle: "{name} tára",
                },
                eventLog: {
                    title: "Eseménynapló",
                },
            },
        },
        supporterDetail: {
            title: "{extId} - Adatlap",
            tabs: {
                profile: {
                    title: "Profil adatok",
                    personalData: {
                        title: "Személyes adatok",
                        emailForm: {
                            email: {
                                label: "E-mail cím:",
                                placeholder: "Adja meg az email címét",
                            },
                        },
                        extId: "Azonosító",
                        copied: "Kimásolva",
                    },
                    accountData: {
                        title: "Profiladatok",
                        delete: {
                            label: "Profil törlése",
                            deleteSucceed: "Töröltük a fiókot!",
                        },
                        accountType: {
                            label: "Csoport:",
                        },
                    },
                    clients: {
                        title: "Támogatottak",
                        noData: "Nincs hozzárendelt támogatott",
                        table: {
                            columns: {
                                extId: "Azonosító",
                                dateOfBirth: "Születési dátum",
                                status: "Státusz",
                            },
                        },
                    },
                },
                contentLibrary: {
                    title: "Tartalomtár",
                    linkGroupTitle: "{extId} tára",
                },
                eventLog: {
                    title: "Eseménynapló",
                },
            },
        },
        clientContentLibrary: {
            title: "{name} - Tartalomtár",
        },
        publicContentLibrary: {
            title: "Tartalomtáram",
            myLibrary: "Saját táram",
            publicLibrary: "Közösségi tár",
            editLibrary: "Kijelölt mappa szerkesztése",
        },
        agendaItemPage: {
            createSaveSucceed: "Napirendi lépés létrehozása sikeres!",
            editSaveSucceed: "Napirendi lépés módosítása sikeres!",
        },
        flowchartItem: {
            title: {
                view: "Folyamatábra lépés megtekintése",
                edit: "Folyamatábra lépés szerkesztése",
                create: "Folyamatábra lépés létrehozása",
            },
            data: {
                type: {
                    label: "Folyamatábra lépés típusa:",
                },
                title: "Folyamatábra lépés adatai",
                name: {
                    label: "Megnevezés:",
                    placeholder: " Add meg a folyamatábra lépés nevét!",
                },
                media: {
                    placeholder: "A folyamatábra lépéshez képet és/vagy hangot vagy videót tölthetsz fel.",
                },
                image: {
                    label: "Kép:",
                },
                flowchart: {
                    label: "Folyamtábra-sor beágyazása",
                    timerWarning: "A begyáazandó folyamatábra-sor időzítése hosszabb, mint a folyamatábra lépés időzítése.",
                },
            },
            createdBy: {
                title: "Eredeti forrás",
                name: {
                    label: "Létrehozta:",
                },
            },
            additions: {
                title: "Kiegészítő funkciók",
            },
            checklistAdd: "További elem hozzáadása",
            saveSucceed: "Folyamatábra lépés mentése sikeres!",
            badConfigInput: {
                moodMeter: "Érzelmi hőmérő",
                award: "Jutalmazás",
            },
            badConfigInputAlert: "Egy vagy több funkció hozzá lett adva a folyamatábra lépéshez, viszont ki van kapcsolva a felhasználónál, ami hibát eredményezhet. A funkció(k): {badConfigItems}",
        },
        flowchart: {
            title: {
                view: "Folyamatábra-sor megtekintése",
                edit: "Folyamatábra-sor szerkesztése",
                create: "Folyamatábra-sor létrehozása",
            },
            data: {
                title: "Folyamatábra-sor adatai",
                name: {
                    label: "Megnevezés:",
                    placeholder: "Add meg a folyamatábra-sor nevét!",
                },
                description: {
                    label: "Leírás:",
                    placeholder: "Add meg a folyamatábra-sor leírását!",
                },
                image: {
                    label: "Előnézeti kép:",
                },
            },
            usableInSortingGame: {
                title: "Sorbarendezős játék",
                label: "Beállítom a sorbarendezős játékhoz is",
            },
            items: {
                title: "Folyamatábra lépések",
            },
            createdBy: {
                title: "Eredeti forrás",
                name: {
                    label: "Létrehozta:",
                },
            },
            saveSucceed: "Folyamatábra mentése sikeres!",
        },
        agenda: {
            title: {
                view: "Napirendi sor megtekintése",
                edit: "Napirendi sor szerkesztése",
                create: "Napirendi sor létrehozása",
            },
            data: {
                title: "Napirendi sor adatai",
                name: {
                    label: "Megnevezés:",
                    placeholder: "Add meg a napirendi sor nevét!",
                },
                description: {
                    label: "Leírás:",
                    placeholder: "Add meg a napirendi sor leírását!",
                },
            },
            items: {
                title: "Napirendi lépések",
            },
            createdBy: {
                title: "Eredeti forrás",
                name: {
                    label: "Létrehozta:",
                },
            },
            saveSucceed: "Napirend mentése sikeres!",
        },
        asset: {
            lead: "Alább lehetőséged van módostani a kiválasztott médiaelem megnevezését és címkéit.",
            titleSection: {
                title: "MÉDIAELEM MEGNEVEZÉSE",
            },
            tagSection: {
                title: "címkék",
            },
            pageTitle: {
                view: "Médiaelem megtekintése",
                edit: "Médiaelem szerkesztése",
            },
            preview: {
                label: "Fájl:",
            },
            title: {
                label: "Médiaelem megnevezése:",
            },
            saveSucceed: "Médiaelem mentése sikeres!",
        },
        agendaItem: {
            title: {
                view: "Napirendi lépés megtekintése",
                edit: "Napirendi lépés szerkesztése",
                create: "Napirendi lépés létrehozása",
            },
            data: {
                title: "NAPIRENDI LÉPÉS ADATAI",
                type: {
                    label: "Napirendi lépés típusa:",
                },
                name: {
                    label: "Megnevezés:",
                    placeholder: "Ez a felirat látható a tartalomtárban",
                },
                isCancelled: {
                    label: "Elmarad:",
                },
                content: {
                    label: "Lépés szövege:",
                    placeholder: "Ez a lépés látható az alkalmazásban a kártyán.",
                },
                media: {
                    label: "A napirendi lépéshez képet és/vagy hangfilet vagy videófilet tölthetsz fel.",
                },
                image: {
                    label: "Lépés képe:",
                },
                audio: {
                    label: "LÉPÉS HANGJA:",
                },
                video: {
                    label: "LÉPÉS VIDEÓJA:",
                },
                agendaItems: {
                    a: "Lépés A:",
                    b: "Lépés B:",
                },
                flowchart: {
                    label: "Kapcsolódó folyamatábra:",
                },
            },
            createdBy: {
                title: "Eredeti forrás",
                name: {
                    label: "Létrehozta:",
                },
            },
            additions: {
                title: "Kiegészítő funkciók",
            },
            saveSucceed: "Napirendi lépés mentése sikeres!",
            confirmAgendaDialog: {
                title: "Napirend mentése",
                description: "Csak a {date} napra vonatkozó napirendet szeretnéd frissíteni vagy az összeset?",
                updateAll: "Összeset",
                updateCurrent: "Csak ezt",
            },
            badConfigInput: {
                moodMeter: "Érzelmi hőmérő",
                award: "Jutalmazás",
            },
            badConfigInputAlert: "Egy vagy több funkció hozzá lett adva a napirendi lépéshez, viszont ki van kapcsolva a felhasználónál, ami hibát eredményezhet. A funkció(k): {badConfigItems}",
        },
        replaceAssetItemPage: {
            title: "médiaelem cseréje",
            description: "Alább lehetőséged van a kiválasztott médiaelem cseréjére. Médiaelemeket csak azonos típusú elemre cserélhetsz. A kicserélt elem mindenhol cserélődni fog. ",
            swappableItem: {
                title: "Cserélendő elem",
            },
            newItem: {
                title: "Új elem:",
                slotLabel: "Húzd ide az új fájlt",
            },
            button: "Médiaelem cseréje",
            messages: {
                success: "A médiaelem sikeresen kicserélésre került!",
                noAsset: "A cserélendő elem megadása kötelező!",
            },
        },
        systemEventLog: {
            title: "Rendszer eseménynapló",
            date: {
                label: "Események dátuma:",
                placeholder: "Válassza ki az események dátumát",
            },
            table: {
                columns: {
                    event: "Esemény megnevezése",
                    timestamp: "Dátum, idő",
                    title: "Cím",
                    startedAt: "Kezdet",
                    finishedAt: "Vég",
                    createdAt: "Dátum, idő",
                    type: "Esemény megnevezése",
                    client: "Felhasználó azonosítója",
                    account: "Támogató azonosítója",
                    data: "Esemény részletei",
                    device: "Készülék",
                    app: "Alkalmazás",
                    value: "Esemény részletei",
                },
                sortField: {
                    type: "esemeny",
                    createdAt: "datum_ido",
                    timestamp: "datum_ido",
                },
            },
            appEventLogTab: {
                title: "Alkalmazás események",
                sectionTitle: "Szűrők",
                type: {
                    label: "Esemény típusa:",
                },
                dateFrom: {
                    label: "Kezdő dátum:",
                    placeholder: "Írjon be egy kezdő dátumot...",
                },
                hourFrom: {
                    label: "Óra:",
                    placeholder: "Írjon be egy kezdő órát...",
                },
                from: "-tól",
                dateTo: {
                    label: "Vég dátum:",
                    placeholder: "Írjon be egy végdátumot...",
                },
                hourTo: {
                    label: "Óra:",
                    placeholder: "Írjon be egy végórát...",
                },
                to: "-ig",
                clientExtId: {
                    label: "Felhasználó:",
                    placeholder: "Írja be a felhasználó azonosítóját...",
                },
                clientProfile: {
                    label: "Perszóna besorolás:",
                    placeholder: "Írja be a felhasználó perszóna besorolását...",
                },
                buttonLabels: {
                    deleteFilters: "Szűrők törlése",
                    applyFilters: "Szűrők alkalmazása",
                },
                noData: "Nincs megjeleníthető adat",
            },
            cmsEventLogTab: {
                title: "Tervezőfelület események",
                sectionTitle: "Szűrők",
                type: {
                    label: "Esemény típusa:",
                },
                dateFrom: {
                    label: "Kezdő dátum:",
                    placeholder: "Írjon be egy kezdődátumot",
                },
                hourFrom: {
                    label: "Óra:",
                },
                from: "-tól",
                dateTo: {
                    label: "Vég dátum:",
                    placeholder: "Írjon be egy végdátumot",
                },
                hourTo: {
                    label: "Óra:",
                },
                to: "-ig",
                authAccountExtId: {
                    label: "Támogató:",
                    placeholder: "Írja be a támogató azonosítóját...",
                },
                clientExtId: {
                    label: "Felhasználó:",
                    placeholder: "Írja be a felhasználó azonosítóját...",
                },
                buttonLabels: {
                    deleteFilters: "Szűrők törlése",
                    applyFilters: "Szűrők alkalmazása",
                },
                noData: "Nincs megjeleníthető adat",
            },
        },
        clientEverydaySituations: {
            tabs: {
                clientDirectory: "Felhasználó mappája",
                directories: "Támogató által létrehozott mappák",
            },
            clientDirectory: {
                title: `Mindennapi helyzetek - {name} mappája`,
                visibility: {
                    description: "Itt engedélyezheted {name} számára, hogy látható legyen a mobilján az applikációban a saját mappája.",
                    label: "Saját mappa látható",
                },
                writability: {
                    description:
                        "Itt engedélyezheted {name} számára, hogy saját mappába (szöveges, fotós, videós, hangalapú) tartalamakat töltsön fel a mobilalkalmazáson keresztül. Ha nem engeded a feltöltést, de a mappa látható, akkor üres lesz, vagy az eddig feltöltött elemek láthatók benne a mobilon.",
                    label: "Tartalomfeltöltés engedélyezett",
                },
                form: {
                    title: {
                        label: "Mappa neve:",
                        placeholder: "pl.: Megoldandó helyzeteim",
                    },
                    image: {
                        label: "Mappa borítóképe:",
                    },
                    items: {
                        label: "A mappa elemei:",
                        error: "Legalább egy elem hozzáadása szükséges!",
                        moveError: "Átmozgatás nem lehetséges! A művelet elvégzéséhez legalább 1 mentett elemnek lennie kell!",
                    },
                },
                saveSucceed: "Felhasználó mappájának mentése sikeres!",
            },
            directories: {
                title: "Mindennapi helyzetek - támogató által létrehozott mappák",
                description: "Itt készíthetsz {name} számára mappákat, melyekhez szöveges, képi és hang tartalmakat rendelhetsz. A mappák sorrendjét változtatni tudod.",
                editDescription:
                    "Itt készíthetsz {name} számára különböző mappákat, amelyben a mindennapi helyzetek kezelését megkönnyítő társas viselkedési és egyéb szabályokat, stratégiákat mutathatod be. A mappát csak akkor tudod hozzáadni, ha legalább egy elemet beleteszel. Üres mappa nem adható hozzá. A képernyőolvasó bekapcsolásához nyomja meg a(z) ⌘+Alt+Z billentyűket. A billentyűkódokról a(z) ⌘törtjel billentyűk együttes lenyomásával tudhat meg többet.",
                add: "Új mappa hozzáadása",
                counts: {
                    text: "Szöveges tartalmak",
                    audio: "Hangfájl tartalmak",
                    image: "Képfájl tartalmak",
                },
                page: {
                    create: {
                        title: "{name} - Mindennapi helyzetek mappa létrehozása",
                    },
                    edit: {
                        title: "{name} - Mindennapi helyzetek mappa szerkesztése",
                    },
                },
                form: {
                    formTitle: {
                        create: "Új elem felvétele",
                        edit: "Elem szerkesztése",
                    },
                    type: {
                        label: "Elem típusa:",
                    },
                    title: {
                        label: {
                            [EverydaySituationType.text]: "Szöveg neve:",
                            [EverydaySituationType.image]: "Kép neve:",
                            [EverydaySituationType.audio]: "Hang neve:",
                        },
                        placeholder: "pl.: Megoldandó helyzeteim",
                    },
                    text: {
                        label: "Szöveg:",
                        placeholder: "pl.: Elem szövege",
                    },
                    image: {
                        label: "Kép:",
                    },
                    audio: {
                        label: "Hang:",
                    },
                },
                saveSucceed: "Mindennapi helyzet mappa mentése sikeres!",
            },
        },
        clientEverydaySituationsDirectory: {
            edit: {
                title: "{name} - Mindennapi helyzetek szerkesztés",
            },
            create: {
                title: "{name} - Mindennapi helyzetek létrehozás",
            },
            form: {
                image: {
                    label: "MAPPA ELŐNÉZETI KÉPE:",
                },
            },
        },
        clientSpareAgendaItems: {
            title: "{name} - Tartalék napirendi lépések",
            sectionTitle: "Tartalék napirendi lépések",
            lead:
                "Itt választhatod ki {name} számára, hogy milyen tartalék napirendi lépései legyenek. Ezeket a lépéseket {name} mobilján a szerkesztésben eléred és bármikor beszúrhatod a napirendjébe. Érdemes olyan napirendi lépéseket választanod, amelyek jól jöhetnek egy mindennapi változás esetén, mint például a szabadidő, mosdó vagy vásárlás.",
            saveSucceed: "Tartalék lépések mentése sikeres!",
            slotMessage: "Húzd ide a kiválasztott napirendi lépést!",
        },
        educationModules: {
            title: "Útmutatók",
            description: "Itt láthatod a modulokat és főbb adataikat, és itt tudsz továbblépni az egyes modulok szerkesztéséhez.",
            table: {
                columns: {
                    title: "Főmodul neve",
                    submoduleCount: "Almodulok száma",
                    createdBy: "Létrehozó",
                    updatedAt: "Utolsó módosítás",
                    actions: " ",
                },
            },
        },
        educationSubModule: {
            title: "{position}. Modul",
            description: "Itt láthatod és szerkesztheted az almodulok helyét, vagy törölheted is a modul almoduljait",
            table: {
                columnContent: {
                    title: "{module}.{subModule} modul: {title}",
                },
                columns: {
                    title: "Almodul neve",
                    createdBy: "Létrehozó",
                    updatedAt: "Utolsó módosítás",
                    actions: " ",
                },
            },
            createSubModuleButton: "Új almodul létrehozása",
        },
        educationSubModuleForm: {
            page: {
                create: {
                    title: "Új almodul létrehozása",
                    lead: "Itt töltheted fel az új almodul tartalmait és kialakíthatod azok sorrendjét.",
                    saveSucceed: "Almodul létrehozása sikeres!",
                },
                edit: {
                    title: "Almodul szerkesztése",
                    lead: "Itt szerkesztheted az almodul tartalmait és átalakíthatod azok sorrendjét.",
                    saveSucceed: "Almodul módosítása sikeres!",
                },
                title: {
                    label: "Almodul címe:*",
                    placeholder: "Írja be az almodul címét...",
                },
                documentsSection: {
                    title: "PDF feltöltés:",
                    button: "PDF hozzáadása",
                },
                videosSection: {
                    title: "Videók feltöltése:",
                    button: "Videó hozzáadása",
                },
                videoTitle: {
                    label: "Videó neve",
                    placeholder: "Írja be a videó nevét...",
                },
                showVideoTitle: "Videó lejátszása",
                uploadProgress: "Feltöltés {progress} %",
                filesAboveMaxSize: {
                    document: "A feltöltött PDF fájlok mérete legfeljebb {maxDocumentSize} lehet! A következő fájlok ezt meghaladják: {documentsToReject}",
                    video: "A feltöltött videók mérete legfeljebb {maxVideoSize} lehet! A következő fájlok ezt meghaladják: {videosToReject}",
                },
            },
        },
        faq: {
            list: {
                title: "Gyakran ismételt kérdések",
                lead: "Itt gyűjtjük össze és folyamatosan bővítjük a DATA rendszer használata során felmerülő kérdéseket és válaszokat.",
                columns: {
                    question: "Kérdés",
                    createdBy: "Létrehozó",
                    updatedAt: "Utolsó módosítás",
                    actions: " ",
                    position: "Sorrend",
                },
                createButtonLabel: "Új kérdés felvitele",
            },
            page: {
                create: {
                    title: "Új kérdés felvétele",
                    lead: "Itt vehetsz fel új kérdést és a hozzá tartozó választ",
                    saveSucceed: "Kérdés létrehozása sikeres!",
                },
                edit: {
                    title: "Kérdés szerkesztése",
                    lead: "Itt szerkesztheted a kérdést és a hozzá tartozó választ",
                    saveSucceed: "Kérdés módosítása sikeres!",
                },
                question: {
                    label: "Kérdés: *",
                    placeholder: "Írjon be egy kérdést...",
                },
                answer: {
                    label: "Válasz: *",
                    placeholder: "Írjon be egy választ...",
                },
            },
        },
        clientAwardsBase: {
            title: "{name} - Jutalmazás",
            showImageDialogTitle: "Jutalom képe",
            clientInstantAwardsTab: {
                lead:
                    "Itt állíthatod össze azokat az azonnali (rögtön a tevékenység után beváltható) jutalmakat, amiket egy napirendi lépéshez vagy folyamatábrához tudsz csatolni, amikor azokat szerkeszted.",
                title: "Azonnali jutalmazás",
                columns: {
                    title: "Jutalmak",
                    actions: " ",
                },
                noData: "Nincs megjeleníthető adat",
            },
            clientInstantAward: {
                create: {
                    title: "{clientName} - Jutalom hozzáadása",
                    lead: "Itt adhatsz hozzá új jutalmat, amelyek a napirendi és folyamatábra lépések szerkesztésénél egy listából kiválaszthatók lesznek.",
                    saveSucceed: "A jutalom létrehozása sikeres!",
                },
                edit: {
                    title: "{clientName} - {clientInstantAwardName}",
                    lead: "Itt adhatsz hozzá új jutalmat, amelyek a napirendi és folyamatábra lépések szerkesztésénél egy listából kiválaszthatók lesznek.",
                    saveSucceed: "A jutalom módosítása sikeres!",
                },
                dataSectionTitle: "Jutalom adatai",
                title: {
                    label: "Jutalom neve",
                    placeholder: "Add meg a jutalom nevét",
                },
                image: {
                    label: "Jutalom képe",
                },
            },
            clientAwardsTab: {
                lead:
                    "Itt állíthatod be {name} jutalmait, amik beváltásához pontokat gyűjthet. A pontokat különböző, általad meghatározott értékű jutalmakra 'költheti el'. Az egyes jutalmakat akkor tudja {name} beváltani, ha azok 'aktív' státuszra vannak állítva (ehhez az 'aktiválom' feliratra kell kattintanod, ha azt szeretnéd, hogy az adott jutalom elérhető legyen {name} számára).",
                balance: "{name} jelenleg összegyűjtött pontszáma:",
                title: "Pontgyűjtős jutalmazás",
                columns: {
                    title: "Jutalmak",
                    targetScore: "Érték",
                    isActive: "Státusz",
                    actions: " ",
                },
                targetScorePoints: "{points} pont",
                isActiveValues: {
                    false: "Aktiválom",
                    true: "Aktív",
                },
                activateSucceed: "Jutalom sikeresen aktiválva!",
                noData: "Nincs megjeleníthető adat",
            },
            clientAward: {
                create: {
                    title: "{clientName} - Jutalom hozzáadása",
                    lead:
                        "Itt adhatsz hozzá új jutalmat. Ha szeretnéd, hogy az adott jutalom egy beváltás után újra elérhető legyen {name} számára, akkor pipáld ki a 'beváltás után automatikusan újra elérhető' felirat melletti jelölőnégyzetet. A jutalom értékénél beállíthatod, hogy hány pontot kell ezért a jutalomért összegyűjteni.",
                    saveSucceed: "A jutalom létrehozása sikeres!",
                },
                edit: {
                    title: "{clientName} - {clientAwardName}",
                    lead:
                        "Ha szeretnéd, hogy az adott jutalom egy beváltás után újra elérhető legyen {name} számára, akkor pipáld ki a 'beváltás után automatikusan újra elérhető' felirat melletti jelölőnégyzetet. A jutalom értékénél beállíthatod, hogy hány pontot kell ezért a jutalomért összegyűjteni.",
                    saveSucceed: "A jutalom módosítása sikeres!",
                },
                settingsSectionTitle: "Beállítások",
                dataSectionTitle: "Jutalom adatai",
                title: {
                    label: "Jutalom neve:",
                    placeholder: "Add meg a jutalom nevét",
                },
                autoReactivation: {
                    label: "Beváltás után automatikusan újra elérhető",
                },
                image: {
                    label: "Jutalom képe",
                },
                targetScore: {
                    label: "Jutalom értéke:",
                    placeholder: "Add meg a jutalom értékét",
                },
            },
        },
        clientEverydaySituationDirectory: {
            title: "{extId} - Mindennapi helyzetek megtekintése",
            directoryNotFound: "A mappa nem található!",
            form: {
                title: "Mappa megnevezése",
                image: "Mappa képe",
                items: "Mappa elemek",
            },
        },
        tags: {
            title: "Címkék",
            description: "Itt módosíthatod a DATA rendszer címkéit.",
            tagTable: {
                sortField: {
                    createdAt: "letrehozas_datuma",
                    title: "nev",
                },
                columns: {
                    title: "Címke neve",
                    category: "Kategória",
                    createdBy: "Készítő",
                    createdAt: "Létrehozás dátuma",
                    updatedAt: "Utolsó módosítás",
                },
                noData: "Nincs megjeleníthető adat",
            },
        },
        createTag: {
            title: "Új címke létrehozása",
            description: "Itt vehetsz fel egy új címkét a címkesorba",
            form: {
                title: {
                    label: "Címke neve",
                    placeholder: "Adja meg a cimke nevét",
                },
                category: {
                    label: "Kategóriája",
                    placeholder: "Válassz kategóriát",
                },
            },
            success: "A címke létrehozása sikeres!",
        },
        education: {
            title: "Útmutatók",
            tab: {
                general: {
                    title: "Általános információk",
                    lead: "Itt láthatod a modulokat és főbb adataikat, és itt tudsz továbblépni az egyes modulok szerkesztéséhez.",
                    dataSection: {
                        title: "A data rendszerről",
                        text:
                            "A DATA projekt célja autizmussal élő, vagy más okból hasonló támogatást igénylő személyek mindennapjait segítő rendszer fejlesztése. A DATA rendszer két komponensből tevődik össze: a DATA mobilalkalmazásból és a webes tartalomtervező felületből. Most a webes tartalomtervező felületen vagy. A mobilapplikáció Android és iOS platformon is fut, letöltheted a Play Áruházból (Android) vagy az Apple Store-ból (iOS).",
                    },
                    moduleSection: {
                        title: "Modulok",
                        text:
                            "A felkészítő anyagok három téma köré szerveződnek: alapismereteket nyújtanak az autizmusról, a vizuális támogatások eszközeiről és módszertanáról, és segítik a DATA rendszerben való eligazodást, a rendszer használatát. Az egyes témáknak megfelelően a felkészítő anyagok három modulban találhatók meg.",
                    },
                    info: {
                        title: "Általános információk",
                        text1:
                            "Mindegyik modul tartalmaz pdf formátumban szöveges felkészítő anyagot. Az első két modul tartalma videókon is megtekinthető. A harmadik modul képernyővideókat is tartalmaz.Ezek online nézhetők és/vagy letölthetők.",
                        text2: "Amennyiben a DATA projektről szeretnél többet megtudni:",
                        contactHomepageTitle: "Látogass el honlapunkra:",
                        contactHomepageDescription: "data.aosz.hu",
                        contactHomepageLink: "http://data.aosz.hu/",
                        contactNewsletterTitle: "Iratkozz fel hírlevelünkre:",
                        contactNewsletterDescription: "data.aosz.hu/csatlakozz-hozzank",
                        contactNewsletterLink: "http://data.aosz.hu/csatlakozz-hozzank/",
                        contactHelpdeskTitle: "Keresd az AOSZ Info-Pont irodában dolgozó Helpdesk munkatársakat országszerte:",
                        contactHelpdeskDescription: "data.aosz.hu/szolgaltatasi-pontok",
                        contactHelpdeskLink: "http://data.aosz.hu/szolgaltatasi-pontok/",
                    },
                },
                module: {
                    mainSectionTitle: "{position}. modul: {title}",
                    documentSectionTitle: "{position}. modul szöveges felkészítő anyaga:",
                    documentSectionLead: "Itt töltheted le a(z) {position}. modulhoz tartozó teljes felkészítő anyagot.",
                    documentSectionLeadOtherDoc: "Itt töltheted le a(z) {position}. modulhoz tartozó felkészítő anyag bevezetőjét.",
                    subModuleTitle: "{position}. modul: {title}",
                    subModuleDocuments: "Itt töltheted le a(z) {position}. modulhoz tartozó felkészítő anyagokat.",
                },
            },
            moduleTabTitle: "Modul {position}",
        },
        contentShareRequestList: {
            title: "Felajánlott tartalmak",
            table: {
                sortField: {
                    libraryContentTitle: "nev",
                    libraryContentCreatedAt: "letrehozas_datuma",
                    libraryContentUpdatedAt: "modositas_datuma",
                },
                columns: {
                    libraryContentTitle: "Név",
                    libraryContentCreatedAt: "Létrehozás dátuma",
                    libraryContentUpdatedAt: "Utolsó módosítás dátuma",
                    actions: " ",
                },
            },
        },
        myEventLog: {
            title: "{name} - Eseménynapló",
        },
    },
};

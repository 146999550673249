/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Component } from "react";
import ReactPlayer, { ReactPlayerProps, Config, FileConfig } from "react-player";
import { connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers";

type ReduxProps = {
    authToken: string | null;
};
type ComponentProps = ReactPlayerProps;

type Props = ReduxProps & DispatchProp & ComponentProps;

class PlayerComponent extends Component<Props> {
    public render(): JSX.Element | null {
        const { authToken, dispatch, ...otherProps } = this.props;
        const url = this.props.authToken ? `${this.props.url}?authorization=${this.props.authToken}` : this.props.url;

        const fileConfig: FileConfig = this.props.config?.file || { attributes: { poster: null } };
        // @ts-ignore
        if (fileConfig.attributes?.poster) {
            // @ts-ignore
            fileConfig.attributes.poster = `${fileConfig.attributes.poster}?authorization=${this.props.authToken}`;
        }
        const config: Config = { ...(this.props.config || {}), file: { ...fileConfig } };

        return <ReactPlayer {...otherProps} url={url} config={config} />;
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { authToken: state.auth.authToken };
};

export const Player = connect(mapStateToProps)(PlayerComponent);

import React, { PureComponent } from "react";
import { ClientProfileData, ClientQuestionnaireQuestion, ClientQuestionnaireQuestion_answerOptions } from "api/graphql/types";
import { ClientQuestionnaireAnswer } from "api/ApiTypes";
import { RadioInput, RadioOption } from "components/Inputs/RadioInput/RadioInput";
import { Section } from "components/Section";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";

interface Props {
    clientProfile: ClientProfileData | null;
    clientQuestionnaireAnswers: ClientQuestionnaireAnswer[];
    questions: ClientQuestionnaireQuestion[];
    onAnswerChange: (index: number) => (selectedOption: RadioOption) => void;
    onBack: () => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

class ClientQuestionnaireForm extends PureComponent<Props> {
    private renderQuestion = (question: ClientQuestionnaireQuestion, index: number, array: ClientQuestionnaireQuestion[]): React.ReactElement<any> => {
        const options: RadioOption[] = question.answerOptions.map((answer: ClientQuestionnaireQuestion_answerOptions) => {
            return {
                id: answer.id,
                label: answer.title,
            };
        });

        const isLastItem: boolean = index === array.length - 1;
        return (
            <React.Fragment key={question.id}>
                <h3>{question.title.toUpperCase()}</h3>
                <RadioInput label={question.description} options={options} onChange={this.props.onAnswerChange(index)} value={this.props.clientQuestionnaireAnswers[index].answerOptionId} />
                {!isLastItem && <hr />}
            </React.Fragment>
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="left-side questionnaire">
                <form noValidate={true} onSubmit={this.props.onSubmit}>
                    <Section label={Intl.formatMessage({ id: "page.addClient.questionnaire.title" })}>
                        {this.props.questions.map(this.renderQuestion)}
                        <hr />
                        <div className="grid-x">
                            <div className="cell medium-6">
                                <Button hollow label={Intl.formatMessage({ id: "common.back" })} onClick={this.props.onBack} />
                            </div>
                            <div className="cell medium-6 text-right">
                                <Button label={Intl.formatMessage({ id: "common.send" })} type="submit" />
                            </div>
                        </div>
                    </Section>
                </form>
            </div>
        );
    }
}

export { ClientQuestionnaireForm };

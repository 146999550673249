import { Env } from "utils/Env";

export const art: () => void = (): void => {
    const style = "color:white;font-weight:bold;background-color:#143A74;";

    // tslint:disable-next-line
    console.log(
        `%c
                                                                           
     ####  #####   ###### #######       ######       #### ########   ####  
    ##### ###  ## ###  ##     ###        ### ##     ##### #  ###    #####  
   ## ### ###  ## ####       ###         ###  ##   ## ###    ###   ## ###  
  ##  ### ###  ##  #####    ###    ####  ###  ##  ##  ###    ###  ##  ###  
 ######## ###  ##    ####  ###           ###  ## ########    ### ########  
 ##   ### ###  ## ##  ### ###            ### ##  ##   ###    ### ##   ###  
 ##   ###  #####   #####  #######        #####   ##   ###    ### ##   ###  
                                                                           
          v${Env.packageJson.version.padEnd(48)}                
                                                                           `,
        style,
    );
};

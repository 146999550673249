import React from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps, RouteComponentProps } from "react-router";
import { Path } from "utils/Path";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AuthSelectors } from "selectors/AuthSelectors";

interface ReduxProps {
    isLoggedIn: boolean;
}

type ComponentProps = RouteProps;

type Props = ReduxProps & ComponentProps;

class GuestRouteComponent extends React.Component<Props> {
    public render(): React.ReactElement {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props: RouteComponentProps<any>): React.ReactNode | null => {
                    if (this.props.isLoggedIn) {
                        return <Redirect to={{ pathname: Path.dashboard }} />;
                    } else {
                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }
                }}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

export const GuestRoute = connect(mapStateToProps)(GuestRouteComponent);

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteProfileDialogProps {
    profileName: string;
    onDelete: () => Promise<void> | void;
}

type Props = DeleteProfileDialogProps & DialogVisibilityProps;

interface State {
    isConfirmAgain: boolean;
    profileName: string;
    isValidationEnabled: boolean;
    formErrors: {
        profileName: string | null;
    };
}

class DeleteProfileDialog extends Component<Props, State> {
    private getInitialState(): State {
        return {
            isConfirmAgain: false,
            profileName: "",
            isValidationEnabled: false,
            formErrors: {
                profileName: null,
            },
        };
    }
    public readonly state: State = this.getInitialState();

    private onProfileNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const formErrors = {
            profileName: this.isValidProfileName(event.currentTarget.value) ? null : Intl.formatMessage({ id: "page.profile.accountData.delete.confirmAgain.validationError" }),
        };

        this.setState({ profileName: event.currentTarget.value, formErrors });
    };

    private isValidProfileName = (value: string): boolean => {
        return value === this.props.profileName;
    };

    private onDeleteClick = (): void => {
        const formErrors = {
            profileName: this.isValidProfileName(this.state.profileName) ? null : Intl.formatMessage({ id: "page.profile.accountData.delete.confirmAgain.validationError" }),
        };

        if (!formErrors.profileName) {
            this.props.onDelete();
        } else {
            this.setState({ isValidationEnabled: true, formErrors });
        }
    };

    private renderConfirmDialog(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.profile.accountData.delete.confirm.title" })} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "page.profile.accountData.delete.confirm.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            label={Intl.formatMessage({ id: "common.yes" })}
                            onClick={() => {
                                this.setState({ isConfirmAgain: true });
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

    private renderConfirmAgainDialog(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.profile.accountData.delete.confirm.title" })} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "page.profile.accountData.delete.confirmAgain.description" })}</p>
                <InputWrapper
                    inputLabel={Intl.formatMessage({ id: "page.profile.accountData.delete.confirmAgain.inputLabel" })}
                    errorMessage={this.state.isValidationEnabled ? this.state.formErrors.profileName : null}
                >
                    <Input
                        type="text"
                        placeholder={Intl.formatMessage({ id: "page.profile.accountData.delete.confirmAgain.inputPlaceholder" })}
                        onChange={this.onProfileNameChange}
                        value={this.state.profileName}
                    />
                </InputWrapper>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            label={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.onDeleteClick}
                            disabled={this.state.isValidationEnabled && !this.isValidProfileName(this.state.profileName)}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

    public render(): React.ReactElement<any> {
        return this.state.isConfirmAgain ? this.renderConfirmAgainDialog() : this.renderConfirmDialog();
    }
}

export { DeleteProfileDialog };

import React from "react";
import { SearchListType, AgendaItemContent, AgendaItemContent_agendaItems, AssetType } from "api/graphql/types";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { withDragContext } from "../DragContext";
import { DragUtils } from "../DragUtils";
import { ContentInput, GenericComponentProps } from "./ContentInput";
import { ContentElement } from "pages/_shared/Draggables/ContentElement/ContentElement";
import { Intl } from "i18n/Intl";
import { ContentElementUtils } from "../ContentElement/ContentElementUtils";
import { ImageSrc } from "utils/ImageSrc";
import { ApiTypes } from "api/ApiTypes";
import { ContentElementButtons } from "../ContentElement/ContentElementButtons";
import { Draggable } from "../Draggable";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface ComponentProps extends GenericComponentProps<AgendaItemContent> {
    slotMessageOverride?: string;
}

class AgendaItemInputComponent extends ContentInput<AgendaItemContent, ComponentProps> {
    protected slotMessage = (): string => this.props.slotMessageOverride ?? Intl.formatMessage({ id: "sharedComponent.contentInput.agendaItem.slotMessage" });
    protected slotDisabledMessage = undefined;
    protected searchListType: SearchListType = SearchListType.agendaItem;

    private renderSimple = (agendaItem: AgendaItemContent, index: number): React.ReactElement<any> => {
        const asset = agendaItem.image || agendaItem.video;
        return (
            <ContentElement
                key={agendaItem.id}
                fallbackImage={ImageSrc[this.searchListType]}
                title={agendaItem.title}
                images={ApiTypes.getAgendaItemImages(agendaItem)}
                info={ContentElementUtils.getAgendaItemContentInfo(agendaItem)}
                contentChildren={ContentElementUtils.getAgendaItemFlowcharts(agendaItem)}
                onCloseClick={() => this.onCloseClick(agendaItem, index)}
                onViewClick={this.onViewClick(agendaItem)}
                onEditClick={this.onEditClick(agendaItem)}
                reorder={this.getReorderOptions(index)}
                disabled={this.props.disabled}
                isReorderDisabled={this.props.isReorderDisabled}
                onPreviewClick={
                    asset
                        ? () => {
                              store.dispatch(
                                  DialogsActions.show({
                                      type: DialogType.showAsset,
                                      assetUrl: asset?.url,
                                      assetType: asset?.assetType || AssetType.image,
                                      originalFileName: asset?.originalFileName,
                                      thumbnailUrl: asset?.thumbnailUrl,
                                      dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                  }),
                              );
                          }
                        : undefined
                }
                overlayIcon={asset ? <OverlayIcon assetType={asset?.assetType} /> : undefined}
            />
        );
    };

    private renderSelect = (agendaItem: AgendaItemContent, index: number): React.ReactElement<any> => {
        const reorder = this.getReorderOptions(index);

        return (
            <div className="agenda-group-selector" key={agendaItem.id}>
                <div className="row align-middle no-gutters agenda-group-selector__head">
                    <div className="col">
                        <h3 className="agenda-group-selector__heading">{agendaItem.title}</h3>
                    </div>
                    <div className="agenda-group-selector__element-buttons-wrapper">
                        <ContentElementButtons
                            onCloseClick={() => this.onCloseClick(agendaItem, index)}
                            onViewClick={this.onViewClick(agendaItem)}
                            onEditClick={this.onEditClick(agendaItem)}
                            isDeleteActive={!this.props.disabled}
                        />
                    </div>
                </div>

                {agendaItem.agendaItems &&
                    agendaItem.agendaItems.map((agendaItem: AgendaItemContent_agendaItems, index: number) => {
                        const asset = agendaItem.agendaItem.image || agendaItem.agendaItem.video;
                        return (
                            <ContentElement
                                key={agendaItem.agendaItem.id}
                                fallbackImage={ImageSrc[this.searchListType]}
                                title={agendaItem.agendaItem.title}
                                images={ApiTypes.getAgendaItemImages(agendaItem.agendaItem)}
                                info={ContentElementUtils.getAgendaItemContentInfo(agendaItem.agendaItem)}
                                contentChildren={ContentElementUtils.getAgendaItemFlowcharts(agendaItem.agendaItem)}
                                reorder={this.getReorderOptions(index)}
                                disabled={this.props.disabled}
                                isChildContent={true}
                                isReorderDisabled={true}
                                ariaLevel={4}
                                onPreviewClick={
                                    asset
                                        ? () => {
                                              store.dispatch(
                                                  DialogsActions.show({
                                                      type: DialogType.showAsset,
                                                      assetUrl: asset?.url,
                                                      assetType: asset?.assetType || AssetType.image,
                                                      originalFileName: asset?.originalFileName,
                                                      thumbnailUrl: asset?.thumbnailUrl,
                                                      dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                                  }),
                                              );
                                          }
                                        : undefined
                                }
                                overlayIcon={asset ? <OverlayIcon assetType={asset?.assetType} /> : undefined}
                            />
                        );
                    })}

                {!this.props.disabled && (
                    <div className="agenda-group-selector__drag-space">
                        {!this.props.isReorderDisabled && reorder.upEnabled && (
                            <div>
                                <button
                                    className="fa fa-sort-up agenda-group-selector__drag-space-button"
                                    type="button"
                                    aria-label={Intl.formatMessage({ id: "common.up" })}
                                    title={Intl.formatMessage({ id: "common.up" })}
                                    onClick={() => reorder.call(true)}
                                />
                            </div>
                        )}
                        {!this.props.isReorderDisabled && reorder.downEnabled && (
                            <div>
                                <button
                                    className="fa fa-sort-down agenda-group-selector__drag-space-button"
                                    type="button"
                                    aria-label={Intl.formatMessage({ id: "common.down" })}
                                    title={Intl.formatMessage({ id: "common.down" })}
                                    onClick={() => reorder.call(false)}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    protected renderDraggable = (agendaItem: AgendaItemContent, index: number): React.ReactElement<any> => {
        if (this.props.isReorderDisabled) {
            return agendaItem.agendaItems && agendaItem.agendaItems.length > 0 ? this.renderSelect(agendaItem, index) : this.renderSimple(agendaItem, index);
        }

        return (
            <Draggable
                draggableId={`${this.props.droppableId}-agendaItem-${index}-${agendaItem.id}`}
                key={`agendaItem-${index}-${agendaItem.id}`}
                index={index}
                type={SearchListType.agendaItem}
                isDragDisabled={this.isDragDisabled()}
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? "grabbed" : ""}
                        style={DragUtils.getStyle(provided.draggableProps.style, snapshot)}
                    >
                        {agendaItem.agendaItems && agendaItem.agendaItems.length > 0 ? this.renderSelect(agendaItem, index) : this.renderSimple(agendaItem, index)}
                    </div>
                )}
            </Draggable>
        );
    };
}

export const AgendaItemInput = withDragContext(AgendaItemInputComponent);

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { SortOrder, ListControl, AgendaListItem, SearchListSortField, SearchListOptionsInput, Account, AccountType, SupportedClient } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { ListResult } from "api/ApiTypes";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";
import { connect, DispatchProp } from "react-redux";
import { Api } from "api/Api";
import { AccountActions } from "actions/AccountActions";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { PageContent, ContentPageUtils } from "../ContentPageUtils";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";
import { isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";

interface AgendaTableSearchListOptions {
    sortField?: SearchListSortField | null;
    control: ListControl;
}

enum AgendaTableColumn {
    title = "title",
    numberOfSteps = "numberOfSteps",
    info = "info",
    createdAt = "createdAt",
    createdByName = "createdByName",
    updatedAt = "updatedAt",
    day = "day",
    actions = "actions",
}

interface ComponentProps {
    search?: string;
    tags?: string[];
    pageContent: PageContent;
    currentUrlParams: string;
    client?: SupportedClient | null;
    supporterExtId?: string | null;
    supervisorId?: string | null;
    isPublic?: boolean;
    accountType: AccountType;
    getAgendas: (options?: SearchListOptionsInput, tags?: string[]) => Promise<ListResult<AgendaListItem>>;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

interface State {
    agendas: AgendaListItem[];
    count: number;
    isLoading: boolean;
    options: AgendaTableSearchListOptions;
    tags: string[];
    agendaToClient: AgendaListItem | null;
    agendaToToggle: AgendaListItem | null;
}

class AgendaTableComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): AgendaTableSearchListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.agendaTable").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search: props.search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    private static getInitialTags(props: Props): string[] {
        return props.tags || [];
    }

    public readonly state: State = {
        agendas: [],
        isLoading: true,
        count: 0,
        options: AgendaTableComponent.getInitialOptions(this.props),
        agendaToClient: null,
        agendaToToggle: null,
        tags: AgendaTableComponent.getInitialTags(this.props),
    };

    public componentDidMount(): void {
        this.refreshAgendas(this.state.options, this.state.tags);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: AgendaTableSearchListOptions = AgendaTableComponent.getInitialOptions(nextProps);
        const nextTags: string[] = AgendaTableComponent.getInitialTags(nextProps);
        if (!isEqual(this.state.options, nextOptions) || this.props.pageContent !== nextProps.pageContent || !isEqual(this.state.tags, nextTags)) {
            this.setState({ options: nextOptions, tags: nextTags }, () => {
                this.updateQueryParams();
            });
        }
    }

    private refreshAgendas = (options?: SearchListOptionsInput, tags?: string[]): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await this.props.getAgendas(options, tags);
                    this.setState({ agendas: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, agendas: [] });
                }
            },
        );
    };

    private refreshAccount = async (): Promise<void> => {
        try {
            const account: Account = await Api.me();
            this.props.dispatch(AccountActions.updateAccount(account));
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private filterColumnNames = (columnNames: AgendaTableColumn[]): AgendaTableColumn[] => {
        let returnedColumnNames: AgendaTableColumn[] = [...columnNames];

        if (!this.props.isPublic || this.props.accountType !== AccountType.supervisor) {
            returnedColumnNames = returnedColumnNames.filter((columnName: AgendaTableColumn) => columnName !== AgendaTableColumn.createdByName);
        }

        return returnedColumnNames;
    };

    private onSetDefaultAgendaClick = (agenda: AgendaListItem) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.setDefaultAgenda,
                onSetSucceed: (): void => {
                    this.refreshAgendas(this.state.options, this.state.tags);
                    this.refreshAccount();
                },
                clientId: this.props.client ? this.props.client.id : undefined,
                agenda,
            }),
        );
    };

    private onDeleteClick = (agenda: AgendaListItem) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteAgenda,
                onDeleted: () => {
                    this.refreshAgendas(this.state.options, this.state.tags);
                },
                agenda,
            }),
        );
    };

    private onUnsetClientDefaultAgenda = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.unsetDefaultAgenda,
                onSetSucceed: (): void => {
                    this.refreshAgendas(this.state.options, this.state.tags);
                    this.refreshAccount();
                },
                clientId: this.props.client ? this.props.client.id : undefined,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<AgendaListItem>> => {
        const clientExtId: string | undefined = this.props.client ? this.props.client.extId : undefined;
        const defaultAgendaId: string | null = this.props.client && this.props.client.defaultAgenda ? this.props.client.defaultAgenda.id : null;
        let columnNames: AgendaTableColumn[] = ObjectUtils.enumAsArray<AgendaTableColumn>(AgendaTableColumn);

        if (!this.props.client) {
            columnNames = columnNames.filter((columnName: AgendaTableColumn) => ![AgendaTableColumn.day].includes(columnName));
        }

        columnNames = this.filterColumnNames(columnNames);

        return columnNames.map(
            (columnName: AgendaTableColumn): Column<AgendaListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.agendaTable.columns.${columnName}` }),
                accessor: columnName as keyof AgendaListItem,
                className: columnName === AgendaTableColumn.info ? "info" : undefined,
                renderCell: (agenda: AgendaListItem): React.ReactElement<any> | null => {
                    const isDefaultAgenda: boolean = defaultAgendaId === agenda.id;
                    const onAssignToDayClick: (() => void) | undefined =
                        !isDefaultAgenda && this.props.client
                            ? (): void => {
                                  this.props.dispatch(
                                      DialogsActions.show({
                                          type: DialogType.assignToDay,
                                          agenda,
                                          onAssigned: (): void => {
                                              this.refreshAgendas(this.state.options, this.state.tags);
                                          },
                                          client: this.props.client,
                                      }),
                                  );
                              }
                            : undefined;

                    switch (columnName) {
                        case AgendaTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    clientExtId={clientExtId}
                                    supporterExtId={this.props.supporterExtId}
                                    supervisorId={this.props.supervisorId}
                                    content={agenda}
                                    onDeleteClick={this.onDeleteClick(agenda)}
                                    onAssignToDayClick={onAssignToDayClick}
                                    onSetDefaultAgendaClick={isDefaultAgenda || !!agenda.day ? undefined : () => this.onSetDefaultAgendaClick(agenda)}
                                    onUnsetClientDefaultAgenda={isDefaultAgenda ? this.onUnsetClientDefaultAgenda : undefined}
                                    onShareClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.shareContent,
                                                content: agenda,
                                                onSucceed: () => this.refreshAgendas(this.state.options, this.state.tags),
                                            }),
                                        );
                                    }}
                                    onAssignToClientClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.assignToClient,
                                                content: agenda,
                                            }),
                                        );
                                    }}
                                    onToggled={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.contentToggler,
                                                onToggled: (): void => this.refreshAgendas(this.state.options, this.state.tags),
                                                content: agenda,
                                            }),
                                        );
                                    }}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: agenda,
                                                onDisabled: (): void => this.refreshAgendas(this.state.options, this.state.tags),
                                            }),
                                        );
                                    }}
                                    refreshContents={() => this.refreshAgendas(this.state.options, this.state.tags)}
                                />
                            );
                        case AgendaTableColumn.createdAt:
                        case AgendaTableColumn.updatedAt:
                            return <>{DateUtils.format(new Date(agenda[columnName]), DateFormat.dateTime)}</>;
                        case AgendaTableColumn.createdByName:
                            return <>{agenda.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</>;
                        case AgendaTableColumn.day:
                            const day: Date | null = agenda[columnName] ? new Date(agenda[columnName]) : null;
                            return day ? <>{DateUtils.format(day, DateFormat.yyyymmdd)}</> : <></>;
                        case AgendaTableColumn.numberOfSteps:
                            return <>{agenda.itemList ? agenda.itemList.length : 0}</>;
                        case AgendaTableColumn.info:
                            return (
                                <>
                                    <DisabledLock isVisible={!isNil(agenda.disabledAt)} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.agendaTable.disabledTooltip" })} />
                                    {isDefaultAgenda ? (
                                        <TooltipWrapper tooltip={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.agendaTable.defaultAgendaTooltip" })}>
                                            <span className="fab fa-adn clr-blue mr-20"></span>
                                        </TooltipWrapper>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            );
                        case AgendaTableColumn.title:
                            return (
                                <div className="table-image-name">
                                    <div className="table-image-container">
                                        <Image fallback={ImageSrc.agenda} />
                                    </div>
                                    {agenda.title}
                                </div>
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: !ObjectUtils.isEnumContains<SearchListSortField>(SearchListSortField, columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<SearchListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
            tags: this.state.tags,
        };

        const params = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.agendaTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshAgendas(this.state.options, this.state.tags);
    };

    private convertColumnIdToSortField = (columnId?: string): SearchListSortField | undefined => {
        switch (columnId) {
            case AgendaTableColumn.createdAt:
                return SearchListSortField.createdAt;
            case AgendaTableColumn.day:
                return SearchListSortField.day;
            case AgendaTableColumn.updatedAt:
                return SearchListSortField.updatedAt;
            case AgendaTableColumn.title:
                return SearchListSortField.title;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: SearchListSortField | null): keyof AgendaListItem | undefined => {
        switch (columnId) {
            case SearchListSortField.createdAt:
                return AgendaTableColumn.createdAt;
            case SearchListSortField.updatedAt:
                return AgendaTableColumn.updatedAt;
            case SearchListSortField.title:
                return AgendaTableColumn.title;
            case SearchListSortField.day:
                return AgendaTableColumn.day;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AgendaListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    public render(): React.ReactElement<any> | null {
        return (
            <>
                <Table
                    keyExtractor={(item: AgendaListItem, column?: Column<AgendaListItem>): string => {
                        return `${item.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                        order: this.state.options.control.sortOrder || undefined,
                    }}
                    data={this.state.agendas}
                    count={this.state.count}
                    limit={this.state.options.control.limit}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={true}
                    currentPage={this.getCurrentPage()}
                    isLoading={this.state.isLoading}
                    onRowClick={(item: AgendaListItem): void => {
                        const path: string | undefined = ContentPageUtils.getViewPath(
                            item,
                            this.props.pageContent,
                            this.props.client ? this.props.client.extId : undefined,
                            this.props.supporterExtId,
                            undefined,
                            this.props.supervisorId,
                        );
                        if (path) {
                            this.props.history.push(path);
                        }
                    }}
                    renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.agendaTable.noData" })}
                />
            </>
        );
    }
}

export const AgendaTable = withRouter(connect()(AgendaTableComponent));

import React from "react";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";

import "./Dialog.scss";

interface Props {
    title: string;
    titleIcon?: string;
    children: React.ReactNode;
    visible: boolean;
    onCloseClick?: () => Promise<void> | void;
    modalWindowCustomClassName?: string;
    modalHeaderCustomClassName?: string;
    modalContentCustomClassName?: string;
}

interface State {
    isClosing: boolean;
}

class Dialog extends React.Component<Props, State> {
    public readonly state: State = {
        isClosing: false,
    };

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.visible !== this.props.visible) {
            this.setState({ isClosing: true });
        }
    }

    private getClassName = (): string => {
        const showClassName: string = this.props.visible || this.state.isClosing ? " show" : "";
        return `modal-layer${showClassName}`;
    };

    public render(): React.ReactElement<any> {
        const className: string = this.getClassName();
        return (
            <div className={`${className}${this.state.isClosing ? " modal-layer--is-closing" : ""}`}>
                <div className={`modal-window${this.props.modalWindowCustomClassName ? ` ${this.props.modalWindowCustomClassName}` : ""}`}>
                    <div className={`modal-header${this.props.modalHeaderCustomClassName ? ` ${this.props.modalHeaderCustomClassName}` : ""}`}>
                        <h3>
                            {this.props.titleIcon && <span className={`fa fa-${this.props.titleIcon}`}></span>}
                            {this.props.title}
                        </h3>
                        {this.props.onCloseClick && (
                            <Button className="close" link icon={{ name: "fa-times", large: true }} title={Intl.formatMessage({ id: "common.close" })} onClick={this.props.onCloseClick} />
                        )}
                    </div>
                    <div className={`modal-content${this.props.modalContentCustomClassName ? ` ${this.props.modalContentCustomClassName}` : ""}`}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export { Dialog };

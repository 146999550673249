import React, { Component } from "react";
import { appData, ClientProfileData, ClientQuestionnaireQuestion, SupportedClient } from "api/graphql/types";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { Page } from "components/Page";
import { AppPath, Path } from "utils/Path";
import { Loading, LoadingType } from "components/Loading/Loading";
import { ApplicationState } from "reducers";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Api } from "api/Api";
import { ApiTypes, ClientQuestionnaireAnswer } from "api/ApiTypes";
import { ClientQuestionnaireForm } from "./ClientQuestionnaireForm";
import { RadioOption } from "components/Inputs/RadioInput/RadioInput";
import { AccountActions } from "actions/AccountActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";

interface ReduxProps {
    accountId: string;
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams> & NotificationProp;

interface State {
    clientProfile: ClientProfileData | null;
    clientQuestionnaireAnswers: ClientQuestionnaireAnswer[];
    questions: ClientQuestionnaireQuestion[];
    isLoading: boolean;
}

class ClientQuestionnairePageComponent extends Component<Props, State> {
    public readonly state: State = {
        clientProfile: null,
        clientQuestionnaireAnswers: [],
        questions: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshClientProfileAndQuestions(this.props.client.id);
        } else {
            this.setState({ isLoading: false });
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.client && this.props.client && this.props.client.id !== nextProps.client.id) {
            this.refreshClientProfileAndQuestions(nextProps.client.id);
        }
    }

    private refreshClientProfileAndQuestions = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientProfile: ClientProfileData = await Api.getMyClientById(clientId);
                    const questions: ClientQuestionnaireQuestion[] = await Api.getClientQuestionnaire();
                    const answers: ClientQuestionnaireAnswer[] = questions.map(
                        (question: ClientQuestionnaireQuestion): ClientQuestionnaireAnswer => ({ questionId: question.id, answerOptionId: null }),
                    );
                    this.setState({ questions, clientQuestionnaireAnswers: answers, clientProfile, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(AppPath.dashboard);
                        },
                    });
                }
            },
        );
    };

    private readonly onAnswerChange = (index: number) => (selectedOption: RadioOption) => {
        const clientQuestionnaireAnswers = [...this.state.clientQuestionnaireAnswers];
        clientQuestionnaireAnswers[index] = { ...clientQuestionnaireAnswers[index], answerOptionId: selectedOption.id };
        this.setState({ clientQuestionnaireAnswers });
    };

    private readonly onBack = () => {
        const { clientProfile } = this.state;
        if (clientProfile) {
            this.props.history.push(Path.clientProfile(clientProfile.extId));
        }
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { clientQuestionnaireAnswers, clientProfile } = this.state;
        if (clientProfile) {
            this.setState(
                { isLoading: true },
                async (): Promise<void> => {
                    try {
                        if (!ApiTypes.isClientQuestionnaireAnswerInputs(clientQuestionnaireAnswers)) {
                            Alert.error({ title: Intl.formatMessage({ id: "page.clientQuestionnaire.questionsNotAnsweredError" }) });
                            this.setState({ isLoading: false });
                            return;
                        }
                        try {
                            await Api.evaluateClientQuestionnaire({ clientId: clientProfile.id, clientQuestionnaireAnswers: clientQuestionnaireAnswers });
                            const appData: appData = await Api.getAppData();
                            this.props.dispatch(AccountActions.updateAccount(appData.me));
                            this.props.dispatch(
                                DialogsActions.show({
                                    type: DialogType.clientQuestionnaireInfo,
                                    title: Intl.formatMessage({ id: "page.clientQuestionnaire.dialog.title" }),
                                    buttonLabel: Intl.formatMessage({ id: "page.clientQuestionnaire.dialog.button" }),
                                    description: this.props.client!.notificationAfterCreate.replace("[CLIENT_NAME]", this.props.client!.name),
                                    onClose: () => {
                                        this.props.history.push(Path.clientProfile(clientProfile.extId));
                                    },
                                }),
                            );
                        } catch (error) {
                            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                        }
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                        this.setState({ isLoading: false });
                    }
                },
            );
        }
    };

    public render(): React.ReactElement<any> {
        if (!this.props.match.params.clientExtId || !this.props.client) {
            return <Redirect to={AppPath.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const { clientProfile } = this.state;
        if (!clientProfile) {
            return <Redirect to={AppPath.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.clientQuestionnaire.title" }, { name: clientProfile.name })}>
                <ClientQuestionnaireForm
                    clientProfile={this.state.clientProfile}
                    clientQuestionnaireAnswers={this.state.clientQuestionnaireAnswers}
                    questions={this.state.questions}
                    onAnswerChange={this.onAnswerChange}
                    onBack={this.onBack}
                    onSubmit={this.onSubmit}
                />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return {
        accountId: state.account!.id,
        client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId),
    };
};

export const ClientQuestionnairePage = withNotification(withRouter(connect(mapStateToProps)(ClientQuestionnairePageComponent)));

// tslint:disable-next-line: no-default-export
export default ClientQuestionnairePage;

import React, { PureComponent } from "react";
import { Image } from "components/Image";
import { Intl } from "i18n/Intl";
import { ContentInfo, ContentChild, ContentElementUtils } from "./ContentElementUtils";
import { ContentElementInfo } from "./ContentElementInfo";
import { ContentElementButtons } from "./ContentElementButtons";
import { ContentChildElement } from "./ContentChildElement";
import { FlowchartItemContent_flowchart, AssetType } from "api/graphql/types";

import "./ContentElement.scss";
import { ImageSrc } from "utils/ImageSrc";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

export interface ReorderDescriptor {
    upEnabled: boolean;
    downEnabled: boolean;
    call: (up: boolean) => void;
}

interface Props {
    title: string;
    meta?: string;
    images: string[];
    fallbackImage: string;
    info?: ContentInfo;
    infoInNewRow?: boolean;
    contentChildren?: ContentChild[];
    isReorderDisabled?: boolean;
    reorder: ReorderDescriptor;
    disabled?: boolean;
    isChildContent?: boolean;
    ariaLevel?: number;
    flowchart?: FlowchartItemContent_flowchart | null;
    onViewClick?: () => void;
    onEditClick?: () => void;
    onCloseClick?: () => void;
    onPreviewClick?: () => void;
    overlayIcon?: React.ReactElement;
}

interface State {
    isExpanded: boolean;
}

/**
 * This component represents an item inside Draggable Input
 */
class ContentElement extends PureComponent<Props, State> {
    public readonly state: State = { isExpanded: false };

    private renderImage = (imageUrl: string | undefined, index: number): React.ReactElement<any> => {
        return (
            <div key={"" + imageUrl + index} className="content-element-image" onClick={imageUrl ? this.props.onPreviewClick : undefined}>
                <div className="image-container">
                    <Image src={imageUrl} fallback={this.props.fallbackImage} />
                    {imageUrl && this.props.overlayIcon && (
                        <>
                            <div className="content-overlay" />
                            <div className="content-overlay-icon">{this.props.overlayIcon}</div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    private renderContentChildren = (contentChild: ContentChild[]): React.ReactElement<any> => {
        return (
            <>
                {((contentChild.length > 1 && this.state.isExpanded) || contentChild.length === 1) &&
                    contentChild.map(
                        (contentChild: ContentChild): React.ReactElement<any> => {
                            return (
                                <div key={contentChild.id}>
                                    <ContentChildElement element={contentChild} fallbackImage={contentChild.fallbackImage} />
                                </div>
                            );
                        },
                    )}
            </>
        );
    };

    private renderEmbeddedFlowchart(flowchart: FlowchartItemContent_flowchart): React.ReactElement<any> {
        const asset = flowchart.image;
        const imageUrl = asset?.thumbnailUrl || asset?.url;
        const contentChildren = ContentElementUtils.getAgendaItemFlowchartChildren(flowchart);
        return (
            <>
                <div className={"content-element-flex"}>
                    <div
                        className="content-element-image"
                        onClick={
                            imageUrl
                                ? () => {
                                      store.dispatch(
                                          DialogsActions.show({
                                              type: DialogType.showAsset,
                                              assetUrl: asset?.url,
                                              assetType: asset?.assetType || AssetType.image,
                                              originalFileName: asset?.title,
                                              thumbnailUrl: asset?.thumbnailUrl,
                                              dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                          }),
                                      );
                                  }
                                : undefined
                        }
                    >
                        <div className="image-container" style={{ width: 50, height: 50, marginTop: 10, alignItems: "center" }}>
                            <Image src={imageUrl} fallback={ImageSrc.flowchart} />
                            {imageUrl && this.props.overlayIcon && (
                                <>
                                    <div className="content-overlay" />
                                    <div className="content-overlay-icon">
                                        <OverlayIcon assetType={AssetType.image} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="content-element-content">
                        <div className="content-element-title">
                            <h3 style={{ fontWeight: "bold", fontSize: 18, color: "#0D325F", marginTop: 20 }}>
                                {flowchart.title}
                                {contentChildren && contentChildren.length > 1 && (
                                    <i onClick={() => this.setState({ isExpanded: !this.state.isExpanded })} className={`fa fa-chevron-${this.state.isExpanded ? "up" : "down"}`} />
                                )}
                            </h3>
                        </div>
                    </div>
                </div>

                {contentChildren && <div className="content-element-child">{this.renderContentChildren(contentChildren)}</div>}
            </>
        );
    }

    public render(): React.ReactElement<any> {
        const { title, images, info, infoInNewRow, contentChildren, ariaLevel, onViewClick, onEditClick, onCloseClick, disabled, isChildContent, isReorderDisabled } = this.props;

        return (
            <div className="draggable-content-element">
                <div className="content-element">
                    <div className={`content-element-flex${infoInNewRow ? " row no-gutters align-items-end" : ""}`}>
                        {images && images.length > 0 ? images.map(this.renderImage) : this.renderImage(undefined, 0)}
                        <div className="content-element-content">
                            <div className="content-element-title">
                                <h3 role={ariaLevel ? "heading" : undefined} aria-level={ariaLevel}>
                                    {title}
                                    {contentChildren && contentChildren.length > 1 && (
                                        <i onClick={() => this.setState({ isExpanded: !this.state.isExpanded })} className={`fa fa-chevron-${this.state.isExpanded ? "up" : "down"}`} />
                                    )}
                                </h3>

                                <ContentElementButtons onViewClick={onViewClick} onEditClick={onEditClick} onCloseClick={onCloseClick} isDeleteActive={!disabled && !isChildContent} />
                            </div>

                            {!disabled && !isChildContent && !isReorderDisabled && (
                                <div className="drag-space">
                                    {this.props.reorder.upEnabled && <div className="up" title={Intl.formatMessage({ id: "common.up" })} onClick={() => this.props.reorder.call(true)} />}
                                    {this.props.reorder.downEnabled && <div className="down" title={Intl.formatMessage({ id: "common.down" })} onClick={() => this.props.reorder.call(false)} />}
                                </div>
                            )}

                            {info && !infoInNewRow && <ContentElementInfo info={info} />}
                        </div>
                    </div>

                    {info && infoInNewRow && <ContentElementInfo info={info} />}

                    {this.props.contentChildren && <div className="content-element-child">{this.renderContentChildren(this.props.contentChildren)}</div>}

                    {this.props.flowchart && this.renderEmbeddedFlowchart(this.props.flowchart)}
                </div>
            </div>
        );
    }
}

export { ContentElement };

import React, { Component } from "react";
import { ItemAwardInput, ItemAwardType, Client_instantAwards_result_image, AssetType, InstantAward } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator, ValidatorConstants } from "utils/Validator";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { AwardSelectOptions } from "models/AwardSelectOptions";
import { isNil, cloneDeep } from "lodash";
import { Image } from "components/Image";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp } from "react-redux";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Api } from "api/Api";
import { Loading } from "components/Loading/Loading";
import { NumberInput } from "components/Inputs/NumberInput/NumberInput";

export interface AwardValidationError {
    score: string | null;
}

interface ComponentProps {
    testIds?: {
        enabled?: string;
        score?: string;
        requestConfirmation?: string;
    };
    awardErrors?: AwardValidationError | null;
    instantAwardErrors?: string | null;
    value: ItemAwardInput | null;
    activeInstantAwards: InstantAward[] | null;
    onAwardChange: (award: ItemAwardInput | null, errors: AwardValidationError | null) => void;
    onInstantAwardsChange: (instantAwards: InstantAward[]) => void;
    disabled?: boolean;
    clientId?: string | null;
    isClientPresent?: boolean;
    isValidationEnabled: boolean;
}

type Props = ComponentProps & DispatchProp;

interface State {
    clientInstantAwards: InstantAward[];
    isLoading: boolean;
}

class AwardInput extends Component<Props, State> {
    private inputRef: HTMLInputElement | null = null;
    private selectAllRef: HTMLInputElement | null = null;

    public readonly state: State = {
        isLoading: true,
        clientInstantAwards: [],
    };

    public componentDidMount() {
        this.refreshClientInstantAwards();
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.clientId !== this.props.clientId) {
            this.refreshClientInstantAwards();
        }
    }

    private refreshClientInstantAwards = () => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    let clientInstantAwards: InstantAward[] = [];
                    if (this.props.clientId) {
                        clientInstantAwards = await Api.getClientInstantAwards(this.props.clientId);
                    }
                    this.setState({ clientInstantAwards, isLoading: false }, this.handleSelectAllIcon);
                } catch (error) {
                    this.setState({ clientInstantAwards: [], isLoading: false }, this.handleSelectAllIcon);
                }
            },
        );
    };

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.award.${id}` });
    };

    private static getInitialAward = (): ItemAwardInput => {
        return { score: 1, type: ItemAwardType.score, useTimer: false };
    };

    private onEnabledChange = (): void => {
        const award: ItemAwardInput | null = this.props.value ? null : AwardInput.getInitialAward();
        this.props.onAwardChange(award, { score: null });
    };

    private onScoreChange = (score: number | null): void => {
        const scoreError: string | null = IntlHelpers.getValidationError(Validator.validateAwardScore(score));
        this.props.onAwardChange({ ...this.props.value!, score }, { score: scoreError });
    };

    public focus(): void {
        if (this.inputRef) {
            this.inputRef.focus();
        } else if (this.selectAllRef) {
            this.selectAllRef.focus();
        }
    }

    // Convert from GraphQL request type to our local form type
    private getTypeOption = (): SelectOption<ItemAwardType> => {
        if (this.props.value && this.props.value.type) {
            return Select.getSelectOption(AwardSelectOptions.type, this.props.value.type);
        }
        return AwardSelectOptions.type[0];
    };

    private onTypeOptionsChange = (item: SelectOption<ItemAwardType>): void => {
        this.props.onAwardChange({ ...this.props.value!, type: item.value }, null);
    };

    public onInstantAwardChange = (instantAward: InstantAward) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInstantAwards: InstantAward[] = [...this.props.activeInstantAwards!];
        if (event.currentTarget.checked) {
            newInstantAwards.push(instantAward);
        } else {
            let foundIndex: number | null = null;
            newInstantAwards.forEach((award: InstantAward, index: number) => {
                if (award.id === instantAward.id) {
                    foundIndex = index;
                }
            });

            if (foundIndex !== null) {
                newInstantAwards.splice(foundIndex, 1);
            }
        }
        this.props.onInstantAwardsChange(newInstantAwards);
    };

    private onImageClick = (image: Client_instantAwards_result_image | null): void => {
        if (isNil(image?.url)) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.showAsset,
                assetUrl: image!.url,
                assetType: AssetType.image,
                originalFileName: image!.originalFileName,
                thumbnailUrl: image!.url,
                dialogTitle: Intl.formatMessage({ id: "page.clientAwardsBase.showImageDialogTitle" }),
            }),
        );
    };

    private handleSelectAllIcon = () => {
        const { activeInstantAwards } = this.props;
        if (this.selectAllRef) {
            if (this.state.clientInstantAwards.length > activeInstantAwards!.length && activeInstantAwards!.length > 0) {
                this.selectAllRef.indeterminate = true;
            } else {
                this.selectAllRef.indeterminate = false;
            }
        }
    };

    private toggleAllInstantAwards = (): void => {
        if (!this.areAllInstantAwardsSelected()) {
            this.props.onInstantAwardsChange(cloneDeep(this.state.clientInstantAwards));
        } else {
            this.props.onInstantAwardsChange([]);
        }
    };

    private areAllInstantAwardsSelected = (): boolean => {
        const { activeInstantAwards } = this.props;
        const { clientInstantAwards } = this.state;
        return !isNil(clientInstantAwards) && !isNil(activeInstantAwards) && clientInstantAwards.length === activeInstantAwards.length;
    };

    private getInstantAwardsError = (): string | undefined => {
        if (!this.props.isValidationEnabled) {
            return undefined;
        }
        return this.props.instantAwardErrors || undefined;
    };

    public renderInstantAwardLabel = (instantAward: InstantAward) => {
        return (
            <div className="awardsbase-title-and-image">
                <div className={`awardsbase-image-container ${!isNil(instantAward.image?.url) ? " show-cursor" : ""}`} onClick={() => this.onImageClick(instantAward.image)}>
                    <Image className="awardsbase-image" src={instantAward.image?.url} />
                    {instantAward.image?.url && (
                        <>
                            <div className="content-overlay" />
                            <div className="content-overlay-icon">
                                <span className="fa fa-search-plus" />
                            </div>
                        </>
                    )}
                </div>
                <span className="awardsbase-title">{instantAward.title}</span>
            </div>
        );
    };

    private renderInstantAwards = (): React.ReactElement | null => {
        const { activeInstantAwards } = this.props;
        const { clientInstantAwards } = this.state;

        if (!clientInstantAwards || clientInstantAwards.length === 0) {
            return <ErrorMessage message={Intl.formatMessage({ id: "sharedComponent.inputs.award.noAvailableAward" })} />;
        }

        if (!activeInstantAwards) {
            if (this.props.isValidationEnabled) {
                return <ErrorMessage message={Intl.formatMessage({ id: "sharedComponent.inputs.award.instantAwardError" })} />;
            }
            return null;
        }

        const errorMessage: string | undefined = this.getInstantAwardsError();

        return (
            <div className="container">
                {!this.props.disabled && (
                    <>
                        <div className="row">
                            <div className="col-6">
                                <Checkbox
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.selectAllRef = ref;
                                    }}
                                    className="awardinput-instant-award-select-all-checkbox"
                                    checked={this.areAllInstantAwardsSelected()}
                                    onChange={this.toggleAllInstantAwards}
                                    label={Intl.formatMessage({ id: "common.selectAll" })}
                                />
                            </div>
                        </div>
                        <hr />
                    </>
                )}
                <div className="row">
                    {clientInstantAwards!.map((instantAward: InstantAward) => {
                        return (
                            <div className="col-6 awardinput-instant-award-row" key={instantAward.id}>
                                <Checkbox
                                    checked={activeInstantAwards!.map((award: InstantAward) => award.id).includes(instantAward.id)}
                                    onChange={this.onInstantAwardChange(instantAward)}
                                    disabled={this.props.disabled}
                                    label={this.renderInstantAwardLabel(instantAward)}
                                    hasError={this.props.isValidationEnabled && !isNil(this.props.instantAwardErrors)}
                                />
                            </div>
                        );
                    })}
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    };

    private renderDetailedInputs = (): React.ReactElement => {
        if (this.props.value!.type === ItemAwardType.score) {
            return (
                <InputWrapper
                    id={this.props.testIds?.score}
                    inputLabel={this.localTranslation("score.label")}
                    padded
                    errorMessage={this.props.isValidationEnabled && this.props.awardErrors ? this.props.awardErrors.score : null}
                >
                    <NumberInput
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.inputRef = ref;
                        }}
                        value={this.props.value!.score}
                        onChange={this.onScoreChange}
                        type="number"
                        min={ValidatorConstants.PRIZE_SCORE_MIN}
                        max={ValidatorConstants.PRIZE_SCORE_MAX}
                        disabled={this.props.disabled}
                    />
                </InputWrapper>
            );
        }

        if (this.state.isLoading) {
            return <Loading />;
        }

        return (
            <>
                {this.renderInstantAwards()}
                <hr />
                <InputWrapper id={this.props.testIds?.enabled} inputLabel={this.localTranslation("useTimer.label")} padded>
                    <Checkbox
                        checked={this.props.value!.useTimer === true}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.props.onAwardChange({ ...this.props.value!, useTimer: event.currentTarget.checked }, null);
                        }}
                        disabled={this.props.disabled}
                    />
                </InputWrapper>
            </>
        );
    };

    public render(): React.ReactElement {
        return (
            <>
                <InputWrapper id={this.props.testIds?.enabled} inputLabel={this.localTranslation("enabled.label")} padded icon="fa-star">
                    <Checkbox checked={!!this.props.value} onChange={this.onEnabledChange} disabled={this.props.disabled} />
                </InputWrapper>

                {this.props.value && (
                    <>
                        <InputWrapper inputLabel={this.localTranslation("type.label")} padded>
                            <Select options={AwardSelectOptions.type} onChange={this.onTypeOptionsChange} value={this.getTypeOption()} disabled={this.props.disabled || !this.props.isClientPresent} />
                        </InputWrapper>
                        {this.renderDetailedInputs()}
                    </>
                )}
            </>
        );
    }
}

export { AwardInput };

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { PeriodInput } from "components/Inputs/PeriodInput/PeriodInput";
import { Timer } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Validator, ValidatorConstants } from "utils/Validator";
import { TimeUnit, DateUtils } from "utils/DateUtils";

export interface TimerValidationError {
    seconds?: string | null;
}

interface Props {
    testIds: {
        enabled?: string;
        seconds?: string;
        requestConfirmation?: string;
        skipEnabled?: string;
        extendTimeEnabled?: string;
        pauseEnabled?: string;
        endWarning?: string;
    };
    value: Timer | null;
    onChange: (timer: Timer | null, validationErrors: TimerValidationError | null) => void;
    disabled?: boolean;
    errors?: TimerValidationError | null;
    isValidationEnabled: boolean;
}

/**
 * Component for timer (idojelzes)
 */
class TimerInput extends Component<Props> {
    private secondsRef: PeriodInput | null = null;

    private static getInitialValue(): Timer {
        return { seconds: 60, skipEnabled: false, endWarning: false, __typename: "Timer" };
    }

    private onEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timer: Timer | null = event.currentTarget.checked ? TimerInput.getInitialValue() : null;
        this.props.onChange(timer, this.props.errors || null);
    };

    private onSecondsChange = (seconds: number | null, unit: TimeUnit) => {
        const validationError: string | null = Validator.validateTimerSeconds(seconds);
        let secondsError = null;
        if (validationError) {
            const min: number = Math.ceil(DateUtils.secondsTo(ValidatorConstants.TIMER_SEC_MIN, unit)!);
            const max: number = Math.floor(DateUtils.secondsTo(ValidatorConstants.TIMER_SEC_MAX, unit)!);
            secondsError = Intl.formatMessage({ id: `error.validation.${validationError}.${unit}` }, { min, max });
        }

        this.props.onChange({ ...this.props.value!, seconds: seconds! }, { ...this.props.errors, seconds: secondsError });
    };

    private onSkipEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, skipEnabled: event.currentTarget.checked }, this.props.errors || null);
    };

    private onEndWarningChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, endWarning: event.currentTarget.checked }, this.props.errors || null);
    };

    public focus(): void {
        if (this.secondsRef) {
            this.secondsRef.focus();
        }
    }

    public render(): React.ReactElement<any> {
        const { value, testIds, disabled, isValidationEnabled, errors } = this.props;
        return (
            <>
                <InputWrapper id={testIds.enabled} inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.timer.enabled.label" })} padded icon="fa-hourglass-half">
                    <Checkbox checked={!!value} onChange={this.onEnabledChange} disabled={this.props.disabled} />
                </InputWrapper>

                {value && (
                    <>
                        <InputWrapper
                            id={testIds.seconds}
                            inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.timer.seconds.label" })}
                            padded
                            errorMessage={errors && isValidationEnabled ? errors.seconds : null}
                        >
                            <PeriodInput
                                ref={(ref: PeriodInput | null): void => {
                                    this.secondsRef = ref;
                                }}
                                value={value.seconds}
                                onChange={this.onSecondsChange}
                                disabled={disabled}
                            />
                        </InputWrapper>

                        <InputWrapper id={testIds.skipEnabled} inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.timer.skipEnabled.label" })} padded>
                            <Checkbox checked={value.skipEnabled} onChange={this.onSkipEnabledChange} disabled={disabled} />
                        </InputWrapper>

                        <InputWrapper id={testIds.endWarning} inputLabel={Intl.formatMessage({ id: "sharedComponent.inputs.timer.endWarning.label" })} padded>
                            <Checkbox checked={!!value.endWarning} onChange={this.onEndWarningChange} disabled={disabled} />
                        </InputWrapper>
                    </>
                )}
            </>
        );
    }
}

export { TimerInput };

import { ApplicationState } from "reducers/index";
import { SupportedClient, AccountType, ClientShareRequest } from "api/graphql/types";
import { Cookie } from "utils/Cookie";

export class AccountSelectors {
    public static getClientByExtId(state: ApplicationState, clientExtId?: string): SupportedClient | null {
        if (!clientExtId) {
            return null;
        }

        return state.account && state.account.myClients
            ? state.account.myClients.result.find((client: SupportedClient): boolean => {
                  return client.extId === clientExtId;
              }) || null
            : null;
    }

    public static getClients(state: ApplicationState): SupportedClient[] {
        return state.account && state.account.myClients ? state.account.myClients.result : [];
    }

    public static getAccountType(state: ApplicationState): AccountType | null {
        return state.account ? state.account.accountType : null;
    }

    public static getClientShareRequestById(state: ApplicationState, requestId?: string): ClientShareRequest | null {
        if (!requestId || !state.account || !state.account.pendingClientShareRequests) {
            return null;
        }

        return state.account.pendingClientShareRequests.result.find((clientShareRequest: ClientShareRequest): boolean => clientShareRequest.id === requestId) || null;
    }

    public static getClientByClientManagerShareRequestId(state: ApplicationState, changeClientManagerRequestId?: string): SupportedClient | null {
        if (!changeClientManagerRequestId || !state.account || !state.account.myClients) {
            return null;
        }

        return (
            state.account.myClients.result.find((client: SupportedClient): boolean => {
                return !!client.pendingClientManagerChangeRequest && client.pendingClientManagerChangeRequest.id === changeClientManagerRequestId;
            }) || null
        );
    }

    public static getClientsWithTodo(state: ApplicationState): SupportedClient[] {
        const clients = state.account?.myClients?.result || [];
        const acceptedNotifications = Cookie.getAcceptedClientTodos();
        return clients.filter(client => {
            return client.showSelfSupportingStateDecisionNotification && !acceptedNotifications.includes(client.extId);
        });
    }
}

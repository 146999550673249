import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Page } from "components/Page";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Account, AccountType } from "api/graphql/types";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { TabPanel } from "components/TabPanel/TabPanel";
import { AdminDetailProfileTab } from "./AdminDetailProfileTab";
import AccountEventLogTable from "pages/_shared/AccountEventLogTable/AccountEventLogTable";

interface ReduxProps {
    accountType: AccountType;
}

interface Params {
    accountId: string;
}

type Props = ReduxProps & RouteComponentProps<Params> & DispatchProp;

interface State {
    isLoading: boolean;
    account: Account | null;
}

class AdminDetailPageComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        account: null,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const account: Account = await Api.getAccountById(props.match.params.accountId);
                    this.setState({ account, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.adminList);
                        },
                    });
                }
            },
        );
    }

    public render(): React.ReactElement | null {
        if (this.state.isLoading && !this.state.account) {
            return <Loading type={LoadingType.layer} />;
        }

        const { account } = this.state;
        if (!account) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.adminDetail.title" }, { name: account.name })} hasTabs={true}>
                <div className="left-side">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.adminDetail.tabs.profile.title" }),
                                label: Intl.formatMessage({ id: "page.adminDetail.tabs.profile.title" }),
                                to: Path.adminDetailProfile(account.id),
                                component: AdminDetailProfileTab,
                                additionalProps: { accountType: this.props.accountType },
                            },
                            {
                                id: Intl.formatMessage({ id: "page.adminDetail.tabs.eventLog.title" }),
                                label: Intl.formatMessage({ id: "page.adminDetail.tabs.eventLog.title" }),
                                to: Path.adminDetailEventLog(account.id),
                                component: AccountEventLogTable,
                                additionalProps: { viewedAccountType: account.accountType, authAccountId: account.id },
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountType: state.account!.accountType };
};

export const AdminDetailPage = withRouter(connect(mapStateToProps)(AdminDetailPageComponent));

export default AdminDetailPage;

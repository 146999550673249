import * as GqlTypes from "./graphql/types";
import { ImageSrc } from "utils/ImageSrc";
import { Log } from "utils/Log";
import { isNil, sortBy } from "lodash";

export interface ListResult<T> {
    count: number;
    result: T[];
}

export type AppEventLogResult = ListResult<GqlTypes.AppEventLog> & { aggregations: GqlTypes.Aggregations | null };
export type AppStatItemResult = ListResult<GqlTypes.AppStatItem> & { aggregations: GqlTypes.Aggregations | null };

export type AnyContentType =
    | GqlTypes.AssetContent
    | GqlTypes.AgendaContent
    | GqlTypes.AgendaItemContent
    | GqlTypes.FlowchartContent
    | GqlTypes.FlowchartItemContent
    | GqlTypes.EverydaySituationDirectoryList
    | GqlTypes.EverydaySituation
    | GqlTypes.InstantAwardListItem
    | GqlTypes.AwardListItem
    | GqlTypes.SelectionBoardListItem;
export type AnyContentListType = GqlTypes.AssetListItem | GqlTypes.AgendaListItem | GqlTypes.AgendaItemListItem | GqlTypes.FlowchartListItem | GqlTypes.FlowchartItemListItem;

export interface ContentLibrary {
    myLibrary: { result: AnyContentType[]; count: number };
    clientLibrary: { result: AnyContentType[]; count: number };
}

export interface ClientAdditionalFunctions {
    isMoodMeterActive: boolean;
}

export interface ClientQuestionnaireAnswer {
    questionId: string;
    answerOptionId: string | null;
}

export enum ExtraContentLibraryTypes {
    everydaySituations = "everydaySituations",
    instantAward = "instantAward",
    award = "award",
    selectionBoard = "selectionBoard",
}

export enum AccountStatus {
    active = "active",
    inactive = "inactive",
    disabled = "disabled",
}

export enum ClientSupporterStatus {
    pending = "pending",
    active = "active",
}

export enum ClientProfile {
    A = "A",
    B = "B",
    C = "C",
    N = "N",
    K = "K",
}

export class ApiTypes {
    private static isClientQuestionnaireAnswer(answer: ClientQuestionnaireAnswer): answer is GqlTypes.ClientQuestionnaireAnswerInput {
        return answer.answerOptionId !== null;
    }

    public static isClientQuestionnaireAnswerInputs(answers: ClientQuestionnaireAnswer[]): answers is GqlTypes.ClientQuestionnaireAnswerInput[] {
        return answers.every(ApiTypes.isClientQuestionnaireAnswer);
    }

    /**
     * Return a status from activatedAt and disabledAt
     * Used for UserLists
     * @param account Account
     */
    public static getAccountStatus(account: { activatedAt: any | null; disabledAt: any | null }): AccountStatus {
        if (account.disabledAt) {
            return AccountStatus.disabled;
        }
        if (account.activatedAt) {
            return AccountStatus.active;
        } else {
            return AccountStatus.inactive;
        }
    }

    public static selectionBoardToSelectionBoardInput(selectionBoard: GqlTypes.SelectionBoard): GqlTypes.SelectionBoardInput {
        return {
            title: selectionBoard.title,
            backgroundColor: selectionBoard.backgroundColor,
            items: selectionBoard.items.map(
                (item: GqlTypes.SelectionBoardItem): GqlTypes.SelectionBoardItemInput => ({
                    id: item.id ? item.id : undefined,
                    title: item.title || null,
                    imageId: item.image ? item.image.id : null,
                }),
            ),
        };
    }

    public static checklistToChecklistInput(checklist: GqlTypes.Checklist | null): GqlTypes.ChecklistInput | null {
        if (!checklist) {
            return null;
        }

        return {
            title: "-",
            items: checklist.items.map(
                (item: GqlTypes.ChecklistItem): GqlTypes.ChecklistItemInput => {
                    return { id: item.id ? item.id : undefined, title: item.title, position: item.position };
                },
            ),
        };
    }

    public static awardToAwardInput(award: GqlTypes.ItemAward | null, instantAwards: GqlTypes.InstantAward[] | null): GqlTypes.ItemAwardInput | null {
        if (!award) {
            return null;
        }

        return {
            score: award.score,
            type: award.type,
            useTimer: award.useTimer,
            instantAwardIds: !isNil(instantAwards) && instantAwards.length > 0 ? instantAwards.map((award: GqlTypes.InstantAward) => award.id) : null,
        };
    }

    public static timerToTimerInput(timer: GqlTypes.Timer | null): GqlTypes.TimerInput | null {
        if (!timer) {
            return null;
        }

        return {
            endWarning: !!timer.endWarning,
            seconds: timer.seconds,
            skipEnabled: !!timer.skipEnabled,
        };
    }

    public static alertToAlertInput(alert: GqlTypes.AgendaItemAlert | null): GqlTypes.AgendaItemAlertInput | null {
        if (!alert) {
            return null;
        }

        return {
            title: alert.title,
            vibrate: alert.vibrate,
            flash: alert.flash,
            display: alert.display,
            requestInteraction: alert.requestInteraction,
            audio: alert.audioChecked,
            audioAssetId: alert.audio ? alert.audio.id : null,
        };
    }

    public static flowchartItemToFlowchartItemInput(flowchartItem: GqlTypes.FlowchartItem): GqlTypes.FlowchartItemInput {
        return {
            type: flowchartItem.flowchartType,
            title: flowchartItem.title,
            clientId: flowchartItem.client ? flowchartItem.client.id : null,
            imageAssetId: flowchartItem.image ? flowchartItem.image.id : null,
            audioAssetId: flowchartItem.audio ? flowchartItem.audio.id : null,
            videoAssetId: flowchartItem.video ? flowchartItem.video.id : null,
            moodMeter: ApiTypes.moodMeterToMoodMeterInput(flowchartItem.moodMeter),
            notification: ApiTypes.notificationToNotificationInput(flowchartItem.notification),
            timer: ApiTypes.timerToTimerInput(flowchartItem.timer),
            checklist: ApiTypes.checklistToChecklistInput(flowchartItem.checklist),
            award: ApiTypes.awardToAwardInput(flowchartItem.award, flowchartItem.instantAwards),
            flowchartId: flowchartItem.flowchart ? flowchartItem.flowchart.id : null,
            tags: flowchartItem.tags,
            flowchartItems:
                flowchartItem.flowchartType === GqlTypes.FlowchartItemType.Simple
                    ? null
                    : (flowchartItem.flowchartItems || []).map(ApiTypes.flowchartItemFlowchartRelToFlowchartItemFlowchartItemRelInput),
        };
    }

    public static flowchartItemFlowchartRelToFlowchartItemFlowchartItemRelInput(item: GqlTypes.FlowchartItemFlowchartItemRel): GqlTypes.FlowchartItemsInput {
        return {
            branch: item.branch,
            flowchartItemId: item.flowchartItem.id,
            position: item.position,
        };
    }

    public static flowchartToFlowchartInput(flowchart: GqlTypes.Flowchart): GqlTypes.FlowchartInput {
        const branchLength = Math.max(flowchart.itemList?.filter(x => x.branch === 1).length || 0, flowchart.itemList?.filter(x => x.branch === 2).length || 0);
        const positions: any = {
            0: 1,
            1: 1,
            2: 1,
        };
        return {
            title: flowchart.title,
            description: flowchart.description,
            imageAssetId: flowchart.image ? flowchart.image.id : null,
            clientId: flowchart.client ? flowchart.client.id : null,
            usableInSortingGame: flowchart.usableInSortingGame,
            tags: flowchart.tags,
            flowchartItems:
                flowchart.itemList &&
                sortBy(flowchart.itemList, ["branch", "position"]).map(
                    (item: GqlTypes.FlowchartItemFlowchartRel): GqlTypes.FlowchartItemsInput => {
                        const position = positions[item.branch]++;
                        if (item.flowchartItem.flowchartType === GqlTypes.FlowchartItemType.Select) {
                            positions[1] = position;
                            positions[2] = position;
                            positions[0] = position + branchLength;
                        }
                        return { id: item.id, flowchartItemId: item.flowchartItem.id, position, branch: item.branch };
                    },
                ),
        };
    }

    public static agendaToAgendaInput(agenda: GqlTypes.Agenda): GqlTypes.AgendaInput {
        return {
            title: agenda.title,
            description: agenda.description,
            clientId: agenda.client ? agenda.client.id : null,
            tags: agenda.tags,
            agendaItems:
                agenda.itemList &&
                agenda.itemList.map(
                    (item: GqlTypes.AgendaItemAgendaRel, index: number): GqlTypes.AgendaItemsInput => {
                        return { id: item.id || undefined, agendaItemId: item.agendaItem.id, position: index + 1 };
                    },
                ),
        };
    }

    public static moodMeterToMoodMeterInput(moodMeter: GqlTypes.MoodMeterDisplayConfig | null): GqlTypes.MoodMeterDisplayConfigInput | null {
        if (!moodMeter) {
            return null;
        }

        return {
            display: moodMeter.display,
            title: moodMeter.title,
        };
    }

    public static notificationToNotificationInput(notification: GqlTypes.Notification | null): GqlTypes.NotificationInput | null {
        if (!notification) {
            return null;
        }

        return {
            audioAssetId: notification.audio?.id || null,
            imageAssetId: notification.image?.id || null,
            text: notification.text,
        };
    }

    public static agendaItemsToAgendaItemsInput(agendaItems: GqlTypes.AgendaItem_agendaItems[] | null): GqlTypes.AgendaItemsInput[] | null {
        if (agendaItems) {
            return agendaItems.map(
                (agendaItemRel: GqlTypes.AgendaItemAgendaItemRel): GqlTypes.AgendaItemsInput => {
                    return { position: agendaItemRel.position, agendaItemId: agendaItemRel.agendaItem.id };
                },
            );
        }
        return null;
    }

    public static agendaItemToAgendaItemInput(agendaItem: GqlTypes.AgendaItem): GqlTypes.AgendaItemInput {
        if (agendaItem.type === GqlTypes.AgendaItemType.Simple) {
            return {
                flowchartId: agendaItem.flowchart ? agendaItem.flowchart.id : null,
                title: agendaItem.title,
                type: agendaItem.type,
                content: agendaItem.content,
                imageAssetId: agendaItem.image ? agendaItem.image.id : null,
                audioAssetId: agendaItem.audio ? agendaItem.audio.id : null,
                videoAssetId: agendaItem.video ? agendaItem.video.id : null,
                isCancelled: !!agendaItem.isCancelled,
                time: agendaItem.time,
                clientId: agendaItem.client ? agendaItem.client.id : null,
                alert: ApiTypes.alertToAlertInput(agendaItem.alert),
                timer: ApiTypes.timerToTimerInput(agendaItem.timer),
                notification: ApiTypes.notificationToNotificationInput(agendaItem.notification),
                award: ApiTypes.awardToAwardInput(agendaItem.award, agendaItem.instantAwards),
                checklist: ApiTypes.checklistToChecklistInput(agendaItem.checklist),
                moodMeter: ApiTypes.moodMeterToMoodMeterInput(agendaItem.moodMeter),
                tags: agendaItem.tags,
                selectionBoardId: agendaItem.selectionBoard?.id || null,
            };
        }
        return {
            title: agendaItem.title,
            type: agendaItem.type,
            content: agendaItem.content,
            isCancelled: !!agendaItem.isCancelled,
            clientId: agendaItem.client ? agendaItem.client.id : null,
            agendaItems: ApiTypes.agendaItemsToAgendaItemsInput(agendaItem.agendaItems),
        };
    }

    public static getAgendaItemImages = (agendaItem: GqlTypes.AgendaItemContent | GqlTypes.AgendaItemContentChild | GqlTypes.AgendaItemContent_agendaItems_agendaItem): string[] => {
        const asset = agendaItem.image || agendaItem.video;
        return asset ? [ApiTypes.getAssetImageUrl(asset)] : [];
    };

    public static getAssetImageUrl(image: { url: string; thumbnailUrl?: string | null; assetType: GqlTypes.AssetType }): string {
        if ([GqlTypes.AssetType.audio, GqlTypes.AssetType.introduction_audio].includes(image.assetType)) {
            return ImageSrc.audioAsset;
        }
        return image.thumbnailUrl || image.url;
    }

    public static reorderAgendaItemList(agenda: GqlTypes.Agenda): GqlTypes.Agenda {
        const agendaItemList = agenda.itemList
            ? agenda.itemList.sort((a: GqlTypes.Agenda_itemList, b: GqlTypes.Agenda_itemList): number => {
                  return a.position - b.position;
              })
            : null;
        agenda.itemList = agendaItemList;
        return agenda;
    }

    public static reorderFlowchartItemList(flowchart: GqlTypes.Flowchart): GqlTypes.Flowchart {
        const flowchartItemList = flowchart.itemList
            ? flowchart.itemList.sort((a: GqlTypes.Flowchart_itemList, b: GqlTypes.Flowchart_itemList): number => {
                  return a.position - b.position;
              })
            : null;
        flowchart.itemList = flowchartItemList;
        return flowchart;
    }

    public static everydaySituationClientDirectoryToUpdateEverydaySituationClientDirectoryInput(
        everydaySituationClientDirectory: GqlTypes.EverydaySituationClientDirectory,
    ): GqlTypes.UpdateEverydaySituationClientDirectoryInput {
        return {
            id: everydaySituationClientDirectory.id,
            imageId: everydaySituationClientDirectory.image?.id,
            isVisible: everydaySituationClientDirectory.isVisible,
            isWritable: everydaySituationClientDirectory.isWritable,
            title: everydaySituationClientDirectory.title,
        };
    }

    public static getEverydaySituationType(everydaySituation: GqlTypes.EverydaySituation): GqlTypes.EverydaySituationType {
        switch (everydaySituation.__typename) {
            case "TextEverydaySituation":
                return GqlTypes.EverydaySituationType.text;
            case "AudioEverydaySituation":
                return GqlTypes.EverydaySituationType.audio;
            case "ImageEverydaySituation":
                return GqlTypes.EverydaySituationType.image;
            case "TextAndImageEverydaySituation":
                return GqlTypes.EverydaySituationType.text_and_image;
            default:
                Log.warning("Unhandled typename for ", everydaySituation, " falling back to text");
                return GqlTypes.EverydaySituationType.text;
        }
    }

    public static getSearchListTypeByContent(content: { __typename: "Agenda" | "AgendaItem" | "Flowchart" | "FlowchartItem" | "Asset" }): GqlTypes.SearchListType | null {
        switch (content.__typename) {
            case "Agenda":
                return GqlTypes.SearchListType.agenda;
            case "AgendaItem":
                return GqlTypes.SearchListType.agendaItem;
            case "Flowchart":
                return GqlTypes.SearchListType.flowchart;
            case "FlowchartItem":
                return GqlTypes.SearchListType.flowchartItem;
            case "Asset":
                return GqlTypes.SearchListType.asset;
            default:
                return null;
        }
    }
}

import React, { Component } from "react";
import { ContentLibraryTable } from "pages/_shared/ContentLibraryTable/ContentLibraryTable";
import { SearchListType, Account } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { Path } from "utils/Path";
import { LinkGroupItem, LinkGroup } from "components/LinkGroup/LinkGroup";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { ContentLibraryLinks } from "models/ContentLibraryLinks";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Intl } from "i18n/Intl";

interface ComponentProps {
    account: Account;
}

type Props = ComponentProps & RouteComponentProps;

class SupervisorDetailContentLibraryTabComponent extends Component<Props> {
    private readonly urlQueryParser = new ContentLibraryUrlQueryParser();

    private renderLinks = (): React.ReactElement<any> => {
        const clientLinkGroupItems: LinkGroupItem[] = ContentLibraryLinks.getForSupervisorDetail(this.props.account.id);
        const currentPath: string = this.props.location.pathname + this.props.location.search;
        return (
            <LinkGroup
                isLinkActive={(item: LinkGroupItem): boolean => currentPath.startsWith(item.toPage)}
                links={clientLinkGroupItems}
                title={Intl.formatMessage({ id: "page.supervisorDetail.tabs.contentLibrary.linkGroupTitle" }, { name: this.props.account.name })}
            />
        );
    };

    public render(): React.ReactElement<any> {
        if (!this.urlQueryParser.parse(this.props.location.search).searchListType) {
            return <Redirect to={`${Path.supervisorDetailContentLibrary(this.props.account.id)}?${this.urlQueryParser.getUrlQuery({ searchListType: SearchListType.agendaItem })}`} />;
        }

        return (
            <ContentLibraryTable
                createdById={this.props.account.id}
                supervisorId={this.props.account.id}
                supervisorName={this.props.account.name}
                pageContent={PageContent.supervisorDetail}
                renderLinks={this.renderLinks}
                storageSizeUsed={IntlHelpers.getFileSize(this.props.account.storageSizeUsed)}
                storageSizeLimit={IntlHelpers.getFileSize(this.props.account.config.storageSizeLimit)}
            />
        );
    }
}

export const SupervisorDetailContentLibraryTab = withRouter(SupervisorDetailContentLibraryTabComponent);

// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function MoveIcon({ width, height }: IconProps): React.ReactElement {
    return (
        <svg width={width} height={height} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6679 4.28555C14.6679 4.06406 14.5724 3.84961 14.4006 3.69141L10.661 0.246094C10.4891 0.0878906 10.2561 0 10.0116 0H9.77863V4.5H14.6679V4.28555ZM21.8109 10.8281L18.1554 7.43906C17.7696 7.08398 17.1088 7.33359 17.1088 7.83633V10.125H14.6641V12.375H17.1088V14.6672C17.1088 15.1699 17.7696 15.4195 18.1554 15.0645L21.8109 11.6719C22.063 11.4398 22.063 11.0602 21.8109 10.8281ZM7.33397 11.8125V10.6875C7.33397 10.3781 7.60899 10.125 7.94513 10.125H14.6679V5.625H9.47304C8.96883 5.625 8.5563 5.24531 8.5563 4.78125V0H0.916746C0.408716 0 0 0.376172 0 0.84375V17.1562C0 17.6238 0.408716 18 0.916746 18H13.7512C14.2592 18 14.6679 17.6238 14.6679 17.1562V12.375H7.94513C7.60899 12.375 7.33397 12.1219 7.33397 11.8125Z"
                fill="#00459B"
            />
        </svg>
    );
}

export { MoveIcon };

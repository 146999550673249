import { Aggregations, Aggregations_timeAggregations_dateAggregation, Aggregations_timeAggregations_hourAggregation, Aggregations_timeAggregations_weekAggregation } from "api/graphql/types";
import { differenceInMinutes } from "date-fns";
import { Intl } from "i18n/Intl";
import { DateFormat, DateUtils } from "./DateUtils";

export interface ChartRow {
    time: string;
    count: number;
}

class ChartUtils {
    // We display hourly/daily/weekly views on the chart's X axis depending on these breakpoints
    private static HOURLY_VIEW_MINUTE_LIMIT = 2880; // 2 days
    private static DAILY_VIEW_MINUTE_LIMIT = 43200; // 30 days

    /**
     * If the number is higher than 2/3 of the max numberArray number, the color is #031A36 (dark-blue)
     * Between 1/3 and 2/3, it's #00459B (blue)
     * Under 1/3 it's #4EC1F9 (light-blue)
     * @param number we need to decide the color of this number
     * @param numberArray array of numbers from which we get the max, then decide which color the param number falls into
     */
    public static readonly getColorByNumbers = (number: number, numberArray: number[]): string => {
        const max: number = Math.max(...numberArray);

        if (number > (max / 3) * 2) {
            return "#031A36";
        } else if (number < (max / 3) * 2 && number > max / 3) {
            return "#00459B";
        } else {
            return "#4EC1F9";
        }
    };

    public static readonly getChartTitle = (from: Date, to: Date) => {
        const differenceInMinutesStored: number = Math.abs(differenceInMinutes(from, to));
        if (differenceInMinutesStored <= ChartUtils.HOURLY_VIEW_MINUTE_LIMIT) {
            return Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.chartTitle.hourly" });
        } else if (differenceInMinutesStored > ChartUtils.HOURLY_VIEW_MINUTE_LIMIT && differenceInMinutesStored <= ChartUtils.DAILY_VIEW_MINUTE_LIMIT) {
            return Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.chartTitle.daily" });
        } else {
            return Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.chartTitle.weekly" });
        }
    };

    public static readonly getXAxisAngle: number = -45;

    public static readonly getChartData = (from: Date, to: Date, aggregations: Aggregations): ChartRow[] => {
        const format = (date: Date): string => {
            return DateUtils.format(date, DateFormat.default);
        };

        const differenceInMinutesStored: number = Math.abs(differenceInMinutes(from, to));
        let chartRows: ChartRow[] = [];

        if (differenceInMinutesStored <= ChartUtils.HOURLY_VIEW_MINUTE_LIMIT) {
            /**
             * Hourly view
             */
            chartRows =
                aggregations.timeAggregations?.hourAggregation?.map((hourAggregation: Aggregations_timeAggregations_hourAggregation) => {
                    return {
                        time: `${hourAggregation.hour}:00`,
                        count: hourAggregation.count,
                    };
                }) ?? [];
        } else if (differenceInMinutesStored > ChartUtils.HOURLY_VIEW_MINUTE_LIMIT && differenceInMinutesStored <= ChartUtils.DAILY_VIEW_MINUTE_LIMIT) {
            /**
             * Daily view
             */
            chartRows =
                aggregations.timeAggregations?.dateAggregation?.map((dateAggregation: Aggregations_timeAggregations_dateAggregation) => {
                    return {
                        time: format(new Date(dateAggregation.date)),
                        count: dateAggregation.count,
                    };
                }) ?? [];
        } else {
            /**
             * Weekly view
             */
            chartRows =
                aggregations.timeAggregations?.weekAggregation?.map((weekAggregation: Aggregations_timeAggregations_weekAggregation) => {
                    return {
                        time: Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.week" }, { week: weekAggregation.week }),
                        count: weekAggregation.count,
                    };
                }) ?? [];
        }

        return chartRows;
    };
}

export { ChartUtils };

import React, { Component } from "react";
import { AccountType, SupportedClient, SelectionBoardListItem, SelectionBoardListSortField, ListControl, SortOrder, AssetType } from "api/graphql/types";
import { PageContent, ContentPageUtils } from "../ContentPageUtils";
import { RouteComponentProps } from "react-router";
import { DispatchProp } from "react-redux";
import { ListQueryParameter, ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { Column, Table } from "components/Table/Table";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import isEqual from "lodash/isEqual";
import { ListResult } from "api/ApiTypes";
import { isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";

interface SelectionBoardTableListOptions {
    sortField?: SelectionBoardListSortField | null;
    control: ListControl;
}

interface State {
    selectionBoards: SelectionBoardListItem[];
    count: number;
    options: SelectionBoardTableListOptions;
    isLoading: boolean;
}

enum SelectionBoardItemTableColumn {
    title = "title",
    isDisabled = "isDisabled",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    actions = "actions",
}

interface ComponentProps {
    currentUrlParams: string;
    directory: string | undefined;
    client: SupportedClient;
    pageContent: PageContent;
    accountType: AccountType;
    getSelectionBoardList: (options?: SelectionBoardTableListOptions) => Promise<ListResult<SelectionBoardListItem>>;
    search: string;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class SelectionBoardTable extends Component<Props, State> {
    private static getInitialOptions(props: Props): SelectionBoardTableListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<SelectionBoardListSortField>(SelectionBoardListSortField, "sharedComponent.contentLibraryTable.selectionBoardTable").parse(
            props.location.search,
        );
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                search: props.search,
                sortOrder,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public constructor(props: Props) {
        super(props);
        this.state = {
            selectionBoards: [],
            count: 0,
            options: SelectionBoardTable.getInitialOptions(props),
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        this.refreshSelectionBoardList(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: SelectionBoardTableListOptions = SelectionBoardTable.getInitialOptions(nextProps);
        if (!isEqual(this.state.options, nextOptions) || nextProps.pageContent !== this.props.pageContent) {
            this.setState({ options: nextOptions }, () => {
                this.updateQueryParams();
            });
        }
    }

    private readonly refreshSelectionBoardList = (options?: SelectionBoardTableListOptions) => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response: ListResult<SelectionBoardListItem> = await this.props.getSelectionBoardList(options);
                    this.setState({
                        selectionBoards: response.result,
                        count: response.count,
                        isLoading: false,
                    });
                } catch (errors) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(errors) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly onDeleteClick = (selectionBoard: SelectionBoardListItem) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteSelectionBoard,
                selectionBoard,
                isFromAdminTable: true,
                onDeleted: () => this.refreshSelectionBoardList(this.state.options),
            }),
        );
    };

    private readonly getColumns = (): Array<Column<SelectionBoardListItem>> => {
        const columnNames: SelectionBoardItemTableColumn[] = ObjectUtils.enumAsArray<SelectionBoardItemTableColumn>(SelectionBoardItemTableColumn);

        return columnNames.map(
            (columnName: SelectionBoardItemTableColumn): Column<SelectionBoardListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.selectionBoardTable.columns.${columnName}` }),
                accessor: columnName as keyof SelectionBoardListItem,
                renderCell: (selectionBoard: SelectionBoardListItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case SelectionBoardItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(selectionBoard.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case SelectionBoardItemTableColumn.updatedAt:
                            return (
                                <>
                                    {selectionBoard.updatedAt
                                        ? DateUtils.format(new Date(selectionBoard.updatedAt), DateFormat.yyyymmddhhmm)
                                        : DateUtils.format(new Date(selectionBoard.createdAt), DateFormat.yyyymmddhhmm)}
                                </>
                            );
                        case SelectionBoardItemTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    content={selectionBoard}
                                    clientExtId={this.props.client.extId}
                                    onToggled={() => this.onToggled(selectionBoard)}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: selectionBoard,
                                                clientId: this.props.client.extId,
                                                onDisabled: (): void => this.refreshSelectionBoardList(this.state.options),
                                            }),
                                        );
                                    }}
                                    onDeleteClick={() => this.onDeleteClick(selectionBoard)}
                                />
                            );
                        case SelectionBoardItemTableColumn.title:
                            return (
                                <div className="table-image-name">
                                    <div
                                        className="table-image-container"
                                        onClick={
                                            selectionBoard.image
                                                ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                      event.stopPropagation();
                                                      store.dispatch(
                                                          DialogsActions.show({
                                                              type: DialogType.showAsset,
                                                              assetUrl: selectionBoard.image?.url,
                                                              assetType: selectionBoard.image?.assetType || AssetType.image,
                                                              originalFileName: selectionBoard.image?.originalFileName,
                                                              thumbnailUrl: selectionBoard.image?.thumbnailUrl,
                                                              dialogTitle: selectionBoard.image?.title || Intl.formatMessage({ id: `enum.assetType.${AssetType.image}` }),
                                                          }),
                                                      );
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Image src={selectionBoard.image?.thumbnailUrl || selectionBoard.image?.url} fallback={ImageSrc.asset} />
                                        {selectionBoard.image && (
                                            <>
                                                <div className="content-overlay" />
                                                <div className="content-overlay-icon">
                                                    <OverlayIcon assetType={selectionBoard.image.assetType} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {selectionBoard.title}
                                </div>
                            );
                        case SelectionBoardItemTableColumn.isDisabled:
                            return (
                                <DisabledLock
                                    isVisible={!isNil(selectionBoard.disabledAt)}
                                    tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.selectionBoardTable.disabledTooltip" })}
                                />
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: ![SelectionBoardItemTableColumn.title, SelectionBoardItemTableColumn.createdAt, SelectionBoardItemTableColumn.updatedAt].includes(columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<SelectionBoardListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<SelectionBoardListSortField>(SelectionBoardListSortField, "sharedComponent.contentLibraryTable.selectionBoardTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshSelectionBoardList(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): SelectionBoardListSortField | undefined => {
        switch (columnId) {
            case SelectionBoardItemTableColumn.title:
                return SelectionBoardListSortField.title;
            case SelectionBoardItemTableColumn.createdAt:
                return SelectionBoardListSortField.createdAt;
            case SelectionBoardItemTableColumn.updatedAt:
                return SelectionBoardListSortField.updatedAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: SelectionBoardListSortField | null): keyof SelectionBoardListItem | undefined => {
        switch (columnId) {
            case SelectionBoardListSortField.title:
                return SelectionBoardItemTableColumn.title;
            case SelectionBoardListSortField.createdAt:
                return SelectionBoardItemTableColumn.createdAt;
            case SelectionBoardListSortField.updatedAt:
                return SelectionBoardItemTableColumn.updatedAt;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<SelectionBoardListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private readonly onToggled = (content: SelectionBoardListItem) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                content: content,
                onToggled: () => {
                    this.refreshSelectionBoardList(this.state.options);
                },
            }),
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Table
                keyExtractor={(item: SelectionBoardListItem, column?: Column<SelectionBoardListItem>): string => {
                    return `${item.id}_${column ? column.id : ""}`;
                }}
                columns={this.getColumns()}
                sortBy={{
                    columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                    order: this.state.options.control.sortOrder || undefined,
                }}
                data={this.state.selectionBoards}
                count={this.state.count}
                limit={this.state.options.control.limit}
                isSortable={true}
                onSortOrderChange={this.onSortOrderChange}
                onPageChange={this.onPageChange}
                isPaginationEnabled={true}
                currentPage={this.getCurrentPage()}
                isLoading={this.state.isLoading}
                onRowClick={selectionBoard => {
                    const path = ContentPageUtils.getViewPath(selectionBoard, PageContent.clientDetail, this.props.client.extId);
                    if (path) {
                        this.props.history.push(path);
                    }
                }}
                renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.selectionBoardTable.noData" })}
            />
        );
    }
}

export { SelectionBoardTable };

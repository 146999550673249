import * as React from "react";

function SvgCalendarCheck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={19} height={20} fill="none" {...props}>
            <path
                d="M14.542 10.759a.364.364 0 000-.502l-.513-.514a.364.364 0 00-.502 0L8.57 14.7l-2.455-2.456a.364.364 0 00-.502 0l-.514.514a.364.364 0 000 .502l3.215 3.214a.378.378 0 00.513 0l5.714-5.714zM1.43 18.57V7.143h15.714V18.57H1.429zM5.714 5c0 .2-.156.357-.357.357h-.714A.353.353 0 014.286 5V1.786c0-.201.156-.357.357-.357h.714c.201 0 .357.156.357.357V5zm8.572 0c0 .2-.156.357-.357.357h-.715A.353.353 0 0112.857 5V1.786c0-.201.156-.357.357-.357h.715c.2 0 .357.156.357.357V5zm4.285-.714c0-.782-.647-1.429-1.428-1.429h-1.429V1.786C15.714.804 14.911 0 13.93 0h-.715a1.79 1.79 0 00-1.785 1.786v1.071H7.143V1.786C7.143.804 6.339 0 5.357 0h-.714C3.66 0 2.857.804 2.857 1.786v1.071H1.43C.647 2.857 0 3.504 0 4.286V18.57C0 19.353.647 20 1.429 20h15.714c.781 0 1.428-.647 1.428-1.429V4.286z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgCalendarCheck;

import React, { Component } from "react";
import { PageType } from "utils/TypeUtils";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Prompt } from "components/Prompt";
import { isEqual, isNil } from "lodash";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { NotificationType } from "components/NotificationBar/Notification";
import { Validator } from "utils/Validator";
import { LocalClientInstantAwardInput } from "./ClientInstantAwardPage";
import { Section } from "components/Section";
import { InstantAward, AssetType, AssetContent, SearchListType } from "api/graphql/types";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "utils/Path";

interface ComponentProps {
    accountId: string;
    clientInstantAward: LocalClientInstantAwardInput;
    clientName: string;
    clientExtId: string;
    pageType: PageType;
    onSubmit: (clientInstantAward: LocalClientInstantAwardInput) => Promise<InstantAward>;
}

type Props = ComponentProps & RouteComponentProps;

interface Errors {
    title: string | null;
}

interface State {
    clientInstantAward: LocalClientInstantAwardInput;
    errors: Errors;
    isValidationEnabled: boolean;
}

class ClientInstantAwardFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            clientInstantAward: {
                title: props.clientInstantAward.title,
                image: props.clientInstantAward.image,
            },
            isValidationEnabled: false,
            errors: {
                title: null,
            },
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = ClientInstantAwardFormComponent.getInitialStateFromProps(props);
    }

    public isChanged = (): boolean => {
        return !isEqual(this.state.clientInstantAward, ClientInstantAwardFormComponent.getInitialStateFromProps(this.props).clientInstantAward);
    };

    private onCancelClick = (): void => {
        this.setState({ clientInstantAward: ClientInstantAwardFormComponent.getInitialStateFromProps(this.props).clientInstantAward, isValidationEnabled: false, errors: { title: null } });
    };

    private onSubmit = async (): Promise<void> => {
        const { clientInstantAward, errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateClientInstantAwardTitle(clientInstantAward.title || ""));
        if (errors.title !== null || titleError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError } });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            return;
        }

        try {
            const clientInstantAward: InstantAward = await this.props.onSubmit(this.state.clientInstantAward);
            this.setState({ clientInstantAward: { title: clientInstantAward.title, image: clientInstantAward.image }, isValidationEnabled: false }, () => {
                if (this.props.pageType === PageType.create) {
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAward.create.saveSucceed" }) });
                    this.props.history.push(Path.clientInstantAwards(this.props.clientExtId));
                } else {
                    if (this.pageRef) {
                        this.pageRef.showNotification({
                            message: Intl.formatMessage({ id: `page.clientAwardsBase.clientInstantAward.${this.props.pageType}.saveSucceed` }),
                            type: NotificationType.success,
                        });
                    }
                }
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private renderBottomBar = (): React.ReactElement => {
        return (
            <BottomBar isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged()} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged()} onClick={this.onSubmit} />
                </div>
            </BottomBar>
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateClientInstantAwardTitle(title));
        this.setState({ clientInstantAward: { ...this.state.clientInstantAward, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onImageChange = (assets: AssetContent[]): void => {
        const image: AssetContent | null = assets[0] || null;
        this.setState({ clientInstantAward: { ...this.state.clientInstantAward, image } });
    };

    public render(): React.ReactElement {
        const { pageType } = this.props;
        const { clientInstantAward, errors, isValidationEnabled } = this.state;

        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage(
                    { id: `page.clientAwardsBase.clientInstantAward.${pageType}.title` },
                    { clientName: this.props.clientName, clientInstantAwardName: this.props.clientInstantAward.title },
                )}
                hasSideBar={true}
            >
                <DragContainer
                    dragIds={this.state.clientInstantAward.image?.id ? [this.state.clientInstantAward.image?.id] : []}
                    searchListTypes={[SearchListType.asset]}
                    assetTypes={[AssetType.image]}
                    createdById={this.props.accountId}
                >
                    <div className="left-side">
                        <p>{Intl.formatMessage({ id: `page.clientAwardsBase.clientInstantAward.${pageType}.lead` })}</p>
                        <Section label={Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAward.dataSectionTitle" })}>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAward.title.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                                <Input
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.titleRef = ref;
                                    }}
                                    value={clientInstantAward.title || ""}
                                    hasError={isValidationEnabled && !isNil(errors.title)}
                                    onChange={this.onTitleChange}
                                    placeholder={Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAward.title.placeholder" })}
                                />
                            </InputWrapper>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAward.image.label" })}>
                                <AssetInput
                                    droppableId="image"
                                    assetType={AssetType.image}
                                    value={!isNil(this.state.clientInstantAward.image) ? [this.state.clientInstantAward.image] : []}
                                    onChange={this.onImageChange}
                                    maxItemCount={1}
                                    minItemCount={1}
                                />
                            </InputWrapper>
                        </Section>
                    </div>
                </DragContainer>
                {this.renderBottomBar()}

                <Prompt when={this.isChanged()} />
            </Page>
        );
    }
}

export const ClientInstantAwardForm = withRouter(ClientInstantAwardFormComponent);

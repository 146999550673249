import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { DateInput } from "components/Inputs/Date/DateInput";
import { SupportedClient } from "api/graphql/types";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface AssignToDayDialogProps {
    onAssigned: () => void;
    agenda: { id: string; day: Date } | null;
    client?: SupportedClient | null;
}

type Props = AssignToDayDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
    day: Date | null;
}

class AssignToDayDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
        day: null,
    };

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.agenda !== this.props.agenda) {
            this.setState({ day: null });
        }
    }

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.assignToDayDialog.${id}` });
    };

    private assignToDay = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                if (this.props.client && this.props.agenda && !!this.state.day) {
                    try {
                        await Api.copyAgendaToClientAgendaLibrary(this.props.client.id, this.props.agenda.id, DateUtils.format(this.state.day, DateFormat.default));
                        Alert.success({ title: this.localTranslation("succeed") });
                        this.props.onAssigned();
                        this.props.onHide();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }
                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog visible={this.props.isVisible} titleIcon={"calendar"} title={this.localTranslation("title")} onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}>
                <InputWrapper inputLabel={this.localTranslation("nameInputTitle")}>
                    <Input name="title" type="text" value={this.props.client ? this.props.client.name : ""} disabled={true} />
                </InputWrapper>
                <InputWrapper inputLabel={this.localTranslation("dateInputTitle")}>
                    <DateInput min={new Date()} value={this.state.day} placeholder={this.localTranslation("placeholder")} onChange={(day: Date | null) => this.setState({ day })} />
                </InputWrapper>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button label={this.localTranslation("assignButtonTitle")} onClick={this.assignToDay} disabled={this.state.isLoading} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const AssignToDayDialog: React.ComponentClass<Props> = AssignToDayDialogComponent;

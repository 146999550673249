import React, { Component } from "react";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Account, Asset } from "api/graphql/types";
import { Api } from "api/Api";
import { AccountActions } from "actions/AccountActions";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AvatarForm } from "components/AvatarForm";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & NotificationProp;

class ProfileAvatarFormComponent extends Component<Props> {
    private uploadAvatar = async (file: File, onProgressChange: (progress: number) => void): Promise<void> => {
        try {
            const avatar: Asset | null = await Api.uploadAccountAvatar(file, onProgressChange);
            if (avatar) {
                avatar.url = `${avatar.url}?${new Date().getTime()}`;
            }
            this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, avatar }));
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.profile.avatar.uploadSucceed" }) });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private deleteAvatar = async (): Promise<void> => {
        try {
            await Api.deleteAccountAvatar();
            this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, avatar: null }));
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.profile.avatar.deleteSucceed" }) });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> {
        const url: string | null = this.props.account.avatar ? this.props.account.avatar.url : null;
        return <AvatarForm avatarUrl={url} onFileSelected={this.uploadAvatar} deleteAvatar={this.deleteAvatar} />;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account!,
    };
};

export const ProfileAvatarForm = withNotification(connect(mapStateToProps)(ProfileAvatarFormComponent));

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import {
    AgendaListItem,
    AgendaItemListItem,
    FlowchartItemListItem,
    FlowchartListItem,
    AssetListItem,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result as EverydaySituationDirectory,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations as EverydaySituation,
    InstantAwardListItem,
    AwardListItem,
    SelectionBoardListItem,
    Asset,
    SelectionBoard,
} from "api/graphql/types";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface ContentTogglerDialogProps {
    onToggled: () => void;
    content:
        | AgendaListItem
        | AgendaItemListItem
        | FlowchartListItem
        | FlowchartItemListItem
        | AssetListItem
        | Asset
        | EverydaySituationDirectory
        | EverydaySituation
        | InstantAwardListItem
        | AwardListItem
        | SelectionBoardListItem
        | SelectionBoard
        | null;
}

type Props = ContentTogglerDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class ContentTogglerDialog extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private isEnable = (): boolean => {
        return !!this.props.content && !!this.props.content.disabledAt;
    };

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.contentTogglerDialog.${this.isEnable() ? "enable" : "disable"}.${id}` });
    };

    private getContentName = (): string => {
        const { content } = this.props;
        if (!content) {
            return "";
        }
        return content.title;
    };

    private onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                if (this.props.content) {
                    try {
                        if (this.props.content.disabledAt) {
                            await Api.enableContent(this.props.content);
                            Alert.success({ title: this.localTranslation("enableSucceed") });
                        } else {
                            await Api.disableContent(this.props.content);
                            Alert.success({ title: this.localTranslation("disableSucceed") });
                        }
                        this.props.onToggled();
                        this.props.onHide();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }
                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        const isEnable: boolean = this.isEnable();
        const title = this.props.content ? this.localTranslation(`title.${this.props.content.__typename}`) : "";
        return (
            <Dialog visible={this.props.isVisible && !!this.props.content} titleIcon={"person"} title={title} onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}>
                <i className="fas fa-exclamation-triangle verify-toggle-content-dialog-icon" />
                <form onSubmit={this.onSubmit}>
                    <p>{Intl.formatMessage({ id: `sharedComponent.contentTogglerDialog.${isEnable ? "enable" : "disable"}.description` }, { name: this.getContentName() })}</p>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" })} type="submit" disabled={this.state.isLoading} />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export { ContentTogglerDialog };

import React from "react";
import { SortOrder } from "api/graphql/types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Table } from "./Table";
import { Intl } from "i18n/Intl";
import { Sortable } from "components/SortableList/SortableList";

import "./SortableTable.scss";

interface SortBy {
    columnId?: string | null;
    order?: SortOrder;
}

interface Column<T extends Sortable> {
    /** Unique key for columns */
    id: string;

    /** label for column */
    name: string;

    /** custom className for columns. If value not set, using accessor */
    className?: string;

    /** item key accessor of type */
    accessor: keyof T;

    /** Render cell overwrite */
    renderCell?: (item: T, column: Column<T>) => React.ReactElement<any> | null;

    /** disable sorting on column */
    isNonSortable?: boolean;
}

interface Props<T extends Sortable> {
    /**
     * Render component after row
     */
    renderUnderRow?: (item: T) => React.ReactElement<any> | null;

    /**
     * When a column's header is pressed
     */
    onSortOrderChange?: (column?: Column<T>, sortOrder?: SortOrder) => void;

    /**
     * When a column's header is pressed
     */
    onPageChange?: (pageNumber: number) => Promise<void> | void;

    /**
     * Sort by which column and which direction
     */
    sortBy?: SortBy;

    /**
     * Columns of table
     */
    columns: Array<Column<T>>;

    limit?: number | null;

    /**
     * Data for table
     */
    data: T[];

    count: number;

    currentPage?: number;

    isPaginationEnabled?: boolean;

    isSortable?: boolean;

    isLoading: boolean;

    hideHeader?: boolean;

    /**
     * Empty component, when loading finished and count is 0
     */
    renderEmpty?: () => React.ReactElement<any> | string;

    onRowClick?: (item: T) => void;

    rowClassName?: (item: T) => string;

    onOrderChange?: (data: T[]) => void;

    renderItem?: (item: T, index: number, array: T[]) => React.ReactElement<any> | null;

    isDraggable?: boolean;

    droppableId?: string;

    areArrowsHidden?: boolean;
}

class SortableTable<T extends Sortable> extends React.Component<Props<T>> {
    private readonly reorder = (list: T[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    private readonly getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        position: "relative",
        backgroundColor: "transparent",
        ...draggableStyle,
    });

    private readonly onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = this.reorder(this.props.data, result.source.index, result.destination.index);
        this.props.onOrderChange && this.props.onOrderChange(items);
    };

    private readonly onReorderClick = (up: boolean, index: number) => () => {
        const items = this.reorder(this.props.data, index, up ? index - 1 : index + 1);
        this.props.onOrderChange && this.props.onOrderChange(items);
    };

    private readonly keyExtractor = (item: T, column?: Column<T>) => item.id + column?.id;

    public render(): React.ReactElement<any> {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={this.props.droppableId || "droppableId"} isDropDisabled={!this.props.onOrderChange}>
                    {(provided, _snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="sortable-table">
                            <Table
                                data={this.props.data}
                                columns={this.props.columns}
                                isLoading={this.props.isLoading}
                                keyExtractor={this.keyExtractor}
                                count={this.props.count}
                                currentPage={this.props.currentPage}
                                hideHeader={this.props.hideHeader}
                                isPaginationEnabled={this.props.isPaginationEnabled}
                                isSortable={this.props.isSortable}
                                sortBy={this.props.sortBy}
                                limit={this.props.limit}
                                onPageChange={this.props.onPageChange}
                                onRowClick={this.props.onRowClick}
                                onSortOrderChange={this.props.onSortOrderChange}
                                renderItem={this.props.renderItem}
                                rowClassName={this.props.rowClassName}
                                renderUnderRow={this.props.renderUnderRow}
                                renderEmpty={this.props.renderEmpty}
                                renderRow={(item: T, index, children: React.ReactElement<any>): React.ReactElement<any> => {
                                    return (
                                        <React.Fragment key={this.keyExtractor(item)}>
                                            <Draggable draggableId={item.id} index={index} isDragDisabled={!this.props.onOrderChange}>
                                                {(draggableProvided, snapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={this.getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
                                                        className={`${snapshot.isDragging ? "grabbed" : ""}`}
                                                    >
                                                        <div className="table-row sortable-table-row">
                                                            {children}
                                                            {!this.props.areArrowsHidden && (
                                                                <div className="drag-space">
                                                                    <div>
                                                                        {index !== 0 && (
                                                                            <div className="up" title={Intl.formatMessage({ id: "common.up" })} onClick={this.onReorderClick(true, index)} />
                                                                        )}
                                                                        {index !== this.props.data.length - 1 && (
                                                                            <div className="down" title={Intl.formatMessage({ id: "common.down" })} onClick={this.onReorderClick(false, index)} />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {draggableProvided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        </React.Fragment>
                                    );
                                }}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export { SortableTable };

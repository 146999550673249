import * as React from "react";

function SvgSupporterManager(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z" fill="currentColor" />
            <path
                d="M13.066 7.625A1.451 1.451 0 0112 8.063c-.42 0-.784-.146-1.094-.438a1.544 1.544 0 01-.437-1.094c0-.419.146-.774.437-1.066.31-.31.675-.465 1.094-.465.42 0 .775.155 1.066.465.31.292.465.647.465 1.066 0 .42-.155.784-.465 1.094zm3.746 9.625c.128 0 .228.037.301.11.091.09.137.2.137.328v.875a.364.364 0 01-.137.3.364.364 0 01-.3.137H7.188a.448.448 0 01-.329-.137.406.406 0 01-.109-.3v-.875c0-.128.036-.237.11-.329.09-.073.2-.109.327-.109h9.625zm1.997-7.219c.127.091.191.21.191.356a.485.485 0 01-.055.218l-2.789 5.77H7.844l-2.79-5.77a.44.44 0 01-.054-.19c0-.165.064-.293.191-.384l.793-.437c.22-.146.42-.1.602.136.11.146.264.274.465.383.2.091.392.137.574.137h.082c.346-.018.638-.164.875-.438.237-.273.355-.592.355-.957a.34.34 0 01.11-.246.34.34 0 01.246-.109h1.066c.2 0 .32.091.356.273.055.292.21.538.465.739.255.2.528.3.82.3.292 0 .565-.1.82-.3.255-.2.41-.447.465-.739.037-.182.155-.273.356-.273h1.066a.34.34 0 01.246.11.34.34 0 01.11.245c0 .329.1.63.3.903.2.255.456.41.766.465.073.018.155.027.246.027.182 0 .374-.046.574-.137.2-.11.356-.237.465-.383.182-.237.383-.282.602-.136l.793.437z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgSupporterManager;

export class TextUtils {
    private static escapeForRegExp(text: string): string {
        return text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
    }

    public static matchAny = (text: string): RegExp => {
        return new RegExp(TextUtils.escapeForRegExp(text), "gi");
    };

    public static matchPartial = (text: string): RegExp => {
        return new RegExp(`(?:^|\\s)${TextUtils.escapeForRegExp(text)}`, "i");
    };

    public static matchExact = (text: string): RegExp => {
        return new RegExp(`^${TextUtils.escapeForRegExp(text)}$`, "i");
    };
}

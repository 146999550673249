import Cookies from "universal-cookie";
import { DateUtils } from "./DateUtils";
import { Env } from "./Env";

class Cookie {
    private static readonly keys = {
        refreshToken: "refreshToken",
        stayLoggedIn: "stayLoggedIn",
        acceptedClientTodos: "acceptedClientTodos",
    };

    private static instance: Cookies = new Cookies();

    public static getRefreshToken(): string | null {
        return Cookie.instance.get(Cookie.keys.refreshToken) || null;
    }

    /**
     * Set refreshToken & stayLoggedIn
     * RefreshToken expiration based on stayLoggedIn value
     * @param refreshToken string
     * @param stayLoggedIn boolean
     */
    public static setAuthInfo(refreshToken: string | null, stayLoggedIn: boolean): void {
        const expires: Date | undefined = stayLoggedIn ? DateUtils.addDays(new Date(), Env.tokenExpirationInDays) : undefined;
        Cookie.setStayLoggedIn(stayLoggedIn);
        Cookie.instance.set(Cookie.keys.refreshToken, refreshToken, { expires, path: "/", sameSite: "lax" });
    }

    public static isStayLoggedIn(): boolean {
        return Cookie.instance.get(Cookie.keys.stayLoggedIn) === "true";
    }

    public static setStayLoggedIn(stayLoggedIn: boolean): void {
        Cookie.instance.set(Cookie.keys.stayLoggedIn, stayLoggedIn, { path: "/", sameSite: "lax" });
    }

    public static clearRefreshToken(): void {
        Cookie.instance.remove(Cookie.keys.refreshToken, { path: "/" });
    }

    public static getAcceptedClientTodos(): string[] {
        return Cookie.instance.get(Cookie.keys.acceptedClientTodos) || [];
    }

    public static setAcceptedClientTodos(extIds: string[]): void {
        Cookie.instance.set(Cookie.keys.acceptedClientTodos, extIds, { path: "/", sameSite: "lax" });
    }

    public static clearClientClosedTodoNotifications(): void {
        Cookie.instance.remove(Cookie.keys.acceptedClientTodos);
    }
}

export { Cookie };

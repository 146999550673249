import React, { Component } from "react";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { DeleteProfileDialog } from "./DeleteProfileDialog";
import { DialogsActions } from "actions/DialogsActions";
import { AssignToDayDialog } from "./AssignToDayDialog";
import { UploadAssetDialog } from "./UploadAssetDialog";
import { ShareContentDialog } from "./ShareContentDialog";
import { AssignToClientDialog } from "./AssignToClientDialog";
import { SetDefaultAgendaDialog } from "./SetDefaultAgendaDialog";
import { DeleteAgendaDialog } from "./DeleteAgendaDialog";
import { DeleteAgendaItemDialog } from "./DeleteAgendaItemDialog";
import { DialogWithUUID } from "reducers/DialogsReducer";
import { DeleteFlowchartDialog } from "./DeleteFlowchartDialog";
import { DeleteFlowchartItemDialog } from "./DeleteFlowchartItemDialog";
import { DeleteAssetDialog } from "./DeleteAssetDialog";
import { DeleteSelectionBoardDialog } from "./DeleteSelectionBoardDialog";
import { ChangeClientManagerDialog } from "./ChangeClientManagerDialog";
import { ContentTogglerDialog } from "./ContentTogglerDialog";
import { LogoutDialog } from "./LogoutDialog";
import { ConfirmLeaveDialog } from "./ConfirmLeaveDialog";
import { DeleteAccountConfirmDialog } from "./DeleteAccountConfirmDialog";
import { ConfirmAvatarDeleteDialog } from "./ConfirmAvatarDeleteDialog";
import { UnsetDefaultAgendaDialog } from "./UnsetDefaultAgendaDialog";
import { SyncDialog } from "./SyncDialog";
import { SyncWarningDialog } from "./SyncWarningDialog";
import { CreateAdminAccountDialog } from "./CreateAdminAccountDialog";
import { ConfirmAgendaDialog } from "./ConfirmAgendaDialog";
import { AddSupporterDialog } from "./AddSupporterDialog";
import { DeleteClientProfileDialog } from "./DeleteClientProfileDialog";
import { CreateSupervisorAccountDialog } from "./CreateSupervisorAccountDialog";
import { CreateSupporterAccountDialog } from "./CreateSupporterAccountDialog";
import { DeleteChangeClientManagerDialog } from "./DeleteChangeClientManagerDialog";
import { VerifyChangeClientManagerDialog } from "./VerifyChangeClientManagerDialog";
import { DeletePinDialog } from "./DeletePinDialog";
import { ConfirmUploadAssetDeleteDialog } from "./ConfirmUploadAssetDeleteDialog";
import { ShowAssetDialog } from "./ShowAssetDialog";
import { ClientTodoDialog } from "./ClientTodoDialog";
import { SelfSupportingDialog } from "./SelfSupportingDialog";
import { DeleteEverydaySituationDirectoryDialog } from "./DeleteEverydaySituationDirectoryDialog";
import { VerifyDeleteFaqDialog } from "./VerifyDeleteFaqDialog";
import { CreateAssetDirectoryDialog } from "./CreateAssetDirectoryDialog";
import { MoveAssetsDialog } from "./MoveAssetsDialog";
import { VerifyDeleteSelectionBoardDialog } from "./VerifyDeleteSelectionBoardDialog";
import { MoveEverydaySituationDialog } from "./MoveEverydaySituationDialog";
import { InfoDialog } from "./InfoDialog";
import { VerifyDeleteClientInstantAwardDialog } from "./VerifyDeleteClientInstantAwardDialog";
import { VerifyDeleteClientAwardDialog } from "./VerifyDeleteClientAwardDialog";
import { DeleteTagDialog } from "./DeleteTagDialog";
import { VerifyDeleteSubModuleDialog } from "./VerifyDeleteSubModuleDialog";
import { DeleteEverydaySituationDialog } from "./DeleteEverydaySituationDialog";
import { RejectContentShareRequestDialog } from "./RejectContentShareRequestDialog";
import { DisableRelatingAssetsDialog } from "./DisableRelatingAssetsDialog";
import { CopyEverydaySituationDialog } from "./CopyEverydaySituationDialog";
import { ClientQuestionnaireInfoDialog } from "./ClientQuestionnaireInfoDialog";
import { EditAssetDirectoryDialog } from "./EditAssetDirectoryDialog";

export enum DialogType {
    deleteProfile = "deleteProfile",
    assignToDay = "assignToDay",
    uploadAsset = "uploadAsset",
    createAssetDirectory = "createAssetDirectory",
    shareContent = "shareContent",
    shareAsset = "shareAsset",
    assignToClient = "assignToClient",
    setDefaultAgenda = "setDefaultAgenda",
    deleteAgenda = "deleteAgenda",
    deleteAgendaItem = "deleteAgendaItem",
    deleteFlowchart = "deleteFlowchart",
    deleteFlowchartItem = "deleteFlowchartItem",
    moveAsset = "moveAsset",
    deleteAsset = "deleteAsset",
    deleteSelectionBoard = "deleteSelectionBoard",
    changeClientManager = "changeClientManager",
    contentToggler = "contentToggler",
    logout = "logout",
    confirmLeave = "confirmLeave",
    deleteAccountConfirm = "deleteAccountConfirm",
    confirmAvatarDelete = "confirmAvatarDelete",
    unsetDefaultAgenda = "unsetDefaultAgenda",
    sync = "sync",
    syncWarning = "syncWarning",
    createAdminAccount = "createAdminAccount",
    confirmAgenda = "confirmAgenda",
    addSupporter = "addSupporter",
    deleteClientProfile = "deleteClientProfile",
    createSupervisorAccount = "createSupervisorAccount",
    createSupporterAccount = "createSupporterAccount",
    deleteChangeClientManager = "deleteChangeClientManager",
    verifyChangeClientManager = "verifyChangeClientManager",
    deletePin = "deletePin",
    clientTodo = "clientTodo",
    confirmUploadAssetDelete = "confirmUploadAssetDelete",
    showAsset = "showAsset",
    selfSupporting = "selfSupporting",
    verifyDeleteFaq = "verifyDeleteFaq",
    verifyDeleteSelectionBoard = "verifyDeleteSelectionBoard",
    moveEverydaySituation = "moveEverydaySituation",
    copyEverydaySituation = "copyEverydaySituation",
    info = "info",
    deleteEverydaySituation = "deleteEverydaySituation",
    deleteEverydaySituationDirectory = "deleteEverydaySituationDirectory",
    verifyDeleteClientInstantAward = "verifyDeleteClientInstantAward",
    verifyDeleteClientAward = "verifyDeleteClientAward",
    deleteTag = "deleteTag",
    verifyDeleteSubModule = "verifyDeleteSubModule",
    rejectContentShareRequest = "rejectContentShareRequest",
    disableRelatingAssets = "disableRelatingAssets",
    clientQuestionnaireInfo = "clientQuestionnaireInfo",
    editAssetDirectory = "editAssetDirectory",
}

interface ReduxProps {
    dialogs: DialogWithUUID[] | null;
}

type Props = ReduxProps & DispatchProp;

export interface DialogVisibilityProps {
    isVisible: boolean;
    onHide: () => void;
}

interface State {
    isVisible: boolean;
}

class DialogsContainerComponent extends Component<Props, State> {
    public readonly state: State = {
        isVisible: true,
    };

    private onHide = (uuid: string, isTransitioned: boolean = true, callback?: () => Promise<void> | void) => {
        this.setState({ isVisible: false }, () => {
            if (isTransitioned) {
                setTimeout(() => {
                    this.props.dispatch(DialogsActions.hide(uuid));
                }, 300);
            } else {
                this.props.dispatch(DialogsActions.hide(uuid));
            }
            this.setState({ isVisible: true }, () => {
                if (callback) {
                    callback();
                }
            });
        });
    };

    private renderDialog = (dialog: DialogWithUUID): React.ReactElement<any> | null => {
        const onHide = () => this.onHide(dialog.uuid);
        switch (dialog.type) {
            case DialogType.deleteProfile:
                return (
                    <DeleteProfileDialog
                        isVisible={this.state.isVisible}
                        profileName={dialog.profileName}
                        onDelete={() => {
                            this.onHide(dialog.uuid, false, dialog.onDelete);
                        }}
                        onHide={onHide}
                    />
                );
            case DialogType.deletePin:
                return <DeletePinDialog isVisible={this.state.isVisible} onDelete={dialog.onDelete} onHide={onHide} />;
            case DialogType.assignToDay:
                return <AssignToDayDialog isVisible={this.state.isVisible} onAssigned={dialog.onAssigned} agenda={dialog.agenda} client={dialog.client} onHide={onHide} />;
            case DialogType.uploadAsset:
                return <UploadAssetDialog isVisible={this.state.isVisible} onAssetUploadFinished={dialog.onAssetUploadFinished} onHide={() => this.onHide(dialog.uuid)} />;
            case DialogType.createAssetDirectory:
                return (
                    <CreateAssetDirectoryDialog isVisible={this.state.isVisible} onHide={onHide} onCreateAssetDirectoryFinished={dialog.onCreateAssetDirectoryFinished} isPublic={dialog.isPublic} />
                );
            case DialogType.shareContent:
                return <ShareContentDialog isVisible={this.state.isVisible} content={dialog.content} onSucceed={dialog.onSucceed} onHide={onHide} />;
            case DialogType.assignToClient:
                return <AssignToClientDialog isVisible={this.state.isVisible} content={dialog.content} onHide={onHide} />;
            case DialogType.setDefaultAgenda:
                return <SetDefaultAgendaDialog isVisible={this.state.isVisible} onSetSucceed={dialog.onSetSucceed} clientId={dialog.clientId} agenda={dialog.agenda} onHide={onHide} />;
            case DialogType.deleteAgenda:
                return <DeleteAgendaDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} agenda={dialog.agenda} onHide={onHide} />;
            case DialogType.deleteAgendaItem:
                return <DeleteAgendaItemDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} agendaItem={dialog.agendaItem} onHide={onHide} />;
            case DialogType.deleteFlowchart:
                return <DeleteFlowchartDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} flowchart={dialog.flowchart} onHide={onHide} />;
            case DialogType.deleteFlowchartItem:
                return <DeleteFlowchartItemDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} flowchartItem={dialog.flowchartItem} onHide={onHide} />;
            case DialogType.moveAsset:
                return (
                    <MoveAssetsDialog
                        isVisible={this.state.isVisible}
                        onMoved={dialog.onMoved}
                        assets={dialog.assets}
                        onHide={onHide}
                        currentDirectory={dialog.currentDirectory}
                        isPublic={dialog.isPublic}
                    />
                );
            case DialogType.deleteAsset:
                return <DeleteAssetDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} asset={dialog.asset} onHide={onHide} />;
            case DialogType.deleteSelectionBoard:
                return <DeleteSelectionBoardDialog isVisible={this.state.isVisible} selectionBoard={dialog.selectionBoard} onDeleteClick={dialog.onDeleteClick} onHide={onHide} />;
            case DialogType.changeClientManager:
                return (
                    <ChangeClientManagerDialog
                        isVisible={this.state.isVisible}
                        client={dialog.client}
                        supporters={dialog.supporters}
                        onChangeClientManagerFinished={dialog.onChangeClientManagerFinished}
                        onHide={onHide}
                    />
                );
            case DialogType.contentToggler:
                return <ContentTogglerDialog isVisible={this.state.isVisible} onToggled={dialog.onToggled} content={dialog.content} onHide={onHide} />;
            case DialogType.logout:
                return (
                    <LogoutDialog
                        isVisible={this.state.isVisible}
                        onLogoutClick={() => {
                            this.onHide(dialog.uuid, false, dialog.onLogoutClick);
                        }}
                        onHide={onHide}
                    />
                );
            case DialogType.confirmLeave:
                return (
                    <ConfirmLeaveDialog
                        isVisible={this.state.isVisible}
                        onCancelClick={dialog.onCancelClick}
                        onAcceptClick={dialog.onAcceptClick}
                        promptMessage={dialog.promptMessage}
                        onHide={onHide}
                    />
                );
            case DialogType.deleteAccountConfirm:
                return <DeleteAccountConfirmDialog isVisible={this.state.isVisible} onConfirmed={dialog.onConfirmed} onHide={onHide} />;
            case DialogType.confirmAvatarDelete:
                return <ConfirmAvatarDeleteDialog isVisible={this.state.isVisible} onOkClick={dialog.onOkClick} onHide={onHide} />;
            case DialogType.unsetDefaultAgenda:
                return <UnsetDefaultAgendaDialog isVisible={this.state.isVisible} onSetSucceed={dialog.onSetSucceed} clientId={dialog.clientId} onHide={onHide} />;
            case DialogType.sync:
                return <SyncDialog isVisible={this.state.isVisible} client={dialog.client} close={dialog.close} onHide={onHide} />;
            case DialogType.syncWarning:
                return <SyncWarningDialog isVisible={this.state.isVisible} client={dialog.client} onHide={onHide} />;
            case DialogType.createAdminAccount:
                return <CreateAdminAccountDialog isVisible={this.state.isVisible} createAdminAccount={dialog.createAdminAccount} onHide={onHide} />;
            case DialogType.confirmAgenda:
                return (
                    <ConfirmAgendaDialog
                        isVisible={this.state.isVisible}
                        agenda={dialog.agenda}
                        onUpdateAllClick={dialog.onUpdateAllClick}
                        onUpdateCurrentClick={dialog.onUpdateCurrentClick}
                        onHide={onHide}
                    />
                );
            case DialogType.addSupporter:
                return <AddSupporterDialog isVisible={this.state.isVisible} onSucceed={dialog.onSucceed} clientId={dialog.clientId} onHide={onHide} />;
            case DialogType.deleteClientProfile:
                return <DeleteClientProfileDialog isVisible={this.state.isVisible} profileName={dialog.profileName} onDelete={dialog.onDelete} onHide={onHide} />;
            case DialogType.createSupervisorAccount:
                return <CreateSupervisorAccountDialog isVisible={this.state.isVisible} createSupervisorAccount={dialog.createSupervisorAccount} onHide={onHide} />;
            case DialogType.createSupporterAccount:
                return <CreateSupporterAccountDialog isVisible={this.state.isVisible} createSupporterAccount={dialog.createSupporterAccount} onHide={onHide} />;
            case DialogType.deleteChangeClientManager:
                return (
                    <DeleteChangeClientManagerDialog
                        isVisible={this.state.isVisible}
                        client={dialog.client}
                        onDeleteChangeClientManagerFinished={dialog.onDeleteChangeClientManagerFinished}
                        onHide={onHide}
                    />
                );
            case DialogType.verifyChangeClientManager:
                return (
                    <VerifyChangeClientManagerDialog
                        isVisible={this.state.isVisible}
                        client={dialog.client}
                        operation={dialog.operation}
                        onChangeClientManagerFinished={dialog.onChangeClientManagerFinished}
                        onClose={dialog.onClose}
                        onHide={onHide}
                    />
                );
            case DialogType.confirmUploadAssetDelete:
                return <ConfirmUploadAssetDeleteDialog isVisible={this.state.isVisible} assetType={dialog.assetType} onOkClick={dialog.onOkClick} onHide={onHide} />;
            case DialogType.clientTodo:
                return <ClientTodoDialog clientsWithTodo={dialog.clientsWithTodo} isVisible={this.state.isVisible} onHide={onHide} />;
            case DialogType.showAsset:
                return (
                    <ShowAssetDialog
                        isVisible={this.state.isVisible}
                        assetUrl={dialog.assetUrl}
                        originalFileName={dialog.originalFileName}
                        thumbnailUrl={dialog.thumbnailUrl}
                        assetType={dialog.assetType}
                        dialogTitle={dialog.dialogTitle}
                        onHide={onHide}
                    />
                );
            case DialogType.selfSupporting:
                return (
                    <SelfSupportingDialog
                        isVisible={this.state.isVisible}
                        onConfirmed={dialog.onConfirmed}
                        isSelfSupporting={dialog.isSelfSupporting}
                        clientProfile={dialog.clientProfile}
                        onHide={onHide}
                    />
                );
            case DialogType.deleteEverydaySituation:
                return <DeleteEverydaySituationDialog isVisible={this.state.isVisible} onDeleted={dialog.onDeleted} everydaySituation={dialog.everydaySituation} onHide={onHide} />;
            case DialogType.deleteEverydaySituationDirectory:
                return (
                    <DeleteEverydaySituationDirectoryDialog
                        isVisible={this.state.isVisible}
                        onDeleted={dialog.onDeleted}
                        everydaySituationDirectory={dialog.everydaySituationDirectory}
                        onHide={onHide}
                    />
                );
            case DialogType.verifyDeleteFaq:
                return <VerifyDeleteFaqDialog isVisible={this.state.isVisible} faqId={dialog.faqId} onDeleted={dialog.onDeleted} onHide={onHide} />;
            case DialogType.verifyDeleteSelectionBoard:
                return (
                    <VerifyDeleteSelectionBoardDialog
                        isVisible={this.state.isVisible}
                        selectionBoard={dialog.selectionBoard}
                        isFromAdminTable={dialog.isFromAdminTable}
                        onDeleted={dialog.onDeleted}
                        onHide={onHide}
                    />
                );
            case DialogType.moveEverydaySituation:
                return (
                    <MoveEverydaySituationDialog
                        isVisible={this.state.isVisible}
                        everydaySituation={dialog.everydaySituation}
                        clientId={dialog.clientId}
                        onMoveSucceed={dialog.onMoveSucceed}
                        onHide={onHide}
                    />
                );
            case DialogType.copyEverydaySituation:
                return (
                    <CopyEverydaySituationDialog
                        isVisible={this.state.isVisible}
                        everydaySituation={dialog.everydaySituation}
                        client={dialog.client}
                        onCopySucceed={dialog.onCopySucceed}
                        onHide={onHide}
                    />
                );
            case DialogType.info:
                return <InfoDialog title={dialog.title} message={dialog.message} isVisible={this.state.isVisible} onHide={onHide} />;
            case DialogType.verifyDeleteClientInstantAward:
                return (
                    <VerifyDeleteClientInstantAwardDialog
                        isVisible={this.state.isVisible}
                        clientInstantAwardId={dialog.clientInstantAwardId}
                        onDeleted={dialog.onDeleted}
                        onHide={onHide}
                        hasReferences={dialog.hasReferences}
                    />
                );
            case DialogType.verifyDeleteClientAward:
                return <VerifyDeleteClientAwardDialog {...dialog} isVisible={this.state.isVisible} onHide={onHide} />;
            case DialogType.deleteTag:
                return <DeleteTagDialog isVisible={this.state.isVisible} tag={dialog.tag} onDeleted={dialog.onDeleted} onHide={onHide} />;
            case DialogType.verifyDeleteSubModule:
                return <VerifyDeleteSubModuleDialog isVisible={this.state.isVisible} subModuleId={dialog.subModuleId} onDeleted={dialog.onDeleted} onHide={onHide} />;
            case DialogType.rejectContentShareRequest:
                return <RejectContentShareRequestDialog isVisible={this.state.isVisible} requestId={dialog.requestId} onRejected={dialog.onRejected} onHide={onHide} />;
            case DialogType.disableRelatingAssets:
                return <DisableRelatingAssetsDialog isVisible={this.state.isVisible} clientId={dialog.clientId} onDisabled={dialog.onDisabled} content={dialog.content} onHide={onHide} />;
            case DialogType.clientQuestionnaireInfo:
                return (
                    <ClientQuestionnaireInfoDialog
                        isVisible={this.state.isVisible}
                        onClose={dialog.onClose}
                        title={dialog.title}
                        description={dialog.description}
                        buttonLabel={dialog.buttonLabel}
                        onHide={onHide}
                    />
                );
            case DialogType.editAssetDirectory:
                return <EditAssetDirectoryDialog isVisible={this.state.isVisible} onClose={dialog.onClose} directoryId={dialog.directoryId} isPublic={dialog.isPublic} onHide={onHide} />;
            default:
                return null;
        }
    };

    public render(): React.ReactElement<any> | null {
        if (!this.props.dialogs) {
            return null;
        }

        return (
            <>
                {this.props.dialogs.reverse().map((dialog: DialogWithUUID) => (
                    <React.Fragment key={dialog.uuid}>{this.renderDialog(dialog)}</React.Fragment>
                ))}
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        dialogs: state.dialogs,
    };
};

export const DialogsContainer = connect(mapStateToProps)(DialogsContainerComponent);

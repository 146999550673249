// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function SelectContentIcon({ width = 26, height = 26 }: IconProps): React.ReactElement {
    return (
        <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="11.75" fill="#4EC1F9" stroke="white" strokeWidth="1.5" />
            <path d="M8.83084 10L6 13M6 13L8.83084 16M6 13L11 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.1692 10L20 13M20 13L17.1692 16M20 13L15 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export { SelectContentIcon };

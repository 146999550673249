import React, { Component } from "react";
import { Asset, Account } from "api/graphql/types";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter } from "react-router";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Redirect } from "react-router-dom";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { AssetForm } from "./AssetForm";

interface ReduxProps {
    account: Account;
}

interface RouteParams {
    assetId: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    asset?: Asset | null;
    isLoading: boolean;
}

class AssetPageComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        asset: null,
    };

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshAsset(this.props);
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.assetId !== nextProps.match.params.assetId) {
            this.refreshAsset(nextProps);
        }
    }

    private refreshAsset = (props: Props): void => {
        const { assetId } = props.match.params;

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const asset: Asset = await Api.getMyAssetById(assetId);
                    this.setState({ isLoading: false, asset });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private onSubmit = async (title: string, tags: string[]): Promise<Asset> => {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        if (pageType === PageType.edit) {
            const result = await Api.updateAsset(this.state.asset!.id, title, tags);
            this.setState({ asset: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    public render(): React.ReactElement<any> | null {
        if (!this.state.isLoading && !this.state.asset) {
            return <Redirect to={Path.dashboard} />;
        }

        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, false);

        if (!this.state.asset) {
            return null;
        }

        return (
            <AssetForm
                pageType={pageType}
                pageContent={pageContent}
                asset={this.state.asset!}
                isLoading={this.state.isLoading}
                onSubmit={this.onSubmit}
                refreshAsset={() => this.refreshAsset(this.props)}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const AssetPage = withRouter(connect(mapStateToProps)(AssetPageComponent));

// tslint:disable-next-line: no-default-export
export default AssetPage;

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { AgendaListItem, AgendaItemListItem, FlowchartItemListItem, FlowchartListItem, SupportedClient } from "api/graphql/types";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { SelectOption, Select } from "components/Inputs/Select/Select";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";

type ClientOption = SelectOption<string>;

interface ReduxProps {
    clients: SupportedClient[];
}

export interface AssignToClientDialogProps {
    content: AgendaListItem | AgendaItemListItem | FlowchartListItem | FlowchartItemListItem | null;
}

type ComponentProps = AssignToClientDialogProps & DialogVisibilityProps;

type Props = ComponentProps & ReduxProps;

interface State {
    isLoading: boolean;
    client: ClientOption;
}

class AssignToClientDialogComponent extends Component<Props, State> {
    private getOptions = (): ClientOption[] => {
        if (this.props.clients.length === 0) {
            return [{ id: "", label: Intl.formatMessage({ id: "sharedComponent.assignToClientDialog.noClient" }), value: "" }];
        }
        return this.props.clients.map(
            (client: SupportedClient): ClientOption => {
                return { id: client.id, label: client.name, value: client.id };
            },
        );
    };

    public readonly state: State = {
        isLoading: false,
        client: this.getOptions()[0],
    };

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.content !== this.props.content) {
            this.setState({ client: this.getOptions()[0] });
        }
    }

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.assignToClientDialog.${id}` });
    };

    private getContentName = (): string => {
        const { content } = this.props;
        if (!content) {
            return "";
        }
        return content.title;
    };

    private onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                if (this.props.content && !!this.state.client) {
                    try {
                        await Api.copyContentToClientLibrary(this.state.client.value, this.props.content);
                        Alert.success({ title: this.localTranslation("succeed") });
                        this.props.onHide();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }
                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible && !!this.props.content}
                titleIcon={"user-plus"}
                title={this.localTranslation("title")}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <form onSubmit={this.onSubmit}>
                    <h2>{this.localTranslation("form.title")}</h2>
                    <InputWrapper inputLabel={this.props.content ? this.localTranslation(`form.type.label.${this.props.content.__typename}`) : ""}>
                        <Input name="title" type="text" value={this.getContentName()} disabled={true} />
                    </InputWrapper>
                    <hr />
                    <InputWrapper inputLabel={this.localTranslation("form.client.label")}>
                        <Select
                            options={this.getOptions()}
                            value={this.state.client}
                            onChange={(client: ClientOption): void => {
                                this.setState({ client });
                            }}
                        />
                    </InputWrapper>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={this.localTranslation("form.assignButton")} type="submit" disabled={this.state.isLoading || this.props.clients.length === 0} />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { clients: AccountSelectors.getClients(state) };
};

export const AssignToClientDialog = connect(mapStateToProps)(AssignToClientDialogComponent);

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Section } from "components/Section";
import { Box } from "pages/EducationPage/Box";
import { IntlHelpers } from "i18n/IntlHelpers";

class GeneralTabComponent extends Component {
    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `page.education.tab.general.${id}` });
    };

    public render(): React.ReactElement {
        return (
            <div className="left-side">
                <p>{this.localTranslation("lead")}</p>

                <Section label={this.localTranslation("dataSection.title")}>
                    <p>{this.localTranslation("dataSection.text")}</p>
                </Section>

                <Section label={this.localTranslation("moduleSection.title")}>
                    <p>{this.localTranslation("moduleSection.text")}</p>
                </Section>

                <Section label={this.localTranslation("info.title")}>
                    <p>{this.localTranslation("info.text1")}</p>
                    <p>{this.localTranslation("info.text2")}</p>

                    <div>
                        <div className="grid-x padded extra-margin">
                            <Box
                                title={this.localTranslation("info.contactHomepageTitle")}
                                description={this.localTranslation("info.contactHomepageDescription")}
                                isSmall
                                href={this.localTranslation("info.contactHomepageLink")}
                            />
                            <Box
                                title={this.localTranslation("info.contactNewsletterTitle")}
                                description={IntlHelpers.asHtml({ id: "page.education.tab.general.info.contactNewsletterDescription" })}
                                isSmall
                                href={this.localTranslation("info.contactNewsletterLink")}
                            />
                            <Box
                                title={this.localTranslation("info.contactHelpdeskTitle")}
                                description={IntlHelpers.asHtml({ id: "page.education.tab.general.info.contactHelpdeskDescription" })}
                                isSmall
                                href={this.localTranslation("info.contactHelpdeskLink")}
                            />
                        </div>
                    </div>
                </Section>
            </div>
        );
    }
}

export const GeneralTab: React.ComponentClass = GeneralTabComponent;

import React from "react";
import { FoldableFC } from "components/Foldable/Foldable";
import "./FoldableSection.scss";

type FoldableSectionProps = {
    id: string;
    toggleButtonLabel: string | JSX.Element;
};

type FoldableSectionStateType = {
    isOpened: boolean;
};

export class FoldableSection extends React.Component<FoldableSectionProps, FoldableSectionStateType> {
    state: FoldableSectionStateType = {
        isOpened: false,
    };

    private toggleIsOpened = (): void => {
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        });
    };

    render(): JSX.Element {
        return (
            <div id={this.props.id} className="foldable-section">
                <div className="foldable-section__header-section">
                    <div role="heading" aria-level={2}>
                        {this.props.toggleButtonLabel}
                    </div>

                    <div aria-hidden="true">
                        <div className={`foldable-section__header-icon-wrapper${this.state.isOpened ? " foldable-section__header-icon-wrapper--is-opened" : ""}`} />
                    </div>

                    <button className="foldable-section__toggle-button" type="button" onClick={this.toggleIsOpened} data-test-id="toggle-foldable">
                        <span id={`${this.props.id}-Heading`} className="show-for-sr">
                            {this.state.isOpened ? "Bezárás:" : "Megnyitás:"} {this.props.toggleButtonLabel}
                        </span>
                    </button>
                </div>

                <FoldableFC ariaId={this.props.id} isOpened={this.state.isOpened}>
                    <div className="foldable-section__body">{this.props.children}</div>
                </FoldableFC>
            </div>
        );
    }
}

import { ApolloClient, ApolloQueryResult, MutationOptions, QueryOptions } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloReducerConfig, InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ErrorResponse, onError } from "apollo-link-error";
import { Env } from "utils/Env";
import { ApolloLink, ExecutionResult, GraphQLRequest, Observable, FetchResult } from "apollo-link";
import { store } from "store";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Log } from "utils/Log";
import { refreshToken, refreshTokenVariables } from "./types";
import { Mutations } from "./queries/Mutations";
import { AuthActions } from "actions/AuthActions";
import { Alert } from "components/Alert/Alert";
import { Intl } from "i18n/Intl";
import get from "lodash/get";
import { ObjectUtils } from "utils/ObjectUtils";
import { IntlHelpers } from "i18n/IntlHelpers";
import { GraphQLError, DocumentNode } from "graphql";
import { ServerParseError, ServerError } from "apollo-link-http-common";
import { uniqueId } from "lodash";

export type OnProgress = (progress: number) => any;

interface GraphQLFileUploadOptions<V> {
    mutation: DocumentNode;
    variables: V;
    file: File;
    onProgress?: OnProgress;
}

export class GraphQLClient {
    private static readonly httpLink: ApolloLink = createHttpLink({ uri: Env.graphqlApiUrl, credentials: "include" });

    private static readonly authLink: ApolloLink = setContext((_: GraphQLRequest, prevContext: any) => {
        const authToken: string | null = store.getState().auth.authToken;
        return {
            headers: {
                ...prevContext.headers,
                Authorization: authToken ? `Bearer ${authToken}` : "",
            },
        };
    });

    private static readonly operationNameToSkipRefreshToken: string[] = ["login", "refreshToken", "forgotPassword", "resetPassword", "activateAccount", "registration"];

    private static readonly errorLink: ApolloLink = onError((errorResponse: ErrorResponse) => {
        const refreshToken: string | null = store.getState().auth.refreshToken;
        if (errorResponse.graphQLErrors && refreshToken) {
            for (const graphQLError of errorResponse.graphQLErrors) {
                if (!graphQLError.extensions || GraphQLClient.operationNameToSkipRefreshToken.some((request: string) => errorResponse.operation.operationName === request)) {
                    continue;
                }

                const apiErrorCode: ApiErrorCode = GraphQLClient.getApiErrorCode(errorResponse);
                if (apiErrorCode === ApiErrorCode.ACCOUNT_DISABLED) {
                    store.dispatch(AuthActions.logout());
                    errorResponse.forward(errorResponse.operation);
                    Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(apiErrorCode)) });
                    continue;
                }

                if (apiErrorCode === ApiErrorCode.UNAUTHENTICATED) {
                    return new Observable((observer: ZenObservable.SubscriptionObserver<FetchResult<{ [key: string]: any }, Record<string, any>, Record<string, any>>>): void => {
                        GraphQLClient.mutate<refreshToken, refreshTokenVariables>({
                            mutation: Mutations.refreshToken,
                            variables: { refreshToken },
                        })
                            .then((response: refreshToken): void => {
                                const subscriber: ZenObservable.Observer<any> = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };

                                store.dispatch(AuthActions.updateAuthToken(response.refreshToken));
                                errorResponse.operation.setContext({
                                    headers: {
                                        ...errorResponse.operation.getContext().headers,
                                        Authorization: `Bearer ${response.refreshToken}`,
                                    },
                                });
                                errorResponse.forward(errorResponse.operation).subscribe(subscriber);
                            })
                            .catch((error: ApiError) => {
                                Log.debug("RefreshToken failed", error);
                                store.dispatch(AuthActions.logout());
                                observer.error(errorResponse);
                                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                            });
                    });
                }
            }
        }

        if (get(errorResponse.networkError, "response.status") === 401) {
            store.dispatch(AuthActions.logout());
            Alert.error({ title: Intl.formatMessage({ id: "error.api.UNAUTHENTICATED" }) });
        }

        return;
    });

    private static readonly fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData: require("./fragmentTypes.json") });

    private static dataIdFromObject(object: any): string {
        /**
         * https://jira.bigfish.hu/browse/AOSZ-473
         * ClientQuestionnaireAnswerOption id-s not unique, so we add title to generate unique key.
         */
        if (object.__typename === "ClientQuestionnaireAnswerOption") {
            return object.title + object.id;
        }
        if (!object.id) {
            return uniqueId();
        }
        return object.id;
    }

    private static client: ApolloClient<ApolloReducerConfig> = new ApolloClient({
        link: GraphQLClient.authLink.concat(GraphQLClient.errorLink).concat(GraphQLClient.httpLink),
        cache: new InMemoryCache({
            fragmentMatcher: GraphQLClient.fragmentMatcher,
            resultCaching: false,
            dataIdFromObject: GraphQLClient.dataIdFromObject,
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "network-only",
                errorPolicy: "all",
            },
        },
    });

    /**
     * GraphQLClient mutation
     * Throws error if response.data is empty
     * @param options MutationOptions<R, V>
     */
    public static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response: ExecutionResult<R> = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(GraphQLClient.getApiErrorCode(error));
        }
    }

    /**
     * GraphQLClient query
     * Throws error if response.data is empty
     * @param options QueryOptions<R>
     */
    public static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof ApiError) {
                throw error;
            }
            throw new ApiError(GraphQLClient.getApiErrorCode(error));
        }
    }

    private static getResult<R>(response: ApolloQueryResult<R> | ExecutionResult<R>): R {
        if (response.errors && response.errors.length > 0) {
            throw new ApiError(ApiErrorCode[GraphQLClient.getApiErrorCode({ graphQLErrors: response.errors })]);
        }

        if (!response.data) {
            throw new ApiError(ApiErrorCode.INVALID_RESPONSE);
        }

        return response.data;
    }

    /**
     * Get error code from ErrorResponse
     * @param error ErrorResponse
     */
    private static getApiErrorCode(error: { graphQLErrors?: ReadonlyArray<GraphQLError>; networkError?: Error | ServerError | ServerParseError }): ApiErrorCode {
        if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.code) {
            const errorCode: string = error.graphQLErrors[0].extensions.code;

            // Remove 'E_' to convert string to ApiErrorCode
            const code: string = errorCode.startsWith("E_") ? errorCode.substr(errorCode.indexOf("E_") + 2) : errorCode;

            if (ObjectUtils.isEnumContains<ApiErrorCode>(ApiErrorCode, code)) {
                return code;
            } else {
                Log.warning("Unknown error code from GraphQL response", code);
            }
        }
        if (error.networkError) {
            Log.debug("Network error occurred", error);
            return ApiErrorCode.NETWORK_ERROR;
        }
        Log.warning("Unknown error code from GraphQL response", error);
        return ApiErrorCode.UNKNOWN;
    }

    private static onProgress = (onProgressFunction: (progress: number) => any): ((this: XMLHttpRequest, ev: ProgressEvent) => any) => {
        return function (this: XMLHttpRequest, event: ProgressEvent): any {
            return onProgressFunction((event.loaded / event.total) * 100);
        };
    };

    public static upload<R, V>(options: GraphQLFileUploadOptions<V>): Promise<R> {
        return new Promise((resolve: (response: R) => void, reject: (error: Error) => void) => {
            const xhr = new XMLHttpRequest();
            const body = new FormData();
            if (!options.mutation.loc) {
                reject(new Error("options.mutation.loc not found!"));
            }
            body.append("operations", JSON.stringify({ query: options.mutation.loc!.source.body, variables: options.variables }));
            body.append("map", JSON.stringify({ 0: ["variables.file"] }));
            body.append("0", options.file);

            xhr.onerror = () => {
                reject(new ApiError(ApiErrorCode.NETWORK_ERROR));
            };

            xhr.ontimeout = () => {
                reject(new ApiError(ApiErrorCode.REQUEST_TIMEOUT));
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    try {
                        const response: { data: R; errors?: GraphQLError[] } = JSON.parse(xhr.response);
                        if (response.errors) {
                            reject(new ApiError(GraphQLClient.getApiErrorCode({ graphQLErrors: response.errors })));
                            return;
                        }
                        resolve(response.data);
                    } catch (error) {
                        reject(new ApiError(ApiErrorCode.INVALID_RESPONSE));
                    }
                }
            };

            if (!Env.graphqlApiUrl) {
                reject(new Error("Env.graphqlApiUrl not set!"));
                return;
            }

            xhr.open("POST", Env.graphqlApiUrl, true);
            xhr.setRequestHeader("Authorization", `Bearer ${store.getState().auth.authToken || ""}`);
            xhr.setRequestHeader("Accept", "*/*");

            if (options.onProgress) {
                xhr.upload.onprogress = GraphQLClient.onProgress(options.onProgress);
            }

            xhr.send(body);
        });
    }
}

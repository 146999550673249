import React, { Component } from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Intl } from "i18n/Intl";
import {
    AssetContent,
    AudioEverydaySituation,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result as EverydaySituationDirectory,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations as EverydaySituation,
    ImageEverydaySituation,
    TextEverydaySituation,
} from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Formatter } from "utils/Formatter";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { connect, DispatchProp } from "react-redux";
import { DirectoryImage } from "./DirectoryImage";
import { ImageSrc } from "utils/ImageSrc";
import { EverydaySituationListItem } from "./EverydaySituationListItem";
import { Select } from "components/Inputs/Select/Select";
import { EverydaySituationTypeOptions } from "models/EverydaySituationTypeOptions";
import { ApiTypes } from "api/ApiTypes";
import { ContentOptionMenu } from "pages/_shared/ContentOptionMenu/ContentOptionMenu";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { Page } from "components/Page";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import "./ClientEverydaySituationDirectoryPage.scss";

interface RouteProps {
    clientExtId: string;
    directoryId: string;
}

type Props = RouteComponentProps<RouteProps> & DispatchProp;

interface State {
    everydaySituationDirectory: EverydaySituationDirectory | null;
    isLoading: boolean;
}

class ClientEverydaySituationDirectoryPageComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            everydaySituationDirectory: null,
            isLoading: true,
        };
    }

    componentDidMount(): void {
        this.fetchDirectory();
    }

    private readonly fetchDirectory = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const { clientExtId, directoryId } = this.props.match.params;
                    const everydaySituationDirectories = await Api.getClientEverydaySituationDirectoryList(clientExtId);
                    const everydaySituationDirectory = everydaySituationDirectories.result.find(directoryItem => directoryItem.id === directoryId) || null;
                    this.setState({
                        everydaySituationDirectory,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly onToggled = (content: EverydaySituationDirectory | EverydaySituation): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                content: content,
                onToggled: () => {
                    this.fetchDirectory();
                },
            }),
        );
    };

    private readonly renderContent = (): React.ReactElement => {
        const { everydaySituationDirectory } = this.state;
        return (
            <div className="content-container">
                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituationDirectory.form.title" })}>
                    <Input type="text" value={everydaySituationDirectory?.title} disabled={true} />
                </InputWrapper>
                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituationDirectory.form.image" })}>
                    <DirectoryImage asset={everydaySituationDirectory?.image} className="client-everyday-situation-directory-page-directory-image" fallback={ImageSrc.asset} />
                </InputWrapper>
                <hr className="mb-5" />
                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituationDirectory.form.items" })} />
                {(everydaySituationDirectory?.situations || []).map(
                    (situation: EverydaySituation): React.ReactElement => {
                        return (
                            <EverydaySituationListItem
                                key={situation.id}
                                formValues={{
                                    id: situation.id,
                                    title: situation.title,
                                    type: Select.getSelectOption(EverydaySituationTypeOptions.get(), ApiTypes.getEverydaySituationType(situation)),
                                    image: ((situation as ImageEverydaySituation).image as AssetContent) || null,
                                    description: (situation as TextEverydaySituation).description || undefined,
                                    audio: ((situation as AudioEverydaySituation).audio as AssetContent) || null,
                                    isDisabled: !!situation.disabledAt,
                                }}
                                isEditable={false}
                                updateFormValues={() => {
                                    return;
                                }}
                                customActions={() => {
                                    return (
                                        <ContentOptionMenu
                                            pageContent={PageContent.clientDetail}
                                            content={situation}
                                            clientExtId={this.props.match.params.clientExtId}
                                            onToggled={() => this.onToggled(situation)}
                                        />
                                    );
                                }}
                            />
                        );
                    },
                )}
            </div>
        );
    };

    private readonly renderButtons = (): React.ReactElement | null => {
        const { everydaySituationDirectory } = this.state;
        if (everydaySituationDirectory) {
            return <ContentOptionMenu pageContent={PageContent.clientDetail} content={everydaySituationDirectory} onToggled={() => this.onToggled(everydaySituationDirectory)} />;
        }
        return null;
    };

    public render(): React.ReactElement {
        const { isLoading, everydaySituationDirectory } = this.state;
        if ((!isLoading && !everydaySituationDirectory) || !this.props.match.params.clientExtId || !this.props.match.params.directoryId) {
            return <Redirect to={Path.dashboard} />;
        }
        return (
            <Page
                title={Intl.formatMessage({ id: "page.clientEverydaySituationDirectory.title" }, { extId: Formatter.formatExtId(this.props.match.params.clientExtId) })}
                renderButtons={this.renderButtons}
            >
                <div className="left-side client-everyday-situation-directory-page">{isLoading ? <Loading type={LoadingType.layer} /> : this.renderContent()}</div>
            </Page>
        );
    }
}

export const ClientEverydaySituationDirectoryPage = withRouter(connect()(ClientEverydaySituationDirectoryPageComponent));

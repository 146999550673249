import React, { Component } from "react";
import { Config } from "react-player";
import { Player } from "components/Player/Player";
interface Props {
    url: string;
    config: Config;
    onClick?: () => void;
}
export class VideoPlayer extends Component<Props> {
    public render(): JSX.Element {
        return (
            <div>
                <Player className="react-player" playing={false} controls={true} url={this.props.url} width="inherit" height="inherit" config={this.props.config} />
            </div>
        );
    }
}

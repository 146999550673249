import React, { Component } from "react";
import { ClientQuestionnaireQuestion, Asset, SupportedClient } from "api/graphql/types";
import { Api } from "api/Api";
import { AddClientQuestionnaireForm } from "./AddClientQuestionnaireForm";
import { AddClientPersonalDataForm } from "./AddClientPersonalDataForm";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { withRouter, RouteComponentProps } from "react-router";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ClientQuestionnaireAnswer, ApiTypes } from "api/ApiTypes";
import { Intl } from "i18n/Intl";
import { connect, DispatchProp } from "react-redux";
import { AccountActions } from "actions/AccountActions";
import { TestId } from "utils/TestId";
import { Page } from "components/Page";
import { Prompt } from "components/Prompt";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

type Props = RouteComponentProps & DispatchProp;

interface State {
    showQuestionnaire: boolean;
    isLoading: boolean;

    name: string;
    dateOfBirth: Date | null;
    introduction: string;
    statementOfSupport: boolean;
    isSelfSupporting: boolean;
    avatar: File | null;

    clientQuestionnaireAnswers: ClientQuestionnaireAnswer[];
    questions: ClientQuestionnaireQuestion[];

    client: SupportedClient | null;
}

class AddClientPageComponent extends Component<Props, State> {
    public readonly state: State = {
        showQuestionnaire: false,
        isLoading: true,

        name: "",
        dateOfBirth: null,
        introduction: "",
        statementOfSupport: false,
        isSelfSupporting: false,
        avatar: null,

        clientQuestionnaireAnswers: [],
        questions: [],

        client: null,
    };

    public componentDidMount(): void {
        this.refreshQuestions();
    }

    private refreshQuestions = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const questions: ClientQuestionnaireQuestion[] = await Api.getClientQuestionnaire();
                    const answers: ClientQuestionnaireAnswer[] = questions.map(
                        (question: ClientQuestionnaireQuestion): ClientQuestionnaireAnswer => ({ questionId: question.id, answerOptionId: null }),
                    );
                    this.setState({ questions, clientQuestionnaireAnswers: answers, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    private onNextClick = (name: string, dateOfBirth: Date | null, isSelfSupporting: boolean, statementOfSupport: boolean): void => {
        this.setState({ name, dateOfBirth, isSelfSupporting, statementOfSupport, showQuestionnaire: true });
    };

    private onPrevClick = (clientQuestionnaireAnswers: ClientQuestionnaireAnswer[]): void => {
        this.setState({ showQuestionnaire: false, clientQuestionnaireAnswers });
    };

    private onAddClick = (clientQuestionnaireAnswers: ClientQuestionnaireAnswer[]): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (!ApiTypes.isClientQuestionnaireAnswerInputs(clientQuestionnaireAnswers)) {
                        Alert.error({ title: Intl.formatMessage({ id: "page.addClient.questionsNotAnsweredError" }) });
                        this.setState({ isLoading: false });
                        return;
                    }

                    const { name, dateOfBirth, avatar, isSelfSupporting } = this.state;
                    const client: SupportedClient = await Api.createClient(name, DateUtils.format(dateOfBirth!, DateFormat.default), clientQuestionnaireAnswers, isSelfSupporting);
                    if (avatar) {
                        try {
                            const asset: Asset = await Api.uploadClientAvatar(client.id, avatar);
                            this.props.dispatch(AccountActions.addClient({ ...client, avatar: asset }));
                        } catch (error) {
                            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                        }
                    } else {
                        this.props.dispatch(AccountActions.addClient(client));
                    }

                    this.props.dispatch(
                        DialogsActions.show({
                            type: DialogType.clientQuestionnaireInfo,
                            title: Intl.formatMessage({ id: "page.addClient.title" }),
                            buttonLabel: Intl.formatMessage({ id: "page.addClient.manage" }),
                            description: client.notificationAfterCreate.replace("[CLIENT_NAME]", client.name),
                            onClose: () => {
                                this.props.history.push(Path.clientProfile(client.extId!));
                            },
                        }),
                    );

                    this.setState({ client, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private renderContent = (): React.ReactElement<any> => {
        const { showQuestionnaire, clientQuestionnaireAnswers, questions, name, client, dateOfBirth, introduction, statementOfSupport, avatar, isSelfSupporting } = this.state;

        if (showQuestionnaire || client) {
            return <AddClientQuestionnaireForm questions={questions} clientQuestionnaireAnswers={clientQuestionnaireAnswers} onPrevClick={this.onPrevClick} onAddClick={this.onAddClick} />;
        }
        return (
            <AddClientPersonalDataForm
                name={name}
                dateOfBirth={dateOfBirth}
                introduction={introduction}
                statementOfSupport={statementOfSupport}
                isSelfSupporting={isSelfSupporting}
                avatar={avatar}
                onNextClick={this.onNextClick}
            />
        );
    };

    public render(): React.ReactElement<any> {
        const { isLoading } = this.state;
        if (isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <Page id={TestId.addClient.container} title={Intl.formatMessage({ id: "page.addClient.title" })}>
                {this.renderContent()}
                <Prompt when={!!this.state.name} pathsToSkip={this.state.client ? [Path.clientProfile(this.state.client.extId)] : []} />
            </Page>
        );
    }
}

export const AddClientPage = connect()(withRouter(AddClientPageComponent));

export default AddClientPage;

import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

interface Icon {
    name: string;
    position?: "left" | "right";
    large?: boolean;
}

interface Props {
    id?: string;
    innerRef?: (ref: HTMLButtonElement | null) => void;
    ariaLabel?: string;
    label?: string;
    disabled?: boolean | false;
    href?: string;
    icon?: Icon;
    link?: boolean;
    hollow?: boolean;
    white?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    type?: "reset" | "submit" | "button";
    tabIndex?: number;
    "data-tip"?: string;
    "data-place"?: string;
    className?: string;
    title?: string;
    renderIcon?: () => React.ReactElement<any>;
}

class Button extends React.PureComponent<Props> {
    private getClassName = (): string => {
        const className: string = this.props.className || "";
        const type: string = this.props.link ? " btn-link" : this.props.className ? "" : " btn-primary";
        const largeIcon: string = this.props.icon && this.props.icon.large ? " large-icon" : "";
        const hollow: string = this.props.hollow ? " btn-outline" : "";
        const white: string = this.props.white ? " btn-white" : "";
        const disabled: string = this.props.disabled ? " disabled" : "";

        return `btn ${className}${type}${largeIcon}${hollow}${white}${disabled}`;
    };

    private renderButtonIcon = (icon: Icon): React.ReactElement<any> | undefined => {
        return <span className={`fa ${icon.name}${icon.position ? ` ${icon.position}` : ""}`} aria-hidden={true} title={`${icon.large ? this.props.title || this.props.ariaLabel : ""}`}></span>;
    };

    public render(): React.ReactElement<any> {
        const { id, innerRef, onClick, label, disabled, type, tabIndex, hollow, ariaLabel, href, icon, link, white, className, title, renderIcon, ...otherProps } = this.props;

        if (href) {
            <Link id={id} className={this.getClassName()} to={href}>
                {icon && icon.position === "left" && <span className={`fa ${icon.name} left`} aria-hidden={true}></span>}
                <span aria-label={ariaLabel || title}>{label}</span>
                {icon && icon.position !== "left" && <span className={`fa ${icon.name} right`} aria-hidden={true}></span>}
            </Link>;
        }

        return (
            <button
                id={id}
                ref={innerRef}
                onClick={onClick}
                aria-label={ariaLabel || title}
                disabled={disabled}
                title={title}
                type={type || "button"}
                tabIndex={tabIndex}
                className={this.getClassName()}
                {...otherProps}
            >
                {renderIcon ? (
                    renderIcon()
                ) : (
                    <>
                        {icon && icon.position === "left" && this.renderButtonIcon(icon)}
                        {label}
                        {icon && icon.position !== "left" && this.renderButtonIcon(icon)}
                    </>
                )}
            </button>
        );
    }
}

export { Button };

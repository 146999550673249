import React, { Component } from "react";
import { Flowchart, FlowchartInput, SupportedClient, AccountType, Account, ContentClient } from "api/graphql/types";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { FlowchartForm } from "./FlowchartForm";
import { PageType } from "utils/TypeUtils";
import { ApiTypes } from "api/ApiTypes";
import { AccountSelectors } from "selectors/AccountSelectors";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

interface RouteParams {
    flowchartId?: string;
    clientExtId?: string;
    supervisorId?: string;
    supporterExtId?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams>;

interface State {
    flowchart: Flowchart;
    isLoading: boolean;
}

class FlowchartPageComponent extends Component<Props, State> {
    public static getInitialStateFromProps(props: Props): State {
        return {
            isLoading: !!props.match.params.flowchartId,
            flowchart: {
                id: "",
                title: "",
                description: "",
                client: (props.client as unknown) as ContentClient,
                image: null,
                itemList: [],
                __typename: "Flowchart",
                createdBy: { id: props.account.id, name: "", extId: props.account.extId, __typename: "Account" },
                usableInSortingGame: false,
                disabledAt: null,
                createdAt: null,
                updatedAt: null,
                publishedAt: null,
                tags: [],
                shareRequests: null,
            },
        };
    }

    public readonly state: State = FlowchartPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshFlowchart(this.props);
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.flowchartId !== nextProps.match.params.flowchartId) {
            this.refreshFlowchart(nextProps);
        }
    }

    private refreshFlowchart = (props: Props): void => {
        const { flowchartId } = props.match.params;
        if (!flowchartId) {
            this.setState(FlowchartPageComponent.getInitialStateFromProps(props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const flowchart: Flowchart = await Api.getFlowchartById(flowchartId);
                    this.setState({ isLoading: false, flowchart });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private onSubmit = async (flowchart: Flowchart): Promise<Flowchart> => {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const flowchartInput: FlowchartInput = ApiTypes.flowchartToFlowchartInput(flowchart);
        if (pageType === PageType.create) {
            const result = await Api.createFlowchart(flowchartInput);
            return result;
        } else if (pageType === PageType.edit) {
            const result = await Api.updateFlowchart(flowchart.id, flowchartInput);
            this.setState({ flowchart: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    private onEditClick = (): void => {
        const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, !!this.state.flowchart.publishedAt);
        const path: string | undefined = ContentPageUtils.getEditPath(this.state.flowchart, pageContent, this.props.match.params.clientExtId);
        if (path) {
            this.props.history.push(path);
        }
    };

    private onToggled = (): void => {
        const flowchart = { ...this.state.flowchart };
        flowchart.disabledAt = flowchart.disabledAt ? null : DateUtils.format(new Date(), DateFormat.default);
        this.setState({ flowchart });
    };

    public render(): React.ReactElement<any> {
        if (this.props.match.params.clientExtId && this.props.account.accountType === AccountType.supporter && !this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        const { flowchart, isLoading } = this.state;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        return (
            <FlowchartForm
                myAccount={this.props.account}
                client={this.props.client}
                clientExtId={this.props.match.params.clientExtId}
                supporterExtId={this.props.match.params.supporterExtId}
                supervisorId={this.props.match.params.supervisorId}
                pageContent={ContentPageUtils.getPageContent(this.props.match.path, !!this.state.flowchart.publishedAt)}
                flowchart={flowchart}
                isLoading={isLoading}
                pageType={pageType}
                onSubmit={this.onSubmit}
                onEditClick={this.onEditClick}
                onToggled={this.onToggled}
                accountType={this.props.account.accountType}
                refreshFlowchart={(): void => this.refreshFlowchart(this.props)}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return { account: state.account!, client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId) };
};

export const FlowchartPage = withRouter(connect(mapStateToProps)(FlowchartPageComponent));

// tslint:disable-next-line: no-default-export
export default FlowchartPage;

import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { TabPanel } from "components/TabPanel/TabPanel";
import { Path } from "utils/Path";
import { GeneralTab } from "./GeneralTab";
import "./EducationPage.scss";
import { Loading } from "components/Loading/Loading";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { EducationContentModuleTab } from "api/graphql/types";
import { ModuleTab } from "./ModuleTab";
import { orderBy } from "lodash";
interface State {
    tabs: EducationContentModuleTab[];
    isLoading: boolean;
}

class EducationPageComponent extends Component<{}, State> {
    public readonly state: Readonly<State> = {
        tabs: [],
        isLoading: false,
    };

    public componentDidMount(): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const tabs: EducationContentModuleTab[] = await Api.getEducationModuleTabs();
                    const orderedTabs: EducationContentModuleTab[] = orderBy(tabs, "position", "asc");
                    this.setState({ tabs: orderedTabs, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ tabs: [], isLoading: false });
                }
            },
        );
    }

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `page.education.${id}` });
    };

    private getTabs = () => {
        const tabs = this.state.tabs.map((tab: EducationContentModuleTab) => {
            const labelText: string = Intl.formatMessage({ id: "page.education.moduleTabTitle" }, { position: tab.position });
            return {
                id: labelText,
                label: (
                    <div className="module-tab-title">
                        <span>{labelText}</span>
                        <br />
                        <span>{tab.title}</span>
                    </div>
                ),
                to: Path.educationModuleTab(tab.id),
                component: ModuleTab,
            };
        });

        return [
            {
                id: this.localTranslation("tab.general.title"),
                label: this.localTranslation("tab.general.title"),
                to: Path.educationGeneralTab,
                component: GeneralTab,
            },
            ...tabs,
        ];
    };

    public render(): React.ReactElement {
        return (
            <Page title={this.localTranslation("title")} hasTabs={true}>
                <div className="left-side education-page">{this.state.isLoading ? <Loading /> : <TabPanel tabs={this.getTabs()} />}</div>
            </Page>
        );
    }
}

export const EducationPage: React.ComponentClass<{}, State> = EducationPageComponent;

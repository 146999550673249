import React, { Component } from "react";
import { Asset, SupportedClient, Account, Award, AwardInput } from "api/graphql/types";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils } from "pages/_shared/ContentPageUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { isNil } from "lodash";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Log } from "utils/Log";
import { ClientAwardForm } from "./ClientAwardForm";

interface RouteParams {
    clientExtId?: string;
    clientAwardId?: string;
}

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

type ComponentProps = RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

type Props = ComponentProps;

export interface LocalClientAwardInput {
    title: string;
    image?: Asset | null;
    targetScore: number | null;
    autoReactivation: boolean;
}

interface State {
    isLoading: boolean;
    clientAward: LocalClientAwardInput;
}

class ClientAwardPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.clientAwardId),
            clientAward: {
                title: "",
                image: null,
                targetScore: 0,
                autoReactivation: false,
            },
        };
    };

    public readonly state: State = ClientAwardPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshClientAward();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.client !== this.props.client || prevProps.match.params.clientAwardId !== this.props.match.params.clientAwardId) {
            this.refreshClientAward();
        }
    }

    private refreshClientAward = (): void => {
        const { clientAwardId } = this.props.match.params;
        if (isNil(this.props.client) || !clientAwardId) {
            this.setState(ClientAwardPageComponent.getInitialStateFromProps(this.props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientAward: Award = await Api.getClientAwardById(this.props.client!.id, clientAwardId);
                    this.setState({
                        isLoading: false,
                        clientAward: {
                            title: clientAward.title,
                            image: clientAward.image,
                            autoReactivation: clientAward.autoReactivation,
                            targetScore: clientAward.targetScore,
                        },
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private getClientAwardInput = (clientAward: LocalClientAwardInput): AwardInput => {
        return {
            title: clientAward.title || "",
            imageId: clientAward.image?.id || null,
            targetScore: clientAward.targetScore || 0,
            autoReactivation: clientAward.autoReactivation || false,
        };
    };

    private onSubmit = async (clientAward: LocalClientAwardInput): Promise<Award> => {
        const clientAwardId: string | undefined = this.props.match.params.clientAwardId;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);

        if (pageType === PageType.create) {
            const result = await Api.createClientAward(this.props.client!.id, this.getClientAwardInput(clientAward));
            this.setState({ clientAward: result });
            return result;
        } else if (pageType === PageType.edit && !isNil(clientAwardId)) {
            const result = await Api.updateClientAward(clientAwardId, this.getClientAwardInput(clientAward));
            this.setState({ clientAward: result });
            return result;
        } else {
            Log.warning("View cannot submit (ClientAwardPage)!");
            throw new ApiError(ApiErrorCode.UNKNOWN);
        }
    };

    public render(): React.ReactElement {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const isClientAwardIdPresentInEditMode: boolean = pageType === PageType.create || (pageType === PageType.edit && !isNil(this.props.match.params.clientAwardId));

        if (!this.props.client || !isClientAwardIdPresentInEditMode) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <ClientAwardForm
                pageType={pageType}
                accountId={this.props.account.id}
                clientName={this.props.client.name}
                clientExtId={this.props.client.extId}
                clientAward={this.state.clientAward}
                onSubmit={this.onSubmit}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        account: state.account!,
        client,
    };
};

export const ClientAwardPage = withRouter(connect(mapStateToProps)(ClientAwardPageComponent));

export default ClientAwardPage;

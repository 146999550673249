import React, { Component } from "react";
import { SupportedClient, Account, EverydaySituationDirectory } from "api/graphql/types";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path, AppPath } from "utils/Path";
import { Api } from "api/Api";
import { AccountSelectors } from "selectors/AccountSelectors";
import { PageType } from "utils/TypeUtils";
import { ClientEverydaySituationsDirectoryForm, FormValues } from "./ClientEverydaySituationsDirectoryForm";
import isNil from "lodash/isNil";
import { EverydaySituationFormValues } from "pages/ClientEverydaySituationsPage/EverydaySituationListItem";
import { Intl } from "../../i18n/Intl";

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

interface RouteParams {
    directoryId?: string;
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    everydaySituationDirectory: FormValues;
    isLoading: boolean;
    isPromptDisabled: boolean;
}

class ClientEverydaySituationsDirectoryPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.directoryId),
            everydaySituationDirectory: {
                id: null,
                title: "",
                image: null,
                position: null,
                situations: [],
            },
            isPromptDisabled: false,
        };
    };

    public readonly state: State = ClientEverydaySituationsDirectoryPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshEverydaySituationsDirectory();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.match.params.directoryId !== prevProps.match.params.directoryId) {
            this.refreshEverydaySituationsDirectory();
        }
    }

    private refreshEverydaySituationsDirectory = (): void => {
        const { directoryId } = this.props.match.params;
        if (!directoryId) {
            this.setState(ClientEverydaySituationsDirectoryPageComponent.getInitialStateFromProps(this.props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const everydaySituationDirectory = await Api.getEverydaySituationDirectoryById(directoryId);
                    this.setState({ isLoading: false, everydaySituationDirectory });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            if (this.props.client) {
                                this.props.history.push(Path.clientEverydaySituationsDirectories(this.props.client.extId));
                            } else {
                                this.props.history.push(Path.dashboard);
                            }
                        },
                    });
                    return;
                }
            },
        );
    };

    public onSubmit = (formValues: FormValues, situations: EverydaySituationFormValues[], situationsToDelete: EverydaySituationFormValues[]): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    let result: EverydaySituationDirectory | null = null;
                    if (formValues.id) {
                        result = await Api.updateEverydaySituationDirectory({
                            id: formValues.id,
                            imageId: formValues.image!.id,
                            title: formValues.title,
                        });
                    } else {
                        result = await Api.createEverydaySituationDirectory({
                            clientId: this.props.client!.id,
                            imageId: formValues.image!.id,
                            title: formValues.title,
                        });
                    }

                    const createsAndUpdates = situations.map(
                        (item: EverydaySituationFormValues): Promise<any> => {
                            if (item.id) {
                                return Api.updateEverydaySituation({
                                    id: item.id,
                                    assetId: item.image?.id || item.audio?.id,
                                    title: item.title,
                                    description: item.description,
                                    directoryId: result!.id,
                                });
                            }
                            return Api.createEverydaySituation({
                                title: item.title,
                                description: item.description || undefined,
                                assetId: item.image?.id || item.audio?.id,
                                situationType: item.type.value,
                                directoryId: result!.id,
                            });
                        },
                    );

                    const deletes = situationsToDelete.map((item: EverydaySituationFormValues) => {
                        return Api.deleteEverydaySituation(item.id!);
                    });
                    await Promise.all([...createsAndUpdates, ...deletes]);
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientEverydaySituations.directories.saveSucceed" }) });
                    this.setState({ everydaySituationDirectory: result, isPromptDisabled: true }, () => {
                        this.props.history.push(Path.clientEverydaySituationsDirectories(this.props.client!.extId));
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.refreshEverydaySituationsDirectory();
                }
            },
        );
    };

    private readonly onCancel = () => {
        this.props.history.push(Path.clientEverydaySituationsDirectories(this.props.client!.extId));
    };

    public render(): React.ReactElement<any> | null {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        const pageType: PageType = this.props.match.path === AppPath.createClientEverydaySituationsDirectory ? PageType.create : PageType.edit;
        return (
            <ClientEverydaySituationsDirectoryForm
                myAccount={this.props.account}
                client={this.props.client}
                isLoading={this.state.isLoading}
                formValues={this.state.everydaySituationDirectory}
                pageType={pageType}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                isPromptDisabled={this.state.isPromptDisabled}
                refreshEverydaySituations={this.refreshEverydaySituationsDirectory}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    return {
        account: state.account!,
        client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId),
    };
};

export const ClientEverydaySituationsDirectoryPage = withRouter(connect(mapStateToProps)(ClientEverydaySituationsDirectoryPageComponent));

export default ClientEverydaySituationsDirectoryPage;

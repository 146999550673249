import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { ClientProfileData } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteChangeClientManagerDialogProps {
    client: ClientProfileData;
    onDeleteChangeClientManagerFinished: () => void;
}

type Props = DeleteChangeClientManagerDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class DeleteChangeClientManagerDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private deleteChangeClientManagerRequest = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (this.props.client) {
                        await Api.deleteClientChangeManagerRequest(this.props.client.pendingClientManagerChangeRequest!.id);
                    }
                    this.setState({ isLoading: false }, this.props.onDeleteChangeClientManagerFinished);
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientProfile.supporterList.deleteClientChangeManagerRequest.succeed" }) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
                this.props.onHide();
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "page.clientProfile.supporterList.deleteClientChangeManagerRequest.confirm.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <form>
                    <p>{Intl.formatMessage({ id: "page.clientProfile.supporterList.deleteClientChangeManagerRequest.confirm.description" })}</p>
                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: "page.clientProfile.supporterList.deleteClientChangeManagerRequest.confirm.button" })}
                                onClick={this.deleteChangeClientManagerRequest}
                                disabled={this.state.isLoading}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const DeleteChangeClientManagerDialog: React.ComponentClass<Props> = DeleteChangeClientManagerDialogComponent;

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";

enum ShareType {
    publish = "publish",
    custom = "custom",
}

type ShareTypeOption = SelectOption<ShareType>;

class ShareTypeOptions {
    public static get(): ShareTypeOption[] {
        return ObjectUtils.enumAsArray<ShareType>(ShareType).map(
            (shareType: ShareType): ShareTypeOption => {
                return { id: shareType, label: Intl.formatMessage({ id: `enum.shareType.${shareType}` }), value: shareType };
            },
        );
    }
}

export { ShareType, ShareTypeOptions };
export type { ShareTypeOption };

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Section } from "components/Section";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Account } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Button } from "components/Button/Button";
import { AccountDetailStatusForm } from "pages/_shared/AccountDetails/AccountDetailStatusForm";
import { Select } from "components/Inputs/Select/Select";
import { AccountTypeSelectOptions } from "models/AccountTypeSelectOptions";
import { DispatchProp, connect } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface Params {
    accountId: string;
}

type Props = RouteComponentProps<Params> & DispatchProp;

interface State {
    isLoading: boolean;
    account: Account | null;
}

class SupervisorDetailProfileTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        account: null,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const account: Account = await Api.getAccountById(props.match.params.accountId);
                    this.setState({ account, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.supervisorList);
                        },
                    });
                }
            },
        );
    }

    private deleteAccount = async (): Promise<void> => {
        try {
            await Api.deleteAccount(this.props.match.params.accountId);
            Alert.success({
                title: Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.accountData.delete.deleteSucceed" }),
                callback: (): void => {
                    this.props.history.push(Path.supervisorList);
                },
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> | null {
        if (this.state.isLoading && !this.state.account) {
            return <Loading type={LoadingType.layer} />;
        }

        const { account } = this.state;
        if (!account) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.personalData.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.personalData.nameForm.name.label" })}>
                        <Input placeholder={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.personalData.nameForm.name.placeholder" })} type="name" value={account.name} disabled={true} />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.personalData.emailForm.email.label" })}>
                        <Input
                            placeholder={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.personalData.emailForm.email.placeholder" })}
                            type="email"
                            value={account.email}
                            disabled={true}
                        />
                    </InputWrapper>
                </Section>

                <Section label={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.accountData.title" })}>
                    <div className="input-wrapper grid-x">
                        <span className="input-label cell">{Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.accountData.delete.label" })}</span>
                        <span className="cell auto">
                            <strong className="value-cell">&nbsp;</strong>
                        </span>
                        <span className="cell button-cell">
                            <Button
                                className="btn-danger btn--text-large fw-700"
                                label={Intl.formatMessage({ id: "common.delete" })}
                                ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                onClick={(): void => {
                                    this.props.dispatch(
                                        DialogsActions.show({
                                            type: DialogType.deleteAccountConfirm,
                                            onConfirmed: this.deleteAccount,
                                        }),
                                    );
                                }}
                            />
                        </span>
                    </div>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.accountData.accountType.label" })}>
                        <Select
                            options={AccountTypeSelectOptions.get()}
                            onChange={(): void => {}}
                            value={Select.getSelectOption(AccountTypeSelectOptions.get(), account.accountType)}
                            disabled={true}
                        />
                    </InputWrapper>

                    <AccountDetailStatusForm account={account} onSaveSucceed={(): void => this.refreshAccount(this.props)} />
                </Section>
            </div>
        );
    }
}

export const SupervisorDetailProfileTab = withRouter(connect()(SupervisorDetailProfileTabComponent));

import {
    AssetType,
    DisabledPublicContentList_result,
    DisabledPublicContentList_result_Agenda as DisabledAgenda,
    DisabledPublicContentList_result_AgendaItem as DisabledAgendaItem,
    DisabledPublicContentList_result_Asset as DisabledAsset,
    DisabledPublicContentList_result_AudioEverydaySituation as DisabledAudioEverydaySituation,
    DisabledPublicContentList_result_Award as DisabledAward,
    DisabledPublicContentList_result_EverydaySituationDirectory as DisabledEverydaySituationDirectory,
    DisabledPublicContentList_result_Flowchart as DisabledFlowchart,
    DisabledPublicContentList_result_FlowchartItem as DisabledFlowchartItem,
    DisabledPublicContentList_result_ImageEverydaySituation as DisabledImageEverydaySituation,
    DisabledPublicContentList_result_InstantAward as DisabledInstantAward,
    DisabledPublicContentList_result_SelectionBoard as DisabledSelectionBoard,
    DisabledPublicContentList_result_TextAndImageEverydaySituation as DisabledTextAndImageEverydaySituation,
    DisabledPublicContentList_result_TextEverydaySituation as DisabledTextEverydaySituation,
    AgendaListItem,
    AgendaItemListItem,
    FlowchartListItem,
    FlowchartItemListItem,
    AssetListItem,
    EverydaySituation,
    InstantAwardListItem,
    AwardListItem,
    SelectionBoardListItem,
    AssetContent,
    Asset,
    EverydaySituationDirectory,
    ImageEverydaySituation,
    AtomicAsset,
    SelectionBoard,
} from "../api/graphql/types";
import { ImageSrc } from "./ImageSrc";
import { uniqBy } from "lodash";

export type AnyDisabledItemType =
    | DisabledAgenda
    | DisabledAgendaItem
    | DisabledFlowchart
    | DisabledFlowchartItem
    | DisabledAsset
    | DisabledAward
    | DisabledInstantAward
    | DisabledTextEverydaySituation
    | DisabledTextAndImageEverydaySituation
    | DisabledImageEverydaySituation
    | DisabledAudioEverydaySituation
    | DisabledEverydaySituationDirectory
    | DisabledSelectionBoard;

export type DisabledItemType =
    | "Agenda"
    | "AgendaItem"
    | "Flowchart"
    | "FlowchartItem"
    | "Asset"
    | "Award"
    | "InstantAward"
    | "TextEverydaySituation"
    | "TextAndImageEverydaySituation"
    | "ImageEverydaySituation"
    | "AudioEverydaySituation"
    | "EverydaySituationDirectory"
    | "SelectionBoard";

export interface DisabledItem {
    id: string;
    clientExtId?: string;
    directoryId?: string;
    thumbnail: string;
    url?: string;
    title: string;
    type: DisabledItemType;
    assetType?: AssetType | null;
    createdAt: string;
}

export const DisabledItemTypesWithoutView: DisabledItemType[] = ["Award", "InstantAward"];

class DisabledItemUtils {
    public static getRelatedAssets(
        content:
            | AgendaListItem
            | AgendaItemListItem
            | FlowchartListItem
            | FlowchartItemListItem
            | Asset
            | AssetListItem
            | EverydaySituationDirectory
            | EverydaySituation
            | InstantAwardListItem
            | AwardListItem
            | SelectionBoardListItem
            | SelectionBoard,
    ): AssetContent[] {
        let assets: Array<AssetContent | AtomicAsset | null> = [];
        switch (content.__typename) {
            case "Agenda":
                break;
            case "AgendaItem":
                assets = [content.image, content.audio, content.video];
                break;
            case "Flowchart":
                assets = [content.image];
                break;
            case "FlowchartItem":
                assets = [content.image, content.audio, content.video];
                break;
            case "Award":
                assets = [content.image];
                break;
            case "InstantAward":
                assets = [content.image];
                break;
            case "SelectionBoard":
                assets = [content.image, ...content.items.map(c => c.image)];
                break;
            case "EverydaySituationDirectory":
                const situations = content.situations.filter((s: EverydaySituation): s is ImageEverydaySituation => {
                    return s.__typename === "ImageEverydaySituation" || s.__typename === "TextAndImageEverydaySituation";
                });
                assets = [content.image, ...situations.map(s => s.image)];
                break;
            default:
                break;
        }

        return uniqBy(
            assets.filter((asset?: AssetContent | AtomicAsset | null): asset is AssetContent => !!asset),
            "id",
        );
    }

    public static getDisabledItems(disabledItems: DisabledPublicContentList_result[]): DisabledItem[] {
        return disabledItems.map(
            (disabledItem: DisabledPublicContentList_result): DisabledItem => {
                return DisabledItemUtils.setDisabledItem(disabledItem);
            },
        );
    }

    private static setDisabledItem(item: AnyDisabledItemType): DisabledItem {
        let clientExtId: string | undefined;
        let directoryId: string | undefined;
        let thumbnail: string;
        let asset: { url: string; thumbnailUrl: string } | null = null;
        let assetType: AssetType | null = null;
        switch (item.__typename) {
            case "Agenda":
                thumbnail = ImageSrc.agenda;
                clientExtId = item.agendaClient?.extId;
                break;
            case "AgendaItem":
                asset = item.agendaItemImage || item.agendaItemVideo;
                assetType = item.agendaItemVideo ? AssetType.video : AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.agendaItem;
                clientExtId = item.agendaItemClient?.extId;
                break;
            case "Flowchart":
                asset = item.flowchartImage;
                assetType = AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.flowchart;
                clientExtId = item.flowchartClient?.extId;
                break;
            case "FlowchartItem":
                asset = item.flowchartItemImage || item.flowchartItemVideo;
                assetType = item.flowchartItemVideo ? AssetType.video : AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.flowchartItem;
                clientExtId = item.flowchartItemClient?.extId;
                break;
            case "Asset":
                asset = item;
                assetType = item.assetType;
                thumbnail = item.thumbnailUrl || (item.assetType === AssetType.audio ? ImageSrc.audioAsset : ImageSrc.asset);
                break;
            case "TextEverydaySituation":
                thumbnail = ImageSrc.textIcon;
                clientExtId = item.textEverydaySituationClient.extId;
                directoryId = item.textEverydaySituationDirectory.id;
                break;
            case "TextAndImageEverydaySituation":
                asset = item.textAndImageEverydaySituationImage;
                assetType = AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.asset;
                clientExtId = item.textAndImageEverydaySituationClient.extId;
                directoryId = item.textAndImageEverydaySituationDirectory.id;
                break;
            case "ImageEverydaySituation":
                asset = item.imageEverydaySituationImage;
                assetType = AssetType.image;
                thumbnail = asset.thumbnailUrl || ImageSrc.asset;
                clientExtId = item.imageEverydaySituationClient.extId;
                directoryId = item.imageEverydaySituationDirectory.id;
                break;
            case "AudioEverydaySituation":
                thumbnail = ImageSrc.audioAsset;
                assetType = AssetType.audio;
                clientExtId = item.audioEverydaySituationClient.extId;
                directoryId = item.audioEverydaySituationDirectory.id;
                break;
            case "EverydaySituationDirectory":
                assetType = AssetType.image;
                thumbnail = item.everydaySituationDirectoryImage ? item.everydaySituationDirectoryImage.thumbnailUrl : ImageSrc.asset;
                clientExtId = item.everydaySituationDirectoryClient.extId;
                break;
            case "Award":
                asset = item.awardImage;
                assetType = AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.asset;
                clientExtId = item.awardClient.extId;
                break;
            case "InstantAward":
                asset = item.instantAwardImage;
                assetType = AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.asset;
                clientExtId = item.instantAwardClient.extId;
                break;
            case "SelectionBoard":
                asset = item.selectionImage;
                assetType = AssetType.image;
                thumbnail = asset?.thumbnailUrl || ImageSrc.asset;
                clientExtId = item.selectionClient.extId;
                break;
            default:
                thumbnail = ImageSrc.asset;
        }
        return {
            id: item.id,
            clientExtId,
            directoryId,
            thumbnail,
            title: item.title,
            type: item.__typename,
            createdAt: item.createdAt,
            url: asset?.url,
            assetType,
        };
    }
}

export { DisabledItemUtils };

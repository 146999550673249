import { ReduxAction } from "actions";
import { AuthActionType } from "actions/AuthActions";
import { Account, SupportedClient } from "api/graphql/types";
import { AccountActionType } from "actions/AccountActions";

export type AccountReducerState = Account | null;

export class AccountReducer {
    private static readonly initialState: AccountReducerState = null;

    public static instance(state: AccountReducerState = AccountReducer.initialState, action: ReduxAction): AccountReducerState {
        switch (action.type) {
            case AccountActionType.updateAccount:
                return { ...state, ...action.account };
            case AccountActionType.addClient: {
                if (!state || !state.myClients) {
                    return state;
                }

                const result = [...(state.myClients.result || []), action.client].sort((c1: SupportedClient, c2: SupportedClient): number => {
                    if (c1.name < c2.name) {
                        return -1;
                    }
                    if (c1.name > c2.name) {
                        return 1;
                    }
                    return 0;
                });

                return { ...state, myClients: { ...state.myClients, result } };
            }
            case AccountActionType.updateClient: {
                if (!state || !state.myClients) {
                    return state;
                }
                const clients: SupportedClient[] = [...state.myClients.result];
                const index: number = clients.findIndex((c: SupportedClient) => c.id === action.client.id);
                if (index === -1) {
                    return state;
                }
                clients[index] = action.client;
                return { ...state, myClients: { ...state.myClients, result: clients } };
            }
            case AccountActionType.deleteClient: {
                if (!state || !state.myClients) {
                    return state;
                }

                return {
                    ...state,
                    myClients: {
                        ...state.myClients,
                        result: state.myClients.result.filter((client: SupportedClient): boolean => client.id !== action.clientId),
                    },
                };
            }
            case AuthActionType.logout:
                return null;
            default:
                return state;
        }
    }
}

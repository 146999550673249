import React, { Component } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Page } from "components/Page";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { SupportedClient, SelectionBoard, NamedColor, SelectionBoardOrderInput, AssetType } from "api/graphql/types";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApplicationState } from "reducers/index";
import { Intl } from "i18n/Intl";
import { Path } from "utils/Path";
import { Button } from "components/Button/Button";
import { Loading, LoadingType } from "components/Loading/Loading";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { Image } from "components/Image";
import { SortableList } from "components/SortableList/SortableList";
import { isNil, capitalize } from "lodash";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { TestId } from "utils/TestId";
import { ImageSrc } from "utils/ImageSrc";

import "./ClientSelectionBoardPage.scss";

interface ReduxProps {
    client: SupportedClient | null;
    accountId: string;
    colors: NamedColor[];
}

interface RouteParams {
    clientExtId?: string;
}

type Props = RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

interface State {
    isLoading: boolean;

    selectionBoards: SelectionBoard[];
    activeTableIndex: number | null;
}

class ClientSelectionBoardsListComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,

        selectionBoards: [],
        activeTableIndex: null,
    };

    public componentDidMount(): void {
        this.refreshSelectionBoards();
    }

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.client !== nextProps.client) {
            this.refreshSelectionBoards();
        }
    }

    private refreshSelectionBoards = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (!this.props.client) {
                        throw new ApiError(ApiErrorCode.NOT_FOUND);
                    }
                    const selectionBoards: SelectionBoard[] = await Api.getClientSelectionBoards(this.props.client.id);
                    this.setState({ selectionBoards, isLoading: false, activeTableIndex: null });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }
            },
        );
    };

    private readonly onDeleteClick = (selectionBoard: SelectionBoard) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteSelectionBoard,
                selectionBoard,
                onDeleted: this.refreshSelectionBoards,
            }),
        );
    };

    private readonly onEditClick = (selectionBoardId: string) => (): void => {
        if (isNil(this.props.client?.extId)) {
            return;
        }
        this.props.history.push(Path.editSelectionBoard(this.props.client!.extId, selectionBoardId));
    };

    private readonly getColorName = (backgroundColor: string): string | undefined => {
        const foundColor: NamedColor | undefined = this.props.colors.find((color: NamedColor) => {
            return color.value === backgroundColor;
        });
        return foundColor?.name ? capitalize(foundColor.name) : undefined;
    };

    private renderSelectionBoard = (selectionBoard: SelectionBoard) => {
        return (
            <div className="selection-board-list-element grid-x">
                <div
                    className="selection-board-list-image-container"
                    onClick={
                        selectionBoard.image
                            ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                  event.stopPropagation();
                                  this.props.dispatch(
                                      DialogsActions.show({
                                          type: DialogType.showAsset,
                                          assetUrl: selectionBoard.image?.url,
                                          assetType: selectionBoard.image?.assetType || AssetType.image,
                                          originalFileName: selectionBoard.image?.originalFileName,
                                          thumbnailUrl: selectionBoard.image?.thumbnailUrl,
                                          dialogTitle: selectionBoard.image?.title || Intl.formatMessage({ id: `enum.assetType.${AssetType.image}` }),
                                      }),
                                  );
                              }
                            : undefined
                    }
                >
                    <div className="image-container">
                        <Image className="selection-board-image" src={selectionBoard.image?.thumbnailUrl || selectionBoard.image?.url || ImageSrc.asset} />
                        {selectionBoard.image && (
                            <>
                                <div className="content-overlay" />
                                <div className="content-overlay-icon">
                                    <span className="fa fa-search-plus" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="cell auto selection-board-info">
                    <div className="selection-board-title">{selectionBoard.title}</div>
                    <div className="selection-board-details">
                        <div className="grid-x selection-board-detail-part">
                            <span className="cell small-4">{Intl.formatMessage({ id: "page.clientSelectionBoards.backgroundColor.listLabel" })}</span>
                            <span className="cell auto">{this.getColorName(selectionBoard.backgroundColor) || ""}</span>
                        </div>
                        <div className="grid-x selection-board-detail-part">
                            <span className="cell small-4">{Intl.formatMessage({ id: "page.clientSelectionBoards.itemCount.label" })}</span>
                            <span className="cell auto">{selectionBoard.itemCount}</span>
                        </div>
                    </div>
                </div>
                <div className="cell shrink">
                    <Button
                        className="edit"
                        link
                        icon={{ name: "fa-pencil-alt", large: true }}
                        title={Intl.formatMessage({ id: "common.edit" })}
                        ariaLabel={Intl.formatMessage({ id: "common.edit" })}
                        onClick={this.onEditClick(selectionBoard.id)}
                    />

                    <Button
                        className="delete"
                        link
                        icon={{ name: "fa-trash", large: true }}
                        title={Intl.formatMessage({ id: "common.delete" })}
                        ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                        onClick={this.onDeleteClick(selectionBoard)}
                    />
                </div>
            </div>
        );
    };

    private readonly onAddClick = () => {
        if (isNil(this.props.client?.extId)) {
            return;
        }
        this.props.history.push(Path.createSelectionBoard(this.props.client!.extId));
    };

    private getSelectionBoardsOrder = (selectionBoards: SelectionBoard[]): SelectionBoardOrderInput[] => {
        return selectionBoards.map((selectionBoard: SelectionBoard, index: number) => {
            return {
                selectionBoardId: selectionBoard.id,
                position: index + 1,
            };
        });
    };

    private onOrderChange = (selectionBoards: SelectionBoard[]) => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const orderInput: SelectionBoardOrderInput[] = this.getSelectionBoardsOrder(selectionBoards);
                    await Api.orderSelectionBoards(this.props.client!.id, orderInput);
                    this.refreshSelectionBoards();
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private renderContent = (): React.ReactElement<any> => {
        return (
            <div className="selection-board-list">
                <p>{Intl.formatMessage({ id: "page.clientSelectionBoards.lead" }, { name: this.props.client!.name })}</p>
                <SortableList data={this.state.selectionBoards} renderItem={this.renderSelectionBoard} onOrderChange={this.onOrderChange} />
                <div className="selection-board-list-new-button">
                    <Button
                        id={TestId.clientSelectionBoardsListPage.addClientSelectionBoardButton}
                        label={Intl.formatMessage({ id: "page.clientSelectionBoards.add" })}
                        icon={{ position: "left", name: "fa-plus" }}
                        onClick={this.onAddClick}
                        disabled={this.state.activeTableIndex !== null}
                    />
                </div>
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page
                id={TestId.clientSelectionBoardsListPage.clientSelectionBoardsListContainer}
                title={Intl.formatMessage({ id: "page.clientSelectionBoards.page.title" }, { name: this.props.client.name })}
                hasSideBar={false}
            >
                {this.state.isLoading && <Loading type={LoadingType.layer} />}
                <div className="left-side">{!this.state.isLoading && this.renderContent()}</div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
        accountId: state.account!.id,
        colors: state.settings.backgroundColors,
    };
};

export const ClientSelectionBoardsList = connect(mapStateToProps)(withRouter(ClientSelectionBoardsListComponent));

// tslint:disable-next-line: no-default-export
export default ClientSelectionBoardsList;

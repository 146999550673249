import React, { PureComponent } from "react";
import { SearchListType } from "api/graphql/types";
import { Slot } from "components/Slot/Slot";

import "./ContentSlot.scss";

interface Props {
    isDraggingOver: boolean;
    isActive: boolean;
    isDragInProgress: boolean;
    placeholder: any;
    isVisible: boolean;
    message: string;
    searchListType: SearchListType;
    hasError?: boolean;
    disabled?: boolean;
    customPlaceholder?: React.ReactElement;
}

class ContentSlot extends PureComponent<Props> {
    private static readonly DISABLED_OPACITY = 0.4;
    private static readonly DEFAULT_OPACITY = 1;

    public render(): React.ReactElement<any> {
        if (!this.props.isVisible) {
            return <div style={{ display: "none" }}>{this.props.placeholder}</div>;
        }

        const opacity: number = !this.props.isActive && this.props.isDragInProgress ? ContentSlot.DISABLED_OPACITY : ContentSlot.DEFAULT_OPACITY;
        if (this.props.searchListType === SearchListType.asset) {
            return (
                <Slot
                    hasError={this.props.hasError}
                    message={this.props.message}
                    style={{ opacity }}
                    onCloseClick={() => {}}
                    disabled={this.props.disabled}
                    customPlaceholder={this.props.customPlaceholder}
                />
            );
        }

        return (
            <div className={`slot${this.props.hasError ? " error" : ""}${this.props.disabled ? " disabled" : ""}`} style={{ opacity }}>
                {!this.props.disabled && <span className="upload-circle" />}
                <span className="upload-text">{this.props.message}</span>

                <div style={{ display: "none" }}>{this.props.placeholder}</div>
            </div>
        );
    }
}

export { ContentSlot };

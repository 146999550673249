import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface CreateSupervisorAccountDialogProps {
    createSupervisorAccount: (email: string, name: string) => Promise<void> | void;
}

type Props = CreateSupervisorAccountDialogProps & DialogVisibilityProps;

interface State {
    email: string;
    name: string;
    formErrors: {
        email: string | null;
        name: string | null;
    };
    isValidationEnabled: boolean;
}

class CreateSupervisorAccountDialog extends Component<Props, State> {
    private getInitialState(): State {
        return {
            email: "",
            name: "",
            isValidationEnabled: false,
            formErrors: {
                email: null,
                name: null,
            },
        };
    }

    public readonly state: State = this.getInitialState();

    private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const formErrors = {
            ...this.state.formErrors,
            email: IntlHelpers.getValidationError(Validator.validateEmail(event.currentTarget.value)),
        };

        this.setState({ email: event.currentTarget.value, formErrors });
    };

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const formErrors = {
            ...this.state.formErrors,
            name: IntlHelpers.getValidationError(Validator.validateName(event.currentTarget.value)),
        };

        this.setState({ name: event.currentTarget.value, formErrors });
    };

    private onAddClick = (): void => {
        const { email, name } = this.state;
        const formErrors = {
            email: IntlHelpers.getValidationError(Validator.validateEmail(email)),
            name: IntlHelpers.getValidationError(Validator.validateName(name)),
        };

        if (formErrors.email || formErrors.name) {
            this.setState({ isValidationEnabled: true, formErrors });
        } else {
            this.props.createSupervisorAccount(email, name);
        }

        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.title" })} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.description" })}</p>
                <InputWrapper
                    inputLabel={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.email.label" })}
                    errorMessage={this.state.isValidationEnabled ? this.state.formErrors.email : null}
                >
                    <Input
                        type="email"
                        placeholder={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.email.placeholder" })}
                        onChange={this.onEmailChange}
                        value={this.state.email}
                    />
                </InputWrapper>
                <InputWrapper
                    inputLabel={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.name.label" })}
                    errorMessage={this.state.isValidationEnabled ? this.state.formErrors.name : null}
                >
                    <Input
                        type="name"
                        placeholder={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.name.placeholder" })}
                        onChange={this.onNameChange}
                        value={this.state.name}
                    />
                </InputWrapper>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            label={Intl.formatMessage({ id: "page.supervisorList.createSupervisorAccountDialog.add" })}
                            onClick={this.onAddClick}
                            disabled={this.state.isValidationEnabled && (!!this.state.formErrors.name || !!this.state.formErrors.email)}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { CreateSupervisorAccountDialog };

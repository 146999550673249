import gql from "graphql-tag";
import { DocumentNode } from "graphql";

import { Fragments } from "./Fragments";

class Mutations {
    public static readonly login: DocumentNode = gql`
        mutation login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
                ...AuthResponse
            }
        }
        ${Fragments.authResponse}
    `;

    public static readonly logout: DocumentNode = gql`
        mutation logout {
            logout
        }
    `;

    public static readonly registration: DocumentNode = gql`
        mutation registration($email: EmailAddress!, $name: String!, $clientToken: String!) {
            registration(email: $email, name: $name, clientToken: $clientToken)
        }
    `;

    public static readonly activateAccount: DocumentNode = gql`
        mutation activateAccount($id: ID!, $code: String!, $password: String!) {
            activateAccount(id: $id, code: $code, password: $password)
        }
    `;

    public static readonly forgotPassword: DocumentNode = gql`
        mutation forgotPassword($email: EmailAddress!) {
            forgotPassword(email: $email)
        }
    `;

    public static readonly resetPassword: DocumentNode = gql`
        mutation resetPassword($id: ID!, $code: String!, $password: String!) {
            resetPassword(id: $id, code: $code, password: $password)
        }
    `;

    public static readonly refreshToken: DocumentNode = gql`
        mutation refreshToken($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken)
        }
    `;

    public static readonly updateName: DocumentNode = gql`
        mutation updateName($name: String!) {
            updateName(name: $name)
        }
    `;

    public static readonly changeEmailRequest: DocumentNode = gql`
        mutation changeEmailRequest($newEmail: EmailAddress!) {
            changeEmailRequest(newEmail: $newEmail)
        }
    `;

    public static readonly changePassword: DocumentNode = gql`
        mutation changePassword($password: String!, $newPassword: String!) {
            changePassword(password: $password, newPassword: $newPassword)
        }
    `;

    public static readonly updatePin: DocumentNode = gql`
        mutation updatePin($pin: String!) {
            updatePin(pin: $pin)
        }
    `;

    public static readonly changeEmailByCode: DocumentNode = gql`
        mutation changeEmailByCode($id: ID!, $code: String!) {
            changeEmailByCode(id: $id, code: $code)
        }
    `;

    public static readonly uploadAccountAvatar: DocumentNode = gql`
        mutation uploadAccountAvatar($file: Upload!) {
            uploadAccountAvatar(file: $file) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteAccountAvatar: DocumentNode = gql`
        mutation deleteAccountAvatar {
            deleteAccountAvatar
        }
    `;

    public static readonly deleteMyAccount: DocumentNode = gql`
        mutation deleteMyAccount {
            deleteMyAccount
        }
    `;

    public static readonly deletePin: DocumentNode = gql`
        mutation deletePin {
            deletePin
        }
    `;

    public static readonly createClient: DocumentNode = gql`
        mutation createClient($clientInput: CreateClientInput!) {
            createClient(clientInput: $clientInput) {
                ...SupportedClient
            }
        }
        ${Fragments.supportedClient}
    `;

    public static readonly uploadClientAvatar: DocumentNode = gql`
        mutation uploadClientAvatar($clientId: ID!, $file: Upload!) {
            uploadClientAvatar(clientId: $clientId, file: $file) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteClientAvatar: DocumentNode = gql`
        mutation deleteClientAvatar($clientId: ID!) {
            deleteClientAvatar(clientId: $clientId)
        }
    `;

    public static readonly updateClient: DocumentNode = gql`
        mutation updateClient($clientId: ID!, $clientInput: UpdateClientInput!) {
            updateClient(clientId: $clientId, clientInput: $clientInput) {
                ...ClientProfileData
            }
        }
        ${Fragments.clientProfileData}
    `;

    public static readonly deleteClient: DocumentNode = gql`
        mutation deleteClient($clientId: ID!) {
            deleteClient(clientId: $clientId)
        }
    `;

    public static readonly shareClient: DocumentNode = gql`
        mutation shareClient($email: EmailAddress!, $clientId: ID!) {
            shareClient(email: $email, clientId: $clientId)
        }
    `;

    public static readonly unlinkClientFromSupporter: DocumentNode = gql`
        mutation unlinkClientFromSupporter($clientId: ID!, $supporterAccountId: ID!) {
            unlinkClientFromSupporter(clientId: $clientId, supporterAccountId: $supporterAccountId)
        }
    `;

    public static readonly updateClientConfig: DocumentNode = gql`
        mutation updateClientConfig($clientId: ID!, $clientConfig: ClientConfigInput!) {
            updateClientConfig(clientId: $clientId, clientConfig: $clientConfig) {
                ...ClientConfig
            }
        }
        ${Fragments.clientConfig}
    `;

    public static readonly createAdminAccount: DocumentNode = gql`
        mutation createAdminAccount($email: EmailAddress!, $name: String!) {
            createAdminAccount(email: $email, name: $name) {
                id
            }
        }
    `;

    public static readonly createSupervisorAccount: DocumentNode = gql`
        mutation createSupervisorAccount($email: EmailAddress!, $name: String!) {
            createSupervisorAccount(email: $email, name: $name) {
                id
            }
        }
    `;

    public static readonly createSupporterAccount: DocumentNode = gql`
        mutation createSupporterAccount($email: EmailAddress!, $name: String!) {
            createSupporterAccount(email: $email, name: $name) {
                id
            }
        }
    `;

    public static readonly enableAccount: DocumentNode = gql`
        mutation enableAccount($accountId: ID!) {
            enableAccount(accountId: $accountId)
        }
    `;

    public static readonly disableAccount: DocumentNode = gql`
        mutation disableAccount($accountId: ID!, $disabledReason: String) {
            disableAccount(accountId: $accountId, disabledReason: $disabledReason)
        }
    `;

    public static readonly enableSupporter: DocumentNode = gql`
        mutation enableSupporter($accountId: ID!) {
            enableSupporter(accountId: $accountId)
        }
    `;

    public static readonly disableSupporter: DocumentNode = gql`
        mutation disableSupporter($accountId: ID!, $disabledReason: String) {
            disableSupporter(accountId: $accountId, disabledReason: $disabledReason)
        }
    `;

    public static readonly deleteAccount: DocumentNode = gql`
        mutation deleteAccount($accountId: ID!) {
            deleteAccount(accountId: $accountId)
        }
    `;

    public static readonly acceptClientShareRequest: DocumentNode = gql`
        mutation acceptClientShareRequest($requestId: ID!) {
            acceptClientShareRequest(requestId: $requestId)
        }
    `;

    public static readonly rejectClientShareRequest: DocumentNode = gql`
        mutation rejectClientShareRequest($requestId: ID!) {
            rejectClientShareRequest(requestId: $requestId)
        }
    `;

    public static readonly deleteClientShareRequest: DocumentNode = gql`
        mutation deleteClientShareRequest($requestId: ID!) {
            deleteClientShareRequest(requestId: $requestId)
        }
    `;

    public static readonly uploadAsset: DocumentNode = gql`
        mutation uploadAsset($title: String!, $file: Upload!, $tags: [String!], $directoryId: ID) {
            uploadAsset(title: $title, file: $file, tags: $tags, directoryId: $directoryId) {
                id
            }
        }
    `;

    public static readonly createSelectionBoard: DocumentNode = gql`
        mutation createSelectionBoard($clientId: ID!, $input: SelectionBoardInput!) {
            createSelectionBoard(clientId: $clientId, input: $input) {
                ...SelectionBoard
            }
        }
        ${Fragments.selectionBoard}
    `;

    public static readonly updateSelectionBoard: DocumentNode = gql`
        mutation updateSelectionBoard($selectionBoardId: ID!, $input: SelectionBoardInput!) {
            updateSelectionBoard(selectionBoardId: $selectionBoardId, input: $input) {
                ...SelectionBoard
            }
        }
        ${Fragments.selectionBoard}
    `;

    public static readonly deleteSelectionBoard: DocumentNode = gql`
        mutation deleteSelectionBoard($selectionBoardId: ID!) {
            deleteSelectionBoard(selectionBoardId: $selectionBoardId)
        }
    `;

    public static readonly createAgendaItem: DocumentNode = gql`
        mutation createAgendaItem($agendaItemInput: AgendaItemInput!) {
            createAgendaItem(agendaItemInput: $agendaItemInput) {
                ...AgendaItem
            }
        }
        ${Fragments.agendaItem}
    `;

    public static readonly updateAgendaItem: DocumentNode = gql`
        mutation updateAgendaItem($agendaItemId: ID!, $agendaId: ID, $agendaItemInput: AgendaItemInput!) {
            updateAgendaItem(agendaItemId: $agendaItemId, agendaId: $agendaId, agendaItemInput: $agendaItemInput) {
                ...AgendaItem
            }
        }
        ${Fragments.agendaItem}
    `;

    public static readonly deleteAgendaItem: DocumentNode = gql`
        mutation deleteAgendaItem($agendaItemId: ID!) {
            deleteAgendaItem(agendaItemId: $agendaItemId)
        }
    `;

    public static readonly createFlowchartItem: DocumentNode = gql`
        mutation createFlowchartItem($flowchartItemInput: FlowchartItemInput!) {
            createFlowchartItem(flowchartItemInput: $flowchartItemInput) {
                ...FlowchartItem
            }
        }
        ${Fragments.flowchartItem}
    `;

    public static readonly updateFlowchartItem: DocumentNode = gql`
        mutation updateFlowchartItem($flowchartItemId: ID!, $flowchartItemInput: FlowchartItemInput!) {
            updateFlowchartItem(flowchartItemId: $flowchartItemId, flowchartItemInput: $flowchartItemInput) {
                ...FlowchartItem
            }
        }
        ${Fragments.flowchartItem}
    `;

    public static readonly deleteFlowchartItem: DocumentNode = gql`
        mutation deleteFlowchartItem($flowchartItemId: ID!) {
            deleteFlowchartItem(flowchartItemId: $flowchartItemId)
        }
    `;

    public static readonly deleteAsset: DocumentNode = gql`
        mutation deleteAsset($assetId: ID!) {
            deleteAsset(assetId: $assetId)
        }
    `;

    public static readonly createFlowchart: DocumentNode = gql`
        mutation createFlowchart($flowchartInput: FlowchartInput!) {
            createFlowchart(flowchartInput: $flowchartInput) {
                ...Flowchart
            }
        }
        ${Fragments.flowchart}
    `;

    public static readonly updateFlowchart: DocumentNode = gql`
        mutation updateFlowchart($flowchartId: ID!, $flowchartInput: FlowchartInput!) {
            updateFlowchart(flowchartId: $flowchartId, flowchartInput: $flowchartInput) {
                ...Flowchart
            }
        }
        ${Fragments.flowchart}
    `;

    public static readonly deleteFlowchart: DocumentNode = gql`
        mutation deleteFlowchart($flowchartId: ID!) {
            deleteFlowchart(flowchartId: $flowchartId)
        }
    `;

    public static readonly createAgenda: DocumentNode = gql`
        mutation createAgenda($agendaInput: AgendaInput!) {
            createAgenda(agendaInput: $agendaInput) {
                ...Agenda
            }
        }
        ${Fragments.agenda}
    `;

    public static readonly updateAgenda: DocumentNode = gql`
        mutation updateAgenda($agendaId: ID!, $agendaInput: AgendaInput!) {
            updateAgenda(agendaId: $agendaId, agendaInput: $agendaInput) {
                ...Agenda
            }
        }
        ${Fragments.agenda}
    `;

    public static readonly deleteAgenda: DocumentNode = gql`
        mutation deleteAgenda($agendaId: ID!) {
            deleteAgenda(agendaId: $agendaId)
        }
    `;

    public static readonly copyAgendaToClientAgendaLibrary: DocumentNode = gql`
        mutation copyAgendaToClientAgendaLibrary($clientId: ID!, $agendaId: ID!, $day: Date!) {
            copyAgendaToClientAgendaLibrary(clientId: $clientId, agendaId: $agendaId, day: $day) {
                id
            }
        }
    `;

    public static readonly setClientDefaultAgenda: DocumentNode = gql`
        mutation setClientDefaultAgenda($clientId: ID!, $agendaId: ID!) {
            setClientDefaultAgenda(clientId: $clientId, agendaId: $agendaId)
        }
    `;

    public static readonly unsetClientDefaultAgenda: DocumentNode = gql`
        mutation unsetClientDefaultAgenda($clientId: ID!) {
            unsetClientDefaultAgenda(clientId: $clientId)
        }
    `;

    public static readonly deleteDevice: DocumentNode = gql`
        mutation deleteDevice($deviceId: String!) {
            deleteDevice(deviceId: $deviceId)
        }
    `;

    public static readonly copyAgendaToPublicLibrary: DocumentNode = gql`
        mutation copyAgendaToPublicLibrary($id: ID!) {
            copyAgendaToPublicLibrary(agendaId: $id) {
                id
            }
        }
    `;

    public static readonly copyAgendaItemToPublicLibrary: DocumentNode = gql`
        mutation copyAgendaItemToPublicLibrary($id: ID!) {
            copyAgendaItemToPublicLibrary(agendaItemId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartToPublicLibrary: DocumentNode = gql`
        mutation copyFlowchartToPublicLibrary($id: ID!) {
            copyFlowchartToPublicLibrary(flowchartId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartItemToPublicLibrary: DocumentNode = gql`
        mutation copyFlowchartItemToPublicLibrary($id: ID!) {
            copyFlowchartItemToPublicLibrary(flowchartItemId: $id) {
                id
            }
        }
    `;

    public static readonly copyAgendaToClientLibrary: DocumentNode = gql`
        mutation copyAgendaToClientPersonalLibrary($clientId: ID!, $id: ID!) {
            copyAgendaToClientPersonalLibrary(clientId: $clientId, agendaId: $id) {
                id
            }
        }
    `;

    public static readonly copyAgendaItemToClientLibrary: DocumentNode = gql`
        mutation copyAgendaItemToClientPersonalLibrary($clientId: ID!, $id: ID!) {
            copyAgendaItemToClientPersonalLibrary(clientId: $clientId, agendaItemId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartToClientLibrary: DocumentNode = gql`
        mutation copyFlowchartToClientLibrary($clientId: ID!, $id: ID!) {
            copyFlowchartToClientLibrary(clientId: $clientId, flowchartId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartItemToClientLibrary: DocumentNode = gql`
        mutation copyFlowchartItemToClientLibrary($clientId: ID!, $id: ID!) {
            copyFlowchartItemToClientLibrary(clientId: $clientId, flowchartItemId: $id) {
                id
            }
        }
    `;

    public static readonly enableAgenda: DocumentNode = gql`
        mutation enableAgenda($id: ID!) {
            enableAgenda(agendaId: $id)
        }
    `;

    public static readonly disableAgenda: DocumentNode = gql`
        mutation disableAgenda($id: ID!) {
            disableAgenda(agendaId: $id)
        }
    `;

    public static readonly enableAgendaItem: DocumentNode = gql`
        mutation enableAgendaItem($id: ID!) {
            enableAgendaItem(agendaItemId: $id)
        }
    `;

    public static readonly disableAgendaItem: DocumentNode = gql`
        mutation disableAgendaItem($id: ID!) {
            disableAgendaItem(agendaItemId: $id)
        }
    `;

    public static readonly enableFlowchart: DocumentNode = gql`
        mutation enableFlowchart($id: ID!) {
            enableFlowchart(flowchartId: $id)
        }
    `;

    public static readonly disableFlowchart: DocumentNode = gql`
        mutation disableFlowchart($id: ID!) {
            disableFlowchart(flowchartId: $id)
        }
    `;

    public static readonly enableFlowchartItem: DocumentNode = gql`
        mutation enableFlowchartItem($id: ID!) {
            enableFlowchartItem(flowchartItemId: $id)
        }
    `;

    public static readonly disableFlowchartItem: DocumentNode = gql`
        mutation disableFlowchartItem($id: ID!) {
            disableFlowchartItem(flowchartItemId: $id)
        }
    `;

    public static readonly disableAsset: DocumentNode = gql`
        mutation disableAsset($id: ID!) {
            disableAsset(assetId: $id)
        }
    `;

    public static readonly enableAsset: DocumentNode = gql`
        mutation enableAsset($id: ID!) {
            enableAsset(assetId: $id)
        }
    `;

    public static readonly disableEverydaySituationDirectory: DocumentNode = gql`
        mutation disableEverydaySituationDirectory($id: ID!) {
            disableEverydaySituationDirectory(id: $id)
        }
    `;

    public static readonly enableEverydaySituationDirectory: DocumentNode = gql`
        mutation enableEverydaySituationDirectory($id: ID!) {
            enableEverydaySituationDirectory(id: $id)
        }
    `;

    public static readonly disableEverydaySituation: DocumentNode = gql`
        mutation disableEverydaySituation($id: ID!) {
            disableEverydaySituation(id: $id)
        }
    `;

    public static readonly enableEverydaySituation: DocumentNode = gql`
        mutation enableEverydaySituation($id: ID!) {
            enableEverydaySituation(id: $id)
        }
    `;

    public static readonly disableInstantAward: DocumentNode = gql`
        mutation disableInstantAward($id: ID!) {
            disableInstantAward(instantAwardId: $id)
        }
    `;

    public static readonly enableInstantAward: DocumentNode = gql`
        mutation enableInstantAward($id: ID!) {
            enableInstantAward(instantAwardId: $id)
        }
    `;

    public static readonly disableAward: DocumentNode = gql`
        mutation disableAward($id: ID!) {
            disableAward(awardId: $id)
        }
    `;

    public static readonly enableAward: DocumentNode = gql`
        mutation enableAward($id: ID!) {
            enableAward(awardId: $id)
        }
    `;

    public static readonly disableSelectionBoard: DocumentNode = gql`
        mutation disableSelectionBoard($id: ID!) {
            disableSelectionBoard(selectionBoardId: $id)
        }
    `;

    public static readonly enableSelectionBoard: DocumentNode = gql`
        mutation enableSelectionBoard($id: ID!) {
            enableSelectionBoard(selectionBoardId: $id)
        }
    `;

    public static readonly syncClientAgendas: DocumentNode = gql`
        mutation syncClientAgendas($clientId: ID!) {
            syncClientAgendas(clientId: $clientId)
        }
    `;

    public static readonly copyAgendaToAccountPersonalLibrary: DocumentNode = gql`
        mutation copyAgendaToAccountPersonalLibrary($id: ID!) {
            copyAgendaToAccountPersonalLibrary(agendaId: $id) {
                id
            }
        }
    `;

    public static readonly copyAgendaItemToAccountPersonalLibrary: DocumentNode = gql`
        mutation copyAgendaItemToAccountPersonalLibrary($id: ID!) {
            copyAgendaItemToAccountPersonalLibrary(agendaItemId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartToAccountPersonalLibrary: DocumentNode = gql`
        mutation copyFlowchartToAccountPersonalLibrary($id: ID!) {
            copyFlowchartToAccountPersonalLibrary(flowchartId: $id) {
                id
            }
        }
    `;

    public static readonly copyFlowchartItemToAccountPersonalLibrary: DocumentNode = gql`
        mutation copyFlowchartItemToAccountPersonalLibrary($id: ID!) {
            copyFlowchartItemToAccountPersonalLibrary(flowchartItemId: $id) {
                id
            }
        }
    `;

    public static readonly createClientChangeManagerRequest: DocumentNode = gql`
        mutation createClientChangeManagerRequest($clientId: ID!, $accountId: ID!) {
            createClientChangeManagerRequest(clientId: $clientId, accountId: $accountId)
        }
    `;

    public static readonly deleteClientChangeManagerRequest: DocumentNode = gql`
        mutation deleteClientChangeManagerRequest($requestId: ID!) {
            deleteClientChangeManagerRequest(requestId: $requestId)
        }
    `;

    public static readonly acceptClientChangeManagerRequest: DocumentNode = gql`
        mutation acceptClientChangeManagerRequest($requestId: ID!) {
            acceptClientChangeManagerRequest(requestId: $requestId)
        }
    `;

    public static readonly rejectClientChangeManagerRequest: DocumentNode = gql`
        mutation rejectClientChangeManagerRequest($requestId: ID!) {
            rejectClientChangeManagerRequest(requestId: $requestId)
        }
    `;

    public static readonly uploadClientIntroductionAudio: DocumentNode = gql`
        mutation uploadClientIntroductionAudio($clientId: ID!, $file: Upload!) {
            uploadClientIntroductionAudio(clientId: $clientId, file: $file) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteClientIntroductionAudio: DocumentNode = gql`
        mutation deleteClientIntroductionAudio($clientId: ID!) {
            deleteClientIntroductionAudio(clientId: $clientId)
        }
    `;

    public static readonly uploadClientIntroductionVideo: DocumentNode = gql`
        mutation uploadClientIntroductionVideo($clientId: ID!, $file: Upload!) {
            uploadClientIntroductionVideo(clientId: $clientId, file: $file) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteClientIntroductionVideo: DocumentNode = gql`
        mutation deleteClientIntroductionVideo($clientId: ID!) {
            deleteClientIntroductionVideo(clientId: $clientId)
        }
    `;

    public static readonly updateAsset: DocumentNode = gql`
        mutation updateAsset($assetId: ID!, $title: String, $tags: [String!]) {
            updateAsset(assetId: $assetId, title: $title, tags: $tags) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly setSelfSupportingStateDecision: DocumentNode = gql`
        mutation setSelfSupportingStateDecision($clientId: ID!, $selfSupportingState: Boolean!) {
            setSelfSupportingStateDecision(clientId: $clientId, selfSupportingState: $selfSupportingState) {
                ...SupportedClient
            }
        }
        ${Fragments.supportedClient}
    `;

    public static readonly copyAssetToPublicLibrary: DocumentNode = gql`
        mutation copyAssetToPublicLibrary($id: ID!) {
            copyAssetToPublicLibrary(assetId: $id) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;
    public static readonly copyAssetToAccountPersonalLibrary: DocumentNode = gql`
        mutation copyAssetToAccountPersonalLibrary($assetId: ID!) {
            copyAssetToAccountPersonalLibrary(assetId: $assetId) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly evaluateClientQuestionnaire: DocumentNode = gql`
        mutation evaluateClientQuestionnaire($clientId: ID!, $clientQuestionnaireAnswers: [ClientQuestionnaireAnswerInput!]!) {
            evaluateClientQuestionnaire(clientId: $clientId, clientQuestionnaireAnswers: $clientQuestionnaireAnswers) {
                ...ClientConfig
            }
        }
        ${Fragments.clientConfig}
    `;

    public static readonly updateClientSpareAgendaItems: DocumentNode = gql`
        mutation updateClientSpareAgendaItems($clientId: ID!, $agendaItemIds: [ID!]!) {
            updateClientSpareAgendaItems(clientId: $clientId, agendaItemIds: $agendaItemIds) {
                ...AgendaItemContent
            }
        }
        ${Fragments.agendaItemContent}
    `;

    public static readonly updateClientMemoryGameConfig: DocumentNode = gql`
        mutation updateClientMemoryGameConfig($clientId: ID!, $memoryGameConfig: MemoryGameConfigInput!) {
            updateClientMemoryGameConfig(clientId: $clientId, memoryGameConfig: $memoryGameConfig) {
                ...MemoryGameConfig
            }
        }
        ${Fragments.memoryGameConfig}
    `;

    public static readonly updateClientSortingGameConfig: DocumentNode = gql`
        mutation updateClientSortingGameConfig($clientId: ID!, $flowchartIds: [ID!]!) {
            updateClientSortingGameConfig(clientId: $clientId, flowchartIds: $flowchartIds) {
                ...Flowchart
            }
        }
        ${Fragments.flowchart}
    `;

    public static readonly updateEverydaySituationClientDirectory = gql`
        mutation updateEverydaySituationClientDirectory($input: UpdateEverydaySituationClientDirectoryInput!) {
            updateEverydaySituationClientDirectory(input: $input) {
                ...EverydaySituationClientDirectory
            }
        }
        ${Fragments.everydaySituationClientDirectory}
    `;

    public static readonly createEverydaySituationDirectory: DocumentNode = gql`
        mutation createEverydaySituationDirectory($input: CreateEverydaySituationDirectoryInput!) {
            createEverydaySituationDirectory(input: $input) {
                ...EverydaySituationDirectory
            }
        }
        ${Fragments.everydaySituationDirectory}
    `;

    public static readonly updateEverydaySituationDirectory: DocumentNode = gql`
        mutation updateEverydaySituationDirectory($input: UpdateEverydaySituationDirectoryInput!) {
            updateEverydaySituationDirectory(input: $input) {
                ...EverydaySituationDirectory
            }
        }
        ${Fragments.everydaySituationDirectory}
    `;

    public static readonly deleteEverydaySituationDirectory: DocumentNode = gql`
        mutation deleteEverydaySituationDirectory($id: ID!) {
            deleteEverydaySituationDirectory(id: $id)
        }
    `;

    public static readonly orderEverydaySituationDirectories: DocumentNode = gql`
        mutation orderEverydaySituationDirectories($clientId: ID!, $orderInput: [EverydaySituationDirectoryOrderInput!]!) {
            orderEverydaySituationDirectories(clientId: $clientId, orderInput: $orderInput)
        }
    `;

    public static readonly createEverydaySituation: DocumentNode = gql`
        mutation createEverydaySituation($input: CreateEverydaySituationInput!) {
            createEverydaySituation(input: $input) {
                ...EverydaySituation
            }
        }
        ${Fragments.everydaySituation}
    `;

    public static readonly updateEverydaySituation: DocumentNode = gql`
        mutation updateEverydaySituation($input: UpdateEverydaySituationInput!) {
            updateEverydaySituation(input: $input) {
                ...EverydaySituation
            }
        }
        ${Fragments.everydaySituation}
    `;

    public static readonly deleteEverydaySituation: DocumentNode = gql`
        mutation deleteEverydaySituation($id: ID!) {
            deleteEverydaySituation(id: $id)
        }
    `;

    public static readonly deleteFaq: DocumentNode = gql`
        mutation deleteFaq($faqItemId: ID!) {
            deleteFaqItem(faqItemId: $faqItemId)
        }
    `;

    public static readonly orderFaqs: DocumentNode = gql`
        mutation orderFaqs($orderInput: [FaqItemOrderInput!]!) {
            orderFaqItems(orderInput: $orderInput)
        }
    `;

    public static readonly createFaq: DocumentNode = gql`
        mutation createFaq($input: CreateFaqItemInput!) {
            createFaqItem(input: $input) {
                ...Faq
            }
        }
        ${Fragments.faq}
    `;

    public static readonly updateFaq: DocumentNode = gql`
        mutation updateFaq($faqItemId: ID!, $input: UpdateFaqItemInput!) {
            updateFaqItem(faqItemId: $faqItemId, input: $input) {
                ...Faq
            }
        }
        ${Fragments.faq}
    `;

    public static readonly createPersonalAssetDirectory: DocumentNode = gql`
        mutation createPersonalAssetDirectory($input: CreateAssetDirectoryInput!) {
            createPersonalAssetDirectory(input: $input) {
                id
            }
        }
    `;

    public static readonly createPublicAssetDirectory: DocumentNode = gql`
        mutation createPublicAssetDirectory($input: CreateAssetDirectoryInput!) {
            createPublicAssetDirectory(input: $input) {
                id
            }
        }
    `;

    public static readonly updatePersonalAssetDirectory: DocumentNode = gql`
        mutation updatePersonalAssetDirectory($assetDirectoryId: ID!, $input: UpdateAssetDirectoryInput!) {
            updatePersonalAssetDirectory(assetDirectoryId: $assetDirectoryId, input: $input) {
                ...AssetDirectoryData
            }
        }
        ${Fragments.assetDirectoryData}
    `;

    public static readonly deletePersonalAssetDirectory: DocumentNode = gql`
        mutation deletePersonalAssetDirectory($assetDirectoryId: ID!) {
            deletePersonalAssetDirectory(assetDirectoryId: $assetDirectoryId)
        }
    `;

    public static readonly updatePublicAssetDirectory: DocumentNode = gql`
        mutation updatePublicAssetDirectory($assetDirectoryId: ID!, $input: UpdateAssetDirectoryInput!) {
            updatePublicAssetDirectory(assetDirectoryId: $assetDirectoryId, input: $input) {
                ...AssetDirectoryData
            }
        }
        ${Fragments.assetDirectoryData}
    `;

    public static readonly deletePublicAssetDirectory: DocumentNode = gql`
        mutation deletePublicAssetDirectory($assetDirectoryId: ID!) {
            deletePublicAssetDirectory(assetDirectoryId: $assetDirectoryId)
        }
    `;

    public static readonly moveAssetsToPersonalAssetDirectory: DocumentNode = gql`
        mutation moveAssetsToPersonalAssetDirectory($assetIds: [ID!]!, $directoryId: ID) {
            moveAssetsToPersonalAssetDirectory(assetIds: $assetIds, directoryId: $directoryId)
        }
    `;

    public static readonly moveAssetsToPublicAssetDirectory: DocumentNode = gql`
        mutation moveAssetsToPublicAssetDirectory($assetIds: [ID!]!, $directoryId: ID) {
            moveAssetsToPublicAssetDirectory(assetIds: $assetIds, directoryId: $directoryId)
        }
    `;

    public static readonly replaceAsset: DocumentNode = gql`
        mutation replaceAsset($assetId: ID!, $replacementAssetId: ID!) {
            replaceAsset(assetId: $assetId, replacementAssetId: $replacementAssetId)
        }
    `;

    public static readonly createClientInstantAward: DocumentNode = gql`
        mutation createClientInstantAward($clientId: ID!, $input: InstantAwardInput!) {
            createInstantAward(clientId: $clientId, input: $input) {
                ...InstantAward
            }
        }
        ${Fragments.instantAward}
    `;

    public static readonly updateClientInstantAward: DocumentNode = gql`
        mutation updateClientInstantAward($instantAwardId: ID!, $input: InstantAwardInput!) {
            updateInstantAward(instantAwardId: $instantAwardId, input: $input) {
                ...InstantAward
            }
        }
        ${Fragments.instantAward}
    `;

    public static readonly deleteClientInstantAward: DocumentNode = gql`
        mutation deleteClientInstantAward($instantAwardId: ID!) {
            deleteInstantAward(instantAwardId: $instantAwardId)
        }
    `;

    public static readonly createClientAward: DocumentNode = gql`
        mutation createClientAward($clientId: ID!, $input: AwardInput!) {
            createAward(clientId: $clientId, input: $input) {
                ...Award
            }
        }
        ${Fragments.award}
    `;

    public static readonly updateClientAward: DocumentNode = gql`
        mutation updateClientAward($awardId: ID!, $input: AwardInput!) {
            updateAward(awardId: $awardId, input: $input) {
                ...Award
            }
        }
        ${Fragments.award}
    `;

    public static readonly deleteClientAward: DocumentNode = gql`
        mutation deleteClientAward($awardId: ID!) {
            deleteAward(awardId: $awardId)
        }
    `;

    public static readonly activateAward: DocumentNode = gql`
        mutation activateAward($awardId: ID!) {
            activateAward(awardId: $awardId)
        }
    `;

    public static readonly orderSelectionBoards: DocumentNode = gql`
        mutation orderSelectionBoards($clientId: ID!, $orderInput: [SelectionBoardOrderInput!]!) {
            orderSelectionBoards(clientId: $clientId, orderInput: $orderInput)
        }
    `;

    public static readonly createTag: DocumentNode = gql`
        mutation createTag($input: CreateTagInput!) {
            createTag(input: $input) {
                ...AdminTag
            }
        }
        ${Fragments.adminTag}
    `;

    public static readonly deleteTag: DocumentNode = gql`
        mutation deleteTag($tagId: ID!) {
            deleteTag(tagId: $tagId)
        }
    `;

    public static readonly createEducationContentModule: DocumentNode = gql`
        mutation createEducationContentModule($input: CreateEducationContentModuleInput!) {
            createEducationContentModule(input: $input) {
                ...EducationContentModule
            }
        }
        ${Fragments.educationContentModule}
    `;

    public static readonly updateEducationContentModule: DocumentNode = gql`
        mutation updateEducationContentModule($input: UpdateEducationContentModuleInput!) {
            updateEducationContentModule(input: $input) {
                ...EducationContentModule
            }
        }
        ${Fragments.educationContentModule}
    `;

    public static readonly orderEducationContentModules: DocumentNode = gql`
        mutation orderEducationContentModules($parentModuleId: ID!, $input: [EducationContentModuleOrderInput!]!) {
            orderEducationContentModules(parentModuleId: $parentModuleId, input: $input)
        }
    `;

    public static readonly deleteSubModule: DocumentNode = gql`
        mutation deleteSubModule($id: ID!) {
            deleteEducationContentModule(id: $id)
        }
    `;

    public static readonly createEducationContent: DocumentNode = gql`
        mutation createEducationContent($moduleId: ID!, $type: EducationContentType!, $title: String!, $file: Upload!) {
            createEducationContent(moduleId: $moduleId, input: { type: $type, title: $title, file: $file }) {
                id
            }
        }
    `;

    public static readonly updateEducationContent: DocumentNode = gql`
        mutation updateEducationContent($input: UpdateEducationContentInput!) {
            updateEducationContent(input: $input) {
                id
            }
        }
    `;

    public static readonly orderEducationContents: DocumentNode = gql`
        mutation orderEducationContents($moduleId: ID!, $input: [EducationContentOrderInput!]!) {
            orderEducationContents(moduleId: $moduleId, input: $input)
        }
    `;

    public static readonly deleteEducationContent: DocumentNode = gql`
        mutation deleteEducationContent($id: ID!) {
            deleteEducationContent(id: $id)
        }
    `;

    public static readonly shareLibraryContentToPublic: DocumentNode = gql`
        mutation shareLibraryContentToPublic($libraryContentId: ID!, $comment: String) {
            shareLibraryContentToPublic(libraryContentId: $libraryContentId, comment: $comment)
        }
    `;

    public static readonly shareLibraryContent: DocumentNode = gql`
        mutation shareLibraryContent($sharedWithAccountIds: [ID!]!, $libraryContentId: ID!) {
            shareLibraryContent(sharedWithAccountIds: $sharedWithAccountIds, libraryContentId: $libraryContentId)
        }
    `;

    public static readonly acceptLibraryContentShareRequest: DocumentNode = gql`
        mutation acceptLibraryContentShareRequest($requestId: ID!) {
            acceptLibraryContentShareRequest(requestId: $requestId) {
                ... on Agenda {
                    id
                }
                ... on AgendaItem {
                    id
                }
                ... on Flowchart {
                    id
                }
                ... on FlowchartItem {
                    id
                }
            }
        }
    `;

    public static readonly rejectLibraryContentShareRequest: DocumentNode = gql`
        mutation rejectLibraryContentShareRequest($requestId: ID!) {
            rejectLibraryContentShareRequest(requestId: $requestId)
        }
    `;
}

export { Mutations };

import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { Fragments } from "./Fragments";

class Queries {
    public static readonly me: DocumentNode = gql`
        query me {
            me {
                ...Account
            }
        }
        ${Fragments.account}
    `;

    public static readonly appData: DocumentNode = gql`
        query appData {
            me {
                ...Account
            }
            getCmsSettings {
                ...CmsSettings
            }
        }
        ${Fragments.account}
        ${Fragments.cmsSettings}
    `;

    public static readonly storageSizeUsed: DocumentNode = gql`
        query storageSizeUsed {
            me {
                ...StorageSizeUsed
            }
        }
        ${Fragments.storageSizeUsed}
    `;

    public static readonly getClientQuestionnaire: DocumentNode = gql`
        query getClientQuestionnaire {
            getClientQuestionnaire {
                ...ClientQuestionnaireQuestion
            }
        }
        ${Fragments.clientQuestionnaireQuestion}
    `;

    public static readonly getMyClients: DocumentNode = gql`
        query getMyClients($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        ...ClientProfileData
                    }
                }
            }
        }
        ${Fragments.clientProfileData}
    `;

    public static readonly getClientEventLog: DocumentNode = gql`
        query getClientEventLog($clientId: ID!, $date: DateFilter!, $options: AppStatItemListOptionsInput, $type: AppStatItemType) {
            getAppStatItemList(clientId: $clientId, date: $date, options: $options, type: $type) {
                count
                aggregations {
                    ...Aggregations
                }
                result {
                    ...AppStatItem
                }
            }
        }
        ${Fragments.aggregations}
        ${Fragments.appStatItem}
    `;

    public static readonly getMyClientsAppConfig: DocumentNode = gql`
        query getMyClientsAppConfig($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        config {
                            ...ClientAppConfig
                        }
                        notificationOnProfilePage
                    }
                }
            }
        }
        ${Fragments.clientAppConfig}
    `;

    public static readonly getMyClientsMainPageConfig: DocumentNode = gql`
        query getMyClientsMainPageConfig($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        config {
                            ...ClientMainPageConfig
                        }
                        notificationOnProfilePage
                    }
                }
            }
        }
        ${Fragments.clientMainPageConfig}
    `;

    public static readonly getMyClientsContentsConfig: DocumentNode = gql`
        query getMyClientsContentsConfig($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        config {
                            ...ClientContentsConfig
                        }
                        notificationOnProfilePage
                    }
                }
            }
        }
        ${Fragments.clientContentsConfig}
    `;

    public static readonly getMyClientsFunctionsConfig: DocumentNode = gql`
        query getMyClientsFunctionsConfig($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        config {
                            ...ClientFunctionsConfig
                        }
                        notificationOnProfilePage
                    }
                }
            }
        }
        ${Fragments.clientFunctionsConfig}
    `;

    public static readonly getAdminList: DocumentNode = gql`
        query getAdminList($options: AccountListOptionsInput) {
            getAccountList(options: $options, accountTypes: [admin, superadmin]) {
                count
                result {
                    ...AdminListItem
                }
            }
        }
        ${Fragments.adminListItem}
    `;

    public static readonly getSupervisorList: DocumentNode = gql`
        query getSupervisorList($options: AccountListOptionsInput) {
            getAccountList(options: $options, accountTypes: [supervisor]) {
                count
                result {
                    ...SupervisorListItem
                }
            }
        }
        ${Fragments.supervisorListItem}
    `;

    public static readonly getSupporterList: DocumentNode = gql`
        query getSupporterList($options: AccountListOptionsInput) {
            getSupporterList(options: $options) {
                count
                result {
                    ...SupporterListItem
                }
            }
        }
        ${Fragments.supporterListItem}
    `;

    public static readonly getClientList: DocumentNode = gql`
        query getClientList($options: ClientListOptionsInput) {
            getClientList(options: $options) {
                count
                result {
                    ...ClientListItem
                }
            }
        }
        ${Fragments.clientListItem}
    `;

    public static readonly getAdminById: DocumentNode = gql`
        query getAdminById($accountId: ID!) {
            getAccountById(accountId: $accountId) {
                ...Account
            }
        }
        ${Fragments.account}
    `;

    public static readonly getSupporterByExtId: DocumentNode = gql`
        query getSupporterByExtId($extId: ID!) {
            getSupporterByExtId(extId: $extId) {
                ...SupporterDetail
            }
        }
        ${Fragments.supporterDetail}
    `;

    public static readonly getClientByExtId: DocumentNode = gql`
        query getClientByExtId($extId: ID!) {
            getClientByExtId(extId: $extId) {
                ...ClientProfileData
            }
        }
        ${Fragments.clientProfileData}
    `;

    public static readonly getClientDetailEventLog: DocumentNode = gql`
        query getClientDetailEventLog($extId: ID!, $date: DateFilter!, $options: AppStatItemListOptionsInput, $type: AppStatItemType) {
            getAppStatItemList(clientExtId: $extId, date: $date, options: $options, type: $type) {
                count
                aggregations {
                    ...Aggregations
                }
                result {
                    ...AppStatItem
                }
            }
        }
        ${Fragments.aggregations}
        ${Fragments.appStatItem}
    `;

    public static readonly getContentLibrary: DocumentNode = gql`
        query getContentLibrary($options: SearchInput) {
            search(options: $options) {
                count
                result {
                    ... on Agenda {
                        ...AgendaListItem
                    }
                    ... on AgendaItem {
                        ...AgendaItemListItem
                    }
                    ... on Flowchart {
                        ...FlowchartListItem
                    }
                    ... on FlowchartItem {
                        ...FlowchartItemListItem
                    }
                    ... on Asset {
                        ...AssetListItem
                    }
                }
            }
        }
        ${Fragments.agendaListItem}
        ${Fragments.agendaItemListItem}
        ${Fragments.flowchartListItem}
        ${Fragments.flowchartItemListItem}
        ${Fragments.assetListItem}
    `;

    public static readonly getMyContentLibrarySidebarData: DocumentNode = gql`
        query getMyContentLibrarySidebarData(
            $createdById: ID!
            $searchListTypes: [SearchListType!]
            $assetTypes: [AssetType!]
            $search: String
            $limit: Int
            $offset: Int
            $tags: [String!]
            $assetDirectory: ID
            $usableInSortingGame: Boolean
        ) {
            myLibrary: search(
                options: {
                    types: $searchListTypes
                    createdBy: $createdById
                    assetTypes: $assetTypes
                    listOptions: { control: { search: $search, limit: $limit, offset: $offset } }
                    tags: $tags
                    assetDirectory: $assetDirectory
                    usableInSortingGame: $usableInSortingGame
                }
            ) {
                count
                result {
                    ... on Agenda {
                        ...AgendaContent
                    }
                    ... on AgendaItem {
                        ...AgendaItemContent
                    }
                    ... on Flowchart {
                        ...FlowchartContent
                    }
                    ... on FlowchartItem {
                        ...FlowchartItemContent
                    }
                    ... on Asset {
                        ...AssetContent
                    }
                }
            }
        }
        ${Fragments.agendaContent}
        ${Fragments.agendaItemContent}
        ${Fragments.flowchartContent}
        ${Fragments.flowchartItemContent}
        ${Fragments.assetContent}
    `;

    public static readonly getClientContentLibrarySidebarData: DocumentNode = gql`
        query getClientContentLibrarySidebarData(
            $clientId: ID!
            $searchListTypes: [SearchListType!]
            $assetTypes: [AssetType!]
            $search: String
            $limit: Int
            $offset: Int
            $tags: [String!]
            $assetDirectory: ID
            $usableInSortingGame: Boolean
        ) {
            clientLibrary: search(
                options: {
                    types: $searchListTypes
                    clientId: $clientId
                    assetTypes: $assetTypes
                    listOptions: { control: { search: $search, limit: $limit, offset: $offset } }
                    assetDirectory: $assetDirectory
                    tags: $tags
                    usableInSortingGame: $usableInSortingGame
                }
            ) {
                count
                result {
                    ... on Agenda {
                        ...AgendaContent
                    }
                    ... on AgendaItem {
                        ...AgendaItemContent
                    }
                    ... on Flowchart {
                        ...FlowchartContent
                    }
                    ... on FlowchartItem {
                        ...FlowchartItemContent
                    }
                    ... on Asset {
                        ...AssetContent
                    }
                }
            }
        }
        ${Fragments.agendaContent}
        ${Fragments.agendaItemContent}
        ${Fragments.flowchartContent}
        ${Fragments.flowchartItemContent}
        ${Fragments.assetContent}
    `;

    public static readonly getAllContentLibrarySidebarData: DocumentNode = gql`
        query getAllContentLibrarySidebarData(
            $createdById: ID
            $clientId: ID
            $searchListTypes: [SearchListType!]
            $assetTypes: [AssetType!]
            $search: String
            $limit: Int
            $offset: Int
            $tags: [String!]
            $assetDirectory: ID
            $usableInSortingGame: Boolean
        ) {
            clientLibrary: search(
                options: {
                    types: $searchListTypes
                    clientId: $clientId
                    assetTypes: $assetTypes
                    listOptions: { control: { search: $search, limit: $limit, offset: $offset } }
                    assetDirectory: $assetDirectory
                    tags: $tags
                    usableInSortingGame: $usableInSortingGame
                }
            ) {
                count
                result {
                    ... on Agenda {
                        ...AgendaContent
                    }
                    ... on AgendaItem {
                        ...AgendaItemContent
                    }
                    ... on Flowchart {
                        ...FlowchartContent
                    }
                    ... on FlowchartItem {
                        ...FlowchartItemContent
                    }
                    ... on Asset {
                        ...AssetContent
                    }
                }
            }
            myLibrary: search(
                options: {
                    types: $searchListTypes
                    createdBy: $createdById
                    assetTypes: $assetTypes
                    listOptions: { control: { search: $search, limit: $limit, offset: $offset } }
                    assetDirectory: $assetDirectory
                    tags: $tags
                    usableInSortingGame: $usableInSortingGame
                }
            ) {
                count
                result {
                    ... on Agenda {
                        ...AgendaContent
                    }
                    ... on AgendaItem {
                        ...AgendaItemContent
                    }
                    ... on Flowchart {
                        ...FlowchartContent
                    }
                    ... on FlowchartItem {
                        ...FlowchartItemContent
                    }
                    ... on Asset {
                        ...AssetContent
                    }
                }
            }
        }
        ${Fragments.agendaContent}
        ${Fragments.agendaItemContent}
        ${Fragments.flowchartContent}
        ${Fragments.flowchartItemContent}
        ${Fragments.assetContent}
    `;

    public static readonly getMyClientSelectionBoards: DocumentNode = gql`
        query getMyClientSelectionBoards($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        selectionBoards {
                            count
                            result {
                                ...SelectionBoard
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.selectionBoard}
    `;

    public static readonly getAgendaById: DocumentNode = gql`
        query getAgendaById($agendaId: ID!) {
            getAgendaById(agendaId: $agendaId) {
                ...Agenda
            }
        }
        ${Fragments.agenda}
    `;

    public static readonly getAgendaItemById: DocumentNode = gql`
        query getAgendaItemById($agendaItemId: ID!) {
            getAgendaItemById(agendaItemId: $agendaItemId) {
                ...AgendaItem
            }
        }
        ${Fragments.agendaItem}
    `;

    public static readonly getAgendaItemByIdWithClientData: DocumentNode = gql`
        query getAgendaItemByIdWithClientData($agendaItemId: ID!, $clientId: ID!) {
            getAgendaItemById(agendaItemId: $agendaItemId) {
                ...AgendaItem
            }
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        config {
                            moodMeter {
                                enabled
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.agendaItem}
    `;

    public static readonly getFlowchartItemById: DocumentNode = gql`
        query getFlowchartItemById($flowchartItemId: ID!) {
            getFlowchartItemById(flowchartItemId: $flowchartItemId) {
                ...FlowchartItem
            }
        }
        ${Fragments.flowchartItem}
    `;

    public static readonly getFlowchartItemByIdWithClientData: DocumentNode = gql`
        query getFlowchartItemByIdWithClientData($flowchartItemId: ID!, $clientId: ID!) {
            getFlowchartItemById(flowchartItemId: $flowchartItemId) {
                ...FlowchartItem
            }
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        config {
                            moodMeter {
                                enabled
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.flowchartItem}
    `;

    public static readonly getAdditionalFunctions: DocumentNode = gql`
        query getAdditionalFunctions($clientId: ID!) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        config {
                            moodMeter {
                                enabled
                            }
                        }
                    }
                }
            }
        }
    `;

    public static readonly getFlowchartById: DocumentNode = gql`
        query getFlowchartById($flowchartId: ID!) {
            getFlowchartById(flowchartId: $flowchartId) {
                ...Flowchart
            }
        }
        ${Fragments.flowchart}
    `;

    public static readonly getClientCalendar: DocumentNode = gql`
        query getClientCalendar($clientId: ID!, $dayFrom: Date!, $dayTo: Date!) {
            search(options: { clientId: $clientId, dayFrom: $dayFrom, dayTo: $dayTo, types: [agenda] }) {
                result {
                    ... on Agenda {
                        ...CalendarAgenda
                    }
                }
            }
        }
        ${Fragments.calendarAgenda}
    `;

    public static readonly getSupporterIdByEmail: DocumentNode = gql`
        query getSupporterIdByEmail($email: String!) {
            getSupporterIdByEmail(email: $email)
        }
    `;

    public static readonly getAppEventLogList: DocumentNode = gql`
        query getAppEventLogList(
            $type: AppEventLogName
            $authAccountId: ID
            $accountId: ID
            $clientId: ID
            $authAccountExtId: ID
            $clientExtId: ID
            $options: AppEventLogListOptionsInput
            $date: DateFilter!
        ) {
            getAppEventLogList(
                type: $type
                authAccountId: $authAccountId
                accountId: $accountId
                clientId: $clientId
                authAccountExtId: $authAccountExtId
                clientExtId: $clientExtId
                options: $options
                date: $date
            ) {
                count
                aggregations {
                    ...Aggregations
                }
                result {
                    ...AppEventLog
                }
            }
        }
        ${Fragments.aggregations}
        ${Fragments.appEventLog}
    `;

    public static readonly getMyAssetById: DocumentNode = gql`
        query getMyAssetById($assetId: ID!) {
            getAssetById(assetId: $assetId) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly getTags: DocumentNode = gql`
        query getTags($options: TagListInput) {
            getTags(options: $options) {
                count
                result {
                    ...Tag
                }
            }
        }
        ${Fragments.tag}
    `;

    public static readonly getAdminTags: DocumentNode = gql`
        query getAdminTags($options: TagListInput) {
            getTags(options: $options) {
                count
                result {
                    ...AdminTag
                }
            }
        }
        ${Fragments.adminTag}
    `;

    public static readonly getClientSpareAgendaItems: DocumentNode = gql`
        query getClientSpareAgendaItems($clientId: ID!) {
            getClientSpareAgendaItems(clientId: $clientId) {
                ...AgendaItemContent
            }
        }
        ${Fragments.agendaItemContent}
    `;

    public static readonly getEverydaySituationClientDirectoryByClientId: DocumentNode = gql`
        query getEverydaySituationClientDirectoryByClientId($id: ID!) {
            getEverydaySituationClientDirectoryByClientId(id: $id) {
                ...EverydaySituationClientDirectory
            }
        }
        ${Fragments.everydaySituationClientDirectory}
    `;

    public static readonly getEverydaySituationDirectoriesByClientId: DocumentNode = gql`
        query getEverydaySituationDirectoriesByClientId($id: ID!) {
            getEverydaySituationDirectoriesByClientId(id: $id) {
                ...EverydaySituationDirectoryWithItemCount
            }
        }
        ${Fragments.everydaySituationDirectoryWithItemCount}
    `;

    public static readonly getEverydaySituationDirectoryById: DocumentNode = gql`
        query getEverydaySituationDirectoryById($id: ID!) {
            getEverydaySituationDirectoryById(id: $id) {
                ...EverydaySituationDirectory
            }
        }
        ${Fragments.everydaySituationDirectory}
    `;

    public static readonly getFaqs: DocumentNode = gql`
        query getFaqs {
            getFaqItemList {
                ...Faq
            }
        }
        ${Fragments.faq}
    `;

    public static readonly getFaqById: DocumentNode = gql`
        query getFaqById($faqItemId: ID!) {
            getFaqItemById(faqItemId: $faqItemId) {
                ...Faq
            }
        }
        ${Fragments.faq}
    `;

    public static readonly getPersonalAssetDirectoriesByParentId: DocumentNode = gql`
        query getPersonalAssetDirectoriesByParentId($parentId: ID) {
            getPersonalAssetDirectoriesByParentId(parentId: $parentId) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly getPublicAssetDirectoriesByParentId: DocumentNode = gql`
        query getPublicAssetDirectoriesByParentId($parentId: ID) {
            getPublicAssetDirectoriesByParentId(parentId: $parentId) {
                ...AssetDirectory
            }
        }
        ${Fragments.assetDirectory}
    `;

    public static readonly getPersonalAssetDirectoryById: DocumentNode = gql`
        query getPersonalAssetDirectoryById($assetDirectoryId: ID!) {
            getPersonalAssetDirectoryById(assetDirectoryId: $assetDirectoryId) {
                ...AssetDirectoryData
            }
        }
        ${Fragments.assetDirectoryData}
    `;

    public static readonly getPublicAssetDirectoryById: DocumentNode = gql`
        query getPublicAssetDirectoryById($assetDirectoryId: ID!) {
            getPublicAssetDirectoryById(assetDirectoryId: $assetDirectoryId) {
                ...AssetDirectoryData
            }
        }
        ${Fragments.assetDirectoryData}
    `;

    public static readonly getClientGameConfig: DocumentNode = gql`
        query getClientGameConfig($clientId: ID!) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        config {
                            ...ClientGamesConfig
                        }
                    }
                }
            }
        }
        ${Fragments.clientGamesConfig}
    `;

    public static readonly getClientMemoryGameConfig: DocumentNode = gql`
        query getClientMemoryGameConfig($clientId: ID!) {
            getClientMemoryGameConfig(clientId: $clientId) {
                ...MemoryGameConfig
            }
        }
        ${Fragments.memoryGameConfig}
    `;

    public static readonly getClientSortingGameConfig: DocumentNode = gql`
        query getClientSortingGameConfig($clientId: ID!) {
            getClientSortingGameConfig(clientId: $clientId) {
                ...Flowchart
            }
        }
        ${Fragments.flowchart}
    `;

    public static readonly getClientInstantAwards: DocumentNode = gql`
        query getClientInstantAwards($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        instantAwards {
                            result {
                                ...InstantAward
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.instantAward}
    `;

    public static readonly getClientAwards: DocumentNode = gql`
        query getClientAwards($clientId: ID) {
            me {
                id
                myClients(clientId: $clientId) {
                    result {
                        id
                        extId
                        awards {
                            result {
                                ...Award
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.award}
    `;

    public static readonly getDisabledPublicContent: DocumentNode = gql`
        query getDisabledPublicContent($options: DisabledContentListOptionsInput) {
            getDisabledContentList(options: $options) {
                ...DisabledPublicContentList
            }
        }
        ${Fragments.disabledPublicContentList}
    `;

    public static readonly getEducationModules: DocumentNode = gql`
        query getEducationModules {
            educationModules {
                ...EducationContentModuleListItem
            }
        }
        ${Fragments.educationContentModuleList}
    `;

    public static readonly getEducationModuleTabs: DocumentNode = gql`
        query getEducationModuleTabs {
            educationModules {
                ...EducationContentModuleTab
            }
        }
        ${Fragments.educationContentModuleTab}
    `;

    public static readonly getEducationModuleById: DocumentNode = gql`
        query getEducationModuleById($id: ID!) {
            educationModule(id: $id) {
                ...EducationContentModule
            }
        }
        ${Fragments.educationContentModule}
    `;

    public static readonly getClientEverydaySituationDirectoryList: DocumentNode = gql`
        query getClientEverydaySituationDirectoryList($clientExtId: ID!, $options: EverydaySituationDirectoryListOptionsInput) {
            getClientByExtId(extId: $clientExtId) {
                id
                extId
                everydaySituationDirectories(options: $options) {
                    count
                    result {
                        ...EverydaySituationDirectoryList
                    }
                }
            }
        }
        ${Fragments.everydaySituationDirectoryList}
    `;

    public static readonly getClientInstantAwardList: DocumentNode = gql`
        query getClientInstantAwardList($clientExtId: ID!, $options: InstantAwardListOptionsInput) {
            getClientByExtId(extId: $clientExtId) {
                id
                extId
                instantAwards(options: $options) {
                    count
                    result {
                        ...InstantAwardListItem
                    }
                }
            }
        }
        ${Fragments.instantAwardListItem}
    `;

    public static readonly getClientAwardList: DocumentNode = gql`
        query getClientAwardList($clientExtId: ID!, $options: AwardListOptionsInput) {
            getClientByExtId(extId: $clientExtId) {
                id
                extId
                awards(options: $options) {
                    count
                    result {
                        ...AwardListItem
                    }
                }
            }
        }
        ${Fragments.awardListItem}
    `;

    public static readonly getClientSelectionBoardList: DocumentNode = gql`
        query getClientSelectionBoardList($clientExtId: ID!, $options: SelectionBoardListOptionsInput) {
            getClientByExtId(extId: $clientExtId) {
                id
                extId
                selectionBoards(options: $options) {
                    count
                    result {
                        ...SelectionBoardListItem
                    }
                }
            }
        }
        ${Fragments.selectionBoardListItem}
    `;

    public static readonly getClientSelectionBoardById: DocumentNode = gql`
        query getClientSelectionBoardById($clientExtId: ID!, $options: SelectionBoardListOptionsInput) {
            getClientByExtId(extId: $clientExtId) {
                id
                extId
                selectionBoards(options: $options) {
                    count
                    result {
                        ...SelectionBoard
                    }
                }
            }
        }
        ${Fragments.selectionBoard}
    `;

    public static readonly getPendingLibraryContentShareRequests: DocumentNode = gql`
        query getPendingLibraryContentShareRequests($options: LibraryContentShareRequestListInput) {
            me {
                id
                pendingLibraryContentShareRequests(options: $options) {
                    count
                    result {
                        ...LibraryContentShareRequestListItem
                    }
                }
            }
        }
        ${Fragments.libraryContentShareRequestListItem}
    `;

    public static readonly getMyClientsLogs: DocumentNode = gql`
        query getMyClientsLogs($date: DateFilter!) {
            me {
                id
                myClients {
                    result {
                        id
                        name
                        avatar {
                            id
                            url
                            thumbnailUrl
                        }
                        logs(date: $date) {
                            count
                            aggregations {
                                ...Aggregations
                            }
                            result {
                                createdAt
                            }
                        }
                    }
                }
            }
        }
        ${Fragments.aggregations}
    `;

    public static readonly getAppStatItemList: DocumentNode = gql`
        query getAppStatItemList($date: DateFilter!, $clientExtId: ID, $clientProfile: String, $options: AppStatItemListOptionsInput, $type: AppStatItemType) {
            getAppStatItemList(date: $date, clientExtId: $clientExtId, clientProfile: $clientProfile, options: $options, type: $type) {
                count
                result {
                    ...AppStatItem
                }
            }
        }
        ${Fragments.appStatItem}
    `;

    public static readonly getPendingLibraryContentShareRequestById: DocumentNode = gql`
        query getPendingLibraryContentShareRequestById($id: ID!) {
            getPendingLibraryContentShareRequestById(id: $id) {
                ...LibraryContentShareRequestWithData
            }
        }
        ${Fragments.libraryContentShareRequestWithData}
    `;
}

export { Queries };

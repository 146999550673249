import React from "react";
import ReactDOM from "react-dom";

import "styles/inspinia/style.scss";
import "./App.scss";

import { AppContainer } from "./AppContainer";

import { Log } from "utils/Log";
import { art } from "utils/Art";

Log.init();
art();

ReactDOM.render(<AppContainer />, document.getElementById("root") as HTMLElement);

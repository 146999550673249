import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { SortOrder, ListControl, SearchListSortField, SearchListOptionsInput, FlowchartListItem, SupportedClient, AccountType, AssetType } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { ListResult, ApiTypes } from "api/ApiTypes";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { PageContent, ContentPageUtils } from "../ContentPageUtils";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect } from "react-redux";
import { DisabledLock } from "components/DisabledLock";
import { isNil } from "lodash";
import { OverlayIcon } from "components/OverlayIcon";

interface ComponentProps {
    client?: SupportedClient | null;
    search?: string;
    tags?: string[];
    isPublic?: boolean;
    pageContent: PageContent;
    currentUrlParams: string;
    getFlowcharts: (options?: SearchListOptionsInput, tags?: string[]) => Promise<ListResult<FlowchartListItem>>;
    supporterExtId?: string | null;
    supervisorId?: string | null;
    clientExtId?: string | null;
    accountType: AccountType;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

interface FlowchartTableSearchListOptions {
    sortField?: SearchListSortField | null;
    control: ListControl;
}

interface State {
    flowcharts: FlowchartListItem[];
    count: number;
    isLoading: boolean;
    options: FlowchartTableSearchListOptions;
    tags: string[];
    flowchartToShare: FlowchartListItem | null;
    flowchartToClient: FlowchartListItem | null;
    flowchartToToggle: FlowchartListItem | null;
}

enum FlowchartTableColumn {
    title = "title",
    info = "info",
    numberOfSteps = "numberOfSteps",
    createdAt = "createdAt",
    createdByName = "createdByName",
    updatedAt = "updatedAt",
    actions = "actions",
}

class FlowchartTableComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): FlowchartTableSearchListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.flowchartTable").parse(
            props.location.search,
        );
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search: props.search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    private static getInitialTags = (props: Props): string[] => {
        return props.tags || [];
    };

    public readonly state: State = {
        flowcharts: [],
        isLoading: true,
        count: 0,
        options: FlowchartTableComponent.getInitialOptions(this.props),
        tags: FlowchartTableComponent.getInitialTags(this.props),
        flowchartToShare: null,
        flowchartToClient: null,
        flowchartToToggle: null,
    };

    public componentDidMount(): void {
        this.refreshFlowcharts(this.state.options, this.state.tags);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: FlowchartTableSearchListOptions = FlowchartTableComponent.getInitialOptions(nextProps);
        const nextTags: string[] = FlowchartTableComponent.getInitialTags(nextProps);
        if (!isEqual(this.state.options, nextOptions) || this.props.pageContent !== nextProps.pageContent || !isEqual(this.state.tags, nextTags)) {
            this.setState({ options: nextOptions, tags: nextTags }, () => {
                this.updateQueryParams();
            });
        }
    }

    private refreshFlowcharts = (options?: SearchListOptionsInput, tags?: string[]): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await this.props.getFlowcharts(options, tags);
                    this.setState({ flowcharts: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, flowcharts: [] });
                }
            },
        );
    };

    private onDeleteClick = (flowchart: FlowchartListItem) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteFlowchart,
                onDeleted: () => {
                    this.refreshFlowcharts(this.state.options, this.state.tags);
                },
                flowchart,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<FlowchartListItem>> => {
        let columnNames: FlowchartTableColumn[] = ObjectUtils.enumAsArray<FlowchartTableColumn>(FlowchartTableColumn);

        if (!this.props.isPublic || this.props.accountType !== AccountType.supervisor) {
            columnNames = columnNames.filter((columnName: FlowchartTableColumn) => columnName !== FlowchartTableColumn.createdByName);
        }

        return columnNames.map(
            (columnName: FlowchartTableColumn): Column<FlowchartListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.flowchartTable.columns.${columnName}` }),
                accessor: columnName as keyof FlowchartListItem,
                renderCell: (flowchart: FlowchartListItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case FlowchartTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    clientExtId={this.props.client ? this.props.client.extId : undefined}
                                    supporterExtId={this.props.supporterExtId}
                                    supervisorId={this.props.supervisorId}
                                    content={flowchart}
                                    onDeleteClick={this.onDeleteClick(flowchart)}
                                    onShareClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.shareContent,
                                                content: flowchart,
                                                onSucceed: () => this.refreshFlowcharts(this.state.options, this.state.tags),
                                            }),
                                        );
                                    }}
                                    onAssignToClientClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.assignToClient,
                                                content: flowchart,
                                            }),
                                        );
                                    }}
                                    onToggled={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.contentToggler,
                                                onToggled: (): void => this.refreshFlowcharts(this.state.options, this.state.tags),
                                                content: flowchart,
                                            }),
                                        );
                                    }}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: flowchart,
                                                clientId: this.props.client?.id,
                                                onDisabled: (): void => this.refreshFlowcharts(this.state.options, this.state.tags),
                                            }),
                                        );
                                    }}
                                    refreshContents={() => this.refreshFlowcharts(this.state.options, this.state.tags)}
                                />
                            );
                        case FlowchartTableColumn.info:
                            return (
                                <DisabledLock isVisible={!isNil(flowchart.disabledAt)} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.flowchartTable.disabledTooltip" })} />
                            );
                        case FlowchartTableColumn.createdAt:
                        case FlowchartTableColumn.updatedAt:
                            return <>{DateUtils.format(new Date(flowchart[columnName]), DateFormat.dateTime)}</>;
                        case FlowchartTableColumn.createdByName:
                            return <>{flowchart.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</>;
                        case FlowchartTableColumn.numberOfSteps:
                            return <>{flowchart.itemList ? flowchart.itemList.length : 0}</>;
                        case FlowchartTableColumn.title:
                            const assetUrl = flowchart.image ? ApiTypes.getAssetImageUrl(flowchart.image) : undefined;
                            return (
                                <>
                                    <div className="table-image-name">
                                        <div
                                            className="table-image-container"
                                            onClick={
                                                assetUrl && flowchart.image
                                                    ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                          event.stopPropagation();
                                                          this.props.dispatch(
                                                              DialogsActions.show({
                                                                  type: DialogType.showAsset,
                                                                  assetUrl: flowchart.image?.url,
                                                                  assetType: flowchart.image?.assetType || AssetType.image,
                                                                  originalFileName: flowchart.image?.originalFileName,
                                                                  thumbnailUrl: flowchart.image?.thumbnailUrl,
                                                                  dialogTitle: flowchart.image?.title || Intl.formatMessage({ id: `enum.assetType.${AssetType.image}` }),
                                                              }),
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <Image src={assetUrl} fallback={ImageSrc.flowchart} />
                                            {assetUrl && flowchart.image && (
                                                <>
                                                    <div className="content-overlay" />
                                                    <div className="content-overlay-icon">
                                                        <OverlayIcon assetType={flowchart.image.assetType} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {flowchart.title}
                                    </div>
                                </>
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: !ObjectUtils.isEnumContains<SearchListSortField>(SearchListSortField, columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<SearchListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.flowchartTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshFlowcharts(this.state.options, this.state.tags);
    };

    private convertColumnIdToSortField = (columnId?: string): SearchListSortField | undefined => {
        switch (columnId) {
            case FlowchartTableColumn.createdAt:
                return SearchListSortField.createdAt;
            case FlowchartTableColumn.updatedAt:
                return SearchListSortField.updatedAt;
            case FlowchartTableColumn.title:
                return SearchListSortField.title;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: SearchListSortField | null): keyof FlowchartListItem | undefined => {
        switch (columnId) {
            case SearchListSortField.createdAt:
                return FlowchartTableColumn.createdAt;
            case SearchListSortField.updatedAt:
                return FlowchartTableColumn.updatedAt;
            case SearchListSortField.title:
                return FlowchartTableColumn.title;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<FlowchartListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    public render(): React.ReactElement<any> | null {
        return (
            <>
                <Table
                    keyExtractor={(item: FlowchartListItem, column?: Column<FlowchartListItem>): string => {
                        return `${item.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                        order: this.state.options.control.sortOrder || undefined,
                    }}
                    data={this.state.flowcharts}
                    count={this.state.count}
                    limit={this.state.options.control.limit}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={true}
                    currentPage={this.getCurrentPage()}
                    isLoading={this.state.isLoading}
                    onRowClick={(item: FlowchartListItem): void => {
                        const path: string | undefined = ContentPageUtils.getViewPath(
                            item,
                            this.props.pageContent,
                            this.props.client ? this.props.client.extId : undefined,
                            this.props.supporterExtId,
                            undefined,
                            this.props.supervisorId,
                        );
                        if (path) {
                            this.props.history.push(path);
                        }
                    }}
                    renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.flowchartTable.noData" })}
                />
            </>
        );
    }
}

export const FlowchartTable = withRouter(connect()(FlowchartTableComponent));

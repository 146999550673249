import React, { PureComponent } from "react";
import { ImageSrc } from "utils/ImageSrc";

interface Props {
    fallback?: string;
    src?: string;
    className?: string;
    alt?: string;
    title?: string;
    style?: React.CSSProperties;
}

interface State {
    isFailed: boolean;
}

class Image extends PureComponent<Props, State> {
    public readonly state: State = {
        isFailed: false,
    };

    private onError = (_event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
        this.setState({ isFailed: true });
    };

    public render(): React.ReactElement {
        const { fallback, src, ...otherProps } = this.props;
        if (this.state.isFailed || !src) {
            return <img src={this.props.fallback || ImageSrc.asset} {...otherProps} />;
        }

        return <img src={src} {...otherProps} onError={this.onError} />;
    }
}

export { Image };

import React, { ComponentType } from "react";
import { Diff } from "utils/TypeUtils";
import { AnyContentType } from "api/ApiTypes";
import { DragType } from "./DragUtils";
import { DraggableContentType } from "./DragContainer";

export type { DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";

export enum DragEvent {
    dragStart = "dragStart",
    dragEnd = "dragEnd",
    addClick = "addClick",
}

export type DropResult = {
    destination?: {
        index: number;
        droppableId: string;
    };
    source: {
        index: number;
        droppableId: string;
    };
};
export type ResponderProvided = {};
export type DragStart = {
    source?: {
        droppableId?: string;
    };
    draggableId: string;
};

export type onDragStart = (initial: DragStart, provided: ResponderProvided, type: DragType | null) => void;
export type onDragEnd = (result: DropResult, provided: ResponderProvided, item?: AnyContentType) => void;
export type onAddClick = (item: AnyContentType, type: DragType | null) => boolean; // return true, when add handled

export type DragCallback = onDragStart | onDragEnd;
export type DragListenerParam = { event: DragEvent.dragStart; listener: onDragStart } | { event: DragEvent.dragEnd; listener: onDragEnd } | { event: DragEvent.addClick; listener: onAddClick };

export interface DragProps {
    addDragListener: (listenerParam: DragListenerParam) => void;
    removeDragListener: (listenerParam: DragListenerParam) => void;
    onDragStart: onDragStart;
    onDragEnd: onDragEnd;
    getDraggableById: (draggableId: string) => DraggableContentType | undefined;
}

export const DragContext = React.createContext<DragProps>({
    addDragListener: () => {},
    removeDragListener: () => {},
    onDragStart: (_initial: DragStart, _provided: ResponderProvided, _type: DragType | null) => {},
    onDragEnd: (_result: DropResult, _provided: ResponderProvided, _item?: AnyContentType) => {},
    getDraggableById: (_draggableId: string) => {
        return undefined;
    },
});

export function withDragContext<P extends DragProps>(WrappedComponent: ComponentType<P>) {
    return class WithDrag extends React.Component<Diff<P, DragProps>> {
        public render() {
            return (
                <DragContext.Consumer>
                    {({ addDragListener, removeDragListener }) => <WrappedComponent {...(this.props as P)} addDragListener={addDragListener} removeDragListener={removeDragListener} />}
                </DragContext.Consumer>
            );
        }
    };
}

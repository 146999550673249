import React, { Component } from "react";
import { AgendaInput, Agenda, SupportedClient, AccountType, Account, ContentClient } from "api/graphql/types";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { AgendaForm } from "./AgendaForm";
import { PageType } from "utils/TypeUtils";
import { ApiTypes } from "api/ApiTypes";
import { AccountSelectors } from "selectors/AccountSelectors";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";

interface ReduxProps {
    account: Account;
    client: SupportedClient | null;
}

interface RouteParams {
    agendaId?: string;
    clientExtId?: string;
    supporterExtId?: string;
    supervisorId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    agenda: Agenda;
    isLoading: boolean;
}

class AgendaPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !!props.match.params.agendaId,
            agenda: {
                id: "",
                title: "",
                description: "",
                client: (props.client as unknown) as ContentClient,
                itemList: [],
                __typename: "Agenda",
                day: null,
                createdBy: { id: props.account.id, extId: props.account.extId, name: "", __typename: "Account" },
                disabledAt: null,
                updatedAt: null,
                createdAt: null,
                publishedAt: null,
                tags: [],
                shareRequests: null,
            },
        };
    };

    public readonly state: State = AgendaPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshAgenda(this.props);
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.agendaId !== nextProps.match.params.agendaId) {
            this.refreshAgenda(nextProps);
        }
    }

    private refreshAgenda = (props: Props): void => {
        const { agendaId } = props.match.params;
        if (!agendaId) {
            this.setState(AgendaPageComponent.getInitialStateFromProps(props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const agenda: Agenda = await Api.getAgendaById(agendaId);
                    this.setState({ isLoading: false, agenda });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private onSubmit = async (agenda: Agenda): Promise<Agenda> => {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const agendaInput: AgendaInput = ApiTypes.agendaToAgendaInput(agenda);

        if (pageType === PageType.create) {
            const result = await Api.createAgenda(agendaInput);
            return result;
        } else if (pageType === PageType.edit) {
            const result = await Api.updateAgenda(agenda.id, agendaInput);
            this.setState({ agenda: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    private onEditClick = (): void => {
        const pageContent: PageContent = ContentPageUtils.getPageContent(this.props.match.path, !!this.state.agenda.publishedAt);
        const path: string | undefined = ContentPageUtils.getEditPath(this.state.agenda, pageContent, this.props.match.params.clientExtId);
        if (path) {
            this.props.history.push(path);
        }
    };

    private onToggled = (): void => {
        const agenda = { ...this.state.agenda };
        agenda.disabledAt = agenda.disabledAt ? null : DateUtils.format(new Date(), DateFormat.default);
        this.setState({ agenda });
    };

    public render(): React.ReactElement<any> {
        if (this.props.match.params.clientExtId && this.props.account.accountType === AccountType.supporter && !this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        const { agenda, isLoading } = this.state;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        return (
            <AgendaForm
                myAccount={this.props.account}
                clientExtId={this.props.match.params.clientExtId}
                supporterExtId={this.props.match.params.supporterExtId}
                supervisorId={this.props.match.params.supervisorId}
                pageContent={ContentPageUtils.getPageContent(this.props.match.path, !!this.state.agenda.publishedAt)}
                client={this.props.client}
                agenda={agenda}
                isLoading={isLoading}
                pageType={pageType}
                onSubmit={this.onSubmit}
                onEditClick={this.onEditClick}
                onToggled={this.onToggled}
                accountType={this.props.account.accountType}
                refreshAgenda={(): void => {
                    return this.refreshAgenda(this.props);
                }}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    return { account: state.account!, client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId) };
};

export const AgendaPage = withRouter(connect(mapStateToProps)(AgendaPageComponent));

// tslint:disable-next-line: no-default-export
export default AgendaPage;

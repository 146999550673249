import * as React from "react";

function SvgSupporterManagerNew(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.791 11.339c.755-.755 1.67-1.132 2.749-1.132 1.078 0 1.994.377 2.749 1.132.754.755 1.132 1.67 1.132 2.749 0 1.078-.378 1.994-1.132 2.749-.755.754-1.671 1.131-2.75 1.131-1.077 0-1.993-.377-2.748-1.131-.755-.755-1.132-1.671-1.132-2.75 0-1.077.377-1.993 1.132-2.748zm4.474 3.084V14.1c0-.18-.09-.27-.27-.27h-1.024v-1.455c0-.18-.09-.27-.27-.27h-.323c-.18 0-.27.09-.27.27v2.048c0 .18.09.27.27.27h1.617c.18 0 .27-.09.27-.27z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9 7.018a1.43 1.43 0 001.05-.43c.306-.306.458-.666.458-1.079 0-.413-.152-.763-.458-1.05A1.389 1.389 0 0010.9 4c-.414 0-.774.153-1.079.458A1.43 1.43 0 009.39 5.51c0 .413.144.773.431 1.078.306.288.665.431 1.078.431zm1.986 8.193H6.803l-2.75-5.686A.434.434 0 014 9.336c0-.162.063-.287.189-.377l.781-.431c.216-.144.413-.1.593.134.108.144.26.27.458.378.198.09.386.134.566.134h.081c.341-.018.629-.161.862-.43.234-.27.35-.585.35-.944 0-.09.037-.17.109-.243a.335.335 0 01.242-.107h1.051c.198 0 .315.09.35.269.054.288.207.53.459.728.251.197.52.296.808.296.288 0 .557-.099.809-.296.251-.198.404-.44.458-.728.036-.18.152-.27.35-.27h1.051c.09 0 .17.036.243.108a.335.335 0 01.107.243c0 .323.1.62.297.89.197.25.45.403.755.457.071.018.152.027.242.027.18 0 .368-.045.566-.134.198-.108.35-.234.458-.378.18-.233.377-.278.593-.134l.782.43c.125.09.188.207.188.351v.008a5.378 5.378 0 00-.258-.007c-1.313 0-2.459.47-3.383 1.395-.925.925-1.395 2.07-1.395 3.383 0 .39.041.764.124 1.123zm1.63 2.587h-8.36a.44.44 0 01-.323-.135.4.4 0 01-.108-.296v-.862c0-.126.036-.234.108-.324a.501.501 0 01.323-.108h7.02c.232.509.559.976.98 1.398.117.116.237.225.36.327z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgSupporterManagerNew;

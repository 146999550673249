// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function DirectoryIcon({ width = 140, height = 148 }: IconProps): React.ReactElement {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 6C0 2.68629 2.68629 0 6 0H54C57.3137 0 60 2.68629 60 6V8H134C137.314 8 140 10.6863 140 14V142C140 145.314 137.314 148 134 148H6C2.6863 148 0 145.314 0 142V15.375V14V6Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6C0 2.68629 2.68629 0 6 0H54C57.3137 0 60 2.68629 60 6V8H134C137.314 8 140 10.6863 140 14V142C140 145.314 137.314 148 134 148H6C2.6863 148 0 145.314 0 142V15.375V14V6Z"
                fill="white"
            />
            <path
                d="M60 8H58V10H60V8ZM6 -2C1.58172 -2 -2 1.58172 -2 6H2C2 3.79086 3.79086 2 6 2V-2ZM54 -2H6V2H54V-2ZM62 6C62 1.58172 58.4183 -2 54 -2V2C56.2091 2 58 3.79086 58 6H62ZM62 8V6H58V8H62ZM60 10H134V6H60V10ZM134 10C136.209 10 138 11.7909 138 14H142C142 9.58172 138.418 6 134 6V10ZM138 14V142H142V14H138ZM138 142C138 144.209 136.209 146 134 146V150C138.418 150 142 146.418 142 142H138ZM134 146H6V150H134V146ZM6 146C3.79086 146 2 144.209 2 142H-2C-2 146.418 1.58173 150 6 150V146ZM2 142V15.375H-2V142H2ZM2 15.375V14H-2V15.375H2ZM-2 6V14H2V6H-2Z"
                fill="#00459B"
                mask="url(#path-1-inside-1)"
            />
        </svg>
    );
}

export { DirectoryIcon };

import React, { PureComponent } from "react";
import { Image } from "components/Image";
import { DirectoryIcon } from "components/Icons/DirectoryIcon";
import { AssetType, AtomicAsset } from "api/graphql/types";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Intl } from "i18n/Intl";

import "./DirectoryImage.scss";

interface Props {
    asset?: AtomicAsset;
    fallback?: string;
    className?: string;
    disableOverlay?: boolean;
    disableHover?: boolean;
}

class DirectoryImageComponent extends PureComponent<Props> {
    public render(): React.ReactElement {
        const { disableHover, disableOverlay, asset, ...otherProps } = this.props;
        return (
            <div className="directory-image">
                <div className={`icon-and-image-container ${this.props.className || ""}`}>
                    <DirectoryIcon />
                    <div
                        className={`image-container ${disableHover ? "no-hover" : ""}`}
                        onClick={
                            disableOverlay
                                ? undefined
                                : () => {
                                      store.dispatch(
                                          DialogsActions.show({
                                              type: DialogType.showAsset,
                                              assetUrl: asset?.url,
                                              assetType: asset?.assetType || AssetType.image,
                                              originalFileName: asset?.originalFileName,
                                              thumbnailUrl: asset?.thumbnailUrl,
                                              dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                          }),
                                      );
                                  }
                        }
                    >
                        <Image src={asset?.thumbnailUrl || asset?.url} {...otherProps} />
                        {!disableOverlay && (
                            <>
                                <div className="content-overlay" />
                                <div className="content-overlay-icon">
                                    <span className="fa fa-search-plus" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export const DirectoryImage: React.ComponentClass<Props> = DirectoryImageComponent;

import React, { PureComponent, ReactElement } from "react";

import "./CardNotification.scss";

interface CardNotificationComponentProps {
    title: string;
    description?: string;
    type: "success" | "warning" | "error";
    button?: string;
    onButtonClick?: () => void;
    renderButton?: React.ReactNode;
    className?: string;
}

type Props = CardNotificationComponentProps;

class CardNotification extends PureComponent<Props> {
    public render(): ReactElement<any> {
        const { title, description, type, button, onButtonClick, renderButton } = this.props;
        return (
            <div className={`card card-notification ${this.props.className || ""}`}>
                <div className="icon">
                    <i className={`fas ${type === "success" ? "fa-check" : "fa-exclamation-triangle"} text-${type}`} />
                </div>
                <div className="content">
                    <div className="title">{title}</div>
                    {description ? <div className="description">{description}</div> : null}
                    {button && onButtonClick ? (
                        <button className="btn btn-primary mt-3" onClick={onButtonClick}>
                            {button}
                        </button>
                    ) : null}
                    {renderButton}
                </div>
            </div>
        );
    }
}

export { CardNotification };

import { Intl } from "i18n/Intl";
import { Path } from "utils/Path";
import { AccountType, SupportedClient } from "api/graphql/types";
import { Env } from "utils/Env";

export interface SubNavItem {
    key: string;
    label: string;
    path: string;
    icon: string;
    hasBadge?: boolean;
    isParent?: boolean;
}

export interface MainNavItem {
    key: string;
    label: string;
    path?: string;
    icon?: string;
    items: SubNavItem[];
    imageUrl?: string;
    isClient?: boolean;
    hasBadge?: boolean;
}

export type NavItem = SubNavItem | MainNavItem;

export class NavItems {
    private static readonly dashboard: NavItem = { key: "dashboard", label: Intl.formatMessage({ id: "component.navigation.menu.dashboard" }), path: Path.dashboard, icon: "fa-home", isParent: true };
    private static readonly knowledgeBase: NavItem = {
        key: "knowledgeBase",
        label: Intl.formatMessage({ id: "component.navigation.menu.knowledgeBase" }),
        icon: "fa-book",
        items: [
            { key: "education", label: Intl.formatMessage({ id: "component.navigation.menu.education" }), path: Path.education, icon: "fa-book" },
            { key: "faq", label: Intl.formatMessage({ id: "component.navigation.menu.faq" }), path: Path.faqList, icon: "fa-question-circle" },
            { key: "helpdesk", label: Intl.formatMessage({ id: "component.navigation.menu.helpdesk" }), path: Env.helpdeskUrl ?? Path.dashboard, icon: "fa-info-circle" },
        ],
    };

    private static readonly adminKnowledgeBase: NavItem = {
        key: "knowledgeBase",
        label: Intl.formatMessage({ id: "component.navigation.menu.knowledgeBase" }),
        icon: "fa-book",
        items: [
            { key: "educationModules", label: Intl.formatMessage({ id: "component.navigation.menu.educationModules" }), path: Path.educationModuleList, icon: "fa-book" },
            { key: "faq", label: Intl.formatMessage({ id: "component.navigation.menu.faq" }), path: Path.faqList, icon: "fa-question-circle" },
            { key: "helpdesk", label: Intl.formatMessage({ id: "component.navigation.menu.helpdesk" }), path: Env.helpdeskUrl ?? Path.dashboard, icon: "fa-info-circle" },
        ],
    };

    private static readonly systemEventLog: NavItem = {
        key: "systemEventLog",
        label: Intl.formatMessage({ id: "component.navigation.menu.systemEventLog" }),
        path: Path.systemEventLog,
        icon: "fa-calendar-check",
        isParent: true,
    };

    private static readonly publicContentLibrary: NavItem = {
        key: "publicContentLibrary",
        label: Intl.formatMessage({ id: "component.navigation.menu.publicContentLibrary" }),
        path: Path.publicContentLibrary,
        icon: "fa-list-alt",
        isParent: true,
    };

    private static readonly publicContentLibrarySuperadmin: NavItem = {
        key: "publicContentLibrary",
        label: Intl.formatMessage({ id: "component.navigation.menu.publicContentLibrarySuperadmin" }),
        path: Path.publicContentLibrary,
        icon: "fa-list-alt",
        isParent: true,
    };

    private static getSupporterNavItems(clients: SupportedClient[]): NavItem[] {
        const clientsNavItems: NavItem[] = clients.map(
            (client: SupportedClient): NavItem => ({
                key: client.extId,
                label: client.name,
                imageUrl: client.avatar ? client.avatar.url : undefined,
                items: [
                    {
                        key: "clientProfile",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientProfile" }),
                        path: Path.clientProfile(client.extId!),
                        icon: "fa-user",
                        hasBadge: client.showSelfSupportingStateDecisionNotification || false,
                    },
                    {
                        key: "clientContentLibrary",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientContentLibrary" }),
                        path: Path.clientContentLibrary(client.extId!),
                        icon: "fa-list-alt",
                    },
                    { key: "clientCalendar", label: Intl.formatMessage({ id: "component.navigation.menu.clientCalendar" }), path: Path.clientCalendar(client.extId!), icon: "fa-calendar-alt" },
                    {
                        key: "clientAwards",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientAwards" }),
                        path: Path.clientAwardsBase(client.extId!),
                        icon: "fa-star",
                    },
                    {
                        key: "clientSelectionBoard",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientSelectionBoard" }),
                        path: Path.clientSelectionBoards(client.extId!),
                        icon: "fa-map-signs",
                    },
                    {
                        key: "everydaySituations",
                        label: Intl.formatMessage({ id: "component.navigation.menu.everydaySituations" }),
                        path: Path.clientEverydaySituations(client.extId!),
                        icon: "fa-compass",
                    },
                    {
                        key: "clientGames",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientGames" }),
                        path: Path.clientGames(client.extId!),
                        icon: "fa-gamepad",
                    },
                    {
                        key: "clientSpareAgendaItems",
                        label: Intl.formatMessage({ id: "component.navigation.menu.clientSpareAgendaItems" }),
                        path: Path.clientSpareAgendaItems(client.extId!),
                        icon: "fa-archive",
                    },
                    { key: "clientEventLog", label: Intl.formatMessage({ id: "component.navigation.menu.clientEventLog" }), path: Path.clientEventLog(client.extId!), icon: "fa-calendar-check" },
                    { key: "clientSettings", label: Intl.formatMessage({ id: "component.navigation.menu.clientSettings" }), path: Path.clientSettings(client.extId!), icon: "fa-sliders-h" },
                ],
                isClient: true,
                hasBadge: client.showSelfSupportingStateDecisionNotification || false,
            }),
        );

        return [NavItems.dashboard, ...clientsNavItems, NavItems.publicContentLibrary, NavItems.knowledgeBase];
    }

    private static getSupervisorNavItems(): NavItem[] {
        const users: NavItem = {
            key: "users",
            icon: "fa-user-circle",
            label: Intl.formatMessage({ id: "component.navigation.menu.users" }),
            items: [
                { key: "supporterList", label: Intl.formatMessage({ id: "component.navigation.menu.supporterList" }), path: Path.supporterList, icon: "fa-user-friends" },
                { key: "clientList", label: Intl.formatMessage({ id: "component.navigation.menu.clientList" }), path: Path.clientList, icon: "fa-user" },
            ],
        };

        const contentLibrary: NavItem = {
            key: "publicContentLibrary",
            label: Intl.formatMessage({ id: "component.navigation.menu.publicContentLibrary" }),
            path: Path.publicContentLibrary,
            icon: "fa-list-alt",
            items: [
                {
                    key: "ownContentLibrary",
                    label: Intl.formatMessage({ id: "component.navigation.menu.ownContentLibrary" }),
                    path: Path.ownContentLibrary,
                    icon: "fa-list-alt",
                },

                {
                    key: "contentShareRequests",
                    label: Intl.formatMessage({ id: "component.navigation.menu.contentShareRequestList" }),
                    path: Path.contentShareRequestList,
                    icon: "fa-list-alt",
                },
            ],
        };

        return [NavItems.dashboard, users, contentLibrary, NavItems.systemEventLog, NavItems.knowledgeBase];
    }

    private static getAdminNavItems(isSuperadmin?: boolean): NavItem[] {
        const users: NavItem = {
            key: "users",
            icon: "fa-user-circle",
            label: Intl.formatMessage({ id: "component.navigation.menu.users" }),
            items: [
                { key: "adminList", label: Intl.formatMessage({ id: "component.navigation.menu.adminList" }), path: Path.adminList, icon: "fa-users-cog" },
                { key: "supervisorList", label: Intl.formatMessage({ id: "component.navigation.menu.supervisorList" }), path: Path.supervisorList, icon: "fa-users" },
                { key: "supporterList", label: Intl.formatMessage({ id: "component.navigation.menu.supporterList" }), path: Path.supporterList, icon: "fa-user-friends" },
                { key: "clientList", label: Intl.formatMessage({ id: "component.navigation.menu.clientList" }), path: Path.clientList, icon: "fa-user" },
            ],
        };

        return [NavItems.dashboard, users, isSuperadmin ? NavItems.publicContentLibrarySuperadmin : NavItems.publicContentLibrary, NavItems.systemEventLog, NavItems.adminKnowledgeBase];
    }

    private static getSuperAdminNavItems(): NavItem[] {
        const tags: NavItem = {
            key: "tags",
            icon: "fa-tags",
            label: Intl.formatMessage({ id: "component.navigation.menu.tags" }),
            path: Path.tags,
            isParent: true,
        };

        return [...NavItems.getAdminNavItems(true), tags];
    }

    public static get(accountType: AccountType | null, clients: SupportedClient[]): NavItem[] {
        switch (accountType) {
            case AccountType.superadmin:
                return NavItems.getSuperAdminNavItems();
            case AccountType.admin:
                return NavItems.getAdminNavItems();
            case AccountType.supervisor:
                return NavItems.getSupervisorNavItems();
            case AccountType.supporter:
                return NavItems.getSupporterNavItems(clients);
            default:
                return [];
        }
    }

    public static isSubNavMenu(navItem: NavItem): navItem is SubNavItem {
        return !(navItem as any).items;
    }

    public static isMainNavItem(navItem: NavItem): navItem is MainNavItem {
        return !NavItems.isSubNavMenu(navItem);
    }
}

import { SelectOption } from "components/Inputs/Select/Select";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";

export type MoodMeterQuestionSelectOption = SelectOption<MoodMeterQuestion | null>;

export enum MoodMeterQuestion {
    k1 = "k1",
    k2 = "k2",
    k3 = "k3",
    k4 = "k4",
}

class MoodMeterQuestionSelectOptions {
    public static getForFunction(): MoodMeterQuestionSelectOption[] {
        return [MoodMeterQuestion.k3, MoodMeterQuestion.k4].map(
            (moodMeterQuestion: MoodMeterQuestion | null): MoodMeterQuestionSelectOption => {
                return { id: `${moodMeterQuestion}`, label: Intl.formatMessage({ id: `enum.moodMeterQuestion.${moodMeterQuestion}` }), value: moodMeterQuestion };
            },
        );
    }

    public static getForContent(): MoodMeterQuestionSelectOption[] {
        return [null, ...ObjectUtils.enumAsArray<MoodMeterQuestion>(MoodMeterQuestion)].map(
            (moodMeterQuestion: MoodMeterQuestion | null): MoodMeterQuestionSelectOption => {
                return { id: `${moodMeterQuestion}`, label: Intl.formatMessage({ id: `enum.moodMeterQuestion.${moodMeterQuestion}` }), value: moodMeterQuestion };
            },
        );
    }
}

export { MoodMeterQuestionSelectOptions };

import React, { PureComponent } from "react";

import "./Loading.scss";

export enum LoadingType {
    input = "input",
    layer = "layer",
}

interface Props {
    type?: LoadingType;
}

class Loading extends PureComponent<Props> {
    private getTypeClassName(): string {
        switch (this.props.type) {
            case LoadingType.input:
                return " input";
            case LoadingType.layer:
                return " layered";
            default:
                return "";
        }
    }

    public render(): React.ReactElement<any> {
        return (
            <div className={`loading${this.getTypeClassName()}`}>
                <div className="loading-animation">
                    <svg id="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160">
                        <title>loader</title>
                        <g id="white-BG">
                            <circle cx="80" cy="80" r="80" fill="#fff" />
                        </g>
                        <g id="circle" opacity="0.1">
                            <path d="M80,149a69,69,0,1,1,69-69A69.07,69.07,0,0,1,80,149ZM80,21a59,59,0,1,0,59,59A59.07,59.07,0,0,0,80,21Z" fill="#0d325f" />
                        </g>
                        <path id="spinner" d="M144,85a5,5,0,0,1-5-5A59.07,59.07,0,0,0,80,21a5,5,0,0,1,0-10,69.07,69.07,0,0,1,69,69A5,5,0,0,1,144,85Z" fill="#4ec1f9" />
                    </svg>
                </div>
            </div>
        );
    }
}

export { Loading };

import React from "react";
import { CalendarCheck, Book, Tags } from "../Svg";

type Props = {
    icon: string;
    title?: string;
};

export class MenuIcon extends React.PureComponent<Props> {
    render() {
        const { icon, title } = this.props;

        if (icon === "fa-book") {
            return (
                <i className="fa" title={title}>
                    <Book />
                </i>
            );
        }

        if (icon === "fa-calendar-check") {
            return (
                <i className="fa" title={title}>
                    <CalendarCheck />
                </i>
            );
        }

        if (icon === "fa-user-circle") {
            return <i className="fa fa-users" title={title} />;
        }

        if (icon === "fa-tags") {
            return (
                <i className="fa" title={title}>
                    <Tags />
                </i>
            );
        }

        return <i className={`fa ${icon}`} title={title} />;
    }
}

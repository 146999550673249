import React, { Component } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { AuthSelectors } from "selectors/AuthSelectors";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Title } from "components/Title";
import { AccountActions } from "actions/AccountActions";

interface ReduxProps {
    isLoggedIn: boolean;
}

interface RouteParams {
    data?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<RouteParams>;

class EmailActivationPageComponent extends Component<Props> {
    private readonly id?: string;
    private readonly code?: string;
    private readonly redirectPath: Path;

    constructor(props: Props) {
        super(props);
        const { data } = this.props.match.params;
        if (data) {
            const [id, code] = data.split(":");
            this.id = id;
            this.code = code;
        }

        this.redirectPath = this.props.isLoggedIn ? Path.login : Path.dashboard;
    }

    public async componentDidMount(): Promise<void> {
        if (this.id && this.code) {
            await this.activateEmailAddress();
        }
    }

    private activateEmailAddress = async (): Promise<void> => {
        try {
            await Api.changeEmailByCode(this.id!, this.code!);
            Alert.success({
                title: Intl.formatMessage({ id: "page.emailActivation.activationSucceed.message" }),
                callback: async (): Promise<void> => {
                    try {
                        const account = await Api.me();
                        this.props.dispatch(AccountActions.updateAccount(account));
                    } catch (error) {
                        Alert.error({
                            title: IntlHelpers.getMessageFromError(error),
                            callback: () => {
                                this.props.history.replace(this.redirectPath);
                            },
                        });
                    }
                    this.props.history.replace(this.redirectPath);
                },
            });
        } catch (error) {
            let message: string = IntlHelpers.getMessageFromError(error);
            if (error instanceof ApiError && error.code === ApiErrorCode.ACCOUNT_NOT_FOUND) {
                message = Intl.formatMessage({ id: "page.emailActivation.activationFailed.message" });
            }
            Alert.error({
                title: message,
                callback: () => {
                    this.props.history.replace(this.redirectPath);
                },
            });
        }
    };

    public render(): React.ReactElement<any> | null {
        if (!this.id || !this.code) {
            return <Redirect to={this.props.isLoggedIn ? Path.login : Path.dashboard} />;
        }

        return <Title>{Intl.formatMessage({ id: "page.emailActivation.title" })}</Title>;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { isLoggedIn: AuthSelectors.isLoggedIn(state) };
};

export const EmailActivationPage = withRouter(connect(mapStateToProps)(EmailActivationPageComponent));

// tslint:disable-next-line: no-default-export
export default EmailActivationPage;

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Navigation } from "components/Navigation/Navigation";
import { GuestRoute } from "components/Route/GuestRoute";
import { PrivateRoute } from "components/Route/PrivateRoute";
import { LogoutPage } from "pages/LogoutPage/LogoutPage";
import { AppPath } from "utils/Path";
import { Env } from "utils/Env";
import { loadReCaptcha } from "react-recaptcha-v3";
import { Log } from "utils/Log";
import { Pages } from "pages/Pages";
import { Api } from "api/Api";
import * as GqlTypes from "api/graphql/types";
import { AccountActions } from "actions/AccountActions";
import { SettingsActions } from "actions/SettingsActions";
import { Loading, LoadingType } from "components/Loading/Loading";
import { AlertContainer } from "components/Alert/AlertContainer";
import { ApplicationState } from "reducers";
import { AuthSelectors } from "selectors/AuthSelectors";
import { AuthActions } from "actions/AuthActions";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogsContainer, DialogType } from "components/DialogContainer/DialogsContainer";
import { TagsActions } from "actions/TagsActions";
import { AccountSelectors } from "selectors/AccountSelectors";
import { DialogsActions } from "actions/DialogsActions";

interface ReduxProps {
    clientsWithTodo: GqlTypes.SupportedClient[];
    isLoggedIn: boolean;
}

type Props = ReduxProps & DispatchProp;

interface State {
    isLoading: boolean;
}

class AppComponent extends React.Component<Props, State> {
    public readonly state: Readonly<State> = {
        isLoading: this.props.isLoggedIn,
    };

    public componentDidMount(): void {
        if (Env.recaptchaSiteKey) {
            loadReCaptcha(Env.recaptchaSiteKey);
        } else {
            Log.error(new Error("Env.recaptchaSiteKey not set"));
        }

        if (this.props.isLoggedIn) {
            this.refreshAppData();
        }
    }

    public componentWillReceiveProps(nextProps: Readonly<Props>) {
        if (!this.props.isLoggedIn && nextProps.isLoggedIn) {
            this.refreshAppData();
        }
    }

    private refreshAppData = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const appData: GqlTypes.appData = await Api.getAppData();
                    this.props.dispatch(AccountActions.updateAccount(appData.me));
                    this.props.dispatch(SettingsActions.updateSettings(appData.getCmsSettings));

                    if (this.props.clientsWithTodo.length > 0) {
                        this.props.dispatch(DialogsActions.show({ type: DialogType.clientTodo, clientsWithTodo: this.props.clientsWithTodo }));
                    }

                    const tags: GqlTypes.getTags = await Api.getTags();
                    this.props.dispatch(TagsActions.updateTags(tags.getTags?.result || []));
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.props.dispatch(AuthActions.logout());
                }
                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement | null {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }
        return (
            <Navigation>
                <Switch>
                    <Route exact={true} path={AppPath.login} component={Pages.auth} />
                    <Route exact={true} path={AppPath.registration} component={Pages.auth} />
                    <Route exact={true} path={AppPath.emailActivation} component={Pages.emailActivation} />
                    <GuestRoute exact={true} path={AppPath.forgotPassword} component={Pages.forgotPassword} />
                    <GuestRoute path={AppPath.accountActivation} component={Pages.accountActivation} />
                    <GuestRoute path={AppPath.resetPassword} component={Pages.resetPassword} />
                    <PrivateRoute exact={true} path={AppPath.clientShareRequest} component={Pages.clientShareRequest} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.dashboard} component={Pages.dashboard} />
                    <PrivateRoute exact={true} path={AppPath.profile} component={Pages.profile} />
                    <PrivateRoute exact={true} path={AppPath.addClient} component={Pages.addClient} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.myEventLog}
                        component={Pages.myEventLog}
                        availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.appEventLog}
                        component={Pages.systemEventLog}
                        availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.cmsEventLog}
                        component={Pages.systemEventLog}
                        availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <Redirect from={AppPath.systemEventLog} to={AppPath.appEventLog} />
                    <PrivateRoute exact={true} path={AppPath.publicContentLibrary} component={Pages.publicContentLibrary} />
                    <PrivateRoute exact={true} path={AppPath.ownContentLibrary} component={Pages.publicContentLibrary} />
                    <PrivateRoute exact={true} path={AppPath.contentShareRequestList} component={Pages.contentShareRequestList} availableFor={[GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.contentShareRequest} component={Pages.contentShareRequest} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewAgendaShareRequest} availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.supporter]} component={Pages.agenda} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewAgendaItemShareRequest}
                        availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.supporter]}
                        component={Pages.agendaItemPage}
                    />
                    <PrivateRoute exact={true} path={AppPath.viewFlowchartShareRequest} availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.supporter]} component={Pages.flowchart} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewFlowchartItemShareRequest}
                        availableFor={[GqlTypes.AccountType.supervisor, GqlTypes.AccountType.supporter]}
                        component={Pages.flowchartItem}
                    />
                    <PrivateRoute exact={true} path={AppPath.replaceAssetItem} component={Pages.replaceAssetItem} />
                    {/* Supporter content Pages */}
                    <PrivateRoute exact={true} path={AppPath.editAgendaAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.viewAgendaAgendaItem} component={Pages.agendaItemPage} />
                    <PrivateRoute exact={true} path={AppPath.createAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.editAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.viewAgendaItem} component={Pages.agendaItemPage} />
                    <PrivateRoute exact={true} path={AppPath.createAgenda} component={Pages.agenda} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.editAgenda} component={Pages.agenda} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.viewAgenda} component={Pages.agenda} />
                    <PrivateRoute exact={true} path={AppPath.createFlowchart} component={Pages.flowchart} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.editFlowchart} component={Pages.flowchart} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.viewFlowchart} component={Pages.flowchart} />
                    <PrivateRoute exact={true} path={AppPath.createFlowchartItem} component={Pages.flowchartItem} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.editFlowchartItem} component={Pages.flowchartItem} availableFor={[GqlTypes.AccountType.supporter, GqlTypes.AccountType.supervisor]} />
                    <PrivateRoute exact={true} path={AppPath.viewFlowchartItem} component={Pages.flowchartItem} />
                    {/** Supporter's client Routes */}
                    <PrivateRoute exact={true} path={AppPath.clientProfile} component={Pages.clientProfile} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientQuestionnaire} component={Pages.clientQuestionnairePage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientCalendar} component={Pages.clientCalendar} />
                    <PrivateRoute exact={true} path={AppPath.clientInstantAwards} component={Pages.clientAwardsBase} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientInstantAward} component={Pages.clientInstantAward} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientInstantAward} component={Pages.clientInstantAward} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientAwards} component={Pages.clientAwardsBase} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientAward} component={Pages.clientAward} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientAward} component={Pages.clientAward} availableFor={[GqlTypes.AccountType.supporter]} />
                    <Redirect from={AppPath.clientAwardsBase} to={AppPath.clientInstantAwards} />
                    <PrivateRoute exact={true} path={AppPath.clientSelectionBoards} component={Pages.clientSelectionBoards} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createSelectionBoard} component={Pages.clientSelectionBoard} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewSelectionBoard} component={Pages.clientSelectionBoard} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editSelectionBoard} component={Pages.clientSelectionBoard} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.createClientEverydaySituationsDirectory}
                        component={Pages.clientEverydaySituationsDirectory}
                        availableFor={[GqlTypes.AccountType.supporter]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.editClientEverydaySituationsDirectory}
                        component={Pages.clientEverydaySituationsDirectory}
                        availableFor={[GqlTypes.AccountType.supporter]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientEverydaySituationsClientDirectory}
                        component={Pages.clientEverydaySituationsClientDirectory}
                        availableFor={[GqlTypes.AccountType.supporter]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientEverydaySituationsDirectories}
                        component={Pages.clientEverydaySituationsClientDirectory}
                        availableFor={[GqlTypes.AccountType.supporter]}
                    />
                    <Redirect from={AppPath.clientEverydaySituations} to={AppPath.clientEverydaySituationsClientDirectory} />
                    <PrivateRoute exact={true} path={AppPath.clientContentLibrary} component={Pages.clientContentLibrary} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientEventLog} component={Pages.clientEventLog} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientMemoryGames} component={Pages.clientGames} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientSortingGames} component={Pages.clientGames} availableFor={[GqlTypes.AccountType.supporter]} />
                    <Redirect from={AppPath.clientGames} to={AppPath.clientMemoryGames} />
                    <PrivateRoute exact={true} path={AppPath.clientAppSettings} component={Pages.clientSettings} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientMainPageSettings} component={Pages.clientSettings} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientContentsSettings} component={Pages.clientSettings} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.clientFunctionsSettings} component={Pages.clientSettings} availableFor={[GqlTypes.AccountType.supporter]} />
                    <Redirect from={AppPath.clientSettings} to={AppPath.clientAppSettings} />
                    <PrivateRoute exact={true} path={AppPath.editClientAgendaAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewClientAgendaAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewClientAgendaItem} component={Pages.agendaItemPage} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientAgenda} component={Pages.agenda} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientAgenda} component={Pages.agenda} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewClientAgenda} component={Pages.agenda} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientFlowchart} component={Pages.flowchart} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientFlowchart} component={Pages.flowchart} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewClientFlowchart} component={Pages.flowchart} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.createClientFlowchartItem} component={Pages.flowchartItem} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.editClientFlowchartItem} component={Pages.flowchartItem} availableFor={[GqlTypes.AccountType.supporter]} />
                    <PrivateRoute exact={true} path={AppPath.viewClientFlowchartItem} component={Pages.flowchartItem} availableFor={[GqlTypes.AccountType.supporter]} />
                    {/** Admin routes */}
                    <PrivateRoute exact={true} path={AppPath.adminList} component={Pages.adminList} availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]} />
                    <PrivateRoute exact={true} path={AppPath.adminDetailProfile} component={Pages.adminDetail} availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]} />
                    <PrivateRoute exact={true} path={AppPath.adminDetailEventLog} component={Pages.adminDetail} availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]} />
                    <Redirect from={AppPath.adminDetail} to={AppPath.adminDetailProfile} />

                    <PrivateRoute exact={true} path={AppPath.supervisorList} component={Pages.supervisorList} availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]} />
                    <PrivateRoute exact={true} path={AppPath.supervisorDetailProfile} component={Pages.supervisorDetail} availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supervisorDetailContentLibrary}
                        component={Pages.supervisorDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supervisorDetailEventLog}
                        component={Pages.supervisorDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supervisorDetailProfile}
                        component={Pages.supporterDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    {/** SupervisorDetail routes */}
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupervisorDetailAgenda}
                        component={Pages.agenda}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupervisorDetailAgendaItem}
                        component={Pages.agendaItemPage}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupervisorDetailFlowchart}
                        component={Pages.flowchart}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupervisorDetailFlowchartItem}
                        component={Pages.flowchartItem}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupervisorDetailAsset}
                        component={Pages.assetPage}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <Redirect from={AppPath.supervisorDetail} to={AppPath.supervisorDetailProfile} />
                    {/* Supervisor routes */}
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supporterList}
                        component={Pages.supporterList}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientList}
                        component={Pages.clientList}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    {/** SupporterDetail routes */}
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supporterDetailProfile}
                        component={Pages.supporterDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupporterDetailAgenda}
                        component={Pages.agenda}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupporterDetailAgendaItem}
                        component={Pages.agendaItemPage}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupporterDetailFlowchart}
                        component={Pages.flowchart}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupporterDetailFlowchartItem}
                        component={Pages.flowchartItem}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewSupporterDetailAsset}
                        component={Pages.assetPage}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supporterDetailContentLibrary}
                        component={Pages.supporterDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.supporterDetailEventLog}
                        component={Pages.supporterDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <Redirect from={AppPath.supporterDetail} to={AppPath.supporterDetailProfile} />
                    {/* ClientDetail Routes  */}
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientDetailProfile}
                        component={Pages.clientDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientDetailAgendaItem}
                        component={Pages.agendaItemPage}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientDetailAgenda}
                        component={Pages.agenda}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientDetailFlowchart}
                        component={Pages.flowchart}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientDetailFlowchartItem}
                        component={Pages.flowchartItem}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientEverydaySituationDirectory}
                        component={Pages.clientEverydaySituationDirectory}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.viewClientSelectionBoard}
                        component={Pages.clientSelectionBoard}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientDetailContentLibrary}
                        component={Pages.clientDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.clientDetailEventLog}
                        component={Pages.clientDetail}
                        availableFor={[GqlTypes.AccountType.superadmin, GqlTypes.AccountType.admin, GqlTypes.AccountType.supervisor]}
                    />
                    <Redirect from={AppPath.clientDetail} to={AppPath.clientDetailProfile} />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.educationModuleList}
                        component={Pages.educationModuleListPage}
                        availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.educationSubModuleList}
                        component={Pages.educationSubModuleListPage}
                        availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.createEducationSubModule}
                        component={Pages.educationSubModulePage}
                        availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <PrivateRoute
                        exact={true}
                        path={AppPath.editEducationSubModule}
                        component={Pages.educationSubModulePage}
                        availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]}
                    />
                    <PrivateRoute exact={true} path={AppPath.faqList} component={Pages.faqList} />
                    <PrivateRoute exact={true} path={AppPath.createFaq} component={Pages.faq} availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]} />
                    <PrivateRoute exact={true} path={AppPath.editFaq} component={Pages.faq} availableFor={[GqlTypes.AccountType.admin, GqlTypes.AccountType.superadmin]} />
                    <PrivateRoute exact={true} path={AppPath.educationGeneralTab} component={Pages.education} />
                    <PrivateRoute exact={true} path={AppPath.educationModuleTab} component={Pages.education} />
                    <Redirect from={AppPath.education} to={AppPath.educationGeneralTab} />
                    <PrivateRoute exact={true} path={AppPath.changeClientManagerRequest} component={Pages.changeClientManagerRequest} />
                    <PrivateRoute exact={true} path={AppPath.viewAsset} component={Pages.assetPage} />
                    <PrivateRoute exact={true} path={AppPath.editAsset} component={Pages.assetPage} />
                    <PrivateRoute exact={true} path={AppPath.clientSpareAgendaItems} component={Pages.clientSpareAgendaItems} />
                    <PrivateRoute exact={true} availableFor={[GqlTypes.AccountType.superadmin]} path={AppPath.tags} component={Pages.tagsPage} />
                    <PrivateRoute exact={true} availableFor={[GqlTypes.AccountType.superadmin]} path={AppPath.createTag} component={Pages.createTagPage} />
                    <Route exact={true} path={AppPath.logout} component={LogoutPage} />
                    {Env.debugMode && <Route exact={true} path={AppPath.sandbox} component={Pages.sandboxPage} />}
                    <Redirect from="*" to={AppPath.login} />
                </Switch>

                <DialogsContainer />
                <AlertContainer />
            </Navigation>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { clientsWithTodo: AccountSelectors.getClientsWithTodo(state), isLoggedIn: AuthSelectors.isLoggedIn(state) };
};

export const App = connect(mapStateToProps)(AppComponent);

import React, { Component } from "react";
import { AccountType, Faq, CreateFaqItemInput, UpdateFaqItemInput } from "api/graphql/types";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { Api } from "api/Api";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils } from "pages/_shared/ContentPageUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { FaqForm } from "./FaqForm";
import { isNil } from "lodash";

type FaqItemInput = CreateFaqItemInput | UpdateFaqItemInput;

interface RouteParams {
    faqId?: string;
}

interface ReduxProps {
    accountType: AccountType;
}

type ComponentProps = RouteComponentProps<RouteParams> & ReduxProps;

type Props = ComponentProps;

interface State {
    faq: FaqItemInput;
    isLoading: boolean;
}

class FaqPageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.faqId),
            faq: {
                question: "",
                answer: "",
            },
        };
    };

    public readonly state: State = FaqPageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshFaq(this.props);
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.faqId !== this.props.match.params.faqId) {
            this.refreshFaq(this.props);
        }
    }

    private refreshFaq = (props: Props): void => {
        const { faqId } = props.match.params;
        if (!faqId) {
            this.setState(FaqPageComponent.getInitialStateFromProps(props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const faq: Faq = await Api.getFaqById(faqId);
                    this.setState({ isLoading: false, faq: this.getFaqInput(faq) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private getFaqInput = (faq: Faq | FaqItemInput): CreateFaqItemInput => {
        return {
            question: faq.question || "",
            answer: faq.answer || "",
        };
    };

    private onSubmit = async (faq: FaqItemInput): Promise<FaqItemInput> => {
        const faqId: string | undefined = this.props.match.params.faqId;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);

        if (pageType === PageType.create) {
            const result = await Api.createFaq(this.getFaqInput(faq));
            this.setState({ faq: result });
            return result;
        } else if (pageType === PageType.edit && !isNil(faqId)) {
            const result = await Api.updateFaq(faqId, faq);
            this.setState({ faq: result });
            return result;
        } else {
            throw new Error("View cannot submit!");
        }
    };

    public render(): React.ReactElement {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const isPermissionOk: boolean = [AccountType.admin, AccountType.superadmin].includes(this.props.accountType);
        const isFaqIdPresentInEditMode: boolean = pageType === PageType.create || (pageType === PageType.edit && !isNil(this.props.match.params.faqId));

        if (!isFaqIdPresentInEditMode || !isPermissionOk) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return <FaqForm pageType={pageType} faq={this.state.faq} onSubmit={this.onSubmit} />;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountType: state.account!.accountType };
};

export const FaqPage = withRouter(connect(mapStateToProps)(FaqPageComponent));

// tslint:disable-next-line: no-default-export
export default FaqPage;

import React, { Component } from "react";

interface Props {
    label: string | React.ReactElement;
    noTopMargin?: boolean;
    children?: React.ReactNode;
}

class Section extends Component<Props> {
    public render(): React.ReactElement<any> {
        return (
            <div className="section">
                <h2 className={`${this.props.noTopMargin ? "no-top-margin" : ""}`}>{this.props.label}</h2>
                {this.props.children}
            </div>
        );
    }
}

export { Section };

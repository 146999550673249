import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Page } from "components/Page";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { SupporterDetail, AccountType } from "api/graphql/types";
import { TabPanel } from "components/TabPanel/TabPanel";
import { SupporterDetailProfileTab } from "./SupporterDetailProfileTab/SupporterDetailProfileTab";
import { SupporterDetailContentLibraryTab } from "./SupporterDetailContentLibraryTab";
// import { SupporterDetailEventLogTab } from "./SupporterDetailEventLogTab";
import { Formatter } from "utils/Formatter";
import AccountEventLogTable from "pages/_shared/AccountEventLogTable/AccountEventLogTable";

interface Params {
    supporterExtId: string;
}

type Props = RouteComponentProps<Params>;

interface State {
    isLoading: boolean;
    supporter: SupporterDetail | null;
    isDeleteDialogVisible: boolean;
}

class SupervisorDetailPageComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        supporter: null,
        isDeleteDialogVisible: false,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.supporterExtId !== nextProps.match.params.supporterExtId) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const supporter: SupporterDetail = await Api.getSupporterByExtId(props.match.params.supporterExtId);
                    this.setState({ supporter, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.supporterList);
                        },
                    });
                }
            },
        );
    }

    public render(): React.ReactElement<any> | null {
        if (this.state.isLoading && !this.state.supporter) {
            return <Loading type={LoadingType.layer} />;
        }

        const { supporter } = this.state;
        if (!supporter) {
            return <Redirect to={Path.supporterList} />;
        }

        const { supporterExtId } = this.props.match.params;
        return (
            <Page title={Intl.formatMessage({ id: "page.supporterDetail.title" }, { extId: Formatter.formatExtId(supporterExtId) })} hasTabs={true}>
                <div className="left-side">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.title" }),
                                label: Intl.formatMessage({ id: "page.supporterDetail.tabs.profile.title" }),
                                to: Path.supporterDetailProfile(supporterExtId),
                                component: SupporterDetailProfileTab,
                            },
                            {
                                id: Intl.formatMessage({ id: "page.supporterDetail.tabs.contentLibrary.title" }),
                                label: Intl.formatMessage({ id: "page.supporterDetail.tabs.contentLibrary.title" }),
                                to: Path.supporterDetailContentLibrary(supporterExtId),
                                component: SupporterDetailContentLibraryTab,
                                additionalProps: { supporter },
                            },
                            {
                                id: Intl.formatMessage({ id: "page.supporterDetail.tabs.eventLog.title" }),
                                label: Intl.formatMessage({ id: "page.supporterDetail.tabs.eventLog.title" }),
                                to: Path.supporterDetailEventLog(supporterExtId),
                                component: AccountEventLogTable,
                                additionalProps: { viewedAccountType: AccountType.supporter, authAccountExtId: supporter.extId },
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

export const SupervisorDetailPage = withRouter(SupervisorDetailPageComponent);

// tslint:disable-next-line: no-default-export
export default SupervisorDetailPage;

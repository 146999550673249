import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Page } from "components/Page";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Account, AccountType } from "api/graphql/types";
import { TabPanel } from "components/TabPanel/TabPanel";
import { SupervisorDetailProfileTab } from "./SupervisorDetailProfileTab";
import { SupervisorDetailContentLibraryTab } from "./SupervisorDetailContentLibraryTab";
import AccountEventLogTable from "pages/_shared/AccountEventLogTable/AccountEventLogTable";

interface Params {
    accountId?: string;
}

type Props = RouteComponentProps<Params>;

interface State {
    isLoading: boolean;
    account: Account | null;
    isDeleteDialogVisible: boolean;
}

class SupervisorDetailPageComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        account: null,
        isDeleteDialogVisible: false,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.accountId !== nextProps.match.params.accountId) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const account: Account = await Api.getAccountById(props.match.params.accountId!);
                    this.setState({ account, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.supervisorList);
                        },
                    });
                }
            },
        );
    }

    public render(): React.ReactElement<any> | null {
        if (this.state.isLoading && !this.state.account) {
            return <Loading type={LoadingType.layer} />;
        }

        const { account } = this.state;
        if (!account) {
            return <Redirect to={Path.supporterList} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.supervisorDetail.title" }, { name: account.name })} hasTabs={true}>
                <div className="left-side">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.title" }),
                                label: Intl.formatMessage({ id: "page.supervisorDetail.tabs.profile.title" }),
                                to: Path.supervisorDetailProfile(account.id),
                                component: SupervisorDetailProfileTab,
                            },
                            {
                                id: Intl.formatMessage({ id: "page.supervisorDetail.tabs.contentLibrary.title" }),
                                label: Intl.formatMessage({ id: "page.supervisorDetail.tabs.contentLibrary.title" }),
                                to: Path.supervisorDetailContentLibrary(account.id),
                                component: SupervisorDetailContentLibraryTab,
                                additionalProps: { account },
                            },
                            {
                                id: Intl.formatMessage({ id: "page.supervisorDetail.tabs.eventLog.title" }),
                                label: Intl.formatMessage({ id: "page.supervisorDetail.tabs.eventLog.title" }),
                                to: Path.supervisorDetailEventLog(account.id),
                                component: AccountEventLogTable,
                                additionalProps: { viewedAccountType: AccountType.supervisor, authAccountId: account.id },
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

export const SupervisorDetailPage = withRouter(SupervisorDetailPageComponent);

// tslint:disable-next-line: no-default-export
export default SupervisorDetailPage;

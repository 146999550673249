// x: extra, d: dark, l: light
export enum Color {
    black = "#000000",
    error = "#e23637",
    gray = "#dfdfdf",
    pinkSwan = "#b4b4b4",
    grayD = "#6d6d6d",
    grayXl = "#fafbfd",
    grayXXl = "#fcfcfa",
    primary = "#143a74",
    primaryL2 = "#35598e",
    primaryL = "#00459b",
    primaryXl = "#b8c4d5",
    primaryXxl = "#f2f6fa",
    white = "#ffffff",
    lightGray = "#f5f5f5",
}

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { DialogVisibilityProps } from "./DialogsContainer";
import { TestId } from "utils/TestId";

export interface ClientQuestionnaireInfoDialogProps {
    title: string;
    description: string;
    buttonLabel: string;
    onClose: () => void;
}

type Props = ClientQuestionnaireInfoDialogProps & DialogVisibilityProps;

class ClientQuestionnaireInfoDialog extends PureComponent<Props> {
    private readonly onClose = () => {
        this.props.onClose();
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={this.props.title} visible={this.props.isVisible} onCloseClick={this.onClose}>
                <p>{this.props.description}</p>
                <hr />
                <div className="row">
                    <button id={TestId.addClient.goToClientProfileButton} className="btn btn-primary button-center" onClick={this.onClose}>
                        {this.props.buttonLabel}
                    </button>
                </div>
            </Dialog>
        );
    }
}

export { ClientQuestionnaireInfoDialog };

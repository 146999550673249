import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { Device, Agenda, AgendaItem, Flowchart, FlowchartItem } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ObjectUtils } from "utils/ObjectUtils";
import { DeviceDetect } from "utils/DeviceDetect";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { Log } from "utils/Log";

interface Props {
    content: Agenda | AgendaItem | Flowchart | FlowchartItem;
}

enum ContentDeviceListColumn {
    id = "id",
    deviceType = "deviceType",
    appVersion = "appVersion",
    createdAt = "createdAt",
    status = "status",
}

enum ContentDeviceSyncStatus {
    syncNotSelected = "syncNotSelected",
    syncSelectedButUnsynced = "syncSelectedButUnsynced",
    syncSelectedButOutdatedContent = "syncSelectedButOutdatedContent",
    synced = "synced",
    unknown = "unknown",
}

class ContentDeviceList extends Component<Props> {
    private getClientDeviceSyncStatus(device: Device): ContentDeviceSyncStatus {
        const lastModified: number = new Date(this.props.content.updatedAt || this.props.content.createdAt).getTime();
        const lastSyncDataGeneratedAt: number = new Date(this.props.content.client!.lastSyncDataGeneratedAt).getTime();
        const agendaDataLastDownloadedAt: number | null = device.agendaDataLastDownloadedAt ? new Date(device.agendaDataLastDownloadedAt).getTime() : null;

        if (!lastModified || !lastSyncDataGeneratedAt) {
            return ContentDeviceSyncStatus.unknown;
        }

        if (lastModified > lastSyncDataGeneratedAt) {
            return ContentDeviceSyncStatus.syncNotSelected;
        }

        if (lastModified <= lastSyncDataGeneratedAt && !agendaDataLastDownloadedAt) {
            return ContentDeviceSyncStatus.syncSelectedButUnsynced;
        }

        if (!!agendaDataLastDownloadedAt && lastModified <= lastSyncDataGeneratedAt) {
            if (agendaDataLastDownloadedAt < lastSyncDataGeneratedAt) {
                return ContentDeviceSyncStatus.syncSelectedButOutdatedContent;
            }

            if (agendaDataLastDownloadedAt >= lastSyncDataGeneratedAt) {
                return ContentDeviceSyncStatus.synced;
            }
        }

        return ContentDeviceSyncStatus.unknown;
    }

    private getColumns(): Array<Column<Device>> {
        return ObjectUtils.enumAsArray<ContentDeviceListColumn>(ContentDeviceListColumn).map(
            (columnName: ContentDeviceListColumn): Column<Device> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `component.contentDeviceList.table.columns.${columnName}` }),
                accessor: columnName as keyof Device,
                renderCell: (device: Device): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case ContentDeviceListColumn.status:
                            return <>{Intl.formatMessage({ id: `enum.contentDeviceSyncStatus.${this.getClientDeviceSyncStatus(device)}` })}</>;
                        case ContentDeviceListColumn.createdAt:
                            const day: Date | null = new Date(device[columnName]);
                            return <>{day ? DateUtils.format(day, DateFormat.yyyymmddhhmm) : ""}</>;
                        case ContentDeviceListColumn.deviceType:
                            const deviceInfo = DeviceDetect.getDeviceInfo(device);
                            return <>{deviceInfo}</>;
                        case ContentDeviceListColumn.appVersion:
                            return <>{DeviceDetect.getAppVersion(device.info.userAgent)}</>;
                        default:
                            return null;
                    }
                },
            }),
        );
    }

    public render(): React.ReactElement<any> | null {
        if (!this.props.content.client) {
            Log.warning("No client found!");
            return null;
        }

        const devices: Device[] = this.props.content.client.devices.result;

        if (devices.length === 0) {
            return (
                <p>
                    <strong>{Intl.formatMessage({ id: "component.contentDeviceList.noData" })}</strong>
                </p>
            );
        }

        return (
            <Table
                keyExtractor={(item: Device, column?: Column<Device>): string => item.id + (column ? column.id : "")}
                columns={this.getColumns()}
                data={devices}
                isLoading={false}
                currentPage={1}
                count={devices.length}
                isPaginationEnabled={false}
                renderEmpty={(): string => Intl.formatMessage({ id: "component.contentDeviceList.noData" })}
            />
        );
    }
}

export { ContentDeviceList };

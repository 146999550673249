import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "reducers";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import ReactTooltip from "react-tooltip";
import { compose } from "redux";
import { AppBar } from "../AppBar/AppBar";
import { Menu } from "./Menu";
import { Button } from "components/Button/Button";
import { Account, AccountType } from "api/graphql/types";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Path } from "utils/Path";
import { AuthSelectors } from "selectors/AuthSelectors";
import { DeviceUtils } from "utils/DeviceUtils";

interface ComponentProps {
    children?: React.ReactNode;
}

interface ReduxProps {
    account: Account | null;
    isLoggedIn: boolean;
}

type Props = ComponentProps & ReduxProps & DispatchProp & RouteComponentProps;

interface State {
    isOpened: boolean;
    isForcedOpened: boolean;
}

class NavigationComponent extends React.Component<Props, State> {
    private static readonly MIN_WINDOW_INNER_WIDTH: number = 1024;

    public componentDidUpdate(): void {
        ReactTooltip.rebuild();
    }

    public readonly state: State = {
        isOpened: !DeviceUtils.isTablet(),
        isForcedOpened: false,
    };

    public componentWillMount(): void {
        window.addEventListener("resize", this.checkWindowWidth);
    }

    public componentDidMount(): void {
        this.checkWindowWidth();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.checkWindowWidth);
    }

    private checkWindowWidth = (): void => {
        if (window.innerWidth <= NavigationComponent.MIN_WINDOW_INNER_WIDTH) {
            this.setState({ isOpened: false });
        } else if (!this.state.isForcedOpened && window.innerWidth > NavigationComponent.MIN_WINDOW_INNER_WIDTH) {
            this.setState({ isOpened: true });
        }
    };

    private onOpenChange = (): void => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened, isForcedOpened: prevState.isOpened }));
    };

    private onLogoutClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.logout,
                onLogoutClick: this.logout,
            }),
        );
    };

    private logout = (): void => {
        this.props.history.push(Path.logout);
    };

    public render(): React.ReactElement | null | undefined {
        if (!this.props.isLoggedIn) {
            return (
                <div className="full-screen gray-bg">
                    <AppBar onLogoutClick={this.onLogoutClick} />
                    {this.props.children}
                </div>
            );
        }
        const isSupervisor: boolean = !!this.props.account && this.props.account.accountType === AccountType.supervisor;
        return (
            <div className={`${isSupervisor ? "supervisor" : ""}`}>
                <nav className={`navbar-default navbar-static-side${!this.state.isOpened ? " collapsed" : ""}`} role="navigation">
                    <div className="sidebar-collapse">
                        <Menu />
                    </div>
                    <div className="navbar-toggler">
                        <Button link icon={{ name: "fa-chevron-left", large: true }} onClick={this.onOpenChange}></Button>
                    </div>
                </nav>

                <div id="page-wrapper" className="gray-bg">
                    <div className="row header">
                        <AppBar onLogoutClick={this.onLogoutClick} />
                    </div>

                    <div className="wrapper wrapper-content animated fadeIn scrollable">
                        {this.props.children}
                        <ReactTooltip className="navigation-tooltip" place="top" type="dark" effect="solid" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account,
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

export const Navigation = compose(withRouter, connect(mapStateToProps))(NavigationComponent) as React.ComponentClass<ComponentProps>;

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { TestId } from "utils/TestId";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteAccountConfirmDialogProps {
    onConfirmed: () => Promise<void> | void;
}

type Props = DeleteAccountConfirmDialogProps & DialogVisibilityProps;

interface State {
    isConfirmAgain: boolean;
}

class DeleteAccountConfirmDialog extends Component<Props, State> {
    private getInitialState(): State {
        return {
            isConfirmAgain: false,
        };
    }
    public readonly state: State = this.getInitialState();

    private onHide = (): void => {
        this.setState(this.getInitialState());
        this.props.onHide();
    };

    private onConfirmed = (): void => {
        this.props.onConfirmed();
        this.props.onHide();
    };

    private renderConfirmDialog(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.deleteAccountConfirmDialog.confirm.title" })} visible={this.props.isVisible} onCloseClick={this.onHide}>
                <p>{Intl.formatMessage({ id: "sharedComponent.deleteAccountConfirmDialog.confirm.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button id={TestId.clientProfile.deleteProfileDialog.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            id={TestId.clientProfile.deleteProfileDialog.acceptButton}
                            label={Intl.formatMessage({ id: "common.yes" })}
                            onClick={() => {
                                this.setState({ isConfirmAgain: true });
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

    private renderConfirmAgainDialog(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.deleteAccountConfirmDialog.confirmAgain.title" })} visible={this.props.isVisible} onCloseClick={this.onHide}>
                <p>{Intl.formatMessage({ id: "sharedComponent.deleteAccountConfirmDialog.confirmAgain.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button id={TestId.clientProfile.deleteProfileDialog.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            id={TestId.clientProfile.deleteProfileDialog.acceptButton}
                            label={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.onConfirmed}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }

    public render(): React.ReactElement<any> {
        return this.state.isConfirmAgain ? this.renderConfirmAgainDialog() : this.renderConfirmDialog();
    }
}

export { DeleteAccountConfirmDialog };

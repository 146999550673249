import React, { Component } from "react";
import { Section } from "components/Section";
import { Button } from "components/Button/Button";
import { ClientQuestionnaireQuestion, ClientQuestionnaireQuestion_answerOptions } from "api/graphql/types";
import { RadioInput, RadioOption } from "components/Inputs/RadioInput/RadioInput";
import { ClientQuestionnaireAnswer } from "api/ApiTypes";
import { Intl } from "i18n/Intl";
import { TestId } from "utils/TestId";

interface Props {
    questions: ClientQuestionnaireQuestion[];
    clientQuestionnaireAnswers: ClientQuestionnaireAnswer[];
    onPrevClick: (clientQuestionnaireAnswers: ClientQuestionnaireAnswer[]) => void;
    onAddClick: (clientQuestionnaireAnswers: ClientQuestionnaireAnswer[]) => void;
}

interface State {
    clientQuestionnaireAnswers: ClientQuestionnaireAnswer[];
}

class AddClientQuestionnaireForm extends Component<Props, State> {
    public readonly state: State = {
        clientQuestionnaireAnswers: [...this.props.clientQuestionnaireAnswers],
    };

    private onPrevClick = (): void => {
        this.props.onPrevClick(this.state.clientQuestionnaireAnswers);
    };

    private onAddClick = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        this.props.onAddClick(this.state.clientQuestionnaireAnswers);
    };

    private onAnswerChange = (index: number) => (selectedOption: RadioOption) => {
        const clientQuestionnaireAnswers = [...this.state.clientQuestionnaireAnswers];
        clientQuestionnaireAnswers[index] = { ...clientQuestionnaireAnswers[index], answerOptionId: selectedOption.id };
        this.setState({ clientQuestionnaireAnswers });
    };

    private renderQuestion = (question: ClientQuestionnaireQuestion, index: number, array: ClientQuestionnaireQuestion[]): React.ReactElement<any> => {
        const options: RadioOption[] = question.answerOptions.map((answer: ClientQuestionnaireQuestion_answerOptions) => {
            return {
                id: answer.id,
                label: answer.title,
            };
        });

        const isLastItem: boolean = index === array.length - 1;
        return (
            <React.Fragment key={question.id}>
                <h3>{question.title.toUpperCase()}</h3>
                <RadioInput label={question.description} options={options} onChange={this.onAnswerChange(index)} value={this.state.clientQuestionnaireAnswers[index].answerOptionId} />
                {!isLastItem && <hr />}
            </React.Fragment>
        );
    };

    public render(): React.ReactElement<any> {
        const isAddButtonDisabled: boolean = this.state.clientQuestionnaireAnswers.some((answer: ClientQuestionnaireAnswer): boolean => answer.answerOptionId === null);
        return (
            <div className="left-side questionnaire">
                <form id={TestId.addClient.questionnaire.container} noValidate={true} onSubmit={this.onAddClick}>
                    <Section label={Intl.formatMessage({ id: "page.addClient.questionnaire.title" })}>
                        {this.props.questions.map(this.renderQuestion)}
                        <div className="grid-x">
                            <div className="cell medium-6">
                                <Button id={TestId.addClient.questionnaire.prevButton} hollow label={Intl.formatMessage({ id: "common.back" })} onClick={this.onPrevClick} />
                            </div>
                            <div className="cell medium-6 text-right">
                                <Button label={Intl.formatMessage({ id: "common.add" })} type="submit" disabled={isAddButtonDisabled} />
                            </div>
                        </div>
                    </Section>
                </form>
            </div>
        );
    }
}

export { AddClientQuestionnaireForm };

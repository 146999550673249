import React, { Component } from "react";
import { AllowedUploadContentTypes, AllowedUploadFileSizes, AssetType } from "api/graphql/types";
import { ApplicationState } from "reducers/index";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import Dropzone from "react-dropzone";
import { Intl } from "i18n/Intl";
import { FileSize } from "utils/FileSize";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Alert } from "components/Alert/Alert";

import "./FileInput.scss";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface ReduxProps {
    allowedUploadContentTypes: AllowedUploadContentTypes | null;
    allowedUploadFileSizes: AllowedUploadFileSizes | null;
}

interface ComponentProps {
    url: string | null;
    assetUrl?: string | null;
    originalFileName?: string;
    type: AssetType[] | AssetType;
    onFileSelected: (file: File[]) => void | Promise<void>;
    progress: number | null;
    disabled?: boolean;
    placeholder: React.ReactElement<any>;
    dialogTitle?: string;
    renderContent?: (url: string) => React.ReactElement<any>;
    multiple?: boolean;
    previewEnabled: boolean;
}

type Props = ComponentProps & ReduxProps & DispatchProp;

class FileInputComponent extends Component<Props> {
    private collectAcceptTypes = (): string => {
        const { type } = this.props;

        if (Array.isArray(type)) {
            return type.map(typeName => this.getAcceptType(typeName)).join();
        }

        const acceptTypes = this.getAcceptType(type);

        return acceptTypes ? acceptTypes : "";
    };

    private getAcceptType = (assetType: AssetType): string | undefined => {
        switch (assetType) {
            case AssetType.image:
                return this.props.allowedUploadContentTypes?.image.join(",");
            case AssetType.audio:
            case AssetType.introduction_audio:
                return this.props.allowedUploadContentTypes?.audio.join(",");
            case AssetType.video:
            case AssetType.introduction_video:
                return this.props.allowedUploadContentTypes?.video.join(",");
            default:
                return this.props.allowedUploadContentTypes?.image.join(",");
        }
    };

    private getMaxSize = (): number | undefined => {
        const { allowedUploadFileSizes } = this.props;
        if (!allowedUploadFileSizes) {
            return undefined;
        }
        switch (this.props.type) {
            case AssetType.image:
                return allowedUploadFileSizes.image;
            case AssetType.audio:
            case AssetType.introduction_audio:
                return allowedUploadFileSizes.audio;
            case AssetType.video:
            case AssetType.introduction_video:
                return allowedUploadFileSizes.video;
            default:
                return undefined;
        }
    };

    private getMaxSizeByType = (type: string): number => {
        const { allowedUploadFileSizes } = this.props;
        if (!allowedUploadFileSizes) {
            return 0;
        }
        const fileType: string = type.split("/")[0];

        switch (fileType) {
            case "image":
                return allowedUploadFileSizes.image;
            case "audio":
                return allowedUploadFileSizes.audio;
            case "video":
                return allowedUploadFileSizes.video;
            default:
                return 0;
        }
    };

    private onDrop = (files: File[]): void | Promise<void> => {
        const error = new Promise((resolve, reject) => {
            files.map((file: File, key) => {
                const maxSize = this.getMaxSizeByType(file.type);
                if (maxSize && file.size >= maxSize) {
                    const title: string = Intl.formatMessage(
                        { id: "component.fileInput.fileRejected.fileSize" },
                        {
                            fileName: file.name,
                            fileType: Intl.formatMessage({ id: `enum.assetType.${file.type.split("/")[0]}` }),
                            maxFileSize: `${maxSize / FileSize.MB} MB`,
                        },
                    );
                    reject(title);
                }
                if (key === files.length - 1) {
                    resolve(true);
                }
            });
        });
        error
            .then(() => {
                this.props.onFileSelected(files);
            })
            .catch(title => {
                Alert.error({ title });
            });
    };

    private onFileRejected = (files: File[]): void => {
        files.forEach((file: File): void => {
            const maxSize = this.getMaxSize();
            const title: string =
                maxSize && file.size >= maxSize
                    ? Intl.formatMessage(
                          { id: "component.fileInput.fileRejected.fileSize" },
                          {
                              fileName: file.name,
                              fileType: Intl.formatMessage({ id: `enum.assetType.${file.type.split("/")[0]}` }),
                              maxFileSize: `${maxSize / FileSize.MB} MB`,
                          },
                      )
                    : Intl.formatMessage({ id: "component.fileInput.fileRejected.assetType" }, { fileName: file.name });

            Alert.error({ title });
        });
    };

    private onThumbnailClick = () => {
        if (this.props.previewEnabled && this.props.url) {
            this.props.dispatch(
                DialogsActions.show({
                    type: DialogType.showAsset,
                    assetUrl: this.props.assetUrl,
                    assetType: this.props.type,
                    originalFileName: this.props.originalFileName,
                    thumbnailUrl: this.props.url,
                    dialogTitle: this.props.dialogTitle,
                }),
            );
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <Dropzone
                onDrop={this.onDrop}
                accept={this.collectAcceptTypes()}
                maxSize={this.getMaxSize()}
                onDropRejected={this.onFileRejected}
                disabled={this.props.disabled}
                multiple={this.props.multiple}
            >
                {({ getRootProps, getInputProps }): React.ReactElement<any> => (
                    <div className="file-input-wrapper" onClick={this.onThumbnailClick}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.props.url ? this.props.renderContent ? this.props.renderContent(this.props.url) : <img src={this.props.url} /> : this.props.placeholder}
                        </div>
                        {this.props.progress !== null && <Loading type={LoadingType.layer} />}
                    </div>
                )}
            </Dropzone>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { allowedUploadContentTypes: state.settings.allowedUploadContentTypes, allowedUploadFileSizes: state.settings.allowedUploadFileSizes };
};

const FileInput = connect(mapStateToProps)(FileInputComponent);

export { FileInput };

import React, { Component } from "react";
import { DragProps, DragEvent, DroppableProvided, DroppableStateSnapshot, DropResult, ResponderProvided, DragStart } from "../DragContext";
import { ContentSlot } from "../ContentSlot/ContentSlot";
import { AnyContentType } from "api/ApiTypes";
import { DragType, DragUtils } from "../DragUtils";
import { Log } from "utils/Log";
import isNumber from "lodash/isNumber";
import { SearchListType } from "api/graphql/types";
import { ReorderDescriptor } from "../ContentElement/ContentElement";
import { Intl } from "i18n/Intl";
import { Droppable } from "../Droppable";

export interface GenericComponentProps<T extends ItemType> {
    minItemCount?: number;
    maxItemCount?: number;
    droppableId: string;
    value: T[];
    onChange: (asset: T[]) => void;
    disabled?: boolean;
    isReorderDisabled?: boolean;
    hidePlaceholder?: boolean;
    hasError?: boolean;
    onViewClick?: (item: T) => void;
    onEditClick?: (item: T) => void;
    customPlaceholder?: React.ReactElement;
}

interface ItemType {
    id: string;
}

export type ContentInputProps<GenericComponentProps> = GenericComponentProps & DragProps;

interface State {
    isActive: boolean;
    isDisabled: boolean;
    isDragInProgress: boolean;
}

abstract class ContentInput<I extends ItemType, T extends GenericComponentProps<I>> extends Component<ContentInputProps<T>, State> {
    protected slotRef: HTMLDivElement | null = null;

    public readonly state: State = {
        isActive: false,
        isDragInProgress: false,
        isDisabled: false,
    };

    protected abstract slotMessage: () => string;
    protected abstract slotDisabledMessage?: () => string;
    protected abstract searchListType: SearchListType;

    constructor(props: ContentInputProps<T>) {
        super(props);

        this.props.addDragListener({ event: DragEvent.dragStart, listener: this.onDragStart });
        this.props.addDragListener({ event: DragEvent.dragEnd, listener: this.onDragEnd });
        this.props.addDragListener({ event: DragEvent.addClick, listener: this.onAddClicked });
    }

    public componentWillUnmount(): void {
        this.props.removeDragListener({ event: DragEvent.dragStart, listener: this.onDragStart });
        this.props.removeDragListener({ event: DragEvent.dragEnd, listener: this.onDragEnd });
        this.props.removeDragListener({ event: DragEvent.addClick, listener: this.onAddClicked });
    }

    private onDragStart = (initial: DragStart, provided: ResponderProvided, dragType: DragType | null): void => {
        this.setState({ isDragInProgress: true });
        if (this.isDropEnabled(initial, provided, dragType)) {
            this.setState({ isActive: true, isDisabled: false });
        } else {
            this.setState({ isDisabled: true });
        }
    };

    private onDragEnd = (result: DropResult, _provided: ResponderProvided, item?: AnyContentType): void => {
        this.setState({ isDragInProgress: false });
        if (this.state.isActive) {
            this.setState({ isActive: false });
        }

        if (this.state.isDisabled) {
            return;
        }

        this.setState({ isDisabled: true });

        if (!result.destination || !item) {
            return;
        }

        if (result.destination.droppableId === this.props.droppableId) {
            if (result.source.droppableId === this.props.droppableId) {
                this.props.onChange(DragUtils.reorderArray(this.props.value, result.source.index, result.destination.index));
            } else {
                if (!item) {
                    Log.warning("Item not found", item);
                    return;
                }
                const value = Array.from(this.props.value);
                value.splice(result.destination.index || this.props.value.length, 0, (item as unknown) as I);
                this.props.onChange(value);
            }
        }
    };

    private onAddClicked = (content: AnyContentType, type: DragType | null): boolean => {
        return this.addClicked(content, type);
    };

    protected addClicked(content: AnyContentType, type: DragType | null): boolean {
        if (!type || this.props.disabled) {
            return false;
        }
        if (type.searchListType === this.searchListType && !this.isMaxCountReached()) {
            this.props.onChange([...this.props.value, content as any]);
            if (this.slotRef) {
                this.slotRef.scrollIntoView();
            }
            return true;
        }
        return false;
    }

    protected getReorderOptions = (index: number): ReorderDescriptor => {
        return {
            upEnabled: index !== 0,
            downEnabled: index !== this.props.value.length - 1,
            call: (up: boolean) => this.reorderItem(index, up),
        };
    };

    protected reorderItem = (fromIndex: number, isUp: boolean): void => {
        if (fromIndex < 0 || fromIndex >= this.props.value.length) {
            Log.warning("item not found");
            return;
        }
        if (isUp && fromIndex === 0) {
            Log.warning("item cannot be reordered up");
            return;
        }
        if (!isUp && fromIndex === this.props.value.length) {
            Log.warning("item cannot be reordered down");
            return;
        }

        const newIndex: number = isUp ? fromIndex - 1 : fromIndex + 1;
        const newOrder: I[] = DragUtils.reorderArray(this.props.value, fromIndex, newIndex);
        this.props.onChange(newOrder);
    };

    protected onCloseClick = (_item: I, index: number): void => {
        if (index !== -1) {
            const value = [...this.props.value];
            value.splice(index, 1);
            this.props.onChange(value);
        } else {
            Log.warning("FlowchartItem not found, but close clicked");
        }
    };

    protected onViewClick = (item: I) => {
        const { onViewClick } = this.props;
        if (onViewClick) {
            return () => onViewClick(item);
        }
        return undefined;
    };

    protected onEditClick = (item: I) => {
        const { onEditClick } = this.props;
        if (onEditClick) {
            return () => onEditClick(item);
        }
        return undefined;
    };

    protected abstract renderDraggable: (item: I, index: number) => React.ReactElement<any>;

    protected isMaxCountReached = (): boolean => {
        return typeof this.props.maxItemCount !== undefined && this.props.value.length >= this.props.maxItemCount!;
    };

    protected isDropEnabled(initial: DragStart, _provided: ResponderProvided, dragType: DragType | null): boolean {
        if (((dragType && dragType.searchListType === this.searchListType) || initial.source?.droppableId === this.props.droppableId) && !this.props.disabled) {
            if (initial.source?.droppableId !== this.props.droppableId && this.isMaxCountReached()) {
                return false;
            }
            return true;
        }
        return false;
    }

    protected isDragDisabled() {
        return this.props.disabled || this.props.value.length <= 1;
    }

    public render(): React.ReactElement<any> {
        let slotsCount: number = isNumber(this.props.minItemCount) ? Math.max(this.props.minItemCount - this.props.value.length, 0) : 0;
        if ((!this.props.maxItemCount || this.props.value.length < this.props.maxItemCount) && slotsCount === 0 && !this.props.disabled) {
            slotsCount++;
        }
        return (
            <Droppable droppableId={this.props.droppableId} isDropDisabled={this.state.isDisabled || this.props.disabled} type={this.searchListType}>
                {(dropProvided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement<any> => {
                    return (
                        <>
                            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                                {this.props.value.map(this.renderDraggable)}
                                <div
                                    ref={(ref: HTMLDivElement | null) => {
                                        this.slotRef = ref;
                                    }}
                                />
                                {!this.props.disabled &&
                                    Array.from({ length: slotsCount }).map((_, index: number) => {
                                        return (
                                            <ContentSlot
                                                key={`slot-${index}`}
                                                isVisible={true}
                                                hasError={this.props.hasError}
                                                isActive={this.state.isActive}
                                                isDraggingOver={snapshot.isDraggingOver}
                                                isDragInProgress={this.state.isDragInProgress}
                                                placeholder={dropProvided.placeholder}
                                                message={this.slotMessage()}
                                                customPlaceholder={this.props.customPlaceholder}
                                                searchListType={this.searchListType}
                                                disabled={this.props.disabled}
                                            />
                                        );
                                    })}
                                {this.props.value.length === 0 && this.props.disabled && (
                                    <ContentSlot
                                        isVisible={true}
                                        hasError={this.props.hasError}
                                        isActive={this.state.isActive}
                                        isDraggingOver={snapshot.isDraggingOver}
                                        isDragInProgress={this.state.isDragInProgress}
                                        placeholder={dropProvided.placeholder}
                                        message={
                                            this.slotDisabledMessage
                                                ? this.slotDisabledMessage()
                                                : Intl.formatMessage({
                                                      id: this.searchListType === SearchListType.asset ? "sharedComponent.contentInput.noImage" : "sharedComponent.contentInput.noContent",
                                                  })
                                        }
                                        searchListType={this.searchListType}
                                        disabled={this.props.disabled}
                                    />
                                )}
                            </div>

                            {this.props.maxItemCount === 1 && !this.props.hidePlaceholder ? dropProvided.placeholder : <div style={{ display: "none" }}>{dropProvided.placeholder}</div>}
                        </>
                    );
                }}
            </Droppable>
        );
    }
}

export { ContentInput };

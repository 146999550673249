import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface DeleteEverydaySituationDialogProps {
    onDeleted: () => void;
    everydaySituation: { id: string; title: string } | null;
}

type Props = DeleteEverydaySituationDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class DeleteEverydaySituationDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private deleteEverydaySituationDialog = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                if (this.props.everydaySituation) {
                    try {
                        await Api.deleteEverydaySituation(this.props.everydaySituation.id);
                        Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.deleteEverydaySituationDialog.succeed" }) });
                        this.props.onHide();
                        this.props.onDeleted();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }

                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible && this.props.everydaySituation !== null}
                title={Intl.formatMessage({ id: "sharedComponent.deleteEverydaySituationDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <i className="fas fa-exclamation-triangle verify-delete-everyday-situation-dialog-icon" />
                <p>{Intl.formatMessage({ id: "sharedComponent.deleteEverydaySituationDialog.description1" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            label={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.deleteEverydaySituationDialog}
                            disabled={this.state.isLoading}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const DeleteEverydaySituationDialog: React.ComponentClass<Props> = DeleteEverydaySituationDialogComponent;

import React, { Component } from "react";
import { Page } from "components/Page";
import { SupportedClient } from "api/graphql/types";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { TabPanel } from "components/TabPanel/TabPanel";
import ClientInstantAwardsTab from "./ClientInstantAwardsTab";
import ClientAwardsTab from "./ClientAwardsTab";
import "./ClientAwardsBasePage.scss";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ReduxProps & ComponentProps & DispatchProp;

class ClientAwardsBaseComponent extends Component<Props> {
    public render(): React.ReactElement {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.clientAwardsBase.title" }, { name: this.props.client.name })} hasTabs={true}>
                <div className="left-side client-awards-base">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAwardsTab.title" }),
                                label: Intl.formatMessage({ id: "page.clientAwardsBase.clientInstantAwardsTab.title" }),
                                to: Path.clientInstantAwards(this.props.match.params.clientExtId!),
                                component: ClientInstantAwardsTab,
                                additionalProps: { client: this.props.client },
                            },
                            {
                                id: Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.title" }),
                                label: Intl.formatMessage({ id: "page.clientAwardsBase.clientAwardsTab.title" }),
                                to: Path.clientAwards(this.props.match.params.clientExtId!),
                                component: ClientAwardsTab,
                                additionalProps: { client: this.props.client },
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return { client };
};

export const ClientAwardsBase = connect(mapStateToProps)(withRouter(ClientAwardsBaseComponent));

export default ClientAwardsBase;

import React from "react";
import { AnyContentType } from "api/ApiTypes";
import { DragType } from "../DragUtils";
import {
    SearchListType,
    AgendaItemContent,
    ItemAward,
    AgendaItemAlert,
    Checklist,
    Checklist_items,
    Timer,
    MoodMeterDisplayConfig,
    AgendaItemType,
    AgendaItemContent_flowchart,
    AgendaItemContent_flowchart_itemList,
    FlowchartContent,
    FlowchartContent_itemList,
    AgendaItemContentChild,
    AgendaItemContentChild_flowchart,
    Notification,
    SelectionBoardInfo,
    AgendaItemContent_agendaItems_agendaItem,
    AgendaItemContent_agendaItems_agendaItem_flowchart,
    AgendaItemContent_agendaItems_agendaItem_flowchart_itemList,
    AssetContent,
    FlowchartItemContent_flowchart,
} from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Formatter } from "utils/Formatter";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { PeriodSelectOptions } from "models/PeriodSelectOptions";
import { DateUtils, TimeUnit } from "utils/DateUtils";
import { ImageSrc } from "utils/ImageSrc";

export interface IconInfo {
    active: boolean;
    tooltip?: React.ReactElement<any> | string;
}

export interface ContentInfo {
    waiting?: IconInfo; // varakozas
    award?: IconInfo; // jutalom
    isFlowchartPresent?: IconInfo; // folyamatabra
    moodMeter?: IconInfo; // erzelmi homero
    alert?: IconInfo; // figyelmeztetes
    notification?: IconInfo; // emlékeztető
    checklist?: IconInfo; // feladatlista
    timer?: IconInfo; // idopont
    time?: IconInfo; // idotartam
    selectionBoard?: IconInfo; // valasztotabla
    timeValue?: string; // HH:mm
    isSelect?: boolean;
}

export interface ContentChildBasicInfo {
    id: string;
    title: string;
    asset: AssetContent | null;
    fallbackImage: string;
    info?: ContentInfo;
}

export interface ContentChild extends ContentChildBasicInfo {
    contentChildren?: ContentChildBasicInfo[];
}

export class ContentElementUtils {
    public static readonly INACTIVE_TOOLTIP: IconInfo = { active: false };

    private static localTranslation = (id: string, params?: { [key: string]: any }): string => {
        return Intl.formatMessage({ id: `sharedComponent.tooltip.agendaItem.${id}` }, { ...params });
    };

    public static getContentType(content: AnyContentType): DragType | null {
        switch (content.__typename) {
            case "Asset":
                return { searchListType: SearchListType.asset, assetType: content.assetType };
            case "Agenda":
                return { searchListType: SearchListType.agenda };
            case "AgendaItem":
                return { searchListType: SearchListType.agendaItem };
            case "Flowchart":
                return { searchListType: SearchListType.flowchart };
            case "FlowchartItem":
                return { searchListType: SearchListType.flowchartItem, flowchartItemType: content.flowchartType, isEmbedded: !!content.flowchart };
            default:
                return null;
        }
    }

    public static getAgendaItemContentInfo(content: AgendaItemContent | AgendaItemContentChild | AgendaItemContent_agendaItems_agendaItem): ContentInfo | undefined {
        if (content.type === AgendaItemType.Select) {
            return {
                isSelect: true,
            };
        }
        return {
            award: content.award ? { active: true, tooltip: ContentElementUtils.getAwardTooltip(content.award) } : ContentElementUtils.INACTIVE_TOOLTIP,
            isFlowchartPresent: { active: !!content.flowchart },
            moodMeter: content.moodMeter ? { active: true, tooltip: ContentElementUtils.getMoodMeterTooltip(content.moodMeter) } : ContentElementUtils.INACTIVE_TOOLTIP,
            alert: content.alert ? { active: true, tooltip: ContentElementUtils.getAgendaItemAlertTooltip(content.alert) } : ContentElementUtils.INACTIVE_TOOLTIP,
            checklist: content.checklist ? { active: true, tooltip: ContentElementUtils.getChecklistTooltip(content.checklist) } : ContentElementUtils.INACTIVE_TOOLTIP,
            notification: content.notification ? { active: true, tooltip: ContentElementUtils.getNotificationTooltip(content.notification) } : ContentElementUtils.INACTIVE_TOOLTIP,
            timer: content.timer ? { active: true, tooltip: ContentElementUtils.getTimerTooltip(content.timer) } : ContentElementUtils.INACTIVE_TOOLTIP,
            time: { active: !!content.time },
            timeValue: content.time,
            selectionBoard: content.selectionBoard ? { active: true, tooltip: ContentElementUtils.getSelectionBoardTooltip(content.selectionBoard) } : ContentElementUtils.INACTIVE_TOOLTIP,
        };
    }

    public static getAgendaItemFlowcharts(agendaItem: AgendaItemContent | AgendaItemContentChild | AgendaItemContent_agendaItems_agendaItem): ContentChild[] {
        if (!agendaItem.flowchart) {
            return [];
        }

        // As of 2020-01-27, only one "flowchart" is implemented
        const asset = agendaItem.flowchart.image;
        return [
            {
                id: agendaItem.id,
                title: agendaItem.flowchart.title,
                asset: asset || null,
                fallbackImage: ImageSrc.flowchart,
                contentChildren: ContentElementUtils.getAgendaItemFlowchartChildren(agendaItem.flowchart),
            },
        ];
    }

    public static getAgendaItemFlowchartChildren(
        flowchart: AgendaItemContent_flowchart | AgendaItemContentChild_flowchart | AgendaItemContent_agendaItems_agendaItem_flowchart | FlowchartItemContent_flowchart | null,
    ): ContentChild[] {
        if (!flowchart || !flowchart.itemList) {
            return [];
        }
        return flowchart.itemList.map((item: AgendaItemContent_flowchart_itemList | AgendaItemContent_agendaItems_agendaItem_flowchart_itemList) => {
            const asset = item.flowchartItem.image || item.flowchartItem.video;
            return {
                id: item.flowchartItem.id,
                title: item.flowchartItem.title,
                asset: asset || null,
                fallbackImage: ImageSrc.flowchartItem,
                info: {
                    award: item.flowchartItem.award ? { active: true, tooltip: ContentElementUtils.getAwardTooltip(item.flowchartItem.award) } : ContentElementUtils.INACTIVE_TOOLTIP,
                    moodMeter: item.flowchartItem.moodMeter ? { active: true, tooltip: ContentElementUtils.getMoodMeterTooltip(item.flowchartItem.moodMeter) } : ContentElementUtils.INACTIVE_TOOLTIP,
                    notification: item.flowchartItem.notification
                        ? { active: true, tooltip: ContentElementUtils.getNotificationTooltip(item.flowchartItem.notification) }
                        : ContentElementUtils.INACTIVE_TOOLTIP,
                    checklist: item.flowchartItem.checklist ? { active: true, tooltip: ContentElementUtils.getChecklistTooltip(item.flowchartItem.checklist) } : ContentElementUtils.INACTIVE_TOOLTIP,
                    timer: item.flowchartItem.timer ? { active: true, tooltip: ContentElementUtils.getTimerTooltip(item.flowchartItem.timer) } : ContentElementUtils.INACTIVE_TOOLTIP,
                },
            };
        });
    }

    public static getFlowchartFlowchartItems(flowchart: FlowchartContent): ContentChild[] {
        if (!flowchart.itemList || flowchart.itemList.length === 0) {
            return [];
        }

        return flowchart.itemList.map(
            (flowchartItem: FlowchartContent_itemList): ContentChild => {
                const asset = flowchartItem.flowchartItem.image;
                return {
                    id: flowchartItem.flowchartItem.id,
                    title: flowchartItem.flowchartItem.title,
                    asset: asset || null,
                    fallbackImage: ImageSrc.flowchartItem,
                    info: {
                        award: flowchartItem.flowchartItem.award
                            ? { active: true, tooltip: ContentElementUtils.getAwardTooltip(flowchartItem.flowchartItem.award) }
                            : ContentElementUtils.INACTIVE_TOOLTIP,
                        notification: flowchartItem.flowchartItem.notification
                            ? { active: true, tooltip: ContentElementUtils.getNotificationTooltip(flowchartItem.flowchartItem.notification) }
                            : ContentElementUtils.INACTIVE_TOOLTIP,
                        moodMeter: flowchartItem.flowchartItem.moodMeter
                            ? { active: true, tooltip: ContentElementUtils.getMoodMeterTooltip(flowchartItem.flowchartItem.moodMeter) }
                            : ContentElementUtils.INACTIVE_TOOLTIP,
                        checklist: flowchartItem.flowchartItem.checklist
                            ? { active: true, tooltip: ContentElementUtils.getChecklistTooltip(flowchartItem.flowchartItem.checklist) }
                            : ContentElementUtils.INACTIVE_TOOLTIP,
                        timer: flowchartItem.flowchartItem.timer
                            ? { active: true, tooltip: ContentElementUtils.getTimerTooltip(flowchartItem.flowchartItem.timer) }
                            : ContentElementUtils.INACTIVE_TOOLTIP,
                    },
                };
            },
        );
    }

    public static getFlowchartItemContentInfo(content: {
        award: ItemAward | null;
        moodMeter: MoodMeterDisplayConfig | null;
        notification: Notification | null;
        checklist: Checklist | null;
        timer: Timer | null;
        flowchart: { id: string } | null;
    }): ContentInfo | undefined {
        return {
            award: content.award ? { active: true, tooltip: ContentElementUtils.getAwardTooltip(content.award) } : ContentElementUtils.INACTIVE_TOOLTIP,
            moodMeter: content.moodMeter ? { active: true, tooltip: ContentElementUtils.getMoodMeterTooltip(content.moodMeter) } : ContentElementUtils.INACTIVE_TOOLTIP,
            notification: content.notification ? { active: true, tooltip: ContentElementUtils.getNotificationTooltip(content.notification) } : ContentElementUtils.INACTIVE_TOOLTIP,
            checklist: content.checklist ? { active: true, tooltip: ContentElementUtils.getChecklistTooltip(content.checklist) } : ContentElementUtils.INACTIVE_TOOLTIP,
            timer: content.timer ? { active: true, tooltip: ContentElementUtils.getTimerTooltip(content.timer) } : ContentElementUtils.INACTIVE_TOOLTIP,
            isFlowchartPresent: content.flowchart ? { active: true } : ContentElementUtils.INACTIVE_TOOLTIP,
        };
    }

    public static getAwardTooltip(award: ItemAward): React.ReactElement<any> | undefined {
        const scorePresent: boolean = award.score !== null && award.score !== undefined;

        if (!scorePresent) {
            return undefined;
        }

        return <ul>{scorePresent && <li>{ContentElementUtils.localTranslation("award.score", { score: award.score })}</li>}</ul>;
    }

    public static getMoodMeterTooltip(moodMeter: MoodMeterDisplayConfig): React.ReactElement<any> | string | undefined {
        if (!moodMeter.display) {
            return undefined;
        }

        return (
            <ul>
                {moodMeter.display && <li>{ContentElementUtils.localTranslation("moodMeter.display", { display: Intl.formatMessage({ id: `enum.moodMeterDisplay.${moodMeter.display}` }) })}</li>}
                {<li>{ContentElementUtils.localTranslation("moodMeter.title", { title: moodMeter.title || Intl.formatMessage({ id: "common.empty" }) })}</li>}
            </ul>
        );
    }

    public static getAgendaItemAlertTooltip(agendaItemAlert: AgendaItemAlert): React.ReactElement<any> | string | undefined {
        let notifications = "";
        if (agendaItemAlert.vibrate || agendaItemAlert.flash) {
            if (agendaItemAlert.vibrate && !agendaItemAlert.flash) {
                notifications = Intl.formatMessage({ id: "enum.agendaItemAlert.notifications.Vibrate" });
            } else if (!agendaItemAlert.vibrate && agendaItemAlert.flash) {
                notifications = Intl.formatMessage({ id: "enum.agendaItemAlert.notifications.Flash" });
            } else {
                notifications = Intl.formatMessage({ id: "enum.agendaItemAlert.notifications.FlashAndVibrate" });
            }
        }

        return (
            <ul>
                {agendaItemAlert.display && (
                    <li>{ContentElementUtils.localTranslation("alert.display", { display: Intl.formatMessage({ id: `enum.agendaItemAlert.display.${agendaItemAlert.display}` }) })}</li>
                )}
                {agendaItemAlert.requestInteraction !== null && agendaItemAlert.requestInteraction !== undefined && (
                    <li>
                        {ContentElementUtils.localTranslation("alert.display", {
                            display: Intl.formatMessage({ id: `enum.agendaItemAlert.type.${agendaItemAlert.requestInteraction ? "Repeating" : "Once"}` }),
                        })}
                    </li>
                )}
                {notifications && <li>{ContentElementUtils.localTranslation("alert.notifications", { notifications })}</li>}
                {agendaItemAlert.title && <li>{ContentElementUtils.localTranslation("alert.title", { title: agendaItemAlert.title })}</li>}
            </ul>
        );
    }

    public static getChecklistTooltip(checklist: Checklist): React.ReactElement<any> | string | undefined {
        if (checklist.items.length === 0) {
            return undefined;
        }

        return (
            <>
                <ul>
                    {checklist.items.map((item: Checklist_items) => {
                        return <li key={item.id}>{item.title}</li>;
                    })}
                </ul>
            </>
        );
    }

    public static getNotificationTooltip(notification: Notification): React.ReactElement<any> | string | undefined {
        return (
            <ul>
                <li>{notification.text}</li>
            </ul>
        );
    }

    public static getTimerTooltip(timer: Timer): React.ReactElement<any> | string | undefined {
        const nearestTimeUnit: SelectOption<TimeUnit> | null = timer.seconds ? Select.getSelectOption(PeriodSelectOptions.get(), DateUtils.getNearestTimeUnit(timer.seconds || 0)) : null;

        return (
            <ul>
                {nearestTimeUnit && (
                    <li>
                        {ContentElementUtils.localTranslation("timer.seconds", {
                            seconds: DateUtils.secondsTo(timer.seconds, nearestTimeUnit.value) || "",
                        })}
                        {` ${nearestTimeUnit.label}`}
                    </li>
                )}
                <li>{ContentElementUtils.localTranslation("timer.skipEnabled", { skipEnabled: Formatter.formatBoolean(timer.skipEnabled) })}</li>
                <li>{ContentElementUtils.localTranslation("timer.endWarning", { endWarning: Formatter.formatBoolean(timer.endWarning) })}</li>
            </ul>
        );
    }

    public static getSelectionBoardTooltip(selectionBoard: SelectionBoardInfo): React.ReactElement<any> | string | undefined {
        return (
            <ul>
                <li>{Intl.formatMessage({ id: "sharedComponent.tooltip.agendaItem.selectionBoard" }, { selectionBoard: selectionBoard.title })}</li>
            </ul>
        );
    }
}

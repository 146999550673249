import React, { Component } from "react";
import { MoodMeter, MoodMeterItemInput, MoodMeterEmotion, MoodMeterItem } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { ObjectUtils } from "utils/ObjectUtils";
import { Input } from "components/Inputs/Input/Input";
import { ImageSrc } from "utils/ImageSrc";
import { MoodMeterQuestionSelectOptions, MoodMeterQuestion, MoodMeterQuestionSelectOption } from "models/MoodMeterQuestionSelectOptions";
import { Select } from "components/Inputs/Select/Select";
import { IntlHelpers } from "i18n/IntlHelpers";

interface Props {
    value: MoodMeter | null;
    onChange: (value: MoodMeter | null) => void;
    isValidationEnabled: boolean;

    errors: {
        moodMeterItems?: string | null;
        question?: string | null;
    };
}

class MoodMeterForm extends Component<Props> {
    private enabledCheckboxRef: Checkbox | null = null;

    public scrollIntoView = () => {
        if (this.enabledCheckboxRef) {
            this.enabledCheckboxRef.scrollIntoView();
        }
    };

    private static getDefaultMoodMeter(): MoodMeter {
        return { title: "", enabled: true, message: false, intensity: false, multiSelect: false, question: null, items: [], __typename: "MoodMeter" };
    }

    private getInitialMoodMeterItems = (): MoodMeterItem[] => {
        return ObjectUtils.enumAsArray<MoodMeterEmotion>(MoodMeterEmotion).map(
            (emotion: MoodMeterEmotion, index: number): MoodMeterItem => {
                return { emotion, name: "", position: index + 1, __typename: "MoodMeterItem" };
            },
        );
    };

    private onMoodMeterEnabledChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange(event.currentTarget.checked ? MoodMeterForm.getDefaultMoodMeter() : null);
    };

    private onMoodMeterMultiSelectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, multiSelect: event.currentTarget.checked });
    };

    private getMoodMeterItemInputByEmotion = (emotion: MoodMeterEmotion): MoodMeterItemInput | undefined => {
        if (!this.props.value) {
            return undefined;
        }

        return this.props.value.items.find((stateItem: MoodMeterItemInput) => stateItem.emotion === emotion);
    };

    private onMoodMeterItemChecked = (emotion: MoodMeterEmotion) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.value) {
            return;
        }

        if (event.currentTarget.checked) {
            const item: MoodMeterItem | undefined = this.getInitialMoodMeterItems().find((item: MoodMeterItem): boolean => item.emotion === emotion);
            if (!item) {
                return;
            }
            this.props.onChange({ ...this.props.value, items: [...this.props.value.items, { ...item, name: Intl.formatMessage({ id: `enum.moodMeterEmotion.${item.emotion}` }) }] });
        } else {
            const items = this.props.value.items.filter((item: MoodMeterItem): boolean => item.emotion !== emotion);
            this.props.onChange({ ...this.props.value, items });
        }
    };

    private onMoodMeterItemNameChange = (emotion: MoodMeterEmotion) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.value) {
            return;
        }

        const itemIndex: number = this.props.value.items.findIndex((item: MoodMeterItem): boolean => item.emotion === emotion);
        if (itemIndex !== -1) {
            const items = [...this.props.value.items];
            items[itemIndex].name = event.currentTarget.value;
            this.props.onChange({ ...this.props.value, items });
        }
    };

    private onMoodMeterMessageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, message: event.currentTarget.checked });
    };

    private onMoodMeterIntensityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, intensity: event.currentTarget.checked });
    };

    private onQuestionSelected = (option: MoodMeterQuestionSelectOption): void => {
        let question: string | null = Intl.formatMessage({ id: `enum.moodMeterQuestion.${option.value}` });
        if (option.value === MoodMeterQuestion.k4) {
            question = "";
        }
        this.props.onChange({ ...this.props.value!, question });
    };

    private onQuestionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.onChange({ ...this.props.value!, question: event.currentTarget.value });
    };

    private emotionResize = (item: MoodMeterItemInput) => {
        if (item.emotion === "Fatigue") {
            return (
                <div className="emotion-icon__relative-wrapper">
                    <img width="49" height="40" src={ImageSrc.emotion[item.emotion]} style={{ transform: "translateX(9px)" }} />
                </div>
            );
        }

        if (item.emotion === "Pain") {
            // Note: 45 and 43 are rounded values.
            return (
                <div className="emotion-icon__relative-wrapper">
                    <img width="45" height="43" src={ImageSrc.emotion[item.emotion]} style={{ transform: "translate(5px, 3px)" }} />
                </div>
            );
        }

        return <img width="40" height="40" src={ImageSrc.emotion[item.emotion]} />;
    };

    private getCurrentOption = (): MoodMeterQuestion | null | undefined => {
        if (!this.props.value) {
            return null;
        }

        if (this.props.value.question === null || this.props.value.question === Intl.formatMessage({ id: "enum.moodMeterQuestion.null" })) {
            return null;
        }

        const res = IntlHelpers.fromI18nToEnum<MoodMeterQuestion>(MoodMeterQuestion, this.props.value?.question, "enum.moodMeterQuestion");
        if ([null, MoodMeterQuestion.k1, MoodMeterQuestion.k2, MoodMeterQuestion.k3].includes(res!)) {
            return res || null;
        }
        return undefined;
    };

    public render(): React.ReactElement<any> {
        const selectOptions = MoodMeterQuestionSelectOptions.getForFunction();
        const currentOption = this.getCurrentOption();
        return (
            <>
                <InputWrapper id={TestId.clientSettings.moodMeterEnabled} inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.enabled.label" })} padded icon="fa-smile">
                    <Checkbox
                        ref={(ref: Checkbox | null): void => {
                            this.enabledCheckboxRef = ref;
                        }}
                        checked={!!this.props.value && this.props.value.enabled}
                        onChange={this.onMoodMeterEnabledChange}
                    />
                </InputWrapper>

                {this.props.value && this.props.value.enabled && (
                    <>
                        <InputWrapper
                            inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.items" })}
                            errorMessage={this.props.isValidationEnabled && this.props.errors ? this.props.errors.moodMeterItems : null}
                            padded
                        >
                            {this.getInitialMoodMeterItems().map((item: MoodMeterItemInput) => {
                                const moodMeterItem: MoodMeterItemInput | undefined = this.getMoodMeterItemInputByEmotion(item.emotion);
                                return (
                                    <div key={`emotion-${item.emotion}`} className="grid-x emotion">
                                        <div className="cell auto emotion-name">
                                            <Checkbox
                                                key={`enabled-${item.emotion}`}
                                                label={Intl.formatMessage({ id: `enum.moodMeterEmotion.${item.emotion}` })}
                                                checked={!!moodMeterItem}
                                                onChange={this.onMoodMeterItemChecked(item.emotion)}
                                            />
                                        </div>
                                        <div className="cell shrink emotion-active">
                                            {moodMeterItem && (
                                                <div className="cell auto">
                                                    <Input
                                                        key={`name-${item.emotion}`}
                                                        value={moodMeterItem ? moodMeterItem.name || "" : ""}
                                                        onChange={this.onMoodMeterItemNameChange(item.emotion)}
                                                        placeholder={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.name.placeholder" })}
                                                        disabled={!this.getMoodMeterItemInputByEmotion(item.emotion)}
                                                    />
                                                </div>
                                            )}
                                            <div className="emotion-icon">{this.emotionResize(item)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </InputWrapper>

                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.multiSelect.label" })} padded>
                            <Checkbox checked={!!this.props.value.multiSelect} onChange={this.onMoodMeterMultiSelectChange} />
                        </InputWrapper>

                        <InputWrapper id={TestId.clientSettings.moodMeterMessage} inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.message.label" })} padded>
                            <Checkbox checked={!!this.props.value.message} onChange={this.onMoodMeterMessageChange} />
                        </InputWrapper>

                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.intensity.label" })} padded>
                            <Checkbox checked={!!this.props.value.intensity} onChange={this.onMoodMeterIntensityChange} />
                        </InputWrapper>

                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.question.label" })} padded>
                            <Select
                                options={selectOptions}
                                value={Select.getSelectOption(selectOptions, currentOption || (currentOption === null ? null : MoodMeterQuestion.k4))}
                                onChange={this.onQuestionSelected}
                            />
                        </InputWrapper>

                        {currentOption !== null && ![null, MoodMeterQuestion.k1, MoodMeterQuestion.k2, MoodMeterQuestion.k3].includes(currentOption!) && (
                            <InputWrapper
                                inputLabel={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.customQuestion.label" })}
                                padded
                                errorMessage={this.props.isValidationEnabled ? this.props.errors?.question : null}
                            >
                                <Input
                                    value={this.props.value.question || ""}
                                    onChange={this.onQuestionChange}
                                    hasError={this.props.isValidationEnabled && !!this.props.errors?.question}
                                    placeholder={Intl.formatMessage({ id: "page.clientSettings.functionsTab.moodMeter.customQuestion.placeholder" })}
                                />
                            </InputWrapper>
                        )}
                    </>
                )}
            </>
        );
    }
}

export { MoodMeterForm };

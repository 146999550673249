import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { PromptMessage } from "components/Prompt";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface ConfirmLeaveDialogProps {
    onCancelClick: () => void;
    onAcceptClick: () => void;
    promptMessage: PromptMessage | null;
}

type Props = ConfirmLeaveDialogProps & DialogVisibilityProps;

class ConfirmLeaveDialog extends PureComponent<Props> {
    private onCancelClick = (): void => {
        this.props.onCancelClick();
        this.props.onHide();
    };

    private onAcceptClick = (): void => {
        this.props.onAcceptClick();
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        let title: string = Intl.formatMessage({ id: "component.confirmLeaveDialog.pageLeave.title" });
        let description: string = Intl.formatMessage({ id: "component.confirmLeaveDialog.pageLeave.description" });
        let acceptLabel: string = Intl.formatMessage({ id: "common.leave" });

        if (this.props.promptMessage === PromptMessage.logout) {
            title = Intl.formatMessage({ id: "component.confirmLeaveDialog.logout.title" });
            description = Intl.formatMessage({ id: "component.confirmLeaveDialog.logout.description" });
            acceptLabel = Intl.formatMessage({ id: "common.logout" });
        }

        return (
            <Dialog title={title} visible={this.props.isVisible} onCloseClick={this.onCancelClick}>
                <p>{description}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button label={acceptLabel} onClick={this.onAcceptClick} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { ConfirmLeaveDialog };

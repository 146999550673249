export class ImageSrc {
    public static readonly add = "/images/add.svg";
    public static readonly alert = {
        success: "/images/alert-success.svg",
        info: "/images/alert-info.svg",
        error: "/images/alert-error.svg",
    };

    public static readonly logo = {
        default: "/images/logo.svg",
        blue: "/images/logo-blue.svg",
    };

    public static readonly textIcon = "/images/text-icon.svg";
    public static readonly avatarUpload = "/images/avatar-upload.png";
    public static readonly avatarUploadSet = "/images/avatar-upload.png 1x, /images/avatar-upload@2x.png 2x, /images/avatar-upload@3x.png 3x";

    public static readonly avatar = "/images/default-avatar-big.png";
    public static readonly asset = "/images/tm-ikonok/kep.svg";
    public static readonly audioAsset = "/images/tm-ikonok/hang.svg";
    public static readonly agenda = "/images/tm-ikonok/napirend.svg";
    public static readonly agendaItem = "/images/tm-ikonok/napirendi-lepes.svg";
    public static readonly flowchart = "/images/tm-ikonok/folyamatabra.svg";
    public static readonly flowchartItem = "/images/tm-ikonok/folyamatabra-lepes.svg";
    public static readonly pdfIcon = "/images/pdf-icon.svg";
    public static readonly moveIcon = "/images/move-icon.svg";
    public static readonly everydaySituationsClientDirectoryIcon = "/images/everyday-situations-client-directory-icon.png";

    public static readonly emotion: { [key: string]: string } = {
        Calmness: "/images/emotion/Calmness.svg",
        Anger: "/images/emotion/Anger.svg",
        Loathing: "/images/emotion/Loathing.svg",
        Sadness: "/images/emotion/Sadness.svg",
        Fear: "/images/emotion/Fear.svg",
        Happiness: "/images/emotion/Happiness.svg",
        Boredom: "/images/emotion/Boredom.svg",
        Constraint: "/images/emotion/Constraint.svg",
        Disappointment: "/images/emotion/Disappointment.svg",
        Disgust: "/images/emotion/Disgust.svg",
        Excitement: "/images/emotion/Excitement.svg",
        Fatigue: "/images/emotion/Fatigue.svg",
        Indifference: "/images/emotion/Indifference.svg",
        Pain: "/images/emotion/Pain.svg",
        Pride: "/images/emotion/Pride.svg",
        Puzzlement: "/images/emotion/Puzzlement.svg",
        Shame: "/images/emotion/Shame.svg",
        Surprise: "/images/emotion/Surprise.svg",
        Worry: "/images/emotion/Worry.svg",
    };

    public static readonly timerTypeIcon = "/images/timer-type-icon.svg";
}

import React, { Component } from "react";
import { DisabledContentListOptionsInput, DisabledContentListSortField, ListControl, SortOrder, AssetType } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { ListQueryParameter, ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { Column, Table } from "components/Table/Table";
import { AnyDisabledItemType, DisabledItem, DisabledItemUtils, DisabledItemTypesWithoutView } from "utils/DisabledItemUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { OptionsMenu, OptionMenuItem } from "components/OptionsMenu/OptionsMenu";
import { Path } from "utils/Path";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { ListResult } from "api/ApiTypes";
import isEqual from "lodash/isEqual";
import { isNil } from "lodash";
import { OverlayIcon } from "components/OverlayIcon";
import { DisabledLock } from "components/DisabledLock";

interface DisabledItemsTableOptions {
    sortField?: DisabledContentListSortField | null;
    control: ListControl;
}

interface State {
    content: DisabledItem[];
    count: number;
    isLoading: boolean;
    options: DisabledItemsTableOptions;
}

enum DisabledItemsTableColumn {
    title = "title",
    type = "type",
    info = "info",
    createdAt = "createdAt",
    actions = "actions",
}

interface ComponentProps {
    search?: string;
    currentUrlParams: string;
    getDisabledContent: (options?: DisabledContentListOptionsInput) => Promise<ListResult<AnyDisabledItemType>>;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class DisabledItemsTableComponent extends Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            content: [],
            count: 0,
            isLoading: false,
            options: DisabledItemsTableComponent.getInitialOptions(props),
        };
    }

    private static readonly getInitialOptions = (props: Props): DisabledItemsTableOptions => {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<DisabledContentListSortField>(
            DisabledContentListSortField,
            "sharedComponent.contentLibraryTable.disabledItemsTable",
        ).parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search: props.search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    };

    public componentDidMount(): void {
        this.refreshContent(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: DisabledItemsTableOptions = DisabledItemsTableComponent.getInitialOptions(nextProps);
        if (!isEqual(this.state.options, nextOptions)) {
            this.setState({ options: nextOptions }, () => {
                this.updateQueryParams();
            });
        }
    }

    private readonly refreshContent = (options: DisabledItemsTableOptions) => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const response = await this.props.getDisabledContent(options);
                    this.setState({
                        content: DisabledItemUtils.getDisabledItems(response.result),
                        count: response.count,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly onViewClick = (item: DisabledItem): void => {
        switch (item.type) {
            case "Agenda":
                this.props.history.push(Path.viewAgenda(item.id));
                break;
            case "AgendaItem":
                this.props.history.push(Path.viewAgendaItem(item.id));
                break;
            case "Flowchart":
                this.props.history.push(Path.viewFlowchart(item.id));
                break;
            case "FlowchartItem":
                this.props.history.push(Path.viewFlowchartItem(item.id));
                break;
            case "Asset":
                this.props.history.push(Path.viewAsset(item.id));
                break;
            case "TextEverydaySituation":
                this.props.history.push(Path.clientEverydaySituations(item.clientExtId || ""));
                break;
            case "TextAndImageEverydaySituation":
                this.props.history.push(Path.clientEverydaySituations(item.clientExtId || ""));
                break;
            case "ImageEverydaySituation":
                this.props.history.push(Path.clientEverydaySituations(item.clientExtId || ""));
                break;
            case "AudioEverydaySituation":
                this.props.history.push(Path.clientEverydaySituations(item.clientExtId || ""));
                break;
            case "EverydaySituationDirectory":
                this.props.history.push(Path.clientEverydaySituationsDirectories(item.clientExtId || ""));
                break;
            case "SelectionBoard":
                this.props.history.push(Path.viewSelectionBoard(item.clientExtId!, item.id));
                break;
            default:
                return;
        }
    };

    private readonly onEditClick = (item: DisabledItem): void => {
        switch (item.type) {
            case "Agenda":
                this.props.history.push(Path.editAgenda(item.id));
                break;
            case "AgendaItem":
                this.props.history.push(Path.editAgendaItem(item.id));
                break;
            case "Flowchart":
                this.props.history.push(Path.editFlowchart(item.id));
                break;
            case "FlowchartItem":
                this.props.history.push(Path.editFlowchartItem(item.id));
                break;
            case "Asset":
                this.props.history.push(Path.editAsset(item.id));
                break;
            case "Award":
                if (!isNil(item.clientExtId)) {
                    this.props.history.push(Path.editClientAward(item.clientExtId, item.id));
                }
                break;
            case "InstantAward":
                if (!isNil(item.clientExtId)) {
                    this.props.history.push(Path.editClientInstantAward(item.clientExtId, item.id));
                }
                break;
            case "TextEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "TextAndImageEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "ImageEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "AudioEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "EverydaySituationDirectory":
                this.props.history.push(Path.clientEverydaySituationsDirectories(item.clientExtId || ""));
                break;
            case "SelectionBoard":
                this.props.history.push(Path.editSelectionBoard(item.clientExtId!, item.id));
                break;
            default:
                return;
        }
    };

    private readonly onDeleteClick = (item: DisabledItem): void => {
        switch (item.type) {
            case "Agenda":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteAgenda,
                        agenda: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "AgendaItem":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteAgendaItem,
                        agendaItem: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "Flowchart":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteFlowchart,
                        flowchart: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "FlowchartItem":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteFlowchartItem,
                        flowchartItem: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "Asset":
                this.props.history.push(Path.editAsset(item.id));
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteAsset,
                        asset: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "Award":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.verifyDeleteClientAward,
                        clientAwardId: item.id,
                        onDeleted: () => this.refreshContent(this.state.options),
                        isAwardActive: false,
                        clientName: "", // award is not active so client name will not be used
                    }),
                );
                break;
            case "InstantAward":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.verifyDeleteClientInstantAward,
                        clientInstantAwardId: item.id,
                        onDeleted: () => this.refreshContent(this.state.options),
                        hasReferences: false,
                    }),
                );
                break;
            case "TextEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "TextAndImageEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "ImageEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "AudioEverydaySituation":
                this.props.history.push(Path.editClientEverydaySituationsDirectory(item.clientExtId || "", item.directoryId || ""));
                break;
            case "EverydaySituationDirectory":
                this.props.dispatch(
                    DialogsActions.show({
                        type: DialogType.deleteEverydaySituationDirectory,
                        everydaySituationDirectory: { id: item.id, title: item.title },
                        onDeleted: () => this.refreshContent(this.state.options),
                    }),
                );
                break;
            case "SelectionBoard":
                this.props.history.push(Path.clientSelectionBoards(item.id));
                break;
            default:
                return;
        }
    };

    private getOptionsMenuItems = (item: DisabledItem): OptionMenuItem[] => {
        const optionsMenuItems: OptionMenuItem[] = [];
        if (!DisabledItemTypesWithoutView.includes(item.type)) {
            optionsMenuItems.push({
                label: Intl.formatMessage({ id: "common.view" }),
                onClick: () => this.onViewClick(item),
                icon: "fa-eye",
            });
        }
        return [
            ...optionsMenuItems,
            {
                label: Intl.formatMessage({ id: "common.edit" }),
                onClick: () => this.onEditClick(item),
                icon: "fa-pencil-alt",
            },
            {
                label: Intl.formatMessage({ id: "common.delete" }),
                onClick: () => this.onDeleteClick(item),
                icon: "fa-trash",
            },
        ];
    };

    private readonly getColumns = (): Array<Column<DisabledItem>> => {
        const columnNames: DisabledItemsTableColumn[] = ObjectUtils.enumAsArray<DisabledItemsTableColumn>(DisabledItemsTableColumn);

        return columnNames.map(
            (columnName: DisabledItemsTableColumn): Column<DisabledItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.disabledItemsTable.columns.${columnName}` }),
                accessor: columnName as keyof DisabledItem,
                renderCell: (item: DisabledItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case DisabledItemsTableColumn.title:
                            return (
                                <>
                                    <div className="table-image-name">
                                        <div
                                            className="table-image-container"
                                            onClick={
                                                item.url && item.assetType
                                                    ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                          event.stopPropagation();
                                                          this.props.dispatch(
                                                              DialogsActions.show({
                                                                  type: DialogType.showAsset,
                                                                  assetUrl: item.url || item.thumbnail,
                                                                  assetType: item.assetType || AssetType.image,
                                                                  originalFileName: item?.title,
                                                                  thumbnailUrl: item?.thumbnail,
                                                                  dialogTitle: item?.title || Intl.formatMessage({ id: `enum.assetType.${item.assetType}` }),
                                                              }),
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <Image src={item.thumbnail} fallback={ImageSrc.asset} />
                                            {item.url && item.assetType && (
                                                <>
                                                    <div className="content-overlay" />
                                                    <div className="content-overlay-icon">
                                                        <OverlayIcon assetType={item.assetType} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {item.title}
                                    </div>
                                </>
                            );
                        case DisabledItemsTableColumn.info:
                            return <DisabledLock isVisible={true} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.disabledItemsTable.disabledTooltip" })} />;
                        case DisabledItemsTableColumn.type:
                            return <>{Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.disabledItemsTable.types.${item.type}` })}</>;
                        case DisabledItemsTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(item.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case DisabledItemsTableColumn.actions:
                            return <OptionsMenu items={this.getOptionsMenuItems(item)} />;
                        default:
                            return null;
                    }
                },
                isNonSortable: !ObjectUtils.enumAsArray(DisabledContentListSortField).includes(columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<DisabledContentListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };
        const params = new ListUrlQueryParser<DisabledContentListSortField>(DisabledContentListSortField, "sharedComponent.contentLibraryTable.disabledItemsTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshContent(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): DisabledContentListSortField | undefined => {
        switch (columnId) {
            case DisabledItemsTableColumn.createdAt:
                return DisabledContentListSortField.createdAt;
            case DisabledItemsTableColumn.title:
                return DisabledContentListSortField.title;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: DisabledContentListSortField | null): keyof DisabledItem | undefined => {
        switch (columnId) {
            case DisabledContentListSortField.createdAt:
                return DisabledItemsTableColumn.createdAt;
            case DisabledContentListSortField.title:
                return DisabledItemsTableColumn.title;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<DisabledItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    public render(): React.ReactElement {
        return (
            <Table
                keyExtractor={(item: DisabledItem, column?: Column<DisabledItem>): string => {
                    return `${item.id}_${column ? column.id : ""}`;
                }}
                columns={this.getColumns()}
                sortBy={{
                    columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                    order: this.state.options.control.sortOrder || undefined,
                }}
                data={this.state.content}
                count={this.state.count}
                limit={this.state.options.control.limit}
                isSortable={true}
                onSortOrderChange={this.onSortOrderChange}
                onPageChange={this.onPageChange}
                isPaginationEnabled={true}
                currentPage={this.getCurrentPage()}
                isLoading={this.state.isLoading}
                renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.disabledItemsTable.noData" })}
            />
        );
    }
}

export const DisabledItemsTable = withRouter(connect()(DisabledItemsTableComponent));

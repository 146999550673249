import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { SortOrder, ListControl, SearchListSortField, SearchListOptionsInput, AssetListItem, AssetType, AccountType, AssetContent } from "api/graphql/types";
import { RouteComponentProps } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { ObjectUtils } from "utils/ObjectUtils";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { ListResult, ApiTypes } from "api/ApiTypes";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { AssetOptionMenu } from "../ContentOptionMenu/AssetOptionMenu";
import { PageContent } from "../ContentPageUtils";
import { DispatchProp } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";
import { MoveIcon } from "components/Icons/MoveIcon";
import { isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";
import { Api } from "../../../api/Api";
import { OverlayIcon } from "components/OverlayIcon";

interface AssetTableSearchListOptions {
    sortField?: SearchListSortField | null;
    control: ListControl;
}

interface AssetSelect {
    id: string;
    selected: boolean;
}

interface State {
    assets: AssetListItem[];
    count: number;
    isLoading: boolean;
    options: AssetTableSearchListOptions;
    tags: string[];
    selectedAssets: AssetSelect[];
    allSelected: boolean;
}

enum AssetItemTableColumn {
    select = "select",
    title = "title",
    type = "type",
    info = "info",
    fileSize = "fileSize",
    createdAt = "createdAt",
    createdByName = "createdByName",
    actions = "actions",
}

interface ComponentProps {
    supporterExtId?: string | null;
    supervisorId?: string | null;
    tags?: string[];
    search?: string;
    isPublic?: boolean;
    currentUrlParams: string;
    directory: string | undefined;
    pageContent: PageContent;
    accountType: AccountType;
    getAssets: (options?: SearchListOptionsInput, tags?: string[]) => Promise<ListResult<AssetListItem>>;
    reloadAssets: () => void;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class AssetTable extends Component<Props, State> {
    private selectAllRef: HTMLInputElement | null = null;

    private static getInitialOptions(props: Props): AssetTableSearchListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.assetTable").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search: props.search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    private static getInitialTags(props: Props): string[] {
        return props.tags || [];
    }

    public readonly state: State = {
        assets: [],
        isLoading: true,
        count: 0,
        options: AssetTable.getInitialOptions(this.props),
        selectedAssets: [],
        allSelected: false,
        tags: AssetTable.getInitialTags(this.props),
    };

    public componentDidMount(): void {
        this.refreshAssets(this.state.options, this.state.tags);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        const nextOptions: AssetTableSearchListOptions = AssetTable.getInitialOptions(nextProps);
        const nextTags: string[] = AssetTable.getInitialTags(nextProps);
        if (!isEqual(this.state.options, nextOptions) || this.props.isPublic !== nextProps.isPublic || !isEqual(this.state.tags, nextTags)) {
            this.setState({ options: nextOptions, tags: nextTags }, () => {
                this.updateQueryParams();
            });
        }
    }

    private refreshAssets = (options?: SearchListOptionsInput, tags?: string[]): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await this.props.getAssets(options, tags);
                    const selectedAssets: AssetSelect[] = result.map(asset => {
                        return {
                            id: asset.id,
                            selected: false,
                        };
                    });
                    this.setState({ assets: result, count, selectedAssets, allSelected: false, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, assets: [] });
                }
            },
        );
    };

    /**
     * Reload assets with current options
     */
    public reloadAssets = (): void => {
        this.refreshAssets(this.state.options, this.state.tags);
    };

    private onDeleteClick = (asset: AssetListItem) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteAsset,
                onDeleted: () => {
                    this.refreshAssets(this.state.options, this.state.tags);
                },
                asset,
            }),
        );
    };

    private readonly onToggleClick = (asset: AssetListItem) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                onToggled: (): void => this.refreshAssets(this.state.options, this.state.tags),
                content: asset,
            }),
        );
    };

    private readonly onCopyClick = (asset: AssetListItem) => async (): Promise<void> => {
        try {
            await Api.copyAssetToAccountPersonalLibrary({ assetId: asset.id });
            Alert.success({ title: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrarySucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private readonly isAssetSelected = (asset: AssetListItem): boolean => {
        const { selectedAssets } = this.state;
        return selectedAssets.some(select => select.id === asset.id && select.selected);
    };

    private readonly toggleSelectAsset = (asset: AssetListItem) => {
        const { selectedAssets } = this.state;
        const toggle: AssetSelect[] = selectedAssets.map(select => {
            if (select.id === asset.id) {
                select.selected = !select.selected;
            }
            return select;
        });
        this.setState(
            {
                selectedAssets: toggle,
            },
            () => this.handleAllSelector(),
        );
    };

    private readonly countSelectedAssets = (): number => {
        const { selectedAssets } = this.state;
        return selectedAssets.filter(asset => asset.selected).length;
    };

    private readonly isAllSelected = (): boolean => {
        const { assets } = this.state;
        return this.countSelectedAssets() === assets.length;
    };

    private readonly handleAllSelector = () => {
        if (this.selectAllRef) {
            if (!this.isAllSelected() && this.countSelectedAssets() > 0) {
                this.selectAllRef.indeterminate = true;
            } else if (this.isAllSelected()) {
                this.selectAllRef.indeterminate = false;
                this.setState({
                    allSelected: true,
                });
            } else {
                this.selectAllRef.indeterminate = false;
                this.setState({
                    allSelected: false,
                });
            }
        }
    };

    private readonly changeAllSelect = (selected: boolean) => {
        const { selectedAssets } = this.state;
        this.setState({
            selectedAssets: selectedAssets.map(asset => {
                return {
                    id: asset.id,
                    selected: selected,
                };
            }),
        });
    };

    private readonly toggleSelectAll = () => {
        if (this.selectAllRef) {
            if (this.isAllSelected()) {
                this.changeAllSelect(false);
                this.setState({
                    allSelected: false,
                });
            } else {
                this.changeAllSelect(true);
                this.setState({
                    allSelected: true,
                });
            }
        }
    };

    private onMoveClick = (asset: AssetContent) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.moveAsset,
                isVisible: true,
                onMoved: () => {
                    this.refreshAssets(this.state.options);
                },
                assets: [asset.id],
                currentDirectory: this.props.directory || null,
                isPublic: this.props.isPublic || false,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<AssetListItem>> => {
        let columnNames: AssetItemTableColumn[] = ObjectUtils.enumAsArray<AssetItemTableColumn>(AssetItemTableColumn);

        if (!this.props.isPublic || this.props.accountType !== AccountType.supervisor) {
            columnNames = columnNames.filter((columnName: AssetItemTableColumn) => columnName !== AssetItemTableColumn.createdByName);
        }

        if (!this.canUserMoveAsset()) {
            columnNames = columnNames.filter((columnName: AssetItemTableColumn) => columnName !== AssetItemTableColumn.select);
        }

        return columnNames.map(
            (columnName: AssetItemTableColumn): Column<AssetListItem> => ({
                id: columnName,
                name: columnName === AssetItemTableColumn.select ? "" : Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.assetTable.columns.${columnName}` }),
                accessor: columnName as keyof AssetListItem,
                renderCell: (asset: AssetListItem): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case AssetItemTableColumn.fileSize:
                            return <>{IntlHelpers.getFileSize(asset.fileSize)}</>;
                        case AssetItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(asset.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case AssetItemTableColumn.createdByName:
                            return <>{asset.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</>;
                        case AssetItemTableColumn.info:
                            return <DisabledLock isVisible={!isNil(asset.disabledAt)} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.assetTable.disabledTooltip" })} />;
                        case AssetItemTableColumn.actions:
                            return (
                                <AssetOptionMenu
                                    isOwnAsset={this.props.pageContent === PageContent.own}
                                    onDeleteClick={this.onDeleteClick(asset)}
                                    supporterExtId={this.props.supporterExtId}
                                    pageContent={this.props.pageContent}
                                    supervisorId={this.props.supervisorId}
                                    isPublic={this.props.isPublic}
                                    asset={asset}
                                    refreshAssets={this.refreshAssets}
                                    onToggleClick={this.onToggleClick(asset)}
                                    onCopyClick={this.onCopyClick(asset)}
                                    onMoveClick={this.onMoveClick(asset)}
                                />
                            );
                        case AssetItemTableColumn.title:
                            const assetUrl: string | undefined = asset.assetType === AssetType.avatar ? `${asset.url}?${new Date().getTime()}` : ApiTypes.getAssetImageUrl(asset);
                            return (
                                <>
                                    <div className="table-image-name">
                                        <div
                                            className="table-image-container"
                                            onClick={
                                                asset.url
                                                    ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                          event.stopPropagation();
                                                          this.props.dispatch(
                                                              DialogsActions.show({
                                                                  type: DialogType.showAsset,
                                                                  assetUrl: asset.url || assetUrl,
                                                                  assetType: asset?.assetType || AssetType.image,
                                                                  originalFileName: asset?.originalFileName,
                                                                  thumbnailUrl: asset?.thumbnailUrl,
                                                                  dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` }),
                                                              }),
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <Image src={assetUrl} fallback={asset.assetType === AssetType.audio ? ImageSrc.audioAsset : ImageSrc.asset} />
                                            {asset.url && (
                                                <>
                                                    <div className="content-overlay" />
                                                    <div className="content-overlay-icon">
                                                        <OverlayIcon assetType={asset.assetType} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {asset.title}
                                    </div>
                                </>
                            );
                        case AssetItemTableColumn.select:
                            return this.canUserMoveAsset() ? <Checkbox checked={this.isAssetSelected(asset)} onChange={() => this.toggleSelectAsset(asset)} /> : null;
                        case AssetItemTableColumn.type:
                            return <>{Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` })}</>;
                        default:
                            return null;
                    }
                },
                isNonSortable: ![AssetItemTableColumn.fileSize, AssetItemTableColumn.title, AssetItemTableColumn.createdAt].includes(columnName),
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<SearchListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
            tags: this.state.tags,
        };

        const params = new ListUrlQueryParser<SearchListSortField>(SearchListSortField, "sharedComponent.contentLibraryTable.assetTable").getUrlQuery(options);
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshAssets(this.state.options, this.state.tags);
    };

    private convertColumnIdToSortField = (columnId?: string): SearchListSortField | undefined => {
        switch (columnId) {
            case AssetItemTableColumn.createdAt:
                return SearchListSortField.createdAt;
            case AssetItemTableColumn.title:
                return SearchListSortField.title;
            case AssetItemTableColumn.fileSize:
                return SearchListSortField.fileSize;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: SearchListSortField | null): keyof AssetListItem | undefined => {
        switch (columnId) {
            case SearchListSortField.createdAt:
                return AssetItemTableColumn.createdAt;
            case SearchListSortField.title:
                return AssetItemTableColumn.title;
            case SearchListSortField.fileSize:
                return AssetItemTableColumn.fileSize;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AssetListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getSelectedAssetIds = (): string[] => {
        const selectedIds: string[] = [];
        this.state.selectedAssets.forEach(asset => {
            if (asset.selected) {
                selectedIds.push(asset.id);
            }
        });
        return selectedIds;
    };

    private onMoved = () => {
        this.props.reloadAssets();
        this.changeAllSelect(false);
        this.setState({
            allSelected: false,
            selectedAssets: [],
        });
    };

    private onMoveAllClick = () => {
        const assets: string[] = this.getSelectedAssetIds();
        if (assets.length) {
            this.props.dispatch(
                DialogsActions.show({
                    type: DialogType.moveAsset,
                    isVisible: true,
                    onMoved: this.onMoved,
                    assets,
                    currentDirectory: this.props.directory || null,
                    isPublic: this.props.isPublic || false,
                }),
            );
        } else {
            Alert.info({ title: Intl.formatMessage({ id: "sharedComponent.moveAssetsDialog.noAsset" }) });
        }
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private canUserMoveAsset = (): boolean => {
        return !this.props.isPublic || this.props.accountType === AccountType.supervisor;
    };

    public render(): React.ReactElement<any> | null {
        return (
            <>
                <div className="grid-x pl-2 table-top justify-content-start">
                    {this.canUserMoveAsset() && (
                        <Checkbox
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.selectAllRef = ref;
                            }}
                            checked={this.state.allSelected}
                            onChange={this.toggleSelectAll}
                        />
                    )}
                    {this.canUserMoveAsset() && (
                        <TooltipWrapper tooltip={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.assetTable.moveAll" })}>
                            <a onClick={this.onMoveAllClick}>
                                <MoveIcon width={22} height={18} />
                            </a>
                        </TooltipWrapper>
                    )}
                </div>
                <hr />
                <Table
                    keyExtractor={(item: AssetListItem, column?: Column<AssetListItem>): string => {
                        return `${item.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    sortBy={{
                        columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                        order: this.state.options.control.sortOrder || undefined,
                    }}
                    data={this.state.assets}
                    count={this.state.count}
                    limit={this.state.options.control.limit}
                    isSortable={true}
                    onSortOrderChange={this.onSortOrderChange}
                    onPageChange={this.onPageChange}
                    isPaginationEnabled={true}
                    currentPage={this.getCurrentPage()}
                    isLoading={this.state.isLoading}
                    renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.assetTable.noData" })}
                />
            </>
        );
    }
}

export { AssetTable };

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: login
// ====================================================

export interface login_login {
  __typename: "AuthResponse";
  /**
   * JWT auth token (short life)
   */
  authToken: string;
  /**
   * JWT refresh token (long life)
   */
  refreshToken: string;
}

export interface login {
  /**
   * Log in with primary e-mail address and password
   */
  login: login_login;
}

export interface loginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: logout
// ====================================================

export interface logout {
  /**
   * Log out current device (invalidate device refresh token).
   */
  logout: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: registration
// ====================================================

export interface registration {
  /**
   * Registration
   */
  registration: any | null;
}

export interface registrationVariables {
  email: any;
  name: string;
  clientToken: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: activateAccount
// ====================================================

export interface activateAccount {
  /**
   * Activate account with activation code sent after registration + with password
   */
  activateAccount: any | null;
}

export interface activateAccountVariables {
  id: string;
  code: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: forgotPassword
// ====================================================

export interface forgotPassword {
  /**
   * Request to send e-mail with 'code' for Mutation.resetPassword
   */
  forgotPassword: any | null;
}

export interface forgotPasswordVariables {
  email: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: resetPassword
// ====================================================

export interface resetPassword {
  /**
   * Change account password authenticated by 'code' received over email
   * - @see Mutation.forgotPassword
   */
  resetPassword: any | null;
}

export interface resetPasswordVariables {
  id: string;
  code: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: refreshToken
// ====================================================

export interface refreshToken {
  /**
   * JWT refresh token
   */
  refreshToken: string;
}

export interface refreshTokenVariables {
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateName
// ====================================================

export interface updateName {
  /**
   * Change account name
   */
  updateName: any | null;
}

export interface updateNameVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeEmailRequest
// ====================================================

export interface changeEmailRequest {
  /**
   * Change e-mail request
   * - @requires auth
   */
  changeEmailRequest: any | null;
}

export interface changeEmailRequestVariables {
  newEmail: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changePassword
// ====================================================

export interface changePassword {
  /**
   * Change account password using current and new passwords
   * - @requires auth
   */
  changePassword: any | null;
}

export interface changePasswordVariables {
  password: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePin
// ====================================================

export interface updatePin {
  /**
   * Change account pin
   */
  updatePin: any | null;
}

export interface updatePinVariables {
  pin: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: changeEmailByCode
// ====================================================

export interface changeEmailByCode {
  /**
   * Change account's primary e-mail address by code
   * - @see Mutation.changeEmailRequest
   */
  changeEmailByCode: any | null;
}

export interface changeEmailByCodeVariables {
  id: string;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadAccountAvatar
// ====================================================

export interface uploadAccountAvatar_uploadAccountAvatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface uploadAccountAvatar_uploadAccountAvatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: uploadAccountAvatar_uploadAccountAvatar_createdBy | null;
  tags: string[];
}

export interface uploadAccountAvatar {
  /**
   * Upload (create or update) account avatar image
   */
  uploadAccountAvatar: uploadAccountAvatar_uploadAccountAvatar | null;
}

export interface uploadAccountAvatarVariables {
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAccountAvatar
// ====================================================

export interface deleteAccountAvatar {
  /**
   * Delete account avatar image
   */
  deleteAccountAvatar: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMyAccount
// ====================================================

export interface deleteMyAccount {
  /**
   * Delete my account - only for supporter
   */
  deleteMyAccount: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePin
// ====================================================

export interface deletePin {
  /**
   * Delete account pin
   */
  deletePin: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClient
// ====================================================

export interface createClient_createClient_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createClient_createClient_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createClient_createClient_avatar_createdBy | null;
  tags: string[];
}

export interface createClient_createClient_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface createClient_createClient_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface createClient_createClient_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createClient_createClient_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: createClient_createClient_pendingClientManagerChangeRequest_createdBy | null;
  newManager: createClient_createClient_pendingClientManagerChangeRequest_newManager | null;
}

export interface createClient_createClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: createClient_createClient_avatar | null;
  defaultAgenda: createClient_createClient_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: createClient_createClient_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface createClient {
  /**
   * Create client
   */
  createClient: createClient_createClient;
}

export interface createClientVariables {
  clientInput: CreateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadClientAvatar
// ====================================================

export interface uploadClientAvatar_uploadClientAvatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface uploadClientAvatar_uploadClientAvatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: uploadClientAvatar_uploadClientAvatar_createdBy | null;
  tags: string[];
}

export interface uploadClientAvatar {
  /**
   * Upload (create or update) client avatar image
   */
  uploadClientAvatar: uploadClientAvatar_uploadClientAvatar | null;
}

export interface uploadClientAvatarVariables {
  clientId: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientAvatar
// ====================================================

export interface deleteClientAvatar {
  /**
   * Delete client avatar image
   */
  deleteClientAvatar: any | null;
}

export interface deleteClientAvatarVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClient
// ====================================================

export interface updateClient_updateClient_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_avatar_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_introductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_introductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_introductionAudio_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_introductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_introductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_introductionVideo_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateClient_updateClient_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateClient_updateClient_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateClient_updateClient_devices {
  __typename: "DeviceList";
  count: number;
  result: updateClient_updateClient_devices_result[];
}

export interface updateClient_updateClient_supporters_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface updateClient_updateClient_supporters_result_clientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
}

export interface updateClient_updateClient_supporters_result {
  __typename: "ClientSupporter";
  account: updateClient_updateClient_supporters_result_account | null;
  clientShareRequest: updateClient_updateClient_supporters_result_clientShareRequest | null;
  name: string | null;
  email: string;
  status: string;
  createdAt: any;
}

export interface updateClient_updateClient_supporters {
  __typename: "ClientSupporterList";
  count: number;
  result: updateClient_updateClient_supporters_result[];
}

export interface updateClient_updateClient_managedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_defaultAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClient_updateClient_defaultAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface updateClient_updateClient_defaultAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_defaultAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: updateClient_updateClient_defaultAgenda_client | null;
  updatedAt: any | null;
  itemList: updateClient_updateClient_defaultAgenda_itemList[] | null;
  createdBy: updateClient_updateClient_defaultAgenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateClient_updateClient_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface updateClient_updateClient_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: updateClient_updateClient_pendingClientManagerChangeRequest_createdBy | null;
  newManager: updateClient_updateClient_pendingClientManagerChangeRequest_newManager | null;
}

export interface updateClient_updateClient_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: updateClient_updateClient_instantAwards_result_image | null;
  referenceCount: number;
}

export interface updateClient_updateClient_instantAwards {
  __typename: "InstantAwardList";
  result: updateClient_updateClient_instantAwards_result[];
}

export interface updateClient_updateClient_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: updateClient_updateClient_selectionBoards_result_items_image | null;
}

export interface updateClient_updateClient_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClient_updateClient_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface updateClient_updateClient_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClient_updateClient_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: updateClient_updateClient_selectionBoards_result_items[];
  image: updateClient_updateClient_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: updateClient_updateClient_selectionBoards_result_createdBy | null;
}

export interface updateClient_updateClient_selectionBoards {
  __typename: "SelectionBoardList";
  result: updateClient_updateClient_selectionBoards_result[];
}

export interface updateClient_updateClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: updateClient_updateClient_avatar | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  introduction: string | null;
  introductionAudio: updateClient_updateClient_introductionAudio | null;
  introductionVideo: updateClient_updateClient_introductionVideo | null;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateClient_updateClient_devices;
  /**
   * Client supporters
   */
  supporters: updateClient_updateClient_supporters;
  /**
   * This account can modify main client datas (default value: created_by)
   */
  managedBy: updateClient_updateClient_managedBy;
  defaultAgenda: updateClient_updateClient_defaultAgenda | null;
  /**
   *  Client is supported by him/herself 
   */
  isSelfSupporting: boolean;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: updateClient_updateClient_pendingClientManagerChangeRequest | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
  awardScoreBalance: number;
  instantAwards: updateClient_updateClient_instantAwards | null;
  selectionBoards: updateClient_updateClient_selectionBoards | null;
  notificationAfterCreate: string;
  notificationOnProfilePage: string;
}

export interface updateClient {
  /**
   * Update client
   */
  updateClient: updateClient_updateClient;
}

export interface updateClientVariables {
  clientId: string;
  clientInput: UpdateClientInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClient
// ====================================================

export interface deleteClient {
  /**
   * Delete client (by supporter or (super)admin)
   */
  deleteClient: any | null;
}

export interface deleteClientVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareClient
// ====================================================

export interface shareClient {
  /**
   * Share client with e-mail
   */
  shareClient: any | null;
}

export interface shareClientVariables {
  email: any;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unlinkClientFromSupporter
// ====================================================

export interface unlinkClientFromSupporter {
  /**
   * Unlink client from supporter (managed by me)
   */
  unlinkClientFromSupporter: any | null;
}

export interface unlinkClientFromSupporterVariables {
  clientId: string;
  supporterAccountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientConfig
// ====================================================

export interface updateClientConfig_updateClientConfig_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface updateClientConfig_updateClientConfig_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda_image | null;
}

export interface updateClientConfig_updateClientConfig_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: updateClientConfig_updateClientConfig_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: updateClientConfig_updateClientConfig_agenda_withoutDefaultAgenda | null;
}

export interface updateClientConfig_updateClientConfig_moodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface updateClientConfig_updateClientConfig_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: updateClientConfig_updateClientConfig_moodMeter_items[];
  question: string | null;
}

export interface updateClientConfig_updateClientConfig_timer {
  __typename: "TimerConfig";
  type: TimerType;
  requestConfirmation: boolean;
}

export interface updateClientConfig_updateClientConfig_award {
  __typename: "AwardConfig";
  requestConfirmation: boolean;
}

export interface updateClientConfig_updateClientConfig_unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

export interface updateClientConfig_updateClientConfig {
  __typename: "ClientConfig";
  enabledDashboardItems: DashboardItem[] | null;
  enabledGames: updateClientConfig_updateClientConfig_enabledGames[] | null;
  mainPageType: MainPageType;
  mainPageBackgroundColor: any | null;
  mainPageDataSyncEnabled: boolean;
  agenda: updateClientConfig_updateClientConfig_agenda | null;
  flowchartListType: FlowChartListType;
  flowchartItemBackgroundColor: any | null;
  moodMeter: updateClientConfig_updateClientConfig_moodMeter | null;
  timer: updateClientConfig_updateClientConfig_timer;
  selectionBoardListType: SelectionBoardListType;
  award: updateClientConfig_updateClientConfig_award;
  unlock: updateClientConfig_updateClientConfig_unlock;
  highContrastMode: boolean;
}

export interface updateClientConfig {
  /**
   * Change client config json
   */
  updateClientConfig: updateClientConfig_updateClientConfig;
}

export interface updateClientConfigVariables {
  clientId: string;
  clientConfig: ClientConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAdminAccount
// ====================================================

export interface createAdminAccount_createAdminAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAdminAccount {
  /**
   * Create admin account
   */
  createAdminAccount: createAdminAccount_createAdminAccount;
}

export interface createAdminAccountVariables {
  email: any;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSupervisorAccount
// ====================================================

export interface createSupervisorAccount_createSupervisorAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createSupervisorAccount {
  /**
   * Create supervisor account
   */
  createSupervisorAccount: createSupervisorAccount_createSupervisorAccount;
}

export interface createSupervisorAccountVariables {
  email: any;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSupporterAccount
// ====================================================

export interface createSupporterAccount_createSupporterAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createSupporterAccount {
  /**
   * Create supporter account
   */
  createSupporterAccount: createSupporterAccount_createSupporterAccount;
}

export interface createSupporterAccountVariables {
  email: any;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAccount
// ====================================================

export interface enableAccount {
  /**
   * Enable admin or supervisor account
   */
  enableAccount: any | null;
}

export interface enableAccountVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAccount
// ====================================================

export interface disableAccount {
  /**
   * Disable admin or supervisor account
   */
  disableAccount: any | null;
}

export interface disableAccountVariables {
  accountId: string;
  disabledReason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableSupporter
// ====================================================

export interface enableSupporter {
  /**
   * Enable supporter account
   */
  enableSupporter: any | null;
}

export interface enableSupporterVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableSupporter
// ====================================================

export interface disableSupporter {
  /**
   * Disable supporter account
   */
  disableSupporter: any | null;
}

export interface disableSupporterVariables {
  accountId: string;
  disabledReason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAccount
// ====================================================

export interface deleteAccount {
  /**
   * Delete account
   */
  deleteAccount: any | null;
}

export interface deleteAccountVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptClientShareRequest
// ====================================================

export interface acceptClientShareRequest {
  /**
   * Accept client share request
   */
  acceptClientShareRequest: any | null;
}

export interface acceptClientShareRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rejectClientShareRequest
// ====================================================

export interface rejectClientShareRequest {
  /**
   * Reject client share request
   */
  rejectClientShareRequest: any | null;
}

export interface rejectClientShareRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientShareRequest
// ====================================================

export interface deleteClientShareRequest {
  /**
   * Delete client share request
   */
  deleteClientShareRequest: any | null;
}

export interface deleteClientShareRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadAsset
// ====================================================

export interface uploadAsset_uploadAsset {
  __typename: "Asset";
  id: string;
}

export interface uploadAsset {
  /**
   * Upload asset
   */
  uploadAsset: uploadAsset_uploadAsset | null;
}

export interface uploadAssetVariables {
  title: string;
  file: any;
  tags?: string[] | null;
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSelectionBoard
// ====================================================

export interface createSelectionBoard_createSelectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createSelectionBoard_createSelectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createSelectionBoard_createSelectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface createSelectionBoard_createSelectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: createSelectionBoard_createSelectionBoard_items_image | null;
}

export interface createSelectionBoard_createSelectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createSelectionBoard_createSelectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createSelectionBoard_createSelectionBoard_image_createdBy | null;
  tags: string[];
}

export interface createSelectionBoard_createSelectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createSelectionBoard_createSelectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: createSelectionBoard_createSelectionBoard_items[];
  image: createSelectionBoard_createSelectionBoard_image | null;
  disabledAt: any | null;
  createdBy: createSelectionBoard_createSelectionBoard_createdBy | null;
}

export interface createSelectionBoard {
  createSelectionBoard: createSelectionBoard_createSelectionBoard;
}

export interface createSelectionBoardVariables {
  clientId: string;
  input: SelectionBoardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSelectionBoard
// ====================================================

export interface updateSelectionBoard_updateSelectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateSelectionBoard_updateSelectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateSelectionBoard_updateSelectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface updateSelectionBoard_updateSelectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: updateSelectionBoard_updateSelectionBoard_items_image | null;
}

export interface updateSelectionBoard_updateSelectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateSelectionBoard_updateSelectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateSelectionBoard_updateSelectionBoard_image_createdBy | null;
  tags: string[];
}

export interface updateSelectionBoard_updateSelectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateSelectionBoard_updateSelectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: updateSelectionBoard_updateSelectionBoard_items[];
  image: updateSelectionBoard_updateSelectionBoard_image | null;
  disabledAt: any | null;
  createdBy: updateSelectionBoard_updateSelectionBoard_createdBy | null;
}

export interface updateSelectionBoard {
  updateSelectionBoard: updateSelectionBoard_updateSelectionBoard;
}

export interface updateSelectionBoardVariables {
  selectionBoardId: string;
  input: SelectionBoardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSelectionBoard
// ====================================================

export interface deleteSelectionBoard {
  deleteSelectionBoard: any | null;
}

export interface deleteSelectionBoardVariables {
  selectionBoardId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAgendaItem
// ====================================================

export interface createAgendaItem_createAgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: createAgendaItem_createAgendaItem_flowchart_image | null;
  client: createAgendaItem_createAgendaItem_flowchart_client | null;
  createdBy: createAgendaItem_createAgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: createAgendaItem_createAgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface createAgendaItem_createAgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface createAgendaItem_createAgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: createAgendaItem_createAgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: createAgendaItem_createAgendaItem_client_devices_result[];
}

export interface createAgendaItem_createAgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: createAgendaItem_createAgendaItem_client_devices;
}

export interface createAgendaItem_createAgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgendaItem_createAgendaItem_alert_audio | null;
}

export interface createAgendaItem_createAgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface createAgendaItem_createAgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgendaItem_createAgendaItem_agendaItems_agendaItem_image | null;
  video: createAgendaItem_createAgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgendaItem_createAgendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgendaItem_createAgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgendaItem_createAgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgendaItem_createAgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: createAgendaItem_createAgendaItem_agendaItems_agendaItem_notification | null;
  timer: createAgendaItem_createAgendaItem_agendaItems_agendaItem_timer | null;
  award: createAgendaItem_createAgendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgendaItem_createAgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: createAgendaItem_createAgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: createAgendaItem_createAgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: createAgendaItem_createAgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgendaItem_createAgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: createAgendaItem_createAgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface createAgendaItem_createAgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface createAgendaItem_createAgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: createAgendaItem_createAgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface createAgendaItem_createAgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: createAgendaItem_createAgendaItem_selectionBoard_items_image | null;
}

export interface createAgendaItem_createAgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface createAgendaItem_createAgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgendaItem_createAgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: createAgendaItem_createAgendaItem_selectionBoard_items[];
  image: createAgendaItem_createAgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: createAgendaItem_createAgendaItem_selectionBoard_createdBy | null;
}

export interface createAgendaItem_createAgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgendaItem_createAgendaItem_flowchart | null;
  client: createAgendaItem_createAgendaItem_client | null;
  image: createAgendaItem_createAgendaItem_image | null;
  audio: createAgendaItem_createAgendaItem_audio | null;
  video: createAgendaItem_createAgendaItem_video | null;
  time: any | null;
  alert: createAgendaItem_createAgendaItem_alert | null;
  timer: createAgendaItem_createAgendaItem_timer | null;
  award: createAgendaItem_createAgendaItem_award | null;
  instantAwards: createAgendaItem_createAgendaItem_instantAwards[] | null;
  checklist: createAgendaItem_createAgendaItem_checklist | null;
  notification: createAgendaItem_createAgendaItem_notification | null;
  moodMeter: createAgendaItem_createAgendaItem_moodMeter | null;
  createdBy: createAgendaItem_createAgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: createAgendaItem_createAgendaItem_agendaItems[] | null;
  shareRequests: createAgendaItem_createAgendaItem_shareRequests[] | null;
  selectionBoard: createAgendaItem_createAgendaItem_selectionBoard | null;
}

export interface createAgendaItem {
  /**
   * Create agenda item
   */
  createAgendaItem: createAgendaItem_createAgendaItem;
}

export interface createAgendaItemVariables {
  agendaItemInput: AgendaItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAgendaItem
// ====================================================

export interface updateAgendaItem_updateAgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: updateAgendaItem_updateAgendaItem_flowchart_image | null;
  client: updateAgendaItem_updateAgendaItem_flowchart_client | null;
  createdBy: updateAgendaItem_updateAgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: updateAgendaItem_updateAgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface updateAgendaItem_updateAgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateAgendaItem_updateAgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateAgendaItem_updateAgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: updateAgendaItem_updateAgendaItem_client_devices_result[];
}

export interface updateAgendaItem_updateAgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateAgendaItem_updateAgendaItem_client_devices;
}

export interface updateAgendaItem_updateAgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgendaItem_updateAgendaItem_alert_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface updateAgendaItem_updateAgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_image | null;
  video: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_notification | null;
  timer: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgendaItem_updateAgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: updateAgendaItem_updateAgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface updateAgendaItem_updateAgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface updateAgendaItem_updateAgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: updateAgendaItem_updateAgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: updateAgendaItem_updateAgendaItem_selectionBoard_items_image | null;
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgendaItem_updateAgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: updateAgendaItem_updateAgendaItem_selectionBoard_items[];
  image: updateAgendaItem_updateAgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: updateAgendaItem_updateAgendaItem_selectionBoard_createdBy | null;
}

export interface updateAgendaItem_updateAgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgendaItem_updateAgendaItem_flowchart | null;
  client: updateAgendaItem_updateAgendaItem_client | null;
  image: updateAgendaItem_updateAgendaItem_image | null;
  audio: updateAgendaItem_updateAgendaItem_audio | null;
  video: updateAgendaItem_updateAgendaItem_video | null;
  time: any | null;
  alert: updateAgendaItem_updateAgendaItem_alert | null;
  timer: updateAgendaItem_updateAgendaItem_timer | null;
  award: updateAgendaItem_updateAgendaItem_award | null;
  instantAwards: updateAgendaItem_updateAgendaItem_instantAwards[] | null;
  checklist: updateAgendaItem_updateAgendaItem_checklist | null;
  notification: updateAgendaItem_updateAgendaItem_notification | null;
  moodMeter: updateAgendaItem_updateAgendaItem_moodMeter | null;
  createdBy: updateAgendaItem_updateAgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: updateAgendaItem_updateAgendaItem_agendaItems[] | null;
  shareRequests: updateAgendaItem_updateAgendaItem_shareRequests[] | null;
  selectionBoard: updateAgendaItem_updateAgendaItem_selectionBoard | null;
}

export interface updateAgendaItem {
  /**
   * Update agenda item
   */
  updateAgendaItem: updateAgendaItem_updateAgendaItem;
}

export interface updateAgendaItemVariables {
  agendaItemId: string;
  agendaId?: string | null;
  agendaItemInput: AgendaItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAgendaItem
// ====================================================

export interface deleteAgendaItem {
  /**
   * Delete agenda item
   */
  deleteAgendaItem: any | null;
}

export interface deleteAgendaItemVariables {
  agendaItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFlowchartItem
// ====================================================

export interface createFlowchartItem_createFlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface createFlowchartItem_createFlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: createFlowchartItem_createFlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createFlowchartItem_createFlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: createFlowchartItem_createFlowchartItem_client_devices_result[];
}

export interface createFlowchartItem_createFlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: createFlowchartItem_createFlowchartItem_client_devices;
}

export interface createFlowchartItem_createFlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: createFlowchartItem_createFlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface createFlowchartItem_createFlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchart_image | null;
  client: createFlowchartItem_createFlowchartItem_flowchart_client | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: createFlowchartItem_createFlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_client | null;
  image: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_image | null;
  video: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface createFlowchartItem_createFlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: createFlowchartItem_createFlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface createFlowchartItem_createFlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface createFlowchartItem_createFlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface createFlowchartItem_createFlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: createFlowchartItem_createFlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface createFlowchartItem_createFlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: createFlowchartItem_createFlowchartItem_image | null;
  audio: createFlowchartItem_createFlowchartItem_audio | null;
  video: createFlowchartItem_createFlowchartItem_video | null;
  client: createFlowchartItem_createFlowchartItem_client | null;
  moodMeter: createFlowchartItem_createFlowchartItem_moodMeter | null;
  notification: createFlowchartItem_createFlowchartItem_notification | null;
  award: createFlowchartItem_createFlowchartItem_award | null;
  instantAwards: createFlowchartItem_createFlowchartItem_instantAwards[] | null;
  checklist: createFlowchartItem_createFlowchartItem_checklist | null;
  timer: createFlowchartItem_createFlowchartItem_timer | null;
  flowchart: createFlowchartItem_createFlowchartItem_flowchart | null;
  flowchartItems: createFlowchartItem_createFlowchartItem_flowchartItems[] | null;
  createdBy: createFlowchartItem_createFlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: createFlowchartItem_createFlowchartItem_shareRequests[] | null;
}

export interface createFlowchartItem {
  /**
   * Create flowchart item
   */
  createFlowchartItem: createFlowchartItem_createFlowchartItem;
}

export interface createFlowchartItemVariables {
  flowchartItemInput: FlowchartItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFlowchartItem
// ====================================================

export interface updateFlowchartItem_updateFlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateFlowchartItem_updateFlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateFlowchartItem_updateFlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateFlowchartItem_updateFlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: updateFlowchartItem_updateFlowchartItem_client_devices_result[];
}

export interface updateFlowchartItem_updateFlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateFlowchartItem_updateFlowchartItem_client_devices;
}

export interface updateFlowchartItem_updateFlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface updateFlowchartItem_updateFlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchart_image | null;
  client: updateFlowchartItem_updateFlowchartItem_flowchart_client | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: updateFlowchartItem_updateFlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_client | null;
  image: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateFlowchartItem_updateFlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: updateFlowchartItem_updateFlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface updateFlowchartItem_updateFlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface updateFlowchartItem_updateFlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface updateFlowchartItem_updateFlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: updateFlowchartItem_updateFlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface updateFlowchartItem_updateFlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: updateFlowchartItem_updateFlowchartItem_image | null;
  audio: updateFlowchartItem_updateFlowchartItem_audio | null;
  video: updateFlowchartItem_updateFlowchartItem_video | null;
  client: updateFlowchartItem_updateFlowchartItem_client | null;
  moodMeter: updateFlowchartItem_updateFlowchartItem_moodMeter | null;
  notification: updateFlowchartItem_updateFlowchartItem_notification | null;
  award: updateFlowchartItem_updateFlowchartItem_award | null;
  instantAwards: updateFlowchartItem_updateFlowchartItem_instantAwards[] | null;
  checklist: updateFlowchartItem_updateFlowchartItem_checklist | null;
  timer: updateFlowchartItem_updateFlowchartItem_timer | null;
  flowchart: updateFlowchartItem_updateFlowchartItem_flowchart | null;
  flowchartItems: updateFlowchartItem_updateFlowchartItem_flowchartItems[] | null;
  createdBy: updateFlowchartItem_updateFlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: updateFlowchartItem_updateFlowchartItem_shareRequests[] | null;
}

export interface updateFlowchartItem {
  /**
   * Update flowchart item
   */
  updateFlowchartItem: updateFlowchartItem_updateFlowchartItem;
}

export interface updateFlowchartItemVariables {
  flowchartItemId: string;
  flowchartItemInput: FlowchartItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFlowchartItem
// ====================================================

export interface deleteFlowchartItem {
  /**
   * Delete flowchart item
   */
  deleteFlowchartItem: any | null;
}

export interface deleteFlowchartItemVariables {
  flowchartItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAsset
// ====================================================

export interface deleteAsset {
  /**
   * Delete asset
   */
  deleteAsset: any | null;
}

export interface deleteAssetVariables {
  assetId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFlowchart
// ====================================================

export interface createFlowchart_createFlowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface createFlowchart_createFlowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: createFlowchart_createFlowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createFlowchart_createFlowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: createFlowchart_createFlowchart_client_devices_result[];
}

export interface createFlowchart_createFlowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: createFlowchart_createFlowchart_client_devices;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_notification_image | null;
  audio: createFlowchart_createFlowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchart_createFlowchart_itemList_flowchartItem_checklist_items[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface createFlowchart_createFlowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: createFlowchart_createFlowchart_itemList_flowchartItem_client | null;
  image: createFlowchart_createFlowchart_itemList_flowchartItem_image | null;
  video: createFlowchart_createFlowchart_itemList_flowchartItem_video | null;
  moodMeter: createFlowchart_createFlowchart_itemList_flowchartItem_moodMeter | null;
  notification: createFlowchart_createFlowchart_itemList_flowchartItem_notification | null;
  award: createFlowchart_createFlowchart_itemList_flowchartItem_award | null;
  timer: createFlowchart_createFlowchart_itemList_flowchartItem_timer | null;
  checklist: createFlowchart_createFlowchart_itemList_flowchartItem_checklist | null;
  createdBy: createFlowchart_createFlowchart_itemList_flowchartItem_createdBy | null;
  flowchart: createFlowchart_createFlowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: createFlowchart_createFlowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface createFlowchart_createFlowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: createFlowchart_createFlowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface createFlowchart_createFlowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface createFlowchart_createFlowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface createFlowchart_createFlowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: createFlowchart_createFlowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface createFlowchart_createFlowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: createFlowchart_createFlowchart_image | null;
  client: createFlowchart_createFlowchart_client | null;
  itemList: createFlowchart_createFlowchart_itemList[] | null;
  createdBy: createFlowchart_createFlowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: createFlowchart_createFlowchart_shareRequests[] | null;
}

export interface createFlowchart {
  /**
   * Create flowchart
   */
  createFlowchart: createFlowchart_createFlowchart;
}

export interface createFlowchartVariables {
  flowchartInput: FlowchartInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFlowchart
// ====================================================

export interface updateFlowchart_updateFlowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateFlowchart_updateFlowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateFlowchart_updateFlowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateFlowchart_updateFlowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: updateFlowchart_updateFlowchart_client_devices_result[];
}

export interface updateFlowchart_updateFlowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateFlowchart_updateFlowchart_client_devices;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_image | null;
  audio: updateFlowchart_updateFlowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchart_updateFlowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface updateFlowchart_updateFlowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: updateFlowchart_updateFlowchart_itemList_flowchartItem_client | null;
  image: updateFlowchart_updateFlowchart_itemList_flowchartItem_image | null;
  video: updateFlowchart_updateFlowchart_itemList_flowchartItem_video | null;
  moodMeter: updateFlowchart_updateFlowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateFlowchart_updateFlowchart_itemList_flowchartItem_notification | null;
  award: updateFlowchart_updateFlowchart_itemList_flowchartItem_award | null;
  timer: updateFlowchart_updateFlowchart_itemList_flowchartItem_timer | null;
  checklist: updateFlowchart_updateFlowchart_itemList_flowchartItem_checklist | null;
  createdBy: updateFlowchart_updateFlowchart_itemList_flowchartItem_createdBy | null;
  flowchart: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: updateFlowchart_updateFlowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateFlowchart_updateFlowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: updateFlowchart_updateFlowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface updateFlowchart_updateFlowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface updateFlowchart_updateFlowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface updateFlowchart_updateFlowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: updateFlowchart_updateFlowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface updateFlowchart_updateFlowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: updateFlowchart_updateFlowchart_image | null;
  client: updateFlowchart_updateFlowchart_client | null;
  itemList: updateFlowchart_updateFlowchart_itemList[] | null;
  createdBy: updateFlowchart_updateFlowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: updateFlowchart_updateFlowchart_shareRequests[] | null;
}

export interface updateFlowchart {
  /**
   * Update flowchart
   */
  updateFlowchart: updateFlowchart_updateFlowchart;
}

export interface updateFlowchartVariables {
  flowchartId: string;
  flowchartInput: FlowchartInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFlowchart
// ====================================================

export interface deleteFlowchart {
  /**
   * Delete flowchart
   */
  deleteFlowchart: any | null;
}

export interface deleteFlowchartVariables {
  flowchartId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAgenda
// ====================================================

export interface createAgenda_createAgenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface createAgenda_createAgenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: createAgenda_createAgenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createAgenda_createAgenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: createAgenda_createAgenda_client_devices_result[];
}

export interface createAgenda_createAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: createAgenda_createAgenda_client_devices;
}

export interface createAgenda_createAgenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_flowchart_image | null;
  itemList: createAgenda_createAgenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgenda_createAgenda_itemList_agendaItem_alert_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgenda_createAgenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: createAgenda_createAgenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface createAgenda_createAgenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createAgenda_createAgenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: createAgenda_createAgenda_itemList_agendaItem_image | null;
  video: createAgenda_createAgenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: createAgenda_createAgenda_itemList_agendaItem_flowchart | null;
  client: createAgenda_createAgenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: createAgenda_createAgenda_itemList_agendaItem_alert | null;
  moodMeter: createAgenda_createAgenda_itemList_agendaItem_moodMeter | null;
  notification: createAgenda_createAgenda_itemList_agendaItem_notification | null;
  timer: createAgenda_createAgenda_itemList_agendaItem_timer | null;
  award: createAgenda_createAgenda_itemList_agendaItem_award | null;
  checklist: createAgenda_createAgenda_itemList_agendaItem_checklist | null;
  selectionBoard: createAgenda_createAgenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: createAgenda_createAgenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: createAgenda_createAgenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface createAgenda_createAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: createAgenda_createAgenda_itemList_agendaItem;
  position: number;
}

export interface createAgenda_createAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface createAgenda_createAgenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface createAgenda_createAgenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: createAgenda_createAgenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface createAgenda_createAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: createAgenda_createAgenda_client | null;
  itemList: createAgenda_createAgenda_itemList[] | null;
  createdBy: createAgenda_createAgenda_createdBy | null;
  shareRequests: createAgenda_createAgenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface createAgenda {
  /**
   * Create agenda
   */
  createAgenda: createAgenda_createAgenda;
}

export interface createAgendaVariables {
  agendaInput: AgendaInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAgenda
// ====================================================

export interface updateAgenda_updateAgenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateAgenda_updateAgenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateAgenda_updateAgenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateAgenda_updateAgenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: updateAgenda_updateAgenda_client_devices_result[];
}

export interface updateAgenda_updateAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateAgenda_updateAgenda_client_devices;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_image | null;
  itemList: updateAgenda_updateAgenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_alert_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAgenda_updateAgenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateAgenda_updateAgenda_itemList_agendaItem_image | null;
  video: updateAgenda_updateAgenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateAgenda_updateAgenda_itemList_agendaItem_flowchart | null;
  client: updateAgenda_updateAgenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: updateAgenda_updateAgenda_itemList_agendaItem_alert | null;
  moodMeter: updateAgenda_updateAgenda_itemList_agendaItem_moodMeter | null;
  notification: updateAgenda_updateAgenda_itemList_agendaItem_notification | null;
  timer: updateAgenda_updateAgenda_itemList_agendaItem_timer | null;
  award: updateAgenda_updateAgenda_itemList_agendaItem_award | null;
  checklist: updateAgenda_updateAgenda_itemList_agendaItem_checklist | null;
  selectionBoard: updateAgenda_updateAgenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: updateAgenda_updateAgenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: updateAgenda_updateAgenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateAgenda_updateAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: updateAgenda_updateAgenda_itemList_agendaItem;
  position: number;
}

export interface updateAgenda_updateAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface updateAgenda_updateAgenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface updateAgenda_updateAgenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: updateAgenda_updateAgenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface updateAgenda_updateAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: updateAgenda_updateAgenda_client | null;
  itemList: updateAgenda_updateAgenda_itemList[] | null;
  createdBy: updateAgenda_updateAgenda_createdBy | null;
  shareRequests: updateAgenda_updateAgenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface updateAgenda {
  /**
   * Update agenda
   */
  updateAgenda: updateAgenda_updateAgenda;
}

export interface updateAgendaVariables {
  agendaId: string;
  agendaInput: AgendaInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAgenda
// ====================================================

export interface deleteAgenda {
  /**
   * Delete agenda
   */
  deleteAgenda: any | null;
}

export interface deleteAgendaVariables {
  agendaId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaToClientAgendaLibrary
// ====================================================

export interface copyAgendaToClientAgendaLibrary_copyAgendaToClientAgendaLibrary {
  __typename: "Agenda";
  id: string;
}

export interface copyAgendaToClientAgendaLibrary {
  /**
   * Copy agenda into client's agenda library
   */
  copyAgendaToClientAgendaLibrary: copyAgendaToClientAgendaLibrary_copyAgendaToClientAgendaLibrary | null;
}

export interface copyAgendaToClientAgendaLibraryVariables {
  clientId: string;
  agendaId: string;
  day: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setClientDefaultAgenda
// ====================================================

export interface setClientDefaultAgenda {
  /**
   * Set default agenda for client
   */
  setClientDefaultAgenda: any | null;
}

export interface setClientDefaultAgendaVariables {
  clientId: string;
  agendaId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unsetClientDefaultAgenda
// ====================================================

export interface unsetClientDefaultAgenda {
  /**
   * Unset default agenda for client
   */
  unsetClientDefaultAgenda: any | null;
}

export interface unsetClientDefaultAgendaVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDevice
// ====================================================

export interface deleteDevice {
  /**
   * Delete device
   */
  deleteDevice: any | null;
}

export interface deleteDeviceVariables {
  deviceId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaToPublicLibrary
// ====================================================

export interface copyAgendaToPublicLibrary_copyAgendaToPublicLibrary {
  __typename: "Agenda";
  id: string;
}

export interface copyAgendaToPublicLibrary {
  /**
   * Copy agenda into the public content library (share)
   */
  copyAgendaToPublicLibrary: copyAgendaToPublicLibrary_copyAgendaToPublicLibrary | null;
}

export interface copyAgendaToPublicLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaItemToPublicLibrary
// ====================================================

export interface copyAgendaItemToPublicLibrary_copyAgendaItemToPublicLibrary {
  __typename: "AgendaItem";
  id: string;
}

export interface copyAgendaItemToPublicLibrary {
  /**
   * Copy agenda item into the public content library (share)
   */
  copyAgendaItemToPublicLibrary: copyAgendaItemToPublicLibrary_copyAgendaItemToPublicLibrary | null;
}

export interface copyAgendaItemToPublicLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartToPublicLibrary
// ====================================================

export interface copyFlowchartToPublicLibrary_copyFlowchartToPublicLibrary {
  __typename: "Flowchart";
  id: string;
}

export interface copyFlowchartToPublicLibrary {
  /**
   * Copy flowchart into the public content library (share)
   */
  copyFlowchartToPublicLibrary: copyFlowchartToPublicLibrary_copyFlowchartToPublicLibrary | null;
}

export interface copyFlowchartToPublicLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartItemToPublicLibrary
// ====================================================

export interface copyFlowchartItemToPublicLibrary_copyFlowchartItemToPublicLibrary {
  __typename: "FlowchartItem";
  id: string;
}

export interface copyFlowchartItemToPublicLibrary {
  /**
   * Copy flowchart item into the public content library (share)
   */
  copyFlowchartItemToPublicLibrary: copyFlowchartItemToPublicLibrary_copyFlowchartItemToPublicLibrary | null;
}

export interface copyFlowchartItemToPublicLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaToClientPersonalLibrary
// ====================================================

export interface copyAgendaToClientPersonalLibrary_copyAgendaToClientPersonalLibrary {
  __typename: "Agenda";
  id: string;
}

export interface copyAgendaToClientPersonalLibrary {
  /**
   * Copy agenda into client's personal content library
   */
  copyAgendaToClientPersonalLibrary: copyAgendaToClientPersonalLibrary_copyAgendaToClientPersonalLibrary | null;
}

export interface copyAgendaToClientPersonalLibraryVariables {
  clientId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaItemToClientPersonalLibrary
// ====================================================

export interface copyAgendaItemToClientPersonalLibrary_copyAgendaItemToClientPersonalLibrary {
  __typename: "AgendaItem";
  id: string;
}

export interface copyAgendaItemToClientPersonalLibrary {
  /**
   * Copy agenda item into client's personal content library
   */
  copyAgendaItemToClientPersonalLibrary: copyAgendaItemToClientPersonalLibrary_copyAgendaItemToClientPersonalLibrary | null;
}

export interface copyAgendaItemToClientPersonalLibraryVariables {
  clientId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartToClientLibrary
// ====================================================

export interface copyFlowchartToClientLibrary_copyFlowchartToClientLibrary {
  __typename: "Flowchart";
  id: string;
}

export interface copyFlowchartToClientLibrary {
  /**
   * Copy flowchart into client's personal content library
   */
  copyFlowchartToClientLibrary: copyFlowchartToClientLibrary_copyFlowchartToClientLibrary | null;
}

export interface copyFlowchartToClientLibraryVariables {
  clientId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartItemToClientLibrary
// ====================================================

export interface copyFlowchartItemToClientLibrary_copyFlowchartItemToClientLibrary {
  __typename: "FlowchartItem";
  id: string;
}

export interface copyFlowchartItemToClientLibrary {
  /**
   * Copy flowchart item into client's personal content library
   */
  copyFlowchartItemToClientLibrary: copyFlowchartItemToClientLibrary_copyFlowchartItemToClientLibrary | null;
}

export interface copyFlowchartItemToClientLibraryVariables {
  clientId: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAgenda
// ====================================================

export interface enableAgenda {
  /**
   * Enable agenda
   */
  enableAgenda: any | null;
}

export interface enableAgendaVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAgenda
// ====================================================

export interface disableAgenda {
  /**
   * Disable agenda
   */
  disableAgenda: any | null;
}

export interface disableAgendaVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAgendaItem
// ====================================================

export interface enableAgendaItem {
  /**
   * Enable agenda item
   */
  enableAgendaItem: any | null;
}

export interface enableAgendaItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAgendaItem
// ====================================================

export interface disableAgendaItem {
  /**
   * Disable agenda item
   */
  disableAgendaItem: any | null;
}

export interface disableAgendaItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableFlowchart
// ====================================================

export interface enableFlowchart {
  /**
   * Enable flowchart
   */
  enableFlowchart: any | null;
}

export interface enableFlowchartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableFlowchart
// ====================================================

export interface disableFlowchart {
  /**
   * Disable flowchart
   */
  disableFlowchart: any | null;
}

export interface disableFlowchartVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableFlowchartItem
// ====================================================

export interface enableFlowchartItem {
  /**
   * Enable flowchart item
   */
  enableFlowchartItem: any | null;
}

export interface enableFlowchartItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableFlowchartItem
// ====================================================

export interface disableFlowchartItem {
  /**
   * Disable flowchart item
   */
  disableFlowchartItem: any | null;
}

export interface disableFlowchartItemVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAsset
// ====================================================

export interface disableAsset {
  /**
   * Disable asset
   */
  disableAsset: any | null;
}

export interface disableAssetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAsset
// ====================================================

export interface enableAsset {
  /**
   * Enable asset
   */
  enableAsset: any | null;
}

export interface enableAssetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableEverydaySituationDirectory
// ====================================================

export interface disableEverydaySituationDirectory {
  disableEverydaySituationDirectory: any | null;
}

export interface disableEverydaySituationDirectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableEverydaySituationDirectory
// ====================================================

export interface enableEverydaySituationDirectory {
  enableEverydaySituationDirectory: any | null;
}

export interface enableEverydaySituationDirectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableEverydaySituation
// ====================================================

export interface disableEverydaySituation {
  disableEverydaySituation: any | null;
}

export interface disableEverydaySituationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableEverydaySituation
// ====================================================

export interface enableEverydaySituation {
  enableEverydaySituation: any | null;
}

export interface enableEverydaySituationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableInstantAward
// ====================================================

export interface disableInstantAward {
  disableInstantAward: any | null;
}

export interface disableInstantAwardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableInstantAward
// ====================================================

export interface enableInstantAward {
  enableInstantAward: any | null;
}

export interface enableInstantAwardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableAward
// ====================================================

export interface disableAward {
  disableAward: any | null;
}

export interface disableAwardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableAward
// ====================================================

export interface enableAward {
  enableAward: any | null;
}

export interface enableAwardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disableSelectionBoard
// ====================================================

export interface disableSelectionBoard {
  disableSelectionBoard: any | null;
}

export interface disableSelectionBoardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: enableSelectionBoard
// ====================================================

export interface enableSelectionBoard {
  enableSelectionBoard: any | null;
}

export interface enableSelectionBoardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: syncClientAgendas
// ====================================================

export interface syncClientAgendas {
  /**
   * Initiate the synchronization of the client's agendas
   */
  syncClientAgendas: any | null;
}

export interface syncClientAgendasVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaToAccountPersonalLibrary
// ====================================================

export interface copyAgendaToAccountPersonalLibrary_copyAgendaToAccountPersonalLibrary {
  __typename: "Agenda";
  id: string;
}

export interface copyAgendaToAccountPersonalLibrary {
  /**
   * Copy agenda into supporter's or supervisor's personal content library
   */
  copyAgendaToAccountPersonalLibrary: copyAgendaToAccountPersonalLibrary_copyAgendaToAccountPersonalLibrary | null;
}

export interface copyAgendaToAccountPersonalLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAgendaItemToAccountPersonalLibrary
// ====================================================

export interface copyAgendaItemToAccountPersonalLibrary_copyAgendaItemToAccountPersonalLibrary {
  __typename: "AgendaItem";
  id: string;
}

export interface copyAgendaItemToAccountPersonalLibrary {
  /**
   * Copy agenda item into supporter's or supervisor's personal content library
   */
  copyAgendaItemToAccountPersonalLibrary: copyAgendaItemToAccountPersonalLibrary_copyAgendaItemToAccountPersonalLibrary | null;
}

export interface copyAgendaItemToAccountPersonalLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartToAccountPersonalLibrary
// ====================================================

export interface copyFlowchartToAccountPersonalLibrary_copyFlowchartToAccountPersonalLibrary {
  __typename: "Flowchart";
  id: string;
}

export interface copyFlowchartToAccountPersonalLibrary {
  /**
   * Copy flowchart into supporter's or supervisor's personal content library
   */
  copyFlowchartToAccountPersonalLibrary: copyFlowchartToAccountPersonalLibrary_copyFlowchartToAccountPersonalLibrary | null;
}

export interface copyFlowchartToAccountPersonalLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyFlowchartItemToAccountPersonalLibrary
// ====================================================

export interface copyFlowchartItemToAccountPersonalLibrary_copyFlowchartItemToAccountPersonalLibrary {
  __typename: "FlowchartItem";
  id: string;
}

export interface copyFlowchartItemToAccountPersonalLibrary {
  /**
   * Copy flowchart item into supporter's or supervisor's personal content library
   */
  copyFlowchartItemToAccountPersonalLibrary: copyFlowchartItemToAccountPersonalLibrary_copyFlowchartItemToAccountPersonalLibrary | null;
}

export interface copyFlowchartItemToAccountPersonalLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClientChangeManagerRequest
// ====================================================

export interface createClientChangeManagerRequest {
  /**
   * Create client change "managed by" suppporter account request
   */
  createClientChangeManagerRequest: any | null;
}

export interface createClientChangeManagerRequestVariables {
  clientId: string;
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientChangeManagerRequest
// ====================================================

export interface deleteClientChangeManagerRequest {
  /**
   * Delete client change "managed by" suppporter account request
   */
  deleteClientChangeManagerRequest: any | null;
}

export interface deleteClientChangeManagerRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptClientChangeManagerRequest
// ====================================================

export interface acceptClientChangeManagerRequest {
  /**
   * Accept client change "managed by" suppporter account request
   */
  acceptClientChangeManagerRequest: any | null;
}

export interface acceptClientChangeManagerRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rejectClientChangeManagerRequest
// ====================================================

export interface rejectClientChangeManagerRequest {
  /**
   * Reject client change "managed by" suppporter account request
   */
  rejectClientChangeManagerRequest: any | null;
}

export interface rejectClientChangeManagerRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadClientIntroductionAudio
// ====================================================

export interface uploadClientIntroductionAudio_uploadClientIntroductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface uploadClientIntroductionAudio_uploadClientIntroductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: uploadClientIntroductionAudio_uploadClientIntroductionAudio_createdBy | null;
  tags: string[];
}

export interface uploadClientIntroductionAudio {
  /**
   * Upload (create or update) client introduction audio
   */
  uploadClientIntroductionAudio: uploadClientIntroductionAudio_uploadClientIntroductionAudio | null;
}

export interface uploadClientIntroductionAudioVariables {
  clientId: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientIntroductionAudio
// ====================================================

export interface deleteClientIntroductionAudio {
  /**
   * Delete client introduction audio
   */
  deleteClientIntroductionAudio: any | null;
}

export interface deleteClientIntroductionAudioVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadClientIntroductionVideo
// ====================================================

export interface uploadClientIntroductionVideo_uploadClientIntroductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface uploadClientIntroductionVideo_uploadClientIntroductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: uploadClientIntroductionVideo_uploadClientIntroductionVideo_createdBy | null;
  tags: string[];
}

export interface uploadClientIntroductionVideo {
  /**
   * Upload (create or update) client introduction video
   */
  uploadClientIntroductionVideo: uploadClientIntroductionVideo_uploadClientIntroductionVideo | null;
}

export interface uploadClientIntroductionVideoVariables {
  clientId: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientIntroductionVideo
// ====================================================

export interface deleteClientIntroductionVideo {
  /**
   * Delete client introduction video
   */
  deleteClientIntroductionVideo: any | null;
}

export interface deleteClientIntroductionVideoVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAsset
// ====================================================

export interface updateAsset_updateAsset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateAsset_updateAsset {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateAsset_updateAsset_createdBy | null;
  tags: string[];
}

export interface updateAsset {
  /**
   * Update asset title and/or tags
   */
  updateAsset: updateAsset_updateAsset;
}

export interface updateAssetVariables {
  assetId: string;
  title?: string | null;
  tags?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setSelfSupportingStateDecision
// ====================================================

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: setSelfSupportingStateDecision_setSelfSupportingStateDecision_avatar_createdBy | null;
  tags: string[];
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest_createdBy | null;
  newManager: setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest_newManager | null;
}

export interface setSelfSupportingStateDecision_setSelfSupportingStateDecision {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: setSelfSupportingStateDecision_setSelfSupportingStateDecision_avatar | null;
  defaultAgenda: setSelfSupportingStateDecision_setSelfSupportingStateDecision_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: setSelfSupportingStateDecision_setSelfSupportingStateDecision_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface setSelfSupportingStateDecision {
  /**
   * Set self supporting state decision
   */
  setSelfSupportingStateDecision: setSelfSupportingStateDecision_setSelfSupportingStateDecision;
}

export interface setSelfSupportingStateDecisionVariables {
  clientId: string;
  selfSupportingState: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAssetToPublicLibrary
// ====================================================

export interface copyAssetToPublicLibrary_copyAssetToPublicLibrary_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface copyAssetToPublicLibrary_copyAssetToPublicLibrary {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: copyAssetToPublicLibrary_copyAssetToPublicLibrary_createdBy | null;
  tags: string[];
}

export interface copyAssetToPublicLibrary {
  /**
   * Copy asset into the public content library (share)
   */
  copyAssetToPublicLibrary: copyAssetToPublicLibrary_copyAssetToPublicLibrary | null;
}

export interface copyAssetToPublicLibraryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: copyAssetToAccountPersonalLibrary
// ====================================================

export interface copyAssetToAccountPersonalLibrary_copyAssetToAccountPersonalLibrary_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface copyAssetToAccountPersonalLibrary_copyAssetToAccountPersonalLibrary {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: copyAssetToAccountPersonalLibrary_copyAssetToAccountPersonalLibrary_createdBy | null;
  tags: string[];
}

export interface copyAssetToAccountPersonalLibrary {
  /**
   * Copy asset into the personal content library
   */
  copyAssetToAccountPersonalLibrary: copyAssetToAccountPersonalLibrary_copyAssetToAccountPersonalLibrary | null;
}

export interface copyAssetToAccountPersonalLibraryVariables {
  assetId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: evaluateClientQuestionnaire
// ====================================================

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda_image | null;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda_withoutDefaultAgenda | null;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_moodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: evaluateClientQuestionnaire_evaluateClientQuestionnaire_moodMeter_items[];
  question: string | null;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_timer {
  __typename: "TimerConfig";
  type: TimerType;
  requestConfirmation: boolean;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_award {
  __typename: "AwardConfig";
  requestConfirmation: boolean;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire_unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

export interface evaluateClientQuestionnaire_evaluateClientQuestionnaire {
  __typename: "ClientConfig";
  enabledDashboardItems: DashboardItem[] | null;
  enabledGames: evaluateClientQuestionnaire_evaluateClientQuestionnaire_enabledGames[] | null;
  mainPageType: MainPageType;
  mainPageBackgroundColor: any | null;
  mainPageDataSyncEnabled: boolean;
  agenda: evaluateClientQuestionnaire_evaluateClientQuestionnaire_agenda | null;
  flowchartListType: FlowChartListType;
  flowchartItemBackgroundColor: any | null;
  moodMeter: evaluateClientQuestionnaire_evaluateClientQuestionnaire_moodMeter | null;
  timer: evaluateClientQuestionnaire_evaluateClientQuestionnaire_timer;
  selectionBoardListType: SelectionBoardListType;
  award: evaluateClientQuestionnaire_evaluateClientQuestionnaire_award;
  unlock: evaluateClientQuestionnaire_evaluateClientQuestionnaire_unlock;
  highContrastMode: boolean;
}

export interface evaluateClientQuestionnaire {
  /**
   * Evaluate client questionnnaire
   */
  evaluateClientQuestionnaire: evaluateClientQuestionnaire_evaluateClientQuestionnaire;
}

export interface evaluateClientQuestionnaireVariables {
  clientId: string;
  clientQuestionnaireAnswers: ClientQuestionnaireAnswerInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientSpareAgendaItems
// ====================================================

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_video | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_image | null;
  itemList: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart_itemList[] | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_image | null;
  audio: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification_audio | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_flowchart | null;
  client: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_alert | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_moodMeter | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_timer | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_award | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_checklist | null;
  selectionBoard: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_agendaItems[] | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_notification | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems_agendaItem;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSpareAgendaItems_updateClientSpareAgendaItems {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: updateClientSpareAgendaItems_updateClientSpareAgendaItems_image | null;
  video: updateClientSpareAgendaItems_updateClientSpareAgendaItems_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: updateClientSpareAgendaItems_updateClientSpareAgendaItems_flowchart | null;
  client: updateClientSpareAgendaItems_updateClientSpareAgendaItems_client | null;
  time: any | null;
  alert: updateClientSpareAgendaItems_updateClientSpareAgendaItems_alert | null;
  moodMeter: updateClientSpareAgendaItems_updateClientSpareAgendaItems_moodMeter | null;
  notification: updateClientSpareAgendaItems_updateClientSpareAgendaItems_notification | null;
  timer: updateClientSpareAgendaItems_updateClientSpareAgendaItems_timer | null;
  award: updateClientSpareAgendaItems_updateClientSpareAgendaItems_award | null;
  checklist: updateClientSpareAgendaItems_updateClientSpareAgendaItems_checklist | null;
  selectionBoard: updateClientSpareAgendaItems_updateClientSpareAgendaItems_selectionBoard | null;
  agendaItems: updateClientSpareAgendaItems_updateClientSpareAgendaItems_agendaItems[] | null;
  createdBy: updateClientSpareAgendaItems_updateClientSpareAgendaItems_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface updateClientSpareAgendaItems {
  /**
   * Change client spare agenda items
   */
  updateClientSpareAgendaItems: updateClientSpareAgendaItems_updateClientSpareAgendaItems[];
}

export interface updateClientSpareAgendaItemsVariables {
  clientId: string;
  agendaItemIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientMemoryGameConfig
// ====================================================

export interface updateClientMemoryGameConfig_updateClientMemoryGameConfig_images_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientMemoryGameConfig_updateClientMemoryGameConfig_images {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientMemoryGameConfig_updateClientMemoryGameConfig_images_createdBy | null;
  tags: string[];
}

export interface updateClientMemoryGameConfig_updateClientMemoryGameConfig {
  __typename: "MemoryGameConfig";
  numberOfPairs: number;
  images: updateClientMemoryGameConfig_updateClientMemoryGameConfig_images[];
}

export interface updateClientMemoryGameConfig {
  /**
   * Change client memory game settings
   */
  updateClientMemoryGameConfig: updateClientMemoryGameConfig_updateClientMemoryGameConfig;
}

export interface updateClientMemoryGameConfigVariables {
  clientId: string;
  memoryGameConfig: MemoryGameConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientSortingGameConfig
// ====================================================

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices {
  __typename: "DeviceList";
  count: number;
  result: updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices_result[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: updateClientSortingGameConfig_updateClientSortingGameConfig_client_devices;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_image | null;
  audio: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_image | null;
  itemList: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_client | null;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_image | null;
  video: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_video | null;
  moodMeter: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_moodMeter | null;
  notification: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_notification | null;
  award: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_award | null;
  timer: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_timer | null;
  checklist: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_checklist | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_createdBy | null;
  flowchart: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchart | null;
  flowchartItems: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: updateClientSortingGameConfig_updateClientSortingGameConfig_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface updateClientSortingGameConfig_updateClientSortingGameConfig {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: updateClientSortingGameConfig_updateClientSortingGameConfig_image | null;
  client: updateClientSortingGameConfig_updateClientSortingGameConfig_client | null;
  itemList: updateClientSortingGameConfig_updateClientSortingGameConfig_itemList[] | null;
  createdBy: updateClientSortingGameConfig_updateClientSortingGameConfig_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: updateClientSortingGameConfig_updateClientSortingGameConfig_shareRequests[] | null;
}

export interface updateClientSortingGameConfig {
  /**
   * Change client sorting game settings
   */
  updateClientSortingGameConfig: updateClientSortingGameConfig_updateClientSortingGameConfig[];
}

export interface updateClientSortingGameConfigVariables {
  clientId: string;
  flowchartIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEverydaySituationClientDirectory
// ====================================================

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation_createdBy | null;
}

export type updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations = updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextEverydaySituation | updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_ImageEverydaySituation | updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_TextAndImageEverydaySituation | updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations_AudioEverydaySituation;

export interface updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory {
  __typename: "EverydaySituationClientDirectory";
  id: string;
  title: string;
  isVisible: boolean;
  isWritable: boolean;
  image: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_image;
  situations: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory_situations[];
  createdAt: any;
  updatedAt: any | null;
}

export interface updateEverydaySituationClientDirectory {
  updateEverydaySituationClientDirectory: updateEverydaySituationClientDirectory_updateEverydaySituationClientDirectory;
}

export interface updateEverydaySituationClientDirectoryVariables {
  input: UpdateEverydaySituationClientDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEverydaySituationDirectory
// ====================================================

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextEverydaySituation_createdBy | null;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation_createdBy | null;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation_createdBy | null;
}

export type createEverydaySituationDirectory_createEverydaySituationDirectory_situations = createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextEverydaySituation | createEverydaySituationDirectory_createEverydaySituationDirectory_situations_ImageEverydaySituation | createEverydaySituationDirectory_createEverydaySituationDirectory_situations_TextAndImageEverydaySituation | createEverydaySituationDirectory_createEverydaySituationDirectory_situations_AudioEverydaySituation;

export interface createEverydaySituationDirectory_createEverydaySituationDirectory_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituationDirectory_createEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: createEverydaySituationDirectory_createEverydaySituationDirectory_image;
  situations: createEverydaySituationDirectory_createEverydaySituationDirectory_situations[];
  createdBy: createEverydaySituationDirectory_createEverydaySituationDirectory_createdBy | null;
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createEverydaySituationDirectory {
  createEverydaySituationDirectory: createEverydaySituationDirectory_createEverydaySituationDirectory;
}

export interface createEverydaySituationDirectoryVariables {
  input: CreateEverydaySituationDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEverydaySituationDirectory
// ====================================================

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation_createdBy | null;
}

export type updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations = updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextEverydaySituation | updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_ImageEverydaySituation | updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_TextAndImageEverydaySituation | updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations_AudioEverydaySituation;

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituationDirectory_updateEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: updateEverydaySituationDirectory_updateEverydaySituationDirectory_image;
  situations: updateEverydaySituationDirectory_updateEverydaySituationDirectory_situations[];
  createdBy: updateEverydaySituationDirectory_updateEverydaySituationDirectory_createdBy | null;
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateEverydaySituationDirectory {
  updateEverydaySituationDirectory: updateEverydaySituationDirectory_updateEverydaySituationDirectory;
}

export interface updateEverydaySituationDirectoryVariables {
  input: UpdateEverydaySituationDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEverydaySituationDirectory
// ====================================================

export interface deleteEverydaySituationDirectory {
  deleteEverydaySituationDirectory: any | null;
}

export interface deleteEverydaySituationDirectoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderEverydaySituationDirectories
// ====================================================

export interface orderEverydaySituationDirectories {
  orderEverydaySituationDirectories: any | null;
}

export interface orderEverydaySituationDirectoriesVariables {
  clientId: string;
  orderInput: EverydaySituationDirectoryOrderInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEverydaySituation
// ====================================================

export interface createEverydaySituation_createEverydaySituation_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituation_createEverydaySituation_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituation_createEverydaySituation_TextEverydaySituation_createdBy | null;
}

export interface createEverydaySituation_createEverydaySituation_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituation_createEverydaySituation_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituation_createEverydaySituation_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: createEverydaySituation_createEverydaySituation_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituation_createEverydaySituation_ImageEverydaySituation_createdBy | null;
}

export interface createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation_createdBy | null;
}

export interface createEverydaySituation_createEverydaySituation_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface createEverydaySituation_createEverydaySituation_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createEverydaySituation_createEverydaySituation_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: createEverydaySituation_createEverydaySituation_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: createEverydaySituation_createEverydaySituation_AudioEverydaySituation_createdBy | null;
}

export type createEverydaySituation_createEverydaySituation = createEverydaySituation_createEverydaySituation_TextEverydaySituation | createEverydaySituation_createEverydaySituation_ImageEverydaySituation | createEverydaySituation_createEverydaySituation_TextAndImageEverydaySituation | createEverydaySituation_createEverydaySituation_AudioEverydaySituation;

export interface createEverydaySituation {
  createEverydaySituation: createEverydaySituation_createEverydaySituation;
}

export interface createEverydaySituationVariables {
  input: CreateEverydaySituationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEverydaySituation
// ====================================================

export interface updateEverydaySituation_updateEverydaySituation_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituation_updateEverydaySituation_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituation_updateEverydaySituation_TextEverydaySituation_createdBy | null;
}

export interface updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation_createdBy | null;
}

export interface updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation_createdBy | null;
}

export type updateEverydaySituation_updateEverydaySituation = updateEverydaySituation_updateEverydaySituation_TextEverydaySituation | updateEverydaySituation_updateEverydaySituation_ImageEverydaySituation | updateEverydaySituation_updateEverydaySituation_TextAndImageEverydaySituation | updateEverydaySituation_updateEverydaySituation_AudioEverydaySituation;

export interface updateEverydaySituation {
  updateEverydaySituation: updateEverydaySituation_updateEverydaySituation;
}

export interface updateEverydaySituationVariables {
  input: UpdateEverydaySituationInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEverydaySituation
// ====================================================

export interface deleteEverydaySituation {
  deleteEverydaySituation: any | null;
}

export interface deleteEverydaySituationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFaq
// ====================================================

export interface deleteFaq {
  /**
   * Delete a FAQ item
   */
  deleteFaqItem: any | null;
}

export interface deleteFaqVariables {
  faqItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderFaqs
// ====================================================

export interface orderFaqs {
  /**
   * Set the order of the FAQ items
   */
  orderFaqItems: any | null;
}

export interface orderFaqsVariables {
  orderInput: FaqItemOrderInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createFaq
// ====================================================

export interface createFaq_createFaqItem_createdBy {
  __typename: "Account";
  name: string;
}

export interface createFaq_createFaqItem {
  __typename: "FaqItem";
  id: string;
  question: string;
  answer: string;
  createdBy: createFaq_createFaqItem_createdBy | null;
  updatedAt: any | null;
  position: number | null;
}

export interface createFaq {
  /**
   * Create a FAQ item
   */
  createFaqItem: createFaq_createFaqItem;
}

export interface createFaqVariables {
  input: CreateFaqItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFaq
// ====================================================

export interface updateFaq_updateFaqItem_createdBy {
  __typename: "Account";
  name: string;
}

export interface updateFaq_updateFaqItem {
  __typename: "FaqItem";
  id: string;
  question: string;
  answer: string;
  createdBy: updateFaq_updateFaqItem_createdBy | null;
  updatedAt: any | null;
  position: number | null;
}

export interface updateFaq {
  /**
   * Update a FAQ item
   */
  updateFaqItem: updateFaq_updateFaqItem;
}

export interface updateFaqVariables {
  faqItemId: string;
  input: UpdateFaqItemInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPersonalAssetDirectory
// ====================================================

export interface createPersonalAssetDirectory_createPersonalAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
}

export interface createPersonalAssetDirectory {
  /**
   * Create personal asset directory
   */
  createPersonalAssetDirectory: createPersonalAssetDirectory_createPersonalAssetDirectory | null;
}

export interface createPersonalAssetDirectoryVariables {
  input: CreateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPublicAssetDirectory
// ====================================================

export interface createPublicAssetDirectory_createPublicAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
}

export interface createPublicAssetDirectory {
  /**
   * Create public asset directory
   */
  createPublicAssetDirectory: createPublicAssetDirectory_createPublicAssetDirectory | null;
}

export interface createPublicAssetDirectoryVariables {
  input: CreateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePersonalAssetDirectory
// ====================================================

export interface updatePersonalAssetDirectory_updatePersonalAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
}

export interface updatePersonalAssetDirectory {
  /**
   * Update personal asset directory
   */
  updatePersonalAssetDirectory: updatePersonalAssetDirectory_updatePersonalAssetDirectory | null;
}

export interface updatePersonalAssetDirectoryVariables {
  assetDirectoryId: string;
  input: UpdateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePersonalAssetDirectory
// ====================================================

export interface deletePersonalAssetDirectory {
  /**
   * Delete personal asset directory
   */
  deletePersonalAssetDirectory: any | null;
}

export interface deletePersonalAssetDirectoryVariables {
  assetDirectoryId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePublicAssetDirectory
// ====================================================

export interface updatePublicAssetDirectory_updatePublicAssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
}

export interface updatePublicAssetDirectory {
  /**
   * Update public asset directory
   */
  updatePublicAssetDirectory: updatePublicAssetDirectory_updatePublicAssetDirectory | null;
}

export interface updatePublicAssetDirectoryVariables {
  assetDirectoryId: string;
  input: UpdateAssetDirectoryInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePublicAssetDirectory
// ====================================================

export interface deletePublicAssetDirectory {
  /**
   * Delete public asset directory
   */
  deletePublicAssetDirectory: any | null;
}

export interface deletePublicAssetDirectoryVariables {
  assetDirectoryId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveAssetsToPersonalAssetDirectory
// ====================================================

export interface moveAssetsToPersonalAssetDirectory {
  /**
   * Move assets to personal directory
   */
  moveAssetsToPersonalAssetDirectory: any | null;
}

export interface moveAssetsToPersonalAssetDirectoryVariables {
  assetIds: string[];
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: moveAssetsToPublicAssetDirectory
// ====================================================

export interface moveAssetsToPublicAssetDirectory {
  /**
   * Move assets to public directory
   */
  moveAssetsToPublicAssetDirectory: any | null;
}

export interface moveAssetsToPublicAssetDirectoryVariables {
  assetIds: string[];
  directoryId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: replaceAsset
// ====================================================

export interface replaceAsset {
  /**
   * Replace asset
   */
  replaceAsset: number;
}

export interface replaceAssetVariables {
  assetId: string;
  replacementAssetId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClientInstantAward
// ====================================================

export interface createClientInstantAward_createInstantAward_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createClientInstantAward_createInstantAward_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createClientInstantAward_createInstantAward_image_createdBy | null;
  tags: string[];
}

export interface createClientInstantAward_createInstantAward {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: createClientInstantAward_createInstantAward_image | null;
  referenceCount: number;
}

export interface createClientInstantAward {
  createInstantAward: createClientInstantAward_createInstantAward;
}

export interface createClientInstantAwardVariables {
  clientId: string;
  input: InstantAwardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientInstantAward
// ====================================================

export interface updateClientInstantAward_updateInstantAward_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientInstantAward_updateInstantAward_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientInstantAward_updateInstantAward_image_createdBy | null;
  tags: string[];
}

export interface updateClientInstantAward_updateInstantAward {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: updateClientInstantAward_updateInstantAward_image | null;
  referenceCount: number;
}

export interface updateClientInstantAward {
  updateInstantAward: updateClientInstantAward_updateInstantAward;
}

export interface updateClientInstantAwardVariables {
  instantAwardId: string;
  input: InstantAwardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientInstantAward
// ====================================================

export interface deleteClientInstantAward {
  deleteInstantAward: any | null;
}

export interface deleteClientInstantAwardVariables {
  instantAwardId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createClientAward
// ====================================================

export interface createClientAward_createAward_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createClientAward_createAward_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createClientAward_createAward_image_createdBy | null;
  tags: string[];
}

export interface createClientAward_createAward {
  __typename: "Award";
  id: string;
  title: string;
  autoReactivation: boolean;
  isActive: boolean;
  targetScore: number;
  image: createClientAward_createAward_image | null;
  disabledAt: any | null;
}

export interface createClientAward {
  createAward: createClientAward_createAward;
}

export interface createClientAwardVariables {
  clientId: string;
  input: AwardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateClientAward
// ====================================================

export interface updateClientAward_updateAward_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface updateClientAward_updateAward_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: updateClientAward_updateAward_image_createdBy | null;
  tags: string[];
}

export interface updateClientAward_updateAward {
  __typename: "Award";
  id: string;
  title: string;
  autoReactivation: boolean;
  isActive: boolean;
  targetScore: number;
  image: updateClientAward_updateAward_image | null;
  disabledAt: any | null;
}

export interface updateClientAward {
  updateAward: updateClientAward_updateAward;
}

export interface updateClientAwardVariables {
  awardId: string;
  input: AwardInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteClientAward
// ====================================================

export interface deleteClientAward {
  deleteAward: any | null;
}

export interface deleteClientAwardVariables {
  awardId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: activateAward
// ====================================================

export interface activateAward {
  activateAward: any | null;
}

export interface activateAwardVariables {
  awardId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderSelectionBoards
// ====================================================

export interface orderSelectionBoards {
  orderSelectionBoards: any | null;
}

export interface orderSelectionBoardsVariables {
  clientId: string;
  orderInput: SelectionBoardOrderInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTag
// ====================================================

export interface createTag_createTag_createdBy_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createTag_createTag_createdBy_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createTag_createTag_createdBy_avatar_createdBy | null;
  tags: string[];
}

export interface createTag_createTag_createdBy_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createTag_createTag_createdBy_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createTag_createTag_createdBy_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface createTag_createTag_createdBy_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface createTag_createTag_createdBy_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: createTag_createTag_createdBy_myClients_result_avatar | null;
  defaultAgenda: createTag_createTag_createdBy_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: createTag_createTag_createdBy_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface createTag_createTag_createdBy_myClients {
  __typename: "ClientList";
  result: createTag_createTag_createdBy_myClients_result[];
}

export interface createTag_createTag_createdBy_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface createTag_createTag_createdBy_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: createTag_createTag_createdBy_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface createTag_createTag_createdBy_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: createTag_createTag_createdBy_pendingClientShareRequests_result_client_avatar | null;
}

export interface createTag_createTag_createdBy_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface createTag_createTag_createdBy_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: createTag_createTag_createdBy_pendingClientShareRequests_result_client;
  createdBy: createTag_createTag_createdBy_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface createTag_createTag_createdBy_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: createTag_createTag_createdBy_pendingClientShareRequests_result[];
}

export interface createTag_createTag_createdBy_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface createTag_createTag_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: createTag_createTag_createdBy_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: createTag_createTag_createdBy_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: createTag_createTag_createdBy_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: createTag_createTag_createdBy_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface createTag_createTag {
  __typename: "Tag";
  id: string;
  title: string;
  category: TagCategory;
  createdBy: createTag_createTag_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createTag {
  /**
   * Create tag
   */
  createTag: createTag_createTag;
}

export interface createTagVariables {
  input: CreateTagInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteTag
// ====================================================

export interface deleteTag {
  /**
   * Delete tag
   */
  deleteTag: any | null;
}

export interface deleteTagVariables {
  tagId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEducationContentModule
// ====================================================

export interface createEducationContentModule_createEducationContentModule_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface createEducationContentModule_createEducationContentModule_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface createEducationContentModule_createEducationContentModule_subModules_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface createEducationContentModule_createEducationContentModule_subModules_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface createEducationContentModule_createEducationContentModule_subModules_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface createEducationContentModule_createEducationContentModule_subModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: createEducationContentModule_createEducationContentModule_subModules_videoContents[];
  documentContents: createEducationContentModule_createEducationContentModule_subModules_documentContents[];
  createdBy: createEducationContentModule_createEducationContentModule_subModules_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createEducationContentModule_createEducationContentModule_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface createEducationContentModule_createEducationContentModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: createEducationContentModule_createEducationContentModule_videoContents[];
  documentContents: createEducationContentModule_createEducationContentModule_documentContents[];
  subModules: createEducationContentModule_createEducationContentModule_subModules[] | null;
  createdBy: createEducationContentModule_createEducationContentModule_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface createEducationContentModule {
  /**
   * Create education content module
   */
  createEducationContentModule: createEducationContentModule_createEducationContentModule;
}

export interface createEducationContentModuleVariables {
  input: CreateEducationContentModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEducationContentModule
// ====================================================

export interface updateEducationContentModule_updateEducationContentModule_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface updateEducationContentModule_updateEducationContentModule_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface updateEducationContentModule_updateEducationContentModule_subModules_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface updateEducationContentModule_updateEducationContentModule_subModules_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface updateEducationContentModule_updateEducationContentModule_subModules_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface updateEducationContentModule_updateEducationContentModule_subModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: updateEducationContentModule_updateEducationContentModule_subModules_videoContents[];
  documentContents: updateEducationContentModule_updateEducationContentModule_subModules_documentContents[];
  createdBy: updateEducationContentModule_updateEducationContentModule_subModules_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateEducationContentModule_updateEducationContentModule_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface updateEducationContentModule_updateEducationContentModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: updateEducationContentModule_updateEducationContentModule_videoContents[];
  documentContents: updateEducationContentModule_updateEducationContentModule_documentContents[];
  subModules: updateEducationContentModule_updateEducationContentModule_subModules[] | null;
  createdBy: updateEducationContentModule_updateEducationContentModule_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface updateEducationContentModule {
  /**
   * Update education content module
   */
  updateEducationContentModule: updateEducationContentModule_updateEducationContentModule;
}

export interface updateEducationContentModuleVariables {
  input: UpdateEducationContentModuleInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderEducationContentModules
// ====================================================

export interface orderEducationContentModules {
  /**
   * Order education content modules
   */
  orderEducationContentModules: any | null;
}

export interface orderEducationContentModulesVariables {
  parentModuleId: string;
  input: EducationContentModuleOrderInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSubModule
// ====================================================

export interface deleteSubModule {
  /**
   * Delete education content module
   */
  deleteEducationContentModule: any | null;
}

export interface deleteSubModuleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEducationContent
// ====================================================

export interface createEducationContent_createEducationContent {
  __typename: "EducationContent";
  id: string;
}

export interface createEducationContent {
  /**
   * Create education content
   */
  createEducationContent: createEducationContent_createEducationContent;
}

export interface createEducationContentVariables {
  moduleId: string;
  type: EducationContentType;
  title: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEducationContent
// ====================================================

export interface updateEducationContent_updateEducationContent {
  __typename: "EducationContent";
  id: string;
}

export interface updateEducationContent {
  /**
   * Update education content
   */
  updateEducationContent: updateEducationContent_updateEducationContent;
}

export interface updateEducationContentVariables {
  input: UpdateEducationContentInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: orderEducationContents
// ====================================================

export interface orderEducationContents {
  /**
   * Order education contents
   */
  orderEducationContents: any | null;
}

export interface orderEducationContentsVariables {
  moduleId: string;
  input: EducationContentOrderInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteEducationContent
// ====================================================

export interface deleteEducationContent {
  /**
   * Delete education content
   */
  deleteEducationContent: any | null;
}

export interface deleteEducationContentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareLibraryContentToPublic
// ====================================================

export interface shareLibraryContentToPublic {
  /**
   * Share library content to public as a sample content
   */
  shareLibraryContentToPublic: any | null;
}

export interface shareLibraryContentToPublicVariables {
  libraryContentId: string;
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareLibraryContent
// ====================================================

export interface shareLibraryContent {
  /**
   * Share library content with account
   */
  shareLibraryContent: any | null;
}

export interface shareLibraryContentVariables {
  sharedWithAccountIds: string[];
  libraryContentId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptLibraryContentShareRequest
// ====================================================

export interface acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_Agenda {
  __typename: "Agenda";
  id: string;
}

export interface acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_AgendaItem {
  __typename: "AgendaItem";
  id: string;
}

export interface acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_Flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
}

export type acceptLibraryContentShareRequest_acceptLibraryContentShareRequest = acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_Agenda | acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_AgendaItem | acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_Flowchart | acceptLibraryContentShareRequest_acceptLibraryContentShareRequest_FlowchartItem;

export interface acceptLibraryContentShareRequest {
  /**
   * Accept library content share request
   * Returns the copied library content
   */
  acceptLibraryContentShareRequest: acceptLibraryContentShareRequest_acceptLibraryContentShareRequest;
}

export interface acceptLibraryContentShareRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rejectLibraryContentShareRequest
// ====================================================

export interface rejectLibraryContentShareRequest {
  /**
   * Reject library content share request
   */
  rejectLibraryContentShareRequest: any | null;
}

export interface rejectLibraryContentShareRequestVariables {
  requestId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface me_me_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: me_me_avatar_createdBy | null;
  tags: string[];
}

export interface me_me_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface me_me_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: me_me_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface me_me_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface me_me_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface me_me_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface me_me_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: me_me_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: me_me_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface me_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: me_me_myClients_result_avatar | null;
  defaultAgenda: me_me_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: me_me_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface me_me_myClients {
  __typename: "ClientList";
  result: me_me_myClients_result[];
}

export interface me_me_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface me_me_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: me_me_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface me_me_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: me_me_pendingClientShareRequests_result_client_avatar | null;
}

export interface me_me_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface me_me_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: me_me_pendingClientShareRequests_result_client;
  createdBy: me_me_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface me_me_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: me_me_pendingClientShareRequests_result[];
}

export interface me_me_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface me_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: me_me_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: me_me_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: me_me_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: me_me_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface me {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: me_me;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appData
// ====================================================

export interface appData_me_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface appData_me_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: appData_me_avatar_createdBy | null;
  tags: string[];
}

export interface appData_me_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface appData_me_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: appData_me_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface appData_me_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface appData_me_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface appData_me_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface appData_me_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: appData_me_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: appData_me_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface appData_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: appData_me_myClients_result_avatar | null;
  defaultAgenda: appData_me_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: appData_me_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface appData_me_myClients {
  __typename: "ClientList";
  result: appData_me_myClients_result[];
}

export interface appData_me_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface appData_me_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: appData_me_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface appData_me_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: appData_me_pendingClientShareRequests_result_client_avatar | null;
}

export interface appData_me_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface appData_me_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: appData_me_pendingClientShareRequests_result_client;
  createdBy: appData_me_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface appData_me_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: appData_me_pendingClientShareRequests_result[];
}

export interface appData_me_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface appData_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: appData_me_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: appData_me_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: appData_me_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: appData_me_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface appData_getCmsSettings_unlockColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface appData_getCmsSettings_backgroundColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface appData_getCmsSettings_itemBackgroundColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface appData_getCmsSettings_allowedUploadContentTypes {
  __typename: "AllowedUploadContentTypes";
  image: string[];
  audio: string[];
  video: string[];
}

export interface appData_getCmsSettings_allowedUploadFileSizes {
  __typename: "AllowedUploadFileSizes";
  image: number;
  audio: number;
  video: number;
  educationDocument: number;
  educationVideo: number;
}

export interface appData_getCmsSettings_appStatItemTypeTitles {
  __typename: "AppStatItemTypeTitle";
  key: AppStatItemType;
  title: string;
}

export interface appData_getCmsSettings_appEventLogNameTitles {
  __typename: "AppEventLogNameTitle";
  key: AppEventLogName;
  title: string;
  accessibility: AccountType[];
  category: AppEventLogCategoryName;
}

export interface appData_getCmsSettings_appEventLogCategoryNameTitles {
  __typename: "AppEventLogCategoryNameTitle";
  key: AppEventLogCategoryName;
  title: string;
  position: number;
}

export interface appData_getCmsSettings {
  __typename: "CmsSettings";
  unlockColors: appData_getCmsSettings_unlockColors[];
  backgroundColors: appData_getCmsSettings_backgroundColors[];
  itemBackgroundColors: appData_getCmsSettings_itemBackgroundColors[];
  allowedUploadContentTypes: appData_getCmsSettings_allowedUploadContentTypes;
  allowedUploadFileSizes: appData_getCmsSettings_allowedUploadFileSizes;
  appStatItemTypeTitles: appData_getCmsSettings_appStatItemTypeTitles[];
  appEventLogNameTitles: appData_getCmsSettings_appEventLogNameTitles[];
  appEventLogCategoryNameTitles: appData_getCmsSettings_appEventLogCategoryNameTitles[];
  clientEverydaySituationDirectoryDefaultImageId: string;
}

export interface appData {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: appData_me;
  /**
   * Get CMS settings
   */
  getCmsSettings: appData_getCmsSettings;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: storageSizeUsed
// ====================================================

export interface storageSizeUsed_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  storageSizeUsed: any;
}

export interface storageSizeUsed {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: storageSizeUsed_me;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientQuestionnaire
// ====================================================

export interface getClientQuestionnaire_getClientQuestionnaire_answerOptions {
  __typename: "ClientQuestionnaireAnswerOption";
  id: string;
  title: string;
}

export interface getClientQuestionnaire_getClientQuestionnaire {
  __typename: "ClientQuestionnaireQuestion";
  id: string;
  title: string;
  description: string;
  answerOptions: getClientQuestionnaire_getClientQuestionnaire_answerOptions[];
}

export interface getClientQuestionnaire {
  /**
   * getClientQuestionnaire
   * - @requires auth
   */
  getClientQuestionnaire: getClientQuestionnaire_getClientQuestionnaire[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClients
// ====================================================

export interface getMyClients_me_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_introductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_introductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_introductionAudio_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_introductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_introductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_introductionVideo_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getMyClients_me_myClients_result_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getMyClients_me_myClients_result_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getMyClients_me_myClients_result_devices {
  __typename: "DeviceList";
  count: number;
  result: getMyClients_me_myClients_result_devices_result[];
}

export interface getMyClients_me_myClients_result_supporters_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getMyClients_me_myClients_result_supporters_result_clientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
}

export interface getMyClients_me_myClients_result_supporters_result {
  __typename: "ClientSupporter";
  account: getMyClients_me_myClients_result_supporters_result_account | null;
  clientShareRequest: getMyClients_me_myClients_result_supporters_result_clientShareRequest | null;
  name: string | null;
  email: string;
  status: string;
  createdAt: any;
}

export interface getMyClients_me_myClients_result_supporters {
  __typename: "ClientSupporterList";
  count: number;
  result: getMyClients_me_myClients_result_supporters_result[];
}

export interface getMyClients_me_myClients_result_managedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_defaultAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyClients_me_myClients_result_defaultAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getMyClients_me_myClients_result_defaultAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getMyClients_me_myClients_result_defaultAgenda_client | null;
  updatedAt: any | null;
  itemList: getMyClients_me_myClients_result_defaultAgenda_itemList[] | null;
  createdBy: getMyClients_me_myClients_result_defaultAgenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getMyClients_me_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getMyClients_me_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: getMyClients_me_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: getMyClients_me_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface getMyClients_me_myClients_result_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getMyClients_me_myClients_result_instantAwards_result_image | null;
  referenceCount: number;
}

export interface getMyClients_me_myClients_result_instantAwards {
  __typename: "InstantAwardList";
  result: getMyClients_me_myClients_result_instantAwards_result[];
}

export interface getMyClients_me_myClients_result_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getMyClients_me_myClients_result_selectionBoards_result_items_image | null;
}

export interface getMyClients_me_myClients_result_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClients_me_myClients_result_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface getMyClients_me_myClients_result_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClients_me_myClients_result_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getMyClients_me_myClients_result_selectionBoards_result_items[];
  image: getMyClients_me_myClients_result_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: getMyClients_me_myClients_result_selectionBoards_result_createdBy | null;
}

export interface getMyClients_me_myClients_result_selectionBoards {
  __typename: "SelectionBoardList";
  result: getMyClients_me_myClients_result_selectionBoards_result[];
}

export interface getMyClients_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: getMyClients_me_myClients_result_avatar | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  introduction: string | null;
  introductionAudio: getMyClients_me_myClients_result_introductionAudio | null;
  introductionVideo: getMyClients_me_myClients_result_introductionVideo | null;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getMyClients_me_myClients_result_devices;
  /**
   * Client supporters
   */
  supporters: getMyClients_me_myClients_result_supporters;
  /**
   * This account can modify main client datas (default value: created_by)
   */
  managedBy: getMyClients_me_myClients_result_managedBy;
  defaultAgenda: getMyClients_me_myClients_result_defaultAgenda | null;
  /**
   *  Client is supported by him/herself 
   */
  isSelfSupporting: boolean;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: getMyClients_me_myClients_result_pendingClientManagerChangeRequest | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
  awardScoreBalance: number;
  instantAwards: getMyClients_me_myClients_result_instantAwards | null;
  selectionBoards: getMyClients_me_myClients_result_selectionBoards | null;
  notificationAfterCreate: string;
  notificationOnProfilePage: string;
}

export interface getMyClients_me_myClients {
  __typename: "ClientList";
  result: getMyClients_me_myClients_result[];
}

export interface getMyClients_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClients_me_myClients | null;
}

export interface getMyClients {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClients_me;
}

export interface getMyClientsVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientEventLog
// ====================================================

export interface getClientEventLog_getAppStatItemList_aggregations_timeAggregations_dateAggregation {
  __typename: "DateAggregation";
  date: any;
  count: number;
}

export interface getClientEventLog_getAppStatItemList_aggregations_timeAggregations_hourAggregation {
  __typename: "HourAggregation";
  hour: number;
  count: number;
}

export interface getClientEventLog_getAppStatItemList_aggregations_timeAggregations_weekAggregation {
  __typename: "WeekAggregation";
  week: number;
  count: number;
}

export interface getClientEventLog_getAppStatItemList_aggregations_timeAggregations {
  __typename: "TimeAggregations";
  dateAggregation: getClientEventLog_getAppStatItemList_aggregations_timeAggregations_dateAggregation[] | null;
  hourAggregation: getClientEventLog_getAppStatItemList_aggregations_timeAggregations_hourAggregation[] | null;
  weekAggregation: getClientEventLog_getAppStatItemList_aggregations_timeAggregations_weekAggregation[] | null;
}

export interface getClientEventLog_getAppStatItemList_aggregations {
  __typename: "Aggregations";
  timeAggregations: getClientEventLog_getAppStatItemList_aggregations_timeAggregations;
}

export interface getClientEventLog_getAppStatItemList_result_device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getClientEventLog_getAppStatItemList_result_device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getClientEventLog_getAppStatItemList_result_device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getClientEventLog_getAppStatItemList_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getClientEventLog_getAppStatItemList_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientEventLog_getAppStatItemList_result {
  __typename: "AppStatItem";
  type: AppStatItemType;
  typeTitle: string;
  data: any | null;
  device: getClientEventLog_getAppStatItemList_result_device | null;
  account: getClientEventLog_getAppStatItemList_result_account | null;
  client: getClientEventLog_getAppStatItemList_result_client | null;
  createdAt: any;
}

export interface getClientEventLog_getAppStatItemList {
  __typename: "AppStatItemList";
  count: number;
  aggregations: getClientEventLog_getAppStatItemList_aggregations | null;
  result: getClientEventLog_getAppStatItemList_result[];
}

export interface getClientEventLog {
  getAppStatItemList: getClientEventLog_getAppStatItemList;
}

export interface getClientEventLogVariables {
  clientId: string;
  date: DateFilter;
  options?: AppStatItemListOptionsInput | null;
  type?: AppStatItemType | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientsAppConfig
// ====================================================

export interface getMyClientsAppConfig_me_myClients_result_config_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image | null;
}

export interface getMyClientsAppConfig_me_myClients_result_config_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: getMyClientsAppConfig_me_myClients_result_config_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: getMyClientsAppConfig_me_myClients_result_config_agenda_withoutDefaultAgenda | null;
}

export interface getMyClientsAppConfig_me_myClients_result_config_unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

export interface getMyClientsAppConfig_me_myClients_result_config {
  __typename: "ClientConfig";
  agenda: getMyClientsAppConfig_me_myClients_result_config_agenda | null;
  flowchartItemBackgroundColor: any | null;
  unlock: getMyClientsAppConfig_me_myClients_result_config_unlock;
  highContrastMode: boolean;
}

export interface getMyClientsAppConfig_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getMyClientsAppConfig_me_myClients_result_config;
  notificationOnProfilePage: string;
}

export interface getMyClientsAppConfig_me_myClients {
  __typename: "ClientList";
  result: getMyClientsAppConfig_me_myClients_result[];
}

export interface getMyClientsAppConfig_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientsAppConfig_me_myClients | null;
}

export interface getMyClientsAppConfig {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientsAppConfig_me;
}

export interface getMyClientsAppConfigVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientsMainPageConfig
// ====================================================

export interface getMyClientsMainPageConfig_me_myClients_result_config_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface getMyClientsMainPageConfig_me_myClients_result_config_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
}

export interface getMyClientsMainPageConfig_me_myClients_result_config {
  __typename: "ClientConfig";
  mainPageType: MainPageType;
  mainPageBackgroundColor: any | null;
  mainPageDataSyncEnabled: boolean;
  enabledDashboardItems: DashboardItem[] | null;
  enabledGames: getMyClientsMainPageConfig_me_myClients_result_config_enabledGames[] | null;
  moodMeter: getMyClientsMainPageConfig_me_myClients_result_config_moodMeter | null;
}

export interface getMyClientsMainPageConfig_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getMyClientsMainPageConfig_me_myClients_result_config;
  notificationOnProfilePage: string;
}

export interface getMyClientsMainPageConfig_me_myClients {
  __typename: "ClientList";
  result: getMyClientsMainPageConfig_me_myClients_result[];
}

export interface getMyClientsMainPageConfig_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientsMainPageConfig_me_myClients | null;
}

export interface getMyClientsMainPageConfig {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientsMainPageConfig_me;
}

export interface getMyClientsMainPageConfigVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientsContentsConfig
// ====================================================

export interface getMyClientsContentsConfig_me_myClients_result_config_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda_image | null;
}

export interface getMyClientsContentsConfig_me_myClients_result_config_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: getMyClientsContentsConfig_me_myClients_result_config_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: getMyClientsContentsConfig_me_myClients_result_config_agenda_withoutDefaultAgenda | null;
}

export interface getMyClientsContentsConfig_me_myClients_result_config {
  __typename: "ClientConfig";
  agenda: getMyClientsContentsConfig_me_myClients_result_config_agenda | null;
  flowchartListType: FlowChartListType;
}

export interface getMyClientsContentsConfig_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getMyClientsContentsConfig_me_myClients_result_config;
  notificationOnProfilePage: string;
}

export interface getMyClientsContentsConfig_me_myClients {
  __typename: "ClientList";
  result: getMyClientsContentsConfig_me_myClients_result[];
}

export interface getMyClientsContentsConfig_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientsContentsConfig_me_myClients | null;
}

export interface getMyClientsContentsConfig {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientsContentsConfig_me;
}

export interface getMyClientsContentsConfigVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientsFunctionsConfig
// ====================================================

export interface getMyClientsFunctionsConfig_me_myClients_result_config_moodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface getMyClientsFunctionsConfig_me_myClients_result_config_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: getMyClientsFunctionsConfig_me_myClients_result_config_moodMeter_items[];
  question: string | null;
}

export interface getMyClientsFunctionsConfig_me_myClients_result_config_timer {
  __typename: "TimerConfig";
  type: TimerType;
  requestConfirmation: boolean;
}

export interface getMyClientsFunctionsConfig_me_myClients_result_config_award {
  __typename: "AwardConfig";
  requestConfirmation: boolean;
}

export interface getMyClientsFunctionsConfig_me_myClients_result_config {
  __typename: "ClientConfig";
  moodMeter: getMyClientsFunctionsConfig_me_myClients_result_config_moodMeter | null;
  timer: getMyClientsFunctionsConfig_me_myClients_result_config_timer;
  selectionBoardListType: SelectionBoardListType;
  award: getMyClientsFunctionsConfig_me_myClients_result_config_award;
  enabledDashboardItems: DashboardItem[] | null;
}

export interface getMyClientsFunctionsConfig_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getMyClientsFunctionsConfig_me_myClients_result_config;
  notificationOnProfilePage: string;
}

export interface getMyClientsFunctionsConfig_me_myClients {
  __typename: "ClientList";
  result: getMyClientsFunctionsConfig_me_myClients_result[];
}

export interface getMyClientsFunctionsConfig_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientsFunctionsConfig_me_myClients | null;
}

export interface getMyClientsFunctionsConfig {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientsFunctionsConfig_me;
}

export interface getMyClientsFunctionsConfigVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminList
// ====================================================

export interface getAdminList_getAccountList_result {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
}

export interface getAdminList_getAccountList {
  __typename: "AccountList";
  count: number;
  result: getAdminList_getAccountList_result[];
}

export interface getAdminList {
  /**
   * Get list of accounts
   * - @requires auth: accountType: [admin, superadmin]
   */
  getAccountList: getAdminList_getAccountList;
}

export interface getAdminListVariables {
  options?: AccountListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSupervisorList
// ====================================================

export interface getSupervisorList_getAccountList_result {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
}

export interface getSupervisorList_getAccountList {
  __typename: "AccountList";
  count: number;
  result: getSupervisorList_getAccountList_result[];
}

export interface getSupervisorList {
  /**
   * Get list of accounts
   * - @requires auth: accountType: [admin, superadmin]
   */
  getAccountList: getSupervisorList_getAccountList;
}

export interface getSupervisorListVariables {
  options?: AccountListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSupporterList
// ====================================================

export interface getSupporterList_getSupporterList_result {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
  createdAt: any;
}

export interface getSupporterList_getSupporterList {
  __typename: "AccountList";
  count: number;
  result: getSupporterList_getSupporterList_result[];
}

export interface getSupporterList {
  /**
   * Get list of supporter accounts
   * - @requires auth: accountType: [supervisor, admin, superadmin]
   */
  getSupporterList: getSupporterList_getSupporterList;
}

export interface getSupporterListVariables {
  options?: AccountListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientList
// ====================================================

export interface getClientList_getClientList_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  createdAt: any;
}

export interface getClientList_getClientList {
  __typename: "ClientList";
  count: number;
  result: getClientList_getClientList_result[];
}

export interface getClientList {
  /**
   * Get list of clients
   * - @requires auth: accountType: [supervisor, admin, superadmin]
   */
  getClientList: getClientList_getClientList;
}

export interface getClientListVariables {
  options?: ClientListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminById
// ====================================================

export interface getAdminById_getAccountById_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminById_getAccountById_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminById_getAccountById_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminById_getAccountById_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminById_getAccountById_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminById_getAccountById_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminById_getAccountById_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface getAdminById_getAccountById_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: getAdminById_getAccountById_myClients_result_avatar | null;
  defaultAgenda: getAdminById_getAccountById_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: getAdminById_getAccountById_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface getAdminById_getAccountById_myClients {
  __typename: "ClientList";
  result: getAdminById_getAccountById_myClients_result[];
}

export interface getAdminById_getAccountById_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminById_getAccountById_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminById_getAccountById_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminById_getAccountById_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: getAdminById_getAccountById_pendingClientShareRequests_result_client_avatar | null;
}

export interface getAdminById_getAccountById_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getAdminById_getAccountById_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: getAdminById_getAccountById_pendingClientShareRequests_result_client;
  createdBy: getAdminById_getAccountById_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface getAdminById_getAccountById_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: getAdminById_getAccountById_pendingClientShareRequests_result[];
}

export interface getAdminById_getAccountById_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface getAdminById_getAccountById {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: getAdminById_getAccountById_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: getAdminById_getAccountById_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: getAdminById_getAccountById_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: getAdminById_getAccountById_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface getAdminById {
  /**
   * Get account by id
   * - @requires auth: accountType: [admin, superadmin]
   */
  getAccountById: getAdminById_getAccountById;
}

export interface getAdminByIdVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSupporterByExtId
// ====================================================

export interface getSupporterByExtId_getSupporterByExtId_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getSupporterByExtId_getSupporterByExtId_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getSupporterByExtId_getSupporterByExtId_avatar_createdBy | null;
  tags: string[];
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client_avatar | null;
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_client;
  createdBy: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests_result[];
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getSupporterByExtId_getSupporterByExtId_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: getSupporterByExtId_getSupporterByExtId_myClients_result_avatar | null;
  defaultAgenda: getSupporterByExtId_getSupporterByExtId_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: getSupporterByExtId_getSupporterByExtId_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface getSupporterByExtId_getSupporterByExtId_myClients {
  __typename: "ClientList";
  result: getSupporterByExtId_getSupporterByExtId_myClients_result[];
}

export interface getSupporterByExtId_getSupporterByExtId_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface getSupporterByExtId_getSupporterByExtId {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  pin: string | null;
  avatar: getSupporterByExtId_getSupporterByExtId_avatar | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: getSupporterByExtId_getSupporterByExtId_pendingClientShareRequests | null;
  /**
   * Clients supported by me
   */
  myClients: getSupporterByExtId_getSupporterByExtId_myClients | null;
  storageSizeUsed: any;
  config: getSupporterByExtId_getSupporterByExtId_config;
  disabledReason: string | null;
  activatedAt: any | null;
  disabledAt: any | null;
}

export interface getSupporterByExtId {
  /**
   * Get supporter by ext id
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getSupporterByExtId: getSupporterByExtId_getSupporterByExtId;
}

export interface getSupporterByExtIdVariables {
  extId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientByExtId
// ====================================================

export interface getClientByExtId_getClientByExtId_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_avatar_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_introductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_introductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_introductionAudio_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_introductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_introductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_introductionVideo_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getClientByExtId_getClientByExtId_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getClientByExtId_getClientByExtId_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getClientByExtId_getClientByExtId_devices {
  __typename: "DeviceList";
  count: number;
  result: getClientByExtId_getClientByExtId_devices_result[];
}

export interface getClientByExtId_getClientByExtId_supporters_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getClientByExtId_getClientByExtId_supporters_result_clientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
}

export interface getClientByExtId_getClientByExtId_supporters_result {
  __typename: "ClientSupporter";
  account: getClientByExtId_getClientByExtId_supporters_result_account | null;
  clientShareRequest: getClientByExtId_getClientByExtId_supporters_result_clientShareRequest | null;
  name: string | null;
  email: string;
  status: string;
  createdAt: any;
}

export interface getClientByExtId_getClientByExtId_supporters {
  __typename: "ClientSupporterList";
  count: number;
  result: getClientByExtId_getClientByExtId_supporters_result[];
}

export interface getClientByExtId_getClientByExtId_managedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_defaultAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientByExtId_getClientByExtId_defaultAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getClientByExtId_getClientByExtId_defaultAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_defaultAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getClientByExtId_getClientByExtId_defaultAgenda_client | null;
  updatedAt: any | null;
  itemList: getClientByExtId_getClientByExtId_defaultAgenda_itemList[] | null;
  createdBy: getClientByExtId_getClientByExtId_defaultAgenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest_createdBy | null;
  newManager: getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest_newManager | null;
}

export interface getClientByExtId_getClientByExtId_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getClientByExtId_getClientByExtId_instantAwards_result_image | null;
  referenceCount: number;
}

export interface getClientByExtId_getClientByExtId_instantAwards {
  __typename: "InstantAwardList";
  result: getClientByExtId_getClientByExtId_instantAwards_result[];
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getClientByExtId_getClientByExtId_selectionBoards_result_items_image | null;
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientByExtId_getClientByExtId_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getClientByExtId_getClientByExtId_selectionBoards_result_items[];
  image: getClientByExtId_getClientByExtId_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: getClientByExtId_getClientByExtId_selectionBoards_result_createdBy | null;
}

export interface getClientByExtId_getClientByExtId_selectionBoards {
  __typename: "SelectionBoardList";
  result: getClientByExtId_getClientByExtId_selectionBoards_result[];
}

export interface getClientByExtId_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: getClientByExtId_getClientByExtId_avatar | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  introduction: string | null;
  introductionAudio: getClientByExtId_getClientByExtId_introductionAudio | null;
  introductionVideo: getClientByExtId_getClientByExtId_introductionVideo | null;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getClientByExtId_getClientByExtId_devices;
  /**
   * Client supporters
   */
  supporters: getClientByExtId_getClientByExtId_supporters;
  /**
   * This account can modify main client datas (default value: created_by)
   */
  managedBy: getClientByExtId_getClientByExtId_managedBy;
  defaultAgenda: getClientByExtId_getClientByExtId_defaultAgenda | null;
  /**
   *  Client is supported by him/herself 
   */
  isSelfSupporting: boolean;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: getClientByExtId_getClientByExtId_pendingClientManagerChangeRequest | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
  awardScoreBalance: number;
  instantAwards: getClientByExtId_getClientByExtId_instantAwards | null;
  selectionBoards: getClientByExtId_getClientByExtId_selectionBoards | null;
  notificationAfterCreate: string;
  notificationOnProfilePage: string;
}

export interface getClientByExtId {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientByExtId_getClientByExtId;
}

export interface getClientByExtIdVariables {
  extId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientDetailEventLog
// ====================================================

export interface getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_dateAggregation {
  __typename: "DateAggregation";
  date: any;
  count: number;
}

export interface getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_hourAggregation {
  __typename: "HourAggregation";
  hour: number;
  count: number;
}

export interface getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_weekAggregation {
  __typename: "WeekAggregation";
  week: number;
  count: number;
}

export interface getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations {
  __typename: "TimeAggregations";
  dateAggregation: getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_dateAggregation[] | null;
  hourAggregation: getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_hourAggregation[] | null;
  weekAggregation: getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations_weekAggregation[] | null;
}

export interface getClientDetailEventLog_getAppStatItemList_aggregations {
  __typename: "Aggregations";
  timeAggregations: getClientDetailEventLog_getAppStatItemList_aggregations_timeAggregations;
}

export interface getClientDetailEventLog_getAppStatItemList_result_device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getClientDetailEventLog_getAppStatItemList_result_device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getClientDetailEventLog_getAppStatItemList_result_device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getClientDetailEventLog_getAppStatItemList_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getClientDetailEventLog_getAppStatItemList_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientDetailEventLog_getAppStatItemList_result {
  __typename: "AppStatItem";
  type: AppStatItemType;
  typeTitle: string;
  data: any | null;
  device: getClientDetailEventLog_getAppStatItemList_result_device | null;
  account: getClientDetailEventLog_getAppStatItemList_result_account | null;
  client: getClientDetailEventLog_getAppStatItemList_result_client | null;
  createdAt: any;
}

export interface getClientDetailEventLog_getAppStatItemList {
  __typename: "AppStatItemList";
  count: number;
  aggregations: getClientDetailEventLog_getAppStatItemList_aggregations | null;
  result: getClientDetailEventLog_getAppStatItemList_result[];
}

export interface getClientDetailEventLog {
  getAppStatItemList: getClientDetailEventLog_getAppStatItemList;
}

export interface getClientDetailEventLogVariables {
  extId: string;
  date: DateFilter;
  options?: AppStatItemListOptionsInput | null;
  type?: AppStatItemType | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContentLibrary
// ====================================================

export interface getContentLibrary_search_result_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getContentLibrary_search_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getContentLibrary_search_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  itemList: getContentLibrary_search_result_Agenda_itemList[] | null;
  createdBy: getContentLibrary_search_result_Agenda_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  day: any | null;
  disabledAt: any | null;
}

export interface getContentLibrary_search_result_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getContentLibrary_search_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  image: getContentLibrary_search_result_AgendaItem_image | null;
  audio: getContentLibrary_search_result_AgendaItem_audio | null;
  video: getContentLibrary_search_result_AgendaItem_video | null;
  createdBy: getContentLibrary_search_result_AgendaItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getContentLibrary_search_result_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  position: number;
}

export interface getContentLibrary_search_result_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getContentLibrary_search_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getContentLibrary_search_result_Flowchart_image | null;
  itemList: getContentLibrary_search_result_Flowchart_itemList[] | null;
  createdBy: getContentLibrary_search_result_Flowchart_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getContentLibrary_search_result_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getContentLibrary_search_result_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getContentLibrary_search_result_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getContentLibrary_search_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: getContentLibrary_search_result_FlowchartItem_image | null;
  audio: getContentLibrary_search_result_FlowchartItem_audio | null;
  video: getContentLibrary_search_result_FlowchartItem_video | null;
  createdBy: getContentLibrary_search_result_FlowchartItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getContentLibrary_search_result_Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getContentLibrary_search_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  fileSize: number;
  assetType: AssetType;
  deletable: boolean;
  url: any;
  thumbnailUrl: any | null;
  createdAt: any;
  disabledAt: any | null;
  publishedAt: any | null;
  createdBy: getContentLibrary_search_result_Asset_createdBy | null;
  tags: string[];
}

export type getContentLibrary_search_result = getContentLibrary_search_result_Agenda | getContentLibrary_search_result_AgendaItem | getContentLibrary_search_result_Flowchart | getContentLibrary_search_result_FlowchartItem | getContentLibrary_search_result_Asset;

export interface getContentLibrary_search {
  __typename: "SearchList";
  count: number;
  result: getContentLibrary_search_result[];
}

export interface getContentLibrary {
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  search: getContentLibrary_search;
}

export interface getContentLibraryVariables {
  options?: SearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyContentLibrarySidebarData
// ====================================================

export interface getMyContentLibrarySidebarData_myLibrary_result_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_Agenda_client | null;
  updatedAt: any | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_Agenda_itemList[] | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Agenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_client | null;
  time: any | null;
  alert: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_alert | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_notification | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_timer | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_award | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist | null;
  selectionBoard: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_selectionBoard | null;
  agendaItems: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems[] | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_AgendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_client | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_award | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_image | null;
  client: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_client | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_Flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image | null;
  itemList: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList[] | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_client | null;
  image: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_image | null;
  video: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_video | null;
  moodMeter: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_moodMeter | null;
  notification: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification | null;
  award: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_award | null;
  timer: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_timer | null;
  checklist: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_createdBy | null;
  flowchart: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart | null;
  flowchartItems: getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyContentLibrarySidebarData_myLibrary_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyContentLibrarySidebarData_myLibrary_result_Asset_createdBy | null;
  tags: string[];
}

export type getMyContentLibrarySidebarData_myLibrary_result = getMyContentLibrarySidebarData_myLibrary_result_Agenda | getMyContentLibrarySidebarData_myLibrary_result_AgendaItem | getMyContentLibrarySidebarData_myLibrary_result_Flowchart | getMyContentLibrarySidebarData_myLibrary_result_FlowchartItem | getMyContentLibrarySidebarData_myLibrary_result_Asset;

export interface getMyContentLibrarySidebarData_myLibrary {
  __typename: "SearchList";
  count: number;
  result: getMyContentLibrarySidebarData_myLibrary_result[];
}

export interface getMyContentLibrarySidebarData {
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  myLibrary: getMyContentLibrarySidebarData_myLibrary;
}

export interface getMyContentLibrarySidebarDataVariables {
  createdById: string;
  searchListTypes?: SearchListType[] | null;
  assetTypes?: AssetType[] | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
  tags?: string[] | null;
  assetDirectory?: string | null;
  usableInSortingGame?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientContentLibrarySidebarData
// ====================================================

export interface getClientContentLibrarySidebarData_clientLibrary_result_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_Agenda_client | null;
  updatedAt: any | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_Agenda_itemList[] | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Agenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_client | null;
  time: any | null;
  alert: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_timer | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_award | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist | null;
  selectionBoard: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_selectionBoard | null;
  agendaItems: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems[] | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_client | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_award | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_image | null;
  client: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_client | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image | null;
  itemList: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList[] | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_client | null;
  image: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image | null;
  video: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video | null;
  moodMeter: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_moodMeter | null;
  notification: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification | null;
  award: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_award | null;
  timer: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_timer | null;
  checklist: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_createdBy | null;
  flowchart: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart | null;
  flowchartItems: getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientContentLibrarySidebarData_clientLibrary_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientContentLibrarySidebarData_clientLibrary_result_Asset_createdBy | null;
  tags: string[];
}

export type getClientContentLibrarySidebarData_clientLibrary_result = getClientContentLibrarySidebarData_clientLibrary_result_Agenda | getClientContentLibrarySidebarData_clientLibrary_result_AgendaItem | getClientContentLibrarySidebarData_clientLibrary_result_Flowchart | getClientContentLibrarySidebarData_clientLibrary_result_FlowchartItem | getClientContentLibrarySidebarData_clientLibrary_result_Asset;

export interface getClientContentLibrarySidebarData_clientLibrary {
  __typename: "SearchList";
  count: number;
  result: getClientContentLibrarySidebarData_clientLibrary_result[];
}

export interface getClientContentLibrarySidebarData {
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  clientLibrary: getClientContentLibrarySidebarData_clientLibrary;
}

export interface getClientContentLibrarySidebarDataVariables {
  clientId: string;
  searchListTypes?: SearchListType[] | null;
  assetTypes?: AssetType[] | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
  tags?: string[] | null;
  assetDirectory?: string | null;
  usableInSortingGame?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllContentLibrarySidebarData
// ====================================================

export interface getAllContentLibrarySidebarData_clientLibrary_result_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_Agenda_client | null;
  updatedAt: any | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_Agenda_itemList[] | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Agenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_notification | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_timer | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_agendaItems[] | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_client | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_image | null;
  client: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_client | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_Flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems_flowchartItem;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_client | null;
  image: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_image | null;
  video: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_createdBy | null;
  flowchart: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchart | null;
  flowchartItems: getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_clientLibrary_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_clientLibrary_result_Asset_createdBy | null;
  tags: string[];
}

export type getAllContentLibrarySidebarData_clientLibrary_result = getAllContentLibrarySidebarData_clientLibrary_result_Agenda | getAllContentLibrarySidebarData_clientLibrary_result_AgendaItem | getAllContentLibrarySidebarData_clientLibrary_result_Flowchart | getAllContentLibrarySidebarData_clientLibrary_result_FlowchartItem | getAllContentLibrarySidebarData_clientLibrary_result_Asset;

export interface getAllContentLibrarySidebarData_clientLibrary {
  __typename: "SearchList";
  count: number;
  result: getAllContentLibrarySidebarData_clientLibrary_result[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_Agenda_client | null;
  updatedAt: any | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_Agenda_itemList[] | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Agenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems_agendaItem;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_flowchart | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_client | null;
  time: any | null;
  alert: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_alert | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_notification | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_timer | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_award | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_checklist | null;
  selectionBoard: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_selectionBoard | null;
  agendaItems: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_agendaItems[] | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_AgendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_client | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_image | null;
  client: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_client | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_Flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_image | null;
  itemList: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart_itemList[] | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems_flowchartItem;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_client | null;
  image: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_image | null;
  video: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_video | null;
  moodMeter: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_moodMeter | null;
  notification: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_notification | null;
  award: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_award | null;
  timer: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_timer | null;
  checklist: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_checklist | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_createdBy | null;
  flowchart: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchart | null;
  flowchartItems: getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAllContentLibrarySidebarData_myLibrary_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAllContentLibrarySidebarData_myLibrary_result_Asset_createdBy | null;
  tags: string[];
}

export type getAllContentLibrarySidebarData_myLibrary_result = getAllContentLibrarySidebarData_myLibrary_result_Agenda | getAllContentLibrarySidebarData_myLibrary_result_AgendaItem | getAllContentLibrarySidebarData_myLibrary_result_Flowchart | getAllContentLibrarySidebarData_myLibrary_result_FlowchartItem | getAllContentLibrarySidebarData_myLibrary_result_Asset;

export interface getAllContentLibrarySidebarData_myLibrary {
  __typename: "SearchList";
  count: number;
  result: getAllContentLibrarySidebarData_myLibrary_result[];
}

export interface getAllContentLibrarySidebarData {
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  clientLibrary: getAllContentLibrarySidebarData_clientLibrary;
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  myLibrary: getAllContentLibrarySidebarData_myLibrary;
}

export interface getAllContentLibrarySidebarDataVariables {
  createdById?: string | null;
  clientId?: string | null;
  searchListTypes?: SearchListType[] | null;
  assetTypes?: AssetType[] | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
  tags?: string[] | null;
  assetDirectory?: string | null;
  usableInSortingGame?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientSelectionBoards
// ====================================================

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items_image | null;
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_items[];
  image: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result_createdBy | null;
}

export interface getMyClientSelectionBoards_me_myClients_result_selectionBoards {
  __typename: "SelectionBoardList";
  count: number;
  result: getMyClientSelectionBoards_me_myClients_result_selectionBoards_result[];
}

export interface getMyClientSelectionBoards_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  selectionBoards: getMyClientSelectionBoards_me_myClients_result_selectionBoards | null;
}

export interface getMyClientSelectionBoards_me_myClients {
  __typename: "ClientList";
  result: getMyClientSelectionBoards_me_myClients_result[];
}

export interface getMyClientSelectionBoards_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientSelectionBoards_me_myClients | null;
}

export interface getMyClientSelectionBoards {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientSelectionBoards_me;
}

export interface getMyClientSelectionBoardsVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAgendaById
// ====================================================

export interface getAgendaById_getAgendaById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getAgendaById_getAgendaById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getAgendaById_getAgendaById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAgendaById_getAgendaById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getAgendaById_getAgendaById_client_devices_result[];
}

export interface getAgendaById_getAgendaById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getAgendaById_getAgendaById_client_devices;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_image | null;
  itemList: getAgendaById_getAgendaById_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_alert_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaById_getAgendaById_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaById_getAgendaById_itemList_agendaItem_image | null;
  video: getAgendaById_getAgendaById_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaById_getAgendaById_itemList_agendaItem_flowchart | null;
  client: getAgendaById_getAgendaById_itemList_agendaItem_client | null;
  time: any | null;
  alert: getAgendaById_getAgendaById_itemList_agendaItem_alert | null;
  moodMeter: getAgendaById_getAgendaById_itemList_agendaItem_moodMeter | null;
  notification: getAgendaById_getAgendaById_itemList_agendaItem_notification | null;
  timer: getAgendaById_getAgendaById_itemList_agendaItem_timer | null;
  award: getAgendaById_getAgendaById_itemList_agendaItem_award | null;
  checklist: getAgendaById_getAgendaById_itemList_agendaItem_checklist | null;
  selectionBoard: getAgendaById_getAgendaById_itemList_agendaItem_selectionBoard | null;
  agendaItems: getAgendaById_getAgendaById_itemList_agendaItem_agendaItems[] | null;
  createdBy: getAgendaById_getAgendaById_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaById_getAgendaById_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: getAgendaById_getAgendaById_itemList_agendaItem;
  position: number;
}

export interface getAgendaById_getAgendaById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getAgendaById_getAgendaById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getAgendaById_getAgendaById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getAgendaById_getAgendaById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getAgendaById_getAgendaById {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: getAgendaById_getAgendaById_client | null;
  itemList: getAgendaById_getAgendaById_itemList[] | null;
  createdBy: getAgendaById_getAgendaById_createdBy | null;
  shareRequests: getAgendaById_getAgendaById_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface getAgendaById {
  /**
   * Get agenda by id
   */
  getAgendaById: getAgendaById_getAgendaById | null;
}

export interface getAgendaByIdVariables {
  agendaId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAgendaItemById
// ====================================================

export interface getAgendaItemById_getAgendaItemById_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_client | null;
  image: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_notification | null;
  award: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_award | null;
  timer: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_timer | null;
  checklist: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getAgendaItemById_getAgendaItemById_flowchart_image | null;
  client: getAgendaItemById_getAgendaItemById_flowchart_client | null;
  createdBy: getAgendaItemById_getAgendaItemById_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getAgendaItemById_getAgendaItemById_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getAgendaItemById_getAgendaItemById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getAgendaItemById_getAgendaItemById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getAgendaItemById_getAgendaItemById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getAgendaItemById_getAgendaItemById_client_devices_result[];
}

export interface getAgendaItemById_getAgendaItemById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getAgendaItemById_getAgendaItemById_client_devices;
}

export interface getAgendaItemById_getAgendaItemById_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemById_getAgendaItemById_alert_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_instantAwards_image | null;
  referenceCount: number;
}

export interface getAgendaItemById_getAgendaItemById_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_image | null;
  video: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_moodMeter | null;
  notification: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_notification | null;
  timer: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_timer | null;
  award: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemById_getAgendaItemById_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: getAgendaItemById_getAgendaItemById_agendaItems_agendaItem;
  position: number;
}

export interface getAgendaItemById_getAgendaItemById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getAgendaItemById_getAgendaItemById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getAgendaItemById_getAgendaItemById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getAgendaItemById_getAgendaItemById_selectionBoard_items_image | null;
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemById_getAgendaItemById_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getAgendaItemById_getAgendaItemById_selectionBoard_items[];
  image: getAgendaItemById_getAgendaItemById_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: getAgendaItemById_getAgendaItemById_selectionBoard_createdBy | null;
}

export interface getAgendaItemById_getAgendaItemById {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemById_getAgendaItemById_flowchart | null;
  client: getAgendaItemById_getAgendaItemById_client | null;
  image: getAgendaItemById_getAgendaItemById_image | null;
  audio: getAgendaItemById_getAgendaItemById_audio | null;
  video: getAgendaItemById_getAgendaItemById_video | null;
  time: any | null;
  alert: getAgendaItemById_getAgendaItemById_alert | null;
  timer: getAgendaItemById_getAgendaItemById_timer | null;
  award: getAgendaItemById_getAgendaItemById_award | null;
  instantAwards: getAgendaItemById_getAgendaItemById_instantAwards[] | null;
  checklist: getAgendaItemById_getAgendaItemById_checklist | null;
  notification: getAgendaItemById_getAgendaItemById_notification | null;
  moodMeter: getAgendaItemById_getAgendaItemById_moodMeter | null;
  createdBy: getAgendaItemById_getAgendaItemById_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: getAgendaItemById_getAgendaItemById_agendaItems[] | null;
  shareRequests: getAgendaItemById_getAgendaItemById_shareRequests[] | null;
  selectionBoard: getAgendaItemById_getAgendaItemById_selectionBoard | null;
}

export interface getAgendaItemById {
  /**
   * Get agenda item by id
   */
  getAgendaItemById: getAgendaItemById_getAgendaItemById | null;
}

export interface getAgendaItemByIdVariables {
  agendaItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAgendaItemByIdWithClientData
// ====================================================

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_client | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_notification | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_award | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_timer | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_image | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_client | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getAgendaItemByIdWithClientData_getAgendaItemById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getAgendaItemByIdWithClientData_getAgendaItemById_client_devices_result[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getAgendaItemByIdWithClientData_getAgendaItemById_client_devices;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_alert_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards_image | null;
  referenceCount: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_image | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_flowchart | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_alert | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_moodMeter | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_notification | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_timer | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_award | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_checklist | null;
  selectionBoard: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems_agendaItem;
  position: number;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getAgendaItemByIdWithClientData_getAgendaItemById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items_image | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_items[];
  image: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard_createdBy | null;
}

export interface getAgendaItemByIdWithClientData_getAgendaItemById {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getAgendaItemByIdWithClientData_getAgendaItemById_flowchart | null;
  client: getAgendaItemByIdWithClientData_getAgendaItemById_client | null;
  image: getAgendaItemByIdWithClientData_getAgendaItemById_image | null;
  audio: getAgendaItemByIdWithClientData_getAgendaItemById_audio | null;
  video: getAgendaItemByIdWithClientData_getAgendaItemById_video | null;
  time: any | null;
  alert: getAgendaItemByIdWithClientData_getAgendaItemById_alert | null;
  timer: getAgendaItemByIdWithClientData_getAgendaItemById_timer | null;
  award: getAgendaItemByIdWithClientData_getAgendaItemById_award | null;
  instantAwards: getAgendaItemByIdWithClientData_getAgendaItemById_instantAwards[] | null;
  checklist: getAgendaItemByIdWithClientData_getAgendaItemById_checklist | null;
  notification: getAgendaItemByIdWithClientData_getAgendaItemById_notification | null;
  moodMeter: getAgendaItemByIdWithClientData_getAgendaItemById_moodMeter | null;
  createdBy: getAgendaItemByIdWithClientData_getAgendaItemById_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: getAgendaItemByIdWithClientData_getAgendaItemById_agendaItems[] | null;
  shareRequests: getAgendaItemByIdWithClientData_getAgendaItemById_shareRequests[] | null;
  selectionBoard: getAgendaItemByIdWithClientData_getAgendaItemById_selectionBoard | null;
}

export interface getAgendaItemByIdWithClientData_me_myClients_result_config_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
}

export interface getAgendaItemByIdWithClientData_me_myClients_result_config {
  __typename: "ClientConfig";
  moodMeter: getAgendaItemByIdWithClientData_me_myClients_result_config_moodMeter | null;
}

export interface getAgendaItemByIdWithClientData_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getAgendaItemByIdWithClientData_me_myClients_result_config;
}

export interface getAgendaItemByIdWithClientData_me_myClients {
  __typename: "ClientList";
  result: getAgendaItemByIdWithClientData_me_myClients_result[];
}

export interface getAgendaItemByIdWithClientData_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getAgendaItemByIdWithClientData_me_myClients | null;
}

export interface getAgendaItemByIdWithClientData {
  /**
   * Get agenda item by id
   */
  getAgendaItemById: getAgendaItemByIdWithClientData_getAgendaItemById | null;
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getAgendaItemByIdWithClientData_me;
}

export interface getAgendaItemByIdWithClientDataVariables {
  agendaItemId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFlowchartItemById
// ====================================================

export interface getFlowchartItemById_getFlowchartItemById_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getFlowchartItemById_getFlowchartItemById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getFlowchartItemById_getFlowchartItemById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getFlowchartItemById_getFlowchartItemById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getFlowchartItemById_getFlowchartItemById_client_devices_result[];
}

export interface getFlowchartItemById_getFlowchartItemById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getFlowchartItemById_getFlowchartItemById_client_devices;
}

export interface getFlowchartItemById_getFlowchartItemById_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_instantAwards_image | null;
  referenceCount: number;
}

export interface getFlowchartItemById_getFlowchartItemById_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_client | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_notification | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_award | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_timer | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchart_image | null;
  client: getFlowchartItemById_getFlowchartItemById_flowchart_client | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getFlowchartItemById_getFlowchartItemById_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_client | null;
  image: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_checklist | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_createdBy | null;
  flowchart: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getFlowchartItemById_getFlowchartItemById_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: getFlowchartItemById_getFlowchartItemById_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface getFlowchartItemById_getFlowchartItemById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getFlowchartItemById_getFlowchartItemById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getFlowchartItemById_getFlowchartItemById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getFlowchartItemById_getFlowchartItemById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getFlowchartItemById_getFlowchartItemById {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: getFlowchartItemById_getFlowchartItemById_image | null;
  audio: getFlowchartItemById_getFlowchartItemById_audio | null;
  video: getFlowchartItemById_getFlowchartItemById_video | null;
  client: getFlowchartItemById_getFlowchartItemById_client | null;
  moodMeter: getFlowchartItemById_getFlowchartItemById_moodMeter | null;
  notification: getFlowchartItemById_getFlowchartItemById_notification | null;
  award: getFlowchartItemById_getFlowchartItemById_award | null;
  instantAwards: getFlowchartItemById_getFlowchartItemById_instantAwards[] | null;
  checklist: getFlowchartItemById_getFlowchartItemById_checklist | null;
  timer: getFlowchartItemById_getFlowchartItemById_timer | null;
  flowchart: getFlowchartItemById_getFlowchartItemById_flowchart | null;
  flowchartItems: getFlowchartItemById_getFlowchartItemById_flowchartItems[] | null;
  createdBy: getFlowchartItemById_getFlowchartItemById_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: getFlowchartItemById_getFlowchartItemById_shareRequests[] | null;
}

export interface getFlowchartItemById {
  /**
   * Get flowchart item by id
   */
  getFlowchartItemById: getFlowchartItemById_getFlowchartItemById | null;
}

export interface getFlowchartItemByIdVariables {
  flowchartItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFlowchartItemByIdWithClientData
// ====================================================

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices_result[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getFlowchartItemByIdWithClientData_getFlowchartItemById_client_devices;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards_image | null;
  referenceCount: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_client | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_notification | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_award | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_timer | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_image | null;
  client: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_client | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_client | null;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_image | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_checklist | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_createdBy | null;
  flowchart: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getFlowchartItemByIdWithClientData_getFlowchartItemById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getFlowchartItemByIdWithClientData_getFlowchartItemById {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: getFlowchartItemByIdWithClientData_getFlowchartItemById_image | null;
  audio: getFlowchartItemByIdWithClientData_getFlowchartItemById_audio | null;
  video: getFlowchartItemByIdWithClientData_getFlowchartItemById_video | null;
  client: getFlowchartItemByIdWithClientData_getFlowchartItemById_client | null;
  moodMeter: getFlowchartItemByIdWithClientData_getFlowchartItemById_moodMeter | null;
  notification: getFlowchartItemByIdWithClientData_getFlowchartItemById_notification | null;
  award: getFlowchartItemByIdWithClientData_getFlowchartItemById_award | null;
  instantAwards: getFlowchartItemByIdWithClientData_getFlowchartItemById_instantAwards[] | null;
  checklist: getFlowchartItemByIdWithClientData_getFlowchartItemById_checklist | null;
  timer: getFlowchartItemByIdWithClientData_getFlowchartItemById_timer | null;
  flowchart: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchart | null;
  flowchartItems: getFlowchartItemByIdWithClientData_getFlowchartItemById_flowchartItems[] | null;
  createdBy: getFlowchartItemByIdWithClientData_getFlowchartItemById_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: getFlowchartItemByIdWithClientData_getFlowchartItemById_shareRequests[] | null;
}

export interface getFlowchartItemByIdWithClientData_me_myClients_result_config_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
}

export interface getFlowchartItemByIdWithClientData_me_myClients_result_config {
  __typename: "ClientConfig";
  moodMeter: getFlowchartItemByIdWithClientData_me_myClients_result_config_moodMeter | null;
}

export interface getFlowchartItemByIdWithClientData_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getFlowchartItemByIdWithClientData_me_myClients_result_config;
}

export interface getFlowchartItemByIdWithClientData_me_myClients {
  __typename: "ClientList";
  result: getFlowchartItemByIdWithClientData_me_myClients_result[];
}

export interface getFlowchartItemByIdWithClientData_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getFlowchartItemByIdWithClientData_me_myClients | null;
}

export interface getFlowchartItemByIdWithClientData {
  /**
   * Get flowchart item by id
   */
  getFlowchartItemById: getFlowchartItemByIdWithClientData_getFlowchartItemById | null;
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getFlowchartItemByIdWithClientData_me;
}

export interface getFlowchartItemByIdWithClientDataVariables {
  flowchartItemId: string;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdditionalFunctions
// ====================================================

export interface getAdditionalFunctions_me_myClients_result_config_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
}

export interface getAdditionalFunctions_me_myClients_result_config {
  __typename: "ClientConfig";
  moodMeter: getAdditionalFunctions_me_myClients_result_config_moodMeter | null;
}

export interface getAdditionalFunctions_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getAdditionalFunctions_me_myClients_result_config;
}

export interface getAdditionalFunctions_me_myClients {
  __typename: "ClientList";
  result: getAdditionalFunctions_me_myClients_result[];
}

export interface getAdditionalFunctions_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getAdditionalFunctions_me_myClients | null;
}

export interface getAdditionalFunctions {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getAdditionalFunctions_me;
}

export interface getAdditionalFunctionsVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFlowchartById
// ====================================================

export interface getFlowchartById_getFlowchartById_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getFlowchartById_getFlowchartById_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getFlowchartById_getFlowchartById_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getFlowchartById_getFlowchartById_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getFlowchartById_getFlowchartById_client_devices_result[];
}

export interface getFlowchartById_getFlowchartById_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getFlowchartById_getFlowchartById_client_devices;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartById_getFlowchartById_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartById_getFlowchartById_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_image | null;
  itemList: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getFlowchartById_getFlowchartById_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getFlowchartById_getFlowchartById_itemList_flowchartItem_client | null;
  image: getFlowchartById_getFlowchartById_itemList_flowchartItem_image | null;
  video: getFlowchartById_getFlowchartById_itemList_flowchartItem_video | null;
  moodMeter: getFlowchartById_getFlowchartById_itemList_flowchartItem_moodMeter | null;
  notification: getFlowchartById_getFlowchartById_itemList_flowchartItem_notification | null;
  award: getFlowchartById_getFlowchartById_itemList_flowchartItem_award | null;
  timer: getFlowchartById_getFlowchartById_itemList_flowchartItem_timer | null;
  checklist: getFlowchartById_getFlowchartById_itemList_flowchartItem_checklist | null;
  createdBy: getFlowchartById_getFlowchartById_itemList_flowchartItem_createdBy | null;
  flowchart: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchart | null;
  flowchartItems: getFlowchartById_getFlowchartById_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getFlowchartById_getFlowchartById_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: getFlowchartById_getFlowchartById_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface getFlowchartById_getFlowchartById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getFlowchartById_getFlowchartById_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getFlowchartById_getFlowchartById_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getFlowchartById_getFlowchartById_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getFlowchartById_getFlowchartById {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getFlowchartById_getFlowchartById_image | null;
  client: getFlowchartById_getFlowchartById_client | null;
  itemList: getFlowchartById_getFlowchartById_itemList[] | null;
  createdBy: getFlowchartById_getFlowchartById_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: getFlowchartById_getFlowchartById_shareRequests[] | null;
}

export interface getFlowchartById {
  /**
   * Get flowchart by id
   */
  getFlowchartById: getFlowchartById_getFlowchartById | null;
}

export interface getFlowchartByIdVariables {
  flowchartId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientCalendar
// ====================================================

export interface getClientCalendar_search_result_AgendaItem {
  __typename: "AgendaItem" | "Asset" | "Flowchart" | "FlowchartItem";
}

export interface getClientCalendar_search_result_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientCalendar_search_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  day: any | null;
  disabledAt: any | null;
  createdBy: getClientCalendar_search_result_Agenda_createdBy | null;
  updatedAt: any | null;
}

export type getClientCalendar_search_result = getClientCalendar_search_result_AgendaItem | getClientCalendar_search_result_Agenda;

export interface getClientCalendar_search {
  __typename: "SearchList";
  result: getClientCalendar_search_result[];
}

export interface getClientCalendar {
  /**
   * Get list of:
   * - agenda
   * - agendaItem
   * - flowchart
   * - flowchartItem
   * - asset
   * - @requires auth
   */
  search: getClientCalendar_search;
}

export interface getClientCalendarVariables {
  clientId: string;
  dayFrom: any;
  dayTo: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSupporterIdByEmail
// ====================================================

export interface getSupporterIdByEmail {
  /**
   * Get supporter id by email
   * - @requires auth accountType: [supporter, supervisor]
   */
  getSupporterIdByEmail: string;
}

export interface getSupporterIdByEmailVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppEventLogList
// ====================================================

export interface getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_dateAggregation {
  __typename: "DateAggregation";
  date: any;
  count: number;
}

export interface getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_hourAggregation {
  __typename: "HourAggregation";
  hour: number;
  count: number;
}

export interface getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_weekAggregation {
  __typename: "WeekAggregation";
  week: number;
  count: number;
}

export interface getAppEventLogList_getAppEventLogList_aggregations_timeAggregations {
  __typename: "TimeAggregations";
  dateAggregation: getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_dateAggregation[] | null;
  hourAggregation: getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_hourAggregation[] | null;
  weekAggregation: getAppEventLogList_getAppEventLogList_aggregations_timeAggregations_weekAggregation[] | null;
}

export interface getAppEventLogList_getAppEventLogList_aggregations {
  __typename: "Aggregations";
  timeAggregations: getAppEventLogList_getAppEventLogList_aggregations_timeAggregations;
}

export interface getAppEventLogList_getAppEventLogList_result_entity_client {
  __typename: "AppEventLogClientEntity";
  id: string;
  extId: string;
}

export interface getAppEventLogList_getAppEventLogList_result_entity {
  __typename: "AppEventLogEntity";
  client: getAppEventLogList_getAppEventLogList_result_entity_client | null;
}

export interface getAppEventLogList_getAppEventLogList_result_request_account {
  __typename: "AppEventLogAccountEntity";
  id: string | null;
  extId: string | null;
}

export interface getAppEventLogList_getAppEventLogList_result_request {
  __typename: "AppEventLogRequest";
  account: getAppEventLogList_getAppEventLogList_result_request_account | null;
}

export interface getAppEventLogList_getAppEventLogList_result {
  __typename: "AppEventLog";
  event: AppEventLogName;
  eventTitle: string;
  timestamp: any;
  entity: getAppEventLogList_getAppEventLogList_result_entity | null;
  entityTitle: string | null;
  request: getAppEventLogList_getAppEventLogList_result_request | null;
}

export interface getAppEventLogList_getAppEventLogList {
  __typename: "AppEventLogList";
  count: number;
  aggregations: getAppEventLogList_getAppEventLogList_aggregations | null;
  result: getAppEventLogList_getAppEventLogList_result[];
}

export interface getAppEventLogList {
  /**
   * Get list of CMS application event log
   * - @requires auth: accountType: [supervisor, admin, superadmin]
   */
  getAppEventLogList: getAppEventLogList_getAppEventLogList;
}

export interface getAppEventLogListVariables {
  type?: AppEventLogName | null;
  authAccountId?: string | null;
  accountId?: string | null;
  clientId?: string | null;
  authAccountExtId?: string | null;
  clientExtId?: string | null;
  options?: AppEventLogListOptionsInput | null;
  date: DateFilter;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyAssetById
// ====================================================

export interface getMyAssetById_getAssetById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getMyAssetById_getAssetById {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getMyAssetById_getAssetById_createdBy | null;
  tags: string[];
}

export interface getMyAssetById {
  /**
   * Get asset by id
   */
  getAssetById: getMyAssetById_getAssetById;
}

export interface getMyAssetByIdVariables {
  assetId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTags
// ====================================================

export interface getTags_getTags_result {
  __typename: "Tag";
  id: string;
  title: string;
  category: TagCategory;
}

export interface getTags_getTags {
  __typename: "TagList";
  count: number;
  result: getTags_getTags_result[];
}

export interface getTags {
  /**
   * Get tags (optionally filter by category)
   */
  getTags: getTags_getTags | null;
}

export interface getTagsVariables {
  options?: TagListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAdminTags
// ====================================================

export interface getAdminTags_getTags_result_createdBy_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminTags_getTags_result_createdBy_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminTags_getTags_result_createdBy_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminTags_getTags_result_createdBy_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface getAdminTags_getTags_result_createdBy_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: getAdminTags_getTags_result_createdBy_myClients_result_avatar | null;
  defaultAgenda: getAdminTags_getTags_result_createdBy_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: getAdminTags_getTags_result_createdBy_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface getAdminTags_getTags_result_createdBy_myClients {
  __typename: "ClientList";
  result: getAdminTags_getTags_result_createdBy_myClients_result[];
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client_avatar | null;
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_client;
  createdBy: getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface getAdminTags_getTags_result_createdBy_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: getAdminTags_getTags_result_createdBy_pendingClientShareRequests_result[];
}

export interface getAdminTags_getTags_result_createdBy_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface getAdminTags_getTags_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: getAdminTags_getTags_result_createdBy_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: getAdminTags_getTags_result_createdBy_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: getAdminTags_getTags_result_createdBy_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: getAdminTags_getTags_result_createdBy_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface getAdminTags_getTags_result {
  __typename: "Tag";
  id: string;
  title: string;
  category: TagCategory;
  createdBy: getAdminTags_getTags_result_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAdminTags_getTags {
  __typename: "TagList";
  count: number;
  result: getAdminTags_getTags_result[];
}

export interface getAdminTags {
  /**
   * Get tags (optionally filter by category)
   */
  getTags: getAdminTags_getTags | null;
}

export interface getAdminTagsVariables {
  options?: TagListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientSpareAgendaItems
// ====================================================

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_video | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_image | null;
  itemList: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart_itemList[] | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_alert_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_image | null;
  audio: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification_audio | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_flowchart | null;
  client: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_alert | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_moodMeter | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_timer | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_award | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_checklist | null;
  selectionBoard: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_agendaItems[] | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_notification | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems_agendaItem;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSpareAgendaItems_getClientSpareAgendaItems {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getClientSpareAgendaItems_getClientSpareAgendaItems_image | null;
  video: getClientSpareAgendaItems_getClientSpareAgendaItems_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getClientSpareAgendaItems_getClientSpareAgendaItems_flowchart | null;
  client: getClientSpareAgendaItems_getClientSpareAgendaItems_client | null;
  time: any | null;
  alert: getClientSpareAgendaItems_getClientSpareAgendaItems_alert | null;
  moodMeter: getClientSpareAgendaItems_getClientSpareAgendaItems_moodMeter | null;
  notification: getClientSpareAgendaItems_getClientSpareAgendaItems_notification | null;
  timer: getClientSpareAgendaItems_getClientSpareAgendaItems_timer | null;
  award: getClientSpareAgendaItems_getClientSpareAgendaItems_award | null;
  checklist: getClientSpareAgendaItems_getClientSpareAgendaItems_checklist | null;
  selectionBoard: getClientSpareAgendaItems_getClientSpareAgendaItems_selectionBoard | null;
  agendaItems: getClientSpareAgendaItems_getClientSpareAgendaItems_agendaItems[] | null;
  createdBy: getClientSpareAgendaItems_getClientSpareAgendaItems_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getClientSpareAgendaItems {
  /**
   * Get spare agenda items
   */
  getClientSpareAgendaItems: getClientSpareAgendaItems_getClientSpareAgendaItems[];
}

export interface getClientSpareAgendaItemsVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEverydaySituationClientDirectoryByClientId
// ====================================================

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextEverydaySituation_createdBy | null;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation_createdBy | null;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation_createdBy | null;
}

export type getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations = getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextEverydaySituation | getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_ImageEverydaySituation | getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_TextAndImageEverydaySituation | getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations_AudioEverydaySituation;

export interface getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId {
  __typename: "EverydaySituationClientDirectory";
  id: string;
  title: string;
  isVisible: boolean;
  isWritable: boolean;
  image: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_image;
  situations: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId_situations[];
  createdAt: any;
  updatedAt: any | null;
}

export interface getEverydaySituationClientDirectoryByClientId {
  getEverydaySituationClientDirectoryByClientId: getEverydaySituationClientDirectoryByClientId_getEverydaySituationClientDirectoryByClientId;
}

export interface getEverydaySituationClientDirectoryByClientIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEverydaySituationDirectoriesByClientId
// ====================================================

export interface getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId_itemCount {
  __typename: "EverydaySituationItemCount";
  text: number;
  audio: number;
  image: number;
}

export interface getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId_image;
  itemCount: getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId_itemCount;
  disabledAt: any | null;
}

export interface getEverydaySituationDirectoriesByClientId {
  getEverydaySituationDirectoriesByClientId: getEverydaySituationDirectoriesByClientId_getEverydaySituationDirectoriesByClientId[] | null;
}

export interface getEverydaySituationDirectoriesByClientIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEverydaySituationDirectoryById
// ====================================================

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextEverydaySituation_createdBy | null;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation_createdBy | null;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation_createdBy | null;
}

export type getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations = getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextEverydaySituation | getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_ImageEverydaySituation | getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_TextAndImageEverydaySituation | getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations_AudioEverydaySituation;

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getEverydaySituationDirectoryById_getEverydaySituationDirectoryById {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_image;
  situations: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_situations[];
  createdBy: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById_createdBy | null;
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getEverydaySituationDirectoryById {
  getEverydaySituationDirectoryById: getEverydaySituationDirectoryById_getEverydaySituationDirectoryById;
}

export interface getEverydaySituationDirectoryByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFaqs
// ====================================================

export interface getFaqs_getFaqItemList_createdBy {
  __typename: "Account";
  name: string;
}

export interface getFaqs_getFaqItemList {
  __typename: "FaqItem";
  id: string;
  question: string;
  answer: string;
  createdBy: getFaqs_getFaqItemList_createdBy | null;
  updatedAt: any | null;
  position: number | null;
}

export interface getFaqs {
  /**
   * Get FAQ items
   */
  getFaqItemList: getFaqs_getFaqItemList[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFaqById
// ====================================================

export interface getFaqById_getFaqItemById_createdBy {
  __typename: "Account";
  name: string;
}

export interface getFaqById_getFaqItemById {
  __typename: "FaqItem";
  id: string;
  question: string;
  answer: string;
  createdBy: getFaqById_getFaqItemById_createdBy | null;
  updatedAt: any | null;
  position: number | null;
}

export interface getFaqById {
  /**
   * Get a FAQ item
   */
  getFaqItemById: getFaqById_getFaqItemById;
}

export interface getFaqByIdVariables {
  faqItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonalAssetDirectoriesByParentId
// ====================================================

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_children_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_children_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_parent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_parent_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
  children: getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_children[] | null;
  parent: getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_parent | null;
}

export interface getPersonalAssetDirectoriesByParentId {
  /**
   * Get asset directories by parent id
   */
  getPersonalAssetDirectoriesByParentId: (getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId | null)[];
}

export interface getPersonalAssetDirectoriesByParentIdVariables {
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPublicAssetDirectoriesByParentId
// ====================================================

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_children_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_children_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_parent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_parent_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
  children: getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_children[] | null;
  parent: getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId_parent | null;
}

export interface getPublicAssetDirectoriesByParentId {
  /**
   * Get public asset directories by parent id
   */
  getPublicAssetDirectoriesByParentId: (getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId | null)[];
}

export interface getPublicAssetDirectoriesByParentIdVariables {
  parentId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPersonalAssetDirectoryById
// ====================================================

export interface getPersonalAssetDirectoryById_getPersonalAssetDirectoryById {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
}

export interface getPersonalAssetDirectoryById {
  /**
   * Get asset directory by id
   */
  getPersonalAssetDirectoryById: getPersonalAssetDirectoryById_getPersonalAssetDirectoryById | null;
}

export interface getPersonalAssetDirectoryByIdVariables {
  assetDirectoryId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPublicAssetDirectoryById
// ====================================================

export interface getPublicAssetDirectoryById_getPublicAssetDirectoryById {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
}

export interface getPublicAssetDirectoryById {
  /**
   * Get public asset directory by id
   */
  getPublicAssetDirectoryById: getPublicAssetDirectoryById_getPublicAssetDirectoryById | null;
}

export interface getPublicAssetDirectoryByIdVariables {
  assetDirectoryId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientGameConfig
// ====================================================

export interface getClientGameConfig_me_myClients_result_config_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface getClientGameConfig_me_myClients_result_config {
  __typename: "ClientConfig";
  enabledGames: getClientGameConfig_me_myClients_result_config_enabledGames[] | null;
}

export interface getClientGameConfig_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Custom configuration values for client: default colors(agenda, flowchart), mobile app unlock colors, etc. 
   */
  config: getClientGameConfig_me_myClients_result_config;
}

export interface getClientGameConfig_me_myClients {
  __typename: "ClientList";
  result: getClientGameConfig_me_myClients_result[];
}

export interface getClientGameConfig_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getClientGameConfig_me_myClients | null;
}

export interface getClientGameConfig {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getClientGameConfig_me;
}

export interface getClientGameConfigVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientMemoryGameConfig
// ====================================================

export interface getClientMemoryGameConfig_getClientMemoryGameConfig_images_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientMemoryGameConfig_getClientMemoryGameConfig_images {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientMemoryGameConfig_getClientMemoryGameConfig_images_createdBy | null;
  tags: string[];
}

export interface getClientMemoryGameConfig_getClientMemoryGameConfig {
  __typename: "MemoryGameConfig";
  numberOfPairs: number;
  images: getClientMemoryGameConfig_getClientMemoryGameConfig_images[];
}

export interface getClientMemoryGameConfig {
  /**
   * Get settings for the memory game
   */
  getClientMemoryGameConfig: getClientMemoryGameConfig_getClientMemoryGameConfig | null;
}

export interface getClientMemoryGameConfigVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientSortingGameConfig
// ====================================================

export interface getClientSortingGameConfig_getClientSortingGameConfig_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getClientSortingGameConfig_getClientSortingGameConfig_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getClientSortingGameConfig_getClientSortingGameConfig_client_devices_result[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getClientSortingGameConfig_getClientSortingGameConfig_client_devices;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_image | null;
  audio: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_checklist_items[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_image | null;
  itemList: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_client | null;
  image: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_image | null;
  video: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_video | null;
  moodMeter: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_moodMeter | null;
  notification: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_notification | null;
  award: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_award | null;
  timer: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_timer | null;
  checklist: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_checklist | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_createdBy | null;
  flowchart: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchart | null;
  flowchartItems: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: getClientSortingGameConfig_getClientSortingGameConfig_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getClientSortingGameConfig_getClientSortingGameConfig_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getClientSortingGameConfig_getClientSortingGameConfig {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getClientSortingGameConfig_getClientSortingGameConfig_image | null;
  client: getClientSortingGameConfig_getClientSortingGameConfig_client | null;
  itemList: getClientSortingGameConfig_getClientSortingGameConfig_itemList[] | null;
  createdBy: getClientSortingGameConfig_getClientSortingGameConfig_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: getClientSortingGameConfig_getClientSortingGameConfig_shareRequests[] | null;
}

export interface getClientSortingGameConfig {
  /**
   * Get settings for the sorting game
   */
  getClientSortingGameConfig: getClientSortingGameConfig_getClientSortingGameConfig[];
}

export interface getClientSortingGameConfigVariables {
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientInstantAwards
// ====================================================

export interface getClientInstantAwards_me_myClients_result_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientInstantAwards_me_myClients_result_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientInstantAwards_me_myClients_result_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientInstantAwards_me_myClients_result_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getClientInstantAwards_me_myClients_result_instantAwards_result_image | null;
  referenceCount: number;
}

export interface getClientInstantAwards_me_myClients_result_instantAwards {
  __typename: "InstantAwardList";
  result: getClientInstantAwards_me_myClients_result_instantAwards_result[];
}

export interface getClientInstantAwards_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  instantAwards: getClientInstantAwards_me_myClients_result_instantAwards | null;
}

export interface getClientInstantAwards_me_myClients {
  __typename: "ClientList";
  result: getClientInstantAwards_me_myClients_result[];
}

export interface getClientInstantAwards_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getClientInstantAwards_me_myClients | null;
}

export interface getClientInstantAwards {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getClientInstantAwards_me;
}

export interface getClientInstantAwardsVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientAwards
// ====================================================

export interface getClientAwards_me_myClients_result_awards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientAwards_me_myClients_result_awards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientAwards_me_myClients_result_awards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientAwards_me_myClients_result_awards_result {
  __typename: "Award";
  id: string;
  title: string;
  autoReactivation: boolean;
  isActive: boolean;
  targetScore: number;
  image: getClientAwards_me_myClients_result_awards_result_image | null;
  disabledAt: any | null;
}

export interface getClientAwards_me_myClients_result_awards {
  __typename: "AwardList";
  result: getClientAwards_me_myClients_result_awards_result[];
}

export interface getClientAwards_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Score awards 
   */
  awards: getClientAwards_me_myClients_result_awards | null;
}

export interface getClientAwards_me_myClients {
  __typename: "ClientList";
  result: getClientAwards_me_myClients_result[];
}

export interface getClientAwards_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getClientAwards_me_myClients | null;
}

export interface getClientAwards {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getClientAwards_me;
}

export interface getClientAwardsVariables {
  clientId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDisabledPublicContent
// ====================================================

export interface getDisabledPublicContent_getDisabledContentList_result_Agenda_agendaClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  createdAt: any;
  agendaClient: getDisabledPublicContent_getDisabledContentList_result_Agenda_agendaClient | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemVideo {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  createdAt: any;
  agendaItemClient: getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemClient | null;
  agendaItemImage: getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemImage | null;
  agendaItemVideo: getDisabledPublicContent_getDisabledContentList_result_AgendaItem_agendaItemVideo | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Flowchart_flowchartClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Flowchart_flowchartImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  createdAt: any;
  flowchartClient: getDisabledPublicContent_getDisabledContentList_result_Flowchart_flowchartClient | null;
  flowchartImage: getDisabledPublicContent_getDisabledContentList_result_Flowchart_flowchartImage | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemVideo {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  createdAt: any;
  flowchartItemClient: getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemClient | null;
  flowchartItemImage: getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemImage | null;
  flowchartItemVideo: getDisabledPublicContent_getDisabledContentList_result_FlowchartItem_flowchartItemVideo | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  createdAt: any;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Award_awardClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Award_awardImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_Award {
  __typename: "Award";
  id: string;
  title: string;
  createdAt: any;
  awardClient: getDisabledPublicContent_getDisabledContentList_result_Award_awardClient;
  awardImage: getDisabledPublicContent_getDisabledContentList_result_Award_awardImage | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_InstantAward_instantAwardClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_InstantAward_instantAwardImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_InstantAward {
  __typename: "InstantAward";
  id: string;
  title: string;
  createdAt: any;
  instantAwardClient: getDisabledPublicContent_getDisabledContentList_result_InstantAward_instantAwardClient;
  instantAwardImage: getDisabledPublicContent_getDisabledContentList_result_InstantAward_instantAwardImage | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation_textEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation_textEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  textEverydaySituationClient: getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation_textEverydaySituationClient;
  textEverydaySituationDirectory: getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation_textEverydaySituationDirectory;
  createdAt: any;
}

export interface getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  createdAt: any;
  imageEverydaySituationClient: getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationClient;
  imageEverydaySituationImage: getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationImage;
  imageEverydaySituationDirectory: getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation_imageEverydaySituationDirectory;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  createdAt: any;
  textAndImageEverydaySituationClient: getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationClient;
  textAndImageEverydaySituationImage: getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationImage;
  textAndImageEverydaySituationDirectory: getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationDirectory;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation_audioEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation_audioEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audioEverydaySituationClient: getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation_audioEverydaySituationClient;
  createdAt: any;
  audioEverydaySituationDirectory: getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation_audioEverydaySituationDirectory;
}

export interface getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory_everydaySituationDirectoryClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory_everydaySituationDirectoryImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  createdAt: any;
  everydaySituationDirectoryClient: getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory_everydaySituationDirectoryClient;
  everydaySituationDirectoryImage: getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory_everydaySituationDirectoryImage;
}

export interface getDisabledPublicContent_getDisabledContentList_result_SelectionBoard_selectionClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getDisabledPublicContent_getDisabledContentList_result_SelectionBoard_selectionImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getDisabledPublicContent_getDisabledContentList_result_SelectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  createdAt: any;
  selectionClient: getDisabledPublicContent_getDisabledContentList_result_SelectionBoard_selectionClient;
  selectionImage: getDisabledPublicContent_getDisabledContentList_result_SelectionBoard_selectionImage | null;
}

export type getDisabledPublicContent_getDisabledContentList_result = getDisabledPublicContent_getDisabledContentList_result_Agenda | getDisabledPublicContent_getDisabledContentList_result_AgendaItem | getDisabledPublicContent_getDisabledContentList_result_Flowchart | getDisabledPublicContent_getDisabledContentList_result_FlowchartItem | getDisabledPublicContent_getDisabledContentList_result_Asset | getDisabledPublicContent_getDisabledContentList_result_Award | getDisabledPublicContent_getDisabledContentList_result_InstantAward | getDisabledPublicContent_getDisabledContentList_result_TextEverydaySituation | getDisabledPublicContent_getDisabledContentList_result_ImageEverydaySituation | getDisabledPublicContent_getDisabledContentList_result_TextAndImageEverydaySituation | getDisabledPublicContent_getDisabledContentList_result_AudioEverydaySituation | getDisabledPublicContent_getDisabledContentList_result_EverydaySituationDirectory | getDisabledPublicContent_getDisabledContentList_result_SelectionBoard;

export interface getDisabledPublicContent_getDisabledContentList {
  __typename: "DisabledContentList";
  count: number;
  result: getDisabledPublicContent_getDisabledContentList_result[];
}

export interface getDisabledPublicContent {
  /**
   * Get list of disabled contents
   */
  getDisabledContentList: getDisabledPublicContent_getDisabledContentList;
}

export interface getDisabledPublicContentVariables {
  options?: DisabledContentListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEducationModules
// ====================================================

export interface getEducationModules_educationModules_subModules {
  __typename: "EducationContentModule";
  id: string;
}

export interface getEducationModules_educationModules_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface getEducationModules_educationModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  subModules: getEducationModules_educationModules_subModules[] | null;
  createdBy: getEducationModules_educationModules_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getEducationModules {
  /**
   * Get all education content modules
   * - @requires auth
   */
  educationModules: getEducationModules_educationModules[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEducationModuleTabs
// ====================================================

export interface getEducationModuleTabs_educationModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
}

export interface getEducationModuleTabs {
  /**
   * Get all education content modules
   * - @requires auth
   */
  educationModules: getEducationModuleTabs_educationModules[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEducationModuleById
// ====================================================

export interface getEducationModuleById_educationModule_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface getEducationModuleById_educationModule_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface getEducationModuleById_educationModule_subModules_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface getEducationModuleById_educationModule_subModules_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface getEducationModuleById_educationModule_subModules_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface getEducationModuleById_educationModule_subModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: getEducationModuleById_educationModule_subModules_videoContents[];
  documentContents: getEducationModuleById_educationModule_subModules_documentContents[];
  createdBy: getEducationModuleById_educationModule_subModules_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getEducationModuleById_educationModule_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface getEducationModuleById_educationModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: getEducationModuleById_educationModule_videoContents[];
  documentContents: getEducationModuleById_educationModule_documentContents[];
  subModules: getEducationModuleById_educationModule_subModules[] | null;
  createdBy: getEducationModuleById_educationModule_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getEducationModuleById {
  /**
   * Get education content module by id
   * - @requires auth
   */
  educationModule: getEducationModuleById_educationModule;
}

export interface getEducationModuleByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientEverydaySituationDirectoryList
// ====================================================

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_image_createdBy | null;
  tags: string[];
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextEverydaySituation_createdBy | null;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation_createdBy | null;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation_createdBy | null;
}

export type getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations = getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextEverydaySituation | getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_ImageEverydaySituation | getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_TextAndImageEverydaySituation | getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations_AudioEverydaySituation;

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_image;
  situations: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_createdBy | null;
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories {
  __typename: "EverydaySituationDirectoryList";
  count: number;
  result: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result[];
}

export interface getClientEverydaySituationDirectoryList_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  everydaySituationDirectories: getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories | null;
}

export interface getClientEverydaySituationDirectoryList {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientEverydaySituationDirectoryList_getClientByExtId;
}

export interface getClientEverydaySituationDirectoryListVariables {
  clientExtId: string;
  options?: EverydaySituationDirectoryListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientInstantAwardList
// ====================================================

export interface getClientInstantAwardList_getClientByExtId_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientInstantAwardList_getClientByExtId_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientInstantAwardList_getClientByExtId_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientInstantAwardList_getClientByExtId_instantAwards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getClientInstantAwardList_getClientByExtId_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  referenceCount: number;
  title: string;
  image: getClientInstantAwardList_getClientByExtId_instantAwards_result_image | null;
  createdBy: getClientInstantAwardList_getClientByExtId_instantAwards_result_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getClientInstantAwardList_getClientByExtId_instantAwards {
  __typename: "InstantAwardList";
  count: number;
  result: getClientInstantAwardList_getClientByExtId_instantAwards_result[];
}

export interface getClientInstantAwardList_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  instantAwards: getClientInstantAwardList_getClientByExtId_instantAwards | null;
}

export interface getClientInstantAwardList {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientInstantAwardList_getClientByExtId;
}

export interface getClientInstantAwardListVariables {
  clientExtId: string;
  options?: InstantAwardListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientAwardList
// ====================================================

export interface getClientAwardList_getClientByExtId_awards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientAwardList_getClientByExtId_awards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientAwardList_getClientByExtId_awards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientAwardList_getClientByExtId_awards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getClientAwardList_getClientByExtId_awards_result {
  __typename: "Award";
  id: string;
  title: string;
  image: getClientAwardList_getClientByExtId_awards_result_image | null;
  targetScore: number;
  createdBy: getClientAwardList_getClientByExtId_awards_result_createdBy | null;
  isActive: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getClientAwardList_getClientByExtId_awards {
  __typename: "AwardList";
  count: number;
  result: getClientAwardList_getClientByExtId_awards_result[];
}

export interface getClientAwardList_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Score awards 
   */
  awards: getClientAwardList_getClientByExtId_awards | null;
}

export interface getClientAwardList {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientAwardList_getClientByExtId;
}

export interface getClientAwardListVariables {
  clientExtId: string;
  options?: AwardListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientSelectionBoardList
// ====================================================

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items_image | null;
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  image: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_image | null;
  items: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_items[];
  createdBy: getClientSelectionBoardList_getClientByExtId_selectionBoards_result_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

export interface getClientSelectionBoardList_getClientByExtId_selectionBoards {
  __typename: "SelectionBoardList";
  count: number;
  result: getClientSelectionBoardList_getClientByExtId_selectionBoards_result[];
}

export interface getClientSelectionBoardList_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  selectionBoards: getClientSelectionBoardList_getClientByExtId_selectionBoards | null;
}

export interface getClientSelectionBoardList {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientSelectionBoardList_getClientByExtId;
}

export interface getClientSelectionBoardListVariables {
  clientExtId: string;
  options?: SelectionBoardListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getClientSelectionBoardById
// ====================================================

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items_image | null;
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_items[];
  image: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: getClientSelectionBoardById_getClientByExtId_selectionBoards_result_createdBy | null;
}

export interface getClientSelectionBoardById_getClientByExtId_selectionBoards {
  __typename: "SelectionBoardList";
  count: number;
  result: getClientSelectionBoardById_getClientByExtId_selectionBoards_result[];
}

export interface getClientSelectionBoardById_getClientByExtId {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  selectionBoards: getClientSelectionBoardById_getClientByExtId_selectionBoards | null;
}

export interface getClientSelectionBoardById {
  /**
   * Get client by ext id
   * - @deprecated
   * - @requires auth accountType: [supervisor, admin, superadmin]
   */
  getClientByExtId: getClientSelectionBoardById_getClientByExtId;
}

export interface getClientSelectionBoardByIdVariables {
  clientExtId: string;
  options?: SelectionBoardListOptionsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPendingLibraryContentShareRequests
// ====================================================

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_requestCreatedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices_result[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client_devices;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList_agendaItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_client | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_itemList[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_createdBy | null;
  shareRequests: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_image | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_client | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices_result[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client_devices;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items_image | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_items[];
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard_createdBy | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_client | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_audio | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_video | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_alert | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_timer | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_award | null;
  instantAwards: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_instantAwards[] | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_checklist | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_moodMeter | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_agendaItems[] | null;
  shareRequests: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_shareRequests[] | null;
  selectionBoard: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem_selectionBoard | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices_result[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client_devices;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_image | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_client | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_itemList[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart_shareRequests[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices_result[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client_devices;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_image | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_client | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_image | null;
  audio: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_audio | null;
  video: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_video | null;
  client: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_client | null;
  moodMeter: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_notification | null;
  award: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_award | null;
  instantAwards: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_instantAwards[] | null;
  checklist: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_timer | null;
  flowchart: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_flowchartItems[] | null;
  createdBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem_shareRequests[] | null;
}

export type getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent = getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Agenda | getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_AgendaItem | getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_Flowchart | getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent_FlowchartItem;

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result {
  __typename: "LibraryContentShareRequestListItem";
  requestId: string;
  requestCreatedBy: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_requestCreatedBy | null;
  requestCreatedAt: any;
  requestStatus: RequestStatus;
  requestComment: string | null;
  libraryContent: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result_libraryContent;
}

export interface getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests {
  __typename: "LibraryContentShareRequestList";
  count: number;
  result: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests_result[];
}

export interface getPendingLibraryContentShareRequests_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Pending library content share requests
   * Only for supporters and supervisors
   * Supervisor: the list of pending share requests where the content is shared to the public
   * Supporter: the list of pending share requests where the content is shared with the supporter
   */
  pendingLibraryContentShareRequests: getPendingLibraryContentShareRequests_me_pendingLibraryContentShareRequests | null;
}

export interface getPendingLibraryContentShareRequests {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getPendingLibraryContentShareRequests_me;
}

export interface getPendingLibraryContentShareRequestsVariables {
  options?: LibraryContentShareRequestListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyClientsLogs
// ====================================================

export interface getMyClientsLogs_me_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_dateAggregation {
  __typename: "DateAggregation";
  date: any;
  count: number;
}

export interface getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_hourAggregation {
  __typename: "HourAggregation";
  hour: number;
  count: number;
}

export interface getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_weekAggregation {
  __typename: "WeekAggregation";
  week: number;
  count: number;
}

export interface getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations {
  __typename: "TimeAggregations";
  dateAggregation: getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_dateAggregation[] | null;
  hourAggregation: getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_hourAggregation[] | null;
  weekAggregation: getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations_weekAggregation[] | null;
}

export interface getMyClientsLogs_me_myClients_result_logs_aggregations {
  __typename: "Aggregations";
  timeAggregations: getMyClientsLogs_me_myClients_result_logs_aggregations_timeAggregations;
}

export interface getMyClientsLogs_me_myClients_result_logs_result {
  __typename: "AppStatItem";
  createdAt: any;
}

export interface getMyClientsLogs_me_myClients_result_logs {
  __typename: "AppStatItemList";
  count: number;
  aggregations: getMyClientsLogs_me_myClients_result_logs_aggregations | null;
  result: getMyClientsLogs_me_myClients_result_logs_result[];
}

export interface getMyClientsLogs_me_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  avatar: getMyClientsLogs_me_myClients_result_avatar | null;
  /**
   * Client app stat logs
   */
  logs: getMyClientsLogs_me_myClients_result_logs | null;
}

export interface getMyClientsLogs_me_myClients {
  __typename: "ClientList";
  result: getMyClientsLogs_me_myClients_result[];
}

export interface getMyClientsLogs_me {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Clients supported by me
   */
  myClients: getMyClientsLogs_me_myClients | null;
}

export interface getMyClientsLogs {
  /**
   * Get profile info for current session
   * - @requires auth
   */
  me: getMyClientsLogs_me;
}

export interface getMyClientsLogsVariables {
  date: DateFilter;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppStatItemList
// ====================================================

export interface getAppStatItemList_getAppStatItemList_result_device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getAppStatItemList_getAppStatItemList_result_device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getAppStatItemList_getAppStatItemList_result_device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getAppStatItemList_getAppStatItemList_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getAppStatItemList_getAppStatItemList_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getAppStatItemList_getAppStatItemList_result {
  __typename: "AppStatItem";
  type: AppStatItemType;
  typeTitle: string;
  data: any | null;
  device: getAppStatItemList_getAppStatItemList_result_device | null;
  account: getAppStatItemList_getAppStatItemList_result_account | null;
  client: getAppStatItemList_getAppStatItemList_result_client | null;
  createdAt: any;
}

export interface getAppStatItemList_getAppStatItemList {
  __typename: "AppStatItemList";
  count: number;
  result: getAppStatItemList_getAppStatItemList_result[];
}

export interface getAppStatItemList {
  getAppStatItemList: getAppStatItemList_getAppStatItemList;
}

export interface getAppStatItemListVariables {
  date: DateFilter;
  clientExtId?: string | null;
  clientProfile?: string | null;
  options?: AppStatItemListOptionsInput | null;
  type?: AppStatItemType | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPendingLibraryContentShareRequestById
// ====================================================

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices_result[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client_devices;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList_agendaItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_client | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_itemList[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_createdBy | null;
  shareRequests: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_image | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_client | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices_result[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client_devices;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_notification | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items_image | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_items[];
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard_createdBy | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_flowchart | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_client | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_audio | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_video | null;
  time: any | null;
  alert: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_alert | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_timer | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_award | null;
  instantAwards: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_instantAwards[] | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_checklist | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_notification | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_moodMeter | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_agendaItems[] | null;
  shareRequests: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_shareRequests[] | null;
  selectionBoard: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem_selectionBoard | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices_result[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client_devices;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_image | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_client | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_itemList[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart_shareRequests[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices_result[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client_devices;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_image | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_client | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client | null;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_image | null;
  audio: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_audio | null;
  video: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_video | null;
  client: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_client | null;
  moodMeter: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_moodMeter | null;
  notification: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_notification | null;
  award: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_award | null;
  instantAwards: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_instantAwards[] | null;
  checklist: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_checklist | null;
  timer: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_timer | null;
  flowchart: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchart | null;
  flowchartItems: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_flowchartItems[] | null;
  createdBy: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem_shareRequests[] | null;
}

export type getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent = getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Agenda | getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_AgendaItem | getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_Flowchart | getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent_FlowchartItem;

export interface getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
  libraryContent: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById_libraryContent;
}

export interface getPendingLibraryContentShareRequestById {
  getPendingLibraryContentShareRequestById: getPendingLibraryContentShareRequestById_getPendingLibraryContentShareRequestById;
}

export interface getPendingLibraryContentShareRequestByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AtomicAsset
// ====================================================

export interface AtomicAsset {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Asset
// ====================================================

export interface Asset_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Asset {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Asset_createdBy | null;
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryContentShareRequest
// ====================================================

export interface LibraryContentShareRequest_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequest {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequest_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Device
// ====================================================

export interface Device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface Device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: Device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientChangeManagerRequest
// ====================================================

export interface ClientChangeManagerRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface ClientChangeManagerRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientChangeManagerRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: ClientChangeManagerRequest_createdBy | null;
  newManager: ClientChangeManagerRequest_newManager | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstantAward
// ====================================================

export interface InstantAward_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface InstantAward_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: InstantAward_image_createdBy | null;
  tags: string[];
}

export interface InstantAward {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: InstantAward_image | null;
  referenceCount: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetContent
// ====================================================

export interface AssetContent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AssetContent {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AssetContent_createdBy | null;
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SelectionBoardItem
// ====================================================

export interface SelectionBoardItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoardItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SelectionBoardItem_image_createdBy | null;
  tags: string[];
}

export interface SelectionBoardItem {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: SelectionBoardItem_image | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SelectionBoard
// ====================================================

export interface SelectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SelectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface SelectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: SelectionBoard_items_image | null;
}

export interface SelectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SelectionBoard_image_createdBy | null;
  tags: string[];
}

export interface SelectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: SelectionBoard_items[];
  image: SelectionBoard_image | null;
  disabledAt: any | null;
  createdBy: SelectionBoard_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SelectionBoardInfo
// ====================================================

export interface SelectionBoardInfo {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Client
// ====================================================

export interface Client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_avatar_createdBy | null;
  tags: string[];
}

export interface Client_introductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_introductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_introductionAudio_createdBy | null;
  tags: string[];
}

export interface Client_introductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_introductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_introductionVideo_createdBy | null;
  tags: string[];
}

export interface Client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface Client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: Client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface Client_devices {
  __typename: "DeviceList";
  result: Client_devices_result[];
}

export interface Client_defaultAgenda {
  __typename: "Agenda";
  id: string;
}

export interface Client_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface Client_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: Client_pendingClientManagerChangeRequest_createdBy | null;
  newManager: Client_pendingClientManagerChangeRequest_newManager | null;
}

export interface Client_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface Client_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: Client_instantAwards_result_image | null;
  referenceCount: number;
}

export interface Client_instantAwards {
  __typename: "InstantAwardList";
  result: Client_instantAwards_result[];
}

export interface Client_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface Client_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: Client_selectionBoards_result_items_image | null;
}

export interface Client_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Client_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface Client_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Client_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: Client_selectionBoards_result_items[];
  image: Client_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: Client_selectionBoards_result_createdBy | null;
}

export interface Client_selectionBoards {
  __typename: "SelectionBoardList";
  result: Client_selectionBoards_result[];
}

export interface Client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: Client_avatar | null;
  introductionAudio: Client_introductionAudio | null;
  introductionVideo: Client_introductionVideo | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   * Client devices
   */
  devices: Client_devices;
  lastSyncDataGeneratedAt: any | null;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  defaultAgenda: Client_defaultAgenda | null;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: Client_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  instantAwards: Client_instantAwards | null;
  selectionBoards: Client_selectionBoards | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContentClient
// ====================================================

export interface ContentClient_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface ContentClient_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: ContentClient_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface ContentClient_devices {
  __typename: "DeviceList";
  count: number;
  result: ContentClient_devices_result[];
}

export interface ContentClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: ContentClient_devices;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SupportedClient
// ====================================================

export interface SupportedClient_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupportedClient_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SupportedClient_avatar_createdBy | null;
  tags: string[];
}

export interface SupportedClient_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface SupportedClient_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface SupportedClient_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupportedClient_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: SupportedClient_pendingClientManagerChangeRequest_createdBy | null;
  newManager: SupportedClient_pendingClientManagerChangeRequest_newManager | null;
}

export interface SupportedClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: SupportedClient_avatar | null;
  defaultAgenda: SupportedClient_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: SupportedClient_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Supporter
// ====================================================

export interface Supporter {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
  name: string;
  activatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientSupporter
// ====================================================

export interface ClientSupporter_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface ClientSupporter_clientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
}

export interface ClientSupporter {
  __typename: "ClientSupporter";
  account: ClientSupporter_account | null;
  clientShareRequest: ClientSupporter_clientShareRequest | null;
  name: string | null;
  email: string;
  status: string;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoodMeterItem
// ====================================================

export interface MoodMeterItem {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoodMeter
// ====================================================

export interface MoodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface MoodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: MoodMeter_items[];
  question: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaConfig
// ====================================================

export interface AgendaConfig_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface AgendaConfig_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaConfig_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaConfig_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface AgendaConfig_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: AgendaConfig_withoutDefaultAgenda_image | null;
}

export interface AgendaConfig {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: AgendaConfig_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: AgendaConfig_withoutDefaultAgenda | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Unlock
// ====================================================

export interface Unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientConfig
// ====================================================

export interface ClientConfig_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface ClientConfig_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface ClientConfig_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientConfig_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientConfig_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface ClientConfig_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: ClientConfig_agenda_withoutDefaultAgenda_image | null;
}

export interface ClientConfig_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: ClientConfig_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: ClientConfig_agenda_withoutDefaultAgenda | null;
}

export interface ClientConfig_moodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface ClientConfig_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: ClientConfig_moodMeter_items[];
  question: string | null;
}

export interface ClientConfig_timer {
  __typename: "TimerConfig";
  type: TimerType;
  requestConfirmation: boolean;
}

export interface ClientConfig_award {
  __typename: "AwardConfig";
  requestConfirmation: boolean;
}

export interface ClientConfig_unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

export interface ClientConfig {
  __typename: "ClientConfig";
  enabledDashboardItems: DashboardItem[] | null;
  enabledGames: ClientConfig_enabledGames[] | null;
  mainPageType: MainPageType;
  mainPageBackgroundColor: any | null;
  mainPageDataSyncEnabled: boolean;
  agenda: ClientConfig_agenda | null;
  flowchartListType: FlowChartListType;
  flowchartItemBackgroundColor: any | null;
  moodMeter: ClientConfig_moodMeter | null;
  timer: ClientConfig_timer;
  selectionBoardListType: SelectionBoardListType;
  award: ClientConfig_award;
  unlock: ClientConfig_unlock;
  highContrastMode: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientAppConfig
// ====================================================

export interface ClientAppConfig_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface ClientAppConfig_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientAppConfig_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientAppConfig_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface ClientAppConfig_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: ClientAppConfig_agenda_withoutDefaultAgenda_image | null;
}

export interface ClientAppConfig_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: ClientAppConfig_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: ClientAppConfig_agenda_withoutDefaultAgenda | null;
}

export interface ClientAppConfig_unlock {
  __typename: "Unlock";
  enabled: boolean;
  colors: any[] | null;
  pattern: any[] | null;
  failMessage: string | null;
}

export interface ClientAppConfig {
  __typename: "ClientConfig";
  agenda: ClientAppConfig_agenda | null;
  flowchartItemBackgroundColor: any | null;
  unlock: ClientAppConfig_unlock;
  highContrastMode: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientMainPageConfig
// ====================================================

export interface ClientMainPageConfig_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface ClientMainPageConfig_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
}

export interface ClientMainPageConfig {
  __typename: "ClientConfig";
  mainPageType: MainPageType;
  mainPageBackgroundColor: any | null;
  mainPageDataSyncEnabled: boolean;
  enabledDashboardItems: DashboardItem[] | null;
  enabledGames: ClientMainPageConfig_enabledGames[] | null;
  moodMeter: ClientMainPageConfig_moodMeter | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientContentsConfig
// ====================================================

export interface ClientContentsConfig_agenda_colorsOfDays {
  __typename: "ColorsOfDays";
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface ClientContentsConfig_agenda_withoutDefaultAgenda_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientContentsConfig_agenda_withoutDefaultAgenda_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientContentsConfig_agenda_withoutDefaultAgenda_image_createdBy | null;
  tags: string[];
}

export interface ClientContentsConfig_agenda_withoutDefaultAgenda {
  __typename: "WithoutDefaultAgendaConfig";
  text: string | null;
  image: ClientContentsConfig_agenda_withoutDefaultAgenda_image | null;
}

export interface ClientContentsConfig_agenda {
  __typename: "AgendaConfig";
  itemBackgroundColor: any | null;
  colorsOfDays: ClientContentsConfig_agenda_colorsOfDays | null;
  itemCheckBoxSize: AgendaItemCheckBoxSize;
  itemSize: AgendaItemSize;
  calendarView: boolean;
  withoutDefaultAgenda: ClientContentsConfig_agenda_withoutDefaultAgenda | null;
}

export interface ClientContentsConfig {
  __typename: "ClientConfig";
  agenda: ClientContentsConfig_agenda | null;
  flowchartListType: FlowChartListType;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientFunctionsConfig
// ====================================================

export interface ClientFunctionsConfig_moodMeter_items {
  __typename: "MoodMeterItem";
  emotion: MoodMeterEmotion;
  name: string | null;
  position: number | null;
}

export interface ClientFunctionsConfig_moodMeter {
  __typename: "MoodMeter";
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  items: ClientFunctionsConfig_moodMeter_items[];
  question: string | null;
}

export interface ClientFunctionsConfig_timer {
  __typename: "TimerConfig";
  type: TimerType;
  requestConfirmation: boolean;
}

export interface ClientFunctionsConfig_award {
  __typename: "AwardConfig";
  requestConfirmation: boolean;
}

export interface ClientFunctionsConfig {
  __typename: "ClientConfig";
  moodMeter: ClientFunctionsConfig_moodMeter | null;
  timer: ClientFunctionsConfig_timer;
  selectionBoardListType: SelectionBoardListType;
  award: ClientFunctionsConfig_award;
  enabledDashboardItems: DashboardItem[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientShareRequestClient
// ====================================================

export interface ClientShareRequestClient_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientShareRequestClient_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientShareRequestClient_avatar_createdBy | null;
  tags: string[];
}

export interface ClientShareRequestClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: ClientShareRequestClient_avatar | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientShareRequest
// ====================================================

export interface ClientShareRequest_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientShareRequest_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientShareRequest_client_avatar_createdBy | null;
  tags: string[];
}

export interface ClientShareRequest_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: ClientShareRequest_client_avatar | null;
}

export interface ClientShareRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface ClientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
  client: ClientShareRequest_client;
  createdBy: ClientShareRequest_createdBy | null;
  status: RequestStatus;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Account
// ====================================================

export interface Account_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Account_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Account_avatar_createdBy | null;
  tags: string[];
}

export interface Account_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Account_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Account_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface Account_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface Account_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface Account_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Account_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: Account_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: Account_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface Account_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: Account_myClients_result_avatar | null;
  defaultAgenda: Account_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: Account_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface Account_myClients {
  __typename: "ClientList";
  result: Account_myClients_result[];
}

export interface Account_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Account_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Account_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface Account_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: Account_pendingClientShareRequests_result_client_avatar | null;
}

export interface Account_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface Account_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: Account_pendingClientShareRequests_result_client;
  createdBy: Account_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface Account_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: Account_pendingClientShareRequests_result[];
}

export interface Account_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface Account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: Account_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: Account_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: Account_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: Account_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StorageSizeUsed
// ====================================================

export interface StorageSizeUsed {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  storageSizeUsed: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthResponse
// ====================================================

export interface AuthResponse {
  __typename: "AuthResponse";
  /**
   * JWT auth token (short life)
   */
  authToken: string;
  /**
   * JWT refresh token (long life)
   */
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientQuestionnaireAnswerOption
// ====================================================

export interface ClientQuestionnaireAnswerOption {
  __typename: "ClientQuestionnaireAnswerOption";
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientQuestionnaireQuestion
// ====================================================

export interface ClientQuestionnaireQuestion_answerOptions {
  __typename: "ClientQuestionnaireAnswerOption";
  id: string;
  title: string;
}

export interface ClientQuestionnaireQuestion {
  __typename: "ClientQuestionnaireQuestion";
  id: string;
  title: string;
  description: string;
  answerOptions: ClientQuestionnaireQuestion_answerOptions[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientEventLog
// ====================================================

export interface ClientEventLog_device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface ClientEventLog_device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: ClientEventLog_device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface ClientEventLog {
  __typename: "AppStatItem";
  type: AppStatItemType;
  data: any | null;
  device: ClientEventLog_device | null;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetListItem
// ====================================================

export interface AssetListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AssetListItem {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  fileSize: number;
  assetType: AssetType;
  deletable: boolean;
  url: any;
  thumbnailUrl: any | null;
  createdAt: any;
  disabledAt: any | null;
  publishedAt: any | null;
  createdBy: AssetListItem_createdBy | null;
  tags: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminListItem
// ====================================================

export interface AdminListItem {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SupervisorListItem
// ====================================================

export interface SupervisorListItem {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SupporterListItem
// ====================================================

export interface SupporterListItem {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
  activatedAt: any | null;
  disabledAt: any | null;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientListItem
// ====================================================

export interface ClientListItem {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SupporterDetail
// ====================================================

export interface SupporterDetail_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupporterDetail_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SupporterDetail_avatar_createdBy | null;
  tags: string[];
}

export interface SupporterDetail_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupporterDetail_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SupporterDetail_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface SupporterDetail_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: SupporterDetail_pendingClientShareRequests_result_client_avatar | null;
}

export interface SupporterDetail_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface SupporterDetail_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: SupporterDetail_pendingClientShareRequests_result_client;
  createdBy: SupporterDetail_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface SupporterDetail_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: SupporterDetail_pendingClientShareRequests_result[];
}

export interface SupporterDetail_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupporterDetail_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SupporterDetail_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface SupporterDetail_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface SupporterDetail_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface SupporterDetail_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SupporterDetail_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: SupporterDetail_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: SupporterDetail_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface SupporterDetail_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: SupporterDetail_myClients_result_avatar | null;
  defaultAgenda: SupporterDetail_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: SupporterDetail_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface SupporterDetail_myClients {
  __typename: "ClientList";
  result: SupporterDetail_myClients_result[];
}

export interface SupporterDetail_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface SupporterDetail {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  pin: string | null;
  avatar: SupporterDetail_avatar | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: SupporterDetail_pendingClientShareRequests | null;
  /**
   * Clients supported by me
   */
  myClients: SupporterDetail_myClients | null;
  storageSizeUsed: any;
  config: SupporterDetail_config;
  disabledReason: string | null;
  activatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaListItem
// ====================================================

export interface AgendaListItem_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface AgendaListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AgendaListItem {
  __typename: "Agenda";
  id: string;
  title: string;
  itemList: AgendaListItem_itemList[] | null;
  createdBy: AgendaListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  day: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemListItem
// ====================================================

export interface AgendaItemListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemListItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemListItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemListItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemListItem_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemListItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemListItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemListItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AgendaItemListItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  image: AgendaItemListItem_image | null;
  audio: AgendaItemListItem_audio | null;
  video: AgendaItemListItem_video | null;
  createdBy: AgendaItemListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartListItem
// ====================================================

export interface FlowchartListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartListItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartListItem_itemList {
  __typename: "FlowchartItemFlowchartRel";
  position: number;
}

export interface FlowchartListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface FlowchartListItem {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartListItem_image | null;
  itemList: FlowchartListItem_itemList[] | null;
  createdBy: FlowchartListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartItemListItem
// ====================================================

export interface FlowchartItemListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemListItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemListItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemListItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemListItem_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemListItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemListItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemListItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface FlowchartItemListItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: FlowchartItemListItem_image | null;
  audio: FlowchartItemListItem_audio | null;
  video: FlowchartItemListItem_video | null;
  createdBy: FlowchartItemListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ChecklistItem
// ====================================================

export interface ChecklistItem {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Checklist
// ====================================================

export interface Checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Checklist_items[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemAlert
// ====================================================

export interface AgendaItemAlert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAlert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAlert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAlert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAlert_audio | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaContent
// ====================================================

export interface AgendaContent_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaContent_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface AgendaContent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaContent {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: AgendaContent_client | null;
  updatedAt: any | null;
  itemList: AgendaContent_itemList[] | null;
  createdBy: AgendaContent_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientProfileData
// ====================================================

export interface ClientProfileData_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_avatar_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_introductionAudio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_introductionAudio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_introductionAudio_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_introductionVideo_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_introductionVideo {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_introductionVideo_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface ClientProfileData_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: ClientProfileData_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface ClientProfileData_devices {
  __typename: "DeviceList";
  count: number;
  result: ClientProfileData_devices_result[];
}

export interface ClientProfileData_supporters_result_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface ClientProfileData_supporters_result_clientShareRequest {
  __typename: "ClientShareRequest";
  id: string;
}

export interface ClientProfileData_supporters_result {
  __typename: "ClientSupporter";
  account: ClientProfileData_supporters_result_account | null;
  clientShareRequest: ClientProfileData_supporters_result_clientShareRequest | null;
  name: string | null;
  email: string;
  status: string;
  createdAt: any;
}

export interface ClientProfileData_supporters {
  __typename: "ClientSupporterList";
  count: number;
  result: ClientProfileData_supporters_result[];
}

export interface ClientProfileData_managedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_defaultAgenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface ClientProfileData_defaultAgenda_itemList {
  __typename: "AgendaItemAgendaRel";
  position: number;
}

export interface ClientProfileData_defaultAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_defaultAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  client: ClientProfileData_defaultAgenda_client | null;
  updatedAt: any | null;
  itemList: ClientProfileData_defaultAgenda_itemList[] | null;
  createdBy: ClientProfileData_defaultAgenda_createdBy | null;
  day: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface ClientProfileData_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface ClientProfileData_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: ClientProfileData_pendingClientManagerChangeRequest_createdBy | null;
  newManager: ClientProfileData_pendingClientManagerChangeRequest_newManager | null;
}

export interface ClientProfileData_instantAwards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_instantAwards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_instantAwards_result_image_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_instantAwards_result {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: ClientProfileData_instantAwards_result_image | null;
  referenceCount: number;
}

export interface ClientProfileData_instantAwards {
  __typename: "InstantAwardList";
  result: ClientProfileData_instantAwards_result[];
}

export interface ClientProfileData_selectionBoards_result_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_selectionBoards_result_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_selectionBoards_result_items_image_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_selectionBoards_result_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: ClientProfileData_selectionBoards_result_items_image | null;
}

export interface ClientProfileData_selectionBoards_result_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_selectionBoards_result_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: ClientProfileData_selectionBoards_result_image_createdBy | null;
  tags: string[];
}

export interface ClientProfileData_selectionBoards_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ClientProfileData_selectionBoards_result {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: ClientProfileData_selectionBoards_result_items[];
  image: ClientProfileData_selectionBoards_result_image | null;
  disabledAt: any | null;
  createdBy: ClientProfileData_selectionBoards_result_createdBy | null;
}

export interface ClientProfileData_selectionBoards {
  __typename: "SelectionBoardList";
  result: ClientProfileData_selectionBoards_result[];
}

export interface ClientProfileData {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: ClientProfileData_avatar | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  introduction: string | null;
  introductionAudio: ClientProfileData_introductionAudio | null;
  introductionVideo: ClientProfileData_introductionVideo | null;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: ClientProfileData_devices;
  /**
   * Client supporters
   */
  supporters: ClientProfileData_supporters;
  /**
   * This account can modify main client datas (default value: created_by)
   */
  managedBy: ClientProfileData_managedBy;
  defaultAgenda: ClientProfileData_defaultAgenda | null;
  /**
   *  Client is supported by him/herself 
   */
  isSelfSupporting: boolean;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: ClientProfileData_pendingClientManagerChangeRequest | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
  awardScoreBalance: number;
  instantAwards: ClientProfileData_instantAwards | null;
  selectionBoards: ClientProfileData_selectionBoards | null;
  notificationAfterCreate: string;
  notificationOnProfilePage: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Timer
// ====================================================

export interface Timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ItemAward
// ====================================================

export interface ItemAward {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MoodMeterDisplayConfig
// ====================================================

export interface MoodMeterDisplayConfig {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Notification
// ====================================================

export interface Notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Notification_image_createdBy | null;
  tags: string[];
}

export interface Notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Notification_audio_createdBy | null;
  tags: string[];
}

export interface Notification {
  __typename: "Notification";
  text: string | null;
  image: Notification_image | null;
  audio: Notification_audio | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartItemContent
// ====================================================

export interface FlowchartItemContent_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItemContent_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemContent_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemContent_notification_image | null;
  audio: FlowchartItemContent_notification_audio | null;
}

export interface FlowchartItemContent_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemContent_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemContent_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemContent_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemContent_checklist_items[];
}

export interface FlowchartItemContent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemContent_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItemContent_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemContent_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemContent_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartItemContent_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItemContent_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartItemContent_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartItemContent_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItemContent_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartItemContent_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartItemContent_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartItemContent_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItemContent_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItemContent_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartItemContent_flowchart_image | null;
  itemList: FlowchartItemContent_flowchart_itemList[] | null;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemContent_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemContent_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItemContent_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemContent_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItemContent_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartItemContent_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartItemContent_flowchartItems_flowchartItem_image | null;
  video: FlowchartItemContent_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItemContent_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItemContent_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItemContent_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItemContent_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItemContent_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartItemContent_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartItemContent_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartItemContent_flowchartItems_flowchartItem;
}

export interface FlowchartItemContent {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartItemContent_client | null;
  image: FlowchartItemContent_image | null;
  video: FlowchartItemContent_video | null;
  moodMeter: FlowchartItemContent_moodMeter | null;
  notification: FlowchartItemContent_notification | null;
  award: FlowchartItemContent_award | null;
  timer: FlowchartItemContent_timer | null;
  checklist: FlowchartItemContent_checklist | null;
  createdBy: FlowchartItemContent_createdBy | null;
  flowchart: FlowchartItemContent_flowchart | null;
  flowchartItems: FlowchartItemContent_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartContent
// ====================================================

export interface FlowchartContent_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartContent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartContent_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartContent_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartContent_itemList_flowchartItem_notification_image | null;
  audio: FlowchartContent_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartContent_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartContent_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartContent_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartContent_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartContent_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartContent_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartContent_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartContent_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartContent_itemList_flowchartItem_flowchart_image | null;
  itemList: FlowchartContent_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartContent_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartContent_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface FlowchartContent_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartContent_itemList_flowchartItem_client | null;
  image: FlowchartContent_itemList_flowchartItem_image | null;
  video: FlowchartContent_itemList_flowchartItem_video | null;
  moodMeter: FlowchartContent_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartContent_itemList_flowchartItem_notification | null;
  award: FlowchartContent_itemList_flowchartItem_award | null;
  timer: FlowchartContent_itemList_flowchartItem_timer | null;
  checklist: FlowchartContent_itemList_flowchartItem_checklist | null;
  createdBy: FlowchartContent_itemList_flowchartItem_createdBy | null;
  flowchart: FlowchartContent_itemList_flowchartItem_flowchart | null;
  flowchartItems: FlowchartContent_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface FlowchartContent_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartContent_itemList_flowchartItem;
  position: number;
}

export interface FlowchartContent {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: FlowchartContent_image | null;
  client: FlowchartContent_client | null;
  createdBy: FlowchartContent_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: FlowchartContent_itemList[] | null;
  usableInSortingGame: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemContentChild
// ====================================================

export interface AgendaItemContentChild_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContentChild_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContentChild_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContentChild_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContentChild_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemContentChild_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemContentChild_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemContentChild_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemContentChild_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemContentChild_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContentChild_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContentChild_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContentChild_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContentChild_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContentChild_flowchart_image | null;
  itemList: AgendaItemContentChild_flowchart_itemList[] | null;
}

export interface AgendaItemContentChild_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContentChild_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContentChild_alert_audio | null;
}

export interface AgendaItemContentChild_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContentChild_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContentChild_notification_image | null;
  audio: AgendaItemContentChild_notification_audio | null;
}

export interface AgendaItemContentChild_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContentChild_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContentChild_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContentChild_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContentChild_checklist_items[];
}

export interface AgendaItemContentChild_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContentChild_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemContentChild_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContentChild_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContentChild_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContentChild_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemContentChild_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContentChild_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContentChild_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemContentChild_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemContentChild_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemContentChild_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemContentChild_agendaItems_agendaItem_timer | null;
  award: AgendaItemContentChild_agendaItems_agendaItem_award | null;
  checklist: AgendaItemContentChild_agendaItems_agendaItem_checklist | null;
  createdBy: AgendaItemContentChild_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemContentChild_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemContentChild_agendaItems_agendaItem;
}

export interface AgendaItemContentChild_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContentChild {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContentChild_image | null;
  video: AgendaItemContentChild_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContentChild_flowchart | null;
  client: AgendaItemContentChild_client | null;
  time: any | null;
  alert: AgendaItemContentChild_alert | null;
  moodMeter: AgendaItemContentChild_moodMeter | null;
  notification: AgendaItemContentChild_notification | null;
  timer: AgendaItemContentChild_timer | null;
  award: AgendaItemContentChild_award | null;
  checklist: AgendaItemContentChild_checklist | null;
  selectionBoard: AgendaItemContentChild_selectionBoard | null;
  agendaItems: AgendaItemContentChild_agendaItems[] | null;
  createdBy: AgendaItemContentChild_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemContent
// ====================================================

export interface AgendaItemContent_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContent_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContent_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemContent_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemContent_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemContent_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemContent_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemContent_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContent_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContent_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContent_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContent_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContent_flowchart_image | null;
  itemList: AgendaItemContent_flowchart_itemList[] | null;
}

export interface AgendaItemContent_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContent_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContent_alert_audio | null;
}

export interface AgendaItemContent_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_notification_image | null;
  audio: AgendaItemContent_notification_audio | null;
}

export interface AgendaItemContent_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_checklist_items[];
}

export interface AgendaItemContent_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemContent_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContent_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemContent_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemContent_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemContent_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemContent_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemContent_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemContent_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContent_agendaItems_agendaItem_image | null;
  video: AgendaItemContent_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContent_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemContent_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemContent_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemContent_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemContent_agendaItems_agendaItem_timer | null;
  award: AgendaItemContent_agendaItems_agendaItem_award | null;
  checklist: AgendaItemContent_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemContent_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemContent_agendaItems_agendaItem_agendaItems[] | null;
  notification: AgendaItemContent_agendaItems_agendaItem_notification | null;
  createdBy: AgendaItemContent_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemContent_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemContent_agendaItems_agendaItem;
}

export interface AgendaItemContent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemContent {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemContent_image | null;
  video: AgendaItemContent_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemContent_flowchart | null;
  client: AgendaItemContent_client | null;
  time: any | null;
  alert: AgendaItemContent_alert | null;
  moodMeter: AgendaItemContent_moodMeter | null;
  notification: AgendaItemContent_notification | null;
  timer: AgendaItemContent_timer | null;
  award: AgendaItemContent_award | null;
  checklist: AgendaItemContent_checklist | null;
  selectionBoard: AgendaItemContent_selectionBoard | null;
  agendaItems: AgendaItemContent_agendaItems[] | null;
  createdBy: AgendaItemContent_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemAgendaItemRel
// ====================================================

export interface AgendaItemAgendaItemRel_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaItemRel_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaItemRel_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaItemRel_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaItemRel_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaItemRel_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaItemRel_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaItemRel_agendaItem_image | null;
  video: AgendaItemAgendaItemRel_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaItemRel_agendaItem_flowchart | null;
  client: AgendaItemAgendaItemRel_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaItemRel_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaItemRel_agendaItem_moodMeter | null;
  notification: AgendaItemAgendaItemRel_agendaItem_notification | null;
  timer: AgendaItemAgendaItemRel_agendaItem_timer | null;
  award: AgendaItemAgendaItemRel_agendaItem_award | null;
  checklist: AgendaItemAgendaItemRel_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaItemRel_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaItemRel_agendaItem_agendaItems[] | null;
  createdBy: AgendaItemAgendaItemRel_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaItemRel {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: AgendaItemAgendaItemRel_agendaItem;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItem
// ====================================================

export interface AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface AgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: AgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: AgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: AgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: AgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: AgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: AgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: AgendaItem_flowchart_image | null;
  client: AgendaItem_flowchart_client | null;
  createdBy: AgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: AgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface AgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface AgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: AgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface AgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: AgendaItem_client_devices_result[];
}

export interface AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: AgendaItem_client_devices;
}

export interface AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItem_alert_audio | null;
}

export interface AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: AgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_checklist_items[];
}

export interface AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_notification_image | null;
  audio: AgendaItem_notification_audio | null;
}

export interface AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: AgendaItem_agendaItems_agendaItem_notification | null;
  timer: AgendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: AgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface AgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface AgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: AgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface AgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: AgendaItem_selectionBoard_items_image | null;
}

export interface AgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface AgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: AgendaItem_selectionBoard_items[];
  image: AgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: AgendaItem_selectionBoard_createdBy | null;
}

export interface AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItem_flowchart | null;
  client: AgendaItem_client | null;
  image: AgendaItem_image | null;
  audio: AgendaItem_audio | null;
  video: AgendaItem_video | null;
  time: any | null;
  alert: AgendaItem_alert | null;
  timer: AgendaItem_timer | null;
  award: AgendaItem_award | null;
  instantAwards: AgendaItem_instantAwards[] | null;
  checklist: AgendaItem_checklist | null;
  notification: AgendaItem_notification | null;
  moodMeter: AgendaItem_moodMeter | null;
  createdBy: AgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: AgendaItem_agendaItems[] | null;
  shareRequests: AgendaItem_shareRequests[] | null;
  selectionBoard: AgendaItem_selectionBoard | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NamedColor
// ====================================================

export interface NamedColor {
  __typename: "NamedColor";
  name: string;
  value: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllowedUploadContentTypes
// ====================================================

export interface AllowedUploadContentTypes {
  __typename: "AllowedUploadContentTypes";
  image: string[];
  audio: string[];
  video: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllowedUploadFileSizes
// ====================================================

export interface AllowedUploadFileSizes {
  __typename: "AllowedUploadFileSizes";
  image: number;
  audio: number;
  video: number;
  educationDocument: number;
  educationVideo: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppStatItemTypeTitle
// ====================================================

export interface AppStatItemTypeTitle {
  __typename: "AppStatItemTypeTitle";
  key: AppStatItemType;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppEventLogNameTitle
// ====================================================

export interface AppEventLogNameTitle {
  __typename: "AppEventLogNameTitle";
  key: AppEventLogName;
  title: string;
  accessibility: AccountType[];
  category: AppEventLogCategoryName;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppEventLogCategoryNameTitle
// ====================================================

export interface AppEventLogCategoryNameTitle {
  __typename: "AppEventLogCategoryNameTitle";
  key: AppEventLogCategoryName;
  title: string;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CmsSettings
// ====================================================

export interface CmsSettings_unlockColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface CmsSettings_backgroundColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface CmsSettings_itemBackgroundColors {
  __typename: "NamedColor";
  name: string;
  value: any;
}

export interface CmsSettings_allowedUploadContentTypes {
  __typename: "AllowedUploadContentTypes";
  image: string[];
  audio: string[];
  video: string[];
}

export interface CmsSettings_allowedUploadFileSizes {
  __typename: "AllowedUploadFileSizes";
  image: number;
  audio: number;
  video: number;
  educationDocument: number;
  educationVideo: number;
}

export interface CmsSettings_appStatItemTypeTitles {
  __typename: "AppStatItemTypeTitle";
  key: AppStatItemType;
  title: string;
}

export interface CmsSettings_appEventLogNameTitles {
  __typename: "AppEventLogNameTitle";
  key: AppEventLogName;
  title: string;
  accessibility: AccountType[];
  category: AppEventLogCategoryName;
}

export interface CmsSettings_appEventLogCategoryNameTitles {
  __typename: "AppEventLogCategoryNameTitle";
  key: AppEventLogCategoryName;
  title: string;
  position: number;
}

export interface CmsSettings {
  __typename: "CmsSettings";
  unlockColors: CmsSettings_unlockColors[];
  backgroundColors: CmsSettings_backgroundColors[];
  itemBackgroundColors: CmsSettings_itemBackgroundColors[];
  allowedUploadContentTypes: CmsSettings_allowedUploadContentTypes;
  allowedUploadFileSizes: CmsSettings_allowedUploadFileSizes;
  appStatItemTypeTitles: CmsSettings_appStatItemTypeTitles[];
  appEventLogNameTitles: CmsSettings_appEventLogNameTitles[];
  appEventLogCategoryNameTitles: CmsSettings_appEventLogCategoryNameTitles[];
  clientEverydaySituationDirectoryDefaultImageId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartItemFlowchartRel
// ====================================================

export interface FlowchartItemFlowchartRel_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartRel_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartRel_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartRel_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartRel_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartRel_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartItemFlowchartRel_flowchartItem_flowchart_image | null;
  itemList: FlowchartItemFlowchartRel_flowchartItem_flowchart_itemList[] | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartItemFlowchartRel_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartItemFlowchartRel_flowchartItem_flowchartItems_flowchartItem;
}

export interface FlowchartItemFlowchartRel_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartItemFlowchartRel_flowchartItem_client | null;
  image: FlowchartItemFlowchartRel_flowchartItem_image | null;
  video: FlowchartItemFlowchartRel_flowchartItem_video | null;
  moodMeter: FlowchartItemFlowchartRel_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartRel_flowchartItem_notification | null;
  award: FlowchartItemFlowchartRel_flowchartItem_award | null;
  timer: FlowchartItemFlowchartRel_flowchartItem_timer | null;
  checklist: FlowchartItemFlowchartRel_flowchartItem_checklist | null;
  createdBy: FlowchartItemFlowchartRel_flowchartItem_createdBy | null;
  flowchart: FlowchartItemFlowchartRel_flowchartItem_flowchart | null;
  flowchartItems: FlowchartItemFlowchartRel_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface FlowchartItemFlowchartRel {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: FlowchartItemFlowchartRel_flowchartItem;
  position: number;
  branch: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Flowchart
// ====================================================

export interface Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface Flowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: Flowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface Flowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: Flowchart_client_devices_result[];
}

export interface Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: Flowchart_client_devices;
}

export interface Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Flowchart_itemList_flowchartItem_notification_image | null;
  audio: Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: Flowchart_itemList_flowchartItem_client | null;
  image: Flowchart_itemList_flowchartItem_image | null;
  video: Flowchart_itemList_flowchartItem_video | null;
  moodMeter: Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: Flowchart_itemList_flowchartItem_notification | null;
  award: Flowchart_itemList_flowchartItem_award | null;
  timer: Flowchart_itemList_flowchartItem_timer | null;
  checklist: Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: Flowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface Flowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface Flowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: Flowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: Flowchart_image | null;
  client: Flowchart_client | null;
  itemList: Flowchart_itemList[] | null;
  createdBy: Flowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: Flowchart_shareRequests[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartItemFlowchartItemRel
// ====================================================

export interface FlowchartItemFlowchartItemRel_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartItemRel_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartItemRel_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartItemRel_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_image | null;
  itemList: FlowchartItemFlowchartItemRel_flowchartItem_flowchart_itemList[] | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems_flowchartItem;
}

export interface FlowchartItemFlowchartItemRel_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartItemFlowchartItemRel_flowchartItem_client | null;
  image: FlowchartItemFlowchartItemRel_flowchartItem_image | null;
  video: FlowchartItemFlowchartItemRel_flowchartItem_video | null;
  moodMeter: FlowchartItemFlowchartItemRel_flowchartItem_moodMeter | null;
  notification: FlowchartItemFlowchartItemRel_flowchartItem_notification | null;
  award: FlowchartItemFlowchartItemRel_flowchartItem_award | null;
  timer: FlowchartItemFlowchartItemRel_flowchartItem_timer | null;
  checklist: FlowchartItemFlowchartItemRel_flowchartItem_checklist | null;
  createdBy: FlowchartItemFlowchartItemRel_flowchartItem_createdBy | null;
  flowchart: FlowchartItemFlowchartItemRel_flowchartItem_flowchart | null;
  flowchartItems: FlowchartItemFlowchartItemRel_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface FlowchartItemFlowchartItemRel {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: FlowchartItemFlowchartItemRel_flowchartItem;
  position: number;
  branch: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FlowchartItem
// ====================================================

export interface FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface FlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: FlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface FlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: FlowchartItem_client_devices_result[];
}

export interface FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: FlowchartItem_client_devices;
}

export interface FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_notification_image | null;
  audio: FlowchartItem_notification_audio | null;
}

export interface FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: FlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_checklist_items[];
}

export interface FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: FlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: FlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: FlowchartItem_flowchart_image | null;
  client: FlowchartItem_flowchart_client | null;
  createdBy: FlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: FlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface FlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: FlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface FlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: FlowchartItem_flowchartItems_flowchartItem_client | null;
  image: FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: FlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: FlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: FlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface FlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface FlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: FlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: FlowchartItem_image | null;
  audio: FlowchartItem_audio | null;
  video: FlowchartItem_video | null;
  client: FlowchartItem_client | null;
  moodMeter: FlowchartItem_moodMeter | null;
  notification: FlowchartItem_notification | null;
  award: FlowchartItem_award | null;
  instantAwards: FlowchartItem_instantAwards[] | null;
  checklist: FlowchartItem_checklist | null;
  timer: FlowchartItem_timer | null;
  flowchart: FlowchartItem_flowchart | null;
  flowchartItems: FlowchartItem_flowchartItems[] | null;
  createdBy: FlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: FlowchartItem_shareRequests[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AgendaItemAgendaRel
// ====================================================

export interface AgendaItemAgendaRel_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaRel_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaRel_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaRel_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaRel_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaRel_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_timer | null;
  award: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_award | null;
  checklist: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaRel_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: AgendaItemAgendaRel_agendaItem_agendaItems_agendaItem;
}

export interface AgendaItemAgendaRel_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AgendaItemAgendaRel_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: AgendaItemAgendaRel_agendaItem_image | null;
  video: AgendaItemAgendaRel_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: AgendaItemAgendaRel_agendaItem_flowchart | null;
  client: AgendaItemAgendaRel_agendaItem_client | null;
  time: any | null;
  alert: AgendaItemAgendaRel_agendaItem_alert | null;
  moodMeter: AgendaItemAgendaRel_agendaItem_moodMeter | null;
  notification: AgendaItemAgendaRel_agendaItem_notification | null;
  timer: AgendaItemAgendaRel_agendaItem_timer | null;
  award: AgendaItemAgendaRel_agendaItem_award | null;
  checklist: AgendaItemAgendaRel_agendaItem_checklist | null;
  selectionBoard: AgendaItemAgendaRel_agendaItem_selectionBoard | null;
  agendaItems: AgendaItemAgendaRel_agendaItem_agendaItems[] | null;
  createdBy: AgendaItemAgendaRel_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface AgendaItemAgendaRel {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: AgendaItemAgendaRel_agendaItem;
  position: number;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Agenda
// ====================================================

export interface Agenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface Agenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: Agenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface Agenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: Agenda_client_devices_result[];
}

export interface Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: Agenda_client_devices;
}

export interface Agenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface Agenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface Agenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_flowchart_image | null;
  itemList: Agenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface Agenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface Agenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: Agenda_itemList_agendaItem_alert_audio | null;
}

export interface Agenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: Agenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: Agenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: Agenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: Agenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: Agenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: Agenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: Agenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface Agenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: Agenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface Agenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Agenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: Agenda_itemList_agendaItem_image | null;
  video: Agenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: Agenda_itemList_agendaItem_flowchart | null;
  client: Agenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: Agenda_itemList_agendaItem_alert | null;
  moodMeter: Agenda_itemList_agendaItem_moodMeter | null;
  notification: Agenda_itemList_agendaItem_notification | null;
  timer: Agenda_itemList_agendaItem_timer | null;
  award: Agenda_itemList_agendaItem_award | null;
  checklist: Agenda_itemList_agendaItem_checklist | null;
  selectionBoard: Agenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: Agenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: Agenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: Agenda_itemList_agendaItem;
  position: number;
}

export interface Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface Agenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface Agenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: Agenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: Agenda_client | null;
  itemList: Agenda_itemList[] | null;
  createdBy: Agenda_createdBy | null;
  shareRequests: Agenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CalendarAgenda
// ====================================================

export interface CalendarAgenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface CalendarAgenda {
  __typename: "Agenda";
  id: string;
  title: string;
  day: any | null;
  disabledAt: any | null;
  createdBy: CalendarAgenda_createdBy | null;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppEventLog
// ====================================================

export interface AppEventLog_entity_client {
  __typename: "AppEventLogClientEntity";
  id: string;
  extId: string;
}

export interface AppEventLog_entity {
  __typename: "AppEventLogEntity";
  client: AppEventLog_entity_client | null;
}

export interface AppEventLog_request_account {
  __typename: "AppEventLogAccountEntity";
  id: string | null;
  extId: string | null;
}

export interface AppEventLog_request {
  __typename: "AppEventLogRequest";
  account: AppEventLog_request_account | null;
}

export interface AppEventLog {
  __typename: "AppEventLog";
  event: AppEventLogName;
  eventTitle: string;
  timestamp: any;
  entity: AppEventLog_entity | null;
  entityTitle: string | null;
  request: AppEventLog_request | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Tag
// ====================================================

export interface Tag {
  __typename: "Tag";
  id: string;
  title: string;
  category: TagCategory;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminTag
// ====================================================

export interface AdminTag_createdBy_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AdminTag_createdBy_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AdminTag_createdBy_avatar_createdBy | null;
  tags: string[];
}

export interface AdminTag_createdBy_myClients_result_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AdminTag_createdBy_myClients_result_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AdminTag_createdBy_myClients_result_avatar_createdBy | null;
  tags: string[];
}

export interface AdminTag_createdBy_myClients_result_defaultAgenda {
  __typename: "Agenda";
  id: string;
  updatedAt: any | null;
}

export interface AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest {
  __typename: "ClientChangeManagerRequest";
  id: string;
  createdBy: AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest_createdBy | null;
  newManager: AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest_newManager | null;
}

export interface AdminTag_createdBy_myClients_result {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  avatar: AdminTag_createdBy_myClients_result_avatar | null;
  defaultAgenda: AdminTag_createdBy_myClients_result_defaultAgenda | null;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  /**
   * Pending client change manager request
   */
  pendingClientManagerChangeRequest: AdminTag_createdBy_myClients_result_pendingClientManagerChangeRequest | null;
  awardScoreBalance: number;
  lastSyncDataGeneratedAt: any | null;
  notificationAfterCreate: string;
  showSelfSupportingStateDecisionNotification: boolean | null;
  showSelfSupportingStateDecisionWarning: boolean | null;
  showClientQuestionnaireEvaluationNotification: boolean | null;
}

export interface AdminTag_createdBy_myClients {
  __typename: "ClientList";
  result: AdminTag_createdBy_myClients_result[];
}

export interface AdminTag_createdBy_pendingClientShareRequests_result_client_avatar_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AdminTag_createdBy_pendingClientShareRequests_result_client_avatar {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AdminTag_createdBy_pendingClientShareRequests_result_client_avatar_createdBy | null;
  tags: string[];
}

export interface AdminTag_createdBy_pendingClientShareRequests_result_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  /**
   *  Supported person's date of birth 
   */
  dateOfBirth: any;
  avatar: AdminTag_createdBy_pendingClientShareRequests_result_client_avatar | null;
}

export interface AdminTag_createdBy_pendingClientShareRequests_result_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface AdminTag_createdBy_pendingClientShareRequests_result {
  __typename: "ClientShareRequest";
  id: string;
  client: AdminTag_createdBy_pendingClientShareRequests_result_client;
  createdBy: AdminTag_createdBy_pendingClientShareRequests_result_createdBy | null;
  status: RequestStatus;
}

export interface AdminTag_createdBy_pendingClientShareRequests {
  __typename: "ClientShareRequestList";
  count: number;
  result: AdminTag_createdBy_pendingClientShareRequests_result[];
}

export interface AdminTag_createdBy_config {
  __typename: "AccountConfig";
  storageSizeLimit: any | null;
}

export interface AdminTag_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
  /**
   * Primary e-mail address
   */
  email: any;
  pin: string | null;
  /**
   * Account(user) type
   */
  accountType: AccountType;
  avatar: AdminTag_createdBy_avatar | null;
  /**
   * Clients supported by me
   */
  myClients: AdminTag_createdBy_myClients | null;
  /**
   * Pending clients share requests
   */
  pendingClientShareRequests: AdminTag_createdBy_pendingClientShareRequests | null;
  storageSizeUsed: any;
  config: AdminTag_createdBy_config;
  disabledReason: string | null;
  disabledAt: any | null;
  activatedAt: any | null;
}

export interface AdminTag {
  __typename: "Tag";
  id: string;
  title: string;
  category: TagCategory;
  createdBy: AdminTag_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClientGamesConfig
// ====================================================

export interface ClientGamesConfig_enabledGames {
  __typename: "ClientGame";
  name: Game;
}

export interface ClientGamesConfig {
  __typename: "ClientConfig";
  enabledGames: ClientGamesConfig_enabledGames[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MemoryGameConfig
// ====================================================

export interface MemoryGameConfig_images_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface MemoryGameConfig_images {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: MemoryGameConfig_images_createdBy | null;
  tags: string[];
}

export interface MemoryGameConfig {
  __typename: "MemoryGameConfig";
  numberOfPairs: number;
  images: MemoryGameConfig_images[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextEverydaySituation
// ====================================================

export interface TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: TextEverydaySituation_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImageEverydaySituation
// ====================================================

export interface ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: ImageEverydaySituation_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TextAndImageEverydaySituation
// ====================================================

export interface TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: TextAndImageEverydaySituation_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AudioEverydaySituation
// ====================================================

export interface AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: AudioEverydaySituation_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EverydaySituationClientDirectory
// ====================================================

export interface EverydaySituationClientDirectory_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationClientDirectory_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationClientDirectory_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationClientDirectory_situations_TextEverydaySituation_createdBy | null;
}

export interface EverydaySituationClientDirectory_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationClientDirectory_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationClientDirectory_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: EverydaySituationClientDirectory_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationClientDirectory_situations_ImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationClientDirectory_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationClientDirectory_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationClientDirectory_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: EverydaySituationClientDirectory_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationClientDirectory_situations_AudioEverydaySituation_createdBy | null;
}

export type EverydaySituationClientDirectory_situations = EverydaySituationClientDirectory_situations_TextEverydaySituation | EverydaySituationClientDirectory_situations_ImageEverydaySituation | EverydaySituationClientDirectory_situations_TextAndImageEverydaySituation | EverydaySituationClientDirectory_situations_AudioEverydaySituation;

export interface EverydaySituationClientDirectory {
  __typename: "EverydaySituationClientDirectory";
  id: string;
  title: string;
  isVisible: boolean;
  isWritable: boolean;
  image: EverydaySituationClientDirectory_image;
  situations: EverydaySituationClientDirectory_situations[];
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EverydaySituation
// ====================================================

export interface EverydaySituation_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituation_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituation_TextEverydaySituation_createdBy | null;
}

export interface EverydaySituation_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituation_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituation_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: EverydaySituation_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituation_ImageEverydaySituation_createdBy | null;
}

export interface EverydaySituation_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituation_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituation_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: EverydaySituation_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituation_TextAndImageEverydaySituation_createdBy | null;
}

export interface EverydaySituation_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituation_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituation_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: EverydaySituation_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituation_AudioEverydaySituation_createdBy | null;
}

export type EverydaySituation = EverydaySituation_TextEverydaySituation | EverydaySituation_ImageEverydaySituation | EverydaySituation_TextAndImageEverydaySituation | EverydaySituation_AudioEverydaySituation;

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EverydaySituationDirectory
// ====================================================

export interface EverydaySituationDirectory_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectory_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectory_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectory_situations_TextEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectory_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectory_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectory_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: EverydaySituationDirectory_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectory_situations_ImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectory_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectory_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: EverydaySituationDirectory_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectory_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectory_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectory_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectory_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: EverydaySituationDirectory_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectory_situations_AudioEverydaySituation_createdBy | null;
}

export type EverydaySituationDirectory_situations = EverydaySituationDirectory_situations_TextEverydaySituation | EverydaySituationDirectory_situations_ImageEverydaySituation | EverydaySituationDirectory_situations_TextAndImageEverydaySituation | EverydaySituationDirectory_situations_AudioEverydaySituation;

export interface EverydaySituationDirectory_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: EverydaySituationDirectory_image;
  situations: EverydaySituationDirectory_situations[];
  createdBy: EverydaySituationDirectory_createdBy | null;
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EverydaySituationDirectoryList
// ====================================================

export interface EverydaySituationDirectoryList_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: EverydaySituationDirectoryList_image_createdBy | null;
  tags: string[];
}

export interface EverydaySituationDirectoryList_situations_TextEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList_situations_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  description: string;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectoryList_situations_TextEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectoryList_situations_ImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectoryList_situations_ImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList_situations_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  image: EverydaySituationDirectoryList_situations_ImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectoryList_situations_ImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  description: string;
  image: EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation_image;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation_createdBy | null;
}

export interface EverydaySituationDirectoryList_situations_AudioEverydaySituation_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectoryList_situations_AudioEverydaySituation_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList_situations_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audio: EverydaySituationDirectoryList_situations_AudioEverydaySituation_audio;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectoryList_situations_AudioEverydaySituation_createdBy | null;
}

export type EverydaySituationDirectoryList_situations = EverydaySituationDirectoryList_situations_TextEverydaySituation | EverydaySituationDirectoryList_situations_ImageEverydaySituation | EverydaySituationDirectoryList_situations_TextAndImageEverydaySituation | EverydaySituationDirectoryList_situations_AudioEverydaySituation;

export interface EverydaySituationDirectoryList_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface EverydaySituationDirectoryList {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: EverydaySituationDirectoryList_image;
  situations: EverydaySituationDirectoryList_situations[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  createdBy: EverydaySituationDirectoryList_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EverydaySituationDirectoryWithItemCount
// ====================================================

export interface EverydaySituationDirectoryWithItemCount_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  originalFileName: string;
  assetType: AssetType;
}

export interface EverydaySituationDirectoryWithItemCount_itemCount {
  __typename: "EverydaySituationItemCount";
  text: number;
  audio: number;
  image: number;
}

export interface EverydaySituationDirectoryWithItemCount {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  position: number | null;
  image: EverydaySituationDirectoryWithItemCount_image;
  itemCount: EverydaySituationDirectoryWithItemCount_itemCount;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Faq
// ====================================================

export interface Faq_createdBy {
  __typename: "Account";
  name: string;
}

export interface Faq {
  __typename: "FaqItem";
  id: string;
  question: string;
  answer: string;
  createdBy: Faq_createdBy | null;
  updatedAt: any | null;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectoryItem
// ====================================================

export interface AssetDirectoryItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AssetDirectoryItem {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: AssetDirectoryItem_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectory
// ====================================================

export interface AssetDirectory_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AssetDirectory_children_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AssetDirectory_children {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: AssetDirectory_children_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface AssetDirectory_parent_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AssetDirectory_parent {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: AssetDirectory_parent_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
}

export interface AssetDirectory {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  createdBy: AssetDirectory_createdBy | null;
  createdAt: any;
  publishedAt: any | null;
  children: AssetDirectory_children[] | null;
  parent: AssetDirectory_parent | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssetDirectoryData
// ====================================================

export interface AssetDirectoryData {
  __typename: "AssetDirectory";
  id: string;
  name: string;
  deletable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Award
// ====================================================

export interface Award_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface Award_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: Award_image_createdBy | null;
  tags: string[];
}

export interface Award {
  __typename: "Award";
  id: string;
  title: string;
  autoReactivation: boolean;
  isActive: boolean;
  targetScore: number;
  image: Award_image | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DisabledPublicContentList
// ====================================================

export interface DisabledPublicContentList_result_Agenda_agendaClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  createdAt: any;
  agendaClient: DisabledPublicContentList_result_Agenda_agendaClient | null;
}

export interface DisabledPublicContentList_result_AgendaItem_agendaItemClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_AgendaItem_agendaItemImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_AgendaItem_agendaItemVideo {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  createdAt: any;
  agendaItemClient: DisabledPublicContentList_result_AgendaItem_agendaItemClient | null;
  agendaItemImage: DisabledPublicContentList_result_AgendaItem_agendaItemImage | null;
  agendaItemVideo: DisabledPublicContentList_result_AgendaItem_agendaItemVideo | null;
}

export interface DisabledPublicContentList_result_Flowchart_flowchartClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_Flowchart_flowchartImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  createdAt: any;
  flowchartClient: DisabledPublicContentList_result_Flowchart_flowchartClient | null;
  flowchartImage: DisabledPublicContentList_result_Flowchart_flowchartImage | null;
}

export interface DisabledPublicContentList_result_FlowchartItem_flowchartItemClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_FlowchartItem_flowchartItemImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_FlowchartItem_flowchartItemVideo {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  createdAt: any;
  flowchartItemClient: DisabledPublicContentList_result_FlowchartItem_flowchartItemClient | null;
  flowchartItemImage: DisabledPublicContentList_result_FlowchartItem_flowchartItemImage | null;
  flowchartItemVideo: DisabledPublicContentList_result_FlowchartItem_flowchartItemVideo | null;
}

export interface DisabledPublicContentList_result_Asset {
  __typename: "Asset";
  id: string;
  title: string;
  createdAt: any;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
}

export interface DisabledPublicContentList_result_Award_awardClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_Award_awardImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_Award {
  __typename: "Award";
  id: string;
  title: string;
  createdAt: any;
  awardClient: DisabledPublicContentList_result_Award_awardClient;
  awardImage: DisabledPublicContentList_result_Award_awardImage | null;
}

export interface DisabledPublicContentList_result_InstantAward_instantAwardClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_InstantAward_instantAwardImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_InstantAward {
  __typename: "InstantAward";
  id: string;
  title: string;
  createdAt: any;
  instantAwardClient: DisabledPublicContentList_result_InstantAward_instantAwardClient;
  instantAwardImage: DisabledPublicContentList_result_InstantAward_instantAwardImage | null;
}

export interface DisabledPublicContentList_result_TextEverydaySituation_textEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_TextEverydaySituation_textEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface DisabledPublicContentList_result_TextEverydaySituation {
  __typename: "TextEverydaySituation";
  id: string;
  title: string;
  textEverydaySituationClient: DisabledPublicContentList_result_TextEverydaySituation_textEverydaySituationClient;
  textEverydaySituationDirectory: DisabledPublicContentList_result_TextEverydaySituation_textEverydaySituationDirectory;
  createdAt: any;
}

export interface DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface DisabledPublicContentList_result_ImageEverydaySituation {
  __typename: "ImageEverydaySituation";
  id: string;
  title: string;
  createdAt: any;
  imageEverydaySituationClient: DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationClient;
  imageEverydaySituationImage: DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationImage;
  imageEverydaySituationDirectory: DisabledPublicContentList_result_ImageEverydaySituation_imageEverydaySituationDirectory;
}

export interface DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface DisabledPublicContentList_result_TextAndImageEverydaySituation {
  __typename: "TextAndImageEverydaySituation";
  id: string;
  title: string;
  createdAt: any;
  textAndImageEverydaySituationClient: DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationClient;
  textAndImageEverydaySituationImage: DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationImage;
  textAndImageEverydaySituationDirectory: DisabledPublicContentList_result_TextAndImageEverydaySituation_textAndImageEverydaySituationDirectory;
}

export interface DisabledPublicContentList_result_AudioEverydaySituation_audioEverydaySituationClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_AudioEverydaySituation_audioEverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
}

export interface DisabledPublicContentList_result_AudioEverydaySituation {
  __typename: "AudioEverydaySituation";
  id: string;
  title: string;
  audioEverydaySituationClient: DisabledPublicContentList_result_AudioEverydaySituation_audioEverydaySituationClient;
  createdAt: any;
  audioEverydaySituationDirectory: DisabledPublicContentList_result_AudioEverydaySituation_audioEverydaySituationDirectory;
}

export interface DisabledPublicContentList_result_EverydaySituationDirectory_everydaySituationDirectoryClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_EverydaySituationDirectory_everydaySituationDirectoryImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_EverydaySituationDirectory {
  __typename: "EverydaySituationDirectory";
  id: string;
  title: string;
  createdAt: any;
  everydaySituationDirectoryClient: DisabledPublicContentList_result_EverydaySituationDirectory_everydaySituationDirectoryClient;
  everydaySituationDirectoryImage: DisabledPublicContentList_result_EverydaySituationDirectory_everydaySituationDirectoryImage;
}

export interface DisabledPublicContentList_result_SelectionBoard_selectionClient {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface DisabledPublicContentList_result_SelectionBoard_selectionImage {
  __typename: "Asset";
  id: string;
  url: any;
  thumbnailUrl: any | null;
}

export interface DisabledPublicContentList_result_SelectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  createdAt: any;
  selectionClient: DisabledPublicContentList_result_SelectionBoard_selectionClient;
  selectionImage: DisabledPublicContentList_result_SelectionBoard_selectionImage | null;
}

export type DisabledPublicContentList_result = DisabledPublicContentList_result_Agenda | DisabledPublicContentList_result_AgendaItem | DisabledPublicContentList_result_Flowchart | DisabledPublicContentList_result_FlowchartItem | DisabledPublicContentList_result_Asset | DisabledPublicContentList_result_Award | DisabledPublicContentList_result_InstantAward | DisabledPublicContentList_result_TextEverydaySituation | DisabledPublicContentList_result_ImageEverydaySituation | DisabledPublicContentList_result_TextAndImageEverydaySituation | DisabledPublicContentList_result_AudioEverydaySituation | DisabledPublicContentList_result_EverydaySituationDirectory | DisabledPublicContentList_result_SelectionBoard;

export interface DisabledPublicContentList {
  __typename: "DisabledContentList";
  count: number;
  result: DisabledPublicContentList_result[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationContentModuleListItem
// ====================================================

export interface EducationContentModuleListItem_subModules {
  __typename: "EducationContentModule";
  id: string;
}

export interface EducationContentModuleListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface EducationContentModuleListItem {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  subModules: EducationContentModuleListItem_subModules[] | null;
  createdBy: EducationContentModuleListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationContent
// ====================================================

export interface EducationContent {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationContentSubModule
// ====================================================

export interface EducationContentSubModule_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentSubModule_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentSubModule_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface EducationContentSubModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: EducationContentSubModule_videoContents[];
  documentContents: EducationContentSubModule_documentContents[];
  createdBy: EducationContentSubModule_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationContentModule
// ====================================================

export interface EducationContentModule_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentModule_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentModule_subModules_videoContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentModule_subModules_documentContents {
  __typename: "EducationContent";
  id: string;
  type: EducationContentType;
  title: string;
  etag: string;
  url: any;
  thumbnail: any;
  mimeType: any;
  size: number;
  position: number | null;
  createdAt: any;
}

export interface EducationContentModule_subModules_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface EducationContentModule_subModules {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: EducationContentModule_subModules_videoContents[];
  documentContents: EducationContentModule_subModules_documentContents[];
  createdBy: EducationContentModule_subModules_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface EducationContentModule_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Account(user) type
   */
  accountType: AccountType;
}

export interface EducationContentModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  videoContents: EducationContentModule_videoContents[];
  documentContents: EducationContentModule_documentContents[];
  subModules: EducationContentModule_subModules[] | null;
  createdBy: EducationContentModule_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationContentModuleTab
// ====================================================

export interface EducationContentModuleTab {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationDocument
// ====================================================

export interface EducationDocument {
  __typename: "EducationContent";
  id: string;
  title: string;
  url: any;
  thumbnail: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationVideo
// ====================================================

export interface EducationVideo {
  __typename: "EducationContent";
  id: string;
  title: string;
  url: any;
  thumbnail: any;
  position: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EducationSubModule
// ====================================================

export interface EducationSubModule_documentContents {
  __typename: "EducationContent";
  id: string;
  title: string;
  url: any;
  thumbnail: any;
}

export interface EducationSubModule_videoContents {
  __typename: "EducationContent";
  id: string;
  title: string;
  url: any;
  thumbnail: any;
  position: number | null;
}

export interface EducationSubModule {
  __typename: "EducationContentModule";
  id: string;
  title: string;
  position: number | null;
  documentContents: EducationSubModule_documentContents[];
  videoContents: EducationSubModule_videoContents[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstantAwardListItem
// ====================================================

export interface InstantAwardListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface InstantAwardListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: InstantAwardListItem_image_createdBy | null;
  tags: string[];
}

export interface InstantAwardListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface InstantAwardListItem {
  __typename: "InstantAward";
  id: string;
  referenceCount: number;
  title: string;
  image: InstantAwardListItem_image | null;
  createdBy: InstantAwardListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AwardListItem
// ====================================================

export interface AwardListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface AwardListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: AwardListItem_image_createdBy | null;
  tags: string[];
}

export interface AwardListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface AwardListItem {
  __typename: "Award";
  id: string;
  title: string;
  image: AwardListItem_image | null;
  targetScore: number;
  createdBy: AwardListItem_createdBy | null;
  isActive: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SelectionBoardListItem
// ====================================================

export interface SelectionBoardListItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoardListItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SelectionBoardListItem_image_createdBy | null;
  tags: string[];
}

export interface SelectionBoardListItem_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface SelectionBoardListItem_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: SelectionBoardListItem_items_image_createdBy | null;
  tags: string[];
}

export interface SelectionBoardListItem_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: SelectionBoardListItem_items_image | null;
}

export interface SelectionBoardListItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
}

export interface SelectionBoardListItem {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  image: SelectionBoardListItem_image | null;
  items: SelectionBoardListItem_items[];
  createdBy: SelectionBoardListItem_createdBy | null;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Aggregations
// ====================================================

export interface Aggregations_timeAggregations_dateAggregation {
  __typename: "DateAggregation";
  date: any;
  count: number;
}

export interface Aggregations_timeAggregations_hourAggregation {
  __typename: "HourAggregation";
  hour: number;
  count: number;
}

export interface Aggregations_timeAggregations_weekAggregation {
  __typename: "WeekAggregation";
  week: number;
  count: number;
}

export interface Aggregations_timeAggregations {
  __typename: "TimeAggregations";
  dateAggregation: Aggregations_timeAggregations_dateAggregation[] | null;
  hourAggregation: Aggregations_timeAggregations_hourAggregation[] | null;
  weekAggregation: Aggregations_timeAggregations_weekAggregation[] | null;
}

export interface Aggregations {
  __typename: "Aggregations";
  timeAggregations: Aggregations_timeAggregations;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppStatItem
// ====================================================

export interface AppStatItem_device_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface AppStatItem_device {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: AppStatItem_device_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface AppStatItem_account {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface AppStatItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface AppStatItem {
  __typename: "AppStatItem";
  type: AppStatItemType;
  typeTitle: string;
  data: any | null;
  device: AppStatItem_device | null;
  account: AppStatItem_account | null;
  client: AppStatItem_client | null;
  createdAt: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryContentShareRequestListItem
// ====================================================

export interface LibraryContentShareRequestListItem_requestCreatedBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices_result[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestListItem_libraryContent_Agenda_client_devices;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_notification | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList_agendaItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestListItem_libraryContent_Agenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: LibraryContentShareRequestListItem_libraryContent_Agenda_client | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Agenda_itemList[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Agenda_createdBy | null;
  shareRequests: LibraryContentShareRequestListItem_libraryContent_Agenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_image | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_client | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices_result[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestListItem_libraryContent_AgendaItem_client_devices;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestListItem_libraryContent_AgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items_image | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_items[];
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard_createdBy | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_AgendaItem_flowchart | null;
  client: LibraryContentShareRequestListItem_libraryContent_AgendaItem_client | null;
  image: LibraryContentShareRequestListItem_libraryContent_AgendaItem_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_AgendaItem_audio | null;
  video: LibraryContentShareRequestListItem_libraryContent_AgendaItem_video | null;
  time: any | null;
  alert: LibraryContentShareRequestListItem_libraryContent_AgendaItem_alert | null;
  timer: LibraryContentShareRequestListItem_libraryContent_AgendaItem_timer | null;
  award: LibraryContentShareRequestListItem_libraryContent_AgendaItem_award | null;
  instantAwards: LibraryContentShareRequestListItem_libraryContent_AgendaItem_instantAwards[] | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_AgendaItem_checklist | null;
  notification: LibraryContentShareRequestListItem_libraryContent_AgendaItem_notification | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_AgendaItem_moodMeter | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_AgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: LibraryContentShareRequestListItem_libraryContent_AgendaItem_agendaItems[] | null;
  shareRequests: LibraryContentShareRequestListItem_libraryContent_AgendaItem_shareRequests[] | null;
  selectionBoard: LibraryContentShareRequestListItem_libraryContent_AgendaItem_selectionBoard | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices_result[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestListItem_libraryContent_Flowchart_client_devices;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestListItem_libraryContent_Flowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_Flowchart_image | null;
  client: LibraryContentShareRequestListItem_libraryContent_Flowchart_client | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_Flowchart_itemList[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_Flowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: LibraryContentShareRequestListItem_libraryContent_Flowchart_shareRequests[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices_result[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client_devices;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_image | null;
  client: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_client | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client | null;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestListItem_libraryContent_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_image | null;
  audio: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_audio | null;
  video: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_video | null;
  client: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_client | null;
  moodMeter: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_notification | null;
  award: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_award | null;
  instantAwards: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_instantAwards[] | null;
  checklist: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_checklist | null;
  timer: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_timer | null;
  flowchart: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_flowchartItems[] | null;
  createdBy: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: LibraryContentShareRequestListItem_libraryContent_FlowchartItem_shareRequests[] | null;
}

export type LibraryContentShareRequestListItem_libraryContent = LibraryContentShareRequestListItem_libraryContent_Agenda | LibraryContentShareRequestListItem_libraryContent_AgendaItem | LibraryContentShareRequestListItem_libraryContent_Flowchart | LibraryContentShareRequestListItem_libraryContent_FlowchartItem;

export interface LibraryContentShareRequestListItem {
  __typename: "LibraryContentShareRequestListItem";
  requestId: string;
  requestCreatedBy: LibraryContentShareRequestListItem_requestCreatedBy | null;
  requestCreatedAt: any;
  requestStatus: RequestStatus;
  requestComment: string | null;
  libraryContent: LibraryContentShareRequestListItem_libraryContent;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryContentShareRequestWithData
// ====================================================

export interface LibraryContentShareRequestWithData_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices_result[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestWithData_libraryContent_Agenda_client_devices;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_notification | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_itemList {
  __typename: "AgendaItemAgendaRel";
  id: string;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList_agendaItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestWithData_libraryContent_Agenda_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_Agenda {
  __typename: "Agenda";
  id: string;
  title: string;
  description: string | null;
  day: any | null;
  client: LibraryContentShareRequestWithData_libraryContent_Agenda_client | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Agenda_itemList[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Agenda_createdBy | null;
  shareRequests: LibraryContentShareRequestWithData_libraryContent_Agenda_shareRequests[] | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  disabledAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_image | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_client | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices_result[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestWithData_libraryContent_AgendaItem_client_devices;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards_image | null;
  referenceCount: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  name: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_image | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_award | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_notification | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_moodMeter | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert {
  __typename: "AgendaItemAlert";
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audioChecked: boolean;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_moodMeter | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_agendaItems[] | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_notification | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  position: number;
  agendaItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems_agendaItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_video | null;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_client | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_alert | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_notification | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_award | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_checklist | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_selectionBoard | null;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_agendaItems[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem_createdBy | null;
  disabledAt: any | null;
  referenceCount: number;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems {
  __typename: "AgendaItemAgendaItemRel";
  agendaItem: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems_agendaItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestWithData_libraryContent_AgendaItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items {
  __typename: "SelectionBoardItem";
  id: string;
  title: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items_image | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard {
  __typename: "SelectionBoard";
  id: string;
  title: string;
  backgroundColor: any;
  itemCount: number;
  items: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_items[];
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_image | null;
  disabledAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard_createdBy | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_AgendaItem {
  __typename: "AgendaItem";
  id: string;
  title: string;
  type: AgendaItemType;
  content: string | null;
  isCancelled: boolean | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_AgendaItem_flowchart | null;
  client: LibraryContentShareRequestWithData_libraryContent_AgendaItem_client | null;
  image: LibraryContentShareRequestWithData_libraryContent_AgendaItem_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_AgendaItem_audio | null;
  video: LibraryContentShareRequestWithData_libraryContent_AgendaItem_video | null;
  time: any | null;
  alert: LibraryContentShareRequestWithData_libraryContent_AgendaItem_alert | null;
  timer: LibraryContentShareRequestWithData_libraryContent_AgendaItem_timer | null;
  award: LibraryContentShareRequestWithData_libraryContent_AgendaItem_award | null;
  instantAwards: LibraryContentShareRequestWithData_libraryContent_AgendaItem_instantAwards[] | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_AgendaItem_checklist | null;
  notification: LibraryContentShareRequestWithData_libraryContent_AgendaItem_notification | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_AgendaItem_moodMeter | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_AgendaItem_createdBy | null;
  tags: string[];
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  referenceCount: number;
  agendaItems: LibraryContentShareRequestWithData_libraryContent_AgendaItem_agendaItems[] | null;
  shareRequests: LibraryContentShareRequestWithData_libraryContent_AgendaItem_shareRequests[] | null;
  selectionBoard: LibraryContentShareRequestWithData_libraryContent_AgendaItem_selectionBoard | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices_result[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestWithData_libraryContent_Flowchart_client_devices;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  id: string;
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList_flowchartItem;
  position: number;
  branch: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestWithData_libraryContent_Flowchart_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_Flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_Flowchart_image | null;
  client: LibraryContentShareRequestWithData_libraryContent_Flowchart_client | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_Flowchart_itemList[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_Flowchart_createdBy | null;
  usableInSortingGame: boolean;
  createdAt: any;
  updatedAt: any | null;
  disabledAt: any | null;
  publishedAt: any | null;
  tags: string[];
  shareRequests: LibraryContentShareRequestWithData_libraryContent_Flowchart_shareRequests[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_audio {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices_result_info {
  __typename: "DeviceInfo";
  userAgent: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices_result {
  __typename: "Device";
  id: string;
  deviceType: DeviceType;
  info: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices_result_info;
  agendaDataLastDownloadedAt: any | null;
  clientDataLastDownloadedAt: any | null;
  createdAt: any;
  updatedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices {
  __typename: "DeviceList";
  count: number;
  result: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices_result[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
  /**
   *  Full or nickname of supported person 
   */
  name: string;
  lastSyncDataGeneratedAt: any | null;
  /**
   * Client devices
   */
  devices: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client_devices;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards {
  __typename: "InstantAward";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards_image | null;
  referenceCount: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_client | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  description: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_image | null;
  client: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_client | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_createdBy | null;
  disabledAt: any | null;
  publishedAt: any | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart_itemList[] | null;
  usableInSortingGame: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client {
  __typename: "Client";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_video | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_award | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_notification | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem_timer | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList {
  __typename: "FlowchartItemFlowchartRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList_flowchartItem;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_image | null;
  itemList: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart_itemList[] | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video {
  __typename: "Asset";
  id: string;
  title: string;
  originalFileName: string;
  url: any;
  thumbnailUrl: any | null;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter {
  __typename: "MoodMeterDisplayCongfig";
  display: MoodMeterDisplay;
  title: string | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio {
  __typename: "Asset";
  id: string;
  title: string;
  url: any;
  thumbnailUrl: any | null;
  originalFileName: string;
  assetType: AssetType;
  disabledAt: any | null;
  deletable: boolean;
  publishedAt: any | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio_createdBy | null;
  tags: string[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification {
  __typename: "Notification";
  text: string | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification_audio | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award {
  __typename: "ItemAward";
  score: number | null;
  type: ItemAwardType;
  useTimer: boolean | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer {
  __typename: "Timer";
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items {
  __typename: "ChecklistItem";
  id: string;
  title: string;
  position: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist {
  __typename: "Checklist";
  id: string;
  title: string;
  items: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist_items[];
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart {
  __typename: "Flowchart";
  id: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_checklist | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem_flowchart | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  position: number;
  branch: number;
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems_flowchartItem;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  client: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_client | null;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_image | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_video | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_award | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_timer | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_checklist | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_createdBy | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem_flowchartItems[] | null;
  disabledAt: any | null;
  publishedAt: any | null;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems {
  __typename: "FlowchartItemFlowchartItemRel";
  flowchartItem: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems_flowchartItem;
  position: number;
  branch: number;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_createdBy {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  name: string;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_shareRequests_sharedWithAccount {
  __typename: "Account";
  /**
   * Unique user identifier
   */
  id: string;
  /**
   * Unique anonymous identifier
   */
  extId: string | null;
  /**
   * Primary e-mail address
   */
  email: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem_shareRequests {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_shareRequests_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
}

export interface LibraryContentShareRequestWithData_libraryContent_FlowchartItem {
  __typename: "FlowchartItem";
  id: string;
  flowchartType: FlowchartItemType;
  title: string;
  image: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_image | null;
  audio: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_audio | null;
  video: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_video | null;
  client: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_client | null;
  moodMeter: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_moodMeter | null;
  notification: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_notification | null;
  award: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_award | null;
  instantAwards: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_instantAwards[] | null;
  checklist: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_checklist | null;
  timer: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_timer | null;
  flowchart: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchart | null;
  flowchartItems: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_flowchartItems[] | null;
  createdBy: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_createdBy | null;
  tags: string[];
  disabledAt: any | null;
  createdAt: any;
  updatedAt: any | null;
  publishedAt: any | null;
  shareRequests: LibraryContentShareRequestWithData_libraryContent_FlowchartItem_shareRequests[] | null;
}

export type LibraryContentShareRequestWithData_libraryContent = LibraryContentShareRequestWithData_libraryContent_Agenda | LibraryContentShareRequestWithData_libraryContent_AgendaItem | LibraryContentShareRequestWithData_libraryContent_Flowchart | LibraryContentShareRequestWithData_libraryContent_FlowchartItem;

export interface LibraryContentShareRequestWithData {
  __typename: "LibraryContentShareRequest";
  id: string;
  sharedWithAccount: LibraryContentShareRequestWithData_sharedWithAccount | null;
  comment: string | null;
  status: RequestStatus;
  createdAt: any;
  libraryContent: LibraryContentShareRequestWithData_libraryContent;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountSortField {
  activatedAt = "activatedAt",
  createdAt = "createdAt",
  disabledAt = "disabledAt",
  email = "email",
  extId = "extId",
  id = "id",
  name = "name",
}

export enum AccountType {
  admin = "admin",
  superadmin = "superadmin",
  supervisor = "supervisor",
  supporter = "supporter",
}

export enum AgendaItemAlertDisplayType {
  After = "After",
  Before = "Before",
  Time = "Time",
}

export enum AgendaItemCheckBoxSize {
  large = "large",
  small = "small",
}

export enum AgendaItemSize {
  large = "large",
  medium = "medium",
  small = "small",
}

export enum AgendaItemTextVisibility {
  normal = "normal",
  reduced = "reduced",
}

export enum AgendaItemType {
  Select = "Select",
  Simple = "Simple",
}

export enum AppEventLogCategoryName {
  account = "account",
  agenda = "agenda",
  asset = "asset",
  auth = "auth",
  award = "award",
  client = "client",
  educationContent = "educationContent",
  everydaySituation = "everydaySituation",
  faq = "faq",
  flowchart = "flowchart",
  game = "game",
  libraryContentShare = "libraryContentShare",
  mail = "mail",
  oauth = "oauth",
  selectionBoard = "selectionBoard",
  tag = "tag",
}

export enum AppEventLogListSortField {
  timestamp = "timestamp",
}

export enum AppEventLogName {
  acceptClientChangeManagerRequest = "acceptClientChangeManagerRequest",
  acceptClientShareRequest = "acceptClientShareRequest",
  acceptLibraryContentPrivateShareRequest = "acceptLibraryContentPrivateShareRequest",
  acceptLibraryContentPublicShareRequest = "acceptLibraryContentPublicShareRequest",
  activateAccount = "activateAccount",
  activateAward = "activateAward",
  changeEmailByCode = "changeEmailByCode",
  changeEmailRequest = "changeEmailRequest",
  changePassword = "changePassword",
  clientProfileChange = "clientProfileChange",
  clientQuestionnaireEvaluation = "clientQuestionnaireEvaluation",
  copyAgendaItemToAccountPersonalLibrary = "copyAgendaItemToAccountPersonalLibrary",
  copyAgendaItemToClientPersonalLibrary = "copyAgendaItemToClientPersonalLibrary",
  copyAgendaItemToPublicLibrary = "copyAgendaItemToPublicLibrary",
  copyAgendaToAccountPersonalLibrary = "copyAgendaToAccountPersonalLibrary",
  copyAgendaToClientAgendaLibrary = "copyAgendaToClientAgendaLibrary",
  copyAgendaToClientPersonalLibrary = "copyAgendaToClientPersonalLibrary",
  copyAgendaToPublicLibrary = "copyAgendaToPublicLibrary",
  copyAssetToAccountPersonalLibrary = "copyAssetToAccountPersonalLibrary",
  copyAssetToPublicLibrary = "copyAssetToPublicLibrary",
  copyFlowchartItemToAccountPersonalLibrary = "copyFlowchartItemToAccountPersonalLibrary",
  copyFlowchartItemToClientLibrary = "copyFlowchartItemToClientLibrary",
  copyFlowchartItemToPublicLibrary = "copyFlowchartItemToPublicLibrary",
  copyFlowchartToAccountPersonalLibrary = "copyFlowchartToAccountPersonalLibrary",
  copyFlowchartToClientLibrary = "copyFlowchartToClientLibrary",
  copyFlowchartToPublicLibrary = "copyFlowchartToPublicLibrary",
  createAccount = "createAccount",
  createAgenda = "createAgenda",
  createAgendaItem = "createAgendaItem",
  createAgendaSyncData = "createAgendaSyncData",
  createAward = "createAward",
  createClient = "createClient",
  createClientChangeManagerRequest = "createClientChangeManagerRequest",
  createEducationContent = "createEducationContent",
  createEducationContentModule = "createEducationContentModule",
  createEverydaySituation = "createEverydaySituation",
  createEverydaySituationByClient = "createEverydaySituationByClient",
  createEverydaySituationDirectory = "createEverydaySituationDirectory",
  createFaqItem = "createFaqItem",
  createFlowchart = "createFlowchart",
  createFlowchartItem = "createFlowchartItem",
  createInstantAward = "createInstantAward",
  createOAuthClient = "createOAuthClient",
  createPersonalAssetDirectory = "createPersonalAssetDirectory",
  createPublicAssetDirectory = "createPublicAssetDirectory",
  createSelectionBoard = "createSelectionBoard",
  createTag = "createTag",
  deleteAccount = "deleteAccount",
  deleteAccountAvatar = "deleteAccountAvatar",
  deleteAgenda = "deleteAgenda",
  deleteAgendaItem = "deleteAgendaItem",
  deleteAsset = "deleteAsset",
  deleteAward = "deleteAward",
  deleteClient = "deleteClient",
  deleteClientAvatar = "deleteClientAvatar",
  deleteClientChangeManagerRequest = "deleteClientChangeManagerRequest",
  deleteClientIntroductionAudio = "deleteClientIntroductionAudio",
  deleteClientIntroductionVideo = "deleteClientIntroductionVideo",
  deleteClientShareRequest = "deleteClientShareRequest",
  deleteEducationContent = "deleteEducationContent",
  deleteEducationContentModule = "deleteEducationContentModule",
  deleteEverydaySituation = "deleteEverydaySituation",
  deleteEverydaySituationDirectory = "deleteEverydaySituationDirectory",
  deleteFaqItem = "deleteFaqItem",
  deleteFlowchart = "deleteFlowchart",
  deleteFlowchartItem = "deleteFlowchartItem",
  deleteInstantAward = "deleteInstantAward",
  deleteOAuthClient = "deleteOAuthClient",
  deletePersonalAssetDirectory = "deletePersonalAssetDirectory",
  deletePublicAssetDirectory = "deletePublicAssetDirectory",
  deleteSelectionBoard = "deleteSelectionBoard",
  deleteTag = "deleteTag",
  disableAccount = "disableAccount",
  disableAgenda = "disableAgenda",
  disableAgendaItem = "disableAgendaItem",
  disableAsset = "disableAsset",
  disableAward = "disableAward",
  disableEverydaySituation = "disableEverydaySituation",
  disableEverydaySituationDirectory = "disableEverydaySituationDirectory",
  disableFlowchart = "disableFlowchart",
  disableFlowchartItem = "disableFlowchartItem",
  disableInstantAward = "disableInstantAward",
  disableOAuthClient = "disableOAuthClient",
  disableSelectionBoard = "disableSelectionBoard",
  enableAccount = "enableAccount",
  enableAgenda = "enableAgenda",
  enableAgendaItem = "enableAgendaItem",
  enableAsset = "enableAsset",
  enableAward = "enableAward",
  enableEverydaySituation = "enableEverydaySituation",
  enableEverydaySituationDirectory = "enableEverydaySituationDirectory",
  enableFlowchart = "enableFlowchart",
  enableFlowchartItem = "enableFlowchartItem",
  enableInstantAward = "enableInstantAward",
  enableOAuthClient = "enableOAuthClient",
  enableSelectionBoard = "enableSelectionBoard",
  forgotPassword = "forgotPassword",
  login = "login",
  logout = "logout",
  moveAssetsToPersonalAssetDirectory = "moveAssetsToPersonalAssetDirectory",
  moveAssetsToPublicAssetDirectory = "moveAssetsToPublicAssetDirectory",
  orderEducationContentModules = "orderEducationContentModules",
  orderEducationContents = "orderEducationContents",
  orderEverydaySituationDirectories = "orderEverydaySituationDirectories",
  orderFaqItems = "orderFaqItems",
  orderSelectionBoards = "orderSelectionBoards",
  refreshToken = "refreshToken",
  rejectClientChangeManagerRequest = "rejectClientChangeManagerRequest",
  rejectClientShareRequest = "rejectClientShareRequest",
  rejectLibraryContentPrivateShareRequest = "rejectLibraryContentPrivateShareRequest",
  rejectLibraryContentPublicShareRequest = "rejectLibraryContentPublicShareRequest",
  replaceAsset = "replaceAsset",
  resendActivationCode = "resendActivationCode",
  resetPassword = "resetPassword",
  revokeOAuthToken = "revokeOAuthToken",
  sendEmail = "sendEmail",
  setDefaultAgenda = "setDefaultAgenda",
  setSelfSupportingStateDecision = "setSelfSupportingStateDecision",
  shareClient = "shareClient",
  shareLibraryContent = "shareLibraryContent",
  shareLibraryContentToPublic = "shareLibraryContentToPublic",
  unlinkSupporter = "unlinkSupporter",
  unsetDefaultAgenda = "unsetDefaultAgenda",
  updateAccount = "updateAccount",
  updateAgenda = "updateAgenda",
  updateAgendaItem = "updateAgendaItem",
  updateAsset = "updateAsset",
  updateAward = "updateAward",
  updateClient = "updateClient",
  updateClientConfig = "updateClientConfig",
  updateClientMemoryGameConfig = "updateClientMemoryGameConfig",
  updateClientSortingGameConfig = "updateClientSortingGameConfig",
  updateClientSpareAgendaItems = "updateClientSpareAgendaItems",
  updateEducationContent = "updateEducationContent",
  updateEducationContentModule = "updateEducationContentModule",
  updateEverydaySituation = "updateEverydaySituation",
  updateEverydaySituationDirectory = "updateEverydaySituationDirectory",
  updateFaqItem = "updateFaqItem",
  updateFlowchart = "updateFlowchart",
  updateFlowchartItem = "updateFlowchartItem",
  updateInstantAward = "updateInstantAward",
  updateOAuthClient = "updateOAuthClient",
  updatePersonalAssetDirectory = "updatePersonalAssetDirectory",
  updatePublicAssetDirectory = "updatePublicAssetDirectory",
  updateSelectionBoard = "updateSelectionBoard",
  uploadAccountAvatar = "uploadAccountAvatar",
  uploadAsset = "uploadAsset",
  uploadClientAvatar = "uploadClientAvatar",
  uploadClientIntroductionAudio = "uploadClientIntroductionAudio",
  uploadClientIntroductionVideo = "uploadClientIntroductionVideo",
}

export enum AppStatItemListSortField {
  createdAt = "createdAt",
  indexedAt = "indexedAt",
  type = "type",
}

export enum AppStatItemType {
  AccountLogin = "AccountLogin",
  AgendaItemStateChange = "AgendaItemStateChange",
  Alert = "Alert",
  AppBackground = "AppBackground",
  AppForeground = "AppForeground",
  AppReset = "AppReset",
  AppUnlock = "AppUnlock",
  AwardAchieved = "AwardAchieved",
  CheckFlowchartItem = "CheckFlowchartItem",
  CheckListItemStateChange = "CheckListItemStateChange",
  ClientSetup = "ClientSetup",
  CreateSituation = "CreateSituation",
  DeviceStat = "DeviceStat",
  DownloadAccountData = "DownloadAccountData",
  DownloadAgendaData = "DownloadAgendaData",
  DownloadClientData = "DownloadClientData",
  InstantAwardAchieved = "InstantAwardAchieved",
  MoodReport = "MoodReport",
  ScoreChanged = "ScoreChanged",
  UploadPushToken = "UploadPushToken",
  UploadStats = "UploadStats",
  UseAgenda = "UseAgenda",
  UseChecklist = "UseChecklist",
  UseEducation = "UseEducation",
  UseEverydaySituation = "UseEverydaySituation",
  UseFlowchart = "UseFlowchart",
  UseGame = "UseGame",
  UseIntroduction = "UseIntroduction",
  UseMoodMeter = "UseMoodMeter",
  UseNotification = "UseNotification",
  UseSelectionBoard = "UseSelectionBoard",
  UseTimer = "UseTimer",
  UseVolumeMeter = "UseVolumeMeter",
  UseWaiting = "UseWaiting",
}

export enum AssetType {
  audio = "audio",
  avatar = "avatar",
  image = "image",
  introduction_audio = "introduction_audio",
  introduction_video = "introduction_video",
  video = "video",
}

export enum AwardListSortField {
  createdAt = "createdAt",
  title = "title",
  updatedAt = "updatedAt",
}

export enum ClientListSortField {
  createdAt = "createdAt",
  dateOfBirth = "dateOfBirth",
  extId = "extId",
  name = "name",
  updatedAt = "updatedAt",
}

export enum ContentLayout {
  BigPictureTextBottom = "BigPictureTextBottom",
  BigPictureTextTop = "BigPictureTextTop",
  OnlyPicture = "OnlyPicture",
  OnlyText = "OnlyText",
  SmallPictureTextBottom = "SmallPictureTextBottom",
  SmallPictureTextTop = "SmallPictureTextTop",
}

export enum DashboardItem {
  agenda = "agenda",
  awards = "awards",
  education = "education",
  flowchart = "flowchart",
  games = "games",
  moodMeter = "moodMeter",
  selectionBoards = "selectionBoards",
  situations = "situations",
  timer = "timer",
  volumeMeter = "volumeMeter",
  waiting = "waiting",
}

export enum DeviceType {
  android = "android",
  browser = "browser",
  ios = "ios",
}

export enum DisabledContentListSortField {
  createdAt = "createdAt",
  title = "title",
  updatedAt = "updatedAt",
}

export enum EducationContentType {
  normal = "normal",
  special = "special",
}

export enum EverydaySituationDirectorySortField {
  createdAt = "createdAt",
  title = "title",
  updatedAt = "updatedAt",
}

export enum EverydaySituationType {
  audio = "audio",
  image = "image",
  text = "text",
  text_and_image = "text_and_image",
}

export enum FlowChartListType {
  card = "card",
  list = "list",
}

export enum FlowchartItemType {
  Select = "Select",
  Simple = "Simple",
}

export enum Game {
  bubbles = "bubbles",
  drawing = "drawing",
  memory = "memory",
  sorting = "sorting",
  spinner = "spinner",
}

export enum InstantAwardListSortField {
  createdAt = "createdAt",
  title = "title",
  updatedAt = "updatedAt",
}

export enum ItemAwardType {
  instant = "instant",
  score = "score",
}

export enum LibraryContentShareRequestSortField {
  libraryContentCreatedAt = "libraryContentCreatedAt",
  libraryContentTitle = "libraryContentTitle",
  libraryContentUpdatedAt = "libraryContentUpdatedAt",
}

export enum MainPageType {
  normal = "normal",
  simple = "simple",
}

export enum MoodMeterDisplay {
  After = "After",
  Before = "Before",
}

export enum MoodMeterEmotion {
  Anger = "Anger",
  Boredom = "Boredom",
  Calmness = "Calmness",
  Constraint = "Constraint",
  Disappointment = "Disappointment",
  Disgust = "Disgust",
  Excitement = "Excitement",
  Fatigue = "Fatigue",
  Fear = "Fear",
  Happiness = "Happiness",
  Indifference = "Indifference",
  Loathing = "Loathing",
  Pain = "Pain",
  Pride = "Pride",
  Puzzlement = "Puzzlement",
  Sadness = "Sadness",
  Shame = "Shame",
  Surprise = "Surprise",
  Worry = "Worry",
}

export enum RequestStatus {
  accepted = "accepted",
  pending = "pending",
  rejected = "rejected",
}

export enum SearchListSortField {
  createdAt = "createdAt",
  day = "day",
  fileSize = "fileSize",
  title = "title",
  updatedAt = "updatedAt",
}

export enum SearchListType {
  agenda = "agenda",
  agendaItem = "agendaItem",
  asset = "asset",
  flowchart = "flowchart",
  flowchartItem = "flowchartItem",
}

export enum SelectionBoardListSortField {
  createdAt = "createdAt",
  title = "title",
  updatedAt = "updatedAt",
}

export enum SelectionBoardListType {
  card = "card",
  list = "list",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TagCategory {
  by_action = "by_action",
  by_age = "by_age",
  by_detail = "by_detail",
  by_form = "by_form",
  by_function = "by_function",
  by_gender = "by_gender",
  by_user = "by_user",
}

export enum TagListSortField {
  category = "category",
  createdAt = "createdAt",
  title = "title",
}

export enum TimerType {
  decreasing = "decreasing",
  sandGlass = "sandGlass",
  timer = "timer",
}

export interface AccountListOptionsInput {
  sortField?: AccountSortField | null;
  control?: ListControl | null;
}

export interface AgendaConfigInput {
  itemBackgroundColor?: any | null;
  colorsOfDays?: ColorsOfDaysInput | null;
  itemCheckBoxSize?: AgendaItemCheckBoxSize | null;
  itemSize?: AgendaItemSize | null;
  itemTextVisibility?: AgendaItemTextVisibility | null;
  calendarView?: boolean | null;
  withoutDefaultAgenda?: WithoutDefaultAgendaConfigInput | null;
}

export interface AgendaInput {
  title?: string | null;
  description?: string | null;
  clientId?: string | null;
  agendaItems?: AgendaItemsInput[] | null;
  tags?: string[] | null;
}

export interface AgendaItemAlertInput {
  title: string;
  vibrate: boolean;
  flash: boolean;
  display: AgendaItemAlertDisplayType;
  requestInteraction: boolean;
  audio: boolean;
  audioAssetId?: string | null;
}

export interface AgendaItemInput {
  type?: AgendaItemType | null;
  title?: string | null;
  content?: string | null;
  contentLayout?: ContentLayout | null;
  isCancelled?: boolean | null;
  imageAssetId?: string | null;
  audioAssetId?: string | null;
  videoAssetId?: string | null;
  clientId?: string | null;
  flowchartId?: string | null;
  selectionBoardId?: string | null;
  moodMeter?: MoodMeterDisplayConfigInput | null;
  time?: any | null;
  alert?: AgendaItemAlertInput | null;
  timer?: TimerInput | null;
  award?: ItemAwardInput | null;
  checklist?: ChecklistInput | null;
  notification?: NotificationInput | null;
  agendaItems?: AgendaItemsInput[] | null;
  tags?: string[] | null;
}

export interface AgendaItemsInput {
  id?: string | null;
  agendaItemId: string;
  position: number;
}

export interface AppEventLogListOptionsInput {
  sortField?: AppEventLogListSortField | null;
  control?: ListControl | null;
}

export interface AppStatItemListOptionsInput {
  sortField?: AppStatItemListSortField | null;
  control?: ListControl | null;
}

export interface AwardConfigInput {
  requestConfirmation?: boolean | null;
}

export interface AwardInput {
  title: string;
  imageId?: string | null;
  targetScore: number;
  autoReactivation: boolean;
}

export interface AwardListOptionsInput {
  sortField?: AwardListSortField | null;
  control?: ListControl | null;
}

export interface ChecklistInput {
  title: string;
  items: ChecklistItemInput[];
}

export interface ChecklistItemInput {
  id?: string | null;
  title: string;
  position: number;
}

export interface ClientConfigInput {
  enabledDashboardItems?: DashboardItem[] | null;
  mainPageType?: MainPageType | null;
  mainPageBackgroundColor?: any | null;
  mainPageDataSyncEnabled?: boolean | null;
  agenda?: AgendaConfigInput | null;
  flowchartListType?: FlowChartListType | null;
  flowchartItemBackgroundColor?: any | null;
  selectionBoardListType?: SelectionBoardListType | null;
  moodMeter?: MoodMeterInput | null;
  timer?: TimerConfigInput | null;
  award?: AwardConfigInput | null;
  unlock?: UnlockInput | null;
  enabledGames?: ClientGameInput[] | null;
  highContrastMode?: boolean | null;
}

export interface ClientGameInput {
  name: Game;
}

export interface ClientListOptionsInput {
  sortField?: ClientListSortField | null;
  control?: ListControl | null;
}

export interface ClientQuestionnaireAnswerInput {
  questionId: string;
  answerOptionId: string;
}

export interface ColorsOfDaysInput {
  mon: any;
  tue: any;
  wed: any;
  thu: any;
  fri: any;
  sat: any;
  sun: any;
}

export interface CreateAssetDirectoryInput {
  name: string;
  parentId?: string | null;
}

export interface CreateClientInput {
  name: string;
  dateOfBirth: any;
  isSelfSupporting: boolean;
  clientQuestionnaireAnswers: ClientQuestionnaireAnswerInput[];
}

export interface CreateEducationContentInput {
  type: EducationContentType;
  title: string;
  file: any;
}

export interface CreateEducationContentModuleInput {
  parentId: string;
  title: string;
  documentContents?: CreateEducationContentInput[] | null;
  videoContents?: CreateEducationContentInput[] | null;
}

export interface CreateEverydaySituationDirectoryInput {
  title: string;
  imageId: string;
  clientId: string;
}

export interface CreateEverydaySituationInput {
  title: string;
  description?: string | null;
  assetId?: string | null;
  situationType: EverydaySituationType;
  directoryId: string;
}

export interface CreateFaqItemInput {
  question: string;
  answer: string;
}

export interface CreateTagInput {
  title: string;
  category: TagCategory;
}

export interface DateFilter {
  from: any;
  to: any;
}

export interface DisabledContentListOptionsInput {
  sortField?: DisabledContentListSortField | null;
  control?: ListControl | null;
}

export interface EducationContentModuleOrderInput {
  moduleId: string;
  position: number;
}

export interface EducationContentOrderInput {
  contentId: string;
  position: number;
}

export interface EverydaySituationDirectoryListOptionsInput {
  sortField?: EverydaySituationDirectorySortField | null;
  control?: ListControl | null;
}

export interface EverydaySituationDirectoryOrderInput {
  id: string;
  position: number;
}

export interface FaqItemOrderInput {
  faqItemId: string;
  position: number;
}

export interface FlowchartInput {
  title?: string | null;
  description?: string | null;
  clientId?: string | null;
  imageAssetId?: string | null;
  usableInSortingGame?: boolean | null;
  flowchartItems?: FlowchartItemsInput[] | null;
  tags?: string[] | null;
}

export interface FlowchartItemInput {
  type?: FlowchartItemType | null;
  title?: string | null;
  clientId?: string | null;
  flowchartId?: string | null;
  imageAssetId?: string | null;
  audioAssetId?: string | null;
  videoAssetId?: string | null;
  moodMeter?: MoodMeterDisplayConfigInput | null;
  timer?: TimerInput | null;
  checklist?: ChecklistInput | null;
  award?: ItemAwardInput | null;
  notification?: NotificationInput | null;
  flowchartItems?: FlowchartItemsInput[] | null;
  tags?: string[] | null;
}

export interface FlowchartItemsInput {
  id?: string | null;
  flowchartItemId: string;
  position: number;
  branch: number;
}

export interface InstantAwardInput {
  title: string;
  imageId?: string | null;
}

export interface InstantAwardListOptionsInput {
  sortField?: InstantAwardListSortField | null;
  control?: ListControl | null;
}

export interface ItemAwardInput {
  type: ItemAwardType;
  score?: number | null;
  instantAwardIds?: string[] | null;
  useTimer?: boolean | null;
}

export interface LibraryContentShareRequestListInput {
  type?: SearchListType | null;
  listOptions?: LibraryContentShareRequestListOptionsInput | null;
}

export interface LibraryContentShareRequestListOptionsInput {
  sortField?: LibraryContentShareRequestSortField | null;
  control?: ListControl | null;
}

export interface ListControl {
  sortOrder?: SortOrder | null;
  search?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export interface MemoryGameConfigInput {
  numberOfPairs: number;
  imageIds: string[];
}

export interface MoodMeterDisplayConfigInput {
  display: MoodMeterDisplay;
  title?: string | null;
}

export interface MoodMeterInput {
  enabled: boolean;
  title: string;
  multiSelect: boolean;
  intensity: boolean;
  message: boolean;
  question?: string | null;
  items: MoodMeterItemInput[];
}

export interface MoodMeterItemInput {
  emotion: MoodMeterEmotion;
  name?: string | null;
  position?: number | null;
}

export interface NotificationInput {
  text?: string | null;
  imageAssetId?: string | null;
  audioAssetId?: string | null;
}

export interface SearchInput {
  types?: SearchListType[] | null;
  assetTypes?: AssetType[] | null;
  assetDirectory?: string | null;
  tags?: string[] | null;
  isPublished?: boolean | null;
  usableInSortingGame?: boolean | null;
  clientId?: string | null;
  createdBy?: string | null;
  dayFrom?: any | null;
  dayTo?: any | null;
  listOptions?: SearchListOptionsInput | null;
}

export interface SearchListOptionsInput {
  sortField?: SearchListSortField | null;
  control?: ListControl | null;
}

export interface SelectionBoardInput {
  title: string;
  imageId?: string | null;
  backgroundColor?: any | null;
  items: SelectionBoardItemInput[];
}

export interface SelectionBoardItemInput {
  id?: string | null;
  title?: string | null;
  imageId?: string | null;
}

export interface SelectionBoardListOptionsInput {
  sortField?: SelectionBoardListSortField | null;
  control?: ListControl | null;
}

export interface SelectionBoardOrderInput {
  selectionBoardId: string;
  position: number;
}

export interface TagListInput {
  category?: TagCategory | null;
  listOptions?: TagListOptionsInput | null;
}

export interface TagListOptionsInput {
  sortField?: TagListSortField | null;
  control?: ListControl | null;
}

export interface TimerConfigInput {
  type?: TimerType | null;
  requestConfirmation?: boolean | null;
}

export interface TimerInput {
  seconds: number;
  skipEnabled: boolean;
  endWarning: boolean;
}

export interface UnlockInput {
  enabled?: boolean | null;
  colors?: any[] | null;
  pattern?: any[] | null;
  failMessage?: string | null;
}

export interface UpdateAssetDirectoryInput {
  name?: string | null;
  parentId?: string | null;
}

export interface UpdateClientInput {
  name?: string | null;
  dateOfBirth?: any | null;
  introduction?: string | null;
}

export interface UpdateEducationContentInput {
  id: string;
  type?: EducationContentType | null;
  title?: string | null;
}

export interface UpdateEducationContentModuleInput {
  id: string;
  title: string;
}

export interface UpdateEverydaySituationClientDirectoryInput {
  id: string;
  isVisible?: boolean | null;
  isWritable?: boolean | null;
  title?: string | null;
  imageId?: string | null;
}

export interface UpdateEverydaySituationDirectoryInput {
  id: string;
  title?: string | null;
  imageId?: string | null;
}

export interface UpdateEverydaySituationInput {
  id: string;
  title?: string | null;
  description?: string | null;
  assetId?: string | null;
  directoryId?: string | null;
}

export interface UpdateFaqItemInput {
  question?: string | null;
  answer?: string | null;
}

export interface WithoutDefaultAgendaConfigInput {
  text?: string | null;
  image?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

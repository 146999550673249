import React from "react";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { Input } from "../Input/Input";
import "../Inputs.scss";
interface Props {
    autoFocus?: boolean;
    disabled?: boolean;
    form?: string;
    hasError?: boolean;
    innerRef?: (ref: HTMLInputElement | null) => void;
    list?: string;
    name?: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    tabIndex?: number;
    value: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
    isVisible: boolean | null;
}

class PasswordInput extends React.PureComponent<Props, State> {
    private inputRef: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }

    public focus = (): void => {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    };

    private toggleVisible = (): void => {
        this.setState({ isVisible: !this.state.isVisible }, this.focus);
    };

    public render(): React.ReactElement<any> {
        const { isVisible } = this.state;

        return (
            <div className="input-password-wrapper">
                <Input
                    {...this.props}
                    innerRef={(ref: HTMLInputElement | null): void => {
                        this.inputRef = ref;
                    }}
                    type={isVisible ? "text" : "password"}
                    placeholder={this.props.disabled ? undefined : this.props.placeholder}
                />

                <div className="button-wrapper">
                    <Button
                        ariaLabel={Intl.formatMessage({ id: isVisible ? "component.passwordInput.showPassword" : "component.passwordInput.hidePassword" })}
                        icon={{ name: isVisible ? "fa-eye" : "fa-eye-slash", large: true }}
                        link
                        onClick={this.toggleVisible}
                    />
                </div>
            </div>
        );
    }
}

export { PasswordInput };

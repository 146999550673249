import React, { Component } from "react";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { AccountStatus, AccountStatusSelectOptions } from "models/AccountStatusSelectOptions";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Account, AccountType } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { Input } from "components/Inputs/Input/Input";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { ApiTypes } from "api/ApiTypes";
import { Prompt } from "components/Prompt";

interface ComponentProps {
    account: Account;
    disabled?: boolean;
    onSaveSucceed: () => void;
}

type Props = ComponentProps & NotificationProp;

interface State {
    isEditable: boolean;
    selectedStatus: SelectOption<AccountStatus>;
    reason: string;
}

class AccountDetailStatusFormComponent extends Component<Props, State> {
    public static getInitialState(props: Props) {
        return {
            isEditable: false,
            selectedStatus: Select.getSelectOption(AccountStatusSelectOptions.get(!!props.account.activatedAt), props.account.disabledAt ? AccountStatus.disabled : AccountStatus.active),
            reason: props.account.disabledReason || "",
        };
    }

    public readonly state: State = AccountDetailStatusFormComponent.getInitialState(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (nextProps.account !== this.props.account) {
            this.setState(AccountDetailStatusFormComponent.getInitialState(nextProps));
        }
    }

    private onEditClick = () => {
        this.setState({ isEditable: true });
    };

    private onReasonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ reason: event.currentTarget.value });
    };

    private resetForm = (): void => {
        this.setState(AccountDetailStatusFormComponent.getInitialState(this.props));
    };

    private enableAccount = (): Promise<void> => {
        if (this.props.account.accountType === AccountType.supporter) {
            return Api.enableSupporter(this.props.account.id);
        }
        return Api.enableAccount(this.props.account.id);
    };

    private disableAccount = (): Promise<void> => {
        if (this.props.account.accountType === AccountType.supporter) {
            return Api.disableSupporter(this.props.account.id, this.state.reason);
        }
        return Api.disableAccount(this.props.account.id, this.state.reason);
    };

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            if (this.state.selectedStatus.value === AccountStatus.active || this.state.selectedStatus.value === AccountStatus.inactive) {
                await this.enableAccount();
            } else {
                await this.disableAccount();
            }
            this.props.onSaveSucceed();
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "sharedComponent.accountDetailStatusForm.saveSucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <form noValidate={true} onSubmit={this.onSubmit}>
                <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.accountDetailStatusForm.accountStatus.label" })}>
                    <Select
                        options={AccountStatusSelectOptions.get(!!this.props.account.activatedAt)}
                        onChange={(selectedStatus: SelectOption<AccountStatus>): void => {
                            this.setState({ selectedStatus });
                        }}
                        value={this.state.selectedStatus}
                        disabled={!this.state.isEditable || this.props.disabled}
                    />
                </InputWrapper>
                {this.state.selectedStatus.value && this.state.selectedStatus.value === AccountStatus.disabled && (
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.accountDetailStatusForm.reason.label" })}>
                        <Input
                            type="text"
                            value={this.state.reason}
                            onChange={this.onReasonChange}
                            placeholder={
                                this.state.isEditable && !this.props.account.disabledAt
                                    ? Intl.formatMessage({ id: "sharedComponent.accountDetailStatusForm.reason.placeholder" })
                                    : Intl.formatMessage({ id: "sharedComponent.accountDetailStatusForm.reason.empty" })
                            }
                            disabled={!this.state.isEditable || !!this.props.account.disabledAt}
                        />
                    </InputWrapper>
                )}
                {!this.props.disabled &&
                    (this.state.isEditable ? (
                        <div className="action-button-container">
                            <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                            <Button ariaLabel={Intl.formatMessage({ id: "common.save" })} label={Intl.formatMessage({ id: "common.save" })} type="submit" />
                        </div>
                    ) : (
                        <div className="edit-button">
                            <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                        </div>
                    ))}
                <Prompt when={this.state.selectedStatus.value !== ApiTypes.getAccountStatus(this.props.account)} />
            </form>
        );
    }
}

export const AccountDetailStatusForm = withNotification(AccountDetailStatusFormComponent);

import React, { Component } from "react";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Button } from "components/Button/Button";
import { AccountDetailStatusForm } from "pages/_shared/AccountDetails/AccountDetailStatusForm";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Select } from "components/Inputs/Select/Select";
import { AccountTypeSelectOptions } from "models/AccountTypeSelectOptions";
import { Intl } from "i18n/Intl";
import { Section } from "components/Section";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DispatchProp, connect } from "react-redux";
import { Path } from "utils/Path";
import { AccountType, Account } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import { Loading, LoadingType } from "components/Loading/Loading";

interface Params {
    accountId: string;
}

interface ComponentProps {
    accountType: AccountType;
}

type Props = RouteComponentProps<Params> & ComponentProps & DispatchProp;

interface State {
    isLoading: boolean;
    account: Account | null;
}

class AdminDetailProfileTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        account: null,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const account: Account = await Api.getAccountById(props.match.params.accountId);
                    this.setState({ account, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.adminList);
                        },
                    });
                }
            },
        );
    }

    private deleteAccount = async (): Promise<void> => {
        try {
            await Api.deleteAccount(this.props.match.params.accountId);
            Alert.success({
                title: Intl.formatMessage({ id: "page.adminDetail.tabs.profile.accountData.delete.deleteSucceed" }),
                callback: (): void => {
                    this.props.history.push(Path.adminList);
                },
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement {
        if (this.state.isLoading && !this.state.account) {
            return <Loading type={LoadingType.layer} />;
        }

        const { account } = this.state;
        if (!account) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.personalData.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.personalData.nameForm.name.label" })}>
                        <Input placeholder={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.personalData.nameForm.name.placeholder" })} type="name" value={account.name} disabled={true} />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.personalData.emailForm.email.label" })}>
                        <Input placeholder={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.personalData.emailForm.email.placeholder" })} type="email" value={account.email} disabled={true} />
                    </InputWrapper>
                </Section>

                <Section label={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.accountData.title" })}>
                    {this.props.accountType === AccountType.superadmin && (
                        <div className="input-wrapper grid-x">
                            <span className="input-label cell">{Intl.formatMessage({ id: "page.adminDetail.tabs.profile.accountData.delete.label" })}</span>
                            <span className="cell auto">
                                <strong className="value-cell">&nbsp;</strong>
                            </span>
                            <span className="cell button-cell">
                                <Button
                                    className="btn-danger btn--text-large fw-700"
                                    label={Intl.formatMessage({ id: "common.delete" })}
                                    ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                    onClick={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.deleteAccountConfirm,
                                                onConfirmed: this.deleteAccount,
                                            }),
                                        );
                                    }}
                                />
                            </span>
                        </div>
                    )}
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.adminDetail.tabs.profile.accountData.accountType.label" })}>
                        <Select
                            options={AccountTypeSelectOptions.get()}
                            onChange={(): void => {}}
                            value={Select.getSelectOption(AccountTypeSelectOptions.get(), account.accountType)}
                            disabled={true}
                        />
                    </InputWrapper>

                    {account.accountType !== AccountType.superadmin && <AccountDetailStatusForm account={account} onSaveSucceed={(): void => this.refreshAccount(this.props)} />}
                </Section>
            </div>
        );
    }
}

export const AdminDetailProfileTab = withRouter(connect()(AdminDetailProfileTabComponent));

import { Intl } from "i18n/Intl";
import { TimeUnit } from "utils/DateUtils";
import { SelectOption } from "components/Inputs/Select/Select";

export type PeriodOption = SelectOption<TimeUnit>;

class PeriodSelectOptions {
    public static get(): PeriodOption[] {
        return [TimeUnit.second, TimeUnit.minute, TimeUnit.hour].map((timeUnit: TimeUnit) => {
            return { id: timeUnit, label: Intl.formatMessage({ id: `enum.timeUnit.${timeUnit}` }), value: timeUnit };
        });
    }
}

export { PeriodSelectOptions };

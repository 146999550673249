import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Intl } from "i18n/Intl";

interface ReduxProps {
    authToken: string | null;
}

interface LocalProps {
    url: string;
    originalFileName: string;
}

interface State {
    progress: number;
}

type Props = ReduxProps & LocalProps;

class DownloadLinkComponent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            progress: 0,
        };
    }

    private XHR: XMLHttpRequest = new XMLHttpRequest();

    public componentWillUnmount(): void {
        this.XHR.abort();
    }

    private download = (): void => {
        this.XHR.open("GET", this.props.url);
        this.XHR.setRequestHeader("authorization", `Bearer ${this.props.authToken}`);
        this.XHR.responseType = "blob";

        this.XHR.onload = (): void => {
            const blob: Blob = this.XHR.response;
            const anchor: HTMLAnchorElement = document.createElement("a");
            const blobObject: string = window.URL.createObjectURL(blob);
            anchor.href = blobObject;
            anchor.download = this.props.originalFileName;
            anchor.dispatchEvent(new MouseEvent("click"));
            window.URL.revokeObjectURL(blobObject);
        };

        this.XHR.onprogress = this.updateProgresses;
        this.XHR.send();
    };

    private updateProgresses = (event: ProgressEvent): void => {
        if (event.lengthComputable) {
            const percent: number = (event.loaded / event.total) * 100;
            const percentRound: number = Math.round(percent);
            this.setState({ progress: percentRound });
        }
    };

    public render(): JSX.Element {
        const { progress } = this.state;
        const displayProgress: boolean = progress > 0 && progress < 100;

        return displayProgress ? (
            <span>{`${Intl.formatMessage({ id: "common.download" })} ${progress} %`}</span>
        ) : (
            <div className="btn btn-primary btn-outline small" onClick={this.download}>
                {Intl.formatMessage({ id: "common.download" })}
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        authToken: state.auth.authToken,
    };
};

export const DownloadLink = connect(mapStateToProps)(DownloadLinkComponent);

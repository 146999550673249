import { ReduxAction } from "actions";
import { AlertOptions } from "components/Alert/AlertTypes";
import { AlertActionType } from "actions/AlertActions";

export type AlertReducerState = AlertOptions[];

export class AlertReducer {
    private static readonly initialState: AlertReducerState = [];

    public static instance(state: AlertReducerState = AlertReducer.initialState, action: ReduxAction): AlertReducerState {
        switch (action.type) {
            case AlertActionType.show:
                return [...state, action.alertOptions];
            case AlertActionType.hide: {
                const alerts = [...state];
                alerts.shift();
                return alerts;
            }
            default:
                return state;
        }
    }
}

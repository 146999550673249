import React, { Component } from "react";
import { Select } from "../Select/Select";
import { Intl } from "i18n/Intl";
import { Formatter } from "utils/Formatter";
import { DateUtils, Time } from "utils/DateUtils";

interface Props {
    value: string | null;
    onChange: (time: string | null) => void;
    disabled?: boolean;
}

interface TimeOption {
    id: string;
    label: string;
    value: number;
}

class TimeSelectInput extends Component<Props> {
    private static readonly hourOptions: TimeOption[] = Array.from({ length: 24 }).map((_: any, index: number) => {
        const value = index.toString();
        return { id: value, label: value, value: Number.parseInt(value, 10) };
    });

    private static readonly minuteOptions: TimeOption[] = Array.from({ length: 60 }).map(
        (_: any, index: number): TimeOption => {
            const value = index.toString();
            return { id: value, label: value, value: Number.parseInt(value, 10) };
        },
    );

    private onHourChange = (option: TimeOption) => {
        const currentTime: Time = this.getCurrentTime();
        currentTime.hour = option.value;
        this.props.onChange(Formatter.formatTime(currentTime));
    };

    private onMinuteChange = (option: TimeOption) => {
        const currentTime: Time = this.getCurrentTime();
        currentTime.minute = option.value;
        this.props.onChange(Formatter.formatTime(currentTime));
    };

    private getCurrentTime = (): Time => {
        return DateUtils.getTime(this.props.value);
    };

    public render(): React.ReactElement<any> {
        const currentTime: Time = this.getCurrentTime();
        return (
            <div className="grid-x align-middle padded time-select">
                {this.props.disabled ? (
                    <div className="cell">
                        <div className="input-info">{this.props.value}</div>
                    </div>
                ) : (
                    <>
                        <div className="cell timeInput">
                            <Select value={Select.getSelectOption(TimeSelectInput.hourOptions, currentTime.hour)} options={TimeSelectInput.hourOptions} onChange={this.onHourChange} />
                        </div>
                        <div className="cell timeUnit">
                            <span className="unit">{Intl.formatMessage({ id: "enum.timeUnit.hour" })}</span>
                        </div>
                        <div className="cell timeInput">
                            <Select value={Select.getSelectOption(TimeSelectInput.minuteOptions, currentTime.minute)} options={TimeSelectInput.minuteOptions} onChange={this.onMinuteChange} />
                        </div>
                        <div className="cell timeUnit">
                            <span className="unit">{Intl.formatMessage({ id: "enum.timeUnit.minute" })}</span>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export { TimeSelectInput };

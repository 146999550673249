import * as qs from "query-string";

export interface ParsedParameter {
    [key: string]: string | string[] | null | undefined;
}

export abstract class UrlQueryParser<T> {
    private parseQuery(queryParams: string): { [key: string]: string | string[] | null | undefined } {
        return qs.parse(queryParams);
    }

    protected abstract getParameter(queryParams: ParsedParameter): T;

    public parse(queryParams: string): T {
        const parsedParams: ParsedParameter = this.parseQuery(queryParams);
        return this.getParameter(parsedParams);
    }

    public abstract getUrlQuery(options: T): string;
}

import React, { Component } from "react";
import { Button } from "components/Button/Button";
import { BottomBar } from "components/BottomBar";
import { Intl } from "i18n/Intl";
import { SearchListType, AccountType, LibraryContentShareRequest, LibraryContentShareRequestWithData } from "api/graphql/types";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Path } from "utils/Path";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Log } from "utils/Log";
import { ApplicationState } from "reducers";

interface ReduxProps {
    accountType: AccountType;
}

interface ComponentProps {
    searchListType: SearchListType;
    shareRequest: LibraryContentShareRequestWithData | LibraryContentShareRequest;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp & ReduxProps;

interface State {}

class ShareRequestBottomBarComponent extends Component<Props, State> {
    private onAcceptClick = async (): Promise<void> => {
        try {
            const id = await Api.acceptLibraryContentShareRequest(this.props.shareRequest.id);
            Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.shareRequestBottomBar.succeed" }) });
            switch (this.props.searchListType) {
                case SearchListType.agenda:
                    this.props.history.push(Path.viewAgenda(id));
                    break;
                case SearchListType.agendaItem:
                    this.props.history.push(Path.viewAgendaItem(id));
                    break;
                case SearchListType.flowchart:
                    this.props.history.push(Path.viewFlowchart(id));
                    break;
                case SearchListType.flowchartItem:
                    this.props.history.push(Path.viewFlowchartItem(id));
                    break;
                default:
                    Log.warning("Unhandled searchListType: ", this.props.searchListType);
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onRejectClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.rejectContentShareRequest,
                requestId: this.props.shareRequest.id,
                onRejected: () => {
                    this.props.history.push(this.getBackUrl());
                },
            }),
        );
    };

    private onCancelClick = (): void => {
        if (this.props.accountType === AccountType.supporter) {
            this.props.history.push(`${Path.publicContentLibrary}?${Intl.formatMessage({ id: "enum.contentLibraryQueryParameter.isShared" })}`);
        } else {
            this.props.history.push(this.getBackUrl());
        }
    };

    private getBackUrl = (): string => {
        if (this.props.accountType === AccountType.supporter) {
            return `${Path.publicContentLibrary}?velem-megosztott`;
        }

        return `${Path.contentShareRequestList}?${Intl.formatMessage({ id: "enum.contentLibraryQueryParameter.searchListType" })}=${Intl.formatMessage({
            id: `enum.searchListTypeQueryParameter.${this.props.searchListType}`,
        })}`;
    };

    public render(): React.ReactElement<any> {
        return (
            <BottomBar isVisible={true}>
                <div className="cell medium-4 text-left">
                    <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                </div>
                <div className="cell auto text-right">
                    <Button className="btn-danger" label={Intl.formatMessage({ id: "common.reject" })} onClick={this.onRejectClick} />
                </div>
                <div className="cell shrink text-left">
                    <Button label={Intl.formatMessage({ id: "common.accept" })} onClick={this.onAcceptClick} />
                </div>
            </BottomBar>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountType: state.account!.accountType };
};

export const ShareRequestBottomBar: React.ComponentClass<ComponentProps> = withRouter(connect(mapStateToProps)(ShareRequestBottomBarComponent));

import { GraphQLClient, OnProgress } from "./graphql/GraphQLClient";
import * as GqlTypes from "./graphql/types";
import { Mutations } from "./graphql/queries/Mutations";
import { Queries } from "./graphql/queries/Queries";
import { ApiError, ApiErrorCode } from "./ApiError";
import { ListResult, ClientAdditionalFunctions, ApiTypes, AnyContentType, ContentLibrary, AppStatItemResult, AppEventLogResult } from "./ApiTypes";
import { DocumentNode } from "graphql";
import { isNil } from "lodash";
import { ContentsOrder } from "pages/EducationModulesPage/EducationSubModulePage";
import { AnyDisabledItemType } from "utils/DisabledItemUtils";
import { getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result as EverydaySituationDirectory } from "./graphql/types";
import { getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations as EverydaySituation } from "./graphql/types";
import { LibraryType } from "pages/_shared/Draggables/ContentLibrarySideBar/ContentLibrarySideBar";
import { Log } from "utils/Log";

class Api {
    /**
     * Register user by email & name
     * @param email string
     * @param name string
     * @param clientToken string recaptcha token
     */
    public static async registration(email: string, name: string, clientToken: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.registration, GqlTypes.registrationVariables>({
            mutation: Mutations.registration,
            variables: { email, name, clientToken },
        });
    }

    /**
     * Login user with email-password pair
     * @param email string
     * @param password string
     */
    public static async login(email: string, password: string): Promise<GqlTypes.login_login> {
        const data: GqlTypes.login = await GraphQLClient.mutate<GqlTypes.login, GqlTypes.loginVariables>({
            mutation: Mutations.login,
            variables: { email, password },
        });

        return data.login;
    }

    /**
     * Log out logged in user
     */
    public static async logout(): Promise<GqlTypes.logout> {
        const data: GqlTypes.logout = await GraphQLClient.mutate<any>({
            mutation: Mutations.logout,
        });

        return data.logout;
    }

    /**
     * Activate account and set password
     * @param id string ID given in link to activation page
     * @param code string given in link to activation page
     * @param password string password for account
     */
    public static async activateAccount(id: string, code: string, password: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.activateAccount, GqlTypes.activateAccountVariables>({
            mutation: Mutations.activateAccount,
            variables: { id, code, password },
        });
    }

    /**
     * Activate new email address
     * @authenticated
     * @param id string ID given in link to email activation page
     * @param code string given in link to email activation page
     */
    public static async changeEmailByCode(id: string, code: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.changeEmailByCode, GqlTypes.changeEmailByCodeVariables>({
            mutation: Mutations.changeEmailByCode,
            variables: { id, code },
        });
    }

    /**
     * Send request for reset-password link
     * @param email EmailAddress
     */
    public static async forgotPassword(email: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.forgotPassword, GqlTypes.forgotPasswordVariables>({
            mutation: Mutations.forgotPassword,
            variables: { email },
        });
    }

    /**
     * Reset password
     * @param id string ID given in link to resetPassword page
     * @param code string given in link to resetPassword page
     * @param password string password for account
     */
    public static async resetPassword(id: string, code: string, password: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.resetPassword, GqlTypes.resetPasswordVariables>({
            mutation: Mutations.resetPassword,
            variables: { id, code, password },
        });
    }

    /**
     * Get User account data
     */
    public static async me(): Promise<GqlTypes.me_me> {
        const data: GqlTypes.me = await GraphQLClient.query<GqlTypes.me>({
            query: Queries.me,
        });

        return data.me;
    }

    public static async getAppData(): Promise<GqlTypes.appData> {
        const appData = await GraphQLClient.query<GqlTypes.appData>({
            query: Queries.appData,
        });

        return appData;
    }

    public static async getStorageSizeUsed(): Promise<GqlTypes.storageSizeUsed> {
        const storageSizeUsed = await GraphQLClient.query<GqlTypes.storageSizeUsed>({
            query: Queries.storageSizeUsed,
        });

        return storageSizeUsed;
    }

    /**
     * Update Profile name
     * @param name new Name
     */
    public static async updateName(name: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.updateName, GqlTypes.updateNameVariables>({
            mutation: Mutations.updateName,
            variables: { name },
        });
    }

    /**
     * Update Profile email. After call, activation email sent.
     * Email will change after activation succeed.
     * @param newEmail new email
     */
    public static async changeEmailRequest(newEmail: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.changeEmailRequest, GqlTypes.changeEmailRequestVariables>({
            mutation: Mutations.changeEmailRequest,
            variables: { newEmail },
        });
    }

    /**
     * Change Profile password
     * @param password old password
     * @param newPassword new password
     */
    public static async changePassword(password: string, newPassword: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.changePassword, GqlTypes.changePasswordVariables>({
            mutation: Mutations.changePassword,
            variables: { password, newPassword },
        });
    }

    /**
     * Change Profile pincode
     * @param pin pincode
     */
    public static async updatePin(pin: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.updatePin, GqlTypes.updatePinVariables>({
            mutation: Mutations.updatePin,
            variables: { pin },
        });
    }

    /**
     * Upload Profile avatar image
     * @param file Avatar image
     * @param onProgress callback for progress change
     */
    public static async uploadAccountAvatar(file: File, onProgress?: OnProgress): Promise<GqlTypes.uploadAccountAvatar_uploadAccountAvatar> {
        const data: GqlTypes.uploadAccountAvatar = await GraphQLClient.upload<GqlTypes.uploadAccountAvatar, GqlTypes.uploadAccountAvatarVariables>({
            mutation: Mutations.uploadAccountAvatar,
            variables: { file },
            onProgress,
            file,
        });

        return data.uploadAccountAvatar!;
    }

    /**
     * Delete User avatar image
     */
    public static async deleteAccountAvatar(): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAccountAvatar>({
            mutation: Mutations.deleteAccountAvatar,
        });
    }

    /**
     * Delete User account
     */
    public static async deleteMyAccount(): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteMyAccount>({
            mutation: Mutations.deleteMyAccount,
        });
    }

    /**
     * Delete User PIN (supporter)
     */
    public static async deletePin(): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deletePin>({
            mutation: Mutations.deletePin,
        });
    }

    /**
     * Get client questionnaire questions
     * Use for creating client
     */
    public static async getClientQuestionnaire(): Promise<GqlTypes.ClientQuestionnaireQuestion[]> {
        const data: GqlTypes.getClientQuestionnaire = await GraphQLClient.query<GqlTypes.getClientQuestionnaire>({
            query: Queries.getClientQuestionnaire,
        });

        return data.getClientQuestionnaire || [];
    }

    /**
     * Create client
     * @param name string
     * @param dateOfBirth string
     * @param clientQuestionnaireAnswers ClientQuestionnaireAnswerInput[]
     * @param isSelfSupporting boolean
     */
    public static async createClient(
        name: string,
        dateOfBirth: string,
        clientQuestionnaireAnswers: GqlTypes.ClientQuestionnaireAnswerInput[],
        isSelfSupporting: boolean,
    ): Promise<GqlTypes.SupportedClient> {
        const data: GqlTypes.createClient = await GraphQLClient.mutate<GqlTypes.createClient, GqlTypes.createClientVariables>({
            mutation: Mutations.createClient,
            variables: { clientInput: { name, dateOfBirth, clientQuestionnaireAnswers, isSelfSupporting } },
        });

        return data.createClient;
    }

    /**
     * Get my client by id
     * @param clientId string
     */
    public static async getMyClientById(clientId: string): Promise<GqlTypes.ClientProfileData> {
        const data: GqlTypes.getMyClients = await GraphQLClient.query<GqlTypes.getMyClients, GqlTypes.getMyClientsVariables>({ query: Queries.getMyClients, variables: { clientId } });
        if (data && data.me.myClients) {
            const clients: GqlTypes.ClientProfileData[] = data.me.myClients.result || [];
            if (clients.length === 0) {
                throw new ApiError(ApiErrorCode.NOT_FOUND);
            }
            return clients[0];
        }
        throw new ApiError(ApiErrorCode.NOT_FOUND);
    }

    /**
     * Get client's eventLog by extId
     * @param extId string
     */
    public static async getClientEventLog(
        clientId: string,
        dateTimeFrom: Date,
        dateTimeTo: Date,
        type?: GqlTypes.AppStatItemType,
        options?: GqlTypes.AppStatItemListOptionsInput,
    ): Promise<AppStatItemResult> {
        const data: GqlTypes.getClientEventLog = await GraphQLClient.query<GqlTypes.getClientEventLog, GqlTypes.getClientEventLogVariables>({
            query: Queries.getClientEventLog,
            variables: {
                clientId,
                options,
                date: {
                    from: new Date(dateTimeFrom),
                    to: new Date(dateTimeTo),
                },
                type,
            },
        });

        return {
            result: data.getAppStatItemList.result || [],
            aggregations: data.getAppStatItemList.aggregations,
            count: data.getAppStatItemList.count,
        };
    }

    /**
     * Upload client avatar
     * @param clientId string
     * @param file File
     * @param onProgress OnProgress
     */
    public static async uploadClientAvatar(clientId: string, file: File, onProgress?: OnProgress): Promise<GqlTypes.Asset> {
        const data: GqlTypes.uploadClientAvatar = await GraphQLClient.upload<GqlTypes.uploadClientAvatar, GqlTypes.uploadClientAvatarVariables>({
            mutation: Mutations.uploadClientAvatar,
            variables: { clientId, file },
            onProgress,
            file,
        });

        if (!data.uploadClientAvatar) {
            throw new ApiError(ApiErrorCode.ASSET_NOT_FOUND);
        }

        return data.uploadClientAvatar;
    }

    /**
     * Delete client avatar
     * @param clientId string
     */
    public static async deleteClientAvatar(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClientAvatar, GqlTypes.deleteClientAvatarVariables>({
            mutation: Mutations.deleteClientAvatar,
            variables: { clientId },
        });
    }

    /**
     * Update client profile
     * @param clientId string
     * @param name string
     * @param dateOfBirth string
     * @param introduction string
     */
    public static async updateClient(clientId: string, clientInput: GqlTypes.UpdateClientInput): Promise<GqlTypes.ClientProfileData> {
        const data: GqlTypes.updateClient = await GraphQLClient.mutate<GqlTypes.updateClient, GqlTypes.updateClientVariables>({
            mutation: Mutations.updateClient,
            variables: { clientId, clientInput },
        });

        return data.updateClient;
    }

    /**
     * Delete client by id
     * @param clientId string
     */
    public static async deleteClient(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClient, GqlTypes.deleteClientVariables>({
            mutation: Mutations.deleteClient,
            variables: { clientId },
        });
    }

    /**
     * Share client with another supporter
     * @param email string supporter email address
     * @param clientId string client id
     */
    public static async shareClient(email: string, clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.shareClient, GqlTypes.shareClientVariables>({
            mutation: Mutations.shareClient,
            variables: { email, clientId },
        });
    }

    /**
     * Remove client from supporter
     * @param clientId string client id
     * @param supporterAccountId string supporter id
     */
    public static async unlinkClientFromSupporter(clientId: string, supporterAccountId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.unlinkClientFromSupporter, GqlTypes.unlinkClientFromSupporterVariables>({
            mutation: Mutations.unlinkClientFromSupporter,
            variables: { supporterAccountId, clientId },
        });
    }

    /**
     * Get my clients, filter by clientId & get client's config (App tab)
     * @param clientId string client id
     */
    public static async getClientAppSettingsData(clientId: string): Promise<{ clientAppConfig: GqlTypes.ClientAppConfig; notificationOnProfilePage: string }> {
        const data = await GraphQLClient.query<GqlTypes.getMyClientsAppConfig, GqlTypes.getMyClientsAppConfigVariables>({
            query: Queries.getMyClientsAppConfig,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getMyClientsAppConfig_me_myClients_result = data.me.myClients.result[0];

        return {
            clientAppConfig: result.config,
            notificationOnProfilePage: result.notificationOnProfilePage,
        };
    }

    /**
     * Get my clients, filter by clientId & get client's config (MainPage tab)
     * @param clientId string client id
     */
    public static async getClientMainPageSettingsData(clientId: string): Promise<{ clientMainPageConfig: GqlTypes.ClientMainPageConfig; notificationOnProfilePage: string }> {
        const data = await GraphQLClient.query<GqlTypes.getMyClientsMainPageConfig, GqlTypes.getMyClientsMainPageConfigVariables>({
            query: Queries.getMyClientsMainPageConfig,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getMyClientsMainPageConfig_me_myClients_result = data.me.myClients.result[0];

        return {
            clientMainPageConfig: result.config,
            notificationOnProfilePage: result.notificationOnProfilePage,
        };
    }

    /**
     * Get my clients, filter by clientId & get client's config (Contents tab)
     * @param clientId string client id
     */
    public static async getClientContentsSettingsData(clientId: string): Promise<{ clientContentsConfig: GqlTypes.ClientContentsConfig; notificationOnProfilePage: string }> {
        const data = await GraphQLClient.query<GqlTypes.getMyClientsContentsConfig, GqlTypes.getMyClientsContentsConfigVariables>({
            query: Queries.getMyClientsContentsConfig,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getMyClientsContentsConfig_me_myClients_result = data.me.myClients.result[0];

        return {
            clientContentsConfig: result.config,
            notificationOnProfilePage: result.notificationOnProfilePage,
        };
    }

    /**
     * Get my clients, filter by clientId & get client's config (Functions tab)
     * @param clientId string client id
     */
    public static async getClientFunctionsSettingsData(clientId: string): Promise<{ clientFunctionsConfig: GqlTypes.ClientFunctionsConfig; notificationOnProfilePage: string }> {
        const data = await GraphQLClient.query<GqlTypes.getMyClientsFunctionsConfig, GqlTypes.getMyClientsFunctionsConfigVariables>({
            query: Queries.getMyClientsFunctionsConfig,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getMyClientsFunctionsConfig_me_myClients_result = data.me.myClients.result[0];

        return {
            clientFunctionsConfig: result.config,
            notificationOnProfilePage: result.notificationOnProfilePage,
        };
    }

    /**
     * Update clientConfig by id
     * @param clientId string client id
     * @param clientConfig client's config
     */
    public static async updateClientConfig(clientId: string, clientConfig: GqlTypes.ClientConfigInput): Promise<GqlTypes.ClientConfig> {
        const data: GqlTypes.updateClientConfig = await GraphQLClient.mutate<GqlTypes.updateClientConfig, GqlTypes.updateClientConfigVariables>({
            mutation: Mutations.updateClientConfig,
            variables: {
                clientId,
                clientConfig,
            },
        });

        return data.updateClientConfig;
    }

    /**
     * Get data for admins page
     * @param options AccountListOptionsInput | null
     */
    public static async getAdminList(options?: GqlTypes.AccountListOptionsInput | null): Promise<GqlTypes.getAdminList_getAccountList> {
        const data: GqlTypes.getAdminList = await GraphQLClient.query<GqlTypes.getAdminList, GqlTypes.getAdminListVariables>({
            query: Queries.getAdminList,
            variables: { options },
        });

        return data.getAccountList;
    }

    /**
     * Get data for supervisors page
     * @param options AccountListOptionsInput | null
     */
    public static async getSupervisorList(options?: GqlTypes.AccountListOptionsInput | null): Promise<GqlTypes.getSupervisorList_getAccountList> {
        const data: GqlTypes.getSupervisorList = await GraphQLClient.query<GqlTypes.getSupervisorList, GqlTypes.getSupervisorListVariables>({
            query: Queries.getSupervisorList,
            variables: { options },
        });

        return data.getAccountList;
    }

    /**
     * Get data for supporter page
     * @param options AccountListOptionsInput | null
     */
    public static async getSupporterList(options?: GqlTypes.AccountListOptionsInput | null): Promise<GqlTypes.getSupporterList_getSupporterList> {
        const data: GqlTypes.getSupporterList = await GraphQLClient.query<GqlTypes.getSupporterList, GqlTypes.getSupporterListVariables>({
            query: Queries.getSupporterList,
            variables: { options },
        });

        return data.getSupporterList;
    }

    /**
     * Get data for clients page
     * @param options ClientListOptionsInput | null
     */
    public static async getClientList(options?: GqlTypes.ClientListOptionsInput | null): Promise<GqlTypes.getClientList_getClientList> {
        const data: GqlTypes.getClientList = await GraphQLClient.query<GqlTypes.getClientList, GqlTypes.getClientListVariables>({
            query: Queries.getClientList,
            variables: { options },
        });

        return data.getClientList;
    }

    /**
     * Create new admin account
     * @param email string
     * @param name string
     */
    public static async createAdminAccount(email: string, name: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.createAdminAccount, GqlTypes.createAdminAccountVariables>({
            mutation: Mutations.createAdminAccount,
            variables: { email, name },
        });
    }

    /**
     * Create new supervisor account
     * @param email string
     * @param name string
     */
    public static async createSupervisorAccount(email: string, name: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.createSupervisorAccount, GqlTypes.createSupervisorAccountVariables>({
            mutation: Mutations.createSupervisorAccount,
            variables: { email, name },
        });
    }

    /**
     * Create new supporter account
     * @param email string
     * @param name string
     */
    public static async createSupporterAccount(email: string, name: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.createSupporterAccount, GqlTypes.createSupporterAccountVariables>({
            mutation: Mutations.createSupporterAccount,
            variables: { email, name },
        });
    }

    /**
     * Get a supporter by extId
     * @param extId string
     */
    public static async getSupporterByExtId(extId: string): Promise<GqlTypes.SupporterDetail> {
        const data: GqlTypes.getSupporterByExtId = await GraphQLClient.query<GqlTypes.getSupporterByExtId, GqlTypes.getSupporterByExtIdVariables>({
            query: Queries.getSupporterByExtId,
            variables: { extId },
        });

        if (!data.getSupporterByExtId) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.getSupporterByExtId;
    }

    /**
     * Get an account by id
     * @param accountId string
     */
    public static async getAccountById(accountId: string): Promise<GqlTypes.Account> {
        const data: GqlTypes.getAdminById = await GraphQLClient.query<GqlTypes.getAdminById, GqlTypes.getAdminByIdVariables>({ query: Queries.getAdminById, variables: { accountId } });
        if (!data.getAccountById) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.getAccountById;
    }

    /**
     * Delete account by id
     * @param accountId string
     */
    public static async deleteAccount(accountId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAccount, GqlTypes.deleteAccountVariables>({
            mutation: Mutations.deleteAccount,
            variables: { accountId },
        });
    }

    /**
     * Enable account
     * Use for ["superadmin","admin","supervisor"]
     * @param accountId string
     */
    public static async enableAccount(accountId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.enableAccount, GqlTypes.enableAccountVariables>({
            mutation: Mutations.enableAccount,
            variables: { accountId },
        });
    }

    /**
     * Disable account
     * Use for ["superadmin","admin","supervisor"]
     * @param accountId string
     * @param disabledReason string
     */
    public static async disableAccount(accountId: string, disabledReason: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.disableAccount, GqlTypes.disableAccountVariables>({
            mutation: Mutations.disableAccount,
            variables: { accountId, disabledReason },
        });
    }

    /**
     * Enable account
     * Use for "supporter"
     * @param accountId string
     */
    public static async enableSupporter(accountId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.enableSupporter, GqlTypes.enableSupporterVariables>({
            mutation: Mutations.enableSupporter,
            variables: { accountId },
        });
    }

    /**
     * Disable account
     * Use for "supporter"
     * @param accountId string
     * @param disabledReason string
     */
    public static async disableSupporter(accountId: string, disabledReason: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.disableSupporter, GqlTypes.disableSupporterVariables>({
            mutation: Mutations.disableSupporter,
            variables: { accountId, disabledReason },
        });
    }

    public static async getClientByExtId(extId: string): Promise<GqlTypes.ClientProfileData> {
        const data = await GraphQLClient.query<GqlTypes.getClientByExtId, GqlTypes.getClientByExtIdVariables>({
            query: Queries.getClientByExtId,
            variables: { extId },
        });

        return data.getClientByExtId;
    }

    /**
     * Get client's eventLog for admin/supervisor by extId
     * @param extId string
     */
    public static async getClientDetailEventLog(
        extId: string,
        dateTimeFrom: Date,
        dateTimeTo: Date,
        type?: GqlTypes.AppStatItemType,
        options?: GqlTypes.AppStatItemListOptionsInput,
    ): Promise<AppStatItemResult> {
        const data: GqlTypes.getClientDetailEventLog = await GraphQLClient.query<GqlTypes.getClientDetailEventLog, GqlTypes.getClientDetailEventLogVariables>({
            query: Queries.getClientDetailEventLog,
            variables: {
                extId,
                date: {
                    from: dateTimeFrom,
                    to: dateTimeTo,
                },
                options,
                type,
            },
        });

        return {
            result: data.getAppStatItemList.result || [],
            aggregations: data.getAppStatItemList.aggregations,
            count: data.getAppStatItemList.count,
        };
    }

    /**
     * Supporter accept client share request
     * @param requestId string
     */
    public static async acceptClientShareRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.acceptClientShareRequest, GqlTypes.acceptClientShareRequestVariables>({
            mutation: Mutations.acceptClientShareRequest,
            variables: { requestId },
        });
    }

    /**
     * Supporter accept client share request
     * @param requestId string
     */
    public static async rejectClientShareRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.rejectClientShareRequest, GqlTypes.rejectClientShareRequestVariables>({
            mutation: Mutations.rejectClientShareRequest,
            variables: { requestId },
        });
    }

    /**
     * Supporter delete client share request
     * @param requestId string
     */
    public static async deleteClientShareRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClientShareRequest, GqlTypes.deleteClientShareRequestVariables>({
            mutation: Mutations.deleteClientShareRequest,
            variables: { requestId },
        });
    }

    /**
     * Create an agenda item
     * @param agendaItemInput AgendaItemInput
     */
    public static async createAgendaItem(agendaItemInput: GqlTypes.AgendaItemInput): Promise<GqlTypes.AgendaItem> {
        const response: GqlTypes.createAgendaItem = await GraphQLClient.mutate<GqlTypes.createAgendaItem, GqlTypes.createAgendaItemVariables>({
            mutation: Mutations.createAgendaItem,
            variables: { agendaItemInput },
        });

        return response.createAgendaItem;
    }

    /**
     * Update an agenda item
     * @param agendaItemInput AgendaItemInput
     */
    public static async updateAgendaItem(agendaItemId: string, agendaItemInput: GqlTypes.AgendaItemInput, agendaId?: string): Promise<GqlTypes.AgendaItem> {
        const response: GqlTypes.updateAgendaItem = await GraphQLClient.mutate<GqlTypes.updateAgendaItem, GqlTypes.updateAgendaItemVariables>({
            mutation: Mutations.updateAgendaItem,
            variables: { agendaItemId, agendaItemInput, agendaId },
        });

        return response.updateAgendaItem;
    }

    /**
     * Delete an agenda item
     * @param agendaItemInput AgendaItemInput
     */
    public static async deleteAgendaItem(agendaItemId: string): Promise<void> {
        const response: GqlTypes.deleteAgendaItem = await GraphQLClient.mutate<GqlTypes.deleteAgendaItem, GqlTypes.deleteAgendaItemVariables>({
            mutation: Mutations.deleteAgendaItem,
            variables: { agendaItemId },
        });

        return response.deleteAgendaItem;
    }

    public static async getContentLibrary(
        type: GqlTypes.SearchListType.agenda,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<ListResult<GqlTypes.AgendaListItem>>;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType.agendaItem,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<ListResult<GqlTypes.AgendaItemListItem>>;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType.flowchart,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<ListResult<GqlTypes.FlowchartListItem>>;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType.flowchartItem,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<ListResult<GqlTypes.FlowchartItemListItem>>;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType.asset,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<ListResult<GqlTypes.AssetListItem>>;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<
        | ListResult<GqlTypes.AgendaListItem>
        | ListResult<GqlTypes.AgendaItemListItem>
        | ListResult<GqlTypes.FlowchartListItem>
        | ListResult<GqlTypes.FlowchartItemListItem>
        | ListResult<GqlTypes.AssetListItem>
    >;
    public static async getContentLibrary(
        type: GqlTypes.SearchListType,
        isPublished?: boolean,
        listOptions?: GqlTypes.SearchListOptionsInput,
        clientId?: string | null,
        createdBy?: string | null,
        assetDirectory?: string | null,
        tags?: string[] | null,
    ): Promise<
        | ListResult<GqlTypes.AgendaListItem>
        | ListResult<GqlTypes.AgendaItemListItem>
        | ListResult<GqlTypes.FlowchartListItem>
        | ListResult<GqlTypes.FlowchartItemListItem>
        | ListResult<GqlTypes.AssetListItem>
    > {
        const data: GqlTypes.getContentLibrary = await GraphQLClient.query<GqlTypes.getContentLibrary, GqlTypes.getContentLibraryVariables>({
            query: Queries.getContentLibrary,
            variables: { options: { types: [type], isPublished, createdBy, clientId, listOptions, assetDirectory, tags } },
        });

        return data.search as any;
    }

    public static async uploadAsset(title: string, file: File, tags: string[], directoryId: string | null, onProgress: OnProgress): Promise<void> {
        await GraphQLClient.upload<GqlTypes.uploadAsset, GqlTypes.uploadAssetVariables>({
            mutation: Mutations.uploadAsset,
            variables: { title, file, tags, directoryId },
            onProgress,
            file,
        });
    }

    public static async getContentLibrarySidebarData(
        libraryType: LibraryType,
        searchParams: {
            clientId?: string;
            createdById?: string;
            searchListTypes: GqlTypes.SearchListType[];
            assetTypes?: GqlTypes.AssetType[];
            search?: string;
            limit: number;
            offset: number;
            tags?: string[];
            assetDirectory?: string | null;
            usableInSortingGame?: boolean;
        },
    ): Promise<ContentLibrary> {
        const { clientId } = searchParams; // typescript lost thread of type.
        if (libraryType === LibraryType.All && !isNil(clientId)) {
            const data: GqlTypes.getAllContentLibrarySidebarData = await GraphQLClient.query<GqlTypes.getAllContentLibrarySidebarData, GqlTypes.getAllContentLibrarySidebarDataVariables>({
                query: Queries.getAllContentLibrarySidebarData,
                variables: { ...searchParams, clientId },
            });

            return {
                myLibrary: { result: data.myLibrary.result, count: data.myLibrary.count },
                clientLibrary: { result: data.clientLibrary.result, count: data.clientLibrary.count },
            };
        } else if ((libraryType === LibraryType.My || (libraryType === LibraryType.All && isNil(clientId))) && searchParams.createdById) {
            const data: GqlTypes.getMyContentLibrarySidebarData = await GraphQLClient.query<GqlTypes.getMyContentLibrarySidebarData, GqlTypes.getMyContentLibrarySidebarDataVariables>({
                query: Queries.getMyContentLibrarySidebarData,
                variables: { ...searchParams, createdById: searchParams.createdById },
            });

            return {
                myLibrary: { result: data.myLibrary.result, count: data.myLibrary.count },
                clientLibrary: { result: [], count: 0 },
            };
        } else if (libraryType === LibraryType.Client && !isNil(clientId)) {
            const data: GqlTypes.getClientContentLibrarySidebarData = await GraphQLClient.query<GqlTypes.getClientContentLibrarySidebarData, GqlTypes.getClientContentLibrarySidebarDataVariables>({
                query: Queries.getClientContentLibrarySidebarData,
                variables: { ...searchParams, clientId },
            });

            return {
                myLibrary: { result: [], count: 0 },
                clientLibrary: { result: data.clientLibrary.result, count: data.clientLibrary.count },
            };
        } else {
            Log.warning("Invalid request type!");
            return {
                myLibrary: { result: [], count: 0 },
                clientLibrary: { result: [], count: 0 },
            };
        }
    }

    public static async getClientCalendar(clientId: string, dayFrom: Date, dayTo: Date): Promise<GqlTypes.CalendarAgenda[]> {
        const data: GqlTypes.getClientCalendar = await GraphQLClient.query<GqlTypes.getClientCalendar, GqlTypes.getClientCalendarVariables>({
            query: Queries.getClientCalendar,
            variables: { clientId, dayFrom, dayTo },
        });
        return data.search.result as GqlTypes.CalendarAgenda[];
    }

    public static async getClientSelectionBoards(clientId: string): Promise<GqlTypes.SelectionBoard[]> {
        const data: GqlTypes.getMyClientSelectionBoards = await GraphQLClient.query<GqlTypes.getMyClientSelectionBoards, GqlTypes.getMyClientSelectionBoardsVariables>({
            query: Queries.getMyClientSelectionBoards,
            variables: { clientId },
        });

        const myClients: GqlTypes.getMyClientSelectionBoards_me_myClients_result[] = data.me.myClients ? data.me.myClients.result : [];
        if (myClients.length === 0 || !myClients[0].selectionBoards) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return myClients[0].selectionBoards.result;
    }

    public static async getMyClientSelectionBoardById(clientId: string, selectionBoardId: string): Promise<GqlTypes.SelectionBoard> {
        const data: GqlTypes.getMyClientSelectionBoards = await GraphQLClient.query<GqlTypes.getMyClientSelectionBoards, GqlTypes.getMyClientSelectionBoardsVariables>({
            query: Queries.getMyClientSelectionBoards,
            variables: { clientId },
        });

        const myClients: GqlTypes.getMyClientSelectionBoards_me_myClients_result[] = data.me.myClients ? data.me.myClients.result : [];
        const foundSelectionBoard: GqlTypes.SelectionBoard | undefined = myClients[0]?.selectionBoards?.result.find((selectionBoard: GqlTypes.SelectionBoard) => {
            return selectionBoard.id === selectionBoardId;
        });

        if (typeof foundSelectionBoard === "undefined") {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return foundSelectionBoard;
    }

    public static async getClientSelectionBoardById(clientExtId: string, selectionBoardId: string): Promise<GqlTypes.SelectionBoard> {
        const data: GqlTypes.getClientSelectionBoardById = await GraphQLClient.query<GqlTypes.getClientSelectionBoardById, GqlTypes.getClientSelectionBoardByIdVariables>({
            query: Queries.getClientSelectionBoardById,
            variables: { clientExtId },
        });

        const foundSelectionBoard: GqlTypes.SelectionBoard | undefined = data.getClientByExtId?.selectionBoards?.result.find((selectionBoard: GqlTypes.SelectionBoard) => {
            return selectionBoard.id === selectionBoardId;
        });

        if (foundSelectionBoard === undefined) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return foundSelectionBoard;
    }

    public static async createSelectionBoard(clientId: string, input: GqlTypes.SelectionBoardInput): Promise<GqlTypes.SelectionBoard> {
        const data: GqlTypes.createSelectionBoard = await GraphQLClient.mutate<GqlTypes.createSelectionBoard, GqlTypes.createSelectionBoardVariables>({
            mutation: Mutations.createSelectionBoard,
            variables: { clientId, input },
        });

        return data.createSelectionBoard;
    }

    public static async updateSelectionBoard(selectionBoardId: string, input: GqlTypes.SelectionBoardInput): Promise<GqlTypes.SelectionBoard> {
        const data: GqlTypes.updateSelectionBoard = await GraphQLClient.mutate<GqlTypes.updateSelectionBoard, GqlTypes.updateSelectionBoardVariables>({
            mutation: Mutations.updateSelectionBoard,
            variables: { selectionBoardId, input },
        });

        return data.updateSelectionBoard;
    }

    public static async deleteSelectionBoard(selectionBoardId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteSelectionBoard, GqlTypes.deleteSelectionBoardVariables>({
            mutation: Mutations.deleteSelectionBoard,
            variables: { selectionBoardId },
        });
    }

    public static async getAgendaItemById(agendaItemId: string): Promise<GqlTypes.AgendaItem> {
        const data: GqlTypes.getAgendaItemById = await GraphQLClient.query<GqlTypes.getAgendaItemById, GqlTypes.getAgendaItemByIdVariables>({
            query: Queries.getAgendaItemById,
            variables: { agendaItemId },
        });

        if (!data.getAgendaItemById) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.getAgendaItemById;
    }

    public static async getAgendaItemByIdWithClientAdditionalFunctions(
        agendaItemId: string,
        clientId: string,
    ): Promise<{
        agendaItem: GqlTypes.AgendaItem;
        clientAdditionalFunctions: ClientAdditionalFunctions;
    }> {
        const data: GqlTypes.getAgendaItemByIdWithClientData = await GraphQLClient.query<GqlTypes.getAgendaItemByIdWithClientData, GqlTypes.getAgendaItemByIdWithClientDataVariables>({
            query: Queries.getAgendaItemByIdWithClientData,
            variables: { agendaItemId, clientId },
        });

        if (!data.getAgendaItemById || !data.me) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const client = data.me.myClients && data.me.myClients.result.length > 0 ? data.me.myClients.result[0] : null;
        const clientAdditionalFunctions: ClientAdditionalFunctions = {
            isMoodMeterActive: client && client.config.moodMeter ? client.config.moodMeter.enabled : false,
        };

        return { agendaItem: data.getAgendaItemById, clientAdditionalFunctions };
    }

    public static async getFlowchartItemById(flowchartItemId: string): Promise<GqlTypes.FlowchartItem> {
        const data: GqlTypes.getFlowchartItemById = await GraphQLClient.query<GqlTypes.getFlowchartItemById, GqlTypes.getFlowchartItemByIdVariables>({
            query: Queries.getFlowchartItemById,
            variables: { flowchartItemId },
        });

        if (!data.getFlowchartItemById) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.getFlowchartItemById;
    }

    public static async getFlowchartItemByIdWithClientAdditionalFunctions(
        flowchartItemId: string,
        clientId: string,
    ): Promise<{
        flowchartItem: GqlTypes.FlowchartItem;
        clientAdditionalFunctions: ClientAdditionalFunctions;
    }> {
        const data: GqlTypes.getFlowchartItemByIdWithClientData = await GraphQLClient.query<GqlTypes.getFlowchartItemByIdWithClientData, GqlTypes.getFlowchartItemByIdWithClientDataVariables>({
            query: Queries.getFlowchartItemByIdWithClientData,
            variables: { flowchartItemId, clientId },
        });

        if (!data.getFlowchartItemById || !data.me) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const client = data.me.myClients && data.me.myClients.result.length > 0 ? data.me.myClients.result[0] : null;
        const clientAdditionalFunctions: ClientAdditionalFunctions = {
            isMoodMeterActive: client && client.config.moodMeter ? client.config.moodMeter.enabled : false,
        };

        return { flowchartItem: data.getFlowchartItemById, clientAdditionalFunctions };
    }

    public static async getClientAdditionalFunctions(clientId: string): Promise<ClientAdditionalFunctions> {
        const data: GqlTypes.getAdditionalFunctions = await GraphQLClient.query<GqlTypes.getAdditionalFunctions, GqlTypes.getAdditionalFunctionsVariables>({
            query: Queries.getAdditionalFunctions,
            variables: { clientId },
        });

        const client = data.me.myClients && data.me.myClients.result.length > 0 ? data.me.myClients.result[0] : null;
        const clientData: ClientAdditionalFunctions = {
            isMoodMeterActive: client && client.config.moodMeter ? client.config.moodMeter.enabled : false,
        };

        return clientData;
    }

    public static async createFlowchartItem(flowchartItemInput: GqlTypes.FlowchartItemInput): Promise<GqlTypes.FlowchartItem> {
        const data = await GraphQLClient.mutate<GqlTypes.createFlowchartItem, GqlTypes.createFlowchartItemVariables>({
            mutation: Mutations.createFlowchartItem,
            variables: { flowchartItemInput },
        });

        return data.createFlowchartItem;
    }

    public static async updateFlowchartItem(flowchartItemId: string, flowchartItemInput: GqlTypes.FlowchartItemInput): Promise<GqlTypes.FlowchartItem> {
        const data = await GraphQLClient.mutate<GqlTypes.updateFlowchartItem, GqlTypes.updateFlowchartItemVariables>({
            mutation: Mutations.updateFlowchartItem,
            variables: { flowchartItemId, flowchartItemInput },
        });

        return data.updateFlowchartItem;
    }

    public static async deleteFlowchartItem(flowchartItemId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteFlowchartItem, GqlTypes.deleteFlowchartItemVariables>({
            mutation: Mutations.deleteFlowchartItem,
            variables: { flowchartItemId },
        });
    }

    public static async deleteAsset(assetId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAsset, GqlTypes.deleteAssetVariables>({
            mutation: Mutations.deleteAsset,
            variables: { assetId },
        });
    }

    public static async getFlowchartById(flowchartId: string): Promise<GqlTypes.Flowchart> {
        const data: GqlTypes.getFlowchartById = await GraphQLClient.query<GqlTypes.getFlowchartById, GqlTypes.getFlowchartByIdVariables>({
            query: Queries.getFlowchartById,
            variables: { flowchartId },
        });

        if (!data.getFlowchartById) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
        return ApiTypes.reorderFlowchartItemList(data.getFlowchartById);
    }

    public static async createFlowchart(flowchartInput: GqlTypes.FlowchartInput): Promise<GqlTypes.Flowchart> {
        const data = await GraphQLClient.mutate<GqlTypes.createFlowchart, GqlTypes.createFlowchartVariables>({
            mutation: Mutations.createFlowchart,
            variables: { flowchartInput },
        });

        return ApiTypes.reorderFlowchartItemList(data.createFlowchart);
    }

    public static async updateFlowchart(flowchartId: string, flowchartInput: GqlTypes.FlowchartInput): Promise<GqlTypes.Flowchart> {
        const data = await GraphQLClient.mutate<GqlTypes.updateFlowchart, GqlTypes.updateFlowchartVariables>({
            mutation: Mutations.updateFlowchart,
            variables: { flowchartId, flowchartInput },
        });

        return ApiTypes.reorderFlowchartItemList(data.updateFlowchart);
    }

    public static async deleteFlowchart(flowchartId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteFlowchart, GqlTypes.deleteFlowchartVariables>({
            mutation: Mutations.deleteFlowchart,
            variables: { flowchartId },
        });
    }

    public static async getAgendaById(agendaId: string): Promise<GqlTypes.Agenda> {
        const data: GqlTypes.getAgendaById = await GraphQLClient.query<GqlTypes.getAgendaById, GqlTypes.getAgendaByIdVariables>({
            query: Queries.getAgendaById,
            variables: { agendaId },
        });

        if (!data.getAgendaById) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return ApiTypes.reorderAgendaItemList(data.getAgendaById);
    }

    public static async createAgenda(agendaInput: GqlTypes.AgendaInput): Promise<GqlTypes.Agenda> {
        const data = await GraphQLClient.mutate<GqlTypes.createAgenda, GqlTypes.createAgendaVariables>({
            mutation: Mutations.createAgenda,
            variables: { agendaInput },
        });

        return ApiTypes.reorderAgendaItemList(data.createAgenda);
    }

    public static async updateAgenda(agendaId: string, agendaInput: GqlTypes.FlowchartInput): Promise<GqlTypes.Agenda> {
        const data = await GraphQLClient.mutate<GqlTypes.updateAgenda, GqlTypes.updateAgendaVariables>({
            mutation: Mutations.updateAgenda,
            variables: { agendaId, agendaInput },
        });

        return ApiTypes.reorderAgendaItemList(data.updateAgenda);
    }

    public static async deleteAgenda(agendaId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteAgenda, GqlTypes.deleteAgendaVariables>({
            mutation: Mutations.deleteAgenda,
            variables: { agendaId },
        });
    }

    public static async copyAgendaToClientAgendaLibrary(clientId: string, agendaId: string, day: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.copyAgendaToClientAgendaLibrary, GqlTypes.copyAgendaToClientAgendaLibraryVariables>({
            mutation: Mutations.copyAgendaToClientAgendaLibrary,
            variables: { clientId, agendaId, day },
        });
    }

    public static async setClientDefaultAgenda(clientId: string, agendaId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.setClientDefaultAgenda, GqlTypes.setClientDefaultAgendaVariables>({
            mutation: Mutations.setClientDefaultAgenda,
            variables: { clientId, agendaId },
        });
    }

    public static async unsetClientDefaultAgenda(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.unsetClientDefaultAgenda, GqlTypes.unsetClientDefaultAgendaVariables>({
            mutation: Mutations.unsetClientDefaultAgenda,
            variables: { clientId },
        });
    }

    /**
     * Delete an attached device from a client
     * @param deviceId Device id
     */
    public static async deleteDevice(deviceId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteDevice, GqlTypes.deleteDeviceVariables>({
            mutation: Mutations.deleteDevice,
            variables: { deviceId },
        });
    }

    public static async copyContentToPublicLibrary(
        content: GqlTypes.AgendaListItem | GqlTypes.AgendaItemListItem | GqlTypes.FlowchartListItem | GqlTypes.FlowchartItemListItem | GqlTypes.AssetContent,
    ): Promise<void> {
        let mutation: DocumentNode | null = null;
        switch (content.__typename) {
            case "Agenda":
                mutation = Mutations.copyAgendaToPublicLibrary;
                break;
            case "AgendaItem":
                mutation = Mutations.copyAgendaItemToPublicLibrary;
                break;
            case "Flowchart":
                mutation = Mutations.copyFlowchartToPublicLibrary;
                break;
            case "FlowchartItem":
                mutation = Mutations.copyFlowchartItemToPublicLibrary;
                break;
            case "Asset":
                mutation = Mutations.copyAssetToPublicLibrary;
                break;
            default:
                throw new ApiError(ApiErrorCode.NOT_IMPLEMENTED);
        }

        await GraphQLClient.mutate({ mutation, variables: { id: content.id } });
    }

    public static async copyContentToClientLibrary(
        clientId: string,
        content: GqlTypes.AgendaListItem | GqlTypes.AgendaItemListItem | GqlTypes.FlowchartListItem | GqlTypes.FlowchartItemListItem | GqlTypes.AssetContent,
    ): Promise<void> {
        let mutation: DocumentNode | null = null;
        switch (content.__typename) {
            case "Agenda":
                mutation = Mutations.copyAgendaToClientLibrary;
                break;
            case "AgendaItem":
                mutation = Mutations.copyAgendaItemToClientLibrary;
                break;
            case "Flowchart":
                mutation = Mutations.copyFlowchartToClientLibrary;
                break;
            case "FlowchartItem":
                mutation = Mutations.copyFlowchartItemToClientLibrary;
                break;
            default:
                throw new ApiError(ApiErrorCode.NOT_IMPLEMENTED);
        }

        await GraphQLClient.mutate({ mutation, variables: { id: content.id, clientId } });
    }

    public static async enableContent(
        content:
            | GqlTypes.AgendaListItem
            | GqlTypes.AgendaItemListItem
            | GqlTypes.FlowchartListItem
            | GqlTypes.FlowchartItemListItem
            | GqlTypes.AssetContent
            | EverydaySituationDirectory
            | EverydaySituation
            | GqlTypes.InstantAwardListItem
            | GqlTypes.AwardListItem
            | GqlTypes.SelectionBoardListItem
            | GqlTypes.SelectionBoard,
    ): Promise<void> {
        let mutation: DocumentNode | null = null;
        switch (content.__typename) {
            case "Agenda":
                mutation = Mutations.enableAgenda;
                break;
            case "AgendaItem":
                mutation = Mutations.enableAgendaItem;
                break;
            case "Flowchart":
                mutation = Mutations.enableFlowchart;
                break;
            case "FlowchartItem":
                mutation = Mutations.enableFlowchartItem;
                break;
            case "Asset":
                mutation = Mutations.enableAsset;
                break;
            case "EverydaySituationDirectory":
                mutation = Mutations.enableEverydaySituationDirectory;
                break;
            case "ImageEverydaySituation":
            case "AudioEverydaySituation":
            case "TextAndImageEverydaySituation":
            case "TextEverydaySituation":
                mutation = Mutations.enableEverydaySituation;
                break;
            case "InstantAward":
                mutation = Mutations.enableInstantAward;
                break;
            case "Award":
                mutation = Mutations.enableAward;
                break;
            case "SelectionBoard":
                mutation = Mutations.enableSelectionBoard;
                break;
            default:
                throw new ApiError(ApiErrorCode.NOT_IMPLEMENTED);
        }

        await GraphQLClient.mutate({ mutation, variables: { id: content.id } });
    }

    public static async disableContent(
        content:
            | GqlTypes.AgendaListItem
            | GqlTypes.AgendaItemListItem
            | GqlTypes.FlowchartListItem
            | GqlTypes.FlowchartItemListItem
            | GqlTypes.AssetContent
            | EverydaySituationDirectory
            | EverydaySituation
            | GqlTypes.InstantAwardListItem
            | GqlTypes.AwardListItem
            | GqlTypes.SelectionBoardListItem
            | GqlTypes.SelectionBoard,
    ): Promise<void> {
        let mutation: DocumentNode | null = null;
        switch (content.__typename) {
            case "Agenda":
                mutation = Mutations.disableAgenda;
                break;
            case "AgendaItem":
                mutation = Mutations.disableAgendaItem;
                break;
            case "Flowchart":
                mutation = Mutations.disableFlowchart;
                break;
            case "FlowchartItem":
                mutation = Mutations.disableFlowchartItem;
                break;
            case "Asset":
                mutation = Mutations.disableAsset;
                break;
            case "EverydaySituationDirectory":
                mutation = Mutations.disableEverydaySituationDirectory;
                break;
            case "ImageEverydaySituation":
            case "AudioEverydaySituation":
            case "TextAndImageEverydaySituation":
            case "TextEverydaySituation":
                mutation = Mutations.disableEverydaySituation;
                break;
            case "InstantAward":
                mutation = Mutations.disableInstantAward;
                break;
            case "Award":
                mutation = Mutations.disableAward;
                break;
            case "SelectionBoard":
                mutation = Mutations.disableSelectionBoard;
                break;
            default:
                throw new ApiError(ApiErrorCode.NOT_IMPLEMENTED);
        }

        await GraphQLClient.mutate({ mutation, variables: { id: content.id } });
    }

    public static async syncClientAgendas(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.syncClientAgendas, GqlTypes.syncClientAgendasVariables>({
            mutation: Mutations.syncClientAgendas,
            variables: { clientId },
        });
    }

    public static async copyContentToAccountPersonalLibrary(
        content:
            | GqlTypes.AgendaListItem
            | GqlTypes.AgendaItemListItem
            | GqlTypes.FlowchartListItem
            | GqlTypes.FlowchartItemListItem
            | AnyContentType
            | GqlTypes.CalendarAgenda
            | GqlTypes.EverydaySituationDirectory,
    ): Promise<void> {
        let mutation: DocumentNode | null = null;
        switch (content.__typename) {
            case "Agenda":
                mutation = Mutations.copyAgendaToAccountPersonalLibrary;
                break;
            case "AgendaItem":
                mutation = Mutations.copyAgendaItemToAccountPersonalLibrary;
                break;
            case "Flowchart":
                mutation = Mutations.copyFlowchartToAccountPersonalLibrary;
                break;
            case "FlowchartItem":
                mutation = Mutations.copyFlowchartItemToAccountPersonalLibrary;
                break;
            default:
                throw new ApiError(ApiErrorCode.NOT_IMPLEMENTED);
        }

        await GraphQLClient.mutate({ mutation, variables: { id: content.id } });
    }

    public static async getAppEventLog(
        params: {
            dateTimeFrom: Date;
            dateTimeTo: Date;
            appEventLogName?: GqlTypes.AppEventLogName;
            authAccountExtId?: string;
            authAccountId?: string;
            clientExtId?: string;
        },
        options?: GqlTypes.AppEventLogListOptionsInput,
    ): Promise<AppEventLogResult> {
        const data: GqlTypes.getAppEventLogList = await GraphQLClient.query<GqlTypes.getAppEventLogList, GqlTypes.getAppEventLogListVariables>({
            query: Queries.getAppEventLogList,
            variables: {
                type: params.appEventLogName,
                date: {
                    from: params.dateTimeFrom,
                    to: params.dateTimeTo,
                },
                authAccountExtId: params.authAccountExtId,
                authAccountId: params.authAccountId,
                clientExtId: params.clientExtId,
                options,
            },
        });

        return data.getAppEventLogList;
    }

    public static async createClientChangeManagerRequest(clientId: string, accountId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.createClientChangeManagerRequest, GqlTypes.createClientChangeManagerRequestVariables>({
            mutation: Mutations.createClientChangeManagerRequest,
            variables: { clientId, accountId },
        });
    }

    public static async deleteClientChangeManagerRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClientChangeManagerRequest, GqlTypes.deleteClientChangeManagerRequestVariables>({
            mutation: Mutations.deleteClientChangeManagerRequest,
            variables: { requestId },
        });
    }

    public static async acceptClientChangeManagerRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.acceptClientChangeManagerRequest, GqlTypes.acceptClientChangeManagerRequestVariables>({
            mutation: Mutations.acceptClientChangeManagerRequest,
            variables: { requestId },
        });
    }

    public static async rejectClientChangeManagerRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.rejectClientChangeManagerRequest, GqlTypes.rejectClientChangeManagerRequestVariables>({
            mutation: Mutations.rejectClientChangeManagerRequest,
            variables: { requestId },
        });
    }

    public static async uploadClientIntroductionAudio(clientId: string, file: File, onProgress?: OnProgress): Promise<GqlTypes.Asset> {
        const data: GqlTypes.uploadClientIntroductionAudio = await GraphQLClient.upload<GqlTypes.uploadClientIntroductionAudio, GqlTypes.uploadClientIntroductionAudioVariables>({
            mutation: Mutations.uploadClientIntroductionAudio,
            variables: { clientId, file },
            onProgress,
            file,
        });

        if (!data.uploadClientIntroductionAudio) {
            throw new ApiError(ApiErrorCode.ASSET_NOT_FOUND);
        }

        return data.uploadClientIntroductionAudio;
    }

    public static async deleteClientIntroductionAudio(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClientIntroductionAudio, GqlTypes.deleteClientIntroductionAudioVariables>({
            mutation: Mutations.deleteClientIntroductionAudio,
            variables: { clientId },
        });
    }

    public static async uploadClientIntroductionVideo(clientId: string, file: File, onProgress?: OnProgress): Promise<GqlTypes.Asset> {
        const data: GqlTypes.uploadClientIntroductionVideo = await GraphQLClient.upload<GqlTypes.uploadClientIntroductionVideo, GqlTypes.uploadClientIntroductionVideoVariables>({
            mutation: Mutations.uploadClientIntroductionVideo,
            variables: { clientId, file },
            onProgress,
            file,
        });

        if (!data.uploadClientIntroductionVideo) {
            throw new ApiError(ApiErrorCode.ASSET_NOT_FOUND);
        }

        return data.uploadClientIntroductionVideo;
    }

    public static async deleteClientIntroductionVideo(clientId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteClientIntroductionVideo, GqlTypes.deleteClientIntroductionVideoVariables>({
            mutation: Mutations.deleteClientIntroductionVideo,
            variables: { clientId },
        });
    }

    public static async getMyAssetById(assetId: string): Promise<GqlTypes.Asset> {
        const response: GqlTypes.getMyAssetById = await GraphQLClient.query<GqlTypes.getMyAssetById, GqlTypes.getMyAssetByIdVariables>({
            query: Queries.getMyAssetById,
            variables: { assetId },
        });

        return response.getAssetById;
    }

    /**
     * Update an asset
     * @param assetId Asset's id
     * @param title Asset's title
     * @param tags Asset's tags
     */
    public static async updateAsset(assetId: string, title: string, tags: string[]): Promise<GqlTypes.Asset> {
        const response: GqlTypes.updateAsset = await GraphQLClient.mutate<GqlTypes.updateAsset, GqlTypes.updateAssetVariables>({
            mutation: Mutations.updateAsset,
            variables: { assetId, title, tags },
        });

        return response.updateAsset;
    }

    /**
     * Set self supporting decision
     * @param variables GqlTypes.setSelfSupportingStateDecisionVariables
     */
    public static async setSelfSupportingStateDecision(variables: GqlTypes.setSelfSupportingStateDecisionVariables): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.setSelfSupportingStateDecision, GqlTypes.setSelfSupportingStateDecisionVariables>({
            mutation: Mutations.setSelfSupportingStateDecision,
            variables,
        });
    }

    /**
     * Get tags for supporter
     * @param variables GqlTypes.getTagsVariables
     */
    public static async getTags(variables?: GqlTypes.getTagsVariables): Promise<GqlTypes.getTags> {
        return await GraphQLClient.query<GqlTypes.getTags, GqlTypes.getTagsVariables>({
            query: Queries.getTags,
            variables,
        });
    }

    /**
     * Get tags for super admin
     * @param variables GqlTypes.getAdminTagsVariables
     */
    public static async getAdminTags(variables?: GqlTypes.TagListInput): Promise<ListResult<GqlTypes.AdminTag>> {
        const tags = await GraphQLClient.query<GqlTypes.getAdminTags, GqlTypes.getAdminTagsVariables>({
            query: Queries.getAdminTags,
            variables: {
                options: variables,
            },
        });
        if (!tags.getTags) {
            return {
                result: [],
                count: 0,
            };
        }
        return tags.getTags;
    }

    public static async copyAssetToAccountPersonalLibrary(variables: GqlTypes.copyAssetToAccountPersonalLibraryVariables): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.copyAssetToAccountPersonalLibrary, GqlTypes.copyAssetToAccountPersonalLibraryVariables>({
            mutation: Mutations.copyAssetToAccountPersonalLibrary,
            variables,
        });
    }

    public static async evaluateClientQuestionnaire(variables: GqlTypes.evaluateClientQuestionnaireVariables): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.evaluateClientQuestionnaire, GqlTypes.evaluateClientQuestionnaireVariables>({
            mutation: Mutations.evaluateClientQuestionnaire,
            variables,
        });
    }

    public static async getClientSpareAgendaItems(clientId: string): Promise<GqlTypes.getClientSpareAgendaItems_getClientSpareAgendaItems[]> {
        const response: GqlTypes.getClientSpareAgendaItems = await GraphQLClient.query<GqlTypes.getClientSpareAgendaItems, GqlTypes.getClientSpareAgendaItemsVariables>({
            query: Queries.getClientSpareAgendaItems,
            variables: { clientId },
        });

        return response.getClientSpareAgendaItems;
    }

    public static async updateClientSpareAgendaItems(clientId: string, agendaItemIds: string[]): Promise<GqlTypes.AgendaItemContent[]> {
        const response: GqlTypes.updateClientSpareAgendaItems = await GraphQLClient.mutate<GqlTypes.updateClientSpareAgendaItems, GqlTypes.updateClientSpareAgendaItemsVariables>({
            mutation: Mutations.updateClientSpareAgendaItems,
            variables: { clientId, agendaItemIds },
        });

        return response.updateClientSpareAgendaItems;
    }

    public static async getEverydaySituationClientDirectoryByClientId(clientId: string): Promise<GqlTypes.EverydaySituationClientDirectory> {
        const result = await GraphQLClient.query<GqlTypes.getEverydaySituationClientDirectoryByClientId, GqlTypes.getEverydaySituationClientDirectoryByClientIdVariables>({
            query: Queries.getEverydaySituationClientDirectoryByClientId,
            variables: {
                id: clientId,
            },
        });

        return result.getEverydaySituationClientDirectoryByClientId;
    }

    public static async updateEverydaySituationClientDirectory(input: GqlTypes.UpdateEverydaySituationClientDirectoryInput): Promise<GqlTypes.EverydaySituationClientDirectory> {
        const result = await GraphQLClient.mutate<GqlTypes.updateEverydaySituationClientDirectory, GqlTypes.updateEverydaySituationClientDirectoryVariables>({
            mutation: Mutations.updateEverydaySituationClientDirectory,
            variables: { input },
        });

        return result.updateEverydaySituationClientDirectory;
    }

    public static async getEverydaySituationDirectoriesByClientId(clientId: string): Promise<GqlTypes.EverydaySituationDirectoryWithItemCount[]> {
        const result = await GraphQLClient.query<GqlTypes.getEverydaySituationDirectoriesByClientId, GqlTypes.getEverydaySituationDirectoriesByClientIdVariables>({
            query: Queries.getEverydaySituationDirectoriesByClientId,
            variables: {
                id: clientId,
            },
        });

        return (result.getEverydaySituationDirectoriesByClientId || []).sort((x1, x2) => x1.position! - x2.position!);
    }

    public static async getEverydaySituationDirectoryById(directoryId: string): Promise<GqlTypes.EverydaySituationDirectory> {
        const result = await GraphQLClient.query<GqlTypes.getEverydaySituationDirectoryById, GqlTypes.getEverydaySituationDirectoryByIdVariables>({
            query: Queries.getEverydaySituationDirectoryById,
            variables: {
                id: directoryId,
            },
        });

        return result.getEverydaySituationDirectoryById;
    }

    public static async createEverydaySituationDirectory(input: GqlTypes.CreateEverydaySituationDirectoryInput): Promise<GqlTypes.EverydaySituationDirectory> {
        const result = await GraphQLClient.mutate<GqlTypes.createEverydaySituationDirectory, GqlTypes.createEverydaySituationDirectoryVariables>({
            mutation: Mutations.createEverydaySituationDirectory,
            variables: { input },
        });

        return result.createEverydaySituationDirectory;
    }

    public static async updateEverydaySituationDirectory(input: GqlTypes.UpdateEverydaySituationDirectoryInput): Promise<GqlTypes.EverydaySituationDirectory> {
        const result = await GraphQLClient.mutate<GqlTypes.updateEverydaySituationDirectory, GqlTypes.updateEverydaySituationDirectoryVariables>({
            mutation: Mutations.updateEverydaySituationDirectory,
            variables: { input },
        });

        return result.updateEverydaySituationDirectory;
    }

    public static async deleteEverydaySituationDirectory(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteEverydaySituationDirectory, GqlTypes.deleteEverydaySituationDirectoryVariables>({
            mutation: Mutations.deleteEverydaySituationDirectory,
            variables: { id },
        });
    }

    public static async createEverydaySituation(input: GqlTypes.CreateEverydaySituationInput): Promise<GqlTypes.EverydaySituation> {
        const result = await GraphQLClient.mutate<GqlTypes.createEverydaySituation, GqlTypes.createEverydaySituationVariables>({
            mutation: Mutations.createEverydaySituation,
            variables: { input },
        });

        return result.createEverydaySituation;
    }

    public static async updateEverydaySituation(input: GqlTypes.UpdateEverydaySituationInput): Promise<GqlTypes.EverydaySituation> {
        const result = await GraphQLClient.mutate<GqlTypes.updateEverydaySituation, GqlTypes.updateEverydaySituationVariables>({
            mutation: Mutations.updateEverydaySituation,
            variables: { input },
        });

        return result.updateEverydaySituation;
    }

    public static async deleteEverydaySituation(id: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteEverydaySituation, GqlTypes.deleteEverydaySituationVariables>({
            mutation: Mutations.deleteEverydaySituation,
            variables: { id },
        });
    }

    public static async orderEverydaySituationDirectories(clientId: string, orderInput: GqlTypes.EverydaySituationDirectoryOrderInput[]) {
        await GraphQLClient.mutate<GqlTypes.orderEverydaySituationDirectories, GqlTypes.orderEverydaySituationDirectoriesVariables>({
            mutation: Mutations.orderEverydaySituationDirectories,
            variables: { clientId, orderInput },
        });
    }

    public static async getFaqs(): Promise<GqlTypes.getFaqs_getFaqItemList[]> {
        const data: GqlTypes.getFaqs = await GraphQLClient.query<GqlTypes.getFaqs>({
            query: Queries.getFaqs,
        });

        return data.getFaqItemList;
    }

    public static async deleteFaq(faqItemId: string) {
        await GraphQLClient.mutate<GqlTypes.deleteFaq>({
            mutation: Mutations.deleteFaq,
            variables: { faqItemId },
        });
    }

    public static async orderFaqs(orderInput: GqlTypes.FaqItemOrderInput[]) {
        await GraphQLClient.mutate<GqlTypes.orderFaqs>({
            mutation: Mutations.orderFaqs,
            variables: { orderInput },
        });
    }

    public static async getFaqById(faqItemId: string): Promise<GqlTypes.getFaqById_getFaqItemById> {
        const data: GqlTypes.getFaqById = await GraphQLClient.query<GqlTypes.getFaqById>({
            query: Queries.getFaqById,
            variables: { faqItemId },
        });

        return data.getFaqItemById;
    }

    public static async createFaq(input: GqlTypes.CreateFaqItemInput): Promise<GqlTypes.CreateFaqItemInput> {
        const data: GqlTypes.createFaq = await GraphQLClient.mutate<GqlTypes.createFaq>({
            mutation: Mutations.createFaq,
            variables: { input },
        });

        return { question: data.createFaqItem.question, answer: data.createFaqItem.question };
    }

    public static async updateFaq(faqItemId: string, input: GqlTypes.UpdateFaqItemInput): Promise<GqlTypes.UpdateFaqItemInput> {
        const data: GqlTypes.updateFaq = await GraphQLClient.mutate<GqlTypes.updateFaq>({
            mutation: Mutations.updateFaq,
            variables: { faqItemId, input },
        });

        return { question: data.updateFaqItem.question, answer: data.updateFaqItem.answer };
    }

    public static async getPersonalAssetDirectoriesByParentId(parentId: string | null = null): Promise<GqlTypes.getPersonalAssetDirectoriesByParentId> {
        return await GraphQLClient.query<GqlTypes.getPersonalAssetDirectoriesByParentId>({
            query: Queries.getPersonalAssetDirectoriesByParentId,
            variables: {
                parentId,
            },
        });
    }

    public static async getPublicAssetDirectoriesByParentId(parentId: string | null = null): Promise<GqlTypes.getPublicAssetDirectoriesByParentId> {
        return await GraphQLClient.query<GqlTypes.getPublicAssetDirectoriesByParentId>({
            query: Queries.getPublicAssetDirectoriesByParentId,
            variables: {
                parentId,
            },
        });
    }

    public static async createPersonalAssetDirectory(input: GqlTypes.CreateAssetDirectoryInput): Promise<void> {
        const result = await GraphQLClient.mutate<GqlTypes.createPersonalAssetDirectory>({
            mutation: Mutations.createPersonalAssetDirectory,
            variables: { input },
        });

        if (!result.createPersonalAssetDirectory) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }
    }

    public static async createPublicAssetDirectory(input: GqlTypes.CreateAssetDirectoryInput): Promise<void> {
        const result = await GraphQLClient.mutate<GqlTypes.createPublicAssetDirectory>({
            mutation: Mutations.createPublicAssetDirectory,
            variables: { input },
        });

        if (!result.createPublicAssetDirectory) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }
    }

    public static async moveAssetsToPersonalAssetDirectory(input: GqlTypes.moveAssetsToPersonalAssetDirectoryVariables): Promise<GqlTypes.moveAssetsToPersonalAssetDirectory> {
        return await GraphQLClient.mutate<GqlTypes.moveAssetsToPersonalAssetDirectory>({
            mutation: Mutations.moveAssetsToPersonalAssetDirectory,
            variables: input,
        });
    }

    public static async moveAssetsToPublicAssetDirectory(input: GqlTypes.moveAssetsToPublicAssetDirectoryVariables): Promise<GqlTypes.moveAssetsToPublicAssetDirectory> {
        return await GraphQLClient.mutate<GqlTypes.moveAssetsToPublicAssetDirectory>({
            mutation: Mutations.moveAssetsToPublicAssetDirectory,
            variables: input,
        });
    }

    public static async replaceAsset(input: GqlTypes.replaceAssetVariables): Promise<GqlTypes.replaceAsset> {
        return await GraphQLClient.mutate<GqlTypes.replaceAsset, GqlTypes.replaceAssetVariables>({
            mutation: Mutations.replaceAsset,
            variables: input,
        });
    }

    public static async orderSelectionBoards(clientId: string, orderInput: GqlTypes.SelectionBoardOrderInput[]) {
        await GraphQLClient.mutate<GqlTypes.orderSelectionBoards>({
            mutation: Mutations.orderSelectionBoards,
            variables: { clientId, orderInput },
        });
    }
    public static async getClientGameConfig(clientId: string): Promise<GqlTypes.ClientGamesConfig> {
        const data = await GraphQLClient.query<GqlTypes.getClientGameConfig, GqlTypes.getClientGameConfigVariables>({
            query: Queries.getClientGameConfig,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.me.myClients.result[0].config;
    }

    public static async getClientMemoryGameConfig(clientId: string): Promise<GqlTypes.getClientMemoryGameConfig> {
        return await GraphQLClient.query<GqlTypes.getClientMemoryGameConfig, GqlTypes.getClientMemoryGameConfigVariables>({
            query: Queries.getClientMemoryGameConfig,
            variables: { clientId },
        });
    }

    public static async getClientSortingGameConfig(clientId: string): Promise<GqlTypes.Flowchart[]> {
        const data = await GraphQLClient.query<GqlTypes.getClientSortingGameConfig, GqlTypes.getClientSortingGameConfigVariables>({
            query: Queries.getClientSortingGameConfig,
            variables: { clientId },
        });

        return data.getClientSortingGameConfig;
    }

    public static async updateClientMemoryGameConfig(clientId: string, memoryGameConfig: GqlTypes.MemoryGameConfigInput): Promise<GqlTypes.updateClientMemoryGameConfig> {
        return await GraphQLClient.mutate<GqlTypes.updateClientMemoryGameConfig, GqlTypes.updateClientMemoryGameConfigVariables>({
            mutation: Mutations.updateClientMemoryGameConfig,
            variables: { clientId, memoryGameConfig },
        });
    }

    public static async updateClientSortingGameConfig(clientId: string, flowchartIds: string[]): Promise<GqlTypes.Flowchart[]> {
        const data = await GraphQLClient.mutate<GqlTypes.updateClientSortingGameConfig, GqlTypes.updateClientSortingGameConfigVariables>({
            mutation: Mutations.updateClientSortingGameConfig,
            variables: { clientId, flowchartIds },
        });

        return data.updateClientSortingGameConfig;
    }

    /**
     * Get my clients, filter by clientId & get client's instant awards
     * @param clientId string client id
     */
    public static async getClientInstantAwards(clientId: string): Promise<GqlTypes.InstantAward[]> {
        const data = await GraphQLClient.query<GqlTypes.getClientInstantAwards, GqlTypes.getClientInstantAwardsVariables>({
            query: Queries.getClientInstantAwards,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getClientInstantAwards_me_myClients_result = data.me.myClients.result[0];

        return result.instantAwards?.result || [];
    }

    public static async getClientInstantAwardById(clientId: string, clientInstantAwardId: string): Promise<GqlTypes.InstantAward> {
        const data = await GraphQLClient.query<GqlTypes.getClientInstantAwards, GqlTypes.getClientInstantAwardsVariables>({
            query: Queries.getClientInstantAwards,
            variables: { clientId },
        });

        const foundAward: GqlTypes.InstantAward | undefined = data.me.myClients?.result[0].instantAwards?.result.find(
            (instantAward: GqlTypes.InstantAward) => instantAward.id === clientInstantAwardId,
        );

        if (!isNil(foundAward)) {
            return foundAward;
        } else {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
    }

    public static async createClientInstantAward(clientId: string, input: GqlTypes.InstantAwardInput): Promise<GqlTypes.InstantAward> {
        const data: GqlTypes.createClientInstantAward = await GraphQLClient.mutate<GqlTypes.createClientInstantAward>({
            mutation: Mutations.createClientInstantAward,
            variables: { clientId, input },
        });

        return data.createInstantAward;
    }

    public static async updateClientInstantAward(instantAwardId: string, input: GqlTypes.InstantAwardInput): Promise<GqlTypes.InstantAward> {
        const data: GqlTypes.updateClientInstantAward = await GraphQLClient.mutate<GqlTypes.updateClientInstantAward>({
            mutation: Mutations.updateClientInstantAward,
            variables: { instantAwardId, input },
        });

        return data.updateInstantAward;
    }

    public static async deleteClientInstantAward(instantAwardId: string) {
        await GraphQLClient.mutate<GqlTypes.deleteClientInstantAward>({
            mutation: Mutations.deleteClientInstantAward,
            variables: { instantAwardId },
        });
    }

    /**
     * Get my clients, filter by clientId & get client's awards
     * @param clientId string client id
     */
    public static async getClientAwards(clientId: string): Promise<GqlTypes.Award[]> {
        const data = await GraphQLClient.query<GqlTypes.getClientAwards, GqlTypes.getClientAwardsVariables>({
            query: Queries.getClientAwards,
            variables: { clientId },
        });

        if (!data.me.myClients || data.me.myClients.result.length === 0) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        const result: GqlTypes.getClientAwards_me_myClients_result = data.me.myClients.result[0];

        return result.awards?.result || [];
    }

    public static async getClientAwardById(clientId: string, clientAwardId: string): Promise<GqlTypes.Award> {
        const data = await GraphQLClient.query<GqlTypes.getClientAwards, GqlTypes.getClientAwardsVariables>({
            query: Queries.getClientAwards,
            variables: { clientId },
        });

        const foundAward: GqlTypes.Award | undefined = data.me.myClients?.result[0].awards?.result.find((award: GqlTypes.Award) => award.id === clientAwardId);

        if (!isNil(foundAward)) {
            return foundAward;
        } else {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
    }

    public static async createClientAward(clientId: string, input: GqlTypes.AwardInput): Promise<GqlTypes.Award> {
        const data: GqlTypes.createClientAward = await GraphQLClient.mutate<GqlTypes.createClientAward>({
            mutation: Mutations.createClientAward,
            variables: { clientId, input },
        });

        return data.createAward;
    }

    public static async updateClientAward(awardId: string, input: GqlTypes.AwardInput): Promise<GqlTypes.Award> {
        const data: GqlTypes.updateClientAward = await GraphQLClient.mutate<GqlTypes.updateClientAward>({
            mutation: Mutations.updateClientAward,
            variables: { awardId, input },
        });

        return data.updateAward;
    }

    public static async deleteClientAward(awardId: string) {
        await GraphQLClient.mutate<GqlTypes.deleteClientAward>({
            mutation: Mutations.deleteClientAward,
            variables: { awardId },
        });
    }

    public static async activateAward(awardId: string) {
        await GraphQLClient.mutate<GqlTypes.activateAward>({
            mutation: Mutations.activateAward,
            variables: { awardId },
        });
    }

    public static async createTag(variables: GqlTypes.createTagVariables): Promise<GqlTypes.AdminTag> {
        return await GraphQLClient.mutate<GqlTypes.AdminTag, GqlTypes.createTagVariables>({
            mutation: Mutations.createTag,
            variables,
        });
    }

    public static async deleteTag(tagId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deleteTag, GqlTypes.deleteTagVariables>({
            mutation: Mutations.deleteTag,
            variables: { tagId },
        });
    }

    public static async getDisabledPublicContent(variables: GqlTypes.getDisabledPublicContentVariables): Promise<ListResult<AnyDisabledItemType>> {
        const response = await GraphQLClient.query<GqlTypes.getDisabledPublicContent, GqlTypes.getDisabledPublicContentVariables>({
            query: Queries.getDisabledPublicContent,
            variables,
        });
        return response.getDisabledContentList;
    }

    public static async getEducationModules(): Promise<GqlTypes.EducationContentModuleListItem[]> {
        const response = await GraphQLClient.query<GqlTypes.getEducationModules>({
            query: Queries.getEducationModules,
        });
        return response.educationModules || [];
    }

    public static async orderEducationContentModules(parentModuleId: string, input: GqlTypes.EducationContentModuleOrderInput[]) {
        await GraphQLClient.mutate<GqlTypes.orderEducationContentModules>({
            mutation: Mutations.orderEducationContentModules,
            variables: { parentModuleId, input },
        });
    }

    public static async createEducationContentModule(input: GqlTypes.CreateEducationContentModuleInput): Promise<GqlTypes.EducationContentSubModule> {
        const data: GqlTypes.createEducationContentModule = await GraphQLClient.mutate<GqlTypes.createEducationContentModule, GqlTypes.createEducationContentModuleVariables>({
            mutation: Mutations.createEducationContentModule,
            variables: { input },
        });

        return data.createEducationContentModule;
    }

    public static async updateEducationContentModule(input: GqlTypes.UpdateEducationContentModuleInput): Promise<GqlTypes.EducationContentSubModule> {
        const data: GqlTypes.updateEducationContentModule = await GraphQLClient.mutate<GqlTypes.updateEducationContentModule>({
            mutation: Mutations.updateEducationContentModule,
            variables: { input },
        });

        return data.updateEducationContentModule;
    }

    public static async getEducationSubModuleById(moduleId: string, subModuleId: string): Promise<GqlTypes.EducationContentSubModule> {
        const response = await GraphQLClient.query<GqlTypes.getEducationModuleById, GqlTypes.getEducationModuleByIdVariables>({
            query: Queries.getEducationModuleById,
            variables: { id: moduleId },
        });

        const foundSubModule: GqlTypes.EducationContentSubModule | undefined = response.educationModule.subModules?.find(
            (subModule: GqlTypes.EducationContentSubModule) => subModule.id === subModuleId,
        );

        if (!isNil(foundSubModule)) {
            return foundSubModule;
        } else {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
    }

    public static async deleteSubModule(subModuleId: string) {
        await GraphQLClient.mutate<GqlTypes.deleteSubModule>({
            mutation: Mutations.deleteSubModule,
            variables: { id: subModuleId },
        });
    }

    public static async createEducationContent(moduleId: string, onProgress: OnProgress, input: GqlTypes.CreateEducationContentInput) {
        return await GraphQLClient.upload<GqlTypes.createEducationContent, GqlTypes.createEducationContentVariables>({
            mutation: Mutations.createEducationContent,
            variables: { moduleId, type: input.type, title: input.title, file: input.file },
            onProgress,
            file: input.file,
        });
    }

    public static async updateEducationContent(input: GqlTypes.UpdateEducationContentInput) {
        await GraphQLClient.mutate<GqlTypes.updateEducationContent>({
            mutation: Mutations.updateEducationContent,
            variables: { input },
        });
    }

    public static async orderEducationContents(moduleId: string, input: ContentsOrder[]) {
        await GraphQLClient.mutate<GqlTypes.orderEducationContents>({
            mutation: Mutations.orderEducationContents,
            variables: { moduleId, input },
        });
    }

    public static async deleteEducationContent(id: string) {
        await GraphQLClient.mutate<GqlTypes.deleteEducationContent>({
            mutation: Mutations.deleteEducationContent,
            variables: { id },
        });
    }
    public static async getEducationModuleTabs(): Promise<GqlTypes.EducationContentModuleTab[]> {
        const data: GqlTypes.getEducationModuleTabs = await GraphQLClient.query<GqlTypes.getEducationModuleTabs>({
            query: Queries.getEducationModuleTabs,
        });

        return data.educationModules;
    }

    public static async getEducationModuleById(id: string): Promise<GqlTypes.EducationContentModule | undefined> {
        const data: GqlTypes.getEducationModuleById = await GraphQLClient.query<GqlTypes.getEducationModuleById, GqlTypes.getEducationModuleByIdVariables>({
            query: Queries.getEducationModuleById,
            variables: { id },
        });

        return data.educationModule;
    }

    public static async getClientEverydaySituationDirectoryList(
        clientExtId: string,
        options?: GqlTypes.EverydaySituationDirectoryListOptionsInput,
    ): Promise<ListResult<GqlTypes.EverydaySituationDirectoryList>> {
        const data = await GraphQLClient.query<GqlTypes.getClientEverydaySituationDirectoryList, GqlTypes.getClientEverydaySituationDirectoryListVariables>({
            query: Queries.getClientEverydaySituationDirectoryList,
            variables: { clientExtId, options },
        });

        if (!data.getClientByExtId.everydaySituationDirectories) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return data.getClientByExtId.everydaySituationDirectories;
    }

    public static async getClientInstantAwardList(clientExtId: string, options?: GqlTypes.InstantAwardListOptionsInput): Promise<ListResult<GqlTypes.InstantAwardListItem>> {
        const response = await GraphQLClient.query<GqlTypes.getClientInstantAwardList, GqlTypes.getClientInstantAwardListVariables>({
            query: Queries.getClientInstantAwardList,
            variables: { clientExtId, options },
        });

        if (!response.getClientByExtId.instantAwards) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
        return response.getClientByExtId.instantAwards;
    }

    public static async getClientAwardList(clientExtId: string, options?: GqlTypes.AwardListOptionsInput): Promise<ListResult<GqlTypes.AwardListItem>> {
        const response = await GraphQLClient.query<GqlTypes.getClientAwardList, GqlTypes.getClientAwardListVariables>({
            query: Queries.getClientAwardList,
            variables: { clientExtId, options },
        });

        if (!response.getClientByExtId.awards) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
        return response.getClientByExtId.awards;
    }

    public static async getClientSelectionBoardList(clientExtId: string, options?: GqlTypes.SelectionBoardListOptionsInput): Promise<ListResult<GqlTypes.SelectionBoardListItem>> {
        const response = await GraphQLClient.query<GqlTypes.getClientSelectionBoardList, GqlTypes.getClientSelectionBoardListVariables>({
            query: Queries.getClientSelectionBoardList,
            variables: { clientExtId, options },
        });
        if (!response.getClientByExtId.selectionBoards) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }
        return response.getClientByExtId.selectionBoards;
    }

    public static async getMyClientsLogs(dateTimeFrom: Date, dateTimeTo: Date): Promise<Array<GqlTypes.getMyClientsLogs_me_myClients_result>> {
        const data: GqlTypes.getMyClientsLogs = await GraphQLClient.query<GqlTypes.getMyClientsLogs, GqlTypes.getMyClientsLogsVariables>({
            query: Queries.getMyClientsLogs,
            variables: { date: { from: new Date(dateTimeFrom), to: new Date(dateTimeTo) } },
        });

        return data.me.myClients?.result || [];
    }

    public static async getSupporterIdByEmail(email: string): Promise<string> {
        const response = await GraphQLClient.query<GqlTypes.getSupporterIdByEmail, GqlTypes.getSupporterIdByEmailVariables>({
            query: Queries.getSupporterIdByEmail,
            variables: { email },
        });

        return response.getSupporterIdByEmail;
    }

    public static async shareLibraryContentToPublic(libraryContentId: string, comment: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.shareLibraryContentToPublic, GqlTypes.shareLibraryContentToPublicVariables>({
            mutation: Mutations.shareLibraryContentToPublic,
            variables: { libraryContentId, comment: comment || undefined },
        });
    }

    public static async shareLibraryContent(libraryContentId: string, sharedWithAccountIds: string[]): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.shareLibraryContent, GqlTypes.shareLibraryContentVariables>({
            mutation: Mutations.shareLibraryContent,
            variables: { libraryContentId, sharedWithAccountIds },
        });
    }

    public static async getAppStatItemList(
        dateTimeFrom: Date,
        dateTimeTo: Date,
        clientExtId?: string,
        clientProfile?: string,
        options?: GqlTypes.AppStatItemListOptionsInput,
        type?: GqlTypes.AppStatItemType,
    ): Promise<ListResult<GqlTypes.AppStatItem>> {
        const data: GqlTypes.getAppStatItemList = await GraphQLClient.query<GqlTypes.getAppStatItemList, GqlTypes.getAppStatItemListVariables>({
            query: Queries.getAppStatItemList,
            variables: {
                date: {
                    from: dateTimeFrom,
                    to: dateTimeTo,
                },
                type,
                clientExtId,
                clientProfile: !isNil(clientProfile) ? clientProfile.toLowerCase() : undefined,
                options,
            },
        });

        return data.getAppStatItemList;
    }

    public static async getPendingLibraryContentShareRequests(options?: GqlTypes.LibraryContentShareRequestListInput): Promise<ListResult<GqlTypes.LibraryContentShareRequestListItem>> {
        const response = await GraphQLClient.query<GqlTypes.getPendingLibraryContentShareRequests, GqlTypes.getPendingLibraryContentShareRequestsVariables>({
            query: Queries.getPendingLibraryContentShareRequests,
            variables: { options },
        });

        if (!response.me.pendingLibraryContentShareRequests) {
            throw new ApiError(ApiErrorCode.NOT_FOUND);
        }

        return response.me.pendingLibraryContentShareRequests;
    }

    /**
     * Accept libraryContent share request
     * @param requestId string
     * @returns id of new content
     */
    public static async acceptLibraryContentShareRequest(requestId: string): Promise<string> {
        const response = await GraphQLClient.mutate<GqlTypes.acceptLibraryContentShareRequest, GqlTypes.acceptLibraryContentShareRequestVariables>({
            mutation: Mutations.acceptLibraryContentShareRequest,
            variables: { requestId },
        });

        return response.acceptLibraryContentShareRequest.id;
    }

    public static async rejectLibraryContentShareRequest(requestId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.rejectLibraryContentShareRequest, GqlTypes.rejectLibraryContentShareRequestVariables>({
            mutation: Mutations.rejectLibraryContentShareRequest,
            variables: { requestId },
        });
    }

    public static async getPendingLibraryContentShareRequestById(requestId: string): Promise<GqlTypes.LibraryContentShareRequestWithData> {
        const response = await GraphQLClient.query<GqlTypes.getPendingLibraryContentShareRequestById, GqlTypes.getPendingLibraryContentShareRequestByIdVariables>({
            query: Queries.getPendingLibraryContentShareRequestById,
            variables: { id: requestId },
        });

        return response.getPendingLibraryContentShareRequestById;
    }

    public static async getPersonalAssetDirectoryById(assetDirectoryId: string): Promise<GqlTypes.AssetDirectoryData> {
        const response = await GraphQLClient.query<GqlTypes.getPersonalAssetDirectoryById, GqlTypes.getPersonalAssetDirectoryByIdVariables>({
            query: Queries.getPersonalAssetDirectoryById,
            variables: { assetDirectoryId },
        });

        if (!response.getPersonalAssetDirectoryById) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }

        return response.getPersonalAssetDirectoryById;
    }

    public static async updatePersonalAssetDirectory(assetDirectoryId: string, input: GqlTypes.UpdateAssetDirectoryInput): Promise<GqlTypes.AssetDirectoryData> {
        const response = await GraphQLClient.mutate<GqlTypes.updatePersonalAssetDirectory, GqlTypes.updatePersonalAssetDirectoryVariables>({
            mutation: Mutations.updatePersonalAssetDirectory,
            variables: { assetDirectoryId, input },
        });

        if (!response.updatePersonalAssetDirectory) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }

        return response.updatePersonalAssetDirectory;
    }

    public static async deletePersonalAssetDirectory(assetDirectoryId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deletePersonalAssetDirectory, GqlTypes.deletePersonalAssetDirectoryVariables>({
            mutation: Mutations.deletePersonalAssetDirectory,
            variables: { assetDirectoryId },
        });
    }

    public static async getPublicAssetDirectoryById(assetDirectoryId: string): Promise<GqlTypes.AssetDirectoryData> {
        const response = await GraphQLClient.query<GqlTypes.getPublicAssetDirectoryById, GqlTypes.getPublicAssetDirectoryByIdVariables>({
            query: Queries.getPublicAssetDirectoryById,
            variables: { assetDirectoryId },
        });

        if (!response.getPublicAssetDirectoryById) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }

        return response.getPublicAssetDirectoryById;
    }

    public static async updatePublicAssetDirectory(assetDirectoryId: string, input: GqlTypes.UpdateAssetDirectoryInput): Promise<GqlTypes.AssetDirectoryData> {
        const response = await GraphQLClient.mutate<GqlTypes.updatePublicAssetDirectory, GqlTypes.updatePublicAssetDirectoryVariables>({
            mutation: Mutations.updatePublicAssetDirectory,
            variables: { assetDirectoryId, input },
        });

        if (!response.updatePublicAssetDirectory) {
            throw new ApiError(ApiErrorCode.ASSET_DIRECTORY_NOT_FOUND);
        }

        return response.updatePublicAssetDirectory;
    }

    public static async deletePublicAssetDirectory(assetDirectoryId: string): Promise<void> {
        await GraphQLClient.mutate<GqlTypes.deletePublicAssetDirectory, GqlTypes.deletePublicAssetDirectoryVariables>({
            mutation: Mutations.deletePublicAssetDirectory,
            variables: { assetDirectoryId },
        });
    }
}

export { Api };

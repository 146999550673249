import React, { PureComponent } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { DragUtils } from "../../DragUtils";
import { SearchListType } from "api/graphql/types";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { Draggable } from "../../Draggable";
import { SelectContentIcon } from "components/Icons/SelectContentIcon";
import SvgEmbeddedFlowChart from "components/Svg/EmbeddedFlowChart";

import "./SidebarContentElement.scss";

interface Props {
    id: string;
    index: number;
    title: string;
    imageUrls: string[];
    meta?: string;
    searchListType: SearchListType;
    isUsed: boolean;
    disabled?: boolean;
    canHaveMultipleAssets?: boolean;
    isSelect?: boolean;
    isEmbedded?: boolean;
    onAddClick: () => void;
    renderOptionsMenu?: () => React.ReactElement<any>;
}

class SidebarContentElement extends PureComponent<Props> {
    private isBlocked = (): boolean => {
        return this.props.disabled || (!this.props.canHaveMultipleAssets && this.props.isUsed);
    };

    private renderImage = (url: string | undefined, index: number): React.ReactElement<any> => {
        return (
            <div key={"" + url + index} className="content-image">
                <div className="image-container">
                    <Image src={url} fallback={ImageSrc[this.props.searchListType]} alt="" />
                </div>
                {this.props.isSelect && (
                    <div className="sidebar-content-element-indicator">
                        <SelectContentIcon />
                    </div>
                )}
                {this.props.isEmbedded && (
                    <div className="sidebar-content-element-indicator">
                        <SvgEmbeddedFlowChart style={{ display: "block" }} />
                    </div>
                )}

                {!this.isBlocked() && (
                    <div className="add-action">
                        <button onClick={this.props.onAddClick}>
                            <img src={ImageSrc.add} alt="" />
                        </button>
                    </div>
                )}
            </div>
        );
    };

    public renderContentElement(): React.ReactElement<any> {
        const { title, imageUrls, meta } = this.props;
        return (
            <div className="sidebar-content-element">
                {imageUrls.length === 0 ? this.renderImage(undefined, 0) : imageUrls.map(this.renderImage)}
                <div className="content-content">
                    <div className="content-title">
                        <h3>{title}</h3>
                        <p>{meta}</p>
                        <div className="buttons">{this.props.renderOptionsMenu && this.props.renderOptionsMenu()}</div>
                    </div>
                </div>
            </div>
        );
    }

    public render(): React.ReactElement<any> {
        return (
            <Draggable draggableId={this.props.id} index={this.props.index} isDragDisabled={this.isBlocked()} type={this.props.searchListType}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                    return (
                        <>
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`sidebar-content-element-container ${snapshot.isDragging ? "grabbed" : ""} ${this.isBlocked() ? " disabled" : ""}`}
                                style={DragUtils.getStyle(provided.draggableProps.style, snapshot)}
                            >
                                {this.renderContentElement()}
                            </div>
                            {/* {snapshot.isDragging && <div className="clone">{this.renderContentElement()}</div>} */}
                        </>
                    );
                }}
            </Draggable>
        );
    }
}

export { SidebarContentElement };

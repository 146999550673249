import React, { Component } from "react";
import { Section } from "components/Section";
import { Client, EverydaySituationDirectoryOrderInput, EverydaySituationDirectoryWithItemCount } from "api/graphql/types";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Loading, LoadingType } from "components/Loading/Loading";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { SortableList } from "components/SortableList/SortableList";
import { DirectoryImage } from "./DirectoryImage";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Path } from "utils/Path";
import { cloneDeep } from "lodash";
import { connect, DispatchProp } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

import "./ClientEverydaySituationsDirectories.scss";

interface ComponentProps {
    client: Client;
    setDragIds: (dragIds: string[]) => void;
}

type Props = ComponentProps & NotificationProp & RouteComponentProps & DispatchProp;

interface State {
    isLoading: boolean;
    everydaySituationDirectories: EverydaySituationDirectoryWithItemCount[];
}

class ClientEverydaySituationsDirectoriesComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        everydaySituationDirectories: [],
    };

    public componentDidMount(): void {
        this.refreshEverydaySituationDirectories();
    }

    private readonly refreshEverydaySituationDirectories = () => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const everydaySituationDirectories = await Api.getEverydaySituationDirectoriesByClientId(this.props.client.id);
                    this.setState({ everydaySituationDirectories, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly onAddClick = (): void => {
        this.props.history.push(Path.createClientEverydaySituationsDirectory(this.props.client.extId));
    };

    private readonly onEditClick = (directoryId: string) => (): void => {
        this.props.history.push(Path.editClientEverydaySituationsDirectory(this.props.client.extId, directoryId));
    };

    private readonly onDeleteClick = (everydaySituationDirectory: EverydaySituationDirectoryWithItemCount) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteEverydaySituationDirectory,
                everydaySituationDirectory,
                onDeleted: this.refreshEverydaySituationDirectories,
            }),
        );
    };

    private readonly onOrderChange = (everydaySituationDirectories: EverydaySituationDirectoryWithItemCount[]) => {
        const originalOrder = cloneDeep(this.state.everydaySituationDirectories);
        this.setState({ everydaySituationDirectories }, async () => {
            try {
                const orderInput = everydaySituationDirectories.map(
                    (esd: EverydaySituationDirectoryWithItemCount, index: number): EverydaySituationDirectoryOrderInput => {
                        return { id: esd.id, position: index + 1 };
                    },
                );
                await Api.orderEverydaySituationDirectories(this.props.client.id, orderInput);
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ everydaySituationDirectories: originalOrder });
            }
        });
    };

    private readonly renderDirectory = (item: EverydaySituationDirectoryWithItemCount) => {
        return (
            <div className="grid-x">
                <div className="cell shrink directory">
                    <DirectoryImage asset={item.image} />
                </div>
                <div className="cell auto">
                    <div className="directory-title">{item.title}</div>
                    <div className="grid-x">
                        {/* TODO: fix sitebuild */}
                        <span className="cell small-4">{Intl.formatMessage({ id: "page.clientEverydaySituations.directories.counts.text" })}</span>
                        <span className="cell auto">{item.itemCount.text}</span>
                    </div>
                    <div className="grid-x">
                        <span className="cell small-4">{Intl.formatMessage({ id: "page.clientEverydaySituations.directories.counts.audio" })}</span>
                        <span className="cell auto">{item.itemCount.audio}</span>
                    </div>
                    <div className="grid-x">
                        <span className="cell small-4">{Intl.formatMessage({ id: "page.clientEverydaySituations.directories.counts.image" })}</span>
                        <span className="cell auto">{item.itemCount.image}</span>
                    </div>
                </div>
                <div className="cell shrink">
                    <Button
                        className="edit"
                        link
                        icon={{ name: "fa-pencil-alt", large: true }}
                        title={Intl.formatMessage({ id: "common.edit" })}
                        ariaLabel={Intl.formatMessage({ id: "common.edit" })}
                        onClick={this.onEditClick(item.id)}
                    />

                    <Button
                        className="delete"
                        link
                        icon={{ name: "fa-trash", large: true }}
                        title={Intl.formatMessage({ id: "common.delete" })}
                        ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                        onClick={this.onDeleteClick(item)}
                    />
                </div>
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);
        return (
            <div className="everyday-situation-directory">
                <Section label={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.title" }, { name: this.props.client.name })}>
                    <p>{Intl.formatMessage({ id: "page.clientEverydaySituations.directories.description" }, { name: clientShortName })}</p>

                    <SortableList data={this.state.everydaySituationDirectories} renderItem={this.renderDirectory} onOrderChange={this.onOrderChange} />

                    <Button label={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.add" })} icon={{ name: "fa-plus", position: "left" }} onClick={this.onAddClick} />
                </Section>
            </div>
        );
    }
}

export const ClientEverydaySituationsDirectories: React.ComponentClass<ComponentProps> = withRouter(connect()(withNotification(ClientEverydaySituationsDirectoriesComponent)));

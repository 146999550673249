import React, { Component } from "react";
import { Page } from "components/Page";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Path } from "utils/Path";
import { TabPanel } from "components/TabPanel/TabPanel";
import { Client, Account } from "api/graphql/types";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Loading, LoadingType } from "components/Loading/Loading";
import { ClientDetailProfileTab } from "./ClientDetailProfileTab/ClientDetailProfileTab";
import { ClientDetailEventLogTab } from "./ClientDetailEventLogTab";
import { ClientDetailContentLibraryTab } from "./ClientDetailContentLibraryTab";
import { Formatter } from "utils/Formatter";
import { Intl } from "i18n/Intl";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers/index";

interface Params {
    clientExtId: string;
}

interface ReduxProps {
    account: Account;
}

type ComponentProps = RouteComponentProps<Params>;

type Props = ReduxProps & ComponentProps;

interface State {
    isLoading: boolean;
    client: Client | null;
}

class ClientDetailPageComponent extends Component<Props, State> {
    public state: State = {
        isLoading: true,
        client: null,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.match.params.clientExtId !== nextProps.match.params.clientExtId) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const client: Client = await Api.getClientByExtId(props.match.params.clientExtId);
                    this.setState({ client, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.clientList);
                        },
                    });
                }
            },
        );
    }

    public render(): React.ReactElement<any> {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        if (!this.state.client) {
            return <Redirect to={Path.clientList} />;
        }

        const { clientExtId } = this.props.match.params;
        return (
            <Page title={Intl.formatMessage({ id: "page.clientDetail.title" }, { extId: Formatter.formatExtId(clientExtId) })} hasTabs={true}>
                <div className="left-side">
                    <TabPanel
                        tabs={[
                            {
                                id: Intl.formatMessage({ id: "page.clientDetail.tabs.profile.title" }),
                                label: Intl.formatMessage({ id: "page.clientDetail.tabs.profile.title" }),
                                to: Path.clientDetailProfile(clientExtId),
                                component: ClientDetailProfileTab,
                            },
                            {
                                id: Intl.formatMessage({ id: "page.clientDetail.tabs.contentLibrary.title" }),
                                label: Intl.formatMessage({ id: "page.clientDetail.tabs.contentLibrary.title" }),
                                to: Path.clientDetailContentLibrary(clientExtId),
                                component: ClientDetailContentLibraryTab,
                                additionalProps: { client: this.state.client, account: this.props.account },
                            },
                            {
                                id: Intl.formatMessage({ id: "page.clientDetail.tabs.eventLog.title" }),
                                label: Intl.formatMessage({ id: "page.clientDetail.tabs.eventLog.title" }),
                                to: Path.clientDetailEventLog(clientExtId),
                                component: ClientDetailEventLogTab,
                                additionalProps: { client: this.state.client },
                            },
                        ]}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const ClientDetailPage = withRouter(connect(mapStateToProps)(ClientDetailPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientDetailPage;

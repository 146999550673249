import React, { Component } from "react";
import { TestId } from "utils/TestId";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { subWeeks, subDays, addDays } from "date-fns";
import {
    getMyClientsLogs_me_myClients_result,
    Account,
    AccountType,
    getClientList_getClientList_result,
    getSupporterList_getSupporterList_result,
    Aggregations_timeAggregations_dateAggregation,
} from "api/graphql/types";
import { Alert } from "components/Alert/Alert";
import { Image } from "components/Image";
import "./DashboardPage.scss";
import { ImageSrc } from "utils/ImageSrc";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Cell, ResponsiveContainer } from "recharts";
import { ChartUtils } from "utils/ChartUtils";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers";
import { DashboardContentShareRequestTable } from "./DashboardContentShareRequestTable";
import { Loading, LoadingType } from "components/Loading/Loading";
import { isNil } from "lodash";

interface State {
    isLoading: boolean;
    myClients: getMyClientsLogs_me_myClients_result[];
    activeClient: getMyClientsLogs_me_myClients_result | null;
    adminClients: getClientList_getClientList_result[];
    adminSupporters: getSupporterList_getSupporterList_result[];
}

interface ChartRow {
    date: string;
    count: number;
}

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps;

class DashboardPageComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        myClients: [],
        activeClient: null,
        adminClients: [],
        adminSupporters: [],
    };

    public componentDidMount(): void {
        if (this.props.account.accountType === AccountType.supporter) {
            this.refreshMyClients();
        } else if ([AccountType.admin, AccountType.superadmin].includes(this.props.account.accountType)) {
            this.refreshMyAdminClients();
            this.refreshMyAdminSupporters();
        }
    }

    private refreshMyClients = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const myClients = await Api.getMyClientsLogs(subWeeks(new Date(), 1), new Date());
                    this.setState({ isLoading: false, myClients, activeClient: myClients[0] });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, myClients: [] });
                }
            },
        );
    };

    private refreshMyAdminClients = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const result = await Api.getClientList({ control: { limit: 9999 } });
                    this.setState({ adminClients: result.result, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, adminClients: [] });
                }
            },
        );
    };

    private refreshMyAdminSupporters = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const result = await Api.getSupporterList({ control: { limit: 9999 } });
                    this.setState({ adminSupporters: result.result, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, adminSupporters: [] });
                }
            },
        );
    };

    private getSupporterChartData = (): ChartRow[] => {
        const dates: string[] = [];
        let chartRows: ChartRow[] = [];
        const activeClientAggregations: Aggregations_timeAggregations_dateAggregation[] | null = this.state.activeClient?.logs?.aggregations?.timeAggregations.dateAggregation || null;

        for (let i = 6; i >= 0; i--) {
            dates.push(DateUtils.format(subDays(new Date(), i), DateFormat.mmdd));

            const aggregation = activeClientAggregations?.find((aggregation: Aggregations_timeAggregations_dateAggregation) => {
                return DateUtils.format(new Date(aggregation.date), DateFormat.default) === DateUtils.format(subDays(new Date(), i), DateFormat.default);
            });

            chartRows = [
                ...chartRows,
                {
                    date: DateUtils.format(subDays(new Date(), i), DateFormat.mmdd),
                    count: !isNil(aggregation?.count) ? aggregation!.count : 0,
                },
            ];
        }

        return chartRows;
    };

    private getAdminNewClientChartData = (): ChartRow[] => {
        const dates: string[] = [];
        let chartRows: ChartRow[] = [];

        for (let i = 6; i >= 0; i--) {
            dates.push(DateUtils.format(subDays(new Date(), i), DateFormat.mmdd));
            chartRows = [
                ...chartRows,
                {
                    date: DateUtils.format(subDays(new Date(), i), DateFormat.mmdd),
                    count:
                        this.state.adminClients.filter((client: getClientList_getClientList_result) => {
                            return DateUtils.format(new Date(client.createdAt), DateFormat.default) === DateUtils.format(subDays(new Date(), i), DateFormat.default);
                        }).length || 0,
                },
            ];
        }

        return chartRows;
    };

    private getAdminNewSupporterChartData = (): ChartRow[] => {
        const dates: string[] = [];
        let chartRows: ChartRow[] = [];

        for (let i = 6; i >= 0; i--) {
            dates.push(DateUtils.format(subDays(new Date(), i), DateFormat.mmdd));
            chartRows = [
                ...chartRows,
                {
                    date: DateUtils.format(subDays(new Date(), i), DateFormat.mmdd),
                    count:
                        this.state.adminSupporters.filter((client: getSupporterList_getSupporterList_result) => {
                            return DateUtils.format(new Date(client.createdAt), DateFormat.default) === DateUtils.format(subDays(new Date(), i), DateFormat.default);
                        }).length || 0,
                },
            ];
        }

        return chartRows;
    };

    private scrollClient = (isNext: boolean) => {
        const offset: number = isNext ? 1 : -1;
        const activeClientIndex: number = this.state.myClients.findIndex((client: getMyClientsLogs_me_myClients_result) => client.id === this.state.activeClient?.id);
        let newIndex: number = activeClientIndex + offset;
        if (activeClientIndex + offset === this.state.myClients.length) {
            newIndex = 0;
        } else if (activeClientIndex + offset === -1) {
            newIndex = this.state.myClients.length - 1;
        }
        this.setState({ activeClient: this.state.myClients[newIndex] });
    };

    private renderAppSection = (): React.ReactElement => {
        return (
            <>
                <p className="dashboard-app-text mb-35">{Intl.formatMessage({ id: "page.dashboard.description" })}</p>
                <div className="dashboard-app-icons">
                    <div className="mb-15">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=hu.aosz.data.app">
                            <img src="/images/google-play-btn.svg" alt="google play" title="google play" width={168} height={50} />
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://apps.apple.com/app/id1494308903">
                            <img src="/images/app-store-btn.svg" alt="app store" title="app store" width={186} height={50} />
                        </a>
                    </div>
                </div>
            </>
        );
    };

    private renderClientEvents = (): React.ReactElement => {
        if (this.state.isLoading) {
            return (
                <div className="col-lg-12">
                    <div className="dashboard-loading-wrapper">
                        <Loading type={LoadingType.input} />
                    </div>
                </div>
            );
        }
        if (this.state.myClients.length === 0) {
            return <div className="col-lg-12">{Intl.formatMessage({ id: "page.dashboard.noClients" })}</div>;
        }

        return (
            <>
                <div className="col-lg-5">
                    <div className="dashboard-client-inner">
                        <Image className="dashboard-client-inner__image" src={this.state.activeClient?.avatar?.url || ImageSrc.avatar} />
                        <div className="scroll-buttons">
                            <button className="fa fa-chevron-left scroll-icon" type="button" onClick={() => this.scrollClient(false)} />
                            <button className="fa fa-chevron-right scroll-icon" type="button" onClick={() => this.scrollClient(true)} />
                            <div className="scroll-buttons__text">{this.state.activeClient?.name || ""}</div>
                        </div>
                        <div className="log-count small-text">{Intl.formatMessage({ id: "page.dashboard.logsCount" }, { count: this.state.activeClient?.logs?.count || 0 })}</div>
                        <div className="index-count small-text">
                            {this.state.myClients.findIndex((client: getMyClientsLogs_me_myClients_result) => client.id === this.state.activeClient?.id) + 1} <span>/</span>
                            {this.state.myClients.length}
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="dashboard-chart__wrapper">
                        <h2 className="chart-title dashboard-chart-title mt-0 bb-0 pl-50 pb-0 mb-15">
                            {Intl.formatMessage(
                                { id: "page.dashboard.chartTitle" },
                                { date: `${DateUtils.format(addDays(subWeeks(new Date(), 1), 1), DateFormat.mmdd)}-${DateUtils.format(new Date(), DateFormat.mmdd)}` },
                            )}
                        </h2>
                        <div style={{ height: 230 + 30 }}>
                            <ResponsiveContainer>
                                <BarChart
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                    data={this.getSupporterChartData()}
                                >
                                    <CartesianGrid vertical={false} stroke="#d8e4e8" />
                                    <XAxis
                                        dataKey="date"
                                        axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                        stroke="#d8e4e8"
                                        tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                        tickLine={{ stroke: "transparent" }}
                                    />
                                    <YAxis
                                        // ticks={[10, 20, 30]}
                                        allowDecimals={false}
                                        axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                        dx={-6}
                                        stroke="#d8e4e8"
                                        tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                        tickLine={{ stroke: "transparent" }}
                                        width={50}
                                    />
                                    <Bar dataKey="count" barSize={15} fill="#8884d8">
                                        {this.getSupporterChartData().map((log: ChartRow, index: number) => {
                                            const color = ChartUtils.getColorByNumbers(
                                                log.count,
                                                this.getSupporterChartData().map((fillLog: ChartRow) => fillLog.count),
                                            );
                                            return <Cell key={index} fill={color} />;
                                        })}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    private renderSupporterContent = (): React.ReactElement => {
        return (
            <div className="container dashboard-container">
                <div className="row">
                    <div className="col mb-30">
                        <div className="dashboard-client">
                            <div className="row no-gutters">{this.renderClientEvents()}</div>
                        </div>
                    </div>
                    <div className="col mb-30">
                        <div className=" dashboard-error">
                            <p className="dashboard-error-title-supporter" role="heading" aria-level={2}>
                                {Intl.formatMessage({ id: "page.dashboard.errorTitle" })}
                            </p>
                            <p className="dashboard-error-text">{Intl.formatMessage({ id: "page.dashboard.description2" })}</p>
                            <p className="dashboard-error-text">{IntlHelpers.asHtml({ id: "page.dashboard.description3" })}</p>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-lg-12 dashboard-app">
                        <h2 className="mt-0 bb-0 pb-0 mb-30 fs-16 lh-20 text-transform-none">{IntlHelpers.asHtml({ id: "page.dashboard.description" })}</h2>
                        <div className="row justify-content-center">
                            <div className="col col-auto text-center">
                                <a className="app-link mt-0 mr-0 mb-0 ml-0" target="_blank" href="https://play.google.com/store/apps/details?id=hu.aosz.data.app">
                                    <strong>{Intl.formatMessage({ id: "page.dashboard.android" })}</strong>
                                    <br />
                                    <img src="/images/google-play-btn.svg" alt="google play" title="google play" width={168} height={50} />
                                </a>
                            </div>
                            <div className="col col-auto text-center">
                                <a className="app-link mt-0 mr-0 mb-0 ml-0" target="_blank" href="https://apps.apple.com/app/id1494308903">
                                    <strong>{Intl.formatMessage({ id: "page.dashboard.ios" })}</strong>
                                    <br />
                                    <img src="/images/app-store-btn.svg" alt="app store" title="app store" width={186} height={50} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderSupervisorContent = (): React.ReactElement => {
        return (
            <div className="container dashboard-container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="dashboard-content-share-request-table-container">
                            <DashboardContentShareRequestTable />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row no-gutters dashboard-supervisor-error h-auto">
                            <p className="dashboard-error-title dashboard-supervisor-error-title">{Intl.formatMessage({ id: "page.dashboard.errorTitle" })}</p>
                            <p className="dashboard-error-text">{Intl.formatMessage({ id: "page.dashboard.description2" })}</p>
                            <p className="dashboard-error-text">{IntlHelpers.asHtml({ id: "page.dashboard.description3" })}</p>
                        </div>
                        <div className="row no-gutters dashboard-supervisor-app h-auto">{this.renderAppSection()}</div>
                    </div>
                </div>
            </div>
        );
    };

    private renderAdminContent = (): React.ReactElement => {
        return (
            <div className="container dashboard-container">
                <div className="row">
                    <div className="col-12">
                        <div className="row no-gutters dashboard-multi-chart">
                            <div className="col-lg-6 dashboard-multi-chart-inner">
                                <div className="pl-15 pr-40 pb-10">
                                    <p className="dashboard-admin-chart-title mb-25" role="heading" aria-level={2}>
                                        {Intl.formatMessage(
                                            { id: "page.dashboard.adminChartTitleClient" },
                                            { date: `${DateUtils.format(addDays(subWeeks(new Date(), 1), 1), DateFormat.mmdd)}-${DateUtils.format(new Date(), DateFormat.mmdd)}` },
                                        )}
                                    </p>

                                    <div style={{ height: 186 + 30 }}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                data={this.getAdminNewClientChartData()}
                                            >
                                                <CartesianGrid vertical={false} stroke="#d8e4e8" />
                                                <XAxis
                                                    dataKey="date"
                                                    axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                                    stroke="#d8e4e8"
                                                    tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                                    tickLine={{ stroke: "transparent" }}
                                                />
                                                <YAxis
                                                    // ticks={[10, 20, 30]}
                                                    allowDecimals={false}
                                                    axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                                    dx={-6}
                                                    stroke="#d8e4e8"
                                                    tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                                    tickLine={{ stroke: "transparent" }}
                                                    width={50}
                                                />
                                                <Bar dataKey="count" barSize={15} fill="#8884d8">
                                                    {this.getAdminNewClientChartData().map((client: ChartRow, index: number) => {
                                                        const color = ChartUtils.getColorByNumbers(
                                                            client.count,
                                                            this.getAdminNewClientChartData().map((fillLog: ChartRow) => fillLog.count),
                                                        );
                                                        return <Cell key={index} fill={color} />;
                                                    })}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 dashboard-multi-chart-inner">
                                <div className="pl-15 pr-40 pb-10">
                                    <p className="dashboard-admin-chart-title mb-25" role="heading" aria-level={2}>
                                        {Intl.formatMessage(
                                            { id: "page.dashboard.adminChartTitleSupporter" },
                                            { date: `${DateUtils.format(addDays(subWeeks(new Date(), 1), 1), DateFormat.mmdd)}-${DateUtils.format(new Date(), DateFormat.mmdd)}` },
                                        )}
                                    </p>

                                    <div style={{ height: 186 + 30 }}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                data={this.getAdminNewSupporterChartData()}
                                            >
                                                <CartesianGrid vertical={false} stroke="#d8e4e8" />
                                                <XAxis
                                                    dataKey="date"
                                                    axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                                    stroke="#d8e4e8"
                                                    tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                                    tickLine={{ stroke: "transparent" }}
                                                />
                                                <YAxis
                                                    // ticks={[10, 20, 30]}
                                                    allowDecimals={false}
                                                    axisLine={{ stroke: "#d8e4e8", strokeWidth: 2 }}
                                                    dx={-6}
                                                    stroke="#d8e4e8"
                                                    tick={{ fill: "#00459b", fontSize: 14, fontWeight: 400, lineHeight: 16 }}
                                                    tickLine={{ stroke: "transparent" }}
                                                    width={50}
                                                />
                                                <Bar dataKey="count" barSize={15} fill="#8884d8">
                                                    {this.getAdminNewSupporterChartData().map((log: ChartRow, index: number) => {
                                                        const color = ChartUtils.getColorByNumbers(
                                                            log.count,
                                                            this.getAdminNewSupporterChartData().map((fillLog: ChartRow) => fillLog.count),
                                                        );
                                                        return <Cell key={index} fill={color} />;
                                                    })}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col-lg-8 ">
                        <div className="dashboard-admin-bottom-row-error pt-54 pb-54">
                            <div className="maxW-320 ml-auto mr-auto">
                                <p className="dashboard-error-title dashboard-admin-error-title mb-30" role="heading" aria-level={2}>
                                    {Intl.formatMessage({ id: "page.dashboard.errorTitle" })}
                                </p>
                                <p className="dashboard-error-text mb-30">{Intl.formatMessage({ id: "page.dashboard.description2" })}</p>
                                <p className="dashboard-error-text">{IntlHelpers.asHtml({ id: "page.dashboard.description3" })}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="dashboard-admin-bottom-row-app">{this.renderAppSection()}</div>
                    </div>
                </div>
            </div>
        );
    };

    private renderLegacyContent = (): React.ReactElement => {
        return (
            <div>
                <h3>{IntlHelpers.asHtml({ id: "page.dashboard.description" })}</h3>
                <div className="grid-x">
                    <div className="cell medium-6 text-center">
                        <a className="app-link" target="_blank" href="https://play.google.com/store/apps/details?id=hu.aosz.data.app">
                            <strong>{Intl.formatMessage({ id: "page.dashboard.android" })}</strong>
                            <br />
                            <img src="/images/google-play-btn.svg" alt="google play" title="google play" width={168} height={50} />
                        </a>
                    </div>
                    <div className="cell medium-6 text-center">
                        <a className="app-link" target="_blank" href="https://apps.apple.com/app/id1494308903">
                            <strong>{Intl.formatMessage({ id: "page.dashboard.ios" })}</strong>
                            <br />
                            <img src="/images/app-store-btn.svg" alt="app store" title="app store" width={186} height={50} />
                        </a>
                    </div>
                </div>
                <p>{IntlHelpers.asHtml({ id: "page.dashboard.description2" })}</p>
            </div>
        );
    };

    private renderContent = (): React.ReactElement => {
        const { accountType } = this.props.account;
        if (accountType === AccountType.supporter) {
            return this.renderSupporterContent();
        } else if (accountType === AccountType.supervisor) {
            return this.renderSupervisorContent();
        } else if ([AccountType.admin, AccountType.superadmin].includes(accountType)) {
            return this.renderAdminContent();
        } else {
            return this.renderLegacyContent();
        }
    };

    public render(): React.ReactElement {
        return (
            <Page title={Intl.formatMessage({ id: "page.dashboard.title" }, { name: this.props.account.name })} id={TestId.dashboard.container}>
                <div className="left-side dashboard">{this.renderContent()}</div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const DashboardPage = connect(mapStateToProps)(DashboardPageComponent);

export default DashboardPage;

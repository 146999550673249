import { ParsedParameter, UrlQueryParser } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { QueryParserTypeUtils } from "utils/TypeUtils";

enum QueryParameter {
    year = "year",
    month = "month",
}

export interface ClientCalendarQueryParameter {
    year?: number;
    month?: number;
}

class ClientCalendarQueryParser extends UrlQueryParser<ClientCalendarQueryParameter> {
    private translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.clientCalendarQueryParameter.${key}` });
    }

    public getUrlQuery(options: ClientCalendarQueryParameter): string {
        const year: number | null = options.year || null;
        const month: number | null = options.month || null;

        const paramArray: string[] = [];
        if (year !== null) {
            paramArray.push(`${this.translateListQuery(QueryParameter.year)}=${year}`);
        }

        // months in options start from 0, but in params start from 1
        paramArray.push(`${this.translateListQuery(QueryParameter.month)}=${month ? month + 1 : 1}`);
        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): ClientCalendarQueryParameter {
        const keys = {
            [QueryParameter.year]: this.translateListQuery(QueryParameter.year),
            [QueryParameter.month]: this.translateListQuery(QueryParameter.month),
        };

        const year: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.year]);
        const month: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.month]);

        // months in params, start from 1, but in options start from 0
        return { year, month: month ? month - 1 : 1 };
    }
}

export { ClientCalendarQueryParser };

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { DialogVisibilityProps } from "./DialogsContainer";
import { TestId } from "utils/TestId";

export interface DeletePinDialogProps {
    onDelete: () => Promise<void> | void;
}

type Props = DeletePinDialogProps & DialogVisibilityProps;

class DeletePinDialog extends Component<Props> {
    private onDelete = (): void => {
        this.props.onDelete();
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.profile.pin.delete.dialog.title" })} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "page.profile.pin.delete.dialog.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button id={TestId.profilePage.deletePinDialog.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button
                            id={TestId.profilePage.deletePinDialog.acceptButton}
                            className="btn-danger"
                            label={Intl.formatMessage({ id: "page.profile.pin.delete.dialog.confirmDelete" })}
                            onClick={this.onDelete}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { DeletePinDialog };

import { Reducer, combineReducers } from "redux";

import { AuthReducerState, AuthReducer } from "reducers/AuthReducer";
import { AccountReducer, AccountReducerState } from "./AccountReducer";
import { SettingsReducer, SettingsReducerState } from "./SettingsReducer";
import { AlertReducer, AlertReducerState } from "./AlertReducer";
import { DialogsReducer, DialogsReducerState } from "./DialogsReducer";
import { TagsReducer, TagsReducerState } from "./TagsReducer";

export interface ApplicationState {
    auth: AuthReducerState;
    account: AccountReducerState;
    settings: SettingsReducerState;
    alerts: AlertReducerState;
    dialogs: DialogsReducerState;
    tags: TagsReducerState;
}

export const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    auth: AuthReducer.instance,
    account: AccountReducer.instance,
    settings: SettingsReducer.instance,
    alerts: AlertReducer.instance,
    dialogs: DialogsReducer.instance,
    tags: TagsReducer.instance,
});

import React, { PureComponent } from "react";

import { Button } from "components/Button/Button";

import "./ColorPicker.scss";

interface ComponentProps {
    id?: string;
    title: string;
    options: string[];
    value: string[];
    maxValueLength?: number;
    onChange: (value: string[]) => any | Promise<any>;
    addAriaLabel: string;
    emptyAriaLabel: string;
    clearAriaLabel: string;
}

type Props = ComponentProps;

class ColorPicker extends PureComponent<Props> {
    private static readonly DEFAULT_MAX_VALUE_LENGTH: number = 8;
    private static readonly ROW_LENGTH: number = 2;
    private containerRef: HTMLDivElement | null = null;

    public scrollIntoView = (): void => {
        if (this.containerRef) {
            this.containerRef.scrollIntoView();
        }
    };

    private onColorClick = (color: string) => () => {
        if (this.props.value.length < (this.props.maxValueLength || ColorPicker.DEFAULT_MAX_VALUE_LENGTH)) {
            this.props.onChange([...this.props.value, color]);
        }
    };

    private renderOptions = (): Array<React.ReactElement<any>> => {
        const rows: string[][] = this.props.options.reduce((prevState: string[][], current: string, index: number): string[][] => {
            if (index % ColorPicker.ROW_LENGTH === 0) {
                prevState.push([current]);
            } else {
                prevState[prevState.length - 1].push(current);
            }
            return prevState;
        }, []);

        return rows.map(
            (row: string[], index: number): React.ReactElement<any> => (
                <div key={`row-${index}`} className="cp-row">
                    {row.map(
                        (color: string): React.ReactElement<any> => (
                            <span key={color} className="cp-button" title={this.props.addAriaLabel} style={{ backgroundColor: color, borderColor: color }} onClick={this.onColorClick(color)}>
                                {color}
                            </span>
                        ),
                    )}
                </div>
            ),
        );
    };

    private renderValues = (): React.ReactElement<any> => {
        const values: Array<string | null> = Array.from({ length: this.props.maxValueLength || ColorPicker.DEFAULT_MAX_VALUE_LENGTH }, (_: any, index: number): string | null => {
            return this.props.value.length > index ? this.props.value[index] : null;
        });

        return (
            <div className="cp-colors">
                {values.map(
                    (color: string | null, index: number): React.ReactElement<any> => {
                        if (color) {
                            return (
                                <span key={color + index} className="cp-color" title="purple" style={{ backgroundColor: color, borderColor: color }}>
                                    {color}
                                </span>
                            );
                        }

                        return (
                            <span key={`${index}`} className="cp-color" title={this.props.emptyAriaLabel}>
                                {this.props.emptyAriaLabel}
                            </span>
                        );
                    },
                )}
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <div
                id={this.props.id}
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                className="color-picker"
            >
                <p className="cp-description">{this.props.title}</p>
                <div className="cp-content">
                    <div className="cp-left">{this.renderOptions()}</div>
                    <div className="cp-right">
                        {this.renderValues()}
                        <div className="clear">
                            <Button
                                link
                                icon={{ name: "fa-times", large: true }}
                                ariaLabel={this.props.clearAriaLabel}
                                onClick={() => {
                                    this.props.onChange([]);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { ColorPicker };

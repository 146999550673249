import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Path } from "utils/Path";
import { store } from "store";
import { AuthActions } from "actions/AuthActions";
import { Api } from "api/Api";

type Props = RouteComponentProps;

class LogoutPageComponent extends Component<Props> {
    public async componentDidMount(): Promise<void> {
        try {
            await Api.logout();
            // eslint-disable-next-line no-empty
        } catch (error) {}
        this.props.history.replace({ pathname: Path.login, state: { noRedirect: true } });
        store.dispatch(AuthActions.logout());
    }

    public render(): React.ReactElement<any> | null {
        return null;
    }
}

export const LogoutPage = withRouter(LogoutPageComponent);

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Button } from "components/Button/Button";
import { Account } from "api/graphql/types";
import { AccountActions } from "actions/AccountActions";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Alert } from "components/Alert/Alert";
import isNil from "lodash/isNil";

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & NotificationProp;

interface State {
    isEditable: boolean;
    pin: string | null;
    formErrors: {
        pin: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ProfilePinFormComponent extends Component<Props, State> {
    private pinRef: HTMLInputElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            pin: this.props.account.pin,
            formErrors: {
                pin: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onPinChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const pin: string = event.target.value;
        const pinError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validatePin(pin)) : null;
        this.setState({ pin, formErrors: { ...this.state.formErrors, pin: pinError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { pin } = this.state;
        const formErrors = {
            pin: IntlHelpers.getValidationError(Validator.validatePin(pin!)),
        };

        if (formErrors.pin) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.pin && this.pinRef) {
                    this.pinRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.updatePin(pin!);
                    this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, pin }));
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.profile.pin.pinForm.pinChangeSucceed" }) });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private onDeleteClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deletePin,
                onDelete: this.deletePin,
            }),
        );
    };

    private deletePin = async (): Promise<void> => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.deletePin();
                    Alert.success({ title: Intl.formatMessage({ id: "page.profile.pin.delete.success" }) });
                    this.setState({ isLoading: false, pin: null });
                    this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, pin: null }));
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }
                this.setState({ isLoading: false });
            },
        );
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    private renderPinInput = (): React.ReactElement<any> => {
        if (this.state.isEditable || this.state.pin) {
            return (
                <PasswordInput
                    innerRef={(ref: HTMLInputElement | null): void => {
                        this.pinRef = ref;
                    }}
                    hasError={!!this.state.formErrors.pin}
                    placeholder={Intl.formatMessage({ id: "page.profile.pin.pinForm.pin.placeholder" })}
                    value={this.state.pin || ""}
                    onChange={this.onPinChange}
                    disabled={!this.state.isEditable}
                />
            );
        }

        return (
            <span className="cell auto">
                <strong className="value-cell">{this.state.pin || Intl.formatMessage({ id: "page.profile.pin.pinForm.pin.notGivenPlaceholder" })}</strong>
            </span>
        );
    };

    public render(): React.ReactElement<any> {
        const { isEditable, pin, formErrors, isLoading } = this.state;
        return (
            <form noValidate={true} onSubmit={this.onSubmit} id={TestId.profilePage.pinForm}>
                <InputWrapper id={TestId.profilePage.pinInput} inputLabel={Intl.formatMessage({ id: "page.profile.pin.pinForm.pin.label" })} errorMessage={formErrors.pin}>
                    {this.renderPinInput()}
                </InputWrapper>

                {isEditable ? (
                    <div className="action-button-container">
                        <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            type="submit"
                            disabled={isLoading || !!formErrors.pin || pin === this.props.account.pin}
                        />
                    </div>
                ) : (
                    <>
                        <div className="pin-buttons">
                            {!isNil(this.state.pin) && (
                                <Button id="delete-button" link icon={{ name: "fa-trash", large: true }} onClick={this.onDeleteClick} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
                            )}
                            <Button id="edit-button" link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                        </div>
                    </>
                )}
                <Prompt when={pin !== this.props.account.pin} />
            </form>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account!,
    };
};

export const ProfilePinForm = withNotification(connect(mapStateToProps)(ProfilePinFormComponent));

import React, { Component } from "react";
import { Page } from "components/Page";
import { SupportedClient, AgendaItemCheckBoxSize, AgendaItemSize, AssetContent, SearchListType, AssetType } from "api/graphql/types";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { ColorOption } from "components/Inputs/ColorSelect/ColorSelect";
import { SelectOption } from "components/Inputs/Select/Select";
import { TestId } from "utils/TestId";
import { SettingsSelectors } from "selectors/SettingsSelectors";
import { ColorsOfDays } from "utils/ColorsOfDaysUtils";
import { AppTab } from "./AppTab";
import { MainPageTab } from "./MainPageTab";
import { ContentsTab } from "./ContentsTab";
import { FunctionsTab } from "./FunctionsTab";
import { TabPanel } from "components/TabPanel/TabPanel";
import { NotificationType } from "components/NotificationBar/Notification";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";

interface ReduxProps {
    client: SupportedClient | null;
    unlockColors: string[];
    accountId: string;
}

interface RouteParams {
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ReduxProps & ComponentProps & DispatchProp;

export interface AgendaType {
    itemBackgroundColor: string | null;
    colorsOfDays: ColorsOfDays;
    itemCheckBoxSize: SelectOption<AgendaItemCheckBoxSize>;
    itemSize: SelectOption<AgendaItemSize>;
    calendarView: SelectOption<boolean>;
    withoutDefaultAgenda: {
        text?: string | null;
        image?: AssetContent | null;
    };
}

interface State {
    notificationOnProfilePage: string | null;
    dragIds: string[];
}

class ClientSettingsComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    public readonly state: State = {
        notificationOnProfilePage: null,
        dragIds: [],
    };

    private showNotification = (): void => {
        if (this.pageRef) {
            this.pageRef.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientSettings.saveSucceed" }) });
        }
    };

    private onDragIdsChange = (dragIds: string[]): void => {
        this.setState({ dragIds });
    };

    public render(): React.ReactElement<any> {
        const clientExtId: string | undefined = this.props.match.params.clientExtId;

        if (!this.props.client || !clientExtId) {
            return <Redirect to={Path.dashboard} />;
        }

        const hasSidebar: boolean = this.props.location.pathname === Path.clientContentsSettings(clientExtId);
        return (
            <Page
                ref={(ref: Page | null) => {
                    this.pageRef = ref;
                }}
                id={TestId.clientSettings.container}
                title={Intl.formatMessage({ id: "page.clientSettings.title" }, { name: this.props.client.name })}
                hasSideBar={hasSidebar}
                hasTabs={true}
            >
                <DragContainer hideSidebar={!hasSidebar} searchListTypes={[SearchListType.asset]} assetTypes={[AssetType.image]} createdById={this.props.accountId} dragIds={this.state.dragIds}>
                    <div className="left-side">
                        <TabPanel
                            tabListClassName="react-tabs__tab-list react-tabs__tab-list--display-table"
                            tabs={[
                                {
                                    label: Intl.formatMessage({ id: "page.clientSettings.appTab.title" }),
                                    to: Path.clientAppSettings(clientExtId),
                                    component: AppTab,
                                    additionalProps: { onShowNotification: this.showNotification },
                                    id: TestId.clientSettings.tabs.app,
                                },
                                {
                                    label: Intl.formatMessage({ id: "page.clientSettings.mainPageTab.title" }),
                                    to: Path.clientMainPageSettings(clientExtId),
                                    component: MainPageTab,
                                    additionalProps: { onShowNotification: this.showNotification },
                                    id: TestId.clientSettings.tabs.mainPage,
                                },
                                {
                                    label: Intl.formatMessage({ id: "page.clientSettings.contentsTab.title" }),
                                    to: Path.clientContentsSettings(clientExtId),
                                    component: ContentsTab,
                                    additionalProps: { onShowNotification: this.showNotification, onDragIdsChange: this.onDragIdsChange },
                                    id: TestId.clientSettings.tabs.contents,
                                },
                                {
                                    label: Intl.formatMessage({ id: "page.clientSettings.functionsTab.title" }),
                                    to: Path.clientFunctionsSettings(clientExtId),
                                    component: FunctionsTab,
                                    additionalProps: { onShowNotification: this.showNotification },
                                    id: TestId.clientSettings.tabs.functions,
                                },
                            ]}
                        />
                    </div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    const unlockColors: string[] = SettingsSelectors.getUnlockColorOptions(state, false).map((color: ColorOption): string => color.value || "");
    return {
        client,
        unlockColors,
        accountId: state.account!.id,
    };
};

export const ClientSettings = connect(mapStateToProps)(withRouter(ClientSettingsComponent));

// tslint:disable-next-line: no-default-export
export default ClientSettings;

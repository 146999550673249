import React, { Component } from "react";
import { Page } from "components/Page";
import { ContentLibraryTable } from "pages/_shared/ContentLibraryTable/ContentLibraryTable";
import { AccountType, SearchListType, Account } from "api/graphql/types";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { LinkGroupItem, LinkGroup } from "components/LinkGroup/LinkGroup";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { ContentLibraryLinks } from "models/ContentLibraryLinks";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { ContentLibraryDirectories, DirectoryList } from "models/ContentLibraryDirectories";
import { Loading, LoadingType } from "components/Loading/Loading";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Alert } from "components/Alert/Alert";
import { Link } from "react-router-dom";

interface ReduxProps {
    account: Account;
}

type Props = RouteComponentProps & ReduxProps & DispatchProp;

type State = {
    personalDirectories: DirectoryList[];
    publicDirectories: DirectoryList[];
    isLoading: boolean;
};

class PublicContentLibraryPageComponent extends Component<Props, State> {
    private readonly urlQueryParser = new ContentLibraryUrlQueryParser();

    constructor(props: Props) {
        super(props);
        this.state = {
            personalDirectories: [],
            publicDirectories: [],
            isLoading: true,
        };
    }

    private readonly fetchDirectories = () => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const personalDirectories = [AccountType.supervisor, AccountType.supporter].includes(this.props.account.accountType)
                        ? await ContentLibraryDirectories.getPersonalAssetDirectories(this.props.history.location.pathname)
                        : [];
                    const publicDirectories = await ContentLibraryDirectories.getPublicAssetDirectories(this.props.history.location.pathname);
                    this.setState({
                        personalDirectories,
                        publicDirectories,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }
            },
        );
    };

    componentDidMount(): void {
        this.fetchDirectories();
    }

    private readonly isLinkActive = (item: LinkGroupItem): boolean => {
        const currentPath: string = this.props.location.pathname + this.props.location.search;
        return currentPath.startsWith(item.toPage);
    };

    private isDirectoryActive = (directory: DirectoryList): boolean => {
        const params = this.urlQueryParser.parse(this.props.location.search);
        return params.directory === directory.id;
    };

    private isDirectoryHasActiveChild = (directories: DirectoryList[]): boolean => {
        let isActive: boolean = false;
        directories.forEach((directory: DirectoryList) => {
            if (this.isDirectoryActive(directory)) {
                isActive = true;
            }
            if (directory.children) {
                isActive = this.isDirectoryHasActiveChild(directory.children);
            }
        });
        return isActive;
    };

    private onCreateAssetDirectoryFinished = (succeed: boolean) => {
        if (succeed) {
            this.fetchDirectories();
        }
    };

    private onCreateAssetDirectoryClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.createAssetDirectory,
                isVisible: true,
                onCreateAssetDirectoryFinished: this.onCreateAssetDirectoryFinished,
                isPublic: this.props.account.accountType === AccountType.superadmin,
            }),
        );
    };

    private onEditAssetDirectoryClick = (isPublic: boolean, directoryId: string) => (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.editAssetDirectory,
                isPublic,
                directoryId,
                onClose: (isDeleted: boolean) => {
                    if (isDeleted) {
                        const link = this.getDirectoryLinkGroup().find(directoryLinkGroup => directoryLinkGroup.isAsset === true);
                        if (link) {
                            this.props.history.push(link.toPage);
                        }
                    }
                    this.fetchDirectories();
                },
            }),
        );
    };

    private getDirectoryLinkGroup = (): LinkGroupItem[] => {
        switch (this.props.account.accountType) {
            case AccountType.supervisor:
                return ContentLibraryLinks.getForSupervisor(this.props.location.pathname);
            case AccountType.admin:
            case AccountType.superadmin:
                return ContentLibraryLinks.getForPublic(this.props.location.pathname);
            case AccountType.supporter:
            default:
                return ContentLibraryLinks.getForSupporter();
        }
    };

    private renderLinks = (): React.ReactElement<any> => {
        const params = this.urlQueryParser.parse(this.props.location.search);
        const directory = params.directory;
        const isPublic = !!params.isPublic;
        return (
            <>
                {this.props.account.accountType === AccountType.supporter && (
                    <>
                        <LinkGroup
                            title={Intl.formatMessage({ id: "page.publicContentLibrary.myLibrary" })}
                            links={ContentLibraryLinks.getForSupporter()}
                            isLinkActive={this.isLinkActive}
                            isDirectoryActive={this.isDirectoryActive}
                            isDirectoryHasActiveChild={this.isDirectoryHasActiveChild}
                            directories={this.state.personalDirectories ? this.state.personalDirectories : undefined}
                        />
                        {directory && !isPublic && (
                            <div className="grid-x pl-2 pb-4">
                                <button className="btn btn-primary btn-block create-directory fs-15" onClick={this.onEditAssetDirectoryClick(isPublic, directory)}>
                                    {Intl.formatMessage({ id: "page.publicContentLibrary.editLibrary" })}
                                </button>
                            </div>
                        )}
                        <div className="grid-x pl-2 pb-4">
                            <button className="btn btn-outline btn-block create-directory" onClick={this.onCreateAssetDirectoryClick}>
                                <i className="fa fa-plus" />
                                &nbsp;
                                {Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAssetDirectory" })}
                            </button>
                        </div>
                    </>
                )}

                {this.props.account.accountType === AccountType.supervisor && (
                    <>
                        <LinkGroup
                            title={Intl.formatMessage({ id: "page.publicContentLibrary.myLibrary" })}
                            links={ContentLibraryLinks.getForSupervisor(this.props.location.pathname)}
                            isLinkActive={this.isLinkActive}
                            isDirectoryActive={this.isDirectoryActive}
                            isDirectoryHasActiveChild={this.isDirectoryHasActiveChild}
                            directories={this.state.personalDirectories ? this.state.personalDirectories : undefined}
                        />
                        {directory && !isPublic && (
                            <div className="grid-x pl-2 pb-4">
                                <button className="btn btn-primary btn-block create-directory fs-15" onClick={this.onEditAssetDirectoryClick(isPublic, directory)}>
                                    {Intl.formatMessage({ id: "page.publicContentLibrary.editLibrary" })}
                                </button>
                            </div>
                        )}

                        <div className="grid-x pl-2 pb-4">
                            <button className="btn btn-outline btn-block create-directory" onClick={this.onCreateAssetDirectoryClick}>
                                <i className="fa fa-plus" />
                                &nbsp;
                                {Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAssetDirectory" })}
                            </button>
                        </div>
                    </>
                )}
                <LinkGroup
                    title={Intl.formatMessage({ id: "page.publicContentLibrary.publicLibrary" })}
                    links={ContentLibraryLinks.getForPublic(this.props.location.pathname)}
                    isLinkActive={this.isLinkActive}
                    isDirectoryActive={this.isDirectoryActive}
                    isDirectoryHasActiveChild={this.isDirectoryHasActiveChild}
                    directories={this.state.publicDirectories ? this.state.publicDirectories : undefined}
                />
                {this.props.account.accountType === AccountType.superadmin && (
                    <>
                        {directory && isPublic && (
                            <div className="grid-x pl-2 pb-4">
                                <button className="btn btn-primary btn-block create-directory fs-15" onClick={this.onEditAssetDirectoryClick(isPublic, directory)}>
                                    {Intl.formatMessage({ id: "page.publicContentLibrary.editLibrary" })}
                                </button>
                            </div>
                        )}
                        <div className="grid-x pl-2 pb-4">
                            <button className="btn btn-outline btn-block create-directory" onClick={this.onCreateAssetDirectoryClick}>
                                <i className="fa fa-plus" />
                                &nbsp;
                                {Intl.formatMessage({ id: "sharedComponent.createContentButton.items.createAssetDirectory" })}
                            </button>
                        </div>
                    </>
                )}
                {this.props.account.accountType === AccountType.supporter && (
                    <>
                        <div className="pb-30">
                            <Link to={`${this.props.history.location.pathname}?${Intl.formatMessage({ id: "enum.contentLibraryQueryParameter.isDisabled" })}`} className="main-link">
                                {Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.disabled" })}
                            </Link>
                        </div>
                        <div>
                            <Link to={`${this.props.history.location.pathname}?${Intl.formatMessage({ id: "enum.contentLibraryQueryParameter.isShared" })}`} className="main-link">
                                {Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.shared" })}
                            </Link>
                        </div>
                    </>
                )}
            </>
        );
    };

    public render(): React.ReactElement<any> {
        const params = this.urlQueryParser.parse(this.props.location.search);
        if ((!params.searchListType || (!params.isPublic && ![AccountType.supporter, AccountType.supervisor].includes(this.props.account.accountType))) && !params.isDisabled && !params.isShared) {
            const urlQuery: string = this.urlQueryParser.getUrlQuery({
                searchListType: SearchListType.agendaItem,
                isPublic: [AccountType.admin, AccountType.superadmin].includes(this.props.account.accountType),
                isDisabled: false,
                isShared: false,
            });

            return <Redirect to={`${this.props.history.location.pathname}?${urlQuery}`} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.publicContentLibrary.title" })}>
                <ContentLibraryTable
                    renderLinks={this.state.isLoading ? () => <Loading type={LoadingType.layer} /> : this.renderLinks}
                    createdById={!params.isPublic ? this.props.account.id : undefined}
                    pageContent={params.isPublic ? PageContent.public : PageContent.own}
                    directory={params.isPublic ? this.state.publicDirectories : this.state.personalDirectories}
                    storageSizeUsed={IntlHelpers.getFileSize(this.props.account.storageSizeUsed)}
                    storageSizeLimit={IntlHelpers.getFileSize(this.props.account.config.storageSizeLimit)}
                />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const PublicContentLibraryPage = withRouter(connect(mapStateToProps)(PublicContentLibraryPageComponent));

// tslint:disable-next-line: no-default-export
export default PublicContentLibraryPage;

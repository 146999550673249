import React, { Component } from "react";
import { AssetContent, AssetType, EverydaySituationType } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { Input } from "components/Inputs/Input/Input";
import { Button } from "components/Button/Button";
import { Select } from "components/Inputs/Select/Select";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { EverydaySituationTypeOptions, EverydaySituationTypeOption } from "models/EverydaySituationTypeOptions";
import { ImageSrc } from "utils/ImageSrc";
import { Image } from "components/Image";
import { isEqual } from "lodash";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { store } from "store";
import "./EverydaySituationListItem.scss";

interface Props {
    isTypeEditable?: boolean;
    formValues: EverydaySituationFormValues | null;
    updateFormValues: (formValues: EverydaySituationFormValues | null) => void;
    onEditModeChange?: () => void;
    onMoveClick?: () => void;
    onCopyClick?: () => void;
    onDeleteClick?: () => void;
    isEditable: boolean;
    customActions?: () => React.ReactElement;
}

export interface EverydaySituationFormValues {
    id?: string;
    title: string;
    description?: string;
    audio: AssetContent | null;
    image: AssetContent | null;
    type: EverydaySituationTypeOption;
    isDisabled?: boolean;
}

interface State {
    isEditMode: boolean;
    formValues: EverydaySituationFormValues;
    errors: {
        title: string | null;
        description: string | null;
        image: string | null;
        audio: string | null;
    };
    isValidationEnabled: boolean;
}

export class EverydaySituationListItem extends Component<Props, State> {
    private titleRef: HTMLInputElement | null = null;
    private descriptionRef: HTMLTextAreaElement | null = null;

    private getInitialState = (props: Props): State => {
        return {
            isEditMode: false,
            formValues: {
                id: props.formValues?.id,
                title: props.formValues?.title || "",
                audio: props.formValues?.audio || null,
                description: props.formValues?.description,
                image: props.formValues?.image || null,
                type: props.formValues?.type || Select.getSelectOption(EverydaySituationTypeOptions.get(), EverydaySituationType.text),
                isDisabled: props.formValues?.isDisabled,
            },
            errors: {
                title: IntlHelpers.getValidationError(Validator.validateNonEmpty(props.formValues?.title || "")),
                image: IntlHelpers.getValidationError(Validator.validateNonNull(props.formValues?.image || null)),
                audio: IntlHelpers.getValidationError(Validator.validateNonNull(props.formValues?.audio || null)),
                description: IntlHelpers.getValidationError(Validator.validateNonEmpty(props.formValues?.description || "")),
            },
            isValidationEnabled: false,
        };
    };

    public readonly state: State = this.getInitialState(this.props);

    public componentDidUpdate(prevProps: Props): void {
        if (!isEqual(this.props.formValues, prevProps.formValues)) {
            this.setState(this.getInitialState(this.props));
        }
    }

    public readonly isEditMode = (): boolean => {
        return this.state.isEditMode;
    };

    private readonly onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title = event.currentTarget.value;
        const titleError = IntlHelpers.getValidationError(Validator.validateNonEmpty(title));
        this.setState({ formValues: { ...this.state.formValues, title }, errors: { ...this.state.errors, title: titleError } });
    };

    public readonly onAddClick = (): void => {
        const { errors } = this.state;
        if (!!errors.title || !this.isDataFieldValid()) {
            if (!!errors.title && this.titleRef) {
                this.titleRef.focus();
            } else if (!!errors.description && this.descriptionRef) {
                this.descriptionRef.focus();
            }
            this.setState({ isValidationEnabled: true });
            return;
        }
        this.setState({ isEditMode: false }, () => {
            this.props.updateFormValues({ ...this.state.formValues });
        });
    };

    private readonly isDataFieldValid = (): boolean => {
        switch (this.state.formValues.type.value) {
            case EverydaySituationType.text:
                return !this.state.errors.description;
            case EverydaySituationType.image:
                return !this.state.errors.image;
            case EverydaySituationType.audio:
                return !this.state.errors.audio;
            case EverydaySituationType.text_and_image:
                return !this.state.errors.image && !this.state.errors.description;
            default:
                return true;
        }
    };

    private readonly onTypeChange = (type: EverydaySituationTypeOption): void => {
        if (type !== this.state.formValues.type) {
            this.setState({ formValues: { ...this.state.formValues, type, audio: null, image: null, description: undefined } });
        }
    };

    private readonly onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const description = event.currentTarget.value;
        const descriptionError = IntlHelpers.getValidationError(Validator.validateNonEmpty(description));
        this.setState({ formValues: { ...this.state.formValues, description }, errors: { ...this.state.errors, description: descriptionError } });
    };

    private readonly onImageChange = (assets: AssetContent[]): void => {
        const image = assets[0] || null;
        const imageError = IntlHelpers.getValidationError(Validator.validateNonNull(image));
        this.setState({ formValues: { ...this.state.formValues, image }, errors: { ...this.state.errors, image: imageError } });
    };

    private readonly onAudioChange = (assets: AssetContent[]): void => {
        const audio = assets[0] || null;
        const audioError = IntlHelpers.getValidationError(Validator.validateNonNull(audio));
        this.setState({ formValues: { ...this.state.formValues, audio }, errors: { ...this.state.errors, audio: audioError } });
    };

    private readonly renderInputByType = (): React.ReactElement<any> | null => {
        const { errors, isValidationEnabled } = this.state;
        switch (this.state.formValues.type.value) {
            case EverydaySituationType.text:
                return (
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.text.label" })} errorMessage={isValidationEnabled ? errors.description : null}>
                        <TextArea
                            innerRef={(ref: HTMLTextAreaElement | null): void => {
                                this.descriptionRef = ref;
                            }}
                            placeholder={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.text.placeholder" })}
                            hasError={isValidationEnabled && !!errors.description}
                            value={this.state.formValues.description}
                            onChange={this.onDescriptionChange}
                        />
                    </InputWrapper>
                );
            case EverydaySituationType.image:
                return (
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.image.label" })} errorMessage={isValidationEnabled ? errors.image : null}>
                        <AssetInput
                            droppableId="image"
                            assetType={AssetType.image}
                            value={this.state.formValues.image ? [this.state.formValues.image] : []}
                            onChange={this.onImageChange}
                            maxItemCount={1}
                            minItemCount={1}
                            hasError={isValidationEnabled && !!errors.image}
                        />
                    </InputWrapper>
                );
            case EverydaySituationType.audio:
                return (
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.audio.label" })} errorMessage={isValidationEnabled ? errors.audio : null}>
                        <AssetInput
                            droppableId="audio"
                            assetType={AssetType.audio}
                            value={this.state.formValues.audio ? [this.state.formValues.audio] : []}
                            onChange={this.onAudioChange}
                            maxItemCount={1}
                            minItemCount={1}
                            hasError={isValidationEnabled && !!errors.audio}
                        />
                    </InputWrapper>
                );
            default:
                return null;
        }
    };

    private renderEditMode(): React.ReactElement<any> {
        const { formValues, errors, isValidationEnabled } = this.state;
        return (
            <div className="edit-mode-content">
                <hr />
                <div className="edit-mode-section">
                    <h3>{Intl.formatMessage({ id: `page.clientEverydaySituations.directories.form.formTitle.${this.props.isTypeEditable ? "create" : "edit"}` })}</h3>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.type.label" })}>
                        <Select options={EverydaySituationTypeOptions.get()} onChange={this.onTypeChange} value={formValues.type} disabled={!this.props.isTypeEditable} />
                    </InputWrapper>
                    <InputWrapper
                        inputLabel={Intl.formatMessage({ id: `page.clientEverydaySituations.directories.form.title.label.${formValues.type.value}` })}
                        errorMessage={isValidationEnabled ? errors.title : null}
                    >
                        <Input
                            innerRef={(ref: HTMLInputElement | null) => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            value={formValues.title}
                            onChange={this.onTitleChange}
                            hasError={isValidationEnabled && !!errors.title}
                            placeholder={Intl.formatMessage({ id: "page.clientEverydaySituations.directories.form.title.placeholder" })}
                        />
                    </InputWrapper>
                    {this.renderInputByType()}
                </div>
                <div className="button-container">
                    <Button
                        hollow={true}
                        label={Intl.formatMessage({ id: "common.cancel" })}
                        onClick={() => {
                            this.setState(this.getInitialState(this.props), () => {
                                this.props.onEditModeChange && this.props.onEditModeChange();
                            });
                            if (this.props.isTypeEditable) {
                                this.props.updateFormValues(null);
                            }
                        }}
                    />
                    <Button label={Intl.formatMessage({ id: this.props.isTypeEditable ? "common.add" : "common.save" })} onClick={this.onAddClick} />
                </div>
            </div>
        );
    }

    private readonly onImageClick = (): void => {
        const { formValues } = this.state;
        if (formValues.image?.url) {
            store.dispatch(
                DialogsActions.show({
                    type: DialogType.showAsset,
                    assetUrl: formValues.image.url,
                    assetType: formValues.image.assetType,
                    originalFileName: formValues.image.originalFileName,
                    thumbnailUrl: formValues.image.url,
                    dialogTitle: formValues.title,
                }),
            );
        } else if (formValues.audio?.url) {
            store.dispatch(
                DialogsActions.show({
                    type: DialogType.showAsset,
                    assetUrl: formValues.audio.url,
                    assetType: formValues.audio.assetType,
                    originalFileName: formValues.audio.originalFileName,
                    thumbnailUrl: formValues.audio.url,
                    dialogTitle: formValues.title,
                }),
            );
        } else {
            store.dispatch(
                DialogsActions.show({
                    type: DialogType.info,
                    title: formValues.title,
                    message: formValues.description!,
                }),
            );
        }
    };

    private renderReadonlyMode(): React.ReactElement<any> {
        const { formValues } = this.state;
        const imageSrc = formValues.image?.url || (formValues.audio && ImageSrc.audioAsset) || ImageSrc.textIcon;
        return (
            <>
                <hr />
                <div className="grid-x readonly-mode-content">
                    <div className="thumbnail-container" onClick={this.onImageClick}>
                        <Image src={imageSrc} />
                        <div className="content-overlay" />
                        <div className="content-overlay-icon">
                            <span className="fa fa-search-plus" />
                        </div>
                    </div>
                    <span className="cell auto title">
                        {formValues.title}
                        {formValues.isDisabled && (
                            <>
                                <br />
                                <span className="badge badge-danger">
                                    <i className="fa fa-lock" />
                                    &nbsp;{Intl.formatMessage({ id: "common.disabledContent" })}
                                </span>
                            </>
                        )}
                    </span>
                    <div className="cell shrink action-buttons">
                        {this.props.isEditable && (
                            <Button
                                className="edit"
                                link
                                icon={{ name: "fa-pencil-alt", large: true }}
                                title={Intl.formatMessage({ id: "common.edit" })}
                                ariaLabel={Intl.formatMessage({ id: "common.edit" })}
                                onClick={() => {
                                    this.setState({ isEditMode: true }, () => {
                                        this.props.onEditModeChange && this.props.onEditModeChange();
                                    });
                                }}
                            />
                        )}
                        {this.props.onMoveClick && (
                            <Button
                                link
                                title={Intl.formatMessage({ id: "common.move" })}
                                ariaLabel={Intl.formatMessage({ id: "common.move" })}
                                icon={{ name: "fa-file-export", large: true }}
                                onClick={this.props.onMoveClick}
                            />
                        )}
                        {this.props.onCopyClick && (
                            <Button
                                link
                                title={Intl.formatMessage({ id: "common.copy" })}
                                ariaLabel={Intl.formatMessage({ id: "common.copy" })}
                                icon={{ name: "fa-clone", large: true }}
                                onClick={this.props.onCopyClick}
                            />
                        )}
                        {this.props.onDeleteClick && (
                            <Button
                                className="delete"
                                link
                                icon={{ name: "fa-trash", large: true }}
                                title={Intl.formatMessage({ id: "common.delete" })}
                                ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                onClick={this.props.onDeleteClick}
                            />
                        )}
                        {this.props.customActions && this.props.customActions()}
                    </div>
                </div>
            </>
        );
    }

    public render(): React.ReactElement<any> {
        return <div className="everyday-situation-list-item">{this.props.isTypeEditable || this.state.isEditMode ? this.renderEditMode() : this.renderReadonlyMode()}</div>;
    }
}

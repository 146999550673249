import React, { useContext } from "react";
import { DroppableProvided, DroppableStateSnapshot, DroppableProvidedProps } from "react-beautiful-dnd";
import { useDrop } from "react-dnd";
import { DragContext } from "./DragContext";
import { Color } from "theme/Color";

export interface Props {
    droppableId: string;
    type?: string | string[];
    isDropDisabled?: boolean;
    children(provided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement<HTMLElement>;
}

export const Droppable: React.FC<Props> = (props: Props) => {
    const context = useContext(DragContext);
    const [{ isOverCurrent }, drop] = useDrop({
        accept: props.type || [],
        drop(item, monitor) {
            if (monitor.isOver()) {
                context.onDragEnd(
                    {
                        source: {
                            droppableId: (item as any).source?.droppableId,
                            index: 0,
                        },
                        destination: {
                            droppableId: props.droppableId,
                            index: 0,
                        },
                    },
                    {},
                    undefined,
                );
            }
        },
        // hover: (item: any) => {
        //     (item as any).droppableId = props.droppableId;
        // },
        canDrop: (_item, monitor) => {
            return !props.isDropDisabled && monitor.isOver({ shallow: true });
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    });

    const provided: DroppableProvided = {
        innerRef: (_element?: HTMLElement | null) => {},
        placeholder: null,
        droppableProps: {} as DroppableProvidedProps,
    };

    const snapshot: DroppableStateSnapshot = {
        isDraggingOver: isOverCurrent,
        draggingOverWith: undefined,
        draggingFromThisWith: undefined,
    };

    const backgroundColor = !props.isDropDisabled && isOverCurrent ? Color.primaryXxl : undefined;
    return (
        <div ref={drop} style={{ backgroundColor, padding: 8 }}>
            {props.children(provided, snapshot)}
        </div>
    );
};

import React, { Component } from "react";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { ColorSelect } from "components/Inputs/ColorSelect/ColorSelect";
import { ColorType } from "selectors/SettingsSelectors";
import { NamedColor, SupportedClient, ClientConfigInput, MainPageType, DashboardItem, ClientGameInput, ClientMainPageConfig, Game } from "api/graphql/types";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { ClientSettingsSelectOptions } from "models/ClientSettingsSelectOptions";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { cloneDeep, isEqual, isNil } from "lodash";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

interface OwnProps {
    onShowNotification: () => void;
}

type Props = OwnProps & RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

interface CurrentConfig {
    mainPageType: SelectOption<MainPageType>;
    mainPageBackgroundColor: string | null;
    mainPageDataSyncEnabled: boolean;
    enabledDashboardItems: DashboardItem[];
    enabledGames: Array<ClientGameInput>;
}

interface FormErrors {
    enabledGames: string | null;
}

interface State {
    clientMainPageConfig: ClientMainPageConfig | null;
    notificationOnProfilePage: string | null;
    validationEnabled: boolean;
    currentConfig: CurrentConfig;
    formErrors: FormErrors;
    isLoading: boolean;
}

class MainPageTabComponent extends Component<Props, State> {
    private enabledGamesRef: HTMLDivElement | null = null;

    public readonly state: State = {
        clientMainPageConfig: null,
        notificationOnProfilePage: null,
        validationEnabled: false,
        currentConfig: {
            mainPageType: ClientSettingsSelectOptions.mainPageType[0],
            mainPageBackgroundColor: null,
            mainPageDataSyncEnabled: false,
            enabledDashboardItems: [],
            enabledGames: [],
        },
        formErrors: {
            enabledGames: null,
        },
        isLoading: true,
    };

    private getDefaultConfig = (clientMainPageConfig: ClientMainPageConfig): CurrentConfig => {
        const { mainPageType, mainPageBackgroundColor, mainPageDataSyncEnabled, enabledDashboardItems, enabledGames } = clientMainPageConfig;

        return {
            mainPageType: Select.getSelectOption(ClientSettingsSelectOptions.mainPageType, mainPageType),
            mainPageBackgroundColor,
            mainPageDataSyncEnabled,
            enabledDashboardItems: [...(enabledDashboardItems || [])],
            enabledGames: [...(enabledGames || [])],
        };
    };

    private refreshSettingsData = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { clientMainPageConfig, notificationOnProfilePage } = await Api.getClientMainPageSettingsData(clientId);
                    this.setState({
                        clientMainPageConfig,
                        currentConfig: this.getDefaultConfig(cloneDeep(clientMainPageConfig)),
                        notificationOnProfilePage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshSettingsData(this.props.client.id);
        } else {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
            this.setState({ isLoading: false });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshSettingsData(nextProps.client.id);
        }
    }

    private onCurrentConfigChange = (currentConfigChanges: Partial<CurrentConfig>, formErrorChanges?: Partial<FormErrors>): void => {
        this.setState({ currentConfig: { ...this.state.currentConfig, ...currentConfigChanges }, formErrors: { ...this.state.formErrors, ...formErrorChanges } });
    };

    private convertCurrentConfigToClientConfigInput = (): ClientConfigInput => {
        const { mainPageType, mainPageBackgroundColor, mainPageDataSyncEnabled, enabledDashboardItems, enabledGames } = this.state.currentConfig;

        return {
            enabledDashboardItems,
            enabledGames: enabledGames.map((enabledGame: ClientGameInput) => {
                return { name: enabledGame.name };
            }),
            mainPageType: mainPageType.value,
            mainPageBackgroundColor,
            mainPageDataSyncEnabled,
        };
    };

    private isChanged = (): boolean => {
        if (this.state.clientMainPageConfig) {
            return !isEqual(this.getDefaultConfig(this.state.clientMainPageConfig), this.state.currentConfig);
        }
        return false;
    };

    private shouldEnabledDashboardItemsBadConfigOpen = (): void => {
        const { clientMainPageConfig, currentConfig } = this.state;
        const isEnabledDashboardItemAdded: boolean =
            !isNil(clientMainPageConfig) && this.getDefaultConfig(clientMainPageConfig).enabledDashboardItems.length < currentConfig.enabledDashboardItems.length;
        const badConfigItems: string[] = [];

        if (!isEnabledDashboardItemAdded) {
            return;
        }

        if (
            clientMainPageConfig?.enabledDashboardItems &&
            !clientMainPageConfig.enabledDashboardItems.find((item: DashboardItem) => item === DashboardItem.moodMeter) &&
            currentConfig.enabledDashboardItems.find((item: DashboardItem) => item === DashboardItem.moodMeter) &&
            (!clientMainPageConfig.moodMeter || !clientMainPageConfig.moodMeter.enabled)
        ) {
            badConfigItems.push(Intl.formatMessage({ id: `page.clientSettings.enabledDashboardItems.${DashboardItem.moodMeter}` }));
        }

        if (
            clientMainPageConfig?.enabledDashboardItems &&
            !clientMainPageConfig.enabledDashboardItems.find((item: DashboardItem) => item === DashboardItem.awards) &&
            currentConfig.enabledDashboardItems.find((item: DashboardItem) => item === DashboardItem.awards)
        ) {
            badConfigItems.push(Intl.formatMessage({ id: `page.clientSettings.enabledDashboardItems.${DashboardItem.awards}` }));
        }

        if (badConfigItems.length > 0) {
            Alert.info({ title: Intl.formatMessage({ id: "page.clientSettings.enabledDashboardItemsWarning" }, { badConfigItems: badConfigItems.join(", ") }) });
        }
    };

    private onSaveClick = async (): Promise<void> => {
        if (!this.props.client) {
            return;
        }

        const formErrors = {
            enabledGames: this.state.currentConfig.enabledDashboardItems.includes(DashboardItem.games)
                ? IntlHelpers.getValidationError(Validator.validateEnabledGamesCount(this.state.currentConfig.enabledGames))
                : null,
        };

        if (formErrors.enabledGames) {
            this.setState({ validationEnabled: true, formErrors }, () => {
                if (!!formErrors.enabledGames && this.enabledGamesRef) {
                    this.enabledGamesRef.scrollIntoView();
                }
            });
            return;
        }

        try {
            const clientMainPageConfig: ClientMainPageConfig = await Api.updateClientConfig(this.props.client.id, this.convertCurrentConfigToClientConfigInput());

            this.shouldEnabledDashboardItemsBadConfigOpen();

            this.props.onShowNotification();

            this.setState({ clientMainPageConfig, currentConfig: this.getDefaultConfig(cloneDeep(clientMainPageConfig)) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
        this.setState({ isLoading: false });
    };

    private onEnabledDashboardItemsChange = (dashboardItem: DashboardItem) => () => {
        let enabledDashboardItems: DashboardItem[] = [...this.state.currentConfig.enabledDashboardItems];
        let clearEnabledGames = false;

        if (enabledDashboardItems.includes(dashboardItem)) {
            if (dashboardItem === DashboardItem.games) {
                clearEnabledGames = true;
            }
            enabledDashboardItems = enabledDashboardItems.filter((item: DashboardItem): boolean => item !== dashboardItem);
        } else {
            enabledDashboardItems.push(dashboardItem);
        }

        this.onCurrentConfigChange(
            {
                enabledDashboardItems,
                enabledGames: clearEnabledGames ? [] : [...this.state.currentConfig.enabledGames],
            },
            { ...this.state.formErrors, enabledGames: null },
        );
    };

    private onEnabledGamesChange = (game: ClientGameInput) => () => {
        let enabledGames: Array<ClientGameInput> = [...this.state.currentConfig.enabledGames];

        if (enabledGames.find((gameInput: ClientGameInput) => gameInput && gameInput.name === game.name)) {
            enabledGames = enabledGames.filter((enabledGame: ClientGameInput): boolean => {
                return !!enabledGame && game.name !== enabledGame.name;
            });
        } else {
            enabledGames.push(game);
        }

        this.onCurrentConfigChange({ enabledGames }, { ...this.state.formErrors, enabledGames: null });
    };

    private renderDashboardGamesCheckboxes = (): JSX.Element => {
        return (
            <div
                className="dashboard-games-checkbox"
                ref={(ref: HTMLDivElement | null): void => {
                    this.enabledGamesRef = ref;
                }}
            >
                {ObjectUtils.enumAsArray<Game>(Game).map((game: Game) => {
                    return (
                        <Checkbox
                            key={game}
                            className={game}
                            label={Intl.formatMessage({ id: `page.clientSettings.enabledGames.${game}` })}
                            checked={!isNil(this.state.currentConfig.enabledGames.find((gameInput: ClientGameInput | null) => gameInput && gameInput.name === game))}
                            onChange={this.onEnabledGamesChange({ name: game })}
                        />
                    );
                })}
                {this.state.formErrors.enabledGames && <ErrorMessage message={this.state.formErrors.enabledGames} />}
            </div>
        );
    };

    private onCancelClick = (): void => {
        this.setState({ validationEnabled: false, currentConfig: this.getDefaultConfig(cloneDeep(this.state.clientMainPageConfig!)) });
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client || (!this.state.isLoading && !this.state.clientMainPageConfig)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientSettings.mainPageTab.title" })}>
                    <p className="lead">{Intl.formatMessage({ id: "page.clientSettings.mainPageTab.description" }, { name: this.props.client.name })}</p>

                    <InputWrapper id={TestId.clientSettings.mainPageType} inputLabel={Intl.formatMessage({ id: "page.clientSettings.mainPageTab.mainPageType" })}>
                        <Select
                            options={ClientSettingsSelectOptions.mainPageType}
                            value={this.state.currentConfig.mainPageType}
                            onChange={(mainPageType: SelectOption<MainPageType>) => {
                                this.onCurrentConfigChange({ mainPageType });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.mainPageTab.mainPageBackgroundColor" })}>
                        <ColorSelect
                            colorType={ColorType.mainPageBackground}
                            selectedColor={this.state.currentConfig.mainPageBackgroundColor}
                            onChange={(color: NamedColor | null): void => {
                                this.onCurrentConfigChange({ mainPageBackgroundColor: color ? color.value : null });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper id={TestId.clientSettings.mainPageDataSyncEnabled} inputLabel={Intl.formatMessage({ id: "page.clientSettings.mainPageTab.mainPageDataSyncEnabled" })}>
                        <Checkbox
                            checked={this.state.currentConfig.mainPageDataSyncEnabled}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({ mainPageDataSyncEnabled: event.currentTarget.checked });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.mainPageTab.enabledDashboardItems" })}>
                        <div className="enabledDashboardItems">
                            {ObjectUtils.enumAsArray<DashboardItem>(DashboardItem).map((item: DashboardItem) => {
                                return (
                                    <div key={item}>
                                        <Checkbox
                                            className={item}
                                            label={Intl.formatMessage({ id: `page.clientSettings.enabledDashboardItems.${item}` })}
                                            checked={this.state.currentConfig.enabledDashboardItems.includes(item)}
                                            onChange={this.onEnabledDashboardItemsChange(item)}
                                        />
                                        {item === DashboardItem.games && this.state.currentConfig.enabledDashboardItems.includes(item) && this.renderDashboardGamesCheckboxes()}
                                    </div>
                                );
                            })}
                        </div>
                    </InputWrapper>
                </Section>
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button id={TestId.clientSettings.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button id={TestId.clientSettings.saveButton} label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>
                <Prompt when={this.isChanged()} />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
    };
};

export const MainPageTab = withRouter(connect(mapStateToProps)(MainPageTabComponent));

export default MainPageTab;

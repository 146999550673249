import React from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps, RouteComponentProps } from "react-router";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Path } from "utils/Path";
import { AccountType } from "api/graphql/types";
import { AccountSelectors } from "selectors/AccountSelectors";
import { AuthSelectors } from "selectors/AuthSelectors";
import { Log } from "utils/Log";

interface ReduxProps {
    accountType: AccountType | null;
    isLoggedIn: boolean;
}

type ComponentProps = RouteProps & { availableFor?: AccountType[] };

type Props = ReduxProps & ComponentProps & DispatchProp;

class PrivateRouteComponent extends React.Component<Props> {
    public render(): React.ReactElement {
        const { component: Component, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props: RouteComponentProps<any>): React.ReactNode | null => {
                    if (!this.props.isLoggedIn) {
                        Log.debug("User not authenticated, redirecting to login screen...");
                        return <Redirect to={{ pathname: Path.login, state: { from: { pathname: props.location } } }} />;
                    } else {
                        if (this.props.accountType && this.props.availableFor && !this.props.availableFor.includes(this.props.accountType)) {
                            Log.debug("User role not fulfill required role", "accountType:", this.props.accountType, "availableFor:", this.props.availableFor, rest);
                            return <Redirect to={Path.dashboard} />;
                        }

                        if (Component) {
                            return <Component {...props} />;
                        } else if (this.props.render) {
                            return this.props.render(props);
                        }
                        return null;
                    }
                }}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        accountType: AccountSelectors.getAccountType(state),
        isLoggedIn: AuthSelectors.isLoggedIn(state),
    };
};

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);

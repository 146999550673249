import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Button } from "components/Button/Button";
import { Account } from "api/graphql/types";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType, NotificationTimeout } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    account: Account;
}

type Props = ReduxProps & DispatchProp & NotificationProp;

interface State {
    isEditable: boolean;
    email: string;
    formErrors: {
        email: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ProfileEmailFormComponent extends Component<Props, State> {
    private emailRef: HTMLInputElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            email: this.props.account.email!,
            formErrors: {
                email: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.target.value;
        const emailError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateEmail(email)) : null;
        this.setState({ email, formErrors: { ...this.state.formErrors, email: emailError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { email } = this.state;
        const formErrors = {
            email: IntlHelpers.getValidationError(Validator.validateEmail(email)),
        };

        if (formErrors.email) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.email && this.emailRef) {
                    this.emailRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.changeEmailRequest(email);
                    this.props.showNotification({
                        type: NotificationType.info,
                        message: Intl.formatMessage({ id: "page.profile.personalData.emailForm.emailChangeSucceed" }),
                        timeout: NotificationTimeout.long,
                    });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, email, formErrors, isLoading } = this.state;
        return (
            <form noValidate={true} onSubmit={this.onSubmit} id={TestId.profilePage.emailForm}>
                <InputWrapper id={TestId.profilePage.emailInput} inputLabel={Intl.formatMessage({ id: "page.profile.personalData.emailForm.email.label" })} errorMessage={formErrors.email}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.emailRef = ref;
                        }}
                        hasError={!!formErrors.email}
                        placeholder={Intl.formatMessage({ id: "page.profile.personalData.emailForm.email.placeholder" })}
                        type="email"
                        value={email}
                        onChange={this.onEmailChange}
                        disabled={!isEditable}
                    />
                </InputWrapper>

                {isEditable ? (
                    <div className="action-button-container">
                        <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            type="submit"
                            disabled={isLoading || !!formErrors.email || this.props.account.email === this.state.email}
                        />
                    </div>
                ) : (
                    <div className="edit-button">
                        <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                    </div>
                )}
                <Prompt when={email !== this.props.account.email} />
            </form>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        account: state.account!,
    };
};

export const ProfileEmailForm = withNotification(connect(mapStateToProps)(ProfileEmailFormComponent));

import { Device, DeviceType } from "api/graphql/types";
import parser from "ua-parser-js";

export class DeviceDetect {
    public static getDeviceInfo(device: Device): string {
        const parseResult = new parser.UAParser(device.info.userAgent).getResult();
        let result = "";
        if (device.deviceType === DeviceType.ios) {
            if (parseResult.device.model) {
                result = `${parseResult.device.model} ${parseResult.os.version}`;
            } else {
                result = "iPhone";
            }
        } else {
            result = `Android${parseResult.os.version ? ` ${parseResult.os.version}` : ""}`;
            if (parseResult.device.vendor || parseResult.device.model) {
                result += ", ";
            }
            result += `${parseResult.device.vendor ? `${parseResult.device.vendor}` : ""}${parseResult.device.model ? ` ${parseResult.device.model}` : ""}`;
        }

        return result;
    }

    public static getAppVersion(userAgent: string): string {
        let appVersion = "";
        let versionPart = userAgent.split("AOSZ-")[1];
        if (versionPart) {
            versionPart = versionPart.split("/")[1];
            const result = versionPart.padStart(6, "0").match(/.{1,2}/g);
            if (result) {
                const [major, minor, patch] = result;
                appVersion = `${Number.parseInt(major, 10)}.${Number.parseInt(minor, 10)}.${Number.parseInt(patch, 10)}`;
            }
        }
        return `v${appVersion}`;
    }
}

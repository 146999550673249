import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { DialogVisibilityProps } from "./DialogsContainer";
import { ClientProfileData, setSelfSupportingStateDecisionVariables } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "../Alert/Alert";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { connect } from "react-redux";
import { ApplicationState } from "../../reducers/index";
import { AccountReducerState } from "../../reducers/AccountReducer";

interface ReduxProps {
    account: AccountReducerState;
}

export interface SelfSupportingDialogProps {
    isSelfSupporting: boolean;
    clientProfile: ClientProfileData | null;
    onConfirmed: (success: boolean) => void;
}

interface SelfSupportingDialogState {
    progress: 1 | 2;
}

type Props = SelfSupportingDialogProps & DialogVisibilityProps & ReduxProps;

class SelfSupportingDialogComponent extends PureComponent<Props, SelfSupportingDialogState> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            progress: 1,
        };
    }

    private getTitle = () => `${Intl.formatMessage({ id: "page.clientProfile.selfSupporting.dialog.title" }, { name: this.props.clientProfile?.name })}`;

    private setStateDecision = async () => {
        const { clientProfile, isSelfSupporting } = this.props;
        if (clientProfile) {
            const variables: setSelfSupportingStateDecisionVariables = {
                clientId: clientProfile.id,
                selfSupportingState: isSelfSupporting,
            };
            try {
                await Api.setSelfSupportingStateDecision(variables);
                this.props.onConfirmed(true);
                this.props.onHide();
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            }
        }
    };

    private renderConfirm = (): React.ReactElement<any> => {
        return (
            <div className="dialog-content">
                {!this.props.isSelfSupporting ? <div className="title mb-1">{Intl.formatMessage({ id: `page.clientProfile.selfSupporting.dialog.${this.props.isSelfSupporting}.title` })}</div> : null}
                <div className="description max-dialog-height">
                    {IntlHelpers.asHtml({ id: `page.clientProfile.selfSupporting.dialog.${this.props.isSelfSupporting}.description` }, { name: this.props.clientProfile?.name })}
                    {this.props.isSelfSupporting ? (
                        <>
                            <hr />
                            <ul className="client-list">{IntlHelpers.asHtml({ id: "page.clientProfile.selfSupporting.dialog.true.list" }, { name: this.props.clientProfile?.name })}</ul>
                        </>
                    ) : null}
                </div>
                <hr />
                <div className="grid-x">
                    <div className="btn btn-outline" onClick={this.props.onHide}>
                        {Intl.formatMessage({ id: "common.cancel" })}
                    </div>
                    <div
                        className="btn btn-primary"
                        onClick={() =>
                            this.setState({
                                progress: 2,
                            })
                        }
                    >
                        {Intl.formatMessage({ id: this.props.isSelfSupporting ? "common.next" : "page.clientProfile.selfSupporting.dialog.false.next" })}
                    </div>
                </div>
            </div>
        );
    };

    private renderStatement = (): React.ReactElement<any> => {
        return (
            <div className="dialog-content">
                <div className="description">
                    {IntlHelpers.asHtml(
                        { id: `page.clientProfile.selfSupporting.dialog.${this.props.isSelfSupporting}.statement` },
                        { clientName: this.props.clientProfile?.name, supervisorName: this.props.account?.name },
                    )}
                </div>
                <hr />
                <div className="grid-x">
                    <div className="btn btn-outline" onClick={() => this.setState({ progress: 1 })}>
                        {Intl.formatMessage({ id: "common.back" })}
                    </div>
                    <div className="btn btn-primary" onClick={() => this.setStateDecision()}>
                        {Intl.formatMessage({ id: "page.clientProfile.selfSupporting.buttons.statement" })}
                    </div>
                </div>
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        const { progress } = this.state;
        return (
            <Dialog title={this.getTitle()} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                {progress === 1 ? this.renderConfirm() : this.renderStatement()}
            </Dialog>
        );
    }
}

const SelfSupportingDialog = connect((state: ApplicationState) => ({ account: state.account }))(SelfSupportingDialogComponent);

export { SelfSupportingDialog };

import React, { Component } from "react";
import { SearchListType, AgendaItemContent, AgendaItemAgendaRel, Agenda, SupportedClient, Account, AccountType, Device, Tag, LibraryContentShareRequestWithData } from "api/graphql/types";
import { Input } from "components/Inputs/Input/Input";
import { cloneDeep } from "apollo-utilities";
import isEqual from "lodash/isEqual";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { PageType } from "utils/TypeUtils";
import { Alert } from "components/Alert/Alert";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { AgendaItemInput } from "pages/_shared/Draggables/Input/AgendaItemInput";
import sortBy from "lodash/sortBy";
import { ContentPageButtons } from "pages/_shared/ContentPageButtons/ContentPageButtons";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { ContentDeviceList } from "components/ContentDeviceList/ContentDeviceList";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import { LibraryContentShareRequestSection } from "pages/_shared/LibraryShareRequestSection/LibraryContentShareRequestSection";

interface ReduxProps {
    tags: Tag[];
}

interface ComponentProps {
    myAccount: Account;
    client?: SupportedClient | null;
    clientExtId?: string;
    supporterExtId?: string;
    isLoading: boolean;
    pageType: PageType;
    agenda: Agenda;
    clientDevices?: Device[];
    onSubmit: (agenda: Agenda) => Promise<Agenda>;
    onEditClick: () => void;
    onToggled: () => void;
    pageContent: PageContent;
    supervisorId?: string;
    accountType: AccountType;
    refreshAgenda: () => void;

    shareRequest?: LibraryContentShareRequestWithData;
}

interface Errors {
    title: string | null;
    description: string | null;
}

interface State {
    isLoading: boolean;
    agenda: Agenda;
    errors: Errors;
    isValidationEnabled: boolean;
    isPromptDisabled: boolean; // For prompt
}

type Props = ReduxProps & ComponentProps & RouteComponentProps & DispatchProp;

class AgendaFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;
    private descriptionRef: HTMLTextAreaElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            agenda: cloneDeep(props.agenda),
            errors: {
                title: null,
                description: null,
            },
            isValidationEnabled: false,
            isPromptDisabled: false,
            isLoading: false,
        };
    }

    public readonly state: State = AgendaFormComponent.getInitialStateFromProps(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.agenda !== nextProps.agenda) {
            this.setState(AgendaFormComponent.getInitialStateFromProps(nextProps));
        }
    }

    private onSubmitClick = (): void => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaTitle(this.state.agenda.title));
        const descriptionError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaDescription(this.state.agenda.description));
        if (errors.title !== null || titleError || errors.description !== null || descriptionError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError, description: descriptionError } });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            } else if (errors.description && this.descriptionRef) {
                this.descriptionRef.focus();
            }
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const agenda: Agenda = await this.props.onSubmit(this.state.agenda);
                    this.setState({ isPromptDisabled: true, isLoading: false });
                    if (this.props.pageType === PageType.create) {
                        const path: string | undefined = ContentPageUtils.getEditPath(agenda, this.props.pageContent, this.props.client ? this.props.client.extId : undefined);

                        if (path) {
                            this.props.history.push(path);
                        }
                    }
                    if (this.pageRef) {
                        this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.agenda.saveSucceed" }), type: NotificationType.success });
                    }
                } catch (error) {
                    this.setState({ isLoading: true });
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                }
            },
        );
    };

    private onAssignClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.assignToClient,
                content: this.props.agenda,
            }),
        );
    };

    private onShareClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.shareContent,
                content: this.props.agenda,
                onSucceed: this.props.refreshAgenda,
            }),
        );
    };

    private onTogglerClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                onToggled: this.onToggled,
                content: this.props.agenda,
            }),
        );
    };

    private onDeleteClick = () => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteAgenda,
                onDeleted: () => {
                    this.props.history.push(ContentPageUtils.getRootPath(this.props.match.path, this.props.clientExtId, this.props.supporterExtId));
                },
                agenda: this.props.agenda,
            }),
        );
    };

    private onToggled = (): void => {
        this.props.onToggled();
    };

    private onSyncClick = (): void => {
        if (!this.props.client) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.sync,
                client: this.props.client,
                close: this.props.refreshAgenda,
            }),
        );
    };

    public renderPageButtons = (): React.ReactElement<any> | null => {
        if (this.props.pageContent === PageContent.shareRequest) {
            return null;
        }
        return (
            <>
                {this.props.agenda.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                <ContentPageButtons
                    pageContent={this.props.pageContent}
                    content={this.props.agenda}
                    isSaveDisabled={!this.isChanged()}
                    myAccount={this.props.myAccount}
                    pageType={this.props.pageType}
                    onShareClick={this.onShareClick}
                    onAssignClick={this.onAssignClick}
                    onEditClick={this.props.onEditClick}
                    onSubmitClick={this.onSubmitClick}
                    onToggleClick={this.onTogglerClick}
                    onDeleteClick={() => this.onDeleteClick()}
                    onSyncClick={this.onSyncClick}
                />
            </>
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaTitle(title));
        this.setState({ agenda: { ...this.state.agenda, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const description: string = event.currentTarget.value;
        const descriptionError: string | null = IntlHelpers.getValidationError(Validator.validateAgendaDescription(description));
        this.setState({ agenda: { ...this.state.agenda, description }, errors: { ...this.state.errors, description: descriptionError } });
    };

    private onAgendaItemsChange = (agendaItems: AgendaItemContent[]) => {
        const ids = (this.props.agenda.itemList || []).map(i => {
            return { relId: i.id, agendaItemId: i.agendaItem.id };
        });

        const itemList: AgendaItemAgendaRel[] = agendaItems.map(
            (agendaItem: AgendaItemContent, position: number): AgendaItemAgendaRel => {
                const index = ids.findIndex(i => i.agendaItemId === agendaItem.id);
                let relId: string | undefined = undefined;
                if (index !== -1) {
                    relId = ids[index].relId;
                    ids.splice(index, 1);
                }
                return { id: relId!, agendaItem, position, __typename: "AgendaItemAgendaRel" };
            },
        );
        this.setState({ agenda: { ...this.state.agenda, itemList } });
    };

    private readonly onTagChange = (tags: string[]) => {
        this.setState({
            agenda: { ...this.state.agenda, tags },
        });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.agenda, this.props.agenda);
    };

    private shouldPreventRouteChange = (): boolean => {
        if (this.props.pageType !== PageType.view && !this.state.isPromptDisabled) {
            return this.isChanged();
        }
        return false;
    };

    public renderContent(): React.ReactElement<any> {
        const { agenda, errors, isValidationEnabled } = this.state;
        const disabled: boolean = this.props.pageType === PageType.view;
        const isEditEnabled: boolean = this.props.pageType === PageType.edit || ContentPageUtils.isEditEnabled(this.props.agenda, this.props.myAccount, this.props.client);
        const isAssignedToDay: boolean = (this.props.client && this.props.client.defaultAgenda && this.props.client.defaultAgenda.id === agenda.id) || !!agenda.day;
        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.agenda.data.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agenda.data.name.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            value={agenda.title}
                            placeholder={Intl.formatMessage({ id: "page.agenda.data.name.placeholder" })}
                            onChange={this.onTitleChange}
                            disabled={disabled}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.agenda.data.description.label" })} errorMessage={isValidationEnabled ? errors.description : null}>
                        <TextArea
                            innerRef={(ref: HTMLTextAreaElement | null): void => {
                                this.descriptionRef = ref;
                            }}
                            value={agenda.description || ""}
                            placeholder={Intl.formatMessage({ id: "page.agenda.data.description.placeholder" })}
                            onChange={this.onDescriptionChange}
                            disabled={disabled}
                        />
                    </InputWrapper>
                </Section>

                <Section label={Intl.formatMessage({ id: "page.agenda.items.title" })}>
                    <AgendaItemInput
                        droppableId="agendaItems"
                        value={sortBy(this.state.agenda.itemList || [], "position").map(
                            (item: AgendaItemAgendaRel): AgendaItemContent => {
                                return item.agendaItem;
                            },
                        )}
                        onChange={this.onAgendaItemsChange}
                        disabled={disabled}
                        onViewClick={(agendaItem: AgendaItemContent) => {
                            const path: string | undefined = ContentPageUtils.getViewPath(
                                agendaItem,
                                this.props.pageContent,
                                this.props.clientExtId,
                                this.props.supporterExtId,
                                this.props.agenda.id,
                                this.props.supervisorId,
                            );
                            if (path) {
                                this.props.history.push(path);
                            }
                        }}
                        onEditClick={
                            isEditEnabled
                                ? (agendaItem: AgendaItemContent) => {
                                      const path: string | undefined = ContentPageUtils.getEditPath(agendaItem, this.props.pageContent, this.props.clientExtId, this.props.agenda.id);
                                      if (path) {
                                          this.props.history.push(path);
                                      }
                                  }
                                : undefined
                        }
                    />
                </Section>

                <Section label={Intl.formatMessage({ id: "page.asset.tagSection.title" })}>
                    <TagSelect tags={this.props.tags} selectedTags={agenda.tags} onChange={this.onTagChange} disabled={disabled} renderTags={true} />
                </Section>

                {this.props.pageType === PageType.view && isAssignedToDay && this.state.agenda.client && (
                    <Section label={Intl.formatMessage({ id: "component.contentDeviceList.title" })}>
                        <ContentDeviceList content={this.props.agenda} />
                    </Section>
                )}

                {(this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest) && (
                    <LibraryContentShareRequestSection shareRequest={this.props.shareRequest} content={this.props.agenda} />
                )}

                <Prompt when={this.shouldPreventRouteChange()} />
            </>
        );
    }

    public render(): React.ReactElement<any> {
        const hideSidebar: boolean = this.props.pageType === PageType.view;
        const titleName: string = ContentPageUtils.getContentPageTitleName(this.props);
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={(titleName ? titleName + " - " : "") + Intl.formatMessage({ id: `page.agenda.title.${this.props.pageType}` })}
                renderButtons={this.renderPageButtons}
                hasSideBar={!hideSidebar}
            >
                <DragContainer dragIds={[]} hideSidebar={hideSidebar} searchListTypes={[SearchListType.agendaItem]} createdById={this.props.myAccount.id} client={this.props.client}>
                    {(this.props.isLoading || this.state.isLoading) && <Loading type={LoadingType.layer} />}
                    <div className="left-side">{!this.props.isLoading && this.renderContent()}</div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags };
};

export const AgendaForm = withRouter(connect(mapStateToProps)(AgendaFormComponent));

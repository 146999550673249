import React, { Component } from "react";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AccountType, Faq, FaqItemOrderInput } from "api/graphql/types";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { Column, Table } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { remove } from "lodash";

import "./FaqPage.scss";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { SortableTable } from "components/Table/SortableTable";
import { Button } from "components/Button/Button";
import { ImageSrc } from "utils/ImageSrc";
import { Path } from "utils/Path";
import { TestId } from "utils/TestId";
import { TestClass } from "utils/TestClass";

enum FaqTableColumn {
    question = "question",
    createdBy = "createdBy",
    updatedAt = "updatedAt",
    actions = "actions",
    position = "position",
}

interface State {
    faqs: Faq[];
    openedFaqs: string[];
    isLoading: boolean;
}

interface ReduxProps {
    accountType: AccountType;
}

interface ComponentProps {}

type Props = ReduxProps & ComponentProps & DispatchProp & RouteComponentProps;

class FaqListComponent extends Component<Props, State> {
    public readonly state: State = {
        faqs: [],
        openedFaqs: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        this.refreshFaqs();
    }

    private refreshFaqs = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const data: Faq[] = await Api.getFaqs();
                    this.setState({
                        faqs: data,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ faqs: [], isLoading: false });
                }
            },
        );
    };

    private readonly isAdmin = (): boolean => {
        return [AccountType.admin, AccountType.superadmin].includes(this.props.accountType);
    };

    private filterColumnNames = (columnNames: FaqTableColumn[]): FaqTableColumn[] => {
        let returnedColumnNames: FaqTableColumn[] = [...columnNames];

        if (!this.isAdmin()) {
            returnedColumnNames = returnedColumnNames.filter((columnName: FaqTableColumn) => [FaqTableColumn.question, FaqTableColumn.actions].includes(columnName));
        }

        return returnedColumnNames;
    };

    private isOpened = (faqId: string) => {
        return this.state.openedFaqs.includes(faqId);
    };

    private onFaqEdit = (faqId: string): void => {
        this.props.history.push(Path.editFaq(faqId));
    };

    private renderActionsCell = (faqId: string): React.ReactElement => {
        if (this.isAdmin()) {
            return (
                <div className="action-buttons">
                    <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={() => this.onFaqEdit(faqId)} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                    <Button
                        className={TestClass.faqPage.faqDeleteButton}
                        link
                        icon={{ name: "fa-trash", large: true }}
                        onClick={() => this.onFaqDelete(faqId)}
                        ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                    />
                </div>
            );
        } else {
            return <span className={`toggler ${this.isOpened(faqId) ? "opened" : ""}`} />;
        }
    };

    private renderPositionCell = (position: number | null): React.ReactElement => {
        if (!this.isAdmin()) {
            return <></>;
        }
        return (
            <>
                <div className="position-number">{position}</div>
                <div className="position-icon">
                    <img src={ImageSrc.moveIcon} />
                </div>
            </>
        );
    };

    private readonly getColumns = (): Array<Column<Faq>> => {
        let columnNames: FaqTableColumn[] = ObjectUtils.enumAsArray<FaqTableColumn>(FaqTableColumn);
        columnNames = this.filterColumnNames(columnNames);

        return columnNames.map(
            (columnName: FaqTableColumn): Column<Faq> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.faq.list.columns.${columnName}` }),
                accessor: columnName as keyof Faq,
                renderCell: (faq: Faq): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case FaqTableColumn.question:
                            return <span className="question-line">{faq.question}</span>;
                        case FaqTableColumn.createdBy:
                            return <>{faq.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</>;
                        case FaqTableColumn.updatedAt:
                            return <>{DateUtils.format(new Date(faq.updatedAt), DateFormat.dateTime)}</>;
                        case FaqTableColumn.actions:
                            return <span>{this.renderActionsCell(faq.id)}</span>;
                        case FaqTableColumn.position:
                            return <span>{this.renderPositionCell(faq.position)}</span>;
                        default:
                            return <></>;
                    }
                },
            }),
        );
    };

    private toggleRow = (faq: Faq): void => {
        const openedFaqs: string[] = [...this.state.openedFaqs];
        if (openedFaqs.includes(faq.id)) {
            remove(openedFaqs, (faqId: string) => faqId === faq.id);
        } else {
            openedFaqs.push(faq.id);
        }
        this.setState({ openedFaqs });
    };

    private onFaqDelete = (faqId: string): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyDeleteFaq,
                faqId,
                onDeleted: this.refreshFaqs,
            }),
        );
    };

    private getFaqOrder = (faqs: Faq[]): FaqItemOrderInput[] => {
        return faqs.map((faq: Faq, index: number) => {
            return {
                faqItemId: faq.id,
                position: index + 1,
            };
        });
    };

    private onOrderChange = (faqs: Faq[]) => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const orderInput: FaqItemOrderInput[] = this.getFaqOrder(faqs);
                    await Api.orderFaqs(orderInput);
                    this.refreshFaqs();
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private renderSupporterContent = (): React.ReactElement => {
        return (
            <div className="supporter-content">
                <p>{Intl.formatMessage({ id: "page.faq.list.lead" })}</p>
                <Table
                    keyExtractor={(faq: Faq, column?: Column<Faq>): string => {
                        return `${faq.id}_${column ? column.id : ""}`;
                    }}
                    columns={this.getColumns()}
                    data={this.state.faqs}
                    count={this.state.faqs.length}
                    isLoading={this.state.isLoading}
                    currentPage={1}
                    onRowClick={this.toggleRow}
                    hideHeader={!this.isAdmin()}
                    renderUnderRow={(faq: Faq): React.ReactElement<any> | null => {
                        return this.isOpened(faq.id) ? <div className="answer-cell">{faq.answer}</div> : null;
                    }}
                    rowClassName={(faq: Faq) => (this.isOpened(faq.id) ? "opened-line" : "")}
                />
            </div>
        );
    };

    private renderAdminContent = (): React.ReactElement => {
        return (
            <div className="admin-content">
                <SortableTable
                    data={this.state.faqs}
                    onOrderChange={this.onOrderChange}
                    isLoading={this.state.isLoading}
                    columns={this.getColumns()}
                    areArrowsHidden={true}
                    count={this.state.faqs.length}
                />
            </div>
        );
    };

    private renderCreateButton = (): React.ReactElement => {
        return (
            <span className="cell medium-6 text-right faq-create-button">
                <Button
                    id={TestId.faqPage.createFaqButton}
                    icon={{ name: "fa fa-plus", position: "left" }}
                    label={Intl.formatMessage({ id: "page.faq.list.createButtonLabel" })}
                    onClick={() => this.props.history.push(Path.createFaq())}
                />
            </span>
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Page id={TestId.faqPage.faqListContainer} title={Intl.formatMessage({ id: "page.faq.list.title" })} renderButtons={this.isAdmin() ? this.renderCreateButton : undefined}>
                <div className="left-side faq">{!this.isAdmin() ? this.renderSupporterContent() : this.renderAdminContent()}</div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountType: state.account!.accountType };
};

export const FaqList = withRouter(connect(mapStateToProps)(FaqListComponent));

// tslint:disable-next-line: no-default-export
export default FaqList;

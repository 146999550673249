import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { Button } from "components/Button/Button";
import { Device } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { DeviceDetect } from "utils/DeviceDetect";

interface Props {
    lastSyncDataGeneratedAt: Date | null;
    data: Device[];
    onRemove: (device: Device) => Promise<void> | void;
}

interface State {
    selectedDeviceForDelete: Device | null;
}

enum DeviceListColumn {
    id = "id",
    deviceType = "deviceType",
    appVersion = "appVersion",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    remove = "remove",
}

enum ClientDeviceSyncStatus {
    pending = "pending",
    updated = "updated",
}

class DeviceList extends Component<Props, State> {
    public readonly state: State = {
        selectedDeviceForDelete: null,
    };

    private getClientDeviceSyncStatus(device: Device): ClientDeviceSyncStatus {
        if (this.props.lastSyncDataGeneratedAt) {
            if (!device.agendaDataLastDownloadedAt || !device.clientDataLastDownloadedAt) {
                return ClientDeviceSyncStatus.pending;
            }

            const lastSyncDataGeneratedAt: number = this.props.lastSyncDataGeneratedAt.getTime();
            const agendaDataLastDownloadedAt: number = new Date(device.agendaDataLastDownloadedAt).getTime();
            const clientDataLastDownloadedAt: number = new Date(device.clientDataLastDownloadedAt).getTime();
            if (lastSyncDataGeneratedAt <= agendaDataLastDownloadedAt && lastSyncDataGeneratedAt <= clientDataLastDownloadedAt) {
                return ClientDeviceSyncStatus.updated;
            }
        }
        return ClientDeviceSyncStatus.pending;
    }

    private getColumns(): Array<Column<Device>> {
        return ObjectUtils.enumAsArray<DeviceListColumn>(DeviceListColumn).map(
            (columnName: DeviceListColumn): Column<Device> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.clientProfile.deviceList.table.columns.${columnName}` }),
                accessor: columnName as keyof Device,
                renderCell: (device: Device): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case DeviceListColumn.updatedAt:
                            return <>{Intl.formatMessage({ id: `enum.clientDeviceSyncStatus.${this.getClientDeviceSyncStatus(device)}` })}</>;
                        case DeviceListColumn.createdAt:
                            const day: Date | null = new Date(device[columnName]);
                            return <>{day ? DateUtils.format(day, DateFormat.yyyymmddhhmm) : ""}</>;
                        case DeviceListColumn.deviceType:
                            const deviceInfo = DeviceDetect.getDeviceInfo(device);
                            return <>{deviceInfo}</>;
                        case DeviceListColumn.appVersion:
                            return <>{DeviceDetect.getAppVersion(device.info.userAgent)}</>;
                        case DeviceListColumn.remove:
                            return <Button link icon={{ name: "fa-trash", large: true }} aria-label={Intl.formatMessage({ id: "common.remove" })} onClick={this.onDeleteDevice(device)} />;
                        default:
                            return null;
                    }
                },
            }),
        );
    }

    private onDeleteDevice = (device: Device) => (): void => {
        this.setState({ selectedDeviceForDelete: device });
    };

    private cancelSelectionForDelete = (): void => {
        this.setState({ selectedDeviceForDelete: null });
    };

    public render(): React.ReactElement<any> {
        if (this.props.data.length === 0) {
            return (
                <p>
                    <strong>{Intl.formatMessage({ id: "page.clientProfile.deviceList.noData" })}</strong>
                </p>
            );
        }
        return (
            <Table
                keyExtractor={(item: Device, column?: Column<Device>): string => item.id + (column ? column.id : "")}
                columns={this.getColumns()}
                data={this.props.data}
                isLoading={false}
                currentPage={1}
                count={this.props.data.length}
                isPaginationEnabled={false}
                renderEmpty={(): string => Intl.formatMessage({ id: "page.clientProfile.deviceList.noData" })}
                renderUnderRow={(device: Device): React.ReactElement<any> | null => {
                    if (this.state.selectedDeviceForDelete && this.state.selectedDeviceForDelete.id === device.id) {
                        return (
                            <div className="table-row no-hover action-button-container">
                                <div className="table-cell text-right">
                                    <Button
                                        hollow
                                        title={Intl.formatMessage({ id: "common.cancel" })}
                                        label={Intl.formatMessage({ id: "common.cancel" })}
                                        onClick={() => {
                                            this.cancelSelectionForDelete();
                                        }}
                                    />
                                    <Button
                                        label={Intl.formatMessage({ id: "common.remove" })}
                                        title={Intl.formatMessage({ id: "common.remove" })}
                                        onClick={async () => {
                                            try {
                                                await this.props.onRemove(device);
                                            } catch (error) {
                                                /** Error handled in ClientProfilePage */
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }
                    return null;
                }}
            />
        );
    }
}

export { DeviceList };

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { TimeSelectInput } from "components/Inputs/TimeSelectInput/TimeSelectInput";
import { Formatter } from "utils/Formatter";

interface Props {
    testIds: {
        enabled: string;
        hours: string;
        minutes: string;
    };
    value: string | null;
    onChange: (time: string | null) => void;
    disabled?: boolean;
    isValidationEnabled: boolean;
    errors?: TimeValidationError | null;
}

export interface TimeValidationError {
    enabled?: string | null;
}

class TimeInput extends Component<Props> {
    private checkboxRef: HTMLInputElement | null = null;

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.time.${id}` });
    };

    private onEnabledChange = (): void => {
        if (this.props.value) {
            this.props.onChange(null);
        } else {
            this.props.onChange(Formatter.formatTime({ hour: 0, minute: 0 }));
        }
    };

    public focus(): void {
        if (this.checkboxRef) {
            this.checkboxRef.focus();
        }
    }

    public render(): React.ReactElement<any> {
        return (
            <>
                <InputWrapper id={this.props.testIds.enabled} inputLabel={this.localTranslation("enabled.label")} padded icon="fa-clock">
                    <Checkbox
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.checkboxRef = ref;
                        }}
                        checked={!!this.props.value}
                        onChange={this.onEnabledChange}
                        disabled={this.props.disabled}
                        errorMessage={this.props.isValidationEnabled && this.props.errors && this.props.errors.enabled ? this.props.errors.enabled : undefined}
                    />
                </InputWrapper>

                {!!this.props.value && (
                    <InputWrapper inputLabel={this.localTranslation("hoursAndMinutes.label")} padded>
                        <TimeSelectInput value={this.props.value} onChange={(time: string | null) => this.props.onChange(time)} disabled={this.props.disabled} />
                    </InputWrapper>
                )}
            </>
        );
    }
}

export { TimeInput };

import React, { Component } from "react";
import { Section } from "components/Section";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { Input } from "components/Inputs/Input/Input";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator, ValidatorConstants } from "utils/Validator";
import { DateInput } from "components/Inputs/Date/DateInput";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { Color } from "theme/Color";
import { TestId } from "utils/TestId";
import { Prompt } from "components/Prompt";
import { DateUtils } from "utils/DateUtils";
import { Env } from "utils/Env";

interface Props {
    name: string;
    dateOfBirth: Date | null;
    introduction: string;
    statementOfSupport: boolean;
    isSelfSupporting: boolean;
    avatar: File | null;
    onNextClick: (name: string, dateOfBirth: Date | null, isSelfSupporting: boolean, statementOfSupport: boolean) => void;
}

interface State {
    name: string;
    dateOfBirth: Date | null;
    statementOfSupport: boolean;
    isSelfSupporting: boolean;
    formErrors: {
        name: string | null;
        dateOfBirth: string | null;
    };
    validationEnabled: boolean;
}

class AddClientPersonalDataForm extends Component<Props, State> {
    private nameRef: HTMLInputElement | null = null;
    private dateOfBirthRef: DateInput | null = null;

    public readonly state: State = {
        name: this.props.name,
        dateOfBirth: this.props.dateOfBirth,
        statementOfSupport: this.props.statementOfSupport,
        isSelfSupporting: this.props.isSelfSupporting,
        formErrors: {
            name: null,
            dateOfBirth: null,
        },
        validationEnabled: false,
    };

    private onNextClick = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { name, isSelfSupporting, dateOfBirth } = this.state;
        const formErrors = {
            name: IntlHelpers.getValidationError(Validator.validateName(name)),
            dateOfBirth: IntlHelpers.getValidationError(Validator.validateClientDateOfBirth(dateOfBirth)),
        };

        if (formErrors.name || formErrors.dateOfBirth) {
            this.setState({ validationEnabled: true, formErrors }, () => {
                if (!!formErrors.name && this.nameRef) {
                    this.nameRef.focus();
                } else if (!!formErrors.dateOfBirth && this.dateOfBirthRef) {
                    this.dateOfBirthRef.focus();
                }
            });
            return;
        }
        this.props.onNextClick(name, dateOfBirth, isSelfSupporting, true);
    };

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name: string = event.target.value;
        const nameError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateName(name)) : null;
        this.setState({ name, formErrors: { ...this.state.formErrors, name: nameError } });
    };

    private onDateOfBirthChange = (dateOfBirth: Date | null): void => {
        const dateOfBirthError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateClientDateOfBirth(dateOfBirth)) : null;
        const isSelfSupporting: boolean = DateUtils.getAge(dateOfBirth || new Date()) >= Env.minSelfSupportingAge ? this.state.isSelfSupporting : false;
        this.setState({ dateOfBirth, isSelfSupporting, formErrors: { ...this.state.formErrors, dateOfBirth: dateOfBirthError } });
    };

    private onStatementOfSupportChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ statementOfSupport: event.target.checked });
    };

    private onSelfSupportingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({ isSelfSupporting: event.target.checked });
    };

    public render(): React.ReactElement<any> {
        const { name, dateOfBirth, formErrors, isSelfSupporting, statementOfSupport } = this.state;
        return (
            <>
                <div id={TestId.addClient.personalData.container} className="left-side">
                    <form noValidate={true} onSubmit={this.onNextClick}>
                        <Section label={Intl.formatMessage({ id: "page.addClient.personalData.form.personalData.title" })}>
                            <InputWrapper
                                id={TestId.addClient.personalData.nameInput}
                                inputLabel={Intl.formatMessage({ id: "page.addClient.personalData.form.personalData.name.label" })}
                                errorMessage={formErrors.name}
                            >
                                <Input
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.nameRef = ref;
                                    }}
                                    hasError={!!formErrors.name}
                                    placeholder={Intl.formatMessage({ id: "page.addClient.personalData.form.personalData.name.placeholder" })}
                                    type="name"
                                    value={name}
                                    onChange={this.onNameChange}
                                />
                            </InputWrapper>

                            <InputWrapper
                                id={TestId.addClient.personalData.dateOfBirthInput}
                                inputLabel={Intl.formatMessage({ id: "page.addClient.personalData.form.personalData.dateOfBirth.label" })}
                                errorMessage={formErrors.dateOfBirth}
                            >
                                <DateInput
                                    ref={(ref: DateInput | null) => {
                                        this.dateOfBirthRef = ref;
                                    }}
                                    placeholder={Intl.formatMessage({ id: "page.addClient.personalData.form.personalData.dateOfBirth.placeholder" })}
                                    value={dateOfBirth}
                                    onChange={this.onDateOfBirthChange}
                                    min={ValidatorConstants.getClientDateOfBirthMinDate()}
                                    max={ValidatorConstants.getClientDateOfBirthMaxDate()}
                                />
                            </InputWrapper>
                        </Section>

                        {DateUtils.getAge(dateOfBirth || new Date()) >= Env.minSelfSupportingAge && (
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.addClient.personalData.form.selfSupporting.label" })}>
                                <Checkbox onChange={this.onSelfSupportingChange} checked={isSelfSupporting} label={""} />
                            </InputWrapper>
                        )}

                        <div className="restOfTheFormWarning">
                            <p>{Intl.formatMessage({ id: "page.addClient.personalData.form.restOfTheFormWarning" })}</p>
                        </div>

                        <hr />
                        <InputWrapper alignCenter={true}>
                            <Checkbox
                                id={TestId.addClient.personalData.statementOfSupport}
                                onChange={this.onStatementOfSupportChange}
                                checked={statementOfSupport}
                                label={IntlHelpers.asHtml(
                                    { id: "page.addClient.personalData.form.statementOfSupport" },
                                    { url: "/docs/tamogatoi_nyilatkozat.pdf", style: `color: ${Color.primaryL}; border-bottom: ${Color.primaryL} 2px solid; font-weight: 800;` },
                                )}
                            />
                        </InputWrapper>
                        <hr />
                        <div className="text-right">
                            <Button type="submit" label={Intl.formatMessage({ id: "common.next" })} disabled={!statementOfSupport} />
                        </div>
                    </form>
                </div>
                <Prompt when={!!name || !!dateOfBirth || !!statementOfSupport} />
            </>
        );
    }
}

export { AddClientPersonalDataForm };

import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { SortOrder, ListControl, ClientListOptionsInput, ClientListItem, ClientListSortField } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AppPath, Path } from "utils/Path";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { ObjectUtils } from "utils/ObjectUtils";
import { SearchForm } from "components/SearchForm/SearchForm";
import { Section } from "components/Section";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { Formatter } from "utils/Formatter";
import { DateUtils, DateFormat } from "utils/DateUtils";

interface ClientsListOptions {
    sortField?: ClientListSortField | null;
    control: ListControl;
}

interface State {
    clients: ClientListItem[];
    count: number;
    isLoading: boolean;
    options: ClientsListOptions;
}

enum ClientListTableColumn {
    extId = "extId",
    dateOfBirth = "dateOfBirth",
    createdAt = "createdAt",
}

type ComponentProps = RouteComponentProps;

type Props = ComponentProps;

class ClientListPageComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): ClientsListOptions {
        const { sortField, sortOrder, search, limit, page } = new ListUrlQueryParser<ClientListSortField>(ClientListSortField, "page.clientList").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public readonly state: State = {
        clients: [],
        isLoading: true,
        count: 0,
        options: ClientListPageComponent.getInitialOptions(this.props),
    };

    public componentDidMount(): void {
        this.refreshClients(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.location.search, nextProps.location.search)) {
            this.setState({ options: ClientListPageComponent.getInitialOptions(nextProps) }, () => this.refreshClients(this.state.options));
        }
    }

    private refreshClients = (options?: ClientListOptionsInput): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await Api.getClientList(options);
                    this.setState({ clients: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(AppPath.dashboard);
                        },
                    });
                }
            },
        );
    };

    private readonly columns: Array<Column<ClientListItem>> = ObjectUtils.enumAsArray<ClientListTableColumn>(ClientListTableColumn).map(
        (columnName: ClientListTableColumn): Column<ClientListItem> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.clientList.table.columns.${columnName}` }),
            accessor: columnName as keyof ClientListItem,
            renderCell: (clientListItem: ClientListItem): React.ReactElement<any> | null => {
                switch (columnName) {
                    case ClientListTableColumn.extId:
                        return <>{Formatter.formatExtId(clientListItem.extId)}</>;
                    case ClientListTableColumn.dateOfBirth:
                        return <>{DateUtils.format(clientListItem.dateOfBirth, DateFormat.yyyymmdd)}</>;
                    case ClientListTableColumn.createdAt:
                        return <>{DateUtils.format(new Date(clientListItem.createdAt), DateFormat.yyyymmddhhmm)}</>;
                    default:
                        return null;
                }
            },
        }),
    );

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<ClientListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<ClientListSortField>(ClientListSortField, "page.clientList").getUrlQuery(options);
        this.props.history.push({ search: `?${params}` });
        this.refreshClients(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): ClientListSortField | undefined => {
        switch (columnId) {
            case ClientListTableColumn.extId:
                return ClientListSortField.extId;
            case ClientListTableColumn.dateOfBirth:
                return ClientListSortField.dateOfBirth;
            case ClientListTableColumn.createdAt:
                return ClientListSortField.createdAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: ClientListSortField | null): keyof ClientListItem | undefined => {
        switch (columnId) {
            case ClientListSortField.extId:
                return ClientListTableColumn.extId;
            case ClientListSortField.createdAt:
                return ClientListTableColumn.createdAt;
            case ClientListSortField.dateOfBirth:
                return ClientListTableColumn.dateOfBirth;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<ClientListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private onSearch = (search: string): void => {
        this.setState(
            {
                options: { ...this.state.options, control: { ...this.state.options.control, search, offset: 0 } },
            },
            this.updateQueryParams,
        );
    };

    public render(): React.ReactElement<any> | null {
        return (
            <Page title={Intl.formatMessage({ id: "page.clientList.title" })}>
                <div className="left-side">
                    <Section
                        label={
                            <label className="grid-x">
                                <span className="cell medium-6">
                                    <SearchForm
                                        defaultValue={this.state.options.control.search || undefined}
                                        icon
                                        searchPlaceholder={Intl.formatMessage({ id: "page.clientList.searchPlaceholder" })}
                                        onSearchClick={this.onSearch}
                                    />
                                </span>
                            </label>
                        }
                    >
                        <Table
                            keyExtractor={(item: ClientListItem, column?: Column<ClientListItem>): string => {
                                return `${item.id}_${column ? column.id : ""}`;
                            }}
                            columns={this.columns}
                            sortBy={{
                                columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                                order: this.state.options.control.sortOrder || undefined,
                            }}
                            data={this.state.clients}
                            count={this.state.count}
                            limit={this.state.options.control.limit}
                            isSortable={true}
                            onSortOrderChange={this.onSortOrderChange}
                            onPageChange={this.onPageChange}
                            isPaginationEnabled={true}
                            currentPage={this.getCurrentPage()}
                            isLoading={this.state.isLoading}
                            renderEmpty={(): string => Intl.formatMessage({ id: "page.clientList.noData" })}
                            onRowClick={(clientListItem: ClientListItem): void => {
                                if (clientListItem.extId) {
                                    this.props.history.push(Path.clientDetail(clientListItem.extId));
                                }
                            }}
                        />
                    </Section>
                </div>
            </Page>
        );
    }
}

export const ClientListPage = withRouter(ClientListPageComponent);

// tslint:disable-next-line: no-default-export
export default ClientListPage;

import React, { Component } from "react";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Dialog } from "../Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "../Button/Button";
import { Api } from "api/Api";
import { Alert } from "../Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";

export interface DeleteTagDialogProps {
    onDeleted: () => void;
    tag: { id: string; title: string };
}

type Props = DeleteTagDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
}

class DeleteTagDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }
    private readonly deleteTag = () => {
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.deleteTag(this.props.tag.id);
                this.props.onDeleted();
                this.props.onHide();
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "sharedComponent.deleteTagDialog.title" })} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "sharedComponent.deleteTagDialog.description" }, { title: this.props.tag.title })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button className="btn-danger btn--text-large fw-700" label={Intl.formatMessage({ id: "common.delete" })} onClick={this.deleteTag} disabled={this.state.isLoading} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { DeleteTagDialog };

import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { EducationContentModuleListItem } from "api/graphql/types";
import { withRouter, RouteComponentProps } from "react-router";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Column, Table } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { Path } from "utils/Path";
import "./EducationModuleListPage.scss";

enum EducationModuleTableColumn {
    title = "title",
    submoduleCount = "submoduleCount",
    createdBy = "createdBy",
    updatedAt = "updatedAt",
    actions = "actions",
}

type Props = RouteComponentProps;

interface State {
    educationModules: EducationContentModuleListItem[];
    isLoading: boolean;
}

class EducationModuleListPageComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            educationModules: [],
            isLoading: true,
        };
    }

    componentDidMount(): void {
        this.refreshModules();
    }

    private readonly refreshModules = (): void => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const educationModules = await Api.getEducationModules();
                    this.setState({
                        educationModules,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private readonly getColumns = (): Array<Column<EducationContentModuleListItem>> => {
        const columnNames: EducationModuleTableColumn[] = ObjectUtils.enumAsArray<EducationModuleTableColumn>(EducationModuleTableColumn);

        return columnNames.map(
            (columnName: EducationModuleTableColumn): Column<EducationContentModuleListItem> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `page.educationModules.table.columns.${columnName}` }),
                accessor: columnName as keyof EducationContentModuleListItem,
                renderCell: (educationModule: EducationContentModuleListItem): React.ReactElement | null => {
                    switch (columnName) {
                        case EducationModuleTableColumn.updatedAt:
                            return (
                                <>
                                    {educationModule.updatedAt
                                        ? DateUtils.format(new Date(educationModule.updatedAt), DateFormat.yyyymmddhhmm)
                                        : DateUtils.format(new Date(educationModule.createdAt), DateFormat.yyyymmddhhmm)}
                                </>
                            );
                        case EducationModuleTableColumn.actions:
                            return <i className="fa fa-chevron-right" />;
                        case EducationModuleTableColumn.createdBy:
                            return (
                                <>
                                    <span>{educationModule.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</span>
                                    <br />
                                    <span>
                                        (
                                        {educationModule.createdBy
                                            ? Intl.formatMessage({ id: `enum.accountType.${educationModule.createdBy.accountType}` })
                                            : Intl.formatMessage({ id: "common.unknown" })}
                                        )
                                    </span>
                                </>
                            );
                        case EducationModuleTableColumn.title:
                            return <>{`${educationModule.position}. ${educationModule.title}`}</>;
                        case EducationModuleTableColumn.submoduleCount:
                            return <>{educationModule.subModules?.length || "-"}</>;
                        default:
                            return null;
                    }
                },
                isNonSortable: true,
            }),
        );
    };

    public render() {
        return (
            <Page title={Intl.formatMessage({ id: "page.educationModules.title" })}>
                <div className="left-side education-module-list-page">
                    <p>{Intl.formatMessage({ id: "page.educationModules.description" })}</p>
                    <Table
                        keyExtractor={(item: EducationContentModuleListItem, column?: Column<EducationContentModuleListItem>): string => {
                            return `${item.id}_${column ? column.id : ""}`;
                        }}
                        columns={this.getColumns()}
                        data={this.state.educationModules}
                        count={this.state.educationModules.length}
                        isLoading={this.state.isLoading}
                        onRowClick={(educationModule: EducationContentModuleListItem) => this.props.history.push(Path.educationSubModuleList(educationModule.id))}
                    />
                </div>
            </Page>
        );
    }
}

export const EducationModuleListPage = withRouter(EducationModuleListPageComponent);

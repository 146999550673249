import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { DialogVisibilityProps } from "./DialogsContainer";
import { SupportedClient } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { Cookie } from "utils/Cookie";
import uniq from "lodash/uniq";

export interface ClientTodoDialogProps {
    clientsWithTodo: SupportedClient[];
}

type Props = ClientTodoDialogProps & DialogVisibilityProps;

class ClientTodoDialog extends PureComponent<Props> {
    private readonly onClose = (): void => {
        const acceptClientTodos = Cookie.getAcceptedClientTodos();
        const extIds = uniq([...acceptClientTodos, ...this.props.clientsWithTodo.map(client => client.extId)]);
        Cookie.setAcceptedClientTodos(extIds);
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.clientTodoDialog.title" })} visible={this.props.isVisible} onCloseClick={this.onClose}>
                <p>{Intl.formatMessage({ id: "sharedComponent.clientTodoDialog.description" })}</p>
                <hr />
                <ul className="client-list">
                    {this.props.clientsWithTodo.map(client => (
                        <li key={client.id}>{client.name}</li>
                    ))}
                </ul>
                <hr />
                <div className="row">
                    <button className="btn btn-primary button-center" onClick={this.onClose}>
                        {Intl.formatMessage({ id: "common.ok" })}
                    </button>
                </div>
            </Dialog>
        );
    }
}

export { ClientTodoDialog };

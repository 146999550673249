import { DeleteProfileDialogProps } from "components/DialogContainer/DeleteProfileDialog";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { uuid4 } from "@sentry/utils";
import { AssignToDayDialogProps } from "components/DialogContainer/AssignToDayDialog";
import { UploadAssetDialogProps } from "components/DialogContainer/UploadAssetDialog";
import { AssignToClientDialogProps } from "components/DialogContainer/AssignToClientDialog";
import { SetDefaultAgendaDialogProps } from "components/DialogContainer/SetDefaultAgendaDialog";
import { DeleteAgendaDialogProps } from "components/DialogContainer/DeleteAgendaDialog";
import { DeleteAgendaItemDialogProps } from "components/DialogContainer/DeleteAgendaItemDialog";
import { ShareContentDialogProps } from "components/DialogContainer/ShareContentDialog";
import { DeleteFlowchartDialogProps } from "components/DialogContainer/DeleteFlowchartDialog";
import { DeleteFlowchartItemDialogProps } from "components/DialogContainer/DeleteFlowchartItemDialog";
import { DeleteAssetDialogProps } from "components/DialogContainer/DeleteAssetDialog";
import { DeleteSelectionBoardDialogProps } from "components/DialogContainer/DeleteSelectionBoardDialog";
import { ChangeClientManagerDialogProps } from "components/DialogContainer/ChangeClientManagerDialog";
import { ContentTogglerDialogProps } from "components/DialogContainer/ContentTogglerDialog";
import { LogoutDialogProps } from "components/DialogContainer/LogoutDialog";
import { ConfirmLeaveDialogProps } from "components/DialogContainer/ConfirmLeaveDialog";
import { DeleteAccountConfirmDialogProps } from "components/DialogContainer/DeleteAccountConfirmDialog";
import { ConfirmAvatarDeleteDialogProps } from "components/DialogContainer/ConfirmAvatarDeleteDialog";
import { UnsetDefaultAgendaDialogProps } from "components/DialogContainer/UnsetDefaultAgendaDialog";
import { SyncDialogProps } from "components/DialogContainer/SyncDialog";
import { CreateAdminAccountDialogProps } from "components/DialogContainer/CreateAdminAccountDialog";
import { ConfirmAgendaDialogProps } from "components/DialogContainer/ConfirmAgendaDialog";
import { AddSupporterDialogProps } from "components/DialogContainer/AddSupporterDialog";
import { DeleteClientProfileDialogProps } from "components/DialogContainer/DeleteClientProfileDialog";
import { CreateSupervisorAccountDialogProps } from "components/DialogContainer/CreateSupervisorAccountDialog";
import { CreateSupporterAccountDialogProps } from "components/DialogContainer/CreateSupporterAccountDialog";
import { DeleteChangeClientManagerDialogProps } from "components/DialogContainer/DeleteChangeClientManagerDialog";
import { VerifyChangeClientManagerDialogProps } from "components/DialogContainer/VerifyChangeClientManagerDialog";
import { DeletePinDialogProps } from "components/DialogContainer/DeletePinDialog";
import { ConfirmUploadAssetDeleteDialogProps } from "components/DialogContainer/ConfirmUploadAssetDeleteDialog";
import { ShowAssetDialogProps } from "components/DialogContainer/ShowAssetDialog";
import { ClientTodoDialogProps } from "components/DialogContainer/ClientTodoDialog";
import { SelfSupportingDialogProps } from "components/DialogContainer/SelfSupportingDialog";
import { DeleteEverydaySituationDirectoryDialog, DeleteEverydaySituationDirectoryDialogProps } from "components/DialogContainer/DeleteEverydaySituationDirectoryDialog";
import { VerifyDeleteFaqDialogProps } from "components/DialogContainer/VerifyDeleteFaqDialog";
import { CreateAssetDirectoryDialogProps } from "components/DialogContainer/CreateAssetDirectoryDialog";
import { MoveAssetsDialogProps } from "components/DialogContainer/MoveAssetsDialog";
import { VerifyDeleteSelectionBoardDialogProps } from "components/DialogContainer/VerifyDeleteSelectionBoardDialog";
import { MoveEverydaySituationDialogProps } from "components/DialogContainer/MoveEverydaySituationDialog";
import { InfoDialogProps } from "components/DialogContainer/InfoDialog";
import { VerifyDeleteClientInstantAwardDialogProps } from "components/DialogContainer/VerifyDeleteClientInstantAwardDialog";
import { VerifyDeleteClientAwardDialogProps } from "components/DialogContainer/VerifyDeleteClientAwardDialog";
import { DeleteTagDialogProps } from "components/DialogContainer/DeleteTagDialog";
import { VerifyDeleteSubModuleDialogProps } from "components/DialogContainer/VerifyDeleteSubModuleDialog";
import { DeleteEverydaySituationDialogProps } from "components/DialogContainer/DeleteEverydaySituationDialog";
import { RejectContentShareRequestDialogProps } from "components/DialogContainer/RejectContentShareRequestDialog";
import { DisableRelatingAssetsDialogProps } from "components/DialogContainer/DisableRelatingAssetsDialog";
import { CopyEverydaySituationDialogProps } from "components/DialogContainer/CopyEverydaySituationDialog";
import { SyncWarningDialogProps } from "components/DialogContainer/SyncWarningDialog";
import { ClientQuestionnaireInfoDialogProps } from "components/DialogContainer/ClientQuestionnaireInfoDialog";
import { EditAssetDirectoryDialogProps } from "components/DialogContainer/EditAssetDirectoryDialog";

export enum DialogsActionType {
    show = "@dialogs/show",
    hide = "@dialogs/hide",
}

interface AbstractDialog {
    type: DialogType;
}

export interface DeleteProfileDialog extends AbstractDialog, DeleteProfileDialogProps {
    type: DialogType.deleteProfile;
}

export interface AssignToDayDialog extends AbstractDialog, AssignToDayDialogProps {
    type: DialogType.assignToDay;
}

export interface UploadAssetDialog extends AbstractDialog, CreateAssetDirectoryDialogProps {
    type: DialogType.createAssetDirectory;
}

export interface CreateAssetDirectoryDialog extends AbstractDialog, UploadAssetDialogProps {
    type: DialogType.uploadAsset;
}

export interface ShareContentDialog extends AbstractDialog, ShareContentDialogProps {
    type: DialogType.shareContent;
}

export interface AssignToClientDialog extends AbstractDialog, AssignToClientDialogProps {
    type: DialogType.assignToClient;
}

export interface SetDefaultAgendaDialog extends AbstractDialog, SetDefaultAgendaDialogProps {
    type: DialogType.setDefaultAgenda;
}

export interface DeleteAgendaDialog extends AbstractDialog, DeleteAgendaDialogProps {
    type: DialogType.deleteAgenda;
}

export interface DeleteAgendaItemDialog extends AbstractDialog, DeleteAgendaItemDialogProps {
    type: DialogType.deleteAgendaItem;
}

export interface DeleteFlowchartDialog extends AbstractDialog, DeleteFlowchartDialogProps {
    type: DialogType.deleteFlowchart;
}

export interface DeleteFlowchartItemDialog extends AbstractDialog, DeleteFlowchartItemDialogProps {
    type: DialogType.deleteFlowchartItem;
}

export interface MoveAssetsDialog extends AbstractDialog, MoveAssetsDialogProps {
    type: DialogType.moveAsset;
}

export interface DeleteAssetDialog extends AbstractDialog, DeleteAssetDialogProps {
    type: DialogType.deleteAsset;
}

export interface DeleteSelectionBoardDialog extends AbstractDialog, DeleteSelectionBoardDialogProps {
    type: DialogType.deleteSelectionBoard;
}

export interface ChangeClientManagerDialog extends AbstractDialog, ChangeClientManagerDialogProps {
    type: DialogType.changeClientManager;
}

export interface ContentTogglerDialog extends AbstractDialog, ContentTogglerDialogProps {
    type: DialogType.contentToggler;
}

export interface LogoutDialog extends AbstractDialog, LogoutDialogProps {
    type: DialogType.logout;
}

export interface ConfirmLeaveDialog extends AbstractDialog, ConfirmLeaveDialogProps {
    type: DialogType.confirmLeave;
}

export interface DeleteAccountConfirmDialog extends AbstractDialog, DeleteAccountConfirmDialogProps {
    type: DialogType.deleteAccountConfirm;
}

export interface ConfirmAvatarDeleteDialog extends AbstractDialog, ConfirmAvatarDeleteDialogProps {
    type: DialogType.confirmAvatarDelete;
}

export interface UnsetDefaultAgendaDialog extends AbstractDialog, UnsetDefaultAgendaDialogProps {
    type: DialogType.unsetDefaultAgenda;
}

export interface SyncDialog extends AbstractDialog, SyncDialogProps {
    type: DialogType.sync;
}

export interface SyncWarningDialog extends AbstractDialog, SyncWarningDialogProps {
    type: DialogType.syncWarning;
}

export interface CreateAdminAccountDialog extends AbstractDialog, CreateAdminAccountDialogProps {
    type: DialogType.createAdminAccount;
}

export interface ConfirmAgendaDialog extends AbstractDialog, ConfirmAgendaDialogProps {
    type: DialogType.confirmAgenda;
}

export interface AddSupporterDialog extends AbstractDialog, AddSupporterDialogProps {
    type: DialogType.addSupporter;
}

export interface DeleteClientProfileDialog extends AbstractDialog, DeleteClientProfileDialogProps {
    type: DialogType.deleteClientProfile;
}

export interface CreateSupervisorAccountDialog extends AbstractDialog, CreateSupervisorAccountDialogProps {
    type: DialogType.createSupervisorAccount;
}

export interface CreateSupporterAccountDialog extends AbstractDialog, CreateSupporterAccountDialogProps {
    type: DialogType.createSupporterAccount;
}

export interface DeleteChangeClientManagerDialog extends AbstractDialog, DeleteChangeClientManagerDialogProps {
    type: DialogType.deleteChangeClientManager;
}

export interface VerifyChangeClientManagerDialog extends AbstractDialog, VerifyChangeClientManagerDialogProps {
    type: DialogType.verifyChangeClientManager;
}

export interface DeletePinDialog extends AbstractDialog, DeletePinDialogProps {
    type: DialogType.deletePin;
}

export interface ClientTodoDialog extends AbstractDialog, ClientTodoDialogProps {
    type: DialogType.clientTodo;
}

export interface ConfirmUploadAssetDeleteDialog extends AbstractDialog, ConfirmUploadAssetDeleteDialogProps {
    type: DialogType.confirmUploadAssetDelete;
}

export interface ShowAssetDialog extends AbstractDialog, ShowAssetDialogProps {
    type: DialogType.showAsset;
}

export interface SelfSupportingDialog extends AbstractDialog, SelfSupportingDialogProps {
    type: DialogType.selfSupporting;
}

export interface DeleteEverydaySituationDirectoryDialog extends AbstractDialog, DeleteEverydaySituationDirectoryDialogProps {
    type: DialogType.deleteEverydaySituationDirectory;
}

export interface VerifyDeleteFaqDialog extends AbstractDialog, VerifyDeleteFaqDialogProps {
    type: DialogType.verifyDeleteFaq;
}

export interface VerifyDeleteSelectionBoardDialog extends AbstractDialog, VerifyDeleteSelectionBoardDialogProps {
    type: DialogType.verifyDeleteSelectionBoard;
}

export interface MoveEverydaySituationDialog extends AbstractDialog, MoveEverydaySituationDialogProps {
    type: DialogType.moveEverydaySituation;
}

export interface CopyEverydaySituationDialog extends AbstractDialog, CopyEverydaySituationDialogProps {
    type: DialogType.copyEverydaySituation;
}

export interface InfoDialog extends AbstractDialog, InfoDialogProps {
    type: DialogType.info;
}

export interface DeleteEverydaySituationDialog extends AbstractDialog, DeleteEverydaySituationDialogProps {
    type: DialogType.deleteEverydaySituation;
}

export interface DeleteEverydaySituationDirectoryDialog extends AbstractDialog, DeleteEverydaySituationDirectoryDialogProps {
    type: DialogType.deleteEverydaySituationDirectory;
}

export interface VerifyDeleteClientInstantAwardDialog extends AbstractDialog, VerifyDeleteClientInstantAwardDialogProps {
    type: DialogType.verifyDeleteClientInstantAward;
}

export interface VerifyDeleteClientAwardDialog extends AbstractDialog, VerifyDeleteClientAwardDialogProps {
    type: DialogType.verifyDeleteClientAward;
}

export interface DeleteTagDialog extends AbstractDialog, DeleteTagDialogProps {
    type: DialogType.deleteTag;
}

export interface VerifyDeleteSubModuleDialog extends AbstractDialog, VerifyDeleteSubModuleDialogProps {
    type: DialogType.verifyDeleteSubModule;
}

export interface RejectContentShareRequestDialog extends AbstractDialog, RejectContentShareRequestDialogProps {
    type: DialogType.rejectContentShareRequest;
}

export interface DisableRelatingAssetsDialog extends AbstractDialog, DisableRelatingAssetsDialogProps {
    type: DialogType.disableRelatingAssets;
}

export interface ClientQuestionnaireInfoDialog extends AbstractDialog, ClientQuestionnaireInfoDialogProps {
    type: DialogType.clientQuestionnaireInfo;
}

export interface EditAssetDirectoryDialog extends AbstractDialog, EditAssetDirectoryDialogProps {
    type: DialogType.editAssetDirectory;
}

export type Dialog =
    | DeleteProfileDialog
    | AssignToDayDialog
    | UploadAssetDialog
    | CreateAssetDirectoryDialog
    | ShareContentDialog
    | AssignToClientDialog
    | SetDefaultAgendaDialog
    | DeleteAgendaDialog
    | DeleteAgendaItemDialog
    | DeleteFlowchartDialog
    | DeleteFlowchartItemDialog
    | MoveAssetsDialog
    | DeleteAssetDialog
    | DeleteSelectionBoardDialog
    | ChangeClientManagerDialog
    | ContentTogglerDialog
    | LogoutDialog
    | ConfirmLeaveDialog
    | DeleteAccountConfirmDialog
    | ConfirmAvatarDeleteDialog
    | UnsetDefaultAgendaDialog
    | SyncDialog
    | SyncWarningDialog
    | CreateAdminAccountDialog
    | ConfirmAgendaDialog
    | AddSupporterDialog
    | DeleteClientProfileDialog
    | CreateSupervisorAccountDialog
    | CreateSupporterAccountDialog
    | DeleteChangeClientManagerDialog
    | VerifyChangeClientManagerDialog
    | DeletePinDialog
    | ClientTodoDialog
    | ConfirmUploadAssetDeleteDialog
    | ShowAssetDialog
    | SelfSupportingDialog
    | VerifyDeleteSelectionBoardDialog
    | DeleteEverydaySituationDialog
    | DeleteEverydaySituationDirectoryDialog
    | VerifyDeleteFaqDialog
    | MoveEverydaySituationDialog
    | CopyEverydaySituationDialog
    | InfoDialog
    | VerifyDeleteClientInstantAwardDialog
    | VerifyDeleteClientAwardDialog
    | VerifyDeleteSubModuleDialog
    | DeleteTagDialog
    | RejectContentShareRequestDialog
    | DisableRelatingAssetsDialog
    | ClientQuestionnaireInfoDialog
    | EditAssetDirectoryDialog;

interface AddAction {
    type: DialogsActionType.show;
    dialog: Dialog;
    uuid: string;
}

interface RemoveAction {
    type: DialogsActionType.hide;
    uuid: string;
}

export class DialogsActions {
    public static show(dialog: Dialog): AddAction {
        return { type: DialogsActionType.show, dialog, uuid: uuid4() };
    }

    public static hide(uuid: string): RemoveAction {
        return { type: DialogsActionType.hide, uuid };
    }
}

export type DialogsAction = AddAction | RemoveAction;

import React, { Component } from "react";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { TestId } from "utils/TestId";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Button } from "components/Button/Button";
import { Api } from "api/Api";
import { ClientProfileData } from "api/graphql/types";
import { withNotification, NotificationProp } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Prompt } from "components/Prompt";

interface ComponentProps {
    clientId: string;
    name: string;
    onNameChange: (client: ClientProfileData) => void;
    disabled: boolean;
}

type Props = ComponentProps & NotificationProp;

interface State {
    isEditable: boolean;
    name: string;
    formErrors: {
        name: string | null;
    };
    validationEnabled: boolean;
    isLoading: boolean;
}

class ClientProfileNameFormComponent extends Component<Props, State> {
    private nameRef: HTMLInputElement | null = null;

    public readonly state: State = this.getInitialState();

    private getInitialState(): State {
        return {
            isEditable: false,
            name: this.props.name,
            formErrors: {
                name: null,
            },
            validationEnabled: false,
            isLoading: false,
        };
    }

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name: string = event.target.value;
        const nameError: string | null = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateName(name)) : null;
        this.setState({ name, formErrors: { ...this.state.formErrors, name: nameError } });
    };

    private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { name } = this.state;
        const formErrors = {
            name: IntlHelpers.getValidationError(Validator.validateName(name)),
        };

        if (formErrors.name) {
            this.setState({ isLoading: false, validationEnabled: true, formErrors }, () => {
                if (!!formErrors.name && this.nameRef) {
                    this.nameRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientProfileData: ClientProfileData = await Api.updateClient(this.props.clientId, { name });
                    this.props.onNameChange(clientProfileData);
                    this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.personalData.nameForm.nameChangeSucceed" }) });
                    this.resetForm();
                } catch (error) {
                    this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onEditClick = (): void => {
        this.setState({ isEditable: true });
    };

    private resetForm = (): void => {
        this.setState(this.getInitialState());
    };

    public render(): React.ReactElement<any> {
        const { isEditable, name, formErrors, isLoading } = this.state;
        return (
            <form id={TestId.clientProfile.nameForm} noValidate={true} onSubmit={this.onSubmit}>
                <InputWrapper id={TestId.clientProfile.nameInput} inputLabel={Intl.formatMessage({ id: "page.clientProfile.personalData.nameForm.name.label" })} errorMessage={formErrors.name}>
                    <Input
                        innerRef={(ref: HTMLInputElement | null): void => {
                            this.nameRef = ref;
                        }}
                        hasError={!!formErrors.name}
                        placeholder={Intl.formatMessage({ id: "page.clientProfile.personalData.nameForm.name.placeholder" })}
                        type="name"
                        value={name}
                        onChange={this.onNameChange}
                        disabled={!isEditable || this.props.disabled}
                    />
                </InputWrapper>

                {!this.props.disabled &&
                    (isEditable ? (
                        <div className="action-button-container">
                            <Button hollow ariaLabel={Intl.formatMessage({ id: "common.cancel" })} label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.resetForm} />
                            <Button
                                ariaLabel={Intl.formatMessage({ id: "common.save" })}
                                label={Intl.formatMessage({ id: "common.save" })}
                                type="submit"
                                disabled={isLoading || !!formErrors.name || name === this.props.name}
                            />
                        </div>
                    ) : (
                        <div className="edit-button">
                            <Button link icon={{ name: "fa-pencil-alt", large: true }} onClick={this.onEditClick} ariaLabel={Intl.formatMessage({ id: "common.edit" })} />
                        </div>
                    ))}

                {!this.props.disabled && <Prompt when={name !== this.props.name} />}
            </form>
        );
    }
}

const ClientProfileNameForm = withNotification(ClientProfileNameFormComponent);

export { ClientProfileNameForm };

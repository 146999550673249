import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { AppStatItem, AppStatItemListOptionsInput, SortOrder, AppStatItemListSortField, ListControl, AppStatItemType, Aggregations } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { AppStatItemResult } from "api/ApiTypes";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { EventLogUrlQueryParser, EventLogQueryParameter } from "../EventLogUrlQueryParser";
import { DateInput, PopperPlacement } from "components/Inputs/Date/DateInput";
import { Select } from "components/Inputs/Select/Select";
import { AppStatItemTypeOption } from "models/AppStatItemTypeOptions";
import { uuid4 } from "@sentry/utils";
import { Button } from "components/Button/Button";
import { Env } from "utils/Env";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Cell, Tooltip } from "recharts";
import { ChartRow, ChartUtils } from "utils/ChartUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { cloneDeep, isNil } from "lodash";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { SettingsSelectors } from "selectors/SettingsSelectors";
import { ValueRow } from "../ValueRow/ValueRow";
import { startOfDay } from "date-fns";
import { Formatter } from "utils/Formatter";
import { TextSelect } from "components/Inputs/TextSelect/TextSelect";
import { TimeSelect } from "components/Inputs/TimeSelect/TimeSelect";

import "./ClientEventLogTable.scss";

interface EventLogPageOptions {
    sortField?: AppStatItemListSortField | null;
    control: ListControl;
    dateTimeFrom: Date;
    dateTimeTo: Date;
    type: AppStatItemType | null;
}

enum ViewType {
    list = "list",
    chart = "chart",
}

interface State {
    clientEventLogs: AppStatItem[];
    aggregations: Aggregations | null;
    count: number;
    isLoading: boolean;
    options: EventLogPageOptions;
    viewType: ViewType;
    chartDateTimeFrom: Date; // To prevent buggy rerendering of charts
    chartDateTimeTo: Date; // To prevent buggy rerendering of charts
    appEventLogNameSearch: string;

    fromTime: string;
    toTime: string;
}

enum ClientEventLogTableColumn {
    clientExtId = "clientExtId",
    type = "type",
    title = "title",
    startedAt = "startedAt",
    finishedAt = "finishedAt",
    value = "value",
    createdAt = "createdAt",
}

interface ComponentProps extends RouteComponentProps {
    clientExtId: string;
    clientId: string;
    clientName: string;
    getClientEventLog: (dateTimeFrom: Date, dateTimeTo: Date, type?: AppStatItemType | null, options?: AppStatItemListOptionsInput) => Promise<AppStatItemResult>;
}

interface ReduxProps {
    appStatItemTypeOptions: AppStatItemTypeOption[];
}

type Props = ComponentProps & ReduxProps;

class ClientEventLogTableComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): EventLogPageOptions {
        const { sortField, sortOrder, search, limit, page, dateTimeFrom, dateTimeTo, appStatItemType: type } = new EventLogUrlQueryParser<AppStatItemListSortField>(
            AppStatItemListSortField,
            "sharedComponent.clientEventLogTable",
        ).parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
            dateTimeFrom: dateTimeFrom || startOfDay(new Date()),
            dateTimeTo: dateTimeTo || DateUtils.lastQuarterMinutes(new Date()),
            type: type || null,
        };
    }

    public constructor(props: Props) {
        super(props);

        const options = ClientEventLogTableComponent.getInitialOptions(this.props);
        this.state = {
            clientEventLogs: [],
            aggregations: null,
            isLoading: true,
            count: 0,
            options,
            viewType: ViewType.list,
            chartDateTimeFrom: options.dateTimeFrom,
            chartDateTimeTo: options.dateTimeTo,
            appEventLogNameSearch: Select.getSelectOption(this.props.appStatItemTypeOptions, options.type).label || "",

            fromTime: DateUtils.toTimeFormat(options.dateTimeFrom),
            toTime: DateUtils.toTimeFormat(options.dateTimeTo),
        };
    }

    public componentDidMount(): void {
        this.refreshEventLog(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.clientExtId, nextProps.clientExtId) || !isEqual(this.props.location.search, nextProps.location.search)) {
            this.setState({ options: ClientEventLogTableComponent.getInitialOptions(nextProps) }, () => {
                this.refreshEventLog(this.state.options);
            });
        }
    }

    private refreshEventLog = (options: EventLogPageOptions): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, aggregations, count } = await this.props.getClientEventLog(options.dateTimeFrom, options.dateTimeTo, options.type, {
                        control: options.control,
                        sortField: options.sortField,
                    });
                    this.setState({ clientEventLogs: result, aggregations, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ clientEventLogs: [], count: 0, isLoading: false });
                }
            },
        );
    };

    private readonly columns: Array<Column<AppStatItem>> = ObjectUtils.enumAsArray<ClientEventLogTableColumn>(ClientEventLogTableColumn).map(
        (columnName: ClientEventLogTableColumn): Column<AppStatItem> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `sharedComponent.clientEventLogTable.table.columns.${columnName}` }),
            accessor: columnName as keyof AppStatItem,
            renderCell: (eventLogRow: AppStatItem): React.ReactElement<any> | null => {
                switch (columnName) {
                    case ClientEventLogTableColumn.clientExtId:
                        return <>{eventLogRow.client?.extId ? Formatter.formatExtId(eventLogRow.client?.extId) : ""}</>;
                    case ClientEventLogTableColumn.type:
                        return <>{eventLogRow.typeTitle}</>;
                    case ClientEventLogTableColumn.title:
                        return <>{eventLogRow.data?.title || ""}</>;
                    case ClientEventLogTableColumn.startedAt:
                        return <>{!isNil(eventLogRow.data?.startedAt) && DateUtils.format(new Date(eventLogRow.data.startedAt), DateFormat.dateTime)}</>;
                    case ClientEventLogTableColumn.finishedAt:
                        return <>{!isNil(eventLogRow.data?.finishedAt) && DateUtils.format(new Date(eventLogRow.data.finishedAt), DateFormat.dateTime)}</>;
                    case ClientEventLogTableColumn.value:
                        return <ValueRow eventLogRow={eventLogRow} />;
                    case ClientEventLogTableColumn.createdAt:
                        return <>{DateUtils.format(new Date(eventLogRow.createdAt), DateFormat.dateTime)}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: !ObjectUtils.enumAsArray<AppStatItemListSortField>(AppStatItemListSortField)
                .map((field: AppStatItemListSortField) => {
                    return `${field}`;
                })
                .includes(columnName),
        }),
    );

    private updateQueryParams = (): void => {
        const { control, sortField, dateTimeFrom, dateTimeTo, type } = this.state.options;
        const options: EventLogQueryParameter<AppStatItemListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
            dateTimeFrom,
            dateTimeTo,
            appStatItemType: type,
        };

        const params = new EventLogUrlQueryParser<AppStatItemListSortField>(AppStatItemListSortField, "sharedComponent.clientEventLogTable").getUrlQuery(options);
        this.props.history.push({ search: `?${params}` });
        this.setState({ clientEventLogs: [] }, () => this.refreshEventLog(this.state.options));
    };

    private convertColumnIdToSortField = (columnId?: string): AppStatItemListSortField | undefined => {
        switch (columnId) {
            case ClientEventLogTableColumn.type:
                return AppStatItemListSortField.type;
            case ClientEventLogTableColumn.createdAt:
                return AppStatItemListSortField.createdAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AppStatItemListSortField | null): keyof AppStatItem | undefined => {
        switch (columnId) {
            case AppStatItemListSortField.type:
                return ClientEventLogTableColumn.type;
            case AppStatItemListSortField.createdAt:
                return ClientEventLogTableColumn.createdAt;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AppStatItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column?.id),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                    dateTimeFrom: this.state.options.dateTimeFrom,
                    dateTimeTo: this.state.options.dateTimeTo,
                    type: this.state.options.type,
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private getDownloadUrl(): string {
        const options = this.state.options;
        const dateTimeFrom: Date | null = options.dateTimeFrom || startOfDay(new Date());
        const dateTimeTo: Date | null = options.dateTimeTo || DateUtils.lastQuarterMinutes(new Date());
        const type: AppStatItemType | null = options.type || null;

        const paramArray: string[] = [`clientId=${this.props.clientId}`];

        if (dateTimeFrom) {
            paramArray.push(`dateFrom=${dateTimeFrom.toISOString()}`);
        }
        if (dateTimeTo) {
            paramArray.push(`dateTo=${dateTimeTo.toISOString()}`);
        }
        if (type) {
            paramArray.push(`type=${type}`);
        }

        return `${Env.exportApiUrl}/appStats/xlsx?${paramArray.join("&")}`;
    }

    private filterTypes = (events: AppStatItemTypeOption[]): AppStatItemTypeOption[] => {
        return events.filter((event: AppStatItemTypeOption) => event.label.toLowerCase().includes(this.state.appEventLogNameSearch.toLowerCase()));
    };

    private renderInputs = (): React.ReactElement => {
        return (
            <div className="inputs">
                <div className="small-text client-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.type.label" })}</div>
                <TextSelect
                    value={Select.getSelectOption(this.props.appStatItemTypeOptions, this.state.options.type)}
                    options={this.filterTypes(this.props.appStatItemTypeOptions)}
                    onChange={(option: AppStatItemTypeOption): void => {
                        this.setState({
                            options: {
                                ...this.state.options,
                                type: option.value,
                                control: {
                                    offset: 0,
                                    limit: Table.DEFAULT_PAGE_SIZE,
                                },
                            },
                            appEventLogNameSearch: option.label,
                        });
                    }}
                    onTextInputChange={(appEventLogNameSearch: string) => this.setState({ appEventLogNameSearch })}
                    textInputValue={this.state.appEventLogNameSearch}
                />
                <div className="client-event-log-table-time-inputs">
                    <div>
                        <div className="small-text client-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dateFrom.label" })}</div>
                        <InputWrapper>
                            <div className="client-event-log-table-date-input">
                                <DateInput
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dateFrom.placeholder" })}
                                    value={this.state.options.dateTimeFrom}
                                    onChange={(dateTimeFrom: Date | null) => {
                                        if (dateTimeFrom) {
                                            this.setState({
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom,
                                                    dateTimeTo: this.state.options.dateTimeTo < dateTimeFrom ? dateTimeFrom : this.state.options.dateTimeTo,
                                                    control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE },
                                                },
                                            });
                                        }
                                    }}
                                    max={new Date()}
                                    popperPlacement={PopperPlacement.bottom}
                                />
                            </div>
                        </InputWrapper>
                    </div>

                    <div>
                        <div className="small-text client-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.hourTo.label" })}</div>
                        <InputWrapper>
                            <div className="client-event-log-table-hour-input">
                                <TimeSelect
                                    value={this.state.fromTime}
                                    onChange={(time: string) => {
                                        const [hours, minutes] = time.split(":");
                                        const newDateTimeFrom: Date = cloneDeep(this.state.options.dateTimeFrom);
                                        newDateTimeFrom.setHours(Number.parseInt(hours, 10) || 0);
                                        newDateTimeFrom.setMinutes(Number.parseInt(minutes, 10) || 0);
                                        this.setState({
                                            fromTime: time,
                                            options: {
                                                ...this.state.options,
                                                dateTimeFrom: newDateTimeFrom,
                                                dateTimeTo: this.state.options.dateTimeTo < newDateTimeFrom ? newDateTimeFrom : this.state.options.dateTimeTo,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </InputWrapper>
                    </div>

                    <div className="client-event-log-table-dummy-text-container">
                        <p>{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dummyText.from" })}</p>
                        <p>{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dummyText.dash" })}</p>
                    </div>

                    <div>
                        <div className="small-text client-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dateTo.label" })}</div>
                        <InputWrapper>
                            <div className="client-event-log-table-date-input">
                                <DateInput
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dateTo.placeholder" })}
                                    value={this.state.options.dateTimeTo}
                                    onChange={(dateTimeTo: Date | null) => {
                                        if (dateTimeTo) {
                                            this.setState({
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom: this.state.options.dateTimeFrom > dateTimeTo ? dateTimeTo : this.state.options.dateTimeFrom,
                                                    dateTimeTo,
                                                    control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE },
                                                },
                                            });
                                        }
                                    }}
                                    max={new Date()}
                                    popperPlacement={PopperPlacement.bottom}
                                />
                            </div>
                        </InputWrapper>
                    </div>

                    <div>
                        <div className="small-text client-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.hourFrom.label" })}</div>
                        <InputWrapper>
                            <div className="client-event-log-table-hour-input">
                                <TimeSelect
                                    value={this.state.toTime}
                                    onChange={(time: string) => {
                                        const newDateTimeTo: Date = cloneDeep(this.state.options.dateTimeTo);
                                        const [hours, minutes] = time.split(":");
                                        newDateTimeTo.setHours(Number.parseInt(hours, 10) || 0);
                                        newDateTimeTo.setMinutes(Number.parseInt(minutes, 10) || 0);
                                        this.setState({
                                            toTime: time,
                                            options: {
                                                ...this.state.options,
                                                dateTimeFrom: this.state.options.dateTimeFrom > newDateTimeTo ? newDateTimeTo : this.state.options.dateTimeFrom,
                                                dateTimeTo: newDateTimeTo,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </InputWrapper>
                    </div>

                    <div className="client-event-log-table-dummy-text-container">
                        <p>{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.dummyText.to" })}</p>
                    </div>
                </div>
                <hr />
                <div className="client-event-log-table-inner-buttons">
                    <Button
                        label={Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.buttonLabels.deleteFilters" })}
                        hollow
                        onClick={() => {
                            this.props.history.push({ search: undefined });
                            this.setState({ options: ClientEventLogTableComponent.getInitialOptions(this.props) });
                        }}
                    />
                    <Button
                        label={Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.buttonLabels.applyFilters" })}
                        onClick={() => {
                            this.setState(
                                {
                                    viewType: ViewType.list,
                                    options: { ...this.state.options, control: { ...this.state.options.control, limit: 10, offset: 0 } },
                                    chartDateTimeFrom: this.state.options.dateTimeFrom,
                                    chartDateTimeTo: this.state.options.dateTimeTo,
                                },
                                this.updateQueryParams,
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    private onViewClick = (): void => {
        if (this.state.viewType === ViewType.list) {
            this.setState(
                {
                    options: {
                        ...this.state.options,
                        control: {
                            ...this.state.options.control,
                            offset: 0,
                            // TODO: chart miatt átgondolni
                            limit: 9999,
                        },
                    },
                    viewType: ViewType.chart,
                },
                this.updateQueryParams,
            );
        } else {
            this.setState(
                { options: { ...this.state.options, control: { ...this.state.options.control, limit: Table.DEFAULT_PAGE_SIZE, offset: 0 } }, viewType: ViewType.list },
                this.updateQueryParams,
            );
        }
    };

    private getTooltip = (tooltip: { active: boolean; payload: any; label: string }): React.ReactElement => {
        const value: number | null = tooltip.payload && tooltip.payload[0] && !isNil(tooltip.payload[0].payload?.count) ? tooltip.payload[0].payload?.count : null;
        return (
            <div className="recharts-tooltip">
                <span>{tooltip.label}</span>
                {!isNil(value) && (
                    <>
                        <br />
                        <span>
                            {value} {Intl.formatMessage({ id: "common.event" })}
                        </span>
                    </>
                )}
            </div>
        );
    };

    public render(): React.ReactElement {
        const chartData: ChartRow[] = !isNil(this.state.aggregations) ? ChartUtils.getChartData(this.state.chartDateTimeFrom, this.state.chartDateTimeTo, this.state.aggregations) : [];

        return (
            <div className="client-event-log-table">
                <Section
                    label={
                        <>
                            <i className="fa fa-filter" />
                            <span className="section-title">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.filtersSection" })}</span>
                        </>
                    }
                >
                    <p>{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.lead" }, { name: this.props.clientName })}</p>
                    <form>
                        {this.renderInputs()}
                        <div className="client-event-log-table-outer-buttons">
                            <Button
                                label={Intl.formatMessage({ id: "common.download" })}
                                onClick={() => {
                                    window.location.href = this.getDownloadUrl();
                                }}
                            />
                            <Button
                                label={Intl.formatMessage({ id: `sharedComponent.clientEventLogTable.buttonLabels.switch.${this.state.viewType === ViewType.list ? ViewType.chart : ViewType.list}` })}
                                hollow
                                onClick={this.onViewClick}
                            />
                        </div>

                        {this.state.viewType === ViewType.list ? (
                            <div className="table-responsive table--display-table">
                                <Table
                                    // TODO: get id from clientEventLog when API is ready
                                    keyExtractor={(_item: AppStatItem): string => {
                                        return uuid4();
                                    }}
                                    columns={this.columns}
                                    sortBy={{
                                        columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                                        order: this.state.options.control.sortOrder || undefined,
                                    }}
                                    data={this.state.clientEventLogs}
                                    count={this.state.count}
                                    limit={this.state.options.control.limit}
                                    isSortable={true}
                                    onSortOrderChange={this.onSortOrderChange}
                                    onPageChange={this.onPageChange}
                                    isPaginationEnabled={true}
                                    currentPage={this.getCurrentPage()}
                                    isLoading={this.state.isLoading}
                                    renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.noData" })}
                                />
                            </div>
                        ) : this.state.isLoading ? (
                            <Loading type={LoadingType.layer} />
                        ) : (
                            <div className="chart-container">
                                <p className="chart-title">{ChartUtils.getChartTitle(this.state.chartDateTimeFrom, this.state.chartDateTimeTo)}</p>
                                <span className="small-text chart-x-axis-label">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.xAxisLabel" })}</span>
                                <div className="chart-inner-container">
                                    <BarChart width={1200} height={600} data={chartData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="time" angle={ChartUtils.getXAxisAngle} textAnchor="end" height={120} />
                                        <YAxis />
                                        <Tooltip content={this.getTooltip} />
                                        <Bar dataKey="count" fill="#8884d8">
                                            {chartData.map((log: ChartRow, index: number) => {
                                                const color = ChartUtils.getColorByNumbers(
                                                    log.count,
                                                    chartData.map((fillLog: ChartRow) => fillLog.count),
                                                );
                                                return <Cell key={log.time + log.count + index + color} fill={color} />;
                                            })}
                                        </Bar>
                                    </BarChart>
                                    <div className="chart-y-axis-label-container">
                                        <span className="small-text">{Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.yAxisLabel" })}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </Section>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { appStatItemTypeOptions: SettingsSelectors.getAppStatItemTypeOption(state) };
};

export const ClientEventLogTable = withRouter(connect(mapStateToProps)(ClientEventLogTableComponent));

import { SelectOption } from "components/Inputs/Select/Select";
import { Intl } from "i18n/Intl";
import { AppEventLogName, AppEventLogCategoryNameTitle, AppEventLogNameTitle, AccountType, AppEventLogCategoryName } from "api/graphql/types";
import { ApplicationState } from "reducers";
import { cloneDeep, orderBy, flattenDeep } from "lodash";

type AppEventLogNameOption = SelectOption<AppEventLogName | null>;

class AppEventLogNameSelectOptions {
    public static getFilteredChildren(categoryKey: AppEventLogCategoryName, events: AppEventLogNameTitle[], accountType: AccountType): AppEventLogNameTitle[] {
        return (
            // Alphabetical ordering
            events
                .sort((a: AppEventLogNameTitle, b: AppEventLogNameTitle) => {
                    return a.title.localeCompare(b.title);
                })
                // Filtering the events that are accessibly by the current user
                .filter((event: AppEventLogNameTitle) => {
                    return AppEventLogNameSelectOptions.canAccountSeeEvent(accountType, event.key);
                })
                // Filtering the children events of the category
                .filter((event: AppEventLogNameTitle) => {
                    return event.category === categoryKey;
                })
        );
    }

    public static get(state: ApplicationState): AppEventLogNameOption[] {
        const appEventLogCategories: AppEventLogCategoryNameTitle[] = cloneDeep(state.settings.appEventLogCategoryNameTitles);
        const appEventLogNameTitles: AppEventLogNameTitle[] = cloneDeep(state.settings.appEventLogNameTitles);

        return [
            // "Every event" option
            { id: "null", label: Intl.formatMessage({ id: "enum.appEventLogName.null" }), value: null },
            ...flattenDeep(
                orderBy(appEventLogCategories, "position", "asc").map((category: AppEventLogCategoryNameTitle): AppEventLogNameOption[] => {
                    const filteredChildren: AppEventLogNameTitle[] = AppEventLogNameSelectOptions.getFilteredChildren(category.key, appEventLogNameTitles, state.account!.accountType);
                    if (filteredChildren.length > 0) {
                        return [
                            { id: `app-event-log-category-${category.key}`, label: `${category.title}`, value: null, disabled: true },
                            ...filteredChildren.map(
                                (eventLogName: AppEventLogNameTitle): AppEventLogNameOption => {
                                    return { id: `app-event-log-name-${eventLogName.key}`, label: eventLogName.title, value: eventLogName.key };
                                },
                            ),
                        ];
                    }
                    return [];
                }),
            ),
        ];
    }

    public static canAccountSeeEvent(accountType: AccountType, event: AppEventLogName): boolean {
        switch (accountType) {
            case AccountType.supporter:
                return [
                    AppEventLogName.createClient,
                    AppEventLogName.updateClient,
                    AppEventLogName.updateClientConfig,
                    AppEventLogName.updateClientSpareAgendaItems,
                    AppEventLogName.setDefaultAgenda,
                    AppEventLogName.unsetDefaultAgenda,
                    AppEventLogName.shareClient,
                    AppEventLogName.deleteClient,
                    AppEventLogName.acceptClientShareRequest,
                    AppEventLogName.rejectClientShareRequest,
                    AppEventLogName.deleteClientShareRequest,
                    AppEventLogName.unlinkSupporter,
                    AppEventLogName.createClientChangeManagerRequest,
                    AppEventLogName.deleteClientChangeManagerRequest,
                    AppEventLogName.acceptClientChangeManagerRequest,
                    AppEventLogName.rejectClientChangeManagerRequest,
                    AppEventLogName.clientQuestionnaireEvaluation,
                    AppEventLogName.resendActivationCode,
                    AppEventLogName.changeEmailRequest,
                    AppEventLogName.changeEmailByCode,
                    AppEventLogName.login,
                    AppEventLogName.resetPassword,
                    AppEventLogName.logout,
                    AppEventLogName.changePassword,
                    AppEventLogName.forgotPassword,
                    AppEventLogName.uploadClientAvatar,
                    AppEventLogName.deleteClientAvatar,
                    AppEventLogName.uploadAsset,
                    AppEventLogName.updateAsset,
                    AppEventLogName.deleteAsset,
                    AppEventLogName.moveAssetsToPersonalAssetDirectory,
                    AppEventLogName.createPersonalAssetDirectory,
                    AppEventLogName.updatePersonalAssetDirectory,
                    AppEventLogName.deletePersonalAssetDirectory,
                    AppEventLogName.uploadClientIntroductionAudio,
                    AppEventLogName.deleteClientIntroductionAudio,
                    AppEventLogName.uploadClientIntroductionVideo,
                    AppEventLogName.deleteClientIntroductionVideo,
                    AppEventLogName.copyAssetToAccountPersonalLibrary,
                    AppEventLogName.replaceAsset,
                    AppEventLogName.createAgenda,
                    AppEventLogName.createAgendaItem,
                    AppEventLogName.updateAgenda,
                    AppEventLogName.updateAgendaItem,
                    AppEventLogName.deleteAgenda,
                    AppEventLogName.deleteAgendaItem,
                    AppEventLogName.createAgendaSyncData,
                    AppEventLogName.copyAgendaToClientAgendaLibrary,
                    AppEventLogName.copyAgendaToClientPersonalLibrary,
                    AppEventLogName.copyAgendaToAccountPersonalLibrary,
                    AppEventLogName.copyAgendaItemToClientPersonalLibrary,
                    AppEventLogName.copyAgendaItemToAccountPersonalLibrary,
                    AppEventLogName.createFlowchart,
                    AppEventLogName.updateFlowchart,
                    AppEventLogName.deleteFlowchart,
                    AppEventLogName.copyFlowchartToClientLibrary,
                    AppEventLogName.createFlowchartItem,
                    AppEventLogName.updateFlowchartItem,
                    AppEventLogName.deleteFlowchartItem,
                    AppEventLogName.copyFlowchartItemToClientLibrary,
                    AppEventLogName.copyFlowchartToAccountPersonalLibrary,
                    AppEventLogName.copyFlowchartItemToAccountPersonalLibrary,
                    AppEventLogName.createAward,
                    AppEventLogName.updateAward,
                    AppEventLogName.deleteAward,
                    AppEventLogName.activateAward,
                    AppEventLogName.createInstantAward,
                    AppEventLogName.updateInstantAward,
                    AppEventLogName.deleteInstantAward,
                    AppEventLogName.shareLibraryContent,
                    AppEventLogName.shareLibraryContentToPublic,
                    AppEventLogName.acceptLibraryContentPrivateShareRequest,
                    AppEventLogName.acceptLibraryContentPublicShareRequest,
                    AppEventLogName.rejectLibraryContentPrivateShareRequest,
                    AppEventLogName.rejectLibraryContentPublicShareRequest,
                    AppEventLogName.revokeOAuthToken,
                ].includes(event);
            case AccountType.supervisor:
                return [
                    AppEventLogName.createClient,
                    AppEventLogName.updateClient,
                    AppEventLogName.updateClientConfig,
                    AppEventLogName.updateClientMemoryGameConfig,
                    AppEventLogName.updateClientSortingGameConfig,
                    AppEventLogName.updateClientSpareAgendaItems,
                    AppEventLogName.setDefaultAgenda,
                    AppEventLogName.unsetDefaultAgenda,
                    AppEventLogName.shareClient,
                    AppEventLogName.deleteClient,
                    AppEventLogName.acceptClientShareRequest,
                    AppEventLogName.rejectClientShareRequest,
                    AppEventLogName.deleteClientShareRequest,
                    AppEventLogName.unlinkSupporter,
                    AppEventLogName.createClientChangeManagerRequest,
                    AppEventLogName.deleteClientChangeManagerRequest,
                    AppEventLogName.acceptClientChangeManagerRequest,
                    AppEventLogName.rejectClientChangeManagerRequest,
                    AppEventLogName.clientQuestionnaireEvaluation,
                    AppEventLogName.setSelfSupportingStateDecision,
                    AppEventLogName.clientProfileChange,
                    AppEventLogName.createAccount,
                    AppEventLogName.disableAccount,
                    AppEventLogName.enableAccount,
                    AppEventLogName.resendActivationCode,
                    AppEventLogName.changeEmailRequest,
                    AppEventLogName.changeEmailByCode,
                    AppEventLogName.deleteAccount,
                    AppEventLogName.updateAccount,
                    AppEventLogName.login,
                    AppEventLogName.activateAccount,
                    AppEventLogName.resetPassword,
                    AppEventLogName.logout,
                    AppEventLogName.changePassword,
                    AppEventLogName.forgotPassword,
                    AppEventLogName.uploadAccountAvatar,
                    AppEventLogName.deleteAccountAvatar,
                    AppEventLogName.uploadClientAvatar,
                    AppEventLogName.deleteClientAvatar,
                    AppEventLogName.uploadAsset,
                    AppEventLogName.updateAsset,
                    AppEventLogName.deleteAsset,
                    AppEventLogName.moveAssetsToPersonalAssetDirectory,
                    AppEventLogName.createPersonalAssetDirectory,
                    AppEventLogName.updatePersonalAssetDirectory,
                    AppEventLogName.deletePersonalAssetDirectory,
                    AppEventLogName.moveAssetsToPublicAssetDirectory,
                    AppEventLogName.createPublicAssetDirectory,
                    AppEventLogName.updatePublicAssetDirectory,
                    AppEventLogName.deletePublicAssetDirectory,
                    AppEventLogName.uploadClientIntroductionAudio,
                    AppEventLogName.deleteClientIntroductionAudio,
                    AppEventLogName.uploadClientIntroductionVideo,
                    AppEventLogName.deleteClientIntroductionVideo,
                    AppEventLogName.copyAssetToPublicLibrary,
                    AppEventLogName.copyAssetToAccountPersonalLibrary,
                    AppEventLogName.disableAsset,
                    AppEventLogName.enableAsset,
                    AppEventLogName.replaceAsset,
                    AppEventLogName.createAgenda,
                    AppEventLogName.createAgendaItem,
                    AppEventLogName.updateAgenda,
                    AppEventLogName.updateAgendaItem,
                    AppEventLogName.disableAgenda,
                    AppEventLogName.enableAgenda,
                    AppEventLogName.deleteAgenda,
                    AppEventLogName.disableAgendaItem,
                    AppEventLogName.enableAgendaItem,
                    AppEventLogName.deleteAgendaItem,
                    AppEventLogName.createAgendaSyncData,
                    AppEventLogName.copyAgendaToClientAgendaLibrary,
                    AppEventLogName.copyAgendaToClientPersonalLibrary,
                    AppEventLogName.copyAgendaToPublicLibrary,
                    AppEventLogName.copyAgendaToAccountPersonalLibrary,
                    AppEventLogName.copyAgendaItemToClientPersonalLibrary,
                    AppEventLogName.copyAgendaItemToPublicLibrary,
                    AppEventLogName.copyAgendaItemToAccountPersonalLibrary,
                    AppEventLogName.createFlowchart,
                    AppEventLogName.updateFlowchart,
                    AppEventLogName.disableFlowchart,
                    AppEventLogName.enableFlowchart,
                    AppEventLogName.deleteFlowchart,
                    AppEventLogName.copyFlowchartToClientLibrary,
                    AppEventLogName.copyFlowchartToPublicLibrary,
                    AppEventLogName.createFlowchartItem,
                    AppEventLogName.updateFlowchartItem,
                    AppEventLogName.disableFlowchartItem,
                    AppEventLogName.enableFlowchartItem,
                    AppEventLogName.deleteFlowchartItem,
                    AppEventLogName.copyFlowchartItemToClientLibrary,
                    AppEventLogName.copyFlowchartItemToPublicLibrary,
                    AppEventLogName.copyFlowchartToAccountPersonalLibrary,
                    AppEventLogName.copyFlowchartItemToAccountPersonalLibrary,
                    AppEventLogName.createSelectionBoard,
                    AppEventLogName.updateSelectionBoard,
                    AppEventLogName.deleteSelectionBoard,
                    AppEventLogName.orderSelectionBoards,
                    AppEventLogName.disableSelectionBoard,
                    AppEventLogName.enableSelectionBoard,
                    AppEventLogName.createAward,
                    AppEventLogName.updateAward,
                    AppEventLogName.deleteAward,
                    AppEventLogName.activateAward,
                    AppEventLogName.disableAward,
                    AppEventLogName.enableAward,
                    AppEventLogName.createInstantAward,
                    AppEventLogName.updateInstantAward,
                    AppEventLogName.deleteInstantAward,
                    AppEventLogName.disableInstantAward,
                    AppEventLogName.enableInstantAward,
                    AppEventLogName.sendEmail,
                    AppEventLogName.createEverydaySituationDirectory,
                    AppEventLogName.updateEverydaySituationDirectory,
                    AppEventLogName.deleteEverydaySituationDirectory,
                    AppEventLogName.disableEverydaySituationDirectory,
                    AppEventLogName.enableEverydaySituationDirectory,
                    AppEventLogName.createEverydaySituation,
                    AppEventLogName.createEverydaySituationByClient,
                    AppEventLogName.updateEverydaySituation,
                    AppEventLogName.deleteEverydaySituation,
                    AppEventLogName.disableEverydaySituation,
                    AppEventLogName.enableEverydaySituation,
                    AppEventLogName.orderEverydaySituationDirectories,
                    AppEventLogName.createFaqItem,
                    AppEventLogName.updateFaqItem,
                    AppEventLogName.deleteFaqItem,
                    AppEventLogName.orderFaqItems,
                    AppEventLogName.createEducationContent,
                    AppEventLogName.updateEducationContent,
                    AppEventLogName.deleteEducationContent,
                    AppEventLogName.createEducationContentModule,
                    AppEventLogName.updateEducationContentModule,
                    AppEventLogName.deleteEducationContentModule,
                    AppEventLogName.orderEducationContentModules,
                    AppEventLogName.orderEducationContents,
                    AppEventLogName.shareLibraryContent,
                    AppEventLogName.shareLibraryContentToPublic,
                    AppEventLogName.acceptLibraryContentPrivateShareRequest,
                    AppEventLogName.acceptLibraryContentPublicShareRequest,
                    AppEventLogName.rejectLibraryContentPrivateShareRequest,
                    AppEventLogName.rejectLibraryContentPublicShareRequest,
                    AppEventLogName.revokeOAuthToken,
                ].includes(event);
            case AccountType.admin:
            case AccountType.superadmin:
                return true;
            default:
                return false;
        }
    }
}

export { AppEventLogNameSelectOptions };
export type { AppEventLogNameOption };

import React, { PureComponent } from "react";
import { Image } from "./Image";
import { ImageSrc } from "utils/ImageSrc";

interface Props {
    name?: string;
    url?: string;
    className?: string;
    hasBadge?: boolean;
}

class Avatar extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <>
                <span className={`profile${this.props.className ? ` ${this.props.className}` : ""}`}>
                    <Image src={this.props.url} fallback={ImageSrc.avatar} title={this.props.name} />
                </span>
                {this.props.hasBadge ? <span className="label label-notification" /> : null}
            </>
        );
    }
}

export { Avatar };

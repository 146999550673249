import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { DialogVisibilityProps } from "./DialogsContainer";
import { AssetType } from "api/graphql/types";
import { Log } from "utils/Log";
import { isNil } from "lodash";
import { ImageSrc } from "utils/ImageSrc";
import { DownloadLink } from "components/DownloadLink/DownloadLink";
import { Player } from "components/Player/Player";
import { VideoPlayer } from "components/VideoPlayer/VideoPlayer";

export interface ShowAssetDialogProps {
    assetUrl?: string | null;
    originalFileName?: string;
    thumbnailUrl?: string | null;
    assetType: AssetType | AssetType[];
    dialogTitle?: string;
}

type Props = ShowAssetDialogProps & DialogVisibilityProps;

class ShowAssetDialog extends PureComponent<Props> {
    private renderContent = (): React.ReactElement => {
        if (isNil(this.props.assetUrl)) {
            Log.warning("Missing assetUrl!");
            return <></>;
        }

        let assetType: AssetType | AssetType[] = this.props.assetType;

        if (Array.isArray(this.props.assetType)) {
            assetType = this.props.assetType[0];
        }

        switch (assetType) {
            case AssetType.introduction_video:
            case AssetType.video:
                return <VideoPlayer config={{ file: { attributes: { controlsList: "nodownload", disablePictureInPicture: true, poster: this.props.thumbnailUrl } } }} url={this.props.assetUrl} />;
            case AssetType.introduction_audio:
            case AssetType.audio:
                return (
                    <>
                        <div className="audio-wrapper">
                            <div>
                                <img src={ImageSrc.audioAsset} height="50px" />
                            </div>
                            <div>
                                <Player
                                    className="react-player"
                                    playing={false}
                                    controls={true}
                                    url={this.props.assetUrl}
                                    width="600px"
                                    height="50px"
                                    config={{ file: { forceAudio: true, attributes: { controlsList: "nodownload", poster: null } } }}
                                />
                            </div>
                        </div>
                    </>
                );
            case AssetType.image:
            case AssetType.avatar:
                return (
                    <div className="show-asset-dialog-image-container">
                        <img className="show-asset-dialog-image" src={this.props.assetUrl} alt={this.props.assetUrl} />
                    </div>
                );
            default:
                Log.warning("Incorrect AssetType in ShowAssetDialog");
                return <></>;
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={this.props.dialogTitle || ""} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                {this.renderContent()}
                <div className="download-button">
                    {!isNil(this.props.assetUrl) && !isNil(this.props.originalFileName) && <DownloadLink url={this.props.assetUrl} originalFileName={this.props.originalFileName} />}
                </div>
            </Dialog>
        );
    }
}

export { ShowAssetDialog };

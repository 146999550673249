import * as React from "react";

function SvgSupporterCollaborator(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm3.598-16.968c0 1.989-1.611 3.6-3.6 3.6a3.599 3.599 0 01-3.6-3.6 3.6 3.6 0 013.6-3.6c1.989 0 3.6 1.61 3.6 3.6zm-10.8 9c0-2.394 4.797-3.6 7.2-3.6 2.403 0 7.2 1.206 7.2 3.6v1.8h-14.4v-1.8z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgSupporterCollaborator;

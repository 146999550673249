import React, { Component } from "react";
import { Asset } from "api/graphql/types";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AvatarForm } from "components/AvatarForm";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";

interface ComponentProps {
    clientId: string;
    avatar: Asset | null;
    onAvatarChange: (asset: Asset | null) => void;
}

type Props = ComponentProps & NotificationProp;

class ClientProfileAvatarFormComponent extends Component<Props> {
    private uploadAvatar = async (file: File, onProgressChange: (progress: number) => void): Promise<void> => {
        try {
            const avatar: Asset | null = await Api.uploadClientAvatar(this.props.clientId, file, onProgressChange);
            if (avatar) {
                avatar.url = `${avatar.url}?${new Date().getTime()}`;
            }
            this.props.onAvatarChange(avatar);
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.avatar.uploadSucceed" }) });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    private deleteAvatar = async (): Promise<void> => {
        try {
            await Api.deleteClientAvatar(this.props.clientId);
            this.props.onAvatarChange(null);
            this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientProfile.avatar.deleteSucceed" }) });
        } catch (error) {
            this.props.showNotification({ type: NotificationType.error, message: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> {
        const url: string | null = this.props.avatar ? this.props.avatar.url : null;
        return <AvatarForm avatarUrl={url} onFileSelected={this.uploadAvatar} deleteAvatar={this.deleteAvatar} />;
    }
}

const ClientProfileAvatarForm = withNotification(ClientProfileAvatarFormComponent);

export { ClientProfileAvatarForm };

import React, { Component } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { Page } from "components/Page";
import { SupportedClient, SearchListType, AssetType } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Path, AppPath } from "utils/Path";
import { TabPanel } from "components/TabPanel/TabPanel";
import { ClientEverydaySituationsClientDirectory } from "./ClientEverydaySituationsClientDirectory";
import { ClientEverydaySituationsDirectories } from "./ClientEverydaySituationsDirectories";

import "./ClientEverydaySituationsPage.scss";

interface ReduxProps {
    accountId: string;
    client: SupportedClient | null;
}

interface Params {
    clientExtId?: string;
}

type Props = RouteComponentProps<Params> & DispatchProp & ReduxProps;

interface State {
    isLoading: boolean;
    dragIds: string[];
}

class ClientEverydaySituationsPageComponent extends Component<Props, State> {
    private pageRef: React.Ref<Page> = React.createRef<Page>();

    public state: State = {
        isLoading: false,
        dragIds: [],
    };

    private readonly setDragIds = (dragIds: string[]): void => {
        this.setState({ dragIds });
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        const hideSidebar = this.props.match.path === AppPath.clientEverydaySituationsDirectories;
        return (
            <Page
                ref={this.pageRef}
                title={Intl.formatMessage({ id: "page.clientEverydaySituations.clientDirectory.title" }, { name: this.props.client.name })}
                hasSideBar={!hideSidebar}
                hasTabs={true}
            >
                <DragContainer hideSidebar={hideSidebar} searchListTypes={[SearchListType.asset]} assetTypes={[AssetType.image]} createdById={this.props.accountId} dragIds={this.state.dragIds}>
                    <div className="left-side everyday-situation">
                        <TabPanel
                            tabs={[
                                {
                                    id: Intl.formatMessage({ id: "page.clientEverydaySituations.tabs.clientDirectory" }),
                                    label: Intl.formatMessage({ id: "page.clientEverydaySituations.tabs.clientDirectory" }),
                                    to: Path.clientEverydaySituationsClientDirectory(this.props.client.extId),
                                    component: ClientEverydaySituationsClientDirectory,
                                    additionalProps: { client: this.props.client, setDragIds: this.setDragIds },
                                },
                                {
                                    id: Intl.formatMessage({ id: "page.clientEverydaySituations.tabs.directories" }),
                                    label: Intl.formatMessage({ id: "page.clientEverydaySituations.tabs.directories" }),
                                    to: Path.clientEverydaySituationsDirectories(this.props.client.extId),
                                    component: ClientEverydaySituationsDirectories,
                                    additionalProps: { client: this.props.client, setDragIds: this.setDragIds },
                                },
                            ]}
                        />
                    </div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<Params>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<Params>): ReduxProps => {
    return {
        accountId: state.account!.id,
        client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId),
    };
};

export const ClientEverydaySituationsPage = withRouter(connect(mapStateToProps)(ClientEverydaySituationsPageComponent));

export default ClientEverydaySituationsPage;

import React, { Component } from "react";
import { NamedColor, SelectionBoardItemInput, SelectionBoard, SelectionBoardInput, AssetType, Asset, SelectionBoardItem, Account, AccountType } from "api/graphql/types";
import { Input } from "components/Inputs/Input/Input";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator, ValidatorMessage, ValidatorConstants } from "utils/Validator";
import { Prompt } from "components/Prompt";
import isEqual from "lodash/isEqual";
import { ColorSelect } from "components/Inputs/ColorSelect/ColorSelect";
import { ColorType } from "selectors/SettingsSelectors";
import { isNil, uniqueId, cloneDeep } from "lodash";
import { ClientSelectionBoardItemInputForm, SelectionBoardItemLocal } from "./ClientSelectionBoardItemInputForm";
import { PageType } from "utils/TypeUtils";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Alert } from "components/Alert/Alert";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { TestId } from "utils/TestId";
import { Path } from "utils/Path";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "./ClientSelectionBoardPage.scss";

interface RouteParams {
    clientExtId?: string;
}

interface ComponentProps {
    pageType: PageType;
    account: Account;
    selectionBoard: SelectionBoard;
    onSubmit: (selectionBoard: SelectionBoardInput) => Promise<SelectionBoard | void>;
}

type Props = ComponentProps & RouteComponentProps<RouteParams>;

interface SelectionBoardInputLocal {
    title: string;
    image: Asset | null;
    backgroundColor: string | null;
    items: SelectionBoardItemLocal[];
}

interface State {
    selectionBoard: SelectionBoardInputLocal;
    errors: {
        title: string | null;
        items: string | null;
        backgroundColor: string | null;
    };
    isValidationEnabled: boolean;
}

class ClientSelectionBoardFormComponent extends Component<Props, State> {
    public readonly state: State = ClientSelectionBoardFormComponent.getInitialStateFromProps(this.props);

    public static createItem(): SelectionBoardItemLocal {
        return {
            key: uniqueId(),
            id: "",
            image: null,
            title: "",
        };
    }

    public static getItems = (props: Props): SelectionBoardItemLocal[] => {
        if (props.pageType === PageType.create && props.selectionBoard.items.length === 0) {
            return [ClientSelectionBoardFormComponent.createItem(), ClientSelectionBoardFormComponent.createItem()];
        }

        const clonedArray: SelectionBoardItemLocal[] = props.selectionBoard.items.map(
            (item: SelectionBoardItem): SelectionBoardItemLocal => {
                return {
                    key: item.id,
                    id: item.id,
                    title: item.title || "",
                    image: cloneDeep(item.image),
                };
            },
        );

        if (clonedArray.length < ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MAX) {
            clonedArray.push(ClientSelectionBoardFormComponent.createItem());
        }

        return clonedArray;
    };

    public static getInitialSelectionBoard = (props: Props): SelectionBoardInputLocal => {
        return {
            title: props.selectionBoard.title,
            image: cloneDeep(props.selectionBoard.image),
            backgroundColor: props.selectionBoard.backgroundColor,
            items: ClientSelectionBoardFormComponent.getItems(props),
        };
    };

    public static getInitialStateFromProps(props: Props): State {
        return {
            selectionBoard: ClientSelectionBoardFormComponent.getInitialSelectionBoard(props),
            errors: {
                title: null,
                items: null,
                backgroundColor: null,
            },
            isValidationEnabled: false,
        };
    }

    private convertLocalItemsToInput = (items: SelectionBoardItemLocal[]): SelectionBoardItemInput[] => {
        return items.map((item: SelectionBoardItemLocal) => {
            return {
                title: item.title,
                imageId: item.image?.id,
            };
        });
    };

    private onSubmit = async (): Promise<void> => {
        const { selectionBoard } = this.state;

        const errors = {
            title: IntlHelpers.getValidationError(Validator.validateSelectionBoardTitle(selectionBoard.title)),
            items: IntlHelpers.getValidationError(Validator.validateSelectionBoardItems(selectionBoard.items)),
            backgroundColor: IntlHelpers.getValidationError(Validator.validateNonNull(selectionBoard.backgroundColor)),
        };

        const toBeSavedItems = (selectionBoard.items || []).filter(this.isSelectionBoardItemToBeSaved);
        const hasOtherError: boolean = toBeSavedItems.some((item: SelectionBoardItemLocal): boolean => !isNil(item) && !isNil(Validator.validateSelectionBoardItem(item)));

        if (errors.title || errors.items || errors.backgroundColor || hasOtherError || toBeSavedItems.length < ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MIN) {
            this.setState({ errors, isValidationEnabled: true });
            return;
        }

        try {
            await this.props.onSubmit({
                title: selectionBoard.title,
                imageId: selectionBoard.image?.id || null,
                backgroundColor: selectionBoard.backgroundColor,
                items: this.convertLocalItemsToInput(selectionBoard.items.filter(this.isSelectionBoardItemToBeSaved)),
            });
            this.setState({ selectionBoard: ClientSelectionBoardFormComponent.getInitialSelectionBoard(this.props) }, () => {
                if (this.props.pageType === PageType.create) {
                    this.props.history.push(Path.clientSelectionBoards(this.props.match.params.clientExtId!));
                }
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onItemChange = (index: number) => (selectionBoardItem: SelectionBoardItemLocal | null): void => {
        const items: SelectionBoardItemLocal[] = [...this.state.selectionBoard.items];
        if (selectionBoardItem === null) {
            if (items.length === 2) {
                items[index] = ClientSelectionBoardFormComponent.createItem();
            } else {
                items.splice(index, 1);
            }
        } else {
            items[index] = selectionBoardItem;
        }
        const itemsError: string | null = IntlHelpers.getValidationError(Validator.validateSelectionBoardItems(items));
        const isEveryItemValid = items.every(this.isSelectionBoardItemValid);
        if (isEveryItemValid && items.length < ValidatorConstants.SELECTION_BOARD_ITEM_COUNT_MAX) {
            items.push(ClientSelectionBoardFormComponent.createItem());
        }

        this.setState({ selectionBoard: { ...this.state.selectionBoard, items }, errors: { ...this.state.errors, items: itemsError } });
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateSelectionBoardTitle(title));
        this.setState({ selectionBoard: { ...this.state.selectionBoard, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onBackgroundColorChange = (item: NamedColor | null) => {
        const backgroundColor: string | null = item ? item.value : null;
        this.setState({ selectionBoard: { ...this.state.selectionBoard, backgroundColor }, errors: { ...this.state.errors, backgroundColor: null } });
    };

    private isSelectionBoardItemValid(selectionBoardItem: SelectionBoardItemLocal): boolean {
        return selectionBoardItem.title !== "";
    }

    private isSelectionBoardItemToBeSaved(selectionBoardItem: SelectionBoardItemLocal): boolean {
        return selectionBoardItem.title !== "" || !isNil(selectionBoardItem.image?.id);
    }

    private renderSelectionBoardItem = (selectionBoardItem: SelectionBoardItemLocal, index: number): React.ReactElement | null => {
        let error: string | null = null;
        if (this.state.isValidationEnabled) {
            if ([0, 1].includes(index)) {
                error = IntlHelpers.getValidationError(
                    this.isSelectionBoardItemToBeSaved(selectionBoardItem) ? Validator.validateSelectionBoardItem(selectionBoardItem) : ValidatorMessage.invalidSelectionBoardItem,
                );
            } else {
                error = IntlHelpers.getValidationError(this.isSelectionBoardItemToBeSaved(selectionBoardItem) ? Validator.validateSelectionBoardItem(selectionBoardItem) : null);
            }
        }

        if (this.props.pageType === PageType.view && !selectionBoardItem.image && !selectionBoardItem.title) {
            return null;
        }

        return (
            <ClientSelectionBoardItemInputForm
                key={selectionBoardItem.key}
                itemKey={selectionBoardItem.key}
                value={selectionBoardItem}
                onChange={this.onItemChange(index)}
                error={error}
                disabled={this.props.pageType === PageType.view}
            />
        );
    };

    private isChanged = (): boolean => {
        const { state, props } = this;
        return (
            props.selectionBoard.title !== state.selectionBoard.title ||
            !isEqual(props.selectionBoard.image, state.selectionBoard.image) ||
            props.selectionBoard.backgroundColor !== state.selectionBoard.backgroundColor ||
            !isEqual(
                ClientSelectionBoardFormComponent.getInitialSelectionBoard(props).items.filter(this.isSelectionBoardItemToBeSaved),
                state.selectionBoard.items.filter(this.isSelectionBoardItemToBeSaved),
            )
        );
    };

    private onCancelClick = (): void => {
        this.setState({
            selectionBoard: ClientSelectionBoardFormComponent.getInitialSelectionBoard(this.props),
            isValidationEnabled: false,
            errors: { title: null, items: null, backgroundColor: null },
        });
    };

    private renderBottomBar = (): React.ReactElement | null => {
        const { errors, isValidationEnabled } = this.state;
        if (this.props.account.accountType !== AccountType.supporter) {
            return null;
        }

        if (this.props.pageType === PageType.view) {
            return (
                <BottomBar key="view" isVisible={true}>
                    <div className="cell medium-12 text-center">
                        <Button
                            label={Intl.formatMessage({ id: "common.edit" })}
                            onClick={() => {
                                this.props.history.push(Path.editSelectionBoard(this.props.match.params.clientExtId!, this.props.selectionBoard.id));
                            }}
                        />
                    </div>
                </BottomBar>
            );
        }
        const validItems = this.state.selectionBoard.items.filter(this.isSelectionBoardItemValid);
        const hasOtherError: boolean = validItems.some((item: SelectionBoardItemLocal): boolean => !isNil(item) && !isNil(Validator.validateSelectionBoardItem(item)));

        return (
            <BottomBar key="edit" isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button id={TestId.clientSelectionBoardsPagePage.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button
                        id={TestId.clientSelectionBoardsPagePage.saveButton}
                        label={Intl.formatMessage({ id: "common.save" })}
                        disabled={!this.isChanged() && isValidationEnabled && (hasOtherError || !!errors.title || !!errors.backgroundColor || !!errors.items)}
                        onClick={this.onSubmit}
                    />
                </div>
            </BottomBar>
        );
    };

    private onImageChange = (assets: Asset[] | null): void => {
        const image: Asset | null = !isNil(assets) && assets.length > 0 ? assets[0] : null;
        this.setState({ selectionBoard: { ...this.state.selectionBoard, image } });
    };

    public render(): React.ReactElement {
        const { selectionBoard, errors, isValidationEnabled } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <div className="selection-board basic-box">
                    <InputWrapper
                        id={TestId.clientSelectionBoardsPagePage.titleInput}
                        inputLabel={Intl.formatMessage({ id: "page.clientSelectionBoards.title.label" })}
                        errorMessage={isValidationEnabled ? errors.title : null}
                    >
                        <Input
                            type="text"
                            value={selectionBoard.title}
                            placeholder={Intl.formatMessage({ id: "page.clientSelectionBoards.title.placeholder" })}
                            onChange={this.onTitleChange}
                            hasError={isValidationEnabled && !isNil(errors.title)}
                            disabled={this.props.pageType === PageType.view}
                        />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSelectionBoards.image.label" })}>
                        <AssetInput
                            droppableId="image"
                            assetType={AssetType.image}
                            value={!isNil(selectionBoard.image) ? [selectionBoard.image] : []}
                            onChange={(assets: Asset[]) => this.onImageChange(assets)}
                            maxItemCount={1}
                            minItemCount={1}
                            disabled={this.props.pageType === PageType.view}
                        />
                    </InputWrapper>
                    <InputWrapper
                        id={TestId.clientSelectionBoardsPagePage.backgroundColorSelect}
                        inputLabel={Intl.formatMessage({ id: "page.clientSelectionBoards.backgroundColor.formLabel" })}
                        errorMessage={isValidationEnabled ? errors.backgroundColor : null}
                    >
                        <ColorSelect
                            selectedColor={this.state.selectionBoard.backgroundColor}
                            colorType={ColorType.background}
                            onChange={this.onBackgroundColorChange}
                            hasError={isValidationEnabled && !isNil(errors.backgroundColor)}
                            className="text-left"
                            disabled={this.props.pageType === PageType.view}
                        />
                    </InputWrapper>

                    <hr />

                    <div className="custom-label">{Intl.formatMessage({ id: "page.clientSelectionBoards.items.label" })}</div>

                    <div className="float-container">{selectionBoard.items.map(this.renderSelectionBoardItem)}</div>
                </div>
                {this.renderBottomBar()}
                <Prompt when={this.isChanged()} />
            </form>
        );
    }
}

export const ClientSelectionBoardForm = withRouter(ClientSelectionBoardFormComponent);

import React, { Component } from "react";
import { PageType } from "utils/TypeUtils";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { LocalEducationContentModuleInput, LocalCreateEducationContentInput, Progresses } from "./EducationSubModulePage";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { isNil, isEqual } from "lodash";
import { Prompt } from "components/Prompt";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Alert } from "components/Alert/Alert";
import { NotificationType } from "components/NotificationBar/Notification";
import { Path } from "utils/Path";
import { GeneralFileInput } from "components/Inputs/GeneralFileInput/GeneralFileInput";
import { EducationContentType, AllowedUploadContentTypes, AssetType } from "api/graphql/types";
import { SortableTable } from "components/Table/SortableTable";
import { ObjectUtils } from "utils/ObjectUtils";
import { Column } from "components/Table/Table";
import { ImageSrc } from "utils/ImageSrc";
import { uuid4 } from "@sentry/utils";
import { PdfIcon } from "components/Icons/PdfIcon";
import { ApplicationState } from "reducers";
import { Image } from "components/Image";

import "./EducationSubModuleForm.scss";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface ReduxProps {
    allowedUploadContentTypes: AllowedUploadContentTypes | null;
    maxDocumentSize?: number;
    maxVideoSize?: number;
}

type ComponentProps = {
    isLoading: boolean;
    pageType: PageType;
    moduleId: string;
    subModule: LocalEducationContentModuleInput;
    fileProgresses: Progresses;
    onSubmit: (subModule: LocalEducationContentModuleInput) => Promise<LocalEducationContentModuleInput>;
};

type Props = ComponentProps & RouteComponentProps & ReduxProps & DispatchProp;

interface Errors {
    title: string | null;
}

interface State {
    isLoading: boolean;
    subModule: LocalEducationContentModuleInput;
    errors: Errors;
    isValidationEnabled: boolean;
}

enum DocumentsTableColumn {
    title = "title",
    actions = "actions",
}

enum VideosTableColumn {
    title = "title",
    inputs = "inputs",
}

class EducationSubModuleFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            isLoading: false,
            subModule: {
                parentId: props.moduleId,
                title: props.subModule.title,
                documentContents: props.subModule.documentContents,
                videoContents: props.subModule.videoContents,
            },
            isValidationEnabled: false,
            errors: {
                title: null,
            },
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = EducationSubModuleFormComponent.getInitialStateFromProps(props);
    }

    private onSubmit = async (): Promise<void> => {
        const { subModule, errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateSubModuleTitle(subModule.title));
        const isVideoTitleErrorPresentCurrently: boolean =
            !isNil(subModule.videoContents?.find((videoContent: LocalCreateEducationContentInput) => !isNil(videoContent.titleError) && videoContent.titleError !== "")) || false;

        let isVideoTitleErrorPresentOnSubmit: boolean = false;
        const videoContentsCheckedForTitleError: LocalCreateEducationContentInput[] | null =
            subModule.videoContents?.map((videoContent: LocalCreateEducationContentInput) => {
                const videoTitleError: string | null = IntlHelpers.getValidationError(Validator.validateSubModuleTitle(videoContent.title));
                isVideoTitleErrorPresentOnSubmit = videoTitleError ? true : false;
                return {
                    ...videoContent,
                    titleError: videoTitleError,
                };
            }) || null;

        if (errors.title !== null || titleError || isVideoTitleErrorPresentCurrently || isVideoTitleErrorPresentOnSubmit) {
            this.setState({
                subModule: {
                    ...this.state.subModule,
                    videoContents: videoContentsCheckedForTitleError,
                },
                isValidationEnabled: true,
                errors: {
                    ...this.state.errors,
                    title: titleError,
                },
            });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            }
            return;
        }

        this.setState({ isLoading: true }, async () => {
            try {
                const subModule: LocalEducationContentModuleInput = await this.props.onSubmit(this.state.subModule);
                this.setState({ subModule, isValidationEnabled: false }, () => {
                    if (this.props.pageType === PageType.create) {
                        Alert.success({ title: Intl.formatMessage({ id: `page.educationSubModuleForm.page.${this.props.pageType}.saveSucceed` }) });
                        this.props.history.push(Path.educationSubModuleList(this.props.moduleId));
                    } else if (this.props.pageType === PageType.edit) {
                        if (this.pageRef) {
                            this.pageRef.showNotification({
                                message: Intl.formatMessage({ id: `page.educationSubModuleForm.page.${this.props.pageType}.saveSucceed` }),
                                type: NotificationType.success,
                            });
                        }
                    }
                });
                this.setState({ isLoading: false });
            } catch (error) {
                this.setState({ isLoading: false });
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            }
        });
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateSubModuleTitle(title));
        this.setState({ subModule: { ...this.state.subModule, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onCancelClick = (): void => {
        this.setState({ subModule: this.props.subModule, isValidationEnabled: false, errors: { title: null } });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.subModule, this.props.subModule);
    };

    private renderBottomBar = (): React.ReactElement => {
        return (
            <BottomBar isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged() || this.state.isLoading} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged() || this.state.isLoading} onClick={this.onSubmit} />
                </div>
            </BottomBar>
        );
    };

    private getLocalCreateEducationContentInput = (files: File[]): LocalCreateEducationContentInput[] => {
        return files.map((file: File) => {
            return {
                id: uuid4(),
                titleError: IntlHelpers.getValidationError(Validator.validateSubModuleTitle(file.name)),
                type: EducationContentType.normal,
                title: file.name,
                url: ImageSrc.asset,
                thumbnail: ImageSrc.asset,
                file,
                progress: null,
            };
        });
    };

    private onDocumentFileSelected = (documents: File[]): void => {
        if (documents.length < 1) {
            return;
        }

        const documentsToUpload: File[] = [];
        const documentsToReject: File[] = [];
        documents.forEach((document: File) => {
            !isNil(this.props.maxDocumentSize) && document.size > this.props.maxDocumentSize ? documentsToReject.push(document) : documentsToUpload.push(document);
        });

        if (documentsToReject.length > 0) {
            Alert.error({
                title: Intl.formatMessage(
                    { id: "page.educationSubModuleForm.page.filesAboveMaxSize.document" },
                    { maxDocumentSize: IntlHelpers.getFileSize(this.props.maxDocumentSize!), documentsToReject: documentsToReject.map((document: File) => document.name).join(", ") },
                ),
                timeout: 7500,
            });
        }

        this.setState({
            subModule: {
                ...this.state.subModule,
                documentContents: !isNil(this.state.subModule.documentContents)
                    ? [...this.state.subModule.documentContents, ...this.getLocalCreateEducationContentInput(documentsToUpload)]
                    : [...this.getLocalCreateEducationContentInput(documentsToUpload)],
            },
        });
    };

    private onVideoFileSelected = (videos: File[]): void => {
        if (videos.length < 1) {
            return;
        }

        const videosToUpload: File[] = [];
        const videosToReject: File[] = [];
        videos.forEach((video: File) => {
            !isNil(this.props.maxVideoSize) && video.size > this.props.maxVideoSize ? videosToReject.push(video) : videosToUpload.push(video);
        });

        if (videosToReject.length > 0) {
            Alert.error({
                title: Intl.formatMessage(
                    { id: "page.educationSubModuleForm.page.filesAboveMaxSize.video" },
                    { maxVideoSize: IntlHelpers.getFileSize(this.props.maxVideoSize!), videosToReject: videosToReject.map((video: File) => video.name).join(", ") },
                ),
                timeout: 7500,
            });
        }

        this.setState({
            subModule: {
                ...this.state.subModule,
                videoContents: !isNil(this.state.subModule.videoContents)
                    ? [...this.state.subModule.videoContents, ...this.getLocalCreateEducationContentInput(videosToUpload)]
                    : [...this.getLocalCreateEducationContentInput(videosToUpload)],
            },
        });
    };

    private onDocumentsOrderChange = (documentContents: LocalCreateEducationContentInput[]): void => {
        this.setState({ subModule: { ...this.state.subModule, documentContents } });
    };

    private deleteDocument = (documentId: string) => {
        this.setState({
            subModule: {
                ...this.state.subModule,
                documentContents: this.state.subModule.documentContents!.filter((document: LocalCreateEducationContentInput) => document.id !== documentId),
            },
        });
    };

    private onVideosOrderChange = (videoContents: LocalCreateEducationContentInput[]): void => {
        this.setState({ subModule: { ...this.state.subModule, videoContents } });
    };

    private deleteVideo = (videoId: string) => {
        this.setState({
            subModule: {
                ...this.state.subModule,
                videoContents: this.state.subModule.videoContents!.filter((video: LocalCreateEducationContentInput) => video.id !== videoId),
            },
        });
    };

    private readonly getDocumentsColumns = (): Array<Column<LocalCreateEducationContentInput>> => {
        const columnNames: DocumentsTableColumn[] = ObjectUtils.enumAsArray<DocumentsTableColumn>(DocumentsTableColumn);

        return columnNames.map(
            (columnName: DocumentsTableColumn): Column<LocalCreateEducationContentInput> => ({
                id: columnName,
                name: " ",
                accessor: columnName as keyof LocalCreateEducationContentInput,
                renderCell: (subModule: LocalCreateEducationContentInput): React.ReactElement | null => {
                    switch (columnName) {
                        case DocumentsTableColumn.title:
                            return (
                                <div className="education-sub-module-form-document-title-cell">
                                    <PdfIcon width={40} height={52} />
                                    <div className="education-sub-module-form-document-title-cell-text">
                                        <span className="education-sub-module-form-document-title-cell-title">{subModule[columnName]}</span>
                                        <span className="education-sub-module-form-document-title-cell-progress">{this.getProgress(subModule.id)}</span>
                                    </div>
                                </div>
                            );
                        case DocumentsTableColumn.actions:
                            return (
                                <div className="education-sub-module-form-document-action-buttons">
                                    <Button link icon={{ name: "fa-trash", large: true }} onClick={() => this.deleteDocument(subModule.id)} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
                                    <div className="move-icon">
                                        <img src={ImageSrc.moveIcon} />
                                    </div>
                                </div>
                            );
                        default:
                            return null;
                    }
                },
            }),
        );
    };

    private onVideoTitleChange = (videoId: string, title: string): void => {
        const newVideoArray: LocalCreateEducationContentInput[] = this.state.subModule.videoContents!.map((videoContent: LocalCreateEducationContentInput) => {
            let newTitle: string = videoContent.title;
            let titleError: string | null = null;
            if (videoContent.id === videoId) {
                newTitle = title;
                titleError = IntlHelpers.getValidationError(Validator.validateSubModuleTitle(title));
            }
            return { ...videoContent, title: newTitle, titleError };
        });

        this.setState({ subModule: { ...this.state.subModule, videoContents: newVideoArray } });
    };

    private onVideoThumbnailClick = (subModule: LocalCreateEducationContentInput): void => {
        if (isNil(subModule.url) || subModule.url === ImageSrc.asset) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.showAsset,
                assetUrl: subModule.url,
                assetType: AssetType.video,
                originalFileName: subModule.title,
                thumbnailUrl: subModule.thumbnail,
                dialogTitle: Intl.formatMessage({ id: "page.educationSubModuleForm.page.showVideoTitle" }),
            }),
        );
    };

    private getProgress = (id: string) => {
        return !isNil(this.props.fileProgresses[id]) ? Intl.formatMessage({ id: "page.educationSubModuleForm.page.uploadProgress" }, { progress: this.props.fileProgresses[id] }) : "";
    };

    private readonly getVideosColumns = (): Array<Column<LocalCreateEducationContentInput>> => {
        const columnNames: VideosTableColumn[] = ObjectUtils.enumAsArray<VideosTableColumn>(VideosTableColumn);

        return columnNames.map(
            (columnName: VideosTableColumn): Column<LocalCreateEducationContentInput> => ({
                id: columnName,
                name: " ",
                accessor: columnName as keyof LocalCreateEducationContentInput,
                renderCell: (video: LocalCreateEducationContentInput): React.ReactElement | null => {
                    switch (columnName) {
                        case VideosTableColumn.title:
                            return (
                                <div onClick={() => this.onVideoThumbnailClick(video)}>
                                    <div className="video-image-container">
                                        <Image className="video-image" src={video.thumbnail} />
                                        {video.url && video.url !== ImageSrc.asset && (
                                            <>
                                                <div className="video-image-overlay" />
                                                <div className="video-overlay-icon">
                                                    <span className="fa fa-play-circle" />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        case VideosTableColumn.inputs:
                            return (
                                <div>
                                    <InputWrapper
                                        inputLabel={Intl.formatMessage({ id: "page.educationSubModuleForm.page.videoTitle.label" })}
                                        errorMessage={this.state.isValidationEnabled ? video.titleError : null}
                                    >
                                        <Input
                                            placeholder={Intl.formatMessage({ id: "page.educationSubModuleForm.page.videoTitle.placeholder" })}
                                            value={video.title || ""}
                                            hasError={this.state.isValidationEnabled && !isNil(video.titleError)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => this.onVideoTitleChange(video.id, event.currentTarget.value)}
                                        />
                                    </InputWrapper>
                                    <div className="education-sub-module-form-video-action-buttons-container">
                                        <p className="progress">{this.getProgress(video.id)}&nbsp;</p>
                                        <div className="actions education-sub-module-form-video-action-buttons">
                                            <Button link icon={{ name: "fa-trash", large: true }} onClick={() => this.deleteVideo(video.id)} ariaLabel={Intl.formatMessage({ id: "common.delete" })} />
                                            <div className="move-icon">
                                                <img src={ImageSrc.moveIcon} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        default:
                            return null;
                    }
                },
            }),
        );
    };

    private renderDocuments = (): React.ReactElement => {
        return (
            <>
                <p className="small-text">{Intl.formatMessage({ id: "page.educationSubModuleForm.page.documentsSection.title" })}</p>

                <div className="grid-x">
                    <div className="cell shrink">
                        <GeneralFileInput
                            type={"application/pdf"}
                            onFileSelected={this.onDocumentFileSelected}
                            url={null}
                            placeholder={<Button label={Intl.formatMessage({ id: "page.educationSubModuleForm.page.documentsSection.button" })} />}
                        />
                    </div>
                </div>

                <SortableTable
                    data={this.state.subModule.documentContents || []}
                    onOrderChange={this.onDocumentsOrderChange}
                    columns={this.getDocumentsColumns()}
                    areArrowsHidden={true}
                    isLoading={this.props.isLoading}
                    count={this.state.subModule.documentContents?.length || 0}
                />
            </>
        );
    };

    private renderVideos = (): React.ReactElement => {
        return (
            <div className="videos-section">
                <p className="small-text">{Intl.formatMessage({ id: "page.educationSubModuleForm.page.videosSection.title" })}</p>

                <div className="grid-x">
                    <div className="cell shrink">
                        <GeneralFileInput
                            type={this.props.allowedUploadContentTypes?.video.join(",") || ""}
                            onFileSelected={this.onVideoFileSelected}
                            url={null}
                            placeholder={<Button label={Intl.formatMessage({ id: "page.educationSubModuleForm.page.videosSection.button" })} />}
                        />
                    </div>
                </div>

                <SortableTable
                    data={this.state.subModule.videoContents || []}
                    onOrderChange={this.onVideosOrderChange}
                    columns={this.getVideosColumns()}
                    areArrowsHidden={true}
                    isLoading={this.props.isLoading}
                    count={this.state.subModule.videoContents?.length || 0}
                />
            </div>
        );
    };

    public render(): React.ReactElement {
        const { pageType } = this.props;
        const { subModule, errors, isValidationEnabled } = this.state;

        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: `page.educationSubModuleForm.page.${this.props.pageType}.title` })}
            >
                <div className="left-side education-sub-module-form">
                    <div className="w-780 ml-0">
                        <p className="education-sub-module-form-lead">{Intl.formatMessage({ id: `page.educationSubModuleForm.page.${pageType}.lead` })}</p>
                        <InputWrapper inputLabel={Intl.formatMessage({ id: "page.educationSubModuleForm.page.title.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                            <Input
                                innerRef={(ref: HTMLInputElement | null): void => {
                                    this.titleRef = ref;
                                }}
                                placeholder={Intl.formatMessage({ id: "page.educationSubModuleForm.page.title.placeholder" })}
                                value={subModule.title || ""}
                                hasError={isValidationEnabled && !isNil(errors.title)}
                                onChange={this.onTitleChange}
                            />
                        </InputWrapper>

                        {this.renderDocuments()}
                        <hr className="mb-60" />
                        {this.renderVideos()}
                        <hr className="mt-0" />
                    </div>
                </div>
                {this.renderBottomBar()}

                <Prompt when={this.isChanged()} />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return {
        allowedUploadContentTypes: state.settings.allowedUploadContentTypes,
        maxDocumentSize: state.settings.allowedUploadFileSizes?.educationDocument,
        maxVideoSize: state.settings.allowedUploadFileSizes?.educationVideo,
    };
};

export const EducationSubModuleForm = withRouter(connect(mapStateToProps)(EducationSubModuleFormComponent));

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { TestId } from "utils/TestId";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface LogoutDialogProps {
    onLogoutClick: () => void;
}

type Props = LogoutDialogProps & DialogVisibilityProps;

class LogoutDialog extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "component.navigation.logoutDialog.title" })} visible={this.props.isVisible} onCloseClick={this.props.onHide}>
                <p>{Intl.formatMessage({ id: "component.navigation.logoutDialog.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button id={TestId.navigation.logoutDialog.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button id={TestId.navigation.logoutDialog.acceptButton} label={Intl.formatMessage({ id: "component.navigation.logoutDialog.logout" })} onClick={this.props.onLogoutClick} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { LogoutDialog };

import { SearchListType } from "api/graphql/types";
import { ParsedParameter, UrlQueryParser } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { QueryParserTypeUtils } from "utils/TypeUtils";

enum QueryParameter {
    searchListType = "searchListType",
    search = "search",
}

export interface ContentLibraryQueryParameter {
    searchListType?: SearchListType;
    search?: string;
}

class ContentShareRequestUrlQueryParser extends UrlQueryParser<ContentLibraryQueryParameter> {
    private translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.contentLibraryQueryParameter.${key}` });
    }

    public getUrlQuery(options: ContentLibraryQueryParameter): string {
        const searchListType: SearchListType | null = options.searchListType || null;

        const paramArray: string[] = [];
        if (searchListType) {
            paramArray.push(`${this.translateListQuery(QueryParameter.searchListType)}=${Intl.formatMessage({ id: `enum.searchListTypeQueryParameter.${searchListType}` })}`);
        }

        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): ContentLibraryQueryParameter {
        const keys = {
            [QueryParameter.searchListType]: this.translateListQuery(QueryParameter.searchListType),
            [QueryParameter.search]: this.translateListQuery(QueryParameter.search),
        };

        const searchListType: SearchListType | undefined = IntlHelpers.fromI18nToEnum<SearchListType>(SearchListType, parsedParams[keys.searchListType], "enum.searchListTypeQueryParameter");
        const search: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.search]);

        return { searchListType, search };
    }
}

export { ContentShareRequestUrlQueryParser };

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Button } from "components/Button/Button";
import { Validator } from "utils/Validator";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";

export interface AddSupporterDialogProps {
    clientId: string;
    onSucceed: () => void;
}

type Props = AddSupporterDialogProps & DialogVisibilityProps;

interface State {
    email: string;
    isValidationEnabled: boolean;
    formErrors: {
        email: string | null;
    };
}

class AddSupporterDialog extends Component<Props, State> {
    private emailRef: HTMLInputElement | null = null;

    private getInitialState(): State {
        return {
            email: "",
            isValidationEnabled: false,
            formErrors: {
                email: null,
            },
        };
    }
    public readonly state: State = this.getInitialState();

    public componentDidMount(): void {
        if (this.emailRef) {
            this.emailRef.focus();
        }
    }

    private onAddSupporter = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formErrors = {
            email: IntlHelpers.getValidationError(Validator.validateEmail(this.state.email)),
        };

        if (!formErrors.email) {
            try {
                await Api.shareClient(this.state.email, this.props.clientId);
                this.props.onSucceed();
                this.props.onHide();
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            }
        } else {
            this.setState({ isValidationEnabled: true, formErrors });
        }
    };

    private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const email: string = event.currentTarget.value;
        const formErrors = {
            email: IntlHelpers.getValidationError(Validator.validateEmail(email)),
        };

        this.setState({ email, formErrors });
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog title={Intl.formatMessage({ id: "page.clientProfile.supporterList.dialog.title" })} onCloseClick={this.props.onHide} visible={this.props.isVisible}>
                <form onSubmit={this.onAddSupporter}>
                    <p>{Intl.formatMessage({ id: "page.clientProfile.supporterList.dialog.description" })}</p>
                    <InputWrapper
                        inputLabel={Intl.formatMessage({ id: "page.clientProfile.supporterList.dialog.inputLabel" })}
                        errorMessage={this.state.isValidationEnabled ? this.state.formErrors.email : null}
                    >
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.emailRef = ref;
                            }}
                            type="text"
                            placeholder={Intl.formatMessage({ id: "page.clientProfile.supporterList.dialog.inputPlaceholder" })}
                            onChange={this.onEmailChange}
                            value={this.state.email}
                        />
                    </InputWrapper>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: "page.clientProfile.supporterList.dialog.confirmButton" })}
                                type="submit"
                                disabled={this.state.isValidationEnabled && !!Validator.validateEmail(this.state.email)}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export { AddSupporterDialog };

import React, { Component } from "react";
import { AssetType } from "api/graphql/types";
import { Button } from "components/Button/Button";
import { Validator } from "utils/Validator";
import { IntlHelpers } from "i18n/IntlHelpers";
import { NotificationProp, withNotification } from "./NotificationBar/NotificationContext";
import { NotificationType } from "./NotificationBar/Notification";
import { DispatchProp, connect } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "./DialogContainer/DialogsContainer";
import { ImageSrc } from "utils/ImageSrc";
import { FileInput } from "./Inputs/FileInput/FileInput";

interface ComponentProps {
    avatarUrl: string | null;
    onFileSelected: (file: File, onProgressChange: (progress: number) => void) => Promise<void> | void;
    deleteAvatar: () => Promise<void> | void;
}

type Props = ComponentProps & NotificationProp & DispatchProp;

interface State {
    progress: number | null;
    avatarObjectUrl: string | null;
}

class AvatarFormComponent extends Component<Props, State> {
    public readonly state: State = {
        progress: null,
        avatarObjectUrl: null,
    };

    private onProgressChange = (progress: number): void => {
        this.setState({ progress });
    };

    private onFileSelected = (files: File[]): void => {
        if (files.length < 1) {
            return;
        }

        const file: File = files[0];

        const image: HTMLImageElement = new Image();
        const avatarObjectUrl: string = URL.createObjectURL(file);

        image.addEventListener("load", () => {
            const validationError: string | null = IntlHelpers.getValidationError(Validator.validateAvatarImageSize(image.width, image.height));
            if (validationError) {
                this.props.showNotification({ type: NotificationType.error, message: validationError });
                return;
            }

            this.setState(
                { avatarObjectUrl },
                async (): Promise<void> => {
                    await this.props.onFileSelected(file, this.onProgressChange);
                    this.setState({ progress: null, avatarObjectUrl: null });
                },
            );
        });
        image.src = avatarObjectUrl;
    };

    public render(): React.ReactElement<any> {
        const url: string | null = this.props.avatarUrl || this.state.avatarObjectUrl;
        return (
            <>
                <div className="avatar">
                    <FileInput
                        progress={this.state.progress}
                        type={AssetType.image}
                        onFileSelected={this.onFileSelected}
                        url={url}
                        placeholder={
                            <>
                                <Button className={"blue left-auto"} link icon={{ name: "fa-plus", large: true }} />
                                <img src={ImageSrc.avatar} srcSet={ImageSrc.avatar} className="upload-holder" />
                            </>
                        }
                        previewEnabled={false}
                    />
                    {url && (
                        <Button
                            onClick={(): void => {
                                this.props.dispatch(
                                    DialogsActions.show({
                                        type: DialogType.confirmAvatarDelete,
                                        onOkClick: this.props.deleteAvatar,
                                    }),
                                );
                            }}
                            ariaLabel={"delete"}
                            icon={{ name: "fa-times", large: true }}
                            link
                        />
                    )}
                </div>
            </>
        );
    }
}

const AvatarForm = withNotification(connect()(AvatarFormComponent));

export { AvatarForm };

import React, { Component } from "react";
import { FlowchartItem, AccountType, Account, Flowchart, AgendaItem, Agenda, LibraryContentShareRequestWithData } from "api/graphql/types";
import { Api } from "api/Api";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { FlowchartItemForm } from "pages/FlowchartItemPage/FlowchartItemForm";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { PageType } from "utils/TypeUtils";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers";
import { FlowchartForm } from "pages/FlowchartPage/FlowchartForm";
import { AgendaItemForm } from "pages/AgendaItemPage/AgendaItemForm";
import { AgendaForm } from "pages/AgendaPage/AgendaForm";

interface ReduxProps {
    myAccount: Account;
}

interface Params {
    shareRequestId?: string;
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<Params>;

interface State {
    contentShareRequest: LibraryContentShareRequestWithData | null;
    isLoading: boolean;
}

class ContentShareRequestPageComponent extends Component<Props, State> {
    public readonly state: State = {
        contentShareRequest: null,
        isLoading: true,
    };

    public componentDidMount(): void {
        this.refreshShareRequest();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.match.params.shareRequestId !== prevProps.match.params.shareRequestId) {
            this.refreshShareRequest();
        }
    }

    private refreshShareRequest = () => {
        this.setState({ isLoading: true }, async () => {
            try {
                const contentShareRequest = await Api.getPendingLibraryContentShareRequestById(this.props.match.params.shareRequestId!);
                this.setState({ isLoading: false, contentShareRequest });
            } catch (error) {
                Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement<any> {
        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        if (!this.state.contentShareRequest) {
            return <Redirect to={Path.contentShareRequestList} />;
        }

        // Show form with share bottombar
        switch (this.state.contentShareRequest.libraryContent.__typename) {
            case "Agenda":
                return (
                    <AgendaForm
                        myAccount={this.props.myAccount}
                        agenda={this.state.contentShareRequest.libraryContent as Agenda}
                        pageContent={PageContent.shareRequest}
                        pageType={PageType.view}
                        onSubmit={((() => {}) as unknown) as any}
                        onToggled={() => {}}
                        accountType={AccountType.supporter}
                        isLoading={false}
                        onEditClick={() => {}}
                        refreshAgenda={() => {}}
                        shareRequest={this.state.contentShareRequest}
                    />
                );
            case "AgendaItem":
                return (
                    <AgendaItemForm
                        myAccount={this.props.myAccount}
                        agendaItem={this.state.contentShareRequest.libraryContent as AgendaItem}
                        pageContent={PageContent.shareRequest}
                        pageType={PageType.view}
                        onSubmit={((() => {}) as unknown) as any}
                        onToggled={() => {}}
                        accountType={AccountType.supporter}
                        isLoading={false}
                        onEditClick={() => {}}
                        refreshAgendaItem={() => {}}
                        shareRequest={this.state.contentShareRequest}
                        agenda={null}
                        clientAdditionalFunctions={{
                            isMoodMeterActive: false,
                        }}
                    />
                );
            case "Flowchart":
                return (
                    <FlowchartForm
                        myAccount={this.props.myAccount}
                        flowchart={this.state.contentShareRequest.libraryContent as Flowchart}
                        pageContent={PageContent.shareRequest}
                        pageType={PageType.view}
                        onSubmit={((() => {}) as unknown) as any}
                        onToggled={() => {}}
                        accountType={AccountType.supporter}
                        isLoading={false}
                        onEditClick={() => {}}
                        refreshFlowchart={() => {}}
                        shareRequest={this.state.contentShareRequest}
                    />
                );
            case "FlowchartItem":
                return (
                    <FlowchartItemForm
                        myAccount={this.props.myAccount}
                        flowchartItem={this.state.contentShareRequest.libraryContent as FlowchartItem}
                        pageContent={PageContent.shareRequest}
                        pageType={PageType.view}
                        onSubmit={((() => {}) as unknown) as any}
                        onToggled={() => {}}
                        accountType={AccountType.supporter}
                        clientAdditionalFunctions={{
                            isMoodMeterActive: false,
                        }}
                        isLoading={false}
                        onEditClick={() => {}}
                        refreshFlowchartItem={() => {}}
                        shareRequest={this.state.contentShareRequest}
                    />
                );
        }
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { myAccount: state.account! };
};

export const ContentShareRequestPage = withRouter(connect(mapStateToProps)(ContentShareRequestPageComponent));

export default ContentShareRequestPage;

import React, { ReactNode } from "react";

import "./Accordion.scss";

interface Props {
    id?: string;
    icon?: string;
    label: string | JSX.Element;
    children?: ReactNode;
    isOpen?: boolean;
}

interface State {
    isOpened: boolean;
}

class Accordion extends React.PureComponent<Props, State> {
    public readonly state: State = {
        isOpened: this.props.isOpen || false,
    };

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.isOpen !== this.props.isOpen && this.state.isOpened !== !!this.props.isOpen) {
            this.setState({ isOpened: !!this.props.isOpen });
        }
    }

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    public render(): React.ReactElement<any> {
        return (
            <div id={this.props.id} className={`dropdown side-bar-node${this.state.isOpened ? " show" : ""}`}>
                <button className="btn btn-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.onOpenChange}>
                    <span className="name">
                        {this.props.icon && <i className={`fa ${this.props.icon}`} style={{ marginLeft: "10px", marginRight: "15px" }}></i>}
                        {this.props.label}
                    </span>
                </button>

                <div className={`dropdown-menu${this.state.isOpened ? " show" : ""}`} x-placement="bottom-start">
                    {this.props.children && this.props.children}
                </div>
            </div>
        );
    }
}

export { Accordion };

export class Path {
    public static readonly root = "/";
    public static readonly login = "/bejelentkezes";
    public static readonly registration = "/regisztracio";

    public static accountActivation(data: string) {
        return `/fiok-aktivalasa/${data}`;
    }

    public static readonly forgotPassword = "/elfelejtett-jelszo";
    public static readonly dashboard = "/kezdolap";
    public static readonly profile = "/profilom";

    public static resetPassword(data: string) {
        return `/jelszo-visszaallitas/${data}`;
    }

    public static readonly publicContentLibrary = "/tartalomtar";
    public static readonly ownContentLibrary = "/tartalomtar/sajat";
    public static readonly contentShareRequestList = "/tartalomtar/felajanlasok";

    public static contentShareRequest(shareRequestId: string): string {
        return `/tartalomtar/velem-megosztott/${shareRequestId}`;
    }

    public static readonly addClient = "/ugyfel-hozzaadasa";
    public static readonly myEventLog = "/esemenynaplom";

    public static clientProfile(clientExtId: string): string {
        return `/${clientExtId}/profil`;
    }

    public static clientQuestionnaire(clientExtId: string): string {
        return `/${clientExtId}/profil/felmeres`;
    }

    public static clientCalendar(clientExtId: string): string {
        return `/${clientExtId}/naptar`;
    }

    public static clientContentLibrary(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar`;
    }

    public static clientAwardsBase(clientExtId: string): string {
        return `/${clientExtId}/jutalmazas`;
    }

    public static clientInstantAwards(clientExtId: string): string {
        return `/${clientExtId}/jutalmazas/azonnali`;
    }

    public static createClientInstantAward(clientExtId: string): string {
        return `/${clientExtId}/jutalmazas/azonnali/letrehozas`;
    }

    public static editClientInstantAward(clientExtId: string, clientInstantAwardId: string): string {
        return `/${clientExtId}/jutalmazas/azonnali/${clientInstantAwardId}/szerkesztes`;
    }

    public static clientAwards(clientExtId: string): string {
        return `/${clientExtId}/jutalmazas/pontgyujtos`;
    }

    public static editClientAward(clientExtId: string, clientAwardId: string): string {
        return `/${clientExtId}/jutalmazas/pontgyujtos/${clientAwardId}/szerkesztes`;
    }

    public static createClientAward(clientExtId: string): string {
        return `/${clientExtId}/jutalmazas/pontgyujtos/letrehozas`;
    }

    public static clientSelectionBoards(clientExtId: string): string {
        return `/${clientExtId}/lehetosegek-tara`;
    }

    public static createSelectionBoard(clientExtId: string): string {
        return `/${clientExtId}/lehetosegek-tara/letrehozas`;
    }

    public static editSelectionBoard(clientExtId: string, selectionBoardId: string): string {
        return `/${clientExtId}/lehetosegek-tara/${selectionBoardId}/szerkesztes`;
    }

    public static viewSelectionBoard(clientExtId: string, selectionBoardId: string): string {
        return `/${clientExtId}/lehetosegek-tara/${selectionBoardId}`;
    }

    public static clientGames(clientExtId: string): string {
        return `/${clientExtId}/jatekok`;
    }

    public static clientMemoryGames(clientExtId: string): string {
        return `/${clientExtId}/jatekok/memoria-jatek`;
    }

    public static clientSortingGames(clientExtId: string): string {
        return `/${clientExtId}/jatekok/sorbarendezo`;
    }

    public static clientEventLog(clientExtId: string): string {
        return `/${clientExtId}/esemeny-naplo`;
    }

    public static clientSettings(clientExtId: string): string {
        return `/${clientExtId}/beallitasok`;
    }

    public static clientAppSettings(clientExtId: string): string {
        return `/${clientExtId}/beallitasok/alkalmazas`;
    }

    public static clientMainPageSettings(clientExtId: string): string {
        return `/${clientExtId}/beallitasok/nyitokepernyo`;
    }

    public static clientContentsSettings(clientExtId: string): string {
        return `/${clientExtId}/beallitasok/tartalmi-elemek`;
    }

    public static clientFunctionsSettings(clientExtId: string): string {
        return `/${clientExtId}/beallitasok/funkciok`;
    }

    public static clientEverydaySituations(clientExtId: string): string {
        return `/${clientExtId}/mindennapi-helyzetek`;
    }

    public static clientEverydaySituationsClientDirectory(clientExtId: string): string {
        return `/${clientExtId}/mindennapi-helyzetek/felhasznalo-mappaja`;
    }

    public static clientEverydaySituationsDirectories(clientExtId: string): string {
        return `/${clientExtId}/mindennapi-helyzetek/mappak`;
    }

    public static createClientEverydaySituationsDirectory(clientExtId: string): string {
        return `/${clientExtId}/mindennapi-helyzetek/mappak/letrehozas`;
    }

    public static editClientEverydaySituationsDirectory(clientExtId: string, directoryId: string): string {
        return `/${clientExtId}/mindennapi-helyzetek/mappak/${directoryId}`;
    }

    public static readonly adminList = "/adminok";
    public static readonly supervisorList = "/supervizorok";
    public static readonly supporterList = "/tamogatok";
    public static readonly clientList = "/tamogatottak";

    public static educationModuleList: string = "/tudasbazis/tananyagok";
    public static educationSubModuleList(moduleId: string): string {
        return `/tudasbazis/tananyagok/${moduleId}`;
    }
    public static createEducationSubModule(moduleId: string): string {
        return `/tudasbazis/tananyagok/${moduleId}/almodulok/letrehozas`;
    }
    public static editEducationSubModule(moduleId: string, subModuleId: string): string {
        return `/tudasbazis/tananyagok/${moduleId}/almodulok/${subModuleId}/szerkesztes`;
    }

    public static faqList: string = "/tudasbazis/gyik";
    public static createFaq(): string {
        return "/tudasbazis/gyik/letrehozas";
    }

    public static editFaq(faqId: string): string {
        return `/tudasbazis/gyik/${faqId}/szerkesztes`;
    }

    public static readonly education: string = "/tudasbazis/tananyag";
    public static readonly educationGeneralTab: string = "/tudasbazis/tananyag/altalanos-informaciok";
    public static educationModuleTab(moduleId: string): string {
        return `/tudasbazis/tananyag/${moduleId}`;
    }

    public static readonly systemEventLog = "/rendszer-esemenynaplo";
    public static readonly appEventLog = "/rendszer-esemenynaplo/app";
    public static readonly cmsEventLog = "/rendszer-esemenynaplo/tm";

    public static readonly logout = "/kijelentkezes";

    public static adminDetail(accountId: string): string {
        return `${Path.adminList}/${accountId}`;
    }
    public static adminDetailProfile(accountId: string): string {
        return `${Path.adminList}/${accountId}/profil`;
    }
    public static adminDetailEventLog(accountId: string): string {
        return `${Path.adminList}/${accountId}/esemenynaplo`;
    }

    public static supervisorDetail(accountId: string): string {
        return `${Path.supervisorList}/${accountId}`;
    }

    public static supervisorDetailProfile(accountId: string): string {
        return `${Path.supervisorList}/${accountId}/profil`;
    }

    public static supervisorDetailContentLibrary(accountId: string): string {
        return `${Path.supervisorList}/${accountId}/tartalomtar`;
    }
    public static supervisorDetailEventLog(accountId: string): string {
        return `${Path.supervisorList}/${accountId}/esemenynaplo`;
    }

    public static supporterDetail(extId: string): string {
        return `${Path.supporterList}/${extId}`;
    }

    public static supporterDetailProfile(extId: string): string {
        return `${Path.supporterList}/${extId}/profil`;
    }
    public static supporterDetailContentLibrary(supporterExtId: string): string {
        return `${Path.supporterList}/${supporterExtId}/tartalomtar`;
    }
    public static supporterDetailEventLog(extId: string): string {
        return `${Path.supporterList}/${extId}/esemenynaplo`;
    }

    public static clientDetail(extId: string): string {
        return `${Path.clientList}/${extId}`;
    }

    public static clientDetailProfile(extId: string): string {
        return `${Path.clientList}/${extId}/profil`;
    }
    public static clientDetailContentLibrary(extId: string): string {
        return `${Path.clientList}/${extId}/tartalomtar`;
    }
    public static clientDetailEventLog(extId: string): string {
        return `${Path.clientList}/${extId}/esemenynaplo`;
    }

    public static emailActivation(data: string): string {
        return `/e-mail-csere/${data}`;
    }

    public static clientShareRequest(requestId: string): string {
        return `/felkeres-egyuttmukodesre/${requestId}`;
    }

    // Own/public content pages
    public static createAgenda(): string {
        return "/tartalomtar/napirend/letrehozas";
    }
    public static viewAgenda(agendaId: string): string {
        return `/tartalomtar/napirend/${agendaId}`;
    }
    public static editAgenda(agendaId: string): string {
        return `/tartalomtar/napirend/${agendaId}/szerkesztes`;
    }

    public static createAgendaItem(): string {
        return "/tartalomtar/napirendi-lepes/letrehozas";
    }
    public static viewAgendaItem(agendaItemId: string): string {
        return `/tartalomtar/napirendi-lepes/${agendaItemId}`;
    }
    public static editAgendaItem(agendaItemId: string): string {
        return `/tartalomtar/napirendi-lepes/${agendaItemId}/szerkesztes`;
    }

    public static viewAgendaAgendaItem(agendaId: string, agendaItemId: string): string {
        return `/tartalomtar/napirend/${agendaId}/napirendi-lepes/${agendaItemId}`;
    }
    public static editAgendaAgendaItem(agendaId: string, agendaItemId: string): string {
        return `/tartalomtar/napirend/${agendaId}/napirendi-lepes/${agendaItemId}/szerkesztes`;
    }

    public static createFlowchart(): string {
        return "/tartalomtar/folyamatabra/letrehozas";
    }
    public static viewFlowchart(flowchartId: string): string {
        return `/tartalomtar/folyamatabra/${flowchartId}`;
    }
    public static editFlowchart(flowchartId: string): string {
        return `/tartalomtar/folyamatabra/${flowchartId}/szerkesztes`;
    }

    public static createFlowchartItem(): string {
        return "/tartalomtar/folyamatabra-lepes/letrehozas";
    }
    public static viewFlowchartItem(flowchartItemId: string): string {
        return `/tartalomtar/folyamatabra-lepes/${flowchartItemId}`;
    }
    public static editFlowchartItem(flowchartItemId: string): string {
        return `/tartalomtar/folyamatabra-lepes/${flowchartItemId}/szerkesztes`;
    }

    public static replaceAssetItem(assetItemId: string): string {
        return `/tartalomtar/mediaelem-csere/${assetItemId}`;
    }

    // client's content pages
    public static createClientAgenda(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar/napirend/letrehozas`;
    }
    public static viewClientAgenda(clientExtId: string, agendaId: string): string {
        return `/${clientExtId}/tartalomtar/napirend/${agendaId}`;
    }
    public static editClientAgenda(clientExtId: string, agendaId: string): string {
        return `/${clientExtId}/tartalomtar/napirend/${agendaId}/szerkesztes`;
    }

    public static createClientAgendaItem(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar/napirendi-lepes/letrehozas`;
    }
    public static viewClientAgendaItem(clientExtId: string, agendaItemId: string): string {
        return `/${clientExtId}/tartalomtar/napirendi-lepes/${agendaItemId}`;
    }
    public static editClientAgendaItem(clientExtId: string, agendaItemId: string): string {
        return `/${clientExtId}/tartalomtar/napirendi-lepes/${agendaItemId}/szerkesztes`;
    }

    public static viewClientAgendaAgendaItem(clientExtId: string, agendaId: string, agendaItemId: string): string {
        return `/${clientExtId}/tartalomtar/napirend/${agendaId}/napirendi-lepes/${agendaItemId}`;
    }
    public static editClientAgendaAgendaItem(clientExtId: string, agendaId: string, agendaItemId: string): string {
        return `/${clientExtId}/tartalomtar/napirend/${agendaId}/napirendi-lepes/${agendaItemId}/szerkesztes`;
    }

    public static createClientFlowchart(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra/letrehozas`;
    }
    public static viewClientFlowchart(clientExtId: string, flowchartId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra/${flowchartId}`;
    }
    public static editClientFlowchart(clientExtId: string, flowchartId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra/${flowchartId}/szerkesztes`;
    }

    public static createClientFlowchartItem(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra-lepes/letrehozas`;
    }
    public static viewClientFlowchartItem(clientExtId: string, flowchartItemId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra-lepes/${flowchartItemId}`;
    }
    public static editClientFlowchartItem(clientExtId: string, flowchartItemId: string): string {
        return `/${clientExtId}/tartalomtar/folyamatabra-lepes/${flowchartItemId}/szerkesztes`;
    }

    public static viewClientEverydaySituations(clientExtId: string): string {
        return `/${clientExtId}/tartalomtar/mindennapi-helyzetek`;
    }

    public static viewClientSelectionBoard(clientExtId: string, selectionBoardId: string): string {
        return `/tamogatottak/${clientExtId}/tartalomtar/lehetosegek-tara/${selectionBoardId}`;
    }

    // supporterDetail content pages
    public static viewSupporterDetailAgenda(supporterId: string, agendaId: string): string {
        return `/tamogatok/${supporterId}/tartalomtar/napirend/${agendaId}`;
    }
    public static viewSupporterDetailAgendaItem(supporterId: string, agendaItemId: string): string {
        return `/tamogatok/${supporterId}/tartalomtar/napirendi-lepes/${agendaItemId}`;
    }
    public static viewSupporterDetailFlowchart(supporterId: string, flowchartId: string): string {
        return `/tamogatok/${supporterId}/tartalomtar/folyamatabra/${flowchartId}`;
    }
    public static viewSupporterDetailFlowchartItem(supporterId: string, flowchartItemId: string): string {
        return `/tamogatok/${supporterId}/tartalomtar/folyamatabra-lepes/${flowchartItemId}`;
    }

    public static viewSupporterDetailAsset(supporterExtId: string, assetId: string) {
        return `tamogatok/${supporterExtId}/tartalomtar/mediaelemek/${assetId}`;
    }

    // supervisorDetail content pages
    public static viewSupervisorDetailAgenda(supervisorId: string, agendaId: string): string {
        return `/supervizorok/${supervisorId}/tartalomtar/napirend/${agendaId}`;
    }
    public static viewSupervisorDetailAgendaItem(supervisorId: string, agendaItemId: string): string {
        return `/supervizorok/${supervisorId}/tartalomtar/napirendi-lepes/${agendaItemId}`;
    }
    public static viewSupervisorDetailFlowchart(supervisorId: string, flowchartId: string): string {
        return `/supervizorok/${supervisorId}/tartalomtar/folyamatabra/${flowchartId}`;
    }
    public static viewSupervisorDetailFlowchartItem(supervisorId: string, flowchartItemId: string): string {
        return `/supervizorok/${supervisorId}/tartalomtar/folyamatabra-lepes/${flowchartItemId}`;
    }

    public static viewSupervisorDetailAsset(supervisorId: string, assetId: string) {
        return `/supervizorok/${supervisorId}/tartalomtar/mediaelemek/${assetId}`;
    }

    // clientDetail content pages
    public static viewClientDetailAgenda(clientExtId: string, agendaId: string): string {
        return `/tamogatottak/${clientExtId}/tartalomtar/napirend/${agendaId}`;
    }
    public static viewClientDetailAgendaItem(clientExtId: string, agendaItemId: string): string {
        return `/tamogatottak/${clientExtId}/tartalomtar/napirendi-lepes/${agendaItemId}`;
    }
    public static viewClientDetailFlowchart(clientExtId: string, flowchartId: string): string {
        return `/tamogatottak/${clientExtId}/tartalomtar/folyamatabra/${flowchartId}`;
    }
    public static viewClientDetailFlowchartItem(clientExtId: string, flowchartItemId: string): string {
        return `/tamogatottak/${clientExtId}/tartalomtar/folyamatabra-lepes/${flowchartItemId}`;
    }
    public static viewClientEverydaySituationDirectory(clientExtId: string, directoryId: string): string {
        return `/tamogatottak/${clientExtId}/mindennapi-helyzetek/${directoryId}`;
    }

    public static changeClientManagerRequest(clientChangeManagerRequestId: string): string {
        return `/fotamogatoi-felkeres/${clientChangeManagerRequestId}`;
    }

    public static viewAsset(assetId: string): string {
        return `/tartalomtar/mediaelem/${assetId}`;
    }

    public static editAsset(assetId: string): string {
        return `/tartalomtar/mediaelem/${assetId}/szerkesztes`;
    }

    public static clientSpareAgendaItems(clientExtId: string): string {
        return `/${clientExtId}/tartalek-lepesek`;
    }

    public static tags: string = "/cimkek";

    public static createTag: string = "/cimkek/letrehozas";

    // Supervisor share request pages
    public static viewAgendaShareRequest(agendaId: string): string {
        return `/tartalomtar/felajanlasok/napirend/${agendaId}`;
    }
    public static viewAgendaItemShareRequest(agendaItemId: string): string {
        return `/tartalomtar/felajanlasok/napirendi-lepes/${agendaItemId}`;
    }
    public static viewFlowchartShareRequest(flowchartId: string): string {
        return `/tartalomtar/felajanlasok/folyamatabra/${flowchartId}`;
    }
    public static viewFlowchartItemShareRequest(flowchartItemId: string): string {
        return `/tartalomtar/felajanlasok/folyamatabra-lepes/${flowchartItemId}`;
    }
}

export class AppPath {
    public static readonly root: string = Path.root;
    public static readonly login: string = Path.login;
    public static readonly registration: string = Path.registration;
    public static readonly accountActivation: string = Path.accountActivation(":data");
    public static readonly forgotPassword: string = Path.forgotPassword;
    public static readonly dashboard: string = Path.dashboard;
    public static readonly profile: string = Path.profile;
    public static readonly resetPassword: string = Path.resetPassword(":data");
    public static readonly addClient: string = Path.addClient;
    public static readonly myEventLog: string = Path.myEventLog;
    public static readonly publicContentLibrary: string = Path.publicContentLibrary;
    public static readonly ownContentLibrary: string = Path.ownContentLibrary;
    public static readonly contentShareRequestList: string = Path.contentShareRequestList;
    public static readonly contentShareRequest: string = Path.contentShareRequest(":shareRequestId");

    public static readonly clientProfile: string = Path.clientProfile(":clientExtId");
    public static readonly clientQuestionnaire: string = Path.clientQuestionnaire(":clientExtId");
    public static readonly clientCalendar: string = Path.clientCalendar(":clientExtId");
    public static readonly clientContentLibrary: string = Path.clientContentLibrary(":clientExtId");
    public static readonly clientGames: string = Path.clientGames(":clientExtId");
    public static readonly clientMemoryGames: string = Path.clientMemoryGames(":clientExtId");
    public static readonly clientSortingGames: string = Path.clientSortingGames(":clientExtId");
    public static readonly clientEventLog: string = Path.clientEventLog(":clientExtId");

    public static readonly clientSettings: string = Path.clientSettings(":clientExtId");
    public static readonly clientAppSettings: string = Path.clientAppSettings(":clientExtId");
    public static readonly clientMainPageSettings: string = Path.clientMainPageSettings(":clientExtId");
    public static readonly clientContentsSettings: string = Path.clientContentsSettings(":clientExtId");
    public static readonly clientFunctionsSettings: string = Path.clientFunctionsSettings(":clientExtId");
    public static readonly clientEverydaySituations: string = Path.clientEverydaySituations(":clientExtId");
    public static readonly clientEverydaySituationsClientDirectory: string = Path.clientEverydaySituationsClientDirectory(":clientExtId");
    public static readonly clientEverydaySituationsDirectories: string = Path.clientEverydaySituationsDirectories(":clientExtId");
    public static readonly createClientEverydaySituationsDirectory: string = Path.createClientEverydaySituationsDirectory(":clientExtId");
    public static readonly editClientEverydaySituationsDirectory: string = Path.editClientEverydaySituationsDirectory(":clientExtId", ":directoryId");

    public static readonly clientAwardsBase: string = Path.clientAwardsBase(":clientExtId");

    public static readonly clientInstantAwards: string = Path.clientInstantAwards(":clientExtId");
    public static readonly createClientInstantAward: string = Path.createClientInstantAward(":clientExtId");
    public static readonly editClientInstantAward: string = Path.editClientInstantAward(":clientExtId", ":clientInstantAwardId");

    public static readonly clientAwards: string = Path.clientAwards(":clientExtId");
    public static readonly createClientAward: string = Path.createClientAward(":clientExtId");
    public static readonly editClientAward: string = Path.editClientAward(":clientExtId", ":clientAwardId");

    public static readonly adminList: string = Path.adminList;
    public static readonly supervisorList: string = Path.supervisorList;
    public static readonly supporterList: string = Path.supporterList;
    public static readonly clientList: string = Path.clientList;

    public static readonly educationModuleList: string = Path.educationModuleList;
    public static readonly educationSubModuleList: string = Path.educationSubModuleList(":moduleId");
    public static readonly createEducationSubModule: string = Path.createEducationSubModule(":moduleId");
    public static readonly editEducationSubModule: string = Path.editEducationSubModule(":moduleId", ":subModuleId");

    public static readonly faqList: string = Path.faqList;
    public static readonly createFaq: string = Path.createFaq();
    public static readonly editFaq: string = Path.editFaq(":faqId");
    public static readonly education: string = Path.education;
    public static readonly educationGeneralTab: string = Path.educationGeneralTab;
    public static readonly educationModuleTab: string = Path.educationModuleTab(":moduleId");

    public static readonly systemEventLog: string = Path.systemEventLog;
    public static readonly appEventLog: string = Path.appEventLog;
    public static readonly cmsEventLog: string = Path.cmsEventLog;

    public static readonly logout: string = Path.logout;
    public static readonly emailActivation: string = Path.emailActivation(":data");

    public static readonly adminDetail: string = Path.adminDetail(":accountId");
    public static readonly adminDetailProfile: string = Path.adminDetailProfile(":accountId");
    public static readonly adminDetailEventLog: string = Path.adminDetailEventLog(":accountId");

    public static readonly supervisorDetail: string = Path.supervisorDetail(":accountId");
    public static readonly supervisorDetailProfile: string = Path.supervisorDetailProfile(":accountId");
    public static readonly supervisorDetailContentLibrary: string = Path.supervisorDetailContentLibrary(":accountId");
    public static readonly supervisorDetailEventLog: string = Path.supervisorDetailEventLog(":accountId");

    public static readonly supporterDetail: string = Path.supporterDetail(":supporterExtId");
    public static readonly supporterDetailProfile: string = Path.supporterDetailProfile(":supporterExtId");
    public static readonly supporterDetailContentLibrary: string = Path.supporterDetailContentLibrary(":supporterExtId");
    public static readonly supporterDetailEventLog: string = Path.supporterDetailEventLog(":supporterExtId");

    public static readonly clientDetail: string = Path.clientDetail(":clientExtId");
    public static readonly clientDetailProfile: string = Path.clientDetailProfile(":clientExtId");
    public static readonly clientDetailContentLibrary: string = Path.clientDetailContentLibrary(":clientExtId");
    public static readonly clientSelectionBoards: string = Path.clientSelectionBoards(":clientExtId");
    public static readonly createSelectionBoard: string = Path.createSelectionBoard(":clientExtId");
    public static readonly viewSelectionBoard: string = Path.viewSelectionBoard(":clientExtId", ":selectionBoardId");
    public static readonly editSelectionBoard: string = Path.editSelectionBoard(":clientExtId", ":selectionBoardId");
    public static readonly clientDetailEventLog: string = Path.clientDetailEventLog(":clientExtId");
    public static readonly clientShareRequest: string = Path.clientShareRequest(":requestId");

    public static readonly createAgenda: string = Path.createAgenda();
    public static readonly viewAgenda: string = Path.viewAgenda(":agendaId");
    public static readonly editAgenda: string = Path.editAgenda(":agendaId");
    public static readonly createAgendaItem: string = Path.createAgendaItem();
    public static readonly viewAgendaItem: string = Path.viewAgendaItem(":agendaItemId");
    public static readonly editAgendaItem: string = Path.editAgendaItem(":agendaItemId");
    public static readonly viewAgendaAgendaItem: string = Path.viewAgendaAgendaItem(":agendaId", ":agendaItemId");
    public static readonly editAgendaAgendaItem: string = Path.editAgendaAgendaItem(":agendaId", ":agendaItemId");
    public static readonly createFlowchart: string = Path.createFlowchart();
    public static readonly viewFlowchart: string = Path.viewFlowchart(":flowchartId");
    public static readonly editFlowchart: string = Path.editFlowchart(":flowchartId");
    public static readonly createFlowchartItem: string = Path.createFlowchartItem();
    public static readonly viewFlowchartItem: string = Path.viewFlowchartItem(":flowchartItemId");
    public static readonly editFlowchartItem: string = Path.editFlowchartItem(":flowchartItemId");

    public static readonly replaceAssetItem: string = Path.replaceAssetItem(":assetItemId");

    public static readonly createClientAgenda: string = Path.createClientAgenda(":clientExtId");
    public static readonly viewClientAgenda: string = Path.viewClientAgenda(":clientExtId", ":agendaId");
    public static readonly editClientAgenda: string = Path.editClientAgenda(":clientExtId", ":agendaId");
    public static readonly createClientAgendaItem: string = Path.createClientAgendaItem(":clientExtId");
    public static readonly viewClientAgendaItem: string = Path.viewClientAgendaItem(":clientExtId", ":agendaItemId");
    public static readonly editClientAgendaItem: string = Path.editClientAgendaItem(":clientExtId", ":agendaItemId");
    public static readonly viewClientAgendaAgendaItem: string = Path.viewClientAgendaAgendaItem(":clientExtId", ":agendaId", ":agendaItemId");
    public static readonly editClientAgendaAgendaItem: string = Path.editClientAgendaAgendaItem(":clientExtId", ":agendaId", ":agendaItemId");
    public static readonly createClientFlowchart: string = Path.createClientFlowchart(":clientExtId");
    public static readonly viewClientFlowchart: string = Path.viewClientFlowchart(":clientExtId", ":flowchartId");
    public static readonly editClientFlowchart: string = Path.editClientFlowchart(":clientExtId", ":flowchartId");
    public static readonly createClientFlowchartItem: string = Path.createClientFlowchartItem(":clientExtId");
    public static readonly viewClientFlowchartItem: string = Path.viewClientFlowchartItem(":clientExtId", ":flowchartItemId");
    public static readonly editClientFlowchartItem: string = Path.editClientFlowchartItem(":clientExtId", ":flowchartItemId");
    public static readonly viewClientEverydaySituations: string = Path.viewClientEverydaySituations(":clientExtId");
    public static readonly viewClientSelectionBoard: string = Path.viewClientSelectionBoard(":clientExtId", ":selectionBoardId");

    public static readonly viewSupporterDetailAgenda: string = Path.viewSupporterDetailAgenda(":supporterExtId", ":agendaId");
    public static readonly viewSupporterDetailAgendaItem: string = Path.viewSupporterDetailAgendaItem(":supporterExtId", ":agendaItemId");
    public static readonly viewSupporterDetailFlowchart: string = Path.viewSupporterDetailFlowchart(":supporterExtId", ":flowchartId");
    public static readonly viewSupporterDetailFlowchartItem: string = Path.viewSupporterDetailFlowchartItem(":supporterExtId", ":flowchartItemId");
    public static readonly viewSupporterDetailAsset: string = Path.viewSupporterDetailAsset(":supporterExtId", ":assetId");

    public static readonly viewSupervisorDetailAgenda: string = Path.viewSupervisorDetailAgenda(":supervisorId", ":agendaId");
    public static readonly viewSupervisorDetailAgendaItem: string = Path.viewSupervisorDetailAgendaItem(":supervisorId", ":agendaItemId");
    public static readonly viewSupervisorDetailFlowchart: string = Path.viewSupervisorDetailFlowchart(":supervisorId", ":flowchartId");
    public static readonly viewSupervisorDetailFlowchartItem: string = Path.viewSupervisorDetailFlowchartItem(":supervisorId", ":flowchartItemId");
    public static readonly viewSupervisorDetailAsset: string = Path.viewSupervisorDetailAsset(":supervisorId", ":assetId");

    public static readonly viewClientDetailAgenda: string = Path.viewClientDetailAgenda(":clientExtId", ":agendaId");
    public static readonly viewClientDetailAgendaItem: string = Path.viewClientDetailAgendaItem(":clientExtId", ":agendaItemId");
    public static readonly viewClientDetailFlowchart: string = Path.viewClientDetailFlowchart(":clientExtId", ":flowchartId");
    public static readonly viewClientDetailFlowchartItem: string = Path.viewClientDetailFlowchartItem(":clientExtId", ":flowchartItemId");
    public static readonly viewClientEverydaySituationDirectory: string = Path.viewClientEverydaySituationDirectory(":clientExtId", ":directoryId");

    public static readonly changeClientManagerRequest: string = Path.changeClientManagerRequest(":clientChangeManagerRequestId");

    public static readonly viewAsset: string = Path.viewAsset(":assetId");
    public static readonly editAsset: string = Path.editAsset(":assetId");

    public static readonly clientSpareAgendaItems: string = Path.clientSpareAgendaItems(":clientExtId");

    public static readonly tags: string = Path.tags;
    public static readonly createTag: string = Path.createTag;

    public static readonly viewAgendaShareRequest: string = Path.viewAgendaShareRequest(":agendaId");
    public static readonly viewAgendaItemShareRequest: string = Path.viewAgendaItemShareRequest(":agendaItemId");
    public static readonly viewFlowchartShareRequest: string = Path.viewFlowchartShareRequest(":flowchartId");
    public static readonly viewFlowchartItemShareRequest: string = Path.viewFlowchartItemShareRequest(":flowchartItemId");

    public static readonly sandbox: string = "/sandbox";
}

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";

export enum AccountStatus {
    active = "active",
    inactive = "inactive",
    disabled = "disabled",
}

export class AccountStatusSelectOptions {
    private static readonly activatedAccountStatuses: AccountStatus[] = [AccountStatus.active, AccountStatus.disabled];
    private static readonly notActivatedAccountStatuses: AccountStatus[] = [AccountStatus.inactive, AccountStatus.disabled];

    public static readonly get = (isAccountActivated: boolean): Array<SelectOption<AccountStatus>> => {
        const accountStatusOptions: AccountStatus[] = isAccountActivated ? AccountStatusSelectOptions.activatedAccountStatuses : AccountStatusSelectOptions.notActivatedAccountStatuses;
        return accountStatusOptions.map(
            (accountStatus: AccountStatus): SelectOption<AccountStatus> => {
                return { id: accountStatus, label: Intl.formatMessage({ id: `enum.accountStatus.${accountStatus}` }), value: accountStatus };
            },
        );
    };
}

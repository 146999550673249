import { Cookie } from "utils/Cookie";

export enum AuthActionType {
    logout = "@auth/logout",
    updateAuthData = "@auth/updateAuthData",
    updateAuthToken = "@auth/updateAuthToken",
}

interface LogoutAction {
    type: AuthActionType.logout;
}

interface UpdateAuthData {
    type: AuthActionType.updateAuthData;
    authToken: string;
    refreshToken: string;
}

interface UpdateAuthToken {
    type: AuthActionType.updateAuthToken;
    authToken: string;
}

export class AuthActions {
    public static logout(): LogoutAction {
        Cookie.clearRefreshToken();
        Cookie.clearClientClosedTodoNotifications();
        return { type: AuthActionType.logout };
    }

    public static updateAuthData(authToken: string, refreshToken: string, isStayLoggedIn: boolean): UpdateAuthData {
        Cookie.setAuthInfo(refreshToken, isStayLoggedIn);
        return { type: AuthActionType.updateAuthData, authToken, refreshToken };
    }

    public static updateAuthToken(authToken: string): UpdateAuthToken {
        return { type: AuthActionType.updateAuthToken, authToken };
    }
}

export type AuthAction = LogoutAction | UpdateAuthData | UpdateAuthToken;

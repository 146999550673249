export class AsyncUtils {
    public static forEach<T>(array: T[], callback: (item: T, index: number, array: T[]) => Promise<void>): Promise<void> {
        return new Promise(async (resolve: () => void, reject: (error: Error) => void) => {
            for (let i = 0; i < array.length; i++) {
                try {
                    await callback(array[i], i, array);
                } catch (error) {
                    reject(error);
                    return;
                }
            }
            resolve();
        });
    }

    public static map<T, R>(array: T[], callback: (item: T, index: number, array: T[]) => Promise<R>): Promise<R[]> {
        return new Promise(async (resolve: (result: R[]) => void, reject: (error: Error) => void) => {
            const result: R[] = [];
            for (let i = 0; i < array.length; i++) {
                try {
                    result.push(await callback(array[i], i, array));
                } catch (error) {
                    reject(error);
                    return;
                }
            }
            resolve(result);
        });
    }

    public static find<T>(array: T[], callback: (item: T, index: number, array: T[]) => Promise<boolean>): Promise<T | undefined> {
        return new Promise(async (resolve: (value: T | undefined) => void, reject: (error: Error) => void) => {
            for (let i = 0; i < array.length; i++) {
                try {
                    if (await callback(array[i], i, array)) {
                        resolve(array[i]);
                        return;
                    }
                } catch (error) {
                    reject(error);
                    return;
                }
            }
            resolve(undefined);
        });
    }
}

// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function PdfIcon({ width, height }: IconProps): React.ReactElement {
    return (
        <svg viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <g clipPath="url(#pdfclip0)">
                <path d="M40 8.39001H38.11V47.77H40V8.39001Z" fill="#444444" />
                <path d="M3.53001 21.92H1.64001V47.82H3.53001V21.92Z" fill="#444444" />
                <path d="M1.63 45.93L1.63 47.82H39.74V45.93H1.63Z" fill="#444444" />
                <path d="M1.64001 1.33514e-05L1.64001 1.89001L31.45 1.89001V1.33514e-05L1.64001 1.33514e-05Z" fill="#444444" />
                <path d="M31.702 0.00194754L30.3655 1.33838L38.6599 9.63274L39.9963 8.29631L31.702 0.00194754Z" fill="#444444" />
                <path d="M31.7 0H29.81V10.19H31.7V0Z" fill="#444444" />
                <path d="M3.53001 0H1.64001V7.58H3.53001V0Z" fill="#444444" />
                <path d="M29.81 8.3V10.19H40V8.3H29.81Z" fill="#444444" />
                <path d="M28.77 8.46997H0V21.18H28.77V8.46997Z" fill="#4ec1f9" />
                <path
                    d="M9.36 13.68C9.38081 14.0024 9.32868 14.3254 9.20749 14.6249C9.08631 14.9244 8.89917 15.1928 8.66 15.41C8.08669 15.843 7.377 16.0559 6.66 16.01H6.02V18.48H4.53V11.48H6.77C7.45793 11.4313 8.14109 11.626 8.7 12.03C8.92973 12.2356 9.10884 12.4914 9.22333 12.7777C9.33782 13.0639 9.38459 13.3727 9.36 13.68ZM6.01 14.78H6.5C6.85367 14.7997 7.20429 14.705 7.5 14.51C7.61187 14.4119 7.70045 14.2901 7.75927 14.1534C7.81809 14.0168 7.84567 13.8687 7.84 13.72C7.84904 13.5784 7.82786 13.4366 7.77786 13.3038C7.72786 13.171 7.65019 13.0504 7.55 12.95C7.28996 12.7696 6.97548 12.6848 6.66 12.71H5.98L6.01 14.78Z"
                    fill="white"
                />
                <path
                    d="M16.49 14.92C16.5203 15.4073 16.4469 15.8955 16.2745 16.3524C16.1021 16.8092 15.8347 17.2242 15.49 17.57C14.696 18.2281 13.6786 18.5541 12.65 18.48H10.65V11.48H12.85C13.8203 11.4199 14.7755 11.7431 15.51 12.38C15.8415 12.7116 16.1 13.1089 16.2688 13.5463C16.4376 13.9838 16.5129 14.4517 16.49 14.92ZM14.95 14.92C14.95 13.42 14.28 12.67 12.95 12.67H12.16V17.22H12.79C14.23 17.26 14.96 16.49 14.96 14.96L14.95 14.92Z"
                    fill="white"
                />
                <path d="M19.39 18.48H17.96V11.48H21.96V12.68H19.39V14.48H21.77V15.68H19.39V18.48Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5 31.99H24.14V42.23H28.7901L20.8201 51.51L12.8501 42.23H17.5V31.99Z" fill="#4ec1f9" />
            </g>
            <defs>
                <clipPath id="pdfclip0">
                    <rect width="40" height="51.51" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export { PdfIcon };

import React from "react";
import { SuccessIcon } from "components/Icons/SuccessIcon";
import { IntlHelpers } from "i18n/IntlHelpers";

interface Props {
    email: string;
}

class RegistrationSucceedComponent extends React.Component<Props> {
    public render(): React.ReactElement<any> {
        return (
            <form>
                <div className="lead">{IntlHelpers.asHtml({ id: "page.auth.registrationSucceed.message" }, { email: this.props.email })}</div>
                <div className="icon-wrapper" aria-hidden={true}>
                    <SuccessIcon width={190} height={190} />
                </div>
            </form>
        );
    }
}

export { RegistrationSucceedComponent as RegistrationSucceed };

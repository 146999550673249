import * as React from "react";

function SvgBook(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={19} fill="none" {...props}>
            <path
                d="M19.455 4.602a1.56 1.56 0 00-.691-.504c.012.21.012.445-.059.668L15.19 16.332c-.13.422-.633.668-1.067.668H3.307c-.645 0-1.453-.14-1.688-.82-.093-.258-.082-.364.012-.504.105-.14.293-.176.445-.176H12.26c1.488 0 1.793-.398 2.344-2.203l3.21-10.617c.165-.551.094-1.102-.21-1.524C17.31.746 16.819.5 16.268.5H7.35c-.2 0-.399.059-.598.105L6.764.57C5.276.148 5.182 1.754 4.69 2.48c-.188.27-.47.504-.516.727-.035.211.082.41.059.61-.07.62-.54 1.734-.82 2.12-.177.235-.434.352-.516.622-.07.187.047.457.023.703-.059.55-.469 1.593-.785 2.133-.117.21-.34.375-.399.597-.058.2.06.457 0 .68-.128.61-.527 1.558-.878 2.144-.188.317-.434.516-.48.797-.024.14.081.293.07.48-.012.282-.06.516-.083.75-.164.446-.164.962.024 1.489.433 1.219 1.71 2.168 2.906 2.168h10.817c1.007 0 2.039-.773 2.332-1.77l3.222-10.617c.164-.539.082-1.09-.21-1.511zm-12.469.023l.247-.75c.07-.21.293-.375.492-.375h7.125c.21 0 .328.164.258.375l-.247.75c-.07.21-.293.375-.492.375H7.244c-.21 0-.328-.164-.258-.375zm-.972 3l.246-.75c.07-.21.293-.375.492-.375h7.125c.211 0 .328.164.258.375l-.246.75c-.07.21-.293.375-.492.375H6.272c-.211 0-.328-.164-.258-.375z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgBook;

import * as React from "react";

function SvgSupporterCollaboratorNew(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm3.598-16.969c0 1.99-1.61 3.6-3.6 3.6a3.599 3.599 0 01-3.6-3.6c0-1.989 1.611-3.6 3.6-3.6 1.99 0 3.6 1.611 3.6 3.6zm-10.8 9c0-2.394 4.797-3.6 7.2-3.6 2.403 0 7.2 1.206 7.2 3.6v1.8h-14.4v-1.8z"
                fill="currentColor"
            />
            <mask id="supporter-collaborator-new_svg__a" maskUnits="userSpaceOnUse" x={13} y={11} width={10} height={10} fill="#000">
                <path fill="#fff" d="M13 11h10v10H13z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.132 13.132c.755-.755 1.67-1.132 2.749-1.132 1.078 0 1.994.377 2.749 1.132.754.755 1.132 1.67 1.132 2.749 0 1.078-.378 1.994-1.132 2.749-.755.754-1.671 1.132-2.75 1.132-1.077 0-1.993-.378-2.748-1.132-.755-.755-1.132-1.671-1.132-2.75 0-1.077.377-1.994 1.132-2.748zm4.474 3.084v-.324c0-.18-.09-.269-.27-.269h-1.024v-1.455c0-.18-.09-.27-.27-.27h-.323c-.18 0-.27.09-.27.27v2.048c0 .18.09.27.27.27h1.617c.18 0 .27-.09.27-.27z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.132 13.132c.755-.755 1.67-1.132 2.749-1.132 1.078 0 1.994.377 2.749 1.132.754.755 1.132 1.67 1.132 2.749 0 1.078-.378 1.994-1.132 2.749-.755.754-1.671 1.132-2.75 1.132-1.077 0-1.993-.378-2.748-1.132-.755-.755-1.132-1.671-1.132-2.75 0-1.077.377-1.994 1.132-2.748zm4.474 3.084v-.324c0-.18-.09-.269-.27-.269h-1.024v-1.455c0-.18-.09-.27-.27-.27h-.323c-.18 0-.27.09-.27.27v2.048c0 .18.09.27.27.27h1.617c.18 0 .27-.09.27-.27z"
                fill="#fff"
            />
            <path
                d="M15.132 13.132l.707.707-.707-.707zm5.498 0l.707-.707-.707.707zm0 5.498l.707.707-.707-.707zm-5.498 0l-.707.707.707-.707zm3.18-3.007h-1v1h1v-1zM17.881 11c-1.34 0-2.512.48-3.456 1.425l1.414 1.414c.565-.565 1.226-.839 2.042-.839v-2zm3.456 1.425C20.392 11.48 19.22 11 17.88 11v2c.816 0 1.477.274 2.041.839l1.415-1.414zm1.425 3.456c0-1.34-.481-2.512-1.425-3.456l-1.415 1.414c.565.565.84 1.226.84 2.042h2zm-1.425 3.456c.944-.945 1.425-2.116 1.425-3.456h-2c0 .816-.275 1.477-.84 2.041l1.415 1.415zm-3.456 1.425c1.34 0 2.511-.481 3.456-1.425l-1.415-1.415c-.564.565-1.226.84-2.041.84v2zm-3.456-1.425c.944.944 2.116 1.425 3.456 1.425v-2c-.816 0-1.477-.275-2.042-.84l-1.414 1.415zM13 15.88c0 1.34.48 2.511 1.425 3.456l1.414-1.415c-.565-.564-.839-1.226-.839-2.041h-2zm1.425-3.456C13.48 13.369 13 14.54 13 15.88h2c0-.816.274-1.477.839-2.042l-1.414-1.414zm4.18 3.467v.324h2v-.324h-2zm.731.731a.737.737 0 01-.505-.226.737.737 0 01-.225-.504h2c0-.245-.062-.611-.36-.91a1.27 1.27 0 00-.91-.36v2zm-1.024 0h1.024v-2h-1.024v2zm-1-2.455v1.455h2v-1.455h-2zm.73.73a.736.736 0 01-.504-.225.736.736 0 01-.226-.505h2c0-.244-.062-.61-.36-.91a1.27 1.27 0 00-.91-.36v2zm-.323 0h.324v-2h-.324v2zm.73-.73a.736.736 0 01-.225.505.736.736 0 01-.505.225v-2c-.244 0-.61.062-.909.36-.299.3-.36.666-.36.91h2zm0 2.048v-2.048h-2v2.048h2zm-.73-.73c.065 0 .296.016.505.225.209.209.226.44.226.505h-2c0 .244.061.61.36.91.299.298.665.36.91.36v-2zm1.617 0H17.72v2h1.617v-2zm-.73.73c0-.064.016-.296.225-.505a.736.736 0 01.505-.226v2c.244 0 .61-.061.91-.36.298-.299.36-.665.36-.91h-2z"
                fill="currentColor"
                mask="url(#supporter-collaborator-new_svg__a)"
            />
        </svg>
    );
}

export default SvgSupporterCollaboratorNew;

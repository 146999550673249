import { AssetListItem, SearchListType } from "api/graphql/types";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { PersonalAssetDirectory, PersonalAssetChildrenDirectory, DirectoryList } from "models/ContentLibraryDirectories";
import { DirectoryOptionType } from "components/DialogContainer/CreateAssetDirectoryDialog";

class DirectoryUtils {
    private static urlQueryParser = new ContentLibraryUrlQueryParser();

    public static readonly processDirectories = (
        directories: PersonalAssetChildrenDirectory[] | PersonalAssetDirectory[],
        path: string,
        isPublic: boolean = false,
        flattenChildren: boolean = false,
    ): DirectoryList[] => {
        const directoryList: DirectoryList[] = [];

        const getBasicData = (directory: PersonalAssetChildrenDirectory | PersonalAssetDirectory) => {
            return {
                id: directory!.id,
                name: directory!.name,
                toPage: `${path}?${DirectoryUtils.urlQueryParser.getUrlQuery({
                    searchListType: SearchListType.asset,
                    directory: directory!.id,
                    isPublic,
                })}`,
            };
        };

        if (flattenChildren) {
            const pushDirectoryWithFlattenedChildren = (directory: PersonalAssetChildrenDirectory | PersonalAssetDirectory) => {
                if (directory === null) return;
                directoryList.push({ ...getBasicData(directory!) });

                if ("children" in directory && directory.children) {
                    directory.children.forEach(child => pushDirectoryWithFlattenedChildren(child));
                }
            };
            directories.forEach((directory: PersonalAssetChildrenDirectory | PersonalAssetDirectory) => pushDirectoryWithFlattenedChildren(directory));
        } else {
            directories.forEach((directory: PersonalAssetChildrenDirectory | PersonalAssetDirectory) => {
                if (directory !== null) {
                    directoryList.push({
                        ...getBasicData(directory),
                        children: "children" in directory && directory.children ? DirectoryUtils.processDirectories(directory.children, path, isPublic) : null,
                    });
                }
            });
        }
        return directoryList;
    };

    public static readonly getDirectoryById = (directories: DirectoryList[], directoryId: string): DirectoryList | null => {
        let finalDirectory: DirectoryList | null = null;
        directories.forEach((directory: DirectoryList) => {
            if (directory.id === directoryId) {
                finalDirectory = directory;
                return;
            }
            if (directory.children) {
                finalDirectory = DirectoryUtils.getDirectoryById(directory.children, directoryId);
            }
        });
        return finalDirectory;
    };

    public static readonly getDirectoriesSelectValue = (directories: DirectoryList[]): DirectoryOptionType[] => {
        return directories.map((directory: DirectoryList) => {
            return {
                id: directory.id,
                value: directory.id,
                label: directory.name,
            };
        });
    };

    public static readonly findFileById = (assets: AssetListItem[], id: string): AssetListItem | null => {
        const asset = assets.find(asset => asset.id === id);
        return asset ? asset : null;
    };
}

export { DirectoryUtils };

import React, { Component } from "react";
import { MapStateToProps, connect } from "react-redux";
import { SupportedClient, AgendaItemContent } from "api/graphql/types";
import { ApplicationState } from "reducers";
import { AccountSelectors } from "selectors/AccountSelectors";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Path } from "utils/Path";
import { isNil } from "lodash";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ClientSpareAgendaItemsForm } from "./ClientSpareAgendaItemsForm";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ReduxProps & ComponentProps;

interface State {
    agendaItems: AgendaItemContent[];
    isLoading: boolean;
}

class ClientSpareAgendaItemsPageComponent extends Component<Props, State> {
    public readonly state: State = {
        agendaItems: [],
        isLoading: true,
    };

    public componentDidMount(): void {
        this.refreshClientSpareAgendaItems(this.props.client!.id);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshClientSpareAgendaItems(nextProps.client.id);
        }
    }

    private refreshClientSpareAgendaItems = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const agendaItems = await Api.getClientSpareAgendaItems(clientId);
                    this.setState({
                        agendaItems,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    private onSubmit = async (agendaItems: AgendaItemContent[]): Promise<AgendaItemContent[]> => {
        const agendaItemIds: string[] = agendaItems.map((agendaItem: AgendaItemContent) => {
            return agendaItem.id;
        });
        const result = await Api.updateClientSpareAgendaItems(this.props.client!.id, agendaItemIds);
        this.setState({ agendaItems: result });
        return result;
    };

    public render(): React.ReactElement {
        if (isNil(this.props.client)) {
            return <Redirect to={Path.dashboard} />;
        }

        return <ClientSpareAgendaItemsForm isLoading={this.state.isLoading} agendaItems={this.state.agendaItems} client={this.props.client} onSubmit={this.onSubmit} />;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return { client };
};

export const ClientSpareAgendaItemsPage = withRouter(connect(mapStateToProps)(ClientSpareAgendaItemsPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientSpareAgendaItemsPage;

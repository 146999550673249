import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import {
    EverydaySituationDirectoryWithItemCount,
    CreateEverydaySituationInput,
    EverydaySituationClientDirectory_situations as EverydaySituation,
    TextEverydaySituation,
    Client,
} from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";
import { ApiTypes } from "api/ApiTypes";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Path } from "utils/Path";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Log } from "utils/Log";

export interface CopyEverydaySituationDialogProps {
    client: Client;
    everydaySituation: EverydaySituation;
    onCopySucceed: () => void;
}

type ComponentProps = CopyEverydaySituationDialogProps & DialogVisibilityProps;

type Props = ComponentProps & RouteComponentProps;

interface State {
    everydaySituationDirectories: EverydaySituationDirectoryWithItemCount[];
    selectedDirectory: SelectOption<EverydaySituationDirectoryWithItemCount> | null;
    isLoading: boolean;
}

class CopyEverydaySituationDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        everydaySituationDirectories: [],
        selectedDirectory: null,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const everydaySituationDirectories = await Api.getEverydaySituationDirectoriesByClientId(this.props.client.id);
            this.setState({
                everydaySituationDirectories,
                selectedDirectory:
                    everydaySituationDirectories.length > 0
                        ? {
                              id: everydaySituationDirectories[0].id,
                              label: everydaySituationDirectories[0].title,
                              value: everydaySituationDirectories[0],
                          }
                        : null,
                isLoading: false,
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    }

    private readonly getInputFromSituation = (everydaySituation: EverydaySituation): CreateEverydaySituationInput => {
        return {
            title: everydaySituation.title,
            situationType: ApiTypes.getEverydaySituationType(everydaySituation),
            assetId: this.getEverydaySituationAssetIdByType(everydaySituation),
            directoryId: this.state.selectedDirectory?.id || "",
            description: (everydaySituation as TextEverydaySituation).description || undefined,
        };
    };

    private getEverydaySituationAssetIdByType(everydaySituation: EverydaySituation): string | null {
        switch (everydaySituation.__typename) {
            case "TextEverydaySituation":
                return null;
            case "AudioEverydaySituation":
                return everydaySituation.audio.id;
            case "ImageEverydaySituation":
            case "TextAndImageEverydaySituation":
                return everydaySituation.image.id;
            default:
                Log.warning("Unhandled typename for ", everydaySituation, " falling back to text");
                return null;
        }
    }

    private copyToDirectory = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.createEverydaySituation(this.getInputFromSituation(this.props.everydaySituation));
                    this.props.onCopySucceed();
                    Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.copySucceed" }) });
                    this.setState({ isLoading: false }, () => {
                        this.props.onHide();
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private addClick = (): void => {
        this.props.history.push(Path.createClientEverydaySituationsDirectory(this.props.client.extId));
        this.props.onHide();
    };

    private getOptions = () => {
        return this.state.everydaySituationDirectories.map(
            (everydaySituationDirectory: EverydaySituationDirectoryWithItemCount): SelectOption<EverydaySituationDirectoryWithItemCount> => {
                return {
                    id: everydaySituationDirectory.id,
                    label: everydaySituationDirectory.title,
                    value: everydaySituationDirectory,
                    disabled: !!everydaySituationDirectory.disabledAt,
                };
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <form>
                    {this.state.everydaySituationDirectories.length === 0 && !this.state.isLoading ? (
                        <ErrorMessage message={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.form.warning" })} />
                    ) : (
                        <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.form.directory.label" })}>
                            <Select
                                options={this.getOptions()}
                                onChange={(selectedDirectory: SelectOption<EverydaySituationDirectoryWithItemCount>): void => {
                                    this.setState({ selectedDirectory });
                                }}
                                emptyLabel={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.form.directory.emptyLabel" })}
                                value={this.state.selectedDirectory}
                            />
                        </InputWrapper>
                    )}
                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            {this.state.everydaySituationDirectories.length === 0 ? (
                                <Button label={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.add" })} onClick={this.addClick} />
                            ) : (
                                <Button
                                    label={Intl.formatMessage({ id: "sharedComponent.copyEverydaySituationDialog.copy" })}
                                    onClick={this.copyToDirectory}
                                    disabled={this.state.isLoading || this.state.selectedDirectory === null}
                                />
                            )}
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const CopyEverydaySituationDialog: React.ComponentClass<ComponentProps> = withRouter(CopyEverydaySituationDialogComponent);

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { EverydaySituationDirectoryWithItemCount, CreateEverydaySituationInput, UpdateEverydaySituationInput } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";
import { isNil } from "lodash";

export interface MoveEverydaySituationDialogProps {
    clientId: string;
    everydaySituation: CreateEverydaySituationInput | UpdateEverydaySituationInput;
    onMoveSucceed: () => void;
}

type Props = MoveEverydaySituationDialogProps & DialogVisibilityProps;

interface State {
    everydaySituationDirectories: EverydaySituationDirectoryWithItemCount[];
    selectedDirectory: SelectOption<EverydaySituationDirectoryWithItemCount> | null;
    isLoading: boolean;
}

class MoveEverydaySituationDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        everydaySituationDirectories: [],
        selectedDirectory: null,
        isLoading: true,
    };

    public async componentDidMount(): Promise<void> {
        try {
            const directories = await Api.getEverydaySituationDirectoriesByClientId(this.props.clientId);
            const everydaySituationDirectories = directories.filter(d => {
                return d.id !== this.props.everydaySituation.directoryId;
            });
            this.setState({
                everydaySituationDirectories,
                selectedDirectory:
                    everydaySituationDirectories.length > 0
                        ? {
                              id: everydaySituationDirectories[0].id,
                              label: everydaySituationDirectories[0].title,
                              value: everydaySituationDirectories[0],
                          }
                        : null,
                isLoading: false,
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    }

    private static isUpdateEverydaySituation(everydaySituationInput: CreateEverydaySituationInput | UpdateEverydaySituationInput): everydaySituationInput is UpdateEverydaySituationInput {
        return !isNil((everydaySituationInput as UpdateEverydaySituationInput).id);
    }

    private moveToDirectory = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (MoveEverydaySituationDialogComponent.isUpdateEverydaySituation(this.props.everydaySituation)) {
                        await Api.updateEverydaySituation({ id: this.props.everydaySituation.id, directoryId: this.state.selectedDirectory!.value.id });
                    } else {
                        await Api.createEverydaySituation(this.props.everydaySituation);
                    }
                    this.props.onMoveSucceed();
                    Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.moveEverydaySituationDialog.moveSucceed" }) });
                    this.setState({ isLoading: false }, () => {
                        this.props.onHide();
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private getOptions = () => {
        return this.state.everydaySituationDirectories.map(
            (everydaySituationDirectory: EverydaySituationDirectoryWithItemCount): SelectOption<EverydaySituationDirectoryWithItemCount> => {
                return {
                    id: everydaySituationDirectory.id,
                    label: everydaySituationDirectory.title,
                    value: everydaySituationDirectory,
                    disabled: !!everydaySituationDirectory.disabledAt,
                };
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "sharedComponent.moveEverydaySituationDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <form>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.moveEverydaySituationDialog.form.directory.label" })}>
                        <Select
                            options={this.getOptions()}
                            onChange={(selectedDirectory: SelectOption<EverydaySituationDirectoryWithItemCount>): void => {
                                this.setState({ selectedDirectory });
                            }}
                            emptyLabel={Intl.formatMessage({ id: "sharedComponent.moveEverydaySituationDialog.form.directory.emptyLabel" })}
                            value={this.state.selectedDirectory}
                        />
                    </InputWrapper>

                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: "sharedComponent.moveEverydaySituationDialog.move" })}
                                onClick={this.moveToDirectory}
                                disabled={this.state.isLoading || this.state.selectedDirectory === null}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const MoveEverydaySituationDialog: React.ComponentClass<Props> = MoveEverydaySituationDialogComponent;

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { SortOrder, ListControl, AppEventLogListSortField, AppEventLog, AppEventLogName, AccountType, Aggregations } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { DateInput, PopperPlacement } from "components/Inputs/Date/DateInput";
import { EventLogUrlQueryParser, EventLogQueryParameter } from "pages/_shared/EventLogUrlQueryParser";
import { Button } from "components/Button/Button";
import { Env } from "utils/Env";
import { Select } from "components/Inputs/Select/Select";
import { cloneDeep, isNil } from "lodash";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { Loading, LoadingType } from "components/Loading/Loading";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Cell, Tooltip } from "recharts";
import { ChartRow, ChartUtils } from "utils/ChartUtils";
import { Section } from "components/Section";
import { AppEventLogNameOption } from "models/AppEventLogNameOptions";
import { Formatter } from "utils/Formatter";
import { SettingsSelectors } from "selectors/SettingsSelectors";
import { startOfDay } from "date-fns";
import { TextSelect } from "components/Inputs/TextSelect/TextSelect";
import { TimeSelect } from "components/Inputs/TimeSelect/TimeSelect";

import "./AccountEventLogTable.scss";

interface EventLogPageOptions {
    sortField?: AppEventLogListSortField | null;
    control: ListControl;
    dateTimeFrom: Date;
    dateTimeTo: Date;
    appEventLogName: AppEventLogName | null;
}

interface State {
    appEventLog: AppEventLog[];
    aggregations: Aggregations | null;
    count: number;
    isLoading: boolean;
    options: EventLogPageOptions;
    viewType: ViewType;
    appEventLogNameSearch: string;
    chartDateTimeFrom: Date; // To prevent buggy rerendering of charts
    chartDateTimeTo: Date; // To prevent buggy rerendering of charts

    fromTime: string;
    toTime: string;
}

enum AccountEventLogTableColumn {
    account = "account",
    client = "client",
    event = "event",
    title = "title",
    timestamp = "timestamp",
}

enum ViewType {
    list = "list",
    chart = "chart",
}

interface ReduxProps {
    appEventLogNameOptions: AppEventLogNameOption[];
}

interface RefreshParams {
    authAccountExtId?: string;
    authAccountId?: string;
}

interface ComponentProps extends RouteComponentProps {
    viewedAccountType: AccountType;
    authAccountId?: string; // Supervisor, admin
    authAccountExtId?: string; // Supporter
}

type Props = ComponentProps & ReduxProps;

class AccountEventLogTableComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): EventLogPageOptions {
        const { sortField, sortOrder, search, limit, page, dateTimeFrom, dateTimeTo, appEventLogName } = new EventLogUrlQueryParser<AppEventLogListSortField>(
            AppEventLogListSortField,
            "page.systemEventLog.table",
        ).parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
            dateTimeFrom: dateTimeFrom || startOfDay(new Date()),
            dateTimeTo: dateTimeTo || new Date(),
            appEventLogName: appEventLogName || null,
        };
    }

    public constructor(props: Props) {
        super(props);

        const options = AccountEventLogTableComponent.getInitialOptions(this.props);
        this.state = {
            appEventLog: [],
            aggregations: null,
            isLoading: true,
            count: 0,
            options,
            viewType: ViewType.list,
            appEventLogNameSearch: Select.getSelectOption(this.props.appEventLogNameOptions, options.appEventLogName).label,
            chartDateTimeFrom: options.dateTimeFrom,
            chartDateTimeTo: options.dateTimeTo,
            fromTime: DateUtils.toTimeFormat(options.dateTimeFrom),
            toTime: DateUtils.toTimeFormat(options.dateTimeTo),
        };
    }

    public componentDidMount(): void {
        this.refreshEventLog(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.location.search, nextProps.location.search)) {
            this.setState({ options: AccountEventLogTableComponent.getInitialOptions(nextProps) }, () => {
                this.refreshEventLog(this.state.options);
            });
        }
    }

    private getRefreshParams = (): RefreshParams => {
        switch (this.props.viewedAccountType) {
            case AccountType.supporter:
                return { authAccountExtId: this.props.authAccountExtId };
            case AccountType.supervisor:
            case AccountType.superadmin:
            case AccountType.admin:
                return { authAccountId: this.props.authAccountId };
            default:
                return {};
        }
    };

    private refreshEventLog = (options: EventLogPageOptions): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, aggregations, count } = await Api.getAppEventLog(
                        {
                            dateTimeFrom: options.dateTimeFrom,
                            dateTimeTo: options.dateTimeTo,
                            appEventLogName: options.appEventLogName || undefined,
                            ...this.getRefreshParams(),
                        },
                        {
                            control: options.control,
                            sortField: options.sortField,
                        },
                    );
                    this.setState({ appEventLog: result, aggregations, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ appEventLog: [], count: 0, isLoading: false });
                }
            },
        );
    };

    private getColumnTitle = (columnName: AccountEventLogTableColumn): string => {
        switch (columnName) {
            case AccountEventLogTableColumn.account:
                return Intl.formatMessage({ id: `sharedComponent.accountEventLogTable.table.columns.${columnName}.${this.props.viewedAccountType}` });
            default:
                return Intl.formatMessage({ id: `sharedComponent.accountEventLogTable.table.columns.${columnName}` });
        }
    };

    private getAccountId = (appEventLog: AppEventLog): string => {
        switch (this.props.viewedAccountType) {
            case AccountType.supporter:
                return !isNil(appEventLog.request?.account?.extId) ? Formatter.formatExtId(appEventLog.request!.account!.extId) : "";
            case AccountType.supervisor:
            case AccountType.admin:
            case AccountType.superadmin:
                return !isNil(appEventLog.request?.account?.id) ? appEventLog.request!.account!.id : "";
            default:
                return "";
        }
    };

    private readonly columns: Array<Column<AppEventLog>> = ObjectUtils.enumAsArray<AccountEventLogTableColumn>(AccountEventLogTableColumn).map(
        (columnName: AccountEventLogTableColumn): Column<AppEventLog> => ({
            id: columnName,
            name: this.getColumnTitle(columnName),
            accessor: columnName as keyof AppEventLog,
            renderCell: (appEventLog: AppEventLog): React.ReactElement | null => {
                switch (columnName) {
                    case AccountEventLogTableColumn.account:
                        return <>{this.getAccountId(appEventLog)}</>;
                    case AccountEventLogTableColumn.client:
                        return <>{!isNil(appEventLog.entity?.client?.extId) && Formatter.formatExtId(appEventLog.entity!.client!.extId)}</>;
                    case AccountEventLogTableColumn.event:
                        return <>{appEventLog.eventTitle}</>;
                    case AccountEventLogTableColumn.title:
                        return <>{appEventLog.entityTitle}</>;
                    case AccountEventLogTableColumn.timestamp:
                        return <>{DateUtils.format(new Date(appEventLog.timestamp), DateFormat.dateTime)}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: !ObjectUtils.enumAsArray<AppEventLogListSortField>(AppEventLogListSortField)
                .map((field: AppEventLogListSortField) => {
                    return `${field}`;
                })
                .includes(columnName),
        }),
    );

    private updateQueryParams = (): void => {
        const { control, sortField, dateTimeFrom, dateTimeTo, appEventLogName } = this.state.options;
        const options: EventLogQueryParameter<AppEventLogListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
            dateTimeFrom: dateTimeFrom || new Date(),
            dateTimeTo: dateTimeTo || new Date(),
            appEventLogName: appEventLogName,
        };

        const params = new EventLogUrlQueryParser<AppEventLogListSortField>(AppEventLogListSortField, "page.systemEventLog.table").getUrlQuery(options);
        this.props.history.push({ search: `?${params}` });
        this.setState({ appEventLog: [] }, () => this.refreshEventLog(this.state.options));
    };

    private convertColumnIdToSortField = (columnId?: string): AppEventLogListSortField | undefined => {
        switch (columnId) {
            case AccountEventLogTableColumn.timestamp:
                return AppEventLogListSortField.timestamp;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AppEventLogListSortField | null): keyof AppEventLog | undefined => {
        switch (columnId) {
            case AppEventLogListSortField.timestamp:
                return AccountEventLogTableColumn.timestamp;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AppEventLog>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column?.id),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                    dateTimeFrom: this.state.options.dateTimeFrom,
                    dateTimeTo: this.state.options.dateTimeTo,
                    appEventLogName: this.state.options.appEventLogName,
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private getDownloadUrl(): string {
        const options = this.state.options;
        const dateTimeFrom: Date | null = options.dateTimeFrom || startOfDay(new Date());
        const dateTimeTo: Date | null = options.dateTimeTo || DateUtils.lastQuarterMinutes(new Date());
        const appEventLogName: AppEventLogName | null = options.appEventLogName || null;
        const authAccountId = this.getRefreshParams().authAccountId;
        const authAccountExtId = this.getRefreshParams().authAccountExtId;
        const paramArray: string[] = [];

        if (dateTimeFrom) {
            paramArray.push(`dateFrom=${dateTimeFrom.toISOString()}`);
        }
        if (dateTimeTo) {
            paramArray.push(`dateTo=${dateTimeTo.toISOString()}`);
        }
        if (appEventLogName) {
            paramArray.push(`type=${appEventLogName}`);
        }
        if (authAccountId) {
            paramArray.push(`authAccountId=${authAccountId}`);
        }
        if (authAccountExtId) {
            paramArray.push(`authAccountExtId=${authAccountExtId}`);
        }

        return `${Env.exportApiUrl}/appEventLog/xlsx?${paramArray.join("&")}`;
    }

    private filterTypes = (events: AppEventLogNameOption[]): AppEventLogNameOption[] => {
        const search: string = this.state.appEventLogNameSearch;
        if (this.state.appEventLogNameSearch.length >= 3) {
            return events.filter((event: AppEventLogNameOption) => event.label.toLowerCase().includes(search.toLowerCase()));
        }
        return events;
    };

    private renderInputs = (): React.ReactElement => {
        return (
            <div className="inputs">
                <div className="small-text account-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.type.label" })}</div>
                <TextSelect
                    value={Select.getSelectOption(this.props.appEventLogNameOptions, this.state.options.appEventLogName)}
                    options={this.filterTypes(this.props.appEventLogNameOptions)}
                    onChange={(option: AppEventLogNameOption): void => {
                        this.setState({
                            options: {
                                ...this.state.options,
                                appEventLogName: option.value,
                                control: {
                                    offset: 0,
                                    limit: Table.DEFAULT_PAGE_SIZE,
                                },
                            },
                            appEventLogNameSearch: option.label,
                        });
                    }}
                    onTextInputChange={(appEventLogNameSearch: string) => this.setState({ appEventLogNameSearch })}
                    textInputValue={this.state.appEventLogNameSearch}
                />
                <div className="account-event-log-table-time-inputs">
                    <div>
                        <div className="small-text account-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dateFrom.label" })}</div>
                        <InputWrapper>
                            <div className="account-event-log-table-date-input">
                                <DateInput
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dateFrom.placeholder" })}
                                    value={this.state.options.dateTimeFrom}
                                    onChange={(dateTimeFrom: Date | null) => {
                                        if (dateTimeFrom) {
                                            this.setState({
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom,
                                                    dateTimeTo: this.state.options.dateTimeTo < dateTimeFrom ? dateTimeFrom : this.state.options.dateTimeTo,
                                                    control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE },
                                                },
                                            });
                                        }
                                    }}
                                    max={new Date()}
                                    popperPlacement={PopperPlacement.bottom}
                                />
                            </div>
                        </InputWrapper>
                    </div>
                    <div>
                        <div className="small-text account-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.hourTo.label" })}</div>
                        <InputWrapper>
                            <div className="account-event-log-table-hour-input">
                                <TimeSelect
                                    value={this.state.fromTime}
                                    onChange={(time: string) => {
                                        const [hours, minutes] = time.split(":");
                                        const newDateTimeFrom: Date = cloneDeep(this.state.options.dateTimeFrom);
                                        newDateTimeFrom.setHours(Number.parseInt(hours, 10) || 0);
                                        newDateTimeFrom.setMinutes(Number.parseInt(minutes, 10) || 0);
                                        this.setState({
                                            fromTime: time,
                                            options: {
                                                ...this.state.options,
                                                dateTimeFrom: newDateTimeFrom,
                                                dateTimeTo: this.state.options.dateTimeTo < newDateTimeFrom ? newDateTimeFrom : this.state.options.dateTimeTo,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="account-event-log-table-dummy-text-container">
                        <p>{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dummyText.from" })}</p>
                        <p>{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dummyText.dash" })}</p>
                    </div>
                    <div>
                        <div className="small-text account-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dateTo.label" })}</div>
                        <InputWrapper>
                            <div className="account-event-log-table-date-input">
                                <DateInput
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dateTo.placeholder" })}
                                    value={this.state.options.dateTimeTo}
                                    onChange={(dateTimeTo: Date | null) => {
                                        if (dateTimeTo) {
                                            this.setState({
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom: this.state.options.dateTimeFrom > dateTimeTo ? dateTimeTo : this.state.options.dateTimeFrom,
                                                    dateTimeTo,
                                                    control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE },
                                                },
                                            });
                                        }
                                    }}
                                    max={new Date()}
                                    popperPlacement={PopperPlacement.bottom}
                                />
                            </div>
                        </InputWrapper>
                    </div>
                    <div>
                        <div className="small-text account-event-log-table-input-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.hourFrom.label" })}</div>
                        <InputWrapper>
                            <div className="account-event-log-table-hour-input">
                                <TimeSelect
                                    value={this.state.toTime}
                                    onChange={(time: string) => {
                                        const newDateTimeTo: Date = cloneDeep(this.state.options.dateTimeTo);
                                        const [hours, minutes] = time.split(":");
                                        newDateTimeTo.setHours(Number.parseInt(hours, 10) || 0);
                                        newDateTimeTo.setMinutes(Number.parseInt(minutes, 10) || 0);
                                        this.setState({
                                            toTime: time,
                                            options: {
                                                ...this.state.options,
                                                dateTimeFrom: this.state.options.dateTimeFrom > newDateTimeTo ? newDateTimeTo : this.state.options.dateTimeFrom,
                                                dateTimeTo: newDateTimeTo,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="account-event-log-table-dummy-text-container">
                        <p>{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.dummyText.to" })}</p>
                    </div>
                </div>
                <hr />
                <div className="account-event-log-table-inner-buttons">
                    <Button
                        label={Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.buttonLabels.deleteFilters" })}
                        hollow
                        onClick={() => {
                            this.props.history.push({ search: undefined });
                            this.setState({ options: AccountEventLogTableComponent.getInitialOptions(this.props) });
                        }}
                    />
                    <Button
                        label={Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.buttonLabels.applyFilters" })}
                        onClick={() => {
                            this.setState(
                                {
                                    viewType: ViewType.list,
                                    options: { ...this.state.options, control: { ...this.state.options.control, limit: 10, offset: 0 } },
                                    chartDateTimeFrom: this.state.options.dateTimeFrom,
                                    chartDateTimeTo: this.state.options.dateTimeTo,
                                },
                                this.updateQueryParams,
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    private onViewClick = (): void => {
        if (this.state.viewType === ViewType.list) {
            this.setState(
                {
                    options: {
                        ...this.state.options,
                        control: {
                            ...this.state.options.control,
                            offset: 0,
                            limit: 9999,
                        },
                    },
                    viewType: ViewType.chart,
                },
                this.updateQueryParams,
            );
        } else {
            this.setState(
                { options: { ...this.state.options, control: { ...this.state.options.control, limit: Table.DEFAULT_PAGE_SIZE, offset: 0 } }, viewType: ViewType.list },
                this.updateQueryParams,
            );
        }
    };

    private getTooltip = (tooltip: { active: boolean; payload: any; label: string }): React.ReactElement => {
        const value: number | null = tooltip.payload && tooltip.payload[0] && !isNil(tooltip.payload[0].payload?.count) ? tooltip.payload[0].payload?.count : null;
        return (
            <div className="recharts-tooltip">
                <span>{tooltip.label}</span>
                {!isNil(value) && (
                    <>
                        <br />
                        <span>
                            {value} {Intl.formatMessage({ id: "common.event" })}
                        </span>
                    </>
                )}
            </div>
        );
    };

    public render(): React.ReactElement<any> | null {
        const chartData: ChartRow[] = !isNil(this.state.aggregations) ? ChartUtils.getChartData(this.state.chartDateTimeFrom, this.state.chartDateTimeTo, this.state.aggregations) : [];

        return (
            <div className="left-side account-event-log-table">
                <Section
                    label={
                        <>
                            <i className="fa fa-filter" />
                            <span className="section-title">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.filtersSection" })}</span>
                        </>
                    }
                >
                    {this.renderInputs()}
                    <div className="account-event-log-table-outer-buttons">
                        <Button
                            label={Intl.formatMessage({ id: "common.download" })}
                            onClick={() => {
                                window.location.href = this.getDownloadUrl();
                            }}
                            disabled={this.state.count === 0}
                        />
                        <Button
                            label={Intl.formatMessage({ id: `sharedComponent.accountEventLogTable.buttonLabels.switch.${this.state.viewType === ViewType.list ? ViewType.chart : ViewType.list}` })}
                            hollow
                            onClick={this.onViewClick}
                        />
                    </div>

                    {this.state.viewType === ViewType.list ? (
                        <div className="table-responsive table--display-table">
                            <Table
                                keyExtractor={(item: AppEventLog, column?: Column<AppEventLog>): string => {
                                    return `${item.timestamp}_${column ? column.id : ""}`;
                                }}
                                columns={this.columns}
                                sortBy={{
                                    columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                                    order: this.state.options.control.sortOrder || undefined,
                                }}
                                data={this.state.appEventLog}
                                count={this.state.count}
                                limit={this.state.options.control.limit}
                                isSortable={true}
                                onSortOrderChange={this.onSortOrderChange}
                                onPageChange={this.onPageChange}
                                isPaginationEnabled={true}
                                currentPage={this.getCurrentPage()}
                                isLoading={this.state.isLoading}
                                renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.noData" })}
                            />
                        </div>
                    ) : this.state.isLoading ? (
                        <Loading type={LoadingType.layer} />
                    ) : (
                        <div className="chart-container">
                            <p className="chart-title">{ChartUtils.getChartTitle(this.state.chartDateTimeFrom, this.state.chartDateTimeTo)}</p>
                            <span className="small-text chart-x-axis-label">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.xAxisLabel" })}</span>
                            <div className="chart-inner-container">
                                <BarChart width={1200} height={600} data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="time" angle={ChartUtils.getXAxisAngle} textAnchor="end" height={120} />
                                    <YAxis />
                                    <Tooltip content={this.getTooltip} />
                                    <Bar dataKey="count" fill="#8884d8">
                                        {chartData.map((log: ChartRow, index: number) => {
                                            const color = ChartUtils.getColorByNumbers(
                                                log.count,
                                                chartData.map((fillLog: ChartRow) => fillLog.count),
                                            );
                                            return <Cell key={log.time + log.count + index + color} fill={color} />;
                                        })}
                                    </Bar>
                                </BarChart>
                                <div className="chart-y-axis-label-container">
                                    <span className="small-text">{Intl.formatMessage({ id: "sharedComponent.accountEventLogTable.yAxisLabel" })}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </Section>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { appEventLogNameOptions: SettingsSelectors.getAppEventLogNameOptions(state) };
};

export const AccountEventLogTable = withRouter(connect(mapStateToProps)(AccountEventLogTableComponent));

export default AccountEventLogTable;

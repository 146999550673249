// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";
import { Color } from "theme/Color";

function PlusIcon({ width = 46, height = 46, fill = Color.lightGray }: IconProps): React.ReactElement {
    return (
        <svg width={width} height={height} viewBox={"0 0 46 46"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7 21.45H21.45V20.7V12.25H24.55V20.7V21.45H25.3H33.75V24.55H25.3H24.55V25.3V33.75H21.45V25.3V24.55H20.7H12.25V21.45H20.7ZM0.75 23C0.75 10.7182 10.7182 0.75 23 0.75C35.2818 0.75 45.25 10.7182 45.25 23C45.25 35.2818 35.2818 45.25 23 45.25C10.7182 45.25 0.75 35.2818 0.75 23ZM3.85 23C3.85 33.5572 12.4428 42.15 23 42.15C33.5572 42.15 42.15 33.5572 42.15 23C42.15 12.4428 33.5572 3.85 23 3.85C12.4428 3.85 3.85 12.4428 3.85 23Z"
                fill={fill}
                stroke={fill}
                strokeWidth="1.5"
            />
        </svg>
    );
}

export { PlusIcon };

import React, { Component } from "react";
import { AgendaItemContent, SearchListType, SupportedClient } from "api/graphql/types";
import { AgendaItemInput } from "pages/_shared/Draggables/Input/AgendaItemInput";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Prompt } from "components/Prompt";
import { isEqual } from "lodash";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Section } from "components/Section";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { NotificationType } from "components/NotificationBar/Notification";
import { Loading, LoadingType } from "components/Loading/Loading";

interface ComponentProps {
    isLoading: boolean;
    client: SupportedClient;
    agendaItems: AgendaItemContent[];
    onSubmit: (agendaItems: AgendaItemContent[]) => Promise<AgendaItemContent[]>;
}

interface State {
    agendaItems: AgendaItemContent[];
}

type Props = ComponentProps & RouteComponentProps;

class ClientSpareAgendaItemsFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            agendaItems: props.agendaItems,
        };
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.agendaItems, nextProps.agendaItems)) {
            this.setState({ agendaItems: nextProps.agendaItems });
        }
    }

    private onChange = (agendaItems: AgendaItemContent[]): void => {
        if (agendaItems.length > this.state.agendaItems.length) {
            const currentAgendaItemIds: string[] = [
                ...this.state.agendaItems.map((agendaItem: AgendaItemContent) => {
                    return agendaItem.id;
                }),
            ];

            const newAgendaItems: AgendaItemContent[] = agendaItems.filter((agendaItem: AgendaItemContent) => {
                return !currentAgendaItemIds.includes(agendaItem.id);
            });

            this.setState({ agendaItems: [...this.state.agendaItems, ...newAgendaItems] });
        } else {
            this.setState({ agendaItems });
        }
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.agendaItems, this.props.agendaItems);
    };

    private onCancelClick = (): void => {
        this.setState({ agendaItems: this.props.agendaItems });
    };

    private onSubmitClick = async (): Promise<void> => {
        try {
            const agendaItems: AgendaItemContent[] = await this.props.onSubmit(this.state.agendaItems);
            this.setState({ agendaItems });
            if (this.pageRef) {
                this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.clientSpareAgendaItems.saveSucceed" }), type: NotificationType.success });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private renderBottomBar = (): React.ReactElement => {
        return (
            <BottomBar isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged()} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged()} onClick={this.onSubmitClick} />
                </div>
            </BottomBar>
        );
    };

    private getDragIds = (): string[] => {
        return this.state.agendaItems.map((agendaItem: AgendaItemContent): string => {
            return agendaItem.id;
        });
    };

    private onViewClick = (agendaItem: AgendaItemContent) => {
        const path: string | undefined = ContentPageUtils.getViewPath(agendaItem, PageContent.client, this.props.client.extId);
        if (path) {
            this.props.history.push(path);
        }
    };

    private onEditClick = (agendaItem: AgendaItemContent) => {
        const path: string | undefined = ContentPageUtils.getEditPath(agendaItem, PageContent.client, this.props.client.extId);
        if (path) {
            this.props.history.push(path);
        }
    };

    public render(): React.ReactElement {
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: "page.clientSpareAgendaItems.title" }, { name: this.props.client.name })}
                hasSideBar={true}
            >
                <DragContainer searchListTypes={[SearchListType.agendaItem]} client={this.props.client} dragIds={this.getDragIds()}>
                    {this.props.isLoading && <Loading type={LoadingType.layer} />}
                    <div className="left-side">
                        {!this.props.isLoading && (
                            <Section label={Intl.formatMessage({ id: "page.clientSpareAgendaItems.sectionTitle" })}>
                                <p>{Intl.formatMessage({ id: "page.clientSpareAgendaItems.lead" }, { name: this.props.client.name })}</p>
                                <AgendaItemInput
                                    droppableId="agendaItems"
                                    value={this.state.agendaItems}
                                    onChange={this.onChange}
                                    onViewClick={this.onViewClick}
                                    onEditClick={this.onEditClick}
                                    isReorderDisabled={true}
                                    slotMessageOverride={Intl.formatMessage({ id: "page.clientSpareAgendaItems.slotMessage" })}
                                />
                                {this.renderBottomBar()}

                                <Prompt when={this.isChanged()} />
                            </Section>
                        )}
                    </div>
                </DragContainer>
            </Page>
        );
    }
}

export const ClientSpareAgendaItemsForm = withRouter(ClientSpareAgendaItemsFormComponent);

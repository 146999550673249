import React, { PureComponent } from "react";
import { Image } from "components/Image";
import { ContentChild } from "./ContentElementUtils";
import { ContentElementInfo } from "./ContentElementInfo";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";
import { AssetType } from "api/graphql/types";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Intl } from "i18n/Intl";

interface Props {
    element: ContentChild;
    fallbackImage: string;
}

interface State {
    isExpanded: boolean;
}

class ContentChildElement extends PureComponent<Props, State> {
    public readonly state: State = { isExpanded: false };

    private renderContentChildren = (contentChild: ContentChild): React.ReactElement<any> | null => {
        return (
            <div key={contentChild.id} className="content-subelement">
                <div className="subelement-image">
                    <div
                        className="content-element-image image-container"
                        onClick={
                            contentChild.asset
                                ? () => {
                                      store.dispatch(
                                          DialogsActions.show({
                                              type: DialogType.showAsset,
                                              assetUrl: contentChild.asset?.url,
                                              assetType: contentChild.asset?.assetType || AssetType.image,
                                              originalFileName: contentChild.asset?.title,
                                              thumbnailUrl: contentChild.asset?.thumbnailUrl,
                                              dialogTitle: contentChild.asset?.title || Intl.formatMessage({ id: `enum.assetType.${contentChild.asset?.assetType}` }),
                                          }),
                                      );
                                  }
                                : undefined
                        }
                    >
                        <Image src={contentChild.asset?.thumbnailUrl || contentChild.asset?.url} fallback={this.props.fallbackImage} />
                        {contentChild.asset && (
                            <>
                                <div className="content-overlay" />
                                <div className="content-overlay-icon">
                                    <OverlayIcon assetType={contentChild.asset.assetType} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="subelement-title">
                    <h3>{contentChild.title}</h3>
                    <div className="subelement-info">{contentChild.info && <ContentElementInfo info={contentChild.info} />}</div>
                </div>
            </div>
        );
    };

    public render(): React.ReactElement<any> | null {
        const { element } = this.props;

        if (!element.contentChildren) {
            return <div className="subelements-group">{this.renderContentChildren(element)}</div>;
        }

        return (
            <>
                <div key={element.id} className="content-element-subgroup" onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
                    <div className="subgroup-image">
                        <div
                            className="content-element-image image-container"
                            onClick={
                                element.asset
                                    ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                          event.stopPropagation();
                                          store.dispatch(
                                              DialogsActions.show({
                                                  type: DialogType.showAsset,
                                                  assetUrl: element.asset?.url,
                                                  assetType: element.asset?.assetType || AssetType.image,
                                                  originalFileName: element.asset?.title,
                                                  thumbnailUrl: element.asset?.thumbnailUrl,
                                                  dialogTitle: element.asset?.title || Intl.formatMessage({ id: `enum.assetType.${element.asset?.assetType}` }),
                                              }),
                                          );
                                      }
                                    : undefined
                            }
                        >
                            <Image src={element.asset?.thumbnailUrl || element.asset?.url} fallback={this.props.fallbackImage} />
                            {element.asset && (
                                <>
                                    <div className="content-overlay" />
                                    <div className="content-overlay-icon">
                                        <OverlayIcon assetType={element.asset.assetType} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="subgroup-title">
                        <h3>{element.title}</h3>
                        {element.contentChildren && element.contentChildren.length > 0 && <i className={`fa fa-chevron-${this.state.isExpanded ? "up" : "down"}`} />}
                    </div>
                </div>

                <div className="subelements-group">{element.contentChildren && this.state.isExpanded && element.contentChildren.map(this.renderContentChildren)}</div>
            </>
        );
    }
}

export { ContentChildElement };

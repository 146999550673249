import React, { Component } from "react";
import { Page } from "components/Page";
import { Calendar } from "./Calendar";
import { Button } from "components/Button/Button";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { SupportedClient } from "api/graphql/types";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApplicationState } from "reducers/index";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { ClientCalendarQueryParser } from "./ClientCalendarQueryParser";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type Props = ReduxProps & RouteComponentProps<RouteParams> & DispatchProp;

interface State {
    currentMonth: Date;
}

class ClientCalendarPageComponent extends Component<Props, State> {
    public readonly state: State = ClientCalendarPageComponent.getDerivedStateFromProps(this.props);

    public static getDerivedStateFromProps(props: Props): State {
        const { year, month } = new ClientCalendarQueryParser().parse(props.location.search);
        let currentMonth: Date = new Date();
        if (typeof year !== "undefined" && typeof month !== "undefined") {
            currentMonth = new Date(year, month);
        } else if (typeof year !== "undefined" && typeof month === "undefined") {
            currentMonth = new Date(year, 0);
        }
        return { currentMonth: DateUtils.startOfMonth(currentMonth) };
    }

    private updateQueryParams = (date: Date): void => {
        const params = new ClientCalendarQueryParser().getUrlQuery({ year: date.getFullYear(), month: date.getMonth() });
        this.props.history.push({ search: `?${params}` });
    };

    private onPrevClick = () => {
        const date: Date = DateUtils.subMonth(this.state.currentMonth, 1);
        this.updateQueryParams(date);
    };

    private onNextClick = () => {
        const date: Date = DateUtils.addMonth(this.state.currentMonth, 1);
        this.updateQueryParams(date);
    };

    private onTodayClick = () => {
        this.updateQueryParams(new Date());
    };

    public renderPageButtons = () => {
        const currentMonth: string = DateUtils.format(this.state.currentMonth, DateFormat.calendar);
        return (
            <>
                <div className="calendar-buttons">
                    <Button ariaLabel={Intl.formatMessage({ id: "common.previousPage" })} link icon={{ name: "fa-chevron-left", large: true }} onClick={this.onPrevClick} />
                    <span className="month">{currentMonth}</span>
                    <Button ariaLabel={Intl.formatMessage({ id: "common.nextPage" })} link icon={{ name: "fa-chevron-right", large: true }} onClick={this.onNextClick} />
                </div>
                <Button label={Intl.formatMessage({ id: "common.today" })} onClick={this.onTodayClick} />
            </>
        );
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.clientCalendar.title" }, { name: this.props.client.name })} renderButtons={this.renderPageButtons}>
                <Calendar client={this.props.client} month={this.state.currentMonth} />
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    return { client: AccountSelectors.getClientByExtId(state, props.match.params.clientExtId) };
};

export const ClientCalendarPage = withRouter(connect(mapStateToProps)(ClientCalendarPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientCalendarPage;

import { SortOrder, Tag } from "api/graphql/types";
import { UrlQueryParser, ParsedParameter } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { QueryParserTypeUtils, EnumType } from "utils/TypeUtils";
import { IntlHelpers } from "i18n/IntlHelpers";
import { store } from "../store";

enum QueryParameter {
    sortField = "sortField",
    sortOrder = "sortOrder",
    search = "search",
    tags = "tags",
    limit = "limit",
    page = "page",
}

export interface ListQueryParameter<SortFieldType> {
    sortField?: SortFieldType | null;
    sortOrder?: SortOrder | null;
    search?: string | null;
    tags?: string[];
    limit?: number | null;
    page?: number | null;
}

class ListUrlQueryParser<SortFieldType> extends UrlQueryParser<ListQueryParameter<SortFieldType>> {
    public constructor(protected sortFieldEnum: EnumType, protected translationBaseKey: string) {
        super();
    }

    public translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.listQueryParameter.${key}` });
    }

    public getUrlQuery(options: ListQueryParameter<SortFieldType>): string {
        const sortField: SortFieldType | null = options.sortField || null;
        const sortOrder: SortOrder | null = options.sortOrder || null;
        const search: string | null = options.search || null;
        const tags: string[] | null = options.tags || null;
        const limit: number | null = options.limit || null;
        const page: number | null = options.page || null;

        const paramArray: string[] = [];

        if (sortField) {
            paramArray.push(`${this.translateListQuery(QueryParameter.sortField)}=${Intl.formatMessage({ id: `${this.translationBaseKey}.sortField.${sortField}` })}`);
        }
        if (sortOrder) {
            paramArray.push(`${this.translateListQuery(QueryParameter.sortOrder)}=${Intl.formatMessage({ id: `enum.sortOrder.${sortOrder}` })}`);
        }
        if (search) {
            paramArray.push(`${this.translateListQuery(QueryParameter.search)}=${search}`);
        }
        if (tags && tags.length > 0) {
            paramArray.push(`${this.translateListQuery(QueryParameter.tags)}=${tags.join(",")}`);
        }
        if (limit) {
            paramArray.push(`${this.translateListQuery(QueryParameter.limit)}=${limit}`);
        }
        if (page) {
            paramArray.push(`${this.translateListQuery(QueryParameter.page)}=${page}`);
        }

        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): ListQueryParameter<SortFieldType> {
        const keys = {
            [QueryParameter.sortField]: this.translateListQuery(QueryParameter.sortField),
            [QueryParameter.sortOrder]: this.translateListQuery(QueryParameter.sortOrder),
            [QueryParameter.search]: this.translateListQuery(QueryParameter.search),
            [QueryParameter.tags]: this.translateListQuery(QueryParameter.tags),
            [QueryParameter.limit]: this.translateListQuery(QueryParameter.limit),
            [QueryParameter.page]: this.translateListQuery(QueryParameter.page),
        };

        const sortField: SortFieldType | undefined = IntlHelpers.fromI18nToEnum<SortFieldType>(this.sortFieldEnum, parsedParams[keys.sortField], `${this.translationBaseKey}.sortField`);

        const sortOrder: SortOrder | null | undefined = IntlHelpers.fromI18nToEnum<SortOrder>(SortOrder, parsedParams[keys.sortOrder], "enum.sortOrder");

        const queryTags: string[] | undefined = QueryParserTypeUtils.toString(parsedParams[keys.tags])?.split(",") || undefined;
        const availableTags = store.getState().tags.map((tag: Tag): string => tag.title);

        const search: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.search]);
        const tags = queryTags?.filter((queryTag: string) => availableTags.includes(queryTag));
        const limit: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.limit]);
        const page: number | undefined = QueryParserTypeUtils.toNumber(parsedParams[keys.page]);

        return { sortField, sortOrder, search, tags, limit, page };
    }
}

export { ListUrlQueryParser };

import React, { Component } from "react";
import { RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "reducers/index";
import { me_me, SupportedClient } from "api/graphql/types";
import { AccountSelectors } from "selectors/AccountSelectors";
import { Path } from "utils/Path";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";
import { Api } from "api/Api";
import { AccountActions } from "actions/AccountActions";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientChangeManagerRequestId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ReduxProps & ComponentProps & DispatchProp;

class ChangeClientManagerRequestPageComponent extends Component<Props> {
    public componentDidMount() {
        this.showDialog();
    }

    private refreshAccount = async (): Promise<void> => {
        try {
            const account: me_me = await Api.me();
            this.props.dispatch(AccountActions.updateAccount(account));
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private showDialog = () => {
        if (this.props.client) {
            this.props.dispatch(
                DialogsActions.show({
                    type: DialogType.verifyChangeClientManager,
                    client: this.props.client,
                    operation: "accept",
                    onClose: () => this.props.history.replace(Path.dashboard),
                    onChangeClientManagerFinished: this.refreshAccount,
                }),
            );
        }
    };

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.clientChangeManagerRequestId !== this.props.match.params.clientChangeManagerRequestId && this.props.client) {
            this.showDialog();
        }
    }

    public render(): React.ReactElement<any> | null {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }
        return null;
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    return {
        client: AccountSelectors.getClientByClientManagerShareRequestId(state, props.match.params.clientChangeManagerRequestId),
    };
};

export const ChangeClientManagerRequestPage = connect(mapStateToProps)(withRouter(ChangeClientManagerRequestPageComponent));

// tslint:disable-next-line: no-default-export
export default ChangeClientManagerRequestPage;

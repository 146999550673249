import React, { Component } from "react";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { IntlHelpers } from "i18n/IntlHelpers";
import { SupportedClient, ClientConfigInput, FlowChartListType, ClientContentsConfig, AgendaItemSize, AssetType, AssetContent, AgendaItemCheckBoxSize } from "api/graphql/types";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { ClientSettingsSelectOptions } from "models/ClientSettingsSelectOptions";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { cloneDeep, isEqual } from "lodash";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { AgendaType } from "./ClientSettingsPage";
import { ColorsOfDaysUtils } from "utils/ColorsOfDaysUtils";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { Input } from "components/Inputs/Input/Input";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    client: SupportedClient | null;
    accountId: string;
}

interface RouteParams {
    clientExtId?: string;
}

interface OwnProps {
    onShowNotification: () => void;
    onDragIdsChange: (dragIds: string[]) => void;
}

type Props = OwnProps & RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

interface CurrentConfig {
    agenda: AgendaType;
    flowchartListType: SelectOption<FlowChartListType>;
}

interface State {
    clientContentsConfig: ClientContentsConfig | null;
    notificationOnProfilePage: string | null;
    currentConfig: CurrentConfig;
    isLoading: boolean;
}

class ContentsTabComponent extends Component<Props, State> {
    public readonly state: State = {
        clientContentsConfig: null,
        notificationOnProfilePage: null,
        currentConfig: {
            agenda: {
                itemBackgroundColor: null,
                colorsOfDays: ColorsOfDaysUtils.getEmptyColorsOfDays(),
                itemCheckBoxSize: ClientSettingsSelectOptions.agendaItemCheckboxSize[0],
                itemSize: ClientSettingsSelectOptions.agendaItemSize[0],
                calendarView: ClientSettingsSelectOptions.calendarView[0],
                withoutDefaultAgenda: {
                    text: null,
                    image: null,
                },
            },
            flowchartListType: ClientSettingsSelectOptions.flowchartListType[0],
        },
        isLoading: true,
    };

    private getDefaultConfig = (clientContentsConfig: ClientContentsConfig): CurrentConfig => {
        const { agenda, flowchartListType } = clientContentsConfig;

        return {
            agenda: {
                itemBackgroundColor: agenda ? agenda.itemBackgroundColor : null,
                colorsOfDays: agenda && agenda.colorsOfDays ? agenda.colorsOfDays : ColorsOfDaysUtils.getEmptyColorsOfDays(),
                itemCheckBoxSize: Select.getSelectOption(ClientSettingsSelectOptions.agendaItemCheckboxSize, agenda ? agenda.itemCheckBoxSize : null),
                itemSize: Select.getSelectOption(ClientSettingsSelectOptions.agendaItemSize, agenda ? agenda.itemSize : null),
                calendarView: Select.getSelectOption(ClientSettingsSelectOptions.calendarView, agenda ? agenda.calendarView : false),
                withoutDefaultAgenda: {
                    text: agenda && agenda.withoutDefaultAgenda ? agenda.withoutDefaultAgenda.text : null,
                    image: agenda && agenda.withoutDefaultAgenda && agenda.withoutDefaultAgenda.image ? agenda.withoutDefaultAgenda.image : null,
                },
            },
            flowchartListType: Select.getSelectOption(ClientSettingsSelectOptions.flowchartListType, flowchartListType),
        };
    };

    private refreshSettingsData = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { clientContentsConfig, notificationOnProfilePage } = await Api.getClientContentsSettingsData(clientId);
                    this.setState(
                        {
                            clientContentsConfig,
                            currentConfig: this.getDefaultConfig(cloneDeep(clientContentsConfig)),
                            notificationOnProfilePage,
                            isLoading: false,
                        },
                        () => {
                            const id = this.state.currentConfig.agenda.withoutDefaultAgenda.image?.id;
                            this.props.onDragIdsChange(id ? [id] : []);
                        },
                    );
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshSettingsData(this.props.client.id);
        } else {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
            this.setState({ isLoading: false });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshSettingsData(nextProps.client.id);
        }
    }

    private onCurrentConfigChange = (currentConfigChanges: Partial<CurrentConfig>): void => {
        this.setState({ currentConfig: { ...this.state.currentConfig, ...currentConfigChanges } });
    };

    private convertCurrentConfigToClientConfigInput = (): ClientConfigInput => {
        const { agenda, flowchartListType } = this.state.currentConfig;

        return {
            agenda: {
                itemSize: agenda.itemSize.value,
                calendarView: agenda.calendarView.value,
                withoutDefaultAgenda: {
                    text: agenda.withoutDefaultAgenda.text,
                    image: agenda.withoutDefaultAgenda.image ? agenda.withoutDefaultAgenda.image.id : null,
                },
                itemCheckBoxSize: agenda.itemCheckBoxSize.value,
            },
            flowchartListType: flowchartListType.value,
        };
    };

    private isChanged = (): boolean => {
        if (this.state.clientContentsConfig) {
            return !isEqual(this.getDefaultConfig(this.state.clientContentsConfig), this.state.currentConfig);
        }
        return false;
    };

    private onSaveClick = async (): Promise<void> => {
        if (!this.props.client) {
            return;
        }

        try {
            const clientContentsConfig: ClientContentsConfig = await Api.updateClientConfig(this.props.client.id, this.convertCurrentConfigToClientConfigInput());

            this.props.onShowNotification();

            this.setState({ clientContentsConfig, currentConfig: this.getDefaultConfig(cloneDeep(clientContentsConfig)) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
        this.setState({ isLoading: false });
    };

    private onCancelClick = (): void => {
        this.setState({ currentConfig: this.getDefaultConfig(cloneDeep(this.state.clientContentsConfig!)) }, () => {
            const id = this.state.currentConfig.agenda.withoutDefaultAgenda.image?.id;
            this.props.onDragIdsChange(id ? [id] : []);
        });
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client || (!this.state.isLoading && !this.state.clientContentsConfig)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientSettings.contentsTab.title" })}>
                    <InputWrapper id={TestId.clientSettings.agendaItemSize} inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.agendaItemSize" })}>
                        <Select
                            options={ClientSettingsSelectOptions.agendaItemSize}
                            value={this.state.currentConfig.agenda.itemSize}
                            onChange={(itemSize: SelectOption<AgendaItemSize>): void => {
                                this.onCurrentConfigChange({ agenda: { ...this.state.currentConfig.agenda, itemSize } });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper id={TestId.clientSettings.calendarView} inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.calendarView" })}>
                        <Select
                            options={ClientSettingsSelectOptions.calendarView}
                            value={this.state.currentConfig.agenda.calendarView}
                            onChange={(calendarView: SelectOption<boolean>): void => {
                                this.onCurrentConfigChange({ agenda: { ...this.state.currentConfig.agenda, calendarView } });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.withoutDefaultAgenda.image.label" })}>
                        <AssetInput
                            droppableId="image"
                            assetType={AssetType.image}
                            value={this.state.currentConfig.agenda.withoutDefaultAgenda.image ? [this.state.currentConfig.agenda.withoutDefaultAgenda.image] : []}
                            onChange={(assets: AssetContent[]) => {
                                const image = assets[0] || null;
                                this.onCurrentConfigChange({
                                    agenda: {
                                        ...this.state.currentConfig.agenda,
                                        withoutDefaultAgenda: {
                                            ...this.state.currentConfig.agenda.withoutDefaultAgenda,
                                            image,
                                        },
                                    },
                                });
                                if (image) {
                                    this.props.onDragIdsChange([image.id]);
                                }
                            }}
                            maxItemCount={1}
                            minItemCount={1}
                        />
                    </InputWrapper>

                    <p className="lead no-max-width">{Intl.formatMessage({ id: "page.clientSettings.contentsTab.withoutDefaultAgendaHint" })}</p>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.withoutDefaultAgenda.text.label" })}>
                        <Input
                            name="title"
                            type="text"
                            placeholder={Intl.formatMessage({ id: "page.clientSettings.contentsTab.withoutDefaultAgenda.text.placeholder" })}
                            value={this.state.currentConfig.agenda.withoutDefaultAgenda.text || ""}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({
                                    agenda: {
                                        ...this.state.currentConfig.agenda,
                                        withoutDefaultAgenda: {
                                            ...this.state.currentConfig.agenda.withoutDefaultAgenda,
                                            text: event.currentTarget.value,
                                        },
                                    },
                                });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper id={TestId.clientSettings.flowchartListType} inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.flowchartListType" })}>
                        <Select
                            options={ClientSettingsSelectOptions.flowchartListType}
                            value={this.state.currentConfig.flowchartListType}
                            onChange={(flowchartListType: SelectOption<FlowChartListType>) => {
                                this.onCurrentConfigChange({ flowchartListType });
                            }}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper id={TestId.clientSettings.agendaItemCheckboxSize} inputLabel={Intl.formatMessage({ id: "page.clientSettings.contentsTab.agendaItemCheckboxSize" })}>
                        <Select
                            options={ClientSettingsSelectOptions.agendaItemCheckboxSize}
                            value={this.state.currentConfig.agenda.itemCheckBoxSize}
                            onChange={(itemCheckBoxSize: SelectOption<AgendaItemCheckBoxSize>) => {
                                this.onCurrentConfigChange({ agenda: { ...this.state.currentConfig.agenda, itemCheckBoxSize } });
                            }}
                        />
                    </InputWrapper>
                </Section>
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button id={TestId.clientSettings.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button id={TestId.clientSettings.saveButton} label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>
                <Prompt when={this.isChanged()} />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return {
        client,
        accountId: state.account!.id,
    };
};

export const ContentsTab = withRouter(connect(mapStateToProps)(ContentsTabComponent));

export default ContentsTab;

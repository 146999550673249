import React, { Component } from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Path } from "utils/Path";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { ClientProfileData } from "api/graphql/types";
import { Section } from "components/Section";
import { Intl } from "i18n/Intl";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";
import { Formatter } from "utils/Formatter";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { DateInput } from "components/Inputs/Date/DateInput";
import { ValidatorConstants } from "utils/Validator";
import { ClientDetailSupporterList } from "./ClientDetailSupporterList";
import { TextArea } from "components/Inputs/TextArea/TextArea";

interface Params {
    clientExtId: string;
}

type Props = RouteComponentProps<Params>;

interface State {
    isLoading: boolean;
    clientProfile: ClientProfileData | null;
    isDeleteDialogVisible: boolean;
}

class ClientDetailProfileTabComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: true,
        clientProfile: null,
        isDeleteDialogVisible: false,
    };

    public componentDidMount(): void {
        this.refreshAccount(this.props);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.refreshAccount(nextProps);
        }
    }

    private refreshAccount(props: Props): void {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const clientProfile: ClientProfileData = await Api.getClientByExtId(props.match.params.clientExtId);
                    this.setState({ clientProfile, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.push(Path.adminList);
                        },
                    });
                }
            },
        );
    }

    public render(): React.ReactElement<any> | null {
        if (this.state.isLoading && !this.state.clientProfile) {
            return <Loading type={LoadingType.layer} />;
        }

        const { clientProfile } = this.state;
        if (!clientProfile) {
            return <Redirect to={Path.clientList} />;
        }

        return (
            <div className="left-side">
                <Section label={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.dateOfBirthForm.dateOfBirth.label" })} childrenWrapperClassName={" doEdit"}>
                        <DateInput
                            placeholder={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.dateOfBirthForm.dateOfBirth.placeholder" })}
                            value={new Date(clientProfile.dateOfBirth)}
                            onChange={(): void => {}}
                            min={ValidatorConstants.getClientDateOfBirthMinDate()}
                            max={ValidatorConstants.getClientDateOfBirthMaxDate()}
                            disabled={true}
                        />
                    </InputWrapper>

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.introductionForm.introduction.label" })}>
                        <TextArea
                            placeholder={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.introductionForm.introduction.placeholder" })}
                            value={clientProfile.introduction || ""}
                            onChange={(): void => {
                                /** TOOD: */
                            }}
                            disabled={true}
                        />
                    </InputWrapper>

                    <div className="input-wrapper grid-x">
                        <span className="input-label cell">{Intl.formatMessage({ id: "page.clientDetail.tabs.profile.personalData.extId" })}</span>
                        <span className="cell auto">
                            <strong className="value-cell">{Formatter.formatExtId(clientProfile.extId!)}</strong>
                        </span>
                        <span className="cell button-cell">
                            <CopyToClipboardButton copiedTooltipMessage={Intl.formatMessage({ id: "page.profile.personalData.copied" })} textToCopy={clientProfile.extId} />
                        </span>
                    </div>
                </Section>
                <Section label={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.title" })}>
                    <ClientDetailSupporterList
                        data={clientProfile.supporters.result}
                        pendingClientManagerChangeRequest={clientProfile.pendingClientManagerChangeRequest}
                        clientManagedBy={clientProfile.managedBy.id}
                    />
                </Section>
            </div>
        );
    }
}

export const ClientDetailProfileTab = withRouter(ClientDetailProfileTabComponent);

import React, { PureComponent } from "react";
import { Input } from "../Input/Input";

import "./TextSelect.scss";

export interface TextSelectOption<T = string> {
    id: string;
    label: string;
    value: T;
    disabled?: boolean;
}

interface Props<T> {
    emptyLabel?: string;
    value: TextSelectOption<T> | null;
    onChange: (item: TextSelectOption<T>) => any;
    options: Array<TextSelectOption<T>>;
    hasError?: boolean;
    disabled?: boolean;
    renderOption?: (item: TextSelectOption<T> | null, index?: number, items?: Array<TextSelectOption<T>>) => React.ReactElement;
    className?: string;
    onTextInputChange: (value: string) => void;
    textInputValue: string;
}

interface State {
    isOpened: boolean;
}

class TextSelect<T> extends PureComponent<Props<T>, State> {
    private inputRef: HTMLInputElement | null = null;
    private containerRef: HTMLDivElement | null = null;

    /**
     * Get TextSelectOption from list by value
     * @param list Select option list
     * @param value current value of selected select option
     */
    public static getTextSelectOption<T>(list: Array<TextSelectOption<T>>, value: T | null): TextSelectOption<T> {
        const current = list.find((option: TextSelectOption<T>): boolean => option.value === value);
        if (!current) {
            console.warn("current not found!", current, list);
        }
        return current || list[0];
    }

    public readonly state: State = {
        isOpened: false,
    };

    public scrollIntoView = (): void => {
        if (this.containerRef) {
            this.containerRef.scrollIntoView();
        }
    };

    public componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside);
    }

    private onClickOutside = (event: MouseEvent): void => {
        if (this.containerRef && !this.containerRef.contains(event.target as Node) && this.state.isOpened) {
            this.props.onTextInputChange!(this.props.value?.label || "");
            this.onOpenChange();
        }
    };

    private onOpenChange = () => {
        this.setState((prevState: State) => ({ isOpened: !prevState.isOpened }));
    };

    private onOptionSelected = (item: TextSelectOption<T>) => () => {
        this.props.onChange(item);
        this.onOpenChange();
    };

    private renderOption = (item: TextSelectOption<T>, index: number, items: Array<TextSelectOption<T>>): React.ReactElement<any> => {
        const isActive: boolean = !!this.props.value && item.id === this.props.value.id;
        const isDisabled: boolean = !!item.disabled;
        return (
            <div key={item.id} className={`option${isActive ? " active" : ""}${isDisabled ? " disabled" : ""}`} onClick={isDisabled ? undefined : this.onOptionSelected(item)} id={`option-${item.id}`}>
                {this.props.renderOption ? this.props.renderOption(item, index, items) : item.label}
            </div>
        );
    };

    private renderSelectedOption = (): React.ReactElement<any> => {
        if (this.props.renderOption) {
            return this.props.renderOption(this.props.value);
        }

        if (this.props.value) {
            return <>{this.props.value.label}</>;
        }

        return <>{this.props.emptyLabel}</>;
    };

    public render(): React.ReactElement {
        if (this.props.disabled) {
            return (
                <div className={`select disabled ${this.props.className || ""}`}>
                    <div className="select-value">{this.renderSelectedOption()}</div>
                </div>
            );
        }

        return (
            <div
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                className={`select${this.state.isOpened ? " opened" : ""} ${this.props.className || ""}`}
            >
                <div
                    className={`select-value${this.props.hasError ? " has-error" : ""}`}
                    onClick={() => {
                        this.setState({ isOpened: true }, () => {
                            this.inputRef?.focus();
                        });
                        this.props.onTextInputChange!("");
                    }}
                >
                    <Input
                        innerRef={(ref: HTMLInputElement | null) => {
                            this.inputRef = ref;
                        }}
                        type="text"
                        className="select-text-input"
                        value={this.props.textInputValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.props.onTextInputChange!(event.currentTarget.value);
                        }}
                    />{" "}
                </div>
                <div className="options">{this.props.options.map(this.renderOption)}</div>
            </div>
        );
    }
}

export { TextSelect };

import { getAdminTags_getTags_result, getTags_getTags_result } from "api/graphql/types";

export enum TagsActionType {
    updateTags = "@tags/update",
}

interface UpdateTags {
    type: TagsActionType.updateTags;
    tags: getTags_getTags_result[];
    adminTags?: getAdminTags_getTags_result[];
}

export class TagsActions {
    public static updateTags(tags: getTags_getTags_result[]): UpdateTags {
        return { type: TagsActionType.updateTags, tags };
    }
}

export type TagsAction = UpdateTags;

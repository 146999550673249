import React, { PureComponent } from "react";

interface Props {
    id?: string;
    message: string;
}

class ErrorMessage extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return <span className="error-wrapper error-message">{this.props.message}</span>;
    }
}

export { ErrorMessage };

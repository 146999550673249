import * as React from "react";

function SvgEmbeddedFlowChart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={26} height={26} fill="none" {...props}>
            <path d="M13 24.75c6.49 0 11.75-5.26 11.75-11.75S19.49 1.25 13 1.25 1.25 6.51 1.25 13 6.51 24.75 13 24.75z" fill="#0D325F" stroke="#fff" strokeWidth={1.5} />
            <path
                d="M7.349 9.845l.412-.394a.414.414 0 01.304-.125c.12 0 .22.042.304.125l3.474 3.474a.415.415 0 01.125.304c0 .12-.042.221-.125.305l-3.474 3.473a.414.414 0 01-.304.125.414.414 0 01-.304-.125l-.412-.394a.414.414 0 01-.125-.304c.012-.12.06-.221.143-.305l2.148-2.059H4.377a.415.415 0 01-.304-.125.414.414 0 01-.126-.304v-.573c0-.12.042-.221.126-.305a.415.415 0 01.304-.125h5.138l-2.148-2.059a.486.486 0 01-.143-.304c0-.12.041-.221.125-.305zM17.09 9.845l.411-.394a.414.414 0 01.304-.125c.12 0 .221.042.305.125l3.473 3.474a.414.414 0 01.125.304c0 .12-.041.221-.125.305l-3.473 3.473a.414.414 0 01-.305.125.414.414 0 01-.304-.125l-.412-.394a.414.414 0 01-.125-.304c.012-.12.06-.221.143-.305l2.149-2.059h-5.139a.415.415 0 01-.304-.125.415.415 0 01-.126-.304v-.573c0-.12.042-.221.126-.305a.415.415 0 01.304-.125h5.139l-2.149-2.059a.486.486 0 01-.143-.304c0-.12.042-.221.125-.305z"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgEmbeddedFlowChart;

import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Input } from "../Inputs/Input/Input";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { CreateAssetDirectoryInput } from "api/graphql/types";
import { ContentLibraryDirectories } from "models/ContentLibraryDirectories";
import { Select, SelectOption } from "../Inputs/Select/Select";
import { DirectoryUtils } from "utils/DirectoryUtils";
import { Api } from "api/Api";
import { Alert } from "../Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Validator } from "utils/Validator";

export interface CreateAssetDirectoryDialogProps {
    onCreateAssetDirectoryFinished: (succeed: boolean) => void;
    isPublic: boolean;
    isVisible: boolean;
}

type Props = CreateAssetDirectoryDialogProps & DialogVisibilityProps & RouteComponentProps;

type DirectoryOptionType = SelectOption<string | null>;

interface State {
    assetDirectory: CreateAssetDirectoryInput;
    directories: SelectOption[];
    selectedDirectory: DirectoryOptionType;
    isLoading: boolean;
    isValidationEnabled: boolean;
    nameError: string;
}

class CreateAssetDirectoryDialogComponent extends PureComponent<Props> {
    private nameRef: HTMLInputElement | null = null;

    private static getInitialState(): State {
        return {
            assetDirectory: {
                name: "",
                parentId: null,
            },
            selectedDirectory: {
                id: "",
                value: null,
                label: Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" }),
            },
            directories: [],
            isLoading: false,
            isValidationEnabled: false,
            nameError: "",
        };
    }

    public readonly state: State = CreateAssetDirectoryDialogComponent.getInitialState();

    public componentDidMount(): void {
        this.refreshDirectories();
    }

    private refreshDirectories = async () => {
        try {
            const directories = this.props.isPublic
                ? await ContentLibraryDirectories.getPublicAssetDirectories(this.props.history.location.pathname)
                : await ContentLibraryDirectories.getPersonalAssetDirectories(this.props.history.location.pathname);
            const { selectedDirectory } = this.state;
            this.setState({
                directories: [selectedDirectory, ...DirectoryUtils.getDirectoriesSelectValue(directories)],
            });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onCloseClick = () => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name: string = event.currentTarget.value;
        const { assetDirectory } = this.state;

        this.setState({
            assetDirectory: {
                name,
                parentId: assetDirectory.parentId,
            },
            nameError: IntlHelpers.getValidationError(Validator.validateAssetName(name)),
        });
    };

    private onDirectoryChange = (item: DirectoryOptionType): void => {
        this.setState({
            selectedDirectory: item,
            assetDirectory: {
                ...this.state.assetDirectory,
                parentId: item.value,
            },
        });
    };

    private createAssetDirectory = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const nameError = IntlHelpers.getValidationError(Validator.validateAssetName(this.state.assetDirectory.name));
        if (nameError) {
            this.setState({ nameError, isValidationEnabled: true });
            if (this.nameRef) {
                this.nameRef.focus();
            }
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (this.props.isPublic) {
                        await Api.createPublicAssetDirectory(this.state.assetDirectory);
                    } else {
                        await Api.createPersonalAssetDirectory(this.state.assetDirectory);
                    }
                    this.props.onHide();
                    this.props.onCreateAssetDirectoryFinished(true);
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    public render(): React.ReactElement<any> | null {
        const { assetDirectory, directories, isLoading, isValidationEnabled, nameError } = this.state;
        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.title" })} visible={this.props.isVisible} onCloseClick={isLoading ? undefined : this.onCloseClick}>
                <form onSubmit={this.createAssetDirectory}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.name.label" })} errorMessage={isValidationEnabled ? nameError : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null) => {
                                this.nameRef = ref;
                            }}
                            type="text"
                            value={assetDirectory.name}
                            onChange={this.onNameChange}
                            disabled={isLoading}
                            placeholder={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.name.placeholder" })}
                        />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.label" })}>
                        <Select
                            options={directories ? directories : []}
                            onChange={item => this.onDirectoryChange(item)}
                            value={this.state.selectedDirectory}
                            emptyLabel={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.directory.placeholder" })}
                            disabled={isLoading}
                        />
                    </InputWrapper>

                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCloseClick} disabled={isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: "sharedComponent.createAssetDirectoryDialog.submitLabel" })}
                                type="submit"
                                disabled={isLoading || (isValidationEnabled && !!nameError)}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const CreateAssetDirectoryDialog = withRouter(CreateAssetDirectoryDialogComponent);
export type { DirectoryOptionType };

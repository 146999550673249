import React, { PureComponent } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Input } from "../Inputs/Input/Input";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { AssetDirectory } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "../Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Validator } from "utils/Validator";

export interface EditAssetDirectoryDialogProps {
    directoryId: string;
    isPublic: boolean;
    onClose: (isDeleted: boolean) => void;
}

type Props = EditAssetDirectoryDialogProps & DialogVisibilityProps & RouteComponentProps;

interface State {
    isLoading: boolean;
    assetDirectory: AssetDirectory | null;
    directoryName: string;
}

class EditAssetDirectoryDialogComponent extends PureComponent<Props> {
    public readonly state: State = {
        isLoading: true,
        assetDirectory: null,
        directoryName: "",
    };

    public componentDidMount(): void {
        this.refreshDirectory();
    }

    private refreshDirectory = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const assetDirectory = this.props.isPublic ? await Api.getPublicAssetDirectoryById(this.props.directoryId) : await Api.getPersonalAssetDirectoryById(this.props.directoryId);
                    this.setState({ isLoading: false, assetDirectory, directoryName: assetDirectory.name });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.props.onHide();
                }
            },
        );
    };

    private onCloseClick = (): void => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    private onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const directoryName: string = event.currentTarget.value;
        this.setState({ directoryName });
    };

    private onSaveClick = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            if (this.props.isPublic) {
                await Api.updatePublicAssetDirectory(this.props.directoryId, { name: this.state.directoryName });
            } else {
                await Api.updatePersonalAssetDirectory(this.props.directoryId, { name: this.state.directoryName });
            }
            Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.saveSucceed" }) });
            this.props.onClose(false);
            this.props.onHide();
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onDeleteClick = async (): Promise<void> => {
        try {
            if (this.props.isPublic) {
                await Api.deletePublicAssetDirectory(this.props.directoryId);
            } else {
                await Api.deletePersonalAssetDirectory(this.props.directoryId);
            }
            Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.delete.succeed" }) });
            this.props.onClose(true);
            this.props.onHide();
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> | null {
        const errorMessage = IntlHelpers.getValidationError(Validator.validateAssetName(this.state.directoryName));
        if (!this.state.assetDirectory && this.state.isLoading) {
            return null;
        }

        return (
            <Dialog title={Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.title" })} visible={this.props.isVisible} onCloseClick={this.onCloseClick}>
                <form onSubmit={this.onSaveClick}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.name.label" })} errorMessage={errorMessage}>
                        <Input
                            type="text"
                            value={this.state.directoryName}
                            onChange={this.onNameChange}
                            disabled={this.state.isLoading}
                            placeholder={Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.name.placeholder" })}
                        />
                    </InputWrapper>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCloseClick} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: "common.save" })}
                                type="submit"
                                disabled={!!errorMessage || this.state.directoryName === this.state.assetDirectory?.name || this.state.isLoading}
                            />
                        </div>
                    </div>

                    <hr className="mt-50" />

                    <div className="input-wrapper">
                        <span className="input-label">{Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.delete.label" })}</span>
                        <p>{Intl.formatMessage({ id: "sharedComponent.editAssetDirectoryDialog.delete.description" })}</p>
                        <Button
                            className="btn-danger btn--text-large fw-700"
                            label={Intl.formatMessage({ id: "common.delete" })}
                            ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                            onClick={this.onDeleteClick}
                            disabled={!(this.state.assetDirectory as any).deletable || this.state.isLoading}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const EditAssetDirectoryDialog = withRouter(EditAssetDirectoryDialogComponent);

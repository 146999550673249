import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { ClientSupporter, ClientChangeManagerRequest } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ObjectUtils } from "utils/ObjectUtils";
import { Formatter } from "utils/Formatter";
import { withRouter, RouteComponentProps } from "react-router";
import { Path } from "utils/Path";
import { Log } from "utils/Log";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";
import { Color } from "theme/Color";
import { ClientSupporterStatus } from "api/ApiTypes";
import { SupporterCollaborator, SupporterCollaboratorNew, SupporterManager, SupporterManagerNew } from "components/Svg";

interface ComponentProps {
    pendingClientManagerChangeRequest: ClientChangeManagerRequest | null;
    clientManagedBy?: string;
    data: ClientSupporter[];
}

type Props = ComponentProps & RouteComponentProps;

interface State {}

enum ClientDetailProfileTabSupporterListColumn {
    email = "email",
    extId = "extId",
    role = "role",
    status = "status",
}

class ClientDetailSupporterListComponent extends Component<Props, State> {
    private isNewCollaborator(clientSupporter: ClientSupporter): boolean {
        return clientSupporter.status === ClientSupporterStatus.pending;
    }

    private isCollaborator(clientSupporter: ClientSupporter): boolean {
        return clientSupporter.status === ClientSupporterStatus.active;
    }

    private isManager(clientSupporter: ClientSupporter): boolean {
        return !!clientSupporter.account && this.props.clientManagedBy === clientSupporter.account.id;
    }

    private isNewManager(clientSupporter: ClientSupporter): boolean {
        return !!this.props.pendingClientManagerChangeRequest && !!clientSupporter.account && this.props.pendingClientManagerChangeRequest.newManager?.id === clientSupporter.account.id;
    }

    private getRoleCell(clientSupporter: ClientSupporter): React.ReactElement<any> | null {
        if (this.isNewManager(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.newManager" })}>
                    <SupporterManagerNew className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isManager(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.manager" })}>
                    <SupporterManager className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isNewCollaborator(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.newCollaborator" })}>
                    <SupporterCollaboratorNew className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isCollaborator(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.collaborator" })}>
                    <SupporterCollaborator className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        Log.warning("Unknown role found for supporter: ", clientSupporter);
        return null;
    }

    private readonly columns: Array<Column<ClientSupporter>> = ObjectUtils.enumAsArray<ClientDetailProfileTabSupporterListColumn>(ClientDetailProfileTabSupporterListColumn).map(
        (columnName: string): Column<ClientSupporter> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.clientDetail.tabs.profile.supporterList.table.columns.${columnName}` }),
            accessor: columnName as keyof ClientSupporter,
            renderCell: (supporter: ClientSupporter): React.ReactElement<any> | null => {
                switch (columnName) {
                    case ClientDetailProfileTabSupporterListColumn.extId:
                        return <>{supporter.account ? Formatter.formatExtId(supporter.account.extId!) : "-"}</>;
                    case ClientDetailProfileTabSupporterListColumn.role:
                        return this.getRoleCell(supporter);
                    case ClientDetailProfileTabSupporterListColumn.status:
                        return <>{Intl.formatMessage({ id: `enum.clientSupporterStatus.${supporter.status}` })}</>;
                    default:
                        return null;
                }
            },
        }),
    );

    public render(): React.ReactElement<any> {
        return (
            <Table
                keyExtractor={(item: ClientSupporter, column?: Column<ClientSupporter>): string => {
                    return item.createdAt + item.email + (column ? column.id : "");
                }}
                columns={this.columns}
                data={this.props.data}
                count={this.props.data.length}
                currentPage={1}
                isLoading={false}
                renderEmpty={(): string => Intl.formatMessage({ id: "page.clientDetail.tabs.profile.supporterList.noData" })}
                onRowClick={(supporter: ClientSupporter): void => {
                    if (supporter.account && supporter.account.extId) {
                        this.props.history.push(Path.supporterDetail(supporter.account.extId));
                    } else {
                        Log.warning("supporter don't have extId", supporter);
                    }
                }}
            />
        );
    }
}

export const ClientDetailSupporterList = withRouter(ClientDetailSupporterListComponent);

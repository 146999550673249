import React from "react";
import { SearchListType, FlowchartItemContent, FlowchartItemType, AssetType } from "api/graphql/types";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { withDragContext, DragStart, ResponderProvided } from "../DragContext";
import { DragUtils, DragType } from "../DragUtils";
import { ContentInput, GenericComponentProps } from "./ContentInput";
import { ContentElement } from "pages/_shared/Draggables/ContentElement/ContentElement";
import { ContentElementButtons } from "pages/_shared/Draggables/ContentElement/ContentElementButtons";
import { Intl } from "i18n/Intl";
import { ContentElementUtils } from "../ContentElement/ContentElementUtils";
import { ImageSrc } from "utils/ImageSrc";
import { ApiTypes, AnyContentType } from "api/ApiTypes";
import { Draggable } from "../Draggable";
import { OverlayIcon } from "components/OverlayIcon";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ApiError, ApiErrorCode } from "api/ApiError";

interface ComponentProps extends GenericComponentProps<FlowchartItemContent> {
    branchA?: FlowchartItemContent[];
    onBranchAChange?: (value: FlowchartItemContent[]) => void;
    branchB?: FlowchartItemContent[];
    onBranchBChange?: (value: FlowchartItemContent[]) => void;
}

class FlowchartItemInputComponent extends ContentInput<FlowchartItemContent, ComponentProps> {
    protected searchListType: SearchListType = SearchListType.flowchartItem;
    protected slotDisabledMessage = undefined;
    protected slotMessage = (): string => Intl.formatMessage({ id: "sharedComponent.contentInput.flowchartItem.slotMessage" });

    protected addClicked(content: AnyContentType, dragType: DragType | null): boolean {
        if (!dragType || this.props.disabled) {
            return false;
        }

        if (!this.canAddSelect(dragType)) {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.FLOWCHART_ONLY_ONE_SELECTION_POINT_ENABLED)) });
            return false;
        }

        if (!this.canAddEmbedded(dragType)) {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.FLOWCHART_ONLY_ONE_EMBEDDED_FLOWCHART_ENABLED)) });
            return false;
        }

        if (dragType.searchListType === this.searchListType && !this.isMaxCountReached()) {
            this.props.onChange([...this.props.value, content as any]);
            if (this.slotRef) {
                this.slotRef.scrollIntoView();
            }
            return true;
        }
        return false;
    }

    private canAddSelect = (dragType: DragType | null): boolean => {
        const hasSelectType = this.props.value.some(v => v.flowchartType === FlowchartItemType.Select);
        if (hasSelectType && dragType?.searchListType === SearchListType.flowchartItem && dragType.flowchartItemType === FlowchartItemType.Select) {
            return false;
        }

        if (!this.props.branchA && dragType?.searchListType === SearchListType.flowchartItem && dragType.flowchartItemType === FlowchartItemType.Select) {
            return false;
        }

        return true;
    };

    private canAddEmbedded = (dragType: DragType | null): boolean => {
        const hasEmbedded = this.props.value.some(v => !!v.flowchart);
        if (dragType?.searchListType === SearchListType.flowchartItem && dragType.isEmbedded && (hasEmbedded || !this.props.branchA)) {
            return false;
        }
        return true;
    };

    protected isDropEnabled(initial: DragStart, _provided: ResponderProvided, dragType: DragType | null): boolean {
        if (((dragType && dragType.searchListType === this.searchListType) || initial.source?.droppableId === this.props.droppableId) && !this.props.disabled) {
            if (initial.source?.droppableId !== this.props.droppableId && this.isMaxCountReached()) {
                return false;
            }

            if (!this.canAddSelect(dragType)) {
                return false;
            }

            if (!this.canAddEmbedded(dragType)) {
                return false;
            }
            return true;
        }
        return false;
    }

    private renderSelect = (flowchartItem: FlowchartItemContent, index: number) => {
        const [a, b] = (flowchartItem.flowchartItems || []).map(item => item.flowchartItem);
        const assetA = a.image || a.video;
        const assetB = b.image || b.video;
        const reorder = this.getReorderOptions(index);
        return (
            <div className="select-flowchart">
                <div className="select-flowchart__top-section">
                    <div className="select-flowchart__top-section-header">
                        <h2 className="select-flowchart__top-section-heading no-top-margin">{flowchartItem.title}</h2>
                    </div>

                    <div className="row no-gutters">
                        <div className="col col-6">
                            <ContentElement
                                title={a.title}
                                images={assetA ? [ApiTypes.getAssetImageUrl(assetA)] : []}
                                fallbackImage={ImageSrc[this.searchListType]}
                                info={ContentElementUtils.getFlowchartItemContentInfo(a)}
                                infoInNewRow={true}
                                onCloseClick={() => this.onCloseClick(a as any, index)}
                                onViewClick={this.onViewClick(a as any)}
                                onEditClick={this.onEditClick(a as any)}
                                reorder={reorder}
                                isReorderDisabled={true}
                                disabled={this.props.disabled}
                                onPreviewClick={(): void => {
                                    store.dispatch(
                                        DialogsActions.show({
                                            type: DialogType.showAsset,
                                            assetUrl: assetA?.url,
                                            assetType: assetA?.assetType || AssetType.image,
                                            originalFileName: assetA?.title,
                                            thumbnailUrl: assetA?.thumbnailUrl,
                                            dialogTitle: assetA?.title || Intl.formatMessage({ id: `enum.assetType.${assetA?.assetType}` }),
                                        }),
                                    );
                                }}
                                overlayIcon={assetA ? <OverlayIcon assetType={assetA?.assetType} /> : undefined}
                            />
                        </div>

                        <div className="col col-6">
                            <ContentElement
                                title={b.title}
                                images={assetB ? [ApiTypes.getAssetImageUrl(assetB)] : []}
                                fallbackImage={ImageSrc[this.searchListType]}
                                info={ContentElementUtils.getFlowchartItemContentInfo(b)}
                                infoInNewRow={true}
                                onCloseClick={() => this.onCloseClick(b as any, index)}
                                onViewClick={this.onViewClick(b as any)}
                                onEditClick={this.onEditClick(b as any)}
                                isReorderDisabled={true}
                                reorder={reorder}
                                disabled={this.props.disabled}
                                onPreviewClick={(): void => {
                                    store.dispatch(
                                        DialogsActions.show({
                                            type: DialogType.showAsset,
                                            assetUrl: assetB?.url,
                                            assetType: assetB?.assetType || AssetType.image,
                                            originalFileName: assetB?.title,
                                            thumbnailUrl: assetB?.thumbnailUrl,
                                            dialogTitle: assetB?.title || Intl.formatMessage({ id: `enum.assetType.${assetB?.assetType}` }),
                                        }),
                                    );
                                }}
                                overlayIcon={assetB ? <OverlayIcon assetType={assetB?.assetType} /> : undefined}
                            />
                        </div>

                        {!this.props.disabled && (
                            <div className="col-12 agenda-group-selector__drag-space">
                                {reorder.upEnabled && (
                                    <div>
                                        <button
                                            className="fa fa-sort-up agenda-group-selector__drag-space-button"
                                            type="button"
                                            aria-label={Intl.formatMessage({ id: "common.up" })}
                                            title={Intl.formatMessage({ id: "common.up" })}
                                            onClick={() => reorder.call(true)}
                                        />
                                    </div>
                                )}
                                {reorder.downEnabled && (
                                    <div>
                                        <button
                                            className="fa fa-sort-down agenda-group-selector__drag-space-button"
                                            type="button"
                                            aria-label={Intl.formatMessage({ id: "common.down" })}
                                            title={Intl.formatMessage({ id: "common.down" })}
                                            onClick={() => reorder.call(false)}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="select-flowchart__bottom-section">
                    <div className="row no-gutters">
                        <div className="col">
                            <FlowchartItemInput
                                droppableId="flowchart-1"
                                onChange={this.props.onBranchAChange!}
                                value={this.props.branchA!}
                                disabled={this.props.disabled}
                                onViewClick={this.props.onViewClick}
                                onEditClick={this.props.onEditClick}
                            />
                        </div>

                        <div className="col">
                            <FlowchartItemInput
                                droppableId="flowchart-2"
                                onChange={this.props.onBranchBChange!}
                                value={this.props.branchB!}
                                disabled={this.props.disabled}
                                onViewClick={this.props.onViewClick}
                                onEditClick={this.props.onEditClick}
                            />
                        </div>
                    </div>
                </div>

                <div className="select-flowchart__buttons-section">
                    <ContentElementButtons
                        onCloseClick={() => {
                            // TODO: refact it
                            this.onCloseClick(flowchartItem, index);
                            setTimeout(() => {
                                this.props.onBranchAChange!([]); // we need to wait setState finish on onCloseClick
                                setTimeout(() => {
                                    this.props.onBranchBChange!([]); // we need to wait setState finish on onBranchAChange
                                }, 200);
                            }, 200);
                        }}
                        onViewClick={this.onViewClick(flowchartItem)}
                        onEditClick={this.onEditClick(flowchartItem)}
                        isDeleteActive={!this.props.disabled}
                    />
                </div>
            </div>
        );
    };

    protected renderDraggable = (flowchartItem: FlowchartItemContent, index: number): React.ReactElement<any> => {
        const asset = flowchartItem.image || flowchartItem.video;
        return (
            <Draggable
                draggableId={`${this.props.droppableId}-flowchartItem-${index}-${flowchartItem.id}`}
                droppableId={this.props.droppableId}
                key={`flowchartItem-${index}-${flowchartItem.id}`}
                index={index}
                type={SearchListType.flowchartItem}
                isDragDisabled={this.isDragDisabled()}
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? "grabbed" : ""}
                        style={DragUtils.getStyle(provided.draggableProps.style, snapshot)}
                    >
                        {flowchartItem.flowchartType === FlowchartItemType.Simple && (
                            <ContentElement
                                title={flowchartItem.title}
                                images={asset ? [ApiTypes.getAssetImageUrl(asset)] : []}
                                fallbackImage={ImageSrc[this.searchListType]}
                                info={ContentElementUtils.getFlowchartItemContentInfo(flowchartItem)}
                                onCloseClick={() => this.onCloseClick(flowchartItem, index)}
                                flowchart={flowchartItem.flowchart}
                                onViewClick={this.onViewClick(flowchartItem)}
                                onEditClick={this.onEditClick(flowchartItem)}
                                reorder={this.getReorderOptions(index)}
                                disabled={this.props.disabled}
                                onPreviewClick={(): void => {
                                    store.dispatch(
                                        DialogsActions.show({
                                            type: DialogType.showAsset,
                                            assetUrl: asset?.url,
                                            assetType: asset?.assetType || AssetType.image,
                                            originalFileName: asset?.title,
                                            thumbnailUrl: asset?.thumbnailUrl,
                                            dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                        }),
                                    );
                                }}
                                overlayIcon={asset ? <OverlayIcon assetType={asset?.assetType} /> : undefined}
                            />
                        )}
                        {flowchartItem.flowchartType === FlowchartItemType.Select && this.renderSelect(flowchartItem, index)}
                    </div>
                )}
            </Draggable>
        );
    };
}

export const FlowchartItemInput = withDragContext(FlowchartItemInputComponent);

import React, { Component } from "react";
import "../Inputs.scss";

export interface RadioOption<T = {}> {
    id: string;
    label: string;
    value?: T;
}

interface Props<T> {
    label: string;
    value: string | null; // id of the selected item
    errorMessage?: string;
    options: Array<RadioOption<T>>;
    onChange: (selectedOption: RadioOption<T>) => void;
}

class RadioInput<T> extends Component<Props<T>> {
    /**
     * Get RadioOption from list by value
     * @param list Radio option list
     * @param value current value of selected radio option
     */
    public static getSelectRadioOption<T>(list: Array<RadioOption<T>>, value: T): RadioOption<T> {
        const current = list.find((option: RadioOption<T>): boolean => option.value === value);
        return current || list[0];
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const selectedOption: RadioOption<T> | undefined = this.props.options.find((option: RadioOption): boolean => option.id === value);
        if (selectedOption) {
            this.props.onChange(selectedOption);
        }
    };

    private renderOption = (option: RadioOption) => {
        const isChecked: boolean = this.props.value === option.id;
        return (
            <div key={option.id} className="radioInputOption">
                <label className="radio-wrapper">
                    <input type="radio" className="show-for-sr" value={option.id} checked={isChecked} onChange={this.onChange} />
                    {option.label}
                    <div className="radio-toggle"></div>
                </label>
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        const { label, errorMessage, options } = this.props;
        return (
            <div className="radio-group">
                {label && <h3>{label}</h3>}
                {options.map(this.renderOption)}
                {errorMessage && <div className="error-wrapper">{errorMessage}</div>}
            </div>
        );
    }
}

export { RadioInput };

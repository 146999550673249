import React, { Component } from "react";
import {
    Account,
    AccountType,
    AssetContent,
    AssetType,
    Checklist,
    SupportedClient,
    FlowchartContent,
    FlowchartItem,
    MoodMeterDisplayConfig,
    SearchListType,
    Timer,
    Notification,
    ItemAwardInput,
    Tag,
    FlowchartItemType,
    FlowchartItemContent,
    FlowchartItemFlowchartItemRel,
    FlowchartItem_instantAwards,
    ItemAwardType,
    LibraryContentShareRequestWithData,
} from "api/graphql/types";
import { Input } from "components/Inputs/Input/Input";
import { ChecklistInput, ChecklistValidationError } from "pages/_shared/Inputs/ChecklistInput";
import { MoodMeterDisplayConfigInput } from "pages/_shared/Inputs/MoodMeterDisplayConfigInput";
import { AwardInput, AwardValidationError } from "pages/_shared/Inputs/AwardInput";
import { TimerInput, TimerValidationError } from "pages/_shared/Inputs/TimerInput";
import { cloneDeep } from "apollo-utilities";
import isEqual from "lodash/isEqual";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Page } from "components/Page";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { PageType } from "utils/TypeUtils";
import { Alert } from "components/Alert/Alert";
import { NotificationType } from "components/NotificationBar/Notification";
import { Intl } from "i18n/Intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TestId } from "utils/TestId";
import { ContentPageButtons } from "pages/_shared/ContentPageButtons/ContentPageButtons";
import { ContentPageUtils, PageContent } from "pages/_shared/ContentPageUtils";
import { ClientAdditionalFunctions } from "api/ApiTypes";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { MultipleAssetInputWrapper } from "components/MultipleAssetInputWrapper/MultipleAssetInputWrapper";
import { NotificationInput } from "pages/_shared/Inputs/NotificationInput";
import { isNil } from "lodash";
import { FlowchartRelInput } from "../_shared/Inputs/FlowchartRelInput";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import { ApplicationState } from "reducers";
import { Select } from "components/Inputs/Select/Select";
import { FlowchartItemTypeSelectOptions, FlowchartItemTypeSelectOption } from "models/FlowchartItemTypeSelectOptions";
import { FlowchartItemInput } from "pages/_shared/Draggables/Input/FlowchartItemInput";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { Log } from "utils/Log";
import { LibraryContentShareRequestSection } from "pages/_shared/LibraryShareRequestSection/LibraryContentShareRequestSection";

interface ReduxProps {
    tags: Tag[];
}

interface ComponentProps {
    isLoading: boolean;
    myAccount: Account;
    client?: SupportedClient | null;
    pageType: PageType;
    flowchartItem: FlowchartItem;
    clientAdditionalFunctions: ClientAdditionalFunctions | null;
    onSubmit: (flowchartItem: FlowchartItem) => Promise<FlowchartItem>;
    onEditClick: () => void;
    onToggled: () => void;

    clientExtId?: string | null;
    supporterExtId?: string | null;
    supervisorId?: string | null;
    pageContent: PageContent;
    accountType: AccountType;
    refreshFlowchartItem: () => void;

    shareRequest?: LibraryContentShareRequestWithData;
}

interface Errors {
    title: string | null;
    checklist: ChecklistValidationError | null;
    award: AwardValidationError | null;
    notification: string | null;
    instantAwards: string | null;
    timer: TimerValidationError | null;
    flowchartItems: string | null;
}

interface State {
    isLoading: boolean;
    flowchartItem: FlowchartItem;
    errors: Errors;
    isFlowchartRelEnabled: boolean;
    isValidationEnabled: boolean;
    isPromptDisabled: boolean;
    isTogglerDialogVisible: boolean;
}

type Props = ReduxProps & ComponentProps & RouteComponentProps & DispatchProp;

class FlowchartItemFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;
    private checklistRef: ChecklistInput | null = null;
    private timerRef: TimerInput | null = null;
    private awardRef: AwardInput | null = null;
    private notificationRef: NotificationInput | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            flowchartItem: cloneDeep(props.flowchartItem),
            errors: {
                title: null,
                checklist: null,
                award: null,
                instantAwards: null,
                notification: null,
                timer: null,
                flowchartItems: null,
            },
            isFlowchartRelEnabled: !!props.flowchartItem.flowchart,
            isValidationEnabled: false,
            isPromptDisabled: false,
            isTogglerDialogVisible: false,
            isLoading: false,
        };
    }

    public readonly state: State = FlowchartItemFormComponent.getInitialStateFromProps(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.flowchartItem !== nextProps.flowchartItem) {
            this.setState(FlowchartItemFormComponent.getInitialStateFromProps(nextProps));
        }
    }

    private shouldBadConfigAlertOpen = (): void => {
        if (!this.props.clientAdditionalFunctions) {
            return;
        }

        const badConfigItems: string[] = [];

        if (!this.props.flowchartItem.moodMeter && this.state.flowchartItem.moodMeter && !this.props.clientAdditionalFunctions.isMoodMeterActive) {
            badConfigItems.push(Intl.formatMessage({ id: "page.flowchartItem.badConfigInput.moodMeter" }));
        }

        if (badConfigItems.length > 0) {
            Alert.info({ title: Intl.formatMessage({ id: "page.flowchartItem.badConfigInputAlert" }, { badConfigItems: badConfigItems.join(", ") }) });
        }
    };

    private onSubmitClick = (): void => {
        const { errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartItemTitle(this.state.flowchartItem.title));

        const instantAwards: string | null =
            this.state.flowchartItem.award?.type === ItemAwardType.instant && !isNil(this.state.flowchartItem.instantAwards)
                ? IntlHelpers.getValidationError(Validator.validateInstantAwardsNumber(this.state.flowchartItem.instantAwards.length))
                : null;

        if (this.state.flowchartItem.flowchartType === FlowchartItemType.Simple) {
            const isChecklistHasError: boolean = !!errors.checklist && !!errors.checklist.items && errors.checklist.items.some((i: string | null) => i !== null);
            const isAwardHasError: boolean = !!errors.award && !!errors.award.score;
            const isTimerHasError: boolean = !!errors.timer && !!errors.timer.seconds;
            const notificationError: boolean = !!errors.notification;
            if (isAwardHasError || errors.title !== null || isChecklistHasError || titleError || isTimerHasError || instantAwards || notificationError) {
                this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError, instantAwards } });
                if (errors.title && this.titleRef) {
                    this.titleRef.focus();
                } else if (isChecklistHasError && this.checklistRef) {
                    this.checklistRef.focus();
                } else if (isTimerHasError && this.timerRef) {
                    this.timerRef.focus();
                } else if ((isAwardHasError || errors.instantAwards) && this.awardRef) {
                    this.awardRef.focus();
                } else if (notificationError && this.notificationRef) {
                    this.notificationRef.focus();
                }
                return;
            }
            this.shouldBadConfigAlertOpen();
        } else if (this.state.flowchartItem.flowchartType === FlowchartItemType.Select) {
            const flowchartItemsError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartItemFlowchartItems(this.state.flowchartItem.flowchartItems || []));
            if (flowchartItemsError || titleError) {
                this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, flowchartItems: flowchartItemsError, title: titleError } });
                return;
            }
        } else {
            Log.warning("Unhandled FlowchartItemType", this.state.flowchartItem.flowchartType);
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const flowchartItem = await this.props.onSubmit(this.state.flowchartItem);
                    this.setState({ flowchartItem, isPromptDisabled: true, isLoading: false });
                    if (this.props.pageType === PageType.create) {
                        const path: string | undefined = ContentPageUtils.getEditPath(flowchartItem, this.props.pageContent, this.props.client ? this.props.client.extId : undefined);
                        if (path) {
                            this.props.history.push(path);
                        }
                    }
                    if (this.pageRef) {
                        this.pageRef.showNotification({ message: Intl.formatMessage({ id: "page.flowchartItem.saveSucceed" }), type: NotificationType.success });
                    }
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onAssignClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.assignToClient,
                content: this.props.flowchartItem,
            }),
        );
    };

    private onShareClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.shareContent,
                content: this.props.flowchartItem,
                onSucceed: this.props.refreshFlowchartItem,
            }),
        );
    };

    private onToggleClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                onToggled: this.onToggled,
                content: this.props.flowchartItem,
            }),
        );
    };

    private onDeleteClick = () => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteFlowchartItem,
                onDeleted: () => {
                    this.props.history.push(ContentPageUtils.getRootPath(this.props.match.path, this.props.clientExtId, this.props.supporterExtId, this.props.supervisorId));
                },
                flowchartItem: this.props.flowchartItem,
            }),
        );
    };

    private onSyncClick = (): void => {
        if (!this.props.client) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.sync,
                client: this.props.client,
                close: this.props.refreshFlowchartItem,
            }),
        );
    };

    private onRelAssetDisable = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.disableRelatingAssets,
                content: this.props.flowchartItem,
                clientId: this.props.client?.id,
                onDisabled: this.props.refreshFlowchartItem,
            }),
        );
    };

    public renderPageButtons = (): React.ReactElement<any> | null => {
        if (this.props.pageContent === PageContent.shareRequest) {
            return null;
        }
        return (
            <>
                {this.props.flowchartItem.disabledAt && (
                    <span className="badge badge-danger">
                        <i className="fa fa-lock" />
                        {Intl.formatMessage({ id: "common.disabledContent" })}
                    </span>
                )}
                <ContentPageButtons
                    pageContent={this.props.pageContent}
                    content={this.props.flowchartItem}
                    isSaveDisabled={!this.isChanged()}
                    myAccount={this.props.myAccount}
                    pageType={this.props.pageType}
                    onShareClick={this.onShareClick}
                    onAssignClick={this.onAssignClick}
                    onEditClick={this.props.onEditClick}
                    onSubmitClick={this.onSubmitClick}
                    onToggleClick={this.onToggleClick}
                    onDeleteClick={this.onDeleteClick}
                    onSyncClick={this.onSyncClick}
                    onRelAssetDisable={this.onRelAssetDisable}
                />
            </>
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartItemTitle(title));
        this.setState({ flowchartItem: { ...this.state.flowchartItem, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onAssetChange = (assets: AssetContent[], assetType: AssetType): void => {
        const assetContent: AssetContent | null = assets.length > 0 ? assets[0] : null;
        switch (assetType) {
            case AssetType.audio: {
                this.setState({ flowchartItem: { ...this.state.flowchartItem, audio: assetContent } });
                break;
            }
            case AssetType.video: {
                this.setState({ flowchartItem: { ...this.state.flowchartItem, video: assetContent } });
                break;
            }
            case AssetType.image: {
                this.setState({ flowchartItem: { ...this.state.flowchartItem, image: assetContent } });
            }
        }
    };

    private readonly onTagChange = (tags: string[]) => {
        this.setState({
            flowchartItem: { ...this.state.flowchartItem, tags },
        });
    };

    public isChanged = (): boolean => {
        return !isEqual(this.state.flowchartItem, this.props.flowchartItem);
    };

    private shouldPreventRouteChange = (): boolean => {
        if (this.props.pageType !== PageType.view && !this.state.isPromptDisabled) {
            return this.isChanged();
        }
        return false;
    };

    private getFlowchartTotalTimer(flowchart: FlowchartContent): number {
        return (
            flowchart.itemList?.reduce((sum, item): number => {
                return sum + (item.flowchartItem.timer?.seconds || 0);
            }, 0) || 0
        );
    }

    private onFlowchartInputChange = (flowchart: FlowchartContent | null) => {
        const { flowchartItem } = this.state;
        if (flowchart && flowchartItem.timer && flowchartItem.timer.seconds < this.getFlowchartTotalTimer(flowchart)) {
            Alert.info({ title: Intl.formatMessage({ id: "page.flowchartItem.data.flowchart.timerWarning" }) });
        }
        this.setState({ flowchartItem: { ...flowchartItem, flowchart } });
    };

    private onToggled = (): void => {
        this.props.onToggled();
    };

    private onAwardInputChange = (award: ItemAwardInput | null, errors: AwardValidationError | null) => {
        this.setState({
            flowchartItem: {
                ...this.state.flowchartItem,
                award: !isNil(award)
                    ? {
                          ...award,
                          __typename: "ItemAward",
                          score: award.type === ItemAwardType.instant ? null : award.score || 1,
                          useTimer: award.type === ItemAwardType.score ? false : award.useTimer || false,
                      }
                    : null,
                instantAwards: award?.type === ItemAwardType.score ? [] : this.state.flowchartItem.instantAwards,
            },
            errors: {
                ...this.state.errors,
                award: !isNil(errors) ? { ...errors, score: award?.type === ItemAwardType.instant ? null : errors.score } : null,
                instantAwards: award?.type === ItemAwardType.score ? null : this.state.errors.instantAwards,
            },
        });
    };

    private onFlowchartItemTypeChange = (flowchartItemType: FlowchartItemTypeSelectOption): void => {
        if (flowchartItemType.value === FlowchartItemType.Simple) {
            this.setState({
                isValidationEnabled: false,
                flowchartItem: {
                    ...this.state.flowchartItem,
                    flowchartType: flowchartItemType.value,
                    flowchartItems: [],
                },
            });
        } else {
            this.setState({
                isValidationEnabled: false,
                flowchartItem: {
                    ...this.state.flowchartItem,
                    flowchartType: flowchartItemType.value,
                    image: null,
                    audio: null,
                    video: null,
                    moodMeter: null,
                    notification: null,
                    timer: null,
                    checklist: null,
                    award: null,
                    flowchart: null,
                },
            });
        }
    };

    private onFlowchartItemFlowchartItemsChange = (items: FlowchartItemContent[]): void => {
        const flowchartItems: FlowchartItemFlowchartItemRel[] = [];

        if (!isNil(items[0])) {
            flowchartItems.push({
                branch: 1,
                flowchartItem: items[0],
                position: 1,
                __typename: "FlowchartItemFlowchartItemRel",
            });
        }

        if (!isNil(items[1])) {
            flowchartItems.push({
                branch: 2,
                flowchartItem: items[1],
                position: 2,
                __typename: "FlowchartItemFlowchartItemRel",
            });
        }

        const flowchartItemsError: string | null = IntlHelpers.getValidationError(Validator.validateFlowchartItemFlowchartItems(flowchartItems));
        this.setState({
            flowchartItem: { ...this.state.flowchartItem, flowchartItems },
            errors: {
                ...this.state.errors,
                flowchartItems: flowchartItemsError,
            },
        });
    };

    private onInstantAwardsChange = (instantAwards: FlowchartItem_instantAwards[]) => {
        let instantAwardError: string | null = null;
        if (this.state.flowchartItem.award?.type === ItemAwardType.instant) {
            instantAwardError = IntlHelpers.getValidationError(Validator.validateInstantAwardsNumber(instantAwards.length));
        }
        this.setState({ flowchartItem: { ...this.state.flowchartItem, instantAwards }, errors: { ...this.state.errors, instantAwards: instantAwardError } });
    };

    public renderContent(): React.ReactElement<any> {
        const { flowchartItem, errors, isValidationEnabled } = this.state;
        const disabled: boolean = this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest;
        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.flowchartItem.data.title" })}>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchartItem.data.type.label" })}>
                        <Select
                            options={FlowchartItemTypeSelectOptions.get()}
                            value={Select.getSelectOption(FlowchartItemTypeSelectOptions.get(), flowchartItem.flowchartType)}
                            onChange={this.onFlowchartItemTypeChange}
                            disabled={disabled || this.props.pageType !== PageType.create}
                        />
                    </InputWrapper>

                    <hr />

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.flowchartItem.data.name.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                        <Input
                            innerRef={(ref: HTMLInputElement | null): void => {
                                this.titleRef = ref;
                            }}
                            type="text"
                            value={flowchartItem.title}
                            placeholder={Intl.formatMessage({ id: "page.flowchartItem.data.name.placeholder" })}
                            onChange={this.onTitleChange}
                            disabled={disabled}
                        />
                    </InputWrapper>

                    <hr />

                    {flowchartItem.flowchartType === FlowchartItemType.Simple && (
                        <MultipleAssetInputWrapper
                            items={flowchartItem}
                            label={Intl.formatMessage({ id: "page.flowchartItem.data.media.placeholder" })}
                            onAssetChange={this.onAssetChange}
                            disabled={disabled}
                        />
                    )}
                    {flowchartItem.flowchartType === FlowchartItemType.Select && (
                        <>
                            <FlowchartItemInput
                                droppableId="flowchartItem"
                                onChange={this.onFlowchartItemFlowchartItemsChange}
                                value={flowchartItem.flowchartItems?.map(item => item.flowchartItem) || []}
                                maxItemCount={2}
                                minItemCount={2}
                                hasError={isValidationEnabled && !!errors.flowchartItems}
                                disabled={disabled}
                                onViewClick={(flowchart: FlowchartItemContent) => {
                                    const path: string | undefined = ContentPageUtils.getViewPath(
                                        flowchart,
                                        this.props.pageContent,
                                        this.props.clientExtId,
                                        this.props.supporterExtId,
                                        undefined,
                                        this.props.supervisorId,
                                    );
                                    if (path) {
                                        this.props.history.push(path);
                                    }
                                }}
                                onEditClick={(flowchart: FlowchartItemContent) => {
                                    const path: string | undefined = ContentPageUtils.getEditPath(flowchart, this.props.pageContent, this.props.clientExtId);
                                    if (path) {
                                        this.props.history.push(path);
                                    }
                                }}
                            />
                            {isValidationEnabled && errors.flowchartItems && <ErrorMessage message={errors.flowchartItems} />}
                        </>
                    )}

                    <hr />
                </Section>

                {flowchartItem.flowchartType === FlowchartItemType.Simple && (
                    <>
                        <hr />

                        <Section label={Intl.formatMessage({ id: "page.flowchartItem.data.flowchart.label" })}>
                            <FlowchartRelInput
                                testIds={TestId.agendaItemForm.flowchart}
                                value={flowchartItem.flowchart}
                                onChange={this.onFlowchartInputChange}
                                disabled={disabled}
                                enabled={this.state.isFlowchartRelEnabled}
                                onEnabledChange={enabled => {
                                    this.setState({ isFlowchartRelEnabled: enabled });
                                }}
                                onViewClick={(flowchart: FlowchartContent) => {
                                    const path: string | undefined = ContentPageUtils.getViewPath(
                                        flowchart,
                                        this.props.pageContent,
                                        this.props.clientExtId,
                                        this.props.supporterExtId,
                                        undefined,
                                        this.props.supervisorId,
                                    );
                                    if (path) {
                                        this.props.history.push(path);
                                    }
                                }}
                                onEditClick={(flowchart: FlowchartContent) => {
                                    const path: string | undefined = ContentPageUtils.getEditPath(flowchart, this.props.pageContent, this.props.clientExtId);
                                    if (path) {
                                        this.props.history.push(path);
                                    }
                                }}
                            />
                        </Section>

                        <Section label={Intl.formatMessage({ id: "page.flowchartItem.additions.title" })}>
                            <MoodMeterDisplayConfigInput
                                testIds={TestId.agendaItemForm.moodMeter}
                                value={flowchartItem.moodMeter}
                                onChange={(moodMeter: MoodMeterDisplayConfig | null) => {
                                    this.setState({ flowchartItem: { ...this.state.flowchartItem, moodMeter } });
                                }}
                                disabled={disabled}
                            />

                            <hr />

                            <NotificationInput
                                ref={(ref: NotificationInput | null): void => {
                                    this.notificationRef = ref;
                                }}
                                error={this.state.errors.notification}
                                isValidationEnabled={isValidationEnabled}
                                value={flowchartItem.notification}
                                onChange={(notification: Notification | null, error: string | null) => {
                                    this.setState({ flowchartItem: { ...this.state.flowchartItem, notification }, errors: { ...this.state.errors, notification: error } });
                                }}
                                disabled={disabled}
                            />

                            <hr />

                            <ChecklistInput
                                ref={(ref: ChecklistInput | null): void => {
                                    this.checklistRef = ref;
                                }}
                                isValidationEnabled={isValidationEnabled}
                                value={flowchartItem.checklist}
                                errors={errors.checklist}
                                onChange={(checklist: Checklist | null, errors: ChecklistValidationError) => {
                                    this.setState({
                                        flowchartItem: { ...this.state.flowchartItem, checklist },
                                        errors: { ...this.state.errors, checklist: errors },
                                    });
                                }}
                                addLabel={Intl.formatMessage({ id: "page.flowchartItem.checklistAdd" })}
                                disabled={disabled}
                            />

                            <hr />

                            <TimerInput
                                ref={(ref: TimerInput | null): void => {
                                    this.timerRef = ref;
                                }}
                                testIds={{}}
                                value={flowchartItem.timer}
                                isValidationEnabled={isValidationEnabled}
                                onChange={(timer: Timer | null, errors: TimerValidationError | null) => {
                                    this.setState({ flowchartItem: { ...this.state.flowchartItem, timer }, errors: { ...this.state.errors, timer: errors } });
                                }}
                                disabled={disabled}
                                errors={errors.timer}
                            />
                            <hr />

                            <AwardInput
                                ref={(ref: AwardInput | null): void => {
                                    this.awardRef = ref;
                                }}
                                clientId={this.props.client?.id}
                                isClientPresent={!!this.props.client}
                                awardErrors={errors.award}
                                instantAwardErrors={errors.instantAwards}
                                isValidationEnabled={isValidationEnabled}
                                value={flowchartItem.award}
                                activeInstantAwards={flowchartItem.instantAwards}
                                onAwardChange={this.onAwardInputChange}
                                onInstantAwardsChange={this.onInstantAwardsChange}
                                disabled={disabled}
                                dispatch={this.props.dispatch}
                            />
                        </Section>
                    </>
                )}

                <Section label={Intl.formatMessage({ id: "page.asset.tagSection.title" })}>
                    <TagSelect tags={this.props.tags} selectedTags={flowchartItem.tags} onChange={this.onTagChange} disabled={disabled} renderTags={true} />
                </Section>

                {(this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest) && (
                    <>
                        <LibraryContentShareRequestSection shareRequest={this.props.shareRequest} content={this.props.flowchartItem} />
                    </>
                )}
                <Prompt when={this.shouldPreventRouteChange()} />
            </>
        );
    }

    private getDragIds(): string[] {
        const dragIds: string[] = [];
        if (this.state.flowchartItem.image) {
            dragIds.push(this.state.flowchartItem.image.id);
        }
        if (this.state.flowchartItem.audio) {
            dragIds.push(this.state.flowchartItem.audio.id);
        }
        if (this.state.flowchartItem.video) {
            dragIds.push(this.state.flowchartItem.video.id);
        }
        if (this.state.flowchartItem.flowchartType === FlowchartItemType.Select) {
            dragIds.push(...(this.state.flowchartItem.flowchartItems?.map(item => item.flowchartItem.id) || []));
        }
        return dragIds;
    }

    public render(): React.ReactElement<any> {
        const hideSidebar: boolean = this.props.pageType === PageType.view || this.props.pageContent === PageContent.shareRequest;
        let searchListTypes: SearchListType[] = [];
        if (this.state.flowchartItem.flowchartType === FlowchartItemType.Simple) {
            searchListTypes = [SearchListType.asset];
            if (this.state.isFlowchartRelEnabled) {
                searchListTypes.push(SearchListType.flowchart);
            }
        } else if (this.state.flowchartItem.flowchartType === FlowchartItemType.Select) {
            searchListTypes = [SearchListType.flowchartItem];
        }
        const title: string = ContentPageUtils.getContentPageTitleName(this.props);
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={(title ? title + " - " : "") + Intl.formatMessage({ id: `page.flowchartItem.title.${this.props.pageType}` })}
                renderButtons={this.renderPageButtons}
                hasSideBar={!hideSidebar}
            >
                <DragContainer
                    dragIds={this.getDragIds()}
                    hideSidebar={hideSidebar}
                    searchListTypes={searchListTypes}
                    assetTypes={this.state.flowchartItem.flowchartType === FlowchartItemType.Simple ? [AssetType.image, AssetType.video, AssetType.audio] : []}
                    createdById={this.props.myAccount.id}
                    client={searchListTypes.includes(SearchListType.flowchart) || this.state.flowchartItem.flowchartType === FlowchartItemType.Select ? this.props.client : undefined}
                    flowchartItemTypes={this.state.flowchartItem.flowchartType === FlowchartItemType.Select ? [FlowchartItemType.Simple] : undefined}
                >
                    {(this.props.isLoading || this.state.isLoading) && <Loading type={LoadingType.layer} />}
                    <div className="left-side">{!this.props.isLoading && this.renderContent()}</div>
                </DragContainer>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags };
};

export const FlowchartItemForm = withRouter(connect(mapStateToProps)(FlowchartItemFormComponent));

import { ApplicationState } from "reducers";
import { ColorOption } from "components/Inputs/ColorSelect/ColorOption";
import { Intl } from "i18n/Intl";
import { NamedColor, AppEventLogNameTitle, AppStatItemTypeTitle } from "api/graphql/types";
import { Log } from "utils/Log";
import { AppEventLogNameOption, AppEventLogNameSelectOptions } from "models/AppEventLogNameOptions";
import { AppStatItemTypeSelectOptions, AppStatItemTypeOption } from "models/AppStatItemTypeOptions";

export enum ColorType {
    unlock = "unlock",
    background = "background",
    itemBackground = "itemBackground",
    mainPageBackground = "mainPageBackground",
}

export class SettingsSelectors {
    private static getColorOptions(colors: Array<NamedColor | null>, withEmpty?: boolean): ColorOption[] {
        return (withEmpty ? [null, ...colors] : colors).map(
            (color: NamedColor | null): ColorOption => {
                if (color) {
                    return { id: color.value, label: color.name, value: color.value };
                }
                return { id: "empty", label: Intl.formatMessage({ id: "component.colorSelect.empty" }), value: null };
            },
        );
    }

    public static getUnlockColorOptions(state: ApplicationState, withEmpty?: boolean): ColorOption[] {
        return SettingsSelectors.getColorOptions(state.settings.unlockColors, withEmpty);
    }

    public static getBackgroundColorOptions(state: ApplicationState, withEmpty?: boolean): ColorOption[] {
        return SettingsSelectors.getColorOptions(state.settings.backgroundColors, withEmpty);
    }

    public static getItemBackgroundColorOptions(state: ApplicationState, withEmpty?: boolean): ColorOption[] {
        return SettingsSelectors.getColorOptions(state.settings.itemBackgroundColors, withEmpty);
    }

    public static getColorOptionsByType(state: ApplicationState, withEmpty: boolean | undefined, colorType: ColorType): ColorOption[] {
        switch (colorType) {
            case ColorType.unlock:
                return SettingsSelectors.getUnlockColorOptions(state, withEmpty);
            case ColorType.background:
            case ColorType.mainPageBackground:
                return SettingsSelectors.getBackgroundColorOptions(state, withEmpty);
            case ColorType.itemBackground:
                return SettingsSelectors.getItemBackgroundColorOptions(state, withEmpty);
            default:
                Log.warning("Unknown ColorType:", colorType);
                return [];
        }
    }

    public static getAppEventLogNameOptions(state: ApplicationState): AppEventLogNameOption[] {
        return AppEventLogNameSelectOptions.get(state).map((option: AppEventLogNameOption) => {
            return { ...option, label: state.settings.appEventLogNameTitles.find((title: AppEventLogNameTitle) => title.key === option.label)?.title || option.label };
        });
    }

    public static getAppStatItemTypeOption(state: ApplicationState): AppStatItemTypeOption[] {
        return AppStatItemTypeSelectOptions.get().map((option: AppStatItemTypeOption) => {
            return { ...option, label: state.settings.appStatItemTypeTitles.find((title: AppStatItemTypeTitle) => title.key === option.label)?.title || option.label };
        });
    }
}

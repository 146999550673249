import { Path } from "utils/Path";
import { LinkGroupItem } from "components/LinkGroup/LinkGroup";
import { ContentLibraryUrlQueryParser } from "pages/_shared/ContentLibraryTable/ContentLibraryUrlQueryParser";
import { SearchListType } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ExtraContentLibraryTypes } from "api/ApiTypes";
import { ObjectUtils } from "utils/ObjectUtils";

class ContentLibraryLinks {
    private static urlQueryParser = new ContentLibraryUrlQueryParser();

    public static getForClient(clientExtId: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${Path.clientContentLibrary(clientExtId)}?${this.urlQueryParser.getUrlQuery({ searchListType })}`,
                    isAsset: false,
                };
            },
        );
    }

    public static getForClientDetail(clientExtId: string): LinkGroupItem[] {
        const linkGroupItem: LinkGroupItem[] = [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${Path.clientDetailContentLibrary(clientExtId)}?${this.urlQueryParser.getUrlQuery({ searchListType })}`,
                    isAsset: false,
                };
            },
        );
        ObjectUtils.enumAsArray<ExtraContentLibraryTypes>(ExtraContentLibraryTypes).map((extraContentLibraryType: ExtraContentLibraryTypes): void => {
            linkGroupItem.push({
                label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${extraContentLibraryType}` }),
                toPage: `${Path.clientDetailContentLibrary(clientExtId)}?${this.urlQueryParser.getUrlQuery({ extraContentLibraryType })}`,
                isAsset: false,
            });
        });
        return linkGroupItem;
    }

    public static getForSupporter(): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart, SearchListType.asset].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${Path.publicContentLibrary}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: false })}`,
                    isAsset: searchListType === SearchListType.asset,
                };
            },
        );
    }

    public static getForSupporterDetail(supporterExtId: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart, SearchListType.asset].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${Path.supporterDetailContentLibrary(supporterExtId)}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: false })}`,
                    isAsset: searchListType === SearchListType.asset,
                };
            },
        );
    }

    public static getForSupervisorDetail(supervisorId: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart, SearchListType.asset].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${Path.supervisorDetailContentLibrary(supervisorId)}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: false })}`,
                    isAsset: searchListType === SearchListType.asset,
                };
            },
        );
    }

    public static getForSupervisor(path: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart, SearchListType.asset].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${path}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: false })}`,
                    isAsset: searchListType === SearchListType.asset,
                };
            },
        );
    }

    public static getForPublic(path: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart, SearchListType.asset].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${path}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: true })}`,
                    isAsset: searchListType === SearchListType.asset,
                };
            },
        );
    }

    public static getForSupervisorShareRequests(path: string): LinkGroupItem[] {
        return [SearchListType.agendaItem, SearchListType.flowchartItem, SearchListType.agenda, SearchListType.flowchart].map(
            (searchListType: SearchListType): LinkGroupItem => {
                return {
                    label: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.links.${searchListType}` }),
                    toPage: `${path}?${this.urlQueryParser.getUrlQuery({ searchListType, isPublic: false })}`,
                    isAsset: false,
                };
            },
        );
    }
}

export { ContentLibraryLinks };

import React, { PureComponent } from "react";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { AccountType, Agenda, AgendaItem, Flowchart, FlowchartItem, Account, CalendarAgenda } from "api/graphql/types";
import { PageType } from "utils/TypeUtils";
import { OptionsMenu, OptionMenuItem } from "components/OptionsMenu/OptionsMenu";
import { AnyContentListType, AnyContentType } from "api/ApiTypes";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { PageContent } from "../ContentPageUtils";
import { DisabledItemUtils } from "utils/DisabledItemUtils";

interface Props {
    myAccount: Account;
    content: Agenda | AgendaItem | Flowchart | FlowchartItem;
    pageContent: PageContent;
    pageType: PageType;
    isSaveDisabled: boolean;
    onEditClick?: () => void;
    onSubmitClick?: () => void;
    onSyncClick?: () => void;
    onAssignClick?: () => void;
    onShareClick?: () => void;
    onToggleClick?: () => void;
    onDeleteClick?: () => void;
    onRelAssetDisable?: () => void;
}

class ContentPageButtonsComponent extends PureComponent<Props> {
    private onCopyClick = async (content: AnyContentListType | AnyContentType | CalendarAgenda): Promise<void> => {
        try {
            await Api.copyContentToAccountPersonalLibrary(content);
            Alert.success({ title: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrarySucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private isSyncAvailable = (): boolean => {
        if (this.props.pageContent !== PageContent.client || !this.props.content.client) {
            return false;
        }

        const updatedAt: Date = new Date(this.props.content.updatedAt);
        const lastSync: Date = new Date(this.props.content.client.lastSyncDataGeneratedAt);
        return updatedAt.getTime() > lastSync.getTime();
    };

    private renderSupporterButtons(): React.ReactElement<any> {
        return (
            <>
                {!this.props.content.publishedAt &&
                    (this.props.pageType === PageType.view ? (
                        <Button ariaLabel={Intl.formatMessage({ id: "common.edit" })} icon={{ name: "fa-pencil-alt", large: true }} link onClick={this.props.onEditClick} />
                    ) : !this.props.isSaveDisabled || this.props.pageType === PageType.create ? (
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.save" })}
                            label={Intl.formatMessage({ id: "common.save" })}
                            icon={{ name: "fa-save", large: true, position: "left" }}
                            onClick={this.props.onSubmitClick}
                            disabled={this.props.isSaveDisabled}
                            className="save"
                        />
                    ) : (
                        <Button
                            ariaLabel={Intl.formatMessage({ id: "common.saved" })}
                            label={Intl.formatMessage({ id: "common.saved" })}
                            icon={{ name: "fa-save", large: true, position: "left" }}
                            onClick={this.props.onSubmitClick}
                            disabled={true}
                            className="saved"
                        />
                    ))}

                {!this.props.content.client && (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "sharedComponent.contentPageButtons.assignToClient" })}
                        icon={{ name: "fa-user-plus", large: true }}
                        link
                        onClick={this.props.onAssignClick}
                        disabled={!this.props.isSaveDisabled || this.props.pageType === PageType.create}
                    />
                )}
                {this.isSyncAvailable() && (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "sharedComponent.contentPageButtons.sync" })}
                        icon={{ name: "fa-sync", large: true }}
                        link
                        onClick={this.props.onSyncClick}
                        disabled={!this.props.isSaveDisabled}
                    />
                )}

                {!this.props.content.client && this.props.content.createdBy?.id === this.props.myAccount.id && this.props.onShareClick && (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "common.share" })}
                        icon={{ name: "fa-share-alt", large: true }}
                        link
                        onClick={this.props.onShareClick}
                        disabled={!this.props.isSaveDisabled || this.props.pageType === PageType.create}
                    />
                )}

                {((this.props.content.client && this.props.content.client.extId) || this.props.content.publishedAt) && this.props.pageType !== PageType.create && (
                    <OptionsMenu
                        items={[
                            {
                                label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                                icon: "fa-clone",
                                onClick: () => this.onCopyClick(this.props.content),
                            },
                        ]}
                    />
                )}
            </>
        );
    }

    private renderSupervisorButtons(): React.ReactElement<any> {
        if (this.props.content.createdBy?.id !== this.props.myAccount.id) {
            const items: OptionMenuItem[] = [];
            if (this.props.onToggleClick) {
                const isEnable = !!this.props.content.disabledAt;
                items.push({
                    label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }),
                    icon: isEnable ? "fa-unlock" : "fa-lock",
                    onClick: this.props.onToggleClick,
                });

                const relAssets = DisabledItemUtils.getRelatedAssets(this.props.content);
                if (relAssets.length > 0 && this.props.onRelAssetDisable) {
                    items.push({ label: Intl.formatMessage({ id: "component.extraContentOptionMenu.assetRelDisable" }), icon: "fa-lock", onClick: this.props.onRelAssetDisable });
                }
            }

            if (this.props.pageContent === PageContent.public && this.props.content.publishedAt) {
                items.push({
                    label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                    icon: "fa-clone",
                    onClick: () => this.onCopyClick(this.props.content),
                });
            }

            return <>{items.length > 0 && <OptionsMenu items={items} />}</>;
        }

        const items: OptionMenuItem[] = [{ label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }), icon: "fa-clone", onClick: () => this.onCopyClick(this.props.content) }];
        const { onDeleteClick } = this.props;
        if (onDeleteClick) {
            items.push({ label: Intl.formatMessage({ id: "common.delete" }), icon: "fa-trash", onClick: onDeleteClick });
        }

        return (
            <>
                {this.props.pageType === PageType.view ? (
                    <Button ariaLabel={Intl.formatMessage({ id: "common.edit" })} icon={{ name: "fa-pencil-alt", large: true }} link onClick={this.props.onEditClick} />
                ) : !this.props.isSaveDisabled || this.props.pageType === PageType.create ? (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "common.save" })}
                        label={Intl.formatMessage({ id: "common.save" })}
                        icon={{ name: "fa-save", large: true, position: "left" }}
                        onClick={this.props.onSubmitClick}
                        disabled={this.props.isSaveDisabled}
                        className="save"
                    />
                ) : (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "common.saved" })}
                        label={Intl.formatMessage({ id: "common.saved" })}
                        icon={{ name: "fa-save", large: true, position: "left" }}
                        onClick={this.props.onSubmitClick}
                        disabled={true}
                        className="saved"
                    />
                )}
                {!this.props.content.publishedAt && this.props.content.createdBy?.id === this.props.myAccount.id && this.props.onShareClick && (
                    <Button
                        ariaLabel={Intl.formatMessage({ id: "common.share" })}
                        icon={{ name: "fa-share-alt", large: true }}
                        link
                        onClick={this.props.onShareClick}
                        disabled={!this.props.isSaveDisabled}
                    />
                )}
                {((this.props.content && this.props.content.createdBy?.id !== this.props.myAccount.id) || this.props.content.publishedAt) && this.props.pageType !== PageType.create && (
                    <OptionsMenu items={items} />
                )}
            </>
        );
    }

    private renderAdminButtons(): React.ReactElement<any> {
        const items: OptionMenuItem[] = [];
        if (this.props.pageContent !== PageContent.supervisorDetail && this.props.pageContent !== PageContent.public && this.props.onToggleClick) {
            const isEnable = !!this.props.content.disabledAt;
            items.push({
                label: Intl.formatMessage({ id: isEnable ? "common.enable" : "common.disable" }),
                icon: isEnable ? "fa-unlock" : "fa-lock",
                onClick: this.props.onToggleClick,
            });

            const relAssets = DisabledItemUtils.getRelatedAssets(this.props.content);
            if (relAssets.length > 0 && this.props.onRelAssetDisable) {
                items.push({ label: Intl.formatMessage({ id: "component.extraContentOptionMenu.assetRelDisable" }), icon: "fa-lock", onClick: this.props.onRelAssetDisable });
            }
        }

        const { onDeleteClick } = this.props;
        if (onDeleteClick) {
            items.push({
                label: Intl.formatMessage({ id: "common.delete" }),
                icon: "fa-trash",
                onClick: onDeleteClick,
            });
        }

        return <>{items.length > 0 && <OptionsMenu items={items} />}</>;
    }

    public render(): React.ReactElement<any> | null {
        switch (this.props.myAccount.accountType) {
            case AccountType.supporter:
                return this.renderSupporterButtons();
            case AccountType.supervisor:
                return this.renderSupervisorButtons();
            case AccountType.admin:
            case AccountType.superadmin:
                return this.renderAdminButtons();
            default:
                return null;
        }
    }
}

export const ContentPageButtons: React.ComponentClass<Props> = ContentPageButtonsComponent;

import { SearchListType, AssetType, FlowchartItemType } from "api/graphql/types";
import { DraggableStateSnapshot } from "react-beautiful-dnd";

interface AssetDragType {
    searchListType: SearchListType.asset;
    assetType: AssetType;
}

interface ContentDragType<S extends SearchListType> {
    searchListType: S;
}

interface FlowchartItemDragType extends ContentDragType<SearchListType.flowchartItem> {
    flowchartItemType: FlowchartItemType;
    isEmbedded: boolean;
}

type DragType = AssetDragType | ContentDragType<SearchListType.agenda> | ContentDragType<SearchListType.agendaItem> | ContentDragType<SearchListType.flowchart> | FlowchartItemDragType;

class DragUtils {
    public static reorderArray<T>(list: T[], startIndex: number, endIndex: number): T[] {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    public static getStyle(style: any, snapshot: DraggableStateSnapshot): any {
        if (snapshot.isDragging) {
            return { ...style, backgroundColor: "white" };
        }
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: "0.001s",
        };
    }

    public static getDragId(item: { id: string }): string {
        return item.id;
    }

    public static getDragIds(items: Array<{ id: string }> | null): string[] {
        if (items === null) {
            return [];
        }
        return items.map(DragUtils.getDragId);
    }
}

export { DragUtils };
export type { DragType };

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { Section } from "components/Section";
import { IntlHelpers } from "i18n/IntlHelpers";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Api } from "api/Api";
import { EducationContentModule, EducationContentModule_subModules, EducationDocument } from "api/graphql/types";
import { Alert } from "components/Alert/Alert";
import { isNil, orderBy } from "lodash";
import { Path } from "utils/Path";
import { Loading, LoadingType } from "components/Loading/Loading";
import { DownloadExtension, KnowledgeBaseDownloadLink } from "components/KnowledgeBaseDownloadLink/KnowledgeBaseDownloadLink";
import { VideoList } from "components/VideoList/VideoList";
import { Link } from "react-scroll";

interface RouteParams {
    moduleId?: string;
}

type Props = RouteComponentProps<RouteParams>;

interface State {
    module: EducationContentModule | null;
    isLoading: boolean;
}

class ModuleTabComponent extends Component<Props, State> {
    public readonly state: Readonly<State> = {
        module: null,
        isLoading: true,
    };

    public componentDidMount(): void {
        const { moduleId } = this.props.match.params;
        if (!moduleId) {
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const module: EducationContentModule | undefined = await Api.getEducationModuleById(moduleId);
                    this.setState({ module: module!, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ module: null, isLoading: false });
                }
            },
        );
    }

    private renderSubModuleTabs = (module: EducationContentModule) => {
        return (
            <div className="sub-module-tabs">
                {(orderBy(module.subModules, "position", "asc") || []).map((subModule: EducationContentModule_subModules) => {
                    return (
                        <Link
                            key={subModule.id}
                            containerId="sub-module-tabs-container"
                            activeClass="sub-module-tab--is-active"
                            className="sub-module-tab"
                            to={`mod-${subModule.position}`}
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-48 - 40}
                        >
                            {module.position}.{subModule.position} {subModule.title}
                        </Link>
                    );
                })}
            </div>
        );
    };

    private renderContents = (module: EducationContentModule) => {
        if (module.subModules && module.subModules.length > 0) {
            return module.subModules.map((subModule: EducationContentModule_subModules) => {
                return subModule.videoContents.length > 0 || subModule.documentContents.length > 0 ? (
                    <div key={subModule.id} id={`mod-${subModule.position}`} className="sub-module-video-section">
                        <p className="small-text">
                            {Intl.formatMessage({ id: "page.education.tab.module.subModuleTitle" }, { position: `${module.position}.${subModule.position}`, title: subModule.title })}
                        </p>
                        <hr />
                        {subModule.documentContents.length > 0 && (
                            <>
                                <p>{Intl.formatMessage({ id: "page.education.tab.module.subModuleDocuments" }, { position: `${module.position}.${subModule.position}` })}</p>
                                {subModule.documentContents.map((content: EducationDocument) => {
                                    return <KnowledgeBaseDownloadLink key={content.id} id={content.id} url={content.url} title={content.title} extension={DownloadExtension.pdf} />;
                                })}
                            </>
                        )}
                        <VideoList key={subModule.id} modulePosition={module.position} subModulePosition={subModule.position} data={orderBy(subModule.videoContents, "position", "asc")} />
                    </div>
                ) : null;
            });
        }
        return <></>;
    };

    public render(): React.ReactElement {
        const { module, isLoading } = this.state;

        if (isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        if (isNil(module)) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div id="sub-module-tabs-container" className="left-side">
                <Section label={Intl.formatMessage({ id: "page.education.tab.module.mainSectionTitle" }, { position: module.position, title: module.title })}>
                    <p className="small-text">{Intl.formatMessage({ id: "page.education.tab.module.documentSectionTitle" }, { position: module.position })}</p>
                    <hr />
                    <p>{Intl.formatMessage({ id: "page.education.tab.module.documentSectionLead" }, { position: module.position })}</p>
                    {!isNil(module.documentContents[0]) && (
                        <KnowledgeBaseDownloadLink id={module.documentContents[0].id} url={module.documentContents[0].url} title={module.documentContents[0].title} extension={DownloadExtension.pdf} />
                    )}

                    {!isNil(module.documentContents[1]) && (
                        <>
                            <p style={{ marginTop: 16 }}>{Intl.formatMessage({ id: "page.education.tab.module.documentSectionLeadOtherDoc" }, { position: module.position })}</p>
                            <KnowledgeBaseDownloadLink
                                id={module.documentContents[1].id}
                                url={module.documentContents[1].url}
                                title={module.documentContents[1].title}
                                extension={DownloadExtension.pdf}
                            />
                        </>
                    )}
                    {this.renderSubModuleTabs(module)}

                    {this.renderContents(module)}
                </Section>
            </div>
        );
    }
}

export const ModuleTab = withRouter(ModuleTabComponent);

enum NotificationType {
    success = "success",
    info = "info",
    error = "error",
}

enum NotificationTimeout {
    normal = 1500,
    long = 4000,
}

interface Notification {
    type: NotificationType;
    message: string;
    timeout?: NotificationTimeout;
}

export { NotificationType, NotificationTimeout };
export type { Notification };

import React, { PureComponent } from "react";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { LoadingType, Loading } from "components/Loading/Loading";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ContentOptionMenu } from "pages/_shared/ContentOptionMenu/ContentOptionMenu";
import { SupportedClient, CalendarAgenda } from "api/graphql/types";
import { PageContent } from "pages/_shared/ContentPageUtils";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DispatchProp, connect } from "react-redux";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";

import "./Calendar.scss";
import { Intl } from "i18n/Intl";

interface ComponentProps {
    month: Date;
    client: SupportedClient;
}

type Props = ComponentProps & DispatchProp;

interface CalendarAgendas {
    [key: string]: CalendarAgenda;
}

interface State {
    start: Date;
    end: Date;
    isLoading: boolean;
    data: CalendarAgendas;
}

class CalendarComponent extends PureComponent<Props, State> {
    public readonly state: State = {
        isLoading: true,
        data: {},
        ...DateUtils.getCalendarIntervals(this.props.month),
    };

    public componentDidMount(): void {
        this.refreshData(this.props.client.id, this.props.month);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client.id !== nextProps.client.id || this.props.month !== nextProps.month) {
            this.refreshData(nextProps.client.id, nextProps.month);
        }
    }

    private refreshData = (clientId: string, month: Date): void => {
        const { start, end } = DateUtils.getCalendarIntervals(month);
        this.setState(
            { isLoading: true, start, end },
            async (): Promise<void> => {
                try {
                    const calendarAgendas: CalendarAgenda[] = await Api.getClientCalendar(clientId, start, end);
                    const data: CalendarAgendas = calendarAgendas.reduce((prevState: CalendarAgendas, current: CalendarAgenda): CalendarAgendas => {
                        if (current.day) {
                            prevState[current.day] = current;
                        }
                        return prevState;
                    }, {});
                    this.setState({ isLoading: false, data });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onDeleteClick = (agenda: CalendarAgenda) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteAgenda,
                onDeleted: async () => {
                    this.refreshData(this.props.client.id, this.props.month);
                },
                agenda,
            }),
        );
    };

    private onSyncClick = (): void => {
        if (!this.props.client) {
            return;
        }

        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.sync,
                client: this.props.client,
                close: () => this.refreshData(this.props.client.id, this.props.month),
            }),
        );
    };

    private renderDays(): Array<React.ReactElement<any>> {
        const weeks: Array<React.ReactElement<any>> = [];
        let days: Array<React.ReactElement<any>> = [];
        let currentDay: Date = DateUtils.addDays(this.state.start, 7);
        const lastDay: Date = DateUtils.addDays(this.state.end, -7);

        while (currentDay.getTime() <= lastDay.getTime()) {
            const dayOfMonth: number = currentDay.getDate();
            const agenda: CalendarAgenda | undefined = this.state.data[DateUtils.format(currentDay, DateFormat.default)] || this.props.client.defaultAgenda;
            const isDefaultAgenda = agenda && agenda === this.props.client.defaultAgenda;

            let isSynced: boolean = true;
            let icon = null;
            if (agenda) {
                const updatedAt: Date = new Date(agenda.updatedAt);
                const lastSync: Date = new Date(this.props.client.lastSyncDataGeneratedAt);
                isSynced = updatedAt.getTime() <= lastSync.getTime();

                if (isDefaultAgenda) {
                    icon = <span className="fab fa-adn clr-blue" />;
                } else if (isSynced) {
                    icon = <span className={"fa fa-check-circle success"} />;
                } else {
                    icon = (
                        <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientCalendar.syncRequired" })}>
                            <span className="fa fa-sync fs-25 clr-blue mr-20" />
                        </TooltipWrapper>
                    );
                }
            }

            days.push(
                <div key={dayOfMonth} className="cell">
                    <span className={`day${this.props.month.getMonth() === currentDay.getMonth() ? " current" : ""}${DateUtils.isSameDay(currentDay, new Date()) ? " today" : ""}`}>
                        <span className="count">{dayOfMonth}</span>
                        {agenda && (
                            <>
                                <div className="menu">
                                    <ContentOptionMenu
                                        pageContent={PageContent.client}
                                        clientExtId={this.props.client.extId}
                                        content={agenda}
                                        onDeleteClick={(): void => this.onDeleteClick(agenda)}
                                        isSyncAvailable={!isSynced}
                                        onSyncClick={this.onSyncClick}
                                    />
                                </div>
                                <span className="icon">{icon}</span>
                            </>
                        )}
                    </span>
                </div>,
            );

            if (days.length === 7) {
                weeks.push(
                    <div key={weeks.length} className="row">
                        {days}
                    </div>,
                );
                days = [];
            }

            currentDay = DateUtils.addDays(currentDay, 1);
        }
        return weeks;
    }

    private renderHeader(): React.ReactElement<any> {
        return (
            <div className="row">
                {IntlHelpers.getWeekdays().map(
                    (weekday: string): React.ReactElement<any> => {
                        return (
                            <div key={weekday} className="cell">
                                <h3>{weekday}</h3>
                            </div>
                        );
                    },
                )}
            </div>
        );
    }

    public render(): React.ReactElement<any> {
        return (
            <div className="calendar">
                <div className="calendar-header">{this.renderHeader()}</div>
                <div className="calendar-body">{this.renderDays()}</div>
                {this.state.isLoading && <Loading type={LoadingType.layer} />}
            </div>
        );
    }
}

export const Calendar = connect()(CalendarComponent);

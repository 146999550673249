import { ReduxAction } from "actions";
import { AuthActionType } from "actions/AuthActions";
import { SettingsActionType } from "actions/SettingsActions";
import { AllowedUploadContentTypes, AllowedUploadFileSizes, NamedColor, AppStatItemTypeTitle, AppEventLogNameTitle, AppEventLogCategoryNameTitle } from "api/graphql/types";

export interface SettingsReducerState {
    unlockColors: NamedColor[];
    backgroundColors: NamedColor[];
    itemBackgroundColors: NamedColor[];
    allowedUploadContentTypes: AllowedUploadContentTypes | null;
    allowedUploadFileSizes: AllowedUploadFileSizes | null;
    appStatItemTypeTitles: AppStatItemTypeTitle[];
    appEventLogNameTitles: AppEventLogNameTitle[];
    appEventLogCategoryNameTitles: AppEventLogCategoryNameTitle[];
    clientEverydaySituationDirectoryDefaultImageId: string | null;
}

export class SettingsReducer {
    private static readonly initialState: SettingsReducerState = {
        unlockColors: [],
        backgroundColors: [],
        itemBackgroundColors: [],
        allowedUploadContentTypes: null,
        allowedUploadFileSizes: null,
        appStatItemTypeTitles: [],
        appEventLogNameTitles: [],
        appEventLogCategoryNameTitles: [],
        clientEverydaySituationDirectoryDefaultImageId: null,
    };

    public static instance(state: SettingsReducerState = SettingsReducer.initialState, action: ReduxAction): SettingsReducerState {
        switch (action.type) {
            case SettingsActionType.updateSettings:
                return {
                    unlockColors: [...action.unlockColors],
                    backgroundColors: [...action.backgroundColors],
                    itemBackgroundColors: [...action.itemBackgroundColors],
                    allowedUploadContentTypes: action.allowedUploadContentTypes,
                    allowedUploadFileSizes: action.allowedUploadFileSizes,
                    appStatItemTypeTitles: [...action.appStatItemTypeTitles],
                    appEventLogNameTitles: [...action.appEventLogNameTitles],
                    appEventLogCategoryNameTitles: [...action.appEventLogCategoryNameTitles],
                    clientEverydaySituationDirectoryDefaultImageId: action.clientEverydaySituationDirectoryDefaultImageId,
                };
            case AuthActionType.logout:
                return { ...SettingsReducer.initialState };
            default:
                return state;
        }
    }
}

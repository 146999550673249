import React, { Component } from "react";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { AppStatItemListOptionsInput, AppStatItemType, Client } from "api/graphql/types";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Path } from "utils/Path";
import { ClientEventLogTable } from "pages/_shared/ClientEventLogTable/ClientEventLogTable";
import { AppStatItemResult } from "api/ApiTypes";
import { Formatter } from "utils/Formatter";

interface RouteParams {
    clientExtId?: string;
}

interface ComponentProps {
    client: Client;
}

type Props = ComponentProps & RouteComponentProps<RouteParams>;

interface State {
    isLoading: boolean;
}

class ClientDetailEventLogTabComponent extends Component<Props, State> {
    private getClientEventLog = async (dateTimeFrom: Date, dateTimeTo: Date, type?: AppStatItemType | null, options?: AppStatItemListOptionsInput): Promise<AppStatItemResult> => {
        try {
            return await Api.getClientDetailEventLog(this.props.match.params.clientExtId!, dateTimeFrom, dateTimeTo, type || undefined, options);
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            return { count: 0, aggregations: null, result: [] };
        }
    };

    public render(): React.ReactElement<any> | null {
        if (!this.props.match.params.clientExtId) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <div className="left-side">
                <ClientEventLogTable
                    clientId={this.props.client.id}
                    clientExtId={this.props.match.params.clientExtId}
                    clientName={Formatter.formatExtId(this.props.match.params.clientExtId)}
                    getClientEventLog={this.getClientEventLog}
                />
            </div>
        );
    }
}

export const ClientDetailEventLogTab = withRouter(ClientDetailEventLogTabComponent);

// tslint:disable max-line-length
import React from "react";
import { IconProps } from "./IconProps";

function SuccessIcon({ width, height }: IconProps): React.ReactElement {
    return (
        <svg viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <path
                d="M94.9981 0C42.6137 0 0 42.617 0 95C0 147.383 42.6137 190 94.9981 190C147.383 190 190 147.383 190 95C190 42.617 147.383 0 94.9981 0ZM94.9981 177.541C49.4855 177.541 12.4592 140.513 12.4592 95C12.4592 49.4869 49.485 12.4597 94.9981 12.4597C140.511 12.4597 177.539 49.4874 177.539 95C177.54 140.513 140.511 177.541 94.9981 177.541Z"
                fill="#00459B"
            />
            <path
                d="M129.393 63.8997L80.4056 112.885L60.6033 93.0865C58.1699 90.656 54.2278 90.6546 51.7944 93.088C49.36 95.5224 49.36 99.4648 51.7944 101.898L76.0018 126.102C77.2183 127.317 78.8119 127.925 80.4056 127.925C81.9992 127.925 83.5971 127.317 84.8131 126.1C84.8169 126.095 84.8197 126.09 84.8259 126.083L138.201 72.7086C140.636 70.2766 140.636 66.3312 138.201 63.8992C135.768 61.4658 131.823 61.4658 129.393 63.8997Z"
                fill="#00459B"
            />
        </svg>
    );
}

export { SuccessIcon };

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Button } from "components/Button/Button";
import { Intl } from "i18n/Intl";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { SupportedClient } from "api/graphql/types";
import { DialogType, DialogVisibilityProps } from "./DialogsContainer";
import { ApiErrorCode } from "../../api/ApiError";
import { DialogsActions } from "../../actions/DialogsActions";
import { connect, DispatchProp } from "react-redux";
import { AccountActions } from "../../actions/AccountActions";

export interface SyncDialogProps {
    client: SupportedClient;
    close: () => void;
}

type Props = SyncDialogProps & DialogVisibilityProps & DispatchProp;

interface State {
    isLoading: boolean;
}

class SyncDialogComponent extends Component<Props, State> {
    public readonly state: State = {
        isLoading: false,
    };

    private onClose = (): void => {
        this.props.close();
        this.props.onHide();
    };

    private onSubmit = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.syncClientAgendas(this.props.client.id);
                    const account = await Api.me();
                    this.props.dispatch(AccountActions.updateAccount(account));
                    Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.syncDialog.syncSucceed" }) });
                    this.setState({ isLoading: false }, this.onClose);
                } catch (error) {
                    if (error.code === ApiErrorCode.DISABLED_ENTITY_IN_SYNC_DATA) {
                        this.props.onHide();
                        this.props.dispatch(
                            DialogsActions.show({
                                type: DialogType.syncWarning,
                                client: this.props.client,
                            }),
                        );
                    } else {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                titleIcon="sync"
                title={Intl.formatMessage({ id: "sharedComponent.syncDialog.title" })}
                onCloseClick={!this.state.isLoading ? this.onClose : undefined}
            >
                <p>{Intl.formatMessage({ id: "sharedComponent.syncDialog.description" })}</p>
                <p>{Intl.formatMessage({ id: "sharedComponent.syncDialog.warning" })}</p>

                <hr />

                <div className="row buttons">
                    <div className="cell medium-6">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onClose} disabled={this.state.isLoading} />
                    </div>
                    <div className="cell medium-6 text-right">
                        <Button label={Intl.formatMessage({ id: "sharedComponent.syncDialog.sync" })} onClick={this.onSubmit} disabled={this.state.isLoading} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const SyncDialog = connect()(SyncDialogComponent);

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";
import { AgendaItemAlertDisplayType } from "api/graphql/types";
import { ObjectUtils } from "utils/ObjectUtils";

export enum AgendaItemAlertTypeOption {
    Once = "Once",
    Repeating = "Repeating",
}

export enum AgendaItemAlertNotificationsOption {
    Flash = "Flash",
    Vibrate = "Vibrate",
    FlashAndVibrate = "FlashAndVibrate",
    WithoutFlashAndVibrate = "WithoutFlashAndVibrate",
}

class AgendaItemAlertSelectOptions {
    public static readonly display: Array<SelectOption<AgendaItemAlertDisplayType | null>> = [
        { id: "null", label: Intl.formatMessage({ id: "enum.agendaItemAlert.display.null" }), value: null },
        ...ObjectUtils.enumAsArray<AgendaItemAlertDisplayType>(AgendaItemAlertDisplayType).map(
            (agendaItemAlertDisplayType: AgendaItemAlertDisplayType): SelectOption<AgendaItemAlertDisplayType> => {
                return {
                    id: agendaItemAlertDisplayType,
                    label: Intl.formatMessage({ id: `enum.agendaItemAlert.display.${agendaItemAlertDisplayType}` }),
                    value: agendaItemAlertDisplayType,
                };
            },
        ),
    ];

    public static readonly type: Array<SelectOption<AgendaItemAlertTypeOption>> = ObjectUtils.enumAsArray<AgendaItemAlertTypeOption>(AgendaItemAlertTypeOption).map(
        (alertTypeOption: AgendaItemAlertTypeOption): SelectOption<AgendaItemAlertTypeOption> => {
            return {
                id: alertTypeOption,
                label: Intl.formatMessage({ id: `enum.agendaItemAlert.type.${alertTypeOption}` }),
                value: alertTypeOption,
            };
        },
    );

    public static readonly notifications: Array<SelectOption<AgendaItemAlertNotificationsOption>> = ObjectUtils.enumAsArray<AgendaItemAlertNotificationsOption>(AgendaItemAlertNotificationsOption).map(
        (alertNotificationsOption: AgendaItemAlertNotificationsOption): SelectOption<AgendaItemAlertNotificationsOption> => {
            return {
                id: alertNotificationsOption,
                label: Intl.formatMessage({ id: `enum.agendaItemAlert.notifications.${alertNotificationsOption}` }),
                value: alertNotificationsOption,
            };
        },
    );
}

export { AgendaItemAlertSelectOptions };

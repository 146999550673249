import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { AppStatItemListOptionsInput, SupportedClient, AppStatItemType } from "api/graphql/types";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { Path } from "utils/Path";
import { connect, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ClientEventLogTable } from "pages/_shared/ClientEventLogTable/ClientEventLogTable";
import { AppStatItemResult } from "api/ApiTypes";

interface ReduxProps {
    client: SupportedClient | null;
}

interface RouteParams {
    clientExtId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps;

class ClientEventLogPageComponent extends Component<Props> {
    private getClientEventLog = (clientId: string) => async (
        dateTimeFrom: Date,
        dateTimeTo: Date,
        type?: AppStatItemType | null,
        options?: AppStatItemListOptionsInput,
    ): Promise<AppStatItemResult> => {
        return Api.getClientEventLog(clientId, dateTimeFrom, dateTimeTo, type || undefined, options);
    };

    public render(): React.ReactElement<any> | null {
        if (!this.props.client) {
            return <Redirect to={Path.dashboard} />;
        }

        return (
            <Page title={Intl.formatMessage({ id: "page.clientEventLog.title" }, { name: this.props.client.name })}>
                <div className="left-side">
                    <ClientEventLogTable
                        clientId={this.props.client.id}
                        clientExtId={this.props.client.extId}
                        clientName={this.props.client.name}
                        getClientEventLog={this.getClientEventLog(this.props.client.id)}
                    />
                </div>
            </Page>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState, props: ComponentProps): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    return { client };
};

export const ClientEventLogPage = withRouter(connect(mapStateToProps)(ClientEventLogPageComponent));

// tslint:disable-next-line: no-default-export
export default ClientEventLogPage;

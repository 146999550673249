import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { AgendaContent } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ObjectUtils } from "utils/ObjectUtils";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { Image } from "components/Image";
import { ImageSrc } from "utils/ImageSrc";
import { ContentOptionMenu } from "pages/_shared/ContentOptionMenu/ContentOptionMenu";

import "./DefaultAgenda.scss";
import { PageContent } from "pages/_shared/ContentPageUtils";

interface Props {
    agenda: AgendaContent | null;
}

enum DefaultAgendaTableColumn {
    title = "title",
    numberOfSteps = "numberOfSteps",
    updatedAt = "updatedAt",
    actions = "actions",
}

class DefaultAgenda extends Component<Props> {
    private static readonly columns: Array<Column<AgendaContent>> = ObjectUtils.enumAsArray<DefaultAgendaTableColumn>(DefaultAgendaTableColumn).map(
        (columnName: string): Column<AgendaContent> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.clientProfile.defaultAgenda.table.columns.${columnName}` }),
            accessor: columnName as keyof AgendaContent,
            renderCell: (agenda: AgendaContent): React.ReactElement<any> | null => {
                switch (columnName) {
                    case DefaultAgendaTableColumn.title:
                        return (
                            <div className="table-image-name">
                                <div className="table-image-container">
                                    <Image fallback={ImageSrc.agenda} />
                                </div>
                                {agenda.title}
                            </div>
                        );
                    case DefaultAgendaTableColumn.numberOfSteps:
                        return <>{agenda.itemList ? agenda.itemList.length : 0}</>;
                    case DefaultAgendaTableColumn.updatedAt:
                        return <>{DateUtils.format(new Date(agenda.updatedAt), DateFormat.yyyymmddhhmm)}</>;
                    case DefaultAgendaTableColumn.actions:
                        return <ContentOptionMenu pageContent={PageContent.own} content={agenda} clientExtId={agenda.client ? agenda.client.extId : undefined} />;
                    default:
                        return null;
                }
            },
        }),
    );

    public render(): React.ReactElement<any> {
        if (!this.props.agenda) {
            return (
                <p>
                    <strong>{Intl.formatMessage({ id: "page.clientProfile.defaultAgenda.noData" })}</strong>
                </p>
            );
        }
        return (
            <div className="default-agenda">
                <Table
                    keyExtractor={(item: AgendaContent, column?: Column<AgendaContent>): string => item.id + (column ? column.id : "")}
                    columns={DefaultAgenda.columns}
                    data={this.props.agenda ? [this.props.agenda] : []}
                    isLoading={false}
                    currentPage={1}
                    count={this.props.agenda ? 1 : 0}
                    isPaginationEnabled={false}
                    renderEmpty={(): string => Intl.formatMessage({ id: "page.clientProfile.defaultAgenda.noData" })}
                />
            </div>
        );
    }
}

export { DefaultAgenda };

import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store";
import { App } from "App";
import { PromptMessage } from "components/Prompt";
import { ObjectUtils } from "utils/ObjectUtils";
import { Log } from "utils/Log";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface State {
    callback: ((ok: boolean) => void) | null;
    promptMessage: PromptMessage | null;
}

class AppContainer extends React.Component<{}, State> {
    public readonly state: Readonly<State> = {
        callback: null,
        promptMessage: null,
    };

    private onLeaveConfirmDialogButtonClick = (ok: boolean) => (): void => {
        if (this.state.callback) {
            this.state.callback(ok);
        }
        this.setState({ promptMessage: null, callback: null });
    };

    private getUserConfirmation = (promptMessage: string, callback: (ok: boolean) => void) => {
        if (!ObjectUtils.isEnumContains<PromptMessage>(PromptMessage, promptMessage)) {
            Log.warning("Invalid promptMessage", promptMessage);
            return;
        }
        if (promptMessage === PromptMessage.samePath || promptMessage === PromptMessage.redirectedPath) {
            return callback(true);
        }

        this.setState({ promptMessage, callback }, () => {
            store.dispatch(
                DialogsActions.show({
                    type: DialogType.confirmLeave,
                    promptMessage: this.state.promptMessage,
                    onCancelClick: this.onLeaveConfirmDialogButtonClick(false),
                    onAcceptClick: this.onLeaveConfirmDialogButtonClick(true),
                }),
            );
        });
    };

    public render(): React.ReactElement<any> {
        return (
            <Provider store={store}>
                <Suspense fallback={null}>
                    <BrowserRouter getUserConfirmation={this.getUserConfirmation}>
                        <App />
                    </BrowserRouter>
                </Suspense>
            </Provider>
        );
    }
}

export { AppContainer };

import React, { PureComponent } from "react";
import debounce from "lodash/debounce";
import { Tag } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { TextUtils } from "utils/TextUtils";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Button } from "components/Button/Button";
import { Input } from "components/Inputs/Input/Input";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { isNil } from "lodash";

import "./SearchForm.scss";

interface ReduxProps {
    tags: Tag[];
}

interface ComponentProps {
    icon?: boolean;
    isLoading?: boolean;
    searchPlaceholder?: string;
    defaultValue?: string;
    withTags?: boolean;
    hideSelectedTags?: boolean;
    selectedTags?: string[];
    onSearchClick: (searchTerm: string, tags?: string[]) => Promise<void> | void;
    onTypeStarted?: () => void;
}

interface State {
    searchTerm: string;
    tagSearchResults: Tag[];
}

type Props = ComponentProps & ReduxProps;

class SearchFormComponent extends PureComponent<Props, State> {
    /**
     * Filter the tags based on the param term
     * @param term term to be matched agasinst with regexp
     */
    private filterTags = (term: string) => {
        const matches: Tag[] = [];
        this.props.tags.forEach(tag => {
            if (TextUtils.matchPartial(term).test(tag.title)) {
                matches.push(tag);
            }
        });
        return matches;
    };

    private getInitialTagSearchResults = (): Tag[] => {
        return this.filterTags(this.props.defaultValue || "");
    };

    public readonly state: State = {
        searchTerm: this.props.defaultValue || "",
        tagSearchResults: this.getInitialTagSearchResults(),
    };

    public componentWillReceiveProps(nextProps: Props) {
        if (this.props.defaultValue !== nextProps.defaultValue) {
            this.setState({ searchTerm: nextProps.defaultValue || "" });
        }
    }

    private submitDebounced = debounce(() => {
        this.props.onSearchClick(this.state.searchTerm, this.props.selectedTags);
    }, 500);

    private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.onSearchClick(this.state.searchTerm, this.props.selectedTags || []);
    };

    private onSearchTermChange = (): void => {
        if (this.props.onTypeStarted) {
            this.props.onTypeStarted();
        }
        this.submitDebounced();
    };

    private onCloseClick = () => {
        this.setState({ searchTerm: "" }, () => {
            this.props.onSearchClick("", []);
        });
    };

    private readonly isTagSelected = (tag: string): boolean => {
        return !isNil(this.props.selectedTags) && this.props.selectedTags.includes(tag);
    };

    private readonly isTagsOpen = (): boolean => {
        return this.state.searchTerm.length >= 3;
    };

    private readonly onTagSelect = (item: Tag) => {
        if (this.isTagSelected(item.title)) {
            return;
        }
        if (!isNil(this.props.selectedTags)) {
            this.setState({ searchTerm: "" }, () => {
                this.props.onSearchClick(this.state.searchTerm, [...this.props.selectedTags!, item.title]);
            });
        }
    };

    private readonly onTagSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm: string = event.currentTarget.value;
        const matches: Tag[] = this.filterTags(searchTerm);

        this.setState({ tagSearchResults: matches, searchTerm }, this.onSearchTermChange);
    };

    public static readonly renderOnlyTags = (tags: string[]): React.ReactElement => {
        return (
            <>
                {tags.map(tag => (
                    <div key={tag} className="tag-combo">
                        <span className="title">{tag}</span>
                    </div>
                ))}
            </>
        );
    };

    public readonly renderTags = (tags: string[]) => {
        return tags.map(tag => (
            <div key={tag} className="tag-combo">
                <span className="title">{tag}</span>
            </div>
        ));
    };

    private renderButton = (): React.ReactElement => {
        if (this.props.isLoading) {
            return <Loading type={LoadingType.input} />;
        }

        if (this.state.searchTerm === "") {
            return <Button type="submit" link icon={{ name: "fa-search", large: true }} title={Intl.formatMessage({ id: "common.search" })} />;
        }

        return <Button type="button" link icon={{ name: "fa-times", large: true }} title={Intl.formatMessage({ id: "common.delete" })} onClick={this.onCloseClick} />;
    };

    private getUnselectedTags = (): Tag[] => {
        return [
            ...this.state.tagSearchResults.filter((tagSearchResult: Tag) => {
                return !this.isTagSelected(tagSearchResult.title);
            }),
        ];
    };

    private readonly onRemoveTag = (tag: string): void => {
        if (this.props.selectedTags) {
            const tags = this.props.selectedTags.filter(selectedTag => selectedTag !== tag);
            this.props.onSearchClick(this.state.searchTerm, tags);
        }
    };

    private renderSelectedTags = (tag: string): React.ReactElement => {
        return (
            <div key={tag} className="tag-combo inline">
                <span className="tag-combo-title">{tag}</span>
                <button className="btn" onClick={() => this.onRemoveTag(tag)}>
                    <i className="fa fa-times" />
                </button>
            </div>
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="search-form">
                <form onSubmit={this.onSubmit}>
                    <div className="input-group">
                        <Input
                            className="form-control form-control-lg"
                            type="text"
                            value={this.state.searchTerm}
                            onChange={this.onTagSearch}
                            placeholder={this.props.searchPlaceholder}
                            name="search"
                            autoComplete="off"
                        />
                        {this.props.icon && <div className="input-group-btn">{this.renderButton()}</div>}
                        {this.props.withTags && this.isTagsOpen() && this.getUnselectedTags().length > 0 && (
                            <div className="tag-combo-input-group">
                                <div className="tag-combo-list-container">
                                    <div className="tag-input-container">
                                        <p className="tag-header">{Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.searchForm.tagHeader" })}</p>
                                    </div>
                                    <ul className="tag-combo-list">
                                        {this.getUnselectedTags().map((tagResult: Tag) => (
                                            <li key={tagResult.title} onClick={() => this.onTagSelect(tagResult)}>
                                                <div className="tag-combo">
                                                    <span className="tag-combo-title">{tagResult.title}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
                {this.props.selectedTags && this.props.selectedTags.length > 0 && !this.props.hideSelectedTags && (
                    <>
                        <p className="search-with-tag">{Intl.formatMessage({ id: "common.searchWithTag" })}</p>
                        {this.props.selectedTags.map(this.renderSelectedTags)}
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { tags: state.tags };
};

const SearchForm = connect(mapStateToProps)(SearchFormComponent);

export { SearchForm };

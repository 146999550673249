import React from "react";
import { AppStatItem, AppStatItemType } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { isNil } from "lodash";
import { Log } from "utils/Log";
import { IntlHelpers } from "i18n/IntlHelpers";

class ClientEventLogValueConverter {
    private static DEFAULT_EXPANDED_ROWS_COUNT: number = 2;

    private static getColumn = (type: AppStatItemType, columnName: string, additionalData?: any): React.ReactElement => {
        return <span>{Intl.formatMessage({ id: `sharedComponent.clientEventLogTable.valueConverter.column.${type}.${columnName}` }, additionalData)}</span>;
    };

    private static renderRow = (data: any, intlSection: AppStatItemType, intlKey: string, customDataRow?: React.ReactElement, customCondition?: boolean): React.ReactElement | null => {
        return customCondition || !isNil(data) ? (
            <tr>
                <td>{ClientEventLogValueConverter.getColumn(intlSection, intlKey)}</td>
                <td className="client-event-log-value-converter-table-data">{customDataRow || data}</td>
            </tr>
        ) : null;
    };

    private static durationToTime = (durationInSeconds: number): string => {
        let totalSeconds = durationInSeconds;
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    private static renderExpandableRows = (rows: Array<React.ReactElement | null>, isOpened: boolean, onOpen: () => void): React.ReactElement => {
        const filteredRows: React.ReactElement[] = rows.filter((row: React.ReactElement | null): row is React.ReactElement => row !== null);
        const alwaysVisibleRows: React.ReactElement[] = filteredRows.slice(0, ClientEventLogValueConverter.DEFAULT_EXPANDED_ROWS_COUNT);
        const hiddenRows: React.ReactElement[] = filteredRows.slice(ClientEventLogValueConverter.DEFAULT_EXPANDED_ROWS_COUNT);
        return (
            <>
                {alwaysVisibleRows.map((row: React.ReactElement, index: number) => {
                    return <React.Fragment key={`row-${index}`}>{row}</React.Fragment>;
                })}
                {isOpened &&
                    hiddenRows.map((row: React.ReactElement, index: number) => {
                        return <React.Fragment key={`hidden-row-${index}`}>{row}</React.Fragment>;
                    })}
                {filteredRows.length > ClientEventLogValueConverter.DEFAULT_EXPANDED_ROWS_COUNT + 1 && (
                    <tr onClick={onOpen}>
                        <td className="client-event-log-value-converter-table-show-more-container" colSpan={5}>
                            <span className="client-event-log-value-converter-table-show-more-text">
                                {Intl.formatMessage({ id: `sharedComponent.clientEventLogTable.valueConverter.${isOpened ? "showLessText" : "showMoreText"}` }) + " "}
                            </span>
                            <i className={`fa fa-chevron-${isOpened ? "up" : "down"}`} />
                        </td>
                    </tr>
                )}
            </>
        );
    };

    public static get(clientEventLog: AppStatItem, isOpened: boolean, onOpen: () => void): React.ReactElement {
        switch (clientEventLog.type) {
            case AppStatItemType.AppForeground:
            case AppStatItemType.AppBackground:
            case AppStatItemType.AccountLogin:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.success,
                                AppStatItemType.AccountLogin,
                                "success",
                                <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.success ? "yes" : "no"}` })}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.AgendaItemStateChange:
            case AppStatItemType.CheckListItemStateChange:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.id, AppStatItemType.AgendaItemStateChange, "id")}
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.checked,
                                AppStatItemType.AgendaItemStateChange,
                                "checked",
                                <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.checked ? "yes" : "no"}` })}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.Alert:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.type, AppStatItemType.Alert, "type", <>{clientEventLog.data?.type || ""}</>)}
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.message, AppStatItemType.Alert, "message")}
                        </tbody>
                    </table>
                );
            case AppStatItemType.AppBackground:
            case AppStatItemType.AppForeground:
            case AppStatItemType.AppReset:
                return <></>;
            case AppStatItemType.AppUnlock:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.success,
                                AppStatItemType.AppUnlock,
                                "success",
                                <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.success ? "yes" : "no"}` })}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.CheckFlowchartItem:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>{ClientEventLogValueConverter.renderRow(clientEventLog.data?.flowchartId, AppStatItemType.CheckFlowchartItem, "flowchartId")}</tbody>
                    </table>
                );
            case AppStatItemType.ClientSetup:
                return <></>;
            case AppStatItemType.CreateSituation:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.type,
                                AppStatItemType.CreateSituation,
                                "type",
                                <span>
                                    {Intl.formatMessage({ id: `sharedComponent.clientEventLogTable.valueConverter.column.${AppStatItemType.CreateSituation}.types.${clientEventLog.data?.type}` })}
                                </span>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.DeviceStat:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderExpandableRows(
                                [
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.battery, AppStatItemType.DeviceStat, "battery"),
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.charging,
                                        AppStatItemType.DeviceStat,
                                        "charging",
                                        <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.charging ? "yes" : "no"}` })}</>,
                                    ),
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.freeAppStorage,
                                        AppStatItemType.DeviceStat,
                                        "freeAppStorage",
                                        <>{IntlHelpers.getFileSize(clientEventLog.data.freeAppStorage)}</>,
                                    ),
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.network, AppStatItemType.DeviceStat, "network"),
                                ],
                                isOpened,
                                onOpen,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.DownloadAccountData:
            case AppStatItemType.DownloadAgendaData:
            case AppStatItemType.DownloadClientData:
            case AppStatItemType.UploadStats:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderExpandableRows(
                                [
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.duration,
                                        AppStatItemType.DownloadAccountData,
                                        "duration",
                                        <>{ClientEventLogValueConverter.durationToTime(clientEventLog.data.duration)}</>,
                                    ),
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.message,
                                        AppStatItemType.DownloadAccountData,
                                        "message",
                                        undefined,
                                        !isNil(clientEventLog.data?.message) && clientEventLog.data.message !== "",
                                    ),
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.size,
                                        AppStatItemType.DownloadAccountData,
                                        "size",
                                        <>{IntlHelpers.getFileSize(clientEventLog.data.size)}</>,
                                    ),
                                    ClientEventLogValueConverter.renderRow(
                                        clientEventLog.data?.success,
                                        AppStatItemType.DownloadAccountData,
                                        "success",
                                        <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.success ? "yes" : "no"}` })}</>,
                                    ),
                                ],
                                isOpened,
                                onOpen,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.MoodReport:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog,
                                AppStatItemType.MoodReport,
                                "results",
                                <span>{ClientEventLogValueConverter.getMoodReport(clientEventLog)}</span>,
                                true,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.ScoreChanged:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderExpandableRows(
                                [
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.flowchartItemId, AppStatItemType.ScoreChanged, "flowchartItemId"),
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.agendaItemId, AppStatItemType.ScoreChanged, "agendaItemId"),
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.score, AppStatItemType.ScoreChanged, "score"),
                                    ClientEventLogValueConverter.renderRow(clientEventLog.data?.balance, AppStatItemType.ScoreChanged, "balance"),
                                ],
                                isOpened,
                                onOpen,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.UploadPushToken:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.success,
                                AppStatItemType.UploadPushToken,
                                "success",
                                <>{Intl.formatMessage({ id: `common.${clientEventLog.data?.success ? "yes" : "no"}` })}</>,
                            )}
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.duration,
                                AppStatItemType.UploadPushToken,
                                "duration",
                                <>{ClientEventLogValueConverter.durationToTime(clientEventLog.data.duration)}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.UseAgenda:
            case AppStatItemType.UseChecklist:
            case AppStatItemType.UseEverydaySituation:
            case AppStatItemType.UseFlowchart:
            case AppStatItemType.UseSelectionBoard:
            case AppStatItemType.UseEducation:
            case AppStatItemType.UseIntroduction:
            case AppStatItemType.UseMoodMeter:
            case AppStatItemType.UseTimer:
            case AppStatItemType.UseVolumeMeter:
            case AppStatItemType.UseWaiting:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.id, AppStatItemType.UseAgenda, "id")}
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.duration,
                                AppStatItemType.UploadPushToken,
                                "duration",
                                <>{ClientEventLogValueConverter.durationToTime(clientEventLog.data.duration)}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.UseGame:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.title, AppStatItemType.UseGame, "title")}
                            {ClientEventLogValueConverter.renderRow(
                                clientEventLog.data?.duration,
                                AppStatItemType.UseGame,
                                "duration",
                                <>{ClientEventLogValueConverter.durationToTime(clientEventLog.data.duration)}</>,
                            )}
                        </tbody>
                    </table>
                );
            case AppStatItemType.UseNotification:
                return (
                    <table className="client-event-log-value-converter-table">
                        <tbody>
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.imageAssetId, AppStatItemType.UseNotification, "imageAssetId")}
                            {ClientEventLogValueConverter.renderRow(clientEventLog.data?.audioAssetId, AppStatItemType.UseNotification, "audioAssetId")}
                        </tbody>
                    </table>
                );
            case AppStatItemType.AwardAchieved:
            case AppStatItemType.InstantAwardAchieved:
            default:
                return <></>;
        }
    }

    private static getMoodReport(clientEventLog: AppStatItem): React.ReactElement<any> {
        let items: object[] = [];
        if (Array.isArray(clientEventLog.data.items)) {
            items = clientEventLog.data.items;
        } else {
            Log.warning("clientEventLog.data.items is not an array!", clientEventLog);
        }

        let moodAndIntensity: string = "";
        if (items.length > 0) {
            moodAndIntensity = `${Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.valueConverter.moodReport.emotionAndIntensity" })} `;
            moodAndIntensity += items
                .map((item: any) => {
                    return `${item.emotion ? Intl.formatMessage({ id: `enum.moodMeterEmotion.${item.emotion}` }) : ""} / ${!isNil(item.intensity) ? item.intensity : ""}`;
                })
                .join(", ");
        }

        const message: string = clientEventLog.data.message
            ? `${Intl.formatMessage({ id: "sharedComponent.clientEventLogTable.valueConverter.moodReport.message" })} ${clientEventLog.data.message}`
            : "";

        return (
            <>
                {moodAndIntensity}
                <br />
                {message}
            </>
        );
    }
}

export { ClientEventLogValueConverter };

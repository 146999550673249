import React from "react";
import { SortOrder } from "api/graphql/types";
import { TableFooter } from "./TableFooter";
import { Loading, LoadingType } from "components/Loading/Loading";
import { isNil } from "lodash";
import { ColumnHeader } from "./ColumnHeader";

import "./Table.scss";

interface SortBy {
    columnId?: string | null;
    order?: SortOrder;
}

interface Column<T> {
    /** Unique key for columns */
    id: string;

    /** label for column */
    name: string;

    /** custom className for columns. If value not set, using accessor */
    className?: string;

    /** item key accessor of type */
    accessor: keyof T;

    /** Render cell overwrite */
    renderCell?: (item: T, column: Column<T>) => React.ReactElement<any> | null;

    /** disable sorting on column */
    isNonSortable?: boolean;
}

interface Props<T> {
    /**
     * Extract unique key for columns and cells
     */
    keyExtractor: (item: T, column?: Column<T>) => string;

    /**
     * Render component after row
     */
    renderUnderRow?: (item: T) => React.ReactElement<any> | null;

    /**
     * When a column's header is pressed
     */
    onSortOrderChange?: (column?: Column<T>, sortOrder?: SortOrder) => void;

    /**
     * When a column's header is pressed
     */
    onPageChange?: (pageNumber: number) => Promise<void> | void;

    /**
     * Sort by which column and which direction
     */
    sortBy?: SortBy;

    /**
     * Columns of table
     */
    columns: Array<Column<T>>;

    limit?: number | null;

    /**
     * Data for table
     */
    data: T[];

    count: number;

    currentPage?: number;

    isPaginationEnabled?: boolean;

    isSortable?: boolean;

    isLoading: boolean;

    hideHeader?: boolean;

    /**
     * Empty component, when loading finished and count is 0
     */
    renderEmpty?: () => React.ReactElement<any> | string;

    onRowClick?: (item: T) => void;

    rowClassName?: (item: T) => string;

    renderItem?: (item: T, index: number, array: T[]) => React.ReactElement<any> | null;

    renderRow?: (item: T, index: number, children: React.ReactElement<any>) => React.ReactElement<any>;
}

class Table<T> extends React.Component<Props<T>> {
    public static readonly DEFAULT_PAGE_SIZE: number = 10;

    public static getCurrentPage(offset: number | null = null, limit: number | null = null, count: number): number {
        const currentPage: number = offset && limit ? Math.ceil(offset / limit) + 1 : 1;
        // return 1, when current offset overflow page count (eg.: on page 3 search for term and count change to 1 element)
        if (offset && limit && count + limit < currentPage * limit) {
            return 1;
        }
        return currentPage;
    }

    private renderCell = (item: T, column: Column<T>): React.ReactElement<any> => {
        const cell: React.ReactElement<any> | null = column.renderCell ? column.renderCell(item, column) : null;
        return (
            <span key={this.props.keyExtractor(item, column)} className={`table-cell ${column.className || column.accessor}`}>
                <span>{cell || item[column.accessor]}</span>
            </span>
        );
    };

    private renderRow = (item: T, index: number): React.ReactElement<any> => {
        const onRowClick = this.props.onRowClick
            ? () => {
                  this.props.onRowClick!(item);
              }
            : undefined;

        if (this.props.renderRow) {
            return this.props.renderRow(
                item,
                index,
                <React.Fragment>
                    {this.props.columns.map(
                        (column: Column<T>): React.ReactElement<any> => {
                            return this.renderCell(item, column);
                        },
                    )}
                </React.Fragment>,
            );
        }

        return (
            <React.Fragment key={this.props.keyExtractor(item)}>
                <div className={`table-row${onRowClick ? " clickable" : ""}${!isNil(this.props.rowClassName) ? " " + this.props.rowClassName(item) : ""}`} onClick={onRowClick}>
                    {this.props.columns.map(
                        (column: Column<T>): React.ReactElement<any> => {
                            return this.renderCell(item, column);
                        },
                    )}
                </div>
                {this.props.renderUnderRow && this.props.renderUnderRow(item)}
            </React.Fragment>
        );
    };

    private renderHeader = (): React.ReactElement<any> => {
        const sortBy: SortBy | undefined = this.props.sortBy;
        return (
            <div className="table-header">
                {this.props.columns.map(
                    (column: Column<T>): React.ReactElement<any> => (
                        <ColumnHeader
                            column={column}
                            key={column.id}
                            isSortable={!!this.props.isSortable && !column.isNonSortable}
                            sortOrder={sortBy && sortBy.columnId === column.id ? sortBy.order : undefined}
                            onChangeSort={this.props.onSortOrderChange || undefined}
                        />
                    ),
                )}
            </div>
        );
    };

    private renderBody = (): React.ReactElement => {
        if (!this.props.isLoading && this.props.count === 0 && this.props.renderEmpty) {
            const result: React.ReactElement | string = this.props.renderEmpty();
            if (typeof result === "string") {
                return (
                    <div className="empty">
                        <p>{result}</p>
                    </div>
                );
            }
            return result;
        }
        return <div className="table-body">{this.props.data.map(this.renderRow)}</div>;
    };

    private onPageChange = (newPage: number): void => {
        if (this.props.onPageChange) {
            this.props.onPageChange(newPage);
        }
    };

    public render(): React.ReactElement {
        return (
            <div className="table">
                {!this.props.hideHeader && this.renderHeader()}
                {this.renderBody()}
                {this.props.isPaginationEnabled && (
                    <TableFooter
                        currentPage={this.props.currentPage || 1}
                        pagesCount={Math.max(Math.ceil(this.props.count / (this.props.limit || Table.DEFAULT_PAGE_SIZE)), 1)}
                        onPageChange={this.onPageChange}
                    />
                )}
                {this.props.isLoading && <Loading type={LoadingType.layer} />}
            </div>
        );
    }
}

export { Table };
export type { Column };

import { PureComponent } from "react";

interface Props {
    children: string;
}

class Title extends PureComponent<Props> {
    public componentDidMount() {
        document.title = this.props.children;
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.children !== prevProps.children) {
            document.title = this.props.children;
        }
    }

    public render(): null {
        return null;
    }
}

export { Title };

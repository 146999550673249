import { SelectOption } from "components/Inputs/Select/Select";
import { MoodMeterDisplay } from "api/graphql/types";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";

class MoodMeterDisplayOptions {
    public static get(): Array<SelectOption<MoodMeterDisplay | null>> {
        return [
            { id: "null", label: Intl.formatMessage({ id: "enum.moodMeterDisplay.null" }), value: null },
            ...ObjectUtils.enumAsArray<MoodMeterDisplay>(MoodMeterDisplay).map(
                (moodMeterDisplayOption: MoodMeterDisplay): SelectOption<MoodMeterDisplay> => {
                    return {
                        id: moodMeterDisplayOption,
                        label: Intl.formatMessage({ id: `enum.moodMeterDisplay.${moodMeterDisplayOption}` }),
                        value: moodMeterDisplayOption,
                    };
                },
            ),
        ];
    }
}

export { MoodMeterDisplayOptions };

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";
import { ObjectUtils } from "utils/ObjectUtils";
import { AccountType } from "api/graphql/types";

export class AccountTypeSelectOptions {
    public static readonly get = (): Array<SelectOption<AccountType>> => {
        return ObjectUtils.enumAsArray<AccountType>(AccountType).map(
            (accountType: AccountType): SelectOption<AccountType> => {
                return { id: accountType, label: Intl.formatMessage({ id: `enum.accountType.${accountType}` }), value: accountType };
            },
        );
    };
}

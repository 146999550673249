import React, { Component } from "react";
import { PageType } from "utils/TypeUtils";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Prompt } from "components/Prompt";
import { isEqual, isNil } from "lodash";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { NotificationType } from "components/NotificationBar/Notification";
import { Validator, ValidatorConstants } from "utils/Validator";
import { Section } from "components/Section";
import { AssetType, AssetContent, SearchListType, Award } from "api/graphql/types";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { DragContainer } from "pages/_shared/Draggables/DragContainer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "utils/Path";
import { LocalClientAwardInput } from "./ClientAwardPage";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { NumberInput } from "components/Inputs/NumberInput/NumberInput";

interface ComponentProps {
    accountId: string;
    clientAward: LocalClientAwardInput;
    clientName: string;
    clientExtId: string;
    pageType: PageType;
    onSubmit: (clientAward: LocalClientAwardInput) => Promise<Award>;
}

type Props = ComponentProps & RouteComponentProps;

interface Errors {
    title: string | null;
    targetScore: string | null;
}

interface State {
    clientAward: LocalClientAwardInput;
    errors: Errors;
    isValidationEnabled: boolean;
}

class ClientAwardFormComponent extends Component<Props, State> {
    private pageRef: Page | null = null;
    private titleRef: HTMLInputElement | null = null;
    private targetScoreRef: HTMLInputElement | null = null;

    private static getInitialStateFromProps(props: Props): State {
        return {
            clientAward: {
                title: props.clientAward.title,
                image: props.clientAward.image,
                autoReactivation: props.clientAward.autoReactivation,
                targetScore: props.clientAward.targetScore || ValidatorConstants.AWARD_SCORE_MIN,
            },
            isValidationEnabled: false,
            errors: {
                title: null,
                targetScore: null,
            },
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = ClientAwardFormComponent.getInitialStateFromProps(props);
    }

    public isChanged = (): boolean => {
        return !isEqual(this.state.clientAward, ClientAwardFormComponent.getInitialStateFromProps(this.props).clientAward);
    };

    private onCancelClick = (): void => {
        this.setState({ clientAward: ClientAwardFormComponent.getInitialStateFromProps(this.props).clientAward, isValidationEnabled: false, errors: { ...this.state.errors, title: null } });
    };

    private onSubmit = async (): Promise<void> => {
        const { clientAward, errors } = this.state;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateClientAwardTitle(clientAward.title || ""));
        const targetScoreError: string | null = IntlHelpers.getValidationError(Validator.validateClientAwardTargetScore(clientAward.targetScore));
        if (errors.title !== null || titleError || errors.targetScore !== null || targetScoreError) {
            this.setState({ isValidationEnabled: true, errors: { ...this.state.errors, title: titleError, targetScore: targetScoreError } });
            if (errors.title && this.titleRef) {
                this.titleRef.focus();
            } else if (errors.targetScore && this.targetScoreRef) {
                this.targetScoreRef.focus();
            }
            return;
        }

        try {
            const clientAward: Award = await this.props.onSubmit(this.state.clientAward);
            this.setState(
                {
                    clientAward: { title: clientAward.title, image: clientAward.image, targetScore: clientAward.targetScore, autoReactivation: clientAward.autoReactivation },
                    isValidationEnabled: false,
                },
                () => {
                    if (this.props.pageType === PageType.create) {
                        Alert.success({ title: Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.create.saveSucceed" }) });
                        this.props.history.push(Path.clientAwards(this.props.clientExtId));
                    } else {
                        if (this.pageRef) {
                            this.pageRef.showNotification({
                                message: Intl.formatMessage({ id: `page.clientAwardsBase.clientAward.${this.props.pageType}.saveSucceed` }),
                                type: NotificationType.success,
                            });
                        }
                    }
                },
            );
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private renderBottomBar = (): React.ReactElement => {
        return (
            <BottomBar isVisible={this.isChanged()}>
                <div className="cell medium-6 text-right">
                    <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} disabled={!this.isChanged()} onClick={this.onCancelClick} />
                </div>
                <div className="cell medium-6 text-left">
                    <Button label={Intl.formatMessage({ id: "common.save" })} disabled={!this.isChanged()} onClick={this.onSubmit} />
                </div>
            </BottomBar>
        );
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = IntlHelpers.getValidationError(Validator.validateClientAwardTitle(title));
        this.setState({ clientAward: { ...this.state.clientAward, title }, errors: { ...this.state.errors, title: titleError } });
    };

    private onImageChange = (assets: AssetContent[]): void => {
        const image: AssetContent | null = assets[0] || null;
        this.setState({ clientAward: { ...this.state.clientAward, image } });
    };

    private onTargetScoreChange = (targetScore: number | null): void => {
        const targetScoreError: string | null = IntlHelpers.getValidationError(Validator.validateClientAwardTargetScore(targetScore));

        this.setState({
            clientAward: {
                ...this.state.clientAward,
                targetScore,
            },
            errors: {
                ...this.state.errors,
                targetScore: targetScoreError,
            },
        });
    };

    public render(): React.ReactElement<any> {
        const { pageType } = this.props;
        const { clientAward, errors, isValidationEnabled } = this.state;
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: `page.clientAwardsBase.clientAward.${pageType}.title` }, { clientName: this.props.clientName, clientAwardName: this.props.clientAward.title })}
                hasSideBar={true}
            >
                <DragContainer
                    dragIds={this.state.clientAward.image?.id ? [this.state.clientAward.image?.id] : []}
                    searchListTypes={[SearchListType.asset]}
                    assetTypes={[AssetType.image]}
                    createdById={this.props.accountId}
                >
                    <div className="left-side">
                        <p>{Intl.formatMessage({ id: `page.clientAwardsBase.clientAward.${pageType}.lead` }, { name: this.props.clientName })}</p>
                        <Section label={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.settingsSectionTitle" })}>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.autoReactivation.label" })}>
                                <Checkbox
                                    checked={clientAward.autoReactivation}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        this.setState({ clientAward: { ...this.state.clientAward, autoReactivation: event.currentTarget.checked } })
                                    }
                                />
                            </InputWrapper>
                        </Section>
                        <Section label={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.dataSectionTitle" })}>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.title.label" })} errorMessage={isValidationEnabled ? errors.title : null}>
                                <Input
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.titleRef = ref;
                                    }}
                                    value={clientAward.title || ""}
                                    placeholder={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.title.placeholder" })}
                                    hasError={isValidationEnabled && !isNil(errors.title)}
                                    onChange={this.onTitleChange}
                                />
                            </InputWrapper>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.image.label" })}>
                                <AssetInput
                                    droppableId="image"
                                    assetType={AssetType.image}
                                    value={!isNil(this.state.clientAward.image) ? [this.state.clientAward.image] : []}
                                    onChange={(assets: AssetContent[]) => this.onImageChange(assets)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                />
                            </InputWrapper>
                            <hr />
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.targetScore.label" })} errorMessage={isValidationEnabled ? errors.targetScore : null}>
                                <NumberInput
                                    innerRef={(ref: HTMLInputElement | null): void => {
                                        this.targetScoreRef = ref;
                                    }}
                                    type="number"
                                    value={clientAward.targetScore}
                                    placeholder={Intl.formatMessage({ id: "page.clientAwardsBase.clientAward.targetScore.placeholder" })}
                                    hasError={isValidationEnabled && !isNil(errors.targetScore)}
                                    onChange={this.onTargetScoreChange}
                                />
                            </InputWrapper>
                        </Section>
                        {this.renderBottomBar()}
                    </div>
                </DragContainer>

                <Prompt when={this.isChanged()} />
            </Page>
        );
    }
}

export const ClientAwardForm = withRouter(ClientAwardFormComponent);

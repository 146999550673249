import React, { Component } from "react";
import { AssetType, AssetContent, Notification } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { AssetInput } from "../Draggables/Input/AssetInput";
import { isNil } from "lodash";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";

interface Props {
    value: Notification | null;
    onChange: (alert: Notification | null, errors: string | null) => void;
    disabled?: boolean;
    isValidationEnabled: boolean;
    error: string | null;
}

class NotificationInput extends Component<Props> {
    private textRef: HTMLTextAreaElement | null = null;

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.notification.${id}` });
    };

    private static getInitialNotification = (): Notification => {
        return { __typename: "Notification", text: null, image: null, audio: null };
    };

    private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const text = event.currentTarget.value;
        const hasError = IntlHelpers.getValidationError(Validator.validateNotificationInputs(text, this.props.value?.image, this.props.value?.audio));
        this.props.onChange({ ...this.props.value!, text: text.length ? text : null }, hasError);
    };

    private onAudioChange = (assets: AssetContent[] | null): void => {
        const assetContent: AssetContent | null = !isNil(assets) && assets.length > 0 ? assets[0] : null;
        const hasError = IntlHelpers.getValidationError(Validator.validateNotificationInputs(this.props.value?.text, this.props.value?.image, assetContent));
        this.props.onChange({ ...this.props.value!, audio: assetContent }, hasError);
    };

    private onImageChange = (assets: AssetContent[] | null): void => {
        const assetContent: AssetContent | null = !isNil(assets) && assets.length > 0 ? assets[0] : null;
        const hasError = IntlHelpers.getValidationError(Validator.validateNotificationInputs(this.props.value?.text, assetContent, this.props.value?.audio));
        this.props.onChange({ ...this.props.value!, image: assetContent }, hasError);
    };

    private onEnabledChange = (): void => {
        const notification: Notification | null = this.props.value ? null : NotificationInput.getInitialNotification();
        const hasError = notification ? IntlHelpers.getValidationError(Validator.validateNotificationInputs(this.props.value?.text, this.props.value?.image, this.props.value?.audio)) : null;
        this.props.onChange(notification, hasError);
    };

    public focus = (): void => {
        this.textRef && this.textRef.focus();
    };

    public render(): React.ReactElement<any> {
        const { disabled, value } = this.props;

        return (
            <>
                <InputWrapper inputLabel={this.localTranslation("enabled.label")} padded icon="fa-comment">
                    <Checkbox checked={!!this.props.value} onChange={this.onEnabledChange} disabled={this.props.disabled} />
                </InputWrapper>

                {this.props.value && (
                    <>
                        <InputWrapper inputLabel={this.localTranslation("image.label")} padded>
                            <AssetInput
                                droppableId="notification-image"
                                assetType={AssetType.image}
                                value={!isNil(value) && value.image ? [value.image] : []}
                                onChange={(assets: AssetContent[]) => this.onImageChange(assets)}
                                maxItemCount={1}
                                minItemCount={1}
                                disabled={disabled}
                                hasError={this.props.isValidationEnabled && !!this.props.error}
                            />
                        </InputWrapper>

                        <InputWrapper inputLabel={this.localTranslation("audio.label")} padded>
                            <AssetInput
                                droppableId="notification-audio"
                                assetType={AssetType.audio}
                                value={!isNil(value) && value.audio ? [value.audio] : []}
                                onChange={(assets: AssetContent[]) => this.onAudioChange(assets)}
                                maxItemCount={1}
                                minItemCount={1}
                                disabled={disabled}
                                hasError={this.props.isValidationEnabled && !!this.props.error}
                            />
                        </InputWrapper>

                        <InputWrapper inputLabel={this.localTranslation("text.label")} padded errorMessage={this.props.isValidationEnabled ? this.props.error : null}>
                            <TextArea
                                innerRef={(ref: HTMLTextAreaElement | null): void => {
                                    this.textRef = ref;
                                }}
                                placeholder={this.localTranslation("text.placeholder")}
                                value={value?.text || ""}
                                onChange={this.onTextChange}
                                disabled={disabled}
                                hasError={this.props.isValidationEnabled && !!this.props.error}
                            />
                        </InputWrapper>
                    </>
                )}
            </>
        );
    }
}

export { NotificationInput };

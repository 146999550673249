import React, { ComponentType } from "react";
import { Notification } from "components/NotificationBar/Notification";
import { Diff } from "utils/TypeUtils";

export interface NotificationProp {
    showNotification: (notification: Notification) => void;
}

export const NotificationContext = React.createContext<NotificationProp>({
    showNotification: () => {},
});

export function withNotification<P extends NotificationProp>(WrappedComponent: ComponentType<P>) {
    return class WithNotificationClass extends React.Component<Diff<P, NotificationProp>> {
        public render() {
            return <NotificationContext.Consumer>{({ showNotification }) => <WrappedComponent {...(this.props as P)} showNotification={showNotification} />}</NotificationContext.Consumer>;
        }
    };
}

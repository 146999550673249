import { SupportedClient } from "../../api/graphql/types";
import { DialogVisibilityProps } from "./DialogsContainer";
import React, { Component } from "react";
import { Dialog } from "../Dialog/Dialog";
import { Intl } from "../../i18n/Intl";
import { Button } from "../Button/Button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Path } from "../../utils/Path";

export interface SyncWarningDialogProps {
    client: SupportedClient;
}

type Props = SyncWarningDialogProps & DialogVisibilityProps & RouteComponentProps;

class SyncWarningDialogComponent extends Component<Props> {
    private readonly onContinue = (): void => {
        this.props.history.push(`${Path.publicContentLibrary}?tiltott_tar`);
        this.props.onHide();
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "sharedComponent.syncWarningDialog.title" })} onCloseClick={this.props.onHide}>
                <i className="fas fa-exclamation-triangle error-sync-dialog-icon" />
                <p>{Intl.formatMessage({ id: "sharedComponent.syncWarningDialog.description" })}</p>
                <hr />
                <div className="row buttons">
                    <div className="cell medium-5">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                    </div>
                    <div className="cell medium-7 text-right">
                        <Button className="btn-danger btn--text-large fw-700" label={Intl.formatMessage({ id: "sharedComponent.syncWarningDialog.continueButton" })} onClick={this.onContinue} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const SyncWarningDialog = withRouter(SyncWarningDialogComponent);

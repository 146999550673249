import React, { Component } from "react";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { DateFormat } from "utils/DateUtils";
import hu from "date-fns/locale/hu";
import get from "lodash/get";
import isNil from "lodash/isNil";
import "../Inputs.scss";

setDefaultLocale("hu");
registerLocale("hu", hu);

import "react-datepicker/dist/react-datepicker.css";

export enum PopperPlacement {
    top = "top",
    bottom = "bottom",
}

interface Props {
    value?: Date | null;
    placeholder?: string;
    onChange: (date: Date | null) => void;
    min?: Date;
    max?: Date;
    disabled?: boolean;
    popperPlacement?: PopperPlacement;
}

class DateInput extends Component<Props> {
    private inputRef: ReactDatePicker | null = null;

    private onChange = (date: Date | null, _event: React.SyntheticEvent<any, Event> | undefined): void => {
        this.props.onChange(date);
    };

    public focus() {
        const input: HTMLInputElement | null = get(this.inputRef, "input");
        if (!isNil(input)) {
            input.focus();
        }
    }

    public render(): React.ReactElement<any> {
        const { value, placeholder, onChange, min, max, ...otherProps } = this.props;
        return (
            <ReactDatePicker
                ref={(ref: ReactDatePicker | null) => {
                    this.inputRef = ref;
                }}
                selected={value}
                onChange={this.onChange}
                placeholderText={placeholder}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat={DateFormat.yyyymmdd}
                minDate={min}
                maxDate={max}
                popperPlacement={this.props.popperPlacement || PopperPlacement.top}
                {...otherProps}
            />
        );
    }
}

export { DateInput };

import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { Path } from "utils/Path";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FoldableSection } from "../../components/FoldableSection/FoldableSection";

import { Intl } from "i18n/Intl";
import { LoginForm } from "./LoginForm";
import { RegistrationForm } from "./RegistrationForm";
import { TestId } from "utils/TestId";
import { RegistrationSucceed } from "./RegistrationSucceed";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { AuthSelectors } from "selectors/AuthSelectors";
import { ApplicationState } from "reducers/index";
import { StaticContext } from "react-router";

interface ReduxProps {
    isLoggedIn: boolean;
}

interface LocationState {
    from: {
        pathname: string;
    };
}

type Props = ReduxProps & DispatchProp & RouteComponentProps<{}, StaticContext, LocationState>;

interface State {
    isRegistrationSucceed: boolean;
    email: string | null;
}
class AuthPageComponent extends React.Component<Props, State> {
    public state: Readonly<State> = {
        isRegistrationSucceed: true,
        email: null,
    };

    /**
     * Tab require onSelect callback
     * SelectedIndex depends on location.pathname
     */
    private onSelectTab(): void {}

    public renderRegistrationSucceed(email: string): React.ReactElement<any> {
        return (
            <main className="guest-page">
                <Tabs selectedIndex={0} selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected" onSelect={this.onSelectTab}>
                    <TabList>
                        <Tab>
                            <Link
                                to={Path.login}
                                onClick={(): void => {
                                    this.setState({ isRegistrationSucceed: false });
                                }}
                            >
                                {Intl.formatMessage({ id: "page.auth.tabs.registrationSucceed" })}
                            </Link>
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <RegistrationSucceed email={email} />
                    </TabPanel>
                </Tabs>
            </main>
        );
    }

    public render(): React.ReactElement<any> | null {
        if (this.state.isRegistrationSucceed && this.state.email) {
            return this.renderRegistrationSucceed(this.state.email);
        }

        return (
            <main className="guest-page">
                <div className="guest-page-landing__absolute-wrapper">
                    <div className="guest-page-landing__background">
                        <div className="guest-page-landing__container">
                            <h1 className="guest-page-landing__h1">Üdvözlünk!</h1>

                            <p className="guest-page-landing__lead">Üdvözlünk a DATA (Digitális autonómia-támogatás az autizmus spektrumon) szoftverrendszer tervező felületén!</p>

                            <FoldableSection id="landing-details" toggleButtonLabel="Részletesebben a DATA Projektről">
                                <p>
                                    A DATA egy olyan alkalmazás, mely célirányos támogatást nyújt az autizmusban érintett személyeknek a mindennapi élethelyzetekben. Az applikáció hasznos eszköz lehet
                                    a hétköznapi akadályok egy részének leküzdésében, a szolgáltatások eléréséhez, az önállóság és függetlenség növeléséhez.
                                </p>

                                <p>
                                    A célirányos támogatáshoz tervezés szükséges. Ezen a felületen a DATA applikációt használó autizmussal élő személy egyénre szabott tartalmait állíthatja össze.
                                    <br />
                                    Amennyiben Ön támogat autizmussal élő személyt, regisztrációt követően a felületre belépve tud új tartalmakat létrehozni, azokat módosítani és a felhasználó személy
                                    által használt eszközön futó applikációra küldeni.
                                </p>

                                <p>
                                    A szoftverrendszerről részletesebben honlapunkon tájékozódhat (
                                    <a href="http://data.aosz.hu/" rel="noopener noreferrer" target="_blank">
                                        www.data.aosz.hu
                                    </a>
                                    ).
                                </p>
                            </FoldableSection>

                            <Tabs
                                onSelect={this.onSelectTab}
                                selectedIndex={this.props.location.pathname === Path.login ? 0 : 1}
                                selectedTabClassName="is-selected"
                                selectedTabPanelClassName="is-selected"
                            >
                                <TabList>
                                    <Tab>
                                        <Link id={TestId.authPage.tabLink.login} to={Path.login}>
                                            {Intl.formatMessage({ id: "page.auth.tabs.login" })}
                                        </Link>
                                    </Tab>
                                    <Tab>
                                        <Link id={TestId.authPage.tabLink.registration} to={Path.registration}>
                                            {Intl.formatMessage({ id: "page.auth.tabs.registration" })}
                                        </Link>
                                    </Tab>
                                </TabList>

                                <TabPanel>
                                    <LoginForm {...this.props} isLoggedIn={this.props.isLoggedIn} />
                                </TabPanel>

                                <TabPanel>
                                    <RegistrationForm
                                        {...this.props}
                                        isLoggedIn={this.props.isLoggedIn}
                                        onRegistrationSucceed={(email: string): void => {
                                            this.setState({ isRegistrationSucceed: true, email });
                                        }}
                                    />
                                </TabPanel>
                            </Tabs>

                            <h2 className="guest-page-landing__h2">Létrehozta</h2>

                            <ul className="ul-reset-style row justify-content-center guest-page-landing__list guest-page-landing__list--translatex">
                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h100"
                                        src="/images/guest-page-landing/aosz_logo.svg"
                                        width={125}
                                        height={100}
                                        alt="Autisták Országos Szövetsége logó"
                                        title="Autisták Országos Szövetsége"
                                        loading="lazy"
                                    />
                                </li>

                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h100"
                                        src="/images/guest-page-landing/elte_logo.png"
                                        width={100}
                                        height={100}
                                        alt="Eötvös Loránd Tudományegyetem logó"
                                        title="Eötvös Loránd Tudományegyetem"
                                        loading="lazy"
                                    />
                                </li>

                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h100"
                                        src="/images/guest-page-landing/barczi_logo.svg"
                                        width={73}
                                        height={100}
                                        alt="Eötvös Loránd Tudományegyetem Bárczi Gusztáv Gyógypedagógiai Kar logó"
                                        title="Eötvös Loránd Tudományegyetem Bárczi Gusztáv Gyógypedagógiai Kar"
                                        loading="lazy"
                                    />
                                </li>
                            </ul>

                            <h2 className="guest-page-landing__h2">Együttműködő partnereink</h2>

                            <ul className="ul-reset-style row justify-content-center guest-page-landing__list">
                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h50"
                                        src="/images/guest-page-landing/erste_weblogo.svg"
                                        width={121}
                                        height={50}
                                        alt="Erste Bank logó"
                                        title="Erste Bank"
                                        loading="lazy"
                                    />
                                </li>

                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h50"
                                        src="/images/guest-page-landing/bkk_logo.svg"
                                        width={203}
                                        height={50}
                                        alt="Budapesti Közlekedési Központ logó"
                                        title="Budapesti Közlekedési Központ"
                                        loading="lazy"
                                    />
                                </li>

                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h50"
                                        src="/images/guest-page-landing/magyar-posta-logo.svg"
                                        alt="Magyar Posta Zrt. logó"
                                        width={95}
                                        height={50}
                                        title="Magyar Posta Zrt."
                                        loading="lazy"
                                    />
                                </li>

                                <li className="col-auto">
                                    <img
                                        className="guest-page-landing__list-image--h50"
                                        src="/images/guest-page-landing/bethesda_logo.jpg"
                                        alt="Bethesda Gyermekkórház logó"
                                        width={55}
                                        height={50}
                                        title="Bethesda Gyermekkórház"
                                        loading="lazy"
                                    />
                                </li>
                            </ul>

                            <p className="guest-page-landing__footer-text">
                                A DATA: Digitális Autonómia-Támogatás az Autizmus Spektrumon (EFOP&nbsp;1.1.5-17-2017-00007) projekt a Széchenyi 2020 program keretében európai uniós támogatás
                                segítségével valósul meg.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { isLoggedIn: AuthSelectors.isLoggedIn(state) };
};

const AuthPage = withRouter(connect(mapStateToProps)(AuthPageComponent));

// tslint:disable-next-line: no-default-export
export default AuthPage;

import React, { Component } from "react";
import { AgendaItemAlert, AgendaItemAlertDisplayType, AssetType, AssetContent, AgendaItemAlert_audio } from "api/graphql/types";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Intl } from "i18n/Intl";
import { SelectOption, Select } from "components/Inputs/Select/Select";
import { AgendaItemAlertNotificationsOption, AgendaItemAlertTypeOption, AgendaItemAlertSelectOptions } from "models/AgendaItemAlertSelectOptions";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { AssetInput } from "../Draggables/Input/AssetInput";
import { isNil } from "lodash";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";

interface Props {
    testIds: {
        displayOptions: string;
        type: string;
        notifications: string;
        text: string;
    };
    value: AgendaItemAlert | null;
    onChange: (alert: AgendaItemAlert | null, errors: AlertValidationError | null) => void;
    disabled?: boolean;
    isValidationEnabled: boolean;
    errors?: AlertValidationError | null;
}

interface State {
    displayOption: AgendaItemAlertDisplayType | null;
}

export interface AlertValidationError {
    title?: string | null;
}

class AgendaItemAlertInput extends Component<Props, State> {
    private titleRef: HTMLTextAreaElement | null = null;

    private static FALLBACK_AUDIO_BACKEND_URL = "asset/alert/alert.mp3";

    public readonly state: State = {
        displayOption: this.props.value ? this.props.value.display : null,
    };

    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.alert.${id}` });
    };

    private isAudioFallback = (audio: AgendaItemAlert_audio | null): boolean => {
        return !isNil(audio?.url) && audio!.url.endsWith(AgendaItemAlertInput.FALLBACK_AUDIO_BACKEND_URL);
    };

    private onDisplayOptionsChange = (displayOption: SelectOption<AgendaItemAlertDisplayType | null>): void => {
        if (displayOption.value !== null) {
            this.setState({ displayOption: displayOption.value });
            // If we changed from null to something else, we expand the other inputs and set the initial values
            if (this.props.value === null) {
                this.props.onChange(
                    {
                        __typename: "AgendaItemAlert",
                        title: "",
                        vibrate: false,
                        flash: true,
                        display: displayOption.value,
                        requestInteraction: false,
                        audioChecked: false,
                        audio: null,
                    },
                    {
                        ...this.props.errors,
                        title: this.validateTitle(""),
                    },
                );
            } else {
                this.props.onChange({ ...this.props.value!, display: displayOption.value }, { ...this.props.errors });
            }
        } else {
            this.setState({ displayOption: AgendaItemAlertSelectOptions.display[0].value });
            this.props.onChange(null, null);
        }
    };

    private validateTitle = (title: string): string | null => {
        return IntlHelpers.getValidationError(Validator.validateAgendaItemAlertTitle(title));
    };

    private onTypeChange = (type: SelectOption<AgendaItemAlertTypeOption>): void => {
        this.props.onChange({ ...this.props.value!, requestInteraction: type.value === AgendaItemAlertTypeOption.Repeating }, { ...this.props.errors });
    };

    private onNotificationsChange = (notification: SelectOption<AgendaItemAlertNotificationsOption>): void => {
        this.props.onChange(
            {
                ...this.props.value!,
                vibrate: notification.value === AgendaItemAlertNotificationsOption.Vibrate || notification.value === AgendaItemAlertNotificationsOption.FlashAndVibrate,
                flash: notification.value === AgendaItemAlertNotificationsOption.Flash || notification.value === AgendaItemAlertNotificationsOption.FlashAndVibrate,
            },
            { ...this.props.errors },
        );
    };

    private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const title: string = event.currentTarget.value;
        const titleError: string | null = this.validateTitle(title);
        this.props.onChange({ ...this.props.value!, title }, { ...this.props.errors, title: titleError });
    };

    private onAudioChange = (assets: AssetContent[]): void => {
        const audio: AssetContent | null = assets[0] || null;
        this.props.onChange({ ...this.props.value!, audio }, { ...this.props.errors });
    };

    // Convert from GraphQL request type to our local form type
    private getDisplayOption = (): SelectOption<AgendaItemAlertDisplayType | null> => {
        if (this.props.value && this.props.value.display) {
            return Select.getSelectOption(AgendaItemAlertSelectOptions.display, this.props.value.display);
        }
        return AgendaItemAlertSelectOptions.display[0];
    };

    // Convert from GraphQL request type to our local form type
    private getType = (): SelectOption<AgendaItemAlertTypeOption> => {
        return (
            Select.getSelectOption(AgendaItemAlertSelectOptions.type, this.props.value && this.props.value.requestInteraction ? AgendaItemAlertTypeOption.Repeating : AgendaItemAlertTypeOption.Once) ||
            AgendaItemAlertSelectOptions.type[0]
        );
    };

    // Convert from GraphQL request type to our local form type
    private getNotifications = (): SelectOption<AgendaItemAlertNotificationsOption> => {
        if (!this.props.value) {
            return AgendaItemAlertSelectOptions.notifications[0];
        }

        const { flash, vibrate } = this.props.value;
        if (flash && vibrate) {
            return Select.getSelectOption(AgendaItemAlertSelectOptions.notifications, AgendaItemAlertNotificationsOption.FlashAndVibrate);
        } else if (flash) {
            return Select.getSelectOption(AgendaItemAlertSelectOptions.notifications, AgendaItemAlertNotificationsOption.Flash);
        } else if (vibrate) {
            return Select.getSelectOption(AgendaItemAlertSelectOptions.notifications, AgendaItemAlertNotificationsOption.Vibrate);
        } else {
            return Select.getSelectOption(AgendaItemAlertSelectOptions.notifications, AgendaItemAlertNotificationsOption.WithoutFlashAndVibrate);
        }
    };

    public focus(): void {
        if (this.titleRef) {
            this.titleRef.focus();
        }
    }

    private onIsCustomAudioClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value: boolean = event.currentTarget.checked;

        this.onAudioChange([]);
        this.props.onChange({ ...this.props.value!, audioChecked: value }, this.props.errors || null);
    };

    public render(): React.ReactElement<any> {
        const { disabled, value } = this.props;

        return (
            <>
                <InputWrapper id={this.props.testIds.displayOptions} inputLabel={this.localTranslation("displayOptions.label")} padded icon="fa-exclamation-circle">
                    <Select options={AgendaItemAlertSelectOptions.display} onChange={this.onDisplayOptionsChange} value={this.getDisplayOption()} disabled={disabled} />
                </InputWrapper>

                {this.state.displayOption !== null && (
                    <>
                        <InputWrapper id={this.props.testIds.type} inputLabel={this.localTranslation("type.label")} padded>
                            <Select options={AgendaItemAlertSelectOptions.type} onChange={this.onTypeChange} value={this.getType()} disabled={disabled} />
                        </InputWrapper>

                        <InputWrapper id={this.props.testIds.notifications} inputLabel={this.localTranslation("notifications.label")} padded>
                            <Select options={AgendaItemAlertSelectOptions.notifications} onChange={this.onNotificationsChange} value={this.getNotifications()} disabled={disabled} />
                        </InputWrapper>

                        <InputWrapper inputLabel={this.localTranslation("isCustomAudio.label")} padded>
                            <Checkbox checked={this.props.value!.audioChecked} onChange={this.onIsCustomAudioClick} disabled={disabled} />
                        </InputWrapper>

                        {this.props.value!.audioChecked && (
                            <InputWrapper inputLabel={this.localTranslation("audio.label")} padded>
                                <AssetInput
                                    droppableId="agenda-item-alert-audio"
                                    assetType={AssetType.audio}
                                    value={this.isAudioFallback(value?.audio || null) || isNil(value?.audio) ? [] : [value!.audio]}
                                    onChange={(assets: AssetContent[]) => this.onAudioChange(assets)}
                                    maxItemCount={1}
                                    minItemCount={1}
                                    disabled={this.props.disabled}
                                />
                            </InputWrapper>
                        )}

                        <InputWrapper
                            id={this.props.testIds.text}
                            inputLabel={this.localTranslation("text.label")}
                            padded
                            errorMessage={this.props.isValidationEnabled ? this.props.errors?.title : null}
                        >
                            <TextArea
                                innerRef={(ref: HTMLTextAreaElement | null): void => {
                                    this.titleRef = ref;
                                }}
                                placeholder={this.localTranslation("text.placeholder")}
                                value={value ? value.title : ""}
                                onChange={this.onTextChange}
                                disabled={this.props.disabled}
                            />
                        </InputWrapper>
                    </>
                )}
            </>
        );
    }
}

export { AgendaItemAlertInput };

import React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { Avatar } from "components/Avatar";
import { NavItem, NavItems, MainNavItem, SubNavItem } from "./NavItems";
import { MenuIcon } from "components/Navigation/MenuIcon";

interface ComponentProps {
    navItem: NavItem;
    isOpened: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

type Props = RouteComponentProps & ComponentProps;

interface State {}

class MenuItemComponent extends React.PureComponent<Props, State> {
    public readonly state: State = {};

    public componentDidMount(): void {
        if (this.hasActiveSubNavItem([this.props.navItem])) {
            this.props.onOpenChange(true);
        }
    }

    private isActivePath(path: string): boolean {
        return this.props.history.location.pathname.startsWith(path);
    }

    private hasActiveSubNavItem(navItems: NavItem[]): boolean {
        return navItems.some((navItem: NavItem) => {
            if (NavItems.isMainNavItem(navItem)) {
                return this.hasActiveSubNavItem(navItem.items);
            }

            if (NavItems.isSubNavMenu(navItem)) {
                return this.isActivePath(navItem.path);
            }
            return false;
        });
    }

    private onOpenChange = () => {
        this.props.onOpenChange(!this.props.isOpened);
    };

    private renderMainNavItem(navItem: MainNavItem): React.ReactElement<any> {
        const isActive: boolean = navItem.path ? this.isActivePath(navItem.path) : this.hasActiveSubNavItem(navItem.items);
        return (
            <li className={`dropdown${isActive ? " active" : ""}`}>
                <div className={`toggler${this.props.isOpened ? " toggled" : ""}`} onClick={this.onOpenChange}>
                    {navItem.icon && <MenuIcon icon={navItem.icon} title={navItem.label} />}

                    {navItem.isClient && <Avatar url={navItem.imageUrl} name={navItem.isClient ? navItem.label : ""} hasBadge={navItem.hasBadge} />}
                    <span className="nav-label">{navItem.label}</span>
                </div>

                {navItem.items && (
                    <ul className={`nav nav-second-level collapse${this.props.isOpened ? " in" : ""}`}>
                        {navItem.items.map(
                            (navItem: NavItem): React.ReactElement<any> => (
                                <MenuItem key={navItem.key} navItem={navItem} isOpened={true} onOpenChange={() => {}} />
                            ),
                        )}
                    </ul>
                )}
            </li>
        );
    }

    private renderSubNavItem(navItem: SubNavItem): React.ReactElement<any> {
        const isActive: boolean = this.isActivePath(navItem.path);
        const isExternal: boolean = navItem.path.startsWith("http");

        return (
            <li className={isActive ? "active" : undefined}>
                <Link to={isExternal ? { pathname: navItem.path } : navItem.path} target={isExternal ? "_blank" : undefined} onClick={navItem.isParent ? this.onOpenChange : undefined}>
                    {navItem.icon && (
                        <div className="nav-icon">
                            {<MenuIcon icon={navItem.icon} title={navItem.label} />}
                            {navItem.hasBadge ? <span className="label label-notification" /> : null}
                        </div>
                    )}
                    <span className="nav-label" style={{ paddingLeft: navItem.key.includes("_") ? 15 : undefined }}>
                        {navItem.label}
                    </span>
                </Link>
            </li>
        );
    }

    public render(): React.ReactElement<any> | null {
        const { navItem } = this.props;
        if (NavItems.isMainNavItem(navItem)) {
            return this.renderMainNavItem(navItem);
        }

        if (NavItems.isSubNavMenu(navItem)) {
            return this.renderSubNavItem(navItem);
        }

        return null;
    }
}

export const MenuItem = withRouter(MenuItemComponent);

import { ParsedParameter, UrlQueryParser } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { QueryParserTypeUtils } from "utils/TypeUtils";
import { store } from "store";
import { Tag } from "api/graphql/types";

enum QueryParameter {
    search = "search",
    tags = "tags",
    directory = "directory",
}

export interface ContentLibrarySideBarQueryParameter {
    search?: string;
    tags?: string[];
    directory?: string;
}

class ContentLibrarySideBarQueryParser extends UrlQueryParser<ContentLibrarySideBarQueryParameter> {
    private translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.contentLibrarySideBarQueryParameter.${key}` });
    }

    public getUrlQuery(options: ContentLibrarySideBarQueryParameter): string {
        const search: string | null = options.search || null;
        const tags: string[] | null = options.tags || null;
        const directory: string | null = options.directory || null;
        const paramArray: string[] = [];

        if (search) {
            paramArray.push(`${this.translateListQuery(QueryParameter.search)}=${search}`);
        }

        if (tags && tags.length > 0) {
            paramArray.push(`${this.translateListQuery(QueryParameter.tags)}=${tags.join(",")}`);
        }

        if (directory) {
            paramArray.push(`${this.translateListQuery(QueryParameter.directory)}=${directory}`);
        }
        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): ContentLibrarySideBarQueryParameter {
        const keys = {
            [QueryParameter.search]: this.translateListQuery(QueryParameter.search),
            [QueryParameter.tags]: this.translateListQuery(QueryParameter.tags),
            [QueryParameter.directory]: this.translateListQuery(QueryParameter.directory),
        };

        const search: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.search]);

        const directory: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.directory]);

        const queryTags: string[] | undefined = QueryParserTypeUtils.toString(parsedParams[keys.tags])?.split(",") || undefined;
        const availableTags = store.getState().tags.map((tag: Tag): string => tag.title);
        const tags = queryTags?.filter((queryTag: string) => availableTags.includes(queryTag));
        return { search, tags, directory };
    }
}

export { ContentLibrarySideBarQueryParser };

import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { SortOrder, ListControl, AdminListItem, AccountSortField, AccountListOptionsInput } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AppPath, Path } from "utils/Path";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { ObjectUtils } from "utils/ObjectUtils";
import { SearchForm } from "components/SearchForm/SearchForm";
import { Button } from "components/Button/Button";
import { Section } from "components/Section";
import { ApiTypes } from "api/ApiTypes";
import { ListUrlQueryParser, ListQueryParameter } from "utils/ListUrlQueryParser";
import { DispatchProp, connect } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { NotificationType } from "components/NotificationBar/Notification";

interface AdminsListOptions {
    sortField?: AccountSortField | null;
    control: ListControl;
}

interface State {
    admins: AdminListItem[];
    count: number;
    isLoading: boolean;
    options: AdminsListOptions;
}

enum AdminListTableColumn {
    name = "name",
    email = "email",
    status = "status",
}

type ComponentProps = RouteComponentProps;

type Props = ComponentProps & DispatchProp;

class AdminListPageComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    private static getInitialOptions(props: Props): AdminsListOptions {
        const { sortField, sortOrder, search, limit, page } = new ListUrlQueryParser<AccountSortField>(AccountSortField, "page.adminList").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public readonly state: State = {
        admins: [],
        isLoading: true,
        count: 0,
        options: AdminListPageComponent.getInitialOptions(this.props),
    };

    public componentDidMount(): void {
        this.refreshAdmins(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.location.search, nextProps.location.search)) {
            this.setState({ options: AdminListPageComponent.getInitialOptions(nextProps) }, () => this.refreshAdmins(this.state.options));
        }
    }

    private refreshAdmins = (options?: AccountListOptionsInput): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await Api.getAdminList(options);
                    this.setState({ admins: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(AppPath.dashboard);
                        },
                    });
                }
            },
        );
    };

    private readonly columns: Array<Column<AdminListItem>> = ObjectUtils.enumAsArray<AdminListTableColumn>(AdminListTableColumn).map(
        (columnName: AdminListTableColumn): Column<AdminListItem> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.adminList.table.columns.${columnName}` }),
            accessor: columnName as keyof AdminListItem,
            renderCell: (adminListItem: AdminListItem): React.ReactElement<any> | null => {
                switch (columnName) {
                    case AdminListTableColumn.status:
                        return <>{Intl.formatMessage({ id: `enum.accountStatus.${ApiTypes.getAccountStatus(adminListItem)}` })}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: columnName === AdminListTableColumn.status,
        }),
    );

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<AccountSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<AccountSortField>(AccountSortField, "page.adminList").getUrlQuery(options);
        this.props.history.push({ search: `?${params}` });
        this.refreshAdmins(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): AccountSortField | undefined => {
        switch (columnId) {
            case AdminListTableColumn.name:
                return AccountSortField.name;
            case AdminListTableColumn.email:
                return AccountSortField.email;
            case AdminListTableColumn.status:
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AccountSortField | null): keyof AdminListItem | undefined => {
        switch (columnId) {
            case AccountSortField.name:
                return AdminListTableColumn.name;
            case AccountSortField.email:
                return AdminListTableColumn.email;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AdminListItem>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private onSearch = (search: string): void => {
        this.setState(
            {
                options: { ...this.state.options, control: { ...this.state.options.control, search, offset: 0 } },
            },
            this.updateQueryParams,
        );
    };

    private createAdminAccount = async (email: string, name: string): Promise<void> => {
        try {
            await Api.createAdminAccount(email, name);
            this.refreshAdmins(this.state.options);
            if (this.pageRef) {
                this.pageRef.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.adminList.createAdminAccountSucceed" }) });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    public render(): React.ReactElement<any> | null {
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: "page.adminList.title" })}
            >
                <div className="left-side">
                    <Section
                        label={
                            <label className="grid-x">
                                <span className="cell medium-6">
                                    <SearchForm
                                        icon
                                        defaultValue={this.state.options.control.search || undefined}
                                        searchPlaceholder={Intl.formatMessage({ id: "page.adminList.searchPlaceholder" })}
                                        onSearchClick={this.onSearch}
                                    />
                                </span>
                                <span className="cell medium-6 text-right">
                                    <Button
                                        icon={{ name: "fa fa-plus", position: "left" }}
                                        label={Intl.formatMessage({ id: "page.adminList.addButton" })}
                                        onClick={(): void => {
                                            this.props.dispatch(
                                                DialogsActions.show({
                                                    type: DialogType.createAdminAccount,
                                                    createAdminAccount: this.createAdminAccount,
                                                }),
                                            );
                                        }}
                                    />
                                </span>
                            </label>
                        }
                    >
                        <Table
                            keyExtractor={(item: AdminListItem, column?: Column<AdminListItem>): string => {
                                return `${item.id}_${column ? column.id : ""}`;
                            }}
                            columns={this.columns}
                            sortBy={{
                                columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                                order: this.state.options.control.sortOrder || undefined,
                            }}
                            data={this.state.admins}
                            count={this.state.count}
                            limit={this.state.options.control.limit}
                            isSortable={true}
                            onSortOrderChange={this.onSortOrderChange}
                            onPageChange={this.onPageChange}
                            isPaginationEnabled={true}
                            currentPage={this.getCurrentPage()}
                            isLoading={this.state.isLoading}
                            onRowClick={(adminListItem: AdminListItem): void => {
                                this.props.history.push(Path.adminDetail(adminListItem.id));
                            }}
                            renderEmpty={(): string => Intl.formatMessage({ id: "page.adminList.noData" })}
                        />
                    </Section>
                </div>
            </Page>
        );
    }
}

export const AdminListPage = withRouter(connect()(AdminListPageComponent));

// tslint:disable-next-line: no-default-export
export default AdminListPage;

import { SearchListType, Tag } from "api/graphql/types";
import { ParsedParameter, UrlQueryParser } from "utils/UrlQueryParser";
import { Intl } from "i18n/Intl";
import { IntlHelpers } from "i18n/IntlHelpers";
import { QueryParserTypeUtils } from "utils/TypeUtils";
import { ExtraContentLibraryTypes } from "api/ApiTypes";
import { store } from "../../../store";

enum QueryParameter {
    searchListType = "searchListType",
    extraContentLibraryType = "extraContentLibraryType",
    isPublic = "isPublic",
    directory = "directory",
    search = "search",
    tags = "tags",
    day = "day",
    isDisabled = "isDisabled",
    isShared = "isShared",
}

export interface ContentLibraryQueryParameter {
    searchListType?: SearchListType;
    extraContentLibraryType?: ExtraContentLibraryTypes;
    isPublic?: boolean;
    directory?: string;
    search?: string;
    tags?: string[];
    isDisabled?: boolean;
    isShared?: boolean;
}

class ContentLibraryUrlQueryParser extends UrlQueryParser<ContentLibraryQueryParameter> {
    private translateListQuery(key: string): string {
        return Intl.formatMessage({ id: `enum.contentLibraryQueryParameter.${key}` });
    }

    public getUrlQuery(options: ContentLibraryQueryParameter): string {
        const searchListType: SearchListType | null = options.searchListType || null;
        const extraContentLibraryType: ExtraContentLibraryTypes | null = options.extraContentLibraryType || null;
        const isPublic: boolean | null = options.isPublic || null;
        const directory: string | null = options.directory || null;
        const tags: string[] | null = options.tags || null;
        const isDisabled: boolean | null = options.isDisabled || null;
        const isShared: boolean | null = options.isShared || null;

        const paramArray: string[] = [];
        if (isPublic) {
            paramArray.push(this.translateListQuery(QueryParameter.isPublic));
        }

        if (isDisabled) {
            paramArray.push(this.translateListQuery(QueryParameter.isDisabled));
        } else if (isShared) {
            paramArray.push(this.translateListQuery(QueryParameter.isShared));
        } else if (searchListType) {
            paramArray.push(`${this.translateListQuery(QueryParameter.searchListType)}=${Intl.formatMessage({ id: `enum.searchListTypeQueryParameter.${searchListType}` })}`);
        } else if (extraContentLibraryType) {
            paramArray.push(`${this.translateListQuery(QueryParameter.extraContentLibraryType)}=${Intl.formatMessage({ id: `enum.searchListTypeQueryParameter.${extraContentLibraryType}` })}`);
        }

        if (tags && tags.length > 0) {
            paramArray.push(`${this.translateListQuery(QueryParameter.tags)}=${tags.join(",")}`);
        }

        if (directory) {
            paramArray.push(`${this.translateListQuery(QueryParameter.directory)}=${directory}`);
        }

        return paramArray.join("&");
    }

    protected getParameter(parsedParams: ParsedParameter): ContentLibraryQueryParameter {
        const keys = {
            [QueryParameter.searchListType]: this.translateListQuery(QueryParameter.searchListType),
            [QueryParameter.extraContentLibraryType]: this.translateListQuery(QueryParameter.extraContentLibraryType),
            [QueryParameter.isPublic]: this.translateListQuery(QueryParameter.isPublic),
            [QueryParameter.directory]: this.translateListQuery(QueryParameter.directory),
            [QueryParameter.search]: this.translateListQuery(QueryParameter.search),
            [QueryParameter.tags]: this.translateListQuery(QueryParameter.tags),
            [QueryParameter.isDisabled]: this.translateListQuery(QueryParameter.isDisabled),
            [QueryParameter.isShared]: this.translateListQuery(QueryParameter.isShared),
        };

        const queryTags: string[] | undefined = QueryParserTypeUtils.toString(parsedParams[keys.tags])?.split(",") || undefined;
        const searchListType: SearchListType | undefined = IntlHelpers.fromI18nToEnum<SearchListType>(SearchListType, parsedParams[keys.searchListType], "enum.searchListTypeQueryParameter");
        const extraContentLibraryType: ExtraContentLibraryTypes | undefined = IntlHelpers.fromI18nToEnum<ExtraContentLibraryTypes>(
            ExtraContentLibraryTypes,
            parsedParams[keys.extraContentLibraryType],
            "enum.searchListTypeQueryParameter",
        );
        const availableTags = store.getState().tags.map((tag: Tag): string => tag.title);
        const isPublic: boolean | undefined = QueryParserTypeUtils.toBoolean(parsedParams[keys.isPublic]);
        const directory: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.directory]);
        const search: string | undefined = QueryParserTypeUtils.toString(parsedParams[keys.search]);
        const tags = queryTags?.filter((queryTag: string) => availableTags.includes(queryTag));
        const isDisabled: boolean | undefined = QueryParserTypeUtils.toBoolean(parsedParams[keys[QueryParameter.isDisabled]]);
        const isShared: boolean | undefined = QueryParserTypeUtils.toBoolean(parsedParams[keys[QueryParameter.isShared]]);

        return { searchListType, extraContentLibraryType, isPublic, directory, search, tags, isDisabled, isShared };
    }
}

export { ContentLibraryUrlQueryParser };

import React, { Component } from "react";
import { Section } from "components/Section";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { TestId } from "utils/TestId";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { ColorPicker } from "components/Inputs/ColorPicker/ColorPicker";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Input } from "components/Inputs/Input/Input";
import { ColorSelect, ColorOption } from "components/Inputs/ColorSelect/ColorSelect";
import { ColorType, SettingsSelectors } from "selectors/SettingsSelectors";
import { NamedColor, ClientAppConfig, SupportedClient, ClientConfigInput } from "api/graphql/types";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { ColorsOfDaysType } from "utils/TypeUtils";
import { ClientSettingsSelectOptions } from "models/ClientSettingsSelectOptions";
import { ObjectUtils } from "utils/ObjectUtils";
import { Intl } from "i18n/Intl";
import { AgendaType } from "./ClientSettingsPage";
import { ColorsOfDays, ColorsOfDaysUtils } from "utils/ColorsOfDaysUtils";
import { Api } from "api/Api";
import { cloneDeep, isEqual } from "lodash";
import { Alert } from "components/Alert/Alert";
import { Path } from "utils/Path";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { ApplicationState } from "reducers/index";
import { AccountSelectors } from "selectors/AccountSelectors";
import { ApiError, ApiErrorCode } from "api/ApiError";
import { BottomBar } from "components/BottomBar";
import { Button } from "components/Button/Button";
import { Loading, LoadingType } from "components/Loading/Loading";
import { Prompt } from "components/Prompt";

interface ReduxProps {
    client: SupportedClient | null;
    unlockColors: string[];
}

interface RouteParams {
    clientExtId?: string;
}

interface OwnProps {
    onShowNotification: () => void;
}

type Props = OwnProps & RouteComponentProps<RouteParams> & ReduxProps & DispatchProp;

interface CurrentConfig {
    unlock: {
        enabled: boolean;
        colors: string[];
        pattern: string[];
        failMessage: string;
    };
    agenda: AgendaType;
    flowchartItemBackgroundColor: string | null;
    selectedColorsOfDaysType: SelectOption<ColorsOfDaysType>;
    highContrastMode: boolean;
}

interface FormErrors {
    unlockPattern: string | null;
    colorsOfDays: string | null;
    failMessage: string | null;
}

interface State {
    clientAppConfig: ClientAppConfig | null;
    notificationOnProfilePage: string | null;
    validationEnabled: boolean;
    currentConfig: CurrentConfig;
    formErrors: FormErrors;
    isLoading: boolean;
}

class AppTabComponent extends Component<Props, State> {
    private unlockPatternRef: ColorPicker | null = null;
    private colorsOfDaysRef: Select<ColorsOfDaysType> | null = null;
    private failMessageDef: HTMLInputElement | null = null;

    public readonly state: State = {
        clientAppConfig: null,
        notificationOnProfilePage: null,
        validationEnabled: false,
        currentConfig: {
            unlock: {
                enabled: false,
                colors: [],
                pattern: [],
                failMessage: "",
            },
            agenda: {
                itemBackgroundColor: null,
                colorsOfDays: ColorsOfDaysUtils.getEmptyColorsOfDays(),
                itemCheckBoxSize: ClientSettingsSelectOptions.agendaItemCheckboxSize[0],
                itemSize: ClientSettingsSelectOptions.agendaItemSize[0],
                calendarView: ClientSettingsSelectOptions.calendarView[0],
                withoutDefaultAgenda: {
                    text: null,
                    image: null,
                },
            },
            flowchartItemBackgroundColor: null,
            selectedColorsOfDaysType: ClientSettingsSelectOptions.colorsOfDaysType[0],
            highContrastMode: false,
        },
        formErrors: {
            unlockPattern: null,
            colorsOfDays: null,
            failMessage: null,
        },
        isLoading: true,
    };

    private getDefaultConfig = (clientAppConfig: ClientAppConfig): CurrentConfig => {
        const { agenda, flowchartItemBackgroundColor, unlock, highContrastMode } = clientAppConfig;

        const colorsOfDays: ColorsOfDaysType = agenda && agenda.colorsOfDays ? ColorsOfDaysType.custom : ColorsOfDaysType.default;
        const selectedColorsOfDaysType: SelectOption<ColorsOfDaysType> = Select.getSelectOption(ClientSettingsSelectOptions.colorsOfDaysType, colorsOfDays);

        return {
            unlock: {
                enabled: unlock.enabled,
                colors: unlock.colors || this.props.unlockColors,
                pattern: unlock.pattern || [],
                failMessage: unlock.failMessage || "",
            },
            agenda: {
                itemBackgroundColor: agenda ? agenda.itemBackgroundColor : null,
                colorsOfDays: agenda && agenda.colorsOfDays ? agenda.colorsOfDays : ColorsOfDaysUtils.getEmptyColorsOfDays(),
                itemCheckBoxSize: Select.getSelectOption(ClientSettingsSelectOptions.agendaItemCheckboxSize, agenda ? agenda.itemCheckBoxSize : null),
                itemSize: Select.getSelectOption(ClientSettingsSelectOptions.agendaItemSize, agenda ? agenda.itemSize : null),
                calendarView: Select.getSelectOption(ClientSettingsSelectOptions.calendarView, agenda ? agenda.calendarView : false),
                withoutDefaultAgenda: {
                    text: agenda && agenda.withoutDefaultAgenda ? agenda.withoutDefaultAgenda.text : null,
                    image: agenda && agenda.withoutDefaultAgenda && agenda.withoutDefaultAgenda.image ? agenda.withoutDefaultAgenda.image : null,
                },
            },
            flowchartItemBackgroundColor,
            selectedColorsOfDaysType,
            highContrastMode,
        };
    };

    private refreshSettingsData = (clientId: string): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { clientAppConfig, notificationOnProfilePage } = await Api.getClientAppSettingsData(clientId);
                    this.setState({
                        clientAppConfig,
                        currentConfig: this.getDefaultConfig(cloneDeep(clientAppConfig)),
                        notificationOnProfilePage,
                        isLoading: false,
                    });
                } catch (error) {
                    Alert.error({
                        title: IntlHelpers.getMessageFromError(error),
                        callback: () => {
                            this.props.history.replace(Path.dashboard);
                        },
                    });
                }
            },
        );
    };

    public componentDidMount(): void {
        if (this.props.client) {
            this.refreshSettingsData(this.props.client.id);
        } else {
            Alert.error({ title: IntlHelpers.getMessageFromError(new ApiError(ApiErrorCode.NOT_FOUND)) });
            this.setState({ isLoading: false });
        }
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.client && nextProps.client && this.props.client.id !== nextProps.client.id) {
            this.refreshSettingsData(nextProps.client.id);
        }
    }

    private onCurrentConfigChange = (currentConfigChanges: Partial<CurrentConfig>, formErrorChanges?: Partial<FormErrors>): void => {
        this.setState({ currentConfig: { ...this.state.currentConfig, ...currentConfigChanges }, formErrors: { ...this.state.formErrors, ...formErrorChanges } });
    };

    private onColorOfDayChange = (day: keyof ColorsOfDays) => (color: NamedColor | null) => {
        this.onCurrentConfigChange({
            agenda: { ...this.state.currentConfig.agenda, colorsOfDays: { ...this.state.currentConfig.agenda.colorsOfDays, [day]: color ? color.value : null } },
        });
    };

    private convertCurrentConfigToClientConfigInput = (): ClientConfigInput => {
        const { selectedColorsOfDaysType, agenda, flowchartItemBackgroundColor, unlock, highContrastMode } = this.state.currentConfig;

        return {
            agenda: {
                itemBackgroundColor: agenda.itemBackgroundColor,
                colorsOfDays:
                    selectedColorsOfDaysType.value === ColorsOfDaysType.custom
                        ? ObjectUtils.keys<ColorsOfDays>(agenda.colorsOfDays).reduce((prevState: any, day: keyof ColorsOfDays) => ({ ...prevState, [day]: agenda.colorsOfDays[day] }), {})
                        : null,
            },
            flowchartItemBackgroundColor,
            unlock: {
                colors: unlock.colors,
                enabled: unlock.enabled,
                failMessage: unlock.failMessage,
                pattern: unlock.pattern,
            },
            highContrastMode,
        };
    };

    private isChanged = (): boolean => {
        if (this.state.clientAppConfig) {
            return !isEqual(this.getDefaultConfig(this.state.clientAppConfig), this.state.currentConfig);
        }
        return false;
    };

    private onSaveClick = async (): Promise<void> => {
        if (!this.props.client) {
            return;
        }

        const formErrors = {
            unlockPattern: this.state.currentConfig.unlock.enabled ? IntlHelpers.getValidationError(Validator.validateClientConfigUnlockPattern(this.state.currentConfig.unlock.pattern)) : null,
            colorsOfDays: IntlHelpers.getValidationError(
                Validator.validateClientConfigColorsOfDays(this.state.currentConfig.selectedColorsOfDaysType.value, this.state.currentConfig.agenda.colorsOfDays),
            ),
            failMessage: this.state.currentConfig.unlock.enabled ? IntlHelpers.getValidationError(Validator.validateNonEmpty(this.state.currentConfig.unlock.failMessage)) : null,
        };

        if (formErrors.unlockPattern || formErrors.colorsOfDays || formErrors.failMessage) {
            this.setState({ validationEnabled: true, formErrors }, () => {
                if (!!formErrors.unlockPattern && this.unlockPatternRef) {
                    this.unlockPatternRef.scrollIntoView();
                } else if (!!formErrors.colorsOfDays && this.colorsOfDaysRef) {
                    this.colorsOfDaysRef.scrollIntoView();
                } else if (!!formErrors.failMessage && this.failMessageDef) {
                    this.failMessageDef.scrollIntoView();
                }
            });
            return;
        }

        try {
            const clientAppConfig: ClientAppConfig = await Api.updateClientConfig(this.props.client.id, this.convertCurrentConfigToClientConfigInput());

            this.props.onShowNotification();

            this.setState({ clientAppConfig, currentConfig: this.getDefaultConfig(cloneDeep(clientAppConfig)) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
        this.setState({ isLoading: false });
    };

    private onCancelClick = (): void => {
        this.setState({ validationEnabled: false, currentConfig: this.getDefaultConfig(cloneDeep(this.state.clientAppConfig!)) });
    };

    public render(): React.ReactElement<any> {
        if (!this.props.client || (!this.state.isLoading && !this.state.clientAppConfig)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <>
                <Section label={Intl.formatMessage({ id: "page.clientSettings.appTab.title" })}>
                    <p className="lead">{Intl.formatMessage({ id: "page.clientSettings.appTab.description" }, { name: this.props.client.name })}</p>
                    <InputWrapper id={TestId.clientSettings.unlockEnabled} inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.unlock.title" })}>
                        <Checkbox
                            label={""}
                            checked={this.state.currentConfig.unlock.enabled}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({ unlock: { ...this.state.currentConfig.unlock, enabled: event.currentTarget.checked } });
                            }}
                        />
                    </InputWrapper>
                    {this.state.currentConfig.unlock.enabled && (
                        <>
                            <InputWrapper
                                id={TestId.clientSettings.unlockPatternColorPicker}
                                inputLabel={""}
                                errorMessage={this.state.validationEnabled && this.state.formErrors.unlockPattern ? this.state.formErrors.unlockPattern : ""}
                            >
                                <ColorPicker
                                    ref={(ref: ColorPicker | null) => {
                                        this.unlockPatternRef = ref;
                                    }}
                                    addAriaLabel={Intl.formatMessage({ id: "common.add" })}
                                    clearAriaLabel={Intl.formatMessage({ id: "common.clear" })}
                                    emptyAriaLabel={Intl.formatMessage({ id: "common.empty" })}
                                    title={Intl.formatMessage({ id: "page.clientSettings.appTab.unlock.pattern.title" })}
                                    value={this.state.currentConfig.unlock.pattern}
                                    onChange={(pattern: string[]) => {
                                        const appFormErrors: Partial<FormErrors> = { ...this.state.formErrors };
                                        appFormErrors.unlockPattern = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateClientConfigUnlockPattern(pattern)) : null;
                                        this.onCurrentConfigChange({ unlock: { ...this.state.currentConfig.unlock, pattern } }, appFormErrors);
                                    }}
                                    options={this.state.currentConfig.unlock.colors || []}
                                />
                            </InputWrapper>
                            <InputWrapper
                                id={TestId.clientSettings.failMessageInput}
                                inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.unlock.failMessage.label" })}
                                errorMessage={this.state.validationEnabled && this.state.formErrors.failMessage ? this.state.formErrors.failMessage : ""}
                            >
                                <Input
                                    innerRef={(ref: HTMLInputElement | null) => {
                                        this.failMessageDef = ref;
                                    }}
                                    value={this.state.currentConfig.unlock.failMessage}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const appFormErrors: Partial<FormErrors> = { ...this.state.formErrors };
                                        appFormErrors.failMessage = this.state.validationEnabled ? IntlHelpers.getValidationError(Validator.validateNonEmpty(event.currentTarget.value)) : null;
                                        this.onCurrentConfigChange({ unlock: { ...this.state.currentConfig.unlock, failMessage: event.currentTarget.value } }, appFormErrors);
                                    }}
                                    placeholder={Intl.formatMessage({ id: "page.clientSettings.appTab.unlock.failMessage.placeholder" })}
                                    hasError={this.state.validationEnabled && !!this.state.formErrors.failMessage}
                                />
                            </InputWrapper>
                        </>
                    )}
                    <hr />
                    <InputWrapper id={TestId.clientSettings.agendaBackgroundItemColor} inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.agendaItemBackground" })}>
                        <ColorSelect
                            colorType={ColorType.itemBackground}
                            selectedColor={this.state.currentConfig.agenda.itemBackgroundColor}
                            onChange={(color: NamedColor | null): void => {
                                this.onCurrentConfigChange({ agenda: { ...this.state.currentConfig.agenda, itemBackgroundColor: color ? color.value : null } });
                            }}
                        />
                    </InputWrapper>
                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.flowchartItemBackground" })}>
                        <ColorSelect
                            colorType={ColorType.itemBackground}
                            selectedColor={this.state.currentConfig.flowchartItemBackgroundColor}
                            onChange={(color: NamedColor | null): void => {
                                this.onCurrentConfigChange({ flowchartItemBackgroundColor: color ? color.value : null });
                            }}
                        />
                    </InputWrapper>
                    <InputWrapper id={TestId.clientSettings.colorsOfDaysType} inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.colorsOfDaysType" })}>
                        <Select
                            ref={(ref: Select<ColorsOfDaysType> | null) => {
                                this.colorsOfDaysRef = ref;
                            }}
                            value={this.state.currentConfig.selectedColorsOfDaysType}
                            onChange={(selectedColorsOfDaysType: SelectOption<ColorsOfDaysType>) => {
                                let colorsOfDays = this.state.currentConfig.agenda.colorsOfDays;
                                if (selectedColorsOfDaysType.value === ColorsOfDaysType.default) {
                                    colorsOfDays = ColorsOfDaysUtils.getEmptyColorsOfDays();
                                }
                                this.onCurrentConfigChange({ selectedColorsOfDaysType, agenda: { ...this.state.currentConfig.agenda, colorsOfDays } });
                            }}
                            options={ClientSettingsSelectOptions.colorsOfDaysType}
                        />
                    </InputWrapper>
                    {this.state.currentConfig.selectedColorsOfDaysType.value === ColorsOfDaysType.custom && (
                        <div className="colorsOfDays">
                            {ObjectUtils.keys(this.state.currentConfig.agenda.colorsOfDays).map((colorsOfDay: keyof ColorsOfDays) => {
                                const errorMessage: string | null =
                                    this.state.validationEnabled && this.state.formErrors.colorsOfDays && this.state.currentConfig.agenda.colorsOfDays[colorsOfDay] === null
                                        ? this.state.formErrors.colorsOfDays
                                        : null;

                                return (
                                    <InputWrapper
                                        key={colorsOfDay}
                                        inputLabel={Intl.formatMessage({ id: `page.clientSettings.days.${colorsOfDay}` })}
                                        isOriginalCase
                                        errorMessage={errorMessage}
                                        subLevel
                                    >
                                        <ColorSelect
                                            colorType={ColorType.background}
                                            selectedColor={this.state.currentConfig.agenda.colorsOfDays[colorsOfDay]}
                                            onChange={this.onColorOfDayChange(colorsOfDay)}
                                        />
                                    </InputWrapper>
                                );
                            })}
                        </div>
                    )}
                    <hr />
                    <InputWrapper id={TestId.clientSettings.highContrastMode} inputLabel={Intl.formatMessage({ id: "page.clientSettings.appTab.highContrastMode" })}>
                        <Checkbox
                            checked={this.state.currentConfig.highContrastMode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.onCurrentConfigChange({ highContrastMode: event.currentTarget.checked });
                            }}
                        />
                    </InputWrapper>
                </Section>
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button id={TestId.clientSettings.cancelButton} hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button id={TestId.clientSettings.saveButton} label={Intl.formatMessage({ id: "common.save" })} onClick={this.onSaveClick} />
                    </div>
                </BottomBar>
                <Prompt when={this.isChanged()} />
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps<RouteParams>, ApplicationState> = (state: ApplicationState, props: RouteComponentProps<RouteParams>): ReduxProps => {
    const client: SupportedClient | null = AccountSelectors.getClientByExtId(state, props.match.params.clientExtId);
    const unlockColors: string[] = SettingsSelectors.getUnlockColorOptions(state, false).map((color: ColorOption): string => color.value || "");
    return {
        client,
        unlockColors,
    };
};

export const AppTab = withRouter(connect(mapStateToProps)(AppTabComponent));

export default AppTab;

import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";
import { AgendaItemType } from "api/graphql/types";

type AgendaItemTypeOption = SelectOption<AgendaItemType>;

class AgendaItemTypeOptions {
    public static get(): AgendaItemTypeOption[] {
        return [AgendaItemType.Simple, AgendaItemType.Select].map(
            (agendaItemType: AgendaItemType): AgendaItemTypeOption => {
                return { id: agendaItemType, label: Intl.formatMessage({ id: `enum.agendaItemType.${agendaItemType}` }), value: agendaItemType };
            },
        );
    }
}

export type { AgendaItemType, AgendaItemTypeOption };
export { AgendaItemTypeOptions };

import React, { Component } from "react";
import { PageType } from "utils/TypeUtils";
import { ContentPageUtils } from "pages/_shared/ContentPageUtils";
import {
    Account,
    CreateEducationContentModuleInput,
    CreateEducationContentInput,
    EducationContentModule_documentContents,
    EducationContentModule_videoContents,
    EducationContentSubModule,
    EducationContentType,
} from "api/graphql/types";
import { withRouter, RouteComponentProps } from "react-router";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers";
import { Redirect } from "react-router-dom";
import { Path } from "utils/Path";
import { EducationSubModuleForm } from "./EducationSubModuleForm";
import { Loading, LoadingType } from "components/Loading/Loading";
import { isNil, isEqual } from "lodash";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { uuid4 } from "@sentry/utils";
import { AsyncUtils } from "utils/AsyncUtils";

interface ReduxProps {
    account: Account;
}

interface RouteParams {
    moduleId?: string;
    subModuleId?: string;
}

type ComponentProps = RouteComponentProps<RouteParams>;

type Props = ComponentProps & ReduxProps & DispatchProp;

export interface ContentsOrder {
    contentId: string;
    position: number;
}

export interface LocalCreateEducationContentInput extends CreateEducationContentInput {
    id: string;
    url: string;
    thumbnail: string;
    titleError: string | null;
}

export interface LocalEducationContentModuleInput {
    parentId: string;
    title: string;
    documentContents?: LocalCreateEducationContentInput[] | null;
    videoContents?: LocalCreateEducationContentInput[] | null;
}

interface State {
    isLoading: boolean;
    subModule: LocalEducationContentModuleInput;
    fileProgresses: Progresses;
}

export interface Progresses {
    [id: string]: number;
}

class EducationSubModulePageComponent extends Component<Props, State> {
    private static getInitialStateFromProps = (props: Props): State => {
        return {
            isLoading: !isNil(props.match.params.subModuleId),
            subModule: {
                parentId: props.match.params.moduleId || "",
                title: "",
                documentContents: [],
                videoContents: [],
            },
            fileProgresses: {},
        };
    };

    public readonly state: State = EducationSubModulePageComponent.getInitialStateFromProps(this.props);

    public componentDidMount(): void {
        if (this.state.isLoading) {
            this.refreshSubModule(this.props);
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.match.params.subModuleId !== this.props.match.params.subModuleId) {
            this.refreshSubModule(this.props);
        }
    }

    private refreshSubModule = (props: Props): void => {
        const { subModuleId } = props.match.params;
        if (!subModuleId) {
            this.setState(EducationSubModulePageComponent.getInitialStateFromProps(props));
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const subModule: EducationContentSubModule = await Api.getEducationSubModuleById(this.props.match.params.moduleId!, subModuleId);
                    const convertedSubModule: LocalEducationContentModuleInput = this.convertToLocalEducationContentModule(subModule);
                    this.setState({ isLoading: false, subModule: convertedSubModule });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error), callback: () => this.props.history.push(Path.dashboard) });
                    return;
                }
            },
        );
    };

    private getCreateEducationContentModuleInput = (subModule: LocalEducationContentModuleInput): CreateEducationContentModuleInput => {
        return {
            parentId: this.props.match.params.moduleId!,
            title: subModule.title,
        };
    };

    private convertToLocalEducationContentModule = (subModule: EducationContentSubModule): LocalEducationContentModuleInput => {
        return {
            parentId: this.props.match.params.moduleId!,
            title: subModule.title,
            documentContents: subModule.documentContents.map((document: EducationContentModule_documentContents) => {
                return { id: document.id || uuid4(), type: document.type, title: document.title, file: document, titleError: null, url: document.url, thumbnail: document.thumbnail, progress: null };
            }),
            videoContents: subModule.videoContents.map((video: EducationContentModule_videoContents) => {
                return { id: video.id || uuid4(), type: video.type, title: video.title, file: video, titleError: null, url: video.url, thumbnail: video.thumbnail, progress: null };
            }),
        };
    };

    private getMergedContents = (subModule: LocalEducationContentModuleInput): LocalCreateEducationContentInput[] => {
        let contents: LocalCreateEducationContentInput[] = [];
        if (subModule.documentContents && subModule.documentContents.length > 0) {
            contents = [...contents, ...subModule.documentContents];
        }
        if (subModule.videoContents && subModule.videoContents.length > 0) {
            contents = [...contents, ...subModule.videoContents];
        }
        return contents;
    };

    private onProgressChange = (id: string, progress: number) => {
        const progressRounded: number = Math.round(progress);
        this.setState({ fileProgresses: { ...this.state.fileProgresses, [id]: progressRounded } });
    };

    private onSubmit = async (subModule: LocalEducationContentModuleInput): Promise<LocalEducationContentModuleInput> => {
        const subModuleId: string | undefined = this.props.match.params.subModuleId;
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);

        if (pageType === PageType.create) {
            const result = await Api.createEducationContentModule(this.getCreateEducationContentModuleInput(subModule));

            let contentsOrder: ContentsOrder[] = [];
            const contentsToCreate: LocalCreateEducationContentInput[] = this.getMergedContents(subModule);
            this.getMergedContents(subModule).forEach(async (documentContent: LocalCreateEducationContentInput, index: number) => {
                const createResult = await Api.createEducationContent(result.id, (progress: number) => this.onProgressChange(result.id, progress), {
                    type: documentContent.type,
                    title: documentContent.title,
                    file: documentContent.file,
                });
                contentsOrder = [...contentsOrder, { contentId: createResult.createEducationContent.id, position: index + 1 }];

                if (contentsToCreate.length === contentsOrder.length) {
                    await Api.orderEducationContents(result.id, contentsOrder);
                }
            });

            this.refreshSubModule(this.props);
            return this.state.subModule;
        } else if (pageType === PageType.edit && !isNil(subModuleId)) {
            if (!isEqual(this.state.subModule.documentContents, subModule.documentContents) || !isEqual(this.state.subModule.videoContents, subModule.videoContents)) {
                const mergedSubModuleContents: LocalCreateEducationContentInput[] = this.getMergedContents(subModule);
                const mergedStateContents: LocalCreateEducationContentInput[] = this.getMergedContents(this.state.subModule);

                // Only update the existing contents
                await AsyncUtils.forEach(mergedSubModuleContents, async (documentContent: LocalCreateEducationContentInput) => {
                    const currentStateContentIds: string[] = mergedStateContents.map((document: LocalCreateEducationContentInput) => document.id);
                    if (currentStateContentIds.includes(documentContent.id)) {
                        await Api.updateEducationContent({ id: documentContent.id, title: documentContent.title, type: EducationContentType.normal });
                    }
                });

                // Delete contents that are no longer present
                const subModuleContentIds: string[] = mergedSubModuleContents.map((content: LocalCreateEducationContentInput) => content.id);
                const contentIdsToDelete: string[] = mergedStateContents
                    .filter((stateContent: LocalCreateEducationContentInput) => !subModuleContentIds.includes(stateContent.id))
                    .map((filteredContent: LocalCreateEducationContentInput) => filteredContent.id);
                await AsyncUtils.forEach(contentIdsToDelete, async (contentIdToDelete: string) => {
                    await Api.deleteEducationContent(contentIdToDelete);
                });

                // Create the new contents and populating content ids' order
                let contentsOrder: ContentsOrder[] = [];
                const stateContentIds = mergedStateContents.map((content: LocalCreateEducationContentInput) => content.id);
                await AsyncUtils.forEach(mergedSubModuleContents, async (documentContent: LocalCreateEducationContentInput, index) => {
                    if (stateContentIds.includes(documentContent.id)) {
                        contentsOrder = [...contentsOrder, { contentId: documentContent.id, position: index + 1 }];
                    } else {
                        const result = await Api.createEducationContent(subModuleId, (progress: number) => this.onProgressChange(documentContent.id, progress), {
                            type: documentContent.type,
                            title: documentContent.title,
                            file: documentContent.file,
                        });
                        contentsOrder = [...contentsOrder, { contentId: result.createEducationContent.id, position: index + 1 }];
                    }
                });

                // Ordering contents
                await Api.orderEducationContents(subModuleId, contentsOrder);
            }

            const result = await Api.updateEducationContentModule({ id: subModuleId, title: subModule.title });
            this.setState({ subModule: this.convertToLocalEducationContentModule(result) });
            return this.convertToLocalEducationContentModule(result);
        } else {
            throw new Error("View cannot submit!");
        }
    };

    public render(): React.ReactElement {
        const pageType: PageType = ContentPageUtils.getPageType(this.props.match.path);
        const isSubModuleIdPresentInEditMode: boolean = pageType === PageType.create || (pageType === PageType.edit && !isNil(this.props.match.params.subModuleId));

        if (!isSubModuleIdPresentInEditMode || isNil(this.props.match.params.moduleId)) {
            return <Redirect to={Path.dashboard} />;
        }

        if (this.state.isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        return (
            <EducationSubModuleForm
                pageType={pageType}
                moduleId={this.props.match.params.moduleId}
                subModule={this.state.subModule}
                fileProgresses={this.state.fileProgresses}
                onSubmit={this.onSubmit}
                isLoading={this.state.isLoading}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const EducationSubModulePage = withRouter(connect(mapStateToProps)(EducationSubModulePageComponent));

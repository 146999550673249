import { Intl } from "i18n/Intl";
import { SelectOption } from "components/Inputs/Select/Select";
import { FlowchartItemType } from "api/graphql/types";

export type FlowchartItemTypeSelectOption = SelectOption<FlowchartItemType>;

class FlowchartItemTypeSelectOptions {
    public static get(): FlowchartItemTypeSelectOption[] {
        return [FlowchartItemType.Simple, FlowchartItemType.Select].map((flowchartItemType: FlowchartItemType) => {
            return { id: flowchartItemType, label: Intl.formatMessage({ id: `enum.flowchartItemType.${flowchartItemType}` }), value: flowchartItemType };
        });
    }
}

export { FlowchartItemTypeSelectOptions };

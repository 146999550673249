import React, { PureComponent } from "react";
import { AssetType, AssetContent, Asset } from "api/graphql/types";
import { AssetInput } from "pages/_shared/Draggables/Input/AssetInput";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { Intl } from "i18n/Intl";
import { isNil, isEqual } from "lodash";
import { Button } from "components/Button/Button";

interface Props {
    itemKey: string;
    value: SelectionBoardItemLocal;
    onChange: (selectionBoardItem: SelectionBoardItemLocal | null) => void;
    error: string | null;
    disabled: boolean;
}

export interface SelectionBoardItemLocal {
    key: string;
    id: string;
    title: string;
    image: Asset | null;
}

type State = SelectionBoardItemLocal;

class ClientSelectionBoardItemInputForm extends PureComponent<Props, State> {
    public static getInitialInputForm = (props: Props) => {
        return {
            key: props.itemKey,
            id: props.value?.id || "",
            title: props.value?.title || "",
            image: props.value?.image || null,
        };
    };

    public readonly state: State = ClientSelectionBoardItemInputForm.getInitialInputForm(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.value, nextProps.value)) {
            this.setState({ ...ClientSelectionBoardItemInputForm.getInitialInputForm(nextProps) });
        }
    }

    private onImageChange = (asset: AssetContent[]): void => {
        this.setState({ image: asset[0] || null }, () => {
            if (this.state.title === "" && this.state.image === null) {
                this.props.onChange(null);
                return;
            }
            this.props.onChange({ key: this.state.key, id: this.state.id, title: this.state.title, image: asset[0] || null });
        });
    };

    private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const title: string = event.currentTarget.value;
        this.setState({ title }, () => this.props.onChange({ ...this.state }));
    };

    private onTitleBlur = () => {
        if (this.state.title === "" && this.state.image === null) {
            this.props.onChange(null);
            return;
        }
        this.props.onChange({ ...this.state });
    };

    public render(): React.ReactElement {
        return (
            <div className="selection-board-item">
                <AssetInput
                    assetType={AssetType.image}
                    maxItemCount={1}
                    droppableId={this.props.itemKey}
                    value={!isNil(this.props.value?.image) ? [this.props.value!.image] : []}
                    onChange={this.onImageChange}
                    hidePlaceholder={true}
                    hasError={!isNil(this.props.error)}
                    disabled={this.props.disabled}
                />
                <InputWrapper errorMessage={this.props.error}>
                    <Input
                        type="text"
                        style={{ paddingRight: 36 }}
                        value={this.state.title}
                        onChange={this.onTitleChange}
                        placeholder={Intl.formatMessage({ id: "page.clientSelectionBoards.itemName.placeholder" })}
                        hasError={!isNil(this.props.error)}
                        onBlur={this.onTitleBlur}
                        disabled={this.props.disabled}
                    />
                    {this.state.title.length > 0 && !this.props.disabled && (
                        <div className="uploaded-image" style={{ right: 10, bottom: 25 }}>
                            <Button
                                className={"left-auto"}
                                link
                                tabIndex={-1}
                                icon={{ name: "fa-times", large: true }}
                                onClick={() => {
                                    this.setState({ title: "" }, this.onTitleBlur);
                                }}
                            />
                        </div>
                    )}
                </InputWrapper>
            </div>
        );
    }
}

export { ClientSelectionBoardItemInputForm };

import { Api } from "../api/Api";
import * as GqlTypes from "api/graphql/types";
import { DirectoryUtils } from "../utils/DirectoryUtils";

type PersonalAssetDirectory = GqlTypes.getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId | null;
type PublicAssetDirectory = GqlTypes.getPublicAssetDirectoriesByParentId_getPublicAssetDirectoriesByParentId | null;
type PersonalAssetChildrenDirectory = GqlTypes.getPersonalAssetDirectoriesByParentId_getPersonalAssetDirectoriesByParentId_children;
type DirectoryList = {
    id: string;
    name: string;
    toPage?: string;
    children?: DirectoryList[] | null;
};

class ContentLibraryDirectories {
    private static fetchPersonalAssetDirectories = async (): Promise<PersonalAssetDirectory[]> => {
        const response: GqlTypes.getPersonalAssetDirectoriesByParentId = await Api.getPersonalAssetDirectoriesByParentId(null);
        return response.getPersonalAssetDirectoriesByParentId;
    };

    private static fetchPublcAssetDirectories = async (): Promise<PersonalAssetDirectory[]> => {
        const response: GqlTypes.getPublicAssetDirectoriesByParentId = await Api.getPublicAssetDirectoriesByParentId(null);
        return response.getPublicAssetDirectoriesByParentId;
    };

    public static getPersonalAssetDirectories = async (path: string, flattenChildren: boolean = false): Promise<DirectoryList[]> => {
        const personalAssetDirectories: PersonalAssetDirectory[] = await ContentLibraryDirectories.fetchPersonalAssetDirectories();

        return DirectoryUtils.processDirectories(personalAssetDirectories, path, false, flattenChildren);
    };

    public static getPublicAssetDirectories = async (path: string, flattenChildren: boolean = false): Promise<DirectoryList[]> => {
        const publicAssetDirectories: PublicAssetDirectory[] = await ContentLibraryDirectories.fetchPublcAssetDirectories();

        return DirectoryUtils.processDirectories(publicAssetDirectories, path, true, flattenChildren);
    };
}

export { ContentLibraryDirectories };
export type { DirectoryList, PersonalAssetDirectory, PersonalAssetChildrenDirectory };

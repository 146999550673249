import React, { Component } from "react";
import { OptionMenuItem, OptionsMenu } from "components/OptionsMenu/OptionsMenu";
import { RouteComponentProps, withRouter } from "react-router";
import { Intl } from "i18n/Intl";
import { store } from "store";
import { Path } from "utils/Path";
import { AccountType, AssetContent, AssetType } from "api/graphql/types";
import { connect, DispatchProp } from "react-redux";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { Alert } from "components/Alert/Alert";
import { ApplicationState } from "reducers";
import { Api } from "api/Api";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ContentPageUtils, PageContent } from "../ContentPageUtils";

interface ReduxProps {
    accountType: AccountType;
}

interface ComponentProps {
    asset: AssetContent;
    isPublic?: boolean;
    pageContent: PageContent;
    clientExtId?: string;
    supporterExtId?: string | null;
    supervisorId?: string | null;
    isOwnAsset: boolean;
    isUsed?: boolean;
    onDeleteClick: () => void;
    onToggleClick?: () => void;
    onCopyClick?: () => Promise<void>;
    onMoveClick?: () => void;
    refreshAssets?: () => void;
}

type Props = ComponentProps & RouteComponentProps & ReduxProps & DispatchProp;

class AssetOptionMenuComponent extends Component<Props> {
    private XHR: XMLHttpRequest = new XMLHttpRequest();

    private readonly download = (url: string, fileName: string): void => {
        this.XHR.open("GET", url);
        this.XHR.setRequestHeader("authorization", `Bearer ${store.getState().auth.authToken || ""}`);
        this.XHR.responseType = "blob";

        this.XHR.onload = (): void => {
            const blob: Blob = this.XHR.response;
            const anchor: HTMLAnchorElement = document.createElement("a");
            const blobObject: string = window.URL.createObjectURL(blob);
            anchor.href = blobObject;
            anchor.download = fileName;
            anchor.dispatchEvent(new MouseEvent("click"));
            window.URL.revokeObjectURL(blobObject);
        };

        this.XHR.send();
    };

    private readonly onViewClick = (): void => {
        const viewPath: string | undefined = ContentPageUtils.getViewPath(
            this.props.asset,
            this.props.pageContent,
            this.props.clientExtId,
            this.props.supporterExtId,
            undefined,
            this.props.supervisorId,
        );

        if (viewPath) {
            this.props.history.push(viewPath);
        }
    };

    private readonly onEditClick = (): void => {
        this.props.history.push(Path.editAsset(this.props.asset.id));
    };

    private onCopyClick = async (): Promise<void> => {
        try {
            await Api.copyAssetToAccountPersonalLibrary({ assetId: this.props.asset.id });
            Alert.success({ title: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrarySucceed" }) });
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private readonly onShareClick = (): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.shareContent,
                content: this.props.asset,
                onSucceed: this.onShareFinished,
            }),
        );
    };

    private readonly onReplaceAssetClick = (): void => {
        this.props.history.push(Path.replaceAssetItem(this.props.asset.id));
    };

    private onShareFinished = () => {
        if (this.props.refreshAssets) {
            this.props.refreshAssets();
        }
    };

    private getOptions = (): OptionMenuItem[] => {
        const options: OptionMenuItem[] = [];

        if (this.props.isPublic && ![AccountType.admin, AccountType.superadmin, AccountType.supervisor].includes(this.props.accountType)) {
            return [
                {
                    label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                    icon: "fa-clone",
                    onClick: this.onCopyClick,
                },
            ];
        }

        options.push({
            label: Intl.formatMessage({ id: "common.download" }),
            icon: "fa-download",
            onClick: () => {
                if (this.props.asset.url) {
                    this.download(this.props.asset.url, this.props.asset.title);
                }
            },
        });

        if (this.props.isOwnAsset && this.props.accountType === AccountType.supervisor) {
            options.push({ label: Intl.formatMessage({ id: "common.share" }), icon: "fa-share-alt", onClick: this.onShareClick });
        }

        options.push({ label: Intl.formatMessage({ id: "common.view" }), icon: "fa-eye", onClick: this.onViewClick });

        if (
            (this.props.isOwnAsset || (this.props.isPublic && this.props.accountType === AccountType.supervisor)) &&
            [AssetType.audio, AssetType.video, AssetType.image].includes(this.props.asset.assetType)
        ) {
            options.push({ label: Intl.formatMessage({ id: "common.edit" }), icon: "fa-pencil-alt", onClick: this.onEditClick });
            if (this.props.onMoveClick) {
                options.push({ label: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.assetTable.move" }), icon: "fa-file-export", onClick: this.props.onMoveClick });
            }
            options.push({ label: Intl.formatMessage({ id: "page.replaceAssetItemPage.button" }), icon: "fa-exchange-alt", onClick: this.onReplaceAssetClick });
        }

        if ((!this.props.isUsed && this.props.asset.deletable) || [AccountType.admin, AccountType.superadmin].includes(this.props.accountType)) {
            options.push({ label: Intl.formatMessage({ id: "common.delete" }), icon: "fa-trash", onClick: this.props.onDeleteClick });
        }

        if (
            this.props.onCopyClick &&
            !this.props.asset.disabledAt &&
            ![AccountType.superadmin, AccountType.admin].includes(this.props.accountType) &&
            ((this.props.accountType === AccountType.supporter && this.props.clientExtId) || this.props.isPublic)
        ) {
            options.push({
                label: Intl.formatMessage({ id: "common.copyToAccountPersonalLibrary" }),
                icon: "fa-copy",
                onClick: this.props.onCopyClick,
            });
        }

        if (this.props.onToggleClick && !this.props.isOwnAsset && !this.props.isPublic && this.props.accountType === AccountType.supervisor) {
            const isEnabled: boolean = !this.props.asset.disabledAt;
            options.push({ label: Intl.formatMessage({ id: isEnabled ? "common.disable" : "common.enable" }), icon: isEnabled ? "fa-lock" : "fa-unlock", onClick: this.props.onToggleClick });
        }

        return options;
    };

    public render(): React.ReactElement<any> | null {
        const options: OptionMenuItem[] = this.getOptions();

        return options.length > 0 ? <OptionsMenu items={options} /> : null;
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return {
        accountType: state.account!.accountType,
    };
};

export const AssetOptionMenu = withRouter(connect(mapStateToProps)(AssetOptionMenuComponent));

import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Select, SelectOption } from "components/Inputs/Select/Select";
import { ClientProfileData, ClientSupporter } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface ChangeClientManagerDialogProps {
    client: ClientProfileData;
    supporters: ClientSupporter[];
    onChangeClientManagerFinished: () => void;
}

type Props = ChangeClientManagerDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
    selectedSupporter: SupporterOption;
}

type SupporterOption = SelectOption<string>;

class ChangeClientManagerDialogComponent extends Component<Props, State> {
    private static getSupporterOptions = (props: Props): SupporterOption[] => {
        return props.supporters
            .filter((supporter: ClientSupporter) => !!supporter.account)
            .map(
                (supporter: ClientSupporter): SupporterOption => {
                    return { id: supporter.account!.id, label: supporter.name!, value: supporter.account!.id };
                },
            );
    };

    public static getInitialState(props: Props): State {
        return {
            isLoading: false,
            selectedSupporter: ChangeClientManagerDialogComponent.getSupporterOptions(props)[0],
        };
    }

    public readonly state: State = ChangeClientManagerDialogComponent.getInitialState(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        this.setState(ChangeClientManagerDialogComponent.getInitialState(nextProps));
    }

    private shareContent = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (this.props.client) {
                        await Api.createClientChangeManagerRequest(this.props.client.id, this.state.selectedSupporter.value);
                    }
                    this.setState({ isLoading: false }, this.props.onChangeClientManagerFinished);
                    this.props.onHide();
                    Alert.success({ title: Intl.formatMessage({ id: "page.clientProfile.accountData.changeManager.succeed" }) });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Dialog
                visible={this.props.isVisible}
                title={Intl.formatMessage({ id: "page.clientProfile.accountData.changeManager.confirm.title" })}
                onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
            >
                <form>
                    <p>
                        {Intl.formatMessage(
                            { id: "page.clientProfile.accountData.changeManager.confirm.description" },
                            {
                                clientName: this.props.client.name,
                                supporterName: this.state.selectedSupporter ? this.state.selectedSupporter.label : "",
                            },
                        )}
                    </p>

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientProfile.accountData.changeManager.confirm.supporter.label" })}>
                        <Select
                            options={ChangeClientManagerDialogComponent.getSupporterOptions(this.props)}
                            onChange={(supporterOption: SupporterOption): void => {
                                this.setState({ selectedSupporter: supporterOption });
                            }}
                            value={this.state.selectedSupporter}
                        />
                    </InputWrapper>

                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: "page.clientProfile.accountData.changeManager.button.label" })} onClick={this.shareContent} disabled={this.state.isLoading} />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

export const ChangeClientManagerDialog: React.ComponentClass<Props> = ChangeClientManagerDialogComponent;

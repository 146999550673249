import { SelectOption } from "components/Inputs/Select/SelectOption";
import { Intl } from "i18n/Intl";
import { DashboardItem, MainPageType, AgendaItemSize, AgendaItemTextVisibility, FlowChartListType, AgendaItemCheckBoxSize, TimerType, SelectionBoardListType } from "api/graphql/types";
import { ObjectUtils } from "utils/ObjectUtils";
import { ColorsOfDaysType } from "utils/TypeUtils";

class ClientSettingsSelectOptions {
    public static readonly colorsOfDaysType: Array<SelectOption<ColorsOfDaysType>> = [
        { id: ColorsOfDaysType.default, label: Intl.formatMessage({ id: "page.clientSettings.colorsOfDaysType.default" }), value: ColorsOfDaysType.default },
        { id: ColorsOfDaysType.custom, label: Intl.formatMessage({ id: "page.clientSettings.colorsOfDaysType.custom" }), value: ColorsOfDaysType.custom },
    ];

    public static readonly dashboardItem: Array<SelectOption<DashboardItem>> = ObjectUtils.enumAsArray<DashboardItem>(DashboardItem).map(
        (dashboardItem: DashboardItem): SelectOption<DashboardItem> => {
            return { id: dashboardItem, label: Intl.formatMessage({ id: `page.clientSettings.enabledDashboardItems.${dashboardItem}` }), value: dashboardItem };
        },
    );

    public static readonly mainPageType: Array<SelectOption<MainPageType>> = ObjectUtils.enumAsArray<MainPageType>(MainPageType).map(
        (mainPageType: MainPageType): SelectOption<MainPageType> => {
            return { id: mainPageType, label: Intl.formatMessage({ id: `page.clientSettings.mainPageType.${mainPageType}` }), value: mainPageType };
        },
    );

    public static readonly agendaItemSize: Array<SelectOption<AgendaItemSize>> = ObjectUtils.enumAsArray<AgendaItemSize>(AgendaItemSize).map(
        (agendaItemSize: AgendaItemSize): SelectOption<AgendaItemSize> => {
            return { id: agendaItemSize, label: Intl.formatMessage({ id: `page.clientSettings.agendaItemSize.${agendaItemSize}` }), value: agendaItemSize };
        },
    );

    public static readonly agendaItemTextVisibility: Array<SelectOption<AgendaItemTextVisibility>> = ObjectUtils.enumAsArray<AgendaItemTextVisibility>(AgendaItemTextVisibility).map(
        (agendaItemTextVisibility: AgendaItemTextVisibility): SelectOption<AgendaItemTextVisibility> => {
            return { id: agendaItemTextVisibility, label: Intl.formatMessage({ id: `page.clientSettings.agendaItemTextVisibility.${agendaItemTextVisibility}` }), value: agendaItemTextVisibility };
        },
    );

    public static readonly calendarView: Array<SelectOption<boolean>> = [true, false].map(
        (key: boolean): SelectOption<boolean> => {
            return { id: `${key}`, label: Intl.formatMessage({ id: `page.clientSettings.calendarView.${key}` }), value: key };
        },
    );

    public static readonly flowchartListType: Array<SelectOption<FlowChartListType>> = ObjectUtils.enumAsArray<FlowChartListType>(FlowChartListType).map(
        (key: FlowChartListType): SelectOption<FlowChartListType> => {
            return { id: key, label: Intl.formatMessage({ id: `page.clientSettings.flowchartListType.${key}` }), value: key };
        },
    );

    public static readonly agendaItemCheckboxSize: Array<SelectOption<AgendaItemCheckBoxSize>> = ObjectUtils.enumAsArray<AgendaItemCheckBoxSize>(AgendaItemCheckBoxSize).map(
        (key: AgendaItemCheckBoxSize): SelectOption<AgendaItemCheckBoxSize> => {
            return { id: key, label: Intl.formatMessage({ id: `page.clientSettings.agendaItemCheckboxSize.${key}` }), value: key };
        },
    );

    public static readonly timerType: Array<SelectOption<TimerType>> = ObjectUtils.enumAsArray<TimerType>(TimerType).map(
        (key: TimerType): SelectOption<TimerType> => {
            return { id: key, label: Intl.formatMessage({ id: `page.clientSettings.timer.${key}` }), value: key };
        },
    );

    public static readonly selectionBoardListType: Array<SelectOption<SelectionBoardListType>> = ObjectUtils.enumAsArray<SelectionBoardListType>(SelectionBoardListType).map(
        (key: SelectionBoardListType): SelectOption<SelectionBoardListType> => {
            return { id: key, label: Intl.formatMessage({ id: `page.clientSettings.selectionBoardListType.${key}` }), value: key };
        },
    );
}

export { ColorsOfDaysType, ClientSettingsSelectOptions };

import React, { Component } from "react";
import {
    AgendaItemListItem,
    AgendaListItem,
    AssetListItem,
    AssetType,
    AwardListItem,
    FlowchartItemListItem,
    FlowchartListItem,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result as EverydaySituationDirectory,
    getClientEverydaySituationDirectoryList_getClientByExtId_everydaySituationDirectories_result_situations as EverydaySituation,
    InstantAwardListItem,
    SelectionBoardListItem,
    AssetContent,
    SelectionBoard,
} from "api/graphql/types";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Dialog } from "../Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Alert } from "../Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { Column, Table } from "../Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { ApiTypes } from "api/ApiTypes";
import { Image } from "../Image";
import { ImageSrc } from "utils/ImageSrc";
import { Checkbox } from "../Inputs/Checkbox/Checkbox";
import { Button } from "../Button/Button";
import { DisabledItemUtils } from "utils/DisabledItemUtils";
import { DisabledLock } from "components/DisabledLock";

enum DisableAssetItemTableColumn {
    select = "select",
    title = "title",
    info = "info",
    type = "type",
}

export interface DisableRelatingAssetsDialogProps {
    onDisabled: () => void;
    content:
        | AgendaListItem
        | AgendaItemListItem
        | FlowchartListItem
        | FlowchartItemListItem
        | AssetListItem
        | EverydaySituationDirectory
        | EverydaySituation
        | InstantAwardListItem
        | AwardListItem
        | SelectionBoardListItem
        | SelectionBoard;
    clientId?: string;
}

type Props = DisableRelatingAssetsDialogProps & DialogVisibilityProps;

interface State {
    isLoading: boolean;
    assets: AssetContent[];
    selectedAssets: AssetContent[];
}

class DisableRelatingAssetsDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            assets: DisabledItemUtils.getRelatedAssets(this.props.content as any),
            selectedAssets: [],
        };
    }

    private readonly isAssetSelected = (asset: AssetContent): boolean => {
        const { selectedAssets } = this.state;
        return selectedAssets.some(select => select.id === asset.id);
    };

    private readonly toggleSelectAsset = (asset: AssetContent) => {
        const { selectedAssets } = this.state;
        if (this.isAssetSelected(asset)) {
            this.setState({ selectedAssets: selectedAssets.filter(selectedAsset => selectedAsset.id !== asset.id) });
        } else {
            this.setState({ selectedAssets: [...selectedAssets, asset] });
        }
    };

    private readonly getColumns = (): Array<Column<AssetContent>> => {
        const columnNames: DisableAssetItemTableColumn[] = ObjectUtils.enumAsArray<DisableAssetItemTableColumn>(DisableAssetItemTableColumn);

        return columnNames.map(
            (columnName: DisableAssetItemTableColumn): Column<AssetContent> => ({
                id: columnName,
                name: columnName === DisableAssetItemTableColumn.select ? "" : Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.assetTable.columns.${columnName}` }),
                accessor: columnName as keyof AssetContent,
                renderCell: (asset: AssetContent): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case DisableAssetItemTableColumn.title:
                            const assetUrl: string | undefined = asset.assetType === AssetType.avatar ? `${asset.url}?${new Date().getTime()}` : ApiTypes.getAssetImageUrl(asset);
                            return (
                                <div className="table-image-name">
                                    <div className="table-image-container">
                                        <Image src={assetUrl} fallback={asset.assetType === AssetType.audio ? ImageSrc.audioAsset : ImageSrc.asset} />
                                    </div>
                                    {asset.title}
                                </div>
                            );
                        case DisableAssetItemTableColumn.info:
                            return asset.disabledAt ? (
                                <DisabledLock isVisible={true} tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.assetTable.disabledTooltip" })} />
                            ) : null;
                        case DisableAssetItemTableColumn.select:
                            return <Checkbox checked={this.isAssetSelected(asset)} onChange={() => this.toggleSelectAsset(asset)} disabled={asset.disabledAt} />;
                        case DisableAssetItemTableColumn.type:
                            return <>{Intl.formatMessage({ id: `enum.assetType.${asset.assetType}` })}</>;
                        default:
                            return null;
                    }
                },
            }),
        );
    };

    private readonly onDisable = () => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                if (this.state.selectedAssets.length) {
                    try {
                        for (const selectedAsset of this.state.selectedAssets) {
                            await Api.disableContent(selectedAsset);
                        }
                        Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.disableRelatingAssetDialog.disableSucceed" }) });
                        this.props.onDisabled();
                        this.props.onHide();
                    } catch (error) {
                        Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    }
                }
                this.setState({ isLoading: false });
            },
        );
    };

    public render(): React.ReactElement {
        const { assets, isLoading } = this.state;
        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "sharedComponent.disableRelatingAssetDialog.title" })} onCloseClick={this.props.onHide}>
                <div className="content-library-table dialog-asset-table">
                    <p>{Intl.formatMessage({ id: "sharedComponent.disableRelatingAssetDialog.description" })}</p>
                    <Table
                        keyExtractor={(item: AssetContent, column?: Column<AssetContent>): string => {
                            return `${item.id}_${column ? column.id : ""}`;
                        }}
                        columns={this.getColumns()}
                        data={assets}
                        isLoading={false}
                        count={assets.length}
                        renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.disableRelatingAssetDialog.assetTable.noData" })}
                    />
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: "common.disable" })} type="button" disabled={isLoading || !this.state.selectedAssets.length} onClick={this.onDisable} />
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export { DisableRelatingAssetsDialog };

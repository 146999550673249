import React, { Component } from "react";
import { Button } from "components/Button/Button";
import { me_me, SupportedClient } from "api/graphql/types";
import { Avatar } from "components/Avatar";
import { connect, DispatchProp } from "react-redux";
import { Intl } from "i18n/Intl";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { DialogsActions } from "actions/DialogsActions";
import { Api } from "api/Api";
import { AccountActions } from "actions/AccountActions";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";

interface ComponentProps {
    accountId: string | null;
    client: SupportedClient;
}

type Props = ComponentProps & DispatchProp;

interface State {
    isOpen: boolean;
    isDialogVisible: boolean;
    dialogType: "accept" | "reject";
}

class ChangeClientManagerRequestMenuItemComponent extends Component<Props, State> {
    public readonly state: State = {
        isOpen: true,
        isDialogVisible: false,
        dialogType: "accept",
    };

    private onAcceptClick = (): void => {
        this.showDialog(true);
    };

    private onRejectClick = (): void => {
        this.showDialog(false);
    };

    private refreshAccount = async (): Promise<void> => {
        try {
            const account: me_me = await Api.me();
            this.props.dispatch(AccountActions.updateAccount(account));
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private showDialog = (isAccept: boolean) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.verifyChangeClientManager,
                operation: isAccept ? "accept" : "reject",
                client: this.props.client,
                onChangeClientManagerFinished: this.refreshAccount,
            }),
        );
    };

    private onOpenChange = (): void => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    public render(): React.ReactElement<any> | null {
        const { pendingClientManagerChangeRequest, avatar, name } = this.props.client;
        if (!pendingClientManagerChangeRequest || pendingClientManagerChangeRequest.newManager?.id !== this.props.accountId) {
            return null;
        }
        return (
            <li className="dropdown">
                <div className={`toggler${this.state.isOpen ? " toggled" : ""}`} onClick={this.onOpenChange}>
                    <Avatar url={avatar ? avatar.url : null} name={name} />
                    <span className="nav-label">{name}</span>
                </div>
                <ul className={`nav nav-second-level collapse ${this.state.isOpen ? "in" : ""}`}>
                    <li>
                        <span className="new-member">
                            <span className="member-text">
                                {Intl.formatMessage(
                                    { id: "component.navigation.changeClientManagerRequest.message" },
                                    { createdBy: pendingClientManagerChangeRequest.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" }), client: name },
                                )}
                            </span>
                            <Button label={Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.accept" })} onClick={this.onAcceptClick} />
                            <Button label={Intl.formatMessage({ id: "component.navigation.changeClientManagerRequest.reject" })} hollow onClick={this.onRejectClick} />
                        </span>
                    </li>
                </ul>
            </li>
        );
    }
}

export const ChangeClientManagerRequestMenuItem = connect()(ChangeClientManagerRequestMenuItemComponent);

import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { FlowchartInput } from "pages/_shared/Draggables/Input/FlowchartInput";
import { FlowchartContent } from "api/graphql/types";
import isEqual from "lodash/isEqual";

interface Props {
    testIds: {
        enabled: string;
        flowchartId: string;
    };
    value: FlowchartContent | null;
    onChange: (flowchartId: FlowchartContent | null) => void;
    onEnabledChange: (enabled: boolean) => void;
    enabled: boolean;
    disabled?: boolean;
    onViewClick: (flowchart: FlowchartContent) => void;
    onEditClick?: (flowchart: FlowchartContent) => void;
}

class FlowchartRelInput extends Component<Props> {
    private localTranslation = (id: string): string => {
        return Intl.formatMessage({ id: `sharedComponent.inputs.flowchart.${id}` });
    };

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.value, nextProps.value)) {
            if (!nextProps.value) {
                this.onEnabledChange();
            } else {
                this.props.onChange(nextProps.value);
            }
        }
    }

    private onEnabledChange = (): void => {
        this.props.onEnabledChange(!this.props.enabled);
        this.props.onChange(null);
    };

    private onFlowchartChange = (flowcharts: FlowchartContent[]) => {
        if (!flowcharts[0]) {
            this.onEnabledChange();
        } else {
            this.props.onChange(flowcharts[0]);
        }
    };

    public render(): React.ReactElement<any> {
        return (
            <>
                <InputWrapper id={this.props.testIds.enabled} inputLabel={this.localTranslation("enabled.label")}>
                    <Checkbox checked={this.props.enabled} onChange={this.onEnabledChange} disabled={this.props.disabled} />
                </InputWrapper>

                {this.props.enabled && (
                    <FlowchartInput
                        droppableId="flowchart-rel"
                        maxItemCount={1}
                        value={this.props.value ? [this.props.value] : []}
                        onChange={this.onFlowchartChange}
                        disabled={this.props.disabled}
                        onViewClick={this.props.onViewClick}
                        onEditClick={this.props.onEditClick}
                    />
                )}
            </>
        );
    }
}

export { FlowchartRelInput };

import { AlertOptions } from "../components/Alert/AlertTypes";

export enum AlertActionType {
    show = "@alert/show",
    hide = "@alert/hide",
}

interface ShowAction {
    type: AlertActionType.show;
    alertOptions: AlertOptions;
}

interface HideAction {
    type: AlertActionType.hide;
}

export class AlertActions {
    public static show(alertOptions: AlertOptions): ShowAction {
        return { type: AlertActionType.show, alertOptions };
    }

    public static hide(): HideAction {
        return { type: AlertActionType.hide };
    }
}

export type AlertAction = ShowAction | HideAction;

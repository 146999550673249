import React, { PureComponent } from "react";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";

interface Props {
    onViewClick?: () => void;
    onEditClick?: () => void;
    onCloseClick?: () => void;
    isDeleteActive: boolean;
}

class ContentElementButtons extends PureComponent<Props> {
    public render(): React.ReactElement<any> {
        return (
            <div className="buttons">
                {this.props.onViewClick && (
                    <Button
                        className="view"
                        link
                        icon={{ name: "fa-eye", large: true }}
                        title={Intl.formatMessage({ id: "sharedComponent.contentElement.action.view" })}
                        ariaLabel={Intl.formatMessage({ id: "sharedComponent.contentElement.action.view" })}
                        onClick={this.props.onViewClick}
                    />
                )}
                {this.props.onEditClick && (
                    <Button
                        className="edit"
                        link
                        icon={{ name: "fa-pencil-alt", large: true }}
                        title={Intl.formatMessage({ id: "sharedComponent.contentElement.action.edit" })}
                        ariaLabel={Intl.formatMessage({ id: "sharedComponent.contentElement.action.edit" })}
                        onClick={this.props.onEditClick}
                    />
                )}

                {this.props.isDeleteActive && (
                    <Button
                        className="delete"
                        link
                        icon={{ name: "fa-times", large: true }}
                        title={Intl.formatMessage({ id: "sharedComponent.contentElement.action.delete" })}
                        ariaLabel={Intl.formatMessage({ id: "sharedComponent.contentElement.action.delete" })}
                        onClick={this.props.onCloseClick}
                    />
                )}
            </div>
        );
    }
}

export { ContentElementButtons };

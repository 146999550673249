import React, { ReactNode } from "react";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

import "../Inputs/Inputs.scss";

interface Props {
    id?: string;
    children?: ReactNode;
    errorMessage?: string | null;
    inputLabel?: string;
    padded?: boolean | null;
    subLevel?: boolean | null;
    icon?: string;
    customIcon?: string;
    className?: string;
    childrenWrapperClassName?: string;
    alignCenter?: boolean;
    isOriginalCase?: boolean;
    style?: React.CSSProperties;
}

class InputWrapper extends React.PureComponent<Props> {
    public render(): React.ReactElement<any> {
        const { errorMessage, children, icon, customIcon, padded, inputLabel, className, childrenWrapperClassName, subLevel, alignCenter, isOriginalCase, ...otherProps } = this.props;
        const hasLabel: boolean = typeof inputLabel !== "undefined";
        return (
            <div className={`input-wrapper grid-x${subLevel ? " sub-level" : ""}${alignCenter ? " input-wrapper--align-center" : ""}${className ? ` ${className}` : ""}`} {...otherProps}>
                {hasLabel ? (
                    <span className={`input-label cell${padded ? " padded" : ""} ${isOriginalCase ? "original-case" : ""}`}>
                        {icon && <span className={`fa ${icon}`} aria-hidden={true}></span>}
                        {customIcon && <img className="icon" src={customIcon} />}
                        <span>{inputLabel}</span>
                    </span>
                ) : (
                    ""
                )}

                <span className={`cell${hasLabel ? " input-content" : ""}${alignCenter ? " shrink" : ""}${childrenWrapperClassName ? ` ${childrenWrapperClassName}` : ""}`}>
                    {children}

                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </span>
            </div>
        );
    }
}

export { InputWrapper };

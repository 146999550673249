import React, { PureComponent } from "react";
import { DragContext, DragEvent, onDragEnd, onDragStart, DragListenerParam, onAddClick, DragStart, ResponderProvided, DropResult } from "./DragContext";
import { Log } from "utils/Log";
import { ContentLibrarySideBar } from "pages/_shared/Draggables/ContentLibrarySideBar/ContentLibrarySideBar";
import { AssetType, SearchListType, SupportedClient, AgendaItemType, FlowchartItemType } from "api/graphql/types";
import { AnyContentType } from "api/ApiTypes";
import { uuid4 } from "@sentry/utils";
import { ContentElementUtils } from "./ContentElement/ContentElementUtils";
import { isNil } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
    children: React.ReactNode;
    client?: SupportedClient | null;
    createdById?: string;
    searchListTypes: SearchListType[];
    agendaItemTypes?: AgendaItemType[];
    assetTypes?: AssetType[];
    hideSidebar?: boolean;
    dragIds: string[];
    isUsableInSortingGame?: boolean;
    canHaveMultipleAssets?: boolean;
    flowchartItemTypes?: FlowchartItemType[];
}

export interface DraggableContentType {
    id: string;
    content: AnyContentType;
}

interface State {
    myLibrary: DraggableContentType[];
    clientLibrary: DraggableContentType[];
}

class DragContainer extends PureComponent<Props, State> {
    private listeners: {
        [DragEvent.dragStart]: onDragStart[];
        [DragEvent.dragEnd]: onDragEnd[];
        addClick: onAddClick[];
    } = {
        [DragEvent.dragStart]: [],
        [DragEvent.dragEnd]: [],
        addClick: [],
    };
    private currentDragged: DraggableContentType | undefined;

    public readonly state: State = {
        myLibrary: [],
        clientLibrary: [],
    };

    private addDragListener = (param: DragListenerParam): void => {
        switch (param.event) {
            case DragEvent.dragStart:
                this.listeners.dragStart.push(param.listener);
                break;
            case DragEvent.dragEnd:
                this.listeners.dragEnd.push(param.listener);
                break;
            case DragEvent.addClick:
                this.listeners.addClick.push(param.listener);
                break;
            default:
                Log.warning("DragEvent not handled", param);
        }
    };

    private removeFromArray<T>(array: T[], value: T): void {
        const index = array.findIndex((item: T) => item === value);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }

    private removeDragListener = (param: DragListenerParam): void => {
        switch (param.event) {
            case DragEvent.dragStart:
                this.removeFromArray(this.listeners.dragStart, param.listener);
                break;
            case DragEvent.dragEnd:
                this.removeFromArray(this.listeners.dragEnd, param.listener);
                break;
            case DragEvent.addClick:
                this.removeFromArray(this.listeners.addClick, param.listener);
                break;
            default:
                Log.warning("DragEvent not handled", param);
        }
    };

    public onDragStart = (initial: DragStart, provided: ResponderProvided) => {
        this.currentDragged = [...this.state.clientLibrary, ...this.state.myLibrary].find((value: DraggableContentType) => value.id === initial.draggableId);
        const type = this.currentDragged ? ContentElementUtils.getContentType(this.currentDragged.content) : null;
        this.listeners.dragStart.forEach((callback: onDragStart) => {
            callback(initial, provided, type);
        });
    };

    public onDragEnd = (result: DropResult, provided: ResponderProvided, item?: AnyContentType) => {
        this.listeners.dragEnd.forEach((callback: onDragEnd): void => {
            callback(result, provided, item || this.currentDragged?.content);
        });
        this.currentDragged = undefined;
    };

    private onAddClicked = (content: AnyContentType) => {
        const type = ContentElementUtils.getContentType(content);
        for (const callback of this.listeners.addClick) {
            if (callback(content, type)) {
                return;
            }
        }
    };

    private getDraggableById = (draggableId: string): DraggableContentType | undefined => {
        return this.state.clientLibrary.find(c => c.id === draggableId) || this.state.myLibrary.find(c => c.id === draggableId);
    };

    private onDataLoaded = (myLibrary?: AnyContentType[], clientLibrary?: AnyContentType[]): void => {
        const toDraggableContentType = (content: AnyContentType): DraggableContentType => ({ id: uuid4(), content });

        this.setState({
            myLibrary: isNil(myLibrary) ? this.state.myLibrary : myLibrary.map(toDraggableContentType),
            clientLibrary: isNil(clientLibrary) ? this.state.clientLibrary : clientLibrary.map(toDraggableContentType),
        });
    };

    public render(): React.ReactElement<any> {
        return (
            <DndProvider backend={HTML5Backend}>
                <DragContext.Provider
                    value={{
                        addDragListener: this.addDragListener,
                        removeDragListener: this.removeDragListener,
                        onDragStart: this.onDragStart,
                        onDragEnd: this.onDragEnd,
                        getDraggableById: this.getDraggableById,
                    }}
                >
                    {this.props.children}

                    {!this.props.hideSidebar && (
                        <ContentLibrarySideBar
                            isUploadEnabled={true}
                            client={this.props.client}
                            createdById={this.props.createdById}
                            searchListTypes={this.props.searchListTypes}
                            agendaItemTypes={this.props.agendaItemTypes}
                            assetTypes={this.props.assetTypes}
                            myLibrary={this.state.myLibrary}
                            clientLibrary={this.state.clientLibrary}
                            onDataLoaded={this.onDataLoaded}
                            onAddClicked={this.onAddClicked}
                            dragIds={this.props.dragIds}
                            isUsableInSortingGame={this.props.isUsableInSortingGame}
                            canHaveMultipleAssets={this.props.canHaveMultipleAssets}
                            flowchartItemTypes={this.props.flowchartItemTypes}
                        />
                    )}
                </DragContext.Provider>
            </DndProvider>
        );
    }
}

export { DragContainer };

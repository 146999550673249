import React, { Component } from "react";
import { Page } from "components/Page";
import { Intl } from "i18n/Intl";
import { AdminTag, ListControl, SortOrder, TagListInput, TagListOptionsInput, TagListSortField } from "api/graphql/types";
import { Api } from "api/Api";
import { RouteComponentProps } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Button } from "components/Button/Button";
import { Path } from "utils/Path";
import { NotificationType } from "components/NotificationBar/Notification";
import { Column, Table } from "components/Table/Table";
import { ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { ObjectUtils } from "utils/ObjectUtils";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";

interface TagTableSearchListOptions {
    sortField?: TagListSortField | null;
    control: ListControl;
}

interface State {
    isLoading: boolean;
    tags: AdminTag[];
    count: number;
    options: TagTableSearchListOptions;
}

enum TagItemTableColumn {
    title = "title",
    category = "category",
    createdBy = "createdBy",
    updatedAt = "updatedAt",
    createdAt = "createdAt",
    actions = "actions",
}

type Props = RouteComponentProps & DispatchProp;

class TagListPageComponent extends Component<Props, State> {
    private pageRef: Page | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            tags: [],
            count: 0,
            options: TagListPageComponent.getInitialOptions(props),
        };
    }

    private readonly refreshTags = async (listOptions?: TagListOptionsInput) => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const options: TagListInput = {
                        listOptions,
                    };
                    const tags = await Api.getAdminTags(options);
                    this.setState({ tags: tags!.result, count: tags!.count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false, tags: [] });
                }
            },
        );
    };

    private readonly onDeleted = () => {
        this.refreshTags();
        if (this.pageRef) {
            this.pageRef.showNotification({ message: Intl.formatMessage({ id: "sharedComponent.deleteTagDialog.succeed" }), type: NotificationType.success });
        }
    };

    private readonly onCreateTagClick = () => {
        this.props.history.push(Path.createTag);
    };

    private readonly renderButtons = (): React.ReactElement => {
        return (
            <Button
                renderIcon={() => {
                    return (
                        <>
                            <span className="fa fa-plus left mr-10" />
                            {Intl.formatMessage({ id: "page.createTag.title" })}
                        </>
                    );
                }}
                onClick={this.onCreateTagClick}
                aria-hidden={true}
            />
        );
    };

    private static getInitialOptions(props: Props): TagTableSearchListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<TagListSortField>(TagListSortField, "page.tags.tagTable").parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField: sortField,
            control: {
                sortOrder: sortOrder,
                limit: Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public componentDidMount(): void {
        this.refreshTags(this.state.options);
    }

    private readonly onDeleteClick = (tagId: string, tagTitle: string) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteTag,
                tag: {
                    id: tagId,
                    title: tagTitle,
                },
                onDeleted: this.onDeleted,
            }),
        );
    };

    private readonly getColumns = (): Array<Column<AdminTag>> => {
        const columnNames: TagItemTableColumn[] = ObjectUtils.enumAsArray<TagItemTableColumn>(TagItemTableColumn);

        return columnNames.map(
            (columnName: TagItemTableColumn): Column<AdminTag> => ({
                id: columnName,
                name: columnName === TagItemTableColumn.actions ? "" : Intl.formatMessage({ id: `page.tags.tagTable.columns.${columnName}` }),
                accessor: columnName as keyof AdminTag,
                renderCell: (tag: AdminTag): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case TagItemTableColumn.category:
                            return <>{Intl.formatMessage({ id: `enum.tagCategory.${tag.category}` })}</>;
                        case TagItemTableColumn.createdBy:
                            return <>{tag.createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" })}</>;
                        case TagItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(tag.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case TagItemTableColumn.updatedAt:
                            return <>{DateUtils.format(new Date(tag.updatedAt), DateFormat.yyyymmddhhmm)}</>;
                        case TagItemTableColumn.actions:
                            return (
                                <>
                                    <div className="tag-actions">
                                        <button className="btn btn-link" onClick={() => this.onDeleteClick(tag.id, tag.title)}>
                                            <i className="fa fa-trash" />
                                        </button>
                                    </div>
                                </>
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: [TagItemTableColumn.createdBy, TagItemTableColumn.updatedAt].includes(columnName),
            }),
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control?.offset, this.state.options.control?.limit, this.state.count);
    }

    private convertSortFieldToColumnId = (columnId?: TagListSortField | null): keyof AdminTag | undefined => {
        switch (columnId) {
            case TagListSortField.createdAt:
                return TagItemTableColumn.createdAt;
            case TagListSortField.title:
                return TagItemTableColumn.title;
            case TagListSortField.category:
                return TagItemTableColumn.category;
            default:
                return undefined;
        }
    };

    private convertColumnIdToSortField = (columnId?: string): TagListSortField | undefined => {
        switch (columnId) {
            case TagItemTableColumn.createdAt:
                return TagListSortField.createdAt;
            case TagItemTableColumn.title:
                return TagListSortField.title;
            case TagItemTableColumn.category:
                return TagListSortField.category;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AdminTag>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            () => this.refreshTags(this.state.options),
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            () => this.refreshTags(this.state.options),
        );
    };

    public render(): React.ReactElement {
        return (
            <Page
                ref={(ref: Page | null): void => {
                    this.pageRef = ref;
                }}
                title={Intl.formatMessage({ id: "page.tags.title" })}
                renderButtons={this.renderButtons}
            >
                <div className="left-side">
                    <p className="m-3">{Intl.formatMessage({ id: "page.tags.description" })}</p>
                    <Table
                        keyExtractor={(item: AdminTag, column?: Column<AdminTag>): string => {
                            return `${item.id}_${column ? column.id : ""}`;
                        }}
                        rowClassName={(): string => {
                            return "tag-table-row";
                        }}
                        columns={this.getColumns()}
                        sortBy={{
                            columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                            order: this.state.options.control.sortOrder || undefined,
                        }}
                        data={this.state.tags}
                        count={this.state.count}
                        limit={this.state.options.control?.limit}
                        isSortable={true}
                        onSortOrderChange={this.onSortOrderChange}
                        isPaginationEnabled={true}
                        currentPage={this.getCurrentPage()}
                        onPageChange={this.onPageChange}
                        isLoading={this.state.isLoading}
                        renderEmpty={(): string => Intl.formatMessage({ id: "page.tags.tagTable.noData" })}
                    />
                </div>
            </Page>
        );
    }
}

export const TagListPage = connect()(TagListPageComponent);

import { ObjectUtils } from "./ObjectUtils";
import { DateUtils } from "./DateUtils";

export type Diff<T, K> = Omit<T, keyof K>;

export interface EnumType {
    [key: number]: string;
}

/**
 * ClientConfig colorsOfDays SelectOption type
 */
export enum ColorsOfDaysType {
    default = "default",
    custom = "custom",
}

export enum PageType {
    view = "view",
    edit = "edit",
    create = "create",
}

export class QueryParserTypeUtils {
    public static toString(value: any): string | undefined {
        if (typeof value === "string") {
            return value;
        }
        return undefined;
    }

    public static toNumber(value: any): number | undefined {
        const asString = QueryParserTypeUtils.toString(value);
        if (asString) {
            return Math.max(Number.parseInt(asString, 10), 0);
        }
        return undefined;
    }

    public static toEnum<T>(enumType: object, value: any): T | undefined {
        return ObjectUtils.isEnumContains<T>(enumType, value) ? (value as T) : undefined;
    }

    public static toBoolean(value: any): true | undefined {
        return typeof value !== "undefined" ? true : undefined;
    }

    public static toDate(value: any): Date | undefined {
        const asString = QueryParserTypeUtils.toString(value);
        if (asString) {
            const asDate = new Date(asString);
            if (DateUtils.isValid(asDate)) {
                return asDate;
            }
        }
        return undefined;
    }
}

import React, { Component } from "react";
import {
    AccountType,
    EverydaySituationDirectoryListOptionsInput,
    EverydaySituationDirectorySortField,
    ListControl,
    SortOrder,
    SupportedClient,
    AssetType,
    EverydaySituationDirectoryList,
} from "api/graphql/types";
import { ContentPageUtils, PageContent } from "../ContentPageUtils";
import { RouteComponentProps } from "react-router";
import { DispatchProp } from "react-redux";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Column, Table } from "components/Table/Table";
import { ObjectUtils } from "utils/ObjectUtils";
import { DateFormat, DateUtils } from "utils/DateUtils";
import { Image } from "components/Image";
import { Intl } from "i18n/Intl";
import { ListQueryParameter, ListUrlQueryParser } from "utils/ListUrlQueryParser";
import { ContentOptionMenu } from "../ContentOptionMenu/ContentOptionMenu";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { isEqual, isNil } from "lodash";
import { DisabledLock } from "components/DisabledLock";
import { OverlayIcon } from "components/OverlayIcon";
import { ListResult } from "api/ApiTypes";
import { ImageSrc } from "utils/ImageSrc";

interface EverydaySituationTableListOptions {
    sortField?: EverydaySituationDirectorySortField | null;
    control: ListControl;
}

interface State {
    everydaySituationDirectories: EverydaySituationDirectoryList[];
    count: number;
    options: EverydaySituationTableListOptions;
    isLoading: boolean;
}

enum EverydaySituationDirectoryItemTableColumn {
    title = "title",
    isDisabled = "isDisabled",
    createdAt = "createdAt",
    updatedAt = "updatedAt",
    actions = "actions",
}

interface ComponentProps {
    currentUrlParams: string;
    directory: string | undefined;
    client: SupportedClient;
    pageContent: PageContent;
    accountType: AccountType;
    search: string;
    getClientEverydaySituationDirectoryList: (options?: EverydaySituationDirectoryListOptionsInput) => Promise<ListResult<EverydaySituationDirectoryList>>;
}

type Props = ComponentProps & RouteComponentProps & DispatchProp;

class EverydaySituationTable extends Component<Props, State> {
    private static getInitialOptions(props: Props): EverydaySituationTableListOptions {
        const { sortField, sortOrder, limit, page } = new ListUrlQueryParser<EverydaySituationDirectorySortField>(
            EverydaySituationDirectorySortField,
            "sharedComponent.contentLibraryTable.assetTable",
        ).parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                search: props.search,
                sortOrder,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
        };
    }

    public constructor(props: Props) {
        super(props);
        this.state = {
            everydaySituationDirectories: [],
            count: 0,
            options: EverydaySituationTable.getInitialOptions(props),
            isLoading: true,
        };
    }

    public componentDidMount(): void {
        this.refreshEverydaySituationDirectories(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Readonly<Props>): void {
        const nextOptions: EverydaySituationTableListOptions = EverydaySituationTable.getInitialOptions(nextProps);
        if (!isEqual(this.state.options, nextOptions) || nextProps.pageContent !== this.props.pageContent) {
            this.setState({ options: nextOptions }, () => {
                this.updateQueryParams();
            });
        }
    }

    private readonly refreshEverydaySituationDirectories = (options?: EverydaySituationDirectoryListOptionsInput) => {
        this.setState(
            {
                isLoading: true,
            },
            async () => {
                try {
                    const list: ListResult<EverydaySituationDirectoryList> = await this.props.getClientEverydaySituationDirectoryList(options);
                    this.setState({
                        everydaySituationDirectories: list.result,
                        count: list.count,
                        isLoading: false,
                    });
                } catch (errors) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(errors) });
                    this.setState({
                        isLoading: false,
                    });
                }
            },
        );
    };

    private readonly getColumns = (): Array<Column<EverydaySituationDirectoryList>> => {
        const columnNames: EverydaySituationDirectoryItemTableColumn[] = ObjectUtils.enumAsArray<EverydaySituationDirectoryItemTableColumn>(EverydaySituationDirectoryItemTableColumn);

        return columnNames.map(
            (columnName: EverydaySituationDirectoryItemTableColumn): Column<EverydaySituationDirectoryList> => ({
                id: columnName,
                name: Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.everydaySituationTable.columns.${columnName}` }),
                accessor: columnName as keyof EverydaySituationDirectoryList,
                renderCell: (situation: EverydaySituationDirectoryList): React.ReactElement<any> | null => {
                    switch (columnName) {
                        case EverydaySituationDirectoryItemTableColumn.createdAt:
                            return <>{DateUtils.format(new Date(situation.createdAt), DateFormat.yyyymmddhhmm)}</>;
                        case EverydaySituationDirectoryItemTableColumn.updatedAt:
                            return (
                                <>
                                    {situation.updatedAt
                                        ? DateUtils.format(new Date(situation.updatedAt), DateFormat.yyyymmddhhmm)
                                        : DateUtils.format(new Date(situation.createdAt), DateFormat.yyyymmddhhmm)}
                                </>
                            );
                        case EverydaySituationDirectoryItemTableColumn.actions:
                            return (
                                <ContentOptionMenu
                                    pageContent={this.props.pageContent}
                                    content={situation}
                                    clientExtId={this.props.client.extId}
                                    onToggled={() => this.onToggled(situation)}
                                    onRelAssetDisable={(): void => {
                                        this.props.dispatch(
                                            DialogsActions.show({
                                                type: DialogType.disableRelatingAssets,
                                                content: situation,
                                                clientId: this.props.client?.id,
                                                onDisabled: (): void => this.refreshEverydaySituationDirectories(this.state.options),
                                            }),
                                        );
                                    }}
                                    onDeleteClick={() => this.onDeleteClick(situation)}
                                />
                            );
                        case EverydaySituationDirectoryItemTableColumn.title:
                            return (
                                <div className="table-image-name">
                                    <div
                                        className="table-image-container"
                                        onClick={
                                            situation.image
                                                ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
                                                      event.stopPropagation();
                                                      this.props.dispatch(
                                                          DialogsActions.show({
                                                              type: DialogType.showAsset,
                                                              assetUrl: situation.image?.url,
                                                              assetType: situation.image?.assetType || AssetType.image,
                                                              originalFileName: situation.image?.originalFileName,
                                                              thumbnailUrl: situation.image?.thumbnailUrl,
                                                              dialogTitle: situation.image?.title || Intl.formatMessage({ id: `enum.assetType.${situation.image?.assetType}` }),
                                                          }),
                                                      );
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Image src={situation.image?.thumbnailUrl || situation.image?.url} fallback={ImageSrc.asset} />
                                        {situation.image && (
                                            <>
                                                <div className="content-overlay" />
                                                <div className="content-overlay-icon">
                                                    <OverlayIcon assetType={AssetType.image} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {situation.title}
                                </div>
                            );
                        case EverydaySituationDirectoryItemTableColumn.isDisabled:
                            return (
                                <DisabledLock
                                    isVisible={!isNil(situation.disabledAt)}
                                    tooltipText={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.everydaySituationTable.disabledTooltip" })}
                                />
                            );
                        default:
                            return null;
                    }
                },
                isNonSortable: false,
            }),
        );
    };

    private updateQueryParams = (): void => {
        const { control, sortField } = this.state.options;
        const options: ListQueryParameter<EverydaySituationDirectorySortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
        };

        const params = new ListUrlQueryParser<EverydaySituationDirectorySortField>(EverydaySituationDirectorySortField, "sharedComponent.contentLibraryTable.everydaySituationTable").getUrlQuery(
            options,
        );
        this.props.history.push({ search: `?${this.props.currentUrlParams}&${params}` });
        this.refreshEverydaySituationDirectories(this.state.options);
    };

    private convertColumnIdToSortField = (columnId?: string): EverydaySituationDirectorySortField | undefined => {
        switch (columnId) {
            case EverydaySituationDirectoryItemTableColumn.title:
                return EverydaySituationDirectorySortField.title;
            case EverydaySituationDirectoryItemTableColumn.createdAt:
                return EverydaySituationDirectorySortField.createdAt;
            case EverydaySituationDirectoryItemTableColumn.updatedAt:
                return EverydaySituationDirectorySortField.updatedAt;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: EverydaySituationDirectorySortField | null): keyof EverydaySituationDirectoryList | undefined => {
        switch (columnId) {
            case EverydaySituationDirectorySortField.title:
                return EverydaySituationDirectoryItemTableColumn.title;
            case EverydaySituationDirectorySortField.createdAt:
                return EverydaySituationDirectoryItemTableColumn.createdAt;
            case EverydaySituationDirectorySortField.updatedAt:
                return EverydaySituationDirectoryItemTableColumn.updatedAt;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<EverydaySituationDirectoryList>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column ? column.id : undefined),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private readonly onToggled = (content: EverydaySituationDirectoryList) => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.contentToggler,
                content: content,
                onToggled: () => {
                    this.refreshEverydaySituationDirectories(this.state.options);
                },
            }),
        );
    };

    private readonly onDeleteClick = (directory: EverydaySituationDirectoryList): void => {
        this.props.dispatch(
            DialogsActions.show({
                type: DialogType.deleteEverydaySituationDirectory,
                everydaySituationDirectory: { id: directory.id, title: directory.title },
                onDeleted: this.refreshEverydaySituationDirectories,
            }),
        );
    };

    public render(): React.ReactElement<any> {
        return (
            <Table
                keyExtractor={(item: EverydaySituationDirectoryList, column?: Column<EverydaySituationDirectoryList>): string => {
                    return `${item.id}_${column ? column.id : ""}`;
                }}
                columns={this.getColumns()}
                sortBy={{
                    columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                    order: this.state.options.control.sortOrder || undefined,
                }}
                data={this.state.everydaySituationDirectories}
                count={this.state.count}
                limit={this.state.options.control.limit}
                isSortable={true}
                onSortOrderChange={this.onSortOrderChange}
                onPageChange={this.onPageChange}
                isPaginationEnabled={true}
                currentPage={this.getCurrentPage()}
                isLoading={this.state.isLoading}
                onRowClick={(item: EverydaySituationDirectoryList): void => {
                    const path: string | undefined = ContentPageUtils.getViewPath(item, this.props.pageContent, this.props.client.extId);
                    if (path) {
                        this.props.history.push(path);
                    }
                }}
                renderEmpty={(): string => Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.everydaySituationTable.noData" })}
            />
        );
    }
}

export { EverydaySituationTable };

import React, { Component } from "react";
import { Button } from "components/Button/Button";
import { ClientShareRequest, Account } from "api/graphql/types";
import { Avatar } from "components/Avatar";
import { Alert } from "components/Alert/Alert";
import { Api } from "api/Api";
import { connect, DispatchProp } from "react-redux";
import { AccountActions } from "actions/AccountActions";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Intl } from "i18n/Intl";

interface ComponentProps {
    clientShareRequest: ClientShareRequest;
}

type Props = ComponentProps & DispatchProp;

interface State {
    isOpen: boolean;
}

class ShareRequestMenuItemComponent extends Component<Props, State> {
    public readonly state: State = {
        isOpen: true,
    };

    private onButtonClick = async (isAccept: boolean): Promise<void> => {
        try {
            if (isAccept) {
                await Api.acceptClientShareRequest(this.props.clientShareRequest.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.shareRequest.accepted" }) });
            } else {
                await Api.rejectClientShareRequest(this.props.clientShareRequest.id);
                Alert.success({ title: Intl.formatMessage({ id: "component.navigation.shareRequest.rejected" }) });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            return;
        }

        try {
            const account: Account = await Api.me();
            this.props.dispatch(AccountActions.updateAccount(account));
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private onAcceptClick = (): void => {
        this.onButtonClick(true);
    };

    private onRejectClick = (): void => {
        this.onButtonClick(false);
    };

    private onOpenChange = (): void => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    public render(): React.ReactElement<any> {
        const { client, createdBy } = this.props.clientShareRequest;
        return (
            <li className="dropdown">
                <div className={`toggler${this.state.isOpen ? " toggled" : ""}`} onClick={this.onOpenChange}>
                    <Avatar url={client.avatar ? client.avatar.url : null} name={client.name} />
                    <span className="nav-label">{client.name}</span>
                </div>
                <ul className={`nav nav-second-level collapse ${this.state.isOpen ? "in" : ""}`}>
                    <li>
                        <span className="new-member">
                            <span className="member-text">
                                {Intl.formatMessage(
                                    { id: "component.navigation.shareRequest.message" },
                                    { createdBy: createdBy?.name || Intl.formatMessage({ id: "common.deletedUser" }), client: client.name },
                                )}
                            </span>
                            <Button label={Intl.formatMessage({ id: "component.navigation.shareRequest.accept" })} onClick={this.onAcceptClick} />
                            <Button label={Intl.formatMessage({ id: "component.navigation.shareRequest.reject" })} hollow onClick={this.onRejectClick} />
                        </span>
                    </li>
                </ul>
            </li>
        );
    }
}

export const ShareRequestMenuItem = connect()(ShareRequestMenuItemComponent);

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AssetContent, AssetType, Client, ClientGamesConfig, Game, MemoryGameConfig, MemoryGameConfigInput } from "api/graphql/types";
import { Section } from "components/Section";
import { Intl } from "i18n/Intl";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator, ValidatorConstants } from "utils/Validator";
import isNil from "lodash/isNil";
import { Loading, LoadingType } from "components/Loading/Loading";
import { CardNotification } from "components/CardNotification/CardNotification";
import { SelectOption } from "components/Inputs/Select/SelectOption";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Select } from "components/Inputs/Select/Select";
import { AssetInput } from "../_shared/Draggables/Input/AssetInput";
import { Button } from "components/Button/Button";
import { BottomBar } from "components/BottomBar";
import { isEqual } from "lodash";
import { Prompt } from "components/Prompt";
import { TestId } from "utils/TestId";
import { NotificationProp, withNotification } from "components/NotificationBar/NotificationContext";
import { NotificationType } from "components/NotificationBar/Notification";

interface RouteParams {
    clientExtId: string;
}

interface ComponentProps {
    gameConfig: ClientGamesConfig;
    client: Client;
    onDragIdChange: (dragIds: string[]) => void;
}

type Props = ComponentProps & NotificationProp & RouteComponentProps<RouteParams>;

interface State {
    memoryGameConfig: MemoryGameConfig | null;
    assets: AssetContent[];
    memoryGameInput: MemoryGameConfigInput;
    errors: {
        assets: string | null;
    };
    isValidationEnabled: boolean;
    isLoading: boolean;
}

class MemoryGameTabComponent extends Component<Props, State> {
    private static MAX_NUMBER_OF_PAIRS: number = 10;
    public constructor(props: Props) {
        super(props);
        this.state = {
            memoryGameConfig: null,
            memoryGameInput: {
                imageIds: [],
                numberOfPairs: 1,
            },
            assets: [],
            errors: {
                assets: null,
            },
            isValidationEnabled: false,
            isLoading: true,
        };
    }

    componentDidMount(): void {
        this.fetchConfig();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.client.id !== prevProps.client.id) {
            this.fetchConfig();
        }
    }

    private readonly fetchConfig = async () => {
        try {
            const config = await Api.getClientMemoryGameConfig(this.props.client.id);
            if (config.getClientMemoryGameConfig) {
                this.setState(
                    {
                        memoryGameConfig: config.getClientMemoryGameConfig,
                        memoryGameInput: {
                            imageIds: config.getClientMemoryGameConfig.images.map(image => image.id),
                            numberOfPairs: config.getClientMemoryGameConfig.numberOfPairs || 0,
                        },
                        assets: [...config.getClientMemoryGameConfig.images],
                        isLoading: false,
                    },
                    () => {
                        this.props.onDragIdChange(this.state.memoryGameInput.imageIds);
                    },
                );
            } else {
                this.setState({ isLoading: false });
            }
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    };

    private readonly isEnabled = (): boolean => {
        return !isNil(this.props.gameConfig.enabledGames?.find(game => game.name === Game.memory));
    };

    private readonly getNumberOfPairsValue = (): SelectOption => {
        const value = `${this.state.memoryGameInput.numberOfPairs || 0}`;
        return {
            id: value,
            label: value !== "0" ? value : Intl.formatMessage({ id: "page.clientGames.form.numberOfPairs.placeholder" }),
            value,
        };
    };

    private readonly getNumberOfPairsOptions = (): SelectOption[] => {
        return Array.from(Array(MemoryGameTabComponent.MAX_NUMBER_OF_PAIRS), (_, i) => i + 1).map(value => {
            return {
                id: `${value}`,
                label: `${value}`,
                value: `${value}`,
            };
        });
    };

    private readonly onNumberOfPairsChange = (item: SelectOption) => {
        const { memoryGameInput, assets } = this.state;
        memoryGameInput.numberOfPairs = Number(item.value);
        const assetError = IntlHelpers.getValidationError(Validator.validateMemoryGamesAssetCount(assets, memoryGameInput.numberOfPairs), { count: memoryGameInput.numberOfPairs });
        this.setState({
            memoryGameInput,
            errors: { ...this.state.errors, assets: assetError },
        });
    };

    private readonly onImageChange = (asset: AssetContent[], index: number) => {
        const { assets, memoryGameInput } = this.state;
        const image: AssetContent | null = asset.length > 0 ? asset[0] : null;
        if (image) {
            assets[index] = image;
            memoryGameInput.imageIds[index] = image.id;
        } else {
            delete assets[index];
            delete memoryGameInput.imageIds[index];
        }
        const assetError = IntlHelpers.getValidationError(
            Validator.validateMemoryGamesAssetCount(
                assets.filter(asset => asset !== null),
                memoryGameInput.numberOfPairs,
            ),
            { count: memoryGameInput.numberOfPairs },
        );
        this.setState(
            {
                assets: assets.filter(asset => asset !== null),
                memoryGameInput: { ...memoryGameInput, imageIds: memoryGameInput.imageIds.filter(id => id !== null) },
                errors: { ...this.state.errors, assets: assetError },
            },
            () => {
                this.props.onDragIdChange(this.state.memoryGameInput.imageIds);
            },
        );
    };

    private isChanged = (): boolean => {
        if (this.state.memoryGameInput && this.state.memoryGameConfig) {
            return !(
                isEqual(this.state.memoryGameInput.numberOfPairs, this.state.memoryGameConfig.numberOfPairs) &&
                isEqual(
                    this.state.memoryGameInput.imageIds,
                    this.state.memoryGameConfig.images.map(image => image.id),
                )
            );
        }
        return false;
    };

    private readonly getAssetNumber = (): number => {
        const { numberOfPairs, imageIds } = this.state.memoryGameInput;
        if (imageIds.length < ValidatorConstants.MEMORY_GAME_MAX_ASSET_COUNT && imageIds.length >= numberOfPairs && numberOfPairs !== 0) {
            return imageIds.length + 1;
        }

        if (imageIds.length < numberOfPairs) {
            return numberOfPairs;
        }

        return imageIds.length;
    };

    private readonly onCancelClick = () => {
        const { memoryGameConfig } = this.state;
        if (memoryGameConfig) {
            this.setState(
                {
                    memoryGameInput: {
                        imageIds: memoryGameConfig.images.map(image => image.id),
                        numberOfPairs: memoryGameConfig.numberOfPairs || 0,
                    },
                    assets: [...memoryGameConfig.images],
                    isValidationEnabled: false,
                    errors: {
                        assets: null,
                    },
                },
                () => {
                    this.props.onDragIdChange(this.state.memoryGameInput.imageIds);
                },
            );
        } else {
            this.props.onDragIdChange([]);
        }
    };

    private readonly onSaveClick = async () => {
        const { client } = this.props;

        if (this.state.errors.assets) {
            this.setState({ isValidationEnabled: true });
            return;
        }
        try {
            this.setState(
                {
                    isLoading: true,
                },
                async () => {
                    const response = await Api.updateClientMemoryGameConfig(client!.id, this.state.memoryGameInput);
                    if (response.updateClientMemoryGameConfig) {
                        this.setState({
                            memoryGameConfig: response.updateClientMemoryGameConfig,
                            isValidationEnabled: false,
                            isLoading: false,
                        });
                        this.props.showNotification({ type: NotificationType.success, message: Intl.formatMessage({ id: "page.clientGames.memoryTab.succeed" }) });
                    }
                },
            );
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
            this.setState({ isLoading: false });
        }
    };

    public render(): React.ReactElement<any> {
        const { isLoading, assets, errors, isValidationEnabled } = this.state;
        if (isLoading) {
            return <Loading type={LoadingType.layer} />;
        }

        const clientShortName = this.props.client.name.slice(this.props.client.name.lastIndexOf(" ") + 1);
        return (
            <Section label={Intl.formatMessage({ id: "page.clientGames.memoryTab.title" }, { name: this.props.client.name })}>
                <p>{Intl.formatMessage({ id: "page.clientGames.memoryTab.description" }, { name: clientShortName })}</p>
                {this.isEnabled() || (
                    <CardNotification
                        title={Intl.formatMessage({ id: "page.clientGames.messages.gameNotEnabled.title" }, { name: this.props.client.name })}
                        description={Intl.formatMessage({ id: "page.clientGames.messages.gameNotEnabled.description" }, { name: this.props.client.name })}
                        type={"warning"}
                        className="mb-20"
                    />
                )}
                <InputWrapper inputLabel={Intl.formatMessage({ id: "page.clientGames.form.numberOfPairs.label" })} id={TestId.clientGames.memoryGameTab.numberOfPairs}>
                    <Select value={this.getNumberOfPairsValue()} onChange={this.onNumberOfPairsChange} options={this.getNumberOfPairsOptions()} />
                </InputWrapper>
                <InputWrapper errorMessage={isValidationEnabled ? errors.assets : null} childrenWrapperClassName="grid-x justify-content-start">
                    {[...Array(this.getAssetNumber()).keys()].map(key => (
                        <div className="mr-3 memory-game" key={key}>
                            <AssetInput
                                droppableId={`image-${key}`}
                                assetType={AssetType.image}
                                value={assets[key] ? [assets[key]] : []}
                                onChange={asset => this.onImageChange(asset, key)}
                                maxItemCount={1}
                                minItemCount={1}
                                disabled={false}
                                hasError={isValidationEnabled && !assets[key] && errors.assets !== null}
                            />
                        </div>
                    ))}
                </InputWrapper>
                <BottomBar isVisible={this.isChanged()}>
                    <div className="cell medium-6 text-right">
                        <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} id={TestId.clientGames.memoryGameTab.cancelButton} onClick={this.onCancelClick} />
                    </div>
                    <div className="cell medium-6 text-left">
                        <Button
                            label={Intl.formatMessage({ id: "common.save" })}
                            id={TestId.clientGames.memoryGameTab.saveButton}
                            onClick={this.onSaveClick}
                            disabled={isValidationEnabled && errors.assets !== null}
                        />
                    </div>
                </BottomBar>

                <Prompt when={this.isChanged()} />
            </Section>
        );
    }
}

export const MemoryGameTab = withNotification(withRouter(MemoryGameTabComponent));

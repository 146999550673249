import React, { Component } from "react";
import { Intl } from "i18n/Intl";
import { SortOrder, ListControl, AppEventLogListSortField, AppEventLog, AppEventLogName } from "api/graphql/types";
import { RouteComponentProps, withRouter } from "react-router";
import { Table, Column } from "components/Table/Table";
import isEqual from "lodash/isEqual";
import { DateUtils, DateFormat } from "utils/DateUtils";
import { ObjectUtils } from "utils/ObjectUtils";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Api } from "api/Api";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { DateInput, PopperPlacement } from "components/Inputs/Date/DateInput";
import { EventLogUrlQueryParser, EventLogQueryParameter } from "pages/_shared/EventLogUrlQueryParser";
import { Button } from "components/Button/Button";
import { Env } from "utils/Env";
import { Section } from "components/Section";
import { Select } from "components/Inputs/Select/Select";
import { cloneDeep, isNil } from "lodash";
import { Input } from "components/Inputs/Input/Input";
import { AppEventLogNameOption } from "models/AppEventLogNameOptions";
import { Formatter } from "utils/Formatter";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "reducers";
import { SettingsSelectors } from "selectors/SettingsSelectors";
import { TextSelect } from "components/Inputs/TextSelect/TextSelect";
import { startOfDay } from "date-fns";
import { TimeSelect } from "components/Inputs/TimeSelect/TimeSelect";

interface EventLogPageOptions {
    sortField?: AppEventLogListSortField | null;
    control: ListControl;
    dateTimeFrom: Date;
    dateTimeTo: Date;
    appEventLogName: AppEventLogName | null;
    authAccountExtId: string | null;
    clientExtId: string | null;
}

interface State {
    appEventLog: AppEventLog[];
    count: number;
    isLoading: boolean;
    options: EventLogPageOptions;
    appEventLogNameSearch: string;

    fromTime: string;
    toTime: string;
}

enum SystemEventLogTableColumn {
    account = "account",
    client = "client",
    event = "event",
    title = "title",
    timestamp = "timestamp",
}

interface ReduxProps {
    appEventLogNameOptions: AppEventLogNameOption[];
}

type Props = RouteComponentProps & ReduxProps;

class CmsEventLogTabComponent extends Component<Props, State> {
    private static getInitialOptions(props: Props): EventLogPageOptions {
        const { sortField, sortOrder, search, limit, page, dateTimeFrom, dateTimeTo, appEventLogName, authAccountExtId, clientExtId } = new EventLogUrlQueryParser<AppEventLogListSortField>(
            AppEventLogListSortField,
            "page.systemEventLog.table",
        ).parse(props.location.search);
        const offsetFromPage: number = limit && page ? limit * (page - 1) : 0;

        return {
            sortField,
            control: {
                sortOrder,
                search,
                limit: limit || Table.DEFAULT_PAGE_SIZE,
                offset: offsetFromPage,
            },
            dateTimeFrom: dateTimeFrom || startOfDay(new Date()),
            dateTimeTo: dateTimeTo || new Date(),
            appEventLogName: appEventLogName || null,
            authAccountExtId: authAccountExtId || null,
            clientExtId: clientExtId || null,
        };
    }

    public constructor(props: Props) {
        super(props);

        const options = CmsEventLogTabComponent.getInitialOptions(this.props);
        this.state = {
            appEventLog: [],
            isLoading: true,
            count: 0,
            options,
            appEventLogNameSearch: Select.getSelectOption(this.props.appEventLogNameOptions, CmsEventLogTabComponent.getInitialOptions(this.props).appEventLogName).label,
            fromTime: DateUtils.toTimeFormat(options.dateTimeFrom),
            toTime: DateUtils.toTimeFormat(options.dateTimeTo),
        };
    }

    public componentDidMount(): void {
        this.refreshEventLog(this.state.options);
    }

    public componentWillReceiveProps(nextProps: Props): void {
        if (!isEqual(this.props.location.search, nextProps.location.search)) {
            this.setState({ options: CmsEventLogTabComponent.getInitialOptions(nextProps) }, () => {
                this.refreshEventLog(this.state.options);
            });
        }
    }

    private refreshEventLog = (options: EventLogPageOptions): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    const { result, count } = await Api.getAppEventLog(
                        {
                            dateTimeFrom: options.dateTimeFrom,
                            dateTimeTo: options.dateTimeTo,
                            appEventLogName: options.appEventLogName || undefined,
                            authAccountExtId: options.authAccountExtId || undefined,
                            clientExtId: options.clientExtId || undefined,
                        },
                        {
                            control: options.control,
                            sortField: options.sortField,
                        },
                    );
                    this.setState({ appEventLog: result, count, isLoading: false });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ appEventLog: [], count: 0, isLoading: false });
                }
            },
        );
    };

    private readonly columns: Array<Column<AppEventLog>> = ObjectUtils.enumAsArray<SystemEventLogTableColumn>(SystemEventLogTableColumn).map(
        (columnName: SystemEventLogTableColumn): Column<AppEventLog> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.systemEventLog.table.columns.${columnName}` }),
            accessor: columnName as keyof AppEventLog,
            renderCell: (appEventLog: AppEventLog): React.ReactElement<any> | null => {
                switch (columnName) {
                    case SystemEventLogTableColumn.account:
                        return <>{!isNil(appEventLog.request?.account?.extId) && Formatter.formatExtId(appEventLog.request!.account!.extId)}</>;
                    case SystemEventLogTableColumn.client:
                        return <>{!isNil(appEventLog.entity?.client?.extId) && Formatter.formatExtId(appEventLog.entity!.client!.extId)}</>;
                    case SystemEventLogTableColumn.event:
                        return <>{appEventLog.eventTitle}</>;
                    case SystemEventLogTableColumn.title:
                        return <>{appEventLog.entityTitle}</>;
                    case SystemEventLogTableColumn.timestamp:
                        return <>{DateUtils.format(new Date(appEventLog.timestamp), DateFormat.dateTime)}</>;
                    default:
                        return null;
                }
            },
            isNonSortable: !ObjectUtils.enumAsArray<AppEventLogListSortField>(AppEventLogListSortField)
                .map((field: AppEventLogListSortField) => {
                    return `${field}`;
                })
                .includes(columnName),
        }),
    );

    private updateQueryParams = (): void => {
        const { control, sortField, dateTimeFrom, dateTimeTo, authAccountExtId, clientExtId, appEventLogName } = this.state.options;
        const options: EventLogQueryParameter<AppEventLogListSortField> = {
            sortOrder: control.sortOrder,
            search: control.search,
            limit: control.limit,
            page: control.limit && control.offset ? control.offset / control.limit + 1 : null,
            sortField,
            dateTimeFrom: dateTimeFrom || new Date(),
            dateTimeTo: dateTimeTo || new Date(),
            authAccountExtId: authAccountExtId || null,
            clientExtId: clientExtId || null,
            appEventLogName: appEventLogName || null,
        };

        const params = new EventLogUrlQueryParser<AppEventLogListSortField>(AppEventLogListSortField, "page.systemEventLog.table").getUrlQuery(options);
        this.props.history.push({ search: `?${params}` });
        this.setState({ appEventLog: [] }, () => this.refreshEventLog(this.state.options));
    };

    private convertColumnIdToSortField = (columnId?: string): AppEventLogListSortField | undefined => {
        switch (columnId) {
            case SystemEventLogTableColumn.timestamp:
                return AppEventLogListSortField.timestamp;
            default:
                return undefined;
        }
    };

    private convertSortFieldToColumnId = (columnId?: AppEventLogListSortField | null): keyof AppEventLog | undefined => {
        switch (columnId) {
            case AppEventLogListSortField.timestamp:
                return SystemEventLogTableColumn.timestamp;
            default:
                return undefined;
        }
    };

    private onSortOrderChange = (column?: Column<AppEventLog>, order?: SortOrder): void => {
        this.setState(
            {
                options: {
                    sortField: this.convertColumnIdToSortField(column?.id),
                    control: {
                        ...this.state.options.control,
                        sortOrder: order,
                    },
                    dateTimeFrom: this.state.options.dateTimeFrom,
                    dateTimeTo: this.state.options.dateTimeTo,
                    appEventLogName: this.state.options.appEventLogName,
                    authAccountExtId: this.state.options.authAccountExtId,
                    clientExtId: this.state.options.clientExtId,
                },
            },
            this.updateQueryParams,
        );
    };

    private onPageChange = (pageNum: number): void => {
        const { options } = this.state;
        const limit: number = options.control.limit || 0;
        const newOffset: number = limit * (pageNum - 1);
        this.setState(
            {
                options: {
                    ...this.state.options,
                    control: {
                        ...this.state.options.control,
                        offset: newOffset,
                    },
                },
            },
            this.updateQueryParams,
        );
    };

    private getCurrentPage(): number {
        return Table.getCurrentPage(this.state.options.control.offset, this.state.options.control.limit, this.state.count);
    }

    private getDownloadUrl(): string {
        const options = this.state.options;
        const dateTimeFrom: Date | null = options.dateTimeFrom || startOfDay(new Date());
        const dateTimeTo: Date | null = options.dateTimeTo || DateUtils.lastQuarterMinutes(new Date());
        const appEventLogName: AppEventLogName | null = options.appEventLogName || null;
        const clientExtId: string | null = options.clientExtId || null;
        const authAccountExtId: string | null = options.authAccountExtId || null;
        const paramArray: string[] = [];

        if (dateTimeFrom) {
            paramArray.push(`dateFrom=${dateTimeFrom.toISOString()}`);
        }
        if (dateTimeTo) {
            paramArray.push(`dateTo=${dateTimeTo.toISOString()}`);
        }
        if (appEventLogName) {
            paramArray.push(`type=${appEventLogName}`);
        }
        if (clientExtId) {
            paramArray.push(`clientExtId=${clientExtId}`);
        }
        if (authAccountExtId) {
            paramArray.push(`authAccountExtId=${authAccountExtId}`);
        }

        return `${Env.exportApiUrl}/appEventLog/xlsx?${paramArray.join("&")}`;
    }

    private filterTypes = (events: AppEventLogNameOption[]): AppEventLogNameOption[] => {
        const search: string = this.state.appEventLogNameSearch;
        if (this.state.appEventLogNameSearch.length >= 3) {
            return events.filter((event: AppEventLogNameOption) => event.label.toLowerCase().includes(search.toLowerCase()));
        }
        return events;
    };

    private renderInputs = (): React.ReactElement => {
        return (
            <div className="inputs container">
                <div className="row">
                    <div className="col-lg-6">
                        <InputWrapper>
                            <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.type.label" })}</div>
                            <TextSelect
                                value={Select.getSelectOption(this.props.appEventLogNameOptions, this.state.options.appEventLogName)}
                                options={this.filterTypes(this.props.appEventLogNameOptions)}
                                onChange={(option: AppEventLogNameOption): void => {
                                    this.setState({
                                        options: {
                                            ...this.state.options,
                                            appEventLogName: option.value,
                                            control: {
                                                offset: 0,
                                                limit: Table.DEFAULT_PAGE_SIZE,
                                            },
                                        },
                                        appEventLogNameSearch: option.label,
                                    });
                                }}
                                onTextInputChange={(appEventLogNameSearch: string) => this.setState({ appEventLogNameSearch })}
                                textInputValue={this.state.appEventLogNameSearch}
                            />
                        </InputWrapper>
                        <div className="system-event-log-page-inline-inputs">
                            <div>
                                <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.dateFrom.label" })}</div>
                                <InputWrapper>
                                    <DateInput
                                        placeholder={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.dateFrom.placeholder" })}
                                        value={this.state.options.dateTimeFrom}
                                        onChange={(dateTimeFrom: Date | null) => {
                                            if (dateTimeFrom) {
                                                this.setState({ options: { ...this.state.options, dateTimeFrom, control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE } } });
                                            }
                                        }}
                                        max={new Date()}
                                        popperPlacement={PopperPlacement.bottom}
                                    />
                                </InputWrapper>
                            </div>

                            <div>
                                <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.hourFrom.label" })}</div>
                                <InputWrapper>
                                    <TimeSelect
                                        value={this.state.fromTime}
                                        onChange={(time: string) => {
                                            const [hours, minutes] = time.split(":");
                                            const newDateTimeFrom: Date = cloneDeep(this.state.options.dateTimeFrom);
                                            newDateTimeFrom.setHours(Number.parseInt(hours, 10) || 0);
                                            newDateTimeFrom.setMinutes(Number.parseInt(minutes, 10) || 0);
                                            this.setState({
                                                fromTime: time,
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom: newDateTimeFrom,
                                                    dateTimeTo: this.state.options.dateTimeTo < newDateTimeFrom ? newDateTimeFrom : this.state.options.dateTimeTo,
                                                },
                                            });
                                        }}
                                    />
                                </InputWrapper>
                            </div>
                            <div className="system-event-log-page-dummy-text">
                                <p>{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.from" })}</p>
                            </div>
                        </div>
                        <div className="system-event-log-page-inline-inputs">
                            <div>
                                <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.dateTo.label" })}</div>
                                <InputWrapper>
                                    <DateInput
                                        placeholder={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.dateTo.placeholder" })}
                                        value={this.state.options.dateTimeTo}
                                        onChange={(dateTimeTo: Date | null) => {
                                            if (dateTimeTo) {
                                                this.setState({ options: { ...this.state.options, dateTimeTo, control: { offset: 0, limit: Table.DEFAULT_PAGE_SIZE } } });
                                            }
                                        }}
                                        max={new Date()}
                                        popperPlacement={PopperPlacement.bottom}
                                    />
                                </InputWrapper>
                            </div>
                            <div>
                                <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.hourTo.label" })}</div>
                                <InputWrapper>
                                    <TimeSelect
                                        value={this.state.toTime}
                                        onChange={(time: string) => {
                                            const newDateTimeTo: Date = cloneDeep(this.state.options.dateTimeTo);
                                            const [hours, minutes] = time.split(":");
                                            newDateTimeTo.setHours(Number.parseInt(hours, 10) || 0);
                                            newDateTimeTo.setMinutes(Number.parseInt(minutes, 10) || 0);
                                            this.setState({
                                                options: {
                                                    ...this.state.options,
                                                    dateTimeFrom: this.state.options.dateTimeFrom > newDateTimeTo ? newDateTimeTo : this.state.options.dateTimeFrom,
                                                    dateTimeTo: newDateTimeTo,
                                                },
                                            });
                                        }}
                                    />
                                </InputWrapper>
                            </div>
                            <div className="system-event-log-page-dummy-text">
                                <p>{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.to" })}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div>
                            <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.authAccountExtId.label" })}</div>
                            <InputWrapper>
                                <Input
                                    type="text"
                                    placeholder={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.authAccountExtId.placeholder" })}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ options: { ...this.state.options, authAccountExtId: event.currentTarget.value } });
                                    }}
                                    value={this.state.options.authAccountExtId || ""}
                                />
                            </InputWrapper>
                        </div>
                        <div>
                            <div className="small-text system-event-log-page-input-label">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.clientExtId.label" })}</div>
                            <InputWrapper>
                                <Input
                                    type="text"
                                    placeholder={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.clientExtId.placeholder" })}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ options: { ...this.state.options, clientExtId: event.currentTarget.value } });
                                    }}
                                    value={this.state.options.clientExtId || ""}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="system-event-log-page-inner-buttons">
                    <Button
                        label={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.buttonLabels.deleteFilters" })}
                        hollow
                        onClick={() => {
                            this.props.history.push({ search: undefined });
                            this.setState({ options: CmsEventLogTabComponent.getInitialOptions(this.props) });
                        }}
                    />
                    <Button
                        label={Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.buttonLabels.applyFilters" })}
                        onClick={() => this.setState({ options: { ...this.state.options, control: { ...this.state.options.control, limit: 10, offset: 0 } } }, this.updateQueryParams)}
                    />
                </div>
            </div>
        );
    };

    public render(): React.ReactElement | null {
        return (
            <div className="left-side">
                <Section
                    label={
                        <>
                            <i className="fa fa-filter" />
                            <span className="system-event-log-page-section-title">{Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.sectionTitle" })}</span>
                        </>
                    }
                >
                    {this.renderInputs()}
                    <div className="system-event-log-page-outer-buttons">
                        <Button
                            label={Intl.formatMessage({ id: "common.download" })}
                            onClick={() => {
                                window.location.href = this.getDownloadUrl();
                            }}
                            disabled={this.state.count === 0}
                        />
                    </div>
                    <div className="table-responsive table--display-table">
                        <Table
                            keyExtractor={(item: AppEventLog, column?: Column<AppEventLog>): string => {
                                return `${item.timestamp}_${column ? column.id : ""}`;
                            }}
                            columns={this.columns}
                            sortBy={{
                                columnId: this.convertSortFieldToColumnId(this.state.options.sortField),
                                order: this.state.options.control.sortOrder || undefined,
                            }}
                            data={this.state.appEventLog}
                            count={this.state.count}
                            limit={this.state.options.control.limit}
                            isSortable={true}
                            onSortOrderChange={this.onSortOrderChange}
                            onPageChange={this.onPageChange}
                            isPaginationEnabled={true}
                            currentPage={this.getCurrentPage()}
                            isLoading={this.state.isLoading}
                            renderEmpty={(): string => Intl.formatMessage({ id: "page.systemEventLog.cmsEventLogTab.noData" })}
                        />
                    </div>
                </Section>
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, RouteComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { appEventLogNameOptions: SettingsSelectors.getAppEventLogNameOptions(state) };
};

export const CmsEventLogTab = withRouter(connect(mapStateToProps)(CmsEventLogTabComponent));

export default CmsEventLogTab;

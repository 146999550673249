import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { Intl } from "i18n/Intl";
import { Button } from "components/Button/Button";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Select } from "components/Inputs/Select/Select";
import { ShareTypeOptions, ShareTypeOption, ShareType } from "./components/ShareTypeOptions";
import { AssetContent, AccountType } from "api/graphql/types";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { IntlHelpers } from "i18n/IntlHelpers";
import { AnyContentListType } from "api/ApiTypes";
import { DialogVisibilityProps } from "./DialogsContainer";
import { Input } from "components/Inputs/Input/Input";
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import { TextArea } from "components/Inputs/TextArea/TextArea";
import { debounce } from "lodash";
import { Validator } from "utils/Validator";
import { Log } from "utils/Log";
import { ApplicationState } from "reducers";
import { MapStateToProps, DispatchProp, connect } from "react-redux";
import { SuccessIcon } from "components/Icons/SuccessIcon";
import { ApiError, ApiErrorCode } from "api/ApiError";

export interface ShareContentDialogProps {
    content: AnyContentListType | AssetContent | null;
    onSucceed: () => void;
}

interface ReduxProps {
    accountType: AccountType;
}

type ComponentProps = ShareContentDialogProps & DialogVisibilityProps;

type Props = ReduxProps & ComponentProps & DispatchProp;

type EmailAndId = {
    email: string;
    id: string;
};

interface State {
    isConfirmed: boolean;
    shareType: ShareTypeOption;
    isLoading: boolean;
    emails: EmailAndId[];
    comment: string;
    currentEmail: string;
    privacyAccepted: boolean;
    errors: {
        currentEmail: string | null;
    };
    isSucceed: boolean;
}

class ShareContentDialogComponent extends Component<Props, State> {
    public static getInitialState(_props: Props): State {
        return {
            isConfirmed: false,
            shareType: ShareTypeOptions.get()[0],
            isLoading: false,
            emails: [],
            comment: "",
            currentEmail: "",
            privacyAccepted: false,
            errors: {
                currentEmail: null,
            },
            isSucceed: false,
        };
    }

    public readonly state: State = ShareContentDialogComponent.getInitialState(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        if (this.props.content !== nextProps.content) {
            this.setState(ShareContentDialogComponent.getInitialState(nextProps));
        }
    }

    private shareContent = (): void => {
        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    if (this.props.accountType === AccountType.supporter) {
                        if (this.state.shareType.value === ShareType.publish) {
                            await Api.shareLibraryContentToPublic(this.props.content!.id, this.state.comment);
                        } else if (this.state.shareType.value === ShareType.custom) {
                            await Api.shareLibraryContent(
                                this.props.content!.id,
                                this.state.emails.map(i => i.id),
                            );
                        } else {
                            Log.warning("Unhandled shareType: ", this.state.shareType.value);
                            throw new ApiError(ApiErrorCode.UNKNOWN);
                        }
                    } else {
                        await Api.copyContentToPublicLibrary(this.props.content!);
                        Alert.success({ title: Intl.formatMessage({ id: "sharedComponent.shareContentDialog.succeed" }) });
                        this.props.onSucceed();
                        this.props.onHide();
                        return;
                    }
                    this.setState({ isLoading: false, isSucceed: true }, this.props.onSucceed);
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private validateEmail = debounce(async () => {
        if (this.state.currentEmail !== "") {
            const currentEmail = IntlHelpers.getValidationError(
                Validator.validateShareRequestEmail(
                    this.state.currentEmail,
                    this.state.emails.map(i => i.email),
                ),
            );
            if (currentEmail) {
                this.setState({ errors: { currentEmail } });
                return;
            }

            try {
                await Api.getSupporterIdByEmail(this.state.currentEmail);
            } catch (error) {
                if (error instanceof ApiError && error.code === ApiErrorCode.ACCOUNT_NOT_FOUND) {
                    this.setState({ errors: { currentEmail: Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.error.accountNotFound" }) } });
                } else {
                    this.setState({ errors: { currentEmail: IntlHelpers.getMessageFromError(error) } });
                }
            }
        }
    }, 300);

    private onAddClick = async () => {
        try {
            const id = await Api.getSupporterIdByEmail(this.state.currentEmail);
            this.setState({
                currentEmail: "",
                emails: [{ email: this.state.currentEmail, id }, ...this.state.emails],
                errors: { currentEmail: null },
            });
        } catch (error) {
            if (error instanceof ApiError && error.code === ApiErrorCode.ACCOUNT_NOT_FOUND) {
                this.setState({ errors: { currentEmail: Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.error.accountNotFound" }) } });
            } else {
                this.setState({ errors: { currentEmail: IntlHelpers.getMessageFromError(error) } });
            }
        }
    };

    private isShareButtonDisabled() {
        if (this.state.shareType.value === ShareType.publish) {
            if (this.props.accountType !== AccountType.supporter) {
                return false;
            } else {
                return !this.state.privacyAccepted;
            }
        }

        return !!this.state.errors.currentEmail || !!this.state.currentEmail || this.state.emails.length === 0;
    }

    public render(): React.ReactElement<any> {
        if (!this.state.isConfirmed) {
            return (
                <Dialog
                    visible={this.props.isVisible && !!this.props.content}
                    title={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.title" })}
                    onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
                >
                    <i className="fas fa-exclamation-triangle verify-delete-everyday-situation-directory-dialog-icon" />
                    <p>{Intl.formatMessage({ id: "sharedComponent.shareContentDialog.confirmDescription" })}</p>
                    <hr />
                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button label={Intl.formatMessage({ id: "common.ok" })} onClick={() => this.setState({ isConfirmed: true })} />
                        </div>
                    </div>
                </Dialog>
            );
        }
        if (this.state.isSucceed) {
            return (
                <Dialog
                    visible={this.props.isVisible && !!this.props.content}
                    title={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.title" })}
                    onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}
                >
                    <div className="icon-wrapper" aria-hidden={true} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 50, marginTop: 30 }}>
                        <SuccessIcon width={130} height={130} />
                    </div>
                    <p className="text-center">{Intl.formatMessage({ id: "sharedComponent.shareContentDialog.succeed" })}</p>
                </Dialog>
            );
        }

        return (
            <Dialog visible={this.props.isVisible} title={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.title" })} onCloseClick={!this.state.isLoading ? this.props.onHide : undefined}>
                <form>
                    <p>
                        {Intl.formatMessage({ id: `sharedComponent.shareContentDialog.form.${this.state.shareType.value}.description` })}
                        {this.props.accountType === AccountType.supporter &&
                            this.state.shareType.value === ShareType.publish &&
                            Intl.formatMessage({ id: `sharedComponent.shareContentDialog.form.${this.state.shareType.value}.supporterDescription` })}
                    </p>

                    <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.shareType.label" })}>
                        <Select
                            options={ShareTypeOptions.get()}
                            onChange={(shareType: ShareTypeOption): void => {
                                if (shareType.value !== this.state.shareType.value) {
                                    this.setState({ shareType, emails: [], currentEmail: "", privacyAccepted: false, comment: "" });
                                }
                            }}
                            value={this.state.shareType}
                        />
                    </InputWrapper>

                    {this.state.shareType.value === ShareType.custom && (
                        <>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.custom.email.label" })} errorMessage={this.state.errors.currentEmail}>
                                <div className="task grid-x align-middle">
                                    <div className="cell auto">
                                        <Input
                                            value={this.state.currentEmail}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.setState({ currentEmail: event.currentTarget.value, errors: { currentEmail: null } }, this.validateEmail);
                                            }}
                                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                // Enter pressed
                                                if (event.keyCode === 13) {
                                                    event.preventDefault();
                                                    const error = Validator.validateShareRequestEmail(
                                                        this.state.currentEmail,
                                                        this.state.emails.map(i => i.email),
                                                    );
                                                    if (!error) {
                                                        this.onAddClick();
                                                    }
                                                }
                                            }}
                                            placeholder={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.custom.email.placeholder" })}
                                            hasError={!!this.state.errors.currentEmail}
                                        />
                                    </div>
                                    <div className="cell delete">
                                        <Button
                                            link
                                            icon={{ name: "fa-plus", large: true }}
                                            ariaLabel={Intl.formatMessage({ id: "common.add" })}
                                            title={Intl.formatMessage({ id: "common.add" })}
                                            onClick={() => {
                                                const error = Validator.validateShareRequestEmail(
                                                    this.state.currentEmail,
                                                    this.state.emails.map(i => i.email),
                                                );
                                                if (!error) {
                                                    this.onAddClick();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </InputWrapper>

                            {this.state.emails.length > 0 && (
                                <InputWrapper inputLabel="">
                                    {this.state.emails.map((item: EmailAndId, index: number) => {
                                        return (
                                            <div key={item.id} className="grid-x">
                                                <div className="medium-8">{item.email}</div>
                                                <div className="text-left medium-4">
                                                    <Button
                                                        link
                                                        className="mr-20"
                                                        icon={{ name: "fa-times", large: true }}
                                                        onClick={() => {
                                                            const emails = [...this.state.emails];
                                                            emails.splice(index, 1);
                                                            this.setState({ emails });
                                                        }}
                                                        ariaLabel={Intl.formatMessage({ id: "common.delete" })}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </InputWrapper>
                            )}
                        </>
                    )}
                    {this.props.accountType === AccountType.supporter && this.state.shareType.value === ShareType.publish && (
                        <>
                            <InputWrapper inputLabel={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.publish.comment.label" })}>
                                <TextArea
                                    value={this.state.comment}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        this.setState({ comment: event.currentTarget.value });
                                    }}
                                    placeholder={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.publish.comment.placeholder" })}
                                />
                            </InputWrapper>

                            <hr />

                            <Checkbox
                                label={Intl.formatMessage({ id: "sharedComponent.shareContentDialog.form.publish.privacyAccept.label" })}
                                checked={this.state.privacyAccepted}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({ privacyAccepted: event.currentTarget.checked });
                                }}
                            />
                        </>
                    )}

                    <hr />

                    <div className="row buttons">
                        <div className="cell medium-6">
                            <Button hollow label={Intl.formatMessage({ id: "common.cancel" })} onClick={this.props.onHide} disabled={this.state.isLoading} />
                        </div>
                        <div className="cell medium-6 text-right">
                            <Button
                                label={Intl.formatMessage({ id: `sharedComponent.shareContentDialog.form.${this.state.shareType.value}.button.label` })}
                                onClick={this.shareContent}
                                disabled={this.state.isLoading || this.isShareButtonDisabled()}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { accountType: state.account!.accountType };
};

export const ShareContentDialog = connect(mapStateToProps)(ShareContentDialogComponent);

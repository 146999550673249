import React, { PureComponent } from "react";
import { Intl } from "i18n/Intl";
import { ApplicationState } from "reducers/index";
import { connect, MapStateToProps } from "react-redux";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Input } from "components/Inputs/Input/Input";
import { FileInput } from "components/Inputs/FileInput/FileInput";
import { AllowedUploadContentTypes, AssetType, Tag } from "api/graphql/types";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Slot } from "components/Slot/Slot";
import { FilePreview } from "utils/FilePreview";
import { ImageSrc } from "utils/ImageSrc";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";
import isNil from "lodash";

interface ReduxProps {
    allowedUploadContentTypes: AllowedUploadContentTypes | null;
    tags: Tag[];
}

interface AssetObjectErrors {
    name: string | null;
    file: string | null;
}

interface AssetObject {
    id: string;
    name: string;
    file: File | null;
    url: string | null;
    progress: number | null;
    isLoading: boolean;
    errors: AssetObjectErrors;
    tags?: string[];
}

interface ComponentProps {
    assetObject: AssetObject;
    onChange: (assetObject: AssetObject | null, files: File[]) => void;
    isValidationEnabled: boolean;
    disabled?: boolean;
    multiple?: boolean;
}

interface State {
    selectedTags: string[];
    isTagSelectOpen: boolean;
}

type Props = ComponentProps & ReduxProps;

class AssetObjectFormComponent extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTags: [],
            isTagSelectOpen: false,
        };
    }

    private onNameChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const name: string = event.currentTarget.value;
        const nameError: string | null = IntlHelpers.getValidationError(Validator.validateAssetName(name));
        this.props.onChange({ ...this.props.assetObject, name, errors: { ...this.props.assetObject.errors, name: nameError } }, []);
    };

    private readonly onTagChange = (selectedTags: string[]) => {
        this.setState(
            {
                selectedTags,
            },
            () => {
                this.props.onChange({ ...this.props.assetObject, tags: selectedTags }, []);
            },
        );
    };

    private onFileSelected = async (files: File[]) => {
        if (files.length < 1) {
            return;
        }

        const file: File = files[0];
        const otherFiles: File[] = files.splice(1);

        const image: HTMLImageElement = new Image();
        let url: string = URL.createObjectURL(file);
        if (this.props.allowedUploadContentTypes?.audio.includes(file.type)) {
            url = ImageSrc.audioAsset;
        } else if (this.props.allowedUploadContentTypes?.video.includes(file.type)) {
            url = await new FilePreview(url!).getVideoThumbnail();
        }

        image.addEventListener("load", () => {
            const name: string = this.props.assetObject.name || file.name;
            this.props.onChange({ ...this.props.assetObject, name, file, url, tags: this.state.selectedTags, errors: { ...this.props.assetObject.errors, file: null, name: null } }, otherFiles);
        });

        image.src = url;
    };

    public render(): React.ReactElement<any> {
        const { assetObject, disabled, isValidationEnabled } = this.props;
        return (
            <>
                <div className="grid-x asset-object-form">
                    <div className="cell medium-5">
                        <div className="grid-x align-justify">
                            <div className="cell shrink w-135">
                                <div className="label pl-0">{Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.file.label" })}</div>
                            </div>
                            <div className="cell auto pr-25">
                                <InputWrapper errorMessage={isValidationEnabled ? assetObject.errors.file : null}>
                                    <FileInput
                                        progress={assetObject.progress}
                                        type={[AssetType.image, AssetType.audio, AssetType.video]}
                                        onFileSelected={this.onFileSelected}
                                        url={assetObject.url}
                                        disabled={disabled}
                                        placeholder={
                                            <Slot
                                                className="w-140 h-140"
                                                message={Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.file.placeholder" })}
                                                onCloseClick={(): void => {}}
                                                hasError={!!assetObject.errors.file}
                                                fileUpload
                                            />
                                        }
                                        renderContent={(url: string) => (
                                            <Slot
                                                className="w-140 h-140"
                                                message={Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.file.placeholder" })}
                                                url={url}
                                                onCloseClick={() => this.props.onChange(null, [])}
                                                fileUpload
                                            />
                                        )}
                                        multiple={this.props.multiple}
                                        previewEnabled={false}
                                    />
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                    <div className="cell medium-7">
                        <div className="label mb-5 pt-0 pr-0 pb-0 pl-0 pl-0">{Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.name.label" })}</div>
                        <InputWrapper className="mb-20" errorMessage={isValidationEnabled ? assetObject.errors.name : null}>
                            <Input
                                type="text"
                                value={assetObject.name}
                                onChange={this.onNameChange}
                                placeholder={Intl.formatMessage({ id: "sharedComponent.uploadAssetDialog.name.placeholder" })}
                                disabled={disabled}
                                hasError={isValidationEnabled && !isNil(assetObject.errors.name)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TagSelect
                                tags={this.props.tags}
                                selectedTags={this.state.selectedTags}
                                onChange={this.onTagChange}
                                renderTags={false}
                                onOpenChange={(isTagSelectOpen: boolean) => this.setState({ isTagSelectOpen })}
                            />
                        </InputWrapper>
                    </div>
                </div>
                <div className="asset-object-tags pl-135">
                    <div className="tags">
                        {!this.state.isTagSelectOpen
                            ? TagSelect.renderOnlyTags(this.state.selectedTags, (index: number): void => {
                                  const remainingTags = [...this.state.selectedTags];
                                  remainingTags.splice(index, 1);
                                  this.onTagChange(remainingTags);
                              })
                            : null}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { allowedUploadContentTypes: state.settings.allowedUploadContentTypes, tags: state.tags };
};

const AssetObjectForm = connect(mapStateToProps)(AssetObjectFormComponent);

export { AssetObjectForm };
export type { AssetObject, AssetObjectErrors };

import React, { Component, ComponentClass } from "react";
import {
    Account,
    AccountType,
    AgendaItemListItem,
    AgendaListItem,
    AssetListItem,
    AwardListItem,
    AwardListOptionsInput,
    DisabledContentListOptionsInput,
    EverydaySituationDirectoryListOptionsInput,
    FlowchartItemListItem,
    FlowchartListItem,
    InstantAwardListItem,
    InstantAwardListOptionsInput,
    SearchListOptionsInput,
    SearchListType,
    SelectionBoardListOptionsInput,
    storageSizeUsed,
    SelectionBoardListItem,
    SupportedClient,
    EverydaySituationDirectoryList,
} from "api/graphql/types";
import { SearchForm } from "components/SearchForm/SearchForm";
import { AgendaTable } from "./AgendaTable";
import { ExtraContentLibraryTypes, ListResult } from "api/ApiTypes";
import { AgendaItemTable } from "./AgendaItemTable";
import { FlowchartTable } from "./FlowchartTable";
import { FlowchartItemTable } from "./FlowchartItemTable";
import { AssetTable } from "./AssetTable";
import { RouteComponentProps, withRouter } from "react-router";
import { ContentLibraryUrlQueryParser } from "./ContentLibraryUrlQueryParser";
import { Api } from "api/Api";
import { Intl } from "i18n/Intl";
import { CreateContentButton } from "../CreateContentButton/CreateContentButton";
import { IntlHelpers } from "i18n/IntlHelpers";
import { PageContent } from "../ContentPageUtils";
import { connect, DispatchProp, MapStateToProps } from "react-redux";
import { ApplicationState } from "reducers/index";
import { AccountActions } from "actions/AccountActions";
import { Alert } from "components/Alert/Alert";
import { DirectoryList } from "models/ContentLibraryDirectories";
import { DirectoryUtils } from "utils/DirectoryUtils";
import { DisabledItemsTable } from "./DisabledItemsTable";
import { AnyDisabledItemType } from "utils/DisabledItemUtils";
import { EverydaySituationTable } from "./EverydaySituationTable";
import { InstantAwardTable } from "./InstantAwardTable";
import { AwardTable } from "./AwardTable";
import { SelectionBoardTable } from "./SelectionBoardTable";
import { Formatter } from "utils/Formatter";
import { ContentLibraryContentShareRequestTable } from "./ContentLibraryContentShareRequestTable";
import { TagSelect } from "components/Inputs/TagSelect/TagSelect";

import "./ContentLibraryTable.scss";

interface ReduxProps {
    account: Account;
}

interface ComponentProps {
    createdById?: string;
    pageContent: PageContent;
    client?: SupportedClient | null;
    directory?: DirectoryList[] | null;
    storageSizeUsed?: string;
    storageSizeLimit?: string;
    supporterExtId?: string | null;
    supervisorId?: string | null;
    supervisorName?: string | null;
    renderLinks: () => React.ReactElement<any>;
}

type Props = ReduxProps & ComponentProps & RouteComponentProps & DispatchProp;

interface State {
    search: string;
    isPublic?: boolean;
    searchListType?: SearchListType;
    tags: string[];
    extraContentLibraryType?: ExtraContentLibraryTypes;
    directory?: string;
    isDisabled?: boolean;
    isShared?: boolean;
}

class ContentLibraryTableComponent extends Component<Props, State> {
    private assetTableRef: AssetTable | null = null;

    private static getStateFromProps(props: Props): State {
        const { search, isPublic, searchListType, extraContentLibraryType, tags, directory, isDisabled, isShared } = new ContentLibraryUrlQueryParser().parse(props.location.search);
        return { search: search || "", isPublic, extraContentLibraryType, searchListType, directory, isDisabled, isShared, tags: tags || [] };
    }

    public readonly state: State = ContentLibraryTableComponent.getStateFromProps(this.props);

    public componentWillReceiveProps(nextProps: Props): void {
        const urlQueryParser = new ContentLibraryUrlQueryParser();
        const currentParams = urlQueryParser.parse(this.props.location.search);
        const newParams = urlQueryParser.parse(nextProps.location.search);
        // Skip search param
        if (
            currentParams.isPublic !== newParams.isPublic ||
            currentParams.searchListType !== newParams.searchListType ||
            currentParams.directory !== newParams.directory ||
            currentParams.extraContentLibraryType !== newParams.extraContentLibraryType ||
            currentParams.isDisabled !== newParams.isDisabled ||
            currentParams.isShared !== newParams.isShared
        ) {
            this.setState(ContentLibraryTableComponent.getStateFromProps(nextProps), () => {
                if (currentParams.directory !== newParams.directory && newParams.searchListType === SearchListType.asset) {
                    if (this.assetTableRef) {
                        this.assetTableRef.reloadAssets();
                    }
                }
            });
        }
    }

    private reloadStorageSizeUsed = async (): Promise<void> => {
        try {
            const storageSizeUsed: storageSizeUsed = await Api.getStorageSizeUsed();
            this.props.dispatch(AccountActions.updateAccount({ ...this.props.account, storageSizeUsed: storageSizeUsed.me.storageSizeUsed }));
        } catch (error) {
            Alert.error({ title: IntlHelpers.getMessageFromError(error) });
        }
    };

    private getAgendas = async (options?: SearchListOptionsInput, tags?: string[]): Promise<ListResult<AgendaListItem>> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        return Api.getContentLibrary(SearchListType.agenda, this.state.isPublic, options, clientId, this.props.createdById, null, tags);
    };

    private getAgendaItems = async (options?: SearchListOptionsInput, tags?: string[]): Promise<ListResult<AgendaItemListItem>> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        return Api.getContentLibrary(SearchListType.agendaItem, this.state.isPublic, options, clientId, this.props.createdById, null, tags);
    };

    private getFlowcharts = async (options?: SearchListOptionsInput, tags?: string[]): Promise<ListResult<FlowchartListItem>> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        return Api.getContentLibrary(SearchListType.flowchart, this.state.isPublic, options, clientId, this.props.createdById, null, tags);
    };

    private getFlowchartItems = async (options?: SearchListOptionsInput, tags?: string[]): Promise<ListResult<FlowchartItemListItem>> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        return Api.getContentLibrary(SearchListType.flowchartItem, this.state.isPublic, options, clientId, this.props.createdById, null, tags);
    };

    private getAssets = async (options?: SearchListOptionsInput, tags?: string[]): Promise<ListResult<AssetListItem>> => {
        const clientId: string | undefined = this.props.client ? this.props.client.id : undefined;
        return Api.getContentLibrary(SearchListType.asset, this.state.isPublic, options, clientId, this.props.createdById, this.state.directory || null, tags);
    };

    private readonly getDisabledContent = async (options?: DisabledContentListOptionsInput): Promise<ListResult<AnyDisabledItemType>> => {
        return await Api.getDisabledPublicContent({ options });
    };

    private getClientEverydaySituationDirectoryList = async (options?: EverydaySituationDirectoryListOptionsInput): Promise<ListResult<EverydaySituationDirectoryList>> => {
        const clientExtId: string | undefined = this.props.client ? this.props.client.extId : "";
        return Api.getClientEverydaySituationDirectoryList(clientExtId, options);
    };

    private getClientInstantAwardList = async (options?: InstantAwardListOptionsInput): Promise<ListResult<InstantAwardListItem>> => {
        const clientExtId: string | undefined = this.props.client ? this.props.client.extId : "";
        return Api.getClientInstantAwardList(clientExtId, options);
    };

    private getClientAwardList = async (options?: AwardListOptionsInput): Promise<ListResult<AwardListItem>> => {
        const clientExtId: string | undefined = this.props.client ? this.props.client.extId : "";
        return Api.getClientAwardList(clientExtId, options);
    };

    private getClientSelectionBoardList = async (options?: SelectionBoardListOptionsInput): Promise<ListResult<SelectionBoardListItem>> => {
        const clientExtId: string | undefined = this.props.client ? this.props.client.extId : "";
        return Api.getClientSelectionBoardList(clientExtId, options);
    };

    private onSearchClick = (search: string, tags?: string[]): void => {
        this.setState({ search, tags: tags || [] });
    };

    private renderTitle(): React.ReactElement<any> {
        if (this.state.isShared) {
            return IntlHelpers.asHtml(
                { id: "sharedComponent.contentLibraryTable.tableTitle" },
                {
                    library: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.shared" }),
                    searchListType: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.sharedContents" }),
                },
            );
        }

        let library: string = Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.mine" });
        if (this.state.isPublic) {
            library = Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.public" });
        } else if (this.props.client) {
            let name = this.props.client.name;
            if (![AccountType.supporter].includes(this.props.account.accountType)) {
                name = Formatter.formatExtId(this.props.client.extId);
            }
            library = Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.client" }, { name });
        } else if (this.props.supporterExtId) {
            library = Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.supported" }, { extId: Formatter.formatExtId(this.props.supporterExtId) });
        } else if (this.props.supervisorId) {
            library = Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.supervisor" }, { name: this.props.supervisorName });
        }

        let searchListType: string = "";
        if (this.state.searchListType) {
            searchListType = Intl.formatMessage({ id: `enum.searchListType.${this.state.searchListType}` });
        }

        if (this.state.extraContentLibraryType) {
            searchListType = Intl.formatMessage({ id: `sharedComponent.contentLibraryTable.extraContentLibraryType.${this.state.extraContentLibraryType}` });
        }

        if (this.state.isDisabled) {
            return IntlHelpers.asHtml(
                { id: "sharedComponent.contentLibraryTable.tableTitle" },
                {
                    library: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.mine" }),
                    searchListType: Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.libraryType.disabled" }),
                },
            );
        }

        let title: JSX.Element | null = null;
        if (this.props.directory) {
            const directory: DirectoryList | null = DirectoryUtils.getDirectoryById(this.props.directory, this.state.directory || "");
            if (directory) {
                title = IntlHelpers.asHtml({ id: "sharedComponent.contentLibraryTable.tableDirectoryTitle" }, { library, searchListType, directoryName: directory.name });
            }
        }

        if (!title) {
            title = IntlHelpers.asHtml({ id: "sharedComponent.contentLibraryTable.tableTitle" }, { library, searchListType });
        }
        return (
            <span className="row table-title-row">
                <span className="col-md-9 p-0">{title}</span>
                <span className="col-md-3 p-0">
                    <SearchForm
                        defaultValue={this.state.search}
                        searchPlaceholder={Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.searchForm.placeholder" })}
                        onSearchClick={this.onSearchClick}
                        icon={true}
                        withTags={!this.state.extraContentLibraryType}
                        hideSelectedTags={true}
                        selectedTags={this.state.tags}
                    />
                </span>
            </span>
        );
    }

    private readonly reloadAssets = (): void => {
        if (this.assetTableRef) {
            this.assetTableRef.reloadAssets();
        }
        this.reloadStorageSizeUsed();
    };

    private readonly onAssetUploaded = (): void => {
        if (this.state.searchListType === SearchListType.asset) {
            if (this.assetTableRef) {
                this.assetTableRef.reloadAssets();
            }
            this.reloadStorageSizeUsed();
        }
    };

    private renderTable = (): React.ReactElement<any> | null => {
        const urlQueryParser = new ContentLibraryUrlQueryParser();
        const currentUrlParams: string = urlQueryParser.getUrlQuery({
            searchListType: this.state.searchListType,
            search: this.state.search,
            isPublic: this.state.isPublic,
            directory: this.state.directory,
            isDisabled: this.state.isDisabled,
            isShared: this.state.isShared,
            extraContentLibraryType: this.state.extraContentLibraryType,
        });

        const props = {
            createdById: this.props.createdById,
            client: this.props.client,
            currentUrlParams,
            isPublic: this.state.isPublic,
            search: this.state.search,
            tags: this.state.tags,
            directory: this.state.directory,
            pageContent: this.props.pageContent,
            supporterExtId: this.props.supporterExtId,
            supervisorId: this.props.supervisorId,
            accountType: this.props.account.accountType,
            isDisabled: this.state.isDisabled,
        };

        if (props.isDisabled) {
            return <DisabledItemsTable currentUrlParams={props.currentUrlParams} getDisabledContent={this.getDisabledContent} search={props.search} />;
        }

        if (this.state.isShared) {
            return <ContentLibraryContentShareRequestTable search={this.state.search} />;
        }

        if (this.state.extraContentLibraryType && this.props.client) {
            switch (this.state.extraContentLibraryType) {
                case ExtraContentLibraryTypes.everydaySituations:
                    return <EverydaySituationTable {...this.props} {...props} getClientEverydaySituationDirectoryList={this.getClientEverydaySituationDirectoryList} client={this.props.client} />;
                case ExtraContentLibraryTypes.instantAward:
                    return <InstantAwardTable {...this.props} {...props} getInstantAwardList={this.getClientInstantAwardList} client={this.props.client} />;
                case ExtraContentLibraryTypes.award:
                    return <AwardTable {...this.props} {...props} getAwardList={this.getClientAwardList} client={this.props.client} />;
                case ExtraContentLibraryTypes.selectionBoard:
                    return <SelectionBoardTable {...this.props} {...props} getSelectionBoardList={this.getClientSelectionBoardList} client={this.props.client} />;
            }
        }

        switch (this.state.searchListType) {
            case SearchListType.agenda:
                return <AgendaTable getAgendas={this.getAgendas} {...props} />;
            case SearchListType.agendaItem:
                return <AgendaItemTable getAgendaItems={this.getAgendaItems} {...props} />;
            case SearchListType.flowchart:
                return <FlowchartTable getFlowcharts={this.getFlowcharts} {...props} />;
            case SearchListType.flowchartItem:
                return <FlowchartItemTable getFlowchartItems={this.getFlowchartItems} {...props} />;
            case SearchListType.asset:
                return (
                    <AssetTable
                        ref={(ref: AssetTable | null): void => {
                            this.assetTableRef = ref;
                        }}
                        getAssets={this.getAssets}
                        {...this.props}
                        {...props}
                        reloadAssets={this.reloadAssets}
                    />
                );
            default:
                return null;
        }
    };

    public render(): React.ReactElement<any> {
        const isContentButtonEnabled: boolean =
            [AccountType.supporter, AccountType.supervisor].includes(this.props.account.accountType) && ![PageContent.clientDetail, PageContent.supporterDetail].includes(this.props.pageContent);
        return (
            <>
                <div className="side-bar-left">
                    {isContentButtonEnabled && <CreateContentButton onAssetUploaded={this.onAssetUploaded} isUploadEnabled={!this.props.client} client={this.props.client} />}
                    {this.props.renderLinks && this.props.renderLinks()}

                    {this.props.createdById && this.props.storageSizeLimit && (
                        <p className="usage">
                            {this.props.storageSizeUsed} / {Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.storageSizeInUse" }, { storageSize: this.props.storageSizeLimit })}
                        </p>
                    )}
                </div>

                <div className="left-side content-library-table pt-0">
                    {this.renderTitle()}
                    {this.state.tags.length > 0 && (
                        <span style={{ marginTop: 12 }}>
                            <div className="table-tag-search" style={{ marginBottom: 10 }}>
                                {Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.searchWithTags.label" })}
                            </div>
                            <div className="grid-x">
                                <div className="cell" style={{ width: "82%" }}>
                                    {TagSelect.renderOnlyTags(this.state.tags, (index: number) => {
                                        const tags = [...this.state.tags];
                                        tags.splice(index, 1);
                                        this.setState({ tags });
                                    })}
                                </div>
                                <div className="cell text-right" style={{ width: "18%" }}>
                                    <span
                                        className="table-tag-remove-all"
                                        onClick={() => {
                                            this.setState({ tags: [] });
                                        }}
                                    >
                                        <i className="fa fa-trash mr-10" /> {Intl.formatMessage({ id: "sharedComponent.contentLibraryTable.searchWithTags.removeAll" })}
                                    </span>
                                </div>
                            </div>
                            <hr />
                        </span>
                    )}
                    {this.renderTable()}
                </div>
            </>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { account: state.account! };
};

export const ContentLibraryTable: ComponentClass<ComponentProps> = withRouter(connect(mapStateToProps)(ContentLibraryTableComponent));

import React from "react";
import { SearchListType, FlowchartContent, AssetType } from "api/graphql/types";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { withDragContext } from "../DragContext";
import { DragUtils } from "../DragUtils";
import { ContentInput, GenericComponentProps } from "./ContentInput";
import { Intl } from "i18n/Intl";
import { ContentElement } from "pages/_shared/Draggables/ContentElement/ContentElement";
import { ImageSrc } from "utils/ImageSrc";
import { ApiTypes } from "api/ApiTypes";
import { ContentElementUtils } from "../ContentElement/ContentElementUtils";
import { Draggable } from "../Draggable";
import { store } from "store";
import { DialogsActions } from "actions/DialogsActions";
import { DialogType } from "components/DialogContainer/DialogsContainer";
import { OverlayIcon } from "components/OverlayIcon";

interface ComponentProps extends GenericComponentProps<FlowchartContent> {
    slotMessageOverride?: string;
}

class FlowchartInputComponent extends ContentInput<FlowchartContent, ComponentProps> {
    protected searchListType: SearchListType = SearchListType.flowchart;
    protected slotDisabledMessage = undefined;
    protected slotMessage = (): string => this.props.slotMessageOverride ?? Intl.formatMessage({ id: "sharedComponent.contentInput.flowchart.slotMessage" });

    protected renderDraggable = (flowchart: FlowchartContent, index: number): React.ReactElement<any> => {
        const asset = flowchart.image;
        return (
            <Draggable
                draggableId={`${this.props.droppableId}-flowchart-${index}-${flowchart.id}`}
                droppableId={this.props.droppableId}
                key={`flowchart-${index}-${flowchart.id}`}
                index={index}
                type={SearchListType.flowchart}
                isDragDisabled={this.isDragDisabled()}
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? "grabbed" : ""}
                        style={DragUtils.getStyle(provided.draggableProps.style, snapshot)}
                    >
                        <ContentElement
                            title={flowchart.title}
                            fallbackImage={ImageSrc[this.searchListType]}
                            images={flowchart.image ? [ApiTypes.getAssetImageUrl(flowchart.image)] : []}
                            meta={Intl.formatMessage({ id: "enum.searchListType.flowchart" })}
                            contentChildren={ContentElementUtils.getFlowchartFlowchartItems(flowchart)}
                            onCloseClick={() => this.onCloseClick(flowchart, index)}
                            reorder={this.getReorderOptions(index)}
                            disabled={this.props.disabled}
                            onViewClick={this.onViewClick(flowchart)}
                            onEditClick={this.onEditClick(flowchart)}
                            onPreviewClick={
                                asset
                                    ? (): void => {
                                          store.dispatch(
                                              DialogsActions.show({
                                                  type: DialogType.showAsset,
                                                  assetUrl: asset?.url,
                                                  assetType: asset?.assetType || AssetType.image,
                                                  originalFileName: asset?.title,
                                                  thumbnailUrl: asset?.thumbnailUrl,
                                                  dialogTitle: asset?.title || Intl.formatMessage({ id: `enum.assetType.${asset?.assetType}` }),
                                              }),
                                          );
                                      }
                                    : undefined
                            }
                            overlayIcon={asset ? <OverlayIcon assetType={asset?.assetType} /> : undefined}
                        />
                    </div>
                )}
            </Draggable>
        );
    };
}

export const FlowchartInput = withDragContext(FlowchartInputComponent);

import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Intl } from "i18n/Intl";

import "./SortableList.scss";

export interface Sortable {
    id: string;
}

interface Props<T extends Sortable> {
    data: T[];
    renderItem: (item: T, index: number, array: T[]) => React.ReactElement<any> | null;
    onOrderChange: (data: T[]) => void;
}

interface State {}

export class SortableList<T extends Sortable> extends Component<Props<T>, State> {
    private readonly reorder = (list: T[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    private readonly getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        padding: 10,
        position: "relative",
        backgroundColor: "transparent",
        ...draggableStyle,
    });

    private readonly onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = this.reorder(this.props.data, result.source.index, result.destination.index);
        this.props.onOrderChange(items);
    };

    private readonly onReorderClick = (up: boolean, index: number) => () => {
        const items = this.reorder(this.props.data, index, up ? index - 1 : index + 1);
        this.props.onOrderChange(items);
    };

    public render(): React.ReactElement<any> {
        return (
            <div className="sortable-list">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, _snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {this.props.data.map((item, index, array) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                className={`${snapshot.isDragging ? "grabbed" : ""}`}
                                            >
                                                <div className="content-element">
                                                    {this.props.renderItem(item, index, array)}

                                                    <div className="drag-space">
                                                        {index !== 0 && <div className="up" title={Intl.formatMessage({ id: "common.up" })} onClick={this.onReorderClick(true, index)} />}
                                                        {index !== array.length - 1 && (
                                                            <div className="down" title={Intl.formatMessage({ id: "common.down" })} onClick={this.onReorderClick(false, index)} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

import React, { Component } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router";
import { TabList, Tab, TabPanel, Tabs } from "react-tabs";
import { Link } from "react-router-dom";
import { Path } from "utils/Path";
import { Intl } from "i18n/Intl";
import { InputWrapper } from "components/InputWrapper/InputWrapper";
import { Button } from "components/Button/Button";
import { PasswordInput } from "components/Inputs/PasswordInput/PasswordInput";
import { IntlHelpers } from "i18n/IntlHelpers";
import { Validator } from "utils/Validator";
import { Api } from "api/Api";
import { Alert } from "components/Alert/Alert";
import { TestId } from "utils/TestId";
import { Title } from "components/Title";

interface RouteParams {
    data?: string;
}

type Props = RouteComponentProps<RouteParams>;

interface State {
    password: string;
    passwordRepeat: string;
    formErrors: {
        password: string | null;
        passwordRepeat: string | null;
    };
    isLoading: boolean;
}

class ResetPasswordPageComponent extends Component<Props, State> {
    private id?: string;
    private code?: string;

    private passwordRef: HTMLInputElement | null = null;
    private passwordRepeatRef: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            password: "",
            passwordRepeat: "",
            formErrors: {
                password: null,
                passwordRepeat: null,
            },
            isLoading: false,
        };

        const { data } = this.props.match.params;
        if (data) {
            const [id, code] = data.split(":");
            this.id = id;
            this.code = code;
        }
    }

    private onActivateSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const { password, passwordRepeat } = this.state;
        const formErrors = {
            password: IntlHelpers.getValidationError(Validator.validatePassword(password)),
            passwordRepeat: IntlHelpers.getValidationError(Validator.validatePasswordRepeat(password, passwordRepeat)),
        };

        if (formErrors.password || formErrors.passwordRepeat) {
            this.setState({ isLoading: false, formErrors }, () => {
                if (!!formErrors.password && this.passwordRef) {
                    this.passwordRef.focus();
                } else if (!!formErrors.passwordRepeat && this.passwordRepeatRef) {
                    this.passwordRepeatRef.focus();
                }
            });
            return;
        }

        this.setState(
            { isLoading: true },
            async (): Promise<void> => {
                try {
                    await Api.resetPassword(this.id!, this.code!, password);
                    Alert.success({
                        title: Intl.formatMessage({ id: "page.resetPassword.resetPasswordSucceed.message" }),
                        callback: (): void => {
                            this.props.history.push(Path.login);
                        },
                    });
                } catch (error) {
                    Alert.error({ title: IntlHelpers.getMessageFromError(error) });
                    this.setState({ isLoading: false });
                }
            },
        );
    };

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const password: string = event.target.value;
        const passwordError: string | null = IntlHelpers.getValidationError(Validator.validatePassword(password));
        const passwordRepeatError: string | null = IntlHelpers.getValidationError(Validator.validatePasswordRepeat(password, this.state.passwordRepeat));
        this.setState({ password, formErrors: { ...this.state.formErrors, password: passwordError, passwordRepeat: passwordRepeatError } });
    };

    private onPasswordRepeatChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const passwordRepeat: string = event.target.value;
        const passwordRepeatError: string | null = IntlHelpers.getValidationError(Validator.validatePasswordRepeat(this.state.password, passwordRepeat));
        this.setState({ passwordRepeat, formErrors: { ...this.state.formErrors, passwordRepeat: passwordRepeatError } });
    };

    public render(): React.ReactElement<any> {
        if (!this.id || !this.code) {
            return <Redirect to={Path.login} />;
        }

        const { password, passwordRepeat, formErrors } = this.state;
        const isActivateButtonDisabled: boolean = !password || !passwordRepeat || !!formErrors.password || !!formErrors.passwordRepeat;
        return (
            <div>
                <Title>{Intl.formatMessage({ id: "page.resetPassword.pageTitle" })}</Title>
                <main className="guest-page">
                    <Tabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
                        <TabList>
                            <Tab>
                                <Link to={this.props.location.pathname}>{Intl.formatMessage({ id: "page.resetPassword.title" })}</Link>
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <form noValidate={true} onSubmit={this.onActivateSubmit}>
                                <InputWrapper
                                    id={TestId.resetPassword.passwordInput}
                                    inputLabel={Intl.formatMessage({ id: "page.resetPassword.form.password.label" })}
                                    errorMessage={formErrors.password}
                                >
                                    <PasswordInput
                                        innerRef={(ref: HTMLInputElement | null): void => {
                                            this.passwordRef = ref;
                                        }}
                                        placeholder={Intl.formatMessage({ id: "page.resetPassword.form.password.placeholder" })}
                                        value={password}
                                        onChange={this.onPasswordChange}
                                        hasError={!!formErrors.password || !!formErrors.passwordRepeat}
                                        name="password"
                                        tabIndex={1}
                                    />
                                </InputWrapper>

                                <InputWrapper
                                    id={TestId.resetPassword.passwordRepeatInput}
                                    inputLabel={Intl.formatMessage({ id: "page.resetPassword.form.passwordRepeat.label" })}
                                    errorMessage={formErrors.passwordRepeat}
                                >
                                    <PasswordInput
                                        innerRef={(ref: HTMLInputElement | null): void => {
                                            this.passwordRepeatRef = ref;
                                        }}
                                        placeholder={Intl.formatMessage({ id: "page.resetPassword.form.passwordRepeat.placeholder" })}
                                        value={passwordRepeat}
                                        onChange={this.onPasswordRepeatChange}
                                        name="passwordRepeat"
                                        tabIndex={2}
                                        hasError={!!formErrors.passwordRepeat}
                                    />
                                </InputWrapper>

                                <div className="button-wrapper">
                                    <div className="grid-x">
                                        <div className="cell">
                                            <Button
                                                disabled={isActivateButtonDisabled}
                                                ariaLabel={Intl.formatMessage({ id: "page.resetPassword.form.button" })}
                                                label={Intl.formatMessage({ id: "page.resetPassword.form.button" })}
                                                type="submit"
                                                tabIndex={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                    </Tabs>
                </main>
            </div>
        );
    }
}

export const ResetPasswordPage = withRouter(ResetPasswordPageComponent);

// tslint:disable-next-line: no-default-export
export default ResetPasswordPage;

import React, { Component } from "react";
import { Table, Column } from "components/Table/Table";
import { Button } from "components/Button/Button";
import { ClientSupporter, ClientChangeManagerRequest } from "api/graphql/types";
import { Intl } from "i18n/Intl";
import { ClientSupporterStatus } from "api/ApiTypes";
import { ObjectUtils } from "utils/ObjectUtils";
import { TooltipWrapper } from "components/TooltipWrapper/TooltipWrapper";
import { Log } from "utils/Log";
import { Color } from "theme/Color";
import { SupporterCollaborator, SupporterCollaboratorNew, SupporterManager, SupporterManagerNew } from "components/Svg";

interface Props {
    data: ClientSupporter[];
    pendingClientManagerChangeRequest: ClientChangeManagerRequest | null;
    managedBySupporterId: string;
    onRemove: (supporter: ClientSupporter) => Promise<void> | void;
    accountId: string;
}

interface State {
    selectedSupporterForDelete: ClientSupporter | null;
}

enum SupporterListColumn {
    email = "email",
    name = "name",
    role = "role",
    status = "status",
    remove = "remove",
}

class SupporterList extends Component<Props, State> {
    public readonly state: State = {
        selectedSupporterForDelete: null,
    };

    private isNewCollaborator(clientSupporter: ClientSupporter): boolean {
        return clientSupporter.status === ClientSupporterStatus.pending;
    }

    private isCollaborator(clientSupporter: ClientSupporter): boolean {
        return clientSupporter.status === ClientSupporterStatus.active;
    }

    private isManager(clientSupporter: ClientSupporter): boolean {
        return !!clientSupporter.account && this.props.managedBySupporterId === clientSupporter.account.id;
    }

    private isNewManager(clientSupporter: ClientSupporter): boolean {
        return !!this.props.pendingClientManagerChangeRequest && !!clientSupporter.account && this.props.pendingClientManagerChangeRequest.newManager?.id === clientSupporter.account.id;
    }

    private getRoleCell(clientSupporter: ClientSupporter): React.ReactElement<any> | null {
        if (this.isNewManager(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientProfile.supporterList.table.newManager" })}>
                    <SupporterManagerNew className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isManager(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientProfile.supporterList.table.manager" })}>
                    <SupporterManager className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isNewCollaborator(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientProfile.supporterList.table.newCollaborator" })}>
                    <SupporterCollaboratorNew className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        if (this.isCollaborator(clientSupporter)) {
            return (
                <TooltipWrapper tooltip={Intl.formatMessage({ id: "page.clientProfile.supporterList.table.collaborator" })}>
                    <SupporterCollaborator className="mr-20" color={Color.primaryL} />
                </TooltipWrapper>
            );
        }

        Log.warning("Unknown role found for supporter: ", clientSupporter);
        return null;
    }

    private readonly columns: Array<Column<ClientSupporter>> = ObjectUtils.enumAsArray<SupporterListColumn>(SupporterListColumn).map(
        (columnName: SupporterListColumn): Column<ClientSupporter> => ({
            id: columnName,
            name: Intl.formatMessage({ id: `page.clientProfile.supporterList.table.columns.${columnName}` }),
            accessor: columnName as keyof ClientSupporter,
            renderCell: (clientSupporter: ClientSupporter): React.ReactElement<any> | null => {
                switch (columnName) {
                    case SupporterListColumn.role:
                        return this.getRoleCell(clientSupporter);
                    case SupporterListColumn.status:
                        return <>{Intl.formatMessage({ id: `enum.clientSupporterStatus.${clientSupporter.status}` })}</>;
                    case SupporterListColumn.remove:
                        if (this.props.accountId === this.props.managedBySupporterId) {
                            // I'm the manager, so I can't remove myself
                            if (clientSupporter.account?.id === this.props.accountId) {
                                return <React.Fragment />;
                            }
                        } else {
                            // I'm not the manager, so I can remove myself, but no one else
                            if (clientSupporter.account?.id !== this.props.accountId) {
                                return <React.Fragment />;
                            }
                        }
                        return <Button link icon={{ name: "fa-trash", large: true }} aria-label="delete" onClick={this.onDeleteSupporter(clientSupporter)} />;
                    default:
                        return null;
                }
            },
        }),
    );

    private onDeleteSupporter = (supporter: ClientSupporter) => (): void => {
        this.setState({ selectedSupporterForDelete: supporter });
    };

    private cancelSelectionForDelete = (): void => {
        this.setState({ selectedSupporterForDelete: null });
    };

    public render(): React.ReactElement<any> {
        if (this.props.data.length === 0) {
            return (
                <p>
                    <strong>{Intl.formatMessage({ id: "page.clientProfile.supporterList.noData" })}</strong>
                </p>
            );
        }
        return (
            <Table
                keyExtractor={(item: ClientSupporter, column?: Column<ClientSupporter>): string => item.createdAt + (column ? column.id : "")}
                columns={this.columns}
                data={this.props.data}
                count={this.props.data.length}
                currentPage={1}
                renderUnderRow={(supporter: ClientSupporter): React.ReactElement<any> | null => {
                    if (this.state.selectedSupporterForDelete && this.state.selectedSupporterForDelete.email === supporter.email) {
                        return (
                            <div className="table-row no-hover action-button-container">
                                <div className="table-cell text-right">
                                    <Button
                                        hollow
                                        title={Intl.formatMessage({ id: "common.cancel" })}
                                        label={Intl.formatMessage({ id: "common.cancel" })}
                                        onClick={() => {
                                            this.cancelSelectionForDelete();
                                        }}
                                    />
                                    <Button
                                        label={Intl.formatMessage({ id: "common.remove" })}
                                        title={Intl.formatMessage({ id: "common.remove" })}
                                        onClick={async () => {
                                            try {
                                                await this.props.onRemove(supporter);
                                            } catch (error) {
                                                /** Error handled in ClientProfilePage */
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }
                    return null;
                }}
                isLoading={false}
                renderEmpty={(): string => Intl.formatMessage({ id: "page.clientProfile.supporterList.noData" })}
            />
        );
    }
}

export { SupporterList };

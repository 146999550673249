import React, { Component } from "react";
import { Dialog } from "components/Dialog/Dialog";
import { DialogVisibilityProps } from "./DialogsContainer";

export interface InfoDialogProps {
    title: string;
    message: string;
}

type Props = InfoDialogProps & DialogVisibilityProps;

class InfoDialog extends Component<Props> {
    public render(): React.ReactElement<any> {
        return (
            <Dialog visible={this.props.isVisible} title={this.props.title} onCloseClick={this.props.onHide}>
                <p>{this.props.message}</p>
            </Dialog>
        );
    }
}

export { InfoDialog };
